import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { IconComponent } from "@metranpage/components";
import { AngularSvgIconModule } from "angular-svg-icon";
import { Observable } from "rxjs";
import { StyleSettings } from "../../../../models/styles";
import { MarkupPreviewService } from "../../../../services/preview.service";
import { TableContextMenuComponent } from "../../../../views/markup-editor/blocks/table-block/components/table-context-menu/table-context-menu.component";
import { CellPosition } from "../../../../views/markup-editor/blocks/table-block/interfaces/cell-position.interface";
import { Cell } from "../../../../views/markup-editor/blocks/table-block/interfaces/cell.type";
import { SelectedCells } from "../../../../views/markup-editor/blocks/table-block/interfaces/selected-cells.interface";
import { TableBlockService } from "../../../../views/markup-editor/blocks/table-block/services/table-block.service";
import { TableOperationService } from "../../../../views/markup-editor/blocks/table-block/services/table-operation.service";
import {
  BlockId,
  EditorBlock,
  FakeCursorNode,
  SelectionState,
  SetCursorOptions,
} from "../../../../views/markup-editor/editor.models";
import { EditorStore } from "../../../../views/markup-editor/editor.store";

export const CELL_LENGTH_LIMIT = 1000;

@Component({
  standalone: true,
  selector: "m-markup-editor-table-block",
  imports: [CommonModule, AngularSvgIconModule, TableContextMenuComponent, IconComponent],
  templateUrl: "./table-block.component.html",
  styleUrls: ["./table-block.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableBlockComponent implements OnChanges, AfterViewInit {
  @Input()
  data!: EditorBlock;

  @Input() styleDisplayOpts: StyleSettings | null = null;

  @Input()
  inlineToolbar!: ElementRef;

  @Output()
  contentChanged = new EventEmitter<EditorBlock>();

  protected selectedBlocksIds$: Observable<BlockId[]>;

  _awaitCursor = false;
  _cursorOpts?: SetCursorOptions;

  tableContextMenuVisible = false;
  tableContextMenuPositionX: number | undefined;
  tableContextMenuPositionY: number | undefined;
  tableSelectedCells: SelectedCells = {
    start: { rI: 0, cI: 0 },
    stop: { rI: 0, cI: 0 },
  };
  tableSelectedRowsCount = 1;
  tableSelectedColumnsCount = 1;
  selectedCells: HTMLTableCellElement[] = [];

  hoveredCell: HTMLTableCellElement | undefined;
  tableToolbarVisible = false;
  tableToolbarRowPositionX: number | undefined;
  tableToolbarRowPositionY: number | undefined;
  tableToolbarColumnPositionX: number | undefined;
  tableToolbarColumnPositionY: number | undefined;

  @ViewChild("content", { read: ElementRef })
  editorContainer!: ElementRef;

  constructor(
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    private readonly tableBlockService: TableBlockService,
    private readonly tableOperationService: TableOperationService,
    private readonly editorStore: EditorStore,
    private readonly previewService: MarkupPreviewService,
  ) {
    this.selectedBlocksIds$ = this.editorStore.getSelectedBlockIdsObservable();
  }

  ngAfterViewInit(): void {
    this.tableBlockService.setDataToHtml(this.data.data, this.editorContainer, this.renderer);

    this.selectedBlocksIds$.subscribe({
      next: (selectedBlockId) => {
        if (!selectedBlockId.includes(this.data.id)) {
          this.tableBlockService.saveData(this.data.data, this.editorContainer);
          this.tableOperationService.clearAllCellsSelected(this.editorContainer);
        }
      },
    });

    if (this._awaitCursor) {
      // if focus was requested before view was created
      this._awaitCursor = false;
      this.setCursor(this._cursorOpts!);
    }

    const style = this.elementRef.nativeElement.style;
    // style.setProperty('--font-size', this.config.fontSize);

    this.changeDetectorRef.detectChanges();
  }

  /**
   * When input data changes - apply new changes to model
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.applyStyleOpts();

    if (this.editorContainer) {
      this.data = changes.data.currentValue;
      this.tableBlockService.setDataToHtml(this.data.data, this.editorContainer, this.renderer);

      this.changeDetectorRef.detectChanges();
    }
  }

  private applyStyleOpts() {
    if (this.styleDisplayOpts) {
      this.previewService.applyPreviewStyle(this.elementRef.nativeElement as Element, this.styleDisplayOpts, false);

      const element = this.elementRef.nativeElement.querySelector(".editor");
    }
    this.changeDetectorRef.markForCheck();
  }

  /**
   * When user inputs data - save it to temp field and apply to model onBlur
   */
  onBlur(event: any): void {
    this.tableBlockService.saveData(this.data.data, this.editorContainer);

    this.contentChanged.emit(this.data.data);
  }

  onChange(event: any) {
    // do nothing, as we don't cache data additionally
  }

  setCursor(opts: SetCursorOptions): void {
    if (!this.editorContainer) {
      // if focus was requested before view initted, then we await view init and focus there
      this._awaitCursor = true;
      this._cursorOpts = opts;
      return;
    }

    this.setCursorInElement(this.editorContainer.nativeElement, opts);
  }

  removeFocus(): void {
    this.editorContainer.nativeElement.blur();
  }

  setCursorInElement(element: HTMLElement, opts?: SetCursorOptions) {
    let atStart: boolean | undefined = false;
    let atEnd: boolean | undefined = false;

    if (!opts) {
      atStart = true;
    } else {
      atStart = opts.atStart;
      atEnd = opts.atEnd;
    }

    if (!atStart && !atEnd) {
      // if both parameters false, then select at start
      atStart = true;
    }

    let position = 0;
    if (atStart) {
      position = 0;
    }
    if (atEnd) {
      position = element.childNodes.length;
    }

    const sel = window.getSelection();

    const range = document.createRange();

    range.setStart(element, position);

    range.collapse(true);
    // range.setEnd(element, position);

    sel?.removeAllRanges();
    sel?.addRange(range);

    element.focus();
    element.normalize();
  }

  onSelectionChange(range: Range | undefined) {
    if (!range) {
      return undefined;
    }

    if (!range.collapsed) {
      this.positionInlineToolbarToSelection(range);
    }

    return this.getSelectionState(range);
  }

  protected positionInlineToolbarToSelection(range: Range) {
    const selectionRect = range.getBoundingClientRect();

    const parentRect =
      this.elementRef.nativeElement.parentElement.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect();

    const toolbarStyle = this.inlineToolbar.nativeElement.style;
    toolbarStyle.opacity = "1";

    toolbarStyle.left = `${selectionRect.x - parentRect.x}px`;
    toolbarStyle.top = `${selectionRect.y - parentRect.y - 20}px`;
  }

  protected hasTagInRange(tag: string, range: Range) {
    let hasTag = false;
    const tags: HTMLCollection = this.elementRef.nativeElement.getElementsByTagName(tag);

    for (let i = 0; i < tags.length; i++) {
      if (range.intersectsNode(tags[i])) {
        hasTag = true;
        break;
      }
    }
    return hasTag;
  }

  protected getSelectionState(range: Range): SelectionState | undefined {
    const hasBold = this.hasTagInRange("b", range);
    const hasItalic = this.hasTagInRange("i", range);

    return { hasBold, hasItalic, hasFootnote: false };
  }

  onInlineBoldClick(range: Range): void {
    const hasBold = this.hasTagInRange("b", range);

    if (hasBold) {
      this.removeInlineTagInRange("b", range);
    } else {
      this.addInlineTagInRange("b", range);
    }
  }

  protected removeInlineTagInRange(tagName: string, range: Range) {
    this.surroundRangeWithFakeCursor(range);

    const tags: HTMLCollection = this.elementRef.nativeElement.getElementsByTagName(tagName);

    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];

      if (range.intersectsNode(tag)) {
        const isTagStartingInSelection = range.isPointInRange(tag, 0);
        const isTagEndingInSelection = range.isPointInRange(tag, tag.childNodes.length);

        if (isTagStartingInSelection && isTagEndingInSelection) {
          // tag is entire in selection
          // | some <i>text</i> and |
          // just remove the tag
          this.unnestElement(tag);
          i--;
          continue;
        }
        // else we will need to split elements to parts

        // if tags starting sooner than selection and ending later
        // <i>text | more te|xt</i>
        // we cut tag into three parts: before, in selection, after

        const fragmentWithoutTag = document.createDocumentFragment();

        const { before, inSelection, after } = this.splitTagOnRange(tag, tagName, range);
        fragmentWithoutTag.append(before);
        fragmentWithoutTag.append(inSelection);
        fragmentWithoutTag.append(after);

        const tagParent = tag.parentNode;
        tagParent?.replaceChild(fragmentWithoutTag, tag);
      }
    }

    this.restoreRangeToFakeCursor(range);

    this.removeEmptyChilds(this.elementRef.nativeElement);
    this.elementRef.nativeElement.normalize();
    this.saveDataFromHtml();

    this.changeDetectorRef.markForCheck();
    const event = new Event("selectionchange");
    document.dispatchEvent(event);
  }

  private splitTagOnRange(tag: Element, tagName: string, range: Range) {
    const before = document.createElement(tagName);
    const inSelection = document.createDocumentFragment();
    const after = document.createElement(tagName);

    let isBefore = true;
    for (let i = 0; i < tag.childNodes.length; i++) {
      const child = tag.childNodes[i];

      if (range.intersectsNode(child)) {
        if (child.nodeType === Node.TEXT_NODE) {
          // we are in text node, so process text only

          let splitStartIndex = 0;
          let splitEndIndex = child.textContent?.length;
          let text = child.textContent!;

          if (child === range.startContainer) {
            // if selection starts in middle of text container -
            // then split text in parts, first part goes in "before"
            // last part goes in "inSelection"
            text = child.textContent!;

            splitStartIndex = range.startOffset;
            const firstPart = text.slice(0, splitStartIndex);

            before.append(document.createTextNode(firstPart));
          }

          if (child === range.endContainer) {
            // if selection ends in middle of text container -
            // then split text in parts,
            // first part goes in "inSelection"
            // last part goes in "after"

            text = child.textContent!;
            splitEndIndex = range.endOffset;
            const lastPart = text.slice(splitEndIndex, text.length);

            after.append(document.createTextNode(lastPart));
          }

          const middleText = text.slice(splitStartIndex, splitEndIndex);
          inSelection.append(document.createTextNode(middleText));
        } else {
          inSelection.append(child);
          i--; // when we append child - it was removed from old parent, so we need to decrement index
        }

        isBefore = false;
      } else {
        if (isBefore) {
          // we are currently before the selection, add child to before section
          before.append(child);
          i--; // when we append child - it was removed from old parent, so we need to decrement index
        } else {
          // we are after selection
          after.append(child);
          i--; // when we append child - it was removed from old parent, so we need to decrement index
        }
      }
    }

    return { before, inSelection, after };
  }

  private unnestElement(element: Element) {
    const fragment = document.createDocumentFragment();
    for (let i = 0; i < element.childNodes.length; i++) {
      fragment.append(element.childNodes[i]);
      i--;
    }
    const elementParent = element.parentNode;
    elementParent?.replaceChild(fragment, element);
  }

  private removeEmptyChilds(element: Element | Node) {
    const tagsToRemove = ["i", "b"];

    if (tagsToRemove.includes(element.nodeName.toLowerCase())) {
      if (element.textContent?.length === 0) {
        (element as Element).remove();
      }
    }

    for (let i = element.childNodes.length - 1; i >= 0; i--) {
      this.removeEmptyChilds(element.childNodes[i]);
    }
  }

  private restoreRangeToFakeCursor(range: Range) {
    const f1 = this.elementRef.nativeElement.querySelector("span#start.fake-cursor");
    const f2 = this.elementRef.nativeElement.querySelector("span#end.fake-cursor");

    range.setStartBefore(f1);
    range.setEndAfter(f2);

    f1.remove();
    f2.remove();
  }

  private surroundRangeWithFakeCursor(range: Range) {
    const cont = range.extractContents();
    const f1 = FakeCursorNode.cloneNode(true) as HTMLElement;
    f1.id = "start";
    const f2 = FakeCursorNode.cloneNode(true) as HTMLElement;
    f2.id = "end";

    const fragment = document.createDocumentFragment();
    fragment.append(f1);
    fragment.append(cont);
    fragment.append(f2);
    range.insertNode(fragment);
  }

  protected addInlineTagInRange(tagName: string, range: Range) {
    const contents = range.extractContents();

    const newFragment = document.createDocumentFragment();
    const tag = document.createElement(tagName);
    tag.appendChild(contents);
    newFragment.appendChild(tag);

    range.insertNode(newFragment);
    range.commonAncestorContainer.normalize();

    this.saveDataFromHtml();
  }

  protected saveDataFromHtml() {
    this.tableBlockService.saveData(this.data.data, this.editorContainer);
  }

  onInlineItalicClick(range: Range): void {
    const hasItalic = this.hasTagInRange("i", range);

    if (hasItalic) {
      this.removeInlineTagInRange("i", range);
    } else {
      this.addInlineTagInRange("i", range);
    }
  }

  /**
   * Keyup and keydown handles all keyboard events, like system ones, but doesn't prevent keypresses (e.g. Enter)
   */
  onKeyDown(event: any) {
    if (event instanceof KeyboardEvent) {
      if (event.key === "Tab") {
        event.preventDefault();
        return;
      }
    }
  }

  onKeyUp(event: any) {
    if (event instanceof KeyboardEvent) {
      // do nothing (yet?)
    }
  }

  /**
   * Keypress handles actual keys, that will be putted in input container.
   * Here we can prevent events from being processed by browser and hadle this events ourselves.
   */
  onKeyPress(event: any) {}

  onMouseLeave(event: any): void {
    this.tableToolbarVisible = false;
  }

  onMouseDown(event: any) {
    this.tableContextMenuVisible = false;

    if (event.button === 1 || event.buttons === 1) {
      this.clearSelectedCells(event);
      this.addCellToSelected(event, this.selectedCells);
    } else if (event.button === 2) {
      // Clear selected cells and add new selected cell if one cell was selected before
      if (this.selectedCells.length <= 1) {
        this.clearSelectedCells(event);
        this.addCellToSelected(event, this.selectedCells);
      }
    }
  }

  onMouseOver(event: any) {
    this.tableToolbarVisible = true;
    this.getContextMenuStyle();
    if (event.buttons === 1) {
      this.addCellToSelected(event, this.selectedCells);

      // Select region on cursor
      const cellsPosition = this.tableOperationService.getSelectedCellsPositionSchema(
        this.selectedCells,
        this.editorContainer,
      );

      const selectedCellsRegion = this.getStartStopOfRegion(cellsPosition);
      // let selectedCellsRegion = this.getSelectedCellsRegion(cellsPosition);

      if (selectedCellsRegion.start !== undefined && selectedCellsRegion.stop !== undefined) {
        this.selectRegion(selectedCellsRegion.start, selectedCellsRegion.stop);
      }
    }

    this.showToolbar(event);
  }

  onMouseClick(event: any) {
    event.preventDefault();
    this.calculateCountSelectedCells(event);

    if (event.buttons === 0 || event.buttons === 1) {
      this.clearSelectedCells(event);
      this.addCellToSelected(event, this.selectedCells);
    }
  }

  showRowContextMenu(): void {
    this.tableContextMenuVisible = true;
    // let mousePositionY = event.pageY;
    this.tableContextMenuPositionX = 0;
    this.tableContextMenuPositionY = 0;
  }

  displayContextMenu(event: any) {
    event.preventDefault();

    this.calculateCountSelectedCells(event);

    this.tableContextMenuVisible = true;
    const table = event.target?.closest("table") as HTMLTableElement;
    if (table) {
      const cellRect = event.target.getBoundingClientRect();
      const tableRect = table.getBoundingClientRect();
      const contextMenuWidht = 298;
      // let contextMenuHeight = 250;
      const mousePositionX = event.pageX;
      // let mousePositionY = event.pageY;
      this.tableContextMenuPositionX = cellRect.left - tableRect.left + 200;
      // this.tableContextMenuPositionY = cellRect.top + window.scrollY - tableRect.top;
      // this.tableContextMenuPositionY = ; // TODO add proper positioning. Math on this works different in ff and chrome

      const divStylesPreview = document.querySelector("div.styles-preview");
      if (divStylesPreview) {
        if (mousePositionX + contextMenuWidht > divStylesPreview.getBoundingClientRect().right) {
          this.tableContextMenuPositionX = cellRect.left - divStylesPreview.getBoundingClientRect().left - 200;
        }
      }
    }
  }

  getContextMenuStyle() {
    return {
      position: "absolute",
      left: `${this.tableContextMenuPositionX}px`,
      // top: `${this.tableContextMenuPositionY}px`,
      top: "unset",
      "z-index": 1,
    };
  }

  private showToolbar(event: any) {
    if (event.target.matches("td > div") || event.target.matches("td")) {
      this.tableToolbarVisible = true;

      if (this.tableToolbarVisible) {
        const tableParent = event.target?.closest("table")?.parentElement as HTMLTableElement;
        const cell = event.target?.closest("td") as HTMLTableCellElement;
        if (tableParent && cell) {
          this.hoveredCell = cell;

          const tableParentRect = tableParent.getBoundingClientRect();
          const cellRect = cell.getBoundingClientRect();

          this.tableToolbarRowPositionX = 25;
          this.tableToolbarRowPositionY = cellRect.y - tableParentRect.y;

          this.tableToolbarColumnPositionX = cellRect.x - tableParentRect.x + cellRect.width / 2;
          this.tableToolbarColumnPositionY = 25;
        }
      }
    }
  }

  getToolbarRowStyle() {
    return {
      position: "absolute",
      left: "0",
      top: `${this.tableToolbarRowPositionY}px`,
    };
  }

  getToolbarColumnStyle() {
    return {
      position: "absolute",
      left: `${this.tableToolbarColumnPositionX}px`,
      top: "0",
    };
  }

  onAddRow(): void {
    this.tableOperationService.onAddRow(
      this.editorContainer,
      this.selectedCells,
      this.hoveredCell,
      this.tableSelectedCells,
      this.renderer,
    );
  }

  onAddColumn() {
    this.tableOperationService.clearAllCellsSelected(this.editorContainer);
    this.selectedCells = [];
    if (this.hoveredCell) {
      this.selectedCells.push(this.hoveredCell);
      const cellsPosition = this.tableOperationService.getSelectedCellsPositionSchema(
        this.selectedCells,
        this.editorContainer,
      );
      this.tableSelectedCells = this.tableOperationService.getSelectedCellsRegion(cellsPosition);
      this.tableOperationService.addColumn("right", this.editorContainer, this.renderer, this.tableSelectedCells);
    }
  }

  handleContextMenuClick(event: any) {
    switch (event.data.action) {
      case "add_row_above":
        this.tableOperationService.addRow(
          "above",
          this.editorContainer,
          this.renderer,
          this.tableSelectedCells,
          event.data.count,
        );
        break;
      case "add_row_below":
        this.tableOperationService.addRow(
          "below",
          this.editorContainer,
          this.renderer,
          this.tableSelectedCells,
          event.data.count,
        );
        break;
      case "add_column_left":
        this.tableOperationService.addColumn(
          "left",
          this.editorContainer,
          this.renderer,
          this.tableSelectedCells,
          event.data.count,
        );
        break;
      case "add_column_right":
        this.tableOperationService.addColumn(
          "right",
          this.editorContainer,
          this.renderer,
          this.tableSelectedCells,
          event.data.count,
        );
        break;
      case "delete_row":
        this.tableOperationService.deleteRow(
          event.data.count,
          this.editorContainer,
          this.renderer,
          this.tableSelectedCells,
        );
        break;
      case "delete_column":
        this.tableOperationService.deleteColumn(
          event.data.count,
          this.editorContainer,
          this.renderer,
          this.tableSelectedCells,
        );
        break;
      case "union_cells":
        this.tableOperationService.unionCells(
          event,
          this.tableSelectedCells,
          this.editorContainer,
          this.renderer,
          this.tableSelectedRowsCount,
          this.tableSelectedColumnsCount,
          this.selectedCells,
        );
        break;
    }
  }

  private getStartStopOfRegion(cellsPosition: CellPosition[]) {
    let startRow: number | undefined = undefined;
    let startCol: number | undefined = undefined;
    let stopRow: number | undefined = undefined;
    let stopCol: number | undefined = undefined;
    for (const cell of cellsPosition) {
      if (startRow === undefined) {
        startRow = cell.rI;
      } else {
        if (cell.rI !== undefined && cell.rI < startRow) {
          startRow = cell.rI;
        }
      }
      if (startCol === undefined) {
        startCol = cell.cI;
      } else {
        if (cell.cI !== undefined && cell.cI < startCol) {
          startCol = cell.cI;
        }
      }
      if (stopRow === undefined) {
        if (cell.rI !== undefined && cell.rowOffset !== undefined) {
          stopRow = cell.rI + cell.rowOffset;
        }
      } else {
        if (cell.rI !== undefined && cell.rI > stopRow && cell.rowOffset !== undefined) {
          stopRow = cell.rI + cell.rowOffset;
        }
      }
      if (stopCol === undefined) {
        if (cell.cI !== undefined && cell.colOffset !== undefined) {
          stopCol = cell.cI + cell.colOffset;
        }
      } else {
        if (cell.cI !== undefined && cell.cI > stopCol && cell.colOffset !== undefined) {
          stopCol = cell.cI + cell.colOffset;
        }
      }
    }
    return {
      start: { rI: startRow, cI: startCol },
      stop: { rI: stopRow, cI: stopCol },
    } as SelectedCells;
  }

  private addCellToSelected(event: any, selectedCells: HTMLTableCellElement[]) {
    if (event.target.matches("td > div") || event.target.matches("td")) {
      const target = event.target.closest("td");
      if (target) {
        if (!selectedCells.includes(target)) {
          selectedCells.push(target);
          target.classList.add("selected");
        }
      }
    }
  }

  private clearSelectedCells(event: any) {
    if (event.target.matches("td > div") || event.target.matches("td")) {
      for (const cell of this.selectedCells) {
        cell.classList.remove("selected");
      }
      this.selectedCells = [];
    }
  }

  private selectRegion(start: CellPosition, stop: CellPosition) {
    const table = this.editorContainer.nativeElement.querySelector("table") as HTMLTableElement;

    if (start.rI !== undefined && start.cI !== undefined && stop.rI !== undefined && stop.cI !== undefined) {
      const data = this.tableBlockService.htmlToRaw(this.editorContainer.nativeElement);
      const tableSchema = this.tableOperationService.makeTableSchema(data);

      for (let i = start.rI; i <= stop.rI; i++) {
        const row = table.rows[i];
        const schemaRow = tableSchema.rows[i];
        const cells = schemaRow.filter((element: Cell, index: number) => {
          return element.row === i && element.col === index;
        });

        if (cells) {
          for (let j = 0; j < cells.length; j++) {
            const schemaCell = cells[j];
            if (schemaCell.row !== undefined && schemaCell.col !== undefined) {
              if (schemaCell.col >= start.cI && schemaCell.col <= stop.cI) {
                const cell = row.cells[j];
                this.selectedCells.push(cell);
                cell.classList.add("selected");
              }
            }
          }
        }
      }
    }
  }

  private calculateCountSelectedCells(event: any) {
    const cellsPosition = this.tableOperationService.getSelectedCellsPositionSchema(
      this.selectedCells,
      this.editorContainer,
    );
    if (
      event.target.matches("td > div") ||
      event.target.matches("td") ||
      event.target.matches("tr") ||
      event.target.matches("tbody")
    ) {
      this.tableSelectedCells = this.tableOperationService.getSelectedCellsRegion(cellsPosition);
    }
    if (
      this.tableSelectedCells.start?.rI !== undefined &&
      this.tableSelectedCells.start?.rowOffset !== undefined &&
      this.tableSelectedCells.start?.cI !== undefined &&
      this.tableSelectedCells.start?.colOffset !== undefined &&
      this.tableSelectedCells.stop?.rI !== undefined &&
      this.tableSelectedCells.stop?.rowOffset !== undefined &&
      this.tableSelectedCells.stop?.cI !== undefined &&
      this.tableSelectedCells.stop?.colOffset !== undefined
    ) {
      // this.tableSelectedRowsCount = this.tableSelectedCells.stop?.rI + this.tableSelectedCells.stop?.rowOffset - this.tableSelectedCells.start?.rI + 1;
      // this.tableSelectedColumnsCount = this.tableSelectedCells.stop?.cI + this.tableSelectedCells.stop?.colOffset - this.tableSelectedCells.start?.cI + 1;
      this.tableSelectedRowsCount = this.tableSelectedCells.stop?.rI - this.tableSelectedCells.start?.rI + 1;
      this.tableSelectedColumnsCount = this.tableSelectedCells.stop?.cI - this.tableSelectedCells.start?.cI + 1;
    }
  }

  @HostListener("document:click", ["$event"])
  documentClick(event: any): void {
    this.tableContextMenuVisible = false;
  }
}
