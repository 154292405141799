import { CommonModule } from "@angular/common";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { I18nHelper } from "./i18n-helper";

@NgModule({
  declarations: [],
  imports: [CommonModule, BrowserModule],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: (i18n: I18nHelper) => i18n.locale,
      deps: [I18nHelper],
    },
  ],
  exports: [],
})
export class I18nModule {}
