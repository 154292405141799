import { Component, Input } from "@angular/core";
import { slideInOutVertical } from "@metranpage/components";

export type FAQ = {
  title: string;
  text: string;
};

@Component({
  selector: "m-faq",
  templateUrl: "./faq.view.html",
  styleUrls: ["./faq.view.scss"],
  animations: [slideInOutVertical],
})
export class FAQView {
  @Input()
  faq!: FAQ;
  isCollapsed = false;

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }
}
