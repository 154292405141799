import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Book } from "@metranpage/book-data";
import { RouterService } from "@metranpage/core";
import { FormatData, FormatService } from "@metranpage/format-data";
import { ActiveSubscription } from "@metranpage/pricing-data";
import { UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";

export type EditSteps = "init" | "markup" | "running-titles" | "margins" | "preview" | "cover";
export type ModalSteps =
  | "templates"
  | "template-preview"
  | "upload"
  | "book-data"
  | "book-data-before-cover"
  | "cover-data";
export type ModalType = "new" | "new-cover" | "edit";

@Injectable({
  providedIn: "root",
})
export class BookRouterService implements OnDestroy {
  activeSubscription: ActiveSubscription | undefined;

  sub: Subscription = new Subscription();

  constructor(
    userStore: UserStore,
    private readonly formatService: FormatService,
    private readonly routerService: RouterService,
    private readonly router: Router,
  ) {
    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.activeSubscription = activeSubscription;
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  navigateToLastBookEditStep(book: Book) {
    const step: EditSteps | ModalSteps = this.getLastEditStep(book);
    this.navigateToBookEditStep(book, step);
  }

  navigateToBookEditStep(book: Book, step: EditSteps | ModalSteps, modalType: ModalType = "edit") {
    if (step === "init") {
      this.showModal(book, "templates", "new");
      return;
    }

    if (step === "cover") {
      this.router.navigate([`/books/${book.id}/cover`]); // got a feeling that this will change in the future
      return;
    }

    if (this.isModalStep(step)) {
      this.showModal(book, step, modalType);
      return;
    }

    this.router.navigate([`/books/${book.id}/${step}`]);
  }

  showModal(
    book: Book,
    step: ModalSteps,
    modalType: ModalType = "edit",
    formatData: FormatData | undefined = undefined,
    templateId: number | undefined = undefined,
  ) {
    const url = this.mapModalStepToUrl(book, step, modalType, formatData, templateId);
    this.routerService.showInModal(url);
  }

  closeModal() {
    this.routerService.closeModal();
  }

  private getLastEditStep(book: Book) {
    let step: EditSteps | ModalSteps = "init";
    if (book.lastEditStep) {
      switch (book.lastEditStep) {
        case "init":
          step = "init";
          break;
        case "markup":
          step = "markup";
          break;
        case "layout":
          step = "margins";

          if (!this.isSettingsAvailable()) {
            step = "markup";
          }
          break;
        case "preview":
          step = "running-titles";
          if (!this.isSettingsAvailable()) {
            step = "markup";
          }
          break;
      }
    }
    return step;
  }

  private mapModalStepToUrl(
    book: Book,
    step: ModalSteps,
    modalType: ModalType = "edit",
    changedFormatData: FormatData | undefined = undefined,
    templateId: number | undefined = undefined,
  ) {
    let formatData = changedFormatData;
    if (!formatData) {
      formatData = this.formatService.getFormatData(book.bookSettings?.width, book.bookSettings?.height);
    }

    switch (step) {
      case "templates":
        return [modalType, book.id, "book-templates", formatData.width, formatData.height];
      case "template-preview":
        return [modalType, book.id, "book-templates", formatData.width, formatData.height, templateId, "preview"];
      case "upload":
        return [modalType, book.id, "book-upload", formatData.width, formatData.height, book.selectedTemplateId || 0];
      case "book-data":
        return [modalType, book.id, "book-data", formatData.width, formatData.height, book.selectedTemplateId || 0];
      case "book-data-before-cover":
        return [
          modalType,
          book.id,
          "book-data",
          formatData.width,
          formatData.height,
          book.selectedTemplateId || 0,
          "to-cover",
        ];
      case "cover-data":
        return [modalType, book.id, "book-data", formatData.width, formatData.height];
    }
  }

  private isModalStep(arg: string): arg is ModalSteps {
    return (
      arg === "templates" ||
      arg === "template-preview" ||
      arg === "upload" ||
      arg === "book-data" ||
      arg === "book-data-before-cover" ||
      arg === "cover-data"
    );
  }

  private isSettingsAvailable() {
    const hasPaidTariff = this.activeSubscription?.hasPaidTariff ?? false;
    const hasTrialPeriod = this.activeSubscription?.hasTrialPeriod ?? false;
    return hasPaidTariff || hasTrialPeriod;
  }
}
