import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { ListType, StyleKey } from "../../models/styles";

export type ToolbarSettings = {
  isAddTableAvailable: boolean;
};

@Component({
  selector: "m-markup-block-toolbar",
  templateUrl: "./markup-block-toolbar.view.html",
  styleUrls: ["./markup-block-toolbar.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class MarkupBlockToolbar {
  @Input()
  indexBlock = 0;
  @Input()
  blockLength = 0;
  @Input()
  settings?: ToolbarSettings;

  @Output()
  deleteBlock: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  upBlock: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  downBlock: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  addTextBlock: EventEmitter<StyleKey> = new EventEmitter<StyleKey>();
  @Output()
  addImageBlock: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  addListBlock: EventEmitter<ListType> = new EventEmitter<ListType>();

  @Output()
  addPageBreakBlock: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  addTableBlock: EventEmitter<void> = new EventEmitter<void>();

  protected isAddMenuVisible = false;
  protected isAdditionalMenuVisible = false;
  protected isListItemHovered = false;

  protected isHovered = false;
  protected isStylesMenuHovered = false;
  protected isTextItemHovered = false;

  protected isAddTextItemSubmenuVisible = false;
  protected isAddListSubmenuVisible = false;

  protected toolbarSettings: ToolbarSettings = {
    isAddTableAvailable: true,
  };

  constructor(private readonly elementRef: ElementRef) {}

  ngOnInit(): void {
    if (this.settings) {
      this.toolbarSettings = this.settings;
    }
  }

  addText(style: StyleKey): void {
    this.addTextBlock.emit(style);
  }

  addList(style: ListType): void {
    this.addListBlock.emit(style);
  }

  toggleAddMenu() {
    this.isAddMenuVisible = !this.isAddMenuVisible;
    if (this.isAddMenuVisible) {
      this.isAdditionalMenuVisible = false;
      this.isStylesMenuHovered = false;
    }
  }

  toggleAdditionalMenu() {
    this.isAdditionalMenuVisible = !this.isAdditionalMenuVisible;
    if (this.isAdditionalMenuVisible) {
      this.isAddMenuVisible = false;
      this.isStylesMenuHovered = false;
    }
    if (this.isTextItemHovered) {
      this.isStylesMenuHovered = true;
    }
  }

  onStylesMenuMouseEnter() {
    if (this.isTextItemHovered) {
      this.isStylesMenuHovered = true;
    }
  }

  onStylesMenuMouseLeave() {
    if (this.isTextItemHovered) {
      this.isStylesMenuHovered = false;
    }
  }

  @HostListener("document:mousedown", ["$event.target"])
  onClickOutside(target: HTMLElement): void {
    const isClickedInside = this.elementRef.nativeElement.contains(target);
    if (!isClickedInside && !this.isStylesMenuHovered) {
      this.isAddMenuVisible = false;
      this.isAdditionalMenuVisible = false;
      this.isHovered = false;
    }
  }

  @HostListener("document:keydown", ["$event"])
  onEscKeydown(event: KeyboardEvent): void {
    if (event.key === "Escape") {
      this.isAddMenuVisible = false;
      this.isAdditionalMenuVisible = false;
      this.isHovered = false;
    }
  }

  onMenuItemEnter(itemType: string): void {
    this.isAddTextItemSubmenuVisible = itemType === "text";
    this.isAddListSubmenuVisible = itemType === "list";

    if (itemType === "list") {
      this.isListItemHovered = true;
    }
    if (itemType === "text") {
      this.isListItemHovered = false;
    }
  }

  onAddTableClick() {
    if (!this.toolbarSettings.isAddTableAvailable) {
      return;
    }
    this.addTableBlock.emit();
    this.toggleAddMenu();
  }

  // onMenuItemLeave(itemType: string): void {
  //   this.isAddTextItemSubmenuVisible = false;
  //   this.isAddListSubmenuVisible = false;

  //   if (itemType === "list") {
  //     this.isListItemHovered = false;
  //   }
  // }
}
