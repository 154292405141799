import { ChangeDetectionStrategy, Component, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Book, BookCover } from "@metranpage/book-data";
import { OnboardingService } from "@metranpage/onboarding";
import { Observable, Subscription, filter, switchMap, take, tap } from "rxjs";
import { BooksStore } from "../../services/books.store";
import { CoverService } from "../../services/cover/cover.service";

@Component({
  selector: "m-cover-page",
  templateUrl: "./cover.page.html",
  styleUrls: ["./cover.page.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverPage {
  book!: Book;
  cover$!: Observable<BookCover>;

  onboardingStarted = false;

  protected isProcessing = false;
  protected isCoverJustCreated = false;

  sub: Subscription = new Subscription();

  constructor(
    readonly bookStoreService: BooksStore,
    readonly coverService: CoverService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly onboardingService: OnboardingService,
  ) {
    this.cover$ = bookStoreService.getActiveBookObservable().pipe(
      filter((v) => !!v),
      take(1),
      tap((v) => {
        this.book = v!;
      }),
      switchMap((v) => {
        if (v!.coverId) {
          this.isCoverJustCreated = false;
          return coverService.getCoverByBookId(v!.id);
        }
        this.isCoverJustCreated = true;
        return coverService.createDefaultCover(v!.id);
      }),
    );

    this.sub.add(
      this.onboardingService.onStartOnboarding$.pipe().subscribe((showForced) => {
        this.startOnboarding(showForced);
      }),
    );
  }

  protected onBackClick() {
    const qp = this.route.snapshot.queryParams;
    if (qp && "from" in qp) {
      if (qp["from"] === "preview") {
        this.router.navigate(["/books", this.book.id, "preview"]);
        return;
      }
    }
    this.router.navigate(["/books"]);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  startOnboarding(showForced = false) {
    if (!this.onboardingStarted || showForced) {
      this.onboardingStarted = true;
      this.onboardingService.startOnboarding("cover-page", showForced);
    }
  }

  onProcessing(isProcessing: boolean) {
    this.isProcessing = isProcessing;
  }

  canDeactivate(): boolean | Observable<boolean> {
    if (this.isProcessing) {
      return confirm($localize`:@@books.markup.close-without-save-data:`);
    }
    return true;
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHander(event: Event) {
    if (this.isProcessing) {
      event.returnValue = true;
    }
  }
}
