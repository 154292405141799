import { Inject, Injectable } from "@angular/core";
import { ActivationEnd, Router } from "@angular/router";
import {
  Book,
  BookCoverLamination,
  BookDto,
  BookFeatures,
  BookPaper,
  BookPrice,
  BookPrintSettings,
  BookPrintSettingsConstants,
  BookResultUpdate,
  BookSize,
  BookSizeAndType,
  BookUpdate,
  MarginsState,
  MarkupSettingsDTO,
  PrintBookCover,
  PrintChromaticity,
  RunningTitlesState,
  ScriptsSettings,
  Template,
  TemplateDataDto,
  TemplateFullDto,
  TemplateWithPreview,
  TemplateWithPreviewDto,
  TocSettings,
  UpdateBookInfo,
  UpdateBookTypes,
} from "@metranpage/book-data";
import { BookFeaturesCollector } from "@metranpage/book-interfaces";
import {
  AnalyticsService,
  BrowserNotificationService,
  LoadingService,
  NotificationsPopUpService,
  RealtimeService,
} from "@metranpage/core";
import { DOWNLOAD_FILE_TEMPLATE } from "@metranpage/core-api";
import { GeneralResultStatus } from "@metranpage/core-data";
import { I18nService } from "@metranpage/i18n";
import { EditorData } from "@metranpage/markup-editor";
import { ActiveSubscription, Tariff } from "@metranpage/pricing-data";
import { PaymentData } from "@metranpage/user-payment-data";
import * as _ from "lodash-es";
import { omit } from "lodash-es";
import { filter } from "rxjs";
import dataHelper from "../views/cover/helpers/data-helper";
import { BookRouterService } from "./book-router.service";
import { BooksApi } from "./books.api";
import { BooksStore } from "./books.store";
import { CoverService } from "./cover/cover.service";
import { CreateProjectEvent } from "./new-project.service";

export type CreateBookData = {
  title: string;
  author: string;
  year: string;
  format: BookSize;
};

export type CreateBookResult = {
  status: GeneralResultStatus;
  book?: Book;
};

export type UploadEditorImageResult = {
  status: GeneralResultStatus;
  response?: any;
};

export type UploadOriginalFileResponse = {
  success: boolean;
  error?: string;
};

export type UploadOriginalFileResultStatus =
  | GeneralResultStatus
  | "file-limit-error"
  | "tables-in-multi-column-template-error";

export type SetTemplateResultStatus = GeneralResultStatus | "template-not-suitable-for-uploaded-document";

@Injectable({
  providedIn: "root",
})
export class BookService implements BookFeaturesCollector {
  activeBook: Book | undefined;

  constructor(
    private readonly booksApi: BooksApi,
    private readonly booksStore: BooksStore,
    private readonly coverService: CoverService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly realtimeService: RealtimeService,
    private readonly browserNotificationsService: BrowserNotificationService,
    private readonly router: Router,
    private readonly analytics: AnalyticsService,
    private readonly i18nService: I18nService,
    @Inject(DOWNLOAD_FILE_TEMPLATE) private readonly downloadFileTemplate: string,
    private readonly loadingService: LoadingService,
    private readonly bookRouterService: BookRouterService,
  ) {
    this.watchRealtimeUpdates();
    this.watchRouteChanges();
    this.watchActiveBook();
  }

  async updateBooks(): Promise<GeneralResultStatus> {
    try {
      const books = await this.booksApi.getBooks();
      this.booksStore.setBooks(books);
      return "success";
    } catch (error: any) {
      console.error(error);
      return "error";
    }
  }

  async updateBooksPaginated(page: number): Promise<GeneralResultStatus> {
    try {
      const data = await this.booksApi.getBooksPaginated(page);
      this.booksStore.addBooksToEnd(data.items);
      this.booksStore.setBooksPageCount(data.pageCount);
      return "success";
    } catch (error: any) {
      console.error(error);
      return "error";
    }
  }

  async getBook(id: number) {
    return await this.booksApi.getBook(id);
  }

  async refreshBook(
    book: Book,
    opts: { showNotificationOnStatusChange: boolean } = { showNotificationOnStatusChange: false },
  ) {
    const oldBook = await this.booksStore.getBooks()?.find((b) => b.id === book.id);
    const refreshedBook = await this.booksApi.getBook(book.id);

    if (opts?.showNotificationOnStatusChange) {
      if (oldBook?.actionKey !== refreshedBook.actionKey) {
        this.browserNotificationsService.sendNotification(
          $localize`:@@app-name:`,
          `Book ${refreshedBook.title} has been updated`,
          "book-done",
        );
      }
    }

    this.booksStore.updateBook(refreshedBook);
  }

  async createBook(): Promise<CreateBookResult> {
    try {
      const newBook = await this.booksApi.create();
      this.booksStore.addBook(newBook);
      return {
        status: "success",
        book: newBook,
      };
    } catch (error: any) {
      return {
        status: "error",
        book: undefined,
      };
    }
  }

  async setBookSize(book: Book, size: BookSize): Promise<GeneralResultStatus> {
    try {
      const updated = await this.booksApi.setBookSize(book.id, size);
      this.booksStore.updateBook(updated);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async setBookType(book: Book, types: UpdateBookTypes): Promise<GeneralResultStatus> {
    try {
      const updated = await this.booksApi.updateBookTypes(book.id, types);
      this.booksStore.updateBook(updated);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async setBookSizeAndType(book: Book, data: BookSizeAndType): Promise<GeneralResultStatus> {
    try {
      const size = { width: data.width, height: data.height };
      const types = { exportPrint: data.exportPrint, exportEpub: data.exportEpub };

      await this.booksApi.setBookSize(book.id, size);
      const updated = await this.booksApi.updateBookTypes(book.id, types);
      this.booksStore.updateBook(updated);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async setBookTemplate(book: Book, templateId: number): Promise<GeneralResultStatus> {
    try {
      const updated = await this.booksApi.setBookTemplate(book.id, templateId);
      this.booksStore.updateBook(updated);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async setBookSizeAndTemplate(book: Book, size: BookSize, templateId: number): Promise<SetTemplateResultStatus> {
    try {
      await this.booksApi.setBookSize(book.id, size);
      const updated = await this.booksApi.setBookTemplate(book.id, templateId);
      this.booksStore.updateBook(updated);
      return "success";
    } catch (error: any) {
      if (error.error.error.code === 43) {
        return "template-not-suitable-for-uploaded-document";
      }
      return "error";
    }
  }

  async setDefaultTemplate(book: Book): Promise<GeneralResultStatus> {
    try {
      const updated = await this.booksApi.setBookDefaultTemplate(book.id);
      this.booksStore.updateBook(updated);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async updateBookInfo(book: Book, bookInfo: UpdateBookInfo): Promise<GeneralResultStatus> {
    try {
      await this.booksApi.updateBookInfo(book.id, bookInfo);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async updateBook(book: Book, bookData: BookDto): Promise<GeneralResultStatus> {
    try {
      const updated = await this.booksApi.updateBook(book.id, bookData);
      this.booksStore.updateBook(updated);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async copyBook(book: Book): Promise<GeneralResultStatus> {
    try {
      const bookCopy = await this.booksApi.copyBook(book.id);
      await this.updateBooks();
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async deleteBook(book: Book): Promise<GeneralResultStatus> {
    try {
      await this.booksApi.deleteBook(book.id);
      this.booksStore.deleteBook(book);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async uploadOriginalFile(book: Book, file: File): Promise<UploadOriginalFileResultStatus> {
    try {
      const response = await this.booksApi.uploadOriginalFile(book.id, file);
      if (!response.success && response.error === "file-limit-error") {
        return "file-limit-error";
      }
      if (!response.success && response.error === "tables-in-multi-column-template-error") {
        return "tables-in-multi-column-template-error";
      }

      await this.refreshBook(book);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async uploadSampleFile(book: Book): Promise<GeneralResultStatus> {
    try {
      const response = await this.booksApi.uploadSampleFile(book.id);
      await this.refreshBook(book);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async uploadEmptyFile(book: Book): Promise<GeneralResultStatus> {
    try {
      const response = await this.booksApi.uploadEmptyFile(book.id);
      await this.refreshBook(book);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async getEditorData(book: Book): Promise<EditorData | undefined> {
    try {
      const response = await this.booksApi.getEditorData(book.id);
      return response as EditorData;
    } catch (error: any) {
      console.error(error);
      return undefined;
    }
  }

  async setEditorData(book: Book, data: EditorData, generateDocx: boolean): Promise<GeneralResultStatus> {
    try {
      const response = await this.booksApi.setEditorData(book.id, data, generateDocx);
      return "success";
    } catch (error: any) {
      console.error(error);
      return "error";
    }
  }

  async uploadEditorImage(file: File, imageIndex: number): Promise<UploadEditorImageResult> {
    try {
      const response = await this.booksApi.uploadEditorImage(this.activeBook!.id, file, imageIndex);
      return { status: "success", response };
    } catch (error: any) {
      console.error(error);

      return { status: "error" };
    }
  }

  getUrlForEditorImage(imageUrl: string) {
    return this.booksApi.getUrlForEditorImage(this.activeBook!.id, imageUrl);
  }

  getUrlForTemplatePreview(templateId: number, imageUrl: string) {
    return this.booksApi.getUrlForTemplatePreview(templateId, imageUrl);
  }

  async getMarkupSettings(bookId: number): Promise<MarkupSettingsDTO> {
    const markupSettings = await this.booksApi.getBookStyles(bookId);

    for (const sk in markupSettings.styles) {
      const locale = this.i18nService.getLocale();
      const localeData = markupSettings.stylesLocalization.find((l) => l.lang === locale && l.styleKey === sk);

      markupSettings.styles[sk].visibleTitle = localeData?.value || sk;
    }

    return markupSettings;
  }

  async setMarkupSettings(book: Book, dto: MarkupSettingsDTO): Promise<GeneralResultStatus> {
    try {
      for (const styleOpts of Object.values(dto.styles)) {
        // styleOpts.font = undefined;
        styleOpts.color = undefined;
      }
      const updated = await this.booksApi.setBookStyles(book.id, dto);
      this.booksStore.updateBook(updated);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  // async producePreview(book: Book, bookSettings: BookDto): Promise<GeneralResultStatus> {
  async producePreview(book: Book): Promise<GeneralResultStatus> {
    try {
      const response = await this.booksApi.createPreview(book.id, book.bookSettings!);
      await this.refreshBook(book);
      return "success";
    } catch (error: any) {
      console.error(error);

      return "error";
    }
  }

  async produceFinal(book: Book, isGoldCreditUsed: boolean): Promise<GeneralResultStatus> {
    try {
      const response = await this.booksApi.createFinal(book.id, isGoldCreditUsed);
      await this.refreshBook(book);
      return "success";
    } catch (error: any) {
      console.error(error);

      return "error";
    }
  }

  async checkCredits(book: Book): Promise<PaymentData | "error"> {
    try {
      const response = await this.booksApi.hasEnoughCreditsForBook(book.id);
      return response;
    } catch (error: any) {
      console.error(error);

      return "error";
    }
  }

  async getBookPrice(book: Book): Promise<BookPrice | "error"> {
    try {
      const response = await this.booksApi.getBookPrice(book.id);
      return response;
    } catch (error: any) {
      // console.error(error);
      return "error";
    }
  }

  async downloadEpub(book: Book, filename: string): Promise<GeneralResultStatus> {
    try {
      const downloadFilename = this.formatFileName(book.id, filename.replace("-fixed", ""), "");
      const response = await this.booksApi.downloadEpub(book, filename, downloadFilename);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async downloadFinal(book: Book, filename: string, suffix: string): Promise<GeneralResultStatus> {
    try {
      const downloadFilename = this.formatFileName(book.id, filename.replace("-fixed", ""), suffix);
      const response = await this.booksApi.downloadFinal(book, filename, downloadFilename);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  private formatFileName(bookId: number, filename: string, suffix: string): string {
    let newFilename = this.downloadFileTemplate;

    const ext = filename.split(".").pop();
    const basename = filename.split(".").slice(0, -1).join(".");

    newFilename = newFilename.replace("{id}", `${bookId}`);
    newFilename = newFilename.replace("{filename}", `${basename}`);

    newFilename = newFilename.replace("CMYK_forPrint", "cmyk");

    if (suffix) {
      newFilename += `_${suffix}`;
    }

    return `${newFilename}.${ext}`;
  }

  async downloadCover(book: Book) {
    const blob = await this.coverService.getCoverFullsizeImageBlob(book.id);
    dataHelper.saveAsFile(blob, `book-${book.id}-${book.title}-cover.png`, "image/png");
  }

  async trackActiveBook(book: Book): Promise<any> {
    await this.booksApi.trackActive(book.id);
  }

  async getBookFeaturesForFile(file: File): Promise<BookFeatures | undefined> {
    try {
      const response = await this.booksApi.getBookFeaturesForFile(file);
      return response as BookFeatures;
    } catch (error: any) {
      return undefined;
    }
  }

  updateTemplateLocalization(data: TemplateDataDto | TemplateFullDto | TemplateWithPreviewDto) {
    const locale = this.i18nService.getLocale();
    let localeData = data?.localization?.find((l) => l.lang === locale);
    if (!localeData) {
      localeData =
        data?.localization?.length > 0
          ? data.localization[0]
          : {
              id: 0,
              title: "",
              descriptionShort: "",
              descriptionLong: "",
              lang: "",
              templateId: -1,
            };
    }

    const templateData = _.omit(data, ["localization"]);
    const templateLocalizedData = _.omit(localeData, ["id", "lang", "templateId"]);
    const template: Template = {
      ...templateData,
      ...templateLocalizedData,
    };
    return template;
  }

  async getTemplates() {
    const templatesData = await this.booksApi.getTemplates();
    const templates = templatesData.templates.map((t) =>
      this.updateTemplateWithPreviewLocalization(t),
    ) as TemplateWithPreview[];
    return templates;
  }

  updateTemplateWithPreviewLocalization(data: TemplateWithPreviewDto) {
    const locale = this.i18nService.getLocale();
    let localeData = data?.localization?.find((l) => l.lang === locale);
    if (!localeData) {
      localeData =
        data?.localization?.length > 0
          ? data.localization[0]
          : {
              id: 0,
              title: "",
              descriptionShort: "",
              descriptionLong: "",
              lang: "",
              templateId: -1,
            };
    }

    const previews: string[] = [];
    let localePreviews = data.previews.filter((p) => p.lang === locale);

    if ((!localePreviews || localePreviews.length === 0) && data.previews.length > 0) {
      const lang = data.previews[0].lang;
      localePreviews = data.previews.filter((p) => p.lang === lang);
    }

    if (localePreviews) {
      for (const preview of localePreviews) {
        previews.push(preview.url);
      }
    }

    const templateData = _.omit(data, ["localization"]);
    const templateLocalizedData = _.omit(localeData, ["id", "lang", "templateId"]);
    const template: TemplateWithPreview = {
      ...templateData,
      ...templateLocalizedData,
      previews: previews,
    };

    return template;
  }

  private watchRealtimeUpdates() {
    this.realtimeService
      .getEvents<BookUpdate>("book-state")
      .pipe(filter((state) => state !== undefined))
      .subscribe((stateUpdate) => {
        this.applyStateUpdate(stateUpdate);
      });

    this.realtimeService
      .getEvents<BookResultUpdate>("book-result-state")
      .pipe(filter((state) => state !== undefined))
      .subscribe((stateUpdate) => {
        this.booksStore.updateLayoutStep(stateUpdate);
      });
  }

  private applyStateUpdate(stateUpdate: BookUpdate) {
    let isBrowserNotificationAboutLayoutCompletedShowed = false;
    if (stateUpdate.errorReason) {
      if (stateUpdate.errorReason === "preview") {
        this.analytics.event("book-preview-error");

        this.notificationService.error($localize`:@@books.error.preview-create-error:`);

        this.browserNotificationsService.sendNotification(
          $localize`:@@app-name:`,
          `preview error ${stateUpdate.bookId}`,
          "book-done",
        );
        isBrowserNotificationAboutLayoutCompletedShowed = true;
      }
      if (stateUpdate.errorReason === "final") {
        this.analytics.event("book-final-error");

        this.notificationService.error($localize`:@@books.error.final-create-error:`);

        this.browserNotificationsService.sendNotification(
          $localize`:@@app-name:`,
          `final error ${stateUpdate.bookId}`,
          "book-done",
        );
        isBrowserNotificationAboutLayoutCompletedShowed = true;
      }
    }

    if (stateUpdate.needUpdate) {
      this.refreshBook({ id: stateUpdate.bookId } as Book, {
        showNotificationOnStatusChange: !isBrowserNotificationAboutLayoutCompletedShowed,
      });
    } else {
      this.booksStore.updateBookState(stateUpdate);
    }
  }

  private watchRouteChanges() {
    this.router.events.subscribe((data) => {
      // TODO maybe need to use && data.snapshot.url[0]?.path === "books"
      if (data instanceof ActivationEnd) {
        if ("bookId" in data.snapshot.params) {
          const bookId = Number.parseInt(data.snapshot.params["bookId"]);
          this.setActiveBookOrRedirectHome(bookId);
        }
        if ("modalBookId" in data.snapshot.params) {
          const bookId = Number.parseInt(data.snapshot.params["modalBookId"]);
          this.setModalBookInStore(bookId);
        }
      }
    });
  }

  private watchActiveBook() {
    this.booksStore.getActiveBookObservable().subscribe((book) => {
      this.activeBook = book;
    });
  }

  private async setActiveBookOrRedirectHome(bookId: number) {
    if (this.booksStore.hasBook(bookId)) {
      this.booksStore.setActiveBook(bookId);
      return;
    }

    // if doesn't have book - update books list and then check again
    await this.updateBooks();

    if (this.booksStore.hasBook(bookId)) {
      this.booksStore.setActiveBook(bookId);
      return;
    }

    // if still doesnt have book - then book may be deleted or user dont have permissions
    this.router.navigateByUrl("/");
    this.notificationService.error($localize`:@@books.error.cant-load-book:`);
  }

  private async setModalBookInStore(bookId: number) {
    if (this.booksStore.hasBook(bookId)) {
      this.booksStore.setModalBook(bookId);
      return;
    }

    // if doesn't have book - update books list and then check again
    await this.updateBooks();

    if (this.booksStore.hasBook(bookId)) {
      this.booksStore.setModalBook(bookId);
      return;
    }

    // if still doesnt have book - then book may be deleted or user dont have permissions
    // this.router.navigateByUrl("/");
    this.notificationService.error($localize`:@@books.error.cant-load-book:`);
  }

  async refreshScriptsParams() {
    const params = await this.booksApi.getScriptsParams();
    this.booksStore.updateScriptsSettings(new ScriptsSettings(params));
  }

  // private getNewBookSettings(info: CreateBookData): BookSettings {
  //   const scriptsSettings = this.booksStore.getScriptsSettings()!;
  //   const defaultGenre = scriptsSettings.getDefaultGenre();
  //   const defaultMood = scriptsSettings.getDefaultMood();

  //   // const masterPages = this.scriptsSettings.value?.selectMasterPages(info.genre, info.mood, info.format);
  //   const masterPages: MasterPagesParams = {
  //     id: 0,
  //     format: '<A4',
  //     genType: 2,
  //     mainFont: 'Pt Serif',
  //     mainSize: 10,
  //     maketType: 5,
  //   };

  //   if (!masterPages) {
  //     throw new Error($localize`:@@books.build.recommended-settings-not-found-master-pages-error:`);
  //   }

  //   const makeStyles = scriptsSettings.selectMakeStyles(
  //     defaultGenre,
  //     defaultMood,
  //     masterPages.mainSize,
  //     undefined,
  //     undefined
  //   );

  //   return {
  //     author: info.author,
  //     year: info.year,
  //     format: info.format,
  //     genre: defaultGenre,
  //     mood: defaultMood,
  //     masterPages: masterPages,
  //     makeStyles: makeStyles,
  //     swatch: defaultBookSettings.swatch,
  //   };
  // }

  async updateMarginsState(book: Book, marginsState: MarginsState): Promise<GeneralResultStatus> {
    try {
      let stateForSend = omit(marginsState, ["margins", "spaceLevel", "lastChangeSource"]);
      stateForSend = { ...stateForSend, ...marginsState.margins };
      const updated = await this.booksApi.updateMarginsState(book.id, stateForSend);
      this.booksStore.updateBook(updated);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async updateRunningTitlesState(book: Book, runningTitlesState: RunningTitlesState): Promise<GeneralResultStatus> {
    try {
      const stateForSend = omit(runningTitlesState, ["fontSizeInPixeles"]);
      const updated = await this.booksApi.updateRunningTitlesState(book.id, stateForSend);
      this.booksStore.updateBook(updated);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async updateTocSettings(book: Book, tocSettings: TocSettings): Promise<GeneralResultStatus> {
    try {
      const updated = await this.booksApi.updateTocSettings(book.id, tocSettings);
      this.booksStore.updateBook(updated);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async updateCenterImagesSettings(book: Book, centerImages: boolean): Promise<GeneralResultStatus> {
    try {
      const updated = await this.booksApi.updateCenterImagesSettings(book.id, centerImages);
      this.booksStore.updateBook(updated);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  pluralizeAvailable(number: number) {
    return this.i18nService.pluralize($localize`:@@books.info-modal.projects-count-limit.pluralize-available:`, {
      value: number,
    });
  }

  pluralizeProjectsCount(number: number) {
    return `${number} ${this.i18nService.pluralize(
      $localize`:@@books.info-modal.projects-count-limit.pluralize-projects-count:`,
      {
        value: number,
      },
    )}`;
  }

  pluralizeAvailableProjectsCount(number: number) {
    return `${number} ${this.i18nService.pluralize(
      $localize`:@@books.info-modal.projects-count-limit.pluralize-available-projects-count:`,
      {
        value: number,
      },
    )}`;
  }

  getInfoModalTitle(higherTariff: Tariff | undefined) {
    const tariffTitle = higherTariff?.title ?? "";
    return $localize`:@@books.info-modal.projects-count-limit.title:` + tariffTitle;
  }

  getInfoModalText(activeSubscription: ActiveSubscription | undefined, higherTariff: Tariff | undefined) {
    const projectCount = activeSubscription?.tariff.activeProjects || 1;
    const tariffTitle = activeSubscription?.tariff.title;

    let availableProjectsCount = $localize`:@@books.info-modal.projects-count-limit.unlimited-projects-count:`;
    if (higherTariff && higherTariff.activeProjects < 1000) {
      availableProjectsCount = this.pluralizeAvailableProjectsCount(higherTariff.activeProjects);
    }

    return `${$localize`:@@books.info-modal.projects-count-limit.text:`} ${tariffTitle} ${$localize`:@@books.info-modal.projects-count-limit.text-2:`} ${this.pluralizeAvailable(
      projectCount,
    )} ${this.pluralizeProjectsCount(
      projectCount,
    )} ${$localize`:@@books.info-modal.projects-count-limit.text-3:`} ${availableProjectsCount} ${$localize`:@@books.info-modal.projects-count-limit.text-4:`}`;
  }

  /////////////////////////////
  // PRINT SETTINGS
  /////////////////////////////

  getPrintSettings(bookId: number): Promise<BookPrintSettings | undefined> {
    return this.booksApi.getPrintSettings(bookId);
  }

  mergePrintSettings(bookId: number, data: Partial<BookPrintSettings>) {
    return this.booksApi.mergePrintSettings(bookId, data);
  }

  createPrintCalculationRequest(bookId: number, data: Partial<BookPrintSettings>): Promise<void> {
    return this.booksApi.createPrintCalculationRequest(bookId, data);
  }

  calculatePrintEstimatedPrice(bookId: number, data: Partial<BookPrintSettings>): Promise<number> {
    return this.booksApi.calculatePrintEstimatedPrice(bookId, data);
  }

  async getPrintSettingsConstants(): Promise<BookPrintSettingsConstants> {
    const locale = this.i18nService.getLocale();
    const dto = await this.booksApi.getPrintSettingsConstants();
    const result = <BookPrintSettingsConstants>{
      paperTypes: dto.paperTypes.map(
        (v) => <BookPaper>{ type: v.type, name: v.translations.find((t) => t.lang === locale)?.value ?? "N/A" },
      ),
      coverTypes: dto.coverTypes.map(
        (v) => <PrintBookCover>{ type: v.type, name: v.translations.find((t) => t.lang === locale)?.value ?? "N/A" },
      ),
      coverLaminationTypes: dto.coverLaminationTypes.map(
        (v) =>
          <BookCoverLamination>{ type: v.type, name: v.translations.find((t) => t.lang === locale)?.value ?? "N/A" },
      ),
      printChromaticityTypes: dto.printChromaticityTypes.map(
        (v) => <PrintChromaticity>{ type: v.type, name: v.translations.find((t) => t.lang === locale)?.value ?? "N/A" },
      ),
    };

    return result;
  }

  async getEditionNotice() {
    const locale = this.i18nService.getLocale();
    const settings = await this.booksApi.getEditionNoticeSettings();
    const settingsLocale = settings.find((s) => s.lang === locale);

    return settingsLocale;
  }

  async onAddNewProjectClick(event: CreateProjectEvent) {
    this.loadingService.startLoading({ fullPage: true });
    const createBookResult = await this.createBook();
    this.loadingService.stopLoading();

    if (createBookResult.status === "success") {
      if (event.projectType === "cover") {
        await this.goToNewBookCover(createBookResult.book!);
      } else if (event.projectType === "book") {
        this.bookRouterService.navigateToBookEditStep(createBookResult.book!, "templates", "new");
      }
    } else {
      this.notificationService.error($localize`:@@books.error.cant-create-book:`);
    }
  }

  private async goToNewBookCover(book: Book) {
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.setBookSizeAndType(book!, {
      width: book.bookSettings!.width,
      height: book.bookSettings!.height,
      exportPrint: true,
      exportEpub: false,
    });
    this.loadingService.stopLoading();

    if (result === "success") {
      this.bookRouterService.showModal(book, "cover-data", "new-cover");
    } else {
      this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
    }
  }
}
