import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule, provideExperimentalZonelessChangeDetection } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { environment } from "@environments/environment";
import { AdminModule, SuperadminModule } from "@metranpage/admin";
import { AuthInterceptor, AuthModule, UnauthInterceptor } from "@metranpage/auth";
import { BookModule, BookService, DefaultImageLoadingProvider } from "@metranpage/book";
import {
  BreadcrumbsService,
  CAN_CREATE_PUBLIC_TEMPLATES,
  CoreModule,
  IS_COMMUNITY_AVAILABLE,
  IS_COVER_EDITOR_ENABLED,
  IS_EDITION_NOTICE_AVAILABLE,
  IS_IMAGES_GENERATION_AVAILABLE,
  IS_PURCHASES_AVAILABLE,
  SocketioConfig,
  SocketioModule,
} from "@metranpage/core";
import { BASE_URL, COMPANY_UUID, DOWNLOAD_FILE_TEMPLATE } from "@metranpage/core-api";
import { ConfigService } from "@metranpage/core-interfaces";
import { AVAILABLE_LANGUAGES, I18nHelper, I18nModule, I18nService } from "@metranpage/i18n";
import { ImageGenerationModule } from "@metranpage/image-generation";
import { MarkupEditorModule } from "@metranpage/markup-editor";
import { PricingModule } from "@metranpage/pricing";
import { TextGenerationModule } from "@metranpage/text-generation";
import { UserModule } from "@metranpage/user";
import { AppRoutingModule } from "./app-routing.module";
import { AppPage } from "./app.page";
import { ConfigServiceImpl } from "./config.service";
import { provideMaintenance } from "./maintenance/provide-maintenance";
import { KV_URL } from "./maintenance/token";
import { AppBarMenuView } from "./views/app-bar-menu/app-bar-menu.view";
import { AppBarView } from "./views/app-bar/app-bar.view";
import { MobileAlertView } from "./views/mobile-alert/mobile-alert.view";
import { PromocodeModalView } from "./views/promocode-modal/promocode-modal.view";
import { WelcomeModalView } from "./views/welcome-modal/welcome-modal.view";
import { WelcomeWebinarModalView } from "./views/welcome-modal-webinar/welcome-webinar-modal.view";

const socketConfig: SocketioConfig = {
  url: environment.socketRootUrl,
  options: {
    autoConnect: false,
    transports: ["websocket"],
    reconnection: true,
    reconnectionAttempts: Number.POSITIVE_INFINITY,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
  },
};

@NgModule({
  declarations: [AppPage, MobileAlertView, AppBarView, AppBarMenuView, WelcomeModalView,WelcomeWebinarModalView, PromocodeModalView],
  imports: [
    SocketioModule.forRoot(socketConfig),
    FormsModule,
    AppRoutingModule,
    CoreModule,
    BookModule,
    UserModule,
    AuthModule,
    PricingModule,
    AdminModule,
    SuperadminModule,
    ImageGenerationModule,
    TextGenerationModule,
    MarkupEditorModule.forRoot({ url: environment.apiRootUrl, imageLoadingProviderClass: DefaultImageLoadingProvider }),
    I18nModule,
  ],
  providers: [
    provideExperimentalZonelessChangeDetection(),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (_bc: BreadcrumbsService, _bs: BookService) => () => {},
      deps: [BreadcrumbsService, BookService],
      multi: true,
    },
    {
      provide: BASE_URL,
      useFactory: (config: ConfigService) => {
        return config.getApiUrl();
      },
      deps: [ConfigServiceImpl],
    },
    {
      provide: KV_URL,
      useFactory: (config: ConfigService) => {
        return config.getConfig().environment.kvUrl;
      },
      deps: [ConfigServiceImpl],
    },
    {
      provide: COMPANY_UUID,
      useFactory: (config: ConfigService) => {
        return config.getConfig().company.companyUuid;
      },
      deps: [ConfigServiceImpl],
    },
    {
      provide: IS_PURCHASES_AVAILABLE,
      useFactory: (config: ConfigService) => {
        return config.getConfig().company.flags.isPurchasesAvailable;
      },
      deps: [ConfigServiceImpl],
    },
    {
      provide: DOWNLOAD_FILE_TEMPLATE,
      useFactory: (config: ConfigService) => {
        return config.getConfig().company.downloadFileTemplate;
      },
      deps: [ConfigServiceImpl],
    },
    {
      provide: IS_EDITION_NOTICE_AVAILABLE,
      useFactory: (config: ConfigService) => {
        return config.getConfig().company.flags.isEditionNoticeAvailable;
      },
      deps: [ConfigServiceImpl],
    },
    {
      provide: IS_IMAGES_GENERATION_AVAILABLE,
      useFactory: (config: ConfigService) => {
        return config.getConfig().company.flags.isImagesGenerationAvailable;
      },
      deps: [ConfigServiceImpl],
    },
    {
      provide: IS_COVER_EDITOR_ENABLED,
      useFactory: (config: ConfigService) => {
        return config.getConfig().company.flags.isCoverEditorEnabled;
      },
      deps: [ConfigServiceImpl],
    },
    {
      provide: IS_COMMUNITY_AVAILABLE,
      useFactory: (config: ConfigService) => {
        return config.getConfig().company.flags.isCommunityAvailable;
      },
      deps: [ConfigServiceImpl],
    },
    {
      provide: AVAILABLE_LANGUAGES,
      useFactory: (config: ConfigService) => {
        return config.getConfig().company.availableLanguages;
      },
      deps: [ConfigServiceImpl],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (i18nService: I18nService, i18n: I18nHelper) => async () => {
        const locale = i18nService.getLocale();

        // Use web pack magic string to only include required locale data
        const localeModule = await import(
          /* webpackInclude: /(de|en|es|ru)\.mjs$/ */
          `../../../../node_modules/@angular/common/locales/${locale}.mjs`
        );
        const localeTranslationsModule = await import(`../assets/i18n/${locale}.json`);
        i18n.setLocale(localeModule, localeTranslationsModule);
      },
      deps: [I18nService, I18nHelper],
      multi: true,
    },
    provideMaintenance(),
    {
      provide: "ConfigService",
      useClass: ConfigServiceImpl,
    },
    {
      provide: CAN_CREATE_PUBLIC_TEMPLATES,
      useFactory: (config: ConfigService) => {
        return config.getConfig().company.flags.canCreatePublicTemplates;
      },
      deps: [ConfigServiceImpl],
    },
  ],
  bootstrap: [AppPage],
})
export class AppModule {}
