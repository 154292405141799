import { Injectable } from "@angular/core";
import { BookCoverTemplate } from "@metranpage/book-data";
import { ApiErrorHandlerService } from "@metranpage/core";
import { AdminCoverTemplatesApi } from "./cover-templates.api";
import { AdminCoverTemplatesStore } from "./cover-templates.store";

@Injectable({
  providedIn: "root",
})
export class AdminCoverTemplatesService {
  constructor(
    private readonly apiErrorHandler: ApiErrorHandlerService,
    private adminCoverTemplatesStore: AdminCoverTemplatesStore,
    private adminCoverTemplatesApi: AdminCoverTemplatesApi,
  ) {}

  async getTemplates(): Promise<BookCoverTemplate[]> {
    return this.adminCoverTemplatesApi.getTemplates();
  }

  getTemplatePreviewImageUrl(templateId: number): string {
    return this.adminCoverTemplatesApi.getTemplatePreviewImageUrl(templateId);
  }

  async setAsDefault(templateId: number | null): Promise<void> {
    return this.adminCoverTemplatesApi.setAsDefault(templateId);
  }
}
