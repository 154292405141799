import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { slideInOutVertical } from "@metranpage/components";

@Component({
  selector: "m-sidebar-section-2",
  templateUrl: "./sidebar-section.view.html",
  styleUrls: ["./sidebar-section.view.scss"],
  animations: [slideInOutVertical],
})
export class SidebarSection2 {
  @Input()
  title!: string;
  @Input()
  actionIcon?: string;
  @Input()
  actionTitle?: string;
  @Input()
  canCollapsing = false;
  @Input()
  isCollapsed = true;
  @Input()
  hasTooltip = false;
  @Input()
  hasCustomAction = false;
  @Input()
  @HostBinding("style.gap")
  gapSize = "8px";

  @Output()
  onActionClick = new EventEmitter();

  toggle() {
    if (!this.canCollapsing) {
      return;
    }
    this.isCollapsed = !this.isCollapsed;
  }
}
