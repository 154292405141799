<m-ng-island [paddingSize]="24">
  <div class="container">
    @for(network of covers$ | async; track network.title) {
    <div class="column border">
      <div class="row full space-between">
        <div>{{network.title}}</div>
        <m-switch
          [isChecked]="network.isEnabled"
          class="elements-group"
          (onToggle)="updateSNCoversState(network.id, !network.isEnabled)"
        ></m-switch>
      </div>
      <div class="row full">
        <div class="items">
          @for(size of network.items; track size.title) {
          <div class="column center border">
            <div class="title">{{size.title | titleLocale}}</div>
            <div>{{size.width}}px x {{size.height}}px</div>
          </div>
          }
        </div>
      </div>
    </div>
    }
  </div>

  <div class="button-block">
    <m-button
      button-style="primary"
      (onClick)="updateSNCoverStates()"
      i18n="@@dialog.save-button"
      [is-disabled]="!updatedStates.length"
    >
      dialog.save-button
    </m-button>
  </div>
</m-ng-island>
