import { Inject, Injectable } from "@angular/core";
import { ActivationEnd, Router } from "@angular/router";
import { BookService } from "@metranpage/book";
import { Template, TemplateFull, TemplateFullDto, TemplatePreviewLoc } from "@metranpage/book-data";
import { GeneralResultStatus } from "@metranpage/core-data";
import { ConfigService } from "@metranpage/core-interfaces";
import * as _ from "lodash-es";
import { TemplateCreateData } from "../../models/template";
import { AdminTemplatesApi, TemplatesFilter } from "./templates.api";
import { AdminTemplatesStore } from "./templates.store";

@Injectable({
  providedIn: "root",
})
export class AdminTemplatesService {
  constructor(
    private readonly adminTemplatesApi: AdminTemplatesApi,
    private readonly adminTemplatesStore: AdminTemplatesStore,
    @Inject("ConfigService")
    private readonly configService: ConfigService,
    private readonly router: Router,
    private readonly bookService: BookService,
  ) {
    this.watchRouteChanges();
  }

  async loadTemplatesPaginated(page: number, filters: TemplatesFilter = {}) {
    const templatesData = await this.adminTemplatesApi.getTemplatesPaginated(page, filters);
    const templates = templatesData.items.map((t) => this.bookService.updateTemplateLocalization(t));
    this.adminTemplatesStore.setTemplates(templates);
    this.adminTemplatesStore.setTemplatesPageCount(templatesData.pageCount);
  }

  async loadTemplate(templateId: number) {
    const templatesData = await this.adminTemplatesApi.getTemplate(templateId);
    const template = this.bookService.updateTemplateLocalization(templatesData.template) as TemplateFull;
    this.adminTemplatesStore.setActiveTemplate(template);
  }

  async createTemplate(data: TemplateCreateData): Promise<{ status: GeneralResultStatus; id?: number }> {
    try {
      const newTemplate = await this.adminTemplatesApi.createTemplate(data);
      return {
        status: "success",
        id: newTemplate.id,
      };
    } catch (error: any) {
      return {
        status: "error",
        id: undefined,
      };
    }
  }

  async updateTemplate(data: TemplateFullDto): Promise<GeneralResultStatus> {
    try {
      await this.adminTemplatesApi.updateTemplate(data);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async copyTemplate(templateId: number): Promise<{ status: GeneralResultStatus; id?: number }> {
    try {
      const result = await this.adminTemplatesApi.copyTemplate(templateId);
      return {
        status: "success",
        id: result.id,
      };
    } catch (error: any) {
      return {
        status: "error",
        id: undefined,
      };
    }
  }

  async deleteTemplate(templateId: number): Promise<GeneralResultStatus> {
    try {
      await this.adminTemplatesApi.deleteTemplate(templateId);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async getTemplatePreviews(templateId: number) {
    const previewsData = await this.adminTemplatesApi.getTemplatePreviews(templateId);

    return previewsData.map((p) => {
      return {
        id: p.id,
        lang: p.lang,
        url: `${this.configService.getConfig().environment.apiRootUrl}/templates/${templateId}/previews/${p.url}`,
      };
    });
  }

  async getTemplatePreviewsLocalizations(templateId: number) {
    const previews = await this.getTemplatePreviews(templateId);

    const previewsLocalization: TemplatePreviewLoc = {};
    for (const preview of previews) {
      if (!previewsLocalization[preview.lang]) {
        previewsLocalization[preview.lang] = [];
      }
      previewsLocalization[preview.lang].push({ id: preview.id, url: preview.url });
    }
    return previewsLocalization;
  }

  async uploadTemplatePreview(templateId: number, file: File, lang: string): Promise<GeneralResultStatus> {
    try {
      await this.adminTemplatesApi.uploadTemplatePreview(templateId, file, lang);
      return "success";
    } catch (error: any) {
      console.error(error);

      return "error";
    }
  }

  async deleteTemplatePreview(id: number): Promise<GeneralResultStatus> {
    try {
      await this.adminTemplatesApi.deleteTemplatePreview(id);
      return "success";
    } catch (error: any) {
      console.error(error);

      return "error";
    }
  }

  private watchRouteChanges() {
    this.router.events.subscribe((data) => {
      if (data instanceof ActivationEnd) {
        if ("templateId" in data.snapshot.params) {
          const templateId = Number.parseInt(data.snapshot.params["templateId"]);
          this.loadTemplate(templateId);
        }
      }
    });
  }

  async saveTemplatesOrder(templates: Template[]): Promise<GeneralResultStatus> {
    try {
      await this.adminTemplatesApi.saveTemplatesOrder(templates);
      return "success";
    } catch (error: any) {
      console.error(error);

      return "error";
    }
  }
}
