import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "m-preview-spread",
  templateUrl: "./preview-spread.component.html",
  styleUrls: ["./preview-spread.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewSpreadComponent implements AfterViewInit, AfterViewChecked {
  @Input()
  fullwidth = false;

  urls: string[] = [];
  leftPageIndexes: number[] = [];

  prevUrl?: string;
  nextUrl?: string;
  leftPageIndex = 0;

  rightIsLoaded = false;
  leftIsLoaded = false;

  @ViewChild("pages")
  pagesContainer!: ElementRef;

  @ViewChild("left")
  left!: ElementRef;
  @ViewChild("shadowLeft")
  shadowLeft!: ElementRef;

  @ViewChild("right")
  right!: ElementRef;
  @ViewChild("shadowRight")
  shadowRight!: ElementRef;

  @Output()
  onSelectLeftPage = new EventEmitter<number>();

  constructor(private readonly changeDetectionRef: ChangeDetectorRef) {}

  @Input("selected-left-page")
  set setSelectedLeftPage(value: number) {
    this.leftPageIndex = value;
    this.selectLeftPage(this.leftPageIndex);
  }

  @Input()
  set imageDataURLs(values: string[] | null) {
    if (values?.length) {
      this.urls = [""].concat(values.map((url) => `${url}?${new Date().getTime()}`));
      this.leftPageIndexes = new Array(Math.ceil(this.urls.length / 2)).fill("").map((v, i) => i * 2);
    } else {
      this.urls = [];
      this.leftPageIndexes = [];
    }
    this.selectLeftPage(0);
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  ngAfterViewChecked(): void {}

  selectLeftPage(index: number) {
    this.prevUrl = this.urls[index];
    this.nextUrl = this.urls[index + 1];
    this.leftPageIndex = index;
    this.rightIsLoaded = false;
    this.leftIsLoaded = false;

    this.changeDetectionRef.detectChanges();

    this.onResize();

    this.emitSelectedLeftPage();
  }

  trySelectLeftPage() {
    if (this.leftPageIndex === 0) {
      return;
    }
    this.selectLeftPage(this.leftPageIndex - 2);
  }

  trySelectRightPage() {
    if (!this.urls[this.leftPageIndex + 2]) {
      return;
    }
    this.selectLeftPage(this.leftPageIndex + 2);
  }

  @HostListener("window:keyup", ["$event", "$event.target.tagName"])
  keyEvent(event: KeyboardEvent, targetTag: any) {
    if (targetTag === "BODY") {
      if (event.key === "ArrowLeft") {
        this.trySelectLeftPage();
      }
      if (event.key === "ArrowRight") {
        this.trySelectRightPage();
      }
    }
  }

  onLoaded(page: "left" | "right") {
    if (page === "left") {
      this.leftIsLoaded = true;
    }
    if (page === "right") {
      this.rightIsLoaded = true;
    }
    this.onResize();
  }

  onResize(event?: Event) {
    const parentRect = this.pagesContainer.nativeElement.getBoundingClientRect() as DOMRect;
    if (this.left) {
      this.left.nativeElement.style.maxHeight = `${window.innerHeight - 200}px`;

      if (this.fullwidth) {
        this.left.nativeElement.style.maxHeight = "100%";
        this.left.nativeElement.style.width = "100%";
      }

      const pageRect = this.left.nativeElement.getBoundingClientRect() as DOMRect;
      const shadowTop = pageRect.top - parentRect.top;
      const shadowBottom = parentRect.bottom - pageRect.bottom;
      this.shadowLeft.nativeElement.style.top = `${shadowTop}px`;
      this.shadowLeft.nativeElement.style.bottom = `${shadowBottom}px`;
    }
    if (this.right) {
      this.right.nativeElement.style.maxHeight = `${window.innerHeight - 200}px`;

      if (this.fullwidth) {
        this.right.nativeElement.style.maxHeight = "100%";
        this.right.nativeElement.style.width = "100%";
      }

      const pageRect = this.right.nativeElement.getBoundingClientRect() as DOMRect;
      const shadowTop = pageRect.top - parentRect.top;
      const shadowBottom = parentRect.bottom - pageRect.bottom;
      this.shadowRight.nativeElement.style.top = `${shadowTop}px`;
      this.shadowRight.nativeElement.style.bottom = `${shadowBottom}px`;
    }
  }

  protected selectPage(value: number) {
    this.leftPageIndex = this.leftPageIndexes[value];
    this.selectLeftPage(this.leftPageIndex);
  }

  protected getSelectedIndex() {
    const index = this.leftPageIndexes.findIndex((item) => item === this.leftPageIndex);
    return index;
  }

  protected emitSelectedLeftPage() {
    this.onSelectLeftPage.emit(this.leftPageIndex);
  }
}
