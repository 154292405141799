import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "@metranpage/core";
import { Subscription } from "rxjs";
import { BookModalEventBus } from "../../services/book-modal-event-bus.service";
import { ModalBookDataView } from "../modal-book-data/modal-book-data.view";
import { ModalBookFileUploadView } from "../modal-book-file-upload/modal-book-file-upload.view";
import { ModalBookTemplatePreviewView } from "../modal-book-template-preview/modal-book-template-preview.view";
import { ModalBookTemplatesView } from "../modal-book-templates/modal-book-templates.view";

@Component({
  selector: "m-edit-project-modal",
  templateUrl: "./edit-project-modal.view.html",
  styleUrls: ["./edit-project-modal.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditProjectModalView implements OnInit {
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;

  sub: Subscription = new Subscription();

  constructor(
    private readonly bookModalEventBus: BookModalEventBus,
    private readonly route: ActivatedRoute,
    private readonly routerService: RouterService,
  ) { }

  ngOnInit(): void {
    this.sub.add(
      this.bookModalEventBus.uploadFile$.subscribe(() => {
        this.onCloseClick(true);
      }),
    );

    this.sub.add(
      this.bookModalEventBus.templateSelected$.subscribe(() => {
        this.onCloseClick(true);
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected async onCloseClick(needReload = false) {
    if (needReload) {
      await this.routerService.closeModalAndReload();
    } else {
      this.routerService.closeModal();
    }
  }

  protected onOutletLoaded(
    component: ModalBookFileUploadView | ModalBookTemplatesView | ModalBookTemplatePreviewView | ModalBookDataView,
  ) {
    // if (component instanceof ModalBookFileUploadView) {
    component.runInEditMode = true;
    // }
  }

  protected onBackDropClick() {
    if (!this.closeOnBackDropClick) {
      return;
    }
    this.onCloseClick();
  }

  @HostListener("window:keydown", ["$event"])
  protected handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.onCloseClick();
    }
  }
}
