import { CommonModule, NgClass } from '@angular/common';

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';

export type RadioType = 'normal' | 'running-titles';
export type RadioStyle = 'normal' | 'on-panel' | 'custom';
export type RadioSize = 'small' | 'normal';

@Component({
  selector: 'm-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  standalone: true,
  imports: [CommonModule, NgClass, ReactiveFormsModule, AngularSvgIconModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent implements OnInit {
  @Input()
  value: string | number = '';
  @Input()
  label?: string = '';
  @Input()
  isRequired = false;
  @Input()
  isChecked = false;
  @Input()
  isDisabled = false;
  @Input()
  gapSize: RadioSize = 'normal';

  @Output()
  onSelect = new EventEmitter<string | number>();

  @ViewChild('input', { static: true, read: ElementRef })
  protected radioElementRef?: ElementRef;

  ngOnInit(): void {}

  onClick() {
    if (this.isDisabled) {
      return;
    }
    this.onSelect.emit(this.value);
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    result.push('container');
    result.push(`gap-size-${this.gapSize}`);
    if (this.isDisabled) {
      result.push('disabled');
    }

    return result;
  }
}
