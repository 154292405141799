import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { SelectValue } from "@metranpage/components";
import { LoadingService, NotificationsPopUpService } from "@metranpage/core";
import {
  ActiveSubscription,
  PaymentCurrency,
  PromocodeDiscountData,
  PromocodeEnterResult,
  Tariff,
} from "@metranpage/pricing-data";
import { Subject, Subscription } from "rxjs";
import { PricingService } from "../../services/pricing.service";
import { FAQ } from "../faq/faq.view";

@Component({
  selector: "m-pricing-tariffs",
  templateUrl: "./tariffs.view.html",
  styleUrls: ["./tariffs.view.scss"],
})
export class TariffsView implements OnInit, OnDestroy {
  @Input()
  activeSubscription?: ActiveSubscription;
  @Input()
  tariffs: Tariff[] = [];
  @Input()
  tariffsPeriods: number[] = [];
  @Input()
  activeTariffPeriod = 1;
  @Input()
  authorListSymbolsCount!: number;
  @Input()
  creditsCount!: number;
  @Input()
  isLoading = false;
  @Input()
  isLoadingSubscribe = false;
  @Input()
  faqs: FAQ[] = [];
  @Input()
  currency: PaymentCurrency = "RUB";

  @Output()
  onPeriodSelectClick = new EventEmitter<number>();
  @Output()
  onBuySubscriptionClick = new EventEmitter<Tariff>();
  @Output()
  onTariffSettingsClick = new EventEmitter<void>();

  protected promocodeDiscountDataEvent$ = new Subject<PromocodeDiscountData | undefined>();
  protected promocodeDiscountData?: PromocodeDiscountData;
  protected promocodeResult?: PromocodeEnterResult;

  sub: Subscription = new Subscription();

  constructor(
    private readonly pricingService: PricingService,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.promocodeDiscountDataEvent$ = this.pricingService.promocodeDiscountDataEvent$;

    this.sub.add(
      this.promocodeDiscountDataEvent$.subscribe((promocodeDiscountDataUpdate) => {
        this.promocodeDiscountData = promocodeDiscountDataUpdate;
      }),
    );
  }

  async ngOnInit() {
    await this.loadPromocodeDiscountData();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected onPeriodSelect(period: number) {
    this.activeTariffPeriod = period;
  }

  protected async loadPromocodeDiscountData() {
    this.loadingService.startLoading({ fullPage: true });
    this.promocodeDiscountData = await this.pricingService.loadPromocodeDiscountData();
    this.loadingService.stopLoading();
  }

  protected async onPromocodeEnter(promocode: string) {
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.pricingService.activatePromocode(promocode);
    this.loadingService.stopLoading();

    const message = this.pricingService.getPromocodeResultText(result);
    if (result.status && result.status !== "success" && message) {
      this.notificationService.error(message);
    } else {
      this.promocodeResult = result;
    }
    this.cdr.markForCheck();
  }
}
