<m-modal position="center" (close)="onCancelAddition.emit()">
  <form *ngIf="form" [formGroup]="form">
    <span class="title" i18n="@@admin.fonts.add-font">admin.fonts.add-font</span>

    <div class="controls">
      <m-localized-text class="description" text="admin.fonts.upload-help" i18n-text="@@admin.fonts.upload-help" />

      <div *ngIf="fontData?.fullName" class="control">
        <span class="label" i18n="@@admin.fonts.font.name">admin.fonts.font.name</span>
        <span class="label">{{fontData?.fullName}}</span>
      </div>

      <div *ngIf="fontData?.familyName" class="control">
        <span class="label" i18n="@@admin.fonts.font.family-name">admin.fonts.font.family-name</span>
        <span class="label">{{fontData?.familyName}}</span>
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.fonts.font.file">admin.fonts.font.file</span>

        <m-file-drop
          *ngIf="!fontFile"
          #filedrop
          [multiple]="false"
          (dropped)="dropped($event)"
          style="--custom-height: 150px"
          accept=".otf, .ttf, .zip"
        >
          <div class="drop-placeholder">
            <div>
              <p class="description-text">
                <ng-container i18n="@@books.upload.drop-text1">books.upload.drop_text1</ng-container>
              </p>
              <p class="description-text">
                &nbsp;
                <span
                  class="mark"
                  (click)="openFileSelector()"
                  [style.color]="'#e02379'"
                  i18n="@@books.upload.drop-text2-upload"
                >
                  books.upload.drop_text2_upload
                </span>
              </p>
            </div>
          </div>
        </m-file-drop>

        <div *ngIf="fontFile" class="font-file">
          <b>{{fontFile.name}}</b>

          <m-icon
            src="book-delete.svg"
            size="m"
            color="--color-error"
            class="delete"
            (click)="deleteFontFile()"
          ></m-icon>
        </div>
      </div>

      <div *ngIf="isFontFileUploaded" class="control group">
        <span class="label" i18n="@@admin.fonts.font.license">admin.fonts.font.license</span>

        <div class="row">
          <span>Ru:</span>
          <m-ng-input formControlName="licenseRu" />
        </div>

        <div class="row">
          <span>En:</span>
          <m-ng-input formControlName="licenseEn" />
        </div>
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.fonts.font.is-available-for-headers">
          admin.fonts.font.is-available-for-headers
        </span>
        <m-switch formControlName="isAvailableForHeaders" input-style="on-panel" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.fonts.font.is-available-for-main-text">
          admin.fonts.font.is-available-for-main-text
        </span>
        <m-switch formControlName="isAvailableForMainText" input-style="on-panel" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.fonts.font.is-selectable">admin.fonts.font.is-selectable</span>
        <m-switch formControlName="isSelectable" input-style="on-panel" />
      </div>
    </div>

    <div class="buttons">
      <m-button button-style="secondary" (onClick)="onCancel()" i18n="@@admin.common.cancel">
        admin.common.cancel
      </m-button>
      <m-button
        button-style="primary"
        [is-disabled]="!form.valid || !fontFile"
        (onClick)="createFont()"
        i18n="@@admin.common.create"
      >
        admin.common.create
      </m-button>
    </div>
  </form>
</m-modal>
