import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { CompanyStore } from "@metranpage/company";
import { filterUndefined } from "@metranpage/core";
import { PricingViewService } from "@metranpage/pricing";
import { ActiveSubscription, PaymentCurrency } from "@metranpage/pricing-data";
import { User, UserStore } from "@metranpage/user-data";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";

@Component({
  selector: "m-account-active-tariff-page",
  templateUrl: "./account-active-tariff.page.html",
  styleUrls: ["./account-active-tariff.page.scss"],
})
export class AccountActiveTariffPage {
  user?: User;
  activeSubscription?: ActiveSubscription;

  currency: PaymentCurrency = "RUB";
  currencySymbol = "₽";

  sub: Subscription = new Subscription();

  constructor(
    private readonly pricingViewService: PricingViewService,
    private readonly router: Router,
    userStore: UserStore,
    companyStore: CompanyStore,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );

    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.activeSubscription = activeSubscription;
      }),
    );

    this.sub.add(
      companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.currency = company.currency;
          this.updateCurrencySymbol();
        }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getTariffStatus(): { body: string; footer: string } {
    let body = "";
    let footer = "";

    const endingDate = this.activeSubscription?.subscription.endingDate;
    if (!endingDate) {
      return { body: "", footer: "" };
    }
    const end = DateTime.fromISO(endingDate); //.set({ hour: 0, minute: 0, second: 0 });
    const start = DateTime.now(); //.set({ hour: 0, minute: 0, second: 0 });
    const diffInDays = end.diff(start, "days");
    let days = diffInDays.toObject().days;

    if (!days) {
      return { body: "", footer: "" };
    }

    days = Math.round(days);
    if (days < 0) {
      days = 0;
    }
    body = `${days} ${this.pricingViewService.pluralizeDays(
      days,
    )} ${$localize`:@@user.profile.tariff-and-payments.left:`}`;
    footer = `${$localize`:@@user.profile.tariff-and-payments.expire:`} ${end.toJSDate().toLocaleDateString("ru-RU")}`;

    if (this.activeSubscription?.tariff.isFree) {
      const trialActivatedDate = this.user?.trialActivatedDate;
      if (!trialActivatedDate) {
        return { body: "", footer: "" };
      }

      const trialPeriodInDay = this.activeSubscription.tariff.trialPeriodInDay;
      const today = DateTime.now(); //.set({ hour: 0, minute: 0, second: 0 });
      const start = DateTime.fromISO(trialActivatedDate); //.set({ hour: 0, minute: 0, second: 0 });
      const expireDate = start.plus({ days: trialPeriodInDay });
      const diffInDays = today.diff(start, "days").toObject().days ?? 0;

      if (!diffInDays) {
        return { body: "", footer: "" };
      }

      if (diffInDays > trialPeriodInDay) {
        body = $localize`:@@user.profile.tariff-and-payments.trial-period-end:`;
        footer = `${$localize`:@@user.profile.tariff-and-payments.connect-tariff:`}`;
      } else {
        body = `${trialPeriodInDay} ${this.pricingViewService.pluralizeDays(
          trialPeriodInDay,
        )} ${$localize`:@@user.profile.tariff-and-payments.trial-period:`}`;
        footer = `${$localize`:@@user.profile.tariff-and-payments.expire:`}
        ${expireDate.toJSDate().toLocaleDateString("ru-RU")}`;
      }
    }

    return {
      body,
      footer,
    };
  }

  getExpireDate(date: string | undefined) {
    if (!date) {
      return;
    }
    return `${$localize`:@@user.profile.tariff-and-payments.expire:`} ${new Date(date)?.toLocaleDateString("ru-RU")}`;
  }

  async onUpgradeTariffClick() {
    this.router.navigate(["/payments/subscription"]);
  }

  async onConnectSupportClick() {
    try {
      (<any>window).ReplainAPI("open");
    } catch (error) {}
  }

  updateCurrencySymbol() {
    this.currencySymbol = this.pricingViewService.getCurrencySymbol(this.currency);
  }

  protected pluralizePeriod() {
    let period = this.activeSubscription!.tariff.period;
    let result = `${this.pricingViewService.pluralizeMonths(period)}`;
    if (period === 12) {
      period = period / 12;
      result = `${this.pricingViewService.pluralizeYears(period)}`;
    }
    if (period > 1) {
      result = `${period} ${result}`;
    }
    return result;
  }
}
