import { CommonModule } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  forwardRef,
  inject,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { ThemeService } from "@metranpage/theme";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "m-switch-book-type",
  templateUrl: "./switch-book-type.component.html",
  styleUrls: ["./switch-book-type.component.scss"],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, AngularSvgIconModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchBookTypeComponent),
      multi: true,
    },
  ],
})
export class SwitchBookTypeComponent implements ControlValueAccessor, OnInit {
  @Input()
  icon = "";
  @Input()
  isChecked = false;
  /**
  Sometimes, this components should just reflect outside state and not change by itself. Set isInteractive to false in that case 
   */
  @Input()
  isInteractive = true;
  @Input()
  withLabel = true;

  @Output()
  onToggle = new EventEmitter<void>(undefined);

  protected iconSrc!: string;

  protected destroyRef = inject(DestroyRef);

  protected isDisabled = false;
  private onTouched = () => {};
  private onChange = (_: any) => {};

  constructor(
    private readonly themeService: ThemeService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    themeService.changeEvents$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((theme) => {
      this.normalizePath();
      this.cdr.markForCheck();
    });
  }

  ngOnInit(): void {
    this.normalizePath();
  }

  ngAfterViewInit() {}

  @HostListener("click")
  toggle() {
    if (this.isDisabled) {
      return;
    }

    if (!this.isInteractive) {
      return;
    }

    this.isChecked = !this.isChecked;
    this.onTouched();
    this.onChange(this.isChecked);

    this.onToggle.emit();
  }

  writeValue(value: boolean): void {
    if (value === null) {
      return;
    }
    this.isChecked = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  normalizePath() {
    if (!this.icon) {
      return;
    }

    if (this.icon.startsWith("/")) {
      this.iconSrc = this.icon;
    } else {
      this.iconSrc = `/assets/icons/${this.icon}`;
    }

    if (this.iconSrc.includes("{theme}")) {
      this.iconSrc = this.iconSrc.replace("{theme}", this.themeService.getThemeSuffix());
    }
  }
}
