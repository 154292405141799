import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { StyleKey } from "../../../models/styles";
import { HtmlRendererService } from "../../../services/html-renderer.service";
import { RangeService } from "../../../services/range.service";
import { BlockMergeEvent, BlockMovementEvent, BlockSplitEvent } from "../../markup-editor/editor.events";
import { FakeCursorClassName, FakeCursorItemAsText, FakeCursorNode } from "../../markup-editor/editor.models";
import { Footnote, TextSelectionState } from "../../markup-editor/editor.service";
import { BaseTextEditor } from "../base-text-editor/base-text-editor.view";

@Component({
  selector: "m-text-editor",
  templateUrl: "./text-editor.view.html",
  styleUrls: ["./text-editor.view.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextEditor),
      multi: true,
    },
  ],
})
export class TextEditor extends BaseTextEditor implements ControlValueAccessor {
  @Input()
  startingNoteIndex = 1;
  @Output()
  onSplit = new EventEmitter<BlockSplitEvent>();
  @Output()
  onMergeWithPrev = new EventEmitter<BlockMergeEvent>();
  @Output()
  blockMovement = new EventEmitter<BlockMovementEvent>();

  @Output()
  footnoteAdded: EventEmitter<Footnote[]> = new EventEmitter();
  @Output()
  footnoteChanged: EventEmitter<Footnote[]> = new EventEmitter();

  constructor(
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    rangeService: RangeService,
    protected htmlRendererService: HtmlRendererService,
  ) {
    super(elementRef, cdr, rangeService);
  }

  protected getSelectionState(range: Range) {
    const hasBold = this.rangeService.hasTagInRange("b", range, this.elementRef.nativeElement);
    const hasItalic = this.rangeService.hasTagInRange("i", range, this.elementRef.nativeElement);

    return { hasBold, hasItalic, hasFootnote: false };
  }

  override onChangeHandle(event: Event) {
    const notes = this.refreshFootnotes();
    this.footnoteChanged.emit(notes);

    this.onChange((event?.target! as Element).innerHTML);
  }

  getTextSelectionState(): TextSelectionState | undefined {
    const range = this.getSelectionRange();
    if (!range) {
      return undefined;
    }

    return {
      range: range,
      hasBold: this.rangeService.hasTagInRange("b", range, this.elementRef.nativeElement),
      hasItalic: this.rangeService.hasTagInRange("i", range, this.elementRef.nativeElement),
      hasFootnote: false,
      onPageIndex: -1,
    };
  }

  private getSelectionRange() {
    const selection = window.getSelection();
    if (this.elementRef.nativeElement.contains(selection?.focusNode)) {
      if (selection?.rangeCount === 0) {
        console.error("cannot process selection");
        return undefined;
      }

      const range = selection?.getRangeAt(0);
      if (!range) {
        console.error("cannot process selection");
        return undefined;
      }
      return range;
    }

    return undefined;
  }

  applyBold(): void {
    const range = this.getSelectionRange();
    if (!range) {
      return;
    }

    const nativeElement = this.elementRef.nativeElement;
    const hasBold = this.rangeService.hasTagInRange("b", range, nativeElement);
    if (hasBold) {
      this.htmlRendererService.removeInlineTagInRange("b", range, nativeElement);
    } else {
      this.htmlRendererService.addInlineTagInRange("b", range);
    }
    this.saveDataFromHtml();
    this.cdr.markForCheck();
  }

  applyItalic(): void {
    const range = this.getSelectionRange();
    if (!range) {
      return;
    }

    const nativeElement = this.elementRef.nativeElement;
    const hasItalic = this.rangeService.hasTagInRange("i", range, nativeElement);
    if (hasItalic) {
      this.htmlRendererService.removeInlineTagInRange("i", range, nativeElement);
    } else {
      this.htmlRendererService.addInlineTagInRange("i", range);
    }
    this.saveDataFromHtml();
    this.cdr.markForCheck();
  }

  addFootnote() {
    const range = this.getRange();
    if (!range) {
      return;
    }
    if (!range.collapsed) {
      range.collapse(false);
    }

    const note = document.createElement("footnote");
    note.setAttribute("data-note-text", FakeCursorClassName);
    note.textContent = "*";
    range.insertNode(note);
    range.setEndAfter(note);
    range.collapse(false);

    this.saveDataFromHtml();

    const notes = this.refreshFootnotes();

    const focusedNote = notes.find((note) => note.text === FakeCursorClassName);
    if (focusedNote) {
      focusedNote.text = "";
      focusedNote.isFocused = true;
    }

    note.setAttribute("data-note-text", "");
    this.footnoteAdded.emit(notes);
  }

  refreshFootnotes() {
    const footnotes: Footnote[] = [];
    const sups: HTMLCollection = this.editorElement.nativeElement.querySelectorAll("[data-note-text]");

    let noteIndex = this.startingNoteIndex;
    for (let i = 0; i < sups.length; i++) {
      const noteText = sups[i].getAttribute("data-note-text");
      sups[i].setAttribute("contenteditable", "false");
      if (noteText !== undefined && noteText !== null) {
        footnotes.push({
          id: noteIndex,
          text: noteText,
          isFocused: false,
        });
        sups[i].textContent = `${noteIndex}`;

        noteIndex++;
      }
    }

    this.cdr.markForCheck();

    return footnotes;
  }

  updateFootnotesText(notes: Footnote[]) {
    const sups: HTMLCollection = this.editorElement.nativeElement.querySelectorAll("[data-note-text]");

    for (let i = 0; i < sups.length; i++) {
      const noteIndex = Number.parseInt(sups[i].textContent!);
      const note = notes.find((n) => n.id === noteIndex);
      if (!note) {
        console.error("cannot update note text");
        continue;
      }

      sups[i].setAttribute("data-note-text", note.text);
    }

    this.cdr.markForCheck();
    this.saveDataFromHtml();
  }

  removeFootnote(index: number): boolean {
    let isRemoved = false;
    const sups: HTMLCollection = this.editorElement.nativeElement.querySelectorAll("[data-note-text]");

    for (let i = 0; i < sups.length; i++) {
      const noteIndex = Number.parseInt(sups[i].textContent!);
      if (noteIndex === index) {
        sups[i].remove();
        isRemoved = true;
      }
    }

    this.saveDataFromHtml();

    const notes = this.refreshFootnotes();
    this.footnoteChanged.emit(notes);

    this.cdr.markForCheck();

    return isRemoved;
  }

  appendTextAndFocus(text: string) {
    this.value += FakeCursorItemAsText + text;
    this.setDataToHtml();
    this.cdr.detectChanges();

    // focus on next tick
    setTimeout(() => {
      this.htmlRendererService.focusFakeCursor(this.editorElement.nativeElement);
      this.saveDataFromHtml();
    }, 1);
  }

  override onKeyPressHandle(event: KeyboardEvent) {
    if (event instanceof KeyboardEvent) {
      if (event.key === "Enter") {
        this.onEnterPress(event);
      }
    }
  }

  override onKeyDownHandle(event: KeyboardEvent) {
    if (event instanceof KeyboardEvent) {
      if (event.key === "Backspace") {
        this.onBackspacePress(event);
      }

      if (window.getSelection()?.isCollapsed) {
        // handle move to next block, only if user move just cursor, not selection range
        if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
          const isCursorAtStart = this.rangeService.isCursorAtStart(this.editorElement);
          if (isCursorAtStart) {
            this.blockMovement.emit({ direction: "prev" });
            event.preventDefault();
          }
        }

        if (event.key === "ArrowDown" || event.key === "ArrowRight") {
          const isCursorAtEnd = this.rangeService.isCursorAtEnd(this.editorElement);
          if (isCursorAtEnd) {
            this.blockMovement.emit({ direction: "next" });
            event.preventDefault();
          }
        }
      }
    }
  }

  override onEnterPress(event: KeyboardEvent) {
    event.preventDefault();

    const range = this.getRange();

    if (!range) {
      console.error("cannot handle enter press");
      return;
    }

    if (event.ctrlKey || event.shiftKey) {
      this.rangeService.addBrToInput(range, this.editorElement.nativeElement);
      this.saveDataFromHtml();
    } else {
      this.splitBlock(range);
    }
  }

  private splitBlock(range: Range) {
    range.insertNode(FakeCursorNode);
    this.saveDataFromHtml();

    const text = this.value;
    const parts = text.split(FakeCursorItemAsText);
    let prevText = parts[0];
    let nextText = parts[1];

    const tags = ["b", "strong", "em", "i"];
    for (const tag of tags) {
      if (this.rangeService.hasTagInRange(tag, range, this.elementRef.nativeElement)) {
        prevText = prevText + `</${tag}>`;
        nextText = `<${tag}>` + nextText;
      }
    }

    prevText = this.removeEmptyInlineTags(prevText, tags);
    nextText = this.removeEmptyInlineTags(nextText, tags);

    this.value = prevText;
    this.setDataToHtml();
    this.onChange(this.value);

    this.onSplit.emit({
      text: nextText,
      styleKey: "mainText" as StyleKey,
    });
  }

  removeEmptyInlineTags(text: string, tags: string[]) {
    let clearedText = text;
    let isTagsRemoved = true;
    while (isTagsRemoved) {
      isTagsRemoved = false;
      const originalLength = clearedText.length;
      for (const tag of tags) {
        const regex = new RegExp(`<${tag}></${tag}>`, "g");
        clearedText = clearedText.replace(regex, "");
      }
      const length = clearedText.length;

      if (originalLength !== length) {
        isTagsRemoved = true;
      }
    }
    return clearedText;
  }

  override onBackspacePress(event: KeyboardEvent) {
    const isCursorAtStart = this.rangeService.isCursorAtStart(this.editorElement);

    if (isCursorAtStart && !event.repeat) {
      //if we are at the beginnig of the line and user doesn't hold backspace to delete all
      this.saveDataFromHtml();

      this.onMergeWithPrev.emit({
        styleKey: "mainText" as StyleKey,
      });

      event.preventDefault();
    }
  }

  override setDataToHtml() {
    this.value = this.value.trim();
    this.editorElement.nativeElement.innerHTML = this.value;

    // remove last br
    const el = this.editorElement.nativeElement as Element;
    if (el.childNodes.length > 0) {
      if (el.lastChild?.nodeName.toLowerCase() === "br") {
        el.lastChild?.remove();
        // force save data with new html
        this.saveDataFromHtml();
      }
    }

    const notes = this.refreshFootnotes();
    this.footnoteAdded.emit(notes);
  }
}
