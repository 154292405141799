<div class="header" [class.can-collapsing]="canCollapsing" (click)="toggle()">
  <div class="title-group">
    <m-ng-icon *ngIf="iconSrc" [src]="iconSrc" [size]="28" class="title-icon" />

    <span class="title">{{ title }}</span>
  </div>

  <span *ngIf="canCollapsing" class="icon" [class.collapsed]="isCollapsed"></span>
  <m-ng-icon
    *ngIf="canCollapsing"
    src="dropdown-arrow-down.svg"
    [size]="16"
    class="collapse-icon"
    [class.collapsed]="isCollapsed"
  />
</div>
<div class="content" *ngIf="isCollapsed" @slideInOutVertical>
  <ng-content></ng-content>
</div>
