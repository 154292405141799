<ng-container *ngIf="singleIcon">
  <div class="wrapper">
    <m-ng-icon src="info-3.svg" [ngClass]="getIconCssClassList()" [size]="iconSize" />
    <div class="container-2">
      <div class="label" *ngFor="let label of labels; trackBy: trackByLabel" [ngClass]="getCssClassList()">
        <div>
          <span
            *ngFor="let t of label.textData; trackBy: trackByTextData"
            [ngClass]="{ 'action-text': t.action }"
            (click)="actionClick(t.action)"
          >
            {{ t.text }}
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!singleIcon">
  <div class="container">
    <div class="label" *ngFor="let label of labels; trackBy: trackByLabel" [ngClass]="getCssClassList()">
      <m-ng-icon src="info-3.svg" [ngClass]="getIconCssClassList()" [size]="iconSize" />
      <div>
        <span
          *ngFor="let t of label.textData; trackBy: trackByTextData"
          [ngClass]="{ 'action-text': t.action }"
          (click)="actionClick(t.action)"
        >
          {{ t.text }}
        </span>
      </div>
    </div>
  </div>
</ng-container>
