import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { I18nService } from "@metranpage/i18n";
import { NotificationPayloadImageModeration } from "../../../models/notification";
import { RouterService } from "../../../services/router.service";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-image-moderation-card-view",
  templateUrl: "account-notification-image-moderation-card.view.html",
  styleUrls: ["account-notification-image-moderation-card.view.scss"],
})
export class AccountNotificationImageModerationCardView extends AccountNotificationBaseCardView<NotificationPayloadImageModeration> {
  constructor(
    private readonly routerService: RouterService,
    private readonly router: Router,
    private readonly i18nService: I18nService,
  ) {
    super();
  }

  openImageOnHomePage() {
    this.router.navigate(["images"]);
    setTimeout(() => {
      this.routerService.showInModal(["images", "preview", this.payload.imageId]);
    }, 100);
  }

  openImageGeneration() {
    this.router.navigate(["image-generation"]);
    setTimeout(() => {
      this.routerService.showInModal([this.payload.generationId, this.payload.imageId]);
    }, 100);
  }

  protected getRewardCreditsText() {
    const rewardCreditsCount = this.payload.rewardCreditsCount;
    if (!rewardCreditsCount || rewardCreditsCount <= 0) {
      return;
    }

    return `${rewardCreditsCount} ${this.i18nService.pluralize($localize`:@@pricing.pluralize-credits:`, {
      credits: rewardCreditsCount,
    })} ${this.i18nService.pluralize($localize`:@@common.pluralize.add:`, {
      value: rewardCreditsCount,
    })} ${$localize`:@@user.profile.notifications.card.moderation-success.reward:`}`;
  }
}
