<div
  class="wrapper"
  [class.external-host]="useColorPickerExternalHost"
  [class.position-top]="popupPosition === 'top'"
  [class.compact]="popupSize === 'compact'"
>
  <div
    class="context-host"
    clickoutsidedetector
    (clickOutside)="isColorPickerVisible = false"
    (click)="toggleColorPickerVisibility()"
    (mouseover)="showColorStopContext()"
    (mouseleave)="hideColorStopContext()"
  >
    <m-cover-selected-color [color]="color"></m-cover-selected-color>

    <ng-container *ngIf="isColorPickerVisible">
      <div class="tooltip-arrow"></div>
    </ng-container>

    <ng-container *ngIf="!useColorPickerExternalHost">
      <ng-container [ngTemplateOutlet]="colorPickerTemplate"></ng-container>
    </ng-container>

    <ng-container *ngIf="showDelete && isColorStopContextVisible && !isColorPickerVisible">
      <div class="tooltip-arrow"></div>
      <div #colorContextMenu class="context-wrapper delete" (click)="$event.stopPropagation()">
        <div class="button" (click)="onDeleteColor()">
          <m-ng-icon src="/assets/icons/delete-01.svg" color="fake" [size]="22"></m-ng-icon>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="useColorPickerExternalHost">
    <ng-container [ngTemplateOutlet]="colorPickerTemplate"></ng-container>
  </ng-container>
</div>

<ng-template #colorPickerTemplate>
  <div
    #colorPicker
    class="context-wrapper color-picker"
    *ngIf="isColorPickerVisible"
    (click)="$event.stopPropagation()"
  >
    <form [formGroup]="form">
      <m-color-picker
        formControlName="color"
        [show-opacity]="showOpacity"
        [show-eyedropper]="true"
        [enable-opacity]="enableOpacity"
        (eyeDropper)="onEyeDropper()"
      ></m-color-picker>
    </form>
  </div>
</ng-template>
