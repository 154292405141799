import { Injectable } from "@angular/core";
import { MarginKey, Margins, MarginsSelectSource, MarginsState, PagePosition } from "@metranpage/book-data";
import { Store } from "@metranpage/state";
import { map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MarginsStore extends Store<MarginsState> {
  protected override getInitialState(): MarginsState {
    return {
      margins: {
        marginTop: 8,
        marginBottom: 8,
        marginOuter: 8,
        marginInner: 20,
        gutter: 5,
        widePadding: 15,
      },
      widePaddingLeftPosition: 1,
      widePaddingRightPosition: 1,
      widePaddingState: true,
      columnsCount: 2,
      spaceLevel: 1,
      lastChangeSource: "sidebar",
    };
  }

  getMarginsState() {
    return this.getState();
  }

  // getMarginsStateObservable() {
  //   return this.getStateObservable();
  // }

  getMarginsStateObservable() {
    return this.getStateObservable().pipe(
      map((s) => {
        return { ...s, margins: { ...s.margins } };
      }),
    );
  }

  setMarginsState(marginsState: MarginsState) {
    this.update((state) => {
      return marginsState;
    });
  }

  getMargins() {
    return { ...this.getField("margins") };
  }

  getMarginsObservable() {
    return this.getFieldObservable("margins");
  }

  setMargins(margins: Margins, source: MarginsSelectSource = "sidebar") {
    this.update((state) => ({ ...state, margins: margins, lastChangeSource: source }));
  }

  getMargin(marginKey: MarginKey) {
    return this.getField("margins")[marginKey];
  }

  setWidePaddingPosition(pagePosition: PagePosition, position: number) {
    if (pagePosition === "left") {
      this.update((state) => ({ ...state, widePaddingLeftPosition: position }));
    }
    if (pagePosition === "right") {
      this.update((state) => ({ ...state, widePaddingRightPosition: position }));
    }
  }

  getColumnsCount() {
    return this.getField("columnsCount");
  }

  setColumnsCount(value: number, source: MarginsSelectSource = "sidebar") {
    this.update((state) => ({ ...state, columnsCount: value, lastChangeSource: source }));
  }

  getSpaceLevel() {
    return this.getField("spaceLevel");
  }

  setSpaceLevel(value: number, source: MarginsSelectSource = "sidebar") {
    this.update((state) => ({ ...state, spaceLevel: value, lastChangeSource: source }));
  }

  getWidePaddingState() {
    return this.getField("widePaddingState");
  }

  setWidePaddingState(value: boolean, source: MarginsSelectSource = "sidebar") {
    this.update((state) => ({ ...state, widePaddingState: value, lastChangeSource: source }));
  }

  setMargin(marginKey: MarginKey, value: number, source: MarginsSelectSource = "sidebar") {
    this.update((state) => ({ ...state, margins: { ...state.margins, [marginKey]: value }, lastChangeSource: source }));
  }
}
