import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { debounceTime, takeUntil } from "rxjs";
import { CompanyFont, FontCreateDataWithFile } from "../../models/font";
import { AdminFontsService } from "../../services/fonts/fonts.service";
import { AdminFontsStore } from "../../services/fonts/fonts.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-fonts-page",
  templateUrl: "./fonts.page.html",
  styleUrls: ["./fonts.page.scss"],
})
export class AdminCompanyFontsPage extends AdminBasePage implements OnInit, OnDestroy {
  fonts: CompanyFont[] = [];
  page = 1;
  pageCount = 1;

  protected searchForm = new FormGroup({
    name: new FormControl("", { nonNullable: true, validators: [] }),
  });
  protected searchName: string | undefined = undefined;

  protected isAddFontModalVisible = false;
  protected isDeleteFontModalVisible = false;
  protected fontForDelete?: CompanyFont = undefined;

  constructor(
    private readonly adminFontsService: AdminFontsService,
    adminFontsStore: AdminFontsStore,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
  ) {
    super();

    this.addSub(
      adminFontsStore
        .getFontsObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((fonts) => {
          this.fonts = fonts;
        }),
    );

    this.addSub(
      adminFontsStore
        .getFontsPageCountObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((count) => {
          this.pageCount = count;
        }),
    );
  }

  ngOnInit(): void {
    this.adminFontsService.loadFontsPaginated(this.page);
    this.searchForm.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(600)).subscribe((value) => {
      this.searchName = undefined;
      if (value.name) {
        this.searchName = value.name;
      }
      this.adminFontsService.loadFontsPaginated(this.page, {
        name: this.searchName,
      });
    });
  }

  trackByFontId(index: number, font: CompanyFont) {
    return font.id;
  }

  onPageChanged(page: number) {
    this.page = page;
    this.adminFontsService.loadFontsPaginated(this.page);
  }

  protected showFontDetails(font: CompanyFont) {
    this.router.navigate(["admin", "fonts", font.id]);
  }

  protected async onShowAddFontModal() {
    this.isAddFontModalVisible = true;
  }

  protected async onFontAdded(data: FontCreateDataWithFile) {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminFontsService.createFont(data);
    this.loadingService.stopLoading();
    if (result.status === "success") {
      this.onCloseAddFontModal();
      this.adminFontsService.loadFontsPaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.fonts.font.error.cant-create-font:`);
    }
  }

  protected onCloseAddFontModal() {
    this.isAddFontModalVisible = false;
  }

  protected onCloseDeleteFontModal() {
    this.isDeleteFontModalVisible = false;
    this.fontForDelete = undefined;
  }

  protected showFontDeleteModal(font: CompanyFont) {
    this.isDeleteFontModalVisible = true;
    this.fontForDelete = font;
  }

  protected async onDeleteFont() {
    if (!this.fontForDelete) {
      return;
    }

    const result = await this.adminFontsService.deleteFont(this.fontForDelete.id);
    if (result.status === "success") {
      this.isDeleteFontModalVisible = false;
      this.fontForDelete = undefined;
      this.adminFontsService.loadFontsPaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.fonts.font.error.cant-delete-font:`);
    }
  }
}
