import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Breadcrumb } from "@metranpage/core";

@Injectable({
  providedIn: "root",
})
export class ImageGenerationBreadcrumbsResolver {
  async resolve(_route: ActivatedRouteSnapshot): Promise<Breadcrumb[]> {
    const bcImageGeneration: Breadcrumb = {
      title: $localize`:@@cover.breadcrumb.generation-step:`,
      url: "image-generation",
      isEnabled: true,
      isVisible: true,
    };

    return [bcImageGeneration];
  }
}
