<div *ngIf="(items$ | async); else noPublishedImages; let items" class="images">
  <m-c-image-grid #grid class="grid" [items]="items" [needClearPreviousOnChange]="false">
    <ng-template #itemTemplate let-item>
      <div class="grid-item">
        <m-published-object-preview
          [objectType]="objectType"
          [publishedObject]="item"
          (click)="onObjectClick(item.id)"
        />
      </div>
    </ng-template>
  </m-c-image-grid>

  <div *ngIf="isLoading" class="loading"><m-loading-spinner @fadeInOut></m-loading-spinner></div>
</div>

<ng-template #noPublishedImages>
  <div class="no-results">
    <div *ngIf="objectType === 'images'" class="header" i18n="@@home.community.empty-state-images">
      home.community.empty-state-images
    </div>
    <div *ngIf="objectType === 'covers'" class="header" i18n="@@home.community.empty-state-covers">
      home.community.empty-state-covers
    </div>
  </div>
</ng-template>
