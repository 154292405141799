import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  Color,
  CoverObject,
  Fill,
  ObjectsAlignment,
  SolidFill,
  TextAlignment,
  TextCase,
  TextObject,
  TextboxShadowOptions,
} from "@metranpage/book-data";
import { SelectValue } from "@metranpage/components";
import * as _ from "lodash-es";
import { Observable, filter, map, startWith, tap } from "rxjs";
import { CoverFontsService } from "../../services/cover/cover-fonts.service";

@Component({
  selector: "m-cover-text-object-settings",
  templateUrl: "./cover-text-object-settings.component.html",
  styleUrls: ["./cover-text-object-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverTextObjectSettingsComponent implements OnInit {
  private _currentObject!: TextObject;
  @Input() set currentObject(value: TextObject) {
    if (value) {
      const previousObjectText = this._currentObject?.text;
      this._currentObject = value;
      this.resetShadowState(previousObjectText, this._currentObject.text);
      if (!this.form) {
        return;
      }
      if (this._currentObject.shadow) {
        this.toggleShadow();
      }
      this.updateFormValue();
    }
  }
  get currentObject(): TextObject {
    return this._currentObject;
  }

  @Output() update = new EventEmitter<CoverObject>();
  @Output() previewFontFamily = new EventEmitter<string>();
  @Output() resetFontFamily = new EventEmitter();
  @Output() align = new EventEmitter<ObjectsAlignment>();

  values$!: Observable<Partial<TextObject>>;
  fontOptions$!: Observable<SelectValue[]>;
  textCase = TextCase;

  color!: Color;
  withShadow = false;
  form!: FormGroup;

  constructor(coverFontsService: CoverFontsService) {
    this.fontOptions$ = coverFontsService.fontsLoaded$.pipe(
      filter((fonts) => fonts.length > 0),
      map((fonts) => {
        return _.uniqBy(fonts.map((f) => <SelectValue>{ id: f.family, value: f.family }) ?? [], (v) => {
          return v.id;
        });
      }),
    );
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      fontSize: new FormControl<number>(24, [Validators.min(4), Validators.max(500)]),
      lineHeight: new FormControl<number>(100, [Validators.min(0), Validators.max(200)]),
      letterSpacing: new FormControl<number>(0, [Validators.min(0), Validators.max(200)]),
      fontFamily: new FormControl<string | undefined>(undefined),
    });

    this.updateFormValue();

    if (this.currentObject.shadow) {
      this.toggleShadow();
    }

    this.values$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      filter(() => this.form.valid),
      map((v) => <Partial<TextObject>>v),
      tap((v) => {
        if (v.shadow) {
          if (!this.currentObject.shadow) {
            this.currentObject.shadow = new TextboxShadowOptions();
          }
          if (this.updateShadowTransparency()) {
            this.setShadowColor();
            return;
          }

        } else {
          this.currentObject.shadow = null;
        }
        Object.assign(this._currentObject, v);
        this.update.emit(this.currentObject);
      }),
    );
  }

  private updateFormValue(): void {
    const patchValue: { [key: string]: any } = {
      fontFamily: this.currentObject.fontFamily,
    };
    if (this.currentObject.fontSize) {
      patchValue.fontSize = this.currentObject.fontSize;
    }
    if (this.currentObject.lineHeight) {
      patchValue.lineHeight = this.currentObject.lineHeight;
    }
    if (this.currentObject.letterSpacing) {
      patchValue.letterSpacing = this.currentObject.letterSpacing;
    }

    this.form.patchValue(patchValue);
  }

  private resetShadowState(previousText: string | undefined, currentText: string | undefined): void {
    if (previousText !== currentText && this.form) {
      if (this.form.controls["shadow"]) {
        this.form.removeControl("shadow");
      }
      this.withShadow = false;
    }
  }

  toggleShadow(): void {
    if (this.withShadow) {
      this.withShadow = false;
      this.form.removeControl("shadow");
      this.update.emit(this.currentObject);
      return;
    }

    if (this.form.controls["shadow"]?.value) {
      this.form.removeControl("shadow");
    }

    this.withShadow = true;
    this.setShadowColor();

    const shadowGroup = new FormGroup({
      blur: new FormControl<number | null>(this.currentObject.shadow?.blur || 10),
      offset: new FormControl<number | null>(this.currentObject.shadow?.offset || 20),
      color: new FormControl<string | null>(Color.toCss(this.color)),
      direction: new FormControl<number | null>(this.currentObject.shadow?.direction || 40),
      transparency: new FormControl<number>(this.getTransparrency()),
    });
    this.form.addControl("shadow", shadowGroup);

    this.update.emit(this.currentObject);
  }

  setShadowColor() {
    if (this.currentObject.shadow?.color) {
      const shadowColor = this.currentObject.shadow?.color;
      const rgbaArray = shadowColor.slice(shadowColor.indexOf("(") + 1, shadowColor.indexOf(")")).split(",");
      const rgbaObject = ["r", "g", "b", "a"].reduce((acc: any, curr, idx) => {
        acc[curr] = rgbaArray[idx];
        return acc;
      }, {});
      this.color = new Color(
        Math.round(rgbaObject.r),
        Math.round(rgbaObject.g),
        Math.round(rgbaObject.b),
        this.getTransparrency() / 100
      );
      if (this.form.controls['shadow']) {

        this.updateShadowColor(this.color);
      }

      return;
    }
    if (this.currentObject.fill instanceof SolidFill) {
      this.color = new Color(0, 0, 0, 0.2);
    }
  }

  setTextAlign(alignment: TextAlignment) {
    this.currentObject.textAlign = alignment;
    this.update.emit(this.currentObject);
  }

  updateFill(fill: Fill) {
    this.currentObject.fill = fill;
    this.update.emit(this.currentObject);
  }

  get fillType(): string {
    if (this.currentObject.fill instanceof SolidFill) {
      return $localize`:@@cover-editor.object.settings.color:`;
    }
    return $localize`:@@cover-editor.object.settings.gradient:`;
  }

  toggleTextBold() {
    this.currentObject.bold = !this.currentObject.bold;
    this.update.emit(this.currentObject);
  }

  toggleTextItalic() {
    this.currentObject.italic = !this.currentObject.italic;
    this.update.emit(this.currentObject);
  }

  toggleTextUnderline() {
    this.currentObject.underline = !this.currentObject.underline;
    this.update.emit(this.currentObject);
  }

  onUpdate(object: CoverObject) {
    this.update.emit(object);
  }

  onPreviewFontFamily(fontFamily: string) {
    this.previewFontFamily.emit(fontFamily);
  }

  onResetFontFamily() {
    this.resetFontFamily.emit();
  }

  onAlign(alignment: ObjectsAlignment) {
    this.align.emit(alignment);
  }

  setTextCase(textCase: TextCase): void {
    if (this.currentObject.text) {
      this.currentObject.textCase = textCase;
      this.update.emit(this.currentObject);
    }
  }

  private getTransparrency(): number {
    const formTransparency = (this.form.controls['shadow'] as FormGroup)?.controls['transparency'].value
    if (formTransparency || formTransparency === 0) {
      return Math.round(+formTransparency);
    }
    const shadowColor = this.currentObject.shadow?.color;
    if (shadowColor) {
      const rgbaArray = shadowColor.slice(shadowColor.indexOf("(") + 1, shadowColor.indexOf(")")).split(",");
      return +rgbaArray[rgbaArray.length - 1] * 100;
    }
    return 20;
  }

  updateShadowTransparency(): boolean {
    const newTransparency = Math.round(+(this.form.controls['shadow'] as FormGroup).controls['transparency'].value)
    const previousTransparency = Math.round(this.color.a * 100);
    return newTransparency !== previousTransparency
  }

  updateShadowColor(color: Color) {
    const newColorCss = Color.toCss(color);
    (this.form.controls["shadow"] as FormGroup).controls["color"].setValue(newColorCss);
  }
}
