import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { Notification } from "../models/notification";

export type NotificationState = {
  unreadNotificationsCount: number;
  notifications: Notification[];
  notificationsPageCount: number;
};

@Injectable({
  providedIn: "root",
})
export class NotificationStore extends Store<NotificationState> {
  protected override getInitialState(): NotificationState {
    return {
      unreadNotificationsCount: 0,
      notifications: [],
      notificationsPageCount: 1,
    };
  }

  getUnreadNotificationsCountObservable() {
    return this.getFieldObservable("unreadNotificationsCount");
  }

  getUnreadNotificationsCount() {
    return this.getField("unreadNotificationsCount");
  }

  getNotificationsObservable() {
    return this.getFieldObservable("notifications");
  }

  getNotifications() {
    return this.getField("notifications");
  }

  getNotificationsPageCountObservable() {
    return this.getFieldObservable("notificationsPageCount");
  }

  setUnreadNotificationsCount(unreadNotificationsCount: number) {
    this.update((state) => ({ ...state, unreadNotificationsCount }));
  }

  setNotifications(notifications: Notification[]) {
    this.update((state) => ({ ...state, notifications }));
  }

  setNotificationsPageCount(count: number) {
    this.update((state) => ({ ...state, notificationsPageCount: count }));
  }

  addNotification(notification: Notification) {
    this.update((state) => {
      const storeNotification = state.notifications.find((n) => n.id === notification.id);
      if (storeNotification) {
        return state;
      }

      return {
        ...state,
        notifications: [notification].concat(state.notifications),
        unreadNotificationsCount: state.unreadNotificationsCount + 1,
      };
    });
  }

  addNotificationsToStart(notifications: Notification[]) {
    this.update((state) => {
      const newNotifications: Notification[] = [];
      for (const notification of notifications) {
        const storeNotification = state.notifications.find((ig) => ig.id === notification.id);
        if (storeNotification) {
          continue;
        }
        newNotifications.push(notification);
      }

      return {
        ...state,
        notifications: newNotifications.concat(state.notifications),
      };
    });
  }

  addNotificationsToEnd(notifications: Notification[]) {
    this.update((state) => {
      const newNotifications: Notification[] = [];
      for (const notification of notifications) {
        const storeNotification = state.notifications.find((ig) => ig.id === notification.id);
        if (storeNotification) {
          continue;
        }
        newNotifications.push(notification);
      }

      return {
        ...state,
        notifications: state.notifications.concat(newNotifications),
      };
    });
  }

  updateNotification(updatedNotification: Notification) {
    const notifications = this.getNotifications();
    const notification = notifications.find((n) => n.id === updatedNotification.id);

    if (notification) {
      this.update((state) => {
        const updatedNotifications = state.notifications.map((n) => {
          if (n.id === updatedNotification.id) {
            return updatedNotification;
          }
          return n;
        });

        return {
          ...state,
          notifications: updatedNotifications,
        };
      });
    } else {
      this.addNotification(updatedNotification);
    }
  }
}
