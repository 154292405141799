import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { Book } from "@metranpage/book-data";
import { UntilDestroy } from "@ngneat/until-destroy";
import { LayoutState } from "../layout-steps/layout-steps.component";

@UntilDestroy()
@Component({
  selector: "m-preview-sidebar-layout-steps",
  templateUrl: "./preview-sidebar-layout-steps.view.html",
  styleUrls: ["./preview-sidebar-layout-steps.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewSidebarLayoutStepsView {
  @Input()
  isPreviewsReady = false;
  @Input()
  isFinalsReady = false;
  @Input()
  layoutStep = "start";
  @Input()
  book?: Book;

  @Output()
  onNewBookClick = new EventEmitter();
  @Output()
  onDownloadProjectClick = new EventEmitter();
  @Output()
  onDownloadEpubClick = new EventEmitter();

  constructor(private readonly changeDetectionRef: ChangeDetectorRef) {}

  getLayoutState(): LayoutState {
    return {
      isPreviewsReady: this.isPreviewsReady,
      isFinalsReady: this.isFinalsReady,
      layoutStep: this.layoutStep,
    };
  }
}
