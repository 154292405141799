import { LangSpec } from "@metranpage/i18n";

export type Environment = {
  production: boolean;
  apiRootUrl: string;
  filesUrl: string;
  socketRootUrl: string;
  kvUrl: string;
};

export type SocialLocales = {
  lang: string;
  value: string;
};
export type Social = {
  icon: string;
  url: string;
  locales: SocialLocales[];
};

export type CompanyConfig = {
  companyUuid: string;
  deployedUrl: string;
  ga?: string;
  availableLanguages: LangSpec[];
  downloadFileTemplate: string;
  color: string;
  socials: Social[];
  replainId?: string;
  flags: CompanyFlags;
};

export type CompanyFlags = {
  canCreatePublicTemplates: boolean;
  isImagesGenerationAvailable: boolean;
  isCoverEditorEnabled: boolean;
  isCommunityAvailable: boolean;
  isPurchasesAvailable: boolean;
  isPublishInRussia: boolean;
  isEditionNoticeAvailable: boolean;
  isUserTemplateUploadAvailable: boolean;
  isUserRoleNotificationAvailable: boolean;
  isVkLoginAvailable: boolean;
  isGoogleLoginAvailable: boolean;
  showUpdatesNews: boolean;
  useSimpleSpinner: boolean;
  hideNavbarLogo: boolean;
  hideNavbarRightParts: boolean;
  forceWhiteTheme: boolean;
  defaultTheme: "dark" | "light" | undefined;
};

export const defaultFlags: CompanyFlags = {
  canCreatePublicTemplates: true,
  isImagesGenerationAvailable: true,
  isCoverEditorEnabled: true,
  isCommunityAvailable: true,
  isPurchasesAvailable: true,
  isPublishInRussia: true,
  isEditionNoticeAvailable: true,
  isUserTemplateUploadAvailable: true,
  isUserRoleNotificationAvailable: true,
  isVkLoginAvailable: true,
  isGoogleLoginAvailable: true,
  showUpdatesNews: true,
  useSimpleSpinner: false,
  hideNavbarLogo: false,
  hideNavbarRightParts: false,
  forceWhiteTheme: false,
  defaultTheme: undefined,
};

export const flagsForPublishers: CompanyFlags = {
  ...defaultFlags,
  canCreatePublicTemplates: false,
  isCommunityAvailable: false,
  isUserTemplateUploadAvailable: false,
  isUserRoleNotificationAvailable: false,
  isVkLoginAvailable: false,
  isGoogleLoginAvailable: false,
  showUpdatesNews: false,
  useSimpleSpinner: true,
};

export type Config = {
  environment: Environment;
  company: CompanyConfig;
};
