import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { ImageGeneration } from "../models/image-generation";

export type ImageGenerationState = {
  imageGenerations: ImageGeneration[];
  imageGenerationsPageCount: number;
};

@Injectable({
  providedIn: "root",
})
export class ImageGenerationStore extends Store<ImageGenerationState> {
  protected override getInitialState(): ImageGenerationState {
    return {
      imageGenerations: [],
      imageGenerationsPageCount: 1,
    };
  }

  getImageGenerationsObservable() {
    return this.getFieldObservable("imageGenerations");
  }

  getImageGenerations() {
    return this.getField("imageGenerations");
  }

  getImageGenerationsPageCountObservable() {
    return this.getFieldObservable("imageGenerationsPageCount");
  }

  setImageGenerations(imageGenerations: ImageGeneration[]) {
    this.update((state) => ({ ...state, imageGenerations }));
  }

  setImageGenerationsPageCount(count: number) {
    this.update((state) => ({ ...state, imageGenerationsPageCount: count }));
  }

  addImageGeneration(imageGeneration: ImageGeneration) {
    this.update((state) => {
      const storeImageGeneration = state.imageGenerations.find((ig) => ig.id === imageGeneration.id);
      if (storeImageGeneration) {
        return state;
      }

      return {
        ...state,
        imageGenerations: [imageGeneration].concat(state.imageGenerations),
      };
    });
  }

  addImageGenerationsToStart(imageGenerations: ImageGeneration[]) {
    this.update((state) => {
      const newImageGenerations: ImageGeneration[] = [];
      for (const imageGeneration of imageGenerations) {
        const storeImageGeneration = state.imageGenerations.find((ig) => ig.id === imageGeneration.id);
        if (storeImageGeneration) {
          continue;
        }
        newImageGenerations.push(imageGeneration);
      }

      return {
        ...state,
        imageGenerations: newImageGenerations.concat(state.imageGenerations),
      };
    });
  }

  addImageGenerationsToEnd(imageGenerations: ImageGeneration[]) {
    this.update((state) => {
      const newImageGenerations: ImageGeneration[] = [];
      for (const imageGeneration of imageGenerations) {
        const storeImageGeneration = state.imageGenerations.find((ig) => ig.id === imageGeneration.id);
        if (storeImageGeneration) {
          continue;
        }
        newImageGenerations.push(imageGeneration);
      }

      return {
        ...state,
        imageGenerations: state.imageGenerations.concat(newImageGenerations),
      };
    });
  }

  updateImageGeneration(updatedImageGeneration: ImageGeneration) {
    const imageGenerations = this.getImageGenerations();
    const imageGeneration = imageGenerations.find((g) => g.id === updatedImageGeneration.id);

    if (imageGeneration) {
      this.update((state) => {
        const updatedImageGenerations = state.imageGenerations.map((g) => {
          if (g.id === updatedImageGeneration.id) {
            return updatedImageGeneration;
          }
          return g;
        });

        return {
          ...state,
          imageGenerations: updatedImageGenerations,
        };
      });
    } else {
      this.addImageGeneration(updatedImageGeneration);
    }
  }
}
