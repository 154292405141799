import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import {
  CoverSnippetAccess,
  CoverSnippetAccessUpdateData,
  CoverSnippetsAccessData,
} from "../../models/cover-snippet/cover-snippet-access";

export type CoverSnippetsAccessFilter = {};

@Injectable({
  providedIn: "root",
})
export class AdminCoverSnippetsAccessApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  getCoverSnippetsAccess() {
    return this.get<CoverSnippetAccess[]>("admin/cover-snippets-access");
  }

  getCoverSnippetsAccessPaginated(page: number, filters: CoverSnippetsAccessFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<CoverSnippetsAccessData>("admin/cover-snippets-access/paginated", {
      page,
      ...clearedFilters,
    });
  }

  async updateCoverSnippetAccess(id: number, data: CoverSnippetAccessUpdateData) {
    return this.post<unknown>(`admin/cover-snippets-access/${id}`, data);
  }
}
