<m-ng-island [paddingSize]="0">
  <div class="container">
    <div class="header">
      <div class="header-group">
        <div i18n="@@user.profile.rewards-list.title">user.profile.rewards-list.title</div>
      </div>
    </div>

    <div class="divider-hor"></div>

    <m-ng-scrollbars [options]="{overflow: {x: 'hidden'}}">
      <div class="rewards-list">
        <div *ngIf="rewardsDaily.length > 0" class="daily-tasks">
          <div class="task" *ngFor="let r of rewardsDaily; let isLast = last;">
            <ng-container [ngSwitch]="getRewardDailyType(r)">
              <m-reward-task-daily-view *ngSwitchCase="'likes-give'" [user]="user" [userRewardDaily]="r" />
            </ng-container>
            <ng-container [ngSwitch]="getRewardDailyType(r)">
              <m-reward-task-daily-view *ngSwitchCase="'likes-take'" [user]="user" [userRewardDaily]="r" />
            </ng-container>
            <div *ngIf="!isLast" class="divider-hor"></div>
          </div>
        </div>

        <div *ngIf="rewardsDaily.length > 0" class="divider-hor"></div>

        <div class="onetime-tasks">
          <div class="task" *ngFor="let r of rewardsOneTime; let isLast = last;">
            <ng-container [ngSwitch]="getRewardOneTimeType(r)">
              <m-reward-task-connect-telegram-view
                *ngSwitchCase="'subscribe-to-telegram-channel'"
                [user]="user"
                [userRewardOneTime]="r"
              />
            </ng-container>
            <div *ngIf="!isLast" class="divider-hor"></div>
          </div>
        </div>
      </div>
    </m-ng-scrollbars>
  </div>
</m-ng-island>
