<m-ng-island [paddingSize]="24">
  <div class="new-promocode">
    <m-button button-style="primary" (onClick)="onAddPromocode()" i18n="@@admin.promocodes.add-promocode">
      admin.promocodes.add-promocode
    </m-button>
  </div>

  <form [formGroup]="searchForm">
    <table>
      <thead>
        <th>ID</th>
        <th>
          Token
          <m-ng-input formControlName="token" size="32" />
        </th>
        <th>Status</th>
        <th>Created Date</th>
        <th>Expire Date</th>
        <th>Credits Count</th>
        <th>Gold Credits Count</th>
        <th>Tariff</th>
        <th>Subscription Period</th>
        <th>Discont For Subscription</th>
        <th>Used Count</th>
        <th></th>
      </thead>

      <tbody>
        <tr *ngFor="let promocode of promocodes; trackBy: trackByPromocodeId">
          <td>{{promocode.id}}</td>
          <td>{{promocode.token}}</td>
          <td><div class="status" [ngClass]="promocode.status"></div></td>
          <td>{{promocode.createdDate}}</td>
          <td>{{promocode.expireDate}}</td>
          <td>{{promocode.creditsCount}}</td>
          <td>{{promocode.goldCreditsCount}}</td>
          <td>{{getTariffTitle(promocode.tariffId)}}</td>
          <td>{{promocode.subscriptionPeriod}}</td>
          <td>{{promocode.discontForSubscription}}</td>
          <td>{{promocode.usedCount}}</td>
          <td>
            <div class="controls">
              <m-icon
                class="settings-icon"
                src="gear.svg"
                size="s"
                color="--color-font-main"
                (click)="onAddPromocode(promocode.id)"
              />
              <m-icon
                class="delete-icon"
                src="block-delete.svg"
                size="s"
                color="--color-error"
                (click)="showPromocodeDeleteModal(promocode)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </form>

  <m-admin-pagination
    [pageCount]="pageCount"
    [currentPage]="page"
    (onPageChanged)="onPageChanged($event)"
  ></m-admin-pagination>

  <m-admin-add-promocode-modal
    *ngIf="isAddPromocodeModalVisible"
    [tariffs]="tariffs"
    [promocode]="promocode"
    (onPromocodeSaved)="onPromocodeSaved($event)"
    (onCancelAddition)="onCloseAddPromocodeModal()"
  ></m-admin-add-promocode-modal>

  <m-modal *ngIf="isDeletePromocodeModalVisible" position="center" (close)="onCloseDeletePromocodeModal()">
    <div class="delete-promocode-modal">
      <span>
        <span i18n="@@admin.promocodes.promocode.delete-promocode-confirmation">
          admin.promocodes.promocode.delete-promocode-confirmation
        </span>

        <span *ngIf="promocodeForDelete" class="promocode-name">
          «
          <span class="promocode-name-accent">{{promocodeForDelete.token}}</span>
          »
        </span>
        ?
      </span>

      <div class="buttons">
        <m-button
          button-style="secondary"
          size="full-width"
          (onClick)="onCloseDeletePromocodeModal()"
          i18n="@@admin.common.cancel"
        >
          admin.common.cancel
        </m-button>
        <m-button button-style="primary" (onClick)="onDeletePromocode()" i18n="@@admin.common.delete">
          admin.common.delete
        </m-button>
      </div>
    </div>
  </m-modal>
</m-ng-island>
