import { Inject, Injectable } from "@angular/core";
import IntlMessageFormat from "intl-messageformat";
import { LangSpec } from "./lang-spec";
import { AVAILABLE_LANGUAGES } from "./tokens";

const LocalStorageKey = "m-locale";

@Injectable({
  providedIn: "root",
})
export class I18nService {
  constructor(@Inject(AVAILABLE_LANGUAGES) private readonly availableLanguages: LangSpec[]) {}

  getSavedLocale() {
    return (
      localStorage.getItem(LocalStorageKey) ||
      localStorage.getItem("mpg_locale") || // old key
      localStorage.getItem("metranpage-locale")
    );
  }

  saveLocale(locale: string) {
    if (locale) {
      localStorage.setItem(LocalStorageKey, locale);
    }
  }

  getBrowserLocale(): string {
    const preferred = navigator.languages;
    if (preferred && preferred.length > 0) {
      if (preferred[0] === "ru" || preferred[0].split("-")[0] === "ru") {
        return "ru";
      }
    }
    return "en";
  }

  getLocale() {
    const userLocale = this.getSavedLocale();

    if (userLocale) {
      return this.getLocaleOrDefault(userLocale);
    } else {
      const browserLocale = this.getBrowserLocale();
      return this.getLocaleOrDefault(browserLocale);
    }
  }

  private getLocaleOrDefault(locale: string): string {
    const language = this.availableLanguages.find((lang) => lang.value === locale);
    if (language) {
      return language.value;
    }
    return this.availableLanguages[0].value;
  }

  /**
   *
   * @param template
   * @param value {books: 3}
   * @returns
   */
  pluralize(template: string, value: Record<string, number>): string {
    return new IntlMessageFormat(template, this.getLocale()).format(value) as string;
  }

  toggleBrowserLocale() {
    let newLocale = "";
    if (this.getLocale() === "ru") {
      newLocale = "en";
    } else if (this.getLocale() === "en") {
      newLocale = "ru";
    }
    this.saveLocale(newLocale);
  }
}
