<m-modal
  [closeButtonVisible]="closeButtonVisible"
  [closeOnBackDropClick]="closeOnBackDropClick"
  (close)="onBackDropClick()"
>
  <div class="container">
    <div class="content">
      <span>{{text}}</span>
    </div>

    <div class="buttons">
      <m-button button-style="secondary" size="full-width" (onClick)="onCloseClick()">{{cancelText}}</m-button>
      <m-button button-style="primary" (onClick)="onConfirmClick()">{{confirmText}}</m-button>
    </div>
  </div>
</m-modal>
