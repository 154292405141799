<m-ng-island [paddingSize]="24">
  <div class="section main">
    <overlay-scrollbars>
      <div class="templates-container" *ngIf="data$ | async as data">
        <div class="template-container">
          <div class="image-wrapper">
            <div class="no-image">
              <m-ng-icon src="no-style.svg" [size]="40" />
            </div>
          </div>
          <div class="button-container">
            <m-button
              button-style="primary"
              size="full-width"
              icon-position="left"
              icon="mark-02.svg"
              class="apply"
              (click)="setDefault(null)"
              i18n="@@admin.cover-templates.button.setDefault"
            >
              admin.cover-templates.button.setDefault
            </m-button>
          </div>
          <div *ngIf="true" class="active-selector">
            <m-ng-icon src="checkmark-01.svg" [size]="16" />
          </div>
        </div>
        <div class="template-container" *ngFor="let template of data.templates">
          <div class="image-wrapper">
            <m-skeleton-image
              [url]="template.previewUrl"
              backgroudColor="var(--surface-on-island)"
              animationStyle="gradient"
            />
          </div>
          <div class="button-container">
            <m-button
              button-style="primary"
              size="full-width"
              icon-position="left"
              icon="mark-02.svg"
              class="apply"
              (click)="setDefault(template)"
              i18n="@@admin.cover-templates.button.setDefault"
            >
              admin.cover-templates.button.setDefault
            </m-button>
          </div>
          <div *ngIf="false" class="active-selector">
            <m-ng-icon src="checkmark-01.svg" [size]="16" />
          </div>
        </div>
      </div>
    </overlay-scrollbars>
  </div>
</m-ng-island>
