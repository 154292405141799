<div class="wrapper" [formGroup]="form">
  <div>
    <div class="caption" i18n="@@cover-editor.position">cover-editor.position</div>
  </div>
  <div class="row">
    <m-ng-input-number-with-icon
      iconLeft="/assets/icons/cover/axis-x.svg"
      formControlName="x"
      size="40"
      [iconSize]="28"
      text-align="right"
    />
    <m-ng-input-number-with-icon
      iconLeft="/assets/icons/cover/axis-y.svg"
      formControlName="y"
      size="40"
      [iconSize]="28"
      text-align="right"
    />
  </div>
  <div class="row">
    <m-ng-input-number-with-icon
      iconLeft="/assets/icons/cover/rotation.svg"
      formControlName="rotationAngle"
      size="40"
      [iconSize]="28"
      text-align="right"
    >
      <div class="input-unit">&deg;</div>
    </m-ng-input-number-with-icon>
    <m-ng-input-number-with-icon
      iconLeft="/assets/icons/unzoom.svg"
      formControlName="scale"
      size="40"
      [iconSize]="28"
      text-align="right"
    >
      <div class="input-unit">&#37;</div>
    </m-ng-input-number-with-icon>
  </div>
  <div class="border"></div>
</div>
