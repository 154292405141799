import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NewMarkComponent } from "../new-mark/new-mark.component";
import { SelectValue } from "../select/select.component";

@Component({
  selector: "m-filter-switch",
  templateUrl: "./filter-switch.component.html",
  styleUrls: ["./filter-switch.component.scss"],
  standalone: true,
  imports: [CommonModule, NewMarkComponent],
})
export class FilterSwitchComponent {
  @Input()
  options: SelectValue[] = [];
  @Input()
  selectedOptionId?: number | string;

  @Output()
  onSelect = new EventEmitter<number | string>();

  onSelectClick(option: SelectValue) {
    if (this.selectedOptionId === option.id) {
      return;
    }
    this.selectedOptionId = option.id;
    this.onSelect.emit(option.id);
  }
}
