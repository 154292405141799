<div class="book-view-pages">
  <m-preview-frame class="page-left" [page-size]="getPageSize()" (onPagePreviewChange)="onPagePreviewChange($event)">
    <div class="page left">
      <div [ngClass]="getPageCssClassList('left')" [ngStyle]="getPageStyle('left')">
        <ng-container *ngTemplateOutlet="marginsPage; context: { page: 'left' }"></ng-container>
        <ng-container *ngTemplateOutlet="marginsPreview; context: { page: 'left' }"></ng-container>
      </div>
    </div>
  </m-preview-frame>

  <m-preview-frame class="page-right" [page-size]="getPageSize()">
    <div class="page right">
      <div [ngClass]="getPageCssClassList('right')" [ngStyle]="getPageStyle('right')">
        <ng-container *ngTemplateOutlet="marginsPage; context: { page: 'right' }"></ng-container>
        <ng-container *ngTemplateOutlet="marginsPreview; context: { page: 'right' }"></ng-container>
      </div>
    </div>
  </m-preview-frame>
</div>

<div class="magins-borders-settings" (click)="toggleMarginsBorders()">
  <div *ngIf="!isMarginsBordersHide">
    <m-icon src="eye-close.svg" size="m" color="--color-font-main" />
    <span class="label-magins-borders" i18n="@@books.running-titles.hide-margins-borders">
      books.running-titles.hide-margins-borders
    </span>
  </div>
  <div *ngIf="isMarginsBordersHide">
    <m-icon src="eye.svg" size="m" color="--color-font-main" />
    <span class="label-magins-borders" i18n="@@books.running-titles.show-margins-borders">
      books.running-titles.show-margins-borders
    </span>
  </div>
</div>

<ng-template #marginsPage let-page="page">
  <div [ngClass]="getMarginCssClassList(page, 'marginTop')" [ngStyle]="getMarginStyle(page, 'marginTop')">
    <div class="active-zone">
      <m-running-titles-styles
        *ngIf="isRunningTitleVisible(0)"
        class="preview-running-title"
        [style]="getRunningTitleStyle()"
        [context]="getRunningTitleText(page, 0)"
        [page-position]="page"
        [font-size]="fontSizeInPixeles"
        [palette]="palette"
        position="marginTop"
      ></m-running-titles-styles>
      <m-page-numbers-styles
        *ngIf="isPageNumberVisible(0)"
        class="preview-running-title"
        [style]="getPageNumberStyle()"
        [context]="getRunningTitleText(page, 0)"
        [page-position]="page"
        [font-size]="fontSizeInPixeles"
        [palette]="palette"
        position="marginTop"
      ></m-page-numbers-styles>
    </div>
  </div>
  <div [ngClass]="getMarginCssClassList(page, 'marginBottom')" [ngStyle]="getMarginStyle(page, 'marginBottom')">
    <div class="active-zone" [ngStyle]="getMarginAZStyle(page, 'marginBottom')">
      <m-running-titles-styles
        *ngIf="isRunningTitleVisible(2)"
        class="preview-running-title"
        [style]="getRunningTitleStyle()"
        [context]="getRunningTitleText(page, 2)"
        [page-position]="page"
        [font-size]="fontSizeInPixeles"
        [palette]="palette"
        position="marginBottom"
      ></m-running-titles-styles>
      <m-page-numbers-styles
        *ngIf="isPageNumberVisible(2)"
        class="preview-running-title"
        [style]="getPageNumberStyle()"
        [context]="getRunningTitleText(page, 2)"
        [page-position]="page"
        [font-size]="fontSizeInPixeles"
        [palette]="palette"
        position="marginBottom"
      ></m-page-numbers-styles>
    </div>
  </div>
  <div [ngClass]="getMarginCssClassList(page, 'marginOuter')" [ngStyle]="getMarginStyle(page, 'marginOuter')">
    <div class="active-zone">
      <m-running-titles-styles
        *ngIf="isRunningTitleVisible(1)"
        class="preview-running-title"
        [style]="getRunningTitleStyle()"
        [context]="getRunningTitleText(page, 1)"
        [page-position]="page"
        [font-size]="fontSizeInPixeles"
        [palette]="palette"
        position="marginOuter"
      ></m-running-titles-styles>
      <m-page-numbers-styles
        *ngIf="isPageNumberVisible(1)"
        class="preview-running-title"
        [style]="getPageNumberStyle()"
        [context]="getRunningTitleText(page, 1)"
        [page-position]="page"
        [font-size]="fontSizeInPixeles"
        [palette]="palette"
        position="marginOuter"
      ></m-page-numbers-styles>
    </div>
  </div>
  <div *ngIf="isWidePaddingEnabled()" [ngClass]="getMarginCssClassList(page, 'gutter')"></div>
  <div [ngClass]="getMarginCssClassList(page, 'marginInner')"></div>

  <div [ngClass]="getMarginsDeadCssClassList(page, 1)" [ngStyle]="getMarginsDeadStyle(page, 1)">
    <div [ngClass]="getMarginsDeadAZCssClassList(page)"></div>
  </div>
  <div [ngClass]="getMarginsDeadCssClassList(page, 2)" [ngStyle]="getMarginsDeadStyle(page, 2)">
    <div [ngClass]="getMarginsDeadAZCssClassList(page)"></div>
  </div>
  <div [ngClass]="getMarginsDeadCssClassList(page, 3)" [ngStyle]="getMarginsDeadStyle(page, 3)">
    <div [ngClass]="getMarginsDeadAZCssClassList(page)"></div>
  </div>
  <div [ngClass]="getMarginsDeadCssClassList(page, 4)" [ngStyle]="getMarginsDeadStyle(page, 4)">
    <div [ngClass]="getMarginsDeadAZCssClassList(page)"></div>
  </div>
</ng-template>

<ng-template #marginsPreview let-page="page">
  <div class="text-padding" [ngStyle]="getStyleTextPadding()">
    <div class="text-preview"></div>
    <div *ngIf="isColumnsEnable()" class="text-preview"></div>
  </div>
  <div *ngIf="isWidePaddingEnabled()" [ngClass]="getMarginCssClassList(page, 'widePadding')">
    <div class="preview-image"></div>
    <div class="preview-caption"></div>
  </div>
</ng-template>
