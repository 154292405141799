import { Component } from "@angular/core";

@Component({
  selector: "m-account-tariff-and-payments-host-page",
  template: `
    <m-ng-island [paddingSize]="0">
      <m-account-tariff-and-payments-nav-view />

      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </m-ng-island>
  `,
  styleUrls: ["./account-tariff-and-payments-host.page.scss"],
})
export class AccountTariffAndPaymentsHostPage {}
