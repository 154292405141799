<div *ngIf="isRunningTitleVisible()" class="container">
  <div [ngClass]="getRunningTitleCssClassList()">
    <div>
      <ng-container *ngTemplateOutlet="decorator"></ng-container>
      <span>&nbsp;{{ getRunningTitleText() }}&nbsp;</span>
      <ng-container *ngTemplateOutlet="decorator"></ng-container>
    </div>
  </div>
</div>

<ng-template #decorator>
  <span class="decorator">—</span>
</ng-template>
