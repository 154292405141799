import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import {
  CompanyCloudPaymentsSettings,
  CompanyPaymentsSettings,
  CompanyS3Settings,
  CompanyStripeSettings,
} from "../../models/company";
import { CompanyUnisenderCreds } from "../../models/unisender-settings";
import { AdminCompanyCredsSettingsApi } from "./companies-creds.api";
import { AdminCompanyCredsSettingsStore } from "./companies-creds.store";

export type UpdateCompanySettingsResult = {
  status: GeneralResultStatus;
  error?: unknown;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyCredsService {
  constructor(
    private readonly api: AdminCompanyCredsSettingsApi,
    private readonly store: AdminCompanyCredsSettingsStore,
  ) {}

  async refreshS3Settings() {
    const settings = await this.api.getS3Settings();
    this.store.setS3Settings(settings);
  }

  async updateS3Settings(data: CompanyS3Settings): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.updateS3Settings(data);
      this.store.setS3Settings(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }

  async deleteS3Settings(): Promise<{ status: GeneralResultStatus }> {
    try {
      await this.api.deleteS3Settings();
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
      };
    }
  }

  async refreshPaymentsSettings() {
    const settings = await this.api.getPaymentsSettings();
    this.store.setPaymentsSettings(settings);
  }

  async updatePaymentsSettings(data: CompanyPaymentsSettings): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.updatePaymentsSettings(data);
      this.store.setPaymentsSettings(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }

  // async refreshCloudPaymentsSettings() {
  //   const settings = await this.api.getCloudPaymentsSettings();
  //   this.store.setCloudPaymentsSettings(settings);
  // }

  // async updateCloudPaymentsSettings(data: CompanyCloudPaymentsSettings): Promise<UpdateCompanySettingsResult> {
  //   try {
  //     const settings = await this.api.updateCloudPaymentsSettings(data);
  //     this.store.setCloudPaymentsSettings(settings);
  //     return {
  //       status: "success",
  //     };
  //   } catch (error: unknown) {
  //     return {
  //       status: "error",
  //       error: error,
  //     };
  //   }
  // }

  async deleteCloudPaymentsSettings(): Promise<{ status: GeneralResultStatus }> {
    try {
      await this.api.deleteCloudPaymentsSettings();
      await this.refreshPaymentsSettings();
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
      };
    }
  }

  // async refreshStripeSettings() {
  //   const settings = await this.api.getStripeSettings();
  //   this.store.setStripeSettings(settings);
  // }

  // async updateStripeSettings(data: CompanyStripeSettings): Promise<UpdateCompanySettingsResult> {
  //   try {
  //     const settings = await this.api.updateStripeSettings(data);
  //     this.store.setStripeSettings(settings);
  //     return {
  //       status: "success",
  //     };
  //   } catch (error: unknown) {
  //     return {
  //       status: "error",
  //       error: error,
  //     };
  //   }
  // }

  async deleteStripeSettings(): Promise<{ status: GeneralResultStatus }> {
    try {
      await this.api.deleteStripeSettings();
      await this.refreshPaymentsSettings();
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
      };
    }
  }

  async refreshUnisenderCreds() {
    const settings = await this.api.getUnisenderCreds();
    this.store.setUnisenderCreds(settings);
  }

  async updateUnisenderCreds(data: CompanyUnisenderCreds): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.updateUnisenderCreds(data);
      this.store.setUnisenderCreds(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }

  async deleteUnisenderCreds(): Promise<{ status: GeneralResultStatus }> {
    try {
      await this.api.deleteUnisenderCreds();
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
      };
    }
  }
}
