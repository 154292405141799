import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SelectValue } from "@metranpage/components";
import { ConnectTelegramService } from "@metranpage/core";
import { User } from "@metranpage/user-data";
import { Subscription } from "rxjs";

export type AccountMainData = {
  firstName: string;
  lastName: string;
  role: number;
  avatarFile?: File;
};

const reNonEmptyString = /^(?!\s*$).+/;

@Component({
  selector: "m-account-main-view",
  templateUrl: "./account-main.view.html",
  styleUrls: ["./account-main.view.scss"],
})
export class AccountMainView implements OnInit, OnDestroy {
  @Input()
  user?: User;
  @Input()
  savedStatus = false;
  @Input()
  roleOptions: SelectValue[] = [];
  @Input()
  avatarUrl?: string;
  @Input()
  isLogoutButtonAvailable = true;

  @Output()
  onSaveClick = new EventEmitter<AccountMainData>();
  @Output()
  onDeleteAccountClick = new EventEmitter<void>();
  @Output()
  onConnectTelegram = new EventEmitter<void>();
  @Output()
  onDisconnectTelegram = new EventEmitter<void>();

  private sub = new Subscription();

  protected initialValue?: AccountMainData;
  protected hasChange = false;
  protected isDeleteAccountModalVisible = false;
  protected isDeleteAccountButtonActive = false;
  protected isDeleteAccountConfirmPhrase = $localize`:@@user.profile.main.delete-account-confirmation-phrase:`;

  protected isDisconnectTelegramModalVisible = false;

  protected fileToUpload?: File;

  protected form = new FormGroup({
    firstName: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required, Validators.pattern(reNonEmptyString)],
    }),
    lastName: new FormControl("", { nonNullable: true, validators: [] }),
    role: new FormControl(-1, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  protected formDeleteConfirmation = new FormGroup({
    confirmText: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  constructor(private readonly connectTelegramService: ConnectTelegramService) {}

  async ngOnInit(): Promise<void> {
    this.initForm();
    this.watchFormChanges();
    this.watchFormDeleteConfirmationChanges();

    await this.connectTelegramService.createToken();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.savedStatus) {
      this.savedStatus = changes.savedStatus.currentValue;
      this.hasChange = false;

      this.initialValue = this.form.getRawValue();
    }
  }

  initForm() {
    this.initialValue = {
      firstName: this.user!.firstName,
      lastName: this.user!.lastName ?? "",
      role: this.user!.role,
    };
    this.form.setValue(this.initialValue);
  }

  watchFormChanges() {
    this.sub.add(
      this.form.valueChanges.subscribe((value) => {
        if (!this.initialValue) {
          return;
        }
        this.hasChange = Object.entries(this.initialValue).some(([key, value]) => value !== this.form.get(key)?.value);
      }),
    );
  }

  watchFormDeleteConfirmationChanges() {
    this.sub.add(
      this.formDeleteConfirmation.get("confirmText")?.valueChanges.subscribe((value) => {
        this.isDeleteAccountButtonActive = value === this.isDeleteAccountConfirmPhrase;
      }),
    );
  }

  protected onSaveButtonClick() {
    const data: AccountMainData = {
      ...this.form.getRawValue(),
      avatarFile: this.fileToUpload,
    };
    this.onSaveClick.emit(data);
  }

  protected onDeleteAccount() {
    this.onDeleteAccountClick.emit();
  }

  protected onShowDeleteAccountModal() {
    this.isDeleteAccountModalVisible = true;
  }

  protected onCloseDeleteAccountModal() {
    this.isDeleteAccountModalVisible = false;
  }

  protected onFileSelected(event: any) {
    this.fileToUpload = event.target.files[0];

    if (!this.fileToUpload) {
      return;
    }

    this.hasChange = true;

    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.avatarUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  protected isTelegramConnectionAvailable() {
    return this.connectTelegramService.isTelegramConnectionAvailable();
  }

  protected onConnectTelegramClick() {
    this.onConnectTelegram.emit();
  }

  protected onDisconnectTelegramClick() {
    this.hideDisconnectTelegramModal();
    this.onDisconnectTelegram.emit();
  }

  protected showDisconnectTelegramModal() {
    this.isDisconnectTelegramModalVisible = true;
  }

  protected hideDisconnectTelegramModal() {
    this.isDisconnectTelegramModalVisible = false;
  }
}
