import { Component, Inject, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AnalyticsService } from "@metranpage/core";
import { User, UserRewardOneTime } from "@metranpage/user-data";
import { ConnectTelegramServiceInterface } from "@metranpage/user-interfaces";

@Component({
  selector: "m-reward-task-connect-telegram-2-view",
  templateUrl: "reward-task-connect-telegram.view.html",
  styleUrls: ["reward-task-connect-telegram.view.scss"],
})
export class RewardTaskConnectTelegram2View {
  @Input()
  user?: User;
  @Input()
  userRewardOneTime?: UserRewardOneTime;

  constructor(
    @Inject("ConnectTelegramServiceInterface")
    private readonly connectTelegramService: ConnectTelegramServiceInterface,
    private readonly router: Router,
    private readonly analytics: AnalyticsService,
  ) {}

  protected onSubscribeToChannelTelegramClick() {
    this.connectTelegramService.subscribeToChannel();

    this.analytics.event("promo-telegram-channel");
  }

  protected async onNavigateToProfileClick() {
    this.router.navigate(["account"]);

    this.analytics.event("promo-connect-telegram");
  }

  protected async onCheckSubscribeToChannelTelegramClick() {
    this.connectTelegramService.checkSubscribeToChannel();
  }

  protected canCheckSubscriptionToChannel() {
    return !!this.user?.telegramProfile && !this.user?.telegramProfile?.isChannelConnected;
  }

  protected isTaskConnectTelegramProfileCompleted() {
    return !!this.user?.telegramProfile;
  }

  protected isTaskSubscribeToTelegramChannelCompleted() {
    return !!this.user?.telegramProfile?.isChannelConnected;
  }
}
