<svg-icon id="selector-bg" src="/assets/images/font-size-selector-bg.svg"></svg-icon>
<canvas
  #canvas
  id="selector-canvas"
  (mousedown)="onMouseDown($event)"
  (mouseup)="onMouseUp($event)"
  (mouseenter)="onMouseEnter($event)"
  (mouseleave)="onMouseLeave($event)"
  i18n="@@canvas.error"
>
  canvas.error
</canvas>
