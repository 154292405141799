import { ChangeDetectorRef, Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { Observable, Subject, takeUntil } from "rxjs";
import { CompanyS3Settings } from "../../models/company";
import { AdminCompanyCredsService } from "../../services/creds/companies-creds.service";
import { AdminCompanyCredsSettingsStore } from "../../services/creds/companies-creds.store";

@Component({
  selector: "m-admin-company-settings-s3-settings-page",
  templateUrl: "./s3-settings.page.html",
  styleUrls: ["./s3-settings.page.scss"],
})
export class AdminCompanyS3SettingsPage {
  private destroyed$ = new Subject<void>();
  protected settings$: Observable<CompanyS3Settings | undefined> | undefined;

  readonly form: FormGroup;

  constructor(
    private readonly service: AdminCompanyCredsService,
    private readonly store: AdminCompanyCredsSettingsStore,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.form = new FormBuilder().group({
      endpoint: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
      region: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
      bucket: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
      accessKey: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
      secretKey: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
    });
  }

  async ngAfterViewInit() {
    await this.service.refreshS3Settings();
    this.settings$ = this.store.getS3SettingsObservable();
    this.settings$!.pipe(takeUntil(this.destroyed$)).subscribe((settings) => {
      this.form.setValue({
        endpoint: settings?.endpoint,
        region: settings?.region,
        bucket: settings?.bucket,
        accessKey: settings?.accessKey,
        secretKey: settings?.secretKey,
      });
    });
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.updateS3Settings(this.form.value as CompanyS3Settings);
    this.loadingService.stopLoading();

    if (result.status === "success") {
      // do nothing
    } else {
      // this.error.emit(result.error);
      this.notificationService.error("Cannot set settings");
      console.error("Cannot set settings");
    }
  }

  async onDelete() {
    const result = await this.service.deleteS3Settings();
    if (result.status === "success") {
      // do nothing, as we already subscribed
    } else {
      this.notificationService.error("Cannot delete settings");
      console.error("Cannot delete settings");
    }
    this.cdr.markForCheck();
  }
}
