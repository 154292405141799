import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { RewardsDataDto } from "../models/rewards/rewards";

@Injectable({
  providedIn: "root",
})
export class RewardsApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async loadRewards() {
    return this.get<RewardsDataDto>("rewards");
  }
}
