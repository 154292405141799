<div class="sidebar-content">
  <m-ng-scrollbars class="scrollbars-wrapper">
    <div class="wrapper">
      <!-- <div class="info-block-wrapper">
        <div class="info-block">
          <m-localized-text
            class="title"
            i18n-text="@@cover-editor.conceptual-assistant.info-block.step-1.title"
            text="cover-editor.conceptual-assistant.info-block.step-1.title"
          />

          <m-localized-text
            class="description"
            i18n-text="@@cover-editor.conceptual-assistant.info-block.step-1.description"
            text="cover-editor.conceptual-assistant.info-block.step-1.description"
          />

          <img src="/assets/img/bot_2.png" />
        </div>
      </div> -->

      <div class="section-wrapper">
        <div class="section-result-group">
          <div class="title" i18n="@@cover-editor.conceptual-assistant.result.theme.title">
            cover-editor.conceptual-assistant.result.theme.title
          </div>

          <span class="text">{{ coverConceptualGeneration.theme }}</span>
        </div>

        <div class="section-result-group">
          <div class="title" i18n="@@cover-editor.conceptual-assistant.result.genre.title">
            cover-editor.conceptual-assistant.result.genre.title
          </div>

          <span class="text">{{ coverConceptualGeneration.genre }}</span>
        </div>

        <div class="section-result-group">
          <div class="title" i18n="@@cover-editor.conceptual-assistant.result.target-audience.title">
            cover-editor.conceptual-assistant.result.target-audience.title
          </div>

          <span class="text">{{ coverConceptualGeneration.au }}</span>
        </div>

        <!-- <div class="section-result-group">
          <div class="title" i18n="@@cover-editor.conceptual-assistant.result.summary.title">
            cover-editor.conceptual-assistant.result.summary.title
          </div>

          <span class="text">{{ coverConceptualGeneration.summary }}</span>
        </div> -->
      </div>

      <div class="divider-hor"></div>

      <div class="section-wrapper">
        <div class="section-island">
          <div class="title">
            <span i18n="@@cover-editor.conceptual-assistant.result.prompt.title">
              cover-editor.conceptual-assistant.result.prompt.title
            </span>

            <div class="refresh-button" (click)="onRefreshClick()">
              <m-ng-icon src="/assets/icons/refresh-10.svg" [size]="12" />
              <span i18n="@@cover-editor.conceptual-assistant.result.refresh-button">
                cover-editor.conceptual-assistant.result.refresh-button
              </span>
            </div>
          </div>

          <div class="prompts">
            <m-radio
              *ngFor="let prompt of coverConceptualGeneration.prompts; let i = index"
              [value]="i"
              [isChecked]="selectedPromptIndex === i"
              (onSelect)="onSelectPrompt($event)"
            >
              <span class="prompt-text">{{ prompt }}</span>
            </m-radio>
          </div>
        </div>

        <div class="section-island">
          <div class="title" i18n="@@cover-editor.conceptual-assistant.result.prompt.image-style.title">
            cover-editor.conceptual-assistant.result.prompt.image-style.title
          </div>

          <div class="styles-wrapper">
            <m-cover-conceptual-assistant-generation-result-style-card
              *ngIf="picStyle1"
              class="style-card"
              [style]="picStyle1"
              [isActive]="isStyleActive(picStyle1Id, picStyle1Mode)"
              (click)="onSelectStyleClick(picStyle1Id, picStyle1Mode, picStyle1Comment, picStyle1Mood, picStyle1Age, picStyle1ColorBackground, picStyle1ColorBackgroundHex, picStyle1ColorAccent, picStyle1ColorAccentHex)"
            />

            <m-cover-conceptual-assistant-generation-result-style-card
              *ngIf="picStyle2"
              class="style-card"
              [style]="picStyle2"
              [isActive]="isStyleActive(picStyle2Id, picStyle2Mode)"
              (click)="onSelectStyleClick(picStyle2Id, picStyle2Mode, picStyle2Comment, picStyle2Mood, picStyle2Age, picStyle2ColorBackground, picStyle2ColorBackgroundHex, picStyle2ColorAccent, picStyle2ColorAccentHex)"
            />

            <m-cover-conceptual-assistant-generation-result-style-card
              *ngIf="picStyle3"
              class="style-card"
              [style]="picStyle3"
              [isActive]="isStyleActive(picStyle3Id, picStyle3Mode)"
              (click)="onSelectStyleClick(picStyle3Id, picStyle3Mode, picStyle3Comment, picStyle3Mood, picStyle3Age, picStyle3ColorBackground, picStyle3ColorBackgroundHex, picStyle3ColorAccent, picStyle3ColorAccentHex)"
            />
          </div>

          <div *ngIf="selectedStyle && selectedStyle.style" class="selected-style-wrapper">
            <div class="selected-style-group">
              <div class="selected-style-title">{{ selectedStyle.style.title }}</div>
              <div class="selected-style-description">{{ selectedStyle.comment }}</div>
            </div>

            <div class="selected-style-group">
              <div *ngIf="selectedStyle.mood" class="selected-style-group-row">
                <span
                  class="selected-style-group-row-title"
                  i18n="@@cover-editor.conceptual-assistant.result.prompt.image-mood.title"
                >
                  cover-editor.conceptual-assistant.result.prompt.image-mood.title
                </span>
                <span class="selected-style-group-row-text">{{ selectedStyle.mood }}</span>
              </div>

              <div *ngIf="selectedStyle.colorBackgroundHex" class="selected-style-group-row">
                <span
                  class="selected-style-group-row-title"
                  i18n="@@cover-editor.conceptual-assistant.result.prompt.image-style.color.background"
                >
                  cover-editor.conceptual-assistant.result.prompt.image-style.color.background
                </span>
                <div
                  class="selected-style-group-row-color"
                  [ngStyle]="{'background-color': selectedStyle.colorBackgroundHex}"
                ></div>
              </div>

              <div *ngIf="selectedStyle.colorAccentHex" class="selected-style-group-row">
                <span
                  class="selected-style-group-row-title"
                  i18n="@@cover-editor.conceptual-assistant.result.prompt.image-style.color.accent"
                >
                  cover-editor.conceptual-assistant.result.prompt.image-style.color.accent
                </span>
                <div
                  class="selected-style-group-row-color"
                  [ngStyle]="{'background-color': selectedStyle.colorAccentHex}"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <m-button
          button-style="primary"
          size="full-width"
          icon="stars.svg"
          icon-position="left"
          i18n="@@cover-editor.conceptual-assistant.result.prompt.action-button"
          [is-disabled]="isGenerateImageDisable()"
          (onClick)="onGenerateImageClick()"
        >
          cover-editor.conceptual-assistant.result.prompt.action-button
        </m-button>
      </div>

      <div class="divider-hor"></div>

      <div class="section-wrapper">
        <div class="section-island">
          <div class="title" i18n="@@cover-editor.conceptual-assistant.result.fonts-and-colors.title">
            cover-editor.conceptual-assistant.result.fonts-and-colors.title
          </div>

          <div class="fonts-and-colors-group">
            <m-cover-conceptual-assistant-generation-result-font-card
              *ngIf="fontMainFamilyName"
              [familyName]="fontMainFamilyName"
              [color]="fontMainColor"
              [comment]="fontMainComment"
              fontStyle="main"
            />
            <m-cover-conceptual-assistant-generation-result-font-card
              *ngIf="fontSubFamilyName"
              [familyName]="fontSubFamilyName"
              [color]="fontSubColor"
              [comment]="fontSubComment"
              fontStyle="subheading"
            />
            <m-cover-conceptual-assistant-generation-result-font-card
              *ngIf="fontSecFamilyName"
              [familyName]="fontSecFamilyName"
              [color]="fontSecColor"
              [comment]="fontSecComment"
              fontStyle="secondary"
            />
          </div>
        </div>

        <m-button
          button-style="primary"
          size="full-width"
          icon="checkmark-02.svg"
          icon-position="left"
          i18n="@@cover-editor.conceptual-assistant.result.fonts-and-colors.action-button"
          [is-disabled]="isApplyFontAndColorDisable()"
          (onClick)="onApplyFontAndColorClick()"
        >
          cover-editor.conceptual-assistant.result.fonts-and-colors.action-button
        </m-button>
      </div>
    </div>
  </m-ng-scrollbars>

  <div class="buttons">
    <!-- <m-button
      button-style="primary"
      size="full-width"
      icon="stars.svg"
      icon-position="left"
      i18n="@@cover-editor.conceptual-assistant.result.prompt.action-button"
      [is-disabled]="isGenerateImageDisable()"
      (onClick)="onGenerateImageClick()"
    >
      cover-editor.conceptual-assistant.result.prompt.action-button
    </m-button> -->

    <m-button
      button-style="secondary"
      size="full-width"
      icon="stars.svg"
      icon-position="left"
      i18n="@@cover-editor.conceptual-assistant.result.reset-button"
      (onClick)="onBackClick()"
    >
      cover-editor.conceptual-assistant.result.reset-button
    </m-button>
  </div>
</div>

<!-- 

        <m-button
          button-style="primary"
          size="full-width"
          icon="stars.svg"
          icon-position="left"
          i18n="@@cover-editor.conceptual-assistant.result.prompt.action-button"
          [is-disabled]="isGenerateImageDisable()"
          (onClick)="onGenerateImageClick()"
        >
          cover-editor.conceptual-assistant.result.prompt.action-button
        </m-button>
      </div>
    </m-cover-conceptual-assistant-generation-menu-section>

    <div class="divider-hor"></div>

    <m-cover-conceptual-assistant-generation-menu-section
      title="cover-editor.conceptual-assistant.result.fonts-and-colors.title"
      i18n-title="@@cover-editor.conceptual-assistant.result.fonts-and-colors.title"
      iconSrc="cover-concept-fonts-and-colors.svg"
      [canCollapsing]="true"
    >
      <div class="fonts-and-colors-section">
        <div class="fonts-and-colors-group">
          <m-cover-conceptual-assistant-generation-result-font-card
            *ngIf="fontMainFamilyName"
            [familyName]="fontMainFamilyName"
            [color]="fontMainColor"
            [comment]="fontMainComment"
            fontStyle="main"
          />
          <m-cover-conceptual-assistant-generation-result-font-card
            *ngIf="fontSubFamilyName"
            [familyName]="fontSubFamilyName"
            [color]="fontSubColor"
            [comment]="fontSubComment"
            fontStyle="subheading"
          />
          <m-cover-conceptual-assistant-generation-result-font-card
            *ngIf="fontSecFamilyName"
            [familyName]="fontSecFamilyName"
            [color]="fontSecColor"
            [comment]="fontSecComment"
            fontStyle="secondary"
          />
        </div>

        <m-button
          button-style="primary"
          size="full-width"
          icon="checkmark-02.svg"
          icon-position="left"
          i18n="@@cover-editor.conceptual-assistant.result.fonts-and-colors.action-button"
          [is-disabled]="isApplyFontAndColorDisable()"
          (onClick)="onApplyFontAndColorClick()"
        >
          cover-editor.conceptual-assistant.result.fonts-and-colors.action-button
        </m-button>
      </div>
    </m-cover-conceptual-assistant-generation-menu-section>

    <div class="divider-hor"></div>

    <m-cover-conceptual-assistant-generation-menu-section
      title="cover-editor.conceptual-assistant.result.summary.title"
      i18n-title="@@cover-editor.conceptual-assistant.result.summary.title"
      iconSrc="cover-concept-summary.svg"
      [canCollapsing]="true"
    >
      <span class="text">{{ coverConceptualGeneration.summary }}</span>
    </m-cover-conceptual-assistant-generation-menu-section>
  </div>

  <div *ngIf="!coverConceptualGeneration.isProcessing" class="button">
    <m-button
      button-style="secondary"
      size="full-width"
      icon="button-back.svg"
      icon-position="left"
      i18n="@@cover-editor.conceptual-assistant.result.reset-button"
      (onClick)="onBackClick()"
    >
      cover-editor.conceptual-assistant.result.reset-button
    </m-button>
  </div> -->
<!-- </div> -->
