import { Injectable } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  Align,
  Case,
  Emphasis,
  IndentHeader,
  IndentParagraph,
  MarkupService,
  StartOn,
  StyleControlsKey,
  StyleKey,
} from "@metranpage/markup-editor";
import { CompanyFont, StylesSettings } from "@metranpage/book-data";
import { ColorConverterService, PaletteDTO, SelectValue } from "@metranpage/components";
import * as _ from "lodash-es";
import { BehaviorSubject, Subscription } from "rxjs";
import { MarginsService } from "../../services/margins.service";

type FontStyleGroup = "header" | "mainText";

type StyleFormGroupData = {
  font: FormControl<string>;
  fontSize: FormControl<number>;
  leading: FormControl<number>;
  tracking: FormControl<number>;
  emphasis: FormControl<Emphasis>;
  align: FormControl<Align>;
  case: FormControl<Case>;
  startsOn: FormControl<StartOn>;
  oneOnPage: FormControl<boolean>;
  dropCap: FormControl<boolean>;
  dropCapChars: FormControl<number>;
  dropCapLines: FormControl<number>;
  indentParagraph: FormControl<IndentParagraph>;
  indentParagraphAfterHeader: FormControl<boolean>;
  indentHeader: FormControl<IndentHeader>;
  color: FormControl<string>;

  isDisplayedAtSidebar: FormControl<boolean>;
  isConfigurableAtDetailsSidebar: FormControl<boolean>;
  availableControls: FormControl<StyleControlsKey[]>;

  type: FormControl<string>;

  styleKey: FormControl<string>;
  styleDecorationId: FormControl<number>;
  order: FormControl<number>;
};

const headersMultipliers = [5 / 4, 4 / 3, Math.sqrt(2) / 1, 3 / 2, 8 / 5, 1.618, 5 / 3, 16 / 9, 15 / 8, 2 / 1, 5 / 2];

@Injectable({
  providedIn: "root",
})
export class MarkupFormService {
  protected availableMainFonts: SelectValue[] = [];
  protected availableHeadersFonts: SelectValue[] = [];

  protected baseMainFonts: SelectValue[] = [
    {
      id: "PT Serif",
      value: "PT Serif",
    },
    // {
    //   id: "Arial",
    //   value: "Arial",
    // },
    // {
    //   id: "Times New Roman",
    //   value: "Times New Roman",
    // },
    {
      id: "Montserrat",
      value: "Montserrat",
    },
    {
      id: "Roboto",
      value: "Roboto",
    },
    // {
    //   id: 'Finlandica',
    //   value: 'Finlandica',
    // },
    // {
    //   id: 'Lack',
    //   value: 'Lack',
    // },
    // {
    //   id: 'Onest',
    //   value: 'Onest',
    // },
    // {
    //   id: 'PiazzollaSC',
    //   value: 'PiazzollaSC',
    // },
    // {
    //   id: 'Random Grotesque',
    //   value: 'Random Grotesque',
    // },
    // {
    //   id: 'Source Serif',
    //   value: 'Source Serif',
    // },
    // {
    //   id: 'Vollkorn',
    //   value: 'Vollkorn',
    // },
  ];
  protected baseHeadersFonts: SelectValue[] = [
    {
      id: "PT Serif",
      value: "PT Serif",
    },
    // {
    //   id: "Arial",
    //   value: "Arial",
    // },
    // {
    //   id: "Times New Roman",
    //   value: "Times New Roman",
    // },
    {
      id: "Montserrat",
      value: "Montserrat",
    },
    {
      id: "Roboto",
      value: "Roboto",
    },
    // {
    //   id: 'Arturito Rostype',
    //   value: 'Arturito Rostype',
    // },
    // {
    //   id: 'Comic Cat',
    //   value: 'Comic Cat',
    // },
    // {
    //   id: 'Dance Partner',
    //   value: 'Dance Partner',
    // },
    // {
    //   id: 'Frankinity',
    //   value: 'Frankinity',
    // },
    // {
    //   id: 'Garcia Marquez',
    //   value: 'Garcia Marquez',
    // },
    // {
    //   id: 'Lack',
    //   value: 'Lack',
    // },
    // {
    //   id: 'Lack Line',
    //   value: 'Lack Line',
    // },
    // {
    //   id: 'Murmure',
    //   value: 'Murmure',
    // },
    // {
    //   id: 'PiazzollaSC',
    //   value: 'PiazzollaSC',
    // },
    // {
    //   id: 'Random Grotesque',
    //   value: 'Random Grotesque',
    // },
    // {
    //   id: 'Source Serif',
    //   value: 'Source Serif',
    // },
    // {
    //   id: 'Vollkorn',
    //   value: 'Vollkorn',
    // },
  ];

  protected availablePalettes = [
    {
      colorAccent: "#000000",
      colorPrimary: "#000000",
      colorSecondary: "#262626",
      colorDecor: "#000000",
    },
    {
      colorAccent: "#E9983E",
      colorPrimary: "#0d0d0d",
      colorSecondary: "#4d4d4d",
      colorDecor: "#000000",
    },

    {
      colorAccent: "#D93832",
      colorPrimary: "#4D1502",
      colorSecondary: "#4d4d4d",
      colorDecor: "#000000",
    },
    {
      colorAccent: "#602F8A",
      colorPrimary: "#230E41",
      colorSecondary: "#7F519A",
      colorDecor: "#000000",
    },
    {
      colorAccent: "#204C9C",
      colorPrimary: "#204C9C",
      colorSecondary: "#9099C9",
      colorDecor: "#000000",
    },
  ];

  protected form!: FormGroup;
  protected styles!: StylesSettings;
  protected isEditableTemplate = true;

  private sub = new Subscription();

  private mainFontSize$ = new BehaviorSubject<number>(10);

  constructor(
    private readonly markupService: MarkupService,
    private readonly formBuilder: FormBuilder,
    private readonly marginsService: MarginsService,
    private readonly colorConverterService: ColorConverterService,
  ) {}

  initForm(styles: StylesSettings, isEditableTemplate: boolean) {
    this.sub.unsubscribe();
    this.sub = new Subscription();

    this.styles = styles;

    this.isEditableTemplate = isEditableTemplate;

    this.createForm();
    this.watchFormChanges();

    return this.getForm();
  }

  destroyForm() {
    this.sub.unsubscribe();
  }

  private createForm() {
    const stylesControls: { [key: string]: FormGroup<StyleFormGroupData> } = {};

    for (const style of Object.keys(this.styles)) {
      // if (['image', 'table', 'list'].includes(style)) {
      //   continue;
      // }

      let fontSizeValidators: ValidatorFn[] = [];
      if (["header1", "header2"].includes(style)) {
        fontSizeValidators = [Validators.required, Validators.min(6), Validators.max(100)];

        if (this.isEditableTemplate) {
          fontSizeValidators.push(createHeaderSizeValidator(this.mainFontSize$));
        }
      } else if (["header3", "header4"].includes(style)) {
        fontSizeValidators = [Validators.required, Validators.min(6), Validators.max(50)];

        if (this.isEditableTemplate) {
          fontSizeValidators.push(createHeaderSizeValidator(this.mainFontSize$));
        }
      } else if (style === "mainText") {
        fontSizeValidators = [Validators.required, Validators.min(6), Validators.max(32)];
      } else {
        fontSizeValidators = [Validators.required, Validators.min(6), Validators.max(32)];
      }

      stylesControls[style] = this.formBuilder.group({
        font: this.formBuilder.control("", {
          nonNullable: true,
          validators: [Validators.required],
        }),
        fontSize: this.formBuilder.control(10, {
          nonNullable: true,
          validators: fontSizeValidators,
        }),
        emphasis: this.formBuilder.control("none" as Emphasis, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        align: this.formBuilder.control("left" as Align, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        case: this.formBuilder.control("none" as Case, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        startsOn: this.formBuilder.control("current-page" as StartOn, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        oneOnPage: this.formBuilder.control(false, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        dropCap: this.formBuilder.control(false, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        dropCapChars: this.formBuilder.control(0, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        dropCapLines: this.formBuilder.control(0, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        indentParagraph: this.formBuilder.control("none" as IndentParagraph, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        indentParagraphAfterHeader: this.formBuilder.control(false, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        indentHeader: this.formBuilder.control("none" as IndentHeader, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        color: this.formBuilder.control("black", { nonNullable: true }),
        leading: this.formBuilder.control(120, {
          nonNullable: true,
          validators: [Validators.required, Validators.min(0), Validators.max(500)],
        }),
        tracking: this.formBuilder.control(3, {
          nonNullable: true,
          validators: [Validators.required, Validators.min(0), Validators.max(1000)],
        }),

        isDisplayedAtSidebar: this.formBuilder.control(false, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        isConfigurableAtDetailsSidebar: this.formBuilder.control(false, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        availableControls: this.formBuilder.control([] as StyleControlsKey[], {
          nonNullable: true,
          validators: [],
        }),

        type: this.formBuilder.control("text", { nonNullable: true, validators: [Validators.required] }),

        //empty
        styleKey: this.formBuilder.control("", { nonNullable: true }),
        styleDecorationId: this.formBuilder.control(1, { nonNullable: true }),
        order: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
      });
    }

    this.form = this.formBuilder.group({
      fontSizeHeaders: this.formBuilder.control(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      headerFont: this.formBuilder.control(this.getHeaderFontId("PT Serif"), {
        nonNullable: true,
        validators: [Validators.required],
      }),
      mainFont: this.formBuilder.control(this.getMainFontId("PT Serif"), {
        nonNullable: true,
        validators: [Validators.required],
      }),
      styles: this.formBuilder.record(stylesControls),
      tocVisible: this.formBuilder.control(false, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      tocPosition: this.formBuilder.control("start", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      centerImages: this.formBuilder.control(false, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      palette: this.formBuilder.control(this.getDefaultPalette(), {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  watchFormChanges() {
    this.sub.add(
      this.form?.get("headerFont")?.valueChanges.subscribe((value) => {
        if (this.isEditableTemplate) {
          this.updateHeaderFont(value);
        }
      }),
    );

    this.sub.add(
      this.form?.get("mainFont")?.valueChanges.subscribe((value) => {
        if (this.isEditableTemplate) {
          this.updateMainFont(value);
        }
      }),
    );

    this.sub.add(
      this.form?.get("palette")?.valueChanges.subscribe((value) => {
        this.updatePalette(value);
      }),
    );

    // watch every style
    for (const key of Object.keys(this.styles)) {
      const sk = key as StyleKey;

      this.sub.add(
        this.form
          ?.get("styles")
          ?.get(sk)
          ?.valueChanges.subscribe((value) => {
            this.markupService.updateStyleDisplayOpts(sk, {
              font: value.font,
              fontSize: value.fontSize,
              leading: value.leading,
              tracking: value.tracking,
              emphasis: value.emphasis,
              align: value.align,
              case: value.case,
              startsOn: value.startsOn,
              oneOnPage: value.oneOnPage,
              dropCap: value.dropCap,
              dropCapChars: value.dropCapChars,
              dropCapLines: value.dropCapLines,
              indentParagraph: value.indentParagraph,
              indentParagraphValue: value.indentParagraphValue,
              indentParagraphAfterHeader: value.indentParagraphAfterHeader,
              indentHeader: value.indentHeader,
              color: value.color,
              isShowedInToc: value.isShowedInToc,

              visibleTitle: value.visibleTitle,
              isDisplayedAtSidebar: value.isDisplayedAtSidebar,
              isConfigurableAtDetailsSidebar: value.isConfigurableAtDetailsSidebar,
              availableControls: value.availableControls,
              order: value.order,
              type: value.type,
            });

            if (value.styleKey === "mainText") {
              this.mainFontSize$.next(value.fontSize);

              // this.updateHeadersFontSize(this.form?.get('fontSizeHeaders')?.getRawValue());
              if (this.isEditableTemplate) {
                this.updateMainFontSize(value.fontSize);
              }
            }

            // if (value.styleKey === "header1" || value.styleKey === "header2") {
            //   let relatedStyle = "header2";
            //   if (value.styleKey === "header2") {
            //     relatedStyle = "header1";
            //   }
            //   this.form
            //     ?.get("styles")
            //     ?.get(relatedStyle)
            //     ?.patchValue({ indentHeader: value.indentHeader }, { emitEvent: false });
            // }
          }),
      );

      this.sub.add(
        this.form
          ?.get("styles")
          ?.get(sk)
          ?.get("fontSize")
          ?.valueChanges.subscribe((value) => {
            for (const key2 of Object.keys(this.styles)) {
              const sk2 = key2 as StyleKey;
              this.form?.get("styles")?.get(sk2)?.get("fontSize")?.updateValueAndValidity({ emitEvent: false });
            }
          }),
      );

      this.watchIndentParagraphAndDropCapChanges(sk);
    }
  }

  watchFontSizeHeadersChanges() {
    this.sub.add(
      this.form?.get("fontSizeHeaders")?.valueChanges.subscribe((value) => {
        if (this.isEditableTemplate) {
          this.updateHeadersFontSize(value);
        }
      }),
    );
  }

  getHeadersFontSizeValue(styles: StylesSettings) {
    const multiplier = styles.header3.fontSize / styles.mainText.fontSize;
    let diff = 10;

    for (let i = 0; i < headersMultipliers.length; i++) {
      const m = headersMultipliers[i];
      const newDiff = Math.abs(m - multiplier);
      if (newDiff < diff) {
        diff = newDiff;
      } else {
        const result = i - 1;
        return result > 0 && result < 6 ? result : 1;
      }
    }
    return 1;
  }

  updateHeadersFontSize(value: any) {
    const newSizes = {} as any;

    const mainTextSize = this.form.getRawValue().styles.mainText.fontSize;
    const mult = headersMultipliers[value];

    const h3Size = this.marginsService.roundToDozens(mainTextSize * mult);
    const h2Size = this.marginsService.roundToDozens(h3Size * mult);
    const h1Size = this.marginsService.roundToDozens(h2Size * mult);

    for (const key of Object.keys(this.styles)) {
      const sk = key as StyleKey;

      let newSize = 0;
      if (sk === "header1") {
        newSize = h1Size;
      } else if (sk === "header2") {
        newSize = h2Size;
      } else if (sk === "header3") {
        newSize = h3Size;
      } else if (sk === "header4") {
        newSize = mainTextSize;
      } else if (sk === "lead") {
        newSize = h3Size;
      } else {
        continue;
      }

      newSizes[sk] = { fontSize: newSize };
    }
    this.form?.patchValue({ styles: newSizes });
  }

  updateMainFontSize(mainFontSize: number) {
    const newSizes = {} as any;

    for (const key of Object.keys(this.styles)) {
      const sk = key as StyleKey;

      let newSize = 0;
      if (sk === "list") {
        newSize = mainFontSize;
      } else if (sk === "quote") {
        newSize = mainFontSize + 2;
      } else if (sk === "quoteAuthor") {
        newSize = mainFontSize;
      } else if (sk === "caption") {
        newSize = mainFontSize - 2;
        if (newSize < 6) {
          newSize = 6;
        }
      } else if (sk === "note") {
        newSize = mainFontSize - 2;
        if (newSize < 6) {
          newSize = 6;
        }
      } else {
        continue;
      }

      newSizes[sk] = { fontSize: newSize };
    }
    this.form?.patchValue({ styles: newSizes });
  }

  updateHeaderFont(value: string) {
    const styleFonts = {} as any;
    styleFonts.header1 = { font: value };
    styleFonts.header2 = { font: value };
    styleFonts.header3 = { font: value };
    styleFonts.header4 = { font: value };

    this.form?.patchValue({ styles: styleFonts });
  }

  updateMainFont(value: string) {
    const styleFonts = {} as any;
    for (const sk of Object.keys(this.styles)) {
      if (sk.includes("header")) {
        continue;
      }
      styleFonts[sk] = { font: value };
    }

    this.form?.patchValue({ styles: styleFonts });
  }

  getForm() {
    return this.form;
  }

  async getAvailableMainFonts(fonts: CompanyFont[], isBookSettingsEditable: boolean) {
    const fontsSelectValue = this.getFontsSelectValue(fonts, "mainText", isBookSettingsEditable);
    this.availableMainFonts = this.baseMainFonts.concat(fontsSelectValue);
    this.availableMainFonts = _.uniqWith(this.availableMainFonts, _.isEqual);
    this.availableMainFonts = _.orderBy(this.availableMainFonts, [(v) => v.value.toLowerCase()], ["asc"]);
    return this.availableMainFonts;
  }

  getMainFontId(value: string) {
    return this.availableMainFonts.find((opt) => opt.value === value)?.id || "PT Serif";
  }

  async getAvailableHeaderFonts(fonts: CompanyFont[], isBookSettingsEditable: boolean) {
    const fontsSelectValue = this.getFontsSelectValue(fonts, "header", isBookSettingsEditable);
    this.availableHeadersFonts = this.baseHeadersFonts.concat(fontsSelectValue);
    this.availableHeadersFonts = _.uniqWith(this.availableHeadersFonts, _.isEqual);
    this.availableHeadersFonts = _.orderBy(this.availableHeadersFonts, [(v) => v.value.toLowerCase()], ["asc"]);
    return this.availableHeadersFonts;
  }

  getHeaderFontId(value: string) {
    return this.availableHeadersFonts.find((opt) => opt.value === value)?.id || "PT Serif";
  }

  getAvailablePalettes() {
    const availablePalettesHsv: PaletteDTO[] = [];
    for (const palette of this.availablePalettes) {
      availablePalettesHsv.push(this.colorConverterService.convertPaletteHexToHsv(palette));
    }
    return availablePalettesHsv;
  }

  getDefaultPalette() {
    return this.colorConverterService.convertPaletteHexToHsv(this.availablePalettes[0]);
  }

  getStyleColor(sk: StyleKey) {
    const stylesColorsMapping: { [key: string]: StyleKey[] } = {
      colorPrimary: ["mainText", "lead", "quote", "quoteAuthor", "verse", "list", "image", "table", "header4"],
      colorAccent: ["header1", "header2", "header3"],
      colorSecondary: ["caption", "note"],
      colorDecor: [],
    };

    let styleColor = "black";
    const palette = this.form?.get("palette")?.value;
    if (palette) {
      styleColor = palette.colorPrimary;
      for (const colorName of Object.keys(palette)) {
        if (!stylesColorsMapping[colorName].includes(sk) || colorName === "colorPrimary") {
          continue;
        }
        styleColor = palette[colorName];
      }
    }

    return styleColor;
  }

  updatePalette(palette: PaletteDTO) {
    this.form?.patchValue({ palette: palette }, { emitEvent: false });

    const newColors = {} as any;
    for (const key of Object.keys(this.styles)) {
      const sk = key as StyleKey;
      const newColor = this.getStyleColor(sk);
      newColors[sk] = { color: newColor };
    }

    this.form?.patchValue({ styles: newColors });
  }

  watchIndentParagraphAndDropCapChanges(sk: StyleKey) {
    this.sub.add(
      this.form
        ?.get("styles")
        ?.get(sk)
        ?.get("dropCap")
        ?.valueChanges.subscribe((value) => {
          if (value) {
            this.form.patchValue({
              styles: { [sk]: { indentParagraphAfterHeader: false } },
            });
          }
        }),
    );

    this.sub.add(
      this.form
        ?.get("styles")
        ?.get(sk)
        ?.get("indentParagraphAfterHeader")
        ?.valueChanges.subscribe((value) => {
          if (value) {
            this.form.patchValue({ styles: { [sk]: { dropCap: false } } });
          }
        }),
    );
  }

  getFilteredFonts(fonts: CompanyFont[], forStyle: FontStyleGroup, isBookSettingsEditable: boolean) {
    let fontsFiltered = fonts.filter((font, index) => {
      if (isBookSettingsEditable && !font.isSelectable) {
        return false;
      }
      if (!isBookSettingsEditable) {
        return true;
      }
      if (forStyle === "header") {
        return font.isAvailableForHeaders;
      }
      if (forStyle === "mainText") {
        return font.isAvailableForMainText;
      }
      return false;
    });

    const fontsFamilies = fontsFiltered.reduce((acc: any, num) => {
      acc[num.familyName] = acc[num.familyName] || [];
      acc[num.familyName].push(num);
      return acc;
    }, {});

    fontsFiltered = [];
    for (const [familyName, value] of Object.entries(fontsFamilies)) {
      const fonts = value as CompanyFont[];
      const regular = fonts.find((f) => f.subfamilyName === "regular");
      if (!regular) {
        fontsFiltered.push(fonts[0]);
        continue;
      }
      fontsFiltered.push(regular);
    }
    return fontsFiltered;
  }

  getFontsSelectValue(fonts: CompanyFont[], forStyle: FontStyleGroup, isBookSettingsEditable: boolean): SelectValue[] {
    const fontsFiltered = this.getFilteredFonts(fonts, forStyle, isBookSettingsEditable);

    return fontsFiltered.map((font) => {
      return { id: font.familyName, value: font.familyName };
    });
  }

  getBaseMainFontsNames(): string[] {
    return this.baseMainFonts.map((f) => f.value);
  }

  getBaseHeadersFontsNames(): string[] {
    return this.baseHeadersFonts.map((f) => f.value);
  }
}

function createHeaderSizeValidator(mainFontSize: BehaviorSubject<number>) {
  function ltMainFontSize(control: AbstractControl): ValidationErrors | null {
    return control.value < mainFontSize.value ? { ltMainFontSize: true } : null;
  }

  return ltMainFontSize;
}
