import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { CoverSnippetAccess, CoverSnippetAccessUpdateData } from "../../models/cover-snippet/cover-snippet-access";
import { AdminCoverSnippetsService } from "../cover-snippets/cover-snippets.service";
import { AdminCoverSnippetsAccessApi, CoverSnippetsAccessFilter } from "./cover-snippets-access.api";
import { AdminCoverSnippetsAccessStore } from "./cover-snippets-access.store";

@Injectable({
  providedIn: "root",
})
export class AdminCoverSnippetsAccessService {
  constructor(
    private readonly adminCoverSnippetsService: AdminCoverSnippetsService,
    private readonly adminCoverSnippetsAccessApi: AdminCoverSnippetsAccessApi,
    private readonly adminCoverSnippetsAccessStore: AdminCoverSnippetsAccessStore,
  ) {}

  async loadCoverSnippetsAccess() {
    const coverSnippetsAccess = await this.adminCoverSnippetsAccessApi.getCoverSnippetsAccess();
    return coverSnippetsAccess;
  }

  async loadCoverSnippetsAccessPaginated(page: number, filters: CoverSnippetsAccessFilter = {}) {
    const coverSnippetsAccessData = await this.adminCoverSnippetsAccessApi.getCoverSnippetsAccessPaginated(
      page,
      filters,
    );
    this.adminCoverSnippetsAccessStore.setCoverSnippetsAccess(coverSnippetsAccessData.items);
    this.adminCoverSnippetsAccessStore.setCoverSnippetsAccessPageCount(coverSnippetsAccessData.pageCount);
  }

  async updateCoverSnippetAccess(id: number, data: CoverSnippetAccessUpdateData): Promise<GeneralResultStatus> {
    try {
      await this.adminCoverSnippetsAccessApi.updateCoverSnippetAccess(id, data);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }
}
