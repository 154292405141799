import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { Subscription } from "rxjs";
import { LoginError } from "../../models/login-errors";

export type RestorePassData = {
  email: string;
};

@Component({
  selector: "m-password-restore-view",
  templateUrl: "./password-restore.view.html",
  styleUrls: ["./password-restore.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class PasswordRestoreView {
  @Input()
  userLanguage = "ru";
  @Input()
  errors: LoginError[] = [];
  @Input()
  isLoading = false;
  @Input()
  isEmailSent = false;

  @Output()
  onRestorePassClick = new EventEmitter<RestorePassData>();

  @Output()
  onLangChangeClick = new EventEmitter<string>();

  protected form = new FormGroup({
    email: new FormControl("", { nonNullable: true, validators: [Validators.email, Validators.required] }),
  });

  protected isInputEmailErrorVisible = false;

  private sub = new Subscription();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.watchFormChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onRestorePassButtonClick() {
    this.isInputEmailErrorVisible = true;
    this.setFormValidators("email");

    if (this.isRestorePassButtonDisabled()) {
      return;
    }

    const value = this.form.value;
    this.onRestorePassClick.emit({
      email: value.email!,
    });
  }

  isRestorePassButtonDisabled() {
    return !!this.form.get("email")?.errors;
  }

  protected onKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      if (!this.isRestorePassButtonDisabled()) {
        this.onRestorePassButtonClick();
      }
    }
  }

  protected isEmailIncorrect() {
    return this.form.get("email")?.errors?.["email"];
  }

  protected isFieldEmpty(controlName: string) {
    return this.form.get(controlName)?.errors?.["required"];
  }

  protected onLangChange(lang: string) {
    this.onLangChangeClick.emit(lang);
  }

  protected focusoutEvent(controlName: string) {
    const value = this.form.get(controlName)?.value;
    if (value && controlName === "email") {
      this.isInputEmailErrorVisible = true;
    }
    this.setFormValidators(controlName);
  }

  protected watchFormChanges() {
    this.sub.add(
      this.form.get("email")?.valueChanges.subscribe((value) => {
        this.isInputEmailErrorVisible = false;
        this.clearFormValidators("email");
        this.cdr.detectChanges();
      }),
    );
  }

  private setFormValidators(controlName: string) {
    if (controlName === "email") {
      this.form.get(controlName)?.setValidators([Validators.email, Validators.required]);
    }
    this.form.get(controlName)?.updateValueAndValidity({ emitEvent: false });
    this.cdr.detectChanges();
  }

  private clearFormValidators(controlName: string) {
    this.form.get(controlName)?.clearValidators();
    this.form.get(controlName)?.updateValueAndValidity({ emitEvent: false });
  }
}
