import { Component, HostBinding, Input } from "@angular/core";
import { Book } from "@metranpage/book-data";
import { BookService } from "../../services/book.service";

@Component({
  selector: "m-book-card-download",
  template: `
    <m-button
      button-style="tertiary"
      icon="/assets/icons/book-download.svg"
      icon-position="left"
      text-align="left"
      size="full-width"
      (onClick)="downloadFile()"
    >
      {{ text }}
    </m-button>
  `,
  styles: [""],
})
export class BookCardDownloadView {
  @Input()
  book!: Book;
  @Input()
  text!: string;
  @Input()
  url!: string;
  @Input()
  isEpub = false;
  @Input()
  isCover = false;
  @Input()
  downloadSuffix = "";

  @HostBinding("class.disabled")
  @Input()
  isDisabled = false;

  constructor(private readonly bookService: BookService) {}

  async downloadFile() {
    if (this.isCover) {
      await this.bookService.downloadCover(this.book);
    } else if (this.isEpub) {
      await this.bookService.downloadEpub(this.book, this.url);
    } else {
      await this.bookService.downloadFinal(this.book, this.url, this.downloadSuffix);
    }
  }
}
