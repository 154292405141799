import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { $localize } from "@angular/localize/init";
import { RadioComponent } from "@metranpage/components";
import { ImageSize } from "../../../../../views/markup-editor/blocks/image-block/image-block.component";

type ISize = { label: string; size: ImageSize };

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, RadioComponent],
  selector: "m-image-sizes",
  templateUrl: "./image-sizes.component.html",
  styleUrls: ["./image-sizes.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ImageSizesComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageSizesComponent implements ControlValueAccessor {
  sizes: ISize[] = [
    {
      label: $localize`:@@editor.image.size_small:`,
      size: "small",
    },
    {
      label: $localize`:@@editor.image.size_normal:`,
      size: "medium",
    },
    {
      label: $localize`:@@editor.image.size_large:`,
      size: "large",
    },
    {
      label: $localize`:@@editor.image.size_fullpage:`,
      size: "fullpage",
    },
  ];
  sizeValue: ImageSize = "small";

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private readonly cdr: ChangeDetectorRef) {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(value: ImageSize): void {
    this.sizeValue = value;
    this.onChange(this.sizeValue);
    this.cdr.detectChanges();
  }

  selectSize(size: ImageSize): void {
    this.sizeValue = size;
    this.onChange(this.sizeValue);
  }
}
