<div
  class="container"
  [class.active]="activeSubscription?.tariff?.id === tariff.id || (activeSubscription?.tariff?.level === tariff.level && activeSubscription?.tariff?.isFree)"
  [class.compact]="appearance === 'compact'"
>
  <div class="section">
    <div class="header">
      <div class="title-wrapper">
        <div class="title">{{ tariff.title.toUpperCase() }}</div>
        <m-premium-mark *ngIf="!tariff.isFree && tariff.level" />
      </div>

      <div class="for-whom">
        <m-localized-text [text]="getSubtitleText(tariff.subtitle)" />
      </div>
    </div>
  </div>

  <div class="section">
    <div class="content">
      <div *ngIf="tariff.giftPeriod" class="gift-time">
        <m-icon src="gift.svg" size="s" />
        <span>{{ pluralizeMonths(tariff.giftPeriod) }}</span>
      </div>
      <div class="offer-block">
        <div class="price-wrapper">
          <div class="price">{{ getPrice() }}</div>
          <div *ngIf="tariff.profit > 0 || hasPromocodeDiscount()" class="without-discount">
            {{ priceWithoutDiscount }}
          </div>
          <div *ngIf="tariff.profit > 0 || hasPromocodeDiscount()" class="without-discount-percent">
            {{ discountPercent }}
          </div>
        </div>

        <div class="accrual-period">{{ getAccrualPeriodText() }}</div>

        <ng-container
          *ngIf="(!activeSubscription || activeSubscription.tariff.isFree) && tariffChangeDirection; else active"
        >
          <m-button
            button-style="primary"
            (onClick)="onBuySubscriptionClick.emit(tariff)"
            i18n="@@pricing.tariffs.tariff-card.buttons.connect"
          >
            pricing.tariffs.tariff-card.buttons.connect
          </m-button>
        </ng-container>

        <ng-template #active>
          <m-button
            *ngIf="tariffChangeDirection === undefined"
            button-style="secondary"
            [is-disabled]="true"
            i18n="@@pricing.tariffs.tariff-card.buttons.active"
          >
            pricing.tariffs.tariff-card.buttons.active
          </m-button>

          <m-button
            *ngIf="tariffChangeDirection === 'downgrade'"
            button-style="secondary"
            (onClick)="onBuySubscriptionClick.emit(tariff)"
            i18n="@@pricing.tariffs.tariff-card.buttons.downgrade"
          >
            pricing.tariffs.tariff-card.buttons.downgrade
          </m-button>

          <m-button
            *ngIf="tariffChangeDirection === 'refresh'"
            button-style="primary"
            (onClick)="onBuySubscriptionClick.emit(tariff)"
            i18n="@@pricing.tariffs.tariff-card.buttons.refresh"
          >
            pricing.tariffs.tariff-card.buttons.refresh
          </m-button>

          <m-button
            *ngIf="tariffChangeDirection === 'upgrade'"
            button-style="primary"
            (onClick)="onBuySubscriptionClick.emit(tariff)"
            i18n="@@pricing.tariffs.tariff-card.buttons.connect"
          >
            pricing.tariffs.tariff-card.buttons.connect
          </m-button>
        </ng-template>

        <!-- <m-button
          *ngIf="activeSubscription?.tariff?.id !== tariff.id && !tariff.isFree && tariff.price > 0 && tariff.forLegalEntity"
          button-style="primary"
          (onClick)="onBuySubscriptionClick.emit(tariff)"
          i18n="@@pricing.tariffs.tariff-card.buttons.bill"
        >
          pricing.tariffs.tariff-card.buttons.bill
        </m-button> -->
      </div>
    </div>
  </div>

  <div class="section tokens">
    <div class="feature">
      <m-icon [src]="features.tokens_included.iconPath" [color]="features.tokens_included.iconColor" size="s"></m-icon>
      <m-localized-text [text]="features.tokens_included.description" />
    </div>
  </div>

  <div class="section primary-limits">
    <div class="feature">
      <m-icon [src]="features.symbol_limits.iconPath" [color]="features.symbol_limits.iconColor" size="xs"></m-icon>
      <m-localized-text [text]="features.symbol_limits.description" />
    </div>
    <div class="feature">
      <m-icon
        [src]="features.image_generation.iconPath"
        [color]="features.image_generation.iconColor"
        size="xs"
      ></m-icon>
      <m-localized-text [text]="features.image_generation.description" />
    </div>
  </div>

  <ng-container *ngIf="appearance === 'full'">
    <div class="section">
      <div class="feature">
        <m-icon [src]="features.upload_limits.iconPath" [color]="features.upload_limits.iconColor" size="xs"></m-icon>
        <m-localized-text [text]="features.upload_limits.description" />
      </div>
      <div class="feature">
        <m-icon
          [src]="features.template_editing.iconPath"
          [color]="features.template_editing.iconColor"
          size="xs"
        ></m-icon>
        <m-localized-text [text]="features.template_editing.description" />
      </div>
    </div>

    <div class="section">
      <div class="feature">
        <m-icon [src]="features.layout_images.iconPath" [color]="features.layout_images.iconColor" size="xs"></m-icon>
        <m-localized-text [text]="features.layout_images.description" />
      </div>
      <div class="feature">
        <m-icon [src]="features.layout_tables.iconPath" [color]="features.layout_tables.iconColor" size="xs"></m-icon>
        <m-localized-text [text]="features.layout_tables.description" />
      </div>
    </div>

    <div class="section">
      <div class="feature">
        <m-icon [src]="features.watermark.iconPath" [color]="features.watermark.iconColor" size="xs"></m-icon>
        <m-localized-text [text]="features.watermark.description" />
      </div>
      <div class="feature">
        <m-icon
          [src]="features.autopublish_in_community.iconPath"
          [color]="features.autopublish_in_community.iconColor"
          size="xs"
        ></m-icon>
        <m-localized-text [text]="features.autopublish_in_community.description" />
      </div>
    </div>

    <div class="section">
      <div class="feature">
        <m-icon [src]="features.output_pdf.iconPath" [color]="features.output_pdf.iconColor" size="xs"></m-icon>
        <m-localized-text [text]="features.output_pdf.description" />
      </div>
      <div class="feature">
        <m-icon
          [src]="features.output_src_indd.iconPath"
          [color]="features.output_src_indd.iconColor"
          size="xs"
        ></m-icon>
        <m-localized-text [text]="features.output_src_indd.description" />
      </div>
      <div class="feature">
        <m-icon [src]="features.output_epub.iconPath" [color]="features.output_epub.iconColor" size="xs"></m-icon>
        <m-localized-text [text]="features.output_epub.description" />
      </div>
    </div>
  </ng-container>
</div>
