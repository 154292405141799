import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { IS_COMMUNITY_AVAILABLE } from "../tokens";

export const CommunityDisabledGuard: CanActivateFn = (_route, _state) =>  {
  const router = inject(Router);
  const isCommunityAvailable = inject(IS_COMMUNITY_AVAILABLE);

  if (!isCommunityAvailable) {
    router.navigate(['/books']);
    return false;
  }
  return true;
}
