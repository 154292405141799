import { MetranpageFile } from "@metranpage/core-data";
import { Expose, Type } from "class-transformer";
import {
  CoverObject,
  CoverObjectType,
  EllipseObject,
  GroupObject,
  ImageObject,
  RectangleObject,
  SvgObject,
  TextObject,
} from "./book-cover";

export class CoverSnippet {
  @Expose() id!: number;

  @Expose() companyId!: number;
  @Expose() authorId!: number;

  @Expose() category!: CoverSnippetCategory;
  @Expose() subcategory!: CoverSnippetSubcategory;

  @Expose() imageUrl!: string;
  @Expose() file!: MetranpageFile;

  @Expose() isVisibleToUsers!: boolean;
  @Expose() isVisibleToOtherCompanies!: boolean;
  @Expose() isPremium!: boolean;

  @Expose() order!: number;
  @Expose() createdAt?: Date;

  @Type(() => CoverObject, {
    discriminator: {
      property: "__type",
      subTypes: [
        { value: TextObject, name: CoverObjectType.Text },
        { value: ImageObject, name: CoverObjectType.Image },
        { value: RectangleObject, name: CoverObjectType.Rectangle },
        { value: EllipseObject, name: CoverObjectType.Ellipse },
        { value: SvgObject, name: CoverObjectType.SVG },
        { value: GroupObject, name: CoverObjectType.Group },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  @Expose()
  objects!: CoverObject[];
}

export type CoverSnippetCreateMetaData = Omit<
  CoverSnippet,
  "id" | "companyId" | "authorId" | "objects" | "imageUrl" | "file"
>;
export type CoverSnippetCreateDataDto = CoverSnippetCreateMetaData & { objects: CoverObject[]; bookId: number };

export type CoverSnippetUpdateData = Partial<Omit<CoverSnippet, "id">>;

export type CoverSnippetCategory = "text" | "objects";
export type CoverSnippetSubcategory = CoverSnippetTextSubcategory | CoverSnippetObjectsSubcategory;

export type CoverSnippetTextSubcategory = "headersAndText" | "lists" | "quotes";
export type CoverSnippetObjectsSubcategory = "basicShapes" | "ageConstraints" | "arrows" | "splashes";

// export type CoverSnippetCategoryObjects = {
//   [key in CoverSnippetCategory]: CoverSnippetSubcategoryObjects[];
// };

// export type CoverSnippetSubcategoryObjects = {
//   [key in CoverSnippetSubcategory]: CoverSnippet[];
// };

export type CoverSnippetCategoryObjects = {
  category: CoverSnippetCategory;
  items: CoverSnippetSubcategoryObjects[];
};

export type CoverSnippetSubcategoryObjects = {
  subcategory: CoverSnippetSubcategory;
  snippets: CoverSnippet[];
};
