<div class="minimized" [ngClass]="getCssClassList()" [class.maximized]="isExpanded">
  <div *ngIf="isExpanded" class="content sidebar-content" (mouseleave)="onMouseLeave()">
    <ng-content></ng-content>
  </div>

  <div *ngIf="sidebarType === 'normal'" class="header" (click)="expand()">
    <m-icon src="bc-arrow.svg" class="header-icon" size="s" color="--color-font-main"></m-icon>
    <span class="header-text">{{ sidebarName }}</span>
  </div>
</div>
