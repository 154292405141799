<m-ng-icon
  *ngIf="withLeftIcon"
  src="/assets/icons/input-lock.svg"
  class="icon lock-icon"
  [size]="24"
/>

<input
  [placeholder]="placeholder"
  [name]="name"
  [autocomplete]="getAutocomplete()"
  [required]="isRequired"
  [tabindex]="tabindex"
  [type]="getInputType()"
  [disabled]="isDisabled"
  (input)="onInputChange($event)"
  #input
/>

<m-ng-icon
  [src]="getPasswordVisibilityIcon()"
  class="icon eye-icon"
  [size]="24"
  (click)="togglePasswordVisibility()"
/>
