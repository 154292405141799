import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { PaletteDTO, fadeInOutForSidebarElements, slideInOutVertical } from "@metranpage/components";

@Component({
  selector: "m-books-markup-sidebar-palette",
  templateUrl: "./markup-sidebar-palette.view.html",
  styleUrls: ["./markup-sidebar-palette.view.scss"],
  animations: [slideInOutVertical, fadeInOutForSidebarElements],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkupSidebarPalette {
  @Input()
  availablePalettes!: PaletteDTO[];
  @Input()
  form?: FormGroup;
  @Input()
  isSettingsAvailable = false;

  @Output()
  onShowColorsDetailsSidebar = new EventEmitter();

  constructor(private readonly cdr: ChangeDetectorRef) {}
}
