<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <m-ng-island [paddingSize]="24">
    <div class="controls">
      <div class="section-title" i18n="@@admin.companies.company.maintenance-settings.tilte">
        admin.companies.company.maintenance-settings.tilte
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.maintenance-settings.image-generation.basic">
          admin.companies.company.maintenance-settings.image-generation.basic
        </span>
        <div class="row">
          <m-switch formControlName="imageGenerationBasic" />
          @if (isImageGenerationBasicMaintenanceEnable) {
          <span i18n="@@admin.companies.company.maintenance-settings.action-enable-description">
            admin.companies.company.maintenance-settings.action-enable-description
          </span>
          } @else {
          <span i18n="@@admin.companies.company.maintenance-settings.action-disable-description">
            admin.companies.company.maintenance-settings.action-disable-description
          </span>
          }
        </div>
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.companies.company.maintenance-settings.image-generation.advanced">
          admin.companies.company.maintenance-settings.image-generation.advanced
        </span>
        <div class="row">
          <m-switch formControlName="imageGenerationAdvanced" />
          @if (isImageGenerationAdvancedMaintenanceEnable) {
          <span i18n="@@admin.companies.company.maintenance-settings.action-enable-description">
            admin.companies.company.maintenance-settings.action-enable-description
          </span>
          } @else {
          <span i18n="@@admin.companies.company.maintenance-settings.action-disable-description">
            admin.companies.company.maintenance-settings.action-disable-description
          </span>
          }
        </div>
      </div>
    </div>

    <div class="row">
      <m-button
        button-style="primary"
        [is-disabled]="isDisabled()"
        (onClick)="onSubmit()"
        [is-disabled]="!form.valid"
        i18n="@@admin.common.save"
      >
        admin.common.save
      </m-button>
    </div>
  </m-ng-island>
</form>
