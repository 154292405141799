import { BookCover, BookCoverTemplate } from "@metranpage/book-data";
import { instanceToInstance, plainToInstance } from "class-transformer";
import  * as _ from "lodash-es";

export type BookCoverStateOptions = {
  template?: BookCoverTemplate;
};

export abstract class BaseState {}

export class BookCoverState {
  private readonly _cover: BookCover;
  private readonly _options?: BookCoverStateOptions;

  constructor(cover: BookCover, options?: BookCoverStateOptions) {
    //this._cover = instanceToInstance(cover, { excludeExtraneousValues: true });
    this._cover = plainToInstance(BookCover, cover, { excludeExtraneousValues: true });

    if (options) {
      this._options = instanceToInstance(options, { excludeExtraneousValues: true });
    }
  }

  get cover(): BookCover {
    return this._cover;
  }

  get options(): BookCoverStateOptions | undefined {
    return this._options;
  }
}

export class SimpleUndoRedo {
  private _states: BaseState[] = [];
  private _currentStateIndex = -1;

  constructor() {
    this.reset();
  }

  get states() {
    return this._states;
  }

  get isUndoAvailable() {
    return this._currentStateIndex > 0;
  }

  get isRedoAvailable() {
    return this._currentStateIndex < this._states.length - 1;
  }

  reset() {
    this._currentStateIndex = -1;
    this._states = [];
  }

  save(state: BaseState) {
    this._currentStateIndex++;
    (state as any).index = this._currentStateIndex;

    this._states.splice(this._currentStateIndex);
    this._states.push(state);

    // this.debugStates()
  }

  undo(): BaseState | undefined {
    if (this._currentStateIndex > 0) {
      this._currentStateIndex--;
      // this.debugStates()
      const copy = _.cloneDeep(this._states[this._currentStateIndex]);
      return copy;
    }
    return undefined;
  }

  redo(): BaseState | undefined {
    if (this._currentStateIndex < this._states.length - 1) {
      this._currentStateIndex++;
      const copy = _.cloneDeep(this._states[this._currentStateIndex]);
      return copy;
    }
    return undefined;
  }

  private debugStates() {
    for (const state of this._states) {
      const debug = (state as any);
      console.log(debug.index, debug._cover.objects[1].x)
    }
    console.log("===");
  }
}
