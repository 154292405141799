import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import {
  ModerationCoverRequest,
  ModerationCoverRequestPublishedFrom,
  ModerationCoverRequestUpdateData,
  ModerationCoverRequestsData,
} from "../../models/moderation-cover-request";

@Injectable({
  providedIn: "root",
})
export class AdminModerationCoverRequestApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async loadModerationCoverRequestsPaginated(page: number, publishedFrom: ModerationCoverRequestPublishedFrom) {
    return this.get<ModerationCoverRequestsData>("admin/moderation/covers/paginated", {
      page,
      publishedFrom,
    });
  }

  async acceptModerationCoverRequest(id: number) {
    return this.post<ModerationCoverRequest>(`admin/moderation/covers/${id}/accept`, {});
  }

  async declineModerationCoverRequest(id: number, data: ModerationCoverRequestUpdateData) {
    return this.post<ModerationCoverRequest>(`admin/moderation/covers/${id}/decline`, data);
  }
}
