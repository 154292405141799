import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BookCover } from "@metranpage/book-data";
import { CoverConceptualGeneration, CoverConceptualGenerationDataDto } from "@metranpage/text-generation";

@Component({
  selector: "m-cover-conceptual-assistant-generation-menu",
  templateUrl: "./cover-conceptual-assistant-generation-menu.view.html",
  styleUrls: ["./cover-conceptual-assistant-generation-menu.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverConceptualAssistantGenerationMenuView {
  @Input()
  cover!: BookCover;
  @Input()
  coverConceptualGeneration?: CoverConceptualGeneration;

  @Output()
  generateCoverConceptual = new EventEmitter<CoverConceptualGenerationDataDto>();
  @Output()
  closeConceptualAssistant = new EventEmitter<void>();

  protected promptAnnotationMaxLength = 1000;

  protected form = new FormGroup({
    promptAnnotation: new FormControl("", {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.pattern(/[\S]/g),
        Validators.minLength(10),
        Validators.maxLength(this.promptAnnotationMaxLength),
      ],
    }),
    promptTargetAudience: new FormControl("", { nonNullable: true, validators: [] }),
    promptGenre: new FormControl("", { nonNullable: true, validators: [] }),
  });

  ngOnInit() {
    this.showPreviousResults();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.coverConceptualGeneration) {
      this.coverConceptualGeneration = changes.coverConceptualGeneration.currentValue;
      this.showPreviousResults();
    }
  }

  private showPreviousResults() {
    if (this.coverConceptualGeneration) {
      this.form.patchValue(this.coverConceptualGeneration);
    }
  }

  async onGenerateCoverConceptualClick() {
    const formData = this.form.getRawValue();
    const data: CoverConceptualGenerationDataDto = {
      promptAnnotation: formData.promptAnnotation.replaceAll("<br>", " "),
      promptGenre: formData.promptGenre.replaceAll("<br>", " "),
      promptTargetAudience: formData.promptTargetAudience.replaceAll("<br>", " "),
      coverId: this.cover.id,
    };
    this.generateCoverConceptual.emit(data);
  }

  onCloseConceptualAssistantClick() {
    this.closeConceptualAssistant.emit();
  }
}
