import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PromoMenuButtonsService {
  webinarModalVisibility$ = new Subject<boolean>();

  onWebinarModalVisibilityChange(isVisible: boolean) {
    this.webinarModalVisibility$.next(isVisible);
  }
}
