import { ElementRef, Injectable, Renderer2 } from "@angular/core";
import * as _ from "lodash-es";
import { CellItem } from "../../../../../views/markup-editor/blocks/table-block/interfaces/cell-item.type";
import { Cell } from "../../../../../views/markup-editor/blocks/table-block/interfaces/cell.type";
import { TableData } from "../../../../../views/markup-editor/blocks/table-block/interfaces/table-data.interface";

@Injectable({
  providedIn: "root",
})
export class TableBlockService {
  setDataToHtml(data: TableData, editorContainer: ElementRef, renderer: Renderer2): void {
    const parent = editorContainer.nativeElement;
    while (parent.lastChild) {
      parent.removeChild(parent.lastChild);
    }

    parent.append(this.rawToHtml(data, renderer));
  }

  rawToHtml(data: TableData, renderer: Renderer2): Element {
    const root = renderer.createElement("table");

    const tableBody = renderer.createElement("tbody");
    this.addChilds(tableBody, data.rows, renderer);

    root.appendChild(tableBody);

    return root;
  }

  private addChilds(tableBody: HTMLElement, rowItems: Array<Cell[]>, renderer: Renderer2) {
    for (const rowItem of rowItems) {
      const row = this.createRow(rowItem, renderer);
      tableBody.appendChild(row);
    }
  }

  private createRow(rowItems: Cell[], renderer: Renderer2) {
    const row = renderer.createElement("tr");
    for (const rowItem of rowItems) {
      const cell = this.createCell(rowItem, renderer);

      row.appendChild(cell);
    }
    return row;
  }

  private createCell(rowItem: Cell, renderer: Renderer2) {
    const cell = renderer.createElement("td");

    if (rowItem.rowspan !== undefined && rowItem.rowspan > 1) {
      cell.rowSpan = rowItem.rowspan;
    }
    if (rowItem.colspan !== undefined && rowItem.colspan > 1) {
      cell.colSpan = rowItem.colspan;
    }

    const div = renderer.createElement("div");
    div.contentEditable = "true";

    if (rowItem.items) {
      for (const cellItem of rowItem.items) {
        if (cellItem.content) {
          div.innerHTML += cellItem.content;
          // div.innerHTML += '<br/>';
        }
      }
    }

    // div.addEventListener('blur', (event: any) => {
    //   this.saveData(data, event);
    // });

    cell.appendChild(div);

    return cell;
  }

  saveData(data: TableData, editorContainer: ElementRef) {
    _.assign(data, this.htmlToRaw(editorContainer.nativeElement));
  }

  htmlToRaw(element: Element): TableData {
    const data = this.parseElement(element.children[0]);

    return data!;
  }

  private parseElement(element: Element): TableData | undefined {
    if (!element.tagName) {
      return undefined;
    }
    const data: TableData = {
      rowsCount: 0,
      colsCount: 0,
      rows: [],
    };

    if (element.tagName.toLowerCase() === "table") {
      const tableElement = element as HTMLTableElement;
      const rows = tableElement.rows;
      data.rowsCount = rows.length;

      for (let i = 0; i < rows.length; i++) {
        const row = [] as Cell[];
        const cells = rows[i].cells;
        // if (data.colsCount < cells.length) {
        //   data.colsCount = cells.length;
        // }
        let cellCount = cells.length;
        for (let j = 0; j < cells.length; j++) {
          const rowItem = cells[j];
          const cell = {
            row: i,
            col: j,
            rowspan: rowItem?.rowSpan,
            colspan: rowItem?.colSpan,
            style: "",
            items: [] as CellItem[],
          } as Cell;

          const colspan = rowItem?.colSpan ?? 1;
          cellCount = cellCount + rowItem.colSpan - 1;

          const addCellItem = (text: string) => {
            cell.items?.push({ content: text });
          };

          if (rowItem.hasChildNodes()) {
            for (let k = 0; k < rowItem.childNodes?.length; k++) {
              const child = rowItem.childNodes[k];
              if (child instanceof Element) {
                addCellItem(child.innerHTML);
              }
            }
          } else {
            addCellItem("");
          }
          row.push(cell);
        }
        if (data.colsCount < cellCount) {
          data.colsCount = cellCount;
        }
        data.rows.push(row);
      }
    } else {
      return undefined;
    }

    return data;
  }
}
