import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { SelectValue } from "@metranpage/components";
import { AnalyticsService, RouterService } from "@metranpage/core";
import { I18nService } from "@metranpage/i18n";
import {
  GeneratedImage,
  GeneratedImageService,
  ImageGeneration,
  ImageGenerationAdvancedStyle,
  ImageGenerationBasicStyle,
  ImageGenerationDataService,
  ImageGenerationService,
  PublishedImageStore,
} from "@metranpage/image-generation";
import { UserStore } from "@metranpage/user-data";
import { UserVerificationOverlayServiceInterface } from "@metranpage/user-interfaces";
import { DateTime } from "luxon";
import { Subject, Subscription, debounceTime, timer } from "rxjs";
import { HomeService } from "../../../../services/home.service";

@Component({
  selector: "m-community-image-details-view",
  templateUrl: "image-details.view.html",
  styleUrls: ["image-details.view.scss"],
})
export class CommunityImageDetailsView implements OnInit, OnDestroy {
  @Input()
  image!: GeneratedImage;

  @Output()
  onUnpublish = new EventEmitter<void>();
  @Output()
  onLike = new EventEmitter<boolean>();

  protected stylesBasic: ImageGenerationBasicStyle[] = [];
  protected stylesAdvanced: ImageGenerationAdvancedStyle[] = [];
  protected models: SelectValue[] = [];
  protected locale = "en";

  protected avatarText = "";

  protected debounceTime = 500;

  protected isLiked = false;
  protected likesCount = 0;

  private likeClick = new Subject<Event>();
  sub: Subscription = new Subscription();

  constructor(
    private readonly imageGenerationService: ImageGenerationService,
    private readonly imageGenerationDataService: ImageGenerationDataService,
    private readonly generatedImageService: GeneratedImageService,
    private readonly router: Router,
    private readonly publishedImageStore: PublishedImageStore,
    private readonly userStore: UserStore,
    private readonly homeService: HomeService,
    private readonly routerService: RouterService,
    @Inject("UserVerificationOverlayService")
    protected readonly userVerificationOverlayService: UserVerificationOverlayServiceInterface,
    i18nService: I18nService,
    private readonly analytics: AnalyticsService,
  ) {
    this.locale = i18nService.getLocale();
  }

  async ngOnInit() {
    this.stylesBasic = await this.imageGenerationService.getImageGenerationBasicStyles();
    this.stylesAdvanced = await this.imageGenerationService.getImageGenerationAdvancedStyles();
    this.models = this.imageGenerationDataService.getModelOptions();

    this.updateAvatarText();

    this.isLiked = this.image.isLiked;
    this.likesCount = this.image.likesCount;
    this.watchLikeClick();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  protected onCopyPublishedImageSettings() {
    if (!this.userStore.getUser()) {
      this.homeService.showLoginModal();
      return;
    }

    this.analytics.event("copy-image-generation");

    this.publishedImageStore.setPublishedImageSettings(this.image?.generationSettings);
    this.router.navigateByUrl("/image-generation");
  }

  isAdvancedGeneration(generationSettings: ImageGeneration) {
    return this.imageGenerationService.isAdvancedGeneration(generationSettings);
  }

  protected canUnpublish() {
    return (
      this.userStore.isUserAdmin() ||
      (this.userStore.isUserHasPaidSubscription() && this.userStore.getUser()?.id === this.image?.userId)
    );
  }

  protected onShowUnpublishImageModal() {
    this.onUnpublish.emit();
  }

  protected getUrlForImage(generatedImage: GeneratedImage) {
    return this.generatedImageService.getUrlForImage(generatedImage.imageGenerationId, generatedImage.imageUrl);
  }

  protected getColorSchemeTitle(value: string | undefined) {
    let colorScheme = $localize`:@@image-generation.color-scheme-value.fullcolor:`;

    if (value === undefined) {
      return colorScheme;
    }

    const colorSchemeData = this.imageGenerationService.convertStringToColorScheme(value);

    if (colorSchemeData.colorScheme === "black and white") {
      colorScheme = $localize`:@@image-generation.color-scheme-value.black-and-white:`;
    }
    if (colorSchemeData.colorScheme === "custom") {
      colorScheme = `${$localize`:@@image-generation.color-scheme-value.colors:`} ${colorSchemeData.colors.join(" ")}`;
    }
    return colorScheme;
  }

  protected getDetalizationTitle(value: string) {
    return this.imageGenerationDataService.getDetalizationTitle(value);
  }

  protected getMoodTitle(value: string) {
    return this.imageGenerationDataService.getMoodTitle(value);
  }

  protected getModelTitle(value: string) {
    const model = this.models.find((m) => {
      return m.id === value;
    });
    return model?.value;
  }

  protected getRealisticTitle(value: string) {
    let realistic = $localize`:@@image-generation.realistic-value.styled:`;
    if (value === "hyperabstract") {
      realistic = $localize`:@@image-generation.realistic-value.hyperabstract:`;
    }
    if (value === "abstract") {
      realistic = $localize`:@@image-generation.realistic-value.abstract:`;
    }
    if (value === "realistic") {
      realistic = $localize`:@@image-generation.realistic-value.realistic:`;
    }
    if (value === "hyperrealistic") {
      realistic = $localize`:@@image-generation.realistic-value.hyperrealistic:`;
    }
    return realistic;
  }

  protected getStyleTitle(imageGeneration: ImageGeneration) {
    let styleTitle = this.getBasicStyleTitle(imageGeneration.styleId);
    if (this.isAdvancedGeneration(imageGeneration)) {
      styleTitle = this.getAdvancedStyleTitle(imageGeneration.styleId);
    }
    return styleTitle;
  }

  protected getBasicStyleTitle(id: number) {
    return this.stylesBasic.find((s) => s.id === id)?.title ?? "";
  }

  protected getAdvancedStyleTitle(id: number) {
    return this.stylesAdvanced.find((s) => s.id === id)?.title ?? "";
  }

  protected getCreationDate() {
    if (!this.image?.createdAt) {
      return;
    }
    const date = DateTime.fromISO(this.image.createdAt.toString()).toLocaleString(
      { year: "numeric", month: "long", day: "2-digit" },
      { locale: this.locale },
    );

    return date;
  }

  protected getTechniqueTitle(id: number) {
    return this.stylesAdvanced.find((t) => t.id === id)?.title ?? "";
  }

  protected onLikeClick(e: Event) {
    const user = this.userStore.getUser();
    if (!user) {
      this.homeService.showLoginModal();
      return;
    }
    if (!user.isVerified) {
      this.routerService.showInModal(undefined);
      timer(100).subscribe(() => {
        this.userVerificationOverlayService.open();
      });
      return;
    }

    this.isLiked = !this.isLiked;
    if (this.isLiked) {
      this.likesCount = this.likesCount + 1;
    } else {
      this.likesCount = this.likesCount - 1;
    }

    this.likeClick.next(e);
  }

  protected watchLikeClick() {
    this.sub.add(
      this.likeClick.pipe(debounceTime(this.debounceTime)).subscribe((e: Event) => {
        this.onLike.emit(this.isLiked);
      }),
    );
  }

  protected updateAvatarText() {
    const firstLetterOfFirstName = this.image.author?.firstName?.charAt(0)?.toUpperCase() || "";
    const firstLetterOfLastName = this.image.author?.lastName?.charAt(0)?.toUpperCase() || "";
    this.avatarText = `${firstLetterOfFirstName}${firstLetterOfLastName}`;
  }
}
