import { Component } from "@angular/core";
import {
  LoadingService,
  RewardNotification,
  RewardNotificationService,
  RewardNotificationStore,
  RewardsService,
  RewardsStore,
  UserRewardDaily,
  UserRewardOneTime,
} from "@metranpage/core";
import { User, UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";
import { UserService } from "../../services/user.service";

@Component({
  selector: "m-account-rewards-page",
  template: `
    <m-account-reward-tasks-list-view [user]="user" [rewardsDaily]="rewardsDaily" [rewardsOneTime]="rewardsOneTime" />
    <m-account-reward-notifications-view [user]="user" [rewardNotifications]="rewardNotifications" (onScroll)="onScroll()" />
  `,
  styleUrls: ["account-rewards.page.scss"],
})
export class AccountRewardsPage {
  protected user?: User;

  protected rewardNotifications: RewardNotification[] = [];

  protected timeoutForMarkRewardsAsRead = 5;

  protected rewardNotificationsPage = 1;
  protected rewardNotificationsPageCount = 1;

  protected rewardsDaily: UserRewardDaily[] = [];
  protected rewardsOneTime: UserRewardOneTime[] = [];

  sub: Subscription = new Subscription();

  constructor(
    private readonly loadingService: LoadingService,
    private readonly rewardNotificationService: RewardNotificationService,
    private readonly rewardNotificationStore: RewardNotificationStore,
    private readonly rewardsService: RewardsService,
    rewardsStore: RewardsStore,
    userStore: UserStore,
    readonly userService: UserService,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );

    this.sub.add(
      this.rewardNotificationStore.getRewardNotificationsObservable().subscribe((rewardNotifications) => {
        this.rewardNotifications = rewardNotifications;
      }),
    );

    this.sub.add(
      rewardsStore.getRewardsDailyObservable().subscribe((rewards) => {
        this.rewardsDaily = rewards;
      }),
    );

    this.sub.add(
      rewardsStore.getRewardsOneTimeObservable().subscribe((rewards) => {
        this.rewardsOneTime = rewards;
      }),
    );
  }

  async ngOnInit() {
    await this.loadRewardsPaginated();

    await this.rewardNotificationService.markRewardsAsRead();

    await this.rewardsService.loadRewards();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected async loadRewardsPaginated() {
    this.loadingService.startLoading({ fullPage: true });
    await this.rewardNotificationService.loadRewardsPaginated(this.rewardNotificationsPage);
    this.loadingService.stopLoading();
  }

  // protected async markRewardsAsRead() {
  //   setTimeout(async () => {
  //     await this.rewardService.markRewardsAsRead();
  //   }, this.timeoutForMarkRewardsAsRead * 1000);
  // }

  protected async onScroll() {
    this.rewardNotificationsPage++;
    await this.rewardNotificationService.loadRewardsPaginated(this.rewardNotificationsPage);
  }
}
