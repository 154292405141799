import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CompanyStore } from "@metranpage/company";
import { filterUndefined } from "@metranpage/core";
import { ActiveSubscription, PaymentCurrency, PromocodeDiscountData, Tariff } from "@metranpage/pricing-data";
import { Subscription } from "rxjs";
import { PricingViewService } from "../pricing.view-service";

type FeatureType =
  | "tokens_included"
  | "symbol_limits"
  | "image_generation"
  | "upload_limits"
  | "template_editing"
  | "layout_images"
  | "layout_tables"
  | "watermark"
  | "autopublish_in_community"
  | "output_pdf"
  | "output_epub"
  | "output_src_indd";

type FeatureDetails = {
  iconPath: string;
  iconColor?: string;
  description: string;
};

export type TariffCardAppearance = "full" | "compact";

type TariffChangeDirection = "downgrade" | "refresh" | "upgrade";

const iconsRoot = "/assets/icons";

@Component({
  selector: "m-pricing-tariff-card",
  templateUrl: "./tariff-card.view.html",
  styleUrls: ["./tariff-card.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TariffCardView implements OnInit, OnChanges {
  @Input()
  tariff!: Tariff;
  @Input()
  activeSubscription?: ActiveSubscription;
  @Input()
  appearance: TariffCardAppearance = "full";
  @Input()
  promocodeDiscountData?: PromocodeDiscountData;
  @Input()
  currency: PaymentCurrency = "RUB";

  @Output()
  onBuySubscriptionClick = new EventEmitter<Tariff>();

  features!: Record<FeatureType, FeatureDetails>;
  tariffChangeDirection?: TariffChangeDirection;
  priceWithoutDiscount?: string;
  discountPercent?: string;

  protected welcomeCreditsCount = 0;
  sub: Subscription = new Subscription();

  constructor(
    private readonly companyStore: CompanyStore,
    private readonly pricingViewService: PricingViewService,
  ) {
    this.sub.add(
      this.companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.welcomeCreditsCount = company.welcomeCreditsCount;
        }),
    );
  }

  ngOnInit(): void {
    this.constructFeatures();

    this.constructDiscountStrings();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeSubscription) {
      this.defineTariffChangeDirection();
    }

    if (changes.promocodeDiscountData) {
      this.constructDiscountStrings();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  constructDiscountStrings(): void {
    if (this.tariff.profit > 0) {
      const priceWithoutDiscount = this.tariff.price + this.tariff.profit;
      if (priceWithoutDiscount === 0) {
        return;
      }
      let discountPercent = 100 * (1 - this.tariff.price / priceWithoutDiscount);

      this.priceWithoutDiscount = this.pricingViewService.thousandsFormat(priceWithoutDiscount);
      this.discountPercent = `- ${Math.ceil(discountPercent)}%`;

      if (this.promocodeDiscountData) {
        discountPercent = discountPercent + this.promocodeDiscountData.discount;

        this.discountPercent = `- ${Math.ceil(discountPercent)}%`;
      }
    } else {
      if (this.promocodeDiscountData) {
        const priceWithoutDiscount = this.tariff.price;
        const discountPercent = this.promocodeDiscountData.discount;

        this.priceWithoutDiscount = this.pricingViewService.thousandsFormat(priceWithoutDiscount);
        this.discountPercent = `- ${Math.ceil(discountPercent)}%`;
      }
    }
  }

  protected defineTariffChangeDirection(): void {
    if (!this.activeSubscription) {
      this.tariffChangeDirection = "upgrade";
    } else if (this.activeSubscription.tariff.level === this.tariff.level && this.tariff.isFree) {
      this.tariffChangeDirection = undefined;
    } else if (this.activeSubscription.tariff.id === this.tariff.id && !this.tariff.isFree) {
      this.tariffChangeDirection = "refresh";
    } else if (
      this.tariff.level > this.activeSubscription.tariff.level ||
      (this.tariff.level === this.activeSubscription.tariff.level &&
        this.tariff.period > this.activeSubscription.tariff.period)
    ) {
      this.tariffChangeDirection = "upgrade";
    } else {
      this.tariffChangeDirection = "downgrade";
    }
  }

  protected getPrice() {
    let newPrice = this.tariff.price;

    const currencySymbol = this.pricingViewService.getCurrencySymbol(this.currency);

    if (this.promocodeDiscountData) {
      const priceWithoutDiscount = this.tariff.price + this.tariff.profit;
      if (priceWithoutDiscount === 0) {
        return this.pricingViewService.priceFormat(priceWithoutDiscount, currencySymbol);
      }
      const discountPercent = 100 * (1 - this.tariff.price / priceWithoutDiscount);
      newPrice =
        priceWithoutDiscount - (priceWithoutDiscount * (discountPercent + this.promocodeDiscountData.discount)) / 100;
    }
    return this.pricingViewService.priceFormat(newPrice, currencySymbol);
  }

  protected pluralizeMonths(months: number) {
    return `${months} ${this.pricingViewService.pluralizeMonths(months)} ${$localize`:@@pricing.tariffs.gift-time:`}`;
  }

  protected getSubtitleText(subtitle: string) {
    const subtitles = this.pricingViewService.getOptionsForSubtitleSelect();
    return subtitles.find((s) => s.id === subtitle)?.value ?? "";
  }

  protected getTokensText(): string {
    const tokens = this.tariff.isFree ? this.welcomeCreditsCount : this.tariff.creditsPerMonth * this.tariff.period;
    const strTokens = this.pricingViewService.thousandsFormat(tokens);
    return `${strTokens} ${this.pricingViewService.pluralizeCredits(tokens)}`;
  }

  protected getSymbolLimitsText(): string {
    let symbols = 0;
    let text = "~ ";
    if (this.tariff.isFree) {
      symbols = 80_000;
      text = `${$localize`:@@pricing.max-count-symbols-in-tariff-1.1:`} `;
    } else if (this.tariff.level === 2) {
      symbols = 500_000;
    } else if (this.tariff.level === 3) {
      symbols = 1_600_000;
    } else if (this.tariff.level === 4) {
      symbols = 6_000_000;
    }

    symbols *= this.tariff.period;

    return `${text} ${this.pricingViewService.symbolsFormat(symbols)} ${this.pricingViewService.pluralizeSymbols(
      symbols,
      false,
    )}`;
  }

  protected getImageGenerationText(): string {
    let images = 0;
    const text = `${$localize`:@@pricing.tariffs.generation:`} `;
    if (this.tariff.isFree) {
      images = 25;
    } else if (this.tariff.level === 2) {
      images = 250;
    } else if (this.tariff.level === 3) {
      images = 850;
    } else if (this.tariff.level === 4) {
      images = 2200;
    }

    images *= this.tariff.period;

    return `${text} ${this.pricingViewService.symbolsFormat(images)} ${this.pricingViewService.pluralizeImages(
      images,
    )}`;
  }

  protected hasPromocodeDiscount() {
    return this.tariff.price > 0 && !this.tariff.isFree && this.promocodeDiscountData;
  }

  protected constructFeatures() {
    this.features = {
      tokens_included: {
        iconPath: `${iconsRoot}/coins-01.svg`,
        iconColor: "--color-font-main",
        description: this.getTokensText(),
      },
      symbol_limits: {
        iconPath: `${iconsRoot}/paragraph-01.svg`,
        iconColor: "--color-font-main",
        description: this.getSymbolLimitsText(),
      },
      image_generation: {
        iconPath: `${iconsRoot}/image-01.svg`,
        iconColor: "--color-font-main",
        description: this.getImageGenerationText(),
      },
      upload_limits: {
        iconPath: `${iconsRoot}/${this.tariff.isFree ? "cross-01" : "checkmark-01"}.svg`,
        iconColor: this.tariff.isFree ? "--color-font-button-primary-danger" : "--color-font-main",
        description: `${$localize`:@@pricing.tariffs.features.ulimited-upload-size:`} `,
      },
      template_editing: {
        iconPath: `${iconsRoot}/${this.tariff.isFree ? "cross-01" : "checkmark-01"}.svg`,
        iconColor: this.tariff.isFree ? "--color-font-button-primary-danger" : "--color-font-main",
        description: `${$localize`:@@pricing.tariffs.features.template-editing:`} `,
      },
      layout_images: {
        iconPath: `${iconsRoot}/image-02.svg`,
        iconColor: "--color-font-main",
        description: `${$localize`:@@pricing.tariffs.features.layout-images:`} `,
      },
      layout_tables: {
        iconPath: `${iconsRoot}/${this.tariff.isFree ? "cross-01" : "table-01"}.svg`,
        iconColor: this.tariff.isFree ? "--color-font-button-primary-danger" : "--color-font-main",
        description: `${$localize`:@@pricing.tariffs.features.layout-tables:`} `,
      },
      watermark: {
        iconPath: `${iconsRoot}/${this.tariff.isFree ? "cross-01" : "checkmark-01"}.svg`,
        iconColor: this.tariff.isFree ? "--color-font-button-primary-danger" : "--color-font-main",
        description: `${$localize`:@@pricing.tariffs.features.no-watermark:`} `,
      },
      autopublish_in_community: {
        iconPath: `${iconsRoot}/${this.tariff.isFree ? "autopublish-auto" : "autopublish-manual"}.svg`,
        iconColor: this.tariff.isFree ? "--warning" : "--color-font-main",
        description: this.tariff.isFree
          ? $localize`:@@pricing.tariffs.features.autopublish-auto:`
          : $localize`:@@pricing.tariffs.features.autopublish-manual:`,
      },
      output_pdf: {
        iconPath: `${iconsRoot}/checkmark-01.svg`,
        iconColor: "--color-font-main",
        description: `${$localize`:@@pricing.tariffs.features.output-pdf:`} `,
      },
      output_epub: {
        iconPath: `${iconsRoot}/checkmark-01.svg`,
        iconColor: "--color-font-main",
        description: `${$localize`:@@pricing.tariffs.features.output-epub:`} `,
      },
      output_src_indd: {
        // iconPath: `${iconsRoot}/${this.tariff.isFree ? "cross-01" : "checkmark-01"}.svg`,
        // iconColor: this.tariff.isFree ? "--color-font-button-primary-danger" : "--color-font-main",
        iconPath: `${iconsRoot}/checkmark-01.svg`,
        iconColor: "--color-font-main",
        description: `${$localize`:@@pricing.tariffs.features.output-src-indd:`} `,
      },
    };
  }

  protected getAccrualPeriodText() {
    if (!this.tariff.isFree && this.tariff.period === 1) {
      return $localize`:@@pricing.tariffs.accrual-period.payment-once-a-month:`;
    }
    if (!this.tariff.isFree && this.tariff.period === 6) {
      return $localize`:@@pricing.tariffs.accrual-period.payment-once-a-half-year:`;
    }
    if (!this.tariff.isFree && this.tariff.period === 12) {
      return $localize`:@@pricing.tariffs.accrual-period.payment-once-a-year:`;
    }
    return $localize`:@@pricing.tariffs.accrual-period.tokens-one-time:`;
  }
}
