import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService, LoadingState } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { I18nService } from "@metranpage/i18n";
import { Theme, ThemeService } from "@metranpage/theme";
import { Observable } from "rxjs";
import { LoginError } from "../../models/login-errors";
import { AuthService } from "../../services/auth.service";
import { ResetPassData } from "../../views/password-restore-new-password/password-restore-new-password.view";

@Component({
  selector: "m-password-restore-new-password-page",
  templateUrl: "./password-restore-new-password.page.html",
  styleUrls: ["./password-restore-new-password.page.scss"],
})
export class PasswordRestoreNewPasswordPage implements OnInit {
  loadingState$: Observable<LoadingState>;

  loginErrors: LoginError[] = [];
  protected userLanguage;

  token?: string;

  protected colorTheme: Theme = "light";

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
    private readonly i18nService: I18nService,
    private readonly themeService: ThemeService,
    private readonly notificationsService: NotificationsPopUpService,
  ) {
    this.userLanguage = i18nService.getLocale();
    this.loadingState$ = this.loadingService.loadingState$;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.token = params["token"];
    });

    this.updateColorTheme();
  }

  async onResetPassClick(data: ResetPassData) {
    this.notificationsService.closeAll();

    if (!this.token) {
      this.notificationsService.error($localize`:@@user.auth.restore-pass-token-not-found-error:`);
      return;
    }

    this.loadingService.startLoading();
    const result = await this.authService.resetPassword(this.token, data.password);

    // clean errors after request due to ugly window size change when error hides and reappears half a second later
    this.loginErrors = [];

    if (result === "success") {
      this.router.navigate(["/"]);
    } else if (result === "restore-token-not-found") {
      this.notificationsService.error($localize`:@@user.auth.restore-pass-token-not-found-error:`);
    } else if (result === "network-error") {
      this.notificationsService.error($localize`:@@user.auth.generic-error:`);
    }

    this.loadingService.stopLoading();
  }

  protected updateColorTheme() {
    this.colorTheme = this.themeService.getTheme();
  }

  protected onColorThemeToggle() {
    this.themeService.toggleTheme();
    this.colorTheme = this.themeService.getTheme();
  }

  onLangChange(lang: string) {
    this.i18nService.saveLocale(lang);
    window.location.reload();
  }
}
