import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ApiErrorHandlerService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { ReferalLinkCreateData, ReferalLinkUpdateData } from "../../models/referal-link";
import { ReferalLinkStat } from "../admin.api";
import { AdminReferalLinksApi, ReferalLinksFilter } from "./referal-links.api";
import { AdminReferalLinksStore } from "./referal-links.store";

@Injectable({
  providedIn: "root",
})
export class AdminReferalLinksService {
  constructor(
    private readonly adminReferalLinksApi: AdminReferalLinksApi,
    private readonly adminReferalLinksStore: AdminReferalLinksStore,
    private readonly apiErrorHandler: ApiErrorHandlerService,
    private readonly notificationService: NotificationsPopUpService,
  ) {}

  async loadReferalLink(referalLinkId: number) {
    const referalLinkData = await this.adminReferalLinksApi.getReferalLink(referalLinkId);
    this.adminReferalLinksStore.setActiveReferalLink(referalLinkData.referalLink);
  }

  async loadReferalLinks() {
    return await this.adminReferalLinksApi.getReferalLinks();
  }

  async loadReferalLinksPaginated(page: number, filters: ReferalLinksFilter = {}) {
    const referalLinksData = await this.adminReferalLinksApi.getReferalLinksPaginated(page, filters);
    this.adminReferalLinksStore.setReferalLinks(referalLinksData.items);
    this.adminReferalLinksStore.setReferalLinksPageCount(referalLinksData.pageCount);
  }

  async updateReferalLink(referalLinkId: number, referalLink: ReferalLinkUpdateData) {
    try {
      await this.adminReferalLinksApi.updateReferalLink(referalLinkId, referalLink);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "error";
    }
  }

  async createReferalLink(data: ReferalLinkCreateData): Promise<{ status: GeneralResultStatus; id?: number }> {
    try {
      const newReferalLink = await this.adminReferalLinksApi.createReferalLink(data);
      return {
        status: "success",
        id: newReferalLink.id,
      };
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return {
        status: "error",
        id: undefined,
      };
    }
  }

  async deleteReferalLink(referalLinkId: number): Promise<{ status: GeneralResultStatus }> {
    try {
      const result = await this.adminReferalLinksApi.deleteReferalLink(referalLinkId);
      return {
        status: "success",
      };
    } catch (error: any) {
      return {
        status: "error",
      };
    }
  }

  async updateReferalLinkStat(referalLinkId: number): Promise<ReferalLinkStat[]> {
    return await this.adminReferalLinksApi.updateReferalLinkStat(referalLinkId);
  }

  createReferalLinkForm() {
    return new FormGroup({
      token: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(/^[a-zA-Z0-9-]+$/)],
      }),
      agentFee: new FormControl(0, { nonNullable: true, validators: [Validators.required, Validators.max(100)] }),
    });
  }

  getReferalLink(landingUrl: string, token: string) {
    let url = landingUrl;

    if (!url?.endsWith("/")) {
      url += "/";
    }

    return `${url}?referalToken=${token}`;
  }

  copyText(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.notificationService.notify({
          content: $localize`:@@common.text-copied-to-clipboard-success:`,
          type: "success",
        });
      })
      .catch((e) => {
        this.notificationService.error($localize`:@@common.text-copied-to-clipboard-failed:`);
      });
  }
}
