import { Injectable } from "@angular/core";
import { PaletteDTO, PaletteHEX } from "../models/editor";

export type Position = { x: number; y: number };
export type RGB = { r: number; g: number; b: number };
export type HSL = { h: number; s: number | string; l: number | string };
export type HSV = { h: number; s: number; v: number };
export type CMYK = { c: number; m: number; y: number; k: number };

const regexHexColor = /^#?([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;
const regexColorWithHashSymbol = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;
const regexColorWithoutHashSymbol = /^([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;

@Injectable({
  providedIn: "root",
})
export class ColorConverterService {
  convertPaletteHexToHsv(paletteHex: PaletteHEX): PaletteDTO {
    return {
      colorAccent: this.color2hsv(paletteHex.colorAccent),
      colorPrimary: this.color2hsv(paletteHex.colorPrimary),
      colorSecondary: this.color2hsv(paletteHex.colorSecondary),
      colorDecor: this.color2hsv(paletteHex.colorDecor),
    };
  }

  convertPaletteHsvToHex(palette: PaletteDTO): PaletteHEX {
    const defaultHSVColor = { h: 0, s: 0, v: 0 };
    return {
      colorAccent: this.hsv2hex(palette.colorAccent ?? defaultHSVColor),
      colorPrimary: this.hsv2hex(palette.colorPrimary ?? defaultHSVColor),
      colorSecondary: this.hsv2hex(palette.colorSecondary ?? defaultHSVColor),
      colorDecor: this.hsv2hex(palette.colorDecor ?? defaultHSVColor),
    };
  }

  toDigits(color: string) {
    const array: number[] = [];

    const values = color.match(/\d+/g);
    if (!values || values?.length === 0) {
      return array;
    }

    for (let i = 0; i < values?.length; i++) {
      array.push(parseInt(values[i]));
    }

    return array;
  }

  normalizeColor(color: HSV | null | undefined) {
    if (!color) {
      return "#000";
    }
    return this.hsv2hex(color);
  }

  color2hex(color: string | null | undefined) {
    if (!color) {
      return "#000";
    }
    if (
      !this.colorName2hex(color) &&
      !regexColorWithHashSymbol.exec(color) &&
      regexColorWithoutHashSymbol.exec(color)
    ) {
      color = `#${color}`;
    }

    return color;
  }

  color2hsv(color: string) {
    let hsv = { h: 0, s: 0, v: 0 };
    let rgb: RGB | undefined = undefined;
    if (this.colorName2hex(color)) {
      rgb = this.hex2rgb(this.colorName2hex(color));
    } else if (regexHexColor.exec(color)) {
      rgb = this.hex2rgb(color);
    } else if (color.includes("hsl")) {
      const [h, s, l] = this.toDigits(color);
      rgb = this.hsl2rgb(h, s, l);
    } else if (color.includes("rgb")) {
      const [r, g, b] = this.toDigits(color);
      rgb = { r, g, b };
    }
    if (rgb) {
      hsv = this.rgb2hsv(rgb.r, rgb.g, rgb.b);
    }
    return hsv;
  }

  rgb2hsl(r: number, g: number, b: number) {
    r /= 255;
    g /= 255;
    b /= 255;
    const l = Math.max(r, g, b);
    const s = l - Math.min(r, g, b);
    const h = s ? (l === r ? (g - b) / s : l === g ? 2 + (b - r) / s : 4 + (r - g) / s) : 0;
    return {
      h: Math.round(60 * h < 0 ? 60 * h + 360 : 60 * h),
      s: Math.round(100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0)),
      l: Math.round((100 * (2 * l - s)) / 2),
    };
  }

  hsl2rgb(h: number, s: number, l: number) {
    s /= 100;
    l /= 100;
    const k = (n: number) => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n: number) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    return {
      r: 255 * f(0),
      g: 255 * f(8),
      b: 255 * f(4),
    };
  }

  rgb2hex(r: number, g: number, b: number) {
    return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;
  }

  hex2rgb(hex: string) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : undefined;
  }

  rgb2hsv(r: number, g: number, b: number) {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    let h = 0;
    let s = 0;
    const v = max;

    const d = max - min;

    s = max === 0 ? 0 : d / max;

    if (min !== max) {
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }

    return { h: h * 360, s: s * 100, v: v * 100 };
  }

  hsv2hex(hsv: HSV) {
    const rgb = this.hsv2rgb(hsv.h, hsv.s, hsv.v);
    return this.rgb2hex(rgb.r, rgb.g, rgb.b);
  }

  hsv2rgb(h: number, s: number, v: number) {
    h /= 360;
    s /= 100;
    v /= 100;

    let r = 0;
    let g = 0;
    let b = 0;

    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);

    switch (i % 6) {
      case 0: {
        r = v;
        g = t;
        b = p;
        break;
      }
      case 1: {
        r = q;
        g = v;
        b = p;
        break;
      }
      case 2: {
        r = p;
        g = v;
        b = t;
        break;
      }
      case 3: {
        r = p;
        g = q;
        b = v;
        break;
      }
      case 4: {
        r = t;
        g = p;
        b = v;
        break;
      }
      case 5: {
        r = v;
        g = p;
        b = q;
        break;
      }
    }

    return { r: r * 255, g: g * 255, b: b * 255 };
  }

  hsl2hsv(hslH: number, hslS: number, hslL: number) {
    const hsv1 = (hslS * (hslL < 50 ? hslL : 100 - hslL)) / 100;
    const hsvS = hsv1 === 0 ? 0 : ((2 * hsv1) / (hslL + hsv1)) * 100;
    const hsvV = hslL + hsv1;

    return { h: hslH, s: hsvS, v: hsvV };
  }

  hsv2hsl(hsvH: number, hsvS: number, hsvV: number) {
    const hslL = ((200 - hsvS) * hsvV) / 100;
    const [hslS, hslV] = [
      hslL === 0 || hslL === 200 ? 0 : ((hsvS * hsvV) / 100 / (hslL <= 100 ? hslL : 200 - hslL)) * 100,
      (hslL * 5) / 10,
    ];

    return { h: hsvH, s: hslS, l: hslV };
  }

  rgb2css(rgb: RGB) {
    return `rgb(${Math.round(rgb.r)},${Math.round(rgb.g)},${Math.round(rgb.b)})`;
  }

  cmyk2hsv(c: number, m: number, y: number, k: number) {
    const rgb = this.cmyk2rgb({ c, m, y, k });
    return this.rgb2hsv(rgb.r, rgb.g, rgb.b);
  }

  hsv2cmyk(h: number, s: number, v: number) {
    const rgb = this.hsv2rgb(h, s, v);
    return this.rgb2cmyk(rgb.r, rgb.g, rgb.b);
  }

  rgb2cmyk(r: number, g: number, b: number) {
    let c = 1 - r / 255;
    let m = 1 - g / 255;
    let y = 1 - b / 255;
    let k = Math.min(c, Math.min(m, y));

    if (r === 0 && g === 0 && b === 0) {
      // c = 0;
      // m = 0;
      // y = 0;
    } else {
      c = ((c - k) / (1 - k)) * 100;
      m = ((m - k) / (1 - k)) * 100;
      y = ((y - k) / (1 - k)) * 100;
    }
    k *= 100;

    return { c: c, m: m, y: y, k: k };
  }

  cmyk2rgb(cmyk: CMYK) {
    const c = cmyk.c / 100;
    const m = cmyk.m / 100;
    const y = cmyk.y / 100;
    const k = cmyk.k / 100;

    let r = (1 - c) * (1 - k);
    let g = (1 - m) * (1 - k);
    let b = (1 - y) * (1 - k);

    r = Math.round(255 * r);
    g = Math.round(255 * g);
    b = Math.round(255 * b);

    return { r, g, b };
  }

  colorName2hex(colorName: string) {
    const colorsName = {
      aliceblue: "#f0f8ff",
      antiquewhite: "#faebd7",
      aqua: "#00ffff",
      aquamarine: "#7fffd4",
      azure: "#f0ffff",
      beige: "#f5f5dc",
      bisque: "#ffe4c4",
      black: "#000000",
      blanchedalmond: "#ffebcd",
      blue: "#0000ff",
      blueviolet: "#8a2be2",
      brown: "#a52a2a",
      burlywood: "#deb887",
      cadetblue: "#5f9ea0",
      chartreuse: "#7fff00",
      chocolate: "#d2691e",
      coral: "#ff7f50",
      cornflowerblue: "#6495ed",
      cornsilk: "#fff8dc",
      crimson: "#dc143c",
      cyan: "#00ffff",
      darkblue: "#00008b",
      darkcyan: "#008b8b",
      darkgoldenrod: "#b8860b",
      darkgray: "#a9a9a9",
      darkgreen: "#006400",
      darkkhaki: "#bdb76b",
      darkmagenta: "#8b008b",
      darkolivegreen: "#556b2f",
      darkorange: "#ff8c00",
      darkorchid: "#9932cc",
      darkred: "#8b0000",
      darksalmon: "#e9967a",
      darkseagreen: "#8fbc8f",
      darkslateblue: "#483d8b",
      darkslategray: "#2f4f4f",
      darkturquoise: "#00ced1",
      darkviolet: "#9400d3",
      deeppink: "#ff1493",
      deepskyblue: "#00bfff",
      dimgray: "#696969",
      dodgerblue: "#1e90ff",
      firebrick: "#b22222",
      floralwhite: "#fffaf0",
      forestgreen: "#228b22",
      fuchsia: "#ff00ff",
      gainsboro: "#dcdcdc",
      ghostwhite: "#f8f8ff",
      gold: "#ffd700",
      goldenrod: "#daa520",
      gray: "#808080",
      green: "#008000",
      greenyellow: "#adff2f",
      honeydew: "#f0fff0",
      hotpink: "#ff69b4",
      indianred: "#cd5c5c",
      indigo: "#4b0082",
      ivory: "#fffff0",
      khaki: "#f0e68c",
      lavender: "#e6e6fa",
      lavenderblush: "#fff0f5",
      lawngreen: "#7cfc00",
      lemonchiffon: "#fffacd",
      lightblue: "#add8e6",
      lightcoral: "#f08080",
      lightcyan: "#e0ffff",
      lightgoldenrodyellow: "#fafad2",
      lightgrey: "#d3d3d3",
      lightgreen: "#90ee90",
      lightpink: "#ffb6c1",
      lightsalmon: "#ffa07a",
      lightseagreen: "#20b2aa",
      lightskyblue: "#87cefa",
      lightslategray: "#778899",
      lightsteelblue: "#b0c4de",
      lightyellow: "#ffffe0",
      lime: "#00ff00",
      limegreen: "#32cd32",
      linen: "#faf0e6",
      magenta: "#ff00ff",
      maroon: "#800000",
      mediumaquamarine: "#66cdaa",
      mediumblue: "#0000cd",
      mediumorchid: "#ba55d3",
      mediumpurple: "#9370d8",
      mediumseagreen: "#3cb371",
      mediumslateblue: "#7b68ee",
      mediumspringgreen: "#00fa9a",
      mediumturquoise: "#48d1cc",
      mediumvioletred: "#c71585",
      midnightblue: "#191970",
      mintcream: "#f5fffa",
      mistyrose: "#ffe4e1",
      moccasin: "#ffe4b5",
      navajowhite: "#ffdead",
      navy: "#000080",
      oldlace: "#fdf5e6",
      olive: "#808000",
      olivedrab: "#6b8e23",
      orange: "#ffa500",
      orangered: "#ff4500",
      orchid: "#da70d6",
      palegoldenrod: "#eee8aa",
      palegreen: "#98fb98",
      paleturquoise: "#afeeee",
      palevioletred: "#d87093",
      papayawhip: "#ffefd5",
      peachpuff: "#ffdab9",
      peru: "#cd853f",
      pink: "#ffc0cb",
      plum: "#dda0dd",
      powderblue: "#b0e0e6",
      purple: "#800080",
      rebeccapurple: "#663399",
      red: "#ff0000",
      rosybrown: "#bc8f8f",
      royalblue: "#4169e1",
      saddlebrown: "#8b4513",
      salmon: "#fa8072",
      sandybrown: "#f4a460",
      seagreen: "#2e8b57",
      seashell: "#fff5ee",
      sienna: "#a0522d",
      silver: "#c0c0c0",
      skyblue: "#87ceeb",
      slateblue: "#6a5acd",
      slategray: "#708090",
      snow: "#fffafa",
      springgreen: "#00ff7f",
      steelblue: "#4682b4",
      tan: "#d2b48c",
      teal: "#008080",
      thistle: "#d8bfd8",
      tomato: "#ff6347",
      turquoise: "#40e0d0",
      violet: "#ee82ee",
      wheat: "#f5deb3",
      white: "#ffffff",
      whitesmoke: "#f5f5f5",
      yellow: "#ffff00",
      yellowgreen: "#9acd32",
    } as any;
    return colorsName[colorName.toLowerCase()] || undefined;
  }
}
