import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { LoadingService, NotificationsPopUpService } from "@metranpage/core";
import { PromocodeDiscountData, PromocodeEnterResult } from "@metranpage/pricing-data";
import { DateTime } from "luxon";
import { PricingService } from "../../services/pricing.service";
import { I18nService } from "@metranpage/i18n";

@Component({
  selector: "m-promocode-enter-menu",
  templateUrl: "./promocode-enter-menu.view.html",
  styleUrls: ["./promocode-enter-menu.view.scss"],
})
export class PromocodeEnterMenuView {
  @Input()
  promocodeResult?: PromocodeEnterResult;
  @Input()
  promocodeDiscountData?: PromocodeDiscountData;
  @Output()
  promocodeEnter = new EventEmitter<string>();

  protected form = new FormGroup({
    promocode: new FormControl("", { nonNullable: true, validators: [] }),
  });

  protected locale = "en";

  constructor(i18nService: I18nService) {
    this.locale = i18nService.getLocale();
  }

  protected async onEnterPromocodeClick() {
    const promocode = this.form.get("promocode")?.value.trim();
    if (!promocode) {
      return;
    }
    this.promocodeEnter.emit(promocode);
  }

  protected onCloseClick() {
    this.promocodeResult = undefined;
  }

  protected getDateShort(date: Date) {
    const dayAndMonth = DateTime.fromISO(date.toString()).toLocaleString(
      { day: "numeric", month: "long" },
      {
        locale: this.locale,
      },
    );
    const year = DateTime.fromISO(date.toString()).toFormat("yyyy");
    return `${dayAndMonth} ${year}`;
  }
}
