import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CoverObject, CoverObjectType, GroupObject } from "@metranpage/book-data";
import { User } from "@metranpage/user-data";
import { CoverUiService } from "../../services/cover/cover-ui.service";

@Component({
  selector: "m-cover-object-list",
  templateUrl: "./cover-object-list.component.html",
  styleUrls: ["./cover-object-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverObjectListComponent implements OnChanges {
  @Input() objects!: CoverObject[];
  @Input() currentObject?: CoverObject;
  @Input() selectedObjects: CoverObject[] = [];
  @Input() user!: User;

  @Input() isGroup = false;
  @Input() listId!: string;
  @Input() listIds: string[] = [];

  @Output() current = new EventEmitter<CoverObject[]>();

  CoverObjectType = CoverObjectType;

  reversedObjects!: CoverObject[];

  isShiftPressed = false;

  constructor(private readonly _coverUiService: CoverUiService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.objects) {
      this.reversedObjects = this.objects.slice().reverse();
    }
  }

  get connectedTo(): string[] {
    /* if (this.isGroup) {
      return [];
    } */
    return this.listIds.filter((v) => v !== this.listId);
  }

  get visibilityIcon(): string {
    if (this.currentObject!.isVisible) {
      return "/assets/icons/eye.svg";
    }
    return "/assets/icons/eye-close.svg";
  }

  get lockIcon(): string {
    return `/assets/icons/${this.currentObject?.isLocked ? "locked-01.svg" : "unlocked-01.svg"}`;
  }

  isSelected(object: CoverObject): boolean {
    return this.selectedObjects.some((v) => v === object);
  }

  isGroupObject(object: CoverObject): boolean {
    return object instanceof GroupObject;
  }

  onObjectSelected(object: CoverObject) {
    let objects = [...this.selectedObjects];
    if (!this.isShiftPressed) {
      this.current.emit([object]);
      return;
    }
    if (objects.some((v) => v === object)) {
      objects = objects.filter((v) => v !== object);
    } else {
      objects.push(object);
    }
    this.current.emit(objects);
  }

  reorderCoverObjects(event: CdkDragDrop<CoverObject[]>) {
    this._coverUiService.reorder(event);
  }

  onDragStarted() {
    window.document.body.style.cursor = "grabbing";
  }

  onDragReleased() {
    window.document.body.style.cursor = "initial";
  }

  dragDropPredicate(drag: CdkDrag<any>, drop: CdkDropList<any>) {
    if (drop.id !== "parent" && drag.element.nativeElement.classList.contains("group")) {
      return false;
    }
    return true;
  }

  @HostListener("keydown", ["$event"])
  onKeydown(event: KeyboardEvent) {
    if (event.key === "Shift") {
      this.isShiftPressed = true;
    }
  }

  @HostListener("keyup", ["$event"])
  onKeyup(event: KeyboardEvent) {
    if (event.key === "Shift") {
      this.isShiftPressed = false;
    }
  }
}
