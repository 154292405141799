<div class="wrapper">
  <div class="section">
    <div class="rowset">
      <div class="caption" i18n="@@cover-editor.object.settings.group.object-colors">
        cover-editor.object.settings.group.object-colors
      </div>
      <div class="colors-wrapper">
        <div class="color-wrapper" *ngFor="let color of colors; index as i">
          <m-cover-color-select
            [color]="color"
            [useColorPickerExternalHost]="true"
            [popupPosition]="'bottom'"
            [popupSize]="'compact'"
            (update)="onColorUpdate(i, $event)"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="section" *ngIf="tones.length > 1">
    <div class="rowset">
      <div class="caption" i18n="@@cover-editor.object.settings.group.object-tones">
        cover-editor.object.settings.group.object-tones
      </div>
      <div class="colors-wrapper">
        <div class="color-wrapper" *ngFor="let tone of tones; index as i">
          <m-cover-color-select
            [color]="tone"
            [useColorPickerExternalHost]="true"
            [popupPosition]="'bottom'"
            [popupSize]="'compact'"
            [enableOpacity]="false"
            (update)="onToneUpdate(i, $event)"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="rowset">
      <div class="row space-between picker-host">
        <div class="caption" i18n="@@cover-editor.object.settings.group.general-tone">
          cover-editor.object.settings.group.general-tone
        </div>
        <div>
          <m-cover-color-select
            [color]="generalTone"
            [useColorPickerExternalHost]="true"
            [popupPosition]="'bottom'"
            [popupSize]="'compact'"
            [enableOpacity]="false"
            (update)="onGeneralToneUpdate($event)"
          />
        </div>
      </div>
      <!--  <m-button
        button-style="secondary"
        size="full-width"
        icon-position="left"
        icon="checkmark-02.svg"
        (click)="applyGeneralTone(generalTone)"
        i18n="@@cover-editor.object.settings.group.button.apply-general-tone"
      >
        cover-editor.object.settings.group.button.apply-general-tone
      </m-button> -->
    </div>
  </div>
</div>
