import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { User } from "../../models/user";

export type State = {
  users: User[];
  usersPageCount: number;
};

@Injectable({
  providedIn: "root",
})
export class AdminUsersStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      users: [],
      usersPageCount: 1,
    };
  }

  getUsersObservable() {
    return this.getFieldObservable("users");
  }

  setUsers(users: User[]) {
    this.update((state) => ({ ...state, users }));
  }

  getUsersPageCountObservable() {
    return this.getFieldObservable("usersPageCount");
  }

  setUsersPageCount(count: number) {
    this.update((state) => ({ ...state, usersPageCount: count }));
  }
}
