import { Component, Input, OnInit, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import * as _ from "lodash-es";

export type RunningTitlesPositions = {
  value: number;
  disabled: boolean;
};

@Component({
  selector: "m-running-titles-position-group",
  templateUrl: "./running-titles-position-group.component.html",
  styleUrls: ["./running-titles-position-group.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RunningTitlesPositionGroupComponent),
      multi: true,
    },
  ],
})
export class RunningTitlesPositionGroupComponent implements OnInit, ControlValueAccessor {
  @Input()
  positions: RunningTitlesPositions[] = [];

  protected isDisabled = false;
  protected value: string | number = "";

  private onTouched = () => {};
  private onChange = (_: any) => {};

  ngOnInit(): void {
    this.moveDisabledToEnd();
  }

  writeValue(value: string | number): void {
    this.value = value;
    this.moveDisabledToEnd();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  protected onSelect(value: string | number) {
    if (this.isRadioDisabled(value)) {
      return;
    }
    this.value = value;
    this.onChange(this.value);
  }

  isRadioChecked(value: string | number) {
    return value === this.value;
  }

  isRadioDisabled(value: string | number) {
    return this.positions.find((element) => element.value === value)?.disabled || false;
  }

  moveDisabledToEnd() {
    this.positions = _.orderBy(this.positions, ["disabled"], ["asc"]);
  }
}
