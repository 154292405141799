import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

type PageItem = {
  text: string;
  page: number;
};

@Component({
  selector: 'm-admin-pagination',
  template: `
    <p
      *ngFor="let page of pages"
      class="page"
      (click)="onPageChanged.emit(page.page)"
      [class.active]="currentPage == page.page"
      [class.disabled]="page.page == -1"
    >
      {{ page.text }}
    </p>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .page {
        display: inline-block;
        padding: 8px;
        margin: 6px;
        border: 1px solid var(--color-bg-panel-border);
        border-radius: 7px;
        background-color: var(--color-bg-elevated);
        cursor: pointer;

        &:hover {
          background-color: var(--color-bg-elevated-hover);
        }

        &.active {
          border-color: var(--accent);
        }
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export class Pagination {
  @Input()
  currentPage!: number;
  @Input()
  pageCount!: number;
  @Output()
  onPageChanged = new EventEmitter<number>();

  protected pages: PageItem[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pageCount) {
      this.pageCount = changes.pageCount.currentValue;

      this.setupPages();
    }
  }

  private setupPages() {
    this.pages = [];

    for (let i = 0; i < this.pageCount; i++) {
      this.pages.push({
        page: i + 1,
        text: `${i + 1}`,
      });
    }
  }
}
