import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import * as _ from "lodash-es";
import { takeUntil } from "rxjs";
import { RewardOneTime } from "../../../models/reward-onetime";
import { AdminRewardsOneTimeService } from "../../../services/rewards/rewards-onetime/rewards-onetime.service";
import { AdminRewardsOneTimeStore } from "../../../services/rewards/rewards-onetime/rewards-onetime.store";
import { AdminBasePage } from "../../admin/admin.page";

@Component({
  selector: "m-admin-reward-onetime-page",
  templateUrl: "./reward-onetime.page.html",
  styleUrls: ["./reward-onetime.page.scss"],
})
export class AdminRewardOneTimePage extends AdminBasePage implements OnInit, AfterViewInit {
  rewardOneTime?: RewardOneTime;

  protected form!: FormGroup;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly adminRewardsOneTimeService: AdminRewardsOneTimeService,
    adminRewardsOneTimeStore: AdminRewardsOneTimeStore,
  ) {
    super();

    this.createForm();

    this.addSub(
      adminRewardsOneTimeStore
        .getActiveRewardOneTimeObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((rewardOneTime) => {
          this.rewardOneTime = rewardOneTime;
          if (rewardOneTime) {
            const formValues = _.omit(rewardOneTime, ["id"]);
            this.form.patchValue(formValues);
          }
        }),
    );
  }

  ngOnInit(): void {}

  async ngAfterViewInit() {
    if ("rewardOneTimeId" in this.route.snapshot.params) {
      this.adminRewardsOneTimeService.loadRewardOneTime(this.route.snapshot.params["rewardOneTimeId"]);
    }
  }

  async onSave() {
    if (!this.rewardOneTime) {
      return;
    }

    const value = this.form.getRawValue();

    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminRewardsOneTimeService.updateRewardOneTime(this.rewardOneTime.id, value);
    this.loadingService.stopLoading();
    if (result === "success") {
      this.router.navigate(["admin", "rewards-onetime"]);
    } else {
      this.notificationService.error($localize`:@@admin.rewards.onetime.error.cant-update:`);
    }
  }

  onCancel() {
    this.router.navigate(["admin", "rewards-onetime"]);
  }

  createForm() {
    this.form = this.adminRewardsOneTimeService.createRewardOneTimeForm();
  }

  protected getTypeSelectOptions() {
    return this.adminRewardsOneTimeService.getAllRewardsOneTimeTypeSelectOptions();
  }
}
