import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { InjectionToken, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CoreModule } from "@metranpage/core";
import { ImageGenerationModule } from "@metranpage/image-generation";
import { MarkupEditorModule } from "@metranpage/markup-editor";
import { PricingModule } from "@metranpage/pricing";
import { NgScrollbarModule } from "ngx-scrollbar";
import { ClickOutsideDetectorDirective } from "./directives/clickoutsidedetector/clickoutsidedetector.directive";
import { ContenteditableDirective } from "./directives/contenteditable/contenteditable.directive";
import { BooksPage } from "./pages/books/books.page";
import { CoverPage } from "./pages/cover/cover.page";
import { MarginsPage } from "./pages/margins/margins.page";
import { MarkupPage } from "./pages/markup/markup.page";
import { PreviewPage } from "./pages/preview/preview.page";
import { RunningTitlesPage } from "./pages/running-titles/running-titles.page";
import { BookService } from "./services/book.service";
import { CoverTextStorage } from "./services/cover/text-storage/cover-text-storage";
import { CoverTextLocalStorage } from "./services/cover/text-storage/local-storage/cover-text-local-storage";
import { AgeRestrictionsModalView } from "./views/age-restrictions-modal/age-restrictions-modal.view";
import { BookCardActionView } from "./views/book-card/book-card-action.view";
import { BookCardDownloadView } from "./views/book-card/book-card-download.view";
import { BookCardEditView } from "./views/book-card/book-card-edit.view";
import { BookCardView } from "./views/book-card/book-card.view";
import { BookEditModalView } from "./views/book-edit-modal/book-edit-modal.view";
import { BookPrintSettingsComponent } from "./views/book-print-settings/book-print-settings.component";
import { ColorPaletteComponent } from "./views/color-palette/color-palette.component";
import { CoverColorSelectComponent } from "./views/cover-color-select/cover-color-select.component";
import { CoverCompletionModalComponent } from "./views/cover-completion-modal/cover-completion-modal.component";
import { CoverConceptualAssistantGenerationHostView } from "./views/cover-conceptual-assistant-generation-host/cover-conceptual-assistant-generation-host.view";
import { CoverConceptualAssistantGenerationMenuSectionView } from "./views/cover-conceptual-assistant-generation-menu-section/cover-conceptual-assistant-generation-menu-section.view";
import { CoverConceptualAssistantGenerationMenuView } from "./views/cover-conceptual-assistant-generation-menu/cover-conceptual-assistant-generation-menu.view";
import { CoverConceptualAssistantGenerationResultFontCardView } from "./views/cover-conceptual-assistant-generation-result-font-card/cover-conceptual-assistant-generation-result-font-card.view";
import { CoverConceptualAssistantGenerationResultStyleCardView } from "./views/cover-conceptual-assistant-generation-result-style-card/cover-conceptual-assistant-generation-result-style-card.view";
import { CoverConceptualAssistantGenerationResultView } from "./views/cover-conceptual-assistant-generation-result/cover-conceptual-assistant-generation-result.view";
import { CoverConceptualAssistantGenerationView } from "./views/cover-conceptual-assistant-generation/cover-conceptual-assistant-generation.view";
import { CoverFillSelectComponent } from "./views/cover-fill-select/cover-fill-select.component";
import { CoverGroupObjectSettingsComponent } from "./views/cover-group-object-settings/cover-group-object-settings.component";
import { CoverImageObjectSettingsComponent } from "./views/cover-image-object-settings/cover-image-object-settings.component";
import { CoverImageSelectionModalComponent } from "./views/cover-image-selection-modal/cover-image-selection-modal.component";
import { CoverLayersSectionComponent } from "./views/cover-layers-section/cover-layers-section.component";
import { CoverMulticolorSettingsComponent } from "./views/cover-multicolor-settings/cover-multicolor-settings.component";
import { CoverMultiselectSettingsComponent } from "./views/cover-multiselect-settings/cover-multiselect-settings.component";
import { CoverObjectAlignmentComponent } from "./views/cover-object-alignment/cover-object-alignment.component";
import { CoverObjectCreateComponent } from "./views/cover-object-create/cover-object-create.component";
import { CoverObjectGradientComponent } from "./views/cover-object-gradient/cover-object-gradient.component";
import { CoverObjectListItemComponent } from "./views/cover-object-list-item/cover-object-list-item.component";
import { CoverObjectListComponent } from "./views/cover-object-list/cover-object-list.component";
import { CoverObjectSettingsComponent } from "./views/cover-object-settings/cover-object-settings.component";
import { CoverSelectedColorComponent } from "./views/cover-selected-color/cover-selected-color.component";
import { CoverShapeObjectSettingsComponent } from "./views/cover-shape-object-settings/cover-shape-object-settings.component";
import { CoverSidebarSectionView } from "./views/cover-sidebar-section/cover-sidebar-section.view";
import { CoverTemplateListComponent } from "./views/cover-template-list/cover-template-list.component";
import { CoverTextObjectSettingsComponent } from "./views/cover-text-object-settings/cover-text-object-settings.component";
import { CoverView } from "./views/cover/cover.view";
import { DeleteProjectModal } from "./views/delete-project-modal/delete-project-modal.view";
import { EditProjectModalView } from "./views/edit-project-modal/edit-project-modal.view";
import { FontSelectComponent } from "./views/font-select/font-select.view";
import { FontSizeSelector } from "./views/font-size-selector/font-size-selector.view";
import { FreeTariffNotificationView } from "./views/free-tariff-notification/free-tariff-notification.view";
import { ImagesPositionSettingsModalView } from "./views/images-position-settings-modal/images-position-settings-modal.view";
import { LayoutStepsComponent } from "./views/layout-steps/layout-steps.component";
import { MarginsPreviewComponent } from "./views/margins-preview/margins-preview.component";
import { MarkupFontSettingsInput } from "./views/markup-font-settings-input/markup-font-settings-input.view";
import { MarkupFontSizeInput } from "./views/markup-font-size-input/markup-font-size-input.view";
import { MarkupIndentParagraphSettings } from "./views/markup-indent-paragraph-settings/markup-indent-paragraph-settings.view";
import { MarkupParagraphTextSettings } from "./views/markup-paragraph-text-settings/markup-paragraph-text-settings.view";
import { MarkupSidebarColorsSettings } from "./views/markup-sidebar-colors-settings/markup-sidebar-colors-settings.view";
import { MarkupSidebarDesignSettings } from "./views/markup-sidebar-design-settings/markup-sidebar-design-settings.view";
import { MarkupSidebarFontSizes } from "./views/markup-sidebar-font-sizes/markup-sidebar-font-sizes.view";
import { MarkupSidebarImagesPositionSettings } from "./views/markup-sidebar-images-position-settings/markup-sidebar-images-position-settings.view";
import { MarkupSidebarImagesSettings } from "./views/markup-sidebar-images-settings/markup-sidebar-images-settings.view";
import { MarkupSidebarPalette } from "./views/markup-sidebar-palette/markup-sidebar-palette.view";
import { MarkupSidebarStyleDetails } from "./views/markup-sidebar-style-details/markup-sidebar-style-details.view";
import { MarkupStyleDetailsSettingsButton } from "./views/markup-sidebar-style-details/settigns-button.view";
import {
  MarkupStyleDetailsSettingsAlignButtonsRow,
  MarkupStyleDetailsSettingsAlignMainTextButtonsRow,
  MarkupStyleDetailsSettingsCaseButtonsRow,
  MarkupStyleDetailsSettingsEmphasisButtonsRow,
  MarkupStyleDetailsSettingsIndentHeaderhButtonsRow,
  MarkupStyleDetailsSettingsIndentParagraphButtonsRow,
  MarkupStyleDetailsSettingsStartOnButtonsRow,
} from "./views/markup-sidebar-style-details/settigns-buttons.view";
import { MarkupSidebarTextSettings } from "./views/markup-sidebar-text-settings/markup-sidebar-text-settings.view";
import { MarkupSidebarTocSettingsButtonsColumn } from "./views/markup-sidebar-toc-settings/markup-sidebar-toc-settings-buttons/markup-sidebar-toc-settings-buttons.view";
import { MarkupSidebarTocSettings } from "./views/markup-sidebar-toc-settings/markup-sidebar-toc-settings.view";
import { ModalBookDataView } from "./views/modal-book-data/modal-book-data.view";
import { ModalBookFileUploadView } from "./views/modal-book-file-upload/modal-book-file-upload.view";
import { ModalBookTemplatePreviewView } from "./views/modal-book-template-preview/modal-book-template-preview.view";
import { ModalBookTemplatesView } from "./views/modal-book-templates/modal-book-templates.view";
import { ModalCoverDataView } from "./views/modal-cover-data/modal-cover-data.view";
import { ModalProjectRouterHost } from "./views/modal-project-router-host/modal-project-router-host.view";
import { NewCoverModalView } from "./views/new-cover-modal/new-cover-modal.view";
import { NewProjectModalSidebarSectionItemView } from "./views/new-project-modal-sidebar-section/item/sidebar-section-item.view";
import { NewProjectModalSidebarSectionView } from "./views/new-project-modal-sidebar-section/sidebar-section.view";
import { NewProjectModalView } from "./views/new-project-modal/new-project-modal.view";
import { PageNumberssStylesComponent } from "./views/page-numbers-styles/page-numbers-styles.component";
import { PageNumberStyle1Component } from "./views/page-numbers-styles/page-numbers-styles/page-number-style-1/page-number-style-1.component";
import { PageNumberStyle2Component } from "./views/page-numbers-styles/page-numbers-styles/page-number-style-2/page-number-style-2.component";
import { Paginator } from "./views/paginator/paginator.view";
import { PaymentsModalView } from "./views/payments-modal/payments-modal.view";
import { PreviewFrameComponent } from "./views/preview-frame/preview-frame.component";
import { PreviewSidebarLayoutStepsView } from "./views/preview-sidebar-layout-steps/preview-sidebar-layout-steps.view";
import { PreviewSpreadComponent } from "./views/preview-spread/preview-spread.component";
import { PreviewSpreadsComponent } from "./views/preview-spreads/preview-spreads.component";
import { PreviewTextComponent } from "./views/preview-text/preview-text.component";
import { RunningTitlesPositionGroupComponent } from "./views/running-titles-position-group/running-titles-position-group.component";
import { RunningTitlesPositionComponent } from "./views/running-titles-position/running-titles-position.component";
import { RunningTitlesPreviewComponent } from "./views/running-titles-preview/running-titles-preview.component";
import { RunningTitlesStylesGroupComponent } from "./views/running-titles-styles-group/running-titles-styles-group.component";
import { RunningTitlesStylesComponent } from "./views/running-titles-styles/running-titles-styles.component";
import { RunningTitlesBaseStyleComponent } from "./views/running-titles-styles/running-titles-styles/running-titles-base-style/running-titles-base-style.component";
import { RunningTitlesStyle1Component } from "./views/running-titles-styles/running-titles-styles/running-titles-style-1/running-titles-style-1.component";
import { RunningTitlesStyle2Component } from "./views/running-titles-styles/running-titles-styles/running-titles-style-2/running-titles-style-2.component";
import { RunningTitlesStyle3Component } from "./views/running-titles-styles/running-titles-styles/running-titles-style-3/running-titles-style-3.component";
import { RunningTitlesStyle4Component } from "./views/running-titles-styles/running-titles-styles/running-titles-style-4/running-titles-style-4.component";
import { RunningTitlesStyle5Component } from "./views/running-titles-styles/running-titles-styles/running-titles-style-5/running-titles-style-5.component";
import { SelectComponentAgeLimit } from "./views/select-agelimits/select.component";
import { SelectBookTypeCardView } from "./views/select-book-type-card/select-book-type-card.view";
import { SelectNewProjectTypeModalView } from "./views/select-new-project-type-modal/select-new-project-type-modal.view";
import { ShareCoverModalView } from "./views/share-cover-modal/share-cover-modal.view";
import { SidebarSection } from "./views/sidebar-section/sidebar-section.view";
import { SidebarTabEbook } from "./views/sidebar-tab-ebook/sidebar-tab-ebook.view";
import { SidebarTabs } from "./views/sidebar-tabs/sidebar-tabs.view";
import { SliderWithIndication } from "./views/slider-with-indication/slider-with-indication.view";
import { TemplateFilterSelectorView } from "./views/template-filter-selector/template-filter-selector.view";
import { TemplatePreviewCardNew } from "./views/template-preview-card-2/template-preview-card.view";
import { TemplatePreviewCard } from "./views/template-preview-card/template-preview-card.view";
import { TemplatePreviewTag } from "./views/template-preview-card/template-preview-tag/template-preview-tag.view";
import { TemplateUploadModalView } from "./views/template-upload-modal/template-upload-modal.view";
import { TocMarkupBar } from "./views/toc-markup-bar/toc-markup-bar.view";

export const COVER_TEXT_STORAGE = new InjectionToken<CoverTextStorage>("cover-text-storage");

@NgModule({
  declarations: [
    // components
    PreviewFrameComponent,
    MarginsPreviewComponent,
    RunningTitlesPreviewComponent,
    RunningTitlesPositionGroupComponent,
    RunningTitlesPositionComponent,
    RunningTitlesStylesGroupComponent,
    RunningTitlesStylesComponent,
    RunningTitlesBaseStyleComponent,
    RunningTitlesStyle1Component,
    RunningTitlesStyle2Component,
    RunningTitlesStyle3Component,
    RunningTitlesStyle4Component,
    RunningTitlesStyle5Component,
    PreviewTextComponent,
    PageNumberssStylesComponent,
    PageNumberStyle1Component,
    PageNumberStyle2Component,
    LayoutStepsComponent,
    ColorPaletteComponent,
    PreviewSpreadComponent,
    PreviewSpreadsComponent,
    FontSelectComponent,
    // views
    BookCardView,
    BookCardActionView,
    BookCardDownloadView,
    BookCardEditView,
    SelectBookTypeCardView,
    TemplatePreviewCard,
    TemplatePreviewTag,
    TocMarkupBar,
    SidebarTabs,
    MarkupSidebarTextSettings,
    MarkupSidebarFontSizes,
    MarkupSidebarStyleDetails,
    MarkupSidebarDesignSettings,
    MarkupSidebarColorsSettings,
    MarkupSidebarPalette,
    MarkupSidebarImagesSettings,
    MarkupSidebarImagesPositionSettings,
    MarkupSidebarTocSettings,
    MarkupSidebarTocSettingsButtonsColumn,
    MarkupIndentParagraphSettings,
    MarkupStyleDetailsSettingsEmphasisButtonsRow,
    MarkupStyleDetailsSettingsAlignButtonsRow,
    MarkupStyleDetailsSettingsAlignMainTextButtonsRow,
    MarkupStyleDetailsSettingsCaseButtonsRow,
    MarkupStyleDetailsSettingsStartOnButtonsRow,
    MarkupStyleDetailsSettingsIndentParagraphButtonsRow,
    MarkupStyleDetailsSettingsIndentHeaderhButtonsRow,
    MarkupStyleDetailsSettingsButton,
    MarkupFontSizeInput,
    MarkupFontSettingsInput,
    MarkupParagraphTextSettings,
    SidebarSection,
    FontSizeSelector,
    Paginator,
    PreviewSidebarLayoutStepsView,
    DeleteProjectModal,
    SidebarTabEbook,
    FreeTariffNotificationView,
    PaymentsModalView,
    AgeRestrictionsModalView,
    BookEditModalView,
    SelectComponentAgeLimit,
    ImagesPositionSettingsModalView,
    TemplateFilterSelectorView,
    CoverView,
    TemplatePreviewCardNew,
    NewProjectModalView,
    NewCoverModalView,
    EditProjectModalView,
    ModalProjectRouterHost,
    ModalBookTemplatesView,
    ModalBookTemplatePreviewView,
    ModalBookFileUploadView,
    NewProjectModalSidebarSectionView,
    NewProjectModalSidebarSectionItemView,
    ModalBookDataView,
    ModalCoverDataView,
    TemplateUploadModalView,
    CoverConceptualAssistantGenerationView,
    CoverConceptualAssistantGenerationHostView,
    CoverConceptualAssistantGenerationMenuView,
    CoverConceptualAssistantGenerationResultView,
    CoverConceptualAssistantGenerationResultStyleCardView,
    CoverConceptualAssistantGenerationResultFontCardView,
    CoverConceptualAssistantGenerationMenuSectionView,
    ShareCoverModalView,
    CoverSidebarSectionView,
    SelectNewProjectTypeModalView,

    // pages
    BooksPage,
    MarkupPage,
    MarginsPage,
    RunningTitlesPage,
    PreviewPage,
    BookPrintSettingsComponent,
    CoverPage,
    CoverObjectListComponent,
    ContenteditableDirective,
    ClickOutsideDetectorDirective,
    CoverCompletionModalComponent,
    CoverObjectGradientComponent,
    CoverSelectedColorComponent,
    CoverTemplateListComponent,
    CoverImageSelectionModalComponent,
    CoverObjectSettingsComponent,
    CoverImageObjectSettingsComponent,
    CoverShapeObjectSettingsComponent,
    CoverTextObjectSettingsComponent,
    CoverObjectCreateComponent,
    CoverFillSelectComponent,
    CoverColorSelectComponent,
    CoverMultiselectSettingsComponent,
    CoverObjectAlignmentComponent,
    CoverGroupObjectSettingsComponent,
    CoverObjectListItemComponent,
    CoverLayersSectionComponent,
    CoverMulticolorSettingsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    CoreModule,
    NgOptimizedImage,
    RouterModule,
    PricingModule,
    // MarkupEditorModule.forRoot({ url: environment.apiRootUrl, imageLoadingProviderClass: DefaultImageLoadingProvider }),
    MarkupEditorModule,
    DragDropModule,
    ImageGenerationModule,
    NgScrollbarModule,
    SliderWithIndication,
  ],

  exports: [BookCardView, NewProjectModalView, ModalProjectRouterHost, SelectNewProjectTypeModalView],
  providers: [
    { provide: "BookFeaturesCollector", useExisting: BookService },
    { provide: COVER_TEXT_STORAGE, useFactory: () => new CoverTextLocalStorage(true) },
  ],
})
export class BookModule {}
