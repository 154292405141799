import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CoreModule } from "@metranpage/core";
import { ImageGenerationModule } from "@metranpage/image-generation";
// import { NgScrollbarModule } from "ngx-scrollbar";
import { AdminMenuItemComponent } from "../common/components/menu-item.component";
import { Pagination } from "../common/views/pagination/pagination.view";
import { AdminHostPage } from "./pages/admin-host.page";
import { AdminBasePage } from "./pages/admin/admin.page";
import { AdminCompanyApiPage } from "./pages/api/api.page";
import { AdminCompanyPage } from "./pages/company/company.page";
import { AdminCoverTemplatesPageComponent } from "./pages/cover-templates/cover-templates.page";
import { AdminCompanyEditionNoticeSettingsPage } from "./pages/edition-notice-settings/edition-notice-settings.page";
import { AdminCompanyFontPage } from "./pages/font/font.page";
import { AdminCompanyFontsPage } from "./pages/fonts/fonts.page";
import { AdminFormatPage } from "./pages/format/format.page";
import { AdminFormatsPage } from "./pages/formats/formats.page";
import { AdminCompanyImageGenerationPriceSettingsPage } from "./pages/image-generation-price-settings/image-generation-price-settings.page";
import { AdminModerationCoversPage } from "./pages/moderation-covers/moderation-covers.page";
import { AdminModerationImagesPage } from "./pages/moderation-images/moderation-images.page";
import { AdminNonEditableTemplatePage } from "./pages/non-editable-template/non-editable-template.page";
import { AdminNonEditableTemplatesPage } from "./pages/non-editable-templates/non-editable-templates.page";
import { AdminCompanyPaymentsPage } from "./pages/payments-settings/payments.page";
import { AdminCompanyPrintSettingsPage } from "./pages/print-settings/print-settings.page";
import { AdminPromocodePage } from "./pages/promocode/promocode.page";
import { AdminPromocodesPage } from "./pages/promocodes/promocodes.page";
import { AdminReferalLinkStatsPage } from "./pages/referal-link-stats/referal-link-stats.page";
import { AdminReferalLinkPage } from "./pages/referal-link/referal-link.page";
import { AdminReferalLinksPage } from "./pages/referal-links/referal-links.page";
import { AdminRewardDailyPage } from "./pages/rewards/reward-daily/reward-daily.page";
import { AdminRewardOneTimePage } from "./pages/rewards/reward-onetime/reward-onetime.page";
import { AdminRewardsDailyPage } from "./pages/rewards/rewards-daily/rewards-daily.page";
import { AdminRewardsOneTimePage } from "./pages/rewards/rewards-onetime/rewards-onetime.page";
import { AdminCompanyS3SettingsPage } from "./pages/s3-settings/s3-settings.page";
import { AdminTariffPage } from "./pages/tariff/tariff.page";
import { AdminTariffsPage } from "./pages/tariffs/tariffs.page";
import { AdminCompanyTelegramSettingsPage } from "./pages/telegram-settings/telegram-settings.page";
import { AdminTemplatePage } from "./pages/template/template.page";
import { AdminTemplatesPage } from "./pages/templates/templates.page";
import { AdminCompanyUnisenderSettingsPage } from "./pages/unisender-settings/unisender-settings.page";
import { AdminUsersPage } from "./pages/users/users.page";
import { AddFontView } from "./views/add-font/add-font.view";
import { AddFormatView } from "./views/add-format/add-format.view";
import { AddNonEditableTemplateView } from "./views/add-non-editable-template/add-non-editable-template.view";
import { AddPromocodeView } from "./views/add-promocode/add-promocode.view";
import { AddReferalLinkView } from "./views/add-referal-link/add-referal-link.view";
import { AddTariffView } from "./views/add-tariff/add-tariff.view";
import { AddTemplateView } from "./views/add-template/add-template.view";
import { CoverPreviewView } from "./views/cover-preview/cover-preview.view";
import { EditCompanyView } from "./views/edit-company/edit-company.view";
import { ModerationCoverPreviewView } from "./views/moderation-cover-preview/moderation-cover-preview.view";
import { AdminModerationCoversQueueView } from "./views/moderation-covers-queue/moderation-covers-queue.view";
import { ModerationImagePreviewView } from "./views/moderation-image-preview/moderation-image-preview.view";
import { AdminModerationImagesQueueView } from "./views/moderation-images-queue/moderation-images-queue.view";
import { AdminNavView } from "./views/nav/nav.view";
import { AddRewardDailyView } from "./views/rewards/add-reward-daily/add-reward-daily.view";
import { AddRewardOneTimeView } from "./views/rewards/add-reward-onetime/add-reward-onetime.view";

@NgModule({
  declarations: [
    // components
    // AdminMenuItemComponent,
    // views
    // Pagination,
    // AdminNavView,
    AddTemplateView,
    AddTariffView,
    AddPromocodeView,
    AddReferalLinkView,
    AddFontView,
    EditCompanyView,
    AddNonEditableTemplateView,
    AdminModerationImagesQueueView,
    ModerationImagePreviewView,
    ModerationCoverPreviewView,
    AdminModerationCoversQueueView,
    CoverPreviewView,
    AddFormatView,
    AddRewardDailyView,
    AddRewardOneTimeView,
    // pages
    AdminBasePage,
    AdminHostPage,
    AdminCompanyPage,
    AdminUsersPage,
    AdminTemplatesPage,
    AdminTemplatePage,
    AdminTariffsPage,
    AdminTariffPage,
    AdminPromocodesPage,
    AdminPromocodePage,
    AdminReferalLinksPage,
    AdminReferalLinkPage,
    AdminReferalLinkStatsPage,
    AdminCompanyFontsPage,
    AdminCompanyFontPage,
    AdminNonEditableTemplatesPage,
    AdminNonEditableTemplatePage,
    AdminCompanyApiPage,
    AdminCompanyS3SettingsPage,
    AdminCompanyUnisenderSettingsPage,
    AdminCompanyPaymentsPage,
    AdminCompanyPrintSettingsPage,
    AdminCompanyImageGenerationPriceSettingsPage,
    AdminCompanyEditionNoticeSettingsPage,
    AdminModerationImagesPage,
    AdminModerationCoversPage,
    AdminFormatsPage,
    AdminFormatPage,
    AdminCoverTemplatesPageComponent,
    AdminCompanyTelegramSettingsPage,
    AdminRewardsOneTimePage,
    AdminRewardOneTimePage,
    AdminRewardsDailyPage,
    AdminRewardDailyPage,
  ],
  imports: [
    RouterModule,
    CoreModule,
    AdminMenuItemComponent,
    Pagination,
    AdminNavView,
    ImageGenerationModule,
    // NgScrollbarModule,
  ],
})
export class AdminModule {}
