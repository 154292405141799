import { ChangeDetectorRef, Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { SelectValue } from "@metranpage/components";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { Observable, Subject, takeUntil } from "rxjs";
import { CompanyPrintSettings } from "../../models/company";
import { AdminCompanyPrintSettingsService } from "../../services/print-settings/companies-print-settings.service";
import { AdminCompanyPrintSettingsStore } from "../../services/print-settings/companies-print-settings.store";

@Component({
  selector: "m-admin-company-settings-print-settings-page",
  templateUrl: "./print-settings.page.html",
  styleUrls: ["./print-settings.page.scss"],
})
export class AdminCompanyPrintSettingsPage {
  private destroyed$ = new Subject<void>();
  protected settings$: Observable<CompanyPrintSettings | undefined> | undefined;

  readonly form: FormGroup;

  protected printViewStylesOptions: SelectValue[] = [
    { id: "hidden", value: $localize`:@@admin.print-settings.print-settings-style-hidden:` },
    { id: "calculator", value: $localize`:@@admin.print-settings.print-settings-style-calc:` },
    { id: "redirectbutton", value: $localize`:@@admin.print-settings.print-settings-style-redirect-button:` },
  ];

  constructor(
    private readonly service: AdminCompanyPrintSettingsService,
    private readonly store: AdminCompanyPrintSettingsStore,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.form = new FormBuilder().group({
      printViewStyle: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
      redirectUrl: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
    });
  }

  async ngAfterViewInit() {
    await this.service.refreshPrintSettings();
    this.settings$ = this.store.getPrintSettingsObservable();
    this.settings$!.pipe(takeUntil(this.destroyed$)).subscribe((settings) => {
      this.form.setValue({
        printViewStyle: settings?.printViewStyle,
        redirectUrl: settings?.redirectUrl,
      });
    });
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.updatePrintSettings(this.form.value as CompanyPrintSettings);
    this.loadingService.stopLoading();

    if (result.status === "success") {
      // do nothing
    } else {
      // this.error.emit(result.error);
      this.notificationService.error("Cannot set settings");
      console.error("Cannot set settings");
    }
  }

  async onDelete() {
    const result = await this.service.deletePrintSettings();
    if (result.status === "success") {
      // do nothing, as we already subscribed
    } else {
      this.notificationService.error("Cannot delete settings");
      console.error("Cannot delete settings");
    }
    this.cdr.markForCheck();
  }
}
