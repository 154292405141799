import { ChangeDetectorRef, Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SelectValue } from "@metranpage/components";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { Observable, Subject, takeUntil } from "rxjs";
import { CompanyImageGenerationPriceSettings } from "../../models/company";
import { AdminCompanyImageGenerationPriceSettingsService } from "../../services/image-generation-price-settings/companies-image-generation-price-settings.service";
import { AdminCompanyImageGenerationPriceSettingsStore } from "../../services/image-generation-price-settings/companies-image-generation-price-settings.store";

type PriceType = "basic" | "advanced" | "other";

@Component({
  selector: "m-admin-image-generation-price-settings-page",
  templateUrl: "./image-generation-price-settings.page.html",
  styleUrls: ["./image-generation-price-settings.page.scss"],
})
export class AdminCompanyImageGenerationPriceSettingsPage {
  private destroyed$ = new Subject<void>();
  protected settings$: Observable<CompanyImageGenerationPriceSettings | undefined> | undefined;

  protected priceTypes;
  protected priceType: PriceType = "basic";

  readonly form: FormGroup;

  constructor(
    private readonly service: AdminCompanyImageGenerationPriceSettingsService,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly store: AdminCompanyImageGenerationPriceSettingsStore,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.priceTypes = this.getPriceTypes();

    this.form = new FormBuilder().group({
      generation1ImagePrice: new FormControl<number>(10, { nonNullable: true, validators: [Validators.required] }),
      generation2ImagePrice: new FormControl<number>(10, { nonNullable: true, validators: [Validators.required] }),
      generation3ImagePrice: new FormControl<number>(10, { nonNullable: true, validators: [Validators.required] }),
      generation4ImagePrice: new FormControl<number>(10, { nonNullable: true, validators: [Validators.required] }),

      generation1ImagePriceAdvancedFast: new FormControl<number>(10, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      generation2ImagePriceAdvancedFast: new FormControl<number>(10, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      generation3ImagePriceAdvancedFast: new FormControl<number>(10, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      generation4ImagePriceAdvancedFast: new FormControl<number>(10, {
        nonNullable: true,
        validators: [Validators.required],
      }),

      generation1ImagePriceAdvancedQuality: new FormControl<number>(10, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      generation2ImagePriceAdvancedQuality: new FormControl<number>(10, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      generation3ImagePriceAdvancedQuality: new FormControl<number>(10, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      generation4ImagePriceAdvancedQuality: new FormControl<number>(10, {
        nonNullable: true,
        validators: [Validators.required],
      }),

      generation1ImagePriceAdvancedUltra: new FormControl<number>(10, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      generation2ImagePriceAdvancedUltra: new FormControl<number>(10, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      generation3ImagePriceAdvancedUltra: new FormControl<number>(10, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      generation4ImagePriceAdvancedUltra: new FormControl<number>(10, {
        nonNullable: true,
        validators: [Validators.required],
      }),

      unzoomPrice: new FormControl<number>(10, { nonNullable: true, validators: [Validators.required] }),
      upscalePrice: new FormControl<number>(10, { nonNullable: true, validators: [Validators.required] }),
      generationWithElementsCoeficient: new FormControl<number>(1.25, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      convertLeonardoAiTokensCoeficient: new FormControl<number>(0.29, {
        nonNullable: true,
        validators: [Validators.required],
      }),

      fabulaRemoveBackground: new FormControl<number>(15, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  async ngAfterViewInit() {
    await this.service.loadImageGenerationPriceSettings();
    this.settings$ = this.store.getPricesObservable();
    this.settings$!.pipe(takeUntil(this.destroyed$)).subscribe((settings) => {
      this.form.setValue({
        generation1ImagePrice: settings?.generation1ImagePrice || 10,
        generation2ImagePrice: settings?.generation2ImagePrice || 10,
        generation3ImagePrice: settings?.generation3ImagePrice || 10,
        generation4ImagePrice: settings?.generation4ImagePrice || 10,

        generation1ImagePriceAdvancedFast: settings?.generation1ImagePriceAdvancedFast || 10,
        generation2ImagePriceAdvancedFast: settings?.generation2ImagePriceAdvancedFast || 10,
        generation3ImagePriceAdvancedFast: settings?.generation3ImagePriceAdvancedFast || 10,
        generation4ImagePriceAdvancedFast: settings?.generation4ImagePriceAdvancedFast || 10,

        generation1ImagePriceAdvancedQuality: settings?.generation1ImagePriceAdvancedQuality || 10,
        generation2ImagePriceAdvancedQuality: settings?.generation2ImagePriceAdvancedQuality || 10,
        generation3ImagePriceAdvancedQuality: settings?.generation3ImagePriceAdvancedQuality || 10,
        generation4ImagePriceAdvancedQuality: settings?.generation4ImagePriceAdvancedQuality || 10,

        generation1ImagePriceAdvancedUltra: settings?.generation1ImagePriceAdvancedUltra || 10,
        generation2ImagePriceAdvancedUltra: settings?.generation2ImagePriceAdvancedUltra || 10,
        generation3ImagePriceAdvancedUltra: settings?.generation3ImagePriceAdvancedUltra || 10,
        generation4ImagePriceAdvancedUltra: settings?.generation4ImagePriceAdvancedUltra || 10,

        unzoomPrice: settings?.unzoomPrice || 10,
        upscalePrice: settings?.upscalePrice || 10,
        generationWithElementsCoeficient: settings?.generationWithElementsCoeficient || 1.25,
        convertLeonardoAiTokensCoeficient: settings?.convertLeonardoAiTokensCoeficient || 0.29,

        fabulaRemoveBackground: settings?.fabulaRemoveBackground || 15,
      });
    });
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.updateImageGenerationPriceSettings(
      this.form.value as CompanyImageGenerationPriceSettings,
    );
    this.loadingService.stopLoading();

    if (result.status === "success") {
      // do nothing
    } else {
      // this.error.emit(result.error);
      this.notificationService.error("Cannot set settings");
      console.error("Cannot set settings");
    }
  }

  isDisabled() {
    return !this.form.valid;
  }

  protected onSelectPriceType(value: number | string) {
    this.priceType = value as PriceType;
  }

  protected getPriceTypes(): SelectValue[] {
    return [
      {
        id: "basic",
        value: $localize`:@@admin.companies.company.image-generation-price.basic:`,
      },
      {
        id: "advanced",
        value: $localize`:@@admin.companies.company.image-generation-price.advanced:`,
      },
      {
        id: "other",
        value: $localize`:@@admin.companies.company.image-generation-price.other:`,
      },
    ];
  }
}
