import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import { Promocode, PromocodeCreateData, PromocodeUpdateData, PromocodesData } from "../../models/promocode";

export type PromocodesFilter = {
  token?: string;
};

@Injectable({
  providedIn: "root",
})
export class AdminPromocodesApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async createPromocode(data: PromocodeCreateData) {
    return this.post<{ id: number }>("admin/promocodes/", data);
  }

  async getPromocode(promocodeId: number) {
    return this.get<Promocode>(`admin/promocodes/${promocodeId}`);
  }

  async getPromocodesPaginated(page: number, filters: PromocodesFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);

    return this.get<PromocodesData>("admin/promocodes/paginated", {
      page,
      ...clearedFilters,
    });
  }

  async updatePromocode(promocodeId: number, promocodeData: PromocodeUpdateData) {
    return this.post<Promocode>(`admin/promocodes/${promocodeId}`, {
      id: promocodeId,
      ...promocodeData,
    });
  }

  async deletePromocode(promocodeId: number) {
    return this.delete(`admin/promocodes/${promocodeId}`);
  }
}
