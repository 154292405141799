import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";
import { AdvancedGenerationMode } from "../../models/image-generation";
import { AdvancedGenerationModeSelectValue } from "../../services/image-generation-data.service";
import {
  ImageProportionValue,
  ImageProportionWithSizeValue,
} from "../image-proportion-selector/image-proportion-selector.view";

@Component({
  selector: "m-image-advanced-generation-mode",
  templateUrl: "./image-advanced-generation-mode.view.html",
  styleUrls: ["./image-advanced-generation-mode.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageAdvancedGenerationModeView),
      multi: true,
    },
  ],
})
export class ImageAdvancedGenerationModeView implements OnDestroy {
  @Input()
  options: AdvancedGenerationModeSelectValue[] = [];
  @Input()
  proportion?: ImageProportionValue;
  @Input()
  hasPremium = true;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  protected value: AdvancedGenerationMode = "fast";

  protected isDisabled = false;

  private destroyed$ = new Subject<void>();

  protected realSizesAdvancedFast: ImageProportionWithSizeValue[] = [
    { width: 1024, height: 1024, wp: 1, hp: 1 },
    { width: 896, height: 1184, wp: 3, hp: 4 },
    { width: 768, height: 1376, wp: 9, hp: 16 },
  ];

  protected realSizesAdvancedQuality: ImageProportionWithSizeValue[] = [
    { width: 1536, height: 1536, wp: 1, hp: 1 },
    { width: 1344, height: 1776, wp: 3, hp: 4 },
    { width: 1152, height: 2064, wp: 9, hp: 16 },
  ];

  protected realSizesAdvancedUltra: ImageProportionWithSizeValue[] = [
    { width: 2048, height: 2048, wp: 1, hp: 1 },
    { width: 1792, height: 2368, wp: 3, hp: 4 },
    { width: 1536, height: 2752, wp: 9, hp: 16 },
  ];

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  writeValue(value: AdvancedGenerationMode): void {
    this.value = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onSelect(option: AdvancedGenerationModeSelectValue) {
    this.value = option.id as AdvancedGenerationMode;
    this.onChange(this.value);
  }

  getSize(sizeMode: string | number) {
    if (!this.proportion) {
      return;
    }

    let sizes: ImageProportionWithSizeValue[] = [];
    if (sizeMode === "fast") {
      sizes = this.realSizesAdvancedFast;
    }
    if (sizeMode === "quality") {
      sizes = this.realSizesAdvancedQuality;
    }
    if (sizeMode === "ultra") {
      sizes = this.realSizesAdvancedUltra;
    }

    const k = this.proportion.wp / this.proportion.hp;
    if (k > 1) {
      sizes = sizes.map((s) => {
        const wp = s.hp;
        const hp = s.wp;
        const width = s.height;
        const height = s.width;
        return {
          width,
          height,
          wp,
          hp,
        };
      });
    }
    const size = sizes.find((s) => s.wp === this.proportion?.wp && s.hp === this.proportion?.hp);
    return `${size?.width} x ${size?.height}`;
  }
}
