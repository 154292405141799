import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CoverSnippet, CoverSnippetCreateDataDto } from "@metranpage/book-data";
import { Api, BASE_URL, FILES_URL } from "@metranpage/core-api";

@Injectable({
  providedIn: "root",
})
export class CoverSnippetApi extends Api {
  constructor(
    http: HttpClient,
    @Inject(BASE_URL) baseUrl: string,
    @Inject(FILES_URL) private filesUrl: string,
  ) {
    super(http, baseUrl);
  }

  getCoverSnippets(): Promise<CoverSnippet[]> {
    return this.get<CoverSnippet[]>("cover-snippets");
  }

  createCoverSnippet(data: CoverSnippetCreateDataDto, file: File): Promise<CoverSnippet> {
    const formData = new FormData();
    formData.append("file", file, file.name);
    for (const [key, value] of Object.entries(data)) {
      if (typeof value !== "string") {
        formData.append(key, JSON.stringify(value));
        continue;
      }
      formData.append(key, value);
    }

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post<CoverSnippet>("cover-snippets", formData, { headers });
  }

  applyCoverSnippet(coverSnippetId: number, bookId: number) {
    return this.post<unknown>(`cover-snippets/apply/${coverSnippetId}/${bookId}`, {});
  }

  getUrlForFile(file: { vid: string }) {
    return `${this.filesUrl}/${file.vid}`;
  }
}
