<m-ng-island class="container" [paddingSize]="0" [ngStyle]="{ width: coverSnippetSidebarWidth + 'px' }">
  <div class="wrapper">
    <!-- <m-sidebar-tabs-new
      [selectedTab]="1"
      i18n-tab1Title="@@cover-editor.sidebar.tabs.service"
      tab1Title="cover-editor.sidebar.tabs.service"
      i18n-tab2Title="@@cover-editor.sidebar.tabs.community"
      tab2Title="cover-editor.sidebar.tabs.community"
      [hasScroll]="false"
      (onTabClick)="onTabClick($event)"
    >
      <div class="tab" id="tab1"> -->
    <div *ngIf="!selectedCoverSnippetSubcategoryObjects" class="content-wrapper">
      <m-ng-scrollbars padding="16px" [scrollPosition]="scrollPosition" (onScroll)="onScroll($event)">
        <div class="scroll-container">
          <div class="section" *ngFor="let coverSnippetCategoryObject of selectedCoverSnippetCategoryObjects?.items">
            <div class="caption-wrapper">
              <div class="caption">
                <span text-left>{{ getCoverSnippetSubcategoryTitle(coverSnippetCategoryObject.subcategory) }}</span>
              </div>
              <div
                class="expand-collapse-wrapper"
                (click)="onSetCoverSnippetSubcategoryObjects(coverSnippetCategoryObject)"
              >
                <div class="text" i18n="@@cover-editor.object.create.object.all">
                  cover-editor.object.create.object.all
                </div>
                <div class="icon">
                  <m-ng-icon [size]="20" src="/assets/icons/chevron-right-02.svg" />
                </div>
              </div>
            </div>

            <div
              class="snippets-wrapper"
              [ngClass]="{column: isTextSnippet(selectedCoverSnippetCategoryObjects?.category)}"
            >
              <ng-container *ngFor="let snippet of limitItems(coverSnippetCategoryObject.snippets)">
                <ng-container *ngTemplateOutlet="coverSnippetTemplate; context: { $implicit: snippet }"></ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </m-ng-scrollbars>
    </div>

    <div *ngIf="selectedCoverSnippetSubcategoryObjects" class="expanded-content-wrapper">
      <div class="expanded-caption-wrapper">
        <div class="expand-collapse-wrapper" (click)="onSetCoverSnippetSubcategoryObjects(undefined)">
          <div class="icon">
            <m-ng-icon [size]="20" src="/assets/icons/chevron-left-02.svg" />
          </div>
          <div class="text" i18n="@@cover-editor.object.create.object.back">cover-editor.object.create.object.back</div>
        </div>
        <div class="caption">
          {{ getCoverSnippetSubcategoryTitle(selectedCoverSnippetSubcategoryObjects.subcategory) }}
        </div>
      </div>

      <div class="content-wrapper">
        <m-ng-scrollbars padding="0 16px 16px 16px" [scrollPosition]="scrollPosition" (onScroll)="onScroll($event)">
          <ng-container>
            <div class="section">
              <div class="snippets-wrapper one-line">
                <ng-container *ngFor="let snippet of selectedCoverSnippetSubcategoryObjects.snippets">
                  <ng-container
                    *ngTemplateOutlet="coverSnippetTemplate; context: { $implicit: snippet }"
                  ></ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </m-ng-scrollbars>
      </div>
    </div>
    <!-- </div>
       
      <div class="tab" id="tab2"></div> -->
    <!-- </m-sidebar-tabs-new> -->
  </div>
</m-ng-island>

<ng-template #coverSnippetTemplate let-snippet>
  @if(!isShapeSnippet(snippet)) {
  <div
    class="snippet-wrapper"
    [ngClass]="{'text-snippet': isTextSnippet(snippet.category)}"
    (click)="onCreateSnippetObject(CoverObjectType.Snippet, snippet)"
  >
    <div class="snippet">
      <img [src]="getUrlForPreviewImage(snippet)" />
    </div>
  </div>
  } @else {
  <div class="snippet-shape-wrapper">
    <div class="snippet" (click)="onCreateSnippetObject(CoverObjectType.Snippet, snippet)">
      <m-ng-icon [size]="77" [src]="getUrlForShapeSnippet(snippet)" />
    </div>
  </div>
  }
</ng-template>
