<div class="card">
  <div class="header">
    <div class="title" [ngStyle]="{'font-family': familyName}">{{ familyName }}</div>
    <div class="color" [ngStyle]="{'background-color': color}"></div>
  </div>

  <div class="description">
    <div *ngIf="fontStyleName" class="font-style-name">{{ fontStyleName }}</div>
    <div *ngIf="comment" class="comment">{{ comment }}</div>
  </div>
</div>
