import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { BookCover, CompanyFont } from "@metranpage/book-data";
import {
  ImageGeneration,
  ImageGenerationAdvancedStyle,
  ImageGenerationBasicStyle,
  ImageGenerationCreativeStyle,
  ImageGenerationMode,
  ImageGenerationService,
} from "@metranpage/image-generation";
import { CoverConceptualGeneration, CoverConceptualGenerationDataDto } from "@metranpage/text-generation";
import { Subscription } from "rxjs";
import { FontsService } from "../../services/fonts.service";

export type ImageGenerationData = {
  prompt: string;
  styleId: number;
  mode: ImageGenerationMode;
  age?: string;
  mood?: string;
};

export type ImageGenerationSelectedStyleData = {
  style?: ImageGenerationBasicStyle | ImageGenerationAdvancedStyle;
  comment?: string;
  mood?: string;
  age?: string;
  colorBackground?: string;
  colorBackgroundHex?: string;
  colorAccent?: string;
  colorAccentHex?: string;
  mode?: ImageGenerationMode;
};

export type FontWithColorData = {
  font: string;
  color?: string;
};

export type FontsWithColorData = {
  main?: FontWithColorData;
  sub?: FontWithColorData;
  sec?: FontWithColorData;
};

@Component({
  selector: "m-cover-conceptual-assistant-generation-host",
  templateUrl: "./cover-conceptual-assistant-generation-host.view.html",
  styleUrls: ["./cover-conceptual-assistant-generation-host.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverConceptualAssistantGenerationHostView implements OnInit, OnDestroy, OnChanges {
  @Input()
  coverConceptualGeneration?: CoverConceptualGeneration;
  @Input()
  cover!: BookCover;
  @Input()
  isGenerationStarted = false;

  @Output()
  applyFontsAndColors = new EventEmitter<FontsWithColorData>();
  @Output()
  createImageGeneration = new EventEmitter<ImageGeneration>();
  @Output()
  generateCoverConceptual = new EventEmitter<CoverConceptualGenerationDataDto>();
  @Output()
  closeConceptualAssistant = new EventEmitter<void>();

  protected stylesBasic: ImageGenerationBasicStyle[] = [];
  protected stylesAdvanced: ImageGenerationAdvancedStyle[] = [];
  protected stylesCreative: ImageGenerationCreativeStyle[] = [];

  protected isCoverConceptualGenerationMenuVisible = false;
  protected isCoverConceptualGenerationResultVisible = false;

  protected fonts: CompanyFont[] = [];

  sub: Subscription = new Subscription();

  protected step = 1;

  constructor(
    // private readonly coverConceptualGenerationStore: CoverConceptualGenerationStore,
    private readonly imageGenerationService: ImageGenerationService,
    private readonly fontsService: FontsService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    // this.sub.add(
    //   this.coverConceptualGenerationStore
    //     .getCoverConceptualGenerationObservable()
    //     .subscribe((coverConceptualGeneration) => {
    //       this.coverConceptualGeneration = coverConceptualGeneration;
    //       this.showResults();
    //       this.cdr.detectChanges();
    //     }),
    // );

    await this.getImageGenerationStyles();
    await this.getFonts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateSidebar();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private updateSidebar() {
    this.isCoverConceptualGenerationMenuVisible = false;
    this.isCoverConceptualGenerationResultVisible = false;

    if ((!this.coverConceptualGeneration || this.coverConceptualGeneration.error) && !this.isGenerationStarted) {
      this.isCoverConceptualGenerationMenuVisible = true;
    } else {
      this.isCoverConceptualGenerationResultVisible = true;
    }
  }

  async getImageGenerationStyles() {
    // this.stylesBasic = await this.imageGenerationService.loadImageGenerationBasicStyles();
    this.stylesBasic = [];
    this.stylesAdvanced = await this.imageGenerationService.loadImageGenerationAdvancedStyles();
    this.stylesCreative = await this.imageGenerationService.loadImageGenerationCreativeStyles();
    this.cdr.detectChanges();
  }

  async getFonts() {
    this.fonts = await this.fontsService.getCompanyFonts();
    this.cdr.detectChanges();
  }

  protected onBackClick() {
    this.isCoverConceptualGenerationMenuVisible = true;
    this.isCoverConceptualGenerationResultVisible = false;
    this.cdr.detectChanges();
  }

  protected onApplyFontsAndColorsClick(data: FontsWithColorData) {
    this.applyFontsAndColors.emit(data);
  }

  protected onGenerateImageClick(data: ImageGenerationData) {
    const settings: ImageGeneration = {
      id: 0,
      width: this.cover.width || 148,
      height: this.cover.height || 210,
      imagesCount: 4,
      prompt: data.prompt,
      promptProcessed: "",
      negativePrompt: "",
      negativePromptProcessed: "",
      styleId: data.styleId,
      userId: -1,
      detalization: "detailed",
      realistic: "",
      mood: this.imageGenerationService.getMood(data.mood ?? ""),
      techniqueId: -1,
      colorScheme: "",
      model: "",
      mode: "",
      creditsCount: 0,
      status: "created",
      error: "",
      generationMode: data.mode ?? "basic",
      generatedImages: [],
      isAgeEnable: false,
      isColorSchemeEnable: false,
      isNegativePromptEnable: false,
      age: this.imageGenerationService.getAge(data.age ?? ""),
      contrast: 3.5,
      dynamicComposition: false,
    };

    this.createImageGeneration.emit(settings);
  }

  protected generateCoverConceptualClick(data: CoverConceptualGenerationDataDto) {
    this.generateCoverConceptual.emit(data);
  }

  protected onRefreshClick() {
    if (!this.coverConceptualGeneration) {
      return;
    }

    const data: CoverConceptualGenerationDataDto = {
      coverId: this.coverConceptualGeneration.coverId,
      promptAnnotation: this.coverConceptualGeneration.promptAnnotation,
      promptGenre: this.coverConceptualGeneration.promptGenre,
      promptTargetAudience: this.coverConceptualGeneration.promptTargetAudience,
    };
    this.generateCoverConceptualClick(data);
  }

  protected closeConceptualAssistantClick() {
    this.closeConceptualAssistant.emit();
  }

  private showResults() {
    this.isCoverConceptualGenerationMenuVisible = true;
    this.isCoverConceptualGenerationResultVisible = false;
    if (this.coverConceptualGeneration && this.coverConceptualGeneration.status !== "error") {
      this.isCoverConceptualGenerationMenuVisible = false;
      this.isCoverConceptualGenerationResultVisible = true;
    }
  }
}
