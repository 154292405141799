import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BASE_URL } from "@metranpage/core-api";
import { Observable } from "rxjs";

@Injectable()
export class SuperadminAuthInterceptor implements HttpInterceptor {
  constructor(@Inject(BASE_URL) private readonly apiUrl: string) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = localStorage.getItem("m-sa-token");

    const isApiUrl = request.url.startsWith(`${this.apiUrl}/superadmin`);

    if (accessToken && isApiUrl) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${accessToken}` },
      });
    }

    return next.handle(request);
  }
}
