<div class="container">
  <div *ngFor="let i of items" class="value" [ngClass]="{active: isSelected(i)}" (click)="onSelect(i)">
    {{getText(i)}}
  </div>

  <div class="menu-format-change-orientation">
    <m-ng-icon
      class="button-proportion-change-orientation"
      [src]="orientationIconSrc"
      [size]="24"
      [noRecolor]="true"
      (click)="onChangeOrientationClick()"
    />
  </div>
</div>
