import { Component } from "@angular/core";
import { LoadingService } from "@metranpage/core";
import { ActiveSubscription } from "@metranpage/pricing-data";
import { UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";
import { PricingService } from "../../services/pricing.service";

@Component({
  selector: "m-payments-next-tariff-connected",
  template: `
    <m-icon src="/assets/img/payment-success.png" size="original"></m-icon>
    <div class="content">
      <div class="title">
        <span i18n="@@pricing.next-tariff-connected-page.title-1">pricing.next-tariff-connected-page.title-1</span>
        {{ nextTariffTitle }}
        <span i18n="@@pricing.next-tariff-connected-page.title-2">pricing.next-tariff-connected-page.title-2</span>
      </div>
      <div *ngIf="!isActualTariffFree" class="text" i18n="@@pricing.next-tariff-connected-page.text">
        pricing.next-tariff-connected-page.text
      </div>
    </div>
    <m-button button-style="primary" i18n="@@pricing.next-tariff-connected-page.return" [routerLink]="'/books'">
      pricing.next-tariff-connected-page.return
    </m-button>
  `,
  styleUrls: ["./payment-next-tariff-connected.page.scss"],
})
export class PaymentNextTariffConnectedPage {
  nextTariffTitle = "";
  isActualTariffFree = true;

  sub: Subscription = new Subscription();

  constructor(
    private readonly userStore: UserStore,
    private readonly loadingService: LoadingService,
    private readonly pricingService: PricingService,
  ) {
    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.getNextTariffData(activeSubscription);
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async getNextTariffData(activeSubscription: ActiveSubscription | undefined) {
    if (!activeSubscription) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });

    const tariffs = await this.pricingService.getTariffsForCompany();
    const nextTariff = tariffs.find((t) => t.id === activeSubscription.subscription.nextTariffId);

    if (nextTariff) {
      this.nextTariffTitle = nextTariff.title.toUpperCase();
    }

    this.isActualTariffFree = activeSubscription.tariff.isFree || activeSubscription.tariff.price === 0;

    this.loadingService.stopLoading();
  }
}
