import { Component, Input } from "@angular/core";
import { ActivatedRoute, ActivationEnd, Router } from "@angular/router";
import { Book, BookSize } from "@metranpage/book-data";
import { slideInOutVertical } from "@metranpage/components";
import { RouterService, filterUndefined } from "@metranpage/core";
import { FormatService, FormatStore } from "@metranpage/format-data";
import { Subscription, filter, first, switchMap } from "rxjs";
import { BookRouterService } from "../../services/book-router.service";
import { BookService } from "../../services/book.service";
import { BooksStore } from "../../services/books.store";

export type SidebarSectionOption = {
  name: string;
  width: number;
  height: number;
  title: string;
  iconSrc?: string;
  order: number;
};

@Component({
  selector: "m-new-project-modal-sidebar-section",
  templateUrl: "./sidebar-section.view.html",
  styleUrls: ["./sidebar-section.view.scss"],
  animations: [slideInOutVertical],
})
export class NewProjectModalSidebarSectionView {
  @Input()
  title = "";
  @Input()
  options: SidebarSectionOption[] = [];
  @Input()
  isCollapsed = true;

  protected book!: Book;
  protected bookFormatName?: string;
  protected bookSize!: BookSize;

  sub: Subscription = new Subscription();

  constructor(
    private readonly bookService: BookService,
    private readonly bookRouterService: BookRouterService,
    private readonly route: ActivatedRoute,
    private readonly routerService: RouterService,
    private readonly formatService: FormatService,
    private readonly router: Router,
    private readonly booksStore: BooksStore,
    private readonly formatStore: FormatStore,
  ) {}

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngAfterViewInit() {
    this.sub.add(
      this.formatStore
        .getFormatsObservable()
        .pipe(
          filterUndefined(),
          filter((formats) => formats.length > 0),
          first(),
          switchMap(() => this.booksStore.getModalBookObservable()),
          filterUndefined(),
        )
        .subscribe(async (book) => {
          this.book = book;
          this.updateBookSize();
        }),
    );

    this.sub.add(
      this.router.events.subscribe((data) => {
        if (data instanceof ActivationEnd) {
          this.updateBookSize();
        }
      }),
    );
  }

  updateBookSize() {
    this.bookSize = {
      width: Number(this.routerService.getRouteParam(this.route.snapshot, "width")),
      height: Number(this.routerService.getRouteParam(this.route.snapshot, "height")),
    };
    this.bookFormatName = this.formatService.getFormatNameBySize(this.bookSize.width, this.bookSize.height);
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  isBookFormatActive(name: string) {
    return this.bookFormatName === name;
  }

  onItemSelect(option: SidebarSectionOption) {
    const format = this.formatService.getFormatData(option.width, option.height);
    this.bookRouterService.showModal(this.book, "templates", "new", format);
  }
}
