import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { CompanyImageGenerationPriceSettings } from "../../models/company";
import { AdminCompanyImageGenerationPriceSettingsApi } from "./companies-image-generation-price-settings.api";
import { AdminCompanyImageGenerationPriceSettingsStore } from "./companies-image-generation-price-settings.store";

export type UpdateCompanySettingsResult = {
  status: GeneralResultStatus;
  error?: unknown;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyImageGenerationPriceSettingsService {
  constructor(
    private readonly api: AdminCompanyImageGenerationPriceSettingsApi,
    private readonly store: AdminCompanyImageGenerationPriceSettingsStore,
  ) {}

  async getImageGenerationPriceSettings() {
    return await this.api.getImageGenerationPriceSettings();
  }

  async loadImageGenerationPriceSettings() {
    const settings = await this.api.getImageGenerationPriceSettings();
    this.store.setPrices(settings);
  }

  async updateImageGenerationPriceSettings(
    data: CompanyImageGenerationPriceSettings,
  ): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.updateImageGenerationPriceSettings(data);
      this.store.setPrices(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }
}
