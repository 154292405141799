import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { AngularSvgIconModule } from "angular-svg-icon";
import { OverlayscrollbarsModule } from "overlayscrollbars-ngx";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { ImageGridComponent } from "./components/cooler-image-grid/cooler-image-grid.component";
import { Icon2Component } from "./components/icon-2/icon-2.component";
import { IconComponent } from "./components/icon/icon.component";
import { InputNumberWithIconComponent } from "./components/input-number-with-icon/input-number-with-icon.component";
import { InputNumberComponent } from "./components/input-number/input-number.component";
import { InputPasswordComponent } from "./components/input-password/input-password.component";
import { InputWithIconComponent } from "./components/input-with-icon/input-with-icon.component";
import { InputWithPrefixComponent } from "./components/input-with-prefix/input-with-prefix.component";
import { InputComponent } from "./components/input/input.component";
import { IslandComponent } from "./components/island/island.component";
import { ScrollbarsComponent } from "./components/scrollbars/scrollbars.component";

@NgModule({
  declarations: [],
  imports: [
    // BrowserModule,
    // BrowserAnimationsModule,
    HttpClientModule,
    // HttpClientJsonpModule,
    // NgxFileDropModule,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
    OverlayscrollbarsModule,

    // simple components
    IconComponent,
    Icon2Component,
    CheckboxComponent,
    InputComponent,
    InputPasswordComponent,
    InputWithIconComponent,
    InputWithPrefixComponent,
    InputNumberComponent,
    InputNumberWithIconComponent,
    ScrollbarsComponent,

    // containers
    IslandComponent,

    // complex components
    ImageGridComponent,
  ],
  exports: [
    AngularSvgIconModule,
    OverlayscrollbarsModule,
    ReactiveFormsModule,
    IconComponent,
    Icon2Component,
    CheckboxComponent,
    InputComponent,
    InputPasswordComponent,
    InputWithIconComponent,
    InputWithPrefixComponent,
    InputNumberComponent,
    InputNumberWithIconComponent,
    IslandComponent,
    ImageGridComponent,
    ScrollbarsComponent,
  ],
  bootstrap: [],
})
export class DesignSystemModule {}
