import { Injectable } from "@angular/core";
import { ConnectTelegramService } from "@metranpage/core";
import { UserAction } from "@metranpage/user-data";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class FirstPaymentService {
  constructor(
    private readonly userService: UserService,
    private readonly connectTelegramService: ConnectTelegramService,
  ) {}

  async onPayment() {
    const actionName = "first-payment";
    const userActions = await this.userService.getUserActions();

    const action = userActions?.find((action: UserAction) => action.name === actionName);
    if (action) {
      return;
    }

    await this.userService.updateUserAction({
      name: actionName,
      status: "complete",
    });

    this.connectTelegramService.showConnectTelegramModal();
  }
}
