import { CommonModule, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ColorFormat } from '../color-converter/color-converter.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'm-color-format-select',
  templateUrl: './color-format-select.component.html',
  styleUrls: ['./color-format-select.component.scss'],
  standalone: true,
  imports: [CommonModule, NgClass, AngularSvgIconModule, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorFormatSelectComponent implements OnInit {
  @Input()
  options?: ColorFormat[];
  @Input('color-format')
  colorFormat: ColorFormat = 'rgb';
  @Input()
  isRequired = false;
  @Input()
  name = '';
  @Input()
  tabIndex = 0;

  @Output()
  onSelect = new EventEmitter<ColorFormat>();

  protected isMenuOpened = false;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setValueById(this.colorFormat);
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    if (this.isMenuOpened) {
      result.push('focused');
    }

    return result;
  }

  protected onFieldClick() {
    this.isMenuOpened = !this.isMenuOpened;
    this.cdr.markForCheck();
  }

  protected onOptionClick(valueId: number | string) {
    this.setValueById(valueId);

    this.isMenuOpened = false;

    this.onSelect.emit(this.colorFormat);
  }

  protected getArrowIconClassList(): string[] {
    if (this.isMenuOpened) {
      return ['reversed'];
    }
    return [];
  }

  private setValueById(valueId: number | string) {
    this.colorFormat = this.options?.find((v) => v === valueId) ?? 'rgb';
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      // click in component
    } else {
      if (this.isMenuOpened) {
        this.isMenuOpened = false;
        this.cdr.markForCheck();
      }
    }
  }
}
