<div class="card" [ngClass]="{'active': isActive}">
  <m-skeleton-image
    *ngIf="style.image"
    class="card-image"
    [url]="style.image"
    width="100"
    height="100"
    unit="%"
    animationStyle="gradient"
    backgroudColor="var(--surface-on-island)"
  />

  <div *ngIf="!style.image" class="no-image">
    <m-ng-icon src="no-style.svg" [size]="40" />
  </div>

  <div *ngIf="isActive" class="active-selector">
    <m-ng-icon src="checkmark-01.svg" [size]="16" />
  </div>
</div>
