import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DateTime } from "luxon";
import { UserActionMessage } from "../welcome-modal/welcome-modal.service";

@Component({
  selector: "m-welcome-webinar-modal",
  templateUrl: "./welcome-webinar-modal.view.html",
  styleUrls: ["./welcome-webinar-modal.view.scss"],
})
export class WelcomeWebinarModalView {
  @Output()
  actionClick = new EventEmitter<UserActionMessage>();
  @Output()
  close = new EventEmitter<UserActionMessage>();

  protected message: UserActionMessage = {
    name: "webinar-1-view",
    header: $localize`:@@welcome.webinar-1.header:`,
    text: "",
    button: $localize`:@@welcome.webinar-1.button:`,
    backgroundUrl: "/assets/img/welcome-webinar-1.png",
    navigateToUrl: "https://t.me/mtp_vebinars_bot",
  };

  protected isWebinarToday = true;

  ngOnInit() {
    this.updateDate();
  }

  protected updateDate() {
    const utcTimeData = DateTime.utc().toObject();
    if (utcTimeData.hour >= 17) {
      this.isWebinarToday = false;
    }
  }

  getImageUrl() {
    return "/assets/img/welcome-webinar-1.png";
  }

  onActionClick() {
    this.actionClick.emit(this.message);
  }

  onCloseClick() {
    this.close.emit(this.message);
  }
}
