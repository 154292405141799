import { Component, Input } from "@angular/core";
import { DateTime } from "luxon";
import { Notification } from "../../../models/notification";
import { I18nService } from "@metranpage/i18n";

export type IconColorStyle = "normal" | "error";

@Component({
  selector: "m-account-notification-card-host",
  templateUrl: "account-notification-card-host.view.html",
  styleUrls: ["account-notification-card-host.view.scss"],
})
export class AccountNotificationCardHost {
  @Input()
  notification!: Notification;
  @Input()
  isLast = true;

  protected date = "";

  protected locale = "en";

  constructor(i18nService: I18nService) {
    this.locale = i18nService.getLocale();
  }

  ngOnInit(): void {
    this.updateDate();
  }

  private updateDate() {
    this.date = DateTime.fromISO(this.notification.createdAt.toString()).toLocaleString(
      {
        ...DateTime.DATETIME_MED,
        year: undefined,
        month: "long",
      },
      { locale: this.locale },
    );
  }
}
