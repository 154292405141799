import { CommonModule, NgClass } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { IconComponent } from "@metranpage/design-system";

export type InfoBlockActionData = { controlName: string; value: number | string };
export type InfoBlockTextData = { text: number | string; action?: InfoBlockActionData };

export type InfoBlockData = {
  id?: number;
  textData: InfoBlockTextData[];
};

@Component({
  selector: "m-info-block",
  templateUrl: "./info-block.component.html",
  styleUrls: ["./info-block.component.scss"],
  standalone: true,
  imports: [CommonModule, NgClass, IconComponent],
})
export class InfoBlockComponent implements OnChanges {
  @Input()
  type: "warning" | "error" | "none" = "none";
  @Input()
  labels: InfoBlockData[] = [];
  @Input()
  singleIcon = false;
  @Input()
  iconSize: 16 | 24 = 16;

  @Output()
  onActionClick = new EventEmitter<InfoBlockActionData>();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labels.currentValue.length > 0) {
      this.elementRef.nativeElement.setAttribute("style", "display: flex");
    } else {
      this.elementRef.nativeElement.setAttribute("style", "display: none");
    }
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    if (this.type !== "none") {
      result.push(`type-${this.type}`);
    }

    return result;
  }

  protected getIconCssClassList(): string[] {
    const result: string[] = [];

    result.push("icon");

    if (this.type !== "none") {
      result.push(this.type);
    }

    return result;
  }

  trackByLabel(index: number, label: InfoBlockData) {
    return label.id;
  }

  trackByTextData(index: number, textData: InfoBlockTextData) {
    return index;
  }

  protected actionClick(actionData: InfoBlockActionData | undefined) {
    if (!actionData) {
      return;
    }
    this.onActionClick.emit(actionData);
  }
}
