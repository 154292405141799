<div class="wrapper">
  <ng-container *ngIf="printAvailability.isAvailable && displayStyle === 'calculator'">
    <div class="form-wrapper" *ngIf="settings$ | async as settings; else loading">
      <form [formGroup]="form" (ngSubmit)="onShowContactForm()">
        <div class="control-row">
          <div class="control cover-type">
            <span class="label" i18n="@@books.book.print-settings.cover-type">
              books.book.print-settings.cover-type
            </span>
            <m-select
              formControlName="coverType"
              size="m-plus"
              [options]="settings.coverTypeOptions"
              [hideArrowDivider]="true"
            ></m-select>
          </div>
          <div class="control cover-lamination-type">
            <span class="label" i18n="@@books.book.print-settings.cover-lamination-type">
              books.book.print-settings.cover-lamination-type
            </span>
            <m-select
              formControlName="coverLaminationType"
              size="m-plus"
              [options]="settings.coverLaminationTypeOptions"
              [hideArrowDivider]="true"
            ></m-select>
          </div>
        </div>
        <div class="control-row">
          <!-- <div class="control paper-type">
            <span class="label" i18n="@@books.book.print-settings.paper-type">books.book.print-settings.paper-type</span>
            <m-select formControlName="paperType" size="m-plus" [options]="settings.paperTypeOptions" [hideArrowDivider]="true"></m-select>
          </div> -->
          <div class="control paper-type">
            <span class="label" i18n="@@books.book.print-settings.print-chromaticity">
              books.book.print-settings.print-chromaticity
            </span>
            <m-select
              formControlName="printChromaticityType"
              size="m-plus"
              [options]="settings.printChromaticityTypeOptions"
              [hideArrowDivider]="true"
            ></m-select>
          </div>
          <div class="control print-run">
            <span class="label" i18n="@@books.book.print-settings.print-run">books.book.print-settings.print-run</span>
            <m-ng-input-number formControlName="printRun" />
          </div>
        </div>
      </form>

      <div class="footer-wrapper">
        <ng-container *ngIf="estimationAvailability.isAvailable; else unavaliableEstimation">
          <div
            *ngIf="estimationAvailability.isAvailable && (estimatedPrice$ | async) as estimatedPrice"
            class="estimation-wrapper"
          >
            <ng-container *ngIf="estimatedPrice.succeeded">
              <div class="price">{{ estimatedPrice.priceStr }}</div>
              <div class="description" i18n="@@books.book.print-settings.estimated-price">
                books.book.print-settings.estimated-price
              </div>
            </ng-container>
          </div>
        </ng-container>

        <ng-template #unavaliableEstimation>
          <div class="estimation-wrapper">
            <div class="description warning">
              {{ estimationAvailability.reason }}
            </div>
          </div>
        </ng-template>

        <m-button
          button-style="secondary"
          [is-disabled]="!form.valid"
          (onClick)="onShowContactForm()"
          i18n="@@books.book.print-settings.button.request-price-calculation"
        >
          books.book.print-settings.button.request-price-calculation
        </m-button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="printAvailability.isAvailable && displayStyle === 'redirectbutton'">
    <div class="redirect-button-wrapper">
      <m-button
        button-style="secondary"
        (onClick)="redirectToPrint()"
        i18n="@@books.book.print-settings.redirect-button-title"
      >
        books.book.print-settings.redirect-button-title
      </m-button>
    </div>
  </ng-container>

  <ng-container *ngIf="!printAvailability.isAvailable">
    <div class="unavailable-wrapper">
      <div class="header">
        <m-icon src="/assets/icons/print-01.svg" color="--warning" size="original"></m-icon>
        <span i18n="@@books.book.print-settings.price-calculation">books.book.print-settings.price-calculation</span>
      </div>
      <div class="reason">
        {{ printAvailability.reason }}
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="loader-wrapper">
    <img src="/assets/img/spinner-128.gif" />
  </div>
</ng-template>

<m-modal *ngIf="isContactFormVisible" position="center" closeButtonPosition="screen" (close)="onCloseContactForm()">
  <div class="contact-form-wrapper" *ngIf="!isFinalizeVisible">
    <form [formGroup]="contactForm" (ngSubmit)="requestCalculation()">
      <div class="header" i18n="@@books.book.print-settings.contact-form-header">
        books.book.print-settings.contact-form-header
      </div>

      <div class="control-column">
        <m-ng-input
          formControlName="phoneNumber"
          placeholder="books.book.print-settings.placeholder.phone-number"
          i18n-placeholder="@@books.book.print-settings.placeholder.phone-number"
        />
        <m-textarea
          formControlName="deliveryAddress"
          placeholder="books.book.print-settings.placeholder.delivery-address"
          i18n-placeholder="@@books.book.print-settings.placeholder.delivery-address"
        ></m-textarea>
      </div>

      <m-button
        button-style="primary"
        [is-disabled]="!contactForm.valid"
        (onClick)="requestCalculation()"
        i18n="@@books.book.print-settings.button.request"
      >
        books.book.print-settings.button.request
      </m-button>
    </form>
    <!-- <ng-container *ngIf="processingRequest">
      <ng-container *ngTemplateOutlet="loading"></ng-container>
    </ng-container> -->
  </div>

  <div class="contact-form-wrapper" *ngIf="isFinalizeVisible">
    <img src="/assets/img/email-wings-01.png" />
    <div class="header" i18n="@@books.book.print-settings.thanks">books.book.print-settings.thanks</div>
    <div class="text" i18n="@@books.book.print-settings.request-explanation">
      books.book.print-settings.request-explanation
    </div>
    <m-button
      button-style="primary"
      [is-disabled]="!contactForm.valid"
      (onClick)="onCloseContactForm()"
      i18n="@@books.book.print-settings.button.excellent"
    >
      books.book.print-settings.button.excellent
    </m-button>
  </div>
</m-modal>
