import { Component, OnInit } from "@angular/core";
import { Observable, combineLatest, map, tap } from "rxjs";
import { AdminCoverTemplatesService } from "../../services/cover-templates/cover-templates.service";
import { AdminCoverTemplatesStore } from "../../services/cover-templates/cover-templates.store";
import { AdminBasePage } from "../admin/admin.page";
import { BookCoverTemplate } from "@metranpage/book-data";

type BookCoverTemplateExt = any;

@Component({
  selector: "m-admin-cover-templates-page",
  templateUrl: "./cover-templates.page.html",
  styleUrls: ["./cover-templates.page.scss"],
})
export class AdminCoverTemplatesPageComponent extends AdminBasePage implements OnInit {
  data$!: Observable<BookCoverTemplateExt[]>;
  hasDefaultTemplate = false

  constructor(
    private readonly adminCoverTemplatesStore: AdminCoverTemplatesStore,
    private readonly adminCoverTemplatesService: AdminCoverTemplatesService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.data$ = this.adminCoverTemplatesStore.getTemplatesObservable().pipe(
      map((v) => {
        return v.map((i) => {
          return <BookCoverTemplateExt>{
            ...i,
            previewUrl: this.adminCoverTemplatesService.getTemplatePreviewImageUrl(i.id!),
          };
        });
      }),
      map((v) => {
        v.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime());
        return v;
      }),
      tap((v) => {
        this.hasDefaultTemplate = v.some((i) => i.isDefaultInCompany);
      })
    );

    this.setTemplates();
  }

  private async setTemplates() {
    const templates = await this.adminCoverTemplatesService.getTemplates();
    this.adminCoverTemplatesStore.setTemplates(templates);
  }

  async setDefault(v: BookCoverTemplate | null) {
    if (!v) {
      await this.adminCoverTemplatesService.setAsDefault(null);
    } else {
      await this.adminCoverTemplatesService.setAsDefault(v.id!);
    }
    this.setTemplates();
  }

}
