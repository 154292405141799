import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivationEnd, Params, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AdvertisementActionCreateData, AdvertisementActionSource } from "../models/advertisement-action.model";
import { AdvertisementActionApi } from "./advertisement-action.api";

@Injectable({
  providedIn: "root",
})
export class AdvertisementActionService {
  protected daysToStoreCookie = 30;
  protected admitadTokenName = "admitad_uid";
  protected referalTokenName = "referalToken";

  sub = new Subscription();

  constructor(
    private readonly advertisementActionApi: AdvertisementActionApi,
    private readonly router: Router,
  ) {
    this.watchRouteChanges();
  }

  async setAdvertisementAction(params: Params) {
    this.saveAdvertisementActionToCookie(params);
    await this.sendToBack();
  }

  saveAdvertisementActionToCookie(params: Params) {
    const source = this.getCookie("source");
    const advId = this.getCookie("advId");

    let newSource = "";
    let newAdvId = "";

    if (params[this.admitadTokenName]) {
      newSource = "admitad";
      newAdvId = params[this.admitadTokenName];
    } else if (params[this.referalTokenName]) {
      newSource = "referal";
      newAdvId = params[this.referalTokenName];
    } else {
      return;
    }

    if (newSource !== source || newAdvId !== advId) {
      const expireDate = this.getExpireDate();
      this.setCookie("source", newSource, { expires: expireDate });
      this.setCookie("advId", newAdvId, { expires: expireDate });
    }
  }

  async sendToBack() {
    const source = this.getCookie("source") as AdvertisementActionSource;
    const advId = this.getCookie("advId");

    if (source && advId) {
      const expireDate = this.getExpireDate();
      const data: AdvertisementActionCreateData = {
        sourceType: source,
        isPaymentMade: false,
        isRegisteredWith: false,
        activeTo: expireDate,
      };

      if (source === "referal") {
        data.referalToken = advId;
      }
      if (source === "admitad") {
        data.admitadUid = advId;
      }

      const result = await this.addAdvertisementAction(data);
      if (result === "success") {
        this.deleteCookie("source");
        this.deleteCookie("advId");
        this.sub.unsubscribe();
      }
    }
  }

  private getExpireDate() {
    const period = this.daysToStoreCookie * 60 * 60 * 24 * 1000;
    return new Date(period + +new Date());
  }

  getCookie(name: string) {
    const matches = document.cookie.match(
      new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1")}=([^;]*)`),
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  deleteCookie(name: string) {
    this.setCookie(name, "", {
      "max-age": -1,
    });
  }

  setCookie(name: string, value: string, options = {}) {
    const cookieOptions: any = {
      path: "/",
      ...options,
    };

    if (cookieOptions?.expires instanceof Date) {
      cookieOptions.expires = cookieOptions.expires.toUTCString();
    }

    let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

    for (const optionKey in cookieOptions) {
      updatedCookie += `; ${optionKey}`;
      const optionValue = cookieOptions[optionKey];
      if (optionValue !== true) {
        updatedCookie += `=${optionValue}`;
      }
    }

    document.cookie = updatedCookie;
  }

  getParamFromUri(route: ActivatedRoute, paramName: string) {
    const qp = route.snapshot.queryParams;
    if (qp && paramName in qp) {
      const param = qp[paramName];
      if (param) {
        if (Array.isArray(param)) {
          return param[param.length - 1];
        }
        return param;
      }
    }
    return "";
  }

  async addAdvertisementAction(data: AdvertisementActionCreateData) {
    try {
      const result = await this.advertisementActionApi.addAdvertisementAction(data);
      return "success";
    } catch (error) {
      return "error";
    }
  }

  private watchRouteChanges() {
    this.sub.add(
      this.router.events.subscribe((data) => {
        if (data instanceof ActivationEnd) {
          const qp = data.snapshot.queryParams;
          if (qp) {
            this.setAdvertisementAction(qp);
          }
        }
      }),
    );
  }

  convertStringToBoolean(value: string | undefined): boolean {
    return value === "true";
  }
}
