import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Color, Fill, Gradient, GradientFill, SolidFill } from "@metranpage/book-data";
import { PopupPosition } from "../cover-color-select/cover-color-select.component";

@Component({
  selector: "m-cover-fill-select",
  templateUrl: "./cover-fill-select.component.html",
  styleUrls: ["./cover-fill-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverFillSelectComponent implements OnChanges {
  @Input() fill?: Fill;
  @Input() popupPosition: PopupPosition = "top";
  @Input() useColorPickerExternalHost = true;
  @Input() isGradientAvailable = true;

  @Output() update = new EventEmitter<Fill>();

  color?: Color;
  gradient?: Gradient;

  get solidFill(): SolidFill | undefined {
    return this.fill instanceof SolidFill ? this.fill : undefined;
  }

  get gradientFill(): GradientFill | undefined {
    return this.fill instanceof GradientFill ? this.fill : undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fill) {
      this.onFill();
    }
  }

  onFill() {
    if (this.fill instanceof SolidFill) {
      this.color = this.fill.color;
    } else if (this.fill instanceof GradientFill) {
      this.gradient = this.fill.gradient;
    }
  }

  toGradient() {
    if (!this.solidFill) {
      return;
    }
    const gradient = new Gradient();
    gradient.colorStops.push({ color: this.solidFill.color }, { color: new Color(0, 0, 0, 1) });
    this.fill = new GradientFill();
    (this.fill as GradientFill).gradient = gradient;
    this.onFill();
    this.update.emit(this.fill);
  }

  toSolid(color: Color) {
    if (!this.gradientFill) {
      return;
    }
    this.fill = new SolidFill();
    (this.fill as SolidFill).color = color;
    this.onFill();
    this.update.emit(this.fill);
  }

  updateSolidFill(color: Color) {
    if (!this.solidFill) {
      return;
    }
    this.solidFill.color = color;
    this.update.emit(this.fill);
  }

  updateGradientFill(gradient: Gradient) {
    if (!this.gradientFill) {
      return;
    }
    if (gradient.colorStops.length === 1) {
      this.toSolid(gradient.colorStops[0].color);
      return;
    }
    this.gradientFill.gradient = gradient;
    this.update.emit(this.fill);
  }
}
