import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { I18nService } from "@metranpage/i18n";
import { DateTime } from "luxon";
import { NotificationSubscriptionEnding } from "../../../models/notification";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-subscription-expire-card-view",
  templateUrl: "account-notification-subscription-expire-card.view.html",
  styleUrls: ["account-notification-subscription-expire-card.view.scss"],
})
export class AccountNotificationSubscriptionExpireCardView extends AccountNotificationBaseCardView<NotificationSubscriptionEnding> {
  protected locale = "en";

  constructor(
    private readonly router: Router,
    private readonly i18nService: I18nService,
  ) {
    super();
    this.locale = i18nService.getLocale();
  }

  protected getDateShort() {
    const date = this.payload.expireDate;
    return DateTime.fromISO(date.toString()).toLocaleString(DateTime.DATE_FULL, { locale: this.locale });
  }

  protected getTitle() {
    return `${$localize`:@@user.profile.notifications.card.subscription-ending.title-1:`}
      «${this.payload.tariffTitle}»
      ${$localize`:@@user.profile.notifications.card.subscription-ending.title-2:`}
      ${this.payload.daysLeft} 
      ${this.i18nService.pluralize($localize`:@@pricing.pluralize-days:`, { days: this.payload.daysLeft })}
    `;
  }
}
