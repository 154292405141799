import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "m-social-login-button",
  template: `  
    <m-ng-icon
      *ngIf="iconSrc"
      class="sl-icon vk"
      [size]="24"
      [src]="iconSrc"
      [noRecolor]="true"
    />
    <span *ngIf="text && !isIconOnly">{{ text }}</span>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 16px;
        border: 2px solid var(--surface-on-island);
        border-radius: 9999px;
        color: var(--text-text);
        cursor: pointer;
        font-size: var(--font-size-14);
        font-weight: var(--font-weight-em);
        gap: 16px;
        line-height: 18.9px;
        text-align: left;
        transition: background-color 0.3s ease-in-out;

        &.icon-only {
          width: fit-content;
        }

        &:hover {
          background-color: var(--surface-on-island);
        }
      }      
    `,
  ],
})
export class SocialLoginButtonComponent {
  @Input()
  iconSrc?: string;
  @Input()
  text?: string;

  @HostBinding("class.icon-only")
  @Input()
  isIconOnly = false;
}
