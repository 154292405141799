import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { CompanyPrintSettings } from "../../models/company";
import { AdminCompanyPrintSettingsApi } from "./companies-print-settings.api";
import { AdminCompanyPrintSettingsStore } from "./companies-print-settings.store";

export type UpdateCompanySettingsResult = {
  status: GeneralResultStatus;
  error?: unknown;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyPrintSettingsService {
  constructor(
    private readonly api: AdminCompanyPrintSettingsApi,
    private readonly store: AdminCompanyPrintSettingsStore,
  ) { }

  async refreshPrintSettings() {
    const settings = await this.api.getPrintSettings();
    this.store.setPrintSettings(settings);
  }

  async updatePrintSettings(data: CompanyPrintSettings): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.updatePrintSettings(data);
      this.store.setPrintSettings(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }

  async deletePrintSettings(): Promise<{ status: GeneralResultStatus }> {
    try {
      await this.api.deletePrintSettings();
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
      };
    }
  }
}
