import { Injectable } from "@angular/core";
import { RouterService } from "@metranpage/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  showLoginModalEvent$ = new Subject<void>();

  constructor(private readonly routerService: RouterService) {}

  showLoginModal() {
    this.routerService.showInModal(undefined);
    this.showLoginModalEvent$.next();
  }
}
