import { ChangeDetectorRef, Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { Observable, Subject, takeUntil } from "rxjs";
import { CompanyUnisenderCreds } from "../../models/unisender-settings";
import { AdminCompanyCredsService } from "../../services/creds/companies-creds.service";
import { AdminCompanyCredsSettingsStore } from "../../services/creds/companies-creds.store";

@Component({
  selector: "m-admin-company-settings-unisender-settings-page",
  templateUrl: "./unisender-settings.page.html",
  styleUrls: ["./unisender-settings.page.scss"],
})
export class AdminCompanyUnisenderSettingsPage {
  private destroyed$ = new Subject<void>();
  protected settings$: Observable<CompanyUnisenderCreds | undefined> | undefined;

  readonly form: FormGroup;

  constructor(
    private readonly service: AdminCompanyCredsService,
    private readonly store: AdminCompanyCredsSettingsStore,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.form = new FormBuilder().group({
      unisenderApiKey: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
      unisenderGoApiKey: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
    });
  }

  async ngAfterViewInit() {
    await this.service.refreshUnisenderCreds();
    this.settings$ = this.store.getUnisenderCredsObservable();
    this.settings$!.pipe(takeUntil(this.destroyed$)).subscribe((settings) => {
      this.form.setValue({
        unisenderApiKey: settings?.unisenderApiKey,
        unisenderGoApiKey: settings?.unisenderGoApiKey,
      });
    });
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.updateUnisenderCreds(this.form.value as CompanyUnisenderCreds);
    this.loadingService.stopLoading();

    if (result.status === "success") {
      // do nothing
    } else {
      // this.error.emit(result.error);
      this.notificationService.error("Cannot set settings");
      console.error("Cannot set settings");
    }
  }

  async onDelete() {
    const result = await this.service.deleteUnisenderCreds();
    if (result.status === "success") {
      // do nothing, as we already subscribed
    } else {
      this.notificationService.error("Cannot delete settings");
      console.error("Cannot delete settings");
    }
    this.cdr.markForCheck();
  }
}
