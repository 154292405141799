<m-auth-cover-view [colorTheme]="colorTheme" (onColorThemeToggle)="onColorThemeToggle()" />

<m-password-restore-view
  [userLanguage]="userLanguage"
  [errors]="loginErrors"
  [isLoading]="(loadingState$ | async)?.isActive  ?? false"
  [isEmailSent]="isEmailSent"
  (onRestorePassClick)="onRestorePassClick($event)"
  (onLangChangeClick)="onLangChange($event)"
></m-password-restore-view>
