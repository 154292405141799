<div class="container">
  <div class="preview-group">
    <ng-container *ngIf="previews.length < 3">
      <m-icon
        [src]="imgSrc"
        [class.active]="isDetailsAvailable"
        (click)="onPreviewClick()"
        alt=""
        class="preview"
        size="fit"
      />
    </ng-container>

    <ng-container *ngIf="previews.length >= 3">
      <m-skeleton-image
        [url]="formatPreviewImage(previews[1])"
        (click)="onPreview.emit()"
        [class.active]="isDetailsAvailable"
        width="290"
        height="200"
      ></m-skeleton-image>
      <m-skeleton-image
        [url]="formatPreviewImage(previews[0])"
        (click)="onPreviewClick()"
        [class.active]="isDetailsAvailable"
        width="290"
        height="200"
      ></m-skeleton-image>
    </ng-container>

    <div *ngIf="isHovered" class="fade">
      <div class="select">
        <m-button
          i18n="@@books.template.select-template.select-button"
          button-style="primary"
          size="full-width"
          [is-disabled]="isSelectDisable"
          (onClick)="onSelectClick()"
        >
          books.template.select-template.select-button
        </m-button>
      </div>

      <div *ngIf="isDetailsAvailable" class="more">
        <m-button
          i18n="@@books.template.select-template.details-button"
          button-style="secondary"
          size="full-width"
          (onClick)="onPreviewClick()"
        >
          books.template.select-template.details-button
        </m-button>
      </div>
    </div>
  </div>

  <div class="info" (click)="onInfoClick()">
    <p class="title">{{ title }}</p>
    <p class="description">{{ description }}⠀</p>
  </div>
</div>
