<form *ngIf="!isLoading" class="container" [formGroup]="form">
  <m-nav-tabs class="nav-tabs" [tabs]="navTabs" (onTabClick)="onNavTabClick($event)"></m-nav-tabs>

  <div class="preview">
    <m-running-titles-preview
      [page-size]="pageSize"
      [font-size]="fontSizeRunningTitles"
      [palette]="palette"
      [hide-margins-borders]="isMarginsBordersHide"
      (onRunningTitlesTextWidthErrors)="onRunningTitlesTextWidthErrors($event)"
    ></m-running-titles-preview>
  </div>

  <div class="side-panel">
    <m-side-panel sidebar-position="right" sidebar-type="fixed">
      <ng-container>
        <div *ngIf="canProduceEpub" class="sidebar-tabs">
          <div [ngClass]="getSidebarTabCssClassList(1)" (click)="activeSidebarTab(1)">
            <m-icon src="book.svg" [color]="getTabIconColor(1)" size="s"></m-icon>
            <span i18n="@@books.margins.sidebar-tabs.printbook">books.margins.sidebar-tabs.printbook</span>
          </div>
          <div [ngClass]="getSidebarTabCssClassList(2)" (click)="activeSidebarTab(2)">
            <m-icon src="ebook.svg" [color]="getTabIconColor(2)" size="s"></m-icon>
            <span i18n="@@books.margins.sidebar-tabs.ebook">books.margins.sidebar-tabs.ebook</span>
          </div>
        </div>

        <div *ngIf="activeSidebarTabNumber === 1" class="side-panel-printbook">
          <div class="running-title-block">
            <div class="running-title-label-block">
              <div class="running-title-label-group">
                <m-switch class="switch-block" formControlName="runningTitleState"></m-switch>
                <span class="label-header" i18n="@@books.running-titles.running-title">
                  books.running-titles.running-title
                </span>
              </div>
              <m-icon
                *ngIf="getRunningTitlesState()"
                class="icon-settings"
                src="gear2.svg"
                color="--color-fill-running-titles-icon-settings-enable"
                size="xs"
                (click)="toggleRunningTitlesSettings()"
              />
            </div>

            <div *ngIf="getRunningTitlesState()" class="running-title-block-settings" @slideInOutVertical>
              <m-info-block *ngIf="this.isMarginsSizeErrorVisible()" type="warning" [labels]="getInfoLabels()" />

              <div class="running-title-position-block">
                <m-running-titles-position-group
                  class="running-title-position"
                  formControlName="runningTitlePosition"
                  [positions]="runningTitlesPositions"
                ></m-running-titles-position-group>
              </div>

              <div class="running-titles-settings">
                <span class="label" i18n="@@books.running-titles.running-title-left">
                  books.running-titles.running-title-left
                </span>
                <m-select
                  class="select"
                  input-style="normal"
                  size="s"
                  [options]="getRunningTitleOptions()"
                  [defaultValueId]="1"
                  name="running-titleLeft"
                  formControlName="runningTitleLeft"
                ></m-select>
                <m-ng-input
                  *ngIf="isRunningTitleInputVisible('left')"
                  class="input"
                  formControlName="runningTitleLeftText"
                  input-style="normal"
                />

                <m-info-block type="error" [labels]="getRunningTitleErrors('left')" />
              </div>

              <div class="running-titles-settings">
                <span class="label" i18n="@@books.running-titles.running-title-right">
                  books.running-titles.running-title-right
                </span>
                <m-select
                  class="select"
                  input-style="normal"
                  size="s"
                  [options]="getRunningTitleOptions()"
                  [defaultValueId]="1"
                  name="running-titleRight"
                  formControlName="runningTitleRight"
                ></m-select>
                <m-ng-input
                  *ngIf="isRunningTitleInputVisible('right')"
                  class="input"
                  formControlName="runningTitleRightText"
                />

                <m-info-block type="error" [labels]="getRunningTitleErrors('right')" />
              </div>
            </div>
          </div>

          <div class="separator-block"></div>

          <div class="running-title-block">
            <div class="running-title-label-block">
              <div class="running-title-label-group">
                <m-switch class="switch-block" formControlName="pageNumberState"></m-switch>
                <span class="label-header" i18n="@@books.running-titles.page-number">
                  books.running-titles.page-number
                </span>
              </div>
              <m-icon
                *ngIf="getPageNumbersState()"
                class="icon-settings"
                [ngClass]="{'disabled': isPageNumberSettingsIconDisabled()}"
                src="gear2.svg"
                [color]="!isPageNumberSettingsIconDisabled() ? '--color-fill-running-titles-icon-settings-enable' : '--color-fill-running-titles-icon-settings-disable'"
                size="xs"
                (click)="togglePageNumbersSettings()"
              />
            </div>

            <div *ngIf="getPageNumbersState()" @slideInOutVertical>
              <div class="running-title-position-block">
                <m-running-titles-position-group
                  class="running-title-position"
                  formControlName="pageNumberPosition"
                  [positions]="runningTitlesPositions"
                ></m-running-titles-position-group>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="activeSidebarTabNumber === 2" class="side-panel-ebook">
          <m-sidebar-tab-ebook />
        </div>
        <div class="buttons">
          <m-button
            button-style="secondary"
            icon-position="only"
            icon="button-back.svg"
            (onClick)="onBackClick()"
          ></m-button>

          <m-button
            button-style="primary"
            size="full-width"
            [is-disabled]="!form.valid"
            (onClick)="onNextClick()"
            i18n="@@books.styles.next-button"
          >
            books.styles.next-button
          </m-button>
        </div>
      </ng-container>
    </m-side-panel>
  </div>

  <m-side-panel
    *ngIf="isRunningTitlesSettingsVisible"
    class="settings-panel"
    sidebar-position="right"
    sidebar-type="fixed"
    @slideInOutSidebarLTR
  >
    <ng-container>
      <div class="sidebar-tabs">
        <div class="settings-tab">
          <m-icon src="pencil.svg" color="--color-font-tab-active" size="s"></m-icon>
          <span i18n="@@books.running-titles.running-titles-design">books.running-titles.running-titles-design</span>
        </div>
      </div>

      <div class="side-panel-styles">
        <m-running-titles-styles-group
          formControlName="runningTitleStyle"
          page-position="right"
          [running-titles-styles]="getRunningTitlesStyles()"
          [palette]="palette"
          [context]="getRunningTitleText()"
        ></m-running-titles-styles-group>
      </div>
    </ng-container>
  </m-side-panel>

  <m-side-panel
    *ngIf="isPageNumbersSettingsVisible"
    class="settings-panel"
    sidebar-position="right"
    sidebar-type="fixed"
    @slideInOutSidebarLTR
  >
    <ng-container>
      <div class="sidebar-tabs">
        <div class="settings-tab">
          <m-icon src="pencil.svg" color="--color-font-tab-active" size="s"></m-icon>
          <span i18n="@@books.running-titles.page-number-design">books.running-titles.page-number-design</span>
        </div>
      </div>

      <div class="side-panel-styles">
        <m-running-titles-styles-group
          formControlName="pageNumberStyle"
          [page-numbers-styles]="getPageNumbersStyles()"
          [palette]="palette"
          [context]="getPageNumberText()"
        ></m-running-titles-styles-group>
      </div>
    </ng-container>
  </m-side-panel>
</form>

<m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>

<m-modal-project-router-host />
