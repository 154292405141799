import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { fadeInOutForSidebarElements, slideInOutVertical } from "@metranpage/components";

@Component({
  selector: "m-books-markup-sidebar-images",
  templateUrl: "./markup-sidebar-images-settings.view.html",
  styleUrls: ["./markup-sidebar-images-settings.view.scss"],
  animations: [slideInOutVertical, fadeInOutForSidebarElements],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkupSidebarImagesSettings {
  @Input()
  form!: FormGroup;
  @Input()
  isSettingsAvailable = false;

  constructor(private readonly cdr: ChangeDetectorRef) {}
}
