import { Injectable, Injector } from "@angular/core";
import { ActivatedRouteSnapshot, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { Breadcrumb } from "../models/breadcrumbs";

@Injectable({
  providedIn: "root",
})
export class BreadcrumbsService {
  breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  private resolvers: Record<any, any> = {};

  constructor(
    private readonly router: Router,
    private readonly injector: Injector,
  ) {
    this.router.events.subscribe(async (data) => {
      if (data instanceof NavigationEnd) {
        const routeData = this.router.routerState.snapshot.root.firstChild?.data;

        if (routeData && "breadcrumbs" in routeData) {
          await this.fillBreadcrumbsFromRoute(this.router.routerState.snapshot.root);
        } else {
          this.breadcrumbs$.next([]);
        }
      }
    });
  }

  async fillBreadcrumbsFromRoute(route: ActivatedRouteSnapshot) {
    const bcDeclaration = route.firstChild?.data["breadcrumbs"];

    if ("titleKey" in bcDeclaration) {
      const breadcrumbs = [];

      // this is singe page breadcrumb, just add title and link to current url
      breadcrumbs.push({
        isEnabled: true,
        title: this.getLocalizedTitle(bcDeclaration.titleKey),
        url: route.firstChild!.url.map((url) => url.path).join("/"),
        isVisible: true,
      });

      this.breadcrumbs$.next(breadcrumbs);
    } else if ("resolver" in bcDeclaration) {
      const resolverType = bcDeclaration.resolver;
      if (!(resolverType in this.resolvers)) {
        const resolver = this.injector.get(resolverType);
        this.resolvers[resolverType] = resolver;
      }
      const resolver = this.resolvers[resolverType];

      // TO DO move to observable

      const bcs = await resolver.resolve(route);
      this.breadcrumbs$.next(bcs);
    }
  }

  disableBreadcrumbs() {
    const bcs = this.breadcrumbs$.value;
    for (const bc of bcs) {
      bc.isEnabled = false;
    }
    this.breadcrumbs$.next(bcs);
  }

  enableBreadcrumbs() {
    const bcs = this.breadcrumbs$.value;
    for (const bc of bcs) {
      bc.isEnabled = true;
    }
    this.breadcrumbs$.next(bcs);
  }

  // we cant use $localize in routing, so we work around this by custom keys and localize after routes initialization
  getLocalizedTitle(key: string) {
    switch (key) {
      case "new-project":
        return $localize`:@@books.breadcrumb.new-project:`;
      case "account":
        return $localize`:@@user.profile.title:`;
      case "admin":
        return $localize`:@@app-bar.admin-button:`;
      case "tariffs-and-payments":
        return $localize`:@@pricing.breadcrumbs.tariffs-and-payments:`;
      case "payments":
        return $localize`:@@pricing.breadcrumbs.payments:`;
      case "cover":
        return $localize`:@@books.breadcrumbs.cover:`;
      default:
        return "there is no loc key, see breadcrumbsService";
    }
  }
}
