import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { Subject, debounceTime, takeUntil } from "rxjs";
import { Company, CompanyCreateData } from "../../models/company";
import { CompaniesAdminService } from "../../services/companies.service";
import { CompaniesAdminStore } from "../../services/companies.store";

@Component({
  selector: "m-admin-companies-page",
  templateUrl: "./companies.page.html",
  styleUrls: ["./companies.page.scss"],
})
export class AdminCompaniesPage implements OnInit {
  private destroyed$ = new Subject<void>();

  companies: Company[] = [];
  page = 1;
  pageCount = 1;

  protected searchForm = new FormGroup({
    title: new FormControl("", { nonNullable: true, validators: [] }),
  });
  protected searchTitle: string | undefined = undefined;

  protected updateCompanyDataForm = new FormGroup({
    title: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    landingUrl: new FormControl("", { nonNullable: true, validators: [] }),
    hostUrl: new FormControl("", { nonNullable: true, validators: [] }),
  });

  protected isAddCompanyModalVisible = false;
  protected isDeleteCompanyModalVisible = false;
  protected isCompanyDetailsShown = false;
  protected detailsCompany?: Company = undefined;
  protected companyForDelete?: Company = undefined;

  constructor(
    private readonly companiesService: CompaniesAdminService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    companiesStore: CompaniesAdminStore,
  ) {
    companiesStore
      .getCompaniesObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((companies) => {
        this.companies = companies;
      });

    companiesStore
      .getCompaniesPageCountObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((count) => {
        this.pageCount = count;
      });
  }

  ngOnInit(): void {
    this.companiesService.loadCompaniesPaginated(this.page);

    this.searchForm.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(600)).subscribe((value) => {
      if (value.title) {
        this.searchTitle = value.title;
      } else {
        this.searchTitle = undefined;
      }

      this.companiesService.loadCompaniesPaginated(this.page, { title: this.searchTitle });
    });
  }

  trackByCompanyId(index: number, company: Company) {
    return company.id;
  }

  onPageChanged(page: number) {
    this.page = page;
    this.companiesService.loadCompaniesPaginated(this.page);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  protected async onUpdateCompanyData(company: Company) {
    await this.companiesService.updateCompanyData(company, this.updateCompanyDataForm.getRawValue());
    await this.companiesService.loadCompaniesPaginated(this.page, { title: this.searchTitle });

    this.closeCompanyDetails();
  }

  protected showCompanyDetails(company: Company) {
    this.detailsCompany = company;
    this.isCompanyDetailsShown = true;
    this.updateCompanyDataForm.patchValue(company);
  }

  protected closeCompanyDetails() {
    this.detailsCompany = undefined;
    this.isCompanyDetailsShown = false;

    // this.updateCompanyDataForm.setValue({ title: '' });
  }

  protected async onAddCompany() {
    this.isAddCompanyModalVisible = true;
  }

  protected async onCompanyAdded(data: CompanyCreateData) {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.companiesService.createCompany(data);
    this.loadingService.stopLoading();
    if (result.status === "success") {
      this.onCloseAddCompanyModal();
      this.companiesService.loadCompaniesPaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.companies.company.error.cant-create-company:`);
    }
  }

  protected onCloseAddCompanyModal() {
    this.isAddCompanyModalVisible = false;
  }

  protected onCloseDeleteCompanyModal() {
    this.isDeleteCompanyModalVisible = false;
    this.companyForDelete = undefined;
  }

  protected showCompanyDeleteModal(company: Company) {
    if (company.id === 1) {
      return;
    }

    this.isDeleteCompanyModalVisible = true;
    this.companyForDelete = company;
  }

  protected async onDeleteCompany() {
    if (!this.companyForDelete) {
      return;
    }

    const result = await this.companiesService.deleteCompany(this.companyForDelete.id);
    if (result.status === "success") {
      this.isDeleteCompanyModalVisible = false;
      this.companyForDelete = undefined;
      this.companiesService.loadCompaniesPaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.companies.company.error.cant-delete-company:`);
    }
  }
}
