<m-account-notification-card-host [notification]="notification" [isLast]="isLast">
  <div icon class="icons-wrapper">
    <m-ng-icon class="icon-main" [size]="24" src="notification-info.svg" />
  </div>

  <div title class="title-group">
    <div class="title">
      <span i18n="@@user.profile.notifications.card.webinar.title">user.profile.notifications.card.webinar.title</span>
    </div>

    <div class="card-label" i18n="@@user.profile.notifications.filter.important">
      user.profile.notifications.filter.important
    </div>
  </div>

  <div description class="description">
    <div *ngIf="!isWebinarEnded" class="row">
      <a
        class="action"
        [href]="payload.url"
        target="_blank"
        i18n="@@user.profile.notifications.card.webinar.active.action"
      >
        user.profile.notifications.card.webinar.active.action
      </a>
      <span>{{ text }}</span>
    </div>

    <div *ngIf="isWebinarEnded" class="row">
      <span i18n="@@user.profile.notifications.card.webinar.ended.description">
        user.profile.notifications.card.webinar.ended.description
      </span>
    </div>
  </div>
</m-account-notification-card-host>
