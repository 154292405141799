import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from "@angular/core";
import { SwitchComponent, ButtonComponent } from '@metranpage/components'

import { AsyncPipe } from "@angular/common";
import { IslandComponent } from "@metranpage/design-system";
import { SNCover, UpdateSNCoversData } from "../../models/social-network-covers";
import { SocialNetworkCoversService } from "../../services/social-network-covers/social-network-covers.service";
import { SocialNetworkCoversStore } from "../../services/social-network-covers/social-network-covers.store";
import { I18nService } from "@metranpage/i18n";
import { BehaviorSubject, map, of, tap } from "rxjs";
import { Observable } from "fabric/*";
import { TitleLocale } from "./title.pipe";

@Component({
  selector: 'm-admin-social-networks-cover-page',
  templateUrl: './social-network-covers.page.html',
  styleUrl: './social-network-covers.page.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SwitchComponent, AsyncPipe, IslandComponent, ButtonComponent, TitleLocale]
})

export class SocialNetworkCoversPage implements OnInit {

  withShadow = true;
  locale!: string;
  covers$!: BehaviorSubject<SNCover[]>;
  updatedStates: UpdateSNCoversData = [];

  constructor(private socialNetworkCoversService: SocialNetworkCoversService, private socialNetworkCoversStore: SocialNetworkCoversStore,
  ) {
  }

  ngOnInit() {

    this.socialNetworkCoversService.getSNCovers();
    this.covers$ = this.socialNetworkCoversStore.getCovers$();
  }

  updateSNCoversState(coverId: number, isEnabled: boolean) {
    const isAdded = this.updatedStates.find(updatedState => updatedState.id === coverId);
    if (isAdded) {
      this.updatedStates.map(updatedState => {
        if (updatedState.id === coverId) {
          updatedState.isEnabled = isEnabled
        }
        return updatedState;
      })
      return;
    }

    this.updatedStates.push({ id: coverId, isEnabled })
  }

  updateSNCoverStates(): void {
    this.socialNetworkCoversService.updateSNCoverStates(this.updatedStates);
  }

}
