<form *ngIf="form" [formGroup]="form">
  <m-sidebar-section
    title="books.markup.sidebar.fonts-title"
    i18n-title="@@books.markup.sidebar.fonts-title"
    [isSettingsAvailable]="isSettingsAvailable"
  >
    <div class="sidebar-content" [class.locked]="!isSettingsAvailable">
      <div class="input">
        <span i18n="@@books.markup.sidebar.header-font" class="input-title">books.markup.sidebar.header-font</span>
        <m-font-select size="s" formControlName="headerFont" [options]="availableHeadersFonts"></m-font-select>
      </div>
      <div class="input">
        <span i18n="@@books.markup.sidebar.main-text-font" class="input-title">
          books.markup.sidebar.main-text-font
        </span>
        <m-font-select size="s" formControlName="mainFont" [options]="availableMainFonts"></m-font-select>
      </div>
    </div>
  </m-sidebar-section>

  <div class="section-divider divider"></div>

  <m-sidebar-section
    title="books.markup.sidebar.text-styles"
    i18n-title="@@books.markup.sidebar.text-styles"
    [isSettingsAvailable]="isSettingsAvailable"
  >
    <div class="sidebar-content styles">
      <div class="row" [class.locked]="!isSettingsAvailable">
        <span i18n="@@books.markup.sidebar.fonts-size-title" class="input-title">
          books.markup.sidebar.fonts-size-title
        </span>
      </div>
      <m-font-size-selector
        [class.locked]="!isSettingsAvailable"
        formControlName="fontSizeHeaders"
      ></m-font-size-selector>

      <div class="styles-wrapper" formGroupName="styles">
        <div class="style-wrapper" *ngFor="let styleKey of getStyleKeys()" [formGroupName]="styleKey">
          <div
            class="style-title"
            [class.inactive]="isInactiveStyle"
            [class.selected]="selectedStyles.includes(styleKey)"
            [class.hovered]="hoveredStyle == styleKey"
            (click)="onStyle(styleKey)"
          >
            <span>{{styles[styleKey].visibleTitle}}</span>
            <m-icon *ngIf="!isStyleSettingsValid(styleKey)" src="info.svg" size="xs" color="--color-error-info" />
          </div>
          <div
            class="style-settings"
            [class.inactive]="isInactiveStyle"
            [class.opened]="styleDetailsSidebarVisible == styleKey"
            (click)="onShowStyleDetails.emit(styleKey); $event.stopPropagation()"
          >
            <m-icon src="gear2.svg" size="xs" color="--color-font-main" />
          </div>
        </div>
      </div>
    </div>
  </m-sidebar-section>
</form>
