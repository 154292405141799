import { animate, group, query, stagger, style, transition, trigger } from '@angular/animations';

export const slideInOutVertical = trigger('slideInOutVertical', [
  transition(':enter', [
    query(':self', [
      style({
        height: 0,
        opacity: 0,
      }),
      animate('0.2s ease-in-out', style({ height: '*' })),
      animate('0.2s 0.1s ease-in-out', style({ opacity: 1 })),
    ]),
  ]),
  transition(':leave', [
    query(':self', [
      style({
        height: '*',
        opacity: 1,
      }),
      animate('0.2s ease-in-out', style({ opacity: 0 })),
      animate('0.2s 0.1s  ease-in-out', style({ height: 0 })),
    ]),
  ]),
]);

export const slideInOutHorizontal = trigger('slideInOutHorizontal', [
  transition(':enter', [
    group([
      query(':self', [
        style({
          width: 0,
        }),
        animate(
          '0.2s ease-in-out',
          style({
            width: '*',
          }),
        ),
      ]),
      query(
        'div',
        [
          style({
            opacity: 0,
            overflow: 'hidden',
          }),
          stagger(150, [
            animate(
              '0.2s ease-in-out',
              style({
                opacity: 1,
              }),
            ),
          ]),
        ],
        { limit: 2 },
      ),
    ]),
  ]),
  transition(':leave', [
    group([
      query(':self', [
        style({
          width: '*',
        }),
        stagger(100, [
          animate(
            '0.2s ease-in-out',
            style({
              width: 0,
            }),
          ),
        ]),
      ]),
      query(
        'div',
        [
          style({
            opacity: 1,
            overflow: 'hidden',
          }),
          animate(
            '0.2s ease-in-out',
            style({
              opacity: 0,
            }),
          ),
        ],
        { limit: 2 },
      ),
    ]),
  ]),
]);

export const slideInOutSidebarLTR = trigger('slideInOutSidebarLTR', [
  transition(':enter', [
    group([
      query(':self', [
        style({
          width: 0,
        }),
        animate(
          '0.2s ease-in-out',
          style({
            width: '*',
          }),
        ),
      ]),
      query('.sidebar-content', [
        style({
          opacity: 0,
          // overflow: 'hidden',
        }),
        animate(
          '0.2s 0.2s ease-in-out',
          style({
            opacity: 1,
          }),
        ),
      ]),
    ]),
  ]),
  transition(':leave', [
    group([
      query('.sidebar-content', [
        style({
          opacity: 1,
          // overflow: 'hidden',
        }),
        animate(
          '0.2s ease-in-out',
          style({
            opacity: 0,
          }),
        ),
      ]),
      query(':self', [
        style({
          width: '*',
        }),
        animate(
          '0.2s 0.2s ease-in-out',
          style({
            width: 0,
          }),
        ),
      ]),
    ]),
  ]),
]);
