import { Component, Input } from "@angular/core";
import { I18nService } from "@metranpage/i18n";
import { DateTime } from "luxon";
import { RewardNotification } from "../../models/rewards/reward-notification";

@Component({
  selector: "m-account-reward-notification-base-card",
  templateUrl: "account-reward-notification-base-card.view.html",
  styleUrls: ["account-reward-notification-base-card.view.scss"],
})
export class AccountRewardNotificationBaseCardView {
  @Input()
  rewardNotification!: RewardNotification;
  @Input()
  title = "";
  @Input()
  iconSrc = "reward-like.svg";
  @Input()
  isLast = true;

  protected date = "";

  protected locale = "en";

  constructor(i18nService: I18nService) {
    this.locale = i18nService.getLocale();
  }

  ngOnInit(): void {
    this.updateDate();
  }

  private updateDate() {
    let yearFormat: "numeric" | undefined = undefined;

    const rewardYear = DateTime.fromISO(this.rewardNotification.createdAt.toString()).year;
    const currentYear = DateTime.now().year;
    if (rewardYear !== currentYear) {
      yearFormat = "numeric";
    }

    this.date = DateTime.fromISO(this.rewardNotification.createdAt.toString()).toLocaleString(
      {
        ...DateTime.DATETIME_MED,
        year: yearFormat,
        month: "long",
      },
      { locale: this.locale },
    );
  }
}
