import { Injectable } from "@angular/core";
import { CoverService, PublishedCoverStore } from "@metranpage/book";
import { BookCover } from "@metranpage/book-data";
import { filterUndefined } from "@metranpage/core";
import { User, UserStore } from "@metranpage/user-data";
import { Observable } from "rxjs";
import { HomeService } from "../../services/home.service";
import { PublishedObjectsDataSource } from "./data-source";

@Injectable({
  providedIn: "root",
})
export class BookCoversDataSource extends PublishedObjectsDataSource<BookCover> {
  private items$: Observable<BookCover[]> | undefined;

  constructor(
    private readonly userStore: UserStore,
    private readonly bookCoverService: CoverService,
    private readonly publishedCoverStore: PublishedCoverStore,
    private readonly homeService: HomeService,
  ) {
    super("covers");
  }

  initItems() {
    if (!this.items$) {
      this.items$ = this.publishedCoverStore.getPublishedCoversObservable().pipe(filterUndefined());

      this.publishedCoverStore.getPublishedCoversPageCountObservable().subscribe((pageCount) => {
        this.pageCount = pageCount;
      });

      this.page = 1;

      this.loadPublishedCoversPaginated();
    }
  }

  override loadNextPage() {
    if (!this.userStore.getUser() && this.page >= 2) {
      this.homeService.showLoginModal();
      return;
    }

    this.page++;
    if (this.page > this.pageCount) {
      this.page = this.pageCount;
      return;
    }
    this.loadPublishedCoversPaginated();
  }

  override getItems(): Observable<BookCover[]> {
    this.initItems();
    return this.publishedCoverStore.getPublishedCoversObservable();
  }

  override getObject(id: number): BookCover | undefined {
    return this.publishedCoverStore.getPublishedCovers().find((ig) => ig.id === id);
  }

  override getImageUrlForObject(id: number, isThumbnail: boolean): string | undefined {
    if (isThumbnail) {
      return this.bookCoverService.getUrlForCoverThumbnailByCoverId(id);
    }
    return this.bookCoverService.getUrlForCoverByCoverId(id);
  }

  override getAuthorForObject(id: number): User | undefined {
    const cover = this.publishedCoverStore.getPublishedCovers().find((ig) => ig.id === id);
    if (!cover) {
      return undefined;
    }
    return cover.author as User;
  }

  protected async loadPublishedCoversPaginated() {
    const user = this.userStore.getUser();
    if (user) {
      this.bookCoverService.loadPublishedCoversPaginated(this.page);
    } else {
      this.bookCoverService.loadPublishedCoversPaginatedUnauthorized(this.page);
    }
  }

  override like(id: number) {
    this.bookCoverService.likeBookCover(id);
  }

  override unlike(id: number) {
    this.bookCoverService.unlikeBookCover(id);
  }
}
