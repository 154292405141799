import { Injectable } from "@angular/core";
import { Canvas } from "fabric";

@Injectable({
  providedIn: "root",
})
export class CoverSnippetCreateService {
  createCanvas(width: number, height: number) {
    const canvas = document.createElement("canvas");
    return new Canvas(canvas, {
      width,
      height,
      uniformScaling: true,
      preserveObjectStacking: true,
      controlsAboveOverlay: true,
      stopContextMenu: true,
      selection: true,
      defaultCursor: "inherit",
    });
  }
}
