<div
  *ngIf="prefix"
  class="prefix"
>
  <span>{{ prefix }}</span>
  <div class="divider"></div>
</div>

<input
  [placeholder]="placeholder"
  [name]="name"
  [autocomplete]="getAutocomplete()"
  [required]="isRequired"
  [tabindex]="tabindex"
  [type]="getInputType()"
  [disabled]="isDisabled"
  (input)="onInputChange($event)"
  #input
/>

<ng-content [select]="input-right">
</ng-content>
