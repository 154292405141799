import { Component, HostBinding, HostListener, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BaseFormControl } from "../base-form-control/base-form-control.component";
import { IconComponent } from "../icon/icon.component";
import { ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "m-ng-checkbox",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IconComponent],
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent extends BaseFormControl<boolean> {
  @Input()
  title?: string;

  @HostBinding("class.checked")
  @HostBinding("attr.aria-checked")
  override value = false;

  @HostBinding("attr.role")
  role = "checkbox";

  protected override getInitValue(): boolean {
    return false;
  }

  protected toggle() {
    if (this.isDisabled) {
      return;
    }
    this.onChange(!this.value);
  }

  @HostListener("click")
  onClick() {
    this.toggle();
  }

  @HostListener("keydown", ["$event"])
  onKeydown(event: KeyboardEvent) {
    if (event.code === "Space" || event.code === "Enter") {
      this.onPressed();
    }
  }
  @HostListener("keyup", ["$event"])
  onKeyup(event: KeyboardEvent) {
    if (event.code === "Space" || event.code === "Enter") {
      this.onUnpressed();
      this.toggle();
    }
  }

  @HostListener("pointerdown")
  onPressed() {
    this.isPressed = true;
    this.cdr.markForCheck();
  }
  @HostListener("pointerup")
  onUnpressed() {
    this.isPressed = false;
    this.cdr.markForCheck();
  }
  @HostListener("mouseout")
  override onUnhover() {
    super.onUnhover();
    this.isPressed = false;
    this.cdr.markForCheck();
  }
}
