import { Component, Input } from "@angular/core";
import { slideInOutVertical } from "@metranpage/components";

@Component({
  selector: "m-sidebar-section",
  templateUrl: "./sidebar-section.view.html",
  styleUrls: ["./sidebar-section.view.scss"],
  animations: [slideInOutVertical],
})
export class SidebarSection {
  @Input()
  title!: string;
  @Input()
  isSettingsAvailable = false;

  isCollapsed = true;

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }
}
