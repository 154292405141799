import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SelectValue } from "@metranpage/components";
import { GeneralResultStatus } from "@metranpage/core-data";
import * as _ from "lodash-es";
import { RewardDaily, RewardDailyCreateData } from "../../../models/reward-daily";
import { AdminRewardsDailyApi, RewardsDailyFilter } from "./rewards-daily.api";
import { AdminRewardsDailyStore } from "./rewards-daily.store";

const reNonEmptyString = /^(?!\s*$).+/;

@Injectable({
  providedIn: "root",
})
export class AdminRewardsDailyService {
  protected rewardsDailyTypeOptions: SelectValue[] = [
    { id: "", value: "" },
    { id: "likes-take", value: $localize`:@@admin.rewards.daily.task.likes.take:` },
    { id: "likes-give", value: $localize`:@@admin.rewards.daily.task.likes.give:` },
  ];

  constructor(
    private readonly adminRewardsDailyApi: AdminRewardsDailyApi,
    private readonly adminRewardsDailyStore: AdminRewardsDailyStore,
  ) {}

  async loadRewardDaily(rewardDailyId: number) {
    const rewardDailyData = await this.adminRewardsDailyApi.getRewardDaily(rewardDailyId);
    this.adminRewardsDailyStore.setActiveRewardDaily(rewardDailyData.rewardDaily);
  }

  async loadRewardsDaily(): Promise<RewardDaily[]> {
    return this.adminRewardsDailyApi.getRewardsDaily();
  }

  async loadRewardsDailyPaginated(page: number, filters: RewardsDailyFilter = {}) {
    const data = await this.adminRewardsDailyApi.getRewardsDailyPaginated(page, filters);
    this.adminRewardsDailyStore.setRewardsDaily(data.items);
    this.adminRewardsDailyStore.setRewardsDailyPageCount(data.pageCount);
  }

  async updateRewardDaily(rewardDailyId: number, data: RewardDailyCreateData): Promise<GeneralResultStatus> {
    try {
      await this.adminRewardsDailyApi.updateRewardDaily(rewardDailyId, data);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async createRewardDaily(data: RewardDailyCreateData): Promise<{ status: GeneralResultStatus; id?: number }> {
    try {
      const newRewardDaily = await this.adminRewardsDailyApi.createRewardDaily(data);
      return {
        status: "success",
        id: newRewardDaily.id,
      };
    } catch (error: any) {
      return {
        status: "error",
        id: undefined,
      };
    }
  }

  async deleteRewardDaily(rewardDailyId: number): Promise<{ status: GeneralResultStatus }> {
    try {
      const result = await this.adminRewardsDailyApi.deleteRewardDaily(rewardDailyId);
      return {
        status: "success",
      };
    } catch (error: any) {
      return {
        status: "error",
      };
    }
  }

  createRewardDailyForm() {
    return new FormGroup({
      type: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(reNonEmptyString)],
      }),
      limit: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
      rewardCreditsCount: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
      companyId: new FormControl(1, { nonNullable: true, validators: [Validators.required] }),
    });
  }

  getAllRewardsDailyTypeSelectOptions() {
    return this.rewardsDailyTypeOptions;
  }

  getAvailableRewardsDailyTypeSelectOptions() {
    const options: SelectValue[] = [];

    const rewardsDaily = this.adminRewardsDailyStore.getRewardsDaily();
    for (const o of this.getAllRewardsDailyTypeSelectOptions()) {
      const existingOption = rewardsDaily.find((r) => r.type === o.id);
      if (existingOption) {
        continue;
      }
      options.push(o);
    }

    return options;
  }
}
