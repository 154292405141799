import { Component, EventEmitter, Input, Output } from "@angular/core";
import { User } from "@metranpage/user-data";

@Component({
  selector: "m-account-social-connect-button-view",
  templateUrl: "./account-social-connect-button.view.html",
  styleUrls: ["./account-social-connect-button.view.scss"],
})
export class AccountSocialConnectButtonView {
  @Input()
  user?: User;
  @Input()
  isConnected = false;
  @Input()
  connectButtonText = "";
  @Input()
  iconSrc = "";
  @Input()
  title = "";

  @Output()
  onConnect = new EventEmitter<void>();
  @Output()
  onDisconnect = new EventEmitter<void>();

  protected onConnectClick() {
    this.onConnect.emit();
  }

  protected onDisconnectClick() {
    this.onDisconnect.emit();
  }

  protected getStatusText() {
    if (this.isConnected) {
      return $localize`:@@user.profile.socials.connected:`;
    }
    return $localize`:@@user.profile.socials.not-connected:`;
  }
}
