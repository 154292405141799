import { Routes } from "@angular/router";
import { AuthGuard } from "@metranpage/auth";
import { BookGuard } from "./book.guard";
import { BooksPage } from "./pages/books/books.page";
import { CoverPage } from "./pages/cover/cover.page";
import { MarginsPage } from "./pages/margins/margins.page";
import { MarkupPage } from "./pages/markup/markup.page";
import { PreviewPage } from "./pages/preview/preview.page";
import { RunningTitlesPage } from "./pages/running-titles/running-titles.page";
import { BookBreadcrumbsResolver } from "./services/book-breadcrumbs.resolver";
import { EditProjectModalView } from "./views/edit-project-modal/edit-project-modal.view";
import { ModalBookDataView } from "./views/modal-book-data/modal-book-data.view";
import { ModalBookFileUploadView } from "./views/modal-book-file-upload/modal-book-file-upload.view";
import { ModalBookTemplatePreviewView } from "./views/modal-book-template-preview/modal-book-template-preview.view";
import { ModalBookTemplatesView } from "./views/modal-book-templates/modal-book-templates.view";
import { ModalCoverDataView } from "./views/modal-cover-data/modal-cover-data.view";
import { NewCoverModalView } from "./views/new-cover-modal/new-cover-modal.view";
import { NewProjectModalView } from "./views/new-project-modal/new-project-modal.view";

export function editModal() {
  return {
    outlet: "modal",
    path: "edit/:modalBookId",
    component: EditProjectModalView,
    children: [
      {
        path: "book-templates/:width/:height",
        component: ModalBookTemplatesView,
      },
      {
        path: "book-templates/:width/:height/:templateId/preview",
        component: ModalBookTemplatePreviewView,
      },
      {
        path: "book-upload/:width/:height/:templateId",
        component: ModalBookFileUploadView,
      },
      {
        path: "book-data/:width/:height/:templateId",
        component: ModalBookDataView,
      },
      {
        path: "book-data/:width/:height/:templateId/to-cover",
        component: ModalBookDataView,
      },
    ],
  };
}

function newProjectModal() {
  return {
    outlet: "modal",
    path: "new/:modalBookId",
    component: NewProjectModalView,
    children: [
      {
        path: "book-templates/:width/:height",
        component: ModalBookTemplatesView,
      },
      {
        path: "book-templates/:width/:height/:templateId/preview",
        component: ModalBookTemplatePreviewView,
      },
      {
        path: "book-upload/:width/:height/:templateId",
        component: ModalBookFileUploadView,
      },
      {
        path: "book-data/:width/:height/:templateId",
        component: ModalBookDataView,
      },
      {
        path: "book-data/:width/:height/:templateId/to-cover",
        component: ModalBookDataView,
      },
    ],
  };
}

function newCoverModal() {
  return {
    outlet: "modal",
    path: "new-cover/:modalBookId",
    component: NewCoverModalView,
    children: [
      {
        path: "book-data/:width/:height",
        component: ModalCoverDataView,
      },
    ],
  };
}

export const bookRoutes: Routes = [
  {
    path: "books",
    component: BooksPage,
    canActivate: [AuthGuard],
    data: {
      showTopBar: true,
      showMobileWarning: false,
      welcomeModalVisible: true,
      hasOnboarding: true,
    },
  },

  {
    path: "books/:bookId/markup",
    component: MarkupPage,
    canActivate: [AuthGuard],
    canDeactivate: [BookGuard],
    data: {
      welcomeModalVisible: true,
      hasOnboarding: true,
      breadcrumbs: {
        resolver: BookBreadcrumbsResolver,
      },
    },
  },
  {
    path: "books/:bookId/margins",
    component: MarginsPage,
    canActivate: [AuthGuard],
    data: {
      welcomeModalVisible: true,
      hasOnboarding: true,
      breadcrumbs: {
        resolver: BookBreadcrumbsResolver,
      },
    },
  },
  {
    path: "books/:bookId/running-titles",
    component: RunningTitlesPage,
    canActivate: [AuthGuard],
    data: {
      welcomeModalVisible: true,
      hasOnboarding: true,
      breadcrumbs: {
        resolver: BookBreadcrumbsResolver,
      },
    },
  },
  {
    path: "books/:bookId/preview",
    component: PreviewPage,
    canActivate: [AuthGuard],
    data: {
      welcomeModalVisible: true,
      hasOnboarding: true,
      breadcrumbs: {
        resolver: BookBreadcrumbsResolver,
      },
    },
  },
  {
    path: "books/:bookId/cover",
    component: CoverPage,
    canActivate: [AuthGuard],
    canDeactivate: [BookGuard],
    data: {
      welcomeModalVisible: true,
      hasOnboarding: true,
      // breadcrumbs: {
      //   titleKey: "cover",
      //   // resolver: BookBreadcrumbsResolver,
      // },
      hideRePlain: true,
    },
  },
  newProjectModal(),
  newCoverModal(),
  editModal(),
];
