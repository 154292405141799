import { Injectable } from "@angular/core";
import { ApiErrorHandlerService, RealtimeService } from "@metranpage/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import {
  ModerationCoverRequest,
  ModerationCoverRequestPublishedFrom,
  ModerationCoverRequestUpdateData,
} from "../../models/moderation-cover-request";
import { AdminModerationCoverRequestApi } from "./moderation-cover-request.api";
import { AdminModerationCoverRequestStore } from "./moderation-cover-request.store";

@Injectable({
  providedIn: "root",
})
export class AdminModerationCoverRequestService {
  constructor(
    private readonly api: AdminModerationCoverRequestApi,
    private readonly store: AdminModerationCoverRequestStore,
    private readonly apiErrorHandler: ApiErrorHandlerService,
    realtimeService: RealtimeService,
  ) {
    realtimeService
      .getEvents<ModerationCoverRequest>("moderation-cover-request-change")
      .subscribe((moderationCoverRequestUpdate: ModerationCoverRequest | undefined) => {
        if (moderationCoverRequestUpdate) {
          store.updateModerationCoverRequest(moderationCoverRequestUpdate);
        }
      });
  }

  async loadModerationCoverRequestsPaginated(page: number, publishedFrom: ModerationCoverRequestPublishedFrom) {
    const coversOnModerationData = await this.api.loadModerationCoverRequestsPaginated(page, publishedFrom);
    this.store.addModerationCoverRequestsToEnd(coversOnModerationData.items);
    this.store.setModerationCoverRequestsPageCount(coversOnModerationData.pageCount);
  }

  async acceptModerationCoverRequest(id: number): Promise<GeneralResultStatus> {
    try {
      await this.api.acceptModerationCoverRequest(id);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "error";
    }
  }

  async declineModerationCoverRequest(
    id: number,
    data: ModerationCoverRequestUpdateData,
  ): Promise<GeneralResultStatus> {
    try {
      await this.api.declineModerationCoverRequest(id, data);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "error";
    }
  }
}
