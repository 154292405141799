<div (mouseleave)="onMouseLeave($event)" (blur)="onBlur($event)">
    <!-- -->
    <div
            class="editor-block-table-content"
            #content
            (mousedown)="onMouseDown($event)"
            (mouseover)="onMouseOver($event)"
            (input)="onChange($event)"
            (keypress)="onKeyPress($event)"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
            (blur)="onBlur($event)"
            (contextmenu)="displayContextMenu($event)"
            (click)="onMouseClick($event)"
    ></div>
    <m-markup-editor-table-context-menu
            *ngIf="tableContextMenuVisible"
            [ngStyle]="getContextMenuStyle()"
            (onContextMenuItemClick)="handleContextMenuClick($event)"
            [selectedRowsCount]="tableSelectedRowsCount"
            [selectedColumnsCount]="tableSelectedColumnsCount"
    ></m-markup-editor-table-context-menu>

    <div
      *ngIf="tableToolbarVisible"
      [ngStyle]="getToolbarRowStyle()"
      #tableToolbarRow
      class="table-toolbar"
    >
        <!-- <a
          *ngIf="tableSelectedColumnsCount > 1"
          class="table-toolbar-item"
          data-tooltip="tooltip1"
          (click)="showRowContextMenu()"
        >
          <m-icon [src]="'/assets/icons/table-toolbar-dots.svg'" class="icon" size="s"></m-icon>
        </a> -->
        <!-- <a class="table-toolbar-item" (click)="onAddRow()">
            <m-icon [src]="'/assets/icons/table-toolbar-add.svg'" class="icon" size="xs"></m-icon>
            <span class="tooltip" i18n="@@editor.table.toolbar.add_row">editor.table.toolbar.add_row</span>
        </a> -->
    </div>
    <div
      #tableToolbarColumn
      *ngIf="tableToolbarVisible"
      [ngStyle]="getToolbarColumnStyle()"
      class="table-toolbar"
    >
        <!-- <a class="table-toolbar-item" *ngIf="tableSelectedRowsCount > 1" (click)="displayContextMenu($event)">
          <m-icon [src]="'/assets/icons/table-toolbar-dots.svg'" class="icon" size="s"></m-icon>
        </a> -->
        <!-- <a class="table-toolbar-item" (click)="onAddColumn()">
            <m-icon [src]="'/assets/icons/table-toolbar-add.svg'" class="icon" size="xs"></m-icon>
            <span class="tooltip" i18n="@@editor.table.toolbar.add_column">editor.table.toolbar.add_column</span>
        </a> -->
    </div>
</div>
