import { Injectable } from "@angular/core";
import { UserBalance } from "@metranpage/user-data";
import {
  FabulGenerationMode,
  FabulaCreateGenerationResultDataDto,
  FabulaRemoveBackgroundDataDto,
} from "../../models/fabula/fabula";
import { ImageGenerationPrices } from "../../models/image-generation";
import { FabulaImageGenerationApi } from "./fabula-image-generation.api";

export type GeneralResultStatus = "success" | "error";

@Injectable({
  providedIn: "root",
})
export class FabulaImageGenerationService {
  constructor(
    private readonly fabulaImageGenerationApi: FabulaImageGenerationApi,
    // realtimeService: RealtimeService,
  ) {}

  async removeBackground(data: FabulaRemoveBackgroundDataDto): Promise<FabulaCreateGenerationResultDataDto> {
    try {
      return await this.fabulaImageGenerationApi.removeBackground(data);
    } catch (error) {
      return { status: "error" };
    }
  }

  isEnoughtTokens(
    prices: ImageGenerationPrices | undefined,
    balance: UserBalance | undefined,
    mode: FabulGenerationMode,
  ): boolean {
    let price: number | undefined = undefined;
    if (mode === "nobg") {
      price = prices?.fabulaRemoveBackground;
    }

    if (price && balance && price <= balance.credits) {
      return true;
    }
    return false;
  }
}
