import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from "rxjs";
import { Character } from "../../../models/character/character";
import { CharacterChangePopupEvent, CharactersService } from "../../../services/characters.service";
import { FakeCursorClassName, PromptTextSelectionState } from "./services/prompt-text-selection.service";
import { RangeService } from "./services/range.service";

export type PromptTextErrors = {
  hasNotExistingCharactersError: boolean;
  hasCharactersLimitError: boolean;
};

@Component({
  selector: "m-prompt-text-editor",
  templateUrl: "prompt-text-editor.view.html",
  styleUrls: ["prompt-text-editor.view.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PromptTextEditor),
      multi: true,
    },
  ],
})
export class PromptTextEditor implements ControlValueAccessor, OnChanges {
  @Input()
  maxLenght = 350;
  @Input()
  characters: Character[] = [];
  @Input()
  charactersInPromptLimit = 3;
  @Input()
  errors?: PromptTextErrors;
  @Input()
  needBlockArrowsUpAndDown = false;

  @Output()
  onBlur = new EventEmitter<void>();
  @Output()
  onFocus = new EventEmitter<void>();

  @Output()
  onShowChangePopup = new EventEmitter<CharacterChangePopupEvent>();
  @Output()
  onShowCharactersModal = new EventEmitter<number>();

  @Output()
  charactersIdsChanged: EventEmitter<number[]> = new EventEmitter();
  @Output()
  notExistingCharactersIdsChanged: EventEmitter<number[]> = new EventEmitter();

  @ViewChild("editor", { read: ElementRef, static: true })
  protected editorElement!: ElementRef;

  value = "";

  protected sub = new Subscription();

  protected listeners: any[] = [];

  protected addedCharactersIds: number[] = [];
  protected notExistingCharactersIds: number[] = [];

  onChange = (_value: string) => {};
  onTouched = () => {};

  constructor(
    private readonly charactersService: CharactersService,
    private readonly rangeService: RangeService,
    protected readonly elementRef: ElementRef,
    protected readonly cdr: ChangeDetectorRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.characters) {
      this.updateCharacters();
    }
  }

  writeValue(value: string): void {
    if (value == null) {
      return;
    }
    this.value = value;
    this.setDataToHtml();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(_isDisabled: boolean): void {
    /* implement if needed */
  }

  onChangeHandle(event: Event) {
    this.checkOnCharactersInPrompt();

    this.updateCharactersIds();
    this.updateNotExistingCharacters();

    this.value = this.convertCharactersToString();

    this.addFakeCursor();

    this.onChange(this.value);
  }

  onFocusHandle(event: Event) {
    this.onFocus.emit();
  }

  onBlurHandle(event: Event) {
    this.addFakeCursor();
    this.onBlur.emit();
  }

  addFakeCursor() {
    const range = this.getRange();

    if (!range) {
      return;
    }

    this.removeFakeCursors();

    if (!range.collapsed) {
      range.collapse(false);
    }

    const fakeCursor = document.createElement("span");
    fakeCursor.setAttribute("data-fake-cursor-id", FakeCursorClassName);
    fakeCursor.setAttribute("contenteditable", "false");

    range.insertNode(fakeCursor);
    range.setEndAfter(fakeCursor);
    range.collapse(false);

    // this.saveDataFromHtml();
  }

  private removeFakeCursors() {
    const fakeCursors: HTMLCollection = this.editorElement.nativeElement.querySelectorAll("[data-fake-cursor-id]");
    for (const fc of Array.from(fakeCursors)) {
      fc.remove();
    }
  }

  private restoreCaretAtFakeCursor() {
    const fakeCursor = this.editorElement.nativeElement.querySelector("[data-fake-cursor-id]");
    if (!fakeCursor) {
      this.rangeService.setCaretToEnd(this.editorElement.nativeElement);
      return;
    }

    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(fakeCursor);
    range.collapse(false);
    sel?.removeAllRanges();
    sel?.addRange(range);

    fakeCursor.remove();

    return range;
  }

  getPromptTextSelectionState(): PromptTextSelectionState | undefined {
    const range = this.getRange();
    if (!range) {
      return undefined;
    }

    return {
      range: range,
    };
  }

  protected getRange(): Range | undefined {
    const sel = window.getSelection();
    if (sel?.rangeCount === 0) {
      return;
    }

    if (sel?.getRangeAt(0).intersectsNode(this.elementRef.nativeElement)) {
      return sel?.getRangeAt(0).cloneRange();
    }

    return undefined;
  }

  addCharacterAtCaret(character: Character, spaceBefore: boolean, spaceAfter: boolean) {
    this.addCharacter(character, spaceBefore, spaceAfter);
  }

  addCharacterToEnd(character: Character, spaceBefore: boolean, spaceAfter: boolean) {
    this.rangeService.setCaretToEnd(this.editorElement.nativeElement);
    this.addCharacter(character, spaceBefore, spaceAfter);
  }

  addCharacterAtFakeCursor(character: Character, spaceBefore: boolean, spaceAfter: boolean) {
    this.removeCharacterName();
    this.restoreCaretAtFakeCursor();
    this.addCharacter(character, spaceBefore, spaceAfter);
    this.addFakeCursor();
  }

  private addCharacter(character: Character, spaceBefore = false, spaceAfter = false) {
    const sel = window.getSelection();
    const range = this.getRange();
    if (!range) {
      return;
    }
    if (!range.collapsed) {
      range.collapse(false);
    }

    const characterTag = this.createCharacterTag(character.id, character.name);

    if (spaceAfter) {
      range.insertNode(document.createTextNode("\u00A0"));
    }

    range.insertNode(characterTag);

    if (spaceBefore) {
      range.insertNode(document.createTextNode(" "));
    }

    const offset = range.endOffset;
    sel?.removeAllRanges();

    const newRange = document.createRange();
    newRange.setStart(this.editorElement.nativeElement, offset);
    newRange.setEnd(this.editorElement.nativeElement, offset);
    newRange.collapse(false);

    sel?.addRange(newRange);

    this.saveDataFromHtml();

    this.updateCharactersEventListeners();

    this.updateCharactersIds();
    this.updateNotExistingCharacters();
  }

  changeCharacter(idForReplace: number, character: Character) {
    const charactersElements: HTMLCollection = this.editorElement.nativeElement.querySelectorAll(
      `[data-character-id="${idForReplace}"]`,
    );
    for (const characterElement of Array.from(charactersElements)) {
      const characterNode = this.createCharacterTag(character.id, character.name);
      this.editorElement.nativeElement.replaceChild(characterNode, characterElement);
    }

    this.updateCharactersIds();
    this.updateNotExistingCharacters();

    this.saveDataFromHtml();
  }

  getTextBetweenCommercialAtAndCaret(range: Range) {
    const textContent = range.startContainer.textContent;
    if (!textContent) {
      return;
    }

    const caretIndex = range.startOffset;
    const lastCommercialAtIndex = textContent.lastIndexOf("@", caretIndex - 1);
    if (lastCommercialAtIndex >= 0) {
      const previousSymbol = textContent.slice(Math.max(0, lastCommercialAtIndex - 1), lastCommercialAtIndex);
      const nextSymbol = textContent.length >= caretIndex ? textContent[caretIndex] : undefined;
      const textBetweenCommercialAtAndCaret = textContent.slice(Math.max(0, lastCommercialAtIndex), caretIndex);

      if (
        /\s/gm.test(textBetweenCommercialAtAndCaret) ||
        /@{2,}/gm.test(textBetweenCommercialAtAndCaret) ||
        (previousSymbol && !/\s/gm.test(previousSymbol)) ||
        (nextSymbol && !/\s/gm.test(nextSymbol))
      ) {
        return;
      }

      return {
        textBetweenCommercialAtAndCaret,
        caretIndex,
      };
    }

    return;
  }

  private removeCharacterName() {
    let html = this.editorElement.nativeElement.innerHTML;
    html = html.replace(/@(\S*)(<span data-fake-cursor-id="fake-cursor" contenteditable="false"><\/span>)/, "$2");
    // html = html.replace(/\s@(.*)(<span data-fake-cursor-id="fake-cursor" contenteditable="false"><\/span>)/, " $2");
    this.editorElement.nativeElement.innerHTML = html;
  }

  private createCharacterTag(id: number, textContent: string | undefined, toLowerCase = false) {
    let tag = "character";
    let text = textContent || $localize`:@@characters.generation.character-select-text:`;
    if (!textContent) {
      tag = "character-select";

      if (toLowerCase) {
        text = text.toLowerCase();
      }
    }

    const character = document.createElement(tag);
    character.setAttribute("data-character-id", `${id}`);
    character.setAttribute("contenteditable", "false");

    const title = document.createElement("span");
    title.textContent = text;
    title.className = "title";

    const listenerTitleClick = this.renderer.listen(title, "click", (e) => {
      this.onCharacterChangeClick(id, e.target);
    });
    this.listeners.push(listenerTitleClick);

    // const listenerTitleMouseEnter = this.renderer.listen(title, "mouseenter", (e) => {
    //   this.onCharacterMouseEnterEvent(id, e.target);
    // });
    // this.listeners.push(listenerTitleMouseEnter);

    // const listenerTitleMouseLeave = this.renderer.listen(title, "mouseleave", (e) => {
    //   this.onCharacterMouseLeaveEvent();
    // });
    // this.listeners.push(listenerTitleMouseLeave);

    const icon = document.createElement("img");
    icon.src = "/assets/icons/close-cross-16.svg";
    icon.className = "icon";

    const listenerIconClick = this.renderer.listen(icon, "click", (e) => {
      this.onRemoveCharacterClick(id);
    });
    this.listeners.push(listenerIconClick);

    character.appendChild(title);
    character.appendChild(icon);

    return character;
  }

  private clearCharactersEventListeners() {
    for (const l of this.listeners) {
      l();
    }
    this.listeners = [];
  }

  private updateCharactersEventListeners() {
    this.clearCharactersEventListeners();

    const characters: HTMLCollection = this.editorElement.nativeElement.querySelectorAll("[data-character-id]");
    for (const character of Array.from(characters)) {
      const dataCharacterId = character.getAttribute("data-character-id");
      if (!dataCharacterId) {
        continue;
      }
      const characterId = Number.parseInt(dataCharacterId);
      if (!characterId) {
        continue;
      }

      const title = character.querySelector(".title");

      const listenerTitleClick = this.renderer.listen(title, "click", (e) => {
        this.onCharacterChangeClick(characterId, e.target);
      });
      this.listeners.push(listenerTitleClick);

      // const listenerTitleMouseEnter = this.renderer.listen(title, "mouseenter", (e) => {
      //   this.onCharacterMouseEnterEvent(characterId, e.target);
      // });
      // this.listeners.push(listenerTitleMouseEnter);

      // const listenerTitleMouseLeave = this.renderer.listen(title, "mouseleave", (e) => {
      //   this.onCharacterMouseLeaveEvent();
      // });
      // this.listeners.push(listenerTitleMouseLeave);

      const icon = character.querySelector(".icon");

      const listenerIconClick = this.renderer.listen(icon, "click", (e) => {
        this.onRemoveCharacterClick(characterId);
      });
      this.listeners.push(listenerIconClick);
    }
  }

  updateCharacters() {
    this.value = this.convertCharactersToString();
    this.setDataToHtml();
  }

  updateCharactersIds() {
    this.addedCharactersIds = this.getCharactersIds();
    this.charactersIdsChanged.emit(this.addedCharactersIds);
  }

  updateNotExistingCharacters() {
    this.notExistingCharactersIds = [];
    for (const id of this.addedCharactersIds) {
      const character = this.characters.find((c) => c.id === id);
      if (character) {
        continue;
      }
      this.notExistingCharactersIds.push(id);
    }
    this.notExistingCharactersIdsChanged.emit(this.notExistingCharactersIds);
  }

  getCharactersIds() {
    const addedCharactersIds: number[] = [];
    const characters: HTMLCollection = this.editorElement.nativeElement.querySelectorAll("[data-character-id]");
    for (const character of Array.from(characters)) {
      character.setAttribute("contenteditable", "false");
      const dataCharacterId = character.getAttribute("data-character-id");
      if (!dataCharacterId) {
        continue;
      }
      const characterId = Number.parseInt(dataCharacterId);
      if (characterId) {
        addedCharactersIds.push(characterId);
      }
    }

    this.cdr.markForCheck();

    return addedCharactersIds;
  }

  removeCharacter(id: number): boolean {
    let isRemoved = false;
    const characters: HTMLCollection = this.editorElement.nativeElement.querySelectorAll("[data-character-id]");
    for (const character of Array.from(characters)) {
      const dataCharacterId = character.getAttribute("data-character-id");
      if (!dataCharacterId) {
        continue;
      }
      const characterId = Number.parseInt(dataCharacterId);
      if (characterId === id) {
        character.remove();
        isRemoved = true;
      }
    }

    this.saveDataFromHtml();

    this.updateCharactersIds();
    this.updateNotExistingCharacters();

    this.cdr.markForCheck();

    return isRemoved;
  }

  protected onRemoveCharacterClick(id: number) {
    this.removeCharacter(id);
  }

  protected onCharacterChangeClick(id: number, element: HTMLElement) {
    this.onShowChangePopup.emit({ id, element });
    this.charactersService.onVisibilityChangeSelectCharacterPopup(true);
  }

  // protected onCharacterMouseEnterEvent(id: number, element: HTMLElement) {
  //   this.onShowChangePopup.emit({ id, element });
  //   this.charactersService.onVisibilityChangeSelectCharacterPopup(true);
  // }

  // protected onCharacterMouseLeaveEvent() {
  //   this.charactersService.onVisibilityChangeSelectCharacterPopup(false);
  // }

  protected onClickHandle(event: Event) {
    this.addFakeCursor();
    this.editorElement.nativeElement.focus();
  }

  protected onKeyPressHandle(event: KeyboardEvent) {
    if (event instanceof KeyboardEvent) {
      if (event.key === "Enter") {
        this.onEnterPress(event);
      }
    }
  }

  protected onKeyDownHandle(event: KeyboardEvent) {}

  protected onDragEnterHandle(_event: Event) {}

  protected onDropHandle(event: Event) {
    event.preventDefault();
  }

  onEnterPress(event: KeyboardEvent) {
    event.preventDefault();

    // const range = this.getRange();

    // if (!range) {
    //   console.error("cannot add br");
    //   return;
    // }

    // this.rangeService.addBrToInput(range, this.editorElement.nativeElement);
  }

  protected onPasteHandle(event: ClipboardEvent) {
    event.preventDefault();

    const text = event.clipboardData?.getData("text/plain") ?? "";

    const selection = document.getSelection();
    if (!selection) {
      console.error("Selection is not available");
      return;
    }

    const range = selection.getRangeAt(0);
    if (!range) {
      console.error("Range is not available");
      return;
    }

    range.deleteContents();

    const lines = text.split("\n");

    for (let i = 0; i < lines.length; i++) {
      const textNode = document.createTextNode(lines[i]);
      range.insertNode(textNode);
      range.selectNodeContents(textNode);
      range.collapse(false);
      range.setStartAfter(textNode);
      range.setEndAfter(textNode);

      if (i < lines.length - 1) {
        const br = document.createElement("br");
        range.insertNode(br);
        range.selectNodeContents(br);
        range.collapse(false);
        range.setStartAfter(br);
        range.setEndAfter(br);
      }
    }

    this.checkOnCharactersInPrompt();

    this.saveDataFromHtml();
  }

  protected onSelectCharacterClick() {
    const characterId = this.notExistingCharactersIds[0];
    const characterElement = this.editorElement.nativeElement.querySelector(`[data-character-id="${characterId}"]`);

    if (!characterId || !characterElement) {
      return;
    }

    this.onCharacterChangeClick(characterId, characterElement);
  }

  protected onCreateCharacterClick() {
    const characterId = this.notExistingCharactersIds[0];

    if (!characterId) {
      return;
    }

    this.onShowCharactersModal.emit(characterId);
  }

  private setDataToHtml() {
    this.convertCharactersFromText(this.value);

    // remove last br
    const el = this.editorElement.nativeElement as Element;
    if (el.childNodes.length > 0) {
      if (el.lastChild?.nodeName.toLowerCase() === "br") {
        el.lastChild?.remove();
        // force save data with new html
        this.saveDataFromHtml();
      }
    }

    this.updateCharactersIds();
    this.updateNotExistingCharacters();
  }

  private saveDataFromHtml(): void {
    this.value = this.convertCharactersToString();
    this.onChange(this.value);

    this.onBlur.emit();
  }

  private convertCharactersFromText(text: string) {
    let newText = text;

    const charactersIds = this.charactersService.getCharactersIdsFromText(newText);
    for (const characterId of charactersIds) {
      const regex = this.charactersService.getCharactersRegex(characterId);
      newText = newText.replace(
        regex,
        `<span data-character-id="${characterId}">@character-id={${characterId}}</span>`,
      );
    }

    this.editorElement.nativeElement.innerHTML = newText;

    const charactersElements: HTMLCollection = this.editorElement.nativeElement.querySelectorAll("[data-character-id]");
    for (const characterElement of Array.from(charactersElements)) {
      const dataCharacterId = characterElement.getAttribute("data-character-id");
      if (dataCharacterId) {
        const characterId = Number.parseInt(dataCharacterId);
        const character = this.characters.find((c) => c.id === characterId);

        const toLowerCase = !!characterElement.previousSibling;

        if (!character) {
          const characterSelectNode = this.createCharacterTag(characterId, undefined, toLowerCase);
          this.editorElement.nativeElement.replaceChild(characterSelectNode, characterElement);
          continue;
        }
        const characterNode = this.createCharacterTag(character.id, character.name, toLowerCase);
        this.editorElement.nativeElement.replaceChild(characterNode, characterElement);
      }
    }
  }

  private convertCharactersToString() {
    let text = this.editorElement.nativeElement.innerHTML;

    const characters: HTMLCollection = this.editorElement.nativeElement.querySelectorAll("[data-character-id]");

    for (const character of Array.from(characters)) {
      const dataCharacterId = character.getAttribute("data-character-id");
      let characterId = -1;
      if (dataCharacterId) {
        characterId = Number.parseInt(dataCharacterId);
      }

      text = text.replace(character.outerHTML, `@character-id={${characterId}}`);
    }

    const spans: HTMLCollection = this.editorElement.nativeElement.querySelectorAll("span");

    for (const span of Array.from(spans)) {
      text = text.replace(span.outerHTML, "");
    }

    text = text.replaceAll(`<span data-fake-cursor-id="fake-cursor" contenteditable="false"></span>`, "");
    text = text.replaceAll("&nbsp;", " ");
    text = text.replaceAll("<div></div>", "");
    text = text.replaceAll("<br>", " ");

    return text;
  }

  private checkOnCharactersInPrompt() {
    const text = this.editorElement.nativeElement.innerHTML;
    const regex = /@character-id=\{\d+\}/gm;
    if (regex.test(text)) {
      this.updateCharacters();
      this.rangeService.setCaretToEnd(this.editorElement.nativeElement);
    }
  }

  protected hasErrors(): boolean {
    return !!this.errors?.hasNotExistingCharactersError || !!this.errors?.hasCharactersLimitError;
  }

  protected isCharactersLimitReached() {
    return this.charactersService.isCharactersLimitReached(this.addedCharactersIds, this.charactersInPromptLimit);
  }

  @HostListener("document:keydown", ["$event"])
  onKeydown(event: KeyboardEvent): void {
    if (event.key === "ArrowUp") {
      if (this.needBlockArrowsUpAndDown) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
    if (event.key === "ArrowDown") {
      if (this.needBlockArrowsUpAndDown) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }
}
