<div class="button-set space-between">
  <m-ng-icon [size]="24" (click)="onAlign('left')" src="/assets/icons/objects-align-left-01.svg"></m-ng-icon>
  <m-ng-icon [size]="24" (click)="onAlign('center')" src="/assets/icons/objects-align-center-01.svg"></m-ng-icon>
  <m-ng-icon [size]="24" (click)="onAlign('right')" src="/assets/icons/objects-align-right-01.svg"></m-ng-icon>
  <m-ng-icon [size]="24" (click)="onAlign('top')" src="/assets/icons/objects-align-top-01.svg"></m-ng-icon>
  <m-ng-icon [size]="24" (click)="onAlign('middle')" src="/assets/icons/objects-align-middle-01.svg"></m-ng-icon>
  <m-ng-icon [size]="24" (click)="onAlign('bottom')" src="/assets/icons/objects-align-bottom-01.svg"></m-ng-icon>
  <m-ng-icon
    [size]="24"
    (click)="onAlign('center-middle')"
    src="/assets/icons/objects-align-center-middle-01.svg"
  ></m-ng-icon>
</div>
