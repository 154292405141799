import { ChangeDetectorRef, Component } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NotificationsPopUpService } from "@metranpage/core";
import { Subject } from "rxjs";
import { User } from "../../../companyadmin/models/user";
import { CompaniesAdminService } from "../../services/companies.service";

@Component({
  selector: "m-superadmin-company-settings-admins-page",
  templateUrl: "./company-settings-admins.page.html",
  styleUrls: ["./company-settings-admins.page.scss"],
})
export class SuperadminCompanyAdminsSettingsPage {
  private destroyed$ = new Subject<void>();
  protected admins: User[] = [];
  protected isAddAdminModalVisible = false;

  protected addAdminForm = new FormGroup({
    email: new FormControl("", { nonNullable: true, validators: [] }),
  });

  constructor(
    private readonly companiesService: CompaniesAdminService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly route: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngAfterViewInit() {
    if ("companyId" in this.route.snapshot.params) {
      const result = await this.companiesService.getCompanyAdmins(this.route.snapshot.params.companyId);
      if (result.status === "success") {
        this.admins = result.data!;
      } else {
        this.admins = [];
        this.notificationService.error("Cannot load admins, TODO notification");
        console.error("Cannot load admins, TODO notification");
      }
      this.cdr.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onAddAdmin() {
    this.isAddAdminModalVisible = true;
  }

  closeAddAdmin() {
    this.isAddAdminModalVisible = false;
  }

  async addAdmin() {
    const companyId = Number.parseInt(this.route.snapshot.params.companyId);
    await this.companiesService.addCompanyAdmin(companyId, this.addAdminForm.value.email!);
    await this.ngAfterViewInit();

    this.closeAddAdmin();
  }
  trackByAdminId(index: number, admin: User) {
    return admin.id;
  }
}
