import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AVAILABLE_LANGUAGES, LangSpec } from "@metranpage/i18n";
import { FormatCreateDataDto } from "../../models/format";
import { FormatLocCreateData } from "../../models/format-loc";
import { AdminFormatService } from "../../services/format/format.service";

@Component({
  selector: "m-admin-add-format",
  templateUrl: "./add-format.view.html",
  styleUrls: ["./add-format.view.scss"],
})
export class AddFormatView {
  @Output()
  onFormatAdded = new EventEmitter<FormatCreateDataDto>();
  @Output()
  onCancelAddition = new EventEmitter<void>();

  protected form!: FormGroup;

  constructor(
    @Inject(AVAILABLE_LANGUAGES) readonly availableLanguages: LangSpec[],
    private readonly adminFormatService: AdminFormatService,
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.adminFormatService.createForm();
  }

  createFormat() {
    const formData = this.form.getRawValue();
    const localizationData: FormatLocCreateData[] = [];

    for (const lang of Object.keys(formData.localization)) {
      const valueLoc = formData.localization[lang];
      localizationData.push({
        title: valueLoc.title,
        lang,
      });
    }
    formData.localization = localizationData;

    this.onFormatAdded.emit(formData);
  }

  onCancel() {
    this.onCancelAddition.emit();
  }

  protected getLanguages() {
    return this.availableLanguages.map((v) => v.value);
  }

  protected getFormatTypeOptions() {
    return this.adminFormatService.getFormatTypeOptions();
  }
}
