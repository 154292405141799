<div class="wrapper">
  <div class="section">
    <div class="rowset">
      <div class="caption" i18n="@@cover-editor.object.settings.alignment">cover-editor.object.settings.alignment</div>
      <m-cover-object-alignment (align)="onAlign($event)"></m-cover-object-alignment>
    </div>
  </div>

  <div *ngIf="!currentObject.isBackgroundRemoved" class="section">
    <m-cover-sidebar-section
      title="cover-editor.image.remove-background"
      i18n-title="@@cover-editor.image.remove-background"
      [hasTooltip]="true"
    >
      <ng-container tooltip>
        <div class="tooltip-group">
          <span i18n="@@cover-editor.image.remove-background.info">cover-editor.image.remove-background.info</span>
        </div>
      </ng-container>

      <m-button
        button-style="secondary"
        [is-disabled]="isRemoveBackgroundDisable()"
        size="full-width"
        (onClick)="onRemoveBackground()"
      >
        <span
          [class.not-enought-tokens]="!isEnoughtTokens('nobg')"
          i18n="@@image-generation.generation.nobg.button-text"
        >
          image-generation.generation.nobg.button-text
        </span>
        <div class="price" [class.not-enought-tokens]="!isEnoughtTokens('nobg')">
          <m-ng-icon class="token-icon" src="/assets/img/app-bar-menu/token-simple-{theme}.svg" [size]="20" />
          <span *ngIf="prices">{{ getPrice('nobg') }}</span>
        </div>
      </m-button>
    </m-cover-sidebar-section>
  </div>
</div>
