export default {
  saveAsFile(data: any, fileName: string, contentType = "application/octet-stream") {
    const blob = new Blob([data], {
      type: contentType,
    });

    const objectUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = objectUrl;
    a.target = "_blank";
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(objectUrl);
  },

  base64ToBlob(input: string, contentType = "application/octet-stream"): Blob {
    const binary = atob(input.split(",")[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: contentType });
  },

  blobToFile(blob: Blob, fileName: string): File {
    const file = new File([blob], fileName, { lastModified: new Date().getUTCDate() });
    return file;
  },
};
