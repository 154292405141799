<div class="book-view-pages">
  <m-preview-frame class="page-left" [page-size]="getPageSize()" (onPagePreviewChange)="onPagePreviewChange($event)">
    <div class="page left">
      <div [ngClass]="getPageCssClassList('left')">
        <ng-container *ngTemplateOutlet="marginsPage; context: { page: 'left' }"></ng-container>
        <ng-container *ngTemplateOutlet="marginsPreview; context: { page: 'left' }"></ng-container>
      </div>
    </div>
  </m-preview-frame>

  <m-preview-frame class="page-right" [page-size]="getPageSize()">
    <div class="page right">
      <div [ngClass]="getPageCssClassList('right')">
        <ng-container *ngTemplateOutlet="marginsPage; context: { page: 'right' }"></ng-container>
        <ng-container *ngTemplateOutlet="marginsPreview; context: { page: 'right' }"></ng-container>
      </div>
    </div>
  </m-preview-frame>
</div>

<ng-template #marginsPage let-page="page">
  <div
    [ngClass]="getMarginCssClassList(page, 'marginTop')"
    (mousedown)="onMouseDown($event, page, 'marginTop')"
    (mouseenter)="onMarginMouseEnter('marginTop')"
    (mouseleave)="onMarginMouseLeave()"
  >
    <div class="indicator-horizontal"></div>
  </div>
  <div
    [ngClass]="getMarginCssClassList(page, 'marginBottom')"
    (mousedown)="onMouseDown($event, page, 'marginBottom')"
    (mouseenter)="onMarginMouseEnter('marginBottom')"
    (mouseleave)="onMarginMouseLeave()"
  >
    <div class="indicator-horizontal"></div>
  </div>
  <div
    [ngClass]="getMarginCssClassList(page, 'marginOuter')"
    (mousedown)="onMouseDown($event, page, 'marginOuter')"
    (mouseenter)="onMarginMouseEnter('marginOuter')"
    (mouseleave)="onMarginMouseLeave()"
  >
    <div class="indicator-vertical"></div>
  </div>
  <div *ngIf="isWidePaddingEnabled()" [ngClass]="getMarginCssClassList(page, 'gutter')">
    <div
      class="gutter-active-zone"
      (mousedown)="onMouseDown($event, page, 'widePadding')"
      (mouseenter)="onMarginMouseEnter('widePadding')"
      (mouseleave)="onMarginMouseLeave()"
    ></div>
    <div class="position-arrows">
      <svg-icon src="/assets/img/margins-position-arrows.svg" (click)="displaceMargins(page)"></svg-icon>
    </div>
    <div
      class="gutter-active-zone"
      (mousedown)="onMouseDown($event, page, 'widePadding')"
      (mouseenter)="onMarginMouseEnter('widePadding')"
      (mouseleave)="onMarginMouseLeave()"
    ></div>
  </div>
  <div
    [ngClass]="getMarginCssClassList(page, 'marginInner')"
    (mousedown)="onMouseDown($event, page, 'marginInner')"
    (mouseenter)="onMarginMouseEnter('marginInner')"
    (mouseleave)="onMarginMouseLeave()"
  >
    <div class="indicator-vertical"></div>
  </div>
</ng-template>

<ng-template #marginsPreview let-page="page">
  <div class="text-padding" [ngStyle]="getStyleTextPadding()">
    <!-- <div #textPreview class="text-preview">
      <m-preview-text
        [font-size]="fontSizeInPixeles"
        [columns-count]="2"
        [gutter]="8"
      ></m-preview-text>
    </div> -->

    <div class="text-preview" [ngStyle]="getStyleTextPreview()"></div>
    <div *ngIf="isColumnsEnable()" class="text-preview" [ngStyle]="getStyleTextPreview()"></div>
  </div>
  <div *ngIf="isWidePaddingEnabled()" [ngClass]="getMarginCssClassList(page, 'widePadding')">
    <div class="preview-image"></div>
    <div class="preview-caption"></div>
  </div>
</ng-template>
