<div class="input-container" [ngClass]="getCssClassList()">
  <textarea
    [ngClass]="{ resizable: resizable, 'auto-height': autoHeight }"
    [placeholder]="placeholder"
    [name]="name"
    [autocomplete]="getAutocomplete()"
    [required]="isRequired"
    [tabindex]="tabIndex"
    [rows]="rows"
    [maxLength]="maxlength"
    (focus)="onInputFocus()"
    (blur)="onInputBlur()"
    (input)="onInputChange($event)"
    #input
  ></textarea>

  @if (isCharactersCountVisible) {
    <div class="characters-count">{{ charactersCountText }}</div>
  }
</div>
