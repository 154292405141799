import { Routes } from "@metranpage/core";
import { LoginGooglePage } from "./pages/login-google/login-google.page";
import { LoginOneTimeTokenPage } from "./pages/login-one-time-token/login-one-time-token.page";
import { LoginVkPage } from "./pages/login-vk/login-vk.page";
import { LoginPage } from "./pages/login/login.page";
import { PasswordRestoreNewPasswordPage } from "./pages/password-restore-new-password/password-restore-new-password.page";
import { PasswordRestorePage } from "./pages/password-restore/password-restore.page";
import { RegistrationPage } from "./pages/registartion/registration.page";

export const authRoutes: Routes = [
  {
    path: "login",
    component: LoginPage,
    data: {
      showTopBar: false,
      showMobileWarning: false,
    },
  },
  {
    path: "registration",
    component: RegistrationPage,
    data: {
      showTopBar: false,
      showMobileWarning: false,
    },
  },
  {
    path: "login/vk",
    component: LoginVkPage,
    data: {
      showTopBar: false,
      showMobileWarning: false,
    },
  },
  {
    path: "login/google",
    component: LoginGooglePage,
    data: {
      showTopBar: false,
      showMobileWarning: false,
    },
  },
  {
    path: "login/one-time",
    component: LoginOneTimeTokenPage,
    data: {
      showTopBar: false,
      showMobileWarning: false,
    },
  },
  {
    path: "restore-password",
    component: PasswordRestorePage,
    data: {
      showTopBar: false,
      showMobileWarning: false,
    },
  },
  {
    path: "reset-password/:token",
    component: PasswordRestoreNewPasswordPage,
    data: {
      showTopBar: false,
      showMobileWarning: false,
    },
  },
];
