<form *ngIf="form" [formGroup]="form">
  <div formGroupName="styles">
    <div [formGroupName]="style.styleKey" class="container">
      <div class="row space-between header">
        <span i18n="@@books.markup.style-details.header">books.markup.style-details.header</span>
        <span>{{style.visibleTitle}}</span>
      </div>

      <div #preview class="preview" i18n="@@books.markup.style-details.preview-text">
        books.markup.style-details.preview-text
      </div>

      <div class="settings-container" [class.locked]="!isSettingsAvailable">
        <m-books-markup-paragraph-text-settings *ngIf="isTextSettingsAvailable(style)" [form]="form" [style]="style" />

        <div class="divider"></div>

        <div class="row" *ngIf="style.availableControls.includes('emphasis')">
          <span class="style-settings-title" i18n="@@books.markup.style-details.emphasis-title">
            books.markup.style-details.emphasis-title
          </span>

          <m-markup-style-details-emphasis-buttons-row formControlName="emphasis" />
        </div>

        <div class="row" *ngIf="style.availableControls.includes('align')">
          <span class="style-settings-title" i18n="@@books.markup.style-details.align-title">
            books.markup.style-details.align-title
          </span>

          <m-markup-style-details-align-buttons-row formControlName="align" />
        </div>

        <div class="row" *ngIf="style.availableControls.includes('align-maintext')">
          <span class="style-settings-title" i18n="@@books.markup.style-details.align-title">
            books.markup.style-details.align-title
          </span>

          <m-markup-style-details-align-maintext-buttons-row formControlName="align" />
        </div>

        <div class="row" *ngIf="style.availableControls.includes('case')">
          <span class="style-settings-title" i18n="@@books.markup.style-details.case-title">
            books.markup.style-details.case-title
          </span>

          <m-markup-style-details-case-buttons-row formControlName="case" />
        </div>

        <div class="divider"></div>

        <m-books-markup-indent-paragraph-settings
          *ngIf="style.availableControls.includes('indent-paragraph')"
          [form]="form"
          [style]="style"
        ></m-books-markup-indent-paragraph-settings>

        <div class="divider"></div>

        <div class="column" *ngIf="style.availableControls.includes('indent-header')">
          <span class="style-settings-title inline-text" i18n="@@books.markup.style-details.indent-header-title">
            books.markup.style-details.indent-header-title
          </span>

          <m-markup-style-details-indent-header-buttons-row formControlName="indentHeader" />
        </div>

        <div class="divider"></div>

        <div class="row space-between" *ngIf="style.availableControls.includes('startsOn')">
          <span class="style-settings-title" i18n="@@books.markup.style-details.start-on-title">
            books.markup.style-details.start-on-title
          </span>

          <m-markup-style-details-start-on-buttons-row formControlName="startsOn" />
        </div>

        <div class="divider"></div>

        <div class="row space-between" *ngIf="style.availableControls.includes('oneOnPage')">
          <span class="style-settings-title inline-text" i18n="@@books.markup.style-details.one-on-page-title">
            books.markup.style-details.one-on-page-title
          </span>

          <m-switch formControlName="oneOnPage" />
        </div>

        <div class="divider"></div>

        <div *ngIf="style.availableControls.includes('drop-cap')">
          <div class="row space-between">
            <span class="style-settings-title" i18n="@@books.markup.style-details.drop-cap-title">
              books.markup.style-details.drop-cap-title
            </span>

            <m-switch formControlName="dropCap" />
          </div>

          <div class="row space-between style-settings-description">
            <m-icon src="markup-drop-cap-{theme}.svg" size="l"></m-icon>
            <m-localized-text class="text" [text]="getDropCapDescription()"></m-localized-text>
          </div>
        </div>
      </div>
      <div *ngIf="style.availableControls.includes('isShowedInToc')">
        <div class="row space-between">
          <span class="style-settings-title inline-text" i18n="@@books.markup.style-details.include-in-content">
            books.markup.style-details.include-in-content
          </span>

          <m-switch formControlName="isShowedInToc" />
        </div>
      </div>
    </div>

    <div *ngIf="!isSettingsAvailable" class="buttons">
      <m-button
        button-style="secondary"
        size="full-width"
        (onClick)="onCancelClick()"
        i18n="@@books.markup.style-details.close"
      >
        books.markup.style-details.close
      </m-button>
    </div>
    <div *ngIf="isSettingsAvailable" class="buttons">
      <m-button
        button-style="secondary"
        size="full-width"
        (onClick)="onCancelClick()"
        i18n="@@books.markup.style-details.cancel"
      >
        books.markup.style-details.cancel
      </m-button>

      <m-button
        button-style="primary"
        size="full-width"
        [is-disabled]="!isStyleSettingsValid()"
        (onClick)="onApplyClick()"
        i18n="@@books.markup.style-details.apply"
      >
        books.markup.style-details.apply
      </m-button>
    </div>
  </div>
</form>
