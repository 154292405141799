<m-ng-island [paddingSize]="0">
  <div class="container">
    <div class="header">
      <div class="header-group">
        <div i18n="@@user.profile.notifications.title">user.profile.notifications.title</div>

        <m-filter-switch
          [options]="filterOptions"
          [selectedOptionId]="selectedFilterOptionId"
          (onSelect)="onSelectFilterOption($event)"
        />
      </div>
    </div>

    <div class="divider-hor"></div>

    <div
      class="notifications-wrapper"
      infiniteScroll
      [scrollWindow]="false"
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScrollEvent()"
    >
      <div class="notifications-card" *ngFor="let n of filteredNotifications; let isLast = last;">
        <ng-container [ngSwitch]="getCardViewType(n)">
          <m-account-notification-registration-card-view
            *ngSwitchCase="'registration'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-image-moderation-card-view
            *ngSwitchCase="'image-moderation'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-cover-moderation-card-view
            *ngSwitchCase="'cover-moderation'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-promocode-activate-card-view
            *ngSwitchCase="'promocode-activate'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-promocode-expire-card-view
            *ngSwitchCase="'promocode-expire'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-email-verification-card-view
            *ngSwitchCase="'email-verification'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-trial-period-activate-card-view
            *ngSwitchCase="'trial-period-activate'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-trial-period-expire-card-view
            *ngSwitchCase="'trial-period-ending'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-book-layout-card-view
            *ngSwitchCase="'book-layout'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-book-without-cover-card-view
            *ngSwitchCase="'book-without-cover'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-subscription-activate-card-view
            *ngSwitchCase="'subscription-activate'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-subscription-expire-card-view
            *ngSwitchCase="'subscription-ending'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-low-balance-card-view
            *ngSwitchCase="'low-balance'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-connect-telegram-card-view
            *ngSwitchCase="'connect-telegram'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-add-tokens-card-view
            *ngSwitchCase="'add-tokens'"
            [notification]="n"
            [isLast]="isLast"
          />
          <m-account-notification-like-card-view *ngSwitchCase="'like'" [notification]="n" [isLast]="isLast" />
          <m-account-notification-webinar-card-view *ngSwitchCase="'webinar'" [notification]="n" [isLast]="isLast" />
        </ng-container>
      </div>
    </div>
  </div>
</m-ng-island>
