import { Injectable } from "@angular/core";
import { UserVerificationOverlayServiceInterface } from "@metranpage/user-interfaces";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class UserVerificationOverlayService implements UserVerificationOverlayServiceInterface {
  readonly modalDisplaying$ = new BehaviorSubject<boolean>(false);

  open(): void {
    this.modalDisplaying$.next(true);
  }
  close(): void {
    this.modalDisplaying$.next(false);
  }
  asObservable(): Observable<boolean> {
    return this.modalDisplaying$.asObservable();
  }
}
