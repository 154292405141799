import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "m-confirmation-modal",
  templateUrl: "./confimation-modal.view.html",
  styleUrls: ["./confimation-modal.view.scss"],
})
export class ConfirmationModalView {
  @Input()
  text = "";
  @Input()
  confirmText = "";
  @Input()
  cancelText = "";

  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;

  @Output()
  onConfirm = new EventEmitter();
  @Output()
  onClose = new EventEmitter();

  protected onConfirmClick() {
    this.onConfirm.emit();
  }

  protected onCloseClick() {
    this.onClose.emit();
  }

  protected onBackDropClick() {
    if (!this.closeOnBackDropClick) {
      return;
    }
    this.onCloseClick();
  }
}
