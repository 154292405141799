<div
  class="container"
  [ngStyle]="{ 'margin-left': calculateMargin(item.level!) }"
>
  <span class="marker">{{ getFormattedMarker() }}</span>

  <m-text-editor
  class="text-editor"
  #textEditor
  [(ngModel)]="text"
  [startingNoteIndex]="startingNoteIndex"
  (onFocus)="onEditorFocus()"
  (onBlur)="onEditorBlur()"
  (footnoteAdded)="onFootnoteAdded($event)"
  (footnoteChanged)="onFootnoteChanged($event)"
  (onSplit)="onSplit.emit($event)"
  (onMergeWithPrev)="onMergeWithPrev.emit($event)"
  (blockMovement)="onBlockMovement($event)"
></m-text-editor>

</div>
