import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FileDropComponent, LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import * as _ from "lodash-es";
import { NgxFileDropEntry } from "ngx-file-drop";
import { takeUntil } from "rxjs";
import { CompanyFont, FontUpdateDataWithFile } from "../../models/font";
import { AdminFontsService } from "../../services/fonts/fonts.service";
import { AdminFontsStore } from "../../services/fonts/fonts.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-font-page",
  templateUrl: "./font.page.html",
  styleUrls: ["./font.page.scss"],
})
export class AdminCompanyFontPage extends AdminBasePage implements OnInit, AfterViewInit {
  font: CompanyFont | undefined;

  @ViewChild("filedrop", { static: false })
  protected fileDropComponent!: FileDropComponent;

  protected fontFile?: File;
  protected originalUploadFilename?: string;

  protected form!: FormGroup;

  protected initialData?: FontUpdateDataWithFile;

  constructor(
    private readonly adminFontsService: AdminFontsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly cdr: ChangeDetectorRef,
    adminFontsStore: AdminFontsStore,
  ) {
    super();

    this.createForm();

    this.addSub(
      adminFontsStore
        .getActiveFontObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(async (font) => {
          this.font = font;
          if (font) {
            this.originalUploadFilename = font.originalUploadFilename;

            const formData = _.omit(font, ["id"]);
            this.form.patchValue(formData);

            this.initialData = {
              ...formData,
              fontFile: undefined,
            };
          }
        }),
    );
  }

  ngOnInit(): void {}

  async ngAfterViewInit() {
    if ("fontId" in this.route.snapshot.params) {
      this.adminFontsService.loadFont(this.route.snapshot.params["fontId"]);
    }
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) => {
        this.fontFile = file;
        this.originalUploadFilename = file.name;
      });
    }
  }

  async deleteFontFile() {
    this.fontFile = undefined;
    this.originalUploadFilename = undefined;
  }

  openFileSelector() {
    this.fileDropComponent.openFileSelector();
  }

  async onSave() {
    if (!this.font || !this.originalUploadFilename) {
      return;
    }

    const data = this.form.getRawValue();
    let dataForUpdate: FontUpdateDataWithFile = {};
    if (this.initialData) {
      dataForUpdate = this.differenceBetweenObjects(data, this.initialData);
    }
    dataForUpdate.fontFile = this.fontFile;

    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminFontsService.updateFont(this.font.id, dataForUpdate);
    this.loadingService.stopLoading();
    if (result === "success") {
      this.router.navigate(["admin", "fonts"]);
    } else {
      this.notificationService.error($localize`:@@admin.fonts.font.error.cant-update-font:`);
    }
  }

  onCancel() {
    this.router.navigate(["admin", "fonts"]);
  }

  createForm() {
    this.form = this.adminFontsService.createFontForm();
  }

  differenceBetweenObjects(object: any, base: any) {
    function changes(object: any, base: any) {
      return _.transform(object, (result: any, value: any, key: any) => {
        if (!_.isEqual(value, base[key])) {
          result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
        }
      });
    }
    return changes(object, base);
  }
}
