import { CommonModule, NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";
import { IconComponent } from "@metranpage/design-system";

@Component({
  selector: "m-premium-mark",
  template: `
    <m-ng-icon src="/assets/icons/crown.svg" [size]="12" />
    <span *ngIf="!isCompact" class="mark" i18n="@@premium-mark">premium-mark</span>
  `,
  styleUrls: ["./premium-mark.component.scss"],
  standalone: true,
  imports: [CommonModule, NgClass, IconComponent],
})
export class PremiumMarkComponent {
  @Input()
  isCompact = true;
}
