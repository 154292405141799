import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import {
  CompanyCloudPaymentsSettings,
  CompanyPaymentsSettings,
  CompanyS3Settings,
  CompanyStripeSettings,
} from "../../models/company";
import { CompanyUnisenderCreds } from "../../models/unisender-settings";

@Injectable({
  providedIn: "root",
})
export class AdminCompanyCredsSettingsApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getS3Settings() {
    return this.get<CompanyS3Settings>("admin/creds/s3");
  }

  async updateS3Settings(data: CompanyS3Settings) {
    return this.post<CompanyS3Settings>("admin/creds/s3", data);
  }

  async deleteS3Settings() {
    return this.delete<void>("admin/creds/s3");
  }

  async getPaymentsSettings() {
    return this.get<CompanyPaymentsSettings>("admin/creds/payments");
  }

  async updatePaymentsSettings(data: CompanyPaymentsSettings) {
    return this.post<CompanyPaymentsSettings>("admin/creds/payments", data);
  }

  // async getCloudPaymentsSettings() {
  //   return this.get<CompanyCloudPaymentsSettings>("admin/creds/cloudpayments");
  // }

  // async updateCloudPaymentsSettings(data: CompanyCloudPaymentsSettings) {
  //   return this.post<CompanyCloudPaymentsSettings>("admin/creds/cloudpayments", data);
  // }

  async deleteCloudPaymentsSettings() {
    return this.delete<void>("admin/creds/cloudpayments");
  }

  // async getStripeSettings() {
  //   return this.get<CompanyStripeSettings>("admin/creds/stripe");
  // }

  // async updateStripeSettings(data: CompanyStripeSettings) {
  //   return this.post<CompanyStripeSettings>("admin/creds/stripe", data);
  // }

  async deleteStripeSettings() {
    return this.delete<void>("admin/creds/stripe");
  }

  async getUnisenderCreds() {
    return this.get<CompanyUnisenderCreds>("admin/creds/unisender");
  }

  async updateUnisenderCreds(data: CompanyUnisenderCreds) {
    return this.post<CompanyUnisenderCreds>("admin/creds/unisender", data);
  }

  async deleteUnisenderCreds() {
    return this.delete<void>("admin/creds/unisender");
  }
}
