import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { SNCoverTitle } from '../../models/social-network-covers';
@Pipe({
  name: 'titleLocale',
  standalone: true,
})
export class TitleLocale implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localeId: string) { }


  transform(titlesWithLanguage: SNCoverTitle[]): string {
    return titlesWithLanguage.find(title => title.lang === this.localeId)?.value || ''
  }
}
