import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SelectValue } from "@metranpage/components";
import { GeneralResultStatus } from "@metranpage/core-data";
import * as _ from "lodash-es";
import { RewardOneTime, RewardOneTimeCreateData } from "../../../models/reward-onetime";
import { AdminRewardsOneTimeApi, RewardsOneTimeFilter } from "./rewards-onetime.api";
import { AdminRewardsOneTimeStore } from "./rewards-onetime.store";

const reNonEmptyString = /^(?!\s*$).+/;

@Injectable({
  providedIn: "root",
})
export class AdminRewardsOneTimeService {
  protected rewardsOneTimeTypeOptions: SelectValue[] = [
    { id: "", value: "" },
    {
      id: "subscribe-to-telegram-channel",
      value: $localize`:@@admin.rewards.onetime.task.subscribe-to-telegram-channel:`,
    },
  ];

  constructor(
    private readonly adminRewardsOneTimeApi: AdminRewardsOneTimeApi,
    private readonly adminRewardsOneTimeStore: AdminRewardsOneTimeStore,
  ) {}

  async loadRewardOneTime(rewardOneTimeId: number) {
    const rewardOneTimeData = await this.adminRewardsOneTimeApi.getRewardOneTime(rewardOneTimeId);
    this.adminRewardsOneTimeStore.setActiveRewardOneTime(rewardOneTimeData.rewardOneTime);
  }

  async loadRewardsOneTime(): Promise<RewardOneTime[]> {
    return this.adminRewardsOneTimeApi.getRewardsOneTime();
  }

  async loadRewardsOneTimePaginated(page: number, filters: RewardsOneTimeFilter = {}) {
    const data = await this.adminRewardsOneTimeApi.getRewardsOneTimePaginated(page, filters);
    this.adminRewardsOneTimeStore.setRewardsOneTime(data.items);
    this.adminRewardsOneTimeStore.setRewardsOneTimePageCount(data.pageCount);
  }

  async updateRewardOneTime(rewardOneTimeId: number, data: RewardOneTimeCreateData): Promise<GeneralResultStatus> {
    try {
      await this.adminRewardsOneTimeApi.updateRewardOneTime(rewardOneTimeId, data);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async createRewardOneTime(data: RewardOneTimeCreateData): Promise<{ status: GeneralResultStatus; id?: number }> {
    try {
      const newRewardOneTime = await this.adminRewardsOneTimeApi.createRewardOneTime(data);
      return {
        status: "success",
        id: newRewardOneTime.id,
      };
    } catch (error: any) {
      return {
        status: "error",
        id: undefined,
      };
    }
  }

  async deleteRewardOneTime(rewardOneTimeId: number): Promise<{ status: GeneralResultStatus }> {
    try {
      const result = await this.adminRewardsOneTimeApi.deleteRewardOneTime(rewardOneTimeId);
      return {
        status: "success",
      };
    } catch (error: any) {
      return {
        status: "error",
      };
    }
  }

  createRewardOneTimeForm() {
    return new FormGroup({
      type: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(reNonEmptyString)],
      }),
      limit: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
      rewardCreditsCount: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
      companyId: new FormControl(1, { nonNullable: true, validators: [Validators.required] }),
    });
  }

  getAllRewardsOneTimeTypeSelectOptions() {
    return this.rewardsOneTimeTypeOptions;
  }

  getAvailableRewardsDailyTypeSelectOptions() {
    const options: SelectValue[] = [];

    const rewardsDaily = this.adminRewardsOneTimeStore.getRewardsOneTime();
    for (const o of this.getAllRewardsOneTimeTypeSelectOptions()) {
      const existingOption = rewardsDaily.find((r) => r.type === o.id);
      if (existingOption) {
        continue;
      }
      options.push(o);
    }

    return options;
  }
}
