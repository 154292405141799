<div class="backdrop" (click)="onCloseClick()"></div>

<div class="container">
  <div class="decoration" [style.background-image]="'url(' + backgroundImage + ')'">
    <div class="button-close" (click)="onCloseClick()">
      <m-icon src="cross.svg" size="xs" color="--color-font-main"></m-icon>
    </div>
  </div>

  <div class="context">
    <span class="title">{{title}}</span>
    <span class="text">{{text}}</span>

    <m-button class="button" button-style="primary" (onClick)="onActionClick()">{{ actionText }}</m-button>
  </div>
</div>
