import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

export type SpreadData = {
  leftPage: string;
  leftPageNumber: number;
  rightPage: string;
  rightPageNumber: number;
};

@UntilDestroy()
@Component({
  selector: 'm-preview-spreads',
  templateUrl: './preview-spreads.component.html',
  styleUrls: ['./preview-spreads.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewSpreadsComponent {
  urls: string[] = [];
  urlsPairs: SpreadData[] = [];
  selectedLeftPage = 0;

  @Input('selected-left-page')
  set setSelectedLeftPage(value: number) {
    this.selectedLeftPage = value;

    const page = this.elementRef.nativeElement.querySelector(`#page-${value}`);
    page?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }

  @Input()
  set imageDataURLs(values: string[] | null) {
    if (values?.length) {
      this.urls = [''].concat(values.map((url) => `${url}?${new Date().getTime()}`));
      this.urlsPairs = this.separateToPairs(this.urls);
    } else {
      this.urls = [];
      this.urlsPairs = [];
    }
  }

  @Output()
  onSelectLeftPage = new EventEmitter<number>();

  constructor(private readonly elementRef: ElementRef) {}

  private separateToPairs(array: string[]): SpreadData[] {
    const separateSize = 2;
    const separatedArray = [];
    let pageNumber = 0;
    for (let i = 0; i < Math.ceil(array.length / separateSize); i++) {
      const leftPageImg = array[i * separateSize] ?? '';
      const leftPageNumber = pageNumber;
      const rightPageImg = array[i * separateSize + (separateSize - 1)] ?? '';
      const rightPageNumber = pageNumber + 1;
      separatedArray[i] = {
        leftPage: leftPageImg,
        leftPageNumber: leftPageImg ? leftPageNumber : 0,
        rightPage: rightPageImg,
        rightPageNumber: rightPageImg ? rightPageNumber : 0,
      };
      pageNumber = pageNumber + separateSize;
    }
    return separatedArray;
  }

  protected onClick(leftPageNumber: number) {
    this.selectedLeftPage = leftPageNumber;
    this.emitSelectedLeftPage();
  }

  protected getCssClassList(pair: SpreadData): string[] {
    const result: string[] = [];

    result.push('spread');
    if (this.selectedLeftPage === pair.leftPageNumber) {
      if (pair.leftPageNumber && pair.rightPageNumber) {
        result.push('selected-both-page');
      } else if (pair.rightPageNumber) {
        result.push('selected-right-page');
      } else {
        result.push('selected-left-page');
      }
    }

    return result;
  }

  protected getPageNumber(pair: SpreadData) {
    let pageNumber = '';

    if (pair.leftPageNumber) {
      pageNumber += pair.leftPageNumber;

      if (pair.rightPageNumber) {
        pageNumber += ' - ';
      }
    }
    if (pair.rightPageNumber) {
      pageNumber += pair.rightPageNumber;
    }

    return pageNumber;
  }

  protected emitSelectedLeftPage() {
    this.onSelectLeftPage.emit(this.selectedLeftPage);
  }
}
