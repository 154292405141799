import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { MarkupService } from "../../services/markup.service";

@Component({
  selector: "m-sidebar-tabs",
  templateUrl: "./sidebar-tabs.view.html",
  styleUrls: ["./sidebar-tabs.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarTabs {
  @Input()
  tab1Icon!: string;
  @Input()
  tab1Title!: string;

  @Input()
  tab2Icon!: string;
  @Input()
  tab2Title!: string;

  @Input()
  hideTabs = false;

  protected selectedTab = 1;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly markupService: MarkupService,
  ) {}

  goToTab(tabIndex: number) {
    this.selectedTab = tabIndex;

    this.markupService.selectSidebarTab(this.selectedTab);

    this.cdr.markForCheck();
  }
}
