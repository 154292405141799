import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { CompanyImageGenerationPriceSettings } from "../../models/company";

@Injectable({
  providedIn: "root",
})
export class AdminCompanyImageGenerationPriceSettingsApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getImageGenerationPriceSettings() {
    return this.get<CompanyImageGenerationPriceSettings>("admin/company-image-generation-price-settings");
  }

  async updateImageGenerationPriceSettings(data: CompanyImageGenerationPriceSettings) {
    return this.post<CompanyImageGenerationPriceSettings>("admin/company-image-generation-price-settings", data);
  }
}
