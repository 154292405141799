import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { CompanyApiSettings } from "../../models/api-settings";

export type State = {
  apiSettings: CompanyApiSettings | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyApiSettingsStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      apiSettings: undefined,
    };
  }

  getApiSettingsObservable() {
    return this.getFieldObservable("apiSettings");
  }

  setApiSettings(settings: CompanyApiSettings) {
    this.update((state) => ({ ...state, apiSettings: settings }));
  }
}
