import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as _ from "lodash-es";
import { Subscription } from "rxjs";

export type AccountPasswordData = {
  oldPassword: string;
  newPassword: string;
};

@Component({
  selector: "m-account-password-view",
  templateUrl: "./account-password.view.html",
  styleUrls: ["./account-password.view.scss"],
})
export class AccountPasswordView implements OnInit, OnDestroy {
  @Input()
  savedStatus = false;

  @Output()
  onSaveClick = new EventEmitter<AccountPasswordData>();

  protected isPasswordsEqual = false;

  private sub = new Subscription();

  protected form = new FormGroup({
    oldPassword: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    newPassword: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    newPasswordRepeat: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
  });

  ngOnInit(): void {
    this.watchFormChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  watchFormChanges() {
    this.sub.add(
      this.form.valueChanges.subscribe((value) => {
        this.isPasswordsEqual =
          this.form.get("oldPassword")?.value !== "" &&
          this.form.get("newPassword")?.value !== "" &&
          this.form.get("newPassword")?.value === this.form.get("newPasswordRepeat")?.value;
      }),
    );
  }

  protected onSaveButtonClick() {
    const formData = _.omit(this.form.getRawValue(), ["newPasswordRepeat"]);
    this.onSaveClick.emit(formData);
  }
}
