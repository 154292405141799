<div *ngIf="isModal" class="backdrop" [class.clickable]="closeOnBackDropClick" (click)="onBackDropClick()">
  <div *ngIf="closeButtonVisible" class="close-icon" (click)="onCloseClick()">
    <m-ng-icon src="close-cross.svg" [size]="16" />
  </div>
</div>

<div class="container-wrapper">
  <m-generation-prompt
    [user]="user"
    [formAdvanced]="formAdvanced"
    [formCreative]="formCreative"
    [igAdvancedPrice]="igAdvancedPrice"
    [igCreativePrice]="igCreativePrice"
    [isModal]="isModal"
    [selectedTab]="selectedTab"
    [characters]="characters"
    [charactersInPromptLimit]="charactersInPromptLimit"
    (onGeneration)="onGenerationClick()"
    (onShowCharactersModal)="showCharactersModal($event)"
    (onCharactersIdsChanged)="onCharactersIdsChanged($event)"
  />

  <div *ngIf="isModal" class="modal-divider-hor"></div>

  <div class="results-wrapper">
    <m-ng-scrollbars [class.modal]="isModal" padding="0 8px 0 0" (onScrolled)="onScroll()">
      <m-generation-results
        [user]="user"
        [isModal]="isModal"
        [imageGenerations]="imageGenerations"
        [stylesAdvanced]="getAdvancedStyles()"
        [stylesCreative]="getCreativeStyles()"
        [isImageGenerationsLoading]="isImageGenerationsLoading"
        [characters]="characters"
        (onScroll)="onScroll()"
        (onPreviewImage)="onShowImagePreviewClick($event)"
        (onSelectImage)="onSelectImage($event)"
        (onDeleteImageGeneration)="onShowDeleteImageGenerationModal($event)"
        (onDownnloadImage)="onDownloadImageClick($event)"
        (onDeleteImage)="onShowDeleteGeneratedImageModal($event)"
        (onUpscaleImage)="onMadeUpscaleImageClick($event)"
        (onUnzoomImage)="onMadeUnzoomImageClick($event)"
        (onEditImageGeneration)="onEditImageGenerationClick($event)"
      />
    </m-ng-scrollbars>
  </div>
</div>

<div *ngIf="isModal" class="modal-divider-ver"></div>

<m-sidebar-fixed class="sidebar" [class.modal]="isModal">
  <m-sidebar-tabs-new
    [selectedTab]="selectedTab"
    i18n-tab1Title="@@image-generation.sidebar.tabs.basic"
    tab1Title="image-generation.sidebar.tabs.basic"
    i18n-tab2Title="@@image-generation.sidebar.tabs.creative"
    tab2Title="image-generation.sidebar.tabs.creative"
    (onTabClick)="onTabClick($event)"
  >
    <div class="tab" id="tab1">
      @if (formAdvanced && !isIGBasicMaintenance) {
      <form #formAdvancedElement [formGroup]="formAdvanced" class="tab-content">
        <m-sidebar-section-2 title="image-generation.image-size" i18n-title="@@image-generation.image-size">
          <m-image-proportion-selector formControlName="proportion" [sizeMode]="getAdvancedGenerationMode()" />
        </m-sidebar-section-2>

        <div class="divider"></div>

        <m-sidebar-section-2 title="image-generation.image-count" i18n-title="@@image-generation.image-count">
          <m-images-count-selector formControlName="imagesCount" />
        </m-sidebar-section-2>

        @if(!igAdvancedSelectedStyleReferenceId) {
        <div class="divider" @slideInOutVertical></div>

        <m-sidebar-section-2
          @slideInOutVertical
          title="image-generation.advanced-generation-mode"
          i18n-title="@@image-generation.advanced-generation-mode"
          [hasTooltip]="true"
        >
          <ng-container tooltip>
            <div class="tooltip-group">
              <b>
                <span class="tooltip-group-title" i18n="@@image-generation.advanced-generation-mode.info.1.title">
                  image-generation.advanced-generation-mode.info.1.title
                </span>
              </b>
              <span i18n="@@image-generation.advanced-generation-mode.info.1.text">
                image-generation.advanced-generation-mode.info.1.text
              </span>
            </div>

            <div class="tooltip-group">
              <b>
                <span class="tooltip-group-title" i18n="@@image-generation.advanced-generation-mode.info.2.title">
                  image-generation.advanced-generation-mode.info.2.title
                </span>
              </b>
              <span i18n="@@image-generation.advanced-generation-mode.info.2.text">
                image-generation.advanced-generation-mode.info.2.text
              </span>
            </div>

            <div class="tooltip-group">
              <b>
                <span class="tooltip-group-title" i18n="@@image-generation.advanced-generation-mode.info.3.title">
                  image-generation.advanced-generation-mode.info.3.title
                </span>
              </b>
              <span i18n="@@image-generation.advanced-generation-mode.info.3.text">
                image-generation.advanced-generation-mode.info.3.text
              </span>
            </div>
          </ng-container>

          <m-image-advanced-generation-mode
            formControlName="advancedGenerationMode"
            [hasPremium]="hasPremium"
            [options]="getAdvancedGenerationModeOptions()"
            [proportion]="advancedGenerationProportion"
          />
        </m-sidebar-section-2>
        }

        <div class="divider"></div>

        <m-sidebar-section-2>
          <m-switch formControlName="dynamicComposition">
            <span class="select-title" i18n="@@image-generation.dynamic-composition">
              image-generation.dynamic-composition
            </span>
          </m-switch>
        </m-sidebar-section-2>

        <div class="divider"></div>

        <m-styles-selector
          formControlName="styleId"
          title="image-generation.select-style"
          i18n-title="@@image-generation.select-style"
          [data]="getAdvancedStyles()"
          [hasNewMark]="true"
          [hasPremium]="hasPremium"
          [hasStyleReference]="true"
          [styleReference]="styleReference"
          (onChangeStyleReference)="showStyleReferenceUploadModal()"
          textViewAllData="image-generation.select-style.all-styles"
          i18n-textViewAllData="@@image-generation.select-style.all-styles"
        />

        <div class="divider"></div>

        <m-sidebar-section-2 title="image-generation.mood" i18n-title="@@image-generation.mood">
          <m-select
            size="m-plus"
            formControlName="mood"
            [options]="getMoodOptions()"
            noSelectedText="common.select-component.not-selected"
            i18n-noSelectedText="@@common.select-component.not-selected"
          />

          <m-info-block type="error" [labels]="advancedMoodErrors" />
        </m-sidebar-section-2>

        <div class="divider"></div>

        <!-- <m-sidebar-section-2>
          <div class="switch-section">
            <m-switch formControlName="isAgeEnable">
              <span class="select-title" i18n="@@image-generation.age">image-generation.age</span>
            </m-switch>

            <m-select
              @fadeInOut
              *ngIf="isAgeSelectVisible"
              size="m-plus"
              formControlName="age"
              [options]="getAgeOptions()"
            />

            <m-ng-input @fadeInOut *ngIf="isSetAgeYearInputVisible" formControlName="ageYear" />
          </div>
        </m-sidebar-section-2>

        <div class="divider"></div> -->

        <m-sidebar-section-2 title="image-generation.detalization" i18n-title="@@image-generation.detalization">
          <m-slider-selector formControlName="detalization" [points]="getDetalizationPoints()" />
        </m-sidebar-section-2>

        <div class="divider"></div>

        <!-- <m-sidebar-section-2 title="image-generation.contrast" i18n-title="@@image-generation.contrast">
          <m-slider-selector formControlName="contrast" [points]="getContrastPoints()" />
        </m-sidebar-section-2>

        <div class="divider"></div> -->

        <m-sidebar-section-2>
          <div class="switch-section">
            <m-switch formControlName="isColorSchemeEnable">
              <span class="select-title" i18n="@@image-generation.color-scheme">image-generation.color-scheme</span>
            </m-switch>

            <m-color-scheme-selector @fadeInOut *ngIf="isAdvancedColorSchemeVisible" formControlName="colorScheme" />
          </div>
        </m-sidebar-section-2>

        <div class="divider"></div>

        <m-sidebar-section-2>
          <div class="switch-section">
            <m-switch formControlName="isNegativePromptEnable">
              <span class="select-title" i18n="@@image-generation.negative-prompt">
                image-generation.negative-prompt
              </span>
            </m-switch>

            <m-textarea
              @fadeInOut
              *ngIf="isAdvancedNegativePromptVisible"
              formControlName="negativePrompt"
              placeholder="image-generation.negative-prompt"
              i18n-placeholder="@@image-generation.negative-prompt"
              [rows]="5"
              [maxlength]="negativePromptMaxLenght"
            />
          </div>
        </m-sidebar-section-2>
      </form>
      } @else if (isIGBasicMaintenance) {
      <ng-container *ngTemplateOutlet="maintenanceTemplate; context: { $implicit: 2 }"></ng-container>
      }
    </div>

    <div class="tab" id="tab2">
      @if (formCreative && !isIGAdvancedMaintenance) {
      <form #formCreativeElement [formGroup]="formCreative" class="tab-content">
        <m-sidebar-section-2 title="image-generation.image-size" i18n-title="@@image-generation.image-size">
          <m-image-proportion-selector formControlName="proportion" />
        </m-sidebar-section-2>

        <div class="divider"></div>

        <!-- <m-sidebar-section-2 title="image-generation.image-count" i18n-title="@@image-generation.image-count">
          <m-images-count-selector formControlName="imagesCount" />
        </m-sidebar-section-2>

        <div class="divider"></div> -->

        <m-sidebar-section-2>
          <m-switch formControlName="dynamicComposition">
            <span class="select-title" i18n="@@image-generation.dynamic-composition">
              image-generation.dynamic-composition
            </span>
          </m-switch>
        </m-sidebar-section-2>

        <div class="divider"></div>

        <m-styles-selector
          formControlName="styleId"
          title="image-generation.select-style"
          i18n-title="@@image-generation.select-style"
          [data]="getCreativeStyles()"
          [hasNewMark]="true"
          [hasPremium]="hasPremium"
          textViewAllData="image-generation.select-style.all-styles"
          i18n-textViewAllData="@@image-generation.select-style.all-styles"
        />

        <div class="divider"></div>

        <m-sidebar-section-2 title="image-generation.mood" i18n-title="@@image-generation.mood">
          <m-select
            size="m-plus"
            formControlName="mood"
            [options]="getMoodOptions()"
            noSelectedText="common.select-component.not-selected"
            i18n-noSelectedText="@@common.select-component.not-selected"
          />

          <m-info-block type="error" [labels]="creativeMoodErrors" />
        </m-sidebar-section-2>

        <div class="divider"></div>

        <!-- <m-sidebar-section-2>
          <div class="switch-section">
            <m-switch formControlName="isAgeEnable">
              <span class="select-title" i18n="@@image-generation.age">image-generation.age</span>
            </m-switch>

            <m-select
              @fadeInOut
              *ngIf="isAgeSelectVisible"
              size="m-plus"
              formControlName="age"
              [options]="getAgeOptions()"
            />

            <m-ng-input @fadeInOut *ngIf="isSetAgeYearInputVisible" formControlName="ageYear" />
          </div>
        </m-sidebar-section-2>

        <div class="divider"></div> -->

        <m-sidebar-section-2 title="image-generation.detalization" i18n-title="@@image-generation.detalization">
          <m-slider-selector formControlName="detalization" [points]="getDetalizationPoints()" />
        </m-sidebar-section-2>

        <div class="divider"></div>

        <!-- <m-sidebar-section-2 title="image-generation.contrast" i18n-title="@@image-generation.contrast">
          <m-slider-selector formControlName="contrast" [points]="getContrastPoints()" />
        </m-sidebar-section-2>

        <div class="divider"></div> -->

        <m-sidebar-section-2>
          <div class="switch-section">
            <m-switch formControlName="isColorSchemeEnable">
              <span class="select-title" i18n="@@image-generation.color-scheme">image-generation.color-scheme</span>
            </m-switch>

            <m-color-scheme-selector @fadeInOut *ngIf="isCreativeColorSchemeVisible" formControlName="colorScheme" />
          </div>
        </m-sidebar-section-2>

        <div class="divider"></div>

        <m-sidebar-section-2>
          <div class="switch-section">
            <m-switch formControlName="isNegativePromptEnable">
              <span class="select-title" i18n="@@image-generation.negative-prompt">
                image-generation.negative-prompt
              </span>
            </m-switch>

            <m-textarea
              @fadeInOut
              *ngIf="isCreativeNegativePromptVisible"
              formControlName="negativePrompt"
              placeholder="image-generation.negative-prompt"
              i18n-placeholder="@@image-generation.negative-prompt"
              [rows]="5"
              [maxlength]="negativePromptMaxLenght"
            />
          </div>
        </m-sidebar-section-2>
      </form>
      } @else if (isIGAdvancedMaintenance) {
      <ng-container *ngTemplateOutlet="maintenanceTemplate; context: { $implicit: 1 }"></ng-container>
      }
    </div>
  </m-sidebar-tabs-new>

  <m-low-balance-modal
    *ngIf="isLowBalanceModalVisible && (tariffsForUpgrade$ | async) as tariffs"
    @fadeInOut
    [user]="user"
    [tariffs]="tariffs"
    [balanceData]="imageGenerationPaymentData"
    [activeSubscription]="activeSubscription"
    [isImageGeneration]="true"
    [userRewardOneTime]="getSubscribeToTelegramChannelReward()"
    [currency]="currency"
    (close)="closePricingModal()"
    (buySubscription)="onBuySubscription($event)"
  />

  <m-preview-image-modal
    *ngIf="previewGeneratedImage && previewImageGeneration"
    [prices]="prices"
    [imageGeneration]="previewImageGeneration"
    [generatedImage]="previewGeneratedImage"
    [hasPrevious]="hasPreviousImage()"
    [hasNext]="hasNextImage()"
    [isModal]="isModal"
    (onPrevious)="onPreviousClick()"
    (onNext)="onNextClick()"
    (onSelect)="onSelectImageFromModalClick($event)"
    (onClose)="onCloseImagePreviewClick()"
  ></m-preview-image-modal>

  <m-confirmation-modal
    *ngIf="imageGenerationForDelete"
    text="image-generation.generation.delete-generation-confirmation"
    i18n-text="@@image-generation.generation.delete-generation-confirmation"
    confirmText="dialog.delete-button"
    i18n-confirmText="@@dialog.delete-button"
    cancelText="dialog.cancel-button"
    i18n-cancelText="@@dialog.cancel-button"
    (onConfirm)="deleteImageGeneration()"
    (onClose)="onCloseDeleteImageGenerationModal()"
  />

  <m-confirmation-modal
    *ngIf="generatedImageForDelete"
    [text]="modalConfirmationDeleteImageText"
    confirmText="dialog.delete-button"
    i18n-confirmText="@@dialog.delete-button"
    cancelText="dialog.cancel-button"
    i18n-cancelText="@@dialog.cancel-button"
    (onConfirm)="deleteImage()"
    (onClose)="onCloseDeleteGeneratedImageModal()"
  />
</m-sidebar-fixed>

<m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>

<m-premium-tariffs-modal @fadeInOut *ngIf="isPremiumModalVisible" (close)="closePremiumModal()" />

<m-characters-modal-view
  *ngIf="isCharactersModalVisible"
  [characters]="characters"
  [addedCharactersIds]="addedCharactersIds"
  [charactersInPromptLimit]="charactersInPromptLimit"
  [isCharacterChanging]="isCharacterChanging"
  (onSelect)="selectCharacter($event)"
  (onClose)="hideCharactersModal()"
/>

<m-style-reference-upload-modal
  *ngIf="isStyleReferenceUploadModalVisible"
  [styleReference]="styleReference"
  (onClose)="closeStyleReferenceUploadModal()"
/>

<ng-template #maintenanceTemplate let-tab>
  <div class="maintenance-wrapper">
    <div>
      <img src="/assets/img/sand_clock.png" />
    </div>

    <span class="maintenance-title" i18n="@@image-generation.maintenance.title">
      image-generation.maintenance.title
    </span>

    <div class="maintenance-description">
      @if (tab === 1) {
      <m-localized-text
        text="image-generation.maintenance.basic.description"
        i18n-text="@@image-generation.maintenance.basic.description"
      />
      } @else if (tab === 2) {
      <m-localized-text
        text="image-generation.maintenance.creative.description"
        i18n-text="@@image-generation.maintenance.creative.description"
      />
      }
    </div>

    <div>
      @if (tab === 1) {
      <m-button
        button-style="secondary"
        icon-position="left"
        icon="button-open-link.svg"
        (onClick)="onTabClick(tab)"
        i18n="@@image-generation.maintenance.action-basic"
      >
        image-generation.maintenance.action-basic
      </m-button>
      } @else if (tab === 2) {
      <m-button
        button-style="secondary"
        icon-position="left"
        icon="button-open-link.svg"
        (onClick)="onTabClick(tab)"
        i18n="@@image-generation.maintenance.action-creative"
      >
        image-generation.maintenance.action-creative
      </m-button>
      }
    </div>
  </div>
</ng-template>
