import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { I18nService } from "@metranpage/i18n";
import { Social } from "@metranpage/core-interfaces";

@Component({
  selector: "m-socials",
  templateUrl: "./socials.view.html",
  styleUrls: ["./socials.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialsView {
  @Input()
  socials: Social[] = [];

  protected locale = "en";

  constructor(private readonly i18nService: I18nService) {
    this.locale = this.i18nService.getLocale();
  }

  getTitle(social: Social) {
    const locale = social.locales.find((l) => l.lang === this.locale);
    return locale?.value || "";
  }
}
