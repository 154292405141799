import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL, COMPANY_UUID } from "@metranpage/core-api";
import { Company } from "../models/company";

@Injectable({
  providedIn: "root",
})
export class CompanyApi extends Api {
  constructor(
    http: HttpClient,
    @Inject(BASE_URL) baseUrl: string,
    @Inject(COMPANY_UUID) private companyUuid: string,
  ) {
    super(http, baseUrl);
  }

  async getCompany() {
    return this.get<Company>("company", { uuid: this.companyUuid });
  }
}
