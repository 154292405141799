import { Component, Input } from "@angular/core";
import { LoginError } from "../models/login-errors";

@Component({
  selector: "m-login-error",
  template: `
    <m-ng-icon src="login-error.svg" class="le-icon" [size]="24" />
    <span class="le-label">{{ getTextFromError() }}</span>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
      }

      .le-label {
        color: var(--danger);
        font-size: 14px;
      }

      m-ng-icon {
      --icon-color: var(--danger);
    }

    `,
  ],
})
export class LoginErrorComponent {
  @Input()
  error?: LoginError;

  getTextFromError() {
    if (!this.error) {
      return "";
    }
    switch (this.error) {
      case "wrong-password":
        return $localize`:@@user.auth.wrong-creds-error:`;
      case "incorrect-email-format":
        return $localize`:@@user.auth.error.wrong-email-format:`;
      case "dpa-agreement-needed":
        return $localize`:@@user.auth.agreement-error:`;
      case "different-login-method":
        return $localize`:@@user.auth.wrong-login-method-error:`;
      case "email-not-unique":
        return $localize`:@@user.auth.email-not-unique-error:`;
      case "passwords-are-different":
        return $localize`:@@user.auth.passwords-are-different-error:`;
      case "field-required":
        return $localize`:@@user.auth.field-required-error:`;
      default:
        return "";
    }
  }
}
