import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { UserActionMessage } from "./welcome-modal.service";

@Component({
  selector: "m-welcome-modal",
  templateUrl: "./welcome-modal.view.html",
  styleUrls: ["./welcome-modal.view.scss"],
})
export class WelcomeModalView {
  @Input() message!: UserActionMessage;

  @Output()
  actionClick = new EventEmitter<UserActionMessage>();
  @Output()
  close = new EventEmitter<UserActionMessage>();

  get backgroundImageStyle(): string {
    const url = this.message.backgroundUrl ?? "/assets/img/welcome.png";
    return `--background-image-url: url(${url})`;
  }

  getImageUrl() {
    return this.message.backgroundUrl ?? "/assets/img/welcome.png";
  }

  onActionClick() {
    this.actionClick.emit(this.message);
  }

  onCloseClick() {
    this.close.emit(this.message);
  }
}
