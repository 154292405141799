import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ImageGenerationBlackListService {
  hasRestrictedText(text: string | undefined): boolean {
    if (!text) {
      return false;
    }

    // const words = text.split(/\s/);
    const expr = new RegExp(this.blackList.join("|"));
    return expr.test(text.toLowerCase());
  }

  blackList = ["сво", "война", "war"];
}
