<div class="container">
  <div class="image-positions">
    <m-radio
      *ngFor="let positionValue of getAvailableImagePosition()"
      [value]="positionValue.value"
      [isChecked]="isImagePositionChecked(positionValue.value)"
      [isDisabled]="positionValue.disabled || isDisabled"
      (onSelect)="onSelectImagePosition($event)"
    >
      <span class="label">{{ getImagePositionText(positionValue.value) }}</span>
    </m-radio>
  </div>

  <div>
    <div class="images-preview">
      <m-icon *ngFor="let image of getPreview()" [src]="image" size="original" />
    </div>

    <div class="description">
      <span *ngFor="let description of getDescription()">{{ description }}</span>
    </div>
  </div>
</div>
