import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'm-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class TooltipComponent {
  @Input()
  position: 'top' | 'bottom' | 'left' | 'right' = 'top';

  @HostBinding('class')
  get classes() {
    return `${this.position}`;
  }
}
