<m-ng-island [paddingSize]="24">
  <form *ngIf="!!rewardOneTime; else noRewardOneTime" [formGroup]="form">
    <div class="controls">
      <div class="control">
        <span class="label" i18n="@@admin.rewards.onetime.type">admin.rewards.onetime.type</span>
        <m-select
          class="select"
          input-style="normal"
          size="s"
          [options]="getTypeSelectOptions()"
          formControlName="type"
        ></m-select>
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.rewards.onetime.limit">admin.rewards.onetime.limit</span>
        <m-ng-input-number formControlName="limit" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.rewards.onetime.reward-credits-count">
          admin.rewards.onetime.reward-credits-count
        </span>
        <m-ng-input-number formControlName="rewardCreditsCount" />
      </div>
    </div>

    <div class="bottom-bar">
      <div class="buttons">
        <m-button button-style="secondary" (onClick)="onCancel()" i18n="@@admin.common.cancel">
          admin.common.cancel
        </m-button>
        <m-button button-style="primary" [is-disabled]="!form.valid" (onClick)="onSave()" i18n="@@admin.common.save">
          admin.common.save
        </m-button>
      </div>
    </div>
  </form>
  <ng-template #noRewardOneTime>No reward loaded</ng-template>
</m-ng-island>
