import { Injectable } from "@angular/core";
import { Book, MarginKey, RunningTitlesStyle } from "@metranpage/book-data";
import { GeneralResultStatus } from "@metranpage/core-data";
import { BookService } from "./book.service";
import { BooksStore } from "./books.store";
import { RunningTitlesStore } from "./running-titles.store";

const mappingMarginsPositions = [
  { marginKey: "marginTop", position: 0 },
  { marginKey: "marginOuter", position: 1 },
  { marginKey: "marginBottom", position: 2 },
];

@Injectable({
  providedIn: "root",
})
export class RunningTitlesService {
  private runningTitlesStyles: RunningTitlesStyle[] = [
    {
      id: 1,
      name: "1",
      isCentered: false,
    },
    {
      id: 2,
      name: "2",
      isCentered: false,
    },
    {
      id: 3,
      name: "3",
      isCentered: false,
    },
    {
      id: 4,
      name: "4",
      isCentered: true,
    },
    {
      id: 5,
      name: "5",
      isCentered: true,
    },
  ];

  private pageNumbersStyles: RunningTitlesStyle[] = [
    {
      id: 1,
      name: "1",
      isCentered: false,
    },
    {
      id: 2,
      name: "2",
      isCentered: true,
    },
    {
      id: 3,
      name: "3",
      isCentered: true,
    },
  ];

  constructor(
    private readonly bookService: BookService,
    private readonly booksStore: BooksStore,
    private readonly runningTitlesStore: RunningTitlesStore,
  ) {}

  isRunningTitleStyleCentered(styleId: number) {
    const style = this.getRunningTitleStyleById(styleId);
    return style?.isCentered ?? false;
  }

  isPageNumberStyleCentered(styleId: number) {
    const style = this.getPageNumbersStyleById(styleId);
    return style?.isCentered ?? false;
  }

  getRunningTitlesStyles() {
    return this.runningTitlesStyles;
  }

  getRunningTitleStyleById(id: number) {
    return this.runningTitlesStyles.find((style) => style.id === id);
  }

  getRunningTitleStyleByName(name: string) {
    return this.runningTitlesStyles.find((style) => style.name === name);
  }

  getPageNumbersStyles() {
    return this.pageNumbersStyles;
  }

  getPageNumbersStyleById(id: number) {
    return this.pageNumbersStyles.find((style) => style.id === id);
  }

  getPageNumbersStyleByName(name: string) {
    return this.pageNumbersStyles.find((style) => style.name === name);
  }

  capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  convertPositionToMarginKey(position: number): MarginKey {
    const marginKey = mappingMarginsPositions.find((item) => item.position === position)?.marginKey ?? "marginTop";
    return marginKey as MarginKey;
  }

  convertMarginKeyToPosition(marginKey: MarginKey): number {
    const position = mappingMarginsPositions.find((item) => item.marginKey === marginKey)?.position ?? 0;
    return position;
  }

  async updateState(book: Book): Promise<GeneralResultStatus> {
    const state = this.runningTitlesStore.getRunningTitlesState();
    return await this.bookService.updateRunningTitlesState(book, state);
  }
}
