<m-ng-island>
  <div class="container">
    <div class="form-panel">
      <div class="header">
        <div i18n="@@user.auth.registration-title">user.auth.registration-title</div>
        <m-lang-select-2 [userLanguage]="userLanguage" (changeLang)="onLangChange($event)" />
      </div>

      <form [formGroup]="form" (keydown)="onKeyDown($event)">
        <div class="inputs" *ngIf="step === 'email-data'">
          <div class="input-group">
            <m-ng-input-with-icon
              class="email"
              iconLeft="input-email.svg"
              name="email"
              i18n-placeholder="@@user.auth.email-placeholder"
              placeholder="@@user.auth.email-placeholder"
              formControlName="email"
              (focusout)="focusoutEvent('email')"
            />

            <m-login-error *ngIf="isInputEmailErrorVisible && isEmailIncorrect()" error="incorrect-email-format" />
            <m-login-error *ngIf="isInputEmailErrorVisible && isFieldEmpty('email')" error="field-required" />
          </div>

          <div class="input-group">
            <m-ng-input-password
              class="password"
              name="password"
              [withLeftIcon]="true"
              i18n-placeholder="@@user.auth.password-placeholder"
              placeholder="@@user.auth.password-placeholder"
              formControlName="password"
              (focusout)="focusoutEvent('password')"
            />

            <m-login-error *ngIf="isInputPasswordErrorVisible && isFieldEmpty('password')" error="field-required" />
          </div>

          <div class="input-group">
            <m-ng-input-password
              class="password"
              name="password"
              [withLeftIcon]="true"
              i18n-placeholder="@@user.auth.password-confirmation-placeholder"
              placeholder="@@user.auth.password-confirmation-placeholder"
              formControlName="passwordConfirm"
              (focusout)="focusoutEvent('passwordConfirm')"
            />

            <m-login-error *ngIf="isPasswordsDifferent()" error="passwords-are-different" />
            <m-login-error
              *ngIf="isInputPasswordConfirmErrorVisible && isFieldEmpty('passwordConfirm')"
              error="field-required"
            />
          </div>
        </div>

        <div class="inputs" *ngIf="step === 'user-data'">
          <div class="input-group">
            <m-ng-input-with-icon
              class="first-name"
              iconLeft="input-user.svg"
              name="first-name"
              i18n-placeholder="@@user.auth.firstname-placeholder"
              placeholder="@@user.auth.firstname-placeholder"
              formControlName="firstName"
            />
            <m-login-error *ngIf="isInputFirstNameErrorVisible && isFieldEmpty('firstName')" error="field-required" />
          </div>

          <div class="input-group">
            <m-select
              class="role"
              input-style="on-panel"
              noSelectedText="user.auth.role-placeholder"
              i18n-noSelectedText="@@user.auth.role-placeholder"
              [options]="roleOptions"
              name="role"
              iconLeft="input-role.svg"
              [isRequired]="true"
              formControlName="role"
            ></m-select>
            <m-login-error *ngIf="isInputRoleErrorVisible && isFieldNotInRange('role')" error="field-required" />
          </div>

          <div class="input-group">
            <m-checkbox class="dpa-agreement" [isChecked]="false" formControlName="dpaAgreement">
              <span i18n="@@user.auth.agreement-part1">user.auth.agreement-part1</span>
              <a
                i18n-href="@@user.auth.agreement-url"
                href="user.auth.agreement-url"
                i18n="@@user.auth.agreement-part2"
                target="_blank"
                rel="noopener noreferrer"
              >
                user.auth.agreement-part2
              </a>
            </m-checkbox>

            <m-login-error
              *ngIf="isInputDpaAgreementErrorVisible && isFieldEmpty('dpaAgreement')"
              error="dpa-agreement-needed"
            ></m-login-error>
          </div>

          <m-checkbox class="promo-agreement" [isChecked]="false" formControlName="promoAgreement">
            <span i18n="@@user.auth.promo-agree">user.auth.promo-agree</span>
          </m-checkbox>
        </div>

        <m-login-error *ngFor="let error of errors" [error]="error"></m-login-error>

        <div class="action-group">
          <m-button
            *ngIf="step === 'email-data'"
            button-style="primary"
            size="full-width"
            i18n="@@dialog.next-button"
            (onClick)="onNextButtonClick()"
          >
            dialog.next-button
          </m-button>

          <div *ngIf="step === 'user-data'" class="buttons-group">
            <m-button
              button-style="secondary"
              icon-position="only"
              icon="button-back.svg"
              (onClick)="onBackButtonClick()"
            />

            <m-button
              button-style="primary"
              size="full-width"
              i18n="@@user.auth.register-button"
              (onClick)="onRegisterButtonClick()"
            >
              user.auth.register-button
            </m-button>
          </div>

          <div class="got-account">
            <span i18n="@@user.auth.already-registered-hint">user.auth.already-registered-hint</span>
            <a class="login-link" routerLink="/login" [queryParams]="getQueryParams()" i18n="@@user.auth.login-switch">
              user.auth.login-switch
            </a>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="isSocialLoginVisible()" class="divider">
      <div class="divider-line"></div>
      <div i18n="@@user.auth.divider-text">user.auth.divider-text</div>
      <div class="divider-line"></div>
    </div>

    <div *ngIf="isSocialLoginVisible()" class="socials-login">
      <m-social-login-button
        *ngIf="isGoogleLoginAvailable"
        iconSrc="auth-google-2.svg"
        text="user.auth.login.social.google"
        i18n-text="@@user.auth.login.social.google"
        [isIconOnly]="true"
        (click)="onGoogleLogin()"
      />
      <m-social-login-button
        *ngIf="isVkLoginAvailable"
        iconSrc="auth-vk-2.svg"
        text="user.auth.login.social.vk"
        i18n-text="@@user.auth.login.social.vk"
        [isIconOnly]="true"
        (click)="onVkLogin()"
      />
    </div>
  </div>

  <m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>
</m-ng-island>
