<div class="info">
  <div class="content">
    <div class="title" (click)="toggle()">
      <m-ng-icon class="collapse-icon" src="bc-arrow-24.svg" [size]="24" [class.collapsed]="isCollapsed" />

      <m-prompt class="prompt" [prompt]="imageGeneration.prompt" [showCharacters]="true" [characters]="characters" />
    </div>

    <div *ngIf="isCollapsed" class="collapsed-wrapper" @slideInOutVertical>
      <div class="settings">
        <div class="settings-group">
          <span class="settings-title" i18n="@@image-generation.selected-mode">image-generation.selected-mode</span>

          <span
            *ngIf="imageGeneration.generationMode === 'advanced'"
            class="settings-text"
            i18n="@@image-generation.selected-mode.basic"
          >
            image-generation.selected-mode.basic
          </span>
          <span
            *ngIf="imageGeneration.generationMode === 'creative'"
            class="settings-text"
            i18n="@@image-generation.selected-mode.creative"
          >
            image-generation.selected-mode.creative
          </span>
        </div>

        <div *ngIf="getAdvancedGenerationModeTitle()" class="settings-group">
          <span class="settings-title" i18n="@@image-generation.selected-quality-level">
            image-generation.selected-quality-level
          </span>
          <span class="settings-text">{{ getAdvancedGenerationModeTitle() }}</span>
        </div>

        <div *ngIf="styleTitle" class="settings-group">
          <span class="settings-title" i18n="@@image-generation.selected-style">image-generation.selected-style</span>
          <span class="settings-text">{{styleTitle}}</span>
        </div>

        <!-- <div *ngIf="advancedStyle" class="settings-group">
          <span class="settings-title" i18n="@@image-generation.selected-style">image-generation.selected-style</span>
          <span class="settings-text">{{advancedStyle.title}}</span>
        </div>

        <div *ngIf="creativeStyle" class="settings-group">
          <span class="settings-title" i18n="@@image-generation.selected-style">image-generation.selected-style</span>
          <span class="settings-text">{{creativeStyle.title}}</span>
        </div>

        <div *ngIf="imageGeneration.userStyleId" class="settings-group">
          <span class="settings-title" i18n="@@image-generation.selected-style">image-generation.selected-style</span>
          <span class="settings-text" i18n="@@image-generation.selected-style.user-style-reference">
            image-generation.selected-style.user-style-reference
          </span>
        </div> -->

        <div *ngIf="getMoodTitle()" class="settings-group">
          <span class="settings-title" i18n="@@image-generation.selected-mood">image-generation.selected-mood</span>
          <span class="settings-text">{{ getMoodTitle() }}</span>
        </div>

        <div *ngIf="getDetalizationTitle()" class="settings-group">
          <span class="settings-title" i18n="@@image-generation.selected-detalization">
            image-generation.selected-detalization
          </span>
          <span class="settings-text">{{ getDetalizationTitle() }}</span>
        </div>
      </div>

      <div
        *ngIf="imageGeneration.status === 'error'"
        class="error"
        i18n="@@image-generation.generation.error.while-processing"
      >
        image-generation.generation.error.while-processing
      </div>

      <div *ngIf="user?.isAdmin" class="settings-group">
        <span>generationId: {{imageGeneration.id}}</span>
        <span>apiCreditCost: {{imageGeneration.apiCreditCost}}</span>
      </div>
    </div>
  </div>

  <div class="actions-wrapper">
    <div *ngIf="isEditable()" class="action-generation-button" (click)="onEditImageGenerationClick()">
      <m-ng-icon src="edit-ig.svg" [size]="24" />
      <span class="action-generation-button-text" i18n="@@image-generation.generation.edit-generation">
        image-generation.generation.edit-generation
      </span>
      <m-soon-mark />
    </div>

    <m-ng-icon class="delete-icon" src="block-delete.svg" [size]="20" (click)="onDeleteImageGenerationClick()" />
  </div>
</div>

<div *ngIf="imageGeneration?.generatedImages && imageGeneration.generatedImages.length > 0" class="images">
  <m-generated-image-card
    *ngFor="let generatedImage of getGeneratedImages(imageGeneration)"
    [generatedImage]="generatedImage"
    [isModal]="isModal"
    (onPreviewImage)="onShowImagePreviewClick($event)"
    (onSelectImage)="onSelectImageClick($event)"
    (onDownnloadImage)="onDownloadImageClick($event)"
    (onDeleteImage)="onDeleteImageClick($event)"
    (onUpscaleImage)="onUpscaleImageClick($event)"
    (onUnzoomImage)="onUnzoomImageClick($event)"
  />
</div>

<div *ngIf="imageGeneration.status === 'created'" class="images">
  <m-generating-image-card
    *ngFor="let image of [].constructor(imageGeneration.imagesCount)"
    [imageGeneration]="imageGeneration"
  />
</div>

<div *ngIf="imageGeneration.status === 'error'" class="images">
  <m-generation-error-image-card
    *ngFor="let image of [].constructor(imageGeneration.imagesCount)"
    [imageGeneration]="imageGeneration"
  />
</div>

<div *ngIf="showDivider" class="divider"></div>
