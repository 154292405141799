import { Component, Input } from "@angular/core";

@Component({
  selector: "m-markup-editor-menu-item",
  templateUrl: "./markup-editor-menu-item.view.html",
  styleUrls: ["./markup-editor-menu-item.view.scss"],
})
export class MarkupEditorMenuItem {
  @Input()
  icon!: string;
  @Input()
  title!: string;
  @Input()
  iconArrow!: string;
}
