<div class="input-container">
  <input
    #input
    class="input"
    type="range"
    [min]="min"
    [max]="max"
    [step]="step"
    [name]="name"
    (change)="onInputChange($event)"
  />
  <div class="labels">
    <span>{{ leftLabel }}</span>
    <span>{{ rightLabel }}</span>
  </div>

  <div *ngIf="isDotted" class="range-dots" [ngStyle]="getDotStyle()">
    <div *ngFor="let item of getCountDots()" class="dot" (click)="onClickDot(item)"></div>
  </div>
</div>
