<ngx-file-drop
  #filedrop
  dropZoneLabel="Drop files here"
  (onFileDrop)="_dropped($event)"
  (onFileOver)="isHovered = true"
  (onFileLeave)="isHovered = false"
  dropZoneClassName="upload-wrapper"
  [accept]="accept"
  [contentClassName]="isHovered ? 'item hovered' : 'item'"
  [multiple]="multiple"
>
  <ng-template ngx-file-drop-content-tmp>
    <ng-content></ng-content>
  </ng-template>
</ngx-file-drop>
