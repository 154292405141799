import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TrialData, UserService } from "@metranpage/user";
import { UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";

export type FreeTariffNotificationDirection = "row" | "column";

@Component({
  selector: "m-free-tariff-notification-view",
  templateUrl: "./free-tariff-notification.view.html",
  styleUrls: ["./free-tariff-notification.view.scss"],
})
export class FreeTariffNotificationView {
  @Input()
  direction: FreeTariffNotificationDirection = "column";

  @ViewChild("progressBar", { static: true, read: ElementRef })
  protected progressBar!: ElementRef;

  protected trialData?: TrialData;

  sub: Subscription = new Subscription();

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly userStore: UserStore,
  ) {
    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        if (!activeSubscription) {
          return;
        }

        const user = userStore.getUser();
        if (!user?.trialActivatedDate) {
          return;
        }

        this.trialData = this.userService.checkIsTrialPeriodActive(
          user?.trialActivatedDate,
          activeSubscription.tariff.trialPeriodInDay,
        );
      }),
    );
  }

  ngOnInit(): void {
    this.fillProgressBar();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected fillProgressBar() {
    if (!this.trialData?.trialActivatedDate) {
      this.progressBar.nativeElement.style.width = "100%";
      return;
    }

    if (!this.trialData.isTrialActive) {
      this.progressBar.nativeElement.style.width = "100%";
      return;
    }

    let progress = (100 / this.trialData.trialPeriodInDay) * this.trialData.diffInDays;
    if (progress <= 0) {
      progress = 0;
    }
    if (progress > 100) {
      progress = 100;
    }

    this.progressBar.nativeElement.style.width = `${progress}%`;
  }

  getText() {
    if (this.direction === "column") {
      return $localize`:@@books.free-tariff-notification.most-settings-is-not-available-column:`;
    }
    return $localize`:@@books.free-tariff-notification.most-settings-is-not-available:`;
  }

  getTrialText() {
    return `${$localize`:@@books.free-tariff-notification.trial-access-1:`}
        ${this.trialData?.trialPeriodInDay || ""}${$localize`:@@books.free-tariff-notification.trial-access-2:`}`;
  }

  getTrialExpireText() {
    if (!this.trialData?.expireDate) {
      return "";
    }
    return `${$localize`:@@books.free-tariff-notification.trial-expire:`} ${this.trialData.expireDate
      .toJSDate()
      .toLocaleDateString("ru-RU")}`;
  }

  getButtonText() {
    return $localize`:@@books.free-tariff-notification.get-tariff:`;
  }

  onGetTariffClick() {
    this.router.navigate(["payments", "subscription"]);
  }
}
