import { animate, animation, style, transition, trigger, useAnimation } from '@angular/animations';

const fadeIn = animation([
  style({
    opacity: 0,
  }),
  animate(
    '0.2s {{delay}} ease-in-out',
    style({
      opacity: 1,
    }),
  ),
]);

const fadeOut = animation([
  style({
    opacity: 1,
  }),
  animate(
    '0.2s {{delay}}  ease-in-out',
    style({
      opacity: 0,
    }),
  ),
]);

export const fadeInOutOnEnterLeave = trigger('fadeInOut', [
  transition(':enter', [useAnimation(fadeIn, { params: { delay: '0s' } })]),
  transition(':leave', [useAnimation(fadeOut, { params: { delay: '0s' } })]),
]);

export const fadeInOutForSidebarElements = trigger('fadeInOut', [
  transition(':enter', [useAnimation(fadeIn, { params: { delay: '0.2s' } })]),
  transition(':leave', [useAnimation(fadeOut, { params: { delay: '0.0s' } })]),
]);
