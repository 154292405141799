import { Injectable } from "@angular/core";
import { EditorBlock } from "../views/markup-editor/editor.models";

const notTextBlocks = ["table", "list", "image"];

@Injectable({
  providedIn: "root",
})
export class EditorDataService {
  cleanData(blocks: EditorBlock[]): EditorBlock[] {
    const result = blocks.slice(0);

    for (const block of blocks) {
      if (notTextBlocks.includes(block.style)) {
        continue;
      }

      this.trimBlockText(block);
    }

    // result = result.filter((b) => !this.isBlockEmpty(b));

    return result;
  }

  private isBlockEmpty(block: EditorBlock): boolean {
    if (notTextBlocks.includes(block.style)) {
      return false;
    }

    if (!block.data.text) {
      return true;
    }
    return false;
  }

  private trimBlockText(block: EditorBlock) {
    if (block.data.text) {
      let text = block.data.text;

      // replace br to \n, as trim will trim \n too
      text = text.replace(/<br\s*\/?>/g, "\n");

      text = text.trim();

      // after trim - replace \n to br back
      text = text.replace(/\n/g, "<br />");

      text = text.replace(/\r/g, "");

      block.data.text = text;
    }
  }
}
