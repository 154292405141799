import { animate, style, transition, trigger } from "@angular/animations";
import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { NotificationsPopUpService } from "../../services/notifications-pop-up.service";
import { NotificationComponent } from "../notification-pop-up/notification.component";

/**
 * There is additional span in notification, so we can pass html code to it
 */
@Component({
  selector: "m-notification-host",
  template: `
    <m-notification
      *ngFor="let data of notificationsService.getNotificationStack()"
      @addRemove
      [notification-type]="data.type!"
      [show-options]="data.showOpts!"
      [notification-id]="data.id!"
      (close)="onNotificationClose(data.id!)"
    >
      <span [innerHTML]="data.content"></span>
    </m-notification>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    `,
  ],
  animations: [
    trigger("addRemove", [
      transition(":enter", [
        style({
          opacity: 0,
          "margin-top": "64px",
          "margin-bottom": "16px",
        }),
        animate(
          "0.3s  ease-in-out",
          style({
            opacity: 1,
            "margin-top": "0px",
            "margin-bottom": "16px",
          }),
        ),
      ]),
      transition(":leave", [
        style({
          opacity: 1,
          "margin-top": "0px",
          "margin-bottom": "16px",
        }),
        animate(
          "0.3s  ease-in-out",
          style({
            height: 0,
            opacity: 0,
            "margin-bottom": "0px",
          }),
        ),
      ]),
    ]),
  ],
  standalone: true,
  imports: [CommonModule, NotificationComponent],
})
export class NotificationHostComponent {
  constructor(protected readonly notificationsService: NotificationsPopUpService) {}

  onNotificationClose(id: number) {
    this.notificationsService.closeNotification(id);
  }
}
