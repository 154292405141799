<m-modal-backdrop (close)="onCloseClick()">
  <div class="container">
    <img [src]="getImageUrl()" />

    <div class="context">
      <m-localized-text class="header" [text]="message.header" />
      <span class="text">
        <m-localized-text [text]="message.text" />
      </span>

      <m-button class="button" button-style="primary" (onClick)="onActionClick()">{{message.button}}</m-button>
    </div>
  </div>
</m-modal-backdrop>
