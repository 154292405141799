import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MarkupPreviewService } from "../../../../services/preview.service";
import { BaseTextEditor } from "../../../text-editor/base-text-editor/base-text-editor.view";
import { Footnote } from "../../editor.service";
import { PaletteDTO } from "./../../../../models/editor";

@Component({
  selector: "m-markup-editor-footnote-block",
  templateUrl: "./footnote.block-view.html",
  styleUrls: ["./footnote.block-view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FootnoteBlockView implements OnInit, OnChanges {
  @Input()
  footnote!: Footnote;
  @Input()
  palette?: PaletteDTO;

  @Output()
  contentChanged = new EventEmitter<Footnote>();
  @Output()
  onRemoveFootnoteClick = new EventEmitter<void>();

  @ViewChild("textEditor")
  textEditor: BaseTextEditor | undefined;

  text = "";

  constructor(
    private readonly previewService: MarkupPreviewService,
    private readonly elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.text = this.footnote.text;
  }

  ngAfterViewInit(): void {
    if (this.footnote.isFocused) {
      this.textEditor?.setCaretToBegin();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.applyStyleOpts();
  }

  onEditorBlur() {
    const newData = {
      ...this.footnote,
      text: this.text,
    };

    this.contentChanged.emit(newData);

    this.cdr.markForCheck();
  }

  protected onRemoveClick() {
    this.onRemoveFootnoteClick.emit();
  }

  private applyStyleOpts() {
    this.previewService.applyColorStyle(this.elementRef.nativeElement as Element, this.palette?.colorSecondary);
    this.cdr.markForCheck();
  }
}
