import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Template } from "@metranpage/book-data";
import { CAN_CREATE_PUBLIC_TEMPLATES, LoadingService, NotificationsPopUpService } from "@metranpage/core";
import { debounceTime, takeUntil } from "rxjs";
import { TemplateCreateData } from "../../models/template";
import { TemplateAccess } from "../../models/template-access";
import { AdminTemplatesAccessService } from "../../services/templates-access/templates-access.service";
import { AdminTemplatesAccessStore } from "../../services/templates-access/templates-access.store";
import { AdminTemplatesService } from "../../services/templates/templates.service";
import { AdminTemplatesStore } from "../../services/templates/templates.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-templates-page",
  templateUrl: "./templates.page.html",
  styleUrls: ["./templates.page.scss"],
})
export class AdminTemplatesPage extends AdminBasePage implements OnInit {
  protected isAddTemplateModalVisible = false;
  protected isDeleteTemplateModalVisible = false;
  protected deleteTemplateId = -1;

  templates: Template[] = [];
  page = 1;
  pageCount = 1;
  dragIndex: number | null = null;

  templatesAccess: TemplateAccess[] = [];
  templatesAccessPage = 1;
  templatesAccessPageCount = 1;

  protected searchForm = new FormGroup({
    title: new FormControl("", { nonNullable: true, validators: [] }),
  });
  protected searchTitle: string | undefined = undefined;

  constructor(
    private readonly adminTemplatesService: AdminTemplatesService,
    private readonly adminTemplatesAccessService: AdminTemplatesAccessService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    adminTemplatesStore: AdminTemplatesStore,
    adminTemplatesAccessStore: AdminTemplatesAccessStore,
    @Inject(CAN_CREATE_PUBLIC_TEMPLATES)
    readonly canCreatePublicTemplates: boolean,
  ) {
    super();

    this.addSub(
      adminTemplatesStore
        .getTemplatesObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((templates) => {
          this.templates = templates;
        }),
    );
    this.addSub(
      adminTemplatesStore
        .getTemplatesPageCountObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((count) => {
          this.pageCount = count;
        }),
    );

    this.addSub(
      adminTemplatesAccessStore
        .getTemplatesAccessObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((templatesAccess) => {
          this.templatesAccess = templatesAccess;
        }),
    );
    this.addSub(
      adminTemplatesAccessStore
        .getTemplatesAccessPageCountObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((count) => {
          this.templatesAccessPageCount = count;
        }),
    );
  }

  ngOnInit(): void {
    this.adminTemplatesService.loadTemplatesPaginated(this.page);

    this.searchForm.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(600)).subscribe((value) => {
      if (value.title) {
        this.searchTitle = value.title;
      } else {
        this.searchTitle = undefined;
      }

      this.adminTemplatesService.loadTemplatesPaginated(this.page, { title: this.searchTitle });
    });

    this.adminTemplatesAccessService.loadTemplatesAccessPaginated(this.templatesAccessPage);
  }

  trackByTemplateId(index: number, template: Template | TemplateAccess) {
    return template.id;
  }

  onPageChanged(page: number) {
    this.page = page;
    this.adminTemplatesService.loadTemplatesPaginated(this.page);
  }

  onAddTemplateClick() {
    this.isAddTemplateModalVisible = true;
  }

  async onTemplateAdded(data: TemplateCreateData) {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminTemplatesService.createTemplate(data);
    this.loadingService.stopLoading();
    if (result.status === "success") {
      this.onCloseAddTemplateModal();
      this.router.navigate(["admin", "templates", result.id]);
    } else {
      this.notificationService.error($localize`:@@admin.templates.template.error.cant-add-template:`);
    }
  }

  onCloseAddTemplateModal() {
    this.isAddTemplateModalVisible = false;
  }

  async onCopyTemplateClick(templateId: number) {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminTemplatesService.copyTemplate(templateId);
    this.loadingService.stopLoading();
    if (result.status === "success") {
      // this.adminTemplatesService.loadTemplatesPaginated(this.page);
      this.router.navigate(["admin", "templates", result.id]);
    } else {
      this.notificationService.error($localize`:@@admin.templates.template.error.cant-copy-template:`);
    }
  }

  onDeleteTemplateClick(templateId: number) {
    this.isDeleteTemplateModalVisible = true;
    this.deleteTemplateId = templateId;
  }

  onCloseDeleteTemplateModal() {
    this.isDeleteTemplateModalVisible = false;
  }

  async onDeleteTemplate() {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminTemplatesService.deleteTemplate(this.deleteTemplateId);
    this.loadingService.stopLoading();
    if (result === "success") {
      this.onCloseDeleteTemplateModal();
      this.adminTemplatesService.loadTemplatesPaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.templates.template.error.cant-delete-template:`);
    }
  }

  onDragStart(event: DragEvent, index: number) {
    event.dataTransfer?.setData("text/plain", index.toString());
    this.dragIndex = index;
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  async onDrop(event: DragEvent, index: number) {
    event.preventDefault();

    if (this.dragIndex === null) return;

    const draggedTemplate = this.templates.splice(this.dragIndex, 1)[0];
    this.templates.splice(index, 0, draggedTemplate);

    for (let i = 0; i < this.templates.length; i++) {
      this.templates[i].order = i;
    }

    this.notificationService.closeAll();
    const status = await this.adminTemplatesService.saveTemplatesOrder(this.templates);
    if (status === "success") {
    } else {
      this.notificationService.error($localize`:@@admin.templates.template.error.cant-update-order:`);
    }

    this.dragIndex = null;
  }

  onTemplatesAccessPageChanged(page: number) {
    this.templatesAccessPage = page;
    this.adminTemplatesAccessService.loadTemplatesAccessPaginated(this.templatesAccessPage);
  }

  async onTemplateVisibleClick(templateAccess: TemplateAccess) {
    const isVisibleToUsers = !templateAccess.isVisibleToUsers;

    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminTemplatesAccessService.updateTemplateAccess(templateAccess.id, { isVisibleToUsers });
    this.loadingService.stopLoading();
    if (result !== "success") {
      this.notificationService.error($localize`:@@admin.templates.template.error.cant-add-template:`);
    }
  }
}
