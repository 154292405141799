import { Route } from "@metranpage/core";

export function routingMaintenance(): Route {
  return {
    path: "maintenance",
    loadComponent: () => import("./pages/maintenance/maintenance.component").then((c) => c.MaintenanceComponent),
    data: {
      showTopBar: false,
    },
  };
}
