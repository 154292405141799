import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "m-new-project-modal-sidebar-section-item",
  templateUrl: "./sidebar-section-item.view.html",
  styleUrls: ["./sidebar-section-item.view.scss"],
})
export class NewProjectModalSidebarSectionItemView {
  @Input()
  title!: string;
  @Input()
  iconSrc?: string;
  @Input()
  @HostBinding("class.active")
  isActive = false;
}
