<div class="wrapper" [class.vertical]="direction == 'column'">
  <m-localized-text *ngIf="!trialData?.isTrialActive" class="title" [text]="getText()" />

  <div *ngIf="trialData?.isTrialActive" class="trial-info">
    <m-localized-text class="trial-title" [text]="getTrialText()" />
    <m-localized-text class="trial-subtitle" [text]="getTrialExpireText()" />
  </div>

  <div *ngIf="direction == 'row'" class="divider-vertical"></div>
  <div *ngIf="direction == 'column'" class="divider-horizontal"></div>

  <div class="button-block" (click)="onGetTariffClick()">
    <m-localized-text [text]="getButtonText()" />
    <m-icon src="button-arrow-right.svg" color="--color-font-main" size="m" />
  </div>
</div>
<div class="progress-bar-wrapper">
  <div #progressBar class="progress-bar" [class.trial-active]="trialData?.isTrialActive"></div>
</div>
