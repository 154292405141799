import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { ThemeService } from "@metranpage/theme";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "m-sidebar-tab-ebook",
  templateUrl: "./sidebar-tab-ebook.view.html",
  styleUrls: ["./sidebar-tab-ebook.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarTabEbook {
  protected imageAsset!: string;
  private destroyed$ = new Subject<void>();

  constructor(
    private readonly themeService: ThemeService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    themeService.changeEvents$.pipe(takeUntil(this.destroyed$)).subscribe((theme) => {
      this.recalcAssets();
    });
  }

  ngOnInit(): void {
    this.recalcAssets();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  recalcAssets() {
    let theme = "lt";
    if (this.themeService.getTheme() === "dark") {
      theme = "dt";
    }
    this.imageAsset = `/assets/img/epub-will-be-soon-${theme}.png`;

    this.cdr.markForCheck();
  }
}
