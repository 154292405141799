import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Color, ColorStop, Gradient, GradientDirection, GradientType } from "@metranpage/book-data";
import { ColorConverterService, HSV, SelectValue } from "@metranpage/components";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { instanceToInstance } from "class-transformer";
import { Observable, filter, map, startWith, tap } from "rxjs";
import { CoverUiService } from "../../services/cover/cover-ui.service";
import { PopupPosition } from "../cover-color-select/cover-color-select.component";

const defaultColor = new Color(0, 0, 0, 1);
const contextMenuTimeout = 500;

@UntilDestroy()
@Component({
  selector: "m-cover-object-gradient",
  templateUrl: "./cover-object-gradient.component.html",
  styleUrls: ["./cover-object-gradient.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverObjectGradientComponent implements OnChanges, OnInit, OnDestroy {
  @Input() gradient!: Gradient;
  @Input() popupPosition: PopupPosition = "top";
  @Input() useColorPickerExternalHost = true;

  @Output() update = new EventEmitter<Gradient>();
  @Output() eyeDropper = new EventEmitter<(color: Color) => void>();

  colorStops: ColorStop[] = [];

  colorPickerVisibleIndex = -1;
  colorStopContextVisibleIndex = -1;
  contextTimeoutHandle?: number;

  gradientTypeOptions: SelectValue[] = [
    { id: "linear", value: "Linear" },
    { id: "radial", value: "Radial" },
  ];

  values$!: Observable<Partial<Gradient>>;

  readonly form = new FormGroup({
    type: new FormControl<GradientType>("linear", [Validators.required]),
    direction: new FormControl<GradientDirection>("horizontal", [Validators.required]),
  });

  constructor(
    private readonly _colorConverter: ColorConverterService,
    private readonly _changeDetector: ChangeDetectorRef,
    private readonly coverUiService: CoverUiService,
  ) {}

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        startWith(this.form.value),
        filter(() => this.form.valid),
        map((v) => <Partial<Gradient>>v),
        tap((v) => {
          if (!this.form.dirty) {
            return;
          }
          Object.assign(this.gradient, v);
          this.update.emit(this.gradient);
        }),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.colorPickerVisibleIndex = -1;
    window.setTimeout(() => {
      this.onGradient();
      this._changeDetector.markForCheck();
    }, 0);
  }

  private onGradient() {
    this.form.reset({ type: "linear", direction: "horizontal" });
    if (!this.gradient) {
      return;
    }
    const patchValue = {
      type: this.gradient.type,
      direction: this.gradient.direction,
    };
    this.form.patchValue(patchValue);
    this.colorStops = [...this.gradient.colorStops];
  }

  onClickOutside(i: number) {
    if (i === this.colorPickerVisibleIndex) {
      this.colorPickerVisibleIndex = -1;
    }
  }

  showColorStopContext(i: number) {
    if (this.colorPickerVisibleIndex !== -1) {
      this.colorStopContextVisibleIndex = -1;
      return;
    }
    this.colorStopContextVisibleIndex = i;
    if (this.contextTimeoutHandle) {
      window.clearTimeout(this.contextTimeoutHandle);
    }
  }

  hideColorStopContext(instantly = false) {
    if (instantly) {
      this.colorStopContextVisibleIndex = -1;
      return;
    }
    this.contextTimeoutHandle = window.setTimeout(() => {
      this.colorStopContextVisibleIndex = -1;
      this._changeDetector.markForCheck();
    }, contextMenuTimeout);
  }

  updateColorStop(color: Color, index: number) {
    this.gradient.colorStops.splice(index, 1, { color: color });
    this.update.emit(this.gradient);
  }

  deleteColorStop(i: number) {
    this.gradient.colorStops.splice(i, 1);
    this.colorStops.splice(i, 1);
    this.update.emit(this.gradient);
  }

  ngOnDestroy(): void {
    if (this.contextTimeoutHandle) {
      window.clearTimeout(this.contextTimeoutHandle);
    }
  }

  addColorStop() {
    this.gradient.colorStops.push({ color: defaultColor });
    this.colorStops.push({ color: defaultColor });
    this.update.emit(this.gradient);
  }
}
