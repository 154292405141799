import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SelectValue } from "@metranpage/components";
import { BrowserNotificationService } from "@metranpage/core";
import * as _ from "lodash-es";
import { Subscription } from "rxjs";
import { NotificationService } from "../../services/notification.service";

@Component({
  selector: "m-account-dev-view",
  templateUrl: "./account-dev.view.html",
  styleUrls: ["./account-dev.view.scss"],
})
export class AccountDevView implements OnInit, OnDestroy {
  protected testNotificationTypeOptions: SelectValue[];
  protected formAdminNotifications: FormGroup;

  private sub = new Subscription();

  constructor(
    private readonly browserNotificationsService: BrowserNotificationService,
    private readonly notificationService: NotificationService,
  ) {
    this.formAdminNotifications = this.createForm();
    this.testNotificationTypeOptions = this.getTestNotificationOptions();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected onEnableNotificationsClick() {
    this.browserNotificationsService.requestNotificationPermission();
  }

  protected async onTestNotificationClick() {
    const formData = this.formAdminNotifications.getRawValue();
    await this.notificationService.testNotification(formData);
  }

  private createForm() {
    return new FormGroup({
      notificationType: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  private getTestNotificationOptions(): SelectValue[] {
    return [
      {
        id: "image-moderation-success",
        value: $localize`:@@admin.account-main.buttons.notification-test.image-moderation-success.option:`,
      },
      {
        id: "image-moderation-fail",
        value: $localize`:@@admin.account-main.buttons.notification-test.image-moderation-fail.option:`,
      },
      {
        id: "cover-moderation-success",
        value: $localize`:@@admin.account-main.buttons.notification-test.cover-moderation-success.option:`,
      },
      {
        id: "cover-moderation-fail",
        value: $localize`:@@admin.account-main.buttons.notification-test.cover-moderation-fail.option:`,
      },
    ];
  }
}
