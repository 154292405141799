<div *ngIf="data.data.url; else upload" class="image-block">
  <div class="image">
    <img
      class="editor-block-content"
      [class]="data.data.size + '-' + data.data.cropClass"
      [src]="visibleUrl"
      draggable="false"
      alt="image-block"
    />
  </div>

  <m-image-sizes
    [ngClass]="{ disabled: !defaultData.isImagesSettingsAvailable }"
    [(ngModel)]="data.data.size"
    (ngModelChange)="onSelect($event)"
  ></m-image-sizes>

  <span
    *ngIf="this.data.data.size === 'fullpage'"
    class="crop-settings"
    [ngClass]="{ disabled: !defaultData.isImagesSettingsAvailable }"
  >
    <span i18n="@@editor.image.select_crop">editor.image.select_crop</span>
    <m-switch
      [isChecked]="this.data.data.cropClass === 'crop'"
      [isChecked]="this.data.data.cropClass === 'crop'"
      (onToggle)="onCropClassSelect()"
      input-style="on-panel"
    />
  </span>
</div>

<ng-template #upload>
  <ngx-file-drop
    *ngIf="!loading"
    (onFileDrop)="_dropped($event)"
    dropZoneClassName="image-upload-wrapper"
    accept=".jpg,.jpeg,.png"
    contentClassName="item"
  >
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div class="image" (click)="openFileSelector()">
        <p class="description-text">
          <span i18n="@@editor.image.upload_hint_p1">editor.image.upload_hint_p1</span>

          <span class="mark" i18n="@@editor.image.upload_hint_p2">editor.image.upload_hint_p2</span>
          <span i18n="@@editor.image.upload_hint_p3">editor.image.upload_hint_p3</span>
          <span>(.jpg,.jpeg,.png)</span>
        </p>
      </div>
    </ng-template>
  </ngx-file-drop>
  <p *ngIf="_errorText" class="upload-error">{{ _errorText }}</p>

  <div class="loading" *ngIf="loading">
    <img width="100px" src="/assets/img/spinner-128.gif" />
  </div>
</ng-template>
