import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UserRewardOneTime } from "@metranpage/core";
import { I18nService } from "@metranpage/i18n";
import { User } from "@metranpage/user-data";

@Component({
  selector: "m-account-connect-telegram-modal-view",
  templateUrl: "./account-connect-telegram-modal.view.html",
  styleUrls: ["./account-connect-telegram-modal.view.scss"],
})
export class AccountConnectTelegramModalView implements OnChanges {
  @Input()
  user?: User;
  @Input()
  userRewardOneTime?: UserRewardOneTime;

  @Output()
  onClose = new EventEmitter<void>();
  @Output()
  onConnect = new EventEmitter<void>();
  @Output()
  onSubscribeToChannel = new EventEmitter<void>();
  @Output()
  onCheckSubscribeToChannel = new EventEmitter<void>();

  constructor(
    private readonly i18nService: I18nService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.cdr.detectChanges();
  }

  protected onCloseClick() {
    this.onClose.emit();
  }

  protected onConnectClick() {
    this.onConnect.emit();
  }

  protected onSubscribeToChannelClick() {
    this.onSubscribeToChannel.emit();
  }

  protected onCheckSubscribeToChannelClick() {
    this.onCheckSubscribeToChannel.emit();
  }

  protected canCheckSubscriptionToChannel() {
    return this.user?.telegramProfile;
  }

  protected getTitle() {
    const rewardCreditsCount = this.userRewardOneTime?.reward.rewardCreditsCount;

    let reward = "";
    if (rewardCreditsCount) {
      reward = `${rewardCreditsCount} ${this.i18nService.pluralize($localize`:@@pricing.pluralize-credits:`, {
        credits: rewardCreditsCount,
      })}`;
    }

    return `${$localize`:@@user.profile.socials.telegram.modal.title-1:`} ${reward}`;
  }
}
