import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { ModerationCoverRequest } from "../../models/moderation-cover-request";

export type State = {
  moderationCoverRequests: ModerationCoverRequest[];
  moderationCoverRequestsPageCount: number;
};

@Injectable({
  providedIn: "root",
})
export class AdminModerationCoverRequestStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      moderationCoverRequests: [],
      moderationCoverRequestsPageCount: 1,
    };
  }

  getModerationCoverRequests() {
    return this.getField("moderationCoverRequests");
  }

  getModerationCoverRequestsObservable() {
    return this.getFieldObservable("moderationCoverRequests");
  }

  setModerationCoverRequests(moderationCoverRequests: ModerationCoverRequest[]) {
    this.update((state) => ({ ...state, moderationCoverRequests: moderationCoverRequests }));
  }

  getModerationCoverRequestsPageCountObservable() {
    return this.getFieldObservable("moderationCoverRequestsPageCount");
  }

  setModerationCoverRequestsPageCount(count: number) {
    this.update((state) => ({ ...state, moderationCoverRequestsPageCount: count }));
  }

  addModerationCoverRequest(moderationCoverRequest: ModerationCoverRequest) {
    this.update((state) => {
      const storeModerationCoverRequest = state.moderationCoverRequests.find(
        (mi) => mi.id === moderationCoverRequest.id,
      );
      if (storeModerationCoverRequest) {
        return state;
      }

      return {
        ...state,
        moderationCoverRequests: state.moderationCoverRequests.concat([moderationCoverRequest]),
      };
    });
  }

  addModerationCoverRequestsToStart(moderationCoverRequests: ModerationCoverRequest[]) {
    this.update((state) => {
      const newModerationCoverRequests: ModerationCoverRequest[] = [];
      for (const moderationCoverRequest of moderationCoverRequests) {
        const storeModerationCoverRequests = state.moderationCoverRequests.find(
          (mi) => mi.id === moderationCoverRequest.id,
        );
        if (storeModerationCoverRequests) {
          continue;
        }
        newModerationCoverRequests.push(moderationCoverRequest);
      }

      return {
        ...state,
        moderationCoverRequests: newModerationCoverRequests.concat(state.moderationCoverRequests),
      };
    });
  }

  addModerationCoverRequestsToEnd(moderationCoverRequests: ModerationCoverRequest[]) {
    this.update((state) => {
      const newModerationCoverRequests: ModerationCoverRequest[] = [];
      for (const moderationCoverRequest of moderationCoverRequests) {
        const storeModerationCoverRequests = state.moderationCoverRequests.find(
          (mi) => mi.id === moderationCoverRequest.id,
        );
        if (storeModerationCoverRequests) {
          continue;
        }
        newModerationCoverRequests.push(moderationCoverRequest);
      }

      return {
        ...state,
        moderationCoverRequests: state.moderationCoverRequests.concat(newModerationCoverRequests),
      };
    });
  }

  updateModerationCoverRequest(updatedModerationCoverRequest: ModerationCoverRequest) {
    const moderationCoverRequests = this.getModerationCoverRequests();
    const moderationCoverRequest = moderationCoverRequests.find((mir) => mir.id === updatedModerationCoverRequest.id);
    if (moderationCoverRequest) {
      this.update((state) => {
        const updatedModerationCoverRequests = state.moderationCoverRequests.map((mir) => {
          if (mir.id === updatedModerationCoverRequest.id) {
            return updatedModerationCoverRequest;
          }
          return mir;
        });

        return {
          ...state,
          moderationCoverRequests: updatedModerationCoverRequests,
        };
      });
    } else {
      this.addModerationCoverRequest(updatedModerationCoverRequest);
    }
  }

  clearModerationCoverRequests() {
    this.update((state) => {
      return {
        ...state,
        moderationCoverRequests: [],
        moderationCoverRequestsPageCount: 1,
      };
    });
  }
}
