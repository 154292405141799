import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { Company } from "../../models/company";

export type State = {
  company: Company | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      company: undefined,
    };
  }

  getCompanyObservable() {
    return this.getFieldObservable("company");
  }

  setCompany(company: Company) {
    this.update((state) => ({ ...state, company }));
  }
}
