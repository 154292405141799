import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { filterUndefined } from "../filterundefined";
import { RewardNotification } from "../models/rewards/reward-notification";
import { RealtimeService } from "./realtime.service";
import { RewardNotificationApi } from "./reward-notification.api";
import { RewardNotificationStore } from "./reward-notification.store";

@Injectable({
  providedIn: "root",
})
export class RewardNotificationService {
  protected soundFile?: HTMLAudioElement;

  constructor(
    private readonly rewardNotificationStore: RewardNotificationStore,
    private readonly rewardNotificationApi: RewardNotificationApi,
    realtimeService: RealtimeService,
  ) {
    realtimeService
      .getEvents<RewardNotification>("reward-notification-update")
      .pipe(filterUndefined())
      .subscribe((rewardUpdate: RewardNotification) => {
        if (rewardUpdate) {
          this.rewardNotificationStore.updateRewardNotification(rewardUpdate);

          if (!rewardUpdate.payload?.muted) {
            this.playRewardSound();
          }
        }
      });

    this.loadSound();
  }

  async getUnreadRewardsCount() {
    const unreadRewardsCount = await this.rewardNotificationApi.getUnreadRewardsCount();
    this.rewardNotificationStore.setUnreadRewardNotificationsCount(unreadRewardsCount);
  }

  async loadRewardsPaginated(page: number) {
    const notificationsData = await this.rewardNotificationApi.loadRewardPaginated(page);
    this.rewardNotificationStore.addRewardNotificationsToEnd(notificationsData.items);
    this.rewardNotificationStore.setRewardNotificationsPageCount(notificationsData.pageCount);
  }

  async markRewardsAsRead(): Promise<GeneralResultStatus> {
    try {
      const result = await this.rewardNotificationApi.markRewardsAsRead();
      // this.markStoreRewardsAsRead();
      return "success";
    } catch (error: unknown) {
      return "error";
    }
  }

  markStoreRewardsAsRead() {
    const notifications = this.rewardNotificationStore.getRewardNotifications();
    for (const n of notifications) {
      n.isShown = true;
    }
    this.rewardNotificationStore.setRewardNotifications(notifications);
    this.rewardNotificationStore.setUnreadRewardNotificationsCount(0);
  }

  loadSound() {
    this.soundFile = new Audio();
    this.soundFile.src = "./assets/sounds/reward.mp3";
    this.soundFile.load();
  }

  playRewardSound() {
    if (!this.soundFile) {
      return;
    }
    this.soundFile.play();
  }
}
