import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { CompanyPrintSettings } from "../../models/company";

export type State = {
  printSettings: CompanyPrintSettings | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyPrintSettingsStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      printSettings: undefined,
    };
  }

  getPrintSettingsObservable() {
    return this.getFieldObservable("printSettings");
  }

  setPrintSettings(settings: CompanyPrintSettings | undefined) {
    this.update((state) => ({ ...state, printSettings: settings }));
  }
}
