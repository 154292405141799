import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { User, UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";

@Component({
  selector: "m-account-dev-page",
  template: `
    <m-account-dev-view  />
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        height: 100%;
      }
    `,
  ],
})
export class AccountDevPage {
  user?: User;

  sub: Subscription = new Subscription();

  constructor(
    private readonly router: Router,
    userStore: UserStore) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );
  }

  ngOnInit(): void {
    if (!this.user || !this.user.isAdmin) {
      this.router.navigate(["/"]);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
