<div *ngIf="isExpanded" class="content" (mouseleave)="onMouseLeave()" @slideInOutSidebarLTR>
  <div class="sidebar-content">
    <m-books-markup-sidebar-toc-settings *ngIf="form && isTocSettingsEnable" [form]="form" />

    <div *ngIf="isTocSettingsEnable" class="divider"></div>

    <div class="headings">
      <div
        *ngFor="let heading of headings; let i = index; trackBy: trackByHeadingId"
        class="heading"
        [class.active]="isHeadingActive(i)"
      >
        <div class="cursor"></div>
        <div class="level" [class]="'level' + heading.level" (click)="headingClick(heading, i)">{{heading.text}}</div>
      </div>
    </div>
  </div>
</div>

<div class="header" (click)="toggle()">
  <m-icon
    src="bc-arrow-square.svg"
    class="header-icon"
    size="original"
    color="--color-font-main"
    [class.pointing-left]="isExpanded"
    [class.pointing-right]="!isExpanded"
  ></m-icon>

  <div class="divider"></div>

  <span class="header-text" i18n="@@books.styles.sidebar-toc-header">books.styles.sidebar-toc-header</span>
</div>
