import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BookCoverTemplate } from "@metranpage/book-data";
import { User, UserStore } from "@metranpage/user-data";
import { Observable, combineLatest, filter, map, tap } from "rxjs";
import { CoverTemplateService } from "../../services/cover/cover-template.service";
import { CoverTemplateStore } from "../../services/cover/cover-template.store";

type BookCoverTemplateExt = BookCoverTemplate & { previewUrl: string };

@Component({
  selector: "m-cover-template-list",
  templateUrl: "./cover-template-list.component.html",
  styleUrls: ["./cover-template-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverTemplateListComponent implements OnInit {
  @Output() apply = new EventEmitter<BookCoverTemplate>();
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter();

  data$!: Observable<{
    templates: BookCoverTemplateExt[];
    activeTemplate: BookCoverTemplateExt | undefined;
  }>;

  user$!: Observable<User | undefined>;

  constructor(
    private readonly coverTemplateService: CoverTemplateService,
    private readonly coverTemplateStore: CoverTemplateStore,
    userStore: UserStore,
  ) {
    const templates$ = coverTemplateStore.getTemplatesObservable().pipe(
      map((v) =>
        v.map((i) => {
          return <BookCoverTemplateExt>{
            ...i,
            previewUrl: coverTemplateService.getTemplatePreviewImageUrl(i.id!),
          };
        }),
      ),
      map((v) => {
        v.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime());
        return v;
      }),
    );

    const activeTemplate$ = coverTemplateStore.getActiveTemplateObservable().pipe(
      map((v) => {
        if (v) {
          return <BookCoverTemplateExt>{
            ...v,
            previewUrl: coverTemplateService.getTemplatePreviewImageUrl(v.id!),
          };
        }
        return v;
      }),
    );

    this.data$ = combineLatest([templates$, activeTemplate$]).pipe(
      map(([t, a]) => ({ templates: t, activeTemplate: a })),
    );

    this.user$ = userStore.getUserObservable();
  }

  async ngOnInit() {
    const templates = await this.coverTemplateService.getTemplates();
    this.coverTemplateStore.setTemplates(templates);
  }

  onApply(template: BookCoverTemplateExt) {
    this.coverTemplateStore.setActiveTemplate(template);
    this.apply.emit(template);
  }

  onClose() {
    this.close.emit();
  }

  onSave() {
    this.save.emit();
  }

  async delete(template: BookCoverTemplate) {
    await this.coverTemplateService.deleteTemplate(template.id!);
    this.coverTemplateStore.deleteTemplate(template);
  }
}
