import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Breadcrumb } from "@metranpage/core";

@Injectable({
  providedIn: "root",
})
export class HomeBreadcrumbsResolver {
  async resolve(route: ActivatedRouteSnapshot): Promise<Breadcrumb[]> {
    const bcHome: Breadcrumb = {
      title: $localize`:@@home.breadcrumb.home-page:`,
      url: "",
      isEnabled: true,
      isVisible: true,
    };

    return [bcHome];
  }
}
