import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export const FakeCursorClassName = "fake-cursor";
export const FakeCursorNode = (() => {
  const cursor = document.createElement("span");
  cursor.className = FakeCursorClassName;
  return cursor;
})();
export const FakeCursorItemAsText = FakeCursorNode.outerHTML;

export type PromptTextSelectionState = {
  range: Range | undefined;
};

@Injectable({
  providedIn: "root",
})
export class PromptTextSelectionService {
  selectionState$ = new BehaviorSubject<PromptTextSelectionState>({
    range: undefined,
  });

  onChangeSelectionState(selectionState: PromptTextSelectionState) {
    this.selectionState$.next(selectionState);
  }
}
