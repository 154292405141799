import { CommonModule, NgClass } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";

export type TextAreaSize = "xs" | "s" | "m" | "l";
export type TextAreaTextAlign = "left" | "center" | "right" | "leftJustify";

@Component({
  selector: "m-textarea",
  templateUrl: "./text-area.component.html",
  styleUrls: ["./text-area.component.scss"],
  standalone: true,
  imports: [CommonModule, NgClass, ReactiveFormsModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent implements ControlValueAccessor, AfterViewInit {
  @Input()
  rows = 5;
  @Input()
  placeholder = "";
  @Input()
  isRequired = false;
  @Input("text-align")
  textAlign: TextAreaTextAlign = "left";
  @Input()
  name = "";
  // @Input("size")
  // inputSize: TextAreaSize = "m";
  @Input()
  tabIndex = 0;
  @Input()
  maxlength = 500;
  @Input()
  resizable = false;
  @Input()
  autoHeight = false;
  @Input("is-highlighted")
  isHighlighted = false;
  @Input()
  isCharactersCountVisible = false;

  @Output()
  focus = new EventEmitter<void>();
  @Output()
  focusout = new EventEmitter<void>();

  protected isInputFocused = false;
  protected isPasswordVisible = false;
  protected isDisabled = false;
  protected value = "";

  protected initialHeight = "10px";

  protected charactersCountText = "";

  @ViewChild("input", { static: true, read: ElementRef })
  protected inputElementRef?: ElementRef;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    const h = this.inputElementRef?.nativeElement.offsetHeight || 10;
    this.initialHeight = `${h}px`;
  }

  writeValue(value: string): void {
    if (!value) {
      value = "";
    }
    value = value?.replaceAll("<br>", "\n");

    this.inputElementRef!.nativeElement.value = value;

    this.updateCharactersCountText(value);
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInputChange(event: Event) {
    const target = event.target! as HTMLInputElement;
    let value = target.value;

    value = value.replaceAll("\n", "<br>");

    if (this.autoHeight) {
      this.autoGrow(target);
    }

    this.updateCharactersCountText(value);

    this.onChange(value);
  }

  /**
   * Return values available here:
   *  https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  protected getAutocomplete(): string {
    return "random-string-as-off-value-not-work";
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    // result.push(`size-${this.inputSize}`);

    result.push(`text-align-${this.textAlign}`);

    if (this.isInputFocused) {
      result.push("focused");
    }
    if (this.isHighlighted) {
      result.push("highlighted");
    }
    if (this.isDisabled) {
      result.push("disabled");
    }

    return result;
  }

  protected onInputFocus() {
    this.isInputFocused = true;
    this.onTouched();

    this.focus.emit();
  }

  protected onInputBlur() {
    this.isInputFocused = false;

    this.focusout.emit();
  }

  addNewLineTag(text: string, token: string) {
    return text.replaceAll(new RegExp(token, "g"), `${token} <br>`);
  }

  protected autoGrow(element: HTMLInputElement) {
    element.style.height = this.initialHeight;
    element.style.height = `${element.scrollHeight}px`;
  }

  updateCharactersCountText(text: string) {
    if (!this.isCharactersCountVisible) {
      return;
    }

    const charactersCount = text.trim().length;

    this.charactersCountText = `${charactersCount} / ${this.maxlength}`;
  }
}
