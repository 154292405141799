import { AfterViewInit, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsPopUpService } from "@metranpage/core";
import { UserService } from "../../services/user.service";

@Component({
  selector: "m-account-verification-check-token-page",
  template: `
    <m-loading-spinner></m-loading-spinner>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100vw;
        height: 100vh;
      }
    `,
  ],
})
export class AccountVerificationCheckTokenPage implements AfterViewInit {
  constructor(
    private readonly userService: UserService,
    private readonly notificationsService: NotificationsPopUpService,
    private route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  async ngAfterViewInit() {
    const token = this.route.snapshot.params["token"];
    const result = await this.userService.verifyEmail(token);
    if (result === "success") {
      this.notificationsService.notify({ content: $localize`:@@user.verify-email-success:`, type: "success" });
      this.router.navigate(["/"]);
    } else {
      this.notificationsService.error($localize`:@@user.verify-error:`);
      this.router.navigate(["/profile"]);
    }
  }
}
