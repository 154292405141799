<div #infoImg class="info-img">
  <m-icon [src]="getIconAsset()!" size="s" color="--color-font-main" />
</div>
<div class="info">
  <div class="title">
    <span>{{ title }}</span>
  </div>
  <div class="description">
    <m-localized-text [text]="description" />
    <ng-content></ng-content>
  </div>
</div>
