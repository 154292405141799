import { CommonModule, NgClass } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { AVAILABLE_LANGUAGES, LangSpec } from "@metranpage/i18n";
import { AngularSvgIconModule } from "angular-svg-icon";
import { IconComponent } from "../icon/icon.component";

@Component({
  selector: "m-lang-select",
  template: `
    <div class="languages-menu" [ngClass]="[selectStyle]">
      <button class="languages-selected-button" (click)="toggleLanguageMenu()">
        <m-icon [src]="currentLanguage!.iconSrc" [size]="getIconSize()" />
        <m-icon src="dropdown-arrow-down.svg" size="xs" color="--color-font-main" />
      </button>

      <ul class="languages-menu-list" *ngIf="isLanguageMenuOpened">
        <li
          *ngFor="let language of languages"
          [ngClass]="{ selected: language.value === userLanguage }"
          (click)="onLanguageChange(language.value)"
        >
          <m-icon [src]="language.iconSrc" [size]="getIconSize()" />
        </li>
      </ul>
    </div>
  `,
  styles: [
    `
      .languages-menu.large {
        position: relative;

        .languages-selected-button {
          display: flex;
          width: 70px;
          height: 40px;
          align-items: center;
          justify-content: space-between;
          padding: 5px;
          border-radius: 4px;
          background-color: var(--color-bg-input-on-popup);
          cursor: pointer;
        }

        .languages-menu-list {
          z-index: 1;
          position: absolute;
          display: block;
          background: var(--color-bg-select-language-dropdown-menu);
          border: 1px solid var(--color-bg-elevated-hover);
          list-style: none;
          transform: translateY(100%);
          bottom: -6px;
          left: 0;
          right: 0;
          border-radius: var(--border-radius-s);

          li {
            width: 100%;
            border-radius: var(--border-radius-s);
            &:hover {
              background: var(--color-bg-elevated-hover);
            }
          }
        }
      }

      .languages-menu.small {
        position: relative;

        .languages-selected-button {
          display: flex;
          width: 51px;
          height: 25px;
          align-items: center;
          justify-content: space-between;
          padding: 0 5px;
          border-radius: var(--border-radius-s);
          background-color: var(--color-bg-select-language-dropdown-menu);
          cursor: pointer;
        }

        .languages-menu-list {
          position: absolute;
          display: block;
          background: var(--color-bg-select-language-dropdown-menu);
          border: 1px solid var(--color-bg-elevated-hover);
          list-style: none;
          transform: translateY(100%);
          bottom: -6px;
          left: 0;
          right: 0;
          border-radius: var(--border-radius-s);

          li {
            width: 100%;
            border-radius: var(--border-radius-s);
            &:hover {
              background: var(--color-bg-elevated-hover);
            }
          }
        }
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, NgClass, ReactiveFormsModule, AngularSvgIconModule, IconComponent],
})
export class LangSelectComponent implements OnInit {
  @Input()
  userLanguage?: string;
  @Input("select-style")
  selectStyle: "large" | "small" = "small";
  @Output()
  changeLang = new EventEmitter<string>();

  protected isLanguageMenuOpened = false;
  protected currentLanguage: LangSpec | undefined;

  constructor(@Inject(AVAILABLE_LANGUAGES) protected readonly languages: LangSpec[]) {}

  ngOnInit(): void {
    this.currentLanguage = this.languages.find((l) => {
      return l.value === this.userLanguage;
    })!;

    if (!this.currentLanguage) {
      this.currentLanguage = this.languages[0];
    }
  }

  protected toggleLanguageMenu() {
    this.isLanguageMenuOpened = !this.isLanguageMenuOpened;
  }

  protected closeLanguageMenu() {
    this.isLanguageMenuOpened = false;
  }

  protected onLanguageChange(value: string) {
    this.changeLang.emit(value);
  }

  protected getIconSize() {
    return this.selectStyle === "small" ? "s" : "m";
  }
}
