<form [formGroup]="form" (keydown)="onKeyDown($event)">
  <m-ng-input-with-icon
    class="email"
    type="email"
    iconLeft="input-email.svg"
    name="email"
    i18n-placeholder="@@user.auth.email-placeholder"
    placeholder="@@user.auth.email-placeholder"
    formControlName="email"
  />

  <m-ng-input-password
    class="password"
    [withLeftIcon]="true"
    name="password"
    i18n-placeholder="@@user.auth.password-placeholder"
    placeholder="@@user.auth.password-placeholder"
    formControlName="password"
  />

  <m-button
    class="login-button"
    button-style="primary"
    size="full-width"
    i18n="@@user.auth.login-button"
    (onClick)="login()"
  >
    user.auth.login-button
  </m-button>
</form>
