<div class="container">
  <div class="header">
    <div class="title" i18n="@@cover-editor.conceptual-assistant.menu.title">
      cover-editor.conceptual-assistant.menu.title
    </div>

    <div class="close-icon" (click)="onCloseConceptualAssistantClick()">
      <m-ng-icon src="close-cross.svg" [size]="12" />
    </div>
  </div>

  <div class="divider-hor"></div>

  <div *ngIf="!coverConceptualGeneration.isProcessing; else isProcessing" class="concept-result">
    <m-cover-conceptual-assistant-generation-menu-section
      title="cover-editor.conceptual-assistant.result.theme.title"
      i18n-title="@@cover-editor.conceptual-assistant.result.theme.title"
      iconSrc="cover-concept-theme.svg"
      [canCollapsing]="false"
    >
      <span class="text">{{ coverConceptualGeneration.theme }}</span>
    </m-cover-conceptual-assistant-generation-menu-section>

    <div class="divider-hor"></div>

    <m-cover-conceptual-assistant-generation-menu-section
      title="cover-editor.conceptual-assistant.result.genre.title"
      i18n-title="@@cover-editor.conceptual-assistant.result.genre.title"
      iconSrc="cover-concept-genre.svg"
      [canCollapsing]="false"
    >
      <span class="text">{{ coverConceptualGeneration.genre }}</span>
    </m-cover-conceptual-assistant-generation-menu-section>

    <div class="divider-hor"></div>

    <m-cover-conceptual-assistant-generation-menu-section
      title="cover-editor.conceptual-assistant.result.target-audience.title"
      i18n-title="@@cover-editor.conceptual-assistant.result.target-audience.title"
      iconSrc="cover-concept-target-audience.svg"
      [canCollapsing]="false"
    >
      <span class="text">{{ coverConceptualGeneration.au }}</span>
    </m-cover-conceptual-assistant-generation-menu-section>

    <div class="divider-hor"></div>

    <m-cover-conceptual-assistant-generation-menu-section
      title="cover-editor.conceptual-assistant.result.prompt.title"
      i18n-title="@@cover-editor.conceptual-assistant.result.prompt.title"
      iconSrc="cover-concept-prompt.svg"
      [canCollapsing]="true"
    >
      <div class="image-generation-section">
        <div class="prompts-group">
          <m-radio
            *ngFor="let prompt of coverConceptualGeneration.prompts; let i = index"
            [value]="i"
            [isChecked]="selectedPromptIndex === i"
            (onSelect)="onSelectPrompt($event)"
          >
            <span class="prompt-text">{{ prompt }}</span>
          </m-radio>
        </div>

        <div class="image-generation-styles-section">
          <div class="sub-header" i18n="@@cover-editor.conceptual-assistant.result.prompt.image-style.title">
            cover-editor.conceptual-assistant.result.prompt.image-style.title
          </div>

          <div class="image-generation-styles-select-group">
            <div class="image-generation-styles-select-subgroup-1">
              <div class="image-generation-styles-group">
                <m-cover-conceptual-assistant-generation-result-style-card
                  *ngIf="picStyle1"
                  [style]="picStyle1"
                  [isActive]="isStyleActive(picStyle1Id, picStyle1Mode)"
                  (click)="onSelectStyleClick(picStyle1Id, picStyle1Mode, picStyle1Comment, picStyle1Mood, picStyle1Age, picStyle1ColorBackground, picStyle1ColorBackgroundHex, picStyle1ColorAccent, picStyle1ColorAccentHex)"
                />
                <m-cover-conceptual-assistant-generation-result-style-card
                  *ngIf="picStyle2"
                  [style]="picStyle2"
                  [isActive]="isStyleActive(picStyle2Id, picStyle2Mode)"
                  (click)="onSelectStyleClick(picStyle2Id, picStyle2Mode, picStyle2Comment, picStyle2Mood, picStyle2Age, picStyle2ColorBackground, picStyle2ColorBackgroundHex, picStyle2ColorAccent, picStyle2ColorAccentHex)"
                />
                <m-cover-conceptual-assistant-generation-result-style-card
                  *ngIf="picStyle3"
                  [style]="picStyle3"
                  [isActive]="isStyleActive(picStyle3Id, picStyle3Mode)"
                  (click)="onSelectStyleClick(picStyle3Id, picStyle3Mode, picStyle3Comment, picStyle3Mood, picStyle3Age, picStyle3ColorBackground, picStyle3ColorBackgroundHex, picStyle3ColorAccent, picStyle3ColorAccentHex)"
                />
              </div>

              <div *ngIf="selectedStyle && selectedStyle.style" class="image-generation-selected-style-data">
                <div class="image-generation-selected-style-title">{{ selectedStyle.style.title }}</div>
                <div *ngIf="selectedStyle.comment" class="image-generation-selected-style-comment">
                  {{ selectedStyle.comment }}
                </div>
              </div>
            </div>

            <div *ngIf="selectedStyle && selectedStyle.style" class="image-generation-styles-select-subgroup-2">
              <div *ngIf="selectedStyle.mood" class="image-generation-selected-style-row-group">
                <span
                  class="image-generation-selected-style-row-group-title"
                  i18n="@@cover-editor.conceptual-assistant.result.prompt.image-mood.title"
                >
                  cover-editor.conceptual-assistant.result.prompt.image-mood.title
                </span>
                <span class="image-generation-selected-style-row-group-text">{{ selectedStyle.mood }}</span>
              </div>
              <!-- <div *ngIf="selectedStyle.age" class="image-generation-selected-style-row-group">
                <span
                  class="image-generation-selected-style-row-group-title"
                  i18n="@@cover-editor.conceptual-assistant.result.prompt.image-age.title"
                >
                  cover-editor.conceptual-assistant.result.prompt.image-age.title
                </span>
                <span class="image-generation-selected-style-row-group-text">{{ selectedStyle.age }}</span>
              </div> -->

              <div *ngIf="selectedStyle.colorBackgroundHex" class="image-generation-selected-style-row-group">
                <span
                  class="image-generation-selected-style-row-group-title"
                  i18n="@@cover-editor.conceptual-assistant.result.prompt.image-style.color.background"
                >
                  cover-editor.conceptual-assistant.result.prompt.image-style.color.background
                </span>
                <div
                  class="image-generation-selected-style-color"
                  [ngStyle]="{'background-color': selectedStyle.colorBackgroundHex}"
                ></div>
              </div>
              <div *ngIf="selectedStyle.colorAccentHex" class="image-generation-selected-style-row-group">
                <span
                  class="image-generation-selected-style-row-group-title"
                  i18n="@@cover-editor.conceptual-assistant.result.prompt.image-style.color.accent"
                >
                  cover-editor.conceptual-assistant.result.prompt.image-style.color.accent
                </span>
                <div
                  class="image-generation-selected-style-color"
                  [ngStyle]="{'background-color': selectedStyle.colorAccentHex}"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <m-button
          button-style="primary"
          size="full-width"
          icon="stars.svg"
          icon-position="left"
          i18n="@@cover-editor.conceptual-assistant.result.prompt.action-button"
          [is-disabled]="isGenerateImageDisable()"
          (onClick)="onGenerateImageClick()"
        >
          cover-editor.conceptual-assistant.result.prompt.action-button
        </m-button>
      </div>
    </m-cover-conceptual-assistant-generation-menu-section>

    <div class="divider-hor"></div>

    <m-cover-conceptual-assistant-generation-menu-section
      title="cover-editor.conceptual-assistant.result.fonts-and-colors.title"
      i18n-title="@@cover-editor.conceptual-assistant.result.fonts-and-colors.title"
      iconSrc="cover-concept-fonts-and-colors.svg"
      [canCollapsing]="true"
    >
      <div class="fonts-and-colors-section">
        <div class="fonts-and-colors-group">
          <m-cover-conceptual-assistant-generation-result-font-card
            *ngIf="fontMainFamilyName"
            [familyName]="fontMainFamilyName"
            [color]="fontMainColor"
            [comment]="fontMainComment"
            fontStyle="main"
          />
          <m-cover-conceptual-assistant-generation-result-font-card
            *ngIf="fontSubFamilyName"
            [familyName]="fontSubFamilyName"
            [color]="fontSubColor"
            [comment]="fontSubComment"
            fontStyle="subheading"
          />
          <m-cover-conceptual-assistant-generation-result-font-card
            *ngIf="fontSecFamilyName"
            [familyName]="fontSecFamilyName"
            [color]="fontSecColor"
            [comment]="fontSecComment"
            fontStyle="secondary"
          />
        </div>

        <m-button
          button-style="primary"
          size="full-width"
          icon="checkmark-02.svg"
          icon-position="left"
          i18n="@@cover-editor.conceptual-assistant.result.fonts-and-colors.action-button"
          [is-disabled]="isApplyFontAndColorDisable()"
          (onClick)="onApplyFontAndColorClick()"
        >
          cover-editor.conceptual-assistant.result.fonts-and-colors.action-button
        </m-button>
      </div>
    </m-cover-conceptual-assistant-generation-menu-section>

    <div class="divider-hor"></div>

    <m-cover-conceptual-assistant-generation-menu-section
      title="cover-editor.conceptual-assistant.result.summary.title"
      i18n-title="@@cover-editor.conceptual-assistant.result.summary.title"
      iconSrc="cover-concept-summary.svg"
      [canCollapsing]="true"
    >
      <span class="text">{{ coverConceptualGeneration.summary }}</span>
    </m-cover-conceptual-assistant-generation-menu-section>
  </div>

  <div *ngIf="!coverConceptualGeneration.isProcessing" class="button">
    <m-button
      button-style="secondary"
      size="full-width"
      icon="button-back.svg"
      icon-position="left"
      i18n="@@cover-editor.conceptual-assistant.result.reset-button"
      (onClick)="onBackClick()"
    >
      cover-editor.conceptual-assistant.result.reset-button
    </m-button>
  </div>
</div>

<ng-template #isProcessing>
  <div class="processing">
    <div class="spinner-24"></div>
    <div class="timer-text" i18n="@@cover-editor.conceptual-assistant.result.processing">
      cover-editor.conceptual-assistant.result.processing
    </div>
  </div>
</ng-template>
