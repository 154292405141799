import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import {
  ReferalLink,
  ReferalLinkCreateData,
  ReferalLinkData,
  ReferalLinkUpdateData,
  ReferalLinksData,
} from "../../models/referal-link";
import { ReferalLinkStat } from "../admin.api";

export type ReferalLinksFilter = {
  token?: string;
};

@Injectable({
  providedIn: "root",
})
export class AdminReferalLinksApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async createReferalLink(data: ReferalLinkCreateData) {
    return this.post<{ id: number }>("admin/referals/", data);
  }

  async getReferalLink(referalLinkId: number) {
    return this.get<ReferalLinkData>(`admin/referals/${referalLinkId}`);
  }

  async getReferalLinks() {
    return this.get<ReferalLink[]>("admin/referals");
  }

  async getReferalLinksPaginated(page: number, filters: ReferalLinksFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);

    return this.get<ReferalLinksData>("admin/referals/paginated", {
      page,
      ...clearedFilters,
    });
  }

  async updateReferalLink(referalLinkId: number, referalLinkData: ReferalLinkUpdateData) {
    return this.post<ReferalLink>(`admin/referals/${referalLinkId}`, {
      id: referalLinkId,
      ...referalLinkData,
    });
  }

  async deleteReferalLink(referalLinkId: number) {
    return this.delete(`admin/referals/${referalLinkId}`);
  }

  async updateReferalLinkStat(referalLinkId: number) {
    return this.get<ReferalLinkStat[]>(`admin/referals/${referalLinkId}/stat`);
  }
}
