<m-modal-backdrop
  [closeButtonVisible]="closeButtonVisible"
  [closeOnBackDropClick]="closeOnBackDropClick"
  (close)="onCloseClick()"
>
  <m-ng-island [paddingSize]="24">
    <form *ngIf="form" [formGroup]="form" class="container">
      <div class="header-group">
        <div class="title" i18n="@@style-reference-upload-modal.title">style-reference-upload-modal.title</div>

        <m-localized-text
          class="description"
          text="style-reference-upload-modal.description"
          i18n-text="@@style-reference-upload-modal.description"
        />
      </div>

      <div class="file-upload">
        <m-file-drop
          #filedrop
          accept="image/png, image/jpeg"
          [multiple]="false"
          (dropped)="dropped($event)"
          style="--custom-height: 100%"
          [ngClass]="{ 'borderless': !!file || !!styleReference }"
        >
          <div *ngIf="!imageUrl" class="file-drop-container">
            <div class="drop-placeholder">
              <m-icon src="upload-2.svg" size="original" color="--color-font-main"></m-icon>

              <div>
                <div class="description-text">
                  <ng-container i18n="@@style-reference-upload-modal.upload-button.text-1">
                    style-reference-upload-modal.upload-button.text-11
                  </ng-container>
                </div>
                <div class="description-text">
                  <ng-container i18n="@@style-reference-upload-modal.upload-button.text-2">
                    style-reference-upload-modal.upload-button.text-2
                  </ng-container>
                  <span
                    class="mark"
                    (click)="openFileSelector()"
                    i18n="@@style-reference-upload-modal.upload-button.text-2-upload"
                  >
                    style-reference-upload-modal.upload-button.text-2-upload
                  </span>
                  <ng-container i18n="@@style-reference-upload-modal.upload-button.text-3">
                    style-reference-upload-modal.upload-button.text-3
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="imageUrl"
            class="preview"
            [ngClass]="{ hovered: isPreviewHovered }"
            (dragover)="onDragOver($event)"
            (mouseenter)="onMouseEnter($event)"
            (mouseleave)="onMouseLeave($event)"
          >
            <img [src]="imageUrl" />

            <div class="upload-another">
              <m-icon src="upload-2.svg" size="original" color="--color-font-main"></m-icon>

              <div>
                <div class="description-text">
                  <span
                    class="mark"
                    (click)="openFileSelector()"
                    i18n="@@style-reference-upload-modal.upload-another-button.text-1-upload"
                  >
                    style-reference-upload-modal.upload-another-button.text-1-upload
                  </span>
                  <ng-container i18n="@@style-reference-upload-modal.upload-another-button.text-2">
                    style-reference-upload-modal.upload-another-button.text-2
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </m-file-drop>
      </div>

      <div *ngIf="!!file || !!styleReference" class="control">
        <span i18n="@@style-reference-upload-modal.style-repeatability.title">
          style-reference-upload-modal.style-repeatability.title
        </span>

        <m-slider-selector formControlName="strengthType" [points]="strengthTypePoints" />
      </div>

      <m-button
        button-style="primary"
        size="full-width"
        icon="done.svg"
        icon-position="left"
        [is-disabled]="!isCreateStyleButtonActive()"
        (onClick)="onCreateStyleClick()"
        i18n="@@style-reference-upload-modal.apply-button"
      >
        style-reference-upload-modal.apply-button
      </m-button>
    </form>
  </m-ng-island>
</m-modal-backdrop>
