import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FileDropComponent } from "@metranpage/core";
import { NgxFileDropEntry } from "ngx-file-drop";
import { NonEditableTemplateCreateDataWithFile } from "../../models/non-editable-template";
import { AdminNonEditableTemplatesService } from "../../services/non-editable-templates/non-editable-templates.service";

@Component({
  selector: "m-admin-add-non-editable-template",
  templateUrl: "./add-non-editable-template.view.html",
  styleUrls: ["./add-non-editable-template.view.scss"],
})
export class AddNonEditableTemplateView {
  @Output()
  onNonEditableTemplateAdded = new EventEmitter<NonEditableTemplateCreateDataWithFile>();

  @ViewChild("filedrop", { static: false })
  protected fileDropComponent!: FileDropComponent;

  protected archiveFile?: File;

  protected form!: FormGroup;

  constructor(private readonly adminNonEditableTemplatesService: AdminNonEditableTemplatesService) {
    this.createForm();
  }

  createForm() {
    this.form = this.adminNonEditableTemplatesService.createNonEditableTemplateForm();
  }

  onCreateNonEditableTemplate() {
    if (!this.archiveFile) {
      return;
    }

    const formData = this.form.getRawValue();
    formData.archiveFile = this.archiveFile;
    this.onNonEditableTemplateAdded.emit(formData);
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) => {
        this.archiveFile = file;
      });
    }
  }

  openFileSelector() {
    this.fileDropComponent.openFileSelector();
  }

  async deleteArchiveFile() {
    this.archiveFile = undefined;
  }
}
