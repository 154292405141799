<m-modal-backdrop
  [closeButtonVisible]="closeButtonVisible"
  [closeOnBackDropClick]="closeOnBackDropClick"
  (close)="onCloseClick()"
>
  <div class="container">
    <div class="modal-title" i18n="@@home.select-new-project-type.title">home.select-new-project-type.title</div>
    <m-ng-island>
      <div class="buttons" (click)="onSelectClick('cover')">
        <div class="button">
          <div class="icon-wrapper">
            <img [src]="'/assets/img/' + coverImg" />
          </div>
          <div class="button-content">
            <div class="title" i18n="@@home.select-new-project-type.create-cover.title">
              home.select-new-project-type.create-cover.title
            </div>
            <m-localized-text
              class="description"
              text="home.select-new-project-type.create-cover.description"
              i18n-text="@@home.select-new-project-type.create-cover.description"
            />
          </div>
        </div>

        <div class="button" (click)="onSelectClick('book')">
          <div class="icon-wrapper">
            <img [src]="'/assets/img/' + bookImg" />
          </div>
          <div class="button-content">
            <div class="title" i18n="@@home.select-new-project-type.create-book.title">
              home.select-new-project-type.create-book.title
            </div>
            <m-localized-text
              class="description"
              text="home.select-new-project-type.create-book.description"
              i18n-text="@@home.select-new-project-type.create-book.description"
            />
          </div>
        </div>
      </div>
    </m-ng-island>
  </div>
</m-modal-backdrop>
