import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CoreModule } from "@metranpage/core";

@NgModule({
  imports: [CommonModule, FormsModule, CoreModule, NgOptimizedImage, RouterModule],
  providers: [],
  declarations: [],
  exports: [],
})
export class TextGenerationModule {}
