import { Directive, ElementRef, EventEmitter, HostListener, Inject, Output } from "@angular/core";

@Directive({
  selector: "[clickoutsidedetector]",
})
export class ClickOutsideDetectorDirective {
  @Output() clickOutside = new EventEmitter<Event>();

  constructor(@Inject(ElementRef) private readonly elementRef: ElementRef) {}

  @HostListener("document:click", ["$event"])
  onClickOutside(event: Event) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      // click in component
    } else {
      this.clickOutside.emit(event);
    }
  }
}
