<div class="container">
  <div class="sidebar-left">
    <m-last-project *ngIf="user" [projects]="projects" (onAddProjectClick)="showNewProjectModal()" />

    <m-home-menu *ngIf="user" [user]="user" />
    <m-login-sidebar-menu *ngIf="!user" (login)="redirectToLoginPage()" />

    <m-socials *ngIf="socials.length > 0" [socials]="socials" />
  </div>

  <router-outlet></router-outlet>
</div>

<m-info-modal
  *ngIf="isChangeTariffModalVisible"
  [title]="getInfoModalTitle()"
  [text]="getInfoModalText()"
  actionText="books.info-modal.projects-count-limit.action-text"
  i18n-actionText="@@books.info-modal.projects-count-limit.action-text"
  [backgroundImage]="'/assets/img/info-modal-projects-count-limit.png'"
  (actionClick)="onClickChangeTariffModal()"
  (close)="onCloseChangeTariffModal()"
/>

<m-login-modal *ngIf="isLoginModalVisible" (login)="redirectToLoginPage()" (close)="closeLoginModal()" />
