import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ImageObject, ObjectsAlignment } from "@metranpage/book-data";
import { FabulGenerationMode, FabulaImageGenerationService, ImageGenerationPrices } from "@metranpage/image-generation";
import { UserBalance } from "@metranpage/user-data";

@Component({
  selector: "m-cover-image-object-settings",
  templateUrl: "./cover-image-object-settings.component.html",
  styleUrls: ["./cover-image-object-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverImageObjectSettingsComponent {
  @Input() currentObject!: ImageObject;

  @Input()
  prices?: ImageGenerationPrices;
  @Input()
  balance?: UserBalance;

  @Output() align = new EventEmitter<ObjectsAlignment>();
  @Output() removeBackground = new EventEmitter<ImageObject>();

  constructor(private readonly fabulaImageGenerationService: FabulaImageGenerationService) {}

  onAlign(alignment: ObjectsAlignment) {
    this.align.emit(alignment);
  }

  onRemoveBackground() {
    this.removeBackground.emit(this.currentObject);
  }

  protected isRemoveBackgroundDisable() {
    return false;
  }

  isEnoughtTokens(mode: FabulGenerationMode): boolean {
    return this.fabulaImageGenerationService.isEnoughtTokens(this.prices, this.balance, mode);
  }

  getPrice(mode: FabulGenerationMode) {
    if (!this.prices) {
      return;
    }

    if (mode === "nobg") {
      return this.prices.fabulaRemoveBackground;
    }

    return;
  }
}
