<m-modal (close)="onCancel.emit()" position="center">
  <div class="container">
    <div class="text-block">
      <div class="modal-header" i18n="@@books.book.delete-dialog-confirmation">
        books.book.delete-dialog-confirmation
      </div>
      <div class="modal-text">
        <span i18n="@@books.book.delete-dialog-title">books.book.delete-dialog-title</span>
        <span>«{{ projectTitle }}»</span>
        <span>?</span>
      </div>
    </div>

    <div class="modal-buttons">
      <m-button button-style="tertiary" (onClick)="onCancel.emit()" i18n="@@dialog.cancel-button">
        dialog.cancel-button
      </m-button>
      <m-button button-style="primary" (onClick)="onDelete.emit()" i18n="@@books.book.delete-project-dialog-button">
        books.book.delete-project-dialog-button
      </m-button>
    </div>
  </div>
</m-modal>
