import { Injectable } from "@angular/core";
import { SocialNetworkCoversStore } from "./social-network-covers.store";
import { SocialNetworkCoversApiService } from "./social-network-covers.api";
import { ApiErrorHandlerService } from "@metranpage/core";
import { UpdateSNCoversData } from "../../models/social-network-covers";

@Injectable({
  providedIn: 'root'
})

export class SocialNetworkCoversService {
  constructor(private socialNetworkCoversApiService: SocialNetworkCoversApiService, private socialNetworkCoversStore: SocialNetworkCoversStore, private apiErrorHandlerService: ApiErrorHandlerService) {

  }

  async getSNCovers(): Promise<void> {
    const covers = await this.socialNetworkCoversApiService.getSNCovers();
    this.socialNetworkCoversStore.updateCoversState(covers);
  }

  async updateSNCoverStates(data: UpdateSNCoversData): Promise<string> {
    try {
      const response = await this.socialNetworkCoversApiService.updateSNCoverStates(data);

      this.socialNetworkCoversStore.updateCoverState(response);
      return "success";
    } catch (errorResponse: any) {
      this.apiErrorHandlerService.handleApiError(errorResponse);
      return "error";
    }
  }
}
