import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService, LoadingState } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { ConfigService } from "@metranpage/core-interfaces";
import { I18nService } from "@metranpage/i18n";
import { Theme, ThemeService } from "@metranpage/theme";
import { Observable } from "rxjs";
import { LoginError } from "../../models/login-errors";
import { AdvertisementActionService } from "../../services/advertisement-action.service";
import { AuthService } from "../../services/auth.service";
import { RegistrationData } from "../../views/registration/registration.view";

@Component({
  selector: "m-registration-page",
  templateUrl: "./registration.page.html",
  styleUrls: ["./registration.page.scss"],
})
export class RegistrationPage implements OnInit, OnDestroy {
  loadingState$: Observable<LoadingState>;

  loginErrors: LoginError[] = [];
  protected userLanguage;

  protected referalToken = "";

  protected colorTheme: Theme = "light";

  protected isVkLoginAvailable = true;
  protected isGoogleLoginAvailable = true;

  protected returnUrl?: string;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
    private readonly i18nService: I18nService,
    private readonly themeService: ThemeService,
    private readonly notificationsService: NotificationsPopUpService,
    private readonly route: ActivatedRoute,
    @Inject("ConfigService")
    readonly configService: ConfigService,
    private readonly advertisementActionService: AdvertisementActionService,
  ) {
    this.userLanguage = i18nService.getLocale();
    this.loadingState$ = this.loadingService.loadingState$;

    this.isVkLoginAvailable = this.configService.getConfig().company.flags.isVkLoginAvailable;
    this.isGoogleLoginAvailable = this.configService.getConfig().company.flags.isGoogleLoginAvailable;

    this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
  }

  ngOnInit(): void {
    this.referalToken = this.advertisementActionService.getParamFromUri(this.route, "referalToken");

    this.updateColorTheme();
  }

  ngOnDestroy(): void {}

  async onRegistration(creds: RegistrationData) {
    this.notificationsService.closeAll();
    this.loadingService.startLoading();
    const result = await this.authService.register(
      creds.email,
      creds.firstName,
      creds.password,
      creds.role,
      creds.promoAgreement,
      this.referalToken,
    );

    // clean errors after request due to ugly window size change when error hides and reappears half a second later
    this.loginErrors = [];

    if (result === "success") {
      if (this.returnUrl) {
        this.router.navigateByUrl(this.returnUrl);
      } else {
        this.router.navigate(["/"]);
        // this.router.navigate(["/account/account-verification"], { queryParams: { from: "registration" } });
      }
    } else if (result === "different-login-method") {
      this.loginErrors.push("different-login-method");
    } else if (result === "email-not-unique") {
      this.loginErrors.push("email-not-unique");
    } else if (result === "network-error") {
      this.notificationsService.error($localize`:@@user.auth.generic-error:`);
    }

    this.loadingService.stopLoading();
  }

  onLoginVk() {
    this.authService.loginVk();
  }

  onLoginGoogle() {
    this.authService.loginGoogle();
  }

  onLangChange(lang: string) {
    this.i18nService.saveLocale(lang);
    window.location.reload();
  }

  protected updateColorTheme() {
    this.colorTheme = this.themeService.getTheme();
  }

  protected onColorThemeToggle() {
    this.themeService.toggleTheme();
    this.colorTheme = this.themeService.getTheme();
  }
}
