import { Injectable } from "@angular/core";
import { BookCover } from "@metranpage/book-data";
import { GeneratedImage } from "@metranpage/image-generation";
import { User } from "@metranpage/user-data";
import { Observable } from "rxjs";
import { BookCoversDataSource } from "./book-covers-data-source";
import { PublishedObjectsDataSource } from "./data-source";
import { ImagesDataSource } from "./images-data-source";

export type PublishedObjectsTypes = "images" | "covers";
export type PublishedObjectsDataSourceType = GeneratedImage | BookCover;
type ReturnType<T extends PublishedObjectsTypes> = T extends "images"
  ? GeneratedImage
  : T extends "covers"
    ? BookCover
    : // T extends "authors" ? Author :
      never;

@Injectable({
  providedIn: "root",
})
export class CommunityDataService {
  protected dataSources: Record<PublishedObjectsTypes, PublishedObjectsDataSource<PublishedObjectsDataSourceType>>;

  constructor(imageDataSource: ImagesDataSource, bookCoverDataSource: BookCoversDataSource) {
    this.dataSources = {
      images: imageDataSource,
      covers: bookCoverDataSource,
    };
  }

  getItems<T extends PublishedObjectsTypes>(objectType: T): Observable<ReturnType<T>[]> {
    return this.dataSources[objectType].getItems() as Observable<ReturnType<T>[]>;
  }

  loadNextPage<T extends PublishedObjectsTypes>(objectType: T) {
    this.dataSources[objectType].loadNextPage();
  }

  getObject<T extends PublishedObjectsTypes>(objectType: T, id: number): ReturnType<T> | undefined {
    return this.dataSources[objectType].getObject(id) as ReturnType<T>;
  }

  getImageUrlForObject<T extends PublishedObjectsTypes>(
    objectType: T,
    id: number,
    isThumbnail: boolean,
  ): string | undefined {
    return this.dataSources[objectType].getImageUrlForObject(id, isThumbnail);
  }

  getAuthorForObject<T extends PublishedObjectsTypes>(objectType: T, id: number): User | undefined {
    return this.dataSources[objectType].getAuthorForObject(id);
  }

  getEventBus<T extends PublishedObjectsTypes>(objectType: T) {
    return this.dataSources[objectType].getEventBus();
  }

  like<T extends PublishedObjectsTypes>(objectType: T, id: number) {
    return this.dataSources[objectType].like(id);
  }

  unlike<T extends PublishedObjectsTypes>(objectType: T, id: number) {
    return this.dataSources[objectType].unlike(id);
  }
}
