import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { LoginDto, LoginGoogleDto, LoginResponseDto, LoginVkDto, RegisterDto } from "../models/auth.dto";

@Injectable({
  providedIn: "any",
})
export class AuthApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  login(body: LoginDto) {
    return this.post<LoginResponseDto>("auth/login", { user: body });
  }

  register(body: RegisterDto) {
    return this.post<LoginResponseDto>("auth/register", { user: body });
  }

  loginGoogle(body: LoginGoogleDto) {
    return this.post<LoginResponseDto>("auth/login/google", {
      user: body,
    });
  }

  loginVk(body: LoginVkDto) {
    return this.post<LoginResponseDto>("auth/login/vk", {
      user: body,
    });
  }

  loginOneTimeToken(token: string) {
    return this.post<LoginResponseDto>("auth/login/one-time", {
      token,
    });
  }
  restorePassword(email: string, companyUuid: string) {
    return this.post<void>("auth/restore/request", { restore: { email, companyUuid } });
  }

  resetPassword(token: string, password: string) {
    return this.post<LoginResponseDto>("auth/restore/reset", { restore: { token, password } });
  }
}
