import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { UserRewardDaily } from "./models/rewards/user-reward-daily";
import { UserRewardOneTime } from "./models/rewards/user-reward-onetime";

export type RewardsState = {
  rewardsDaily: UserRewardDaily[];
  rewardsOneTime: UserRewardOneTime[];
};

@Injectable({
  providedIn: "root",
})
export class RewardsStore extends Store<RewardsState> {
  protected override getInitialState(): RewardsState {
    return {
      rewardsDaily: [],
      rewardsOneTime: [],
    };
  }

  getRewardsDaily() {
    return this.getField("rewardsDaily");
  }

  getRewardsDailyObservable() {
    return this.getFieldObservable("rewardsDaily");
  }

  getRewardsOneTime() {
    return this.getField("rewardsOneTime");
  }

  getRewardsOneTimeObservable() {
    return this.getFieldObservable("rewardsOneTime");
  }

  setRewardsDaily(rewardsDaily: UserRewardDaily[]) {
    this.update((state) => ({ ...state, rewardsDaily: rewardsDaily }));
  }

  setRewardsOneTime(rewardsOneTime: UserRewardOneTime[]) {
    this.update((state) => ({ ...state, rewardsOneTime: rewardsOneTime }));
  }

  addRewardsDaily(reward: UserRewardDaily) {
    this.update((state) => {
      const storeReward = state.rewardsDaily.find((r) => r.id === reward.id);
      if (storeReward) {
        return state;
      }
      return {
        ...state,
        rewardsDaily: [reward].concat(state.rewardsDaily),
      };
    });
  }

  updateRewardsDaily(updatedReward: UserRewardDaily) {
    const rewards = this.getRewardsDaily();
    const reward = rewards.find((n) => n.id === updatedReward.id);
    if (reward) {
      this.update((state) => {
        const updated = state.rewardsDaily.map((r) => {
          if (r.id === updatedReward.id) {
            return updatedReward;
          }
          return r;
        });
        return {
          ...state,
          rewardsDaily: updated,
        };
      });
    } else {
      this.addRewardsDaily(updatedReward);
    }
  }

  addRewardsOneTime(reward: UserRewardOneTime) {
    this.update((state) => {
      const storeReward = state.rewardsOneTime.find((r) => r.id === reward.id);
      if (storeReward) {
        return state;
      }
      return {
        ...state,
        rewardsOneTime: [reward].concat(state.rewardsOneTime),
      };
    });
  }

  updateRewardsOneTime(updatedReward: UserRewardOneTime) {
    const rewards = this.getRewardsOneTime();
    const reward = rewards.find((n) => n.id === updatedReward.id);
    if (reward) {
      this.update((state) => {
        const updated = state.rewardsOneTime.map((r) => {
          if (r.id === updatedReward.id) {
            return updatedReward;
          }
          return r;
        });
        return {
          ...state,
          rewardsOneTime: updated,
        };
      });
    } else {
      this.addRewardsOneTime(updatedReward);
    }
  }
}
