import { CommonModule, NgClass } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "m-soon-mark",
  template: `
    <ng-content select="[text-left]"></ng-content>
    <span class="mark" i18n="@@soon-mark">soon-mark</span>
    <ng-content select="[text-right]"></ng-content>
  `,
  styleUrls: ["./soon-mark.component.scss"],
  standalone: true,
  imports: [CommonModule, NgClass],
})
export class SoonMarkComponent {}
