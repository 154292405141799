import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { I18nService } from "@metranpage/i18n";
import { IconComponent } from "@metranpage/components";
import { AngularSvgIconModule } from "angular-svg-icon";

interface Data {
  action: string;
  count?: number | undefined;
}

type PluralizeKey =
  | "add-rows-above"
  | "add-rows-below"
  | "add-columns-left"
  | "add-columns-right"
  | "delete-columns"
  | "delete-rows";

@Component({
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, IconComponent],
  selector: "m-markup-editor-table-context-menu",
  templateUrl: "./table-context-menu.component.html",
  styleUrls: ["./table-context-menu.component.scss"],
})
export class TableContextMenuComponent {
  @Output()
  onContextMenuItemClick: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Input() selectedRowsCount = 1;
  @Input() selectedColumnsCount = 1;

  constructor(private readonly i18nService: I18nService) {}

  onItemClick(event: any, data: Data) {
    this.onContextMenuItemClick.emit({
      event,
      data,
    });
  }

  pluralize(key: PluralizeKey, data: Record<string, number>) {
    let template = "";
    switch (key) {
      case "add-rows-above":
        template = $localize`:@@editor.table.context_menu.add_rows_above:`;
        break;
      case "add-rows-below":
        template = $localize`:@@editor.table.context_menu.add_rows_below:`;
        break;
      case "add-columns-left":
        template = $localize`:@@editor.table.context_menu.add_columns_left:`;
        break;
      case "add-columns-right":
        template = $localize`:@@editor.table.context_menu.add_columns_right:`;
        break;
      case "delete-rows":
        template = $localize`:@@editor.table.context_menu.delete_rows:`;
        break;
      case "delete-columns":
        template = $localize`:@@editor.table.context_menu.delete_columns:`;
        break;
    }
    return this.i18nService.pluralize(template, data);
    //   $localize`:@@pricing.pluralize-active-projects:`, {
    //     value: number,
    // }
  }
}
