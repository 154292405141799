import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PaymentMethodDataDto, PaymentMethodType } from "@metranpage/pricing-data";

@Component({
  selector: "m-account-payment-method",
  templateUrl: "./account-payment-method.view.html",
  styleUrls: ["./account-payment-method.view.scss"],
})
export class AccountPaymentMethodView {
  @Input()
  pm?: PaymentMethodDataDto;
  @Input()
  isActive = false;
  @Input()
  newPaymentMethod = false;

  @Output()
  onAddPaymentMethod = new EventEmitter<void>();
  @Output()
  onSelectPaymentMethod = new EventEmitter<PaymentMethodDataDto>();
  @Output()
  onDeletePaymentMethod = new EventEmitter<PaymentMethodDataDto>();

  getPaymentMethodTypeIcon(type: PaymentMethodType, cardBrand: string | undefined) {
    if (type === "card" && cardBrand) {
      return `/assets/img/cards/${cardBrand.toLowerCase()}.svg`;
    }
    return `/assets/img/cards/${type.toLowerCase()}.svg`;
  }

  protected addNewPaymentMethod() {
    this.onAddPaymentMethod.emit();
  }

  protected setPaymentMethodForPayment() {
    this.onSelectPaymentMethod.emit(this.pm);
  }

  protected showDeletePaymentMethodModal() {
    this.onDeletePaymentMethod.emit(this.pm);
  }
}
