import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { fadeInOutOnEnterLeave, slideInOutVertical } from "@metranpage/components";
import { User } from "@metranpage/user-data";
import { Character } from "../../models/character/character";
import { DownloadGeneratedImageData, GeneratedImage } from "../../models/generated-image";
import {
  ImageGeneration,
  ImageGenerationAdvancedStyle,
  ImageGenerationBasicStyle,
} from "../../models/image-generation";
import { ImageGenerationService } from "../../services/image-generation.service";
import { PreviewImageData } from "../generation-result/generation-result.view";

@Component({
  selector: "m-generation-results",
  templateUrl: "./generation-results.view.html",
  styleUrls: ["./generation-results.view.scss"],
  animations: [slideInOutVertical, fadeInOutOnEnterLeave],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerationResultsView implements OnInit {
  @Input()
  user?: User;
  @Input()
  isModal = false;
  @Input()
  imageGenerations: ImageGeneration[] = [];
  @Input()
  stylesBasic: ImageGenerationBasicStyle[] = [];
  @Input()
  stylesAdvanced: ImageGenerationAdvancedStyle[] = [];
  @Input()
  isImageGenerationsLoading = false;
  @Input()
  characters: Character[] = [];

  @Output()
  onScroll = new EventEmitter();
  @Output()
  onPreviewImage = new EventEmitter<PreviewImageData>();
  @Output()
  onSelectImage = new EventEmitter<GeneratedImage>();
  @Output()
  onDeleteImageGeneration = new EventEmitter<ImageGeneration>();
  @Output()
  onDownnloadImage = new EventEmitter<DownloadGeneratedImageData>();
  @Output()
  onDeleteImage = new EventEmitter<GeneratedImage>();
  @Output()
  onUpscaleImage = new EventEmitter<PreviewImageData>();
  @Output()
  onUnzoomImage = new EventEmitter<PreviewImageData>();
  @Output()
  onEditImageGeneration = new EventEmitter<ImageGeneration>();

  @ViewChildren("generationResult", { read: ElementRef })
  generationResultElements?: QueryList<ElementRef>;

  constructor(
    private readonly imageGenerationService: ImageGenerationService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.imageGenerationService.scrollToTopImageGenerationResults$.subscribe((v) => {
      this.scrollGenerationRelultsToTop();
    });
  }

  private scrollGenerationRelultsToTop() {
    if (!this.generationResultElements || this.generationResultElements.toArray().length === 0) {
      return;
    }

    this.generationResultElements.toArray()[0].nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }

  protected onScrollEvent() {
    this.onScroll.emit();
  }

  protected onPreviewImageClick(value: PreviewImageData) {
    this.onPreviewImage.emit(value);
  }

  protected onDeleteImageGenerationClick(imageGeneration: ImageGeneration) {
    this.onDeleteImageGeneration.emit(imageGeneration);
  }

  protected onSelectImageClick(generatedImage: GeneratedImage) {
    this.onSelectImage.emit(generatedImage);
  }

  protected onDownloadImageClick(downloadGeneratedImageData: DownloadGeneratedImageData) {
    this.onDownnloadImage.emit(downloadGeneratedImageData);
  }

  protected onDeleteImageClick(generatedImage: GeneratedImage) {
    this.onDeleteImage.emit(generatedImage);
  }

  protected onUpscaleImageClick(imageGeneration: ImageGeneration, generatedImage: GeneratedImage) {
    this.onUpscaleImage.emit({ imageGeneration, generatedImage });
  }

  protected onUnzoomImageClick(imageGeneration: ImageGeneration, generatedImage: GeneratedImage) {
    this.onUnzoomImage.emit({ imageGeneration, generatedImage });
  }

  protected onEditImageGenerationClick(imageGeneration: ImageGeneration) {
    this.onEditImageGeneration.emit(imageGeneration);
  }

  protected getBasicStyle(result: ImageGeneration): ImageGenerationBasicStyle | undefined {
    if (result.generationMode !== "basic") {
      return undefined;
    }
    return this.stylesBasic.find((s) => s.id === result.styleId);
  }

  protected getAdvancedStyle(result: ImageGeneration): ImageGenerationAdvancedStyle | undefined {
    if (result.generationMode !== "advanced") {
      return undefined;
    }
    return this.stylesAdvanced.find((s) => s.id === result.styleId);
  }

  protected trackByImageGeneration(_index: number, imageGeneration: ImageGeneration) {
    return imageGeneration.id;
  }
}
