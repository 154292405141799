import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotificationsPopUpService } from "@metranpage/core";

@Component({
  selector: "m-login-modal",
  templateUrl: "./login-modal.view.html",
  styleUrls: ["./login-modal.view.scss"],
})
export class LoginModalView implements OnInit {
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;

  @Output()
  login = new EventEmitter();
  @Output()
  close = new EventEmitter();

  constructor(private readonly notificationService: NotificationsPopUpService) {}

  ngOnInit(): void {}

  onLoginClick() {
    this.login.emit();
  }

  onCloseClick() {
    this.close.emit();
  }
}
