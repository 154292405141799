import { Component, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Align, Case, Emphasis, IndentHeader, IndentParagraph, StartOn } from "@metranpage/markup-editor";

@Component({
  selector: "m-markup-style-details-emphasis-buttons-row",
  template: `
    <m-markup-style-details-button
      iconSrc="markup-emphasis-bold.svg"
      [isSelected]="value === 'bold' || value === 'bolditalic'"
      (click)="toggle('bold')"
    ></m-markup-style-details-button>
    <m-markup-style-details-button
      iconSrc="markup-emphasis-italic.svg"
      [isSelected]="value === 'italic' || value === 'bolditalic'"
      (click)="toggle('italic')"
    ></m-markup-style-details-button>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        gap: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkupStyleDetailsSettingsEmphasisButtonsRow),
      multi: true,
    },
  ],
})
export class MarkupStyleDetailsSettingsEmphasisButtonsRow implements ControlValueAccessor {
  value: Emphasis = "none";

  private onTouched = () => {};
  private onChange = (_: any) => {};

  writeValue(value: Emphasis): void {
    this.value = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  toggle(em: Emphasis) {
    if (this.value === em) {
      this.value = "none";
    } else if (this.value === "bolditalic") {
      if (em === "bold") {
        this.value = "italic";
      }
      if (em === "italic") {
        this.value = "bold";
      }
    } else if (this.value === "none") {
      this.value = em;
    } else {
      this.value = "bolditalic";
    }
    this.onChange(this.value);
  }
}

@Component({
  selector: "m-markup-style-details-align-buttons-row",
  template: `
    <m-markup-style-details-button
      iconSrc="markup-align-left.svg"
      [isSelected]="value === 'left'"
      (click)="select('left')"
    ></m-markup-style-details-button>
    <m-markup-style-details-button
      iconSrc="markup-align-center.svg"
      [isSelected]="value === 'center'"
      (click)="select('center')"
    ></m-markup-style-details-button>
    <m-markup-style-details-button
      iconSrc="markup-align-right.svg"
      [isSelected]="value === 'right'"
      (click)="select('right')"
    ></m-markup-style-details-button>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        gap: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkupStyleDetailsSettingsAlignButtonsRow),
      multi: true,
    },
  ],
})
export class MarkupStyleDetailsSettingsAlignButtonsRow implements ControlValueAccessor {
  value: Align = "left";

  private onTouched = () => {};
  private onChange = (_: any) => {};

  writeValue(value: Align): void {
    this.value = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  select(value: Align) {
    this.value = value;
    this.onChange(value);
  }
}

@Component({
  selector: "m-markup-style-details-align-maintext-buttons-row",
  template: `
    <m-markup-style-details-button
      iconSrc="markup-align-left.svg"
      [isSelected]="value === 'left'"
      (click)="select('left')"
    ></m-markup-style-details-button>
    <m-markup-style-details-button
      iconSrc="markup-align-left-justify.svg"
      [isSelected]="value === 'leftJustify'"
      (click)="select('leftJustify')"
    ></m-markup-style-details-button>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        gap: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkupStyleDetailsSettingsAlignMainTextButtonsRow),
      multi: true,
    },
  ],
})
export class MarkupStyleDetailsSettingsAlignMainTextButtonsRow implements ControlValueAccessor {
  value: Align = "left";

  private onTouched = () => {};
  private onChange = (_: any) => {};

  writeValue(value: Align): void {
    this.value = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  select(value: Align) {
    this.value = value;
    this.onChange(value);
  }
}

@Component({
  selector: "m-markup-style-details-case-buttons-row",
  template: `
    <m-markup-style-details-button
      iconSrc="markup-case-none.svg"
      [isSelected]="value === 'none'"
      (click)="select('none')"
      size="xs"
    ></m-markup-style-details-button>
    <m-markup-style-details-button
      iconSrc="markup-case-uppercase.svg"
      [isSelected]="value === 'uppercase'"
      (click)="select('uppercase')"
      size="s"
    ></m-markup-style-details-button>
    <!-- <m-markup-style-details-button
      iconSrc="markup-case-lowercase.svg"
      [isSelected]="value === 'lowercase'"
      (click)="select('lowercase')"
      size="xs"
    ></m-markup-style-details-button> -->
    <m-markup-style-details-button
      iconSrc="markup-case-small-caps.svg"
      [isSelected]="value === 'small-caps'"
      (click)="select('small-caps')"
      size="xs"
    ></m-markup-style-details-button>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        gap: 4px;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkupStyleDetailsSettingsCaseButtonsRow),
      multi: true,
    },
  ],
})
export class MarkupStyleDetailsSettingsCaseButtonsRow implements ControlValueAccessor {
  value: Case = "none";

  private onTouched = () => {};
  private onChange = (_: any) => {};

  writeValue(value: Case): void {
    this.value = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  select(value: Case) {
    this.value = value;
    this.onChange(value);
  }
}

@Component({
  selector: "m-markup-style-details-start-on-buttons-row",
  template: `
    <m-radio
      class="radio-button"
      [value]="'next-page'"
      [isChecked]="value === 'next-page'"
      gapSize="small"
      (onSelect)="select('next-page')"
    >
      <m-icon src="markup-start-on-next-page-{theme}.svg" size="original" />
    </m-radio>

    <m-radio
      class="radio-button"
      [value]="'odd-page'"
      [isChecked]="value === 'odd-page'"
      gapSize="small"
      (onSelect)="select('odd-page')"
    >
      <m-icon src="markup-start-on-odd-page-{theme}.svg" size="original" />
    </m-radio>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        gap: 8px;
      }

      m-icon {
        width: 62px;
        height: 40px;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkupStyleDetailsSettingsStartOnButtonsRow),
      multi: true,
    },
  ],
})
export class MarkupStyleDetailsSettingsStartOnButtonsRow implements ControlValueAccessor {
  value: StartOn = "current-page";

  private onTouched = () => {};
  private onChange = (_: any) => {};

  writeValue(value: StartOn): void {
    this.value = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  select(value: StartOn) {
    this.value = value;
    this.onChange(value);
  }
}

@Component({
  selector: "m-markup-style-details-indent-paragraph-buttons-row",
  template: `
    <m-radio
      class="radio-button"
      [value]="'indented-line'"
      [isChecked]="value === 'indented-line'"
      (onSelect)="select('indented-line')"
    >
      <m-icon src="markup-indent-paragraph-indented-line-{theme}.svg" size="original" />
    </m-radio>

    <m-radio
      class="radio-button"
      [value]="'indent-after'"
      [isChecked]="value === 'indent-after'"
      (onSelect)="select('indent-after')"
    >
      <m-icon src="markup-indent-paragraph-indent-after-{theme}.svg" size="original" />
    </m-radio>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
        gap: 20px;
      }

      m-icon {
        width: 30px;
        height: 40px;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkupStyleDetailsSettingsIndentParagraphButtonsRow),
      multi: true,
    },
  ],
})
export class MarkupStyleDetailsSettingsIndentParagraphButtonsRow implements ControlValueAccessor {
  value: IndentParagraph = "indented-line";

  private onTouched = () => {};
  private onChange = (_: any) => {};

  writeValue(value: IndentParagraph): void {
    this.value = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  select(value: IndentParagraph) {
    this.value = value;
    this.onChange(value);
  }
}

@Component({
  selector: "m-markup-style-details-indent-header-buttons-row",
  template: `
    <m-radio class="radio-button" [value]="'none'" [isChecked]="value === 'none'" (onSelect)="select('none')">
      <m-icon src="markup-indent-header-none-{theme}.svg" size="original" />
    </m-radio>

    <m-radio class="radio-button" [value]="'before'" [isChecked]="value === 'before'" (onSelect)="select('before')">
      <m-icon src="markup-indent-header-before-{theme}.svg" size="original" />
    </m-radio>

    <m-radio class="radio-button" [value]="'after'" [isChecked]="value === 'after'" (onSelect)="select('after')">
      <m-icon src="markup-indent-header-after-{theme}.svg" size="original" />
    </m-radio>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      m-icon {
        width: 30px;
        height: 40px;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkupStyleDetailsSettingsIndentHeaderhButtonsRow),
      multi: true,
    },
  ],
})
export class MarkupStyleDetailsSettingsIndentHeaderhButtonsRow implements ControlValueAccessor {
  value: IndentHeader = "none";

  private onTouched = () => {};
  private onChange = (_: any) => {};

  writeValue(value: IndentHeader): void {
    this.value = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  select(value: IndentHeader) {
    this.value = value;
    this.onChange(value);
  }
}
