import { Component } from "@angular/core";

@Component({
  selector: "m-success-payment",
  template: `
    <m-icon src="/assets/img/payment-success.png" size="original"></m-icon>
    <div class="content">
      <div class="title" i18n="@@pricing.success-page.title">pricing.success-page.title</div>
      <div class="text" i18n="@@pricing.success-page.text">pricing.success-page.text</div>
    </div>
    <m-button button-style="primary" i18n="@@pricing.success-page.return" [routerLink]="'/books'">
      pricing.success-page.return
    </m-button>
  `,
  styleUrls: ["./payment-success.page.scss"],
})
export class PaymentSuccessPage {}
