import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SelectValue } from "@metranpage/components";
import { BehaviorSubject, Subscription } from "rxjs";

export type GeneralResultStatus = "success" | "error";

export type FormChangeData = {
  type: string;
};

@Injectable({
  providedIn: "any",
})
export class CharactersFormService {
  protected form!: FormGroup;

  private sub = new Subscription();

  protected reDigits = /^[0-9.]+$/;

  protected typeOptions: SelectValue[] = [
    { id: "human", value: $localize`:@@characters.new.type.human:` },
    { id: "cyborg", value: $localize`:@@characters.new.type.cyborg:` },
    { id: "elf", value: $localize`:@@characters.new.type.elf:` },
    { id: "dwarf", value: $localize`:@@characters.new.type.dwarf:` },
    { id: "troll", value: $localize`:@@characters.new.type.troll:` },
    { id: "orc", value: $localize`:@@characters.new.type.orc:` },
    { id: "demon", value: $localize`:@@characters.new.type.demon:` },
    { id: "custom", value: $localize`:@@characters.new.type.custom:` },
  ];

  protected genderOptions: SelectValue[] = [
    { id: "male", value: $localize`:@@characters.new.gender.male:` },
    { id: "female", value: $localize`:@@characters.new.gender.female:` },
  ];

  formValueChangesEvent$ = new BehaviorSubject<FormChangeData | undefined>(undefined);

  destroyForm() {
    this.sub.unsubscribe();
    this.sub = new Subscription();
  }

  initForm() {
    this.createForm();
    this.setDefaultFormValue();
    this.watchFormChanges();
    return this.getForm();
  }

  createForm() {
    this.form = new FormGroup(
      {
        name: new FormControl("", [Validators.required]),
        type: new FormControl("", [Validators.required]),
        customType: new FormControl("", []),
        gender: new FormControl("male", [Validators.required]),
        age: new FormControl(20, [Validators.required, Validators.pattern(this.reDigits)]),
        hair: new FormControl("", []),
        isBald: new FormControl(false, [Validators.required]),
        appearance: new FormControl("", []),
      },
      [],
    );
  }

  protected watchFormChanges() {
    this.sub.add(
      this.form.valueChanges.subscribe((value) => {
        if (value.type === "custom") {
          this.form.get("customType")?.addValidators(Validators.required);
        } else {
          this.form.get("customType")?.clearValidators();
        }
        this.form.get("customType")?.updateValueAndValidity({ emitEvent: false });

        this.formValueChangesEvent$.next({
          type: value.type,
        });
      }),
    );
  }

  setDefaultFormValue() {
    this.form.patchValue({
      name: "",
      type: "",
      customType: "",
      gender: "male",
      age: 20,
      hair: "",
      isBald: false,
      appearance: "",
    });
  }

  getForm() {
    return this.form;
  }

  getTypeOptions() {
    return this.typeOptions;
  }

  getTypeOptionById(value: string) {
    let type = "custom";
    const option = this.typeOptions.find((o) => o.id === value);
    if (value && option) {
      type = `${option.id}`;
    }
    return type;
  }

  getGenderOptions() {
    return this.genderOptions;
  }
}
