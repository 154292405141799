import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'm-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input()
  title = '';
  @Input()
  isChecked = false;

  @Output()
  onValueChange = new EventEmitter<boolean>(false);

  protected isDisabled = false;
  private onTouched = () => {};
  private onChange = (_: any) => {};

  toggle() {
    this.isChecked = !this.isChecked;
    this.onTouched();
    this.onChange(this.isChecked);

    this.onValueChange.emit(this.isChecked);
  }

  writeValue(value: boolean): void {
    this.isChecked = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  getCheckboxImage() {
    if (this.isChecked) {
      return '/assets/components/checkbox-on.svg';
    } else {
      return undefined;
    }
  }

  @HostListener('click')
  onClick() {
    this.toggle();
  }
}
