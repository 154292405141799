import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { UploadUserTemplateFileResponse, UserTemplateData } from "./user-template.service";

@Injectable({
  providedIn: "root",
})
export class UserTemplateApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async uploadUserTemplateFile(data: UserTemplateData, file: File) {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    for (const [key, value] of Object.entries(data)) {
      if (typeof value !== "string") {
        formData.append(key, JSON.stringify(value));
        continue;
      }
      formData.append(key, value);
    }

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post<UploadUserTemplateFileResponse>("user-templates/template-file", formData, {
      headers,
    });
  }
}
