import { Injectable } from "@angular/core";
import { ApiErrorHandlerService, RealtimeService } from "@metranpage/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import {
  ModerationImageRequest,
  ModerationImageRequestPublishedFrom,
  ModerationImageRequestUpdateData,
} from "../../models/moderation-image-request";
import { AdminModerationImageRequestApi } from "./moderation-image-request.api";
import { AdminModerationImageRequestStore } from "./moderation-image-request.store";

@Injectable({
  providedIn: "root",
})
export class AdminModerationImageRequestService {
  constructor(
    private readonly api: AdminModerationImageRequestApi,
    private readonly store: AdminModerationImageRequestStore,
    private readonly apiErrorHandler: ApiErrorHandlerService,
    realtimeService: RealtimeService,
  ) {
    realtimeService
      .getEvents<ModerationImageRequest>("moderation-image-request-change")
      .subscribe((moderationImageRequestUpdate: ModerationImageRequest | undefined) => {
        if (moderationImageRequestUpdate) {
          store.updateModerationImageRequest(moderationImageRequestUpdate);
        }
      });
  }

  async loadModerationImageRequestsPaginated(page: number, publishedFrom: ModerationImageRequestPublishedFrom) {
    const imagesOnModerationData = await this.api.loadModerationImageRequestsPaginated(page, publishedFrom);
    this.store.addModerationImageRequestsToEnd(imagesOnModerationData.items);
    this.store.setModerationImageRequestsPageCount(imagesOnModerationData.pageCount);
  }

  async acceptModerationImageRequest(id: number): Promise<GeneralResultStatus> {
    try {
      await this.api.acceptModerationImageRequest(id);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "error";
    }
  }

  async declineModerationImageRequest(
    id: number,
    data: ModerationImageRequestUpdateData,
  ): Promise<GeneralResultStatus> {
    try {
      await this.api.declineModerationImageRequest(id, data);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "error";
    }
  }
}
