import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import { FormatAccessData, FormatAccessDto, FormatAccessUpdateData } from "../../models/format-access.model";

export type FormatAccessFilter = {};

@Injectable({
  providedIn: "root",
})
export class AdminFormatAccessApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  getFormatsAccess() {
    return this.get<FormatAccessDto[]>("admin/format-access");
  }

  getFormatsAccessPaginated(page: number, filters: FormatAccessFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<FormatAccessData>("admin/format-access/paginated", {
      page,
      ...clearedFilters,
    });
  }

  async updateFormatAccess(id: number, data: FormatAccessUpdateData) {
    return this.post<FormatAccessDto>(`admin/format-access/${id}`, data);
  }
}
