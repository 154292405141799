import { Component, EventEmitter, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { ReferalLinkCreateData } from "../../models/referal-link";
import { AdminReferalLinksService } from "../../services/referal-links/referal-links.service";

@Component({
  selector: "m-admin-add-referal-link-modal",
  templateUrl: "./add-referal-link.view.html",
  styleUrls: ["./add-referal-link.view.scss"],
})
export class AddReferalLinkView {
  @Output()
  onReferalLinkAdded = new EventEmitter<ReferalLinkCreateData>();
  @Output()
  onCancelAddition = new EventEmitter<void>();

  protected referalLinkUrl = "";

  protected form?: FormGroup;

  private sub: Subscription = new Subscription();

  constructor(private readonly adminReferalLinksService: AdminReferalLinksService) {}

  ngOnInit(): void {
    this.createForm();
    this.watchFormChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  createForm() {
    this.form = this.adminReferalLinksService.createReferalLinkForm();
  }

  watchFormChanges() {
    this.sub.add(
      this.form?.valueChanges.subscribe((data) => {
        // this.referalLinkUrl = this.getReferalLink();
      }),
    );
  }

  createReferalLink() {
    const formData = this.form?.getRawValue();
    formData.agentFee = +formData.agentFee;
    this.onReferalLinkAdded.emit(formData);
  }

  onCancel() {
    this.onCancelAddition.emit();
  }

  protected getReferalLink() {
    // const company = this.companies.find((c) => c.id === this.form?.get("companyId")?.value);
    // const hostUrl = company?.hostUrl ?? "";
    // const token = this.form?.get("token")?.value ?? "";
    // return this.adminService.getReferalLink(hostUrl, token);
  }

  onCopyReferalLinkUrl() {
    this.adminReferalLinksService.copyText(this.referalLinkUrl);
  }

  onGenerateToken() {
    this.form?.patchValue({ token: uuidv4() });
  }
}
