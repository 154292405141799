import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { ElementRef, Injectable } from "@angular/core";
import { Color, CoverObject, GroupObject } from "@metranpage/book-data";
import { Subject } from "rxjs";

type UpdateGroupKind = "finalize" | "full";
export type UpdateGroupParam = {
  group: GroupObject;
  objectToAdd?: CoverObject;
  objectToRemove?: CoverObject;
  kind: UpdateGroupKind;
};

@Injectable({
  providedIn: "root",
})
export class CoverUiService {
  private eyedropper = new Subject<(color: Color) => void>();
  eyedropper$ = this.eyedropper.asObservable();

  private colorContextMenu = new Subject<ElementRef>();
  colorContextMenu$ = this.colorContextMenu.asObservable();

  private colorPicker = new Subject<ElementRef>();
  colorPicker$ = this.colorPicker.asObservable();

  private reorderSubject = new Subject<CdkDragDrop<CoverObject[]>>();
  reorder$ = this.reorderSubject.asObservable();

  private updateGroupSubject = new Subject<UpdateGroupParam>();
  updateGroup$ = this.updateGroupSubject.asObservable();

  private renameObjectSubject = new Subject<CoverObject>();
  renameObject$ = this.renameObjectSubject.asObservable();

  showEyeDropper(callback: (color: Color) => void) {
    this.eyedropper.next(callback);
  }

  showColorContextMenu(element: ElementRef) {
    this.colorContextMenu.next(element);
  }

  showColorPicker(element: ElementRef) {
    this.colorPicker.next(element);
  }

  reorder(event: CdkDragDrop<CoverObject[]>) {
    this.reorderSubject.next(event);
  }

  updateGroup(param: UpdateGroupParam) {
    this.updateGroupSubject.next(param);
  }

  renameObject(object: CoverObject) {
    this.renameObjectSubject.next(object);
  }
}
