import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { lastValueFrom } from "rxjs";

export class Api {
  protected baseUrl: string;
  constructor(protected readonly http: HttpClient, baseUrl: string) {
    this.baseUrl = baseUrl;
    if (!this.baseUrl.endsWith("/")) {
      this.baseUrl = `${this.baseUrl}/`;
    }
  }

  protected get<T>(url: string, params: Record<string, string | number> | undefined = undefined): Promise<T> {
    return lastValueFrom(
      this.http.get<T>(this.absoluteUrl(url), {
        params: params,
      }),
    );
  }

  protected getAsBlob(url: string): Promise<Blob> {
    return lastValueFrom(this.http.get(this.absoluteUrl(url), { responseType: "blob" }));
  }

  protected post<T>(
    url: string,
    data: object,
    opts?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      params?:
        | HttpParams
        | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
          };
      responseType?: "json";
    },
  ): Promise<T> {
    return lastValueFrom(this.http.post<T>(this.absoluteUrl(url), data, opts));
  }

  protected put<T>(url: string, data: object): Promise<T> {
    return lastValueFrom(this.http.put<T>(this.absoluteUrl(url), data));
  }

  protected delete<T>(url: string): Promise<T> {
    return lastValueFrom(this.http.delete<T>(this.absoluteUrl(url)));
  }

  protected absoluteUrl(url: string) {
    return this.baseUrl + url;
  }
}
