import { CommonModule, NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  booleanAttribute,
  inject,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ThemeService } from "@metranpage/theme";
import { AngularSvgIconModule } from "angular-svg-icon";

export type IconSize =
  | 8
  | 10
  | 12
  | 14
  | 16
  | 18
  | 20
  | 22
  | 24
  | 25
  | 28
  | 32
  | 36
  | 40
  | 48
  | 64
  | 77
  | 92
  | 100
  | 150
  | 200
  | 340;

@Component({
  selector: "m-ng-icon",
  template: `<svg-icon [src]="iconSrc" [ngClass]="'size-' + size"></svg-icon>`,
  styleUrls: ["./icon.component.scss"],
  standalone: true,
  imports: [CommonModule, NgClass, AngularSvgIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit, OnChanges {
  @Input()
  src!: string;
  @Input()
  size: IconSize = 24;

  @HostBinding("class.no-recolor")
  @Input({ transform: booleanAttribute })
  noRecolor = false;

  @HostBinding("class")
  get sizeClass() {
    return `size-${this.size}`;
  }

  protected iconSrc!: string;

  protected destroyRef = inject(DestroyRef);

  constructor(
    private readonly themeService: ThemeService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    themeService.changeEvents$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((theme) => {
      this.normalizePath();
      this.cdr.markForCheck();
    });
  }

  ngOnInit(): void {
    this.normalizePath();
    // this.elementRef.nativeElement.style.setProperty('--icon-color', `var(${this.color})`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["src"]) {
      this.src = changes["src"].currentValue;
    }
    //   if (changes['color']) {
    //     this.color = changes['color'].currentValue;
    //   }
    //
    this.normalizePath();
    // this.elementRef.nativeElement.style.setProperty('--icon-color', `var(${this.color})`);
    // if (this.colorOnHover) {
    //   this.elementRef.nativeElement.style.setProperty('--icon-color-hover', `var(${this.colorOnHover})`);
    // }
    this.cdr.markForCheck();
  }

  normalizePath() {
    if (!this.src) {
      return;
    }

    if (this.src.startsWith("/")) {
      this.iconSrc = this.src;
    } else {
      this.iconSrc = `/assets/icons/${this.src}`;
    }

    if (this.iconSrc.includes("{theme}")) {
      this.iconSrc = this.iconSrc.replace("{theme}", this.themeService.getThemeSuffix());
    }
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    result.push(`size-${this.size}`);

    // if (this.color) {
    //   result.push('colored');
    // }
    //
    // if (this.colorOnHover) {
    //   result.push('colored-hover');
    // }

    return result;
  }
}
