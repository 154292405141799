import { Component, ElementRef, Input, OnInit, Renderer2, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MarginKey, PagePosition, RunningTitleContext, RunningTitlesStyle } from "@metranpage/book-data";
import { PaletteDTO } from "@metranpage/components";

@Component({
  selector: "m-running-titles-styles-group",
  templateUrl: "./running-titles-styles-group.component.html",
  styleUrls: ["./running-titles-styles-group.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RunningTitlesStylesGroupComponent),
      multi: true,
    },
  ],
})
export class RunningTitlesStylesGroupComponent implements OnInit, ControlValueAccessor {
  @Input("running-titles-styles")
  runningTitlesStyles?: RunningTitlesStyle[];
  @Input("page-numbers-styles")
  pageNumbersStyles?: RunningTitlesStyle[];
  @Input()
  context: RunningTitleContext = {
    runningTitle: "",
    pageNumber: "",
  };
  @Input("page-position")
  pagePosition: PagePosition = "left";
  @Input("font-size")
  fontSize = 12;
  @Input()
  palette?: PaletteDTO;
  @Input()
  position: MarginKey = "marginTop";

  protected isDisabled = false;
  protected value: string | number = "";

  private onTouched = () => {};
  private onChange = (_: any) => {};

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.moveDisabledToEnd();
  }

  writeValue(value: string | number): void {
    this.value = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  protected onSelect(value: string | number) {
    if (this.isRadioDisabled(value)) {
      return;
    }
    this.value = value;
    this.onChange(this.value);
  }

  isRadioChecked(value: string | number) {
    return value === this.value;
  }

  isStyleDisabled(value: string | number) {
    return false;
  }

  isRadioDisabled(value: string | number) {
    return this.isStyleDisabled(value) || false;
  }

  moveDisabledToEnd() {
    if (this.runningTitlesStyles) {
      for (let i = 0; i < this.runningTitlesStyles.length; i++) {
        if (this.isStyleDisabled(this.runningTitlesStyles[i].name)) {
          const item = this.runningTitlesStyles.splice(i, 1)[0];
          this.runningTitlesStyles.splice(this.runningTitlesStyles.length, 1, item);
        }
      }
    }

    if (this.pageNumbersStyles) {
      for (let i = 0; i < this.pageNumbersStyles.length; i++) {
        if (this.isStyleDisabled(this.pageNumbersStyles[i].name)) {
          const item = this.pageNumbersStyles.splice(i, 1)[0];
          this.pageNumbersStyles.splice(this.pageNumbersStyles.length, 1, item);
        }
      }
    }
  }
}
