import { Injectable } from "@angular/core";
import { NotificationsPopUpService, RealtimeService } from "@metranpage/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { I18nService } from "@metranpage/i18n";
import { BalanceData } from "@metranpage/user-payment-data";
import * as _ from "lodash-es";
import { Subject } from "rxjs";
import { GeneratedImage } from "../models/generated-image";
import {
  AdvancedImageGenerationDataDto,
  BasicImageGenerationDataDto,
  ImageGeneration,
  ImageGenerationAdvancedStyle,
  ImageGenerationAdvancedStyleDto,
  ImageGenerationBasicStyle,
  ImageGenerationCreditCheckData,
  ImageGenerationMode,
  ImageGenerationPrices,
  ImageGenerationStyleDto,
  LastImageGenerationsDataDto,
  UnzoomSettings,
  UpscaleSettings,
} from "../models/image-generation";
import { ImageGenerationResultUpdate } from "../models/image-generation-update";
import {
  GenerationImageColorScheme,
  GenerationImageColorSettings,
  GenerationImageCustomColor,
} from "../views/color-scheme-selector/color-scheme-selector.view";
import { ImageGenerationApi } from "./image-generation.api";
import { ImageGenerationStore } from "./image-generation.store";
import { VariantImageGenerationStore } from "./variant-image-generation.store";

@Injectable({
  providedIn: "root",
})
export class ImageGenerationService {
  private scrollToTopImageGenerationResults = new Subject<void>();
  scrollToTopImageGenerationResults$ = this.scrollToTopImageGenerationResults.asObservable();

  protected basicStyles: ImageGenerationBasicStyle[] = [];
  protected advancedStyles: ImageGenerationAdvancedStyle[] = [];

  constructor(
    private readonly imageGenerationStore: ImageGenerationStore,
    private readonly variantImageGenerationStore: VariantImageGenerationStore,
    private readonly imageGenerationApi: ImageGenerationApi,
    private readonly i18nService: I18nService,
    private readonly notificationService: NotificationsPopUpService,
    realtimeService: RealtimeService,
  ) {
    realtimeService
      .getEvents<ImageGenerationResultUpdate>("image-generation-result")
      .subscribe((imageGenerationUpdate: ImageGenerationResultUpdate | undefined) => {
        if (imageGenerationUpdate) {
          const newImageGeneration = imageGenerationUpdate.imageGeneration;
          newImageGeneration.generatedImages = this.orderGeneratedImages(newImageGeneration?.generatedImages);

          if (!newImageGeneration.rootGenerationId) {
            this.imageGenerationStore.updateImageGeneration(newImageGeneration);
          } else {
            this.variantImageGenerationStore.updateVariantImageGeneration(newImageGeneration);
          }

          if (imageGenerationUpdate.isError) {
            this.notifyOnImageGenerationError(imageGenerationUpdate.imageGeneration.generationMode);
          }
        }
      });
  }

  notifyOnImageGenerationError(generationMode: ImageGenerationMode) {
    switch (generationMode) {
      case "upscale":
        this.notificationService.error($localize`:@@image-generation.generation.variant-image.upscale.error:`);
        break;
      case "unzoom":
        this.notificationService.error($localize`:@@image-generation.generation.variant-image.unzoom.error:`);
        break;
      default:
        this.notificationService.error($localize`:@@image-generation.generation.error:`);
        break;
    }
  }

  onScrollToTopImageGenerationResults() {
    this.scrollToTopImageGenerationResults.next();
  }

  async getImageGenerationById(id: number): Promise<ImageGeneration | undefined> {
    return await this.imageGenerationApi.getImageGenerationById(id);
  }

  async getImageGenerationBasicStyles(): Promise<ImageGenerationBasicStyle[]> {
    if (this.basicStyles.length === 0) {
      this.basicStyles = await this.loadImageGenerationBasicStyles();
    }
    return this.basicStyles;
  }

  async loadImageGenerationBasicStyles(): Promise<ImageGenerationBasicStyle[]> {
    const stylesWithLocalizations = await this.imageGenerationApi.getImageGenerationBasicStyles();
    const styles = stylesWithLocalizations.map((s) => this.updateBasicStyleLocalization(s));
    return styles;
  }

  async getImageGenerationAdvancedStyles(): Promise<ImageGenerationAdvancedStyle[]> {
    if (this.advancedStyles.length === 0) {
      this.advancedStyles = await this.loadImageGenerationAdvancedStyles();
    }
    return this.advancedStyles;
  }

  async loadImageGenerationAdvancedStyles(): Promise<ImageGenerationAdvancedStyle[]> {
    const stylesWithLocalizations = await this.imageGenerationApi.getImageGenerationAdvancedStyles();
    const styles = stylesWithLocalizations.map((s) => this.updateAdvancedStyleLocalization(s));
    return styles;
  }

  updateBasicStyleLocalization(data: ImageGenerationStyleDto) {
    const locale = this.i18nService.getLocale();
    let localeData = data?.localization?.find((l) => l.lang === locale);
    if (!localeData) {
      localeData =
        data?.localization?.length > 0
          ? data.localization[0]
          : {
              id: 0,
              value: "",
              lang: "",
            };
    }

    const styleData = _.omit(data, ["localization"]);
    const style: ImageGenerationBasicStyle = {
      ...styleData,
      title: localeData.value,
    };
    return style;
  }

  updateAdvancedStyleLocalization(data: ImageGenerationAdvancedStyleDto) {
    const locale = this.i18nService.getLocale();
    let localeData = data?.localization?.find((l) => l.lang === locale);
    if (!localeData) {
      localeData =
        data?.localization?.length > 0
          ? data.localization[0]
          : {
              id: 0,
              value: "",
              lang: "",
            };
    }

    const techniqueData = _.omit(data, ["localization"]);
    const technique: ImageGenerationAdvancedStyle = {
      ...techniqueData,
      title: localeData.value,
    };
    return technique;
  }

  async getLastImageGenerations(): Promise<LastImageGenerationsDataDto> {
    return await this.imageGenerationApi.getLastImageGenerations();
  }

  async loadImageGenerationsPaginated(page: number) {
    const imageGenerationsData = await this.imageGenerationApi.loadImageGenerationsPaginated(page);
    this.imageGenerationStore.addImageGenerationsToEnd(imageGenerationsData.items);
    this.imageGenerationStore.setImageGenerationsPageCount(imageGenerationsData.pageCount);
  }

  async loadImageGenerations() {
    const imageGenerations = await this.imageGenerationApi.loadImageGenerations();
    this.imageGenerationStore.setImageGenerations(imageGenerations);
  }

  async loadVariantImageGenerations(rootGenerationId: number, generatedImageId: number) {
    const variantImageGenerations = await this.imageGenerationApi.getVariantGenerations(
      rootGenerationId,
      generatedImageId,
    );
    this.variantImageGenerationStore.setVariantImageGenerations(rootGenerationId, variantImageGenerations);
  }

  async basicImageGeneration(data: BasicImageGenerationDataDto): Promise<GeneralResultStatus> {
    try {
      await this.imageGenerationApi.basicImageGeneration(data);
      return "success";
    } catch (errorResponse: any) {
      return "error";
    }
  }

  async advancedImageGeneration(data: AdvancedImageGenerationDataDto): Promise<GeneralResultStatus> {
    try {
      await this.imageGenerationApi.advancedImageGeneration(data);
      return "success";
    } catch (errorResponse: any) {
      return "error";
    }
  }

  isAdvancedGeneration(settings: ImageGeneration): boolean {
    return (settings.techniqueId !== undefined && settings.techniqueId >= 0) || settings.generationMode === "advanced";
  }

  async deleteImageGeneration(id: number) {
    return await this.imageGenerationApi.deleteImageGeneration(id);
  }

  async upscaleImage(data: UpscaleSettings) {
    return await this.imageGenerationApi.upscaleGeneratedImage(data);
  }

  async unzoomImage(data: UnzoomSettings) {
    return await this.imageGenerationApi.unzoomGeneratedImage(data);
  }

  async loadPrices(): Promise<ImageGenerationPrices> {
    return await this.imageGenerationApi.loadPrices();
  }

  async getUpscalePrice(generatedImageId: number): Promise<number> {
    return await this.imageGenerationApi.getUpscalePrice(generatedImageId);
  }

  async checkCredits(data: ImageGenerationCreditCheckData): Promise<BalanceData | "error"> {
    try {
      const response = await this.imageGenerationApi.hasEnoughCredits(data);
      return response;
    } catch (error: any) {
      console.error(error);

      return "error";
    }
  }

  roundToNumber(number: number, numberToRound = 10) {
    return number % numberToRound ? number + numberToRound - (number % numberToRound) : number;
  }

  convertMmToPx(value: number) {
    const coefficient = 3.7795751758;
    return value * coefficient;
  }

  convertColorSchemeToString(data: GenerationImageColorSettings): string {
    if (data.colorScheme !== "custom") {
      return `${data.colorScheme} color scheme`;
    }
    let colorScheme = data.colors.join(" and ");
    if (!colorScheme) {
      colorScheme = "fullcolor";
    }
    return `${colorScheme} color scheme`;
  }

  convertStringToColorScheme(value: string) {
    let colorSchemeData: GenerationImageColorSettings = {
      colorScheme: "fullcolor",
      colors: [],
    };
    if (value !== "") {
      let colors: GenerationImageCustomColor[] = [];
      let colorScheme = "fullcolor";
      if (value.includes("black and white")) {
        colorScheme = "black and white";
      }
      if (!value.includes("fullcolor") && !value.includes("black and white")) {
        colorScheme = "custom";
        colors = value
          .replaceAll("and", "")
          .replaceAll("color scheme", "")
          .replaceAll(/\s+/gm, " ")
          .trim()
          .split(" ") as GenerationImageCustomColor[];
      }

      colorSchemeData = {
        colorScheme: colorScheme as GenerationImageColorScheme,
        colors: colors,
      };
    }

    return colorSchemeData;
  }

  getRandomValue(values: string[]) {
    const i = Math.floor(Math.random() * values.length);
    return values[i];
  }

  getImageMegapixel(image: GeneratedImage): number {
    return Math.ceil((image.width * image.height) / 1_000_000);
  }

  orderGeneratedImages(generatedImages: GeneratedImage[] | undefined) {
    return _.orderBy(generatedImages, ["id"], ["desc"]);
  }

  getAge(value: string): string {
    switch (value.toLowerCase()) {
      case "каменный век":
        return "stone-age";
      case "бронзовый век":
        return "bronze-age";
      case "железный век":
        return "iron-age";
      case "античность":
        return "antiquity";
      case "средневековье":
        return "middle-ages";
      case "возрождение":
        return "renaissance";
      case "индустриализация":
        return "industrialization";
      case "современность":
        return "modernity";
      case "будущее":
        return "future";
      default:
        return "modernity";
    }
  }

  getAgeData(generationSettings: ImageGeneration | undefined) {
    let age = generationSettings?.age ?? "modernity";
    let ageYear = new Date().getFullYear();
    if (generationSettings?.age && /^[0-9]{1,4}$/.test(generationSettings.age)) {
      age = "set-year";
      ageYear = Number.parseInt(generationSettings.age);
    }
    return { age, ageYear };
  }

  getMood(value: string): string {
    switch (value.toLowerCase()) {
      case "дьявольское":
        return "devilish";
      case "злое":
        return "evil";
      case "сумасшедшее":
        return "crazy";
      case "драматичное":
        return "dramatic";
      case "грустное":
        return "sad";
      case "мистическое":
        return "mystical";
      case "нейтральное":
        return "neutral";
      case "героическое":
        return "heroic";
      case "романтичное":
        return "romantic";
      case "радостное":
        return "joyful";
      case "счастливое":
        return "happy";
      default:
        return "neutral";
    }
  }

  getMoodData(generationSettings: ImageGeneration | undefined) {
    const moodList = [
      "devilish",
      "evil",
      "crazy",
      "dramatic",
      "sad",
      "mystical",
      "neutral",
      "heroic",
      "romantic",
      "joyful",
      "happy",
    ];
    if (generationSettings?.mood && moodList.includes(generationSettings.mood)) {
      return generationSettings.mood;
    }
    return "";
  }
}
