import { Injectable } from "@angular/core";
import { BookCoverTemplate } from "@metranpage/book-data";
import { CoverTemplateApi } from "./cover-template.api";

@Injectable({
  providedIn: "root",
})
export class CoverTemplateService {
  constructor(private readonly coverTemplateApi: CoverTemplateApi) {}

  async getTemplates(): Promise<BookCoverTemplate[]> {
    return this.coverTemplateApi.getTemplates();
  }

  async deleteTemplate(templateId: number): Promise<void> {
    return this.coverTemplateApi.deleteTemplate(templateId);
  }

  /////////////////////////////
  // Images

  getTemplatePreviewImageUrl(templateId: number): string {
    return this.coverTemplateApi.getTemplatePreviewImageUrl(templateId);
  }
}
