import { Injectable } from "@angular/core";
import { Company, CompanyStore } from "@metranpage/company";
import { NotificationsPopUpService } from "@metranpage/core";
import { CreateTelegramProfileDto } from "@metranpage/user-data";
import { ConnectTelegramServiceInterface } from "@metranpage/user-interfaces";
import { Subject, Subscription } from "rxjs";
import { ConnectTelegramApiService } from "./connect-telegram.api";

@Injectable({
  providedIn: "root",
})
export class ConnectTelegramService implements ConnectTelegramServiceInterface {
  onShowConnectModal$ = new Subject<void>();
  onSubscribeToChannel$ = new Subject<void>();
  onCheckSubscribeToChannel$ = new Subject<void>();

  sub: Subscription = new Subscription();

  company?: Company;

  protected token?: string;

  constructor(
    private readonly notificationService: NotificationsPopUpService,
    private readonly api: ConnectTelegramApiService,
    private readonly companyStore: CompanyStore,
  ) {
    this.sub.add(
      this.companyStore.getCompanyObservable().subscribe((company) => {
        this.company = company;
      }),
    );
  }

  async createToken() {
    const result = await this.api.createOneTimeTelegramIntegrationToken();
    this.token = result.token;
  }

  isTelegramConnectionAvailable() {
    return !!this.company?.telegramSettings?.canConnect;
  }

  showConnectTelegramModal() {
    this.onShowConnectModal$.next();
  }

  subscribeToChannel() {
    this.onSubscribeToChannel$.next();
  }

  checkSubscribeToChannel() {
    this.onCheckSubscribeToChannel$.next();
  }

  connectTelegramProfile() {
    try {
      window.open(`${this.company?.telegramSettings?.botUrl}?start=${this.token}`, "_blank");
      return { status: "success" };
    } catch (errorResponse: any) {
      this.notificationService.error($localize`:@@user.profile.socials.telegram.link-account.error:`);
      return { status: "error" };
    }
  }

  async createTelegramProfile(data: CreateTelegramProfileDto) {
    return await this.api.createTelegramProfile(data);
  }

  async deleteTelegramProfile() {
    try {
      await this.api.deleteTelegramProfile();
      return "success";
    } catch (errorResponse: any) {
      return "error";
    }
  }

  async subscribeToChannelTelegram() {
    if (!this.company?.telegramSettings?.channelUrl) {
      return;
    }
    window.open(this.company?.telegramSettings?.channelUrl, "_blank");
  }

  async checkSubscriptionToChannel() {
    try {
      const result = await this.api.checkSubscriptionToChannel();

      if (result.isSubscribed) {
        this.notificationService.notify({
          content: $localize`:@@user.profile.socials.telegram.subscription-to-channel-verified:`,
          type: "success",
        });
      }
      return { status: "success", isSubscribed: result.isSubscribed };
    } catch (errorResponse: any) {
      this.notificationService.error($localize`:@@user.profile.socials.telegram.check-subscription-to-channel-error:`);
      return { status: "error" };
    }
  }
}
