import { Injectable } from "@angular/core";
import { RealtimeService } from "@metranpage/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { Subject } from "rxjs";
import {
  ImageGenerationUserStyle,
  ImageGenerationUserStyleDataDto,
} from "../../models/image-generation-user-style/image-generation-user-style";
import { ImageGenerationUserStyleApi } from "./image-generation-user-style.api";
import { ImageGenerationUserStyleStore } from "./image-generation-user-style.store";

export type CreateImageGenerationUserStyleResultStatus = GeneralResultStatus | "file-limit-error";
export type CreateImageGenerationUserStyleResponse = {
  userStyleReference?: ImageGenerationUserStyle;
  error?: string;
};

@Injectable({
  providedIn: "root",
})
export class ImageGenerationUserStyleService {
  onChangeUserStyleReference$ = new Subject<number>();

  constructor(
    private readonly imageGenerationUserStyleStore: ImageGenerationUserStyleStore,
    private readonly imageGenerationUserStyleApi: ImageGenerationUserStyleApi,
    readonly realtimeService: RealtimeService,
  ) {
    realtimeService
      .getEvents<ImageGenerationUserStyle | undefined>("image-generation-user-style-result")
      .subscribe((userStyle: ImageGenerationUserStyle | undefined) => {
        this.imageGenerationUserStyleStore.setUserStyle(userStyle);
      });
  }

  onChangeUserStyleReference(userStyleReferenceId: number) {
    this.onChangeUserStyleReference$.next(userStyleReferenceId);
  }

  async loadUserStyle() {
    const userStyle = await this.imageGenerationUserStyleApi.getStyle();
    this.imageGenerationUserStyleStore.setUserStyle(userStyle);
  }

  async createStyle(data: ImageGenerationUserStyleDataDto, file: File | undefined) {
    try {
      return await this.imageGenerationUserStyleApi.createStyle(data, file);
    } catch (error: any) {
      return { error: "request-error" };
    }
  }

  getUrlForImage(styleId: number, imagePath: string, isThumbnail = false) {
    if (isThumbnail) {
      return this.imageGenerationUserStyleApi.getUrlForThumbnailImage(styleId, imagePath);
    }
    return this.imageGenerationUserStyleApi.getUrlForImage(styleId, imagePath);
  }
}
