import { Injectable } from "@angular/core";
import { RunningTitlesState } from "@metranpage/book-data";
import { Store } from "@metranpage/state";

@Injectable({
  providedIn: "root",
})
export class RunningTitlesStore extends Store<RunningTitlesState> {
  protected override getInitialState(): RunningTitlesState {
    return {
      fontSizeInPixeles: 12,
      runningTitleState: true,
      runningTitleLeft: 2,
      runningTitleLeftText: "",
      runningTitleRight: 1,
      runningTitleRightText: "",
      runningTitleStyle: 1,
      runningTitlePosition: 0,
      pageNumberState: true,
      pageNumberStyle: 1,
      pageNumberPosition: 0,
    };
  }

  getRunningTitlesState() {
    return this.getState();
  }

  getRunningTitlesStateObservable() {
    return this.getStateObservable();
  }

  setRunningTitlesState(runningTitlesState: RunningTitlesState) {
    this.update((state) => {
      return runningTitlesState;
    });
  }

  getFontSizeInPixeles() {
    return this.getField("fontSizeInPixeles");
  }

  setFontSizeInPixeles(value: number) {
    this.update((state) => ({ ...state, fontSizeInPixeles: value }));
  }

  getRunningTitleState() {
    return this.getField("runningTitleState");
  }

  setRunningTitleState(value: boolean) {
    this.update((state) => ({ ...state, runningTitleState: value }));
  }

  getRunningTitleLeft() {
    return this.getField("runningTitleLeft");
  }

  setRunningTitleLeft(value: number) {
    this.update((state) => ({ ...state, runningTitleLeft: value }));
  }

  getRunningTitleLeftText() {
    return this.getField("runningTitleLeftText");
  }

  setRunningTitleLeftText(value: string) {
    this.update((state) => ({ ...state, runningTitleLeftText: value }));
  }

  getRunningTitleRight() {
    return this.getField("runningTitleRight");
  }

  setRunningTitleRight(value: number) {
    this.update((state) => ({ ...state, runningTitleRight: value }));
  }

  getRunningTitleRightText() {
    return this.getField("runningTitleRightText");
  }

  setRunningTitleRightText(value: string) {
    this.update((state) => ({ ...state, runningTitleRightText: value }));
  }

  getRunningTitleStyle() {
    return this.getField("runningTitleStyle");
  }

  setRunningTitleStyle(value: number) {
    this.update((state) => ({ ...state, runningTitleStyle: value }));
  }

  getRunningTitlePosition() {
    return this.getField("runningTitlePosition");
  }

  setRunningTitlePosition(value: number) {
    this.update((state) => ({ ...state, runningTitlePosition: value }));
  }

  getPageNumberState() {
    return this.getField("pageNumberState");
  }

  setPageNumberState(value: boolean) {
    this.update((state) => ({ ...state, pageNumberState: value }));
  }

  getPageNumberStyle() {
    return this.getField("pageNumberStyle");
  }

  setPageNumberStyle(value: number) {
    this.update((state) => ({ ...state, pageNumberStyle: value }));
  }

  getPageNumberPosition() {
    return this.getField("pageNumberPosition");
  }

  setPageNumberPosition(value: number) {
    this.update((state) => ({ ...state, pageNumberPosition: value }));
  }
}
