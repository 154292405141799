import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

export type LayoutStep = {
  id: number;
  name: string;
  title: string;
  isRunning: boolean;
  isDone: boolean;
};
export type LayoutState = {
  isPreviewsReady: boolean;
  isFinalsReady: boolean;
  layoutStep: string;
  actionKey?: string;
};

@UntilDestroy()
@Component({
  selector: 'm-layout-steps',
  templateUrl: './layout-steps.component.html',
  styleUrls: ['./layout-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutStepsComponent {
  protected layoutSteps: LayoutStep[] = [
    {
      id: 0,
      name: 'start',
      title: $localize`:@@books.layout-steps.step-start:`,
      isRunning: true,
      isDone: false,
    },
    {
      id: 1,
      name: 'masterSpread',
      title: $localize`:@@books.layout-steps.step-1:`,
      isRunning: false,
      isDone: false,
    },
    {
      id: 2,
      name: 'anchoredObjects',
      title: $localize`:@@books.layout-steps.step-2:`,
      isRunning: false,
      isDone: false,
    },
    {
      id: 3,
      name: 'grepTag',
      title: $localize`:@@books.layout-steps.step-3:`,
      isRunning: false,
      isDone: false,
    },
    {
      id: 4,
      name: 'pictures',
      title: $localize`:@@books.layout-steps.step-4:`,
      isRunning: false,
      isDone: false,
    },
    {
      id: 5,
      name: 'stop',
      title: $localize`:@@books.layout-steps.step-stop:`,
      isRunning: false,
      isDone: false,
    },
  ];

  private stepNumber = 0;

  constructor(private readonly changeDetectionRef: ChangeDetectorRef) {}

  @Input()
  set layoutState(value: LayoutState) {
    if (value.isFinalsReady) {
      this.setStepsDone(this.layoutSteps);
      return;
    }

    this.updateSteps(value.layoutStep, value.isPreviewsReady);
    this.stepNumber++;
  }

  private resetSteps(steps: LayoutStep[]) {
    steps.forEach((element) => {
      element.isRunning = false;
      element.isDone = false;
    });
  }

  private setStepsDone(steps: LayoutStep[]) {
    steps.forEach((element) => {
      element.isRunning = false;
      element.isDone = true;
    });
  }

  updateSteps(step: string, isPreviewsReady: boolean) {
    const currentStep = this.layoutSteps.find((item) => step.includes(item.name));
    if (!currentStep) {
      return;
    }
    this.resetSteps(this.layoutSteps);

    currentStep.isDone = false;
    currentStep.isRunning = true;

    if (step === 'stop' && isPreviewsReady) {
      currentStep.isDone = true;
      currentStep.isRunning = false;
    }

    const currentStepId = currentStep.id;
    const previousSteps = this.layoutSteps.filter((item) => item.id < currentStepId);
    this.setStepsDone(previousSteps);
  }
}
