<ng-container>
  <div class="wrapper">
    <div class="left-wrapper">
      <m-ng-icon class="telegram-icon" [size]="64" src="reward-telegram.svg" />

      <div class="content-wrapper">
        <div class="tasks-wrapper">
          <div class="task-group">
            <m-ng-icon
              *ngIf="isTaskConnectTelegramProfileCompleted()"
              class="task-completed-icon"
              [size]="16"
              src="reward-task-completed.svg"
            />
            <m-ng-icon
              *ngIf="!isTaskConnectTelegramProfileCompleted()"
              class="task-uncompleted-icon"
              [size]="16"
              src="reward-task-uncompleted.svg"
            />

            <div class="task-text">
              <span i18n="@@user.profile.reward-tasks.subscribe-to-telegram-channel.task-1">
                user.profile.reward-tasks.subscribe-to-telegram-channel.task-1
              </span>
              <span
                class="action"
                i18n="@@user.profile.reward-tasks.subscribe-to-telegram-channel.task-1-action"
                (click)="onNavigateToProfileClick()"
              >
                user.profile.reward-tasks.subscribe-to-telegram-channel.task-1-action
              </span>
            </div>
          </div>

          <div class="task-group">
            <m-ng-icon
              *ngIf="isTaskSubscribeToTelegramChannelCompleted()"
              class="task-completed-icon"
              [size]="16"
              src="reward-task-completed.svg"
            />
            <m-ng-icon
              *ngIf="!isTaskSubscribeToTelegramChannelCompleted()"
              class="task-uncompleted-icon"
              [size]="16"
              src="reward-task-uncompleted.svg"
            />

            <div class="task-text">
              <span i18n="@@user.profile.reward-tasks.subscribe-to-telegram-channel.task-2">
                user.profile.reward-tasks.subscribe-to-telegram-channel.task-2
              </span>
              <span
                class="action"
                (click)="onSubscribeToChannelTelegramClick()"
                i18n="@@user.profile.reward-tasks.subscribe-to-telegram-channel.task-2-action"
              >
                user.profile.reward-tasks.subscribe-to-telegram-channel.task-2-action
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="userRewardOneTime" class="reward-wrapper">
          <span i18n="@@user.profile.reward-tasks.subscribe-to-telegram-channel.you-get-text">
            user.profile.reward-tasks.subscribe-to-telegram-channel.you-get-text
          </span>
          <m-ng-icon class="reward-icon" [size]="24" src="reward-tokens.svg" />
          <span class="reward">{{ userRewardOneTime.reward.rewardCreditsCount }}</span>
        </div>
      </div>
    </div>

    <div class="right-wrapper">
      <m-button
        button-style="secondary"
        icon-position="left"
        icon="button-circle-arrows.svg"
        text-align="center"
        i18n="@@user.profile.reward-tasks.subscribe-to-telegram-channel.button"
        [is-disabled]="!canCheckSubscriptionToChannel()"
        (onClick)="onCheckSubscribeToChannelTelegramClick()"
      >
        user.profile.reward-tasks.subscribe-to-telegram-channel.button
      </m-button>
    </div>
  </div>
</ng-container>
