<m-modal position="center" [closeOnBackDropClick]="false" (close)="onCancelAddition.emit()">
  <form *ngIf="form" [formGroup]="form">
    <p><b i18n="@@admin.referal-links.add-referal-link">admin.referal-links.add-referal-link</b></p>

    <div class="controls">
      <div class="control">
        <span class="label" i18n="@@admin.referal-links.referal-link.token">
          admin.referal-links.referal-link.token
        </span>
        <div class="group">
          <m-ng-input formControlName="token" />
          <m-icon
            title="common.copy-text"
            i18n-title="@@common.copy-text"
            class="icon"
            size="s"
            src="plus.svg"
            color="--color-font-main"
            (click)="onGenerateToken()"
          />
        </div>
      </div>

      <div *ngIf="referalLinkUrl" class="ref-link-preview">
        <span class="ref-link">{{referalLinkUrl}}</span>
        <m-icon
          title="common.copy-text"
          i18n-title="@@common.copy-text"
          class="icon"
          size="s"
          src="copy.svg"
          color="--color-font-main"
          (click)="onCopyReferalLinkUrl()"
        />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.referal-links.referal-link.agent-fee">
          admin.referal-links.referal-link.agent-fee
        </span>
        <m-ng-input-number formControlName="agentFee" />
      </div>
    </div>

    <div class="buttons">
      <m-button button-style="secondary" (onClick)="onCancel()" i18n="@@admin.common.cancel">
        admin.common.cancel
      </m-button>
      <m-button
        button-style="primary"
        [is-disabled]="!form.valid"
        (onClick)="createReferalLink()"
        i18n="@@admin.common.create"
      >
        admin.common.create
      </m-button>
    </div>
  </form>
</m-modal>
