<div class="container">
  <div class="group-circles">
    <div
      class="circle scheme-fullcolor"
      [class.active]="isColorSchemeActive('fullcolor')"
      (click)="onSelect('fullcolor')"
    ></div>
    <div
      class="circle scheme-black-and-white"
      [class.active]="isColorSchemeActive('black and white')"
      (click)="onSelect('black and white')"
    ></div>
    <div class="circle scheme-custom" [class.active]="isColorSchemeActive('custom')" (click)="onSelect('custom')">
      <m-ng-icon src="gear.svg" [size]="16" />
    </div>
  </div>

  <div *ngIf="isColorSchemeCustom()" class="divider"></div>

  <div *ngIf="isColorSchemeCustom()" class="group-colors">
    <div *ngFor="let color of value.colors" class="color-preview" [ngStyle]="{'background-color': color}">
      <m-ng-icon src="close-cross.svg" [size]="16" class="delete-icon" (click)="onColorDelete(color)" />
    </div>

    <div
      *ngIf="isAddColorVisible()"
      class="color"
      [ngClass]="getCssClassList()"
      (click)="toggleColorSelector()"
      (mouseenter)="onColorSelectorMouseEnter()"
      (mouseleave)="onColorSelectorMouseLeave()"
    >
      <m-ng-icon src="plus.svg" [size]="24" class="add-icon" />

      <m-colors-menu *ngIf="isColorSelectorVisible">
        <div *ngFor="let colorsGroup of colorsGroups" class="colors-row">
          <div
            *ngFor="let color of colorsGroup"
            class="color"
            [ngStyle]="{'background-color': color}"
            (click)="onColorAdd(color)"
          ></div>
        </div>
      </m-colors-menu>
    </div>
  </div>
</div>
