import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BookRouterService, BookService, BooksStore, CreateProjectEvent, NewProjectService } from "@metranpage/book";
import { Book } from "@metranpage/book-data";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { AnalyticsService, LoadingService, NotificationsPopUpService, filterUndefined } from "@metranpage/core";
import { ConfigService, Social } from "@metranpage/core-interfaces";
import { OnboardingService } from "@metranpage/onboarding";
import { PricingService } from "@metranpage/pricing";
import { ActiveSubscription, Tariff } from "@metranpage/pricing-data";
import { User, UserStore } from "@metranpage/user-data";
import { Observable, Subscription, map } from "rxjs";
import { HomeService } from "../../services/home.service";

@Component({
  selector: "m-home-page",
  templateUrl: "./home.page.html",
  styleUrls: ["./home.page.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class HomePage implements OnInit, OnDestroy {
  protected user?: User;

  projects: Book[] = [];
  projects$?: Observable<Book[]>;

  onboardingStarted = false;

  protected activeSubscription?: ActiveSubscription;
  protected higherTariff?: Tariff;

  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;

  sub: Subscription = new Subscription();

  protected isChangeTariffModalVisible = false;

  protected socials: Social[] = [];

  protected isNewProjectModalVisible = true;

  protected isLoginModalVisible = false;

  protected hasPremium = true;
  protected isPremiumLabelVisible = false;
  protected isPremiumModalVisible = false;

  constructor(
    private readonly onboardingService: OnboardingService,
    private readonly pricingService: PricingService,
    private readonly bookRouterService: BookRouterService,
    private readonly bookService: BookService,
    private readonly booksStore: BooksStore,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly analytics: AnalyticsService,
    private readonly router: Router,
    @Inject("ConfigService")
    readonly configService: ConfigService,
    readonly userStore: UserStore,
    readonly homeService: HomeService,
    private readonly newProjectService: NewProjectService,
  ) {
    if (userStore.getUser()) {
      this.sub.add(
        userStore
          .getUserObservable()
          .pipe()
          .subscribe((user) => {
            this.user = user;
          }),
      );

      this.projects$ = booksStore.getBooksObservable().pipe(map((bl) => bl.filter((b) => b.isVisibleToUser)));
      this.sub.add(
        this.projects$.subscribe((books) => {
          this.projects = books;
          this.updatePremiumLabel();
        }),
      );

      this.sub.add(
        this.onboardingService.onStartOnboarding$.pipe().subscribe(() => {
          this.startOnboarding(true);
        }),
      );

      this.sub.add(
        userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
          this.activeSubscription = activeSubscription;
          this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
          this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;

          if (!activeSubscription) {
            return;
          }
          this.getHigherTariff();

          this.hasPremium = this.pricingService.hasPremium(this.activeSubscription);
          this.updatePremiumLabel();
        }),
      );

      this.sub.add(
        newProjectService.createProjectEvent$.pipe(filterUndefined()).subscribe((event) => {
          newProjectService.resetNewProjectModal();

          if (this.projects.length >= 1 && !this.hasPremium) {
            this.showPremiumModal();
            return;
          }

          if (
            this.projects.length >= (this.activeSubscription?.tariff.activeProjects || 1) &&
            !this.activeSubscription?.tariff.isUnlimited
          ) {
            this.isChangeTariffModalVisible = true;
            return;
          }

          this.onAddNewProjectClick(event);
        }),
      );
    }

    this.sub.add(
      homeService.showLoginModalEvent$.pipe().subscribe(() => {
        this.showLoginModal();
      }),
    );

    this.socials = configService.getConfig().company.socials || [];
  }

  async ngOnInit() {
    this.analytics.event("home");

    if (this.user) {
      this.loadingService.startLoading({ fullPage: true });
      const result = await this.bookService.updateBooksPaginated(1);
      this.loadingService.stopLoading();
      if (result === "error") {
        this.notificationService.error($localize`:@@books.error.cant-load-books:`);
      }

      // this.startOnboarding();
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  startOnboarding(showForced = false) {
    const url = this.router.routerState.snapshot.url.replaceAll("/", "");
    if ((!this.onboardingStarted || showForced) && url === "") {
      this.onboardingStarted = true;
      this.onboardingService.startOnboarding("home-page", showForced);
    }
  }

  protected async showNewProjectModal() {
    if (this.projects.length >= 1 && !this.hasPremium) {
      this.showPremiumModal();
      return;
    }

    if (
      this.projects.length >= (this.activeSubscription?.tariff.activeProjects || 1) &&
      !this.activeSubscription?.tariff.isUnlimited
    ) {
      this.isChangeTariffModalVisible = true;
      return;
    }

    this.newProjectService.selectNewProjectType();
  }

  protected async onAddNewProjectClick(event: CreateProjectEvent) {
    if (
      this.projects.length >= (this.activeSubscription?.tariff.activeProjects || 1) &&
      !this.activeSubscription?.tariff.isUnlimited
    ) {
      this.isChangeTariffModalVisible = true;
      return;
    }

    this.analytics.event("create-new-project");

    await this.bookService.onAddNewProjectClick(event);
  }

  protected onClickChangeTariffModal() {
    this.router.navigate(["payments", "subscription"]);
  }

  protected onCloseChangeTariffModal() {
    this.isChangeTariffModalVisible = false;
  }

  protected async getHigherTariff() {
    this.higherTariff = await this.pricingService.getHigherTariff();
  }

  protected isSettingsAvailable() {
    const book = this.booksStore.getActiveBook();
    return (this.hasPaidTariff || this.hasTrialPeriod) && !!book?.bookSettings?.isEditable;
  }

  protected getInfoModalTitle() {
    return this.bookService.getInfoModalTitle(this.higherTariff);
  }

  protected getInfoModalText() {
    return this.bookService.getInfoModalText(this.activeSubscription, this.higherTariff);
  }

  protected redirectToLoginPage() {
    this.router.navigate(["/login"]);
  }

  protected showLoginModal() {
    this.isLoginModalVisible = true;
  }

  protected closeLoginModal() {
    this.isLoginModalVisible = false;
  }

  protected showPremiumModal() {
    this.isPremiumModalVisible = true;
  }

  protected closePremiumModal() {
    this.isPremiumModalVisible = false;
  }

  private updatePremiumLabel() {
    this.isPremiumLabelVisible = !this.hasPremium && !!this.projects.length;
  }
}
