import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import { User } from "../../companyadmin/models/user";
import { CompaniesData, CompaniesFilter, Company, CompanyCreateData } from "../models/company";
import { FeatureToggle } from "../models/feature-toggle";
import { SuperadminLoginRequest, SuperadminLoginResponse } from "../models/superadmin";

@Injectable({
  providedIn: "root",
})
export class SuperadminApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async login(data: SuperadminLoginRequest) {
    return this.post<SuperadminLoginResponse>("superadmin/auth", data);
  }

  async createCompany(data: CompanyCreateData) {
    return this.post<{ id: number }>("superadmin/companies/", data);
  }
  async getCompaniesPaginated(page: number, filters: CompaniesFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);

    return this.get<CompaniesData>("superadmin/companies", {
      page,
      ...clearedFilters,
    });
  }
  async getCompany(id: number) {
    return this.get<Company>(`superadmin/companies/${id}`);
  }
  async updateCompanyData(companyId: number, data: { title: string }) {
    return this.post<unknown>("superadmin/update-company-data", {
      companyId,
      ...data,
    });
  }
  async deleteCompany(companyId: number) {
    return this.delete(`superadmin/companies/${companyId}`);
  }

  async getCompanyAdmins(id: number) {
    return this.get<User[]>(`superadmin/companies/${id}/admins`);
  }
  async addCompanyAdmin(id: number, data: { email: string }) {
    return this.post<{ id: number }>(`superadmin/companies/${id}/admins`, data);
  }

  async getApiToken(id: number) {
    return this.get<{ token: string }>(`superadmin/companies/${id}/api-token`);
  }
  async resetApiToken(id: number) {
    return this.post<{ token: string }>(`superadmin/companies/${id}/api-token`, {});
  }

  async getFeatureToggles() {
    return this.get<FeatureToggle[]>("superadmin/feature-toggles");
  }
  async setFeatureToggle(title: string, isEnabled: boolean) {
    return this.post<unknown>("superadmin/feature-toggles", {
      title,
      isEnabled,
    });
  }
  /* async getUsers(page: number, filters: UsersFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<UsersData>("admin/users", {
      page,
      ...clearedFilters,
    });
  }

  async addCredits(userId: number, creditsBody: { credits: number; goldCredits: number }) {
    return this.post<void>("admin/add-credits", {
      ...creditsBody,
      userId,
    });
  }

  getTemplates() {
    return this.get<Template[]>("admin/templates");
  }

  getTemplatesPaginated(page: number, filters: TemplatesFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<TemplatesData>("admin/templates-paginated", {
      page,
      ...clearedFilters,
    });
  }

  getTemplate(templateId: number) {
    return this.get<TemplateData>(`admin/templates/${templateId}`);
  }

  async createTemplate(data: TemplateCreateData) {
    return this.post<{ id: number }>("admin/templates/", data);
  }

  async updateTemplate(data: TemplateFull) {
    return this.post<void>(`admin/templates/${data.id}`, data);
  }

  copyTemplate(templateId: number) {
    return this.post<{ id: number }>(`admin/templates/${templateId}/copy`, {});
  }

  deleteTemplate(templateId: number) {
    return this.delete<void>(`admin/templates/${templateId}`);
  }

  getTemplatePreviews(templateId: number) {
    return this.get<TemplatePreviewsData>(`admin/templates/${templateId}/previews`);
  }

  async uploadTemplatePreview(templateId: number, file: File) {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post(`admin/templates/${templateId}/previews`, formData, {
      headers,
    });
  }

  deleteTemplatePreview(id: number) {
    return this.delete<void>(`admin/templates/${0}/previews/${id}`);
  }


  // Companies

  // Tariffs
  async createTariff(data: CompanyCreateData) {
    return this.post<{ id: number }>("admin/tariffs/", data);
  }

  async copyTariff(tariffId: number) {
    return this.post<{ id: number }>(`admin/tariffs/${tariffId}/copy`, {});
  }

  async getTariff(tariffId: number) {
    return this.get<TariffData>(`admin/tariffs/${tariffId}`);
  }

  async getTariffs() {
    return this.get<Tariff[]>("admin/tariffs");
  }

  async getTariffsForCompany(companyId: number) {
    return this.get<Tariff[]>(`admin/tariffs-for-company/${companyId}`);
  }

  async getTariffsPaginated(page: number, filters: TariffsFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);

    return this.get<TariffsData>("admin/tariffs-paginated", {
      page,
      ...clearedFilters,
    });
  }

  async updateTariff(tariffId: number, data: TariffCreateData) {
    return this.post<void>("admin/update-tariff-data", {
      tariffId,
      ...data,
    });
  }

  async deleteTariff(tariffId: number) {
    return this.delete(`admin/tariffs/${tariffId}`);
  }

  // Promocodes
  async createPromocode(data: PromocodeCreateData) {
    return this.post<{ id: number }>("admin/promocodes/", data);
  }

  async getPromocode(promocodeId: number) {
    return this.get<PromocodeData>(`admin/promocodes/${promocodeId}`);
  }

  async getPromocodes() {
    return this.get<Promocode[]>("admin/promocodes");
  }

  async getPromocodesPaginated(page: number, filters: PromocodesFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);

    return this.get<PromocodesData>("admin/promocodes-paginated", {
      page,
      ...clearedFilters,
    });
  }

  async updatePromocode(promocodeId: number, promocodeData: PromocodeUpdateData) {
    return this.post<Promocode>("admin/update-promocode-data", {
      id: promocodeId,
      ...promocodeData,
    });
  }

  async deletePromocode(promocodeId: number) {
    return this.delete(`admin/promocodes/${promocodeId}`);
  }

  // Referal Links
  async createReferalLink(data: ReferalLinkCreateData) {
    return this.post<{ id: number }>("admin/referal-links/", data);
  }

  async getReferalLink(referalLinkId: number) {
    return this.get<ReferalLinkData>(`admin/referal-links/${referalLinkId}`);
  }

  async getReferalLinks() {
    return this.get<ReferalLink[]>("admin/referal-links");
  }

  async getReferalLinksPaginated(page: number, filters: ReferalLinksFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);

    return this.get<ReferalLinksData>("admin/referal-links-paginated", {
      page,
      ...clearedFilters,
    });
  }

  async updateReferalLink(referalLinkId: number, referalLinkData: ReferalLinkUpdateData) {
    return this.post<ReferalLink>("admin/update-referal-link-data", {
      id: referalLinkId,
      ...referalLinkData,
    });
  }

  async deleteReferalLink(referalLinkId: number) {
    return this.delete(`admin/referal-links/${referalLinkId}`);
  }

  async updateReferalLinkStat(referalLinkId: number) {
    return this.get<ReferalLinkStat[]>(`admin/referal-links/${referalLinkId}/stat`);
  } */
}
