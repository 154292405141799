import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { BehaviorSubject } from "rxjs";
import { NotificationType, ShowOptions } from "../components/notification-pop-up/notification.component";

const DefaultTimeout = 10;

export type NotificationData = {
  id?: number;
  content: string;
  type?: NotificationType;
  showOpts?: ShowOptions;
  showOptsTimeout?: number;
};

@Injectable({
  providedIn: "root",
})
export class NotificationsPopUpService {
  protected stack: NotificationData[] = [];
  protected lastId = 1;

  notifications$ = new BehaviorSubject<NotificationData[]>([]);

  constructor(private readonly sanitizer: DomSanitizer) {}

  notify(data: NotificationData) {
    const defaultSettings: NotificationData = {
      id: this.lastId + 1,
      type: "info",
      showOpts: "close-after-time",
      showOptsTimeout: DefaultTimeout,
      content: "",
    };

    data = {
      ...defaultSettings,
      ...data,
      content: this.sanitizer.bypassSecurityTrustHtml(data.content) as string,
      // content: this.sanitizer.sanitize(SecurityContext.HTML, data.content)!,
    };

    if (data.showOpts === "close-after-time") {
      setTimeout(
        () => {
          this.closeNotification(data.id!);
        },
        (data.showOptsTimeout ?? DefaultTimeout) * 1000,
      );
    }

    this.stack.push(data);
    this.notifications$.next(this.stack);
    this.lastId++;
  }

  error(content: string) {
    this.notify({
      content,
      type: "error",
      showOpts: "dont-close",
    });
  }

  closeNotification(id: number) {
    this.stack = this.stack.filter((d) => d.id !== id);
    this.notifications$.next(this.stack);
  }

  closeAll() {
    this.stack = [];
    this.notifications$.next(this.stack);
  }

  getNotificationStack() {
    return this.stack;
  }
}
