<m-account-notification-card-host [notification]="notification" [isLast]="isLast">
  <div icon class="icons-wrapper">
    <m-ng-icon class="icon-main" [size]="24" src="notification-info.svg" />
  </div>

  <div title class="title-group">
    <div *ngIf="payload.mode === 'final'" class="title">
      <span i18n="@@user.profile.notifications.card.book-layout.final.title-1">
        user.profile.notifications.card.book-layout.final.title-1
      </span>
      <span>«{{ payload.bookTitle }}»</span>
      <span i18n="@@user.profile.notifications.card.book-layout.final.title-2">
        user.profile.notifications.card.book-layout.final.title-2
      </span>
    </div>
    <div *ngIf="payload.mode === 'preview'" class="title">
      <span i18n="@@user.profile.notifications.card.book-layout.preview.title-1">
        user.profile.notifications.card.book-layout.preview.title-1
      </span>
      <span>«{{ payload.bookTitle }}»</span>
      <span i18n="@@user.profile.notifications.card.book-layout.preview.title-2">
        user.profile.notifications.card.book-layout.preview.title-2
      </span>
    </div>

    <div class="card-label" i18n="@@user.profile.notifications.filter.important">
      user.profile.notifications.filter.important
    </div>
  </div>

  <div *ngIf="payload.mode === 'final'" description class="description">
    <div class="row">
      <span i18n="@@user.profile.notifications.card.book-layout.final.description-1">
        user.profile.notifications.card.book-layout.final.description-1
      </span>
      <a class="action" (click)="openBookPage()">«{{ payload.bookTitle }}»</a>
      <span i18n="@@user.profile.notifications.card.book-layout.final.description-2">
        user.profile.notifications.card.book-layout.final.description-2
      </span>
    </div>
  </div>
  <div *ngIf="payload.mode === 'preview'" description class="description">
    <div class="row">
      <span i18n="@@user.profile.notifications.card.book-layout.preview.description-1">
        user.profile.notifications.card.book-layout.preview.description-1
      </span>
      <a class="action" (click)="openBookPage()">«{{ payload.bookTitle }}»</a>
      <span i18n="@@user.profile.notifications.card.book-layout.preview.description-2">
        user.profile.notifications.card.book-layout.preview.description-2
      </span>
    </div>
  </div>
</m-account-notification-card-host>
