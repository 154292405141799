<m-ng-island>
  <div class="container">
    <div class="form-panel">
      <div class="header">
        <div i18n="@@user.auth.new-password-title">user.auth.new-password-title</div>
        <m-lang-select-2 [userLanguage]="userLanguage" (changeLang)="onLangChange($event)" />
      </div>

      <div class="reset-pass-description" i18n="@@user.auth.reset-password-description">
        user.auth.reset-password-description
      </div>

      <form [formGroup]="form" (keydown)="onKeyDown($event)">
        <div class="inputs">
          <div class="input-group">
            <m-ng-input-password
              class="password"
              name="password"
              [withLeftIcon]="true"
              i18n-placeholder="@@user.auth.password-placeholder"
              placeholder="@@user.auth.password-placeholder"
              formControlName="password"
              (focusout)="focusoutEvent('password')"
            />

            <m-login-error *ngIf="isInputPasswordErrorVisible && isFieldEmpty('password')" error="field-required" />
          </div>

          <div class="input-group">
            <m-ng-input-password
              class="password"
              name="password"
              [withLeftIcon]="true"
              i18n-placeholder="@@user.auth.password-confirmation-placeholder"
              placeholder="@@user.auth.password-confirmation-placeholder"
              formControlName="passwordConfirm"
              (focusout)="focusoutEvent('passwordConfirm')"
            />

            <m-login-error *ngIf="isPasswordsDifferent()" error="passwords-are-different" />
            <m-login-error
              *ngIf="isInputPasswordConfirmErrorVisible && isFieldEmpty('passwordConfirm')"
              error="field-required"
            />
          </div>
        </div>

        <m-login-error *ngFor="let error of errors" [error]="error"></m-login-error>

        <div class="action-group">
          <m-button
            button-style="primary"
            size="full-width"
            i18n="@@user.auth.change-password-button"
            (onClick)="onResetPassButtonClick()"
          >
            user.auth.change-password-button
          </m-button>
        </div>
      </form>
    </div>
  </div>

  <m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>
</m-ng-island>
