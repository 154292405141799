import { ElementRef, Injectable, Renderer2 } from "@angular/core";
import { CellItem } from "../../../../../views/markup-editor/blocks/table-block/interfaces/cell-item.type";
import { CellPosition } from "../../../../../views/markup-editor/blocks/table-block/interfaces/cell-position.interface";
import { Cell } from "../../../../../views/markup-editor/blocks/table-block/interfaces/cell.type";
import { SelectedCells } from "../../../../../views/markup-editor/blocks/table-block/interfaces/selected-cells.interface";
import { TableData } from "../../../../../views/markup-editor/blocks/table-block/interfaces/table-data.interface";
import { TableBlockService } from "../../../../../views/markup-editor/blocks/table-block/services/table-block.service";

@Injectable({
  providedIn: "root",
})
export class TableOperationService {
  constructor(private readonly tableBlockService: TableBlockService) {}

  addRow(
    direction: "above" | "below",
    editorContainer: ElementRef,
    renderer: Renderer2,
    tableSelectedCells: SelectedCells,
    count = 1,
  ) {
    // Get offset for target index
    let offset = 0;
    let cellPosition = tableSelectedCells.start;
    if (direction === "below") {
      offset = 1;
      cellPosition = tableSelectedCells.stop;
    }

    const rowIndex = cellPosition?.rI;
    const colIndex = cellPosition?.cI;
    if (rowIndex !== undefined && colIndex !== undefined) {
      for (let n = 0; n < count; n++) {
        const data = this.tableBlockService.htmlToRaw(editorContainer.nativeElement);
        // Make table schema
        const tableSchema = this.makeTableSchema(data);
        let rowIndexOrigin = rowIndex;
        const rowIndexTarget = rowIndexOrigin + offset;
        if (offset === 1 && rowIndexOrigin + 1 <= tableSchema.rows.length - 1) {
          rowIndexOrigin++;
        }
        // Create empty row
        const emptyRow = this.createEmptyRow(tableSchema.rows, rowIndexOrigin);
        // Insert empty row to table
        this.insertEmptyRow(tableSchema, emptyRow, rowIndexTarget);
        // Update rows indexes
        this.updateRowsIndexesAdd(tableSchema.rows, rowIndexTarget, offset);
        // Update cells rowspan
        this.updateRowsCellsRowspanAdd(tableSchema.rows, rowIndexTarget, offset);
        // Update cells colspan
        this.updateRowsCellsColspanAdd(tableSchema.rows, rowIndexTarget, offset);
        // Clear rows
        this.clearRows(tableSchema.rows);
        // Update table view
        this.tableBlockService.setDataToHtml(tableSchema, editorContainer, renderer);
      }
    }
  }

  addColumn(
    direction: "left" | "right",
    editorContainer: ElementRef,
    renderer: Renderer2,
    tableSelectedCells: SelectedCells,
    count = 1,
  ) {
    // Get offset for target index
    let offset = 0;
    let cellPosition = tableSelectedCells.start;
    if (direction === "right") {
      offset = 1;
      cellPosition = tableSelectedCells.stop;
    }
    const rowIndex = cellPosition?.rI;
    const colIndex = cellPosition?.cI;
    if (rowIndex !== undefined && colIndex !== undefined) {
      for (let n = 0; n < count; n++) {
        const data = this.tableBlockService.htmlToRaw(editorContainer.nativeElement);
        // Make table schema
        const tableSchema = this.makeTableSchema(data);
        // Get cell index from schema
        let schemaCellIndex = colIndex;
        const schemaRow = tableSchema.rows[rowIndex];
        // Find edge index of span cell
        schemaCellIndex = this.updateEdgeCellIndexAdd(schemaRow, schemaCellIndex, rowIndex, colIndex, offset);
        // Process cells
        this.processCellsAdd(tableSchema.rows, tableSchema, schemaCellIndex, offset);
        // Clear rows
        this.clearRows(tableSchema.rows);
        // Update table view
        this.tableBlockService.setDataToHtml(tableSchema, editorContainer, renderer);
      }
    }
  }

  deleteRow(count: number, editorContainer: ElementRef, renderer: Renderer2, tableSelectedCells: SelectedCells) {
    const cellPosition = tableSelectedCells.start;
    const rowIndex = cellPosition?.rI;
    const colIndex = cellPosition?.cI;
    if (rowIndex !== undefined && colIndex !== undefined) {
      for (let n = 0; n < count; n++) {
        const data = this.tableBlockService.htmlToRaw(editorContainer.nativeElement);
        // Make table schema
        const tableSchema = this.makeTableSchema(data);
        // Get delete row
        const deletedSchemaRow = tableSchema.rows.splice(rowIndex, 1)[0];
        // Update cells rowspan
        this.updateRowsCellsRowspanDelete(tableSchema.rows, deletedSchemaRow);
        // Update rows indexes
        this.updateRowsIndexesDelete(tableSchema.rows, deletedSchemaRow, rowIndex);
        // Clear rows
        this.clearRows(tableSchema.rows);
        // Update table view
        this.tableBlockService.setDataToHtml(tableSchema, editorContainer, renderer);
      }
    }
  }

  deleteColumn(count: number, editorContainer: ElementRef, renderer: Renderer2, tableSelectedCells: SelectedCells) {
    const cellPosition = tableSelectedCells.start;
    const rowIndex = cellPosition?.rI;
    const colIndex = cellPosition?.cI;
    if (rowIndex !== undefined && colIndex !== undefined) {
      for (let n = 0; n < count; n++) {
        const data = this.tableBlockService.htmlToRaw(editorContainer.nativeElement);
        // Make table schema
        const tableSchema = this.makeTableSchema(data);
        const schemaCellIndex = colIndex;
        // Get deleted cell
        for (let i = 0; i < tableSchema.rows.length; i++) {
          const row = tableSchema.rows[i];
          const deletedSchemaCell = row.splice(schemaCellIndex, 1)[0];
          // Update cells colspan
          this.updateRowsCellsColspanDelete(row, deletedSchemaCell);
          // Update cols indexes
          this.updateColsIndexesDelete(row, deletedSchemaCell, schemaCellIndex);
        }
        // Clear rows
        this.clearRows(tableSchema.rows);
        // Update table view
        this.tableBlockService.setDataToHtml(tableSchema, editorContainer, renderer);
      }
    }
  }

  private updateRowsCellsColspanDelete(row: Cell[], deletedCell: Cell) {
    for (let j = 0; j < row.length; j++) {
      const cell = row[j];
      if (cell.row === deletedCell.row && cell.col === deletedCell.col) {
        if (cell.colspan > 1) {
          cell.colspan--;
        }
      }
    }
  }

  unionCells(
    event: any,
    tableSelectedCells: SelectedCells,
    editorContainer: ElementRef,
    renderer: Renderer2,
    tableSelectedRowsCount: number,
    tableSelectedColumnsCount: number,
    selectedCells: HTMLTableCellElement[],
  ) {
    const rowIndexStart = tableSelectedCells.start?.rI;
    const colIndexStart = tableSelectedCells.start?.cI;
    const colOffsetStart = tableSelectedCells.start?.colOffset;
    const rowIndexStop = tableSelectedCells.stop?.rI;
    const colIndexStop = tableSelectedCells.stop?.cI;
    const colOffsetStop = tableSelectedCells.stop?.colOffset;
    if (
      rowIndexStart !== undefined &&
      colIndexStart !== undefined &&
      colOffsetStart !== undefined &&
      rowIndexStop !== undefined &&
      colIndexStop !== undefined &&
      colOffsetStop !== undefined
    ) {
      this.clearAllCellsSelected(editorContainer);
      selectedCells = [];

      const data = this.tableBlockService.htmlToRaw(editorContainer.nativeElement);
      // Make table schema
      const tableSchema = this.makeTableSchema(data);

      let rowSpan = tableSelectedRowsCount;
      let colSpan = tableSelectedColumnsCount;
      const items = [] as CellItem[];

      const unitedCells = [] as CellPosition[];

      // Calculate rowspan and colspan for union cell
      const columnsSettings = {} as any;
      for (let i = rowIndexStart; i <= rowIndexStop; i++) {
        const row = tableSchema.rows[i];
        for (let j = colIndexStart; j <= colIndexStop + colOffsetStop; j++) {
          const cell = row[j];
          if (!columnsSettings[j]) {
            columnsSettings[j] = { rowSpan: 1, colSpan: 1 };
          }
          if (cell.rowspan > 1) {
            columnsSettings[j].rowSpan = columnsSettings[j].rowSpan + cell.rowspan - 1;
          }
          if (cell.colspan > 1) {
            columnsSettings[j].colSpan = columnsSettings[j].colSpan + cell.colspan - 1;
          }
        }
      }
      for (const value of Object.values(columnsSettings) as any) {
        if (value.rowSpan > rowSpan) {
          rowSpan = value.rowSpan;
        }
        if (value.colSpan > colSpan) {
          colSpan = value.colSpan;
        }
      }

      for (let i = rowIndexStart; i <= rowIndexStop; i++) {
        const row = tableSchema.rows[i];
        for (let j = colIndexStart; j <= colIndexStop + colOffsetStop; j++) {
          const cell = row[j];
          const findedCell = unitedCells.find((item: any) => item.rI === cell.row && item.cI === cell.col);
          if (cell.items && !findedCell) {
            unitedCells.push({ rI: cell.row, cI: cell.col });
            items?.push(...cell.items);
          }
          cell.items = items;
          cell.row = rowIndexStart;
          cell.col = colIndexStart;
          cell.rowspan = rowSpan;
          cell.colspan = colSpan;
        }
      }
      // Clear rows
      this.clearRows(tableSchema.rows);
      // Update table view
      this.tableBlockService.setDataToHtml(tableSchema, editorContainer, renderer);
    }
  }

  clearAllCellsSelected(editorContainer: ElementRef) {
    const table = editorContainer.nativeElement.querySelector("table") as HTMLTableElement;
    const cells = table.querySelectorAll("td");
    cells.forEach((cell) => {
      cell.classList.remove("selected");
    });
  }

  private clearRows(rows: Array<Cell[]>) {
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      // Remove cell not from this row
      for (let j = row.length - 1; j >= 0; j--) {
        const cell = row[j];
        if (cell.row !== undefined && cell.row !== i) {
          row.splice(j, 1);
        }
      }
      // Remove united cells
      let prevCell: Cell = row[row.length - 1];
      for (let j = row.length - 2; j >= 0; j--) {
        const cell = row[j];
        if (cell.row === prevCell.row && cell.col === prevCell.col) {
          row.splice(j, 1);
        }
        prevCell = cell;
      }
    }
  }

  private getCellElement(element: HTMLElement) {
    if (element) {
      const cell = element?.closest("td");
      if (cell) {
        return cell;
      }
    }
    return undefined;
  }

  private getTableParameters(table: HTMLTableElement) {
    const tableRows = table.rows as HTMLCollectionOf<HTMLTableRowElement>;
    const tableRowsCount = tableRows.length ?? 0;
    let tableColumnsCount = 0;
    for (let i = 0; i < tableRowsCount; i++) {
      if (tableColumnsCount < tableRows[i].cells.length) {
        tableColumnsCount = tableRows[i].cells.length;
      }
    }
    return { rowsCount: tableRowsCount, columnsCount: tableColumnsCount };
  }

  private getSelectedCellsPosition(selectedCells: HTMLTableCellElement[]) {
    const cellsPosition: CellPosition[] = [];
    selectedCells.forEach((cell: HTMLTableCellElement) => {
      const row = cell.closest("tr");
      if (row) {
        const rowIndex = row.rowIndex;
        const colIndex = cell.cellIndex;
        if (rowIndex >= 0 && colIndex >= 0) {
          cellsPosition.push({ rI: rowIndex, cI: colIndex });
        }
      }
    });
    return cellsPosition;
  }

  getSelectedCellsPositionSchema(selectedCells: HTMLTableCellElement[], editorContainer: ElementRef) {
    const cellsPosition: CellPosition[] = [];
    selectedCells.forEach((cell: HTMLTableCellElement) => {
      const cellPosition = this.getCellPosition(cell, editorContainer);
      if (cellPosition) {
        cellsPosition.push(cellPosition);
      }
    });
    return cellsPosition;
  }

  private getCellPosition(cellElement: HTMLTableCellElement | null | undefined, editorContainer: ElementRef) {
    if (cellElement) {
      const cell = cellElement as HTMLTableCellElement;
      if (cell) {
        const row = cell?.parentNode as HTMLTableRowElement;
        const rowIndex = row.rowIndex;
        const columnIndex = cell.cellIndex;
        const data = this.tableBlockService.htmlToRaw(editorContainer.nativeElement);
        const tableSchema = this.makeTableSchema(data);
        const schemaRow = tableSchema.rows[rowIndex];
        let schemaCellIndex: number | undefined = undefined;
        let rowOffset = 0;
        let colOffset = 0;
        const cells = schemaRow.filter((element: Cell, index: number) => {
          return element.row === rowIndex && element.col === index;
        });
        if (cells) {
          for (let j = 0; j < cells.length; j++) {
            rowOffset = 0;
            colOffset = 0;
            const cellsItem = cells[j];
            if (cellsItem.rowspan > 1) {
              rowOffset = cellsItem.rowspan - 1;
            }
            if (cellsItem.colspan > 1) {
              colOffset = cellsItem.colspan - 1;
            }
            if (j === columnIndex) {
              break;
            }
          }
          schemaCellIndex = cells[columnIndex].col;
        }
        return {
          rI: rowIndex,
          cI: schemaCellIndex,
          rowOffset: rowOffset,
          colOffset: colOffset,
        };
      }
    }
    return undefined;
  }

  makeTableSchema(data: TableData) {
    const tableSchema = {
      rowsCount: data.rowsCount,
      colsCount: data.colsCount,
      rows: Array.from(Array(data.rowsCount), () => {
        return Array.from(Array(data.colsCount), () => {
          return {} as Cell;
        }) as Cell[];
      }) as Array<Cell[]>,
    } as TableData;

    for (let r = 0; r < data?.rows?.length; r++) {
      const row = data?.rows[r];
      let z = 0;
      for (let c = 0; c < row?.length; c++) {
        const cell = row[c];
        while (tableSchema.rows[r][c + z] && Object.keys(tableSchema.rows[r][c + z])?.length !== 0) {
          z++;
        }
        for (let i = 0; i < cell.rowspan; i++) {
          for (let j = 0; j < cell.colspan; j++) {
            if (tableSchema.rows[r + i] && tableSchema.rows[r + i][c + z + j]) {
              tableSchema.rows[r + i][c + z + j] = {
                row: r,
                col: c + z,
                rowspan: data?.rows[r][c].rowspan,
                colspan: data?.rows[r][c].colspan,
                items: data?.rows[r][c].items,
              };
            }
          }
        }
      }
    }

    return tableSchema;
  }

  private createEmptyRow(rows: Array<Cell[]>, rowIndexOrigin: number) {
    const originRow = rows[rowIndexOrigin];
    const emptyRow = [] as Array<Cell>;
    for (let i = 0; i < originRow.length; i++) {
      const originCell = originRow[i];
      emptyRow.push({
        row: originCell.row,
        col: originCell.col,
        rowspan: originCell.rowspan,
        colspan: originCell.colspan,
        items: [
          {
            content: "",
          } as CellItem,
        ] as CellItem[],
      } as Cell);
    }
    return emptyRow;
  }

  private insertEmptyRow(tableSchema: TableData, emptyRow: Cell[], rowIndexTarget: number) {
    if (rowIndexTarget > tableSchema.rows.length) {
      tableSchema.rows.push(emptyRow);
      rowIndexTarget--;
    } else {
      tableSchema.rows.splice(rowIndexTarget, 0, emptyRow);
    }
    tableSchema.rowsCount = tableSchema.rows.length;
  }

  private createEmptyCell(originCell: Cell) {
    const emptyCell = {
      row: originCell.row,
      col: originCell.col,
      rowspan: originCell.rowspan,
      colspan: originCell.colspan,
      items: [
        {
          content: "",
        } as CellItem,
      ] as CellItem[],
    } as Cell;
    return emptyCell;
  }

  private updateEdgeCellIndexAdd(row: Cell[], cellIndex: number, rowIndex: number, colIndex: number, offset: number) {
    if (row[cellIndex].colspan > 1) {
      for (let j = 0; j < row.length; j++) {
        const cell = row[j];
        if (cell.row === rowIndex && cell.col === colIndex) {
          cellIndex = j;
          if (offset <= 0) {
            break;
          }
        }
      }
    }
    return cellIndex;
  }

  private processCellsAdd(rows: Array<Cell[]>, tableSchema: TableData, colIndexOrigin: number, offset: number) {
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const originCell = row[colIndexOrigin];
      let colIndexTarget = colIndexOrigin + offset;
      // Create empty cell
      const emptyCell = this.createEmptyCell(originCell);
      // Insert empty cell to row
      this.insertEmptyCell(tableSchema, row, emptyCell, colIndexTarget);
      if (emptyCell.colspan > 1) {
        const previousIndex = colIndexTarget - 1;
        const nextIndex = colIndexTarget + 1;
        let previousCell = row[0];
        let nextCell = row[row.length - 1];
        if (previousIndex >= 0) {
          previousCell = row[previousIndex];
        }
        if (nextIndex < row.length) {
          nextCell = row[nextIndex];
        }

        if (offset >= 1) {
          if (nextIndex < row.length) {
            const nextCell = row[nextIndex];
            if (emptyCell.row === nextCell.row && emptyCell.col === nextCell.col) {
              for (let j = 0; j < row.length; j++) {
                const cell = row[j];
                if (cell.row === emptyCell.row && cell.col === emptyCell.col) {
                  cell.colspan++;
                  colIndexTarget++;
                }
              }
              emptyCell.items = originCell.items;
            } else {
              emptyCell.colspan = 1;
            }
          }
        } else {
          if (previousIndex >= 0) {
            const previousCell = row[previousIndex];
            if (emptyCell.row === previousCell.row && emptyCell.col === previousCell.col) {
              for (let j = 0; j < row.length; j++) {
                const cell = row[j];
                if (cell.row === emptyCell.row && cell.col === emptyCell.col) {
                  cell.colspan++;
                }
              }
              emptyCell.items = originCell.items;
              colIndexTarget++;
            } else {
              emptyCell.colspan = 1;
            }
          } else {
            emptyCell.colspan = 1;
          }
        }
      }
      // Update cols indexes
      this.updateColumnsIndexesAdd(row, colIndexTarget, offset);
    }
  }

  private insertEmptyCell(tableSchema: TableData, row: Cell[], emptyCell: Cell, colIndexTarget: number) {
    // Insert empty row to table
    if (colIndexTarget > row.length) {
      row.push(emptyCell);
    } else {
      row.splice(colIndexTarget, 0, emptyCell);
    }
    tableSchema.colsCount = row.length;
  }

  private updateColsIndexesDelete(row: Cell[], deletedCell: Cell, cellIndex: number) {
    for (let j = cellIndex; j < row.length; j++) {
      const cell = row[j];
      if (cell.col !== undefined && cell.col > 0) {
        if (cell.col !== deletedCell.col) {
          cell.col--;
        }
      }
    }
  }

  private updateRowsIndexesAdd(rows: Array<Cell[]>, rowIndexTarget: number, offset: number) {
    for (let i = rowIndexTarget + 1; i < rows.length; i++) {
      const row = rows[i];
      for (let j = 0; j < row.length; j++) {
        const cell = row[j];
        if (cell.row !== undefined && cell.row === i - 1) {
          cell.row = cell.row + 1;
        }
      }
    }
    if (offset >= 1) {
      let cellCol = 0;
      let cellColSpan = 1;
      const row = rows[rowIndexTarget];
      for (let j = 0; j < row.length; j++) {
        const cell = row[j];
        if (rowIndexTarget < rows.length - 1) {
          if (cell.row !== undefined && cell.row === rowIndexTarget - 1 && cell.rowspan <= 1) {
            cell.row = cell.row + 1;
          }
        } else {
          if (cell.colspan > 1) {
            if (cellColSpan <= 1) {
              cellColSpan = cell.colspan;
              cellCol++;
            } else {
              cellColSpan--;
            }
          } else {
            cellCol = j;
          }
          cell.row = rowIndexTarget;
          cell.col = cellCol;
          cell.rowspan = 1;
        }
      }
    }
  }

  private updateRowsIndexesDelete(rows: Array<Cell[]>, deletedSchemaRow: Cell[], rowIndex: number) {
    for (let i = rowIndex; i < rows.length; i++) {
      const row = rows[i];
      for (let j = 0; j < row?.length; j++) {
        const cell = row[j];
        if (cell.row !== undefined && cell.row > 0) {
          if (cell.row !== deletedSchemaRow[j].row) {
            cell.row--;
          }
        }
      }
    }
  }

  private updateRowsCellsRowspanAdd(rows: Array<Cell[]>, rowIndexTarget: number, offset: number) {
    const emptyRow = rows[rowIndexTarget];
    for (let j = 0; j < emptyRow.length; j++) {
      const cell = emptyRow[j];
      if (cell.row !== rowIndexTarget) {
        if (cell.rowspan > 1) {
          if (cell.row !== undefined && cell.col !== undefined) {
            const cells = rows[cell.row].filter((elem: Cell) => {
              return elem.row === cell.row && elem.col === cell.col;
            });
            if (cells) {
              cells.forEach((element: Cell) => {
                element.rowspan = cell.rowspan + 1;
              });
            }
          }
        }
      } else {
        if (cell.rowspan > 1) {
          cell.rowspan = 1;
        }
      }
    }
  }

  private updateRowsCellsRowspanDelete(rows: Array<Cell[]>, deletedSchemaRow: Cell[]) {
    for (let k = 0; k < deletedSchemaRow.length; k++) {
      const deletedCell = deletedSchemaRow[k];
      if (deletedCell.rowspan > 1) {
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          for (let j = 0; j < row.length; j++) {
            const cell = row[j];
            if (cell.row === deletedCell.row && cell.col === deletedCell.col) {
              if (cell.rowspan > 1) {
                cell.rowspan--;
              }
            }
          }
        }
      }
    }
  }

  private updateRowsCellsColspanAdd(rows: Array<Cell[]>, rowIndexTarget: number, offset: number) {
    if (offset === 1 && rowIndexTarget < rows.length - 1) {
      const emptyRow = rows[rowIndexTarget];
      for (let j = 0; j < emptyRow.length; j++) {
        const cell = emptyRow[j];
        const cellAbowe = rows[rowIndexTarget - 1][j];
        cell.colspan = cellAbowe.colspan;
        cell.col = cellAbowe.col;
      }
    }
  }

  private updateColumnsIndexesAdd(row: Cell[], colIndexTarget: number, offset: number) {
    let startIndex = colIndexTarget + 1;
    if (offset >= 1) {
      startIndex = colIndexTarget;
    }
    for (let j = startIndex; j < row.length; j++) {
      const cell = row[j];
      if (cell.col !== undefined) {
        cell.col = cell.col + 1;
      }
    }
  }

  onAddRow(
    editorContainer: ElementRef,
    selectedCells: HTMLTableCellElement[],
    hoveredCell: HTMLTableCellElement | undefined,
    tableSelectedCells: SelectedCells,
    renderer: Renderer2,
  ) {
    this.clearAllCellsSelected(editorContainer);
    selectedCells = [];
    if (hoveredCell) {
      selectedCells.push(hoveredCell);
      const cellsPosition = this.getSelectedCellsPositionSchema(selectedCells, editorContainer);
      tableSelectedCells = this.getSelectedCellsRegion(cellsPosition);
      this.addRow("below", editorContainer, renderer, tableSelectedCells);
    }
  }

  getSelectedCellsRegion(cellsPosition: CellPosition[]) {
    const startCell = cellsPosition.reduce((acc, curr) => {
      if (curr.rI !== undefined && curr.cI !== undefined && acc.rI !== undefined && acc.cI !== undefined) {
        if (acc.rI < curr.rI) {
          return acc;
        } else {
          if (acc.rI === curr.rI) {
            if (acc.cI < curr.cI) {
              return acc;
            } else {
              return curr;
            }
          } else {
            return curr;
          }
        }
      } else {
        return curr;
      }
    });
    const stopCell = cellsPosition.reduce((acc, curr) => {
      if (curr.rI !== undefined && curr.cI !== undefined && acc.rI !== undefined && acc.cI !== undefined) {
        if (acc.rI > curr.rI) {
          return acc;
        } else {
          if (acc.rI === curr.rI) {
            if (acc.cI > curr.cI) {
              return acc;
            } else {
              return curr;
            }
          } else {
            return curr;
          }
        }
      } else {
        return curr;
      }
    });
    return { start: startCell, stop: stopCell } as SelectedCells;
  }
}
