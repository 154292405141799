<div class="header">
  <m-button
    *ngIf="!runInEditMode"
    button-style="tertiary"
    icon="button-back.svg"
    icon-position="only"
    (onClick)="onBackClick()"
  />

  <span>{{ contentHeader }}</span>

  <m-button button-style="primary" [is-disabled]="!form?.valid" (onClick)="onNextClick()">
    {{ getActionButtonText() }}
  </m-button>
</div>

<div class="divider-hor"></div>

<form *ngIf="form" [formGroup]="form" class="content">
  <div *ngFor="let section of formData; let last = last" class="section" [formGroupName]="section.name">
    <div class="section-title">
      <span>{{ section.title }}</span>

      <m-switch *ngIf="section.enableControl" [formControlName]="section.enableControl.name" input-style="on-panel">
        <span class="label">{{ section.enableControl.title }}</span>
      </m-switch>
    </div>

    <div class="controls">
      <div *ngFor="let control of section.controls" class="control">
        <ng-container [ngSwitch]="control.type">
          <div *ngIf="isControlTitleVisible(control)" class="control-title">
            <span>{{ control.title }}</span>
            <span *ngIf="isFieldRequired(control)" class="required-mark">*</span>
          </div>

          <m-ng-input *ngSwitchCase="'text'" [formControlName]="control.name" [placeholder]="control.placeholder" />

          <m-select
            *ngSwitchCase="'select'"
            [formControlName]="control.name"
            size="m-plus"
            [options]="control.options!"
          ></m-select>

          <m-textarea
            *ngSwitchCase="'textarea'"
            [formControlName]="control.name"
            [rows]="5"
            [placeholder]="control.placeholder"
          />
          <!-- [maxlength]="annotationMaxlength" -->

          <m-ng-input-with-prefix
            *ngSwitchCase="'with-prefix'"
            [formControlName]="control.name"
            text-align="right"
            [prefix]="control.prefix || ''"
            [placeholder]="control.placeholder"
          />

          <m-switch *ngSwitchCase="'switch'" [formControlName]="control.name" input-style="on-panel">
            <span class="label">{{ control.title }}</span>
          </m-switch>

          <m-switch-book-type
            *ngSwitchCase="'type-print'"
            [formControlName]="control.name"
            icon="/assets/img/printed-version-{theme}.png"
          >
            <span class="label">{{ control.title }}</span>
          </m-switch-book-type>

          <m-switch-book-type
            *ngSwitchCase="'type-epub'"
            [formControlName]="control.name"
            icon="/assets/img/electronic-version-{theme}.png"
          >
            <span class="label">{{ control.title }}</span>
          </m-switch-book-type>
        </ng-container>
      </div>
    </div>

    <div *ngIf="!last" class="divider-hor"></div>
  </div>
</form>
