import { CommonModule } from '@angular/common';
import { Component, Input, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'm-loading-spinner-resizable',
  template: `
    <img src="/assets/img/spinner-128.gif" />
    <div class="comment"><ng-content></ng-content></div>
  `,
  styleUrls: ['./loading-spinner-resizable.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class LoadingSpinnerResizableComponent {
  @Input()
  showAppBar = false;

  constructor(private readonly viewRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.applyAppBarStyle();
  }

  applyAppBarStyle() {
    if (this.showAppBar) {
      this.viewRef.element.nativeElement.setAttribute('style', 'margin-top: var(--app-bar-height);');
    }
  }
}
