<div class="preview-info-modal">
  <div class="header">
    <div class="author">
      <div *ngIf="avatarText" class="avatar">{{ avatarText }}</div>
      <div class="author-name">
        <span>{{ image.author.firstName }}</span>
        <span>{{ image.author.lastName }}</span>
      </div>
    </div>

    <div class="likes" [ngClass]="{liked: isLiked}" (click)="onLikeClick($event)">
      <m-ng-icon *ngIf="!isLiked" class="icon-unliked" src="like-1.svg" [size]="25" />
      <m-ng-icon *ngIf="isLiked" class="icon-liked" src="like-2.svg" [size]="25" />

      <span>{{ likesCount }}</span>
    </div>
  </div>

  <div class="divider"></div>

  <div class="settings">
    <div class="settings-group">
      <span class="settings-title" i18n="@@published-image.settings.prompt">published-image.settings.prompt</span>
      <m-prompt class="settings-text" [prompt]="image.generationSettings.prompt" />
    </div>

    <div class="divider"></div>

    <div class="settings-group">
      <span class="settings-title" i18n="@@image-generation.selected-mode">image-generation.selected-mode</span>
      <span
        *ngIf="!isAdvancedGeneration(image.generationSettings)"
        class="settings-text"
        i18n="@@image-generation.selected-mode.basic"
      >
        image-generation.selected-mode.basic
      </span>
      <span
        *ngIf="isAdvancedGeneration(image.generationSettings)"
        class="settings-text"
        i18n="@@image-generation.selected-mode.advanced"
      >
        image-generation.selected-mode.advanced
      </span>
    </div>

    <div class="divider"></div>

    <div *ngIf="image.generationSettings?.styleId && image.generationSettings.styleId > 0" class="settings-group">
      <span class="settings-title" i18n="@@published-image.settings.style">published-image.settings.style</span>
      <span class="settings-text">{{ getStyleTitle(image.generationSettings) }}</span>
    </div>
    <div *ngIf="image.generationSettings?.userStyleId" class="settings-group">
      <span class="settings-title" i18n="@@published-image.settings.style">published-image.settings.style</span>
      <span class="settings-text" i18n="@@published-image.settings.used-style-reference-2">
        published-image.settings.used-style-reference-2
      </span>
    </div>

    <div class="divider"></div>

    <div
      *ngIf="isAdvancedGeneration(image.generationSettings) && getMoodTitle(image.generationSettings.mood)"
      class="settings-group"
    >
      <span class="settings-title" i18n="@@published-image.settings.mood">published-image.settings.mood</span>
      <span class="settings-text">{{ getMoodTitle(image.generationSettings.mood) }}</span>
    </div>

    <div class="divider"></div>

    <div
      *ngIf="isAdvancedGeneration(image.generationSettings) && image.generationSettings.detalization"
      class="settings-group"
    >
      <span class="settings-title" i18n="@@published-image.settings.detalization">
        published-image.settings.detalization
      </span>
      <span class="settings-text">{{ getDetalizationTitle(image.generationSettings.detalization) }}</span>
    </div>

    <div class="divider"></div>

    <div
      *ngIf="isAdvancedGeneration(image.generationSettings) && image.generationSettings.isColorSchemeEnable"
      class="settings-group"
    >
      <span class="settings-title" i18n="@@published-image.settings.color-scheme">
        published-image.settings.color-scheme
      </span>
      <span class="settings-text">{{ getColorSchemeTitle(image.generationSettings.colorScheme) }}</span>
    </div>

    <div class="divider"></div>

    <div
      *ngIf="isAdvancedGeneration(image.generationSettings) && image.generationSettings.isNegativePromptEnable && image.generationSettings.negativePrompt"
      class="settings-group"
    >
      <span class="settings-title" i18n="@@published-image.settings.negative-prompt">
        published-image.settings.negative-prompt
      </span>
      <span class="settings-text">{{ image.generationSettings.negativePrompt }}</span>
    </div>
  </div>

  <div class="modal-buttons">
    <m-button
      button-style="primary"
      size="full-width"
      [is-disabled]="!image"
      (onClick)="onCopyPublishedImageSettings()"
      i18n="@@published-image.settings.use-current-settings"
    >
      published-image.settings.use-current-settings
    </m-button>

    <m-button
      *ngIf="canUnpublish()"
      button-style="secondary"
      icon="book-delete.svg"
      icon-position="only"
      (onClick)="onShowUnpublishImageModal()"
    />
  </div>
</div>
