import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BookCover } from "@metranpage/book-data";
import { SelectValue } from "@metranpage/components";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { takeUntil } from "rxjs";
import { ModerationCoverRequest, ModerationCoverRequestPublishedFrom } from "../../models/moderation-cover-request";
import { AdminModerationCoverRequestService } from "../../services/moderation-cover-request/moderation-cover-request.service";
import { AdminModerationCoverRequestStore } from "../../services/moderation-cover-request/moderation-cover-request.store";
import { AdminBasePage } from "../admin/admin.page";

export type DescriptionOptions = {
  id: number;
  text: string;
};

@Component({
  selector: "m-admin-moderation-covers-page",
  templateUrl: "./moderation-covers.page.html",
  styleUrls: ["./moderation-covers.page.scss"],
})
export class AdminModerationCoversPage extends AdminBasePage implements OnInit, OnDestroy {
  protected form?: FormGroup;

  moderationCoverRequests: ModerationCoverRequest[] = [];
  page = 1;
  pageCount = 1;

  filterPublishedFrom: ModerationCoverRequestPublishedFrom = "user";
  filterOptions: SelectValue[] = [
    { id: "user", value: $localize`:@@admin.moderation.covers.filter-options.user:` },
    { id: "free-tariff", value: $localize`:@@admin.moderation.covers.filter-options.free-tariff:` },
  ];

  protected moderatingCovers: BookCover[] = [];

  protected needSelectFirstCover = true;

  protected previewModerationCoverRequest?: ModerationCoverRequest;
  protected previewModerationCoverRequestIndex?: number;

  protected selectedDescription?: DescriptionOptions;
  protected descriptionOptions: DescriptionOptions[] = [
    { id: 1, text: $localize`:@@admin.moderation.covers.description-options.1:` },
    { id: 2, text: $localize`:@@admin.moderation.covers.description-options.2:` },
    { id: 0, text: $localize`:@@admin.moderation.covers.description-options.0:` },
  ];

  @ViewChild("previewCoverElement", { read: ElementRef })
  previewCoverElement?: ElementRef;

  constructor(
    private readonly adminModerationCoverRequestService: AdminModerationCoverRequestService,
    private readonly adminModerationCoverRequestStore: AdminModerationCoverRequestStore,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();

    this.addSub(
      adminModerationCoverRequestStore
        .getModerationCoverRequestsObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((moderationCoverRequests) => {
          this.moderationCoverRequests = moderationCoverRequests.filter(
            (mcr) => mcr.status === "created" && mcr.publishedFrom === this.filterPublishedFrom,
          );
          this.moderatingCovers = this.moderationCoverRequests.map((mir) => mir.bookCover);

          if (this.moderationCoverRequests.length === 0) {
            this.resetPreviewModerationCoverRequest();
          }

          this.selectFirstCover();
          this.cdr.markForCheck();
        }),
    );

    this.addSub(
      adminModerationCoverRequestStore
        .getModerationCoverRequestsPageCountObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((count) => {
          this.pageCount = count;
        }),
    );
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    this.selectDefaultModeratorDescription();

    await this.adminModerationCoverRequestService.loadModerationCoverRequestsPaginated(
      this.page,
      this.filterPublishedFrom,
    );
  }

  protected async onAcceptClick() {
    if (!this.previewModerationCoverRequest) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminModerationCoverRequestService.acceptModerationCoverRequest(
      this.previewModerationCoverRequest.id,
    );
    this.loadingService.stopLoading();
    if (result === "success") {
      this.selectNextOrPreviousModeratedCover();
    } else {
      this.notificationService.error($localize`:@@admin.moderation.covers.error.update:`);
    }
  }

  protected async onDeclineClick() {
    if (!this.previewModerationCoverRequest) {
      return;
    }

    let moderatorDescription = "";
    if (this.selectedDescription?.id === 0) {
      moderatorDescription = this.form?.get("moderatorDescription")?.value;
    } else {
      moderatorDescription = this.selectedDescription?.text || "";
    }

    if (!moderatorDescription) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminModerationCoverRequestService.declineModerationCoverRequest(
      this.previewModerationCoverRequest.id,
      {
        moderatorDescription,
      },
    );
    this.loadingService.stopLoading();
    if (result === "success") {
      this.selectNextOrPreviousModeratedCover();
    } else {
      this.notificationService.error($localize`:@@admin.moderation.covers.error.update:`);
    }
  }

  protected isDeclineAvailable() {
    return (
      (this.selectedDescription?.id !== 0 && this.selectedDescription?.text !== "") ||
      (this.selectedDescription?.id === 0 && this.form?.valid)
    );
  }

  protected onSelectDescription(selectedDescription: DescriptionOptions) {
    this.selectedDescription = selectedDescription;
  }

  protected onFocusModeratorDescription() {
    this.selectCustomModeratorDescription();
  }

  private selectDefaultModeratorDescription() {
    const descriptionOption = this.descriptionOptions[0];
    if (!descriptionOption) {
      return;
    }
    this.selectedDescription = descriptionOption;
  }

  private selectCustomModeratorDescription() {
    const descriptionOption = this.descriptionOptions.find((o) => o.id === 0);
    if (!descriptionOption) {
      return;
    }
    this.selectedDescription = descriptionOption;
  }

  private selectFirstCover() {
    if (this.moderationCoverRequests.length === 0 || !this.needSelectFirstCover) {
      return;
    }
    this.previewModerationCoverRequestIndex = 0;
    this.previewModerationCoverRequest = this.moderationCoverRequests[this.previewModerationCoverRequestIndex];
    this.needSelectFirstCover = false;
  }

  private selectNextOrPreviousModeratedCover() {
    if (this.moderationCoverRequests.length === 0 || this.previewModerationCoverRequestIndex === undefined) {
      return;
    }

    if (
      this.previewModerationCoverRequestIndex &&
      this.moderationCoverRequests.length === this.previewModerationCoverRequestIndex
    ) {
      this.previewModerationCoverRequestIndex = this.moderationCoverRequests.length - 1;
    }
    this.previewModerationCoverRequest = this.moderationCoverRequests[this.previewModerationCoverRequestIndex];
  }

  private resetPreviewModerationCoverRequest() {
    this.previewModerationCoverRequestIndex = undefined;
    this.previewModerationCoverRequest = undefined;
    this.needSelectFirstCover = true;
  }

  protected async onSelectModerationCoverRequest(bookCover: BookCover) {
    const moderationCoverRequest = this.moderationCoverRequests.find((mir) => mir.bookCover.id === bookCover.id);
    if (!moderationCoverRequest) {
      return;
    }

    this.previewModerationCoverRequest = moderationCoverRequest;
    this.previewModerationCoverRequestIndex = this.moderationCoverRequests.findIndex(
      (mir) => mir.id === moderationCoverRequest.id,
    );
    this.scrollToPreview();
  }

  protected async onScrollEvent() {
    this.page++;
    await this.adminModerationCoverRequestService.loadModerationCoverRequestsPaginated(
      this.page,
      this.filterPublishedFrom,
    );
  }

  protected onPrevious() {
    if (this.previewModerationCoverRequestIndex === undefined || !this.hasPreviousCover()) {
      return;
    }
    this.previewModerationCoverRequestIndex--;
    this.previewModerationCoverRequest = this.moderationCoverRequests[this.previewModerationCoverRequestIndex];
  }

  protected onNext() {
    if (this.previewModerationCoverRequestIndex === undefined || !this.hasNextCover()) {
      return;
    }
    this.previewModerationCoverRequestIndex++;
    this.previewModerationCoverRequest = this.moderationCoverRequests[this.previewModerationCoverRequestIndex];
  }

  protected hasPreviousCover() {
    if (this.previewModerationCoverRequestIndex === undefined) {
      return false;
    }
    return this.previewModerationCoverRequestIndex - 1 >= 0;
  }

  protected hasNextCover() {
    if (this.previewModerationCoverRequestIndex === undefined) {
      return false;
    }
    return this.previewModerationCoverRequestIndex + 1 < this.moderationCoverRequests.length;
  }

  private scrollToPreview() {
    if (!this.previewCoverElement) {
      return;
    }

    this.previewCoverElement.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }

  createForm() {
    this.form = this.formBuilder.group({
      moderatorDescription: this.formBuilder.control("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  protected async onFilterChanged(option: number | string) {
    this.filterPublishedFrom = option as ModerationCoverRequestPublishedFrom;
    this.clearStore();
    this.loadingService.startLoading({ fullPage: true });
    await this.adminModerationCoverRequestService.loadModerationCoverRequestsPaginated(
      this.page,
      this.filterPublishedFrom,
    );
    this.loadingService.stopLoading();
  }

  private clearStore() {
    this.page = 1;
    this.pageCount = 1;
    this.moderationCoverRequests = [];
    this.adminModerationCoverRequestStore.clearModerationCoverRequests();
  }

  @HostListener("window:keydown", ["$event"])
  protected handleKeyDown(event: KeyboardEvent) {
    if (event.key === "ArrowLeft" && this.hasPreviousCover()) {
      this.onPrevious();
    }
    if (event.key === "ArrowRight" && this.hasNextCover()) {
      this.onNext();
    }
  }
}
