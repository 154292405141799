import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "m-superadmin-company-settings-page",
  template: `
    <m-superadmin-company-settings-nav-view />

    <div class="content">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        height: calc(100vh - var(--app-bar-height));
      }

      .content {
      }
    `,
  ],
})
export class SuperadminCompanySettingsHostPage implements OnInit {
  private destroyed$ = new Subject<void>();
  // protected company: Company | undefined = undefined;

  constructor /* private readonly companiesService: CompaniesAdminService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    companiesStore: CompaniesAdminStore, */() {
    /* companiesStore
      .getActiveCompanyObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((company) => {
        this.company = company;
      }); */
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    /* if ("companyId" in this.route.snapshot.params) {
      this.companiesService.loadCompany(this.route.snapshot.params.companyId);
    } */
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
