import { CommonModule } from "@angular/common";
import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from "@angular/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { IconComponent } from "../icon/icon.component";
import { PremiumMarkComponent } from "../premium-mark/premium-mark.component";

export type ButtonStyle = "primary" | "secondary" | "tertiary";
export type ButtonIconPosition = "none" | "left" | "right" | "only";
export type ButtonColorTheme = "primary" | "danger" | "gold";
export type ButtonSize = "xs" | "s" | "m" | "l" | "full-width" | "full-height" | "fill";
export type ButtonTextAlign = "left" | "center" | "right" | "space-between";

@Component({
  selector: "m-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, IconComponent, PremiumMarkComponent],
})
export class ButtonComponent {
  @Input("button-style")
  buttonStyle: ButtonStyle = "tertiary";
  @Input("size")
  buttonSize: ButtonSize = "m";
  @Input()
  icon: string | undefined = undefined;
  @Input("icon-position")
  iconPosition: ButtonIconPosition = "none";
  @Input("color-theme")
  colorTheme: ButtonColorTheme = "primary";
  @Input("text-align")
  textAlign: ButtonTextAlign = "center";
  @Input("show-premium-mark")
  showPremiumMark = false;

  @Output()
  onClick = new EventEmitter<void>();

  @Input("is-disabled")
  @HostBinding("class.disabled")
  isDisabled = false;

  @HostBinding("class.pressed")
  isPressed = false;

  @HostBinding("class")
  get cssClassList() {
    const classes: string[] = [];

    classes.push(`button-style-${this.buttonStyle}`);
    classes.push(`button-color-theme-${this.colorTheme}`);
    classes.push(`button-text-align-${this.textAlign}`);
    classes.push(`button-size-${this.buttonSize}`);

    if (this.iconPosition !== "none") {
      classes.push(`icon-position-${this.iconPosition}`);
    }

    if (this.isDisabled) {
      classes.push("disabled");
    }

    if (this.showPremiumMark) {
      classes.push("with-premium-mark");
    }

    return classes.join(" ");
  }

  getIconSrc() {
    if (this.icon?.startsWith("/")) {
      return this.icon;
    } else {
      return `/assets/icons/${this.icon}`;
    }
  }

  @HostListener("click")
  onClickEvent() {
    if (!this.isDisabled) {
      this.onClick.emit();
    }
  }

  @HostListener("mousedown")
  onMouseDown() {
    if (!this.isDisabled) {
      this.isPressed = true;
    }
  }

  @HostListener("window:mouseup")
  onMouseUp() {
    if (!this.isDisabled) {
      this.isPressed = false;
    }
  }
}
