import { Injectable } from "@angular/core";
import { I18nService } from "@metranpage/i18n";
import { filterUndefined } from "../filterundefined";
import { UserRewardDaily } from "../models/rewards/user-reward-daily";
import { UserRewardOneTime } from "../models/rewards/user-reward-onetime";
import { NotificationsPopUpService } from "./notifications-pop-up.service";
import { RealtimeService } from "./realtime.service";
import { RewardsApi } from "./rewards.api";
import { RewardsStore } from "./rewards.store";

@Injectable({
  providedIn: "root",
})
export class RewardsService {
  constructor(
    private readonly notificationsService: NotificationsPopUpService,
    private readonly rewardsStore: RewardsStore,
    private readonly rewardsApi: RewardsApi,
    realtimeService: RealtimeService,
    private readonly i18nService: I18nService,
  ) {
    realtimeService
      .getEvents<UserRewardDaily>("reward-daily-update")
      .pipe(filterUndefined())
      .subscribe((rewardUpdate: UserRewardDaily) => {
        this.rewardsStore.updateRewardsDaily(rewardUpdate);

        this.processUserRewardDailyUpdate(rewardUpdate);
      });

    realtimeService
      .getEvents<UserRewardOneTime>("reward-onetime-update")
      .pipe(filterUndefined())
      .subscribe((rewardUpdate: UserRewardOneTime) => {
        this.rewardsStore.updateRewardsOneTime(rewardUpdate);
      });
  }

  async loadRewards() {
    const rewardsData = await this.rewardsApi.loadRewards();
    this.rewardsStore.setRewardsDaily(rewardsData.daily);
    this.rewardsStore.setRewardsOneTime(rewardsData.onetime);
  }

  private processUserRewardDailyUpdate(rewardUpdate: UserRewardDaily) {
    const rewardCreditsCount = rewardUpdate.reward.rewardCreditsCount;

    let content = "";

    if (rewardUpdate.reward.type === "likes-give") {
      content = `${$localize`:@@user.profile.rewards.like.give.notification:`} ${this.i18nService.pluralize(
        $localize`:@@common.pluralize.add:`,
        { value: rewardCreditsCount },
      )} ${rewardCreditsCount} ${this.i18nService.pluralize($localize`:@@pricing.pluralize-credits:`, { credits: rewardCreditsCount })} `;
    }

    if (!content) {
      return;
    }

    this.notificationsService.notify({
      content,
      type: "success",
      showOpts: "close-after-time",
      showOptsTimeout: 2,
    });
  }

  getSubscribeToTelegramChannelReward(rewardsOneTime: UserRewardOneTime[]) {
    return rewardsOneTime.find((r) => r.reward.type === "subscribe-to-telegram-channel");
  }
}
