import { Injectable } from "@angular/core";
import { AnalyticsService, RealtimeService, filterUndefined } from "@metranpage/core";
import * as _ from "lodash-es";
import { DownloadGeneratedImageData, GeneratedImage } from "../models/generated-image";
import { GeneratedImageApi } from "./generated-image.api";
import { GeneratedImageStore } from "./generated-image.store";
import { ImageGenerationService } from "./image-generation.service";
import { PublishedImageStore } from "./published-image.store";

export type GeneralResultStatus = "success" | "error";

@Injectable({
  providedIn: "root",
})
export class GeneratedImageService {
  constructor(
    private readonly generatedImageStore: GeneratedImageStore,
    private readonly publishedImageStore: PublishedImageStore,
    private readonly imageGenerationService: ImageGenerationService,
    private readonly generatedImageApi: GeneratedImageApi,
    private readonly analytics: AnalyticsService,
    realtimeService: RealtimeService,
  ) {
    realtimeService
      .getEvents<GeneratedImage>("generated-image-change")
      .pipe(filterUndefined())
      .subscribe((generatedImageUpdate: GeneratedImage) => {
        if (generatedImageUpdate) {
          this.publishedImageStore.updatePublishedImage(generatedImageUpdate);
          this.generatedImageStore.updateGeneratedImage(generatedImageUpdate);
        }
      });
  }

  async getGeneratedImageById(id: number): Promise<GeneratedImage | undefined> {
    return await this.generatedImageApi.getGeneratedImageById(id);
  }

  async getGeneratedImageByIdUnauthorized(id: number) {
    return await this.generatedImageApi.getGeneratedImageByIdUnauthorized(id);
  }

  async loadPublishedImagesPaginated(page: number) {
    const publishedImagesData = await this.generatedImageApi.loadPublishedImagesPaginated(page);
    this.publishedImageStore.addPublishedImagesToEnd(publishedImagesData.items);
    this.publishedImageStore.setPublishedImagesPageCount(publishedImagesData.pageCount);
  }

  async loadPublishedImages() {
    const publishedImages = await this.generatedImageApi.loadPublishedImages();
    this.publishedImageStore.setPublishedImages(publishedImages);
  }

  async loadPublishedImagesPaginatedUnauthorized(page: number) {
    const publishedImagesData = await this.generatedImageApi.loadPublishedImagesPaginatedUnauthorized(page);
    this.publishedImageStore.addPublishedImagesToEnd(publishedImagesData.items);
    this.publishedImageStore.setPublishedImagesPageCount(publishedImagesData.pageCount);
  }

  getUrlForImage(generationId: number, imagePath: string, isThumbnail = false) {
    if (isThumbnail) {
      return this.generatedImageApi.getUrlForThumbnailImage(generationId, imagePath);
    }
    return this.generatedImageApi.getUrlForImage(generationId, imagePath);
  }

  async publishGeneratedImage(id: number) {
    return await this.generatedImageApi.publishGeneratedImage(id);
  }

  async unpublishGeneratedImage(id: number) {
    return await this.generatedImageApi.unpublishGeneratedImage(id);
  }

  async deleteGeneratedImage(id: number) {
    return await this.generatedImageApi.deleteGeneratedImage(id);
  }

  async downloadGeneratedImage(downloadGeneratedImageData: DownloadGeneratedImageData) {
    return await this.generatedImageApi.downloadGeneratedImage(downloadGeneratedImageData);
  }

  getGeneratedImageIndex(generatedImage: GeneratedImage, generatedImages: GeneratedImage[] | undefined) {
    const orderedGeneratedImages = this.imageGenerationService.orderGeneratedImages(generatedImages);
    const index = orderedGeneratedImages.findIndex((gi) => gi.id === generatedImage.id);
    return index;
  }

  async likeGeneratedImage(id: number) {
    this.publishedImageStore.updatePublishedImageOnLike(id, "like");

    this.analytics.event("like-image");

    return await this.generatedImageApi.likeGeneratedImage(id);
  }

  async unlikeGeneratedImage(id: number) {
    this.publishedImageStore.updatePublishedImageOnLike(id, "unlike");

    this.analytics.event("unlike-image");

    return await this.generatedImageApi.unlikeGeneratedImage(id);
  }
}
