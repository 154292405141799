<form [formGroup]="form">
  <h1 i18n="@@admin.format.add-format">admin.format.add-format</h1>

  <div class="controls">
    <div formGroupName="localization">
      <span i18n="@@admin.format.add-format.title">admin.format.add-format.title</span>

      <div class="localizations" *ngFor="let language of getLanguages()" [formGroupName]="language">
        <p class="section-title">{{ language.toUpperCase() }}</p>

        <div class="control">
          <m-ng-input formControlName="title" />
        </div>
      </div>
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.format.add-format.value">admin.format.add-format.value</span>
      <m-ng-input formControlName="name" />
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.format.add-format.type">admin.format.add-format.type</span>
      <m-select formControlName="type" [options]="getFormatTypeOptions()" />
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.format.add-format.width">admin.format.add-format.width</span>
      <m-ng-input-number formControlName="width" />
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.format.add-format.height">admin.format.add-format.height</span>
      <m-ng-input-number formControlName="height" />
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.format.add-format.is-available-to-user">
        admin.format.add-format.is-available-to-user
      </span>
      <m-switch formControlName="isAvailableToUser" />
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.format.add-format.is-available-to-other-companies">
        admin.format.add-format.is-available-to-other-companies
      </span>
      <m-switch formControlName="isVisibleToOtherCompanies" />
    </div>
  </div>

  <div class="buttons">
    <m-button button-style="secondary" (onClick)="onCancel()" i18n="@@admin.common.cancel">
      admin.common.cancel
    </m-button>
    <m-button
      button-style="primary"
      [is-disabled]="!form.valid"
      (onClick)="createFormat()"
      i18n="@@admin.common.create"
    >
      admin.common.create
    </m-button>
  </div>
</form>
