<m-books-toc-bar
  [isTocSettingsEnable]="isTocSettingsEnable()"
  [form]="form"
  [headings]="headings"
  [activeHeadingIndex]="activeHeadingIndex"
  (onHeadingClick)="onHeadingClick($event)"
></m-books-toc-bar>

<div class="content">
  <m-markup-editor
    *ngIf="styles && editorItems.length > 0"
    [styles]="styles"
    [items]="editorItems"
    [styleChangeEvents]="styleChangeEvents"
    [selectBlockEvents]="selectBlockEvents"
    [formChanges]="formChanges"
    [palette]="activePalette"
    [bookId]="(book$ | async)!.id"
    [imageDefaultData]="imageDefaultData"
    [toolbarSettings]="toolbarSettings"
    (onReady)="onEditorReady()"
    (onBlockSelectionChanged)="onBlockSelectionChanged($event)"
    (onHeadingsChanged)="onHeadingsChanged($event)"
    (onBlockHovered)="onBlockHovered($event)"
    (onBlockUpdate)="onBlockUpdate()"
    (onBlockInViewport)="onBlockInViewport($event)"
    (onAiGenerationClick)="onAiGenerationClick($event)"
  ></m-markup-editor>
</div>

<m-fixed-sidebar *ngIf="isStyleDetailsSidebarVisible && form" class="sidebar-right-styles" @slideInOutSidebarLTR>
  <div class="sidebar-content">
    <div *ngFor="let styleKey of getStyleKeysForDetailsSidebar()">
      <m-books-markup-sidebar-style
        *ngIf="styleKey === detailsStyle?.styleKey"
        [style]="getDetailsStyle(styleKey)"
        [form]="form"
        [isSettingsAvailable]="isSettingsAvailable()"
        (onClose)="hideStyleDetailsSidebar()"
      />
    </div>
  </div>
</m-fixed-sidebar>

<m-fixed-sidebar *ngIf="isColorsDetailsVisible && form" class="sidebar-right-styles" @slideInOutSidebarLTR>
  <div class="sidebar-content">
    <form [formGroup]="form">
      <m-books-markup-sidebar-colors
        [form]="form"
        [isSettingsAvailable]="isSettingsAvailable()"
        formControlName="palette"
        (onClose)="hideColorsDetailsSidebar()"
      />
    </form>
  </div>
</m-fixed-sidebar>

<m-fixed-sidebar class="sidebar-right">
  <m-sidebar-tabs
    tab1Icon="markup-sidebar-tab-text.svg"
    i18n-tab1Title="@@books.markup.sidebar-tab-text"
    tab1Title="books.markup.sidebar-tab-text"
    tab2Icon="markup-sidebar-tab-design.svg"
    i18n-tab2Title="@@books.markup.sidebar-tab-design"
    tab2Title="books.markup.sidebar-tab-design"
    [hideTabs]="!shouldDisplaySidebarTabs()"
  >
    <div id="tab1">
      <m-free-tariff-notification-view *ngIf="!hasPaidTariff && !hasUnlimitedTariff" />

      <!-- (onStyleDisplayOptionChanged)="onStyleDisplayOptionChanged($event)" -->
      <m-books-markup-sidebar-text
        *ngIf="form"
        [styles]="stylesSettings"
        [selectedStyles]="selectedStyles"
        [hoveredStyle]="hoveredStyle"
        [availableMainFonts]="availableMainFonts"
        [availableHeadersFonts]="availableHeadersFonts"
        [form]="form"
        [styleDetailsSidebarVisible]="styleDetailsSidebarVisible"
        [isSettingsAvailable]="isSettingsAvailable()"
        (onStyleSelected)="onStyleSelected($event)"
        (onShowStyleDetails)="showStyleDetails($event)"
      />
    </div>

    <div id="tab2">
      <m-free-tariff-notification-view *ngIf="!hasPaidTariff && !hasUnlimitedTariff" />

      <m-books-markup-sidebar-design
        *ngIf="form"
        [isBookPrint]="isTocSettingsEnable()"
        [isBookEpub]="isBookEpub()"
        [availablePalettes]="availablePalettes"
        [form]="form"
        [isSettingsAvailable]="isSettingsAvailable()"
        (onShowColorsDetailsSidebar)="showColorsDetailsSidebar()"
      />
    </div>
  </m-sidebar-tabs>

  <div *ngIf="!isStyleDetailsSidebarVisible && !isColorsDetailsVisible" class="buttons" @fadeInOut>
    <m-button
      button-style="secondary"
      icon-position="only"
      icon="button-save.svg"
      [is-disabled]="!this.form?.valid"
      (onClick)="onSaveClick()"
    />

    <m-button
      button-style="primary"
      size="full-width"
      [is-disabled]="!isNextStepEnable()"
      (onClick)="onNextClick()"
      i18n="@@books.styles.next-button"
    >
      books.styles.next-button
    </m-button>
  </div>
</m-fixed-sidebar>

<m-modal-project-router-host />
