import { CommonModule } from "@angular/common";
import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { ConfigService } from "@metranpage/core-interfaces";

@Component({
  selector: "m-loading-spinner",
  template: `
    <div class="spinner" *ngIf="useSimpleSpinner">
      <svg [attr.viewBox]="getViewBox()" class="svg-spinner"
           xmlns="http://www.w3.org/2000/svg" focusable="false">
        <circle [attr.r]="circleRadius"
                fill="transparent"
                [style.stroke-width.%]="circleStrokeWidth"
                cx="50%" cy="50%"/>
      </svg>
    </div>
    <img src="/assets/img/spinner-128.gif" *ngIf="!useSimpleSpinner" />
    <div class="comment"><ng-content></ng-content></div>
  `,
  styleUrls: ["./loading-spinner.component.scss"],
  standalone: true,
  imports: [CommonModule],
})
export class LoadingSpinnerComponent {
  protected useSimpleSpinner = false;

  protected circleRadius = 40;
  protected circleStrokeWidth = 10;

  constructor(
    @Inject("ConfigService")
    configService: ConfigService,
  ) {
    this.useSimpleSpinner = !!configService.getConfig().company.flags.useSimpleSpinner;
  }

  protected getViewBox() {
    const viewBox = this.circleRadius * 2 + this.circleStrokeWidth;
    return `0 0 ${viewBox} ${viewBox}`;
  }
}
