import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { BookCover } from "@metranpage/book-data";
import { InfoBlockData } from "@metranpage/components";
import { NotificationsPopUpService } from "@metranpage/core";
import { CoverConceptualGeneration, CoverConceptualGenerationDataDto } from "@metranpage/text-generation";
import { Subscription } from "rxjs";

function isEmptyValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value.trim() === "") {
      return { isEmpty: true };
    }
    return null;
  };
}

function minNonEmptyValidator(min: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value.trim().length < min) {
      return { minNonEmpty: true };
    }
    return null;
  };
}

@Component({
  selector: "m-cover-conceptual-assistant-generation-start-modal",
  templateUrl: "./cover-conceptual-assistant-generation-start-modal.view.html",
  styleUrls: ["./cover-conceptual-assistant-generation-start-modal.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverConceptualAssistantGenerationStartModalView implements OnInit, OnChanges, OnDestroy {
  @Input()
  cover!: BookCover;
  @Input()
  coverConceptualGeneration?: CoverConceptualGeneration;

  @Output()
  generateCoverConceptual = new EventEmitter<CoverConceptualGenerationDataDto>();
  @Output()
  closeConceptualAssistant = new EventEmitter<void>();

  protected promptAnnotationMinLength = 10;
  protected promptAnnotationMaxLength = 1000;

  protected infoBlockData: InfoBlockData[] = [];

  protected form?: FormGroup;

  sub: Subscription = new Subscription();

  constructor(
    private readonly notificationService: NotificationsPopUpService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.initForm();
    this.showPreviousResults();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.coverConceptualGeneration) {
      this.coverConceptualGeneration = changes.coverConceptualGeneration.currentValue;
      this.showPreviousResults();
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private showPreviousResults() {
    if (this.coverConceptualGeneration) {
      this.form?.patchValue(this.coverConceptualGeneration);
    }
  }

  async onGenerateCoverConceptualClick() {
    if (!this.form?.valid) {
      this.checkFormErrors();
      return;
    }
    const formData = this.form.getRawValue();
    const data: CoverConceptualGenerationDataDto = {
      promptAnnotation: formData.promptAnnotation.trim().replaceAll("<br>", " "),
      promptGenre: formData.promptGenre.trim().replaceAll("<br>", " "),
      promptTargetAudience: formData.promptTargetAudience.trim().replaceAll("<br>", " "),
      coverId: this.cover.id,
    };
    this.generateCoverConceptual.emit(data);
  }

  onCloseConceptualAssistantClick() {
    this.closeConceptualAssistant.emit();
  }

  private initForm() {
    this.createForm();
    this.watchFormChanges();
  }

  private createForm() {
    this.form = new FormGroup({
      promptAnnotation: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.minLength(this.promptAnnotationMinLength),
          Validators.maxLength(this.promptAnnotationMaxLength),
          minNonEmptyValidator(this.promptAnnotationMinLength),
          isEmptyValidator(),
        ],
      }),
      promptTargetAudience: new FormControl("", { nonNullable: true, validators: [] }),
      promptGenre: new FormControl("", { nonNullable: true, validators: [] }),
    });
  }

  private watchFormChanges() {
    this.sub.add(
      this.form?.valueChanges.subscribe((v) => {
        this.checkFormErrors(false);
      }),
    );
  }

  private checkFormErrors(needNotify = true) {
    this.updateInfoBlockData();
    this.markInvalidControlAsDirty();
    if (needNotify) {
      this.notifyOnFormError();
    }
  }

  private markInvalidControlAsDirty(): void {
    const controls = this.form?.controls;
    for (const k in controls) {
      const control = controls[k];
      if (!control.valid) {
        control.markAsDirty();
      }
    }
  }

  protected notifyOnFormError() {
    if (!this.form) {
      return;
    }

    this.notificationService.error($localize`:@@cover-editor.conceptual-assistant.start-modal.form.error:`);
  }

  protected updateInfoBlockData() {
    this.infoBlockData = [];

    if (this.form?.valid) {
      return;
    }

    const controlErrors = this.form?.get("promptAnnotation")?.errors;
    if (!controlErrors) {
      return;
    }
    const processedErrors: string[] = [];
    for (const error of Object.keys(controlErrors)) {
      const minLengthErrors = ["required", "minlength", "minNonEmpty", "isEmpty"];
      if (minLengthErrors.includes(error)) {
        const processedError = processedErrors.some((v) => minLengthErrors.includes(v));
        if (!processedError) {
          this.infoBlockData.push({
            textData: [
              {
                text: `${$localize`:@@cover-editor.conceptual-assistant.start-modal.form-min-number-characters.error:`}: ${this.promptAnnotationMinLength}`,
              },
            ],
          });
        }
        processedErrors.push(error);
      }
      if (error === "maxlength") {
        this.infoBlockData.push({
          textData: [
            {
              text: `${$localize`:@@cover-editor.conceptual-assistant.start-modal.form-max-number-characters.error:`}: ${this.promptAnnotationMaxLength}`,
            },
          ],
        });
        processedErrors.push(error);
      }
    }

    this.cdr.markForCheck();
  }
}
