<div *ngIf="characters.length > 0 || hasAddButton" class="container">
  <m-ng-scrollbars class="wrapper">
    <div class="items">
      <div
        *ngIf="hasAddButton"
        class="item"
        id="character-add"
        [class.selected]="!selectedCharacter"
        (mouseenter)="onItemMouseEnter(undefined)"
        (click)="onAddCharacterClick()"
      >
        <m-ng-icon class="image" src="plus-2.svg" [size]="32" />
        <span class="title" i18n="@@characters.popup.create">characters.popup.create</span>
      </div>
      <div
        *ngFor="let c of characters; trackBy: trackById"
        class="item"
        [id]="'character-' + c.id"
        [class.selected]="selectedCharacter === c"
        (mouseenter)="onItemMouseEnter(c)"
        (click)="onSelectClick(c)"
      >
        <m-skeleton-image
          class="image"
          [url]="getUrlForImage(c)"
          width="100"
          unit="%"
          backgroudColor="var(--surface-on-island)"
          animationStyle="gradient"
        />
        <span class="title">{{ c.name }}</span>
      </div>
    </div>
  </m-ng-scrollbars>
</div>
