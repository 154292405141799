import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { InfoBlockData, PaletteDTO, fadeInOutForSidebarElements, slideInOutVertical } from "@metranpage/components";

@Component({
  selector: "m-books-markup-sidebar-design",
  templateUrl: "./markup-sidebar-design-settings.view.html",
  styleUrls: ["./markup-sidebar-design-settings.view.scss"],
  animations: [slideInOutVertical, fadeInOutForSidebarElements],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkupSidebarDesignSettings {
  @Input()
  isBookPrint = false;
  @Input()
  isBookEpub = false;
  @Input()
  availablePalettes!: PaletteDTO[];
  @Input()
  form!: FormGroup;
  @Input()
  isSettingsAvailable = false;

  @Output()
  onShowColorsDetailsSidebar = new EventEmitter();

  protected epubInfoLabels: InfoBlockData[] = [];

  constructor(private readonly cdr: ChangeDetectorRef) {
    this.epubInfoLabels = this.getEpubInfoLabels();
  }

  protected getEpubInfoLabels(): InfoBlockData[] {
    const labels: InfoBlockData[] = [];

    const text = $localize`:@@books.markup.additional-settings-not-available-in-epub:`;
    labels.push({
      id: 1,
      textData: [{ text }],
    });

    return labels;
  }
}
