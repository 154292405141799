import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type BlockType = 'text' | 'image' | 'list' | 'table' | 'page-break';

@Injectable({
  providedIn: 'root',
})
export class MarkupService {
  private selectedSidebarTab = new BehaviorSubject<number>(1);
  selectedSidebarTab$ = this.selectedSidebarTab.asObservable();

  selectSidebarTab(selectedTab: number) {
    this.selectedSidebarTab.next(selectedTab);
  }
}
