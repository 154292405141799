import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AnalyticsService } from "@metranpage/core";
import { RewardNotificationStore, User } from "@metranpage/user-data";
import { Subscription } from "rxjs";
import { RewardNotificationService } from "../../services/reward-notification.service";

@Component({
  selector: "m-account-rewards-icon-view",
  templateUrl: "account-rewards-icon.view.html",
  styleUrls: ["account-rewards-icon.view.scss"],
})
export class AccountRewardsIconView implements OnInit, OnDestroy {
  @Input()
  user?: User;

  protected unreadRewardsCount = 0;

  sub: Subscription = new Subscription();

  constructor(
    private readonly rewardNotificationService: RewardNotificationService,
    private readonly rewardNotificationStore: RewardNotificationStore,
    private readonly analytics: AnalyticsService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.rewardNotificationService.getUnreadRewardsCount();

    this.sub.add(
      this.rewardNotificationStore.getUnreadRewardNotificationsCountObservable().subscribe((count) => {
        this.unreadRewardsCount = count;
        this.cdr.markForCheck();
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected track() {
    this.analytics.event("header-rewards");
  }
}
