import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, ViewChild, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subscription } from "rxjs";
import { RangeService } from "../../../services/range.service";

@Component({
  selector: "m-base-text-editor",
  templateUrl: "../text-editor/text-editor.view.html",
  styleUrls: ["../text-editor/text-editor.view.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseTextEditor),
      multi: true,
    },
  ],
})
export class BaseTextEditor implements ControlValueAccessor {
  @Output()
  onBlur = new EventEmitter<void>();
  @Output()
  onFocus = new EventEmitter<void>();

  @ViewChild("editor", { read: ElementRef })
  protected editorElement!: ElementRef;

  value = "";

  protected sub = new Subscription();

  onChange = (_value: string) => { };
  onTouched = () => { };

  constructor(
    protected readonly elementRef: ElementRef,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly rangeService: RangeService,
  ) { }

  writeValue(value: string): void {
    if (value == null) {
      // https://github.com/angular/angular/issues/14988
      return;
    }
    this.value = value;
    this.setDataToHtml();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(_isDisabled: boolean): void {
    /* implement if needed */
  }

  onChangeHandle(event: Event) {
    this.onChange((event.target! as Element).innerHTML);
  }

  onFocusHandle(event: Event) {
    this.onFocus.emit()
  }

  onBlurHandle(event: Event) {
    this.onBlur.emit();
  }

  setCaretToBegin() {
    this.rangeService.setCaretToBegin(this.editorElement.nativeElement);
  }

  setCaretToEnd() {
    this.rangeService.setCaretToEnd(this.editorElement.nativeElement);
  }

  protected getRange(): Range | undefined {
    const sel = window.getSelection();
    if (sel?.rangeCount === 0) {
      return;
    }

    if (sel?.getRangeAt(0).intersectsNode(this.elementRef.nativeElement)) {
      return sel?.getRangeAt(0);
    }

    return undefined;
  }

  onKeyPressHandle(event: KeyboardEvent) {
    if (event instanceof KeyboardEvent) {
      if (event.key === "Enter") {
        this.onEnterPress(event);
      }
    }
  }

  onKeyDownHandle(event: KeyboardEvent) {
    if (event instanceof KeyboardEvent) {
      if (event.key === "Backspace") {
        this.onBackspacePress(event);
      }
    }
  }

  onEnterPress(event: KeyboardEvent) {
    event.preventDefault();

    const range = this.getRange();

    if (!range) {
      console.error("cannot add br");
      return;
    }

    this.rangeService.addBrToInput(range, this.editorElement.nativeElement);
  }

  onBackspacePress(_event: KeyboardEvent) {
    // use browser defined behavior
  }

  onDragEnterHandle(_event: Event) { }

  onDropHandle(event: Event) {
    event.preventDefault();
  }

  onPasteHandle(event: ClipboardEvent) {
    event.preventDefault();

    const text = event.clipboardData?.getData("text/plain") ?? "";

    const selection = document.getSelection();
    if (!selection) {
      console.error("Selection is not available");
      return;
    }

    const range = selection.getRangeAt(0);
    if (!range) {
      console.error("Range is not available");
      return;
    }

    range.deleteContents();

    const lines = text.split("\n");

    for (let i = 0; i < lines.length; i++) {
      const textNode = document.createTextNode(lines[i]);
      range.insertNode(textNode);
      range.selectNodeContents(textNode);
      range.collapse(false);
      range.setStartAfter(textNode);
      range.setEndAfter(textNode);

      if (i < lines.length - 1) {
        const br = document.createElement("br");
        range.insertNode(br);
        range.selectNodeContents(br);
        range.collapse(false);
        range.setStartAfter(br);
        range.setEndAfter(br);
      }
    }

    this.value = this.editorElement.nativeElement.innerHTML;

    this.onChange(this.value);
    this.onBlur.emit();
  }

  setDataToHtml() {
    this.value = this.value.trim();
    this.editorElement.nativeElement.innerHTML = this.value;

    // remove last br
    const el = this.editorElement.nativeElement as Element;
    if (el.childNodes.length > 0) {
      if (el.lastChild?.nodeName.toLowerCase() === "br") {
        el.lastChild?.remove();
        // force save data with new html
        this.saveDataFromHtml();
      }
    }
  }

  saveDataFromHtml(): void {
    this.value = this.editorElement.nativeElement.innerHTML;
    this.onChange(this.value);

    this.onBlur.emit();
  }
}
