<div class="new-company">
  <m-button button-style="primary" (onClick)="onAddCompany()" i18n="@@admin.companies.add-company">
    admin.companies.add-company
  </m-button>
</div>

<form [formGroup]="searchForm">
  <table>
    <thead>
      <th>ID</th>
      <th>
        Title
        <m-ng-input formControlName="title" size="32"/>
      </th>
      <th>UUID</th>
      <th></th>
    </thead>

    <tbody>
      <tr *ngFor="let company of companies; trackBy: trackByCompanyId">
        <td>{{company.id}}</td>
        <td>{{company.title}}</td>
        <td>{{company.uuid}}</td>

        <td>
          <div class="controls">
            <a [routerLink]="['/superadmin/companies', company.id, 'settings']">
              <m-icon class="settings-icon" src="gear.svg" size="s" color="--color-font-main" />
            </a>
            <m-icon
              class="delete-icon"
              src="block-delete.svg"
              size="s"
              color="--color-error"
              (click)="showCompanyDeleteModal(company)"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</form>

<m-admin-pagination
  [pageCount]="pageCount"
  [currentPage]="page"
  (onPageChanged)="onPageChanged($event)"
></m-admin-pagination>

<m-modal *ngIf="isCompanyDetailsShown && detailsCompany" (close)="closeCompanyDetails()" class="details">
  <h1>{{detailsCompany.title}}</h1>
  <p>
    <b>ID</b>
    : {{detailsCompany.id}}
  </p>
  <p>
    <b>UUID</b>
    : {{detailsCompany.uuid}}
  </p>

  <form [formGroup]="updateCompanyDataForm" class="add-credits-form">
    <p><b i18n="@@admin.companies.change-company-data">admin.companies.change-company-data</b></p>
    <div class="control">
      <span class="label" i18n="@@admin.companies.company.title">admin.companies.company.title</span>
      <m-ng-input formControlName="title" size="32"/>
    </div>
    <div class="control">
      <span class="label" i18n="@@admin.companies.company.landing-url">admin.companies.company.landing-url</span>
      <m-ng-input formControlName="landingUrl" size="32" />
    </div>
    <div class="control">
      <span class="label" i18n="@@admin.companies.company.host-url">admin.companies.company.host-url</span>
      <m-ng-input formControlName="hostUrl" size="32" />
    </div>

    <div class="buttons">
      <m-button
        button-style="secondary"
        size="full-width"
        (onClick)="closeCompanyDetails()"
        i18n="@@admin.common.cancel"
      >
        admin.common.cancel
      </m-button>
      <m-button
        button-style="primary"
        size="full-width"
        (onClick)="onUpdateCompanyData(detailsCompany)"
        i18n="@@admin.common.save"
      >
        admin.common.save
      </m-button>
    </div>
  </form>
</m-modal>

<m-admin-add-company-modal
  *ngIf="isAddCompanyModalVisible"
  (onCompanyAdded)="onCompanyAdded($event)"
  (onCancelAddition)="onCloseAddCompanyModal()"
></m-admin-add-company-modal>

<m-modal *ngIf="isDeleteCompanyModalVisible" position="center" (close)="onCloseDeleteCompanyModal()">
  <div class="delete-company-modal">
    <span>
      <span i18n="@@admin.companies.company.delete-company-confirmation">
        admin.companies.company.delete-company-confirmation
      </span>

      <span *ngIf="companyForDelete" class="company-name">
        «
        <span class="company-name-accent">{{companyForDelete.title}}</span>
        »
      </span>
      ?
    </span>

    <div class="buttons">
      <m-button
        button-style="secondary"
        size="full-width"
        (onClick)="onCloseDeleteCompanyModal()"
        i18n="@@admin.common.cancel"
      >
        admin.common.cancel
      </m-button>
      <m-button button-style="primary" (onClick)="onDeleteCompany()" i18n="@@admin.common.delete">
        admin.common.delete
      </m-button>
    </div>
  </div>
</m-modal>
