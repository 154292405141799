import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Warning } from "../../editor.models";

@Component({
  selector: "m-markup-editor-warning-block",
  templateUrl: "./warning.block-view.html",
  styleUrls: ["./warning.block-view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningBlockView implements OnInit {
  @Input()
  warning!: Warning;

  @Output()
  onRemoveWarningClick = new EventEmitter<void>();

  @ViewChild("text")
  warningText: ElementRef | undefined;

  text = "";

  constructor(private readonly elementRef: ElementRef, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.text = this.warning.text;
  }

  ngAfterViewInit(): void {
    if (this.warningText) {
      this.warningText.nativeElement.innerHTML = this.text ?? "";
    }
  }

  protected onRemoveClick() {
    this.onRemoveWarningClick.emit();
  }

  getTitleText() {
    switch (this.warning?.warning) {
      case "posible-incorrect-union-blocks":
        return $localize`:@@editor.block.warning.posible-incorrect-union-blocks:`;
      case "check-list":
        return $localize`:@@editor.block.warning.check-list:`;
      case "check-symbols":
        return $localize`:@@editor.block.warning.check-symbols:`;
      case "single-symbol":
        return $localize`:@@editor.block.warning.single-symbol:`;
      case "undefined-symbols":
        return $localize`:@@editor.block.warning.undefined-symbols:`;
      case "starts-with-sup":
        return $localize`:@@editor.block.warning.starts-with-sup:`;
      case "starts-with-sub":
        return $localize`:@@editor.block.warning.starts-with-sub:`;
      case "dublicate-text":
        return $localize`:@@editor.block.warning.dublicate-text:`;
      case "no-spaces":
        return $localize`:@@editor.block.warning.no-spaces:`;
      case "many-spaces":
        return $localize`:@@editor.block.warning.many-spaces:`;
      case "containes-private-char":
        return $localize`:@@editor.block.warning.containes-private-char:`;

      default:
        return "";
    }
  }
}
