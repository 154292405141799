<m-modal (close)="onCancelClick()" position="center">
  <div class="container">
    <div class="text-block">
      <div class="modal-header" i18n="@@characters.delete-confirmation.title">characters.delete-confirmation.title</div>
      <div class="modal-text">
        <span i18n="@@characters.delete-confirmation.text">characters.delete-confirmation.text</span>
        <span>«{{ name }}»</span>
        <span>?</span>
      </div>
    </div>

    <div class="modal-buttons">
      <m-button button-style="tertiary" (onClick)="onCancelClick()" i18n="@@dialog.cancel-button">
        dialog.cancel-button
      </m-button>
      <m-button
        button-style="primary"
        (onClick)="onDeleteClick()"
        i18n="@@characters.delete-confirmation.delete-button"
      >
        characters.delete-confirmation.delete-button
      </m-button>
    </div>
  </div>
</m-modal>
