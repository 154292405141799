import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import {
  AdvertisementAction,
  AdvertisementActionCreateData,
  AdvertisementActionSource,
} from "../models/advertisement-action.model";

@Injectable({
  providedIn: "any",
})
export class AdvertisementActionApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  addAdvertisementAction(body: AdvertisementActionCreateData) {
    return this.post<AdvertisementAction>("advertisement-action", body);
  }

  getAdvertisementAction(sourse: AdvertisementActionSource) {
    return this.get<AdvertisementAction>(`advertisement-action/${sourse}`);
  }
}
