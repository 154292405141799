import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconComponent, IconSize } from "../icon/icon.component";
import { ReactiveFormsModule } from "@angular/forms";
import { InputComponent } from "../input/input.component";
import { InputNumberComponent } from "../input-number/input-number.component";

@Component({
  selector: "m-ng-input-number-with-icon",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IconComponent, InputComponent, InputNumberComponent],
  templateUrl: "./input-number-with-icon.component.html",
  styleUrls: ["../input/input.component.scss", "./input-number-with-icon.component.scss"],
})
export class InputNumberWithIconComponent extends InputNumberComponent {
  @Input()
  iconLeft?: string = undefined;
  @Input()
  iconRight?: string = undefined;
  @Input()
  iconSize: IconSize = 24;

}
