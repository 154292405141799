import { Component } from "@angular/core";

@Component({
  selector: "m-pricing-host-page",
  template: `
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        height: calc(100vh - var(--app-bar-height));
        flex-direction: column;
        align-items: center;
      }

      .content {
        display: flex;
        width: 100%;
        height: calc(100vh - var(--app-bar-height) - var(--nav-tabs-pricing-height));
        flex-direction: column;
        align-items: center;
      }
    `,
  ],
})
export class PricingHostPage {}
