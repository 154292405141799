<m-ng-scrollbars>
  <div class="container">
    <div
      *ngIf="activeSubscription"
      class="active-tariff-card"
      [class.background-accent]="!activeSubscription.tariff.isFree"
    >
      <div class="tariff-info">
        <div class="tariff-data">
          <span class="tariff-title">{{ activeSubscription.tariff.title }}</span>
          <span class="tariff-subtitle">
            {{ activeSubscription.tariff.price }} {{ currencySymbol }}/{{ pluralizePeriod() }}
          </span>
        </div>
        <div class="divider"></div>
        <div class="tariff-data">
          <span class="tariff-days-left">{{ getTariffStatus().body }}</span>
          <span class="tariff-subtitle">{{ getTariffStatus().footer }}</span>
        </div>
      </div>
      <m-button
        button-style="secondary"
        (onClick)="onUpgradeTariffClick()"
        i18n="@@user.profile.tariff-and-payments.upgrade-tariff"
      >
        user.profile.tariff-and-payments.upgrade-tariff
      </m-button>
    </div>

    <div class="support-card">
      <span i18n="@@user.profile.tariff-and-payments.need-help">user.profile.tariff-and-payments.need-help</span>
      <m-button
        button-style="secondary"
        (onClick)="onConnectSupportClick()"
        i18n="@@user.profile.tariff-and-payments.contact-support"
      >
        user.profile.tariff-and-payments.contact-support
      </m-button>
    </div>
  </div>
</m-ng-scrollbars>
