import { ChangeDetectorRef, Component, ComponentRef, Input, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { MarginKey, PagePosition, RunningTitleContext, RunningTitlesStyle } from "@metranpage/book-data";
import { PaletteDTO } from "@metranpage/components";
import { Subscription } from "rxjs";
import { RunningTitlesService } from "../../services/running-titles.service";
import { RunningTitlesStore } from "../../services/running-titles.store";
import { PageNumberStyle1Component } from "./page-numbers-styles/page-number-style-1/page-number-style-1.component";
import { PageNumberStyle2Component } from "./page-numbers-styles/page-number-style-2/page-number-style-2.component";

export type PageNumbersStyles = PageNumberStyle1Component | PageNumberStyle2Component;

const pageNumbersStylesMappings = [
  { id: 1, style: PageNumberStyle1Component },
  { id: 2, style: PageNumberStyle1Component },
  { id: 3, style: PageNumberStyle2Component },
];

@Component({
  selector: "m-page-numbers-styles",
  templateUrl: "./page-numbers-styles.component.html",
})
export class PageNumberssStylesComponent implements OnInit {
  @Input()
  style?: RunningTitlesStyle;
  @Input()
  context: RunningTitleContext = {
    runningTitle: "",
    pageNumber: "",
  };
  @Input("page-position")
  pagePosition: PagePosition = "left";
  @Input("font-size")
  fontSize = 12;
  @Input()
  palette?: PaletteDTO;
  @Input()
  position: MarginKey = "marginTop";
  @Input()
  isDisabled = false;

  @ViewChild("pageNumberContainer", { read: ViewContainerRef })
  pageNumberContainerRef!: ViewContainerRef;
  private pageNumberComponentRef: ComponentRef<PageNumbersStyles> | null = null;

  private sub = new Subscription();

  constructor(
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly runningTitlesService: RunningTitlesService,
    private readonly runningTitlesStore: RunningTitlesStore,
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.runningTitlesStore
        .getRunningTitlesStateObservable()
        .pipe()
        .subscribe((state) => {
          this.updateStyleComponentData();
          this.changeDetectionRef.detectChanges();
        }),
    );
  }

  ngAfterViewInit() {
    this.updateStyleComponentData();
    this.changeDetectionRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.pageNumberComponentRef) {
      this.pageNumberComponentRef.destroy();
      this.pageNumberComponentRef = null;
    }
    this.sub.unsubscribe();
  }

  private getPageNumberComponentType(typeId: number) {
    const type = pageNumbersStylesMappings.find((item) => item.id === typeId)?.style;
    return type || undefined;
  }

  private createPageNumberStyleComponent() {
    const styleId = this.style?.id ?? 1;
    const componentType = this.getPageNumberComponentType(styleId) as any;
    if (!componentType) {
      return;
    }
    this.pageNumberContainerRef.clear();
    this.pageNumberComponentRef = this.pageNumberContainerRef.createComponent(componentType);
    this.setStyleComponentData(this.pageNumberComponentRef);
  }

  private setStyleComponentData(componentRef: ComponentRef<PageNumbersStyles> | null) {
    if (!componentRef) {
      return;
    }
    componentRef.instance.style = this.style;
    componentRef.instance.context = this.context;
    componentRef.instance.pagePosition = this.pagePosition;
    componentRef.instance.fontSize = this.fontSize;
    componentRef.instance.palette = this.palette;
    componentRef.instance.position = this.position;
  }

  private updateStyleComponentData() {
    if (this.pageNumberContainerRef) {
      this.createPageNumberStyleComponent();
    }
  }
}
