import { Inject, Injectable } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivationEnd, Router } from "@angular/router";
import { SelectValue } from "@metranpage/components";
import { GeneralResultStatus } from "@metranpage/core-data";
import { ConfigService } from "@metranpage/core-interfaces";
import { AVAILABLE_LANGUAGES, I18nService, LangSpec } from "@metranpage/i18n";
import * as _ from "lodash-es";
import { Format, FormatCreateDataDto, FormatDto, FormatUpdateDataDto } from "../../models/format";
import { AdminFormatApi, FormatFilter } from "./format.api";
import { AdminFormatStore } from "./format.store";

type FormatLocalizationFormGroupData = {
  language: FormControl<string>;
  title: FormControl<string>;
};

@Injectable({
  providedIn: "root",
})
export class AdminFormatService {
  constructor(
    private readonly adminFormatApi: AdminFormatApi,
    private readonly adminFormatStore: AdminFormatStore,
    @Inject("ConfigService")
    private readonly configService: ConfigService,
    private readonly router: Router,
    private readonly i18nService: I18nService,
    private readonly formBuilder: FormBuilder,
    @Inject(AVAILABLE_LANGUAGES) readonly availableLanguages: LangSpec[],
  ) {
    this.watchRouteChanges();
  }

  private watchRouteChanges() {
    this.router.events.subscribe((data) => {
      if (data instanceof ActivationEnd) {
        if ("formatId" in data.snapshot.params) {
          const formatId = Number.parseInt(data.snapshot.params["formatId"]);
          this.loadFormat(formatId);
        }
      }
    });
  }

  async loadFormats() {
    const formats = await this.adminFormatApi.getFormats();
    const localizedFormats = formats.map((f) => this.updateFormatLocalization(f));
    this.adminFormatStore.setFormats(localizedFormats);
  }

  async loadFormat(formatId: number) {
    const formatData = await this.adminFormatApi.getFormat(formatId);
    this.adminFormatStore.setActiveFormat(formatData);
  }

  updateFormatLocalization(data: FormatDto) {
    const locale = this.i18nService.getLocale();
    let localeData = data?.localization?.find((l) => l.lang === locale);
    if (!localeData) {
      localeData =
        data?.localization?.length > 0
          ? data.localization[0]
          : {
              id: 0,
              title: "",
              lang: "",
              formatId: -1,
            };
    }

    const formatData = _.omit(data, ["localization"]);
    const formatLocalizedData = _.omit(localeData, ["id", "lang", "formatId"]);
    const format: Format = {
      ...formatData,
      ...formatLocalizedData,
    };
    return format;
  }

  async createFormat(data: FormatCreateDataDto): Promise<{ status: GeneralResultStatus; id?: number }> {
    try {
      const newFormat = await this.adminFormatApi.createFormat(data);
      return {
        status: "success",
        id: newFormat.id,
      };
    } catch (error: any) {
      return {
        status: "error",
        id: undefined,
      };
    }
  }

  async updateFormat(id: number, data: FormatUpdateDataDto): Promise<GeneralResultStatus> {
    try {
      await this.adminFormatApi.updateFormat(id, data);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async deleteFormat(formatId: number): Promise<GeneralResultStatus> {
    try {
      await this.adminFormatApi.deleteFormat(formatId);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  createForm() {
    const localization: { [key: string]: FormGroup<FormatLocalizationFormGroupData> } = {};

    for (const { value } of this.availableLanguages) {
      localization[value] = this.formBuilder.group({
        language: this.formBuilder.control(value, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        title: this.formBuilder.control("", {
          nonNullable: true,
          validators: [Validators.required],
        }),
      });
    }

    return this.formBuilder.group(
      {
        id: this.formBuilder.control(0),
        companyId: this.formBuilder.control(1),

        type: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),
        name: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),

        width: this.formBuilder.control(100, { nonNullable: true, validators: [Validators.required] }),
        height: this.formBuilder.control(100, { nonNullable: true, validators: [Validators.required] }),
        order: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),

        isAvailableToUser: this.formBuilder.control(false, { nonNullable: true, validators: [Validators.required] }),
        isVisibleToOtherCompanies: this.formBuilder.control(false, {
          nonNullable: true,
          validators: [Validators.required],
        }),

        localization: this.formBuilder.record(localization),
      },
      // { validator: [] },
    );
  }

  getFormatTypeOptions(): SelectValue[] {
    return [
      {
        id: "book",
        value: "book",
      },
    ];
  }

  async saveFormatsOrder(formats: Format[]): Promise<GeneralResultStatus> {
    try {
      await this.adminFormatApi.saveFormatsOrder(formats);
      return "success";
    } catch (error: any) {
      console.error(error);

      return "error";
    }
  }
}
