import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FileDropComponent } from "@metranpage/core";
import * as fontkit from "fontkit";
import { NgxFileDropEntry } from "ngx-file-drop";
import { Subscription } from "rxjs";
import { FontCreateDataWithFile } from "../../models/font";
import { AdminFontsService } from "../../services/fonts/fonts.service";

const reFontExtensions = /(\.)(ttf|otf)$/gm;

@Component({
  selector: "m-admin-add-font-modal",
  templateUrl: "./add-font.view.html",
  styleUrls: ["./add-font.view.scss"],
})
export class AddFontView {
  @Output()
  onFontAdded = new EventEmitter<FontCreateDataWithFile>();
  @Output()
  onCancelAddition = new EventEmitter<void>();

  @ViewChild("filedrop", { static: false })
  protected fileDropComponent!: FileDropComponent;

  protected fontFile?: File;
  protected fontData?: fontkit.Font;
  protected isFontFileUploaded = false;

  protected form?: FormGroup;

  private sub: Subscription = new Subscription();

  constructor(private readonly adminFontsService: AdminFontsService) {}

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) => {
        this.fontFile = file;
        this.isFontFileUploaded = file.name.match(reFontExtensions) ? true : false;

        if (this.isFontFileUploaded) {
          await this.updateFontData();
        }
      });
    }
  }

  async deleteFontFile() {
    this.fontFile = undefined;
    this.fontData = undefined;
    this.isFontFileUploaded = false;
    this.form?.patchValue({
      licenseRu: "",
      licenseEn: "",
    });
  }

  openFileSelector() {
    this.fileDropComponent.openFileSelector();
  }

  createForm() {
    this.form = this.adminFontsService.createFontForm();
  }

  createFont() {
    const formData = this.form?.getRawValue();
    formData.fontFile = this.fontFile;

    this.onFontAdded.emit(formData);
  }

  onCancel() {
    this.onCancelAddition.emit();
  }

  async updateFontData() {
    if (!this.fontFile) {
      return;
    }
    this.fontData = await this.getFontData(this.fontFile);
    const copyright = this.fontData.copyright;
    if (copyright) {
      this.form?.patchValue({
        licenseRu: copyright,
        licenseEn: copyright,
      });
    }
  }

  private getFontData(file: File): Promise<fontkit.Font> {
    const fileData = new Blob([file]);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(fileData);
      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const bytes = new Uint8Array(arrayBuffer);
        const font = fontkit.create(bytes as Buffer);
        resolve(font as fontkit.Font);
      };
    });
  }
}
