import { Component } from "@angular/core";
import { User, UserStore } from "@metranpage/user-data";
import { AdminCompanyService } from "../services/company/company.service";

@Component({
  selector: "m-admin-host-page",
  template: `
    <m-admin-nav-view></m-admin-nav-view>

    <div class="content">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        gap: 8px;
        height: calc(100vh - var(--app-bar-height));
      }

      .content {
        display: flex;
        width: 100%;
        height: 100%;
        min-width: 50vw;
        flex-direction: column;
        margin: 0 0 70px;
        overflow-x: hidden;
        overflow-y: auto;
      }
    `,
  ],
})
export class AdminHostPage {
  user?: User;

  constructor(userStore: UserStore, adminCompanyService: AdminCompanyService) {
    userStore.getUserObservable().subscribe((user) => {
      this.user = user;
    });
    adminCompanyService.refreshCompany();
  }
}
