import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MarkupPreviewService, StyleDef, StyleSettings } from "@metranpage/markup-editor";
import { Observable, Subscription, filter } from "rxjs";
import { MarkupService } from "../../services/markup.service";

@Component({
  selector: "m-books-markup-sidebar-style",
  templateUrl: "./markup-sidebar-style-details.view.html",
  styleUrls: ["./markup-sidebar-style-details.view.scss"],
  animations: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkupSidebarStyleDetails implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  style!: StyleDef;
  @Input()
  form!: FormGroup;
  @Input()
  isSettingsAvailable = false;

  @Output()
  onClose = new EventEmitter<void>();

  @ViewChild("preview")
  previewRef!: ElementRef;

  initialValue!: StyleSettings;

  protected selectedSidebarTab$ = new Observable<number>();

  private sub = new Subscription();

  constructor(
    private readonly previewService: MarkupPreviewService,
    private readonly cdr: ChangeDetectorRef,
    private readonly markupService: MarkupService,
  ) {}

  ngOnInit() {
    this.watchForClose();
  }

  ngAfterViewInit(): void {
    if (this.form) {
      const styleControl = this.form.get("styles")?.get(this.style.styleKey)!;
      this.updatePreview(styleControl.getRawValue());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.style?.currentValue) {
      this.cdr.markForCheck();
    }
    if (changes.form?.currentValue) {
      const styleControl = this.form.get("styles")?.get(this.style.styleKey)!;

      this.initialValue = styleControl.getRawValue();

      this.sub.unsubscribe();
      this.sub = new Subscription();
      this.sub.add(
        styleControl.valueChanges.subscribe((value) => {
          this.updatePreview(value);
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private watchForClose() {
    this.selectedSidebarTab$ = this.markupService.selectedSidebarTab$;
    this.sub.add(
      this.selectedSidebarTab$.pipe(filter((e) => e === 2)).subscribe(() => {
        this.onCancelClick();
      }),
    );
  }

  updatePreview(value: StyleSettings) {
    if (!this.previewRef) {
      return;
    }

    value.fontSize = 13;
    this.previewService.applyPreviewStyle(this.previewRef.nativeElement as Element, value);
    this.previewService.applyDropCap(this.previewRef.nativeElement, value);

    this.cdr.markForCheck();
  }

  protected getDropCapDescription() {
    return `${$localize`:@@books.markup.style-details.drop-cap-description:`} <b>${$localize`:@@books.styles.h1:`}</b> ${$localize`:@@books.markup.style-details.drop-cap-description-2:`} <b>${$localize`:@@books.styles.h2:`}</b>`;
  }

  isTextSettingsAvailable(style: StyleDef) {
    return (
      style.availableControls.includes("font-size") ||
      style.availableControls.includes("leading") ||
      style.availableControls.includes("tracking")
    );
  }

  onCancelClick() {
    const styleControl = this.form.get("styles")?.get(this.style.styleKey)!;
    styleControl.patchValue(this.initialValue);

    // const initialNoSize = _.omit(this.initialValue, ['fontSize']);
    // styleControl.patchValue(initialNoSize);
    this.onClose.emit();
  }

  onApplyClick() {
    if (!this.isStyleSettingsValid()) {
      return;
    }

    this.onClose.emit();
  }

  isStyleSettingsValid() {
    if (!this.form.get("styles")?.get(this.style.styleKey)?.valid) {
      return false;
    }
    return true;
  }
}
