import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { Observable, Subject, takeUntil } from "rxjs";
import { CompanyMaintenanceSettings } from "../../models/company";
import { AdminCompanyMaintenanceSettingsService } from "../../services/maintenance-settings/companies-maintenance-settings.service";
import { AdminCompanyMaintenanceSettingsStore } from "../../services/maintenance-settings/companies-maintenance-settings.store";

@Component({
  selector: "m-admin-maintenance-settings-page",
  templateUrl: "./maintenance.page.html",
  styleUrls: ["./maintenance.page.scss"],
})
export class AdminMaintenancePage implements AfterViewInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  protected settings$: Observable<CompanyMaintenanceSettings | undefined> | undefined;

  readonly form: FormGroup;

  protected isImageGenerationBasicMaintenanceEnable = true;
  protected isImageGenerationAdvancedMaintenanceEnable = true;

  constructor(
    private readonly service: AdminCompanyMaintenanceSettingsService,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly store: AdminCompanyMaintenanceSettingsStore,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.form = new FormBuilder().group({
      imageGenerationBasic: new FormControl<boolean>(false, { nonNullable: true, validators: [Validators.required] }),
      imageGenerationAdvanced: new FormControl<boolean>(false, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  async ngAfterViewInit() {
    this.watchFormChanges();

    await this.service.loadMaintenanceSettings();
    this.settings$ = this.store.getSettingsObservable();
    this.settings$!.pipe(takeUntil(this.destroyed$)).subscribe((settings) => {
      this.form.setValue({
        imageGenerationBasic: settings?.imageGenerationBasic || false,
        imageGenerationAdvanced: settings?.imageGenerationAdvanced || false,
      });
    });
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  protected watchFormChanges() {
    this.form.valueChanges.subscribe((v) => {
      this.isImageGenerationBasicMaintenanceEnable = v.imageGenerationBasic;
      this.isImageGenerationAdvancedMaintenanceEnable = v.imageGenerationAdvanced;
    });
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.updateMaintenanceSettings(this.form.value as CompanyMaintenanceSettings);
    this.loadingService.stopLoading();

    if (result.status === "success") {
      // do nothing
    } else {
      // this.error.emit(result.error);
      this.notificationService.error("Cannot set settings");
      console.error("Cannot set settings");
    }
  }

  isDisabled() {
    return !this.form.valid;
  }
}
