import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  Input,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { fadeInOutForSidebarElements } from "@metranpage/components";
import { RouterService } from "@metranpage/core";
import { User, UserStore } from "@metranpage/user-data";
import { Subject, Subscription, debounceTime, tap, timer } from "rxjs";
import { HomeService } from "../../../services/home.service";
import { CommunityDataService, PublishedObjectsDataSourceType } from "../community-data.service";
import { UserVerificationOverlayServiceInterface } from "@metranpage/user-interfaces";

@Component({
  selector: "m-published-object-preview",
  templateUrl: "./published-object-preview.view.html",
  styleUrls: ["./published-object-preview.view.scss"],
  animations: [fadeInOutForSidebarElements],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublishedObjectPreview {
  @Input()
  publishedObject!: PublishedObjectsDataSourceType;
  @Input()
  objectType!: "images" | "covers";

  protected imageUrl!: string;
  protected author: User | undefined;

  protected isCardInfoVisible = false;

  protected debounceTime = 500;

  protected isLiked = false;
  protected likesCount = 0;

  private likeClick = new Subject<Event>();
  sub: Subscription = new Subscription();

  constructor(
    private readonly communityDataService: CommunityDataService,
    private readonly userStore: UserStore,
    private readonly homeService: HomeService,
    private readonly router: Router,
    private readonly routerService: RouterService,
    private readonly cdr: ChangeDetectorRef,
    @Inject("UserVerificationOverlayService")
    protected readonly userVerificationOverlayService: UserVerificationOverlayServiceInterface,
  ) {}

  ngOnInit() {
    this.updateLikesData();
    this.watchLikeClick();

    const eventBus = this.communityDataService.getEventBus(this.objectType);
    this.sub.add(
      eventBus.subscribe((id) => {
        if (id === this.publishedObject.id) {
          this.updateLikesData();
          this.cdr.detectChanges();
        }
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.imageUrl && changes.publishedObject?.currentValue) {
      const url = this.getUrlForImage();
      if (!url) {
        return;
      }
      this.imageUrl = url;

      this.author = this.getAuthor();

      // this is inside if, so, the image wont redraw and trigger animation
      this.cdr.detectChanges();
    }
  }

  protected getUrlForImage() {
    // return this.generatedImageService.getUrlForImage(
    //   this.publishedImage.imageGenerationId,
    //   this.publishedImage.imageUrl,
    //   true,
    // );
    return this.communityDataService.getImageUrlForObject(this.objectType, this.publishedObject.id, true);
  }

  protected getAuthor() {
    return this.communityDataService.getAuthorForObject(this.objectType, this.publishedObject.id);
  }

  private updateLikesData() {
    this.isLiked = this.publishedObject.isLiked;
    this.likesCount = this.publishedObject.likesCount;
  }

  protected onLikeClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    const user = this.userStore.getUser();
    if (!user) {
      this.homeService.showLoginModal();
      return;
    }
    if (!user.isVerified) {
      this.routerService.showInModal(undefined);
      timer(100).subscribe(() => {
        this.userVerificationOverlayService.open();
      });
      return;
    }

    this.isLiked = !this.isLiked;
    if (this.isLiked) {
      this.likesCount = this.likesCount + 1;
    } else {
      this.likesCount = this.likesCount - 1;
    }

    this.likeClick.next(e);
  }

  protected watchLikeClick() {
    this.sub.add(
      this.likeClick
        .pipe(
          debounceTime(this.debounceTime),
          tap((e: Event) => {
            if (!this.isLiked) {
              return this.communityDataService.unlike(this.objectType, this.publishedObject.id);
            }
            return this.communityDataService.like(this.objectType, this.publishedObject.id);
          }),
        )
        .subscribe((_: any) => {
          this.cdr.detectChanges();
        }),
    );
  }

  @HostListener("mouseenter", ["$event"])
  onEnter(e: MouseEvent) {
    this.isCardInfoVisible = true;
  }

  @HostListener("mouseleave", ["$event"])
  onLeave(e: MouseEvent) {
    this.isCardInfoVisible = false;
  }
}
