import { Injectable } from "@angular/core";
import { Template, TemplateFull } from "@metranpage/book-data";
import { Store } from "@metranpage/state";

export type State = {
  templates: Template[];
  templatesPageCount: number;
  activeTemplate: TemplateFull | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminTemplatesStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      templates: [],
      templatesPageCount: 1,
      activeTemplate: undefined,
    };
  }

  getTemplatesObservable() {
    return this.getFieldObservable("templates");
  }

  setTemplates(templates: Template[]) {
    this.update((state) => ({ ...state, templates }));
  }

  getTemplatesPageCountObservable() {
    return this.getFieldObservable("templatesPageCount");
  }

  setTemplatesPageCount(count: number) {
    this.update((state) => ({ ...state, templatesPageCount: count }));
  }

  setActiveTemplate(template: TemplateFull) {
    this.update((state) => ({ ...state, activeTemplate: template }));
  }

  getActiveTemplateObservable() {
    return this.getFieldObservable("activeTemplate");
  }
}
