import { registerLocaleData } from "@angular/common";
import { Injectable, RendererFactory2 } from "@angular/core";
import { loadTranslations } from "@angular/localize";
import { I18nService } from "./i18n.service";

@Injectable({
  providedIn: "root",
})
export class I18nHelper {
  locale = "en";

  constructor(private readonly i18nService: I18nService, private readonly rendererFactory: RendererFactory2) {}

  async setLocale(localeModule: any, translations: any) {
    this.locale = this.i18nService.getLocale();

    // Use web pack magic string to only include required locale data
    // const localeModule = await import(
    // 	/* webpackInclude: /(de|en|es|ru)\.mjs$/ */
    // 	`../../../../node_modules/@angular/common/locales/${this.locale}.mjs`
    // );

    // Set locale for built in pipes, etc.
    registerLocaleData(localeModule.default);

    // Load translation file
    // const localeTranslationsModule = await import(
    // 	`../../../assets/i18n/${this.locale}.json`
    // );

    // Load translations for the current locale at run-time
    loadTranslations(translations.default.translations);

    // update lang tag
    if (this.rendererFactory) {
      // ^ storybook hack. We initialize i18n at service, without ui access
      this.rendererFactory.createRenderer(null, null).setAttribute(document.querySelector("html"), "lang", this.locale);
    }
  }
}
