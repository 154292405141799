import { Injectable } from "@angular/core";
import { ApiErrorHandlerService } from "@metranpage/core";
import { User } from "../../models/user";
import { AdminUsersApi, UsersFilter } from "./users.api";
import { AdminUsersStore } from "./users.store";

@Injectable({
  providedIn: "root",
})
export class AdminUsersService {
  constructor(
    private readonly adminUsersApi: AdminUsersApi,
    private readonly adminUsersStore: AdminUsersStore,
    private readonly apiErrorHandler: ApiErrorHandlerService,
  ) {}

  async loadUsers(page: number, filters: UsersFilter = {}) {
    try {
      const usersData = await this.adminUsersApi.getUsers(page, filters);
      this.adminUsersStore.setUsers(usersData.items);
      this.adminUsersStore.setUsersPageCount(usersData.pageCount);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "error";
    }
  }

  async addCredits(user: User, value: { credits: number; goldCredits: number }) {
    try {
      await this.adminUsersApi.addCredits(user.id, value);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "error";
    }
  }

  async addSubscription(user: User, value: { tariffId: number; period: number }) {
    try {
      await this.adminUsersApi.addSubscription(user.id, value);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "error";
    }
    // await this.subscriptionService.addSubscription(userId, tariffId, period);
    // return this.userService.getUser(userId);
  }

  async getUserPaymentData(userId: number) {
    try {
      return await this.adminUsersApi.getUserPaymentData(userId);
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return undefined;
    }
  }

  async resetSubscription(user: User) {
    try {
      await this.adminUsersApi.resetSubscription(user.id);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "error";
    }
  }
}
