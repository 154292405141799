import { ChangeDetectionStrategy, Component } from "@angular/core";
import { BaseTextBlockView } from "../base-text/base-text.block-view";

@Component({
  selector: "m-markup-editor-header-block",
  templateUrl: "./header.block-view.html",
  styleUrls: ["./header.block-view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderBlockView extends BaseTextBlockView {
  isStartsOnPanelVisible() {
    return this.styleDisplayOpts?.startsOn !== "current-page";
  }

  getStartsOnPanelText() {
    switch (this.styleDisplayOpts?.startsOn) {
      case "next-page":
        return $localize`:@@editor.starts-on.next-page:`;
      case "odd-page":
        return $localize`:@@editor.starts-on.odd-page:`;
      case "even-page":
        return $localize`:@@editor.starts-on.even-page:`;
      default:
        return "";
    }
  }
}
