import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import { UserPaymentData, UsersData } from "../../models/user";

export type UsersFilter = {
  email?: string;
  integrationUserId?: string;
};

@Injectable({
  providedIn: "root",
})
export class AdminUsersApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getUsers(page: number, filters: UsersFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<UsersData>("admin/users", {
      page,
      ...clearedFilters,
    });
  }

  async addCredits(userId: number, creditsBody: { credits: number; goldCredits: number }) {
    return this.post<unknown>("admin/users/add-credits", {
      ...creditsBody,
      userId,
    });
  }

  async addSubscription(userId: number, subscriptionBody: { tariffId: number; period: number }) {
    return this.post<unknown>("admin/users/add-subscription", {
      ...subscriptionBody,
      userId,
    });
  }

  async getUserPaymentData(userId: number) {
    return this.get<UserPaymentData>("admin/users/payment-data", {
      userId,
    });
  }

  async resetSubscription(userId: number) {
    return this.post<unknown>("admin/users/reset-subscription", {
      userId,
    });
  }
}
