<div class="wrapper">
  <div class="section">
    <div class="rowset">
      <div class="caption" i18n="@@cover-editor.object.settings.alignment">cover-editor.object.settings.alignment</div>
      <m-cover-object-alignment (align)="onAlign($event)"></m-cover-object-alignment>
    </div>
  </div>
  <div class="section slim">
    <m-cover-multicolor-settings [objects]="objects" (update)="onColorsUpdate($event)" />
  </div>
  <div class="section">
    <m-button
      button-style="secondary"
      size="full-width"
      icon-position="left"
      icon="group-01.svg"
      (click)="onGroupObjects()"
      i18n="@@cover-editor.object.settings.multiselect.button.group"
    >
      cover-editor.object.settings.multiselect.button.group
    </m-button>
  </div>
</div>
