import { Component, Input } from "@angular/core";
import { User } from "@metranpage/user-data";
import { UserRewardDaily } from "../../models/rewards/user-reward-daily";
import { UserRewardOneTime } from "../../models/rewards/user-reward-onetime";
import { ConnectTelegramService } from "../../services/connect-telegram.service";

@Component({
  selector: "m-account-reward-tasks-list-view",
  templateUrl: "account-reward-tasks-list.view.html",
  styleUrls: ["account-reward-tasks-list.view.scss"],
})
export class AccountRewardTasksListView {
  @Input()
  user?: User;
  @Input()
  rewardsDaily: UserRewardDaily[] = [];
  @Input()
  rewardsOneTime: UserRewardOneTime[] = [];

  constructor(private readonly connectTelegramService: ConnectTelegramService) {}

  protected getRewardDailyType(reward: UserRewardDaily) {
    if (reward.reward.type === "likes-give") {
      return "likes-give";
    }
    if (reward.reward.type === "likes-take") {
      return "likes-take";
    }
    return;
  }

  protected isTelegramConnectionAvailable() {
    return this.connectTelegramService.isTelegramConnectionAvailable();
  }

  protected getRewardOneTimeType(reward: UserRewardOneTime) {
    if (reward.reward.type === "subscribe-to-telegram-channel" && this.isTelegramConnectionAvailable()) {
      return "subscribe-to-telegram-channel";
    }
    return;
  }
}
