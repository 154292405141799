import { Component, EventEmitter, Input, Output } from "@angular/core";
import { GeneratedImage, GeneratedImageService } from "@metranpage/image-generation";

@Component({
  selector: "m-moderation-image-preview",
  templateUrl: "./moderation-image-preview.view.html",
  styleUrls: ["./moderation-image-preview.view.scss"],
})
export class ModerationImagePreviewView {
  @Input()
  previewImage?: GeneratedImage;
  @Input()
  paddingSize = 16;
  @Input()
  hasPrevious = true;
  @Input()
  hasNext = true;

  @Output()
  onPrevious = new EventEmitter();
  @Output()
  onNext = new EventEmitter();

  constructor(private readonly generatedImageService: GeneratedImageService) {}

  protected onPreviousClick() {
    this.onPrevious.emit();
  }

  protected onNextClick() {
    this.onNext.emit();
  }

  protected getUrlForImage(generatedImage: GeneratedImage) {
    return this.generatedImageService.getUrlForImage(generatedImage.imageGenerationId, generatedImage.imageUrl);
  }
}
