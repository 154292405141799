import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import {
  CompanyCloudPaymentsSettings,
  CompanyPaymentsSettings,
  CompanyS3Settings,
  CompanyStripeSettings,
} from "../../models/company";
import { CompanyUnisenderCreds } from "../../models/unisender-settings";

export type State = {
  s3Settings: CompanyS3Settings | undefined;

  paymentsSettings: CompanyPaymentsSettings | undefined;
  // cloudPaymentsSettings: CompanyCloudPaymentsSettings | undefined;
  // stripeSettings: CompanyStripeSettings | undefined;
  unisenderCreds: CompanyUnisenderCreds | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyCredsSettingsStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      s3Settings: undefined,
      paymentsSettings: undefined,
      // cloudPaymentsSettings: undefined,
      // stripeSettings: undefined,
      unisenderCreds: undefined,
    };
  }

  getS3SettingsObservable() {
    return this.getFieldObservable("s3Settings");
  }

  setS3Settings(settings: CompanyS3Settings | undefined) {
    this.update((state) => ({ ...state, s3Settings: settings }));
  }

  getPaymentsSettings() {
    return this.getField("paymentsSettings");
  }

  getPaymentsSettingsObservable() {
    return this.getFieldObservable("paymentsSettings");
  }

  setPaymentsSettings(paymentsSettings: CompanyPaymentsSettings | undefined) {
    this.update((state) => ({ ...state, paymentsSettings: paymentsSettings }));
  }

  // getCloudPaymentsSettingsObservable() {
  //   return this.getFieldObservable("cloudPaymentsSettings");
  // }

  // setCloudPaymentsSettings(settings: CompanyCloudPaymentsSettings | undefined) {
  //   this.update((state) => ({ ...state, cloudPaymentsSettings: settings }));
  // }

  // getStripeSettingsObservable() {
  //   return this.getFieldObservable("stripeSettings");
  // }

  // setStripeSettings(settings: CompanyStripeSettings | undefined) {
  //   this.update((state) => ({ ...state, stripeSettings: settings }));
  // }

  getUnisenderCredsObservable() {
    return this.getFieldObservable("unisenderCreds");
  }

  setUnisenderCreds(settings: CompanyUnisenderCreds | undefined) {
    this.update((state) => ({ ...state, unisenderCreds: settings }));
  }
}
