<div class="backdrop" (click)="close.emit()"></div>
<div class="container">
  <span class="header" i18n="@@books.preview.payments-modal.header">books.preview.payments-modal-header</span>
  <div class="cards">
    <div class="card-gc" [ngClass]="{'single': !isPurchasesAvailable}">
      <div *ngIf="!isAboutGoldCreditsVisible" class="card-content">
        <div class="price">
          <div class="price-group">
            <span class="price-value">1</span>
            <m-icon src="/assets/img/app-bar-menu/token-gold.svg" size="l-plus" color="--accent" />
          </div>
          <span class="price-name">{{ pluralizeGoldCredits() }}</span>
        </div>
        <div class="balance">
          <span i18n="@@books.preview.payments-modal.your-balance">books.preview.payments-modal.your-balance</span>
          {{ getUserGoldCreditsBalance() }}
        </div>
        <span
          class="what-is-gold-credits"
          (click)="toggleAboutGoldCredits()"
          i18n="@@books.preview.payments-modal.what-is-gold-credits"
        >
          books.preview.payments-modal.what-is-gold-credits
        </span>
        <m-button
          *ngIf="!isNotEnoughGoldCredits()"
          class="button"
          button-style="primary"
          color-theme="gold"
          (click)="onClickPayGoldCredits()"
          i18n="@@books.preview.payments-modal.use-gold"
        >
          books.preview.payments-modal.use-gold
        </m-button>
        <m-button
          *ngIf="isNotEnoughGoldCredits()"
          class="button"
          button-style="primary"
          color-theme="gold"
          (onClick)="toggleAboutGoldCredits()"
          i18n="@@books.preview.payments-modal.get-gold-credits"
        >
          books.preview.payments-modal.get-gold-credits
        </m-button>
      </div>

      <div *ngIf="isAboutGoldCreditsVisible" class="about-content">
        <div class="button-back" (click)="toggleAboutGoldCredits()">
          <svg-icon class="icon-back" src="/assets/icons/back.svg"></svg-icon>
        </div>
        <div class="info-block">
          <span class="info-header" i18n="@@books.preview.payments-modal.what-is-gold-credits">
            books.preview.payments-modal.what-is-gold-credits
          </span>
          <span class="info-text" i18n="@@books.preview.payments-modal.what-is-gold-credits-text">
            books.preview.payments-modal.what-is-gold-credits-text
          </span>
        </div>
        <div class="info-block">
          <span class="info-header" i18n="@@books.preview.payments-modal.how-to-get-gold-credits">
            books.preview.payments-modal.how-to-get-gold-credits
          </span>
          <div>
            <m-localized-text
              class="info-text"
              text="books.preview.payments-modal.how-to-get-gold-credits-text"
              i18n-text="@@books.preview.payments-modal.how-to-get-gold-credits-text"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="separator" *ngIf="isPurchasesAvailable">
      <div class="round">
        <span i18n="@@books.preview.payments-modal.or">books.preview.payments-modal.or</span>
      </div>
    </div>

    <div class="card-sc" *ngIf="isPurchasesAvailable">
      <div class="card-content">
        <div class="price">
          <div class="price-group">
            <span class="price-value">{{ getBookPrice() }}</span>
            <m-icon src="/assets/img/app-bar-menu/token-simple-{theme}.svg" size="l-plus" />
          </div>
          <span class="price-name">{{ pluralizeCredits() }}</span>
        </div>
        <div class="balance">
          <span i18n="@@books.preview.payments-modal.your-balance">books.preview.payments-modal.your-balance</span>
          {{ getUserCreditsBalance() }}
        </div>
        <span *ngIf="isNotEnoughCredits()" class="not-enought">{{ pluralizeNotEnoughtCredits() }}</span>
        <m-button
          *ngIf="!isNotEnoughCredits()"
          class="button"
          button-style="primary"
          (onClick)="onClickPayCredits()"
          i18n="@@books.preview.payments-modal.use-simple"
        >
          books.preview.payments-modal.use-simple
        </m-button>
        <m-button *ngIf="isNotEnoughCredits()" class="button" button-style="primary" (onClick)="onClickBuyCredits()">
          {{ getBuyCreditsPrice() }}
        </m-button>

        <div class="button-close" (click)="onCloseClick()">
          <svg-icon class="icon-close" src="/assets/icons/cross.svg"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</div>
