import { Component, Input } from '@angular/core';

@Component({
  selector: 'm-pricing-nav-view',
  template: `
    <div class="nav-tabs" [ngClass]="getCssClassList()">
      <m-pricing-menu-item-component
        routerLink="/payments/subscription"
        routerLinkActive="active"
        title="pricing.nav-tabs.subscription"
        i18n-title="@@pricing.nav-tabs.subscription"
      />

      <!-- <m-pricing-menu-item-component
        routerLink="/payments/credits"
        routerLinkActive="active"
        title="pricing.nav-tabs.buying-credits"
        i18n-title="@@pricing.nav-tabs.buying-credits"
      /> -->
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        height: var(--nav-tabs-pricing-height);
        user-select: none;
      }

      .nav-tabs {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;

        border-bottom: 1px solid var(--color-border-nav-tabs);
        column-gap: 32px;

        &.position-left {
          justify-content: flex-start;
        }

        &.position-center {
          justify-content: center;
        }

        &.position-right {
          justify-content: flex-end;
        }

        &.edge-padding {
          padding: 0 32px;
        }
      }
    `,
  ],
})
export class PricingNavView {
  @Input()
  alignTabs: 'left' | 'center' | 'right' = 'left';
  @Input()
  edgePadding = true;

  protected getCssClassList(): string[] {
    const result: string[] = [];

    result.push(`position-${this.alignTabs}`);
    if (this.edgePadding) {
      result.push('edge-padding');
    }

    return result;
  }
}
