<div class="container">
  <m-ng-scrollbars>
    <div class="cards">
      <m-account-payment-method [newPaymentMethod]="true" (onAddPaymentMethod)="onAddNewPaymentMethodClick()" />
      <m-account-payment-method
        *ngFor="let pm of paymentMethods; trackBy: trackByPaymentMethodId"
        [pm]="pm"
        [isActive]="isPaymentMethodActive(pm)"
        (onSelectPaymentMethod)="selectPaymentMethodClick($event)"
        (onDeletePaymentMethod)="showDeletePaymentMethodModal($event)"
      />
    </div>
  </m-ng-scrollbars>
</div>

<m-modal
  *ngIf="paymentMethodForDelete"
  position="center"
  [closeButtonVisible]="false"
  (close)="closeDeletePaymentMethodModal()"
>
  <div class="delete-modal">
    <span i18n="@@user.profile.tariff-and-payments.delete-card-confirmation">
      user.profile.main.delete-card-confirmation
    </span>

    <div class="buttons-modal">
      <m-button
        button-style="secondary"
        size="full-width"
        (onClick)="closeDeletePaymentMethodModal()"
        i18n="@@dialog.cancel-button"
      >
        dialog.cancel-button
      </m-button>
      <m-button button-style="primary" (onClick)="deletePaymentMethod()" i18n="@@dialog.delete-button">
        dialog.delete-button
      </m-button>
    </div>
  </div>
</m-modal>
