import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CompanyFont } from "@metranpage/book-data";
import { Api, BASE_URL, FILES_URL } from "@metranpage/core-api";

export type FontsFilter = {
  name?: string;
};

@Injectable({
  providedIn: "root",
})
export class FontsApi extends Api {
  constructor(
    http: HttpClient,
    @Inject(BASE_URL) baseUrl: string,
    @Inject(FILES_URL) private filesUrl: string,
  ) {
    super(http, baseUrl);
  }

  async getCompanyFonts() {
    return this.get<CompanyFont[]>("fonts");
  }

  getCompanyFontUrl(font: CompanyFont) {
    return `${this.filesUrl}/${font.file.vid}`;
  }
}
