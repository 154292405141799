import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import {
  ColorConverterService,
  HSV,
  PaletteColorName,
  PaletteDTO,
  fadeInOutForSidebarElements,
  slideInOutVertical,
} from "@metranpage/components";
import * as _ from "lodash-es";
import { Observable, Subscription, filter } from "rxjs";
import { MarkupService } from "../../services/markup.service";

@Component({
  selector: "m-books-markup-sidebar-colors",
  templateUrl: "./markup-sidebar-colors-settings.view.html",
  styleUrls: ["./markup-sidebar-colors-settings.view.scss"],
  animations: [slideInOutVertical, fadeInOutForSidebarElements],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkupSidebarColorsSettings),
      multi: true,
    },
  ],
})
export class MarkupSidebarColorsSettings implements OnDestroy, ControlValueAccessor {
  @Input()
  form!: FormGroup;
  @Input()
  isSettingsAvailable = false;

  @Output()
  onClose = new EventEmitter<void>();

  private defaultHSVColor: HSV = { h: 0, s: 0, v: 0 };

  private palette!: PaletteDTO;
  private initialPalette?: PaletteDTO = undefined;
  protected selectedColorName: PaletteColorName = "colorAccent";
  protected selectedColorValue: HSV = this.defaultHSVColor;
  protected selectedColorDescription = "";

  protected formColorPicker!: FormGroup;

  protected selectedSidebarTab$ = new Observable<number>();

  private sub = new Subscription();

  private onTouched = () => {};
  private onChange = (_: any) => {};

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly markupService: MarkupService,
    private colorConverterService: ColorConverterService,
  ) {}

  ngOnInit(): void {
    this.watchForClose();
    this.initFormColorPicker();
    this.watchFormColorPickerChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  writeValue(value: PaletteDTO): void {
    this.palette = value;

    if (!this.initialPalette) {
      this.initialPalette = _.clone(value);
    }

    this.selectColor(this.selectedColorName);
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  private watchForClose() {
    this.selectedSidebarTab$ = this.markupService.selectedSidebarTab$;
    this.sub.add(
      this.selectedSidebarTab$.pipe(filter((e) => e === 1)).subscribe(() => {
        this.onCancelClick();
      }),
    );
  }

  private initFormColorPicker() {
    this.formColorPicker = new FormGroup({
      colorPicker: new FormControl(this.selectedColorValue, {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  private watchFormColorPickerChanges() {
    this.sub.add(
      this.formColorPicker.get("colorPicker")?.valueChanges.subscribe((value) => {
        this.selectedColorValue = value;
        this.updatePalette(value);
      }),
    );
  }

  private updatePalette(color: HSV) {
    this.palette[this.selectedColorName] = color;
    this.onChange(this.palette);
  }

  onCancelClick() {
    this.onChange(this.initialPalette);
    this.onClose.emit();
  }

  onApplyClick() {
    this.onClose.emit();
  }

  protected getColorTitle(color: PaletteColorName) {
    switch (color) {
      case "colorAccent":
        return $localize`:@@books.markup.sidebar.color-accent-title:`;
      case "colorPrimary":
        return $localize`:@@books.markup.sidebar.color-primary-title:`;
      case "colorSecondary":
        return $localize`:@@books.markup.sidebar.color-secondary-title:`;
      case "colorDecor":
        return $localize`:@@books.markup.sidebar.color-decor-title:`;
      default:
        return $localize`:@@books.markup.sidebar.color-primary-title:`;
    }
  }

  protected getColorNames() {
    return Object.keys(_.omit(this.palette, "colorDecor")) as PaletteColorName[];
    // return Object.keys(this.palette) as PaletteColorName[];
  }

  protected getCssClassList(color: PaletteColorName): string[] {
    const result: string[] = [];

    result.push("color");
    if (this.selectedColorName === color) {
      result.push("selected");
    }

    return result;
  }

  protected getColorPreviewStyle(colorName: PaletteColorName) {
    const color = this.colorConverterService.hsv2hex(this.palette?.[colorName] ?? this.defaultHSVColor);
    return { "background-color": color };
  }

  protected selectColor(color: PaletteColorName) {
    this.selectedColorName = color;
    this.selectedColorValue = _.clone(this.palette[this.selectedColorName]) ?? this.defaultHSVColor;

    switch (color) {
      case "colorAccent":
        this.selectedColorDescription = $localize`:@@books.markup.sidebar.colors-description-display:`;
        break;
      case "colorPrimary":
        this.selectedColorDescription = $localize`:@@books.markup.sidebar.colors-description-main:`;
        break;
      case "colorSecondary":
        this.selectedColorDescription = $localize`:@@books.markup.sidebar.colors-description-secondary:`;
        break;
      default:
        this.selectedColorDescription = "";
    }

    this.formColorPicker.get("colorPicker")?.setValue(this.selectedColorValue, { emitEvent: false });
  }
}
