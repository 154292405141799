<div class="header" (click)="toggle()">
  <div class="title-group">
    <m-ng-icon src="dropdown-arrow-down.svg" [size]="16" [class.collapsed]="isCollapsed" />

    <span class="title">{{ title }}</span>
  </div>
</div>

<div class="content" *ngIf="isCollapsed" @slideInOutVertical>
  <m-new-project-modal-sidebar-section-item
    *ngFor="let option of options"
    [class.active]="isBookFormatActive(option.name)"
    [title]="option.title"
    [iconSrc]="option.iconSrc"
    (click)="onItemSelect(option)"
  />
  <ng-content></ng-content>
</div>
