import { Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { ConfigService } from "@metranpage/core-interfaces";
import { User, UserBalance } from "@metranpage/user-data";

@Component({
  selector: "m-app-bar",
  templateUrl: "./app-bar.view.html",
  styleUrls: ["./app-bar.view.scss"],
})
export class AppBarView {
  @Input()
  user?: User;
  @Input()
  userBalance!: UserBalance;
  @Input()
  isLanguageSwitchAvailable = true;
  @Input()
  hideNavbarLogo = false;
  @Input()
  hideNavbarRightParts = false;

  @Output()
  onThemeToggle = new EventEmitter<void>();
  @Output()
  onLanguageChange = new EventEmitter<string>();
  @Output()
  onLogoutClick = new EventEmitter<void>();
  @Output()
  onPromocodeEnter = new EventEmitter<string>();
}
