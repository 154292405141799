import { CommonModule, NgClass } from "@angular/common";

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { AngularSvgIconModule } from "angular-svg-icon";
import { IconComponent } from "../icon/icon.component";

export type InputType = "normal" | "email" | "password" | "number" | "date" | "with-prefix";
export type InputStyle = "normal" | "on-panel" | "custom";
export type InputSize = "s" | "m" | "m-plus" | "l" | "custom";
export type InputTextAlign = "left" | "center" | "right";

@Component({
  selector: "m-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  standalone: true,
  imports: [CommonModule, NgClass, ReactiveFormsModule, AngularSvgIconModule, IconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input()
  placeholder = "";
  @Input()
  isRequired = false;
  @Input("text-align")
  textAlign: InputTextAlign = "left";
  @Input()
  name = "";
  @Input()
  type: InputType = "normal";
  @Input("input-style")
  inputStyle: InputStyle = "normal";
  @Input("size")
  inputSize: InputSize = "m";
  @Input()
  tabIndex = 0;
  @Input()
  iconLeft?: string = undefined;
  @Input()
  iconRight?: string = undefined;
  @Input("is-highlighted")
  isHighlighted = false;
  @Input()
  step = 1;
  @Input()
  multiplier = 10;
  @Input()
  prefix = "";

  @Output()
  focus = new EventEmitter<void>();
  @Output()
  focusout = new EventEmitter<void>();

  protected isInputFocused = false;
  protected isPasswordVisible = false;
  protected isDisabled = false;
  protected value = "";

  @ViewChild("input", { static: true, read: ElementRef })
  protected inputElementRef?: ElementRef;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    if (this.type === "password") {
      this.setPasswordEyeIcon();

      if (!this.iconLeft) {
        this.iconLeft = "input-lock.svg";
      }
    }
  }

  writeValue(value: string): void {
    this.value = value;
    this.renderer.setProperty(this.inputElementRef?.nativeElement, "value", this.value);
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInputChange(event: Event) {
    const value = (event.target! as HTMLInputElement).value;
    if (this.type === "number") {
      this.onChange(+value); // cast to number
    } else {
      this.onChange(value);
    }
  }

  /**
   * Return values available here:
   *  https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  protected getAutocomplete(): string {
    if (this.type === "email") {
      return "email";
    }
    return "random-string-as-off-value-not-work";
  }

  protected getInputType(): string {
    if (this.type === "email") {
      return "email";
    }
    if (this.type === "number") {
      return "number";
    }
    if (this.type === "password") {
      if (this.isPasswordVisible) {
        return "text";
      } else {
        return "password";
      }
    }
    if (this.type === "date") {
      return "date";
    }
    return "text";
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    result.push(this.inputStyle);
    result.push(`size-${this.inputSize}`);

    result.push(`text-align-${this.textAlign}`);

    if (this.isInputFocused) {
      result.push("focused");
    }
    if (this.iconLeft) {
      result.push("has-left-icon");
    }
    if (this.iconRight) {
      result.push("has-right-icon");
    }

    if (this.isHighlighted) {
      result.push("highlighted");
    }

    if (this.isDisabled) {
      result.push("disabled");
    }

    return result;
  }

  protected onInputFocus() {
    this.isInputFocused = true;
    this.onTouched();

    this.focus.emit();
  }

  protected onInputBlur() {
    this.isInputFocused = false;

    this.focusout.emit();
  }

  protected onKeyDown(event: KeyboardEvent) {
    if (this.type !== "number") {
      return;
    }

    let value = (event.target! as HTMLInputElement).value;
    value = value.replaceAll(",", ".");
    const digitNumber = +value;
    if (Number.isNaN(digitNumber)) {
      return;
    }

    if (event.key === "ArrowUp") {
      event.preventDefault();
      this.increase(digitNumber, event.shiftKey);
    }
    if (event.key === "ArrowDown") {
      event.preventDefault();
      this.decrease(digitNumber, event.shiftKey);
    }
  }

  protected increase(value: number, isMultiplierEnable: boolean) {
    if (value % 1 !== 0) {
      value = Math.ceil(value);
    } else {
      const addendum = isMultiplierEnable ? this.step * this.multiplier : this.step;
      value += addendum;
    }
    this.setValue(value);
  }

  protected decrease(value: number, isMultiplierEnable: boolean) {
    if (value % 1 !== 0) {
      value = Math.floor(value);
    } else {
      const addendum = isMultiplierEnable ? this.step * this.multiplier : this.step;
      value -= addendum;
    }
    this.setValue(value);
  }

  setValue(value: number) {
    this.renderer.setProperty(this.inputElementRef?.nativeElement, "value", value);
    this.onChange(value);
  }

  protected getLeftIconAsset(): string | undefined {
    if (!this.iconLeft) {
      return undefined;
    }
    return `/assets/icons/${this.iconLeft}`;
  }

  protected getRightIconAsset(): string | undefined {
    if (!this.iconRight) {
      return undefined;
    }
    return `/assets/icons/${this.iconRight}`;
  }

  protected getRightIconClassList(): string[] {
    const result: string[] = [];

    if (!this.isPasswordVisible) {
      result.push("icon-right-eye-closed");
    }
    return result;
  }

  private setPasswordEyeIcon() {
    if (this.type === "password") {
      if (this.isPasswordVisible) {
        this.iconRight = "input-eye.svg";
      } else {
        this.iconRight = "input-eye-closed.svg";
      }
    }
  }

  protected onRightIconClick() {
    this.isPasswordVisible = !this.isPasswordVisible;
    this.setPasswordEyeIcon();
  }
}
