import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { ImageGeneration } from "../models/image-generation";

export type ImageGenerationState = {
  rootId: number;
  variantImageGenerations: ImageGeneration[];
};

@Injectable({
  providedIn: "root",
})
export class VariantImageGenerationStore extends Store<ImageGenerationState> {
  protected override getInitialState(): ImageGenerationState {
    return {
      rootId: 0,
      variantImageGenerations: [],
    };
  }

  getVariantImageGenerationsObservable() {
    return this.getFieldObservable("variantImageGenerations");
  }

  getVariantImageGenerations() {
    return this.getField("variantImageGenerations");
  }

  setVariantImageGenerations(rootId: number, variantImageGenerations: ImageGeneration[]) {
    this.update((state) => ({ ...state, rootId, variantImageGenerations }));
  }

  addVariantImageGeneration(variantImageGenerations: ImageGeneration) {
    this.update((state) => {
      const storeVariantImageGeneration = state.variantImageGenerations.find(
        (ig) => ig.id === variantImageGenerations.id,
      );
      if (storeVariantImageGeneration) {
        return state;
      }

      return {
        ...state,
        variantImageGenerations: [variantImageGenerations].concat(state.variantImageGenerations),
      };
    });
  }

  updateVariantImageGeneration(updatedVariantImageGeneration: ImageGeneration) {
    if (updatedVariantImageGeneration.rootGenerationId !== this.getState().rootId) {
      return;
    }

    const variantImageGenerations = this.getVariantImageGenerations();
    const variantImageGeneration = variantImageGenerations.find((g) => g.id === updatedVariantImageGeneration.id);
    if (variantImageGeneration) {
      this.update((state) => {
        const updatedVariantImageGenerations = state.variantImageGenerations.map((g) => {
          if (g.id === updatedVariantImageGeneration.id) {
            return updatedVariantImageGeneration;
          }
          return g;
        });

        return {
          ...state,
          variantImageGenerations: updatedVariantImageGenerations,
        };
      });
    } else {
      this.addVariantImageGeneration(updatedVariantImageGeneration);
    }
  }
}
