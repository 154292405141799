import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'm-info-modal',
  templateUrl: './info-modal.view.html',
  styleUrls: ['./info-modal.view.scss'],
})
export class InfoModalView {
  @Input()
  title = '';
  @Input()
  text = '';
  @Input()
  actionText = '';
  @Input()
  backgroundImage = '';

  @Output()
  actionClick = new EventEmitter();
  @Output()
  close = new EventEmitter();

  onActionClick() {
    this.actionClick.emit();
  }

  onCloseClick() {
    this.close.emit();
  }
}
