<m-modal
  [paddingSize]="24"
  [closeButtonVisible]="closeButtonVisible"
  [closeOnBackDropClick]="closeOnBackDropClick"
  (close)="onCloseClick()"
>
  <div class="container">
    <m-icon src="/assets/img/login-modal-{theme}.png" />

    <div class="content">
      <div class="title" i18n="@@user.auth.login-registration.modal.title">
        user.auth.login-registration.modal.title
      </div>
      <m-localized-text
        class="description"
        text="user.auth.login-registration.modal.description"
        i18n-text="@@user.auth.login-registration.modal.description"
      />
    </div>

    <m-button
      button-style="primary"
      text-align="center"
      size="full-width"
      i18n="@@user.auth.login-registration.button"
      (onClick)="onLoginClick()"
    >
      user.auth.login-registration.button
    </m-button>
  </div>
</m-modal>
