import { Component, Inject } from "@angular/core";
import { CompanyStore } from "@metranpage/company";
import { LoadingService, filterUndefined } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { ActiveSubscription, PaymentCurrency, Tariff } from "@metranpage/pricing-data";
import { UserStore } from "@metranpage/user-data";
import { UserRefresher } from "@metranpage/user-interfaces";
import * as _ from "lodash-es";
import { Observable, Subscription } from "rxjs";
import { PricingService } from "../../services/pricing.service";
import { FAQ } from "../../views/faq/faq.view";

@Component({
  selector: "m-pricing-subscription-page",
  templateUrl: "./pricing-subscription.page.html",
  styleUrls: ["./pricing-subscription.page.scss"],
})
export class PricingSubscriptionPage {
  activeTariffPeriod = 1;
  tariffsPeriods: number[] = [];
  tariffs: Tariff[] = [];

  activeSubscription: Observable<ActiveSubscription | undefined>;

  faqs: FAQ[] = [
    {
      title: $localize`:@@pricing.faq.title-1:`,
      text: $localize`:@@pricing.faq.text-1:`,
    },
    {
      title: $localize`:@@pricing.faq.title-2:`,
      text: $localize`:@@pricing.faq.text-2:`,
    },
    {
      title: $localize`:@@pricing.faq.title-3:`,
      text: $localize`:@@pricing.faq.text-3:`,
    },
    {
      title: $localize`:@@pricing.faq.title-4:`,
      text: $localize`:@@pricing.faq.text-4:`,
    },
    {
      title: $localize`:@@pricing.faq.title-5:`,
      text: $localize`:@@pricing.faq.text-5:`,
    },
  ];

  currency: PaymentCurrency = "RUB";

  private sub = new Subscription();

  constructor(
    private readonly pricingService: PricingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private userStore: UserStore,
    @Inject("UserRefresher")
    userRefresher: UserRefresher,
    private companyStore: CompanyStore,
  ) {
    userRefresher.refreshUser();

    this.activeSubscription = this.userStore.getActiveSubscriptionObservable().pipe(filterUndefined());

    this.sub.add(
      this.activeSubscription.subscribe((activeSubscription) => {
        this.setActiveTariffsPeriod(activeSubscription);
      }),
    );

    this.sub.add(
      companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.currency = company.currency;
        }),
    );
  }

  async ngOnInit() {
    this.loadingService.startLoading({ fullPage: true });

    this.tariffs = await this.pricingService.getTariffsForCompany();
    this.tariffsPeriods = this.getTariffsPeriods(this.tariffs);

    this.loadingService.stopLoading();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async onBuySubscriptionClick(tariff: Tariff) {
    window.open(`payments/await-payment-link?tariffId=${tariff.id}`, "_blank");
  }

  protected getTariffsForPeriod(): Tariff[] {
    const freeTariffForPeriod = this.tariffs.filter((t) => t.isFree);
    const tariffForPeriod = this.tariffs.filter((t) => !t.isFree && t.period === this.activeTariffPeriod);
    if (freeTariffForPeriod.length || tariffForPeriod.length) {
      return freeTariffForPeriod.concat(tariffForPeriod);
    }
    return [];
  }

  protected getTariffsPeriods(tariffs: Tariff[]) {
    let periods = tariffs.map((t) => t.period);
    if (periods.length) {
      periods = _.uniq(periods);
      periods = _.sortBy(periods);
      return periods;
    }
    return [];
  }

  protected onPeriodSelectClick(tariffPeriod: number) {
    this.activeTariffPeriod = tariffPeriod;
  }

  protected onTariffSettingsClick(data: any) {}

  protected setActiveTariffsPeriod(activeSubscription: ActiveSubscription | undefined) {
    if (activeSubscription) {
      this.activeTariffPeriod = activeSubscription.tariff.period;
    }
  }
}
