import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { StyleDef } from "@metranpage/markup-editor";
import { InfoBlockData } from "@metranpage/components";
import { Subject, Subscription, filter } from "rxjs";

type TextSettingsStyleControlsKey = "fontSize" | "leading" | "tracking";
type InputChanges = { ck: string; error: string };

@Component({
  selector: "m-books-markup-paragraph-text-settings",
  templateUrl: "./markup-paragraph-text-settings.view.html",
  styleUrls: ["./markup-paragraph-text-settings.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkupParagraphTextSettings implements OnDestroy {
  @Input()
  style!: StyleDef;
  @Input()
  form!: FormGroup;

  private sub = new Subscription();

  protected warnings: InfoBlockData[] = [];

  inputChanged: Subject<void> = new Subject<void>();
  inputChanges: InputChanges = { ck: "", error: "" };

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.watchCorrectValue();

    this.checkOnErrors();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  watchCorrectValue() {
    const styleKeys: TextSettingsStyleControlsKey[] = ["fontSize", "leading", "tracking"];

    for (const key of styleKeys) {
      this.sub.add(
        this.form
          ?.get("styles")
          ?.get(this.style.styleKey)
          ?.get(key)
          ?.valueChanges.subscribe((value) => {
            this.warnings = [];

            const errors = this.hasStyleControlsKeyError(key);

            if (errors?.min) {
              this.form?.get("styles")?.get(this.style.styleKey)?.get(key)?.setValue(errors.min.min);

              this.inputChanges = { ck: key, error: "min" };
            }
            if (errors?.max) {
              this.form?.get("styles")?.get(this.style.styleKey)?.get(key)?.setValue(errors.max.max);

              this.inputChanges = { ck: key, error: "max" };
            }
            if (errors?.ltMainFontSize) {
              const mainFontSize = this.form?.get("styles")?.get("mainText")?.get("fontSize")?.value ?? 10;
              this.form?.get("styles")?.get(this.style.styleKey)?.get(key)?.setValue(mainFontSize);

              this.inputChanges = { ck: key, error: "min" };
            }

            this.inputChanged.next();
          }),
      );
    }

    this.sub.add(
      this.inputChanged
        .pipe(filter(() => this.inputChanges.ck === "fontSize" && this.inputChanges.error === "min"))
        .subscribe(() => {
          this.inputChanges = { ck: "", error: "" };

          this.warnings.push({ textData: [{ text: $localize`:@@books.markup.style-details.font-size-error:` }] });
        }),
    );
  }

  protected hasStyleControlsKeyError(key: TextSettingsStyleControlsKey, errorKeys: string[] | undefined = undefined) {
    const errors = this.form?.get("styles")?.get(this.style.styleKey)?.get(key)?.errors;
    if (errorKeys) {
      for (const errorKey of errorKeys) {
        if (errors?.[errorKey]) {
          return errors;
        }
      }
      return undefined;
    }
    return errors;
  }

  protected checkOnErrors() {
    const warnings: InfoBlockData[] = [];

    if (this.hasStyleControlsKeyError("fontSize", ["min", "ltMainFontSize"])) {
      warnings.push({ textData: [{ text: $localize`:@@books.markup.style-details.font-size-error:` }] });
    }

    this.warnings = warnings;
  }
}
