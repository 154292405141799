import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { I18nService } from "@metranpage/i18n";
import { NotificationBookWithoutCover } from "../../../models/notification";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-book-without-cover-card-view",
  templateUrl: "account-notification-book-without-cover-card.view.html",
  styleUrls: ["account-notification-book-without-cover-card.view.scss"],
})
export class AccountNotificationBookWithoutCoverCardView extends AccountNotificationBaseCardView<NotificationBookWithoutCover> {
  protected locale = "en";

  constructor(
    private readonly router: Router,
    private readonly i18nService: I18nService,
  ) {
    super();
    this.locale = i18nService.getLocale();
  }

  protected createBookCover() {
    this.router.navigate(["books", this.payload.bookId, "cover"]);
  }
}
