import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { ConfigService } from "@metranpage/core-interfaces";
import { CompanyEditionNoticeSettings, CompanyEditionNoticeSettingsUpdateData } from "../../models/company";
import { EditionNoticeFileType } from "../../pages/edition-notice-settings/edition-notice-settings.page";

@Injectable({
  providedIn: "root",
})
export class AdminCompanyEditionNoticeSettingsApi extends Api {
  constructor(
    http: HttpClient,
    @Inject(BASE_URL) baseUrl: string,
    @Inject("ConfigService")
    private readonly configService: ConfigService,
  ) {
    super(http, baseUrl);
  }

  async getEditionNoticeSettings() {
    return this.get<CompanyEditionNoticeSettings[]>("admin/edition-notice-settings");
  }

  async updateEditionNoticeData(data: CompanyEditionNoticeSettingsUpdateData[]) {
    return this.post("admin/edition-notice-settings", data);
  }

  async uploadEditionNoticeFile(file: File, lang: string, type: EditionNoticeFileType) {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post(`admin/edition-notice-settings/${lang}/${type}`, formData, {
      headers,
    });
  }

  deleteEditionNoticeFile(lang: string, type: EditionNoticeFileType) {
    return this.delete<unknown>(`admin/edition-notice-settings/${lang}/${type}`);
  }

  getPreviewUrl(lang: string, imageName: string) {
    return `${this.configService.getConfig().environment.apiRootUrl}/edition-notice/${lang}/previews/${imageName}`;
  }
}
