<m-modal-backdrop
  [closeButtonVisible]="closeButtonVisible"
  [closeOnBackDropClick]="closeOnBackDropClick"
  (close)="onCloseClick()"
>
  <m-ng-island>
    <div class="new-project-modal">
      <div *ngIf="isSidebarVisible" class="sidebar">
        <div class="header">
          <m-ng-icon src="folder-simple-user-outline.svg" [size]="28" />
          <span i18n="@@home.new-project-modal.title">home.new-project-modal.title</span>
        </div>

        <div class="divider-hor"></div>

        <div class="menu">
          <m-new-project-modal-sidebar-section
            title="home.new-project-modal.sidebar-section.book"
            i18n-title="@@home.new-project-modal.sidebar-section.book"
            [options]="bookFormatOptions"
          />

          <!-- <m-new-project-modal-sidebar-section
          title="home.new-project-modal.sidebar-section.corporate-material"
          i18n-title="@@home.new-project-modal.sidebar-section.corporate-material"
          [options]="corporateMaterialOptions"
          [hostUrl]="hostUrl"
        />

        <m-new-project-modal-sidebar-section
          title="home.new-project-modal.sidebar-section.scientific-publication"
          i18n-title="@@home.new-project-modal.sidebar-section.scientific-publication"
          [hostUrl]="hostUrl"
        /> -->
        </div>

        <div class="divider-hor"></div>

        <div class="buttons">
          <m-button
            *ngIf="isUserTemplateUploadAvailable"
            button-style="secondary"
            size="full-width"
            (onClick)="showUploadTemplateModal()"
            i18n="@@home.new-project-modal.upload-template"
          >
            home.new-project-modal.upload-template
          </m-button>
        </div>
      </div>

      <div *ngIf="isSidebarVisible" class="divider-ver"></div>

      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </m-ng-island>
</m-modal-backdrop>

<m-template-upload-modal *ngIf="isUploadTemplateModalVisible" [user]="user" (onClose)="closeUploadTemplateModal()" />
