import { Injectable } from "@angular/core";
import { StyleSettings } from "../models/styles";
import { ColorConverterService, HSV } from "./color-converter.service";

@Injectable({
  providedIn: "root",
})
export class MarkupPreviewService {
  constructor(private colorConverterService: ColorConverterService) { }

  applyPreviewStyle(el: Element, styleDisplayOpts: StyleSettings, isHeaderBefore = true) {
    const cssStyles: string[] = [];
    cssStyles.push(`font-family: ${styleDisplayOpts.font}`);
    cssStyles.push(`font-size: ${styleDisplayOpts.fontSize * 1.5}px`);

    cssStyles.push(`line-height: ${styleDisplayOpts.leading}%`);
    cssStyles.push(`letter-spacing: ${styleDisplayOpts.tracking / 100}px`);

    if (styleDisplayOpts.emphasis === "italic" || styleDisplayOpts.emphasis === "bolditalic") {
      cssStyles.push("font-style: italic");
    }

    if (styleDisplayOpts.emphasis === "bold" || styleDisplayOpts.emphasis === "bolditalic") {
      cssStyles.push("font-weight: 700");
    }
    if (styleDisplayOpts.align === "leftJustify") {
      cssStyles.push("text-align: justify");
    } else {
      cssStyles.push(`text-align: ${styleDisplayOpts.align}`);
    }

    if (styleDisplayOpts.case === "small-caps") {
      cssStyles.push("font-variant: small-caps");
    } else {
      cssStyles.push(`text-transform: ${styleDisplayOpts.case}`);
    }

    if (styleDisplayOpts.color) {
      const color = this.colorConverterService.hsv2hex(styleDisplayOpts.color);
      cssStyles.push(`color: ${color}`);
    }

    if (styleDisplayOpts.indentParagraph === "indented-line") {
      if ((isHeaderBefore && styleDisplayOpts.indentParagraphAfterHeader) || !isHeaderBefore) {
        cssStyles.push("text-indent: 20px;");
      }
    }

    el.setAttribute("style", cssStyles.join(";"));
  }

  applyDropCap(el: HTMLElement, styleDisplayOpts: StyleSettings, isHeaderBefore = true) {
    el.classList.remove("drop-cap");

    if (!isHeaderBefore) {
      return;
    }

    const color = this.colorConverterService.hsv2hex(styleDisplayOpts.color ?? { h: 0, s: 0, v: 0 });

    if (styleDisplayOpts.dropCap) {
      el.classList.add("drop-cap");
      el.style.setProperty("--drop-cap-font-size", `${styleDisplayOpts.fontSize * 4.3 * 1.5}px`);
      // el.style.setProperty(`--drop-cap-line-height`, `${80}%`);
      el.style.setProperty("--drop-cap-color", color);
    }
  }

  applyIndentHeaderStyle(el: Element, styleDisplayOpts: StyleSettings) {
    const cssStyles: string[] = [];

    if (styleDisplayOpts.indentHeader === "before") {
      cssStyles.push("padding-top: 50px");
    }
    if (styleDisplayOpts.indentHeader === "after") {
      cssStyles.push("padding-bottom: 50px");
    }

    el.setAttribute("style", cssStyles.join(";"));
  }

  applyColorStyle(el: Element, colorHsv: HSV | null | undefined) {
    const cssStyles: string[] = [];

    if (colorHsv) {
      const color = this.colorConverterService.hsv2hex(colorHsv);
      cssStyles.push(`color: ${color}`);
    }

    el.setAttribute("style", cssStyles.join(";"));
  }
}
