import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { User } from "@metranpage/user-data";
import { Subscription } from "rxjs";

export type AccountContactsData = {
  email: string;
};

@Component({
  selector: "m-account-contacts-view",
  templateUrl: "./account-contacts.view.html",
  styleUrls: ["./account-contacts.view.scss"],
})
export class AccountContactsView implements OnInit, OnDestroy {
  @Input()
  user?: User;
  @Input()
  savedStatus = false;

  @Output()
  onSaveClick = new EventEmitter<AccountContactsData>();
  @Output()
  onVerifyEmailClick = new EventEmitter<void>();

  protected initialValue?: AccountContactsData;
  protected hasChange = false;
  protected isSaving = false;

  private sub = new Subscription();

  protected form = new FormGroup({
    email: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
  });

  ngOnInit(): void {
    this.initForm();
    this.watchFormChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.savedStatus) {
      this.savedStatus = changes.savedStatus.currentValue;
      this.isSaving = false;
      this.hasChange = false;
      this.initialValue = this.form.getRawValue();
    }
  }

  initForm() {
    this.initialValue = {
      email: this.user!.email,
    };
    this.form.setValue(this.initialValue);
  }

  watchFormChanges() {
    this.sub.add(
      this.form.valueChanges.subscribe((value) => {
        if (!this.initialValue) {
          return;
        }
        this.hasChange = Object.entries(this.initialValue).some(([key, value]) => value !== this.form.get(key)?.value);
      }),
    );
  }

  protected onSave() {
    this.isSaving = true;
    this.onSaveClick.emit(this.form.getRawValue());
  }

  protected onVerifyEmail() {
    this.onVerifyEmailClick.emit();
  }
}
