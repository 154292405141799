import { Component } from "@angular/core";
import { I18nService } from "@metranpage/i18n";
import { NotificationSubscriptionActivate } from "@metranpage/user-data";
import { DateTime } from "luxon";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-subscription-activate-card-view",
  templateUrl: "account-notification-subscription-activate-card.view.html",
  styleUrls: ["account-notification-subscription-activate-card.view.scss"],
})
export class AccountNotificationSubscriptionActivateCardView extends AccountNotificationBaseCardView<NotificationSubscriptionActivate> {
  protected locale = "en";

  constructor(private readonly i18nService: I18nService) {
    super();
    this.locale = i18nService.getLocale();
  }

  protected getDateShort() {
    const date = this.payload.expireDate;
    return DateTime.fromISO(date.toString()).toLocaleString(DateTime.DATE_FULL, { locale: this.locale });
  }

  protected getTitle() {
    return `${$localize`:@@user.profile.notifications.card.subscription-activate.title-1:`}
      «${this.payload.tariffTitle}»
      ${$localize`:@@user.profile.notifications.card.subscription-activate.title-2:`}
      ${this.payload.tariffPeriod} 
      ${this.i18nService.pluralize($localize`:@@pricing.pluralize-months:`, { months: this.payload.tariffPeriod })}
    `;
  }
}
