import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { IconComponent } from "@metranpage/components";
import { AngularSvgIconModule } from "angular-svg-icon";
import { Observable, Subscription } from "rxjs";
import { Breadcrumb } from "../models/breadcrumbs";
import { BreadcrumbsService } from "./breadcrumbs.service";

@Component({
  selector: "m-breadcrumbs",
  templateUrl: "./breadcrumbs.view.html",
  styleUrls: ["./breadcrumbs.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, IconComponent, RouterModule],
})
export class Breadcrumbs implements OnDestroy {
  breadcrumbs: Observable<Breadcrumb[]>;
  sub: Subscription;

  constructor(
    private readonly router: Router,
    private readonly breadcrumbsService: BreadcrumbsService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.breadcrumbs = this.breadcrumbsService.breadcrumbs$;

    this.sub = this.breadcrumbs.subscribe(() => this.cdr.markForCheck());
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected getCssClassList(url: string, urls: string[] | undefined): string[] {
    const result: string[] = [];
    const urlActive = this.router.url.replace("/", "");
    result.push("breadcrumb");
    if (url === urlActive || urls?.includes(urlActive)) {
      result.push("active");
    }

    return result;
  }
}
