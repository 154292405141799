import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { SNCover, UpdateSNCoversData } from "../../models/social-network-covers";

@Injectable({
  providedIn: 'root'
})

export class SocialNetworkCoversApiService extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl)
  }

  getSNCovers(): Promise<SNCover[]> {
    return this.get<SNCover[]>('admin/social-network-covers')
  }

  updateSNCoverStates(data: UpdateSNCoversData): Promise<SNCover> {
    return this.post('admin/social-network-covers', data)
  }

}
