import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { Subscription } from "rxjs";
import { LoginError } from "../../models/login-errors";

export type Creds = {
  email: string;
  password: string;
};

@Component({
  selector: "m-login-view",
  templateUrl: "./login.view.html",
  styleUrls: ["./login.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class LoginView {
  @Input()
  errors: LoginError[] = [];
  @Input()
  isLoading = false;
  @Input()
  isVkLoginAvailable = true;
  @Input()
  isGoogleLoginAvailable = true;
  @Input()
  userLanguage = "ru";

  @Output()
  onVkLoginClick = new EventEmitter<void>();
  @Output()
  onGoogleLoginClick = new EventEmitter<void>();
  @Output()
  onLoginClick = new EventEmitter<Creds>();
  @Output()
  onLangChangeClick = new EventEmitter<string>();

  protected form = new FormGroup({
    email: new FormControl("", { nonNullable: true, validators: [] }),
    password: new FormControl("", { nonNullable: true, validators: [] }),
  });

  protected isInputEmailErrorVisible = false;
  protected isInputPasswordErrorVisible = false;

  private sub = new Subscription();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.watchFormChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onSignInClick() {
    this.isInputEmailErrorVisible = true;
    this.isInputPasswordErrorVisible = true;
    this.setFormValidators("email");
    this.setFormValidators("password");

    if (this.isSignInButtonDisabled()) {
      return;
    }

    const value = this.form.value;
    this.onLoginClick.emit({
      email: value.email!,
      password: value.password!,
    });
  }

  protected onKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      if (!this.isSignInButtonDisabled()) {
        this.onSignInClick();
      }
    }
  }

  protected isEmailIncorrect() {
    return this.form.get("email")?.errors?.["email"];
  }

  protected isFieldEmpty(controlName: string) {
    return this.form.get(controlName)?.errors?.["required"];
  }

  isSignInButtonDisabled() {
    return !this.form.valid;
  }

  protected watchFormChanges() {
    this.sub.add(
      this.form.get("email")?.valueChanges.subscribe((value) => {
        this.isInputEmailErrorVisible = false;
        this.clearFormValidators("email");
        this.cdr.detectChanges();
      }),
    );

    this.sub.add(
      this.form.get("password")?.valueChanges.subscribe((value) => {
        this.isInputPasswordErrorVisible = false;
        this.clearFormValidators("password");
        this.cdr.detectChanges();
      }),
    );
  }

  private setFormValidators(controlName: string) {
    if (controlName === "email") {
      this.form.get(controlName)?.setValidators([Validators.email, Validators.required]);
    }
    if (controlName === "password") {
      this.form.get(controlName)?.setValidators([Validators.required]);
    }
    this.form.get(controlName)?.updateValueAndValidity({ emitEvent: false });
    this.cdr.detectChanges();
  }

  private clearFormValidators(controlName: string) {
    this.form.get(controlName)?.clearValidators();
    this.form.get(controlName)?.updateValueAndValidity({ emitEvent: false });
  }

  protected getQueryParams() {
    return this.route.snapshot.queryParams;
  }

  protected onLangChange(lang: string) {
    this.onLangChangeClick.emit(lang);
  }

  protected isSocialLoginVisible() {
    return this.isVkLoginAvailable || this.isGoogleLoginAvailable;
  }

  protected onGoogleLogin() {
    this.onGoogleLoginClick.emit();
  }

  protected onVkLogin() {
    this.onVkLoginClick.emit();
  }

  protected focusoutEvent(controlName: string) {
    const value = this.form.get(controlName)?.value;
    if (value && controlName === "email") {
      this.isInputEmailErrorVisible = true;
    }
    if (value && controlName === "password") {
      this.isInputPasswordErrorVisible = true;
    }
    this.setFormValidators(controlName);
  }
}
