import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { FormatAccess, FormatAccessDto, FormatAccessUpdateData } from "../../models/format-access.model";
import { AdminFormatService } from "../format/format.service";
import { AdminFormatAccessApi, FormatAccessFilter } from "./format-access.api";
import { AdminFormatAccessStore } from "./format-access.store";

@Injectable({
  providedIn: "root",
})
export class AdminFormatAccessService {
  constructor(
    private readonly adminFormatService: AdminFormatService,
    private readonly adminFormatAccessApi: AdminFormatAccessApi,
    private readonly adminFormatAccessStore: AdminFormatAccessStore,
  ) {}

  async loadFormatsAccess() {
    const formatsAccessData = await this.adminFormatAccessApi.getFormatsAccess();
    const formatsAccess = await Promise.all(
      formatsAccessData.map(async (t) => {
        return await this.prepareFormatAccess(t);
      }),
    );
    this.adminFormatAccessStore.setFormatsAccess(formatsAccess);
  }

  async prepareFormatAccess(fa: FormatAccessDto) {
    const format = this.adminFormatService.updateFormatLocalization(fa.format);
    const formatAccess: FormatAccess = {
      ...fa,
      format: format,
    };

    return formatAccess;
  }

  async updateFormatAccess(id: number, data: FormatAccessUpdateData): Promise<GeneralResultStatus> {
    try {
      const updatedFormatAccess = await this.adminFormatAccessApi.updateFormatAccess(id, data);
      const formatAccess = await this.prepareFormatAccess(updatedFormatAccess);
      this.adminFormatAccessStore.updateFormatAccess(formatAccess);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }
}
