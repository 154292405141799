import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { fadeInOutOnEnterLeave, slideInOutVertical } from "@metranpage/components";
import { filterUndefined } from "@metranpage/core";
import { User } from "@metranpage/user-data";
import { Subscription, debounceTime, filter, timer } from "rxjs";
import { Character } from "../../models/character/character";
import { CharacterChangePopupEvent, CharactersService } from "../../services/characters.service";
import { PromptTextEditor, PromptTextErrors } from "./prompt-text-editor/prompt-text-editor.view";
import {
  PromptTextSelectionService,
  PromptTextSelectionState,
} from "./prompt-text-editor/services/prompt-text-selection.service";

@Component({
  selector: "m-generation-prompt",
  templateUrl: "./generation-prompt.view.html",
  styleUrls: ["./generation-prompt.view.scss"],
  animations: [slideInOutVertical, fadeInOutOnEnterLeave],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerationPromptView implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  user?: User;
  @Input()
  isModal = false;
  @Input()
  selectedTab = 1;
  @Input()
  formBasic?: FormGroup;
  @Input()
  formAdvanced?: FormGroup;
  @Input()
  igBasicPrice?: number;
  @Input()
  igAdvancedPrice?: number;
  @Input()
  promptMaxLenght = 350;
  @Input()
  charactersInPromptLimit = 3;
  @Input()
  characters: Character[] = [];

  @Output()
  onGeneration = new EventEmitter();
  @Output()
  onShowCharactersModal = new EventEmitter<boolean>();
  @Output()
  onCharactersIdsChanged = new EventEmitter<number[]>();

  @ViewChild("charactersPopup", { read: ElementRef })
  charactersPopupElement!: ElementRef;

  @ViewChild("charactersChangePopup", { read: ElementRef })
  charactersChangePopupElement!: ElementRef;

  @ViewChild("promptText")
  promptTextElement: PromptTextEditor | undefined = undefined;

  protected form?: FormGroup;

  protected availableCharacters: Character[] = [];
  protected addedCharactersIds: number[] = [];
  protected searchCharactersName = "";

  sub: Subscription = new Subscription();

  protected promptTextSelectionState: PromptTextSelectionState | undefined = undefined;

  protected isFormBasicInitialized = false;
  protected isFormAdvancedInitialized = false;

  protected isPopupActive = false;
  protected isChangePopupActive = false;

  protected changeCharacterId: number | undefined = undefined;

  protected promptErrors: PromptTextErrors = {
    hasNotExistingCharactersError: false,
    hasCharactersLimitError: false,
  };

  constructor(
    private readonly elementRef: ElementRef,
    private readonly charactersService: CharactersService,
    private readonly promptTextSelectionService: PromptTextSelectionService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.sub.add(
      charactersService.selectCharacter$.pipe(filterUndefined()).subscribe((character) => {
        this.changeCharacter(character);
      }),
    );

    // this.sub.add(
    //   charactersService.deleteCharacter$.pipe(filterUndefined()).subscribe((character) => {
    //     this.removeCharacter(character);
    //   }),
    // );

    this.sub.add(
      charactersService.showChangePopup$
        .pipe(
          debounceTime(1000),
          filter((v) => !v),
        )
        .subscribe((v) => {
          this.hideChangePopup();
        }),
    );
  }

  ngAfterViewInit() {
    this.updateFormDependingOnSelectedTab();

    this.sub.add(
      this.promptTextSelectionService.selectionState$.subscribe((promptTextSelectionState) => {
        this.promptTextSelectionState = promptTextSelectionState;
        this.onSelectionChange(promptTextSelectionState.range);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTab || changes.formBasic || changes.formAdvanced) {
      this.updateFormDependingOnSelectedTab();
    }

    if (this.formAdvanced && !this.isFormAdvancedInitialized) {
      this.isFormAdvancedInitialized = true;

      this.sub.add(
        this.formAdvanced.get("prompt")?.valueChanges.subscribe((value) => {
          const selection = this.promptTextElement?.getPromptTextSelectionState();
          if (selection) {
            this.changeCharacterId = undefined;
            this.promptTextSelectionService.onChangeSelectionState(selection);
          }
        }),
      );

      this.sub.add(
        this.formAdvanced.get("hasNotExistingCharacter")?.valueChanges.subscribe((value) => {
          this.promptErrors.hasNotExistingCharactersError = value;
        }),
      );

      this.sub.add(
        this.formAdvanced.get("isCharactersLimitReached")?.valueChanges.subscribe((value) => {
          this.promptErrors.hasCharactersLimitError = value;
        }),
      );
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  protected onGenerationClick() {
    this.onGeneration.emit();
  }

  protected showCharactersModalClick() {
    this.changeCharacterId = undefined;
    this.onShowCharactersModal.emit(false);
  }

  protected updateFormDependingOnSelectedTab() {
    if (this.selectedTab === 2) {
      this.form = this.formAdvanced;
      return;
    }
    this.form = this.formBasic;
  }

  protected getPrice() {
    let price = this.igBasicPrice;
    if (this.selectedTab === 2) {
      price = this.igAdvancedPrice;
    }
    return price;
  }

  onSelectionChange(range: Range | undefined) {
    if (!range) {
      return;
    }

    this.togglePopup(range);
  }

  protected togglePopup(range: Range) {
    const textContent = range.startContainer.textContent;
    if (!textContent) {
      return;
    }

    const data = this.promptTextElement?.getTextBetweenCommercialAtAndCaret(range);
    if (!data) {
      this.hidePopup();
      return;
    }

    const searchCharactersName = data.textBetweenCommercialAtAndCaret.replace("@", "");
    this.showPopup(range, searchCharactersName);
  }

  protected showPopup(range: Range, searchCharactersName: string) {
    if (this.isLimitCharactersAddedReached()) {
      return;
    }

    this.hideChangePopup();

    this.searchCharactersName = searchCharactersName;
    this.filterCharacters();

    this.isPopupActive = true;

    const selectionRect = range.getBoundingClientRect();
    const parentRect = this.elementRef.nativeElement.getBoundingClientRect();
    const charactersPopupStyle = this.charactersPopupElement.nativeElement.style;
    charactersPopupStyle.opacity = "1";
    charactersPopupStyle.left = `${selectionRect.x - parentRect.x}px`;
    charactersPopupStyle.top = `${selectionRect.y - parentRect.y + 34}px`;
  }

  protected hidePopup() {
    this.isPopupActive = false;
    this.searchCharactersName = "";

    const charactersPopupStyle = this.charactersPopupElement.nativeElement.style;
    charactersPopupStyle.opacity = "0";
    charactersPopupStyle.left = "-1000px";
  }

  protected onSelectFromPopup(character: Character) {
    this.promptTextElement?.addCharacterAtFakeCursor(character, false, true);
    this.hidePopup();
  }

  protected onSelectFromPopupByKeyboard(character: Character) {
    this.promptTextElement?.addFakeCursor();
    this.onSelectFromPopup(character);
  }

  protected showChangePopup(data: CharacterChangePopupEvent) {
    if (!data.element) {
      return;
    }

    this.hidePopup();

    this.changeCharacterId = data.id;

    this.searchCharactersName = "";
    this.filterCharacters();

    this.isChangePopupActive = true;

    const selectionRect = data.element.getBoundingClientRect();
    const parentRect = this.elementRef.nativeElement.getBoundingClientRect();
    const charactersPopupStyle = this.charactersChangePopupElement.nativeElement.style;
    charactersPopupStyle.opacity = "1";
    charactersPopupStyle.left = `${selectionRect.x - parentRect.x}px`;
    charactersPopupStyle.top = `${selectionRect.y - parentRect.y + 25}px`;
  }

  protected hideChangePopup() {
    this.isChangePopupActive = false;

    const charactersPopupStyle = this.charactersChangePopupElement.nativeElement.style;
    charactersPopupStyle.opacity = "0";
    charactersPopupStyle.left = "-1000px";
  }

  protected onAddCharacterFromChangePopup() {
    this.onShowCharactersModal.emit(true);
  }

  protected onSelectFromChangePopup(character: Character) {
    this.changeCharacter(character);
    this.hideChangePopup();
  }

  protected onSelectFromChangePopupByKeyboard(character: Character) {
    this.promptTextElement?.addFakeCursor();
    this.onSelectFromChangePopup(character);
  }

  protected showCharactersModal(changeCharacterId: number) {
    this.hidePopup();
    this.hideChangePopup();

    this.changeCharacterId = changeCharacterId;
    this.onShowCharactersModal.emit(true);
  }

  protected changeCharacter(character: Character) {
    if (this.changeCharacterId !== undefined) {
      this.promptTextElement?.changeCharacter(this.changeCharacterId, character);
      return;
    }

    if (this.isCharacterAdded(character) || this.isLimitCharactersAddedReached()) {
      return;
    }

    this.promptTextElement?.addCharacterAtFakeCursor(character, true, true);
  }

  // protected removeCharacter(character: Character) {
  //   this.promptTextElement?.removeCharacter(character.id);
  // }

  protected charactersIdsChanged(addedCharactersIds: number[]) {
    this.addedCharactersIds = addedCharactersIds;
    this.onCharactersIdsChanged.emit(this.addedCharactersIds);

    this.checkCharactersLimit();
  }

  protected notExistingCharactersIdsChanged(notExistingCharactersIds: number[]) {
    this.formAdvanced?.patchValue({ hasNotExistingCharacter: notExistingCharactersIds.length > 0 });
  }

  protected checkCharactersLimit() {
    this.formAdvanced?.patchValue({
      isCharactersLimitReached: this.charactersService.isCharactersLimitReached(
        this.addedCharactersIds,
        this.charactersInPromptLimit,
        false,
      ),
    });
  }

  protected isCharacterAdded(character: Character) {
    return this.addedCharactersIds.includes(character.id);
  }

  protected isLimitCharactersAddedReached() {
    return this.charactersService.isCharactersLimitReached(this.addedCharactersIds, this.charactersInPromptLimit);
  }

  protected filterCharacters() {
    this.availableCharacters = this.characters.filter((c) => {
      const isCharacterAdded = this.addedCharactersIds.includes(c.id);
      if (!this.searchCharactersName && !isCharacterAdded) {
        return true;
      }
      return c.name.toLowerCase().startsWith(this.searchCharactersName.toLowerCase()) && !isCharacterAdded;
    });
  }

  protected needBlockArrowsUpAndDown() {
    return this.isPopupActive || this.isChangePopupActive;
  }
}
