import { Injectable } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { NotificationsPopUpService } from "@metranpage/core";
import * as _ from "lodash-es";
import { BehaviorSubject, Subscription } from "rxjs";
import {
  AdvancedGenerationMode,
  ImageGeneration,
  ImageGenerationAdvancedStyle,
  ImageGenerationBasicStyle,
  ImageSize,
} from "../models/image-generation";
import { ImageGenerationUserStyle } from "../models/image-generation-user-style/image-generation-user-style";
import { GenerationImageColorSettings } from "../views/color-scheme-selector/color-scheme-selector.view";
import { ImageProportionWithSizeValue } from "../views/image-proportion-selector/image-proportion-selector.view";
import { ImageGenerationBlackListService } from "./image-generation-black-list.service";
import { ImageGenerationDataService } from "./image-generation-data.service";
import { ImageGenerationService } from "./image-generation.service";

export type GeneralResultStatus = "success" | "error";

export type BasicFormChangeData = {
  imagesCount: number;
  styleId: string;
};

export type AdvancedFormChangeData = {
  imagesCount: number;
  age: string;
  isAgeEnable: boolean;
  isColorSchemeEnable: boolean;
  isNegativePromptEnable: boolean;
  styleId: string;
  advancedGenerationMode: AdvancedGenerationMode;
  mood: string;
};

const imageGenerationBlackListService = new ImageGenerationBlackListService();

function promptValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (imageGenerationBlackListService.hasRestrictedText(value.prompt)) {
      return {
        restrictedWord: true,
      };
    }

    return null;
  };
}

function promptLengthValidator(minLength: number | undefined, maxLength: number | undefined): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // <character[^>]+>(.+?)<\/character>
    // value = value.replaceAll(/<([^>]+)>/gm, "");

    if (minLength && value.length < minLength) {
      return {
        minLength: true,
      };
    }

    if (maxLength && value.length > maxLength) {
      return {
        maxLength: true,
      };
    }

    return null;
  };
}

function characterValidators(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value) {
      return {
        hasNotExistingCharacter: true,
      };
    }

    return null;
  };
}

function charactersLimitValidators(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value) {
      return {
        isCharactersLimitReached: true,
      };
    }

    return null;
  };
}

@Injectable({
  providedIn: "any",
})
export class ImageGenerationFormService {
  protected formBasic!: FormGroup;
  protected formAdvanced!: FormGroup;

  stylesBasic: ImageGenerationBasicStyle[] = [];
  stylesAdvanced: ImageGenerationAdvancedStyle[] = [];

  private sub = new Subscription();

  basicFormValueChangesEvent$ = new BehaviorSubject<BasicFormChangeData | undefined>(undefined);
  advancedFormValueChangesEvent$ = new BehaviorSubject<AdvancedFormChangeData | undefined>(undefined);
  advancedFormProportionsValueChangesEvent$ = new BehaviorSubject<ImageProportionWithSizeValue | undefined>(undefined);

  constructor(
    private readonly imageGenerationService: ImageGenerationService,
    private readonly imageGenerationDataService: ImageGenerationDataService,
    private readonly notificationService: NotificationsPopUpService,
  ) {}

  destroyForm() {
    this.sub.unsubscribe();
    this.sub = new Subscription();
  }

  initBasicForm(
    imagesCount: number,
    styles: ImageGenerationBasicStyle[],
    lastIG: ImageGeneration | undefined,
    imageSize: ImageSize | undefined,
    promptMaxLenght: number,
    userStyleReferencePrefix: string,
    userStyleReference: ImageGenerationUserStyle | undefined,
  ) {
    this.stylesBasic = styles;

    this.createBasicForm(imagesCount, promptMaxLenght);
    this.setDefaultBasicFormValue(imagesCount, lastIG, imageSize, userStyleReferencePrefix, userStyleReference);
    this.watchBasicFormChanges();

    return this.getBasicForm();
  }

  createBasicForm(imagesCount: number, promptMaxLenght: number) {
    this.formBasic = new FormGroup(
      {
        proportion: new FormControl("", [Validators.required]),
        imagesCount: new FormControl(imagesCount, [Validators.required, Validators.min(1), Validators.max(4)]),
        prompt: new FormControl("", [Validators.required, Validators.maxLength(promptMaxLenght)]),
        styleId: new FormControl("1", [Validators.required]),
        dynamicComposition: new FormControl(false, [Validators.required]),
      },
      // [promptValidator()],
    );
  }

  setDefaultBasicFormValue(
    imagesCount: number,
    lastIG: ImageGeneration | undefined,
    imageSize: ImageSize | undefined,
    userStyleReferencePrefix: string,
    userStyleReference: ImageGenerationUserStyle | undefined,
  ) {
    const proportion: ImageProportionWithSizeValue = {
      width: imageSize?.width || lastIG?.width || 148,
      height: imageSize?.height || lastIG?.height || 210,
      wp: 3,
      hp: 4,
    };

    let styleId = "1";
    if (lastIG?.styleId && lastIG.styleId >= 0) {
      styleId = `${lastIG.styleId}`;
    }
    if (lastIG?.userStyleId && userStyleReference?.id === lastIG?.userStyleId) {
      styleId = `${userStyleReferencePrefix}${lastIG.userStyleId}`;
    }

    const defaultPrompts = this.imageGenerationDataService.getDefaultPrompts();
    const prompt = lastIG?.prompt ?? this.imageGenerationService.getRandomValue(defaultPrompts);

    const newImagesCount = lastIG?.imagesCount ?? imagesCount;
    const dynamicComposition = lastIG?.dynamicComposition ?? false;

    this.formBasic.patchValue({
      proportion: proportion,
      imagesCount: newImagesCount,
      prompt,
      styleId,
      dynamicComposition,
    });
  }

  // https://docs.google.com/spreadsheets/d/1EVc5548u1qVssZ8DZYX6pRo1dA_NJVU2F2K5geZUAcw/edit?pli=1#gid=490801645
  protected watchBasicFormChanges() {
    this.sub.add(
      this.formBasic.valueChanges.subscribe((value) => {
        this.basicFormValueChangesEvent$.next({
          imagesCount: value.imagesCount,
          styleId: value.styleId,
        });
      }),
    );
  }

  initAdvancedForm(
    imagesCount: number,
    styles: ImageGenerationAdvancedStyle[],
    lastIG: ImageGeneration | undefined,
    imageSize: ImageSize | undefined,
    promptMaxLenght: number,
    negativePromptMaxLenght: number,
    userStyleReferencePrefix: string,
    userStyleReference: ImageGenerationUserStyle | undefined,
  ) {
    this.stylesAdvanced = styles;

    this.createAdvancedForm(imagesCount, promptMaxLenght, negativePromptMaxLenght);
    this.watchAdvancedFormProportionsChanges();
    this.setDefaultAdvancedFormValue(imagesCount, lastIG, imageSize, userStyleReferencePrefix, userStyleReference);
    this.watchAdvancedFormChanges();

    return this.getAdvancedForm();
  }

  createAdvancedForm(imagesCount: number, promptMaxLenght: number, negativePromptMaxLenght: number) {
    this.formAdvanced = new FormGroup(
      {
        proportion: new FormControl("", [Validators.required]),
        imagesCount: new FormControl(imagesCount, [Validators.required, Validators.min(1), Validators.max(4)]),
        prompt: new FormControl("", [Validators.required, promptLengthValidator(1, promptMaxLenght)]),
        negativePrompt: new FormControl("", [Validators.maxLength(negativePromptMaxLenght)]),
        detalization: new FormControl("", [Validators.required]),
        mood: new FormControl("", [Validators.required]),
        colorScheme: new FormControl("", [Validators.required]),
        isNegativePromptEnable: new FormControl(false, [Validators.required]),
        isAgeEnable: new FormControl(false, [Validators.required]),
        age: new FormControl("", [Validators.required]),
        ageYear: new FormControl(new Date().getFullYear(), [Validators.required, Validators.pattern(/^[0-9]{1,4}$/)]),
        contrast: new FormControl(1, [Validators.required]),
        styleId: new FormControl("1", [Validators.required]),
        isColorSchemeEnable: new FormControl(false, [Validators.required]),
        advancedGenerationMode: new FormControl("quality", [Validators.required]),
        dynamicComposition: new FormControl(false, [Validators.required]),
        hasNotExistingCharacter: new FormControl(false, [characterValidators()]),
        isCharactersLimitReached: new FormControl(false, [charactersLimitValidators()]),
      },
      // [promptValidator()],
    );
  }

  setDefaultAdvancedFormValue(
    imagesCount: number,
    lastIG: ImageGeneration | undefined,
    imageSize: ImageSize | undefined,
    userStyleReferencePrefix: string,
    userStyleReference: ImageGenerationUserStyle | undefined,
  ) {
    const proportion: ImageProportionWithSizeValue = {
      width: imageSize?.width || lastIG?.width || 148,
      height: imageSize?.height || lastIG?.height || 210,
      wp: 3,
      hp: 4,
    };

    let styleId = "18";
    if (lastIG?.styleId && lastIG.styleId >= 0) {
      styleId = `${lastIG.styleId}`;
    }
    if (lastIG?.userStyleId && userStyleReference?.id === lastIG?.userStyleId) {
      styleId = `${userStyleReferencePrefix}${lastIG.userStyleId}`;
    }

    const localCsColors = localStorage.getItem("m_image_generation_color_scheme_colors") || "";
    const csColors = localCsColors === "" ? [] : localCsColors.split(",");

    const defaultPrompts = this.imageGenerationDataService.getDefaultPrompts();
    const prompt = lastIG?.prompt ?? this.imageGenerationService.getRandomValue(defaultPrompts);

    const newImagesCount = lastIG?.imagesCount ?? imagesCount;
    const negativePrompt = lastIG?.negativePrompt ?? "";
    const detalization = lastIG?.detalization ?? "detailed";
    const mood = this.imageGenerationService.getMoodData(lastIG);
    const isAgeEnable = lastIG?.isAgeEnable ?? true;
    const contrast = lastIG?.contrast ?? 3.5;
    const isNegativePromptEnable = lastIG?.isNegativePromptEnable ?? false;
    const isColorSchemeEnable = lastIG?.isColorSchemeEnable ?? false;

    const ageData = this.imageGenerationService.getAgeData(lastIG);
    const age = ageData.age;
    const ageYear = ageData.ageYear;

    let colorScheme = {
      colorScheme: "fullcolor",
      colors: csColors,
    };
    if (lastIG?.colorScheme) {
      colorScheme = this.imageGenerationService.convertStringToColorScheme(lastIG.colorScheme);
    }

    const advancedGenerationMode = lastIG?.advancedGenerationMode ?? "quality";

    const dynamicComposition = lastIG?.dynamicComposition ?? false;

    this.formAdvanced.patchValue({
      proportion: proportion,
      imagesCount: newImagesCount,
      prompt,
      negativePrompt,
      detalization,
      mood,
      colorScheme,
      isAgeEnable,
      age,
      ageYear,
      contrast,
      isNegativePromptEnable,
      isColorSchemeEnable,
      advancedGenerationMode,
      styleId,
      dynamicComposition,
      hasNotExistingCharacter: false,
      isCharactersLimitReached: false,
    });
  }

  protected watchAdvancedFormChanges() {
    this.sub.add(
      this.formAdvanced.valueChanges.subscribe((value) => {
        this.advancedFormValueChangesEvent$.next({
          imagesCount: value.imagesCount,
          age: value.age,
          isAgeEnable: value.isAgeEnable,
          isColorSchemeEnable: value.isColorSchemeEnable,
          isNegativePromptEnable: value.isNegativePromptEnable,
          styleId: value.styleId,
          advancedGenerationMode: value.advancedGenerationMode,
          mood: value.mood,
        });
      }),
    );

    this.sub.add(
      this.formAdvanced.get("colorScheme")?.valueChanges.subscribe((value) => {
        if (value.colorScheme === "custom") {
          localStorage.setItem("m_image_generation_color_scheme_colors", value.colors);
        }
      }),
    );
  }

  protected watchAdvancedFormProportionsChanges() {
    this.sub.add(
      this.formAdvanced.get("proportion")?.valueChanges.subscribe((value) => {
        this.advancedFormProportionsValueChangesEvent$.next(value);
      }),
    );
  }

  setPublishedImageSettings(
    generationSettings: ImageGeneration | undefined,
    lastIG: ImageGeneration | undefined,
    userStyleReferencePrefix: string,
    userStyleReference: ImageGenerationUserStyle | undefined,
  ) {
    if (!generationSettings) {
      return;
    }
    const proportion: ImageProportionWithSizeValue = {
      width: generationSettings.width || 148,
      height: generationSettings.height || 210,
      wp: 3,
      hp: 4,
    };

    let styleId = "1";

    if (generationSettings.styleId >= 0) {
      styleId = `${generationSettings.styleId}`;
    }
    if (generationSettings?.userStyleId) {
      if (userStyleReference?.id === generationSettings?.userStyleId) {
        styleId = `${userStyleReferencePrefix}${generationSettings.userStyleId}`;
      } else {
        this.notificationService.notify({
          type: "info",
          content: $localize`:@@published-image.settings.used-style-reference:`,
          showOptsTimeout: 3,
        });
      }
    }

    if (this.imageGenerationService.isAdvancedGeneration(generationSettings)) {
      const ageData = this.imageGenerationService.getAgeData(generationSettings);
      const age = ageData.age;
      const ageYear = ageData.ageYear;

      const isNegativePromptEnable = generationSettings.isNegativePromptEnable;
      let negativePrompt = generationSettings.negativePrompt;
      if (!isNegativePromptEnable) {
        negativePrompt = "";
      }

      const isColorSchemeEnable = generationSettings.isColorSchemeEnable;
      let colorScheme = this.imageGenerationService.convertStringToColorScheme(generationSettings.colorScheme);
      if (!isColorSchemeEnable) {
        const localCsColors = localStorage.getItem("m_image_generation_color_scheme_colors") || "";
        const csColors = localCsColors === "" ? [] : localCsColors.split(",");
        colorScheme = {
          colorScheme: "fullcolor",
          colors: csColors,
        } as GenerationImageColorSettings;
        if (lastIG?.colorScheme) {
          colorScheme = this.imageGenerationService.convertStringToColorScheme(lastIG.colorScheme);
        }
      }

      this.formAdvanced.patchValue({
        proportion: proportion,
        imagesCount: generationSettings.imagesCount,
        prompt: generationSettings.prompt,
        negativePrompt: negativePrompt,
        detalization: generationSettings.detalization,
        mood: this.imageGenerationService.getMoodData(generationSettings),
        styleId: styleId,
        colorScheme: colorScheme,
        isAgeEnable: generationSettings.isAgeEnable,
        age,
        ageYear,
        contrast: generationSettings?.contrast ?? 3.5,
        isNegativePromptEnable: isNegativePromptEnable,
        isColorSchemeEnable: isColorSchemeEnable,
        advancedGenerationMode: generationSettings.advancedGenerationMode ?? "quality",
        dynamicComposition: generationSettings.dynamicComposition ?? false,
      });

      return;
    }

    this.formBasic.patchValue({
      proportion: proportion,
      imagesCount: generationSettings.imagesCount,
      prompt: generationSettings.prompt,
      styleId: styleId,
      dynamicComposition: generationSettings.dynamicComposition ?? false,
    });
  }

  getBasicForm() {
    return this.formBasic;
  }

  getAdvancedForm() {
    return this.formAdvanced;
  }

  getBasicStyles() {
    return this.stylesBasic;
  }

  getAdvancedStyles() {
    return this.stylesAdvanced;
  }
}
