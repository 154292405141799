import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Color, CoverObject, Fill, ShapeObject, SolidFill, TextObject } from "@metranpage/book-data";
import { instanceToInstance } from "class-transformer";

type ColorSet = {
  colors: Color[];
  tones: Color[];
};

@Component({
  selector: "m-cover-multicolor-settings",
  templateUrl: "./cover-multicolor-settings.component.html",
  styleUrls: ["./cover-multicolor-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverMulticolorSettingsComponent implements OnChanges {
  @Input() objects!: CoverObject[];

  @Output() update = new EventEmitter<CoverObject[]>();

  colors: Color[] = [];
  initialColors: Color[] = [];

  tones: Color[] = [];
  initialTones: Color[] = [];

  generalTone = new Color(0, 0, 0, 1);

  constructor(private readonly _changeDetector: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const colorSet = this.getColorSet();
    this.initColors(colorSet, true, true, true);
  }

  initColors(colorSet: ColorSet, colors: boolean, tones: boolean, generalTone: boolean) {
    if (colors) {
      this.colors = colorSet.colors;
      this.initialColors = instanceToInstance(colorSet.colors);
    }

    if (tones) {
      this.tones = colorSet.tones;
      this.initialTones = instanceToInstance(colorSet.tones);
    }

    if (generalTone) {
      this.generalTone = instanceToInstance(colorSet.tones[0]);
      //Object.assign(this.generalTone, colorSet.tones[0]);
    }
  }

  getColorSet(): ColorSet {
    const colors: Color[] = [];
    const tones: Color[] = [];
    let fill: Fill | undefined;
    let strokeFill: Fill | undefined;
    for (const object of this.objects) {
      if (object instanceof ShapeObject) {
        fill = object.fill;
        strokeFill = object.strokeFill;
      } else if (object instanceof TextObject) {
        fill = object.fill;
        strokeFill = object.strokeFill;
      } else {
        continue;
      }
      if (fill instanceof SolidFill) {
        const color = instanceToInstance(fill.color);
        const tone = instanceToInstance(fill.color);
        tone.a = 1;
        if (!colors.some((v) => v.equals(color))) {
          colors.push(color);
        }
        if (!tones.some((v) => v.isEqualTone(tone))) {
          tones.push(tone);
        }
      }
      if (strokeFill instanceof SolidFill && (object.strokeWidth ?? 0) > 0) {
        const color = instanceToInstance(strokeFill.color);
        const tone = instanceToInstance(strokeFill.color);
        tone.a = 1;
        if (!colors.some((v) => v.equals(color))) {
          colors.push(color);
        }
        if (!tones.some((v) => v.isEqualTone(tone))) {
          tones.push(tone);
        }
      }
    }

    return { colors, tones };
  }

  onColorUpdate(index: number, color: Color) {
    let fill: Fill | undefined;
    let strokeFill: Fill | undefined;
    const initialColor = this.initialColors[index];
    for (const object of this.objects) {
      if (object instanceof ShapeObject) {
        fill = object.fill;
        strokeFill = object.strokeFill;
      } else if (object instanceof TextObject) {
        fill = object.fill;
        strokeFill = object.strokeFill;
      } else {
        continue;
      }
      if (fill instanceof SolidFill) {
        if (fill.color.equals(initialColor)) {
          fill.color = instanceToInstance(color);
        }
      }
      if (strokeFill instanceof SolidFill && (object.strokeWidth ?? 0) > 0) {
        if (strokeFill.color.equals(initialColor)) {
          strokeFill.color = instanceToInstance(color);
        }
      }
    }
    this.initialColors[index] = color;
    this.update.emit(this.objects);

    const colorSet = this.getColorSet();
    this.initColors(colorSet, colorSet.colors.length !== this.colors.length, true, true);
  }

  onToneUpdate(index: number, tone: Color) {
    let fill: Fill | undefined;
    let strokeFill: Fill | undefined;
    const initialTone = this.initialTones[index];
    for (const object of this.objects) {
      if (object instanceof ShapeObject) {
        fill = object.fill;
        strokeFill = object.strokeFill;
      } else if (object instanceof TextObject) {
        fill = object.fill;
        strokeFill = object.strokeFill;
      } else {
        continue;
      }
      if (fill instanceof SolidFill) {
        if (fill.color.isEqualTone(initialTone)) {
          const a = fill.color.a;
          fill.color = instanceToInstance(tone);
          fill.color.a = a;
        }
      }
      if (strokeFill instanceof SolidFill && (object.strokeWidth ?? 0) > 0) {
        if (strokeFill.color.isEqualTone(initialTone)) {
          const a = strokeFill.color.a;
          strokeFill.color = instanceToInstance(tone);
          strokeFill.color.a = a;
        }
      }
    }
    this.initialTones[index] = tone;
    this.update.emit(this.objects);

    const colorSet = this.getColorSet();
    this.initColors(colorSet, true, colorSet.tones.length !== this.tones.length, true);
  }

  onGeneralToneUpdate(tone: Color) {
    let fill: Fill | undefined;
    let strokeFill: Fill | undefined;
    for (const object of this.objects) {
      if (object instanceof ShapeObject) {
        fill = object.fill;
        strokeFill = object.strokeFill;
      } else if (object instanceof TextObject) {
        fill = object.fill;
        strokeFill = object.strokeFill;
      } else {
        continue;
      }
      if (fill instanceof SolidFill) {
        const a = fill.color.a;
        fill.color = instanceToInstance(tone);
        fill.color.a = a;
      }
      if (strokeFill instanceof SolidFill && (object.strokeWidth ?? 0) > 0) {
        const a = strokeFill.color.a;
        strokeFill.color = instanceToInstance(tone);
        strokeFill.color.a = a;
      }
    }

    this.update.emit(this.objects);

    const colorSet = this.getColorSet();
    this.initColors(colorSet, true, true, false);
  }
}
