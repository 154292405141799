<div class="nav-tabs" [ngClass]="getCssClassList()">
  <div
    *ngFor="let tab of tabs"
    class="nav-tab"
    [class.active]="tab.isActive || tab.id === activeTab"
    (click)="onClick(tab)"
  >
    <span>{{ tab.text }}</span>
  </div>
</div>
