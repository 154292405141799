import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { IconComponent } from '../icon/icon.component';

type DotState = {
  scale: number;
  selected: boolean;
  index: number;
};

const DOTS_PER_GROUP = 7;

@Component({
  selector: 'm-preview-dots',
  templateUrl: './preview-dots.component.html',
  styleUrls: ['./preview-dots.component.scss'],
  standalone: true,
  imports: [IconComponent, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewDotsComponent implements AfterViewInit, OnChanges {
  @Input() dotsCount = 0;
  @Input() selectedIndex = 0;
  @Output() onSelect = new EventEmitter<number>();

  dots: DotState[] = [];
  hasLeftArrow = false;
  hasRightArrow = false;
  dotsScrollX = 0;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.recalcDots();
  }

  ngOnChanges(): void {
    this.recalcDots();
  }

  private recalcDots() {
    this.dots = [];

    const halfSize = Math.floor(DOTS_PER_GROUP / 2);
    for (let i = 0; i < this.dotsCount; i++) {
      let scale = 0.5;
      if (i > this.selectedIndex - halfSize && i < this.selectedIndex + halfSize) {
        scale = 1;
      } else if (i === this.selectedIndex - halfSize || i === this.selectedIndex + halfSize) {
        scale = 0.5;
      } else {
        scale = 0;
      }

      this.dots.push({
        index: i,
        selected: this.selectedIndex === i,
        scale,
      });
    }

    if (this.dotsCount < DOTS_PER_GROUP) {
      this.dotsScrollX = 0;
    } else {
      const dotSize = 10;
      const marginBetweenDots = 16;
      const centerX = this.selectedIndex * (dotSize + marginBetweenDots);
      const offsetToLeft = halfSize * (dotSize + marginBetweenDots);
      this.dotsScrollX = -centerX + offsetToLeft;
    }

    if (this.selectedIndex > halfSize) {
      this.hasLeftArrow = true;
    } else {
      this.hasLeftArrow = false;
    }

    if (this.selectedIndex < this.dotsCount - halfSize - 1) {
      this.hasRightArrow = true;
    } else {
      this.hasRightArrow = false;
    }
    this.cdr.detectChanges();
  }

  navigate(offset: number) {
    this.onSelect.emit(this.selectedIndex + offset);
  }

  trackBy(_index: number, dot: DotState) {
    return dot.index;
  }
}
