import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { Format, FormatDto } from "../../models/format";

export type State = {
  formats: Format[];
  activeFormat: FormatDto | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminFormatStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      formats: [],
      activeFormat: undefined,
    };
  }

  getFormatsObservable() {
    return this.getFieldObservable("formats");
  }

  setFormats(formats: Format[]) {
    this.update((state) => ({ ...state, formats: formats }));
  }

  setActiveFormat(format: FormatDto) {
    this.update((state) => ({ ...state, activeFormat: format }));
  }

  getActiveFormatObservable() {
    return this.getFieldObservable("activeFormat");
  }
}
