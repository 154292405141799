import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { StyleSettings } from "../../../../../models/styles";
import { MarkupPreviewService } from "../../../../../services/preview.service";
import { TextEditor } from "../../../../text-editor/text-editor/text-editor.view";
import { BlockMergeEvent, BlockMovementEvent, BlockSplitEvent } from "../../../editor.events";
import { Footnote } from "../../../editor.models";
import { TextSelectionState } from "../../../editor.service";
import { BlockDelegate } from "../../block-delegate";
import { ListItem, ListStyle } from "../interfaces/list-data.interface";

@Component({
  selector: "m-markup-editor-list-item-view",
  templateUrl: "./list-item-view.html",
  styleUrls: ["./list-item-view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemView implements BlockDelegate, OnInit, OnChanges {
  text = "";
  marker = "";

  @Input()
  item!: ListItem;
  @Input()
  styleDisplayOpts: StyleSettings | null = null;
  @Input()
  startingNoteIndex = 1;

  @Output() onBlur = new EventEmitter<ListItem>();
  @Output() onFocus: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  footnoteAdded: EventEmitter<Footnote[]> = new EventEmitter();
  @Output()
  footnoteChanged: EventEmitter<Footnote[]> = new EventEmitter();
  @Output()
  onSplit: EventEmitter<BlockSplitEvent> = new EventEmitter();
  @Output()
  onMergeWithPrev: EventEmitter<BlockMergeEvent> = new EventEmitter();
  @Output()
  blockMovement = new EventEmitter<BlockMovementEvent>();

  @ViewChild("textEditor")
  textEditor: TextEditor | undefined;
  @ViewChild("content", { read: ElementRef })
  editorContainer!: ElementRef;

  constructor(
    private previewService: MarkupPreviewService,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.text = this.item.content;
    this.updateMarker();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.applyStyleOpts();
    this.updateMarker();
  }

  private isOrderedList(style: ListStyle): boolean {
    return style === "ordered" || style === "ordered-bkt";
  }

  updateMarker() {
    if (this.item.style) {
      if (this.isOrderedList(this.item.style)) {
        this.marker = this.constructNumberedMarker();
      } else if (this.item.style === "unordered") {
        const shapes = ["\u25CF", "\u25A0", "\u25CB"];
        this.marker = shapes[this.item.level % shapes.length];
      }
    }

    this.cdr.detectChanges();
  }

  private constructNumberedMarker(): string {
    let result = "";
    if (this.item.parentIndex) {
      result = `${this.item.parentIndex}.`;
    }

    result = `${result}${this.item.index}.`;
    if (this.item.style === "ordered-bkt") {
      result = result.replace(/[.]$/gi, ")");
    }
    return result;
  }

  getFormattedMarker(): string {
    if (this.isOrderedList(this.item.style)) {
      return this.constructNumberedMarker();
      // biome-ignore lint/style/noUselessElse:
    } else if (this.item.style === "unordered") {
      // const shapes = ["\u25CF", "\u25A0", "\u25CB"];
      const shapes = ["\u25CF"];
      return shapes[this.item.level % shapes.length];
    }
    return "";
  }

  calculateMargin(level: number): string {
    const baseMargin = 10;
    const maxAllowedPercentage = 110;
    const maxAllowedMargin = (maxAllowedPercentage / 100) * this.calculateContainerWidth();
    const calculatedMargin = level * baseMargin;
    return `${Math.min(calculatedMargin, maxAllowedMargin)}px`;
  }

  calculateContainerWidth(): number {
    return 0.5 * window.innerWidth;
  }

  onFootnoteAdded(footnotes: Footnote[]) {
    this.cdr.markForCheck();
    this.footnoteAdded.emit(footnotes);
  }

  onFootnoteChanged(footnotes: Footnote[]) {
    this.cdr.markForCheck();
    this.footnoteChanged.emit(footnotes);
  }

  onEditorFocus() {
    this.onFocus.emit();
  }

  onEditorBlur() {
    const newData: ListItem = {
      ...this.item,
      content: this.text,
    };
    this.cdr.markForCheck();
    this.onBlur.emit(newData);
  }

  onBlockMovement(event: BlockMovementEvent) {
    this.blockMovement.emit(event);
  }

  getTextSelection(): TextSelectionState | undefined {
    return this.textEditor?.getTextSelectionState();
  }

  applyBold(): void {
    this.textEditor?.applyBold();
  }

  applyItalick(): void {
    this.textEditor?.applyItalic();
  }

  addFootnote(): void {
    this.textEditor?.addFootnote();
  }

  updateFootnotesText(footnotes: Footnote[]) {
    this.textEditor?.updateFootnotesText(footnotes);
  }

  removeFootnote(id: number): void {
    this.textEditor?.removeFootnote(id);
  }

  setCaretToBegin(): void {
    this.textEditor?.setCaretToBegin();
  }

  setCaretToEnd(): void {
    this.textEditor?.setCaretToEnd();
  }

  appendTextAndFocus(text: string): void {
    this.textEditor?.appendTextAndFocus(text);
  }

  private applyStyleOpts() {
    if (this.styleDisplayOpts) {
      this.previewService.applyPreviewStyle(
        this.elementRef.nativeElement.querySelector("m-text-editor") as Element,
        this.styleDisplayOpts,
        false,
      );
      this.previewService.applyPreviewStyle(
        this.elementRef.nativeElement.querySelector(".marker") as Element,
        this.styleDisplayOpts,
        false,
      );
    }
    this.cdr.markForCheck();
  }
}
