import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type Opts = {
  fullPage?: boolean;
  description?: string;
};

export type LoadingState = {
  isActive: boolean;
  description?: string;
};

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingState = new BehaviorSubject<LoadingState>({ isActive: false });
  loadingState$ = this.loadingState.asObservable();

  private fullPageLoadingState = new BehaviorSubject<LoadingState>({ isActive: false });
  fullPageLoadingState$ = this.fullPageLoadingState.asObservable();

  startLoading(opts: Opts | undefined = { fullPage: false, description: undefined }) {
    if (opts?.fullPage) {
      this.fullPageLoadingState.next({ isActive: true, description: opts.description });
    } else {
      this.loadingState.next({ isActive: true, description: opts?.description });
    }
  }

  stopLoading() {
    // console.trace();
    this.loadingState.next({ isActive: false, description: undefined });
    this.fullPageLoadingState.next({ isActive: false, description: undefined });
  }
}
