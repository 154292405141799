import { Injectable } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ApiErrorHandlerService } from "@metranpage/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { CompanyFont, FontCreateDataWithFile, FontUpdateDataWithFile } from "../../models/font";
import { AdminFontsApi, FontsFilter } from "./fonts.api";
import { AdminFontsStore } from "./fonts.store";

function companyFontValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value.isAvailableForHeaders && !value.isAvailableForMainText) {
      return {
        expectedType: true,
      };
    }
    return null;
  };
}

@Injectable({
  providedIn: "root",
})
export class AdminFontsService {
  constructor(
    private readonly adminFontsApi: AdminFontsApi,
    private readonly adminFontsStore: AdminFontsStore,
    private readonly apiErrorHandler: ApiErrorHandlerService,
  ) {}

  async getFonts(): Promise<CompanyFont[]> {
    return await this.adminFontsApi.getFonts();
  }

  async loadFont(fontId: number) {
    const fontData = await this.adminFontsApi.getFont(fontId);
    this.adminFontsStore.setActiveFont(fontData);
  }

  async loadFontsPaginated(page: number, filters: FontsFilter = {}) {
    const fontsData = await this.adminFontsApi.getFontsPaginated(page, filters);
    this.adminFontsStore.setFonts(fontsData.items);
    this.adminFontsStore.setFontsPageCount(fontsData.pageCount);
  }

  async createFont(data: FontCreateDataWithFile): Promise<{ status: GeneralResultStatus; id?: number }> {
    try {
      const newFont = await this.adminFontsApi.createFont(data);
      return {
        status: "success",
      };
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return {
        status: "error",
      };
    }
  }

  async updateFont(fontId: number, data: FontUpdateDataWithFile) {
    try {
      await this.adminFontsApi.updateFont(fontId, data);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "error";
    }
  }

  async deleteFont(fontId: number): Promise<{ status: GeneralResultStatus }> {
    try {
      const result = await this.adminFontsApi.deleteFont(fontId);
      return {
        status: "success",
      };
    } catch (error: any) {
      return {
        status: "error",
      };
    }
  }

  createFontForm() {
    return new FormGroup(
      {
        licenseRu: new FormControl("", { nonNullable: true, validators: [Validators.maxLength(150)] }),
        licenseEn: new FormControl("", { nonNullable: true, validators: [Validators.maxLength(150)] }),
        isAvailableForHeaders: new FormControl(false, { nonNullable: true, validators: [] }),
        isAvailableForMainText: new FormControl(false, { nonNullable: true, validators: [] }),
        isSelectable: new FormControl(false, { nonNullable: true, validators: [] }),
      },
      // [companyFontValidator()],
    );
  }
}
