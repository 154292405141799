import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { RewardOneTime } from "../../../models/reward-onetime";

export type State = {
  rewardsOneTime: RewardOneTime[];
  rewardsOneTimePageCount: number;
  activeRewardOneTime: RewardOneTime | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminRewardsOneTimeStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      rewardsOneTime: [],
      rewardsOneTimePageCount: 1,
      activeRewardOneTime: undefined,
    };
  }

  getRewardsOneTime() {
    return this.getField("rewardsOneTime");
  }

  getRewardsOneTimeObservable() {
    return this.getFieldObservable("rewardsOneTime");
  }

  setRewardsOneTime(rewardsOneTime: RewardOneTime[]) {
    this.update((state) => ({ ...state, rewardsOneTime }));
  }

  getRewardsOneTimePageCountObservable() {
    return this.getFieldObservable("rewardsOneTimePageCount");
  }

  setRewardsOneTimePageCount(count: number) {
    this.update((state) => ({ ...state, rewardsOneTimePageCount: count }));
  }

  setActiveRewardOneTime(rewardOneTime: RewardOneTime) {
    this.update((state) => ({ ...state, activeRewardOneTime: rewardOneTime }));
  }

  getActiveRewardOneTimeObservable() {
    return this.getFieldObservable("activeRewardOneTime");
  }
}
