import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { CompanyStore } from "@metranpage/company";
import { AnalyticsService, filterUndefined } from "@metranpage/core";
import { ActiveSubscription, PaymentCurrency, Tariff } from "@metranpage/pricing-data";
import { User, UserStore } from "@metranpage/user-data";
import { BalanceData } from "@metranpage/user-payment-data";
import { Observable, Subscription, combineLatest, map } from "rxjs";
import { PricingService } from "../../services/pricing.service";
import { PricingViewService } from "../pricing.view-service";

@Component({
  selector: "m-premium-tariffs-modal",
  templateUrl: "./premium-tariffs-modal.component.html",
  styleUrls: ["./premium-tariffs-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("fade-scale", [
      transition("void => *", [
        style({ opacity: "0", transform: "scale(0)" }),
        animate("300ms ease-out", style({ opacity: "1", transform: "scale(1)" })),
      ]),
      transition("* => void", [
        style({ opacity: "1", transform: "scale(1)" }),
        animate("300ms ease-out", style({ opacity: "0", transform: "scale(0)" })),
      ]),
    ]),
  ],
})
export class PremiumTariffsModalComponent implements OnInit {
  @Output()
  close = new EventEmitter();

  @Output()
  buySubscription = new EventEmitter<Tariff>();

  private sub = new Subscription();

  protected user?: User;

  protected currency: PaymentCurrency = "RUB";
  protected balanceData?: BalanceData;
  protected activeSubscription?: ActiveSubscription;
  protected higherTariff?: Tariff;
  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;

  tariffsForUpgrade$!: Observable<Tariff[]>;

  constructor(
    private readonly pricingViewService: PricingViewService,
    private readonly analytics: AnalyticsService,
    readonly userStore: UserStore,
    readonly companyStore: CompanyStore,
    private readonly pricingService: PricingService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );

    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.activeSubscription = activeSubscription;
        this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
        this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;

        if (!this.activeSubscription) {
          return;
        }
        this.getHigherTariff();
      }),
    );

    this.sub.add(
      companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.currency = company.currency;
        }),
    );

    this.tariffsForUpgrade$ = combineLatest([
      userStore.getActiveSubscriptionObservable(),
      pricingService.getTariffsForCompany(),
    ]).pipe(
      map(([subscription, tariffs]) => ({ subscription, tariffs: tariffs.filter((v) => v.isFree === false) })),
      map((info) => {
        if (!info.subscription || info.subscription.tariff.isFree) {
          return info.tariffs.filter((t) => t.period === 1);
        }
        return info.tariffs.filter((t) => t.period === info.subscription?.tariff.period);
      }),
    );
  }

  ngOnInit() {
    this.analytics.event("popup-pricing-premium");
  }

  protected onCloseClick() {
    this.analytics.event("popup-pricing-premium-close");
    this.close.emit();
  }

  protected async getHigherTariff() {
    this.higherTariff = await this.pricingService.getHigherTariff();
  }

  protected onBuySubscription(tariff: Tariff) {
    this.onCloseClick();

    this.analytics.event("popup-pricing-premium-buy-subscription");

    window.open(`payments/await-payment-link?tariffId=${tariff.id}`, "_blank");
  }
}
