<div class="book-view-pages" *ngIf="urls" (window:resize)="onResize($event)">
  <div #pages class="page page-left">
    <img *ngIf="prevUrl" #left (click)="trySelectLeftPage()" [attr.src]="prevUrl" (load)="onLoaded('left')" alt="" />
    <div #shadowLeft [class.visible]="leftIsLoaded" class="shadow-left"></div>
  </div>

  <div class="page page-right">
    <img *ngIf="nextUrl" #right (click)="trySelectRightPage()" [attr.src]="nextUrl" (load)="onLoaded('right')" alt="" />
    <div #shadowRight [class.visible]="rightIsLoaded" class="shadow-right"></div>
  </div>
</div>

<!-- <div class="book-view-nav">
  <div class="book-view-dots">
    <button
      *ngFor="let dot of leftPageIndexes"
      class="book-view-dot"
      [class.selected]="dot === leftPageIndex"
      (click)="selectLeftPage(dot)"
    ></button>
  </div>
</div> -->

<m-preview-dots
  [dotsCount]="leftPageIndexes.length"
  [selectedIndex]="getSelectedIndex()"
  (onSelect)="selectPage($event)"
></m-preview-dots>
