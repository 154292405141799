import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";

export type ImagePosition = "fixed" | "flex";
export type ImagePositionOption = {
  value: ImagePosition;
  disabled: boolean;
};

const availableImagePosition: ImagePositionOption[] = [
  { value: "fixed", disabled: false },
  { value: "flex", disabled: false },
];

@Component({
  selector: "m-books-markup-sidebar-images-position",
  templateUrl: "./markup-sidebar-images-position-settings.view.html",
  styleUrls: ["./markup-sidebar-images-position-settings.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkupSidebarImagesPositionSettings),
      multi: true,
    },
  ],
})
export class MarkupSidebarImagesPositionSettings implements ControlValueAccessor {
  @Input()
  form!: FormGroup;

  protected selectedImagePosition: ImagePosition = "fixed";
  protected isDisabled = false;
  protected value = false;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  constructor(private readonly cdr: ChangeDetectorRef) {}

  writeValue(value: boolean): void {
    this.value = value;
    this.selectedImagePosition = value ? "fixed" : "flex";

    this.cdr.detectChanges();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  protected isImagePositionChecked(position: ImagePosition) {
    return this.selectedImagePosition === position;
  }

  protected onSelectImagePosition(position: string | number) {
    const imagePosition = position as ImagePosition;
    this.selectedImagePosition = imagePosition;

    this.value = imagePosition === "fixed" ? true : false;
    this.onChange(this.value);
  }

  protected getAvailableImagePosition() {
    return availableImagePosition;
  }

  protected getImagePositionText(position: ImagePosition) {
    switch (position) {
      case "fixed":
        return $localize`:@@books.markup.sidebar.images-fixed:`;
      case "flex":
        return $localize`:@@books.markup.sidebar.images-flex:`;
      default:
        return $localize`:@@books.markup.sidebar.images-fixed:`;
    }
  }

  protected getPreview() {
    return [
      `/assets/img/image-position-${this.selectedImagePosition}-1-{theme}.svg`,
      `/assets/img/image-position-${this.selectedImagePosition}-2-{theme}.svg`,
    ];
  }

  protected getDescription() {
    if (this.selectedImagePosition === "fixed") {
      return [$localize`:@@books.markup.sidebar.images-description-fixed:`];
    }
    return [
      $localize`:@@books.markup.sidebar.images-description-flex:`,
      $localize`:@@books.markup.sidebar.images-description-flex-2:`,
    ];
  }
}
