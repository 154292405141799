<div
  *ngIf="title || actionTitle || actionIcon"
  class="header"
  [class.can-collapsing]="canCollapsing"
  (click)="toggle()"
>
  <div class="title-group">
    <span class="title">{{ title }}</span>

    <div *ngIf="actionTitle || actionIcon" class="action" (click)="onActionClick.emit()">
      <span *ngIf="actionTitle" class="action-title">{{ actionTitle }}</span>
      <m-ng-icon *ngIf="actionIcon" [src]="actionIcon" [size]="14" />
    </div>

    <span *ngIf="hasTooltip" class="tooltip-host">
      <m-ng-icon src="info.svg" [size]="16" />

      <div class="tooltip">
        <ng-content select="[tooltip]"></ng-content>
      </div>
    </span>

    <span *ngIf="canCollapsing" class="icon" [class.collapsed]="isCollapsed"></span>
    <m-ng-icon *ngIf="canCollapsing" src="dropdown-arrow-down.svg" [size]="16" [class.collapsed]="isCollapsed" />
  </div>
</div>
<div class="content" *ngIf="isCollapsed">
  <ng-content></ng-content>
</div>
