import { NgClass, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { I18nService } from "@metranpage/i18n";
import { ThemeService } from "@metranpage/theme";
import { AngularSvgIconModule } from "angular-svg-icon";
import { Subject, takeUntil } from "rxjs";
import { IconComponent } from "../icon/icon.component";

export type LogoStyle = "full" | "image-only";
// export type LogoSize = 'm' | 'xl';

@Component({
  selector: "m-logo",
  templateUrl: "./logo.component.html",
  styleUrls: ["./logo.component.scss"],
  standalone: true,
  imports: [NgIf, NgClass, AngularSvgIconModule, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnInit, OnDestroy {
  @Input("logo-style")
  logoStyle: LogoStyle = "image-only";

  protected imageAsset!: string;
  private destroyed$ = new Subject<void>();

  constructor(
    private readonly localizationService: I18nService,
    private readonly themeService: ThemeService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    themeService.changeEvents$.pipe(takeUntil(this.destroyed$)).subscribe((theme) => {
      this.recalcAssets();
    });
  }

  ngOnInit(): void {
    this.recalcAssets();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private recalcAssets() {
    let theme = "lt";
    if (this.themeService.getTheme() === "dark") {
      theme = "dt";
    }

    let fileName = "logo-image";
    if (this.logoStyle === "full") {
      fileName += "-with-text";
    }

    this.imageAsset = `/assets/img/${fileName}-${theme}-${this.localizationService.getLocale()}.svg`;

    this.changeDetectorRef.markForCheck();
  }
}
