import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { Character } from "../models/character/character";

export type CharactersState = {
  characters: Character[];
  charactersPageCount: number;
  activeCharacter: Character | undefined;
};

@Injectable({
  providedIn: "root",
})
export class CharacterStore extends Store<CharactersState> {
  protected override getInitialState(): CharactersState {
    return {
      characters: [],
      charactersPageCount: 1,
      activeCharacter: undefined,
    };
  }

  getCharactersObservable() {
    return this.getFieldObservable("characters");
  }

  getCharacters() {
    return this.getField("characters");
  }

  setCharacters(characters: Character[]) {
    this.update((state) => ({ ...state, characters: characters }));
  }

  getCharactersPageCountObservable() {
    return this.getFieldObservable("charactersPageCount");
  }

  setCharactersPageCount(count: number) {
    this.update((state) => ({ ...state, charactersPageCount: count }));
  }

  addCharacter(character: Character) {
    this.update((state) => {
      const storeCharacter = state.characters.find((c) => c.id === character.id);
      if (storeCharacter) {
        return state;
      }

      return {
        ...state,
        characters: [character].concat(state.characters),
      };
    });
  }

  addCharactersToStart(characters: Character[]) {
    this.update((state) => {
      const newCharacters: Character[] = [];
      for (const character of characters) {
        const storeCharacter = state.characters.find((c) => c.id === character.id);
        if (storeCharacter) {
          continue;
        }
        newCharacters.push(character);
      }

      return {
        ...state,
        characters: newCharacters.concat(state.characters),
      };
    });
  }

  addCharactersToEnd(characters: Character[]) {
    this.update((state) => {
      const newCharacters: Character[] = [];
      for (const character of characters) {
        const storeImageGeneration = state.characters.find((c) => c.id === character.id);
        if (storeImageGeneration) {
          continue;
        }
        newCharacters.push(character);
      }

      return {
        ...state,
        characters: state.characters.concat(newCharacters),
      };
    });
  }

  updateCharacter(updatedCharacter: Character) {
    const characters = this.getCharacters();
    const character = characters.find((c) => c.id === updatedCharacter.id);

    if (character) {
      this.update((state) => {
        const updatedCharacters = state.characters.map((c) => {
          if (c.id === updatedCharacter.id) {
            return updatedCharacter;
          }
          return c;
        });

        return {
          ...state,
          characters: updatedCharacters,
        };
      });
    } else {
      this.addCharacter(updatedCharacter);
    }

    const activeCharacter = this.getField("activeCharacter");
    if (updatedCharacter.id === activeCharacter?.id) {
      this.update((state) => {
        return {
          ...state,
          activeCharacter: updatedCharacter,
        };
      });
    }
  }

  getActiveCharacterObservable() {
    return this.getFieldObservable("activeCharacter");
  }

  getActiveCharacter() {
    return this.getField("activeCharacter");
  }

  setActiveCharacter(character: Character | undefined) {
    this.update((state) => {
      return {
        ...state,
        activeCharacter: character,
      };
    });
  }
}
