import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ActiveSubscription, PaymentCurrency, PromocodeDiscountData, Tariff } from "@metranpage/pricing-data";
import { TariffCardAppearance } from "../tariff-card/tariff-card.view";

@Component({
  selector: "m-tariff-grid",
  templateUrl: "./tariff-grid.component.html",
  styleUrls: ["./tariff-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TariffGridComponent {
  @Input()
  tariffs: Tariff[] = [];

  @Input()
  currency: PaymentCurrency = "RUB";

  @Input()
  activeSubscription?: ActiveSubscription;

  @Input()
  cardAppearance: TariffCardAppearance = "full";

  @Input()
  promocodeDiscountData?: PromocodeDiscountData;

  @Output()
  buySubscription = new EventEmitter<Tariff>();
}
