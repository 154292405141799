import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { CompanyMaintenanceSettings } from "../../models/company";
import { AdminCompanyMaintenanceSettingsApi } from "./companies-maintenance-settings.api";
import { AdminCompanyMaintenanceSettingsStore } from "./companies-maintenance-settings.store";

export type UpdateCompanySettingsResult = {
  status: GeneralResultStatus;
  error?: unknown;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyMaintenanceSettingsService {
  constructor(
    private readonly api: AdminCompanyMaintenanceSettingsApi,
    private readonly store: AdminCompanyMaintenanceSettingsStore,
  ) {}

  async getMaintenanceSettings() {
    return await this.api.getMaintenanceSettings();
  }

  async loadMaintenanceSettings() {
    const settings = await this.api.getMaintenanceSettings();
    this.store.setSettings(settings);
  }

  async updateMaintenanceSettings(data: CompanyMaintenanceSettings): Promise<UpdateCompanySettingsResult> {
    try {
      const settings = await this.api.updateMaintenanceSettings(data);
      this.store.setSettings(settings);
      return {
        status: "success",
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }
}
