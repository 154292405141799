import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2, afterNextRender } from "@angular/core";
import { Router, RouterStateSnapshot, RoutesRecognized } from "@angular/router";
import { ConfigService } from "@metranpage/core-interfaces";
import { UserStore } from "@metranpage/user-data";

@Injectable({
  providedIn: "root",
})
export class RePlainService {
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    // private renderer: Renderer2,
    private readonly router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject("ConfigService")
    private readonly configService: ConfigService,
    private readonly userStore: UserStore,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  replainStart() {
    if (!this.configService.getConfig().company.replainId) {
      return;
    }

    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        const routeData = data.state.root.firstChild?.data;

        if (routeData && "hideRePlain" in routeData) {
          this.hideReplain();
        } else {
          this.showReplain();

          // delay setting fields, as replain can be not loaded yet
          setTimeout(() => {
            this.updateReplainFields(data.state);
          }, 1000);
        }
      }
    });
  }

  showReplain() {
    const rePlainScript = this.getRePlainScriptElement();
    const rePlainElement = this.getRePlainWidgetElement();

    if (rePlainScript) {
      return;
    }

    const replainId = this.configService.getConfig().company.replainId;

    const script = this.renderer.createElement("script");
    script.id = "rePlainScript";
    script.type = "text/javascript";
    script.text = `
    window.replainSettings = {
      id: '${replainId}'
    };
    (function (u) {
      var s = document.createElement('script');
      s.async = true;
      s.id = "rePlainScript2";
      s.src = u;
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    })('https://widget.replain.cc/dist/client.js');`;
    this.renderer.appendChild(this.document.body, script);
  }

  hideReplain() {
    const rePlainScript = this.getRePlainScriptElement();
    const rePlainScript2 = this.getRePlainScript2Element();
    const rePlainElement = this.getRePlainWidgetElement();
    const rePlainStylesElement = this.getRePlainStylesElement();

    if (rePlainScript) {
      (window as any).ReplainAPI("destroy");
      rePlainScript.remove();
    }
    if (rePlainElement) {
      rePlainElement.remove();
    }
    if (rePlainScript2) {
      rePlainScript2.remove();
    }
    if (rePlainStylesElement) {
      rePlainStylesElement.remove();
    }
  }

  getRePlainScriptElement() {
    return this.document.getElementById("rePlainScript");
  }

  getRePlainScript2Element() {
    return this.document.getElementById("rePlainScript2");
  }

  getRePlainWidgetElement() {
    return this.document.getElementById("__replain_widget");
  }

  getRePlainStylesElement() {
    return this.document.getElementById("__replain_styles");
  }

  updateReplainFields(route?: RouterStateSnapshot) {
    const email = this.userStore.getUser()?.email;
    const userId = this.userStore.getUser()?.id;

    const url = this.router.url;
    const ids: { [key: string]: string } = {};
    const stack = [route?.root];
    while (stack.length > 0) {
      const node = stack.pop();
      if (!node) {
        break;
      }
      if (node?.children) {
        stack.push(...node.children);
      }

      for (const key in node.params) {
        if (key.toLowerCase().includes("id")) {
          ids[key] = node.params[key];
        }
      }
    }

    const fields = {
      email,
      userId,
      ...ids,
      url,
    };
    const replainApi = (window as any).ReplainAPI;
    if (!replainApi) {
      return;
    }
    replainApi("setFields", fields);
  }
}
