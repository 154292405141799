import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import { CompanyFont, FontCreateDataWithFile, FontUpdateDataWithFile, FontsData } from "../../models/font";

export type FontsFilter = {
  name?: string;
};

@Injectable({
  providedIn: "root",
})
export class AdminFontsApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getFonts() {
    return this.get<CompanyFont[]>("admin/fonts");
  }

  async createFont(data: FontCreateDataWithFile) {
    const formData: FormData = new FormData();

    formData.append("file", data.fontFile, data.fontFile.name);

    for (const [key, value] of Object.entries(data)) {
      if (key === "fontFile") {
        continue;
      }
      if (typeof value !== "string") {
        formData.append(key, JSON.stringify(value));
        continue;
      }
      formData.append(key, value);
    }

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post<{ id: number }>("admin/fonts/", formData, {
      headers,
    });
  }

  async getFont(fontId: number) {
    return this.get<CompanyFont>(`admin/fonts/${fontId}`);
  }

  async getFontsPaginated(page: number, filters: FontsFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);

    return this.get<FontsData>("admin/fonts/paginated", {
      page,
      ...clearedFilters,
    });
  }

  async updateFont(fontId: number, data: FontUpdateDataWithFile) {
    const formData: FormData = new FormData();

    if (data.fontFile) {
      formData.append("file", data.fontFile, data.fontFile.name);
    }

    for (const [key, value] of Object.entries(data)) {
      if (key === "fontFile") {
        continue;
      }
      if (typeof value !== "string") {
        formData.append(key, JSON.stringify(value));
        continue;
      }
      formData.append(key, value);
    }

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post<CompanyFont>(`admin/fonts/${fontId}`, formData, {
      headers,
    });
  }

  async deleteFont(fontId: number) {
    return this.delete(`admin/fonts/${fontId}`);
  }
}
