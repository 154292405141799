import { Injectable } from "@angular/core";
import { CoverSnippet } from "@metranpage/book-data";
import { Store } from "@metranpage/state";

export type State = {
  coverSnippets: CoverSnippet[];
  coverSnippetsPageCount: number;
  activeCoverSnippet: CoverSnippet | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminCoverSnippetsStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      coverSnippets: [],
      coverSnippetsPageCount: 1,
      activeCoverSnippet: undefined,
    };
  }

  getCoverSnippetsObservable() {
    return this.getFieldObservable("coverSnippets");
  }

  setCoverSnippets(coverSnippets: CoverSnippet[]) {
    this.update((state) => ({ ...state, coverSnippets }));
  }

  getCoverSnippetsPageCountObservable() {
    return this.getFieldObservable("coverSnippetsPageCount");
  }

  setCoverSnippetsPageCount(count: number) {
    this.update((state) => ({ ...state, coverSnippetsPageCount: count }));
  }

  setActiveCoverSnippet(coverSnippet: CoverSnippet) {
    this.update((state) => ({ ...state, activeCoverSnippet: coverSnippet }));
  }

  getActiveCoverSnippetObservable() {
    return this.getFieldObservable("activeCoverSnippet");
  }
}
