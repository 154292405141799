import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImagePosition } from '../markup-sidebar-images-position-settings/markup-sidebar-images-position-settings.view';

@Component({
  selector: 'm-images-position-settings-modal',
  templateUrl: './images-position-settings-modal.view.html',
  styleUrls: ['./images-position-settings-modal.view.scss'],
})
export class ImagesPositionSettingsModalView {
  @Input()
  value = false;

  @Output()
  onSave = new EventEmitter<boolean>();
  @Output()
  close = new EventEmitter();

  protected selectedImagePosition: ImagePosition = 'fixed';

  onSaveClick() {
    this.onSave.emit(this.value);
    this.close.emit();
  }

  protected isImagePositionChecked(position: ImagePosition) {
    return this.selectedImagePosition === position;
  }

  protected onSelectImagePosition(position: string | number) {
    const imagePosition = position as ImagePosition;
    this.selectedImagePosition = imagePosition;

    this.value = imagePosition === 'fixed' ? true : false;
  }
}
