<span class="checkmark-container">
  <m-ng-icon
    src="/assets/components/checkbox-checkmark.svg"
    class="checkmark-image"
    [size]="14"
  />
</span>

<span class="checkbox-label">
  <span>{{ title }}</span>
  <ng-content></ng-content>
</span>
