<div class="image-card" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <m-skeleton-image
    *ngIf="generatedImage.imageUrl"
    [url]="getUrlForImage()"
    width="100"
    unit="%"
    backgroudColor="var(--surface-on-island)"
    animationStyle="gradient"
  />

  <div *ngIf="generatedImage.isVariantProcessing" class="spinner-group">
    <div class="spinner-24"></div>
    <div class="spinner-text" i18n="@@image-generation.generation.processing">
      image-generation.generation.processing
    </div>
  </div>

  <div *ngIf="!generatedImage.imageUrl || isButtonsVisible" class="fade"></div>

  <div *ngIf="generatedImage.imageUrl && isButtonsVisible" class="buttons" @fadeInOut>
    <div class="card-buttons">
      <div *ngIf="isModal" class="card-button" (click)="onSelectClick()">
        <m-ng-icon src="done.svg" [size]="24" />
        <span i18n="@@image-generation.image.select-button">image-generation.image.select-button</span>
      </div>

      <div *ngIf="isModal" class="divider"></div>

      <div class="card-button preview-cursor" (click)="onPreviewClick()">
        <m-ng-icon src="zoom-increase.svg" [size]="24" />
        <span i18n="@@image-generation.image.preview-button">image-generation.image.preview-button</span>
      </div>
    </div>

    <div class="divider"></div>

    <div class="footer-buttons" [class.modal]="isModal">
      <!-- <div *ngIf="isModal" class="button" (click)="onSelectClick()">
        <m-ng-icon src="done.svg" [size]="24" />
        <span i18n="@@image-generation.image.select-button">image-generation.image.select-button</span>
      </div> -->

      <div class="buttons-group">
        <div
          class="button"
          title="image-generation.generation.unzoom.button-description"
          i18n-title="@@image-generation.generation.unzoom.button-description"
          (click)="onUnzoomClick()"
        >
          <m-ng-icon src="unzoom.svg" [size]="24" />
        </div>

        <div
          class="button"
          title="image-generation.generation.upscale.button-description"
          i18n-title="@@image-generation.generation.upscale.button-description"
          (click)="onUpscaleClick()"
        >
          <m-ng-icon src="upscale.svg" [size]="24" />
        </div>

        <div
          class="button"
          title="image-generation.generation.download.button-description"
          i18n-title="@@image-generation.generation.download.button-description"
          (click)="onDownloadClick()"
        >
          <m-ng-icon src="download.svg" [size]="24" />
        </div>

        <div
          class="delete-button"
          title="image-generation.generation.delete.button-description"
          i18n-title="@@image-generation.generation.delete.button-description"
          (click)="onDeleteImageClick()"
        >
          <m-ng-icon src="delete.svg" [size]="20" />
        </div>
      </div>
    </div>
  </div>
</div>
