import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CoverService } from "@metranpage/book";
import { BookCover } from "@metranpage/book-data";
import { slideInOutVertical } from "@metranpage/components";

@Component({
  selector: "m-cover-preview",
  templateUrl: "./cover-preview.view.html",
  styleUrls: ["./cover-preview.view.scss"],
  animations: [slideInOutVertical],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverPreviewView {
  @Input()
  bookCover!: BookCover;

  @Output()
  onLike = new EventEmitter();

  protected imageUrl!: string;

  constructor(
    private readonly coverService: CoverService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.imageUrl && changes.bookCover?.currentValue) {
      this.getUrlForImage();
      // this is inside if, so, the image wont redraw and trigger animation
      this.cdr.detectChanges();
    }
  }

  protected getUrlForImage() {
    const bookId = this.bookCover?.book?.id;
    if (!bookId) {
      return;
    }
    this.imageUrl = this.coverService.getCoverPreviewImageUrl(bookId);
  }
}
