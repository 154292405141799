<div *ngIf="!hideTabs" class="tabs">
  <div class="tab" [class.active]="selectedTab === 1" (click)="goToTab(1)">
    <m-ng-icon *ngIf="tab1Icon" [src]="tab1Icon" [size]="20"></m-ng-icon>
    <span>{{tab1Title}}</span>
  </div>
  <div class="tab" [class.active]="selectedTab === 2" (click)="goToTab(2)">
    <m-ng-icon *ngIf="tab2Icon" [src]="tab2Icon" [size]="20"></m-ng-icon>
    <span>{{tab2Title}}</span>
  </div>
</div>

<div [ngClass]="{'tab-content': !hasScroll, 'tab-scroll-content': hasScroll}">
  <ng-content select="#tab1" *ngIf="selectedTab === 1"></ng-content>
  <ng-content select="#tab2" *ngIf="selectedTab === 2"></ng-content>
</div>
