import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { CoverSnippet } from "@metranpage/book-data";
import { CAN_CREATE_PUBLIC_TEMPLATES, LoadingService, NotificationsPopUpService } from "@metranpage/core";
import { debounceTime, takeUntil } from "rxjs";
import { CoverSnippetAccess } from "../../models/cover-snippet/cover-snippet-access";
import { AdminCoverSnippetsAccessService } from "../../services/cover-snippets-access/cover-snippets-access.service";
import { AdminCoverSnippetsAccessStore } from "../../services/cover-snippets-access/cover-snippets-access.store";
import { AdminCoverSnippetsService } from "../../services/cover-snippets/cover-snippets.service";
import { AdminCoverSnippetsStore } from "../../services/cover-snippets/cover-snippets.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-cover-snippets-page",
  templateUrl: "./cover-snippets.page.html",
  styleUrls: ["./cover-snippets.page.scss"],
})
export class AdminCoverSnippetsPage extends AdminBasePage implements OnInit {
  protected isDeleteCoverSnippetModalVisible = false;
  protected deleteCoverSnippetId = -1;

  coverSnippets: CoverSnippet[] = [];
  page = 1;
  pageCount = 1;
  dragIndex: number | null = null;

  coverSnippetsAccess: CoverSnippetAccess[] = [];
  coverSnippetsAccessPage = 1;
  coverSnippetsAccessPageCount = 1;

  protected searchForm = new FormGroup({
    title: new FormControl("", { nonNullable: true, validators: [] }),
  });
  protected searchTitle: string | undefined = undefined;

  constructor(
    private readonly adminCoverSnippetsService: AdminCoverSnippetsService,
    private readonly adminCoverSnippetsAccessService: AdminCoverSnippetsAccessService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    adminCoverSnippetsStore: AdminCoverSnippetsStore,
    adminCoverSnippetsAccessStore: AdminCoverSnippetsAccessStore,
    @Inject(CAN_CREATE_PUBLIC_TEMPLATES)
    readonly canCreatePublicTemplates: boolean,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();

    this.addSub(
      adminCoverSnippetsStore
        .getCoverSnippetsObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((coverSnippets) => {
          this.coverSnippets = coverSnippets;
          this.cdr.markForCheck();
        }),
    );
    this.addSub(
      adminCoverSnippetsStore
        .getCoverSnippetsPageCountObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((count) => {
          this.pageCount = count;
          this.cdr.markForCheck();
        }),
    );

    this.addSub(
      adminCoverSnippetsAccessStore
        .getCoverSnippetsAccessObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((coverSnippetsAccess) => {
          this.coverSnippetsAccess = coverSnippetsAccess;
          this.cdr.markForCheck();
        }),
    );
    this.addSub(
      adminCoverSnippetsAccessStore
        .getCoverSnippetsAccessPageCountObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((count) => {
          this.coverSnippetsAccessPageCount = count;
          this.cdr.markForCheck();
        }),
    );
  }

  ngOnInit(): void {
    this.adminCoverSnippetsService.loadCoverSnippetsPaginated(this.page);

    this.searchForm.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(600)).subscribe((value) => {
      if (value.title) {
        this.searchTitle = value.title;
      } else {
        this.searchTitle = undefined;
      }

      this.adminCoverSnippetsService.loadCoverSnippetsPaginated(this.page, { title: this.searchTitle });
    });

    this.adminCoverSnippetsAccessService.loadCoverSnippetsAccessPaginated(this.coverSnippetsAccessPage);
  }

  trackByCoverSnippetId(index: number, coverSnippet: CoverSnippet | CoverSnippetAccess) {
    return coverSnippet.id;
  }

  onPageChanged(page: number) {
    this.page = page;
    this.adminCoverSnippetsService.loadCoverSnippetsPaginated(this.page);
  }

  onDeleteCoverSnippetClick(coverSnippetId: number) {
    this.isDeleteCoverSnippetModalVisible = true;
    this.deleteCoverSnippetId = coverSnippetId;
  }

  onCloseDeleteCoverSnippetModal() {
    this.isDeleteCoverSnippetModalVisible = false;
  }

  async onDeleteCoverSnippet() {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminCoverSnippetsService.deleteCoverSnippet(this.deleteCoverSnippetId);
    this.loadingService.stopLoading();
    if (result === "success") {
      this.onCloseDeleteCoverSnippetModal();
      this.adminCoverSnippetsService.loadCoverSnippetsPaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.cover-snippets.cover-snippet.error.cant-delete-cover-snippet:`);
    }
  }

  onDragStart(event: DragEvent, index: number) {
    event.dataTransfer?.setData("text/plain", index.toString());
    this.dragIndex = index;
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  async onDrop(event: DragEvent, index: number) {
    event.preventDefault();

    if (this.dragIndex === null) return;

    const draggedCoverSnippet = this.coverSnippets.splice(this.dragIndex, 1)[0];
    this.coverSnippets.splice(index, 0, draggedCoverSnippet);

    for (let i = 0; i < this.coverSnippets.length; i++) {
      this.coverSnippets[i].order = i;
    }

    this.notificationService.closeAll();
    const status = await this.adminCoverSnippetsService.saveCoverSnippetsOrder(this.coverSnippets);
    if (status === "success") {
    } else {
      this.notificationService.error($localize`:@@admin.cover-snippets.cover-snippet.error.cant-update-order:`);
    }

    this.dragIndex = null;
  }

  onCoverSnippetsAccessPageChanged(page: number) {
    this.coverSnippetsAccessPage = page;
    this.adminCoverSnippetsAccessService.loadCoverSnippetsAccessPaginated(this.coverSnippetsAccessPage);
  }

  async onCoverSnippetVisibleClick(coverSnippetAccess: CoverSnippetAccess) {
    const isVisibleToUsers = !coverSnippetAccess.isVisibleToUsers;

    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminCoverSnippetsAccessService.updateCoverSnippetAccess(coverSnippetAccess.id, {
      isVisibleToUsers,
    });
    this.loadingService.stopLoading();
    if (result !== "success") {
      this.notificationService.error($localize`:@@admin.coverSnippets.coverSnippet.error.cant-add-coverSnippet:`);
    }
  }

  async onCoverSnippetPremiumChangeClick(coverSnippetAccess: CoverSnippetAccess) {
    const isPremium = !coverSnippetAccess.isPremium;

    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminCoverSnippetsAccessService.updateCoverSnippetAccess(coverSnippetAccess.id, {
      isPremium,
    });
    this.loadingService.stopLoading();
    if (result !== "success") {
      this.notificationService.error($localize`:@@admin.coverSnippets.coverSnippet.error.cant-add-coverSnippet:`);
    }
  }

  async onChangeVisibleToUsers(coverSnippet: CoverSnippet) {
    this.loadingService.startLoading({ fullPage: true });
    await this.adminCoverSnippetsService.updateCoverSnippet(coverSnippet.id, {
      isVisibleToUsers: !coverSnippet.isVisibleToUsers,
    });
    this.onPageChanged(this.page);
    this.loadingService.stopLoading();
  }

  async onChangeVisibleToOtherCompanies(coverSnippet: CoverSnippet) {
    this.loadingService.startLoading({ fullPage: true });
    await this.adminCoverSnippetsService.updateCoverSnippet(coverSnippet.id, {
      isVisibleToOtherCompanies: !coverSnippet.isVisibleToOtherCompanies,
    });
    this.onPageChanged(this.page);
    this.loadingService.stopLoading();
  }

  getUrlForPreviewImage(coverSnippet: CoverSnippet) {
    return this.adminCoverSnippetsService.getUrlForPreviewImageByFileVid(coverSnippet.file.vid);
  }
}
