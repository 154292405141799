<m-ng-island>
  <div class="container">
    <div class="form-panel">
      <div class="header">
        <div i18n="@@user.auth.login-title">user.auth.login-title</div>
        <m-lang-select-2 [userLanguage]="userLanguage" (changeLang)="onLangChange($event)" />
      </div>

      <form [formGroup]="form" (keydown)="onKeyDown($event)">
        <div class="inputs">
          <div class="input-group">
            <m-ng-input-with-icon
              class="email"
              type="email"
              iconLeft="input-email.svg"
              name="email"
              i18n-placeholder="@@user.auth.email-placeholder"
              placeholder="@@user.auth.email-placeholder"
              formControlName="email"
              (focusout)="focusoutEvent('email')"
            />
            <m-login-error
              *ngIf="isInputEmailErrorVisible && isEmailIncorrect()"
              error="incorrect-email-format"
            ></m-login-error>
            <m-login-error
              *ngIf="isInputEmailErrorVisible && isFieldEmpty('email')"
              error="field-required"
            ></m-login-error>
          </div>

          <div class="input-group">
            <m-ng-input-password
              class="password"
              type="password"
              name="password"
              [withLeftIcon]="true"
              i18n-placeholder="@@user.auth.password-placeholder"
              placeholder="@@user.auth.password-placeholder"
              formControlName="password"
              (focusout)="focusoutEvent('password')"
            />
            <m-login-error
              *ngIf="isInputPasswordErrorVisible && isFieldEmpty('password')"
              error="field-required"
            ></m-login-error>

            <a
              class="forgot-password"
              routerLink="/restore-password"
              [queryParams]="getQueryParams()"
              i18n="@@user.auth.restore-password-prompt"
            >
              user.auth.restore-password-prompt
            </a>
          </div>
        </div>

        <m-login-error *ngFor="let error of errors" [error]="error"></m-login-error>

        <div class="action-group">
          <m-button
            class="login-button"
            button-style="primary"
            size="full-width"
            i18n="@@user.auth.login-button"
            (onClick)="onSignInClick()"
          >
            user.auth.login-button
          </m-button>

          <div class="no-account">
            <span i18n="@@user.auth.not-registered-hint">user.auth.not-registered-hint</span>
            <a
              class="register-link"
              routerLink="/registration"
              [queryParams]="getQueryParams()"
              i18n="@@user.auth.register-switch"
            >
              user.auth.register-switch
            </a>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="isSocialLoginVisible()" class="divider">
      <div class="divider-line"></div>
      <div i18n="@@user.auth.divider-text">user.auth.divider-text</div>
      <div class="divider-line"></div>
    </div>

    <div *ngIf="isSocialLoginVisible()" class="socials-login">
      <m-social-login-button
        *ngIf="isGoogleLoginAvailable"
        iconSrc="auth-google-2.svg"
        text="user.auth.login.social.google"
        i18n-text="@@user.auth.login.social.google"
        (click)="onGoogleLogin()"
      />
      <m-social-login-button
        *ngIf="isVkLoginAvailable"
        iconSrc="auth-vk-2.svg"
        text="user.auth.login.social.vk"
        i18n-text="@@user.auth.login.social.vk"
        (click)="onVkLogin()"
      />
    </div>
  </div>

  <m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>
</m-ng-island>
