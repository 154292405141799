<div *ngIf="options.length > 0" class="content">
  <div
    *ngFor="let option of options"
    class="button"
    [class.active]="option.id === selectedOptionId"
    (click)="onSelectClick(option)"
  >
    <span>{{ option.value }}</span>
    <m-new-mark *ngIf="option.id !== selectedOptionId && option.hasNewMark" />
  </div>
</div>
