<m-cover-conceptual-assistant-generation-menu
  *ngIf="isCoverConceptualGenerationMenuVisible || !coverConceptualGeneration"
  [cover]="cover"
  [coverConceptualGeneration]="coverConceptualGeneration"
  (generateCoverConceptual)="generateCoverConceptualClick($event)"
  (closeConceptualAssistant)="closeConceptualAssistantClick()"
/>

<m-cover-conceptual-assistant-generation-result
  *ngIf="isCoverConceptualGenerationResultVisible && coverConceptualGeneration"
  [cover]="cover"
  [coverConceptualGeneration]="coverConceptualGeneration"
  [stylesBasic]="stylesBasic"
  [stylesAdvanced]="stylesAdvanced"
  [fonts]="fonts"
  (generateImage)="onGenerateImageClick($event)"
  (applyFontsAndColors)="onApplyFontsAndColorsClick($event)"
  (back)="onBackClick()"
  (closeConceptualAssistant)="closeConceptualAssistantClick()"
/>
