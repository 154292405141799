import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import { Format, FormatCreateDataDto, FormatData, FormatDto, FormatUpdateDataDto } from "../../models/format";

export type FormatFilter = {
  value?: string;
};

@Injectable({
  providedIn: "root",
})
export class AdminFormatApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  getFormats() {
    return this.get<FormatDto[]>("admin/format");
  }

  getFormat(formatId: number) {
    return this.get<FormatDto>(`admin/format/${formatId}`);
  }

  async createFormat(data: FormatCreateDataDto) {
    return this.post<{ id: number }>("admin/format/", data);
  }

  async updateFormat(id: number, data: FormatUpdateDataDto) {
    return this.post<unknown>(`admin/format/${id}`, data);
  }

  async deleteFormat(formatId: number) {
    return this.delete<unknown>(`admin/format/${formatId}`);
  }

  saveFormatsOrder(data: Format[]) {
    return this.post<unknown>("admin/format/update-order", data);
  }
}
