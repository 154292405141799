import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CoverObject, GroupObject, ObjectsAlignment } from "@metranpage/book-data";

@Component({
  selector: "m-cover-group-object-settings",
  templateUrl: "./cover-group-object-settings.component.html",
  styleUrls: ["./cover-group-object-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverGroupObjectSettingsComponent {
  @Input() currentObject!: GroupObject;

  @Output() align = new EventEmitter<ObjectsAlignment>();
  @Output() ungroup = new EventEmitter();
  @Output() update = new EventEmitter<CoverObject>();

  onColorsUpdate(objects: CoverObject[]) {
    this.update.emit(this.currentObject);
  }

  onAlign(alignment: ObjectsAlignment) {
    this.align.emit(alignment);
  }

  onUngroupObjects() {
    this.ungroup.emit();
  }
}
