import { ChangeDetectorRef, Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { Observable, Subject, takeUntil } from "rxjs";
import { CompanyApiSettings, TestWebhookEventResult } from "../../models/api-settings";
import { AdminCompanyApiSettingsService } from "../../services/api/companies-api.service";
import { AdminCompanyApiSettingsStore } from "../../services/api/companies-api.store";

@Component({
  selector: "m-admin-company-settings-api-page",
  templateUrl: "./api.page.html",
  styleUrls: ["./api.page.scss"],
})
export class AdminCompanyApiPage {
  private destroyed$ = new Subject<void>();
  protected apiSettings$: Observable<CompanyApiSettings | undefined> | undefined;

  readonly form: FormGroup;

  protected lastWebhookResult: TestWebhookEventResult | undefined;

  constructor(
    private readonly service: AdminCompanyApiSettingsService,
    private readonly store: AdminCompanyApiSettingsStore,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.form = new FormBuilder().group({
      webhookUrl: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
      redirectOnLayoutFinishUrl: new FormControl<string | undefined>("", { nonNullable: false, validators: [] }),
    });
  }

  async ngAfterViewInit() {
    await this.service.refreshApiSettings();
    this.apiSettings$ = this.store.getApiSettingsObservable();
    this.apiSettings$!.pipe(takeUntil(this.destroyed$)).subscribe((apiSettings) => {
      this.form.setValue({
        webhookUrl: apiSettings?.webhookUrl,
        redirectOnLayoutFinishUrl: apiSettings?.redirectOnLayoutFinishUrl,
      });
    });
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.updateApiSettings(this.form.value as CompanyApiSettings);
    this.loadingService.stopLoading();

    if (result.status === "success") {
      // do nothing
    } else {
      // this.error.emit(result.error);
      this.notificationService.error("Cannot load token, TODO notification");
      console.error("Cannot load token, TODO notification");
    }
  }

  async onResetToken() {
    const result = await this.service.resetApiToken();
    if (result.status === "success") {
      // do nothing, as we already subscribed
    } else {
      this.notificationService.error("Cannot load token, TODO notification");
      console.error("Cannot load token, TODO notification");
    }
    this.cdr.markForCheck();
  }

  async testWebhook() {
    const result = await this.service.testWebhook(this.form.value.webhookUrl!);
    if (result.status === "success") {
      this.lastWebhookResult = result.result;
    } else {
      this.notificationService.error("Cannot send webhook request");
      this.lastWebhookResult = result.result;
    }
  }
}
