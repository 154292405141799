import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { slideInOutVertical } from "@metranpage/components";

@Component({
  selector: "m-cover-conceptual-assistant-generation-menu-section",
  templateUrl: "./cover-conceptual-assistant-generation-menu-section.view.html",
  styleUrls: ["./cover-conceptual-assistant-generation-menu-section.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOutVertical],
})
export class CoverConceptualAssistantGenerationMenuSectionView {
  @Input()
  title!: string;
  @Input()
  iconSrc?: string;
  @Input()
  canCollapsing = false;
  @Input()
  isCollapsed = true;

  toggle() {
    if (!this.canCollapsing) {
      return;
    }
    this.isCollapsed = !this.isCollapsed;
  }
}
