import { Injectable } from "@angular/core";
import { RealtimeService, filterUndefined } from "@metranpage/core";
import { Company } from "../models/company";
import { CompanyApi } from "./company.api";
import { CompanyStore } from "./company.store";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  constructor(
    private readonly companyApi: CompanyApi,
    private readonly companyStore: CompanyStore,
    realtimeService: RealtimeService,
  ) {
    realtimeService
      .getEvents<Company>("company-update")
      .pipe(filterUndefined())
      .subscribe((companyUpdate: Company) => {
        this.companyStore.setCompany(companyUpdate);
      });
  }

  async refreshCompany() {
    const company = await this.companyApi.getCompany();
    this.companyStore.setCompany(company);
  }
}
