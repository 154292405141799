<m-ng-island>
  <div class="container">
    <div class="form-panel">
      <div class="header">
        <div i18n="@@user.auth.restore-password-title">user.auth.restore-password-title</div>
        <m-lang-select-2 [userLanguage]="userLanguage" (changeLang)="onLangChange($event)" />
      </div>

      <div *ngIf="!isEmailSent" class="restore-pass-description" i18n="@@user.auth.restore-password-description">
        user.auth.restore-password-description
      </div>

      <div *ngIf="isEmailSent" class="email-sent-description" i18n="@@user.auth.restore-email-sent-text">
        user.auth.restore-email-sent-text
      </div>

      <form [formGroup]="form" (keydown)="onKeyDown($event)">
        <div class="inputs">
          <div class="input-group">
            <m-ng-input-with-icon
              class="email"
              type="email"
              iconLeft="input-email.svg"
              name="email"
              i18n-placeholder="@@user.auth.email-placeholder"
              placeholder="@@user.auth.email-placeholder"
              formControlName="email"
              (focusout)="focusoutEvent('email')"
            />

            <m-login-error *ngIf="isInputEmailErrorVisible && isEmailIncorrect()" error="incorrect-email-format" />
            <m-login-error *ngIf="isInputEmailErrorVisible && isFieldEmpty('email')" error="field-required" />
          </div>

          <m-login-error *ngFor="let error of errors" [error]="error"></m-login-error>
        </div>

        <div class="action-group">
          <m-button
            *ngIf="!isEmailSent"
            class="restore-password-button"
            button-style="primary"
            size="full-width"
            i18n="@@user.auth.restore-password-request-button"
            (onClick)="onRestorePassButtonClick()"
          >
            user.auth.restore-password-request-button
          </m-button>

          <m-button
            *ngIf="isEmailSent"
            class="restore-password-button"
            button-style="primary"
            size="full-width"
            i18n="@@user.auth.restore-password.login-button"
            routerLink="/login"
          >
            user.auth.restore-password.login-button
          </m-button>

          <div *ngIf="!isEmailSent" class="back" routerLink="/login" i18n="@@user.auth.restore-password-back-button">
            user.auth.restore-password-back-button
          </div>
        </div>
      </form>
    </div>
  </div>

  <m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>
</m-ng-island>
