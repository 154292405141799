<div class="image-card">
  <ng-container [ngSwitch]="type">
    <div *ngSwitchCase="'timer'" class="timer-group">
      <div class="spinner-18"></div>
      <div class="timer-text">
        <span>{{time}}</span>
      </div>
    </div>

    <div *ngSwitchCase="'text'" class="timer-group-2">
      <div class="spinner-24"></div>
      <div class="timer-text">{{text}}</div>
    </div>
  </ng-container>
</div>
