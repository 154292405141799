import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { LoadingService } from "@metranpage/core";
import { PricingService, PricingViewService } from "@metranpage/pricing";
import { Order } from "@metranpage/pricing-data";
import { User, UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";

@Component({
  selector: "m-account-payments-history-page",
  templateUrl: "./account-payments-history.page.html",
  styleUrls: ["./account-payments-history.page.scss"],
})
export class AccountPaymentsHistoryPage implements OnInit, OnDestroy {
  user?: User;
  orders: Order[] = [];

  sub: Subscription = new Subscription();

  constructor(
    private readonly userStore: UserStore,
    private readonly pricingService: PricingService,
    private readonly pricingViewService: PricingViewService,
    private readonly loadingService: LoadingService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );
  }

  ngOnInit(): void {
    this.updateUserData();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async updateUserData() {
    this.loadingService.startLoading({ fullPage: true });
    this.orders = await this.pricingService.getOrders();
    this.loadingService.stopLoading();

    this.cdr.detectChanges();
  }

  trackByOrderId(index: number, order: Order) {
    return order.id;
  }

  priceFormat(order: Order) {
    const currency = this.pricingViewService.getCurrencySymbol(order.currency);

    return this.pricingViewService.priceFormat(order.amount || 0, currency);
  }

  orderDate(orderDate: string) {
    return new Date(orderDate).toLocaleDateString("ru-RU");
  }

  orderStatus(orderStatus: string) {
    return this.pricingViewService.orderStatus(orderStatus);
  }

  orderDetails(order: Order) {
    return `${order.creditsCount} ${this.pricingViewService.pluralizeCredits(order.creditsCount)}`;
  }
}
