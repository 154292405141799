import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { RewardNotification } from "./models/rewards/reward-notification";

export type RewardNotificationState = {
  unreadRewardNotificationsCount: number;
  rewardNotifications: RewardNotification[];
  rewardNotificationsPageCount: number;
};

@Injectable({
  providedIn: "root",
})
export class RewardNotificationStore extends Store<RewardNotificationState> {
  protected override getInitialState(): RewardNotificationState {
    return {
      unreadRewardNotificationsCount: 0,
      rewardNotifications: [],
      rewardNotificationsPageCount: 1,
    };
  }

  getUnreadRewardNotificationsCountObservable() {
    return this.getFieldObservable("unreadRewardNotificationsCount");
  }

  getUnreadRewardNotificationsCount() {
    return this.getField("unreadRewardNotificationsCount");
  }

  getRewardNotificationsObservable() {
    return this.getFieldObservable("rewardNotifications");
  }

  getRewardNotifications() {
    return this.getField("rewardNotifications");
  }

  getPageCountObservable() {
    return this.getFieldObservable("rewardNotificationsPageCount");
  }

  setUnreadRewardNotificationsCount(unreadRewardsCount: number) {
    this.update((state) => ({ ...state, unreadRewardNotificationsCount: unreadRewardsCount }));
  }

  setRewardNotifications(rewardNotifications: RewardNotification[]) {
    this.update((state) => ({ ...state, rewardNotifications: rewardNotifications }));
  }

  setRewardNotificationsPageCount(count: number) {
    this.update((state) => ({ ...state, rewardNotificationsPageCount: count }));
  }

  addRewardNotification(rewardNotification: RewardNotification) {
    this.update((state) => {
      const storeRewardNotification = state.rewardNotifications.find((n) => n.id === rewardNotification.id);
      if (storeRewardNotification) {
        return state;
      }

      return {
        ...state,
        rewardNotifications: [rewardNotification].concat(state.rewardNotifications),
        unreadRewardNotificationsCount: state.unreadRewardNotificationsCount + 1,
      };
    });
  }

  addRewardNotificationsToStart(rewards: RewardNotification[]) {
    this.update((state) => {
      const newRewards: RewardNotification[] = [];
      for (const reward of rewards) {
        const storeReward = state.rewardNotifications.find((ig) => ig.id === reward.id);
        if (storeReward) {
          continue;
        }
        newRewards.push(reward);
      }

      return {
        ...state,
        rewardNotifications: newRewards.concat(state.rewardNotifications),
      };
    });
  }

  addRewardNotificationsToEnd(rewards: RewardNotification[]) {
    this.update((state) => {
      const newRewardNotifications: RewardNotification[] = [];
      for (const reward of rewards) {
        const storeReward = state.rewardNotifications.find((ig) => ig.id === reward.id);
        if (storeReward) {
          continue;
        }
        newRewardNotifications.push(reward);
      }

      return {
        ...state,
        rewardNotifications: state.rewardNotifications.concat(newRewardNotifications),
      };
    });
  }

  updateRewardNotification(updatedReward: RewardNotification) {
    const rewards = this.getRewardNotifications();
    const reward = rewards.find((n) => n.id === updatedReward.id);

    if (reward) {
      this.update((state) => {
        const updated = state.rewardNotifications.map((n) => {
          if (n.id === updatedReward.id) {
            return updatedReward;
          }
          return n;
        });

        return {
          ...state,
          rewardNotifications: updated,
        };
      });
    } else {
      this.addRewardNotification(updatedReward);
    }
  }
}
