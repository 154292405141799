<ng-container>
  <div class="table-context-menu">
    <a
      class="table-context-menu-item"
      (click)="
        onItemClick($event, {
          action: 'add_row_above',
          count: selectedRowsCount
        })
      "
    >
      <m-icon
        [src]="'/assets/icons/table-menu-add.svg'"
        class="icon"
        size="xs"
      ></m-icon>
      {{ pluralize('add-rows-above', {rows: selectedRowsCount}) }}
     </a>
    <a
      class="table-context-menu-item"
      (click)="
        onItemClick($event, {
          action: 'add_row_below',
          count: selectedRowsCount
        })
      "
    >
      <m-icon
        [src]="'/assets/icons/table-menu-add.svg'"
        class="icon"
        size="xs"
      ></m-icon>
      {{ pluralize('add-rows-below', {rows: selectedRowsCount}) }}
    </a>
    <a
      class="table-context-menu-item"
      (click)="
        onItemClick($event, {
          action: 'add_column_right',
          count: selectedColumnsCount
        })
      "
    >
      <m-icon
        [src]="'/assets/icons/table-menu-add.svg'"
        class="icon"
        size="xs"
      ></m-icon>
      {{ pluralize('add-columns-right', {cols: selectedColumnsCount}) }}
    </a>
    <a
      class="table-context-menu-item"
      (click)="
        onItemClick($event, {
          action: 'add_column_left',
          count: selectedColumnsCount
        })
      "
    >
      <m-icon
        [src]="'/assets/icons/table-menu-add.svg'"
        class="icon"
        size="xs"
      ></m-icon>
      {{ pluralize('add-columns-left', {cols: selectedColumnsCount}) }}
    </a>
    <hr />
    <a
      class="table-context-menu-item"
      (click)="
        onItemClick($event, { action: 'delete_row', count: selectedRowsCount })
      "
    >
      <m-icon
        [src]="'/assets/icons/table-menu-delete.svg'"
        class="icon"
        size="xs"
      ></m-icon>
      {{ pluralize('delete-rows', {rows: selectedRowsCount}) }}
    </a>
    <a
      class="table-context-menu-item"
      (click)="
        onItemClick($event, {
          action: 'delete_column',
          count: selectedColumnsCount
        })
      "
    >
      <m-icon
        [src]="'/assets/icons/table-menu-delete.svg'"
        class="icon"
        size="xs"
      ></m-icon>
      {{ pluralize('delete-columns', {cols: selectedColumnsCount}) }}
    </a>
    <!-- <a class="table-context-menu-item" (click)="onItemClick($event, 'delete_table')">
            <svg-icon [src]="'/assets/img/table-menu-delete.svg'" alt="" class="icon"></svg-icon>
            <span i18n="@@editor.table.context_menu.delete_table">editor.table.context_menu.delete_table</span>
        </a> -->
    <span *ngIf="selectedRowsCount > 1 || selectedColumnsCount > 1">
      <hr />
      <a
        class="table-context-menu-item"
        (click)="onItemClick($event, { action: 'union_cells' })"
      >
        <m-icon
          [src]="'/assets/icons/table-menu-union.svg'"
          class="icon"
          size="xs"
        ></m-icon>
        <span i18n="@@editor.table.context_menu.union_cells"
          >editor.table.context_menu.union_cells</span
        >
      </a>
    </span>
  </div>
</ng-container>
