import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject } from "@angular/core";
import { BASE_URL } from "../tokens";
import { Api } from "./api.service";

export type GeneralResultStatus = "success" | "error";

export type UploadEditorImageResult = {
  status: GeneralResultStatus;
  response?: any;
};

export interface ImageLoadingProvider {
  getUrlForEditorImage(bookId: number, imagePath: string): string;
  uploadEditorImage(bookId: number, file: File, imageIndex: number): Promise<UploadEditorImageResult>;
}

export class DefaultImageLoadingProvider extends Api implements ImageLoadingProvider {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  getUrlForEditorImage(bookId: number, imagePath: string) {
    if (imagePath.startsWith("images/")) {
      return this.absoluteUrl(`books/files/${bookId}/${imagePath}`);
    }
    // not our image
    return imagePath;
  }

  async uploadEditorImage(bookId: number, file: File, imageIndex: number): Promise<UploadEditorImageResult> {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    formData.append("imageIndex", `${imageIndex}`);

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    try {
      const response = await this.post<unknown>(`books/files/${bookId}/images`, formData, {
        headers,
      });
      return { status: "success", response };
    } catch (error: unknown) {
      console.error(error);

      return { status: "error" };
    }
  }
}
