import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@metranpage/auth";
import { CompanyStore } from "@metranpage/company";
import { SelectValue } from "@metranpage/components";
import { ConnectTelegramService, LoadingService, NotificationsPopUpService, filterUndefined } from "@metranpage/core";
import { User, UserStore } from "@metranpage/user-data";
import { Subscription, timer } from "rxjs";
import { UserService } from "../../services/user.service";
import { AccountMainData } from "../../views/account-main/account-main.view";

@Component({
  selector: "m-account-main-page",
  template: `
    <m-account-main-view
      [user]="user"
      [savedStatus]="savedStatus"
      [roleOptions]="getRoleOptions()"
      [avatarUrl]="avatarUrl"
      [isLogoutButtonAvailable]="isLogoutButtonAvailable"
      (onSaveClick)="onSaveClick($event)"
      (onDeleteAccountClick)="onDeleteAccountClick()"
      (onConnectTelegram)="onConnectTelegramClick()"
      (onDisconnectTelegram)="onDisconnectTelegramClick()"
    />
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        height: 100%;
      }
    `,
  ],
})
export class AccountMainPage {
  protected user?: User;
  protected savedStatus = false;
  protected savedStatusVisibleTimeout = 1;
  protected avatarUrl?: string;
  protected isLogoutButtonAvailable = true;

  sub: Subscription = new Subscription();

  constructor(
    private readonly userService: UserService,
    private readonly loadingService: LoadingService,
    private readonly notificationsService: NotificationsPopUpService,
    private readonly authService: AuthService,
    private readonly router: Router,
    userStore: UserStore,
    readonly connectTelegramService: ConnectTelegramService,
    companyStore: CompanyStore,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );

    this.sub.add(
      companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.isLogoutButtonAvailable = company.isLogoutButtonAvailable;
        }),
    );
  }

  ngOnInit(): void {
    this.getProfilePicture();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async onSaveClick(data: AccountMainData) {
    this.notificationsService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    if (this.user && data.avatarFile) {
      const resultAvatar = await this.userService.updateUserProfilePicture(this.user?.id, data.avatarFile);
    }

    const result = await this.userService.updateUser(data);
    if (result === "success") {
      this.notificationsService.notify({
        content: $localize`:@@user.profile.updated-successfully:`,
        type: "success",
      });

      this.savedStatus = true;
      timer(this.savedStatusVisibleTimeout * 1000).subscribe(() => {
        this.savedStatus = false;
      });
    }
    this.loadingService.stopLoading();
  }

  protected getRoleOptions(): SelectValue[] {
    return [
      { id: 1, value: $localize`:@@user.roles.publisher:` },
      { id: 2, value: $localize`:@@user.roles.author:` },
      { id: 3, value: $localize`:@@user.roles.designer:` },
      { id: 4, value: $localize`:@@user.roles.student:` },
    ];
  }

  async getProfilePicture() {
    if (!this.user) {
      return;
    }

    const result = await this.userService.getUserProfilePicture(this.user);
    if (result.url) {
      this.avatarUrl = result.url;
    }
  }

  async onDeleteAccountClick() {
    const result = await this.userService.deleteUser();
    if (result !== "success") {
      this.notificationsService.error($localize`:@@user.profile.password.delete-profile-error:`);
      return;
    }
    this.authService.logout();
    this.router.navigate(["/"]);
  }

  protected async onConnectTelegramClick() {
    this.notificationsService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.connectTelegramService.connectTelegramProfile();

    this.loadingService.stopLoading();
  }

  protected async onDisconnectTelegramClick() {
    this.notificationsService.closeAll();
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.connectTelegramService.deleteTelegramProfile();
    if (result !== "success") {
      this.notificationsService.error(
        $localize`:@@user.profile.socials.disconnect-account-confirmation.telegram.error:`,
      );
    }
    this.loadingService.stopLoading();
  }
}
