import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "m-character-delete-modal",
  templateUrl: "./character-delete-modal.view.html",
  styleUrls: ["./character-delete-modal.view.scss"],
})
export class CharacterDeleteModal {
  @Input()
  name = "";

  @Output()
  onCancel = new EventEmitter<void>();
  @Output()
  onDelete = new EventEmitter<void>();

  onDeleteClick() {
    this.onDelete.emit();
  }

  onCancelClick() {
    this.onCancel.emit();
  }
}
