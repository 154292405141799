<m-promo-card [title]="getTitleLeft()" [description]="getTextLeft()" icon="admin-nav-tariffs.svg" />

<m-promo-card
  [backgroundColor]="'present'"
  title="@@books.info-modal.free-layout.title!"
  i18n-title="@@books.info-modal.free-layout.title!"
  description="@@books.layout-as-a-gift-conditions.text"
  i18n-description="@@books.layout-as-a-gift-conditions.text"
  icon="gift.svg"
/>
