import { Inject, Injectable } from "@angular/core";
import { ConfigService } from "@metranpage/core-interfaces";
import type { User } from "@metranpage/user-data";
import { BehaviorSubject } from "rxjs";

export type Theme = "light" | "dark";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  private changeEvents = new BehaviorSubject<string>("");
  changeEvents$ = this.changeEvents.asObservable();

  private forcedWhiteTheme = false;
  private defaultTheme: "dark" | "light" | undefined = undefined;

  constructor(@Inject("ConfigService") private configService: ConfigService) {
    this.forcedWhiteTheme = configService.getConfig().company.flags.forceWhiteTheme;
    this.defaultTheme = configService.getConfig().company.flags.defaultTheme;
    this.setTheme(this.getTheme());
  }

  toggleTheme() {
    if (this.getTheme() === "dark") {
      this.setTheme("light");
    } else {
      this.setTheme("dark");
    }
  }

  setTheme(theme: Theme) {
    if (theme === "dark") {
      document.body.classList.add("m-dark-theme");
    } else {
      document.body.classList.remove("m-dark-theme");
    }

    try {
      if (theme === "dark") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>window).ReplainAPI("activateDarkTheme");
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (<any>window).ReplainAPI("activateLightTheme");
      }
    } catch (error) {
      // do nothing
    }

    localStorage.setItem("m-theme", theme);
    this.changeEvents.next(theme);
  }

  getSavedTheme() {
    return localStorage.getItem("m-theme");
  }

  saveThemeFromUser(user: User) {
    if (user.darkTheme) {
      this.setTheme("dark");
    } else {
      this.setTheme("light");
    }
  }

  getBrowserTheme(): Theme {
    if (this.defaultTheme) {
      return this.defaultTheme;
    }
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
    if (prefersDarkScheme.matches || false) {
      return "dark";
    }
    return "light";
  }

  getTheme(): Theme {
    if (this.forcedWhiteTheme) {
      return "light";
    }
    const userTheme = this.getSavedTheme();

    if (userTheme) {
      return userTheme as Theme;
    }
    return this.getBrowserTheme();
  }

  getThemeSuffix() {
    let theme = "lt";
    if (this.getTheme() === "dark") {
      theme = "dt";
    }
    return theme;
  }
}
