<m-modal position="center" [closeOnBackDropClick]="false" (close)="onCancelAddition.emit()">
  <form [formGroup]="form">
    <h1 i18n="@@admin.rewards.daily.add">admin.rewards.daily.add</h1>

    <div class="controls">
      <div class="control">
        <span class="label" i18n="@@admin.rewards.daily.type">admin.rewards.daily.type</span>
        <m-select
          class="select"
          input-style="normal"
          size="s"
          [options]="getTypeSelectOptions()"
          formControlName="type"
        ></m-select>
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.rewards.daily.limit">admin.rewards.daily.limit</span>
        <m-ng-input-number formControlName="limit" />
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.rewards.daily.reward-credits-count">
          admin.rewards.daily.reward-credits-count
        </span>
        <m-ng-input-number formControlName="rewardCreditsCount" />
      </div>
    </div>

    <div class="buttons">
      <m-button button-style="secondary" (onClick)="onCancel()" i18n="@@admin.common.cancel">
        admin.common.cancel
      </m-button>
      <m-button
        button-style="primary"
        [is-disabled]="!form.valid"
        (onClick)="createRewardDaily()"
        i18n="@@admin.common.create"
      >
        admin.common.create
      </m-button>
    </div>
  </form>
</m-modal>
