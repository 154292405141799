import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import { Tariff, TariffCreateData, TariffData, TariffsData } from "../../models/tariff";

export type TariffsFilter = {
  title?: string;
};

@Injectable({
  providedIn: "root",
})
export class AdminTariffsApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async createTariff(data: TariffCreateData) {
    return this.post<{ id: number }>("admin/tariffs/", data);
  }

  async copyTariff(tariffId: number) {
    return this.post<{ id: number }>(`admin/tariffs/${tariffId}/copy`, {});
  }

  async getTariff(tariffId: number) {
    return this.get<TariffData>(`admin/tariffs/${tariffId}`);
  }

  async getTariffs() {
    return this.get<Tariff[]>("admin/tariffs");
  }

  async getTariffsPaginated(page: number, filters: TariffsFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<TariffsData>("admin/tariffs/paginated", {
      page,
      ...clearedFilters,
    });
  }

  async updateTariff(tariffId: number, data: TariffCreateData) {
    return this.post<unknown>("admin/tariffs/update-tariff-data", {
      tariffId,
      ...data,
    });
  }

  async deleteTariff(tariffId: number) {
    return this.delete(`admin/tariffs/${tariffId}`);
  }
}
