import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'm-delete-project-modal',
  templateUrl: './delete-project-modal.view.html',
  styleUrls: ['./delete-project-modal.view.scss'],
})
export class DeleteProjectModal {
  @Input('project-title')
  projectTitle = '';

  @Output()
  onCancel = new EventEmitter<void>();
  @Output()
  onDelete = new EventEmitter<void>();
}
