import { Component, OnInit } from "@angular/core";
import { Observable, combineLatest, map } from "rxjs";
import { AdminCoverTemplatesService } from "../../services/cover-templates/cover-templates.service";
import { AdminCoverTemplatesStore } from "../../services/cover-templates/cover-templates.store";
import { AdminBasePage } from "../admin/admin.page";

type BookCoverTemplateExt = any;

@Component({
  selector: "m-admin-cover-templates-page",
  templateUrl: "./cover-templates.page.html",
  styleUrls: ["./cover-templates.page.scss"],
})
export class AdminCoverTemplatesPageComponent extends AdminBasePage implements OnInit {
  data$!: Observable<{
    templates: BookCoverTemplateExt[];
    activeTemplate: BookCoverTemplateExt | undefined;
  }>;

  constructor(
    private readonly adminCoverTemplatesStore: AdminCoverTemplatesStore,
    private readonly adminCoverTemplatesService: AdminCoverTemplatesService,
  ) {
    super();
  }

  ngOnInit(): void {
    const templates$ = this.adminCoverTemplatesStore.getTemplatesObservable().pipe(
      map((v) => {
        return v.map((i) => {
          return <BookCoverTemplateExt>{
            ...i,
            previewUrl: this.adminCoverTemplatesService.getTemplatePreviewImageUrl(i.id!),
          };
        });
      }),
      map((v) => {
        v.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime());
        return v;
      }),
    );

    const activeTemplate$ = this.adminCoverTemplatesStore.getActiveTemplateObservable().pipe(
      map((v) => {
        if (v) {
          return <BookCoverTemplateExt>{
            ...v,
            previewUrl: this.adminCoverTemplatesService.getTemplatePreviewImageUrl(v.id!),
          };
        }
        return v;
      }),
    );

    this.data$ = combineLatest([templates$, activeTemplate$]).pipe(
      map(([t, a]) => ({ templates: t, activeTemplate: a })),
    );

    this.setTemplates();
  }

  private async setTemplates() {
    const templates = await this.adminCoverTemplatesService.getTemplates();
    this.adminCoverTemplatesStore.setTemplates(templates);
  }

  setDefault(v: any): any {}
}
