import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { I18nService } from "@metranpage/i18n";
import { NotificationPayloadPromocodeEnding } from "@metranpage/user-data";
import { DateTime } from "luxon";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-promocode-expire-card-view",
  templateUrl: "account-notification-promocode-expire-card.view.html",
  styleUrls: ["account-notification-promocode-expire-card.view.scss"],
})
export class AccountNotificationPromocodeExpireCardView extends AccountNotificationBaseCardView<NotificationPayloadPromocodeEnding> {
  protected locale = "en";

  constructor(
    private readonly i18nService: I18nService,
    private readonly router: Router,
  ) {
    super();
    this.locale = i18nService.getLocale();
  }

  protected getTitle() {
    let leftText = `${this.i18nService.pluralize($localize`:@@common.pluralize.left:`, {
      value: this.payload.daysBeforeNotice,
    })}`;
    leftText = leftText.charAt(0).toUpperCase() + leftText.slice(1);

    return `${leftText} ${this.payload.daysBeforeNotice} ${this.i18nService.pluralize(
      $localize`:@@pricing.pluralize-days:`,
      {
        days: this.payload.daysBeforeNotice,
      },
    )} ${$localize`:@@user.profile.notifications.card.promocode-expire.title:`}`;
  }

  protected getDateShort() {
    const date = this.payload.expireDate;
    return DateTime.fromISO(date.toString()).toLocaleString(DateTime.DATE_FULL, { locale: this.locale });
  }

  protected openTariffsPage() {
    this.router.navigate(["/payments/subscription"]);
  }
}
