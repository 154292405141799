import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "m-cover-conceptual-assistant-generation-result-font-card",
  templateUrl: "./cover-conceptual-assistant-generation-result-font-card.view.html",
  styleUrls: ["./cover-conceptual-assistant-generation-result-font-card.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverConceptualAssistantGenerationResultFontCardView implements OnInit {
  @Input()
  familyName!: string;
  @Input()
  color?: string;
  @Input()
  fontStyle: "main" | "subheading" | "secondary" = "main";
  @Input()
  comment?: string;

  protected fontStyleName = "";

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  private init() {
    this.updateFontGroupName();
    this.updateColor();
    this.cdr.detectChanges();
  }

  protected updateColor() {
    if (this.color && !this.color.includes("#")) {
      this.color = `#${this.color}`;
    }
  }

  protected updateFontGroupName() {
    this.fontStyleName = $localize`:@@cover-editor.conceptual-assistant.result.font.style.main:`;
    if (this.fontStyle === "subheading") {
      this.fontStyleName = $localize`:@@cover-editor.conceptual-assistant.result.font.style.subheading:`;
    }
    if (this.fontStyle === "secondary") {
      this.fontStyleName = $localize`:@@cover-editor.conceptual-assistant.result.font.style.secondary:`;
    }
  }
}
