<div *ngIf="isStartsOnPanelVisible()" class="starts-on-panel">
  <span class="text">{{ getStartsOnPanelText() }}</span>
</div>
<m-text-editor
  #textEditor
  [(ngModel)]="text"
  (onBlur)="onEditorBlur()"
  (footnoteAdded)="onFootnoteAdded($event)"
  (footnoteChanged)="onFootnoteChanged($event)"
  (onSplit)="onSplit.emit($event)"
  (onMergeWithPrev)="onMergeWithPrev.emit($event)"
  (blockMovement)="onBlockMovement($event)"
/>
