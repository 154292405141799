import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { slideInOutVertical } from "@metranpage/components";

export type SliderPoint = {
  value: string | number;
  text?: string;
};

@Component({
  selector: "m-slider-selector",
  templateUrl: "./slider-selector.view.html",
  styleUrls: ["./slider-selector.view.scss"],
  animations: [slideInOutVertical],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderSelectorView),
      multi: true,
    },
  ],
})
export class SliderSelectorView {
  @Input()
  points: SliderPoint[] = [];

  private onTouched = () => {};
  private onChange = (_: any) => {};

  protected value: string | number = "";
  protected index = 0;

  protected isDisabled = false;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  writeValue(value: number | string): void {
    this.index = this.getIndex(value);
    this.value = this.getValue(this.index);

    this.cdr.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInputChange(event: Event) {
    const index = Number.parseInt((event.target! as HTMLInputElement).value);
    this.onSelect(index);
  }

  onSelect(index: number) {
    this.index = index;
    this.value = this.getValue(index);
    this.onChange(this.value);
  }

  private getIndex(value: number | string) {
    return this.points.findIndex((p) => p.value === value);
  }

  private getValue(index: number) {
    return this.points[index].value;
  }
}
