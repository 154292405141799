<m-modal-backdrop
  [closeButtonVisible]="closeButtonVisible"
  [closeOnBackDropClick]="closeOnBackDropClick"
  (close)="onCloseClick()"
>
  <m-ng-island>
    <div class="content">
      <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
    </div>
  </m-ng-island>
</m-modal-backdrop>
