import { Injectable } from "@angular/core";
import { User } from "./models/user.models";

const LocalStorageKey = "metranpage-user";
/*
mpg_data - deprecated token
 TODO remove after some time
*/

/**
 * Storage used for long term (across different sessions) storage
 */
@Injectable({
  providedIn: "root",
})
export class UserStorage {
  saveUser(user: User) {
    localStorage.setItem(LocalStorageKey, JSON.stringify(user));
  }

  getUser(): User | undefined {
    let raw = localStorage.getItem(LocalStorageKey);
    if (!raw) {
      // check old token
      raw = localStorage.getItem("mpg_data");
      if (!raw) {
        return undefined;
      }
    }
    try {
      const user = JSON.parse(raw);
      if (this.isValidUser(user)) {
        return user;
      }
    } catch {
      // do nothing
    }
    return undefined;
  }

  clear() {
    localStorage.removeItem("mpg_data");
    localStorage.removeItem(LocalStorageKey);
  }

  setLastRoleNotificationDate(date: number) {
    localStorage.setItem("metranpage-role-notification-date", `${date}`);
  }

  getLastRoleNotificationDate(): number | undefined {
    const savedDate = localStorage.getItem("metranpage-role-notification-date");
    if (!savedDate) {
      return undefined;
    }
    return Number.parseInt(savedDate);
  }

  private isValidUser(user: User) {
    if (!user.id) {
      return false;
    }
    return true;
  }
}
