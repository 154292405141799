import { Component, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TocPosition } from "@metranpage/book-data";

@Component({
  selector: "m-books-markup-sidebar-toc-settings-buttons-column",
  templateUrl: "./markup-sidebar-toc-settings-buttons.view.html",
  styleUrls: ["./markup-sidebar-toc-settings-buttons.view.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkupSidebarTocSettingsButtonsColumn),
      multi: true,
    },
  ],
})
export class MarkupSidebarTocSettingsButtonsColumn implements ControlValueAccessor {
  value: TocPosition = "start";

  private onTouched = () => {};
  private onChange = (_: any) => {};

  writeValue(value: TocPosition): void {
    this.value = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  select(value: TocPosition) {
    this.value = value;
    this.onChange(value);
  }
}
