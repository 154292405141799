<div class="container">
  <div class="color-format-selector">
    <m-color-format-select
      [color-format]="selectedColorFormat"
      [options]="colorFormatOptions"
      (onSelect)="changeColorFormat($event)"
    />
  </div>

  <form *ngIf="selectedColorFormat === 'rgb'" [formGroup]="formRGB" class="inputs format-3">
    <m-ng-input-number  formControlName="r" size="32" text-align="center" />
    <m-ng-input-number  formControlName="g" size="32" text-align="center" />
    <m-ng-input-number  formControlName="b" size="32" text-align="center" />
  </form>

  <form *ngIf="selectedColorFormat === 'hsl'" [formGroup]="formHSL" class="inputs format-3">
    <m-ng-input-number  formControlName="h" size="32" text-align="center" />
    <m-ng-input-number  formControlName="s" size="32" text-align="center" />
    <m-ng-input-number  formControlName="l" size="32" text-align="center" />
  </form>

  <form *ngIf="selectedColorFormat === 'hsv'" [formGroup]="formHSV" class="inputs format-3">
    <m-ng-input-number  formControlName="h" size="32" text-align="center" />
    <m-ng-input-number  formControlName="s" size="32" text-align="center" />
    <m-ng-input-number  formControlName="v" size="32" text-align="center" />
  </form>

  <form *ngIf="selectedColorFormat === 'hex'" [formGroup]="formHEX" class="inputs format-1">
    <m-ng-input formControlName="hex"  size="32" text-align="center" />
  </form>

  <form *ngIf="selectedColorFormat === 'cmyk'" [formGroup]="formCMYK" class="inputs format-4">
    <m-ng-input-number  formControlName="c" size="32" text-align="center" />
    <m-ng-input-number  formControlName="m" size="32" text-align="center" />
    <m-ng-input-number  formControlName="y" size="32" text-align="center" />
    <m-ng-input-number  formControlName="k" size="32" text-align="center" />
  </form>
</div>
