import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Book, BookSize } from "@metranpage/book-data";
import { CompanyStore } from "@metranpage/company";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import {
  AnalyticsService,
  LoadingService,
  NotificationsPopUpService,
  RouterService,
  filterUndefined,
} from "@metranpage/core";
import { Format, FormatData, FormatService, FormatStore } from "@metranpage/format-data";
import { Subscription, filter, first, switchMap, tap } from "rxjs";
import { BookService } from "../../services/book.service";
import { BooksStore } from "../../services/books.store";

@Component({
  selector: "m-modal-cover-data",
  templateUrl: "modal-cover-data.view.html",
  styleUrls: ["modal-cover-data.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class ModalCoverDataView implements AfterViewInit, OnDestroy, OnInit {
  protected book!: Book;
  protected bookSize!: BookSize;

  protected bookForm?: FormGroup;
  protected customFormatForm?: FormGroup;

  protected formatData?: FormatData;

  protected formats: Format[] = [
    {
      id: 1,
      companyId: 1,
      isAvailableToUser: true,
      isVisibleToOtherCompanies: true,
      type: "book",
      name: "a4",
      width: 210,
      height: 297,
      order: 1,
      title: "А4",
    },
    {
      id: 2,
      companyId: 1,
      isAvailableToUser: true,
      isVisibleToOtherCompanies: true,
      type: "book",
      name: "a5",
      width: 148,
      height: 210,
      order: 2,
      title: "А5",
    },
    {
      id: 3,
      companyId: 1,
      isAvailableToUser: true,
      isVisibleToOtherCompanies: true,
      type: "book",
      name: "custom",
      width: 148,
      height: 148,
      order: 3,
      title: $localize`:@@cover.modal.create.custom-format:`,
    },
  ];
  protected selectedFormat: Format | undefined = undefined;

  sub: Subscription = new Subscription();

  constructor(
    private readonly bookService: BookService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly routerService: RouterService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly booksStore: BooksStore,
    private readonly formatService: FormatService,
    private readonly formatStore: FormatStore,
    private readonly companyStore: CompanyStore,
    private readonly analytics: AnalyticsService,
  ) {}

  ngOnInit() {
    this.createBookForm();
    this.createFormatForm();
  }

  async ngAfterViewInit() {
    this.sub.add(
      this.formatStore
        .getFormatsObservable()
        .pipe(
          filterUndefined(),
          filter((formats) => formats.length > 0),
          first(),
          tap((formats) => {
            // this.formats = formats;
            // this.selectedFormat = this.formats[0]
          }),
          switchMap(() => this.companyStore.getCompanyObservable()),
          filterUndefined(),
          tap((company) => {}),
          switchMap(() => this.booksStore.getModalBookObservable()),
          filterUndefined(),
        )
        .subscribe(async (book) => {
          await this.init(book);
        }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async init(book: Book) {
    this.book = book;

    this.bookSize = {
      width: Number(this.routerService.getRouteParam(this.route.snapshot, "width")),
      height: Number(this.routerService.getRouteParam(this.route.snapshot, "height")),
    };

    this.formatData = this.formatService.getFormatData(this.bookSize.width, this.bookSize.height);

    this.selectDefaultFormat();

    this.cdr.markForCheck();
  }

  protected selectDefaultFormat() {
    const format = this.formats.find((f) => f.name === this.formatData?.format);
    this.selectedFormat = format || this.formats[0];

    this.customFormatForm?.setValue({ width: this.bookSize.width, height: this.bookSize.height });
  }

  protected async onCreateClick() {
    if (this.isCreateButtonDisabled()) {
      return;
    }

    this.analytics.event("new-cover-form");

    if (this.selectedFormat?.name === "custom") {
      if (!this.customFormatForm) {
        return;
      }

      this.bookSize = this.customFormatForm.getRawValue();
    }

    this.notificationService.closeAll();

    this.loadingService.startLoading({ fullPage: true });

    await this.bookService.setBookSize(this.book, this.bookSize);

    const data = this.bookForm!.getRawValue();
    const result = await this.bookService.updateBookInfo(this.book, data);
    this.loadingService.stopLoading();

    if (result !== "success") {
      this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
      return;
    }

    this.router.navigateByUrl(`books/${this.book.id}/cover`);
  }

  protected selectFormat(format: Format) {
    this.selectedFormat = format;

    if (this.selectedFormat.name !== "custom") {
      this.bookSize.width = format.width;
      this.bookSize.height = format.height;
    }
  }

  createBookForm() {
    this.bookForm = new FormGroup({
      title: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      authorFirstName: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      authorLastName: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  protected isCreateButtonDisabled(): boolean {
    return (
      !this.bookForm ||
      !this.bookForm.valid ||
      (this.selectedFormat?.name === "custom" && !this.customFormatForm?.valid)
    );
  }

  createFormatForm() {
    this.customFormatForm = this.formatService.createFormatForm();
  }

  trackByFormatId(index: number, format: Format) {
    return format.id;
  }
}
