import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, ActivationEnd, Router } from "@angular/router";
import { Book, BookDataStep, BookSize, TemplateWithPreview } from "@metranpage/book-data";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import {
  AnalyticsService,
  IS_COVER_EDITOR_ENABLED,
  LoadingService,
  NotificationsPopUpService,
  RouterService,
  filterUndefined,
} from "@metranpage/core";
import { FormatData, FormatService, FormatStore } from "@metranpage/format-data";
import { OnboardingService } from "@metranpage/onboarding";
import { ThemeService } from "@metranpage/theme";
import { UserStore } from "@metranpage/user-data";
import { Subject, Subscription, filter, first, switchMap } from "rxjs";
import { BookModalEventBus } from "../../services/book-modal-event-bus.service";
import { BookRouterService, ModalType } from "../../services/book-router.service";
import { BookService } from "../../services/book.service";
import { BooksStore } from "../../services/books.store";
import { TemplateFilterSelectorType } from "../template-filter-selector/template-filter-selector.view";

@Component({
  selector: "m-modal-book-templates",
  templateUrl: "modal-book-templates.view.html",
  styleUrls: ["modal-book-templates.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class ModalBookTemplatesView implements AfterViewInit, OnDestroy {
  @Input()
  runInEditMode = false;

  protected bookFormatName?: string;

  book!: Book;
  bookSize!: BookSize;

  allTemplates: TemplateWithPreview[];
  templates: Subject<TemplateWithPreview[]>;

  activeFilter: TemplateFilterSelectorType = "all";

  protected hasTable = false;

  protected selectedTemplateId?: number;
  protected isSelectedNonEditableTemplate = false;
  protected hasCustomStylesInSelectedTemplate = false;

  protected isCustomFormatFormVisible = false;

  onboardingStarted = false;

  protected isErrorModalVisible = false;
  protected isFileLimitModalVisible = false;
  protected errorText = "";
  protected bookDataStep: BookDataStep = "general";

  protected orientationIconSrc = "";
  protected orientation: "hor" | "ver" = "ver";

  protected formFormat: FormGroup;

  protected formatData?: FormatData;

  protected hasCustomBookFormat = false;

  sub: Subscription = new Subscription();

  constructor(
    private readonly route: ActivatedRoute,
    @Inject(IS_COVER_EDITOR_ENABLED) protected readonly isCoverEditorEnabled: boolean,
    private readonly bookService: BookService,
    private readonly bookRouterService: BookRouterService,
    private readonly bookModalEventBus: BookModalEventBus,
    private readonly userStore: UserStore,
    private readonly routerService: RouterService,
    private readonly themeService: ThemeService,
    private readonly onboardingService: OnboardingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly formatService: FormatService,
    private readonly formatStore: FormatStore,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly booksStore: BooksStore,
    private readonly analytics: AnalyticsService,
  ) {
    this.allTemplates = [];
    this.templates = new Subject();

    this.formFormat = formatService.createFormatForm();
  }

  async ngAfterViewInit() {
    if (this.runInEditMode) {
      this.analytics.event("popup-edit-project");
    } else {
      this.analytics.event("popup-new-project");
    }

    this.sub.add(
      this.formatStore
        .getFormatsObservable()
        .pipe(
          filterUndefined(),
          filter((formats) => formats.length > 0),
          first(),
          switchMap(() => this.booksStore.getModalBookObservable()),
          filterUndefined(),
        )
        .subscribe(async (book) => {
          await this.init(book);
        }),
    );

    this.sub.add(
      this.router.events.subscribe((data) => {
        if (data instanceof ActivationEnd) {
          this.updateBookSize();
          this.selectSuitableTemplates();
        }
      }),
    );

    this.sub.add(
      this.formFormat.valueChanges.subscribe((value) => {
        this.formatData = this.formatService.getFormatData(value.width, value.height);
        this.navigateToSelectedFormat(this.formatData);
      }),
    );
  }

  async init(b: Book) {
    this.book = b;
    this.bookSize = {
      width: b.bookSettings?.width || -1,
      height: b.bookSettings?.height || -1,
    };

    this.hasTable = !!b.bookFeatures?.hasTables;
    this.hasCustomStylesInSelectedTemplate = b.hasCustomStylesInSelectedTemplate;

    this.updateBookSize();

    this.formatData = this.formatService.getFormatData(this.bookSize.width, this.bookSize.height);
    if (this.formatData.format !== this.bookFormatName) {
      this.navigateToSelectedFormat(this.formatData);
    }

    this.hasCustomBookFormat = this.formatService.hasCustomFormat("book");

    this.loadingService.startLoading({ fullPage: true });
    this.allTemplates = await this.bookService.getTemplates();
    this.selectSuitableTemplates();
    this.loadingService.stopLoading();

    this.updateOrientationIconSrc();

    this.startOnboarding();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  startOnboarding(showForced = false) {
    if (this.isCoverAvailable() && (!this.onboardingStarted || showForced)) {
      this.onboardingStarted = true;
      this.onboardingService.startOnboarding("modal-book-templates", showForced);
    }
  }

  updateBookSize() {
    this.bookSize = {
      width: Number(this.routerService.getRouteParam(this.route.snapshot, "width")),
      height: Number(this.routerService.getRouteParam(this.route.snapshot, "height")),
    };

    this.bookFormatName = this.formatService.getFormatNameBySize(this.bookSize.width, this.bookSize.height);

    this.formFormat.setValue(this.bookSize);
  }

  navigateToSelectedFormat(formatData: FormatData) {
    let type: ModalType = "new";

    if (this.runInEditMode) {
      type = "edit";
    }

    this.bookRouterService.showModal(this.book, "templates", type, formatData);
  }

  selectSuitableTemplates() {
    if (!this.bookSize) {
      this.templates.next(this.allTemplates);
    } else {
      this.templates.next(
        this.allTemplates.filter((template) => {
          let widthFrom = this.bookSize.width;
          let widthTo = this.bookSize.width;
          let heightFrom = this.bookSize.height;
          let heightTo = this.bookSize.height;
          if (!this.hasCustomBookFormat) {
            const sizeRange = this.formatService.getBookSizeRange("book");
            widthFrom = sizeRange.widthFrom;
            widthTo = sizeRange.widthTo;
            heightFrom = sizeRange.heightFrom;
            heightTo = sizeRange.heightTo;
          }

          if (
            (widthFrom >= template.widthFrom &&
              widthTo <= template.widthTo &&
              heightFrom >= template.heightFrom &&
              heightTo <= template.heightTo) ||
            !template.isEditable
          ) {
            if (this.activeFilter === "editable" && template.isEditable) {
              return this.formFormat.valid;
              // return true
            }
            if (this.activeFilter === "non-editable" && !template.isEditable) {
              return true;
            }
            if (this.activeFilter === "all") {
              return !template.isEditable || (template.isEditable && this.formFormat.valid);
              // return true
            }
          }
          return false;
        }),
      );
    }
  }

  async onSelectTemplateClick(template: TemplateWithPreview | undefined = undefined) {
    if (!template) {
      if (!this.hasCustomStylesInSelectedTemplate) {
        await this.selectDefaultTemplate();
        return;
      }

      this.selectedTemplateId = -1;
      this.isSelectedNonEditableTemplate = false;
      return;
    }

    if (!this.hasCustomStylesInSelectedTemplate && template.isEditable) {
      await this.selectTemplate(template.id);
      return;
    }

    this.selectedTemplateId = template.id;
    this.isSelectedNonEditableTemplate = !template.isEditable;
  }

  async selectTemplate(templateId: number) {
    if (this.runInEditMode) {
      this.analytics.event("edit-project-template-choose", { templateId });
    } else {
      this.analytics.event("new-project-template-choose", { templateId });
    }

    if (templateId === -1) {
      await this.selectDefaultTemplate();
      return;
    }

    this.notificationService.closeAll();

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.bookService.setBookSizeAndTemplate(this.book, this.bookSize, templateId);

    this.loadingService.stopLoading();

    if (result === "success") {
      if (this.runInEditMode) {
        this.bookModalEventBus.onTemplateSelected();
        // this.bookRouterService.closeModal();
        return;
      }

      this.bookRouterService.showModal(this.book, "upload", "new");
    } else if (result === "template-not-suitable-for-uploaded-document") {
      this.notificationService.error(
        $localize`:@@books.template.select-template.template-not-suitable-for-uploaded-document:`,
      );
    } else {
      this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
    }
  }

  async selectDefaultTemplate() {
    if (this.runInEditMode) {
      this.analytics.event("edit-project-blank");
    } else {
      this.analytics.event("new-project-blank");
    }

    this.notificationService.closeAll();

    this.loadingService.startLoading({ fullPage: true });
    await this.bookService.setBookSize(this.book, this.bookSize);
    const result = await this.bookService.setDefaultTemplate(this.book);
    this.loadingService.stopLoading();

    if (result === "success") {
      if (this.runInEditMode) {
        this.bookModalEventBus.onTemplateSelected();
        return;
      }

      this.bookRouterService.showModal(this.book, "upload", "new", this.formatData);
    } else {
      this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
    }
  }

  async previewTemplate(templateId: number) {
    if (this.runInEditMode) {
      this.analytics.event("edit-project-template-more-info", { templateId });
    } else {
      this.analytics.event("new-project-template-more-info", { templateId });
    }

    this.notificationService.closeAll();

    let type: ModalType = "new";

    if (this.runInEditMode) {
      type = "edit";
    }

    this.bookRouterService.showModal(this.book, "template-preview", type, this.formatData, templateId);
  }

  trackTemplate(_index: number, item: TemplateWithPreview) {
    return item.id;
  }

  protected onCloseSelectNonEditableTemplateModal() {
    this.selectedTemplateId = undefined;
    this.isSelectedNonEditableTemplate = false;
  }

  protected onTemplateEditableTypeSelect(filter: TemplateFilterSelectorType) {
    this.activeFilter = filter;
    this.selectSuitableTemplates();
  }

  async onCoverSelectClick() {
    this.analytics.event("new-project-cover");

    this.notificationService.closeAll();

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.bookService.setBookSizeAndType(this.book, {
      ...this.bookSize,
      exportPrint: true,
      exportEpub: false,
    });
    this.loadingService.stopLoading();

    if (result === "success") {
      this.showBookDataModalOrGoToCover();
    } else {
      this.notificationService.error($localize`:@@books.error.cant-edit-book:`);
    }
  }

  showBookDataModalOrGoToCover() {
    const book = this.book;
    if (!book.title || !book.authorLastName) {
      this.bookRouterService.showModal(book, "book-data-before-cover", "new");
      return;
    }

    this.router.navigateByUrl(`books/${book.id}/cover`);
  }

  onChangeOrientationClick() {
    const value = this.formFormat.value;
    this.formFormat.setValue({
      width: value.height || 0,
      height: value.width || 0,
    });
    this.updateOrientation();
  }

  updateOrientation() {
    if (this.orientation === "hor") {
      this.orientation = "ver";
    } else {
      this.orientation = "hor";
    }

    this.updateOrientationIconSrc();
  }

  isCoverAvailable() {
    return this.formFormat.valid && !this.book?.coverId && !this.runInEditMode && this.isCoverEditorEnabled;
  }

  isDefaultTemplateAvailable() {
    return this.formFormat.valid && this.userStore.isUserHasPremium();
  }

  updateOrientationIconSrc() {
    const theme = this.themeService.getTheme();
    this.orientationIconSrc = `format-orientation-${this.orientation}-${theme}.svg`;

    this.cdr.markForCheck();
  }

  protected isSelectDisable(template: TemplateWithPreview) {
    return this.hasTablesInMultiColumnTemplate(template);
  }

  protected hasTablesInMultiColumnTemplate(template: TemplateWithPreview) {
    return template?.columnsCount > 1 && !!this.book?.bookFeatures?.hasTables;
  }
}
