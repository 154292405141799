import { Injectable } from "@angular/core";
import { config as companyConfig } from "@environments/company-config";
import { environment } from "@environments/environment";
import { Config, ConfigService } from "@metranpage/core-interfaces";

@Injectable({ providedIn: "root" })
export class ConfigServiceImpl implements ConfigService {
  private config: Config;

  constructor() {
    this.config = this.collectConfig();
  }

  private collectConfig(): Config {
    return {
      environment: environment,
      company: companyConfig,
    };
  }

  getConfig(): Config {
    return this.config;
  }

  getApiUrl(): string {
    return this.config.environment.apiRootUrl;
  }

  getFilesUrl(): string {
    return this.config.environment.filesUrl;
  }

  getDeployedUrl(): string {
    return this.config.company.deployedUrl;
  }
}
