import { Injectable } from "@angular/core";
import { Book, BookResultUpdate, BookUpdate, ScriptsSettings } from "@metranpage/book-data";
import { Store } from "@metranpage/state";

export type State = {
  books: Book[];
  activeBook: Book | undefined;
  modalBook: Book | undefined;
  scriptsSettings: ScriptsSettings | undefined;
  booksPageCount: number;
};

@Injectable({
  providedIn: "root",
})
export class BooksStore extends Store<State> {
  protected override getInitialState(): State {
    return { books: [], activeBook: undefined, modalBook: undefined, scriptsSettings: undefined, booksPageCount: 1 };
  }

  getBooksObservable() {
    return this.getFieldObservable("books");
  }

  getBooks() {
    return this.getField("books");
  }

  setBooks(books: Book[]) {
    this.update((state) => ({ ...state, books }));
  }

  addBook(book: Book) {
    this.update((state) => ({ ...state, books: state.books.concat([book]) }));
  }

  deleteBook(book: Book) {
    this.update((state) => ({ ...state, books: state.books.filter((b) => b.id !== book.id) }));
  }

  updateBook(updatedBook: Book) {
    this.update((state) => {
      const updatedBooks = state.books.map((book) => {
        if (book.id === updatedBook.id) {
          return updatedBook;
        }
        return book;
      });

      return {
        ...state,
        books: updatedBooks,
      };
    });

    const activeBook = this.getField("activeBook");
    if (updatedBook.id === activeBook?.id) {
      this.update((state) => {
        return {
          ...state,
          activeBook: updatedBook,
        };
      });
    }
  }

  updateBookState(stateUpdate: BookUpdate) {
    const book = this.getBookById(stateUpdate.bookId);
    if (!book) {
      console.error("update event on not exising book", stateUpdate.bookId);
      return;
    }
    const updated: Book = {
      ...book,
      actionKey: stateUpdate.currentAction,
    };
    this.updateBook(updated);
  }

  updateLayoutStep(stateUpdate: BookResultUpdate) {
    const book = this.getBookById(stateUpdate.bookId);
    if (!book) {
      console.error("update event on not exising book", stateUpdate.bookId);
      return;
    }
    if (book.bookResults) {
      const updated: Book = {
        ...book,
        bookResults: { ...book.bookResults, layoutStep: stateUpdate.layoutStep },
      };
      this.updateBook(updated);
    }
  }

  private getBookById(bookId: number) {
    return this.getField("books").find((b) => b.id === bookId);
  }

  hasBook(bookId: number) {
    return !!this.getField("books").find((b) => b.id === bookId);
  }

  /**
   * Use observable version over getField, as active book can be not loaded yet
   */
  getActiveBookObservable() {
    return this.getFieldObservable("activeBook");
  }

  getActiveBook() {
    return this.getField("activeBook");
  }

  setActiveBook(bookId: number) {
    this.update((state) => {
      return {
        ...state,
        activeBook: state.books.find((b) => b.id === bookId),
      };
    });
  }

  getModalBookObservable() {
    return this.getFieldObservable("modalBook");
  }

  getModalBook() {
    return this.getField("modalBook");
  }

  setModalBook(bookId: number) {
    this.update((state) => {
      return {
        ...state,
        modalBook: state.books.find((b) => b.id === bookId),
      };
    });
  }

  getScriptsSettings() {
    return this.getField("scriptsSettings");
  }

  getScriptsSettingsObservable() {
    return this.getFieldObservable("scriptsSettings");
  }

  updateScriptsSettings(settings: ScriptsSettings) {
    this.update((state) => ({ ...state, scriptsSettings: settings }));
  }

  getBookPrice(bookId: number) {
    return (
      this.getField("books").find((b) => b.id === bookId)?.bookPrice ?? { total: 0, print: 0, epub: 0, printAndEpub: 0 }
    );
  }

  getBooksPageCountObservable() {
    return this.getFieldObservable("booksPageCount");
  }

  setBooksPageCount(count: number) {
    this.update((state) => ({ ...state, booksPageCount: count }));
  }

  addBooksToEnd(books: Book[]) {
    this.update((state) => {
      const newBooks: Book[] = [];
      for (const book of books) {
        const storeBooks = state.books.find((b) => b.id === book.id);
        if (storeBooks) {
          continue;
        }
        newBooks.push(book);
      }

      return {
        ...state,
        books: state.books.concat(newBooks),
      };
    });
  }
}
