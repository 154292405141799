<m-modal-backdrop (close)="onCloseClick()">
  <div class="container">
    <img [src]="getImageUrl()" />

    <div class="context">
      <m-localized-text class="header" text="welcome.webinar-1.header" i18n-text="@@welcome.webinar-1.header" />

      <div class="group">
        <span *ngIf="isWebinarToday" class="group-title" i18n="@@welcome.webinar-1.today.text">
          welcome.webinar-1.today.text
        </span>
        <span *ngIf="!isWebinarToday" class="group-title" i18n="@@welcome.webinar-1.tomorrow.text">
          welcome.webinar-1.tomorrow.text
        </span>

        <ul>
          <li i18n="@@welcome.webinar-1.list.1">welcome.webinar-1.list.1</li>
          <li i18n="@@welcome.webinar-1.list.2">welcome.webinar-1.list.2</li>
          <li i18n="@@welcome.webinar-1.list.3">welcome.webinar-1.list.3</li>
        </ul>
      </div>

      <span class="text">
        <span class="author-name" i18n="@@welcome.webinar-1.author-name">welcome.webinar-1.author-name</span>
        <span i18n="@@welcome.webinar-1.author-text">welcome.webinar-1.author-text</span>
      </span>

      <span class="text" i18n="@@welcome.webinar-1.gift">welcome.webinar-1.gift</span>

      <m-button
        class="button"
        button-style="primary"
        icon-position="right"
        icon="button-arrow-right.svg"
        i18n="@@welcome.webinar-1.button"
        (onClick)="onActionClick()"
      >
        welcome.webinar-1.button
      </m-button>
    </div>
  </div>
</m-modal-backdrop>
