import { Component, EventEmitter, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { PricingViewService } from "@metranpage/pricing";
import { TariffCreateData } from "../../models/tariff";
import { AdminTariffsService } from "../../services/tariffs/tariffs.service";

@Component({
  selector: "m-admin-add-tariff-modal",
  templateUrl: "./add-tariff.view.html",
  styleUrls: ["./add-tariff.view.scss"],
})
export class AddTariffView {
  @Output()
  onTariffAdded = new EventEmitter<TariffCreateData>();
  @Output()
  onCancelAddition = new EventEmitter<void>();

  protected form!: FormGroup;

  constructor(
    private readonly pricingViewService: PricingViewService,
    private readonly adminTariffsService: AdminTariffsService,
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.adminTariffsService.createTariffForm();
  }

  createTariff() {
    this.onTariffAdded.emit(this.form.getRawValue());
  }

  onCancel() {
    this.onCancelAddition.emit();
  }

  protected getOptionsForCurrencySelect() {
    return this.pricingViewService.getOptionsForCurrencySelect();
  }

  protected getOptionsForSubtitleSelect() {
    return this.pricingViewService.getOptionsForSubtitleSelect();
  }
}
