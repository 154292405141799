import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CoverService } from "@metranpage/book";
import { BookCover } from "@metranpage/book-data";

@Component({
  selector: "m-moderation-cover-preview",
  templateUrl: "./moderation-cover-preview.view.html",
  styleUrls: ["./moderation-cover-preview.view.scss"],
})
export class ModerationCoverPreviewView {
  @Input()
  bookCover?: BookCover;
  @Input()
  paddingSize = 16;
  @Input()
  hasPrevious = true;
  @Input()
  hasNext = true;

  @Output()
  onPrevious = new EventEmitter();
  @Output()
  onNext = new EventEmitter();

  protected coverPreviewImageUrl?: string;

  constructor(private readonly coverService: CoverService) {}

  ngOnChanges() {
    this.getUrlForImage();
  }

  protected onPreviousClick() {
    this.onPrevious.emit();
  }

  protected onNextClick() {
    this.onNext.emit();
  }

  protected getUrlForImage() {
    const bookId = this.bookCover?.book?.id;
    if (!bookId) {
      return;
    }
    this.coverPreviewImageUrl = this.coverService.getCoverPreviewImageUrl(bookId);
  }
}
