<div
  class="editor"
  #editor
  contenteditable="true"
  i18n-data-placeholder="@@editor.placeholder"
  data-placeholder="@@editor.placeholder"
  (input)="onChangeHandle($event)"
  (blur)="onBlurHandle($event)"
  (focus)="onFocusHandle($event)"
  (dragenter)="onDragEnterHandle($event)"
  (drop)="onDropHandle($event)"
  (keypress)="onKeyPressHandle($event)"
  (keydown)="onKeyDownHandle($event)"
  (paste)="onPasteHandle($event)"
></div>
