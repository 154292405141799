import { Injectable } from "@angular/core";
import { NotificationsPopUpService, RealtimeService, filterUndefined } from "@metranpage/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { Notification, NotificationApi, NotificationStore, TestNotificationData } from "@metranpage/user-data";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  protected soundFile?: HTMLAudioElement;

  constructor(
    private readonly notificationStore: NotificationStore,
    private readonly notificationService: NotificationsPopUpService,
    private readonly notificationApi: NotificationApi,
    realtimeService: RealtimeService,
  ) {
    realtimeService
      .getEvents<Notification>("notification-update")
      .pipe(filterUndefined())
      .subscribe((notificationUpdate: Notification) => {
        if (notificationUpdate) {
          this.notificationStore.updateNotification(notificationUpdate);

          if (!notificationUpdate.payload?.muted) {
            this.playNotificationSound();
          }
        }
      });

    this.loadSound();
  }

  async getUnreadNotificationsCount() {
    const unreadNotificationsCount = await this.notificationApi.getUnreadNotificationsCount();
    this.notificationStore.setUnreadNotificationsCount(unreadNotificationsCount);
  }

  async loadNotificationsPaginated(page: number) {
    const notificationsData = await this.notificationApi.loadNotificationPaginated(page);
    this.notificationStore.addNotificationsToEnd(notificationsData.items);
    this.notificationStore.setNotificationsPageCount(notificationsData.pageCount);
  }

  async markNotificationsAsRead(): Promise<GeneralResultStatus> {
    try {
      const result = await this.notificationApi.markNotificationsAsRead();
      // this.markStoreNotificationsAsRead();
      return "success";
    } catch (error: unknown) {
      return "error";
    }
  }

  markStoreNotificationsAsRead() {
    const notifications = this.notificationStore.getNotifications();
    for (const n of notifications) {
      n.isShown = true;
    }
    this.notificationStore.setNotifications(notifications);
    this.notificationStore.setUnreadNotificationsCount(0);
  }

  async notifyOnEmailVerification() {
    await this.notificationApi.notifyOnEmailVerification();
  }

  async testNotification(data: TestNotificationData) {
    await this.notificationApi.testNotification(data);
  }

  loadSound() {
    this.soundFile = new Audio();
    this.soundFile.src = "./assets/sounds/notification.mp3";
    this.soundFile.load();
  }

  playNotificationSound() {
    if (!this.soundFile) {
      return;
    }
    this.soundFile.play();
  }
}
