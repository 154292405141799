<m-auth-cover-view [colorTheme]="colorTheme" (onColorThemeToggle)="onColorThemeToggle()" />

<m-registration-view
  [errors]="loginErrors"
  [isLoading]="(loadingState$ | async)?.isActive  ?? false"
  [isVkLoginAvailable]="isVkLoginAvailable"
  [isGoogleLoginAvailable]="isGoogleLoginAvailable"
  [userLanguage]="userLanguage"
  (onVkLoginClick)="onLoginVk()"
  (onGoogleLoginClick)="onLoginGoogle()"
  (onRegisterClick)="onRegistration($event)"
  (onLangChangeClick)="onLangChange($event)"
></m-registration-view>

<script type="text/javascript">
  !(function () {
    var t = document.createElement('script');
    (t.type = 'text/javascript'),
      (t.async = !0),
      (t.src = 'https://vk.com/js/api/openapi.js?169'),
      (t.onload = function () {
        VK.Retargeting.Init('VK-RTRG-1423432-2Bes3'), VK.Retargeting.Hit();
      }),
      document.head.appendChild(t);
  })();
</script>
<noscript>
  <img src="https://vk.com/rtrg?p=VK-RTRG-1423432-2Bes3" style="position: fixed; left: -999px" alt="" />
</noscript>
