import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Dimensions } from "@metranpage/book-data";

@Component({
  selector: "m-preview-frame",
  templateUrl: "./preview-frame.component.html",
  styleUrls: ["./preview-frame.component.scss"],
})
export class PreviewFrameComponent implements AfterViewInit {
  @Input("page-size")
  pageSize!: Dimensions;
  @ViewChild("page")
  page!: ElementRef;

  @Output()
  onPagePreviewChange = new EventEmitter<Dimensions>();

  protected viewPageSize: Dimensions = { width: 0, height: 0 };

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.onResize();
  }

  onResize(event?: Event) {
    if (this.pageSize) {
      const k = this.pageSize.width / this.pageSize.height;

      let width = this.elementRef.nativeElement.offsetWidth;
      let height = this.elementRef.nativeElement.offsetHeight;

      if (width / height >= k) {
        width = height * k;
      } else {
        height = width / k;
      }

      this.page.nativeElement.style.width = `${width}px`;
      this.page.nativeElement.style.height = `${height}px`;

      this.viewPageSize.width = width;
      this.viewPageSize.height = height;

      this.onPagePreviewChange.emit(this.viewPageSize);
    }
  }
}
