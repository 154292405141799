import { ChangeDetectorRef, Component, QueryList, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FileDropComponent, LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { NgxFileDropEntry } from "ngx-file-drop";
import { CompanyEditionNoticeSettings, CompanyEditionNoticeSettingsUpdateData } from "../../models/company";
import { AdminCompanyEditionNoticeSettingsService } from "../../services/edition-notice-settings/companies-edition-notice-settings.service";

export type EditionNoticeFileType = "template" | "template-small" | "template-preview";

@Component({
  selector: "m-admin-edition-notice-settings-page",
  templateUrl: "./edition-notice-settings.page.html",
  styleUrls: ["./edition-notice-settings.page.scss"],
})
export class AdminCompanyEditionNoticeSettingsPage {
  protected settings: CompanyEditionNoticeSettings[] = [];

  protected form?: FormGroup;

  constructor(
    private readonly service: AdminCompanyEditionNoticeSettingsService,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  @ViewChildren("filedropTemplate", { read: FileDropComponent })
  fileDropTemplateComponents!: QueryList<FileDropComponent>;

  @ViewChildren("filedropTemplateSmall", { read: FileDropComponent })
  fileDropTemplateSmallComponents!: QueryList<FileDropComponent>;

  @ViewChildren("filedropPreview", { read: FileDropComponent })
  fileDropPreviewComponents!: QueryList<FileDropComponent>;

  async ngOnInit(): Promise<void> {
    await this.updateSettings();
    this.createForm();
    this.setFormValue();
  }

  createForm() {
    const sectionsControls: Record<string, any> = {};
    for (const section of this.settings) {
      sectionsControls[section.lang] = this.formBuilder.group({
        legalName: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),
        projectName: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),
        projectDescription: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),
      });
    }
    this.form = this.formBuilder.group(sectionsControls);
  }

  protected setFormValue() {
    const formData: { [key: string]: Partial<CompanyEditionNoticeSettings> } = {};
    for (const s of this.settings) {
      formData[s.lang] = {
        legalName: s.legalName,
        projectName: s.projectName,
        projectDescription: s.projectDescription,
      };
    }
    this.form?.patchValue(formData);
  }

  protected async onSaveClick() {
    const formData = this.form?.getRawValue();
    const data: CompanyEditionNoticeSettingsUpdateData[] = [];

    for (const lang of Object.keys(formData)) {
      const valueLoc = formData[lang];
      data.push({
        ...valueLoc,
        lang,
      });
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.updateEditionNoticeData(data);
    this.loadingService.stopLoading();
    if (result !== "success") {
      this.notificationService.error($localize`:@@admin.templates.template.error.cant-update-template:`);
    }
  }

  dropped(files: NgxFileDropEntry[], lang: string, type: EditionNoticeFileType) {
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) => {
        this.notificationService.closeAll();
        this.loadingService.startLoading({ fullPage: true });
        const result = await this.service.uploadEditionNoticeFile(file, lang, type);
        this.loadingService.stopLoading();
        if (result === "success") {
          await this.updateSettings();
        } else if (result === "error") {
          this.notificationService.error($localize`:@@admin.templates.template.error.cant-upload-preview:`);
        }
      });
    }
  }

  async deleteFile(settings: CompanyEditionNoticeSettings, type: EditionNoticeFileType) {
    this.notificationService.closeAll();

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.service.deleteEditionNoticeFile(settings.lang, type);
    this.loadingService.stopLoading();
    if (result === "success") {
      this.updateSettings();
    } else if (result === "error") {
      this.notificationService.error($localize`:@@admin.templates.template.error.cant-upload-preview:`);
    }
  }

  openFileSelector(index: number, type: EditionNoticeFileType) {
    let fileDropComponent: FileDropComponent | undefined = undefined;
    if (type === "template") {
      fileDropComponent = this.fileDropTemplateComponents.find((_, i) => i === index);
    }
    if (type === "template-small") {
      fileDropComponent = this.fileDropTemplateSmallComponents.find((_, i) => i === index);
    }
    if (type === "template-preview") {
      fileDropComponent = this.fileDropPreviewComponents.find((_, i) => i === index);
    }

    if (!fileDropComponent) {
      return;
    }

    fileDropComponent.openFileSelector();
  }

  protected async updateSettings() {
    const settings = await this.service.getEditionNoticeSettings();
    this.settings = settings.reverse();
  }

  protected hasEmptyField() {
    for (const s of this.settings) {
      if (!s.preview || !s.templateSrc || !s.templateSmallSrc) {
        return true;
      }
    }
    return false;
  }
}
