import { Component, Input } from "@angular/core";
import { User } from "@metranpage/user-data";
import { UserRewardDaily } from "../../../../models/rewards/user-reward-daily";

@Component({
  selector: "m-reward-task-daily-view",
  templateUrl: "reward-task-daily.view.html",
  styleUrls: ["reward-task-daily.view.scss"],
})
export class RewardTaskDailyView {
  @Input()
  user?: User;
  @Input()
  userRewardDaily!: UserRewardDaily;

  // constructor() {}

  protected getTitle() {
    switch (this.userRewardDaily.reward.type) {
      case "likes-give":
        return $localize`:@@user.profile.reward-tasks.daily.likes.give:`;
      case "likes-take":
        return $localize`:@@user.profile.reward-tasks.daily.likes.take:`;
      default:
        return "";
    }
  }

  protected isTaskComplete(): boolean {
    return (
      this.userRewardDaily.reward.limit <= this.userRewardDaily.currentCounter || this.userRewardDaily.isLimitReached
    );
  }

  protected getProgress(): number {
    const completedCount = this.userRewardDaily.currentCounter;
    const totalCount = this.userRewardDaily.reward.limit;
    return (completedCount / totalCount) * 100;
  }

  protected getProgressText(): string {
    const completedCount = this.userRewardDaily.currentCounter;
    const totalCount = this.userRewardDaily.reward.limit;
    return `${completedCount}/${totalCount} ${$localize`:@@user.profile.reward-tasks.daily.per-day:`}`;
  }
}
