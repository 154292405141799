import { CommonModule, NgClass } from "@angular/common";
import { Component, forwardRef, type OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
  type ControlValueAccessor,
} from "@angular/forms";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ColorFormatSelectComponent } from "../color-format-select/color-format-select.component";
import { InputComponent } from "../input/input.component";
import { ColorConverterService } from "./color-converter.service";
import { HSV } from "./color.models";
import { InputNumberComponent } from "@metranpage/design-system";

export type ColorFormat = "rgb" | "hsl" | "hsv" | "hex" | "cmyk";

@Component({
  selector: "m-color-converter",
  templateUrl: "./color-converter.component.html",
  styleUrls: ["./color-converter.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    NgClass,
    ReactiveFormsModule,
    AngularSvgIconModule,
    InputComponent,
    InputNumberComponent,
    ColorFormatSelectComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorConverterComponent),
      multi: true,
    },
  ],
})
export class ColorConverterComponent implements OnInit, ControlValueAccessor {
  protected isDisabled = false;
  protected selectedColorFormat: ColorFormat = "cmyk";

  protected hsv: HSV = { h: 0, s: 0, v: 0 };

  protected colorFormatOptions: ColorFormat[] = ["hex", "rgb", "hsl", "hsv", "cmyk"];

  formHEX!: FormGroup;
  formRGB!: FormGroup;
  formHSL!: FormGroup;
  formHSV!: FormGroup;
  formCMYK!: FormGroup;

  private regexClearValue = /[^\d.-]/g;
  private regexCheckDigitValue = /^\d+(.\d+)?$/;
  private regexCheckPersentValue = /^\d+(.\d+)?%?$/;
  private regexCheckHexValue = /^#?[0-9a-fA-F]{3,6}$/;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  constructor(private colorConverterService: ColorConverterService) {}

  ngOnInit(): void {
    this.initForms();
    this.watchFormChanges();
  }

  writeValue(hsv: HSV): void {
    this.hsv = hsv;
    this.setFormValues();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private initForms() {
    this.formRGB = new FormGroup({
      r: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(255),
          Validators.pattern(this.regexCheckDigitValue),
        ],
      }),
      g: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(255),
          Validators.pattern(this.regexCheckDigitValue),
        ],
      }),
      b: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(255),
          Validators.pattern(this.regexCheckDigitValue),
        ],
      }),
    });

    this.formHSL = new FormGroup({
      h: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(360),
          Validators.pattern(this.regexCheckDigitValue),
        ],
      }),
      s: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
          Validators.pattern(this.regexCheckPersentValue),
        ],
      }),
      l: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
          Validators.pattern(this.regexCheckPersentValue),
        ],
      }),
    });

    this.formHEX = new FormGroup({
      hex: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(this.regexCheckHexValue)],
      }),
    });

    this.formCMYK = new FormGroup({
      c: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
          Validators.pattern(this.regexCheckPersentValue),
        ],
      }),
      m: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
          Validators.pattern(this.regexCheckPersentValue),
        ],
      }),
      y: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
          Validators.pattern(this.regexCheckPersentValue),
        ],
      }),
      k: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
          Validators.pattern(this.regexCheckPersentValue),
        ],
      }),
    });

    this.formHSV = new FormGroup({
      h: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(360),
          Validators.pattern(this.regexCheckDigitValue),
        ],
      }),
      s: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
          Validators.pattern(this.regexCheckPersentValue),
        ],
      }),
      v: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
          Validators.pattern(this.regexCheckPersentValue),
        ],
      }),
    });
  }

  private watchFormChanges() {
    this.formHEX.valueChanges.subscribe((value) => {
      if (!value.hex || !value.hex.match(this.regexCheckHexValue)) {
        return;
      }
      const rgb = this.colorConverterService.hex2rgb(value.hex);
      if (rgb) {
        const hexLength = value.hex.replace("#", "").length;
        if (hexLength === 3 || hexLength === 6) {
          const hsv = this.colorConverterService.rgb2hsv(rgb.r, rgb.g, rgb.b);
          this.onFormChange(hsv);
        }
      }
    });

    this.formRGB.valueChanges.subscribe((value) => {
      const r = this.convertValue(value.r, 0, 255);
      const g = this.convertValue(value.g, 0, 255);
      const b = this.convertValue(value.b, 0, 255);

      this.formRGB.controls.r.setValue(r, { emitEvent: false });
      this.formRGB.controls.g.setValue(g, { emitEvent: false });
      this.formRGB.controls.b.setValue(b, { emitEvent: false });

      const hsv = this.colorConverterService.rgb2hsv(r, g, b);
      this.onFormChange(hsv);
    });

    this.formHSL.valueChanges.subscribe((value) => {
      const h = this.convertValue(value.h, 0, 360);
      const s = this.convertValue(value.s, 0, 100);
      const l = this.convertValue(value.l, 0, 100);

      this.formHSL.controls.h.setValue(h, { emitEvent: false });
      this.formHSL.controls.s.setValue(s, { emitEvent: false });
      this.formHSL.controls.l.setValue(l, { emitEvent: false });

      const hsv = this.colorConverterService.hsl2hsv(h, s, l);

      this.onFormChange(hsv);
    });

    this.formHSV.valueChanges.subscribe((value) => {
      const h = this.convertValue(value.h, 0, 360);
      const s = this.convertValue(value.s, 0, 100);
      const v = this.convertValue(value.v, 0, 100);

      this.onFormChange({ h, s, v });
    });

    this.formCMYK.valueChanges.subscribe((value) => {
      const c = this.convertValue(value.c, 0, 100);
      const m = this.convertValue(value.m, 0, 100);
      const y = this.convertValue(value.y, 0, 100);
      const k = this.convertValue(value.k, 0, 100);

      this.formCMYK.controls.c.setValue(c, { emitEvent: false });
      this.formCMYK.controls.m.setValue(m, { emitEvent: false });
      this.formCMYK.controls.y.setValue(y, { emitEvent: false });
      this.formCMYK.controls.k.setValue(k, { emitEvent: false });

      const hsv = this.colorConverterService.cmyk2hsv(c, m, y, k);

      this.onFormChange(hsv);
    });
  }

  private setFormValues() {
    if (this.selectedColorFormat === "hex") {
      const rgb = this.colorConverterService.hsv2rgb(this.hsv.h, this.hsv.s, this.hsv.v);
      const hex = this.colorConverterService.rgb2hex(rgb.r, rgb.g, rgb.b);
      this.formHEX.controls.hex.setValue(hex, { emitEvent: false });
    }
    if (this.selectedColorFormat === "rgb") {
      const rgb = this.colorConverterService.hsv2rgb(this.hsv.h, this.hsv.s, this.hsv.v);
      this.formRGB.controls.r.setValue(Math.round(rgb.r), { emitEvent: false });
      this.formRGB.controls.g.setValue(Math.round(rgb.g), { emitEvent: false });
      this.formRGB.controls.b.setValue(Math.round(rgb.b), { emitEvent: false });
    }
    if (this.selectedColorFormat === "hsl") {
      const hsl = this.colorConverterService.hsv2hsl(this.hsv.h, this.hsv.s, this.hsv.v);
      this.formHSL.controls.h.setValue(Math.round(hsl.h), { emitEvent: false });
      this.formHSL.controls.s.setValue(Math.round(hsl.s), { emitEvent: false });
      this.formHSL.controls.l.setValue(Math.round(hsl.l), { emitEvent: false });
    }
    if (this.selectedColorFormat === "hsv") {
      this.formHSV.controls.h.setValue(Math.round(this.hsv.h), { emitEvent: false });
      this.formHSV.controls.s.setValue(Math.round(this.hsv.s), { emitEvent: false });
      this.formHSV.controls.v.setValue(Math.round(this.hsv.v), { emitEvent: false });
    }
    if (this.selectedColorFormat === "cmyk") {
      const cmyk = this.colorConverterService.hsv2cmyk(this.hsv.h, this.hsv.s, this.hsv.v);
      this.formCMYK.controls.c.setValue(Math.round(cmyk.c), { emitEvent: false });
      this.formCMYK.controls.m.setValue(Math.round(cmyk.m), { emitEvent: false });
      this.formCMYK.controls.y.setValue(Math.round(cmyk.y), { emitEvent: false });
      this.formCMYK.controls.k.setValue(Math.round(cmyk.k), { emitEvent: false });
    }
  }

  private isValueCorrect(value: string) {
    return !Number.isNaN(this.getDigitValue(value)) && Number.parseInt(value) >= 0;
  }

  private convertValue(value: number, min: number, max: number) {
    // let digitValue = this.getDigitValue(value);
    let digitValue = value;
    if (digitValue < min) {
      digitValue = min;
    }
    if (digitValue > max) {
      digitValue = max;
    }
    return digitValue;
  }

  private getDigitValue(value: string) {
    return Number.parseFloat(value.replace(this.regexClearValue, ""));
  }

  private onFormChange(value: HSV) {
    this.hsv = value;
    this.onChange(this.hsv);
  }

  protected changeColorFormat(format: ColorFormat) {
    this.selectedColorFormat = format;
    this.setFormValues();
  }
}
