import { Injectable } from "@angular/core";
import { ActiveSubscription } from "@metranpage/pricing-data";
import { Store } from "@metranpage/state";
import { map } from "rxjs";
import { User, UserBalance } from "./models/user.models";

type State = {
  user: User | undefined;
  balance: UserBalance | undefined;
  activeSubscription: ActiveSubscription | undefined;
};

@Injectable({
  providedIn: "root",
})
export class UserStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      balance: undefined,
      user: undefined,
      activeSubscription: undefined,
    };
  }

  setUser(user: User | undefined) {
    this.update((state) => ({ ...state, user }));
  }

  setBalance(balance: UserBalance | undefined) {
    this.update((state) => ({ ...state, balance }));
  }

  setActiveSubscription(activeSubscription: ActiveSubscription | undefined) {
    this.update((state) => ({ ...state, activeSubscription }));
  }

  getUser() {
    return this.getField("user");
  }

  getUserObservable() {
    return this.getFieldObservable("user");
  }

  getBalance() {
    return this.getState().balance;
  }

  getBalanceObservable() {
    return this.getStateObservable().pipe(map((state) => state.balance));
  }

  getActiveSubscription() {
    return this.getState().activeSubscription;
  }

  getActiveSubscriptionObservable() {
    return this.getStateObservable().pipe(map((state) => state.activeSubscription));
  }

  isUserAdmin() {
    return this.getUser()?.isAdmin ?? false;
  }

  /**
   * Check if user has paid subscription. Doesn't check for trial or supertokens
   * */
  isUserHasPaidSubscription() {
    return this.getActiveSubscription()?.hasPaidTariff ?? false;
  }

  /**
   * Check if user has any of premium features:
   * * paid sub
   * * trial
   * * supertokens
   */
  isUserHasPremium() {
    const hasPaidSub = this.getActiveSubscription()?.hasPaidTariff ?? false;
    const hasTrial = this.getActiveSubscription()?.hasTrialPeriod ?? false;
    const isUnlimited = this.getActiveSubscription()?.tariff?.isUnlimited ?? false;
    const hasSuperTokens = (this.getBalance()?.goldCredits ?? 0) > 0;

    return hasPaidSub || hasTrial || hasSuperTokens || isUnlimited;
  }
}
