<m-modal-backdrop
  [closeButtonVisible]="closeButtonVisible"
  [closeOnBackDropClick]="closeOnBackDropClick"
  (close)="onCloseClick()"
>
  <m-ng-island>
    <div class="container">
      <div class="title" i18n="@@books.modal.upload-template.title">books.modal.upload-template.title</div>

      <m-localized-text
        class="description"
        text="books.modal.upload-template.upload.description"
        i18n-text="@@books.modal.upload-template.upload.description"
      />

      <form *ngIf="form" [formGroup]="form">
        <m-ng-input
          formControlName="email"
          i18n-placeholder="@@books.modal.upload-template.email.placeholder"
          placeholder="books.modal.upload-template.email.placeholder"
        />

        <m-ng-input
          formControlName="phone"
          i18n-placeholder="@@books.modal.upload-template.phone.placeholder"
          placeholder="books.modal.upload-template.phone.placeholder"
        />

        <div class="file-upload">
          <m-file-drop #filedrop accept="*.*" (dropped)="dropped($event)" style="--custom-height: 100%">
            <div class="file-drop-container">
              <div class="drop-placeholder">
                <div class="drop-placeholder">
                  <m-icon src="upload.svg" size="original" color="--color-font-main"></m-icon>

                  <div>
                    <div class="description-text">
                      <ng-container i18n="@@books.modal.upload-template.upload-button.text-1">
                        books.modal.upload-template.upload-button.text-11
                      </ng-container>
                    </div>
                    <div class="description-text">
                      <ng-container i18n="@@books.modal.upload-template.upload-button.text-2">
                        books.modal.upload-template.upload-button.text-2
                      </ng-container>
                      <span
                        class="mark"
                        (click)="openFileSelector()"
                        i18n="@@books.modal.upload-template.upload-button.text-2-upload"
                      >
                        books.modal.upload-template.upload-button.text-2-upload
                      </span>
                      <ng-container i18n="@@books.modal.upload-template.upload-button.text-3">
                        books.modal.upload-template.upload-button.text-3
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div *ngIf="file" class="upload-template">
                  <span i18n="@@books.upload.original-filename-hint">books.upload.original-filename-hint</span>
                  {{ file.name }}
                </div>
              </div>
            </div>
          </m-file-drop>
        </div>
      </form>

      <m-button
        button-style="primary"
        size="full-width"
        i18n="@@books.modal.upload-template.request.button"
        icon="email.svg"
        icon-position="left"
        [is-disabled]="!isUploadActive()"
        (onClick)="onUploadTemplate()"
      >
        books.modal.upload-template.request.button
      </m-button>
    </div>
  </m-ng-island>
</m-modal-backdrop>
