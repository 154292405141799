import { CommonModule, NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ThemeService } from "@metranpage/theme";
import { AngularSvgIconModule } from "angular-svg-icon";
import { Subject, takeUntil } from "rxjs";

export type IconSize =
  | "xxs"
  | "xs-16"
  | "xs"
  | "xs-plus"
  | "s"
  | "m"
  | "m-plus"
  | "l"
  | "l-plus"
  | "xl"
  | "original"
  | "fit";

@Component({
  selector: "m-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
  standalone: true,
  imports: [CommonModule, NgClass, AngularSvgIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit, OnChanges {
  @Input()
  src!: string;
  @Input()
  size: IconSize = "m";
  @Input()
  color?: string;
  @Input()
  colorOnHover?: string;

  protected iconSrc!: string;
  protected isExtSvg = true;
  private destroyed$ = new Subject<void>();

  constructor(
    private readonly elementRef: ElementRef,
    private readonly themeService: ThemeService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    themeService.changeEvents$.pipe(takeUntil(this.destroyed$)).subscribe((theme) => {
      this.normalizePath();
      this.cdr.markForCheck();
    });
  }

  ngOnInit(): void {
    this.normalizePath();
    this.elementRef.nativeElement.style.setProperty("--icon-color", `var(${this.color})`);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["src"]) {
      this.src = changes["src"].currentValue;
    }
    if (changes["color"]) {
      this.color = changes["color"].currentValue;
    }

    this.normalizePath();
    this.elementRef.nativeElement.style.setProperty("--icon-color", `var(${this.color})`);
    if (this.colorOnHover) {
      this.elementRef.nativeElement.style.setProperty("--icon-color-hover", `var(${this.colorOnHover})`);
    }
    this.cdr.markForCheck();
  }

  normalizePath() {
    if (!this.src) {
      return;
    }

    if (this.src.startsWith("/")) {
      this.iconSrc = this.src;
    } else {
      this.iconSrc = `/assets/icons/${this.src}`;
    }

    if (this.iconSrc.includes("{theme}")) {
      this.iconSrc = this.iconSrc.replace("{theme}", this.themeService.getThemeSuffix());
    }

    if (this.iconSrc.split(".").pop()?.toLowerCase() !== "svg") {
      this.isExtSvg = false;
    }
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    result.push(`size-${this.size}`);

    if (this.color) {
      result.push("colored");
    }

    if (this.colorOnHover) {
      result.push("colored-hover");
    }

    return result;
  }
}
