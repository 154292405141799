import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IndentParagraph, StyleDef } from "@metranpage/markup-editor";
import { InfoBlockData } from "@metranpage/components";
import { Subscription } from "rxjs";

@Component({
  selector: "m-books-markup-indent-paragraph-settings",
  templateUrl: "./markup-indent-paragraph-settings.view.html",
  styleUrls: ["./markup-indent-paragraph-settings.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkupIndentParagraphSettings implements OnChanges, OnDestroy {
  @Input()
  style!: StyleDef;
  @Input()
  form!: FormGroup;

  protected indentParagraph: IndentParagraph = "none";

  private sub = new Subscription();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.form) {
      const styleControl = this.form.get("styles")?.get(this.style.styleKey)!;
      this.indentParagraph = styleControl.get("indentParagraph")?.value;
      this.updateIndentAfterHeaderState();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.style?.currentValue) {
      this.cdr.markForCheck();
    }
    if (changes.form?.currentValue) {
      const styleControl = this.form.get("styles")?.get(this.style.styleKey)!;

      this.sub.unsubscribe();
      this.sub = new Subscription();
      this.sub.add(
        styleControl?.get("indentParagraph")?.valueChanges.subscribe((value) => {
          this.indentParagraph = value;
          this.updateIndentAfterHeaderState();
        }),
      );

      this.sub.add(
        styleControl?.get("indentParagraphAfterHeader")?.valueChanges.subscribe((value) => {
          this.cdr.markForCheck();
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  updateIndentAfterHeaderState() {
    const styleControl = this.form.get("styles")?.get(this.style.styleKey)!;
    if (this.indentParagraph === "indent-after") {
      styleControl?.get("indentParagraphAfterHeader")?.setValue(false);
      styleControl?.get("indentParagraphAfterHeader")?.disable({ emitEvent: false });
    } else {
      styleControl?.get("indentParagraphAfterHeader")?.enable({ emitEvent: false });
    }
  }

  protected getInfoLabels(): InfoBlockData[] {
    const labels: InfoBlockData[] = [];

    if (this.indentParagraph === "indented-line") {
      labels.push({
        textData: [{ text: $localize`:@@books.markup.style-details.indent-paragraph-indented-line-description:` }],
      });
    }

    if (this.indentParagraph === "indent-after") {
      labels.push({
        textData: [{ text: $localize`:@@books.markup.style-details.indent-paragraph-new-line-description:` }],
      });
    }

    return labels;
  }
}
