import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { Company } from "../models/company";

export type State = {
  companies: Company[];
  companiesPageCount: number;
  activeCompany: Company | undefined;
};

@Injectable({
  providedIn: "root",
})
export class CompaniesAdminStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      companies: [],
      companiesPageCount: 1,
      activeCompany: undefined,
    };
  }

  getCompaniesObservable() {
    return this.getFieldObservable("companies");
  }

  setCompanies(companies: Company[]) {
    this.update((state) => ({ ...state, companies }));
  }

  getCompaniesPageCountObservable() {
    return this.getFieldObservable("companiesPageCount");
  }

  setCompaniesPageCount(count: number) {
    this.update((state) => ({ ...state, companiesPageCount: count }));
  }

  setActiveCompany(company: Company) {
    this.update((state) => ({ ...state, activeCompany: company }));
  }

  getActiveCompanyObservable() {
    return this.getFieldObservable("activeCompany");
  }
}
