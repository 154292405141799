import { Inject, Injectable, NgModule } from "@angular/core";
import { offset } from "@floating-ui/dom";
import Shepherd from "shepherd.js";
import Step from "shepherd.js/src/types/step";
import { ButtonsCreator } from "./buttons-creator.service";

export type OnboardingPage =
  | "home-page"
  | "image-generation-basic"
  | "image-generation-advanced"
  | "image-generation-creative"
  | "modal-book-templates"
  | "cover-page"
  | "books-page"
  | "upload-page"
  | "templates-page"
  | "markup-page"
  | "margins-page"
  | "running-titles-page"
  | "preview-page";

@Injectable({
  providedIn: "root",
})
export class StepsCreator {
  constructor(@Inject(ButtonsCreator) private readonly buttonsCreator: ButtonsCreator) {}

  createSteps(onboardingPage: OnboardingPage) {
    switch (onboardingPage) {
      case "home-page":
        return this.createHomePageSteps();
      case "image-generation-basic":
        return this.createImageGenerationBasicSteps();
      case "image-generation-advanced":
        return this.createImageGenerationAdvancedSteps();
      case "image-generation-creative":
        return this.createImageGenerationCreativeSteps();
      case "modal-book-templates":
        return this.createModalBookTemplatesSteps();
      case "cover-page":
        return this.createCoverPageSteps();
      case "books-page":
        return this.createBooksPageSteps();
      case "upload-page":
        return this.createUploadPageSteps();
      case "templates-page":
        return this.createTemplatesPageSteps();
      case "markup-page":
        return this.createMarkupPageSteps();
      case "margins-page":
        return this.createMarginsPageSteps();
      case "running-titles-page":
        return this.createRunningTitlesPageSteps();
      case "preview-page":
        return this.createPreviewPageSteps();
      default:
        return [];
    }
  }

  getDefaultStepOptions(): Step.StepOptions {
    return {
      classes: "onboarding-class",
      scrollTo: { behavior: "smooth", block: "center" },
      canClickTarget: false,
      cancelIcon: {
        enabled: true,
      },
      when: {
        show() {
          const stepsCount = Shepherd.activeTour?.steps.length;
          if (stepsCount && stepsCount <= 1) {
            return;
          }
          const currentStep = Shepherd.activeTour?.getCurrentStep();
          const currentStepElement = currentStep?.getElement();
          if (!currentStep || !currentStepElement) {
            return;
          }
          const title = currentStepElement.querySelector(".shepherd-title") as HTMLElement;
          if (title) {
            let currentStepIndex = Shepherd.activeTour?.steps.indexOf(currentStep) ?? 0 + 1;
            if (currentStepIndex >= 0) {
              currentStepIndex++;
            }
            title.innerText = `${title.textContent} ${currentStepIndex}/${Shepherd.activeTour?.steps.length}`;
          }
        },
      },
    };
  }

  protected createCoverPageSteps(): Step.StepOptions[] {
    return [
      {
        id: "cover-page-step-1",
        attachTo: {
          element: "m-cover-view > div > div.cell-wrapper.center > div.canvas-wrapper > div.toolbar-wrapper.left > div",
          on: "bottom",
        },
        beforeShowPromise: () => {
          return new Promise((resolve) => {
            setTimeout(() => {
              const element = document.querySelector(".backdrop") as HTMLElement;
              element?.click();
              resolve(true);
            }, 250);
          });
        },
        buttons: [this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.cover-page.title:`,
        text: $localize`:@@onboarding.cover-page.text-step-1:`,
      },
      {
        id: "cover-page-step-2",
        attachTo: {
          element: "m-cover-view > div > div.cell-wrapper.left > m-cover-object-create > div > div.section.main",
          on: "left",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.cover-page.title:`,
        text: $localize`:@@onboarding.cover-page.text-step-2:`,
      },
      {
        id: "cover-page-step-3",
        attachTo: {
          element: "m-cover-view .cdk-drop-list",
          on: "left-start",
        },
        buttons: [
          this.buttonsCreator.getButton("back"),
          {
            classes: this.buttonsCreator.getButton("next").classes,
            text: this.buttonsCreator.getButton("next").text,
            action() {
              const element = document.querySelector("m-cover-view .cdk-drop-list") as HTMLElement;
              element?.click();
              setTimeout(() => {
                return this.next();
              }, 150);
            },
          },
        ],
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.cover-page.title:`,
        text: $localize`:@@onboarding.cover-page.text-step-3:`,
      },
      {
        id: "cover-page-step-4",
        attachTo: {
          element: "m-cover-view .cdk-drop-list div.cdk-drag.plate.layer-wrapper",
          on: "left-start",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("complete")],
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.cover-page.title:`,
        text: $localize`:@@onboarding.cover-page.text-step-4:`,
      },
      // {
      //   id: "cover-page-step-5",
      //   attachTo: {
      //     element:
      //       "m-cover-view > div > div.cell-wrapper.center > div.top-toolbar-wrapper > m-cover-object-toolbar > div > div.cell.left > m-cover-text-object-toolbar > form",
      //     on: "bottom",
      //   },
      //   buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
      //   modalOverlayOpeningPadding: 5,
      //   modalOverlayOpeningRadius: 15,
      //   floatingUIOptions: {
      //     middleware: [offset({ mainAxis: 20 })],
      //   },
      //   title: $localize`:@@onboarding.cover-page.title:`,
      //   text: $localize`:@@onboarding.cover-page.text-step-5:`,
      // },
    ];
  }

  protected createModalBookTemplatesSteps(): Step.StepOptions[] {
    return [
      {
        id: "modal-book-templates-step-1",
        attachTo: {
          element: "m-modal-book-templates m-template-preview-card-new:nth-child(1)",
          on: "right-start",
        },
        buttons: [this.buttonsCreator.getButton("complete")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.modal-book-templates.title:`,
        text: $localize`:@@onboarding.modal-book-templates.text-step-1:`,
      },
    ];
  }

  protected createImageGenerationBasicSteps(): Step.StepOptions[] {
    return [
      {
        id: "image-generation-page-step-3",
        attachTo: {
          element: "#tab1 > form > m-sidebar-section-2:nth-of-type(1)",
          on: "left-start",
        },
        beforeShowPromise: () => {
          return new Promise((resolve) => {
            const element = document.querySelector("#tab1 > form > m-sidebar-section-2:nth-of-type(1)") as HTMLElement;
            if (element) {
              resolve(true);
            }
            setTimeout(() => {
              resolve(true);
            }, 150);
          });
        },
        buttons: [this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-3:`,
      },
      {
        id: "image-generation-page-step-4",
        attachTo: {
          element: "#tab1 > form > m-sidebar-section-2:nth-of-type(2)",
          on: "left-start",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-4:`,
      },
      {
        id: "image-generation-page-step-5",
        attachTo: {
          element: "#tab1 > form > m-sidebar-section-2:nth-of-type(3)",
          on: "left-start",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-5:`,
      },
      {
        id: "image-generation-page-step-6",
        attachTo: {
          element: "#tab1 > form > m-styles-selector",
          on: "left-start",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-6:`,
      },
      {
        id: "image-generation-page-step-14",
        attachTo: {
          element: "m-image-generation-view > m-sidebar-fixed > div > m-button.button-style-primary",
          on: "left-end",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("complete")],
        modalOverlayOpeningPadding: 2,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-14:`,
      },
    ];
  }

  protected createImageGenerationAdvancedSteps(): Step.StepOptions[] {
    return [
      {
        id: "image-generation-page-step-1",
        attachTo: {
          element: "m-prompt-text-editor",
          on: "bottom",
        },
        buttons: [this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-5:`,
      },
      {
        id: "image-generation-page-step-2",
        attachTo: {
          element: "m-generation-prompt > m-ng-island > div > form > div > div",
          on: "bottom",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-15:`,
      },
      {
        id: "image-generation-page-step-3",
        attachTo: {
          element: "#tab1 > form > m-sidebar-section-2:nth-of-type(1)",
          on: "left-start",
        },
        beforeShowPromise: () => {
          return new Promise((resolve) => {
            const element = document.querySelector("#tab1 > form > m-sidebar-section-2:nth-of-type(1)") as HTMLElement;
            if (element) {
              resolve(true);
            }
            setTimeout(() => {
              resolve(true);
            }, 150);
          });
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-3:`,
      },
      {
        id: "image-generation-page-step-4",
        attachTo: {
          element: "#tab1 > form > m-sidebar-section-2:nth-of-type(2)",
          on: "left-start",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-4:`,
      },

      // {
      //   id: "image-generation-page-step-5",
      //   attachTo: {
      //     element: "#tab1 > form > m-sidebar-section-2:nth-of-type(3)",
      //     on: "left-start",
      //   },
      //   beforeShowPromise: () => {
      //     return new Promise((resolve, reject) => {
      //       const element = document.querySelector("#tab1 > form > m-image-advanced-generation-mode") as HTMLElement;
      //       if (!element) {
      //         Shepherd.activeTour?.next();
      //         reject();
      //       }
      //     });
      //   },
      //   buttons: [
      //     this.buttonsCreator.getButton("back"),
      //     {
      //       classes: this.buttonsCreator.getButton("next").classes,
      //       text: this.buttonsCreator.getButton("next").text,
      //       action() {
      //         const element = document.querySelector(
      //           "#tab1 > form > m-sidebar-section-2:nth-of-type(5)",
      //         ) as HTMLElement;
      //         element?.scrollIntoView({ behavior: "smooth", block: "center" });
      //         setTimeout(() => {
      //           return this.next();
      //         }, 250);
      //       },
      //     },
      //   ],
      //   modalOverlayOpeningPadding: 10,
      //   modalOverlayOpeningRadius: 15,
      //   floatingUIOptions: {
      //     middleware: [offset({ mainAxis: 20 })],
      //   },
      //   title: $localize`:@@onboarding.image-generation-basic.title:`,
      //   text: $localize`:@@onboarding.image-generation-page.text-step-9:`,
      // },

      {
        id: "image-generation-page-step-6",
        attachTo: {
          element: "#tab1 > form > m-styles-selector",
          on: "left-start",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-6:`,
      },
      {
        id: "image-generation-page-step-8",
        attachTo: {
          element: "#tab1 > form > m-sidebar-section-2:nth-of-type(4)",
          on: "left-start",
        },
        buttons: [
          {
            classes: this.buttonsCreator.getButton("back").classes,
            text: this.buttonsCreator.getButton("back").text,
            action() {
              const element = document.querySelector(
                "#tab1 > form > m-sidebar-section-2:nth-of-type(3)",
              ) as HTMLElement;
              element?.scrollIntoView({ behavior: "smooth", block: "center" });
              setTimeout(() => {
                return this.back();
              }, 250);
            },
          },
          this.buttonsCreator.getButton("next"),
        ],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-8:`,
      },
      {
        id: "image-generation-page-step-9",
        attachTo: {
          element: "#tab1 > form > m-sidebar-section-2:nth-of-type(5)",
          on: "left-start",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-7:`,
      },
      {
        id: "image-generation-page-step-10",
        attachTo: {
          element: "#tab1 > form > m-sidebar-section-2:nth-of-type(6)",
          on: "left-end",
        },
        buttons: [
          {
            classes: this.buttonsCreator.getButton("back").classes,
            text: this.buttonsCreator.getButton("back").text,
            action() {
              const element = document.querySelector(
                "#tab1 > form > m-sidebar-section-2:nth-of-type(5)",
              ) as HTMLElement;
              element?.scrollIntoView({ behavior: "smooth", block: "center" });
              setTimeout(() => {
                return this.back();
              }, 250);
            },
          },
          {
            classes: this.buttonsCreator.getButton("next").classes,
            text: this.buttonsCreator.getButton("next").text,
            action() {
              const element = document.querySelector(
                "#tab1 > form > m-sidebar-section-2:nth-of-type(7) > div > div",
              ) as HTMLElement;
              element?.click();
              setTimeout(() => {
                return this.next();
              }, 150);
            },
          },
        ],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-10:`,
      },
      {
        id: "image-generation-page-step-11",
        attachTo: {
          element: "#tab1 > form > m-sidebar-section-2:nth-of-type(7)",
          on: "left-end",
        },
        buttons: [
          {
            classes: this.buttonsCreator.getButton("back").classes,
            text: this.buttonsCreator.getButton("back").text,
            action() {
              const element = document.querySelector(
                "#tab1 > form > m-sidebar-section-2:nth-of-type(6) > div > div",
              ) as HTMLElement;
              element?.click();
              setTimeout(() => {
                return this.back();
              }, 150);
            },
          },
          this.buttonsCreator.getButton("complete"),
        ],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-basic.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-11:`,
      },
    ];
  }

  protected createImageGenerationCreativeSteps(): Step.StepOptions[] {
    return [
      {
        id: "image-generation-page-step-1",
        attachTo: {
          element: "m-prompt-text-editor",
          on: "bottom",
        },
        buttons: [this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-creative.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-5:`,
      },
      {
        id: "image-generation-page-step-2",
        attachTo: {
          element: "m-generation-prompt > m-ng-island > div > form > div > div",
          on: "bottom",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-creative.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-15:`,
      },
      {
        id: "image-generation-page-step-3",
        attachTo: {
          element: "#tab2 > form > m-sidebar-section-2:nth-of-type(1)",
          on: "left-start",
        },
        beforeShowPromise: () => {
          return new Promise((resolve) => {
            const element = document.querySelector("#tab1 > form > m-sidebar-section-2:nth-of-type(1)") as HTMLElement;
            if (element) {
              resolve(true);
            }
            setTimeout(() => {
              resolve(true);
            }, 150);
          });
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-creative.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-3:`,
      },
      {
        id: "image-generation-page-step-4",
        attachTo: {
          element: "#tab2 > form > m-styles-selector",
          on: "left-start",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-creative.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-6:`,
      },
      {
        id: "image-generation-page-step-8",
        attachTo: {
          element: "#tab2 > form > m-sidebar-section-2:nth-of-type(3)",
          on: "left-start",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-creative.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-8:`,
      },
      {
        id: "image-generation-page-step-9",
        attachTo: {
          element: "#tab2 > form > m-sidebar-section-2:nth-of-type(4)",
          on: "left-start",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-creative.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-7:`,
      },
      {
        id: "image-generation-page-step-10",
        attachTo: {
          element: "#tab2 > form > m-sidebar-section-2:nth-of-type(5)",
          on: "left-end",
        },
        buttons: [
          {
            classes: this.buttonsCreator.getButton("back").classes,
            text: this.buttonsCreator.getButton("back").text,
            action() {
              const element = document.querySelector(
                "#tab2 > form > m-sidebar-section-2:nth-of-type(4)",
              ) as HTMLElement;
              element?.scrollIntoView({ behavior: "smooth", block: "center" });
              setTimeout(() => {
                return this.back();
              }, 250);
            },
          },
          {
            classes: this.buttonsCreator.getButton("next").classes,
            text: this.buttonsCreator.getButton("next").text,
            action() {
              const element = document.querySelector(
                "#tab2 > form > m-sidebar-section-2:nth-of-type(3) > div > div",
              ) as HTMLElement;
              element?.click();
              setTimeout(() => {
                return this.next();
              }, 150);
            },
          },
        ],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-creative.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-10:`,
      },
      {
        id: "image-generation-page-step-11",
        attachTo: {
          element: "#tab2 > form > m-sidebar-section-2:nth-of-type(6)",
          on: "left-end",
        },
        buttons: [
          {
            classes: this.buttonsCreator.getButton("back").classes,
            text: this.buttonsCreator.getButton("back").text,
            action() {
              const element = document.querySelector(
                "#tab2 > form > m-sidebar-section-2:nth-of-type(5) > div > div",
              ) as HTMLElement;
              element?.click();
              setTimeout(() => {
                return this.back();
              }, 150);
            },
          },
          this.buttonsCreator.getButton("complete"),
        ],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.image-generation-creative.title:`,
        text: $localize`:@@onboarding.image-generation-page.text-step-11:`,
      },
    ];
  }

  protected createHomePageSteps(): Step.StepOptions[] {
    return [
      {
        id: "home-page-step-1",
        attachTo: {
          element: "m-published-images",
        },
        buttons: [
          {
            classes: this.buttonsCreator.getButton("next").classes,
            text: this.buttonsCreator.getButton("next").text,
            action() {
              const element = document.querySelector(
                "m-published-images > m-ng-island > div > div.images m-published-image",
              ) as HTMLElement;
              element?.click();
              setTimeout(() => {
                return this.next();
              }, 150);
            },
          },
        ],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.home-page.title:`,
        text: $localize`:@@onboarding.home-page.text-step-1:`,
      },
      {
        id: "home-page-step-2",
        attachTo: {
          element: "m-preview-published-image-modal div.settings",
          on: "left",
        },
        buttons: [
          {
            classes: this.buttonsCreator.getButton("back").classes,
            text: this.buttonsCreator.getButton("back").text,
            action() {
              const element = document.querySelector(".backdrop") as HTMLElement;
              element?.click();
              return this.back();
            },
          },
          this.buttonsCreator.getButton("next"),
        ],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.home-page.title:`,
        text: $localize`:@@onboarding.home-page.text-step-2:`,
      },
      {
        id: "home-page-step-3",
        attachTo: {
          element: "m-preview-published-image-modal div.modal-buttons > m-button.button-style-primary",
          on: "left-end",
        },
        buttons: [
          this.buttonsCreator.getButton("back"),
          {
            classes: this.buttonsCreator.getButton("next").classes,
            text: this.buttonsCreator.getButton("next").text,
            action() {
              const element = document.querySelector(".backdrop") as HTMLElement;
              element?.click();
              return this.next();
            },
          },
        ],
        modalOverlayOpeningPadding: 2,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.home-page.title:`,
        text: $localize`:@@onboarding.home-page.text-step-3:`,
      },
      {
        id: "home-page-step-4",
        attachTo: {
          element: "m-home-menu > m-ng-island > div > m-button:nth-child(2)",
          on: "right",
        },
        buttons: [
          {
            classes: this.buttonsCreator.getButton("back").classes,
            text: this.buttonsCreator.getButton("back").text,
            action() {
              const element = document.querySelector(
                "m-published-images > m-ng-island > div > div.images m-published-image",
              ) as HTMLElement;
              element?.click();
              setTimeout(() => {
                return this.back();
              }, 150);
            },
          },
          this.buttonsCreator.getButton("next"),
        ],
        modalOverlayOpeningPadding: 2,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.home-page.title:`,
        text: $localize`:@@onboarding.home-page.text-step-4:`,
      },
      {
        id: "home-page-step-5",
        attachTo: {
          element: "m-home-menu > m-ng-island > div > m-button:nth-child(3)",
          on: "right",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("complete")],
        modalOverlayOpeningPadding: 2,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.home-page.title:`,
        text: $localize`:@@onboarding.home-page.text-step-5:`,
      },
    ];
  }

  protected createPreviewPageSteps(): Step.StepOptions[] {
    return [
      {
        id: "step-preview-layout-book-button",
        attachTo: {
          element: "div.side-panel-right > m-side-panel > div > div > div.buttons > m-button",
          on: "left",
        },
        buttons: [this.buttonsCreator.getButton("complete")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.preview-page.title:`,
        text: $localize`:@@onboarding.preview-page.text-step-1:`,
      },
    ];
  }

  protected createRunningTitlesPageSteps(): Step.StepOptions[] {
    return [
      {
        id: "step-sidebar-running-titles-settings",
        attachTo: {
          element: "div.side-panel > m-side-panel > div > div > div.side-panel-printbook",
          on: "left",
        },
        buttons: [this.buttonsCreator.getButton("complete")],
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.running-titles-page.title:`,
        text: $localize`:@@onboarding.running-titles-page.text-step-1:`,
      },
    ];
  }

  protected createMarginsPageSteps(): Step.StepOptions[] {
    return [
      {
        id: "step-sidebar-margins-settings",
        attachTo: {
          element: "div.side-panel > m-side-panel > div > div > div.side-panel-printbook",
          on: "left",
        },
        buttons: [this.buttonsCreator.getButton("complete")],
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.margins-page.title:`,
        text: $localize`:@@onboarding.margins-page.text-step-1:`,
      },
    ];
  }

  protected createMarkupPageSteps(): Step.StepOptions[] {
    return [
      // text-settings-sidebar
      {
        id: "step-headers-size-selector",
        attachTo: {
          element: "m-font-size-selector",
          on: "left",
        },
        buttons: [this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.markup-page.title:`,
        text: $localize`:@@onboarding.markup-page.text-step-1:`,
      },
      {
        id: "step-styles-list",
        attachTo: {
          element:
            "#tab1 > m-books-markup-sidebar-text > form > m-sidebar-section > div.content > div > div.styles-wrapper > div:nth-child(1)",
          on: "left",
        },
        buttons: [
          this.buttonsCreator.getButton("back"),
          {
            classes: this.buttonsCreator.getButton("next").classes,
            text: this.buttonsCreator.getButton("next").text,
            action() {
              const element = document.querySelector(
                "#tab1 > m-books-markup-sidebar-text > form > m-sidebar-section > div.content > div > div.styles-wrapper > div:nth-child(2) > div.style-settings",
              ) as HTMLElement;
              element?.click();
              return this.next();
            },
          },
        ],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.markup-page.title:`,
        text: $localize`:@@onboarding.markup-page.text-step-2:`,
      },
      // style-details-settings-sidebar
      {
        id: "step-style-details-settings-sidebar",
        attachTo: {
          element: "m-fixed-sidebar.sidebar-right-styles > div > div:nth-child(2) > m-books-markup-sidebar-style",
          on: "left",
        },
        buttons: [
          {
            classes: this.buttonsCreator.getButton("back").classes,
            text: this.buttonsCreator.getButton("back").text,
            action() {
              const element = document.querySelector(
                "m-fixed-sidebar.sidebar-right-styles > div > div:nth-child(2) > m-books-markup-sidebar-style > form > div > div.buttons > m-button",
              ) as HTMLElement;
              element?.click();
              return this.back();
            },
          },
          this.buttonsCreator.getButton("next"),
        ],
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.markup-page.title:`,
        text: $localize`:@@onboarding.markup-page.text-step-3:`,
      },
      {
        id: "step-style-indent",
        attachTo: {
          element:
            "m-fixed-sidebar.sidebar-right-styles > div > div:nth-child(2) > m-books-markup-sidebar-style > form > div > div.container > div > div.row.space-between > m-markup-style-details-start-on-buttons-row > m-radio:nth-child(1) > div",
          on: "left",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.markup-page.title:`,
        text: $localize`:@@onboarding.markup-page.text-step-4:`,
      },
      {
        id: "step-style-indent",
        attachTo: {
          element:
            "m-fixed-sidebar.sidebar-right-styles > div > div:nth-child(2) > m-books-markup-sidebar-style > form > div > div.container > div > div.row.space-between > m-markup-style-details-start-on-buttons-row > m-radio:nth-child(2) > div",
          on: "left",
        },
        buttons: [
          this.buttonsCreator.getButton("back"),
          {
            classes: this.buttonsCreator.getButton("next").classes,
            text: this.buttonsCreator.getButton("next").text,
            action() {
              const element = document.querySelector(
                "m-fixed-sidebar.sidebar-right-styles > div > div:nth-child(2) > m-books-markup-sidebar-style > form > div > div.buttons > m-button",
              ) as HTMLElement;
              element?.click();
              setTimeout(() => {
                return this.next();
              }, 150);
            },
          },
        ],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.markup-page.title:`,
        text: $localize`:@@onboarding.markup-page.text-step-5:`,
      },
      // design-sidebar
      {
        id: "step-design-tab",
        attachTo: {
          element: "m-fixed-sidebar.sidebar-right > m-sidebar-tabs > div.tabs > div:nth-child(2)",
          on: "bottom-end",
        },
        buttons: [
          {
            classes: this.buttonsCreator.getButton("back").classes,
            text: this.buttonsCreator.getButton("back").text,
            action() {
              const element = document.querySelector(
                "m-fixed-sidebar.sidebar-right > m-sidebar-tabs > div.tabs > div:nth-child(1)",
              ) as HTMLElement;
              element?.click();
              setTimeout(() => {
                const element = document.querySelector(
                  "#tab1 > m-books-markup-sidebar-text > form > m-sidebar-section > div.content > div > div.styles-wrapper > div:nth-child(2) > div.style-settings",
                ) as HTMLElement;
                element?.click();
                setTimeout(() => {
                  return this.back();
                }, 150);
              }, 150);
            },
          },
          {
            classes: this.buttonsCreator.getButton("next").classes,
            text: this.buttonsCreator.getButton("next").text,
            action() {
              const element = document.querySelector(
                "m-fixed-sidebar.sidebar-right > m-sidebar-tabs > div.tabs > div:nth-child(2)",
              ) as HTMLElement;
              element?.click();
              setTimeout(() => {
                return this.next();
              }, 150);
            },
          },
        ],
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.markup-page.title:`,
        text: $localize`:@@onboarding.markup-page.text-step-6:`,
      },
      {
        id: "step-color-palette",
        attachTo: {
          element:
            "#tab2 > m-books-markup-sidebar-design > div > m-books-markup-sidebar-palette > form > div > m-color-palette",
          on: "left",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.markup-page.title:`,
        text: $localize`:@@onboarding.markup-page.text-step-7:`,
      },
      {
        id: "step-color-palette-settings",
        attachTo: {
          element: "#tab2 > m-books-markup-sidebar-design > div > m-books-markup-sidebar-palette > form > div > div",
          on: "left",
        },
        buttons: [
          this.buttonsCreator.getButton("back"),
          {
            classes: this.buttonsCreator.getButton("next").classes,
            text: this.buttonsCreator.getButton("next").text,
            action() {
              const element = document.querySelector("m-books-toc-bar > div.header") as HTMLElement;
              element?.click();
              setTimeout(() => {
                return this.next();
              }, 150);
            },
          },
        ],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.markup-page.title:`,
        text: $localize`:@@onboarding.markup-page.text-step-8:`,
      },
      // toc
      {
        id: "step-editor-toc-settings",
        attachTo: {
          element: "m-books-toc-bar > div.content > div > m-books-markup-sidebar-toc-settings",
          on: "right",
        },
        buttons: [
          {
            classes: this.buttonsCreator.getButton("back").classes,
            text: this.buttonsCreator.getButton("back").text,
            action() {
              const element = document.querySelector("m-books-toc-bar > div.header") as HTMLElement;
              element?.click();
              setTimeout(() => {
                return this.back();
              }, 150);
            },
          },
          {
            classes: this.buttonsCreator.getButton("next").classes,
            text: this.buttonsCreator.getButton("next").text,
            action() {
              const element = document.querySelector(
                "#blocks > m-markup-editor-block:nth-child(1) > div.content",
              ) as HTMLElement;
              const clickEvent = document.createEvent("MouseEvents");
              clickEvent.initEvent("mousedown", true, true);
              element.dispatchEvent(clickEvent);
              setTimeout(() => {
                const element = document.querySelector("m-books-toc-bar > div.header") as HTMLElement;
                element?.click();
                return this.next();
              }, 150);
            },
          },
        ],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20, crossAxis: 80 })],
        },
        title: $localize`:@@onboarding.markup-page.title:`,
        text: $localize`:@@onboarding.markup-page.text-step-9:`,
      },
      // editor-toolbar
      {
        id: "step-editor-toolbar-plus",
        attachTo: {
          element: "m-markup-editor > m-markup-block-toolbar > div:nth-child(1)",
          on: "right",
        },
        buttons: [
          {
            classes: this.buttonsCreator.getButton("back").classes,
            text: this.buttonsCreator.getButton("back").text,
            action() {
              const element = document.querySelector("m-books-toc-bar > div.header") as HTMLElement;
              element?.click();
              setTimeout(() => {
                return this.back();
              }, 150);
            },
          },
          this.buttonsCreator.getButton("next"),
        ],
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 8,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20, crossAxis: 70 })],
        },
        title: $localize`:@@onboarding.markup-page.title:`,
        text: $localize`:@@onboarding.markup-page.text-step-10:`,
      },
      {
        id: "step-editor-toolbar-dots",
        attachTo: {
          element: "m-markup-editor > m-markup-block-toolbar > div:nth-child(2)",
          on: "right",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("complete")],
        modalOverlayOpeningPadding: 5,
        modalOverlayOpeningRadius: 8,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20, crossAxis: 70 })],
        },
        title: $localize`:@@onboarding.markup-page.title:`,
        text: $localize`:@@onboarding.markup-page.text-step-11:`,
      },
    ];
  }

  protected createTemplatesPageSteps(): Step.StepOptions[] {
    return [
      // {
      //   id: 'step-format-size',
      //   attachTo: {
      //     element: 'm-select-template-format-view',
      //     on: 'bottom',
      //   },
      //   buttons: [this.buttonsCreator.getButton('next')],
      //   modalOverlayOpeningPadding: 10,
      //   modalOverlayOpeningRadius: 15,
      //   floatingUIOptions: {
      //     middleware: [offset({ mainAxis: 20 })],
      //   },
      //   title: $localize`:@@onboarding.templates-page.title:`,
      //   text: $localize`:@@onboarding.templates-page.text-step-1:`,
      // },
      {
        id: "step-template-card",
        attachTo: {
          element: "m-template-preview-card",
          on: "right",
        },
        buttons: [
          // this.buttonsCreator.getButton("back"),
          this.buttonsCreator.getButton("complete"),
        ],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.templates-page.title:`,
        text: $localize`:@@onboarding.templates-page.text-step-2:`,
      },
    ];
  }

  protected createUploadPageSteps(): Step.StepOptions[] {
    return [
      {
        id: "step-upload-document",
        attachTo: {
          element: ".left",
          on: "right",
        },
        buttons: [],
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 15,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.upload-page.title:`,
        text: $localize`:@@onboarding.upload-page.text-step-1:`,
      },
    ];
  }

  protected createBooksPageSteps(): Step.StepOptions[] {
    return [
      // {
      //   id: 'step-project-card',
      //   attachTo: {
      //     element: 'div > m-book-card',
      //     on: 'bottom',
      //   },
      //   buttons: [this.buttonsCreator.getButton('back'), this.buttonsCreator.getButton('next')],
      //   modalOverlayOpeningPadding: 10,
      //   modalOverlayOpeningRadius: 15,
      //   floatingUIOptions: {
      //     middleware: [offset({ mainAxis: 20 })],
      //   },
      //   title: $localize`:@@onboarding.books-page.title:`,
      //   text: $localize`:@@onboarding.books-page.text-step-1:`,
      // },
      // {
      //   id: "step-project-name-settings",
      //   attachTo: {
      //     element: "m-book-card  > div > div.info > m-button",
      //     on: "right",
      //   },
      //   buttons: [this.buttonsCreator.getButton("next")],
      //   modalOverlayOpeningPadding: 5,
      //   modalOverlayOpeningRadius: 15,
      //   floatingUIOptions: {
      //     middleware: [offset({ mainAxis: 20 })],
      //   },
      //   title: $localize`:@@onboarding.books-page.title:`,
      //   text: $localize`:@@onboarding.books-page.text-step-1:`,
      // },
      {
        id: "step-project-download-files",
        attachTo: {
          element: "m-book-card:nth-child(1) > div > div.buttons > m-book-card-action:nth-of-type(1)",
          on: "left",
        },
        buttons: [this.buttonsCreator.getButton("next")],
        modalOverlayOpeningRadius: {
          topLeft: 0,
          bottomLeft: 0,
          bottomRight: 0,
          topRight: 15,
        },
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.books-page.title:`,
        text: $localize`:@@onboarding.books-page.text-step-2:`,
      },
      {
        id: "step-project-edit",
        attachTo: {
          element: "m-book-card:nth-child(1) > div > div.buttons > m-book-card-action:nth-of-type(2)",
          on: "left",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningRadius: 0,
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.books-page.title:`,
        text: $localize`:@@onboarding.books-page.text-step-3:`,
      },
      {
        id: "step-project-copy",
        attachTo: {
          element: "m-book-card:nth-child(1) > div > div.buttons > m-book-card-action:nth-of-type(3)",
          on: "left",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("next")],
        modalOverlayOpeningRadius: {
          topLeft: 0,
          bottomLeft: 0,
          bottomRight: 15,
          topRight: 0,
        },
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.books-page.title:`,
        text: $localize`:@@onboarding.books-page.text-step-4:`,
      },
      {
        id: "step-project-delete",
        attachTo: {
          element: "m-book-card:nth-child(1) > div > div.buttons > m-book-card-action:nth-of-type(4)",
          on: "left",
        },
        buttons: [this.buttonsCreator.getButton("back"), this.buttonsCreator.getButton("complete")],
        modalOverlayOpeningRadius: {
          topLeft: 0,
          bottomLeft: 0,
          bottomRight: 15,
          topRight: 0,
        },
        floatingUIOptions: {
          middleware: [offset({ mainAxis: 20 })],
        },
        title: $localize`:@@onboarding.books-page.title:`,
        text: $localize`:@@onboarding.books-page.text-step-5:`,
      },
    ];
  }
}

// advanceOn: {
//   selector:
//     '#tab1 > m-books-markup-sidebar-text > form > m-sidebar-section > div.content > div > div.styles-wrapper > div:nth-child(1) > div.style-settings',
//   event: 'click',
// },
// beforeShowPromise() {
//   return new Promise((resolve, reject) => {
//     const settingsButton = document.querySelector(
//       '#tab1 > m-books-markup-sidebar-text > form > m-sidebar-section > div.content > div > div.styles-wrapper > div:nth-child(1) > div.style-settings',
//     ) as HTMLElement;
//     if (!settingsButton) {
//       return reject;
//     }
//     settingsButton.click();
//     return resolve;
//   });
// },
