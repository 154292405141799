import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit } from "@angular/core";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { ImageGeneration } from "../../models/image-generation";

@Component({
  selector: "m-generation-error-image-card",
  templateUrl: "./generation-error-image-card.view.html",
  styleUrls: ["./generation-error-image-card.view.scss"],
  animations: [fadeInOutOnEnterLeave],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerationErrorImageCardView implements OnInit {
  @Input()
  imageGeneration!: ImageGeneration;

  @HostBinding("class.width-limited")
  @Input()
  isWidthLimited = true;

  @HostBinding("style.aspect-ratio")
  protected aspectRatio = 1;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.aspectRatio = this.imageGeneration.width / this.imageGeneration.height;
  }
}
