<form [formGroup]="form">
  <p><b i18n="@@admin.templates.add-template">admin.templates.add-template</b></p>
  <div>
    <span class="label" i18n="@@admin.templates.template.title">admin.templates.template.title</span>
    <m-ng-input formControlName="title" />
  </div>

  <div class="archive">
    <m-file-drop
      *ngIf="!archiveFile"
      #filedrop
      [multiple]="false"
      (dropped)="dropped($event)"
      style="--custom-height: 256px"
      accept=".zip"
    >
      <div class="drop-placeholder">
        <div>
          <p class="description-text">
            <ng-container i18n="@@books.upload.drop-text1">books.upload.drop_text1</ng-container>
          </p>
          <p class="description-text">
            &nbsp;
            <span
              class="mark"
              (click)="openFileSelector()"
              [style.color]="'#e02379'"
              i18n="@@books.upload.drop-text2-upload"
            >
              books.upload.drop_text2_upload
            </span>
          </p>
        </div>
      </div>
    </m-file-drop>

    <div *ngIf="archiveFile" class="archive-file">
      <b>{{archiveFile.name}}</b>

      <m-icon
        src="book-delete.svg"
        size="m"
        color="--color-error"
        class="delete"
        (click)="deleteArchiveFile()"
      ></m-icon>
    </div>
  </div>

  <m-button
    button-style="primary"
    (onClick)="onCreateNonEditableTemplate()"
    [is-disabled]="!form.valid || !archiveFile"
    i18n="@@admin.common.create"
  >
    admin.common.create
  </m-button>
</form>
