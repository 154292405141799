<div
  class="editor"
  #editor
  contenteditable="true"
  i18n-data-placeholder="@@editor.placeholder"
  data-placeholder="@@editor.placeholder"
  (input)="onChangeHandle($event)"
  (onFocus)="onFocusHandle($event)"
  (blur)="onBlurHandle($event)"
  (dragenter)="onDragEnterHandle($event)"
  (drop)="onDropHandle($event)"
  (keypress)="onKeyPressHandle($event)"
  (paste)="onPasteHandle($event)"
  (click)="onClickHandle($event)"
></div>

<div *ngIf="hasErrors()" class="error-wrapper">
  <m-ng-icon src="info-3.svg" [size]="24" />
  <div class="errors">
    <div *ngIf="errors?.hasNotExistingCharactersError" class="text">
      <span i18n="@@characters.not-existing.error.text-1">characters.not-existing.error.text-1</span>
      <span class="action" (click)="onSelectCharacterClick()" i18n="@@characters.not-existing.error.action.select">
        characters.not-existing.error.action.select
      </span>
      <span i18n="@@characters.not-existing.error.text-2">characters.not-existing.error.text-2</span>
      <span class="action" (click)="onCreateCharacterClick()" i18n="@@characters.not-existing.error.action.create">
        characters.not-existing.error.action.create
      </span>
    </div>

    <div *ngIf="errors?.hasCharactersLimitError" class="text">
      <span i18n="@@characters.limit.error.text">characters.limit.error.text</span>
      <span>{{ charactersInPromptLimit }}</span>
    </div>
  </div>
</div>
