import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { User } from "@metranpage/user-data";
import { Subscription } from "rxjs";
import { AnalyticsService } from "../../services/analytics.service";
import { NotificationService } from "../../services/notification.service";
import { NotificationStore } from "../../services/notification.store";

@Component({
  selector: "m-account-notifications-icon-view",
  templateUrl: "account-notifications-icon.view.html",
  styleUrls: ["account-notifications-icon.view.scss"],
})
export class AccountNotificationsIconView implements OnInit, OnDestroy {
  @Input()
  user?: User;

  protected unreadNotificationsCount = 0;

  sub: Subscription = new Subscription();

  constructor(
    private readonly notificationService: NotificationService,
    private readonly notificationStore: NotificationStore,
    private readonly analytics: AnalyticsService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.notificationService.getUnreadNotificationsCount();

    this.sub.add(
      this.notificationStore.getUnreadNotificationsCountObservable().subscribe((count) => {
        this.unreadNotificationsCount = count;
        this.cdr.markForCheck();
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getUnreadNotificationsCount() {
    return this.unreadNotificationsCount >= 99 ? "99+" : this.unreadNotificationsCount;
  }

  protected track() {
    this.analytics.event("header-notifications");
  }
}
