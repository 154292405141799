<div class="container">
  <m-localized-text
    class="title"
    i18n-text="@@cover-editor.conceptual-assistant.title"
    text="cover-editor.conceptual-assistant.title"
  />

  <m-new-mark />

  <m-localized-text
    *ngIf="!coverConceptualGeneration"
    class="description"
    i18n-text="@@cover-editor.conceptual-assistant.new.description"
    text="cover-editor.conceptual-assistant.new.description"
  />
  <m-localized-text
    *ngIf="coverConceptualGeneration"
    class="description"
    i18n-text="@@cover-editor.conceptual-assistant.view.description"
    text="cover-editor.conceptual-assistant.view.description"
  />

  <img src="/assets/img/bot_2.png" />

  <m-button button-style="primary" (onClick)="onShowConceptualAssistantMenuClick()">
    <div class="button">
      <span *ngIf="!coverConceptualGeneration" i18n="@@cover-editor.conceptual-assistant.try-button">
        cover-editor.conceptual-assistant.try-button
      </span>
      <span *ngIf="coverConceptualGeneration" i18n="@@cover-editor.conceptual-assistant.view-button">
        cover-editor.conceptual-assistant.view-button
      </span>
      <m-ng-icon src="button-back.svg" [size]="28" />
    </div>
  </m-button>
</div>
