import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { AnalyticsService, LoadingService, LoadingState } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { UserStore } from "@metranpage/user-data";
import { UserVerificationOverlayServiceInterface } from "@metranpage/user-interfaces";
import { Observable } from "rxjs";
import { UserService } from "../../services/user.service";

@Component({
  selector: "m-account-verification-page",
  templateUrl: "./account-verification.page.html",
  styleUrls: ["./account-verification.page.scss"],
})
export class AccountVerificationPage implements OnInit {
  loadingState$: Observable<LoadingState>;

  timeoutTimeLeft = 0;
  email = "";

  protected isEmailSend = false;

  constructor(
    private readonly userService: UserService,
    private readonly userStore: UserStore,
    private readonly loadingService: LoadingService,
    private readonly notificationsService: NotificationsPopUpService,
    @Inject("UserVerificationOverlayService")
    protected readonly userVerificationOverlayService: UserVerificationOverlayServiceInterface,
    private readonly analytics: AnalyticsService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.loadingState$ = this.loadingService.loadingState$;
  }

  ngOnInit(): void {
    const user = this.userStore.getUser();
    if (!user) {
      return;
    }

    this.email = user.email;

    this.analytics.event("popup-confirm-mail");

    this.onSendVerificationEmailClick();
  }

  async onSendVerificationEmailClick() {
    this.notificationsService.closeAll();

    this.loadingService.startLoading();
    const result = await this.userService.sendVerificationEmail();
    if (result === "success") {
      this.timeoutTimeLeft = 10;

      const timer = setInterval(() => {
        this.timeoutTimeLeft--;
        if (this.timeoutTimeLeft <= 0) {
          clearInterval(timer);
        }
        this.cdr.markForCheck();
      }, 1000);

      this.analyticsAction();
    }

    this.loadingService.stopLoading();
  }

  closeModal() {
    this.userVerificationOverlayService.close();

    this.analytics.event("popup-confirm-mail-close");
  }

  private analyticsAction() {
    if (!this.isEmailSend) {
      this.isEmailSend = true;
      this.analytics.event("send-confirmation-mail");
    } else {
      this.analytics.event("confirm-mail-resend");
    }
  }
}
