<m-modal-backdrop (close)="onCloseClick()">
  <div class="container">
    <img [src]="getImageUrl()" />

    <div class="context">
      <span class="header">{{message.header}}</span>
      <span class="text">
        <m-localized-text [text]="message.text"></m-localized-text>
      </span>

      <m-button class="button" button-style="primary" (onClick)="onActionClick()">{{message.button}}</m-button>
    </div>
  </div>
</m-modal-backdrop>
