<div class="container">
  <div class="header">
    <div class="title" i18n="@@cover-editor.conceptual-assistant.menu.title">
      cover-editor.conceptual-assistant.menu.title
    </div>

    <div class="close-icon" (click)="closeConceptualAssistantClick()">
      <m-ng-icon src="close-cross.svg" [size]="12" />
    </div>
  </div>

  <div class="divider-hor"></div>

  @if (!coverConceptualGeneration?.isProcessing) {
  <div class="sidebar-content">
    <!-- <m-cover-conceptual-assistant-generation-menu
      *ngIf="isCoverConceptualGenerationMenuVisible || !coverConceptualGeneration"
      [cover]="cover"
      [coverConceptualGeneration]="coverConceptualGeneration"
      (generateCoverConceptual)="generateCoverConceptualClick($event)"
      (closeConceptualAssistant)="closeConceptualAssistantClick()"
    /> -->

    <m-cover-conceptual-assistant-generation-start-modal
      *ngIf="isCoverConceptualGenerationMenuVisible"
      [cover]="cover"
      [coverConceptualGeneration]="coverConceptualGeneration"
      (generateCoverConceptual)="generateCoverConceptualClick($event)"
      (closeConceptualAssistant)="closeConceptualAssistantClick()"
    />

    <m-cover-conceptual-assistant-generation-step-1
      *ngIf="isCoverConceptualGenerationResultVisible && coverConceptualGeneration && !coverConceptualGeneration.error && step === 1"
      [cover]="cover"
      [coverConceptualGeneration]="coverConceptualGeneration"
      [stylesBasic]="stylesBasic"
      [stylesAdvanced]="stylesAdvanced"
      [stylesCreative]="stylesCreative"
      [fonts]="fonts"
      (generateImage)="onGenerateImageClick($event)"
      (applyFontsAndColors)="onApplyFontsAndColorsClick($event)"
      (refresh)="onRefreshClick()"
      (back)="onBackClick()"
      (closeConceptualAssistant)="closeConceptualAssistantClick()"
    />

    <!-- <m-cover-conceptual-assistant-generation-result
      *ngIf="isCoverConceptualGenerationResultVisible && coverConceptualGeneration"
      [cover]="cover"
      [coverConceptualGeneration]="coverConceptualGeneration"
      [stylesBasic]="stylesBasic"
      [stylesAdvanced]="stylesAdvanced"
      [fonts]="fonts"
      (generateImage)="onGenerateImageClick($event)"
      (applyFontsAndColors)="onApplyFontsAndColorsClick($event)"
      (back)="onBackClick()"
      (closeConceptualAssistant)="closeConceptualAssistantClick()"
    /> -->
  </div>
  } @else {

  <div isCoverConceptualGenerationResultVisible class="processing">
    <div class="spinner-24"></div>
    <div class="timer-text" i18n="@@cover-editor.conceptual-assistant.result.processing">
      cover-editor.conceptual-assistant.result.processing
    </div>
  </div>
  }
</div>
