import { BookCover, TextObject } from "@metranpage/book-data";
import { CoverTextStorage } from "../cover-text-storage";

const localStorageKey = "cover-text-storage";
const keysToTrack = ["title", "author", "subtitle"];

class ObjectData<T> {
  key!: string;
  data!: T;
}

class CoverData<T> {
  coverId!: number;
  data!: ObjectData<T>[];
}

export class CoverTextLocalStorage implements CoverTextStorage {
  constructor(private readonly separate: boolean) {}

  async fromCoverAsync(cover: BookCover): Promise<void> {
    const objects = this.getTextObjects(cover);
    const storageLocation = this.separate ? `${localStorageKey}-${cover.id}` : localStorageKey;
    const storedString = localStorage.getItem(storageLocation);
    let storedCollection: CoverData<string>[] = [];
    let coverData: CoverData<string> | undefined = undefined;
    if (storedString) {
      if (!this.separate) {
        storedCollection = JSON.parse(storedString);
        coverData = storedCollection.find((v) => v.coverId === cover.id);
      } else {
        coverData = JSON.parse(storedString);
      }
    }
    if (coverData) {
      for (const object of objects) {
        const stored = coverData.data.find((v) => v.key === object.id || v.key === object.name);
        if (stored) {
          stored.data = object.text!;
        } else {
          coverData.data.push({
            key: object.id ?? object.name!,
            data: object.text!,
          });
        }
      }
    } else {
      coverData = {
        coverId: cover.id,
        data: objects.map((v) => {
          return {
            key: v.id ?? v.name!,
            data: v.text!,
          };
        }),
      };
      if (!this.separate) {
        storedCollection.push(coverData);
      }
    }
    localStorage.setItem(storageLocation, JSON.stringify(this.separate ? coverData : storedCollection));
  }

  async toCoverAsync(cover: BookCover): Promise<void> {
    const storageLocation = this.separate ? `${localStorageKey}-${cover.id}` : localStorageKey;
    const storedString = localStorage.getItem(storageLocation);
    if (!storedString) {
      return;
    }
    const objects = this.getTextObjects(cover);
    let coverData: CoverData<string> | undefined = undefined;

    if (!this.separate) {
      const storedCollection: CoverData<string>[] = JSON.parse(storedString);
      coverData = storedCollection.find((v) => v.coverId === cover.id);
    } else {
      coverData = JSON.parse(storedString);
    }

    if (!coverData) {
      return;
    }

    for (const stored of coverData.data) {
      const object = objects.find((v) => v.id === stored.key || v.name === stored.key);
      if (object) {
        object.text = stored.data;
      }
    }
  }

  private getTextObjects(cover: BookCover): TextObject[] {
    if (!cover.objects) {
      return [];
    }
    return cover.objects
      .filter(
        (v) =>
          v instanceof TextObject && keysToTrack.some((k) => k === v.id?.toLowerCase() || k === v.name?.toLowerCase()),
      )
      .map((v) => v as TextObject);
  }
}
