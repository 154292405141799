import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { ModerationImageRequest } from "../../models/moderation-image-request";

export type State = {
  moderationImageRequests: ModerationImageRequest[];
  moderationImageRequestsPageCount: number;
};

@Injectable({
  providedIn: "root",
})
export class AdminModerationImageRequestStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      moderationImageRequests: [],
      moderationImageRequestsPageCount: 1,
    };
  }

  getModerationImageRequests() {
    return this.getField("moderationImageRequests");
  }

  getModerationImageRequestsObservable() {
    return this.getFieldObservable("moderationImageRequests");
  }

  setModerationImageRequests(moderationImageRequests: ModerationImageRequest[]) {
    this.update((state) => ({ ...state, moderationImageRequests: moderationImageRequests }));
  }

  getModerationImageRequestsPageCountObservable() {
    return this.getFieldObservable("moderationImageRequestsPageCount");
  }

  setModerationImageRequestsPageCount(count: number) {
    this.update((state) => ({ ...state, moderationImageRequestsPageCount: count }));
  }

  addModerationImageRequest(moderationImageRequest: ModerationImageRequest) {
    this.update((state) => {
      const storeModerationImageRequest = state.moderationImageRequests.find(
        (mi) => mi.id === moderationImageRequest.id,
      );
      if (storeModerationImageRequest) {
        return state;
      }

      return {
        ...state,
        moderationImageRequests: state.moderationImageRequests.concat([moderationImageRequest]),
      };
    });
  }

  addModerationImageRequestsToStart(moderationImageRequests: ModerationImageRequest[]) {
    this.update((state) => {
      const newModerationImageRequests: ModerationImageRequest[] = [];
      for (const moderationImageRequest of moderationImageRequests) {
        const storeModerationImageRequests = state.moderationImageRequests.find(
          (mi) => mi.id === moderationImageRequest.id,
        );
        if (storeModerationImageRequests) {
          continue;
        }
        newModerationImageRequests.push(moderationImageRequest);
      }

      return {
        ...state,
        moderationImageRequests: newModerationImageRequests.concat(state.moderationImageRequests),
      };
    });
  }

  addModerationImageRequestsToEnd(moderationImageRequests: ModerationImageRequest[]) {
    this.update((state) => {
      const newModerationImageRequests: ModerationImageRequest[] = [];
      for (const moderationImageRequest of moderationImageRequests) {
        const storeModerationImageRequests = state.moderationImageRequests.find(
          (mi) => mi.id === moderationImageRequest.id,
        );
        if (storeModerationImageRequests) {
          continue;
        }
        newModerationImageRequests.push(moderationImageRequest);
      }

      return {
        ...state,
        moderationImageRequests: state.moderationImageRequests.concat(newModerationImageRequests),
      };
    });
  }

  updateModerationImageRequest(updatedModerationImageRequest: ModerationImageRequest) {
    const moderationImageRequests = this.getModerationImageRequests();
    const moderationImageRequest = moderationImageRequests.find((mir) => mir.id === updatedModerationImageRequest.id);
    if (moderationImageRequest) {
      this.update((state) => {
        const updatedModerationImageRequests = state.moderationImageRequests.map((mir) => {
          if (mir.id === updatedModerationImageRequest.id) {
            return updatedModerationImageRequest;
          }
          return mir;
        });

        return {
          ...state,
          moderationImageRequests: updatedModerationImageRequests,
        };
      });
    } else {
      this.addModerationImageRequest(updatedModerationImageRequest);
    }
  }

  clearModerationImageRequests() {
    this.update((state) => {
      return {
        ...state,
        moderationImageRequests: [],
        moderationImageRequestsPageCount: 1,
      };
    });
  }
}
