import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActiveSubscription } from "@metranpage/pricing-data";

@Component({
  selector: "m-tariff-active-card",
  templateUrl: "./tariff-active-card.view.html",
  styleUrls: ["./tariff-active-card.view.scss"],
})
export class TariffActiveCardView {
  @Input()
  activeSubscription!: ActiveSubscription;

  @Output()
  onTariffSettingsClick = new EventEmitter<void>();

  ngOnInit(): void {
    this.getExpiredDate();
  }

  protected getExpiredDate() {
    if (!this.activeSubscription.subscription.endingDate) {
      return "";
    }
    return new Date(this.activeSubscription.subscription.endingDate).toLocaleDateString("ru-RU");
  }

  getTextLeft() {
    return `${$localize`:@@pricing.tariffs.tariff-active-card.expire:`} ${this.getExpiredDate()}`;
  }

  getTitleLeft(): string {
    return `${$localize`:@@pricing.tariffs.tariff-active-card.tariff-title:`} ${this.activeSubscription.tariff.title}`;
  }

  getLocalizedTitle(): string {
    return $localize`:@@books.info-modal.free-layout.title!`;
  }
}
