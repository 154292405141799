<form *ngIf="form" [formGroup]="form">
  <div formGroupName="styles">
    <div [formGroupName]="style.styleKey" class="container">
      <span class="style-settings-title inline-text" i18n="@@books.markup.style-details.text settings-title">
        books.markup.style-details.text settings-title
      </span>

      <div class="row">
        <m-markup-font-settings-input
          *ngIf="style.availableControls.includes('font-size')"
          formControlName="fontSize"
          icon="markup-font-size.svg"
          [step]="1"
          [multiplier]="10"
          i18n-tooltipText="@@books.markup.style-details.font-size-tooltip"
          tooltipText="books.markup.style-details.font-size-tooltip"
        />
        <m-markup-font-settings-input
          *ngIf="style.availableControls.includes('leading')"
          formControlName="leading"
          icon="markup-leading.svg"
          units="%"
          [step]="10"
          [multiplier]="5"
          i18n-tooltipText="@@books.markup.style-details.leading-tooltip"
          tooltipText="books.markup.style-details.leading-tooltip"
        />
        <m-markup-font-settings-input
          *ngIf="style.availableControls.includes('tracking')"
          formControlName="tracking"
          icon="markup-tracking.svg"
          [step]="10"
          [multiplier]="10"
          i18n-tooltipText="@@books.markup.style-details.tracking-tooltip"
          tooltipText="books.markup.style-details.tracking-tooltip"
        />
      </div>
      <m-info-block *ngIf="warnings.length > 0" type="warning" [labels]="warnings" />
    </div>
  </div>
</form>
