import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { CoverSnippetAccess } from "../../models/cover-snippet/cover-snippet-access";

export type State = {
  coverSnippetsAccess: CoverSnippetAccess[];
  coverSnippetsAccessPageCount: number;
};

@Injectable({
  providedIn: "root",
})
export class AdminCoverSnippetsAccessStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      coverSnippetsAccess: [],
      coverSnippetsAccessPageCount: 1,
    };
  }

  getCoverSnippetsAccessObservable() {
    return this.getFieldObservable("coverSnippetsAccess");
  }

  setCoverSnippetsAccess(coverSnippetsAccess: CoverSnippetAccess[]) {
    this.update((state) => ({ ...state, coverSnippetsAccess }));
  }

  getCoverSnippetsAccessPageCountObservable() {
    return this.getFieldObservable("coverSnippetsAccessPageCount");
  }

  setCoverSnippetsAccessPageCount(count: number) {
    this.update((state) => ({ ...state, coverSnippetsAccessPageCount: count }));
  }
}
