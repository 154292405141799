import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsPopUpService } from "@metranpage/core";
import { ConnectTelegramService } from "../../services/connect-telegram.service";

@Component({
  selector: "m-account-connect-telegram-page",
  template: `
    <m-loading-spinner></m-loading-spinner>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100vw;
        height: 100vh;
      }
    `,
  ],
})
export class AccountConnectTelegramPage implements OnInit {
  constructor(
    private readonly notificationService: NotificationsPopUpService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly connectTelegramService: ConnectTelegramService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.queryParamMap.subscribe(async (params) => {
      const token = params.get("token");
      if (token) {
        await this.connectTelegram(token);
      }

      this.router.navigate(["/"]);
    });
  }

  protected async connectTelegram(token: string) {
    this.notificationService.closeAll();
    const result = await this.connectTelegramService.createTelegramProfile({ token });
    if (result.status === "success") {
      this.notificationService.notify({
        content: $localize`:@@user.profile.socials.telegram-profile-connected:`,
        type: "success",
      });
    }
    if (result.status === "error") {
      if (result.error === "expired-token") {
        this.notificationService.error($localize`:@@user.profile.socials.telegram.token-expire-error:`);
        return;
      }
      if (result.error === "account-already-connected") {
        this.notificationService.error($localize`:@@user.profile.socials.telegram.account-already-connected-error:`);
        return;
      }
      if (result.error === "account-already-connected-to-another-account") {
        this.notificationService.error(
          $localize`:@@user.profile.socials.telegram.account-already-connected-to-another-account-error:`,
        );
        return;
      }
      this.notificationService.error($localize`:@@user.profile.socials.telegram-profile-not-found-error:`);
    }
  }
}
