import { Routes } from "@metranpage/core";
import { SuperadminAuthPage } from "./pages/auth/auth.page";
import { AdminCompaniesPage } from "./pages/companies/companies.page";
import { SuperadminCompanyAdminsSettingsPage } from "./pages/company-settings-admins/company-settings-admins.page";
import { SuperadminCompanyCommonSettingsPage } from "./pages/company-settings-common/company-settings-common.page";
import { SuperadminCompanySettingsHostPage } from "./pages/company-settings-host/company-settings-host.page";
import { SuperadminHostPage } from "./pages/superadmin-host.page";

export const superadminRoutes: Routes = [
  {
    path: "superadmin/auth",
    component: SuperadminAuthPage,
    data: {
      showTopBar: false,
      showMobileWarning: false,
    },
  },
  {
    path: "superadmin",
    component: SuperadminHostPage,
    data: {
      showTopBar: true,
      showMobileWarning: true,
      breadcrumbs: {
        titleKey: "admin",
      },
    },
    children: [
      {
        path: "companies",
        component: AdminCompaniesPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "companies/:companyId",
        component: SuperadminCompanySettingsHostPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
        children: [
          {
            path: "settings",
            component: SuperadminCompanyCommonSettingsPage,
          },
          {
            path: "admins",
            component: SuperadminCompanyAdminsSettingsPage,
          },
        ],
      },
    ],
  },
  /*
      {
        path: "templates",
        component: AdminTemplatesPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "templates/:templateId",
        component: AdminTemplatePage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },

      {
        path: "feature-flags",
        component: FeatureTogglesPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },

      {
        path: "companies",
        component: AdminCompaniesPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },

      {
        path: "tariffs",
        component: AdminTariffsPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "tariffs/:tariffId",
        component: AdminTariffPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },

      {
        path: "promocodes",
        component: AdminPromocodesPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "promocodes/:promocodeId",
        component: AdminPromocodePage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },

      {
        path: "referal-links",
        component: AdminReferalLinksPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "referal-links/:referalLinkId",
        component: AdminReferalLinkPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "referal-links/:referalLinkId/stats",
        component: AdminReferalLinkStatsPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
    ],
  }, */
];
