import { Injectable } from "@angular/core";
import { BookService } from "@metranpage/book";
import { TemplateWithPreviewOnly } from "@metranpage/book-data";
import { GeneralResultStatus } from "@metranpage/core-data";
import { TemplateAccess, TemplateAccessDto, TemplateAccessUpdateData } from "../../models/template-access";
import { AdminTemplatesService } from "../templates/templates.service";
import { AdminTemplatesAccessApi, TemplatesAccessFilter } from "./templates-access.api";
import { AdminTemplatesAccessStore } from "./templates-access.store";

@Injectable({
  providedIn: "root",
})
export class AdminTemplatesAccessService {
  constructor(
    private readonly adminTemplatesService: AdminTemplatesService,
    private readonly adminTemplatesAccessApi: AdminTemplatesAccessApi,
    private readonly adminTemplatesAccessStore: AdminTemplatesAccessStore,
    private readonly bookService: BookService,
  ) {}

  async loadTemplatesAccess() {
    const templatesAccessData = await this.adminTemplatesAccessApi.getTemplatesAccess();
    const templatesAccess = await Promise.all(
      templatesAccessData.map(async (t) => {
        return await this.prepareTemplateAccess(t);
      }),
    );
    return templatesAccess;
  }

  async loadTemplatesAccessPaginated(page: number, filters: TemplatesAccessFilter = {}) {
    const templatesData = await this.adminTemplatesAccessApi.getTemplatesAccessPaginated(page, filters);
    const templates = await Promise.all(
      templatesData.items.map(async (t) => {
        return await this.prepareTemplateAccess(t);
      }),
    );
    this.adminTemplatesAccessStore.setTemplatesAccess(templates);
    this.adminTemplatesAccessStore.setTemplatesAccessPageCount(templatesData.pageCount);
  }

  async prepareTemplateAccess(t: TemplateAccessDto) {
    const template = this.bookService.updateTemplateLocalization(t.template);
    const previewsLoc = await this.adminTemplatesService.getTemplatePreviewsLocalizations(t.templateId);
    const previews = Object.values(previewsLoc)[0]?.map((preview) => preview.url);
    const templateWithPreview: TemplateWithPreviewOnly = {
      ...template,
      previews,
    };
    const templateAccess: TemplateAccess = {
      ...t,
      template: templateWithPreview,
    };

    return templateAccess;
  }

  async updateTemplateAccess(id: number, data: TemplateAccessUpdateData): Promise<GeneralResultStatus> {
    try {
      await this.adminTemplatesAccessApi.updateTemplateAccess(id, data);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }
}
