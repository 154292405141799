import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { slideInOutVertical } from "@metranpage/components";
import { Subscription, timer } from "rxjs";

export type GenerationImageColorScheme = "fullcolor" | "black and white" | "custom";
export type GenerationImageColorSettings = {
  colorScheme: GenerationImageColorScheme;
  colors: GenerationImageCustomColor[];
};
export type GenerationImageCustomColor =
  | "black"
  | "gray"
  | "white"
  | "red"
  | "orangered"
  | "orange"
  | "goldenrod"
  | "yellow"
  | "chartreuse"
  | "green"
  | "teal"
  | "blue"
  | "violet"
  | "purple"
  | "magenta";

@Component({
  selector: "m-color-scheme-selector",
  templateUrl: "./color-scheme-selector.view.html",
  styleUrls: ["./color-scheme-selector.view.scss"],
  animations: [slideInOutVertical],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorSchemeSelectorView),
      multi: true,
    },
  ],
})
export class ColorSchemeSelectorView {
  private onTouched = () => {};
  private onChange = (_: any) => {};

  protected value: GenerationImageColorSettings = {
    colorScheme: "fullcolor",
    colors: [],
  };

  protected colorSelectorTimer?: Subscription;
  protected isColorSelectorVisible = false;
  protected isCollapsed = false;
  protected colorsGroups: [GenerationImageCustomColor[], GenerationImageCustomColor[], GenerationImageCustomColor[]] = [
    ["black", "gray", "white", "red", "orangered"],
    ["orange", "goldenrod", "yellow", "chartreuse", "green"],
    ["teal", "blue", "violet", "purple", "magenta"],
  ];

  protected isDisabled = false;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  writeValue(value: GenerationImageColorSettings): void {
    this.value = value;
    this.cdr.detectChanges();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  isColorSchemeCustom() {
    return this.value.colorScheme === "custom";
  }

  isColorSchemeActive(colorScheme: GenerationImageColorScheme) {
    return this.value.colorScheme === colorScheme;
  }

  isAddColorVisible() {
    return this.value.colorScheme === "custom" && this.value.colors.length < 3;
  }

  onSelect(colorScheme: GenerationImageColorScheme) {
    this.value.colorScheme = colorScheme;
    this.onChange(this.value);
  }

  toggleColorSelector() {
    this.isColorSelectorVisible = !this.isColorSelectorVisible;
  }

  onColorSelectorMouseEnter() {
    this.isColorSelectorVisible = true;
    this.colorSelectorTimer?.unsubscribe();
  }

  onColorSelectorMouseLeave() {
    this.colorSelectorTimer = timer(1000).subscribe(() => {
      this.isColorSelectorVisible = false;
      this.cdr.detectChanges();
    });
  }

  onColorAdd(color: GenerationImageCustomColor) {
    if (this.value.colors.includes(color)) {
      return;
    }
    this.value.colors.push(color);
    this.isColorSelectorVisible = false;

    this.onChange(this.value);
  }

  onColorDelete(color: GenerationImageCustomColor) {
    this.value.colors = this.value.colors.filter((c) => c !== color);
    this.isColorSelectorVisible = false;

    this.onChange(this.value);
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    const index = this.value.colors.length + 1;
    if (index === 1) {
      result.push("color-add-position-first");
    }
    if (index === 3) {
      result.push("color-add-position-last");
    }

    return result;
  }
}
