import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL, COMPANY_UUID, DOWNLOAD_FILE_TEMPLATE, FILES_URL } from "@metranpage/core-api";
import { saveAs } from "file-saver";
import { DownloadGeneratedImageData, GeneratedImage, GeneratedImagesData } from "../models/generated-image";

@Injectable({
  providedIn: "root",
})
export class GeneratedImageApi extends Api {
  constructor(
    http: HttpClient,
    @Inject(BASE_URL) baseUrl: string,
    @Inject(DOWNLOAD_FILE_TEMPLATE) private readonly downloadFileTemplate: string,
    @Inject(COMPANY_UUID) private companyUuid: string,
    @Inject(FILES_URL) private filesUrl: string,
  ) {
    super(http, baseUrl);
  }

  async getGeneratedImageById(id: number) {
    return this.get<GeneratedImage | undefined>(`generated-image/${id}`);
  }

  async getGeneratedImageByIdUnauthorized(id: number) {
    return this.get<GeneratedImage | undefined>(`generated-image-unauthorized/published/${id}`);
  }

  async loadPublishedImagesPaginated(page: number) {
    return this.get<GeneratedImagesData>("generated-image/published/paginated", {
      page,
    });
  }

  async loadPublishedImagesPaginatedUnauthorized(page: number) {
    return this.get<GeneratedImagesData>("generated-image-unauthorized/published/paginated", {
      companyUuid: this.companyUuid,
      page,
    });
  }

  async loadPublishedImages() {
    return this.get<GeneratedImage[]>("generated-image/published/all");
  }

  async loadGeneratedImages() {
    return this.get<GeneratedImage[]>("generated-image/all");
  }

  async publishGeneratedImage(id: number) {
    return this.post("generated-image/publish", { id });
  }

  async unpublishGeneratedImage(id: number) {
    return this.post("generated-image/unpublish", { id });
  }

  async deleteGeneratedImage(id: number) {
    return this.delete(`generated-image/${id}`);
  }

  getUrlForFile(file: { vid: string }) {
    return `${this.filesUrl}/${file.vid}`;
  }

  // getUrlForImage(generationId: number, imagePath: string) {
  //   return this.absoluteUrl(`generated-image/${generationId}/previews/${imagePath}`);
  // }
  //
  // getUrlForThumbnailImage(generationId: number, imagePath: string) {
  //   return this.absoluteUrl(`generated-image/${generationId}/previews/thumbnail/${imagePath}`);
  // }

  async downloadGeneratedImage(downloadGeneratedImageData: DownloadGeneratedImageData) {
    let ext = downloadGeneratedImageData.imageUrl.split(".").pop();
    if (!ext) {
      ext = "jpg";
    }
    ext = ext.toLowerCase();

    let prefix = this.downloadFileTemplate.replace("_{id}", "").replace("_{filename}", "");
    if (prefix) {
      prefix += "_";
    }
    const imageName = `image_${downloadGeneratedImageData.imageGenerationId}.${ext}`;
    return (
      this.getAsBlob(
        `generated-image/${downloadGeneratedImageData.imageGenerationId}/previews/${downloadGeneratedImageData.imageUrl}`,
      )
        // .then((res) => res.blob())
        .then((blob) => saveAs(blob, `${prefix}${imageName}`))
    );
  }

  async likeGeneratedImage(id: number) {
    return this.post("generated-image/like", { id });
  }

  async unlikeGeneratedImage(id: number) {
    return this.post("generated-image/unlike", { id });
  }
}
