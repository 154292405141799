<form *ngIf="form" [formGroup]="form">
  <div formGroupName="styles">
    <div [formGroupName]="style.styleKey" class="container">
      <div class="column">
        <div class="row">
          <span class="style-settings-title inline-text" i18n="@@books.markup.style-details.indent-paragraph-title">
            books.markup.style-details.indent-paragraph-title
          </span>

          <m-markup-style-details-indent-paragraph-buttons-row formControlName="indentParagraph" />
        </div>

        <div class="row space-between">
          <span
            class="style-settings-title inline-text"
            i18n="@@books.markup.style-details.indent-paragraph-after-header-title"
          >
            books.markup.style-details.indent-paragraph-after-header-title
          </span>

          <m-switch formControlName="indentParagraphAfterHeader" />
        </div>

        <m-info-block type="warning" [labels]="getInfoLabels()" />
      </div>
    </div>
  </div>
</form>
