<m-app-bar
  *ngIf="shouldShowTopBar"
  [user]="user"
  [userBalance]="userBalance!"
  [isLanguageSwitchAvailable]="isLanguageSwitchAvailable()"
  [hideNavbarLogo]="configService.getConfig().company.flags.hideNavbarLogo"
  [hideNavbarRightParts]="configService.getConfig().company.flags.hideNavbarRightParts"
  (onThemeToggle)="onThemeToggle()"
  (onLanguageChange)="onLanguageChange($event)"
  (onLogoutClick)="onLogoutClick()"
  (onPromocodeEnter)="onPromocodeEnter($event)"
></m-app-bar>

<router-outlet></router-outlet>

<router-outlet name="modal"></router-outlet>

<m-notification-host></m-notification-host>

<m-mobile-alert></m-mobile-alert>

<!-- <m-welcome-modal
  *ngIf="!userActionMessageRead && (userActionMessage$ | async) as message"
  [message]="message"
  (actionClick)="onModalActionClick($event, true)"
  (close)="onModalActionClick($event, false)"
></m-welcome-modal> -->

<m-welcome-webinar-modal
  *ngIf="isWebinarModalVisible"
  (actionClick)="onWebinarModalActionClick($event, true)"
  (close)="onWebinarModalActionClick($event, false)"
/>

<m-loading-spinner *ngIf="isLoading" @fadeInOut>{{loadingDescription}}</m-loading-spinner>

<m-account-connect-telegram-modal-view
  *ngIf="isAccountConnectTelegramModalVisible"
  [user]="user"
  [userRewardOneTime]="getSubscribeToTelegramChannelReward()"
  (onConnect)="onConnectTelegramClick()"
  (onSubscribeToChannel)="onSubscribeToChannelTelegramClick()"
  (onCheckSubscribeToChannel)="onCheckSubscribeToChannelTelegramClick()"
  (onClose)="hideAccountConnectTelegramModal()"
/>

<m-account-verification-page
  *ngIf="userVerificationOverlayService.asObservable() | async"
></m-account-verification-page>

<m-select-new-project-type-modal
  *ngIf="isSelectNewProjectTypeModalVisible"
  (select)="onSelectNewProjectType($event)"
  (close)="closeSelectNewProjectTypeModal()"
/>
