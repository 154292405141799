<m-account-notification-card-host [notification]="notification" [isLast]="isLast">
  <div icon class="icons-wrapper">
    <m-ng-icon class="icon-main" [size]="24" src="notification-info-2.svg" />
  </div>

  <div title class="title-group">
    <div class="title">
      <span>{{ getTitle() }}</span>
    </div>

    <div class="card-label" i18n="@@user.profile.notifications.filter.important">
      user.profile.notifications.filter.important
    </div>
  </div>

  <div description class="description">
    <div *ngIf="payload.daysLeft === 3" class="row">
      <span i18n="@@user.profile.notifications.card.trial-period-ending.3-days-left.description-1">
        user.profile.notifications.card.trial-period-ending.3-days-left.description-1
      </span>
      <a
        class="action"
        i18n="@@user.profile.notifications.card.trial-period-ending.description-action"
        (click)="openTariffsPage()"
      >
        user.profile.notifications.card.trial-period-ending.description-action
      </a>
      <span i18n="@@user.profile.notifications.card.trial-period-ending.3-days-left.description-2">
        user.profile.notifications.card.trial-period-ending.3-days-left.description-2
      </span>
    </div>
    <div *ngIf="payload.daysLeft === 1" class="row">
      <span i18n="@@user.profile.notifications.card.trial-period-ending.1-day-left.description-1">
        user.profile.notifications.card.trial-period-ending.1-day-left.description-1
      </span>
      <a
        class="action"
        i18n="@@user.profile.notifications.card.trial-period-ending.description-action"
        (click)="openTariffsPage()"
      >
        user.profile.notifications.card.trial-period-ending.description-action
      </a>
      <span i18n="@@user.profile.notifications.card.trial-period-ending.1-day-left.description-2">
        user.profile.notifications.card.trial-period-ending.1-day-left.description-2
      </span>
    </div>
    <div class="row">
      <span i18n="@@user.profile.notifications.card.trial-period-ending.description">
        user.profile.notifications.card.trial-period-ending.description
      </span>
      <span>{{ getDateShort() }}</span>
    </div>
  </div>
</m-account-notification-card-host>
