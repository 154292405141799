<m-ng-island [paddingSize]="24">
  <form *ngIf="!!template && form; else noTemplate" [formGroup]="form">
    <p class="section-title" i18n="@@admin.templates.template.editable-settings">
      admin.templates.template.editable-settings
    </p>
    <m-switch i18n="@@admin.templates.template.is-editable" formControlName="isEditable">
      admin.templates.template.is-editable
    </m-switch>
    <div class="row">
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.non-editable-template-name">
          admin.templates.template.non-editable-template-name
        </span>
        <m-select formControlName="nonEditableTemplateId" [options]="nonEditableTemplateOptions"></m-select>
      </div>
    </div>
    <div class="row">
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.images-size">admin.templates.template.images-size</span>
        <m-select formControlName="imagesSize" [options]="getImagesSizeOptions()"></m-select>
      </div>
    </div>
    <div class="row">
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.images-crop-class">
          admin.templates.template.images-crop-class
        </span>
        <m-select formControlName="imagesCropClass" [options]="getImagesCropClassOptions()"></m-select>
      </div>
    </div>
    <div class="control">
      <m-switch i18n="@@admin.templates.template.images-settings-available" formControlName="isImagesSettingsAvailable">
        admin.templates.template.images-settings-available
      </m-switch>
    </div>

    <div class="divider"></div>

    <div class="control">
      <span class="label" i18n="@@admin.templates.template.title">admin.templates.template.title</span>
      <m-ng-input formControlName="title" />
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.templates.template.description-short">
        admin.templates.template.description-short
      </span>
      <m-ng-input formControlName="descriptionShort" />
    </div>

    <div class="control">
      <span class="label" i18n="@@admin.templates.template.description-long">
        admin.templates.template.description-long
      </span>

      <span class="label" i18n="@@admin.templates.template.description-long-tags-description">
        admin.templates.template.description-long-tags-description
      </span>

      <m-textarea class="textarea" [rows]="15" formControlName="descriptionLong" type="normal" />

      <span class="label" i18n="@@admin.templates.template.description-long-preview">
        admin.templates.template.description-long-preview
      </span>
      <m-localized-text
        class="description-long-preview"
        [text]="form.get('descriptionLong')?.value ?? ''"
      ></m-localized-text>
    </div>

    <div class="previews-loc">
      <div *ngFor="let lang of availableLanguages; let i = index" class="previews">
        <span class="lang">{{ lang.value }}</span>
        <m-file-drop
          #filedrop
          (dropped)="dropped($event, lang.value)"
          style="--custom-height: 256px; flex-shrink: 0"
          accept="image/*"
        >
          <div class="drop-placeholder">
            <div>
              <p class="description-text">
                <ng-container i18n="@@books.upload.drop-text1">books.upload.drop_text1</ng-container>
              </p>
              <p class="description-text">
                &nbsp;
                <span
                  class="mark"
                  (click)="openFileSelector(i)"
                  [style.color]="'#e02379'"
                  i18n="@@books.upload.drop-text2-upload"
                >
                  books.upload.drop_text2_upload
                </span>
              </p>
            </div>
          </div>
        </m-file-drop>

        <div class="preview-images">
          <div *ngFor="let preview of previews[lang.value]" class="preview-image">
            <img [src]="preview.url" alt="" />
            <m-icon
              src="book-delete.svg"
              size="m"
              color="--color-error"
              class="delete"
              (click)="deletePreview(preview.id)"
            ></m-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <p class="section-title" i18n="@@admin.templates.template.section-sizes">admin.templates.template.section-sizes</p>

    <div class="row">
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.width-from">admin.templates.template.width-from</span>
        <m-ng-input-number formControlName="widthFrom" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.width-to">admin.templates.template.width-to</span>
        <m-ng-input-number formControlName="widthTo" />
      </div>
    </div>

    <div class="row">
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.height-from">admin.templates.template.height-from</span>
        <m-ng-input-number formControlName="heightFrom" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.height-to">admin.templates.template.height-to</span>
        <m-ng-input-number formControlName="heightTo" />
      </div>
    </div>

    <div class="divider"></div>

    <p class="section-title" i18n="@@admin.templates.template.section-styles">
      admin.templates.template.section-styles
    </p>

    <div class="row">
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.font-main">admin.templates.template.font-main</span>
        <m-select formControlName="fontMain" [options]="availableMainFonts"></m-select>
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.font-heading">admin.templates.template.font-heading</span>
        <m-select formControlName="fontHeading" [options]="availableHeadersFonts"></m-select>
      </div>

      <div class="control">
        <span class="label" i18n="@@admin.templates.template.main-text-size">
          admin.templates.template.main-text-size
        </span>
        <m-ng-input-number formControlName="mainSize" />
      </div>
    </div>

    <div class="divider"></div>

    <p class="section-title" i18n="@@admin.templates.template.section-colors">
      admin.templates.template.section-colors
    </p>

    <div class="row">
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.color-accent">admin.templates.template.color-accent</span>
        <m-ng-input formControlName="colorAccentHex" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.color-primary">
          admin.templates.template.color-primary
        </span>
        <m-ng-input formControlName="colorPrimaryHex" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.color-secondary">
          admin.templates.template.color-secondary
        </span>
        <m-ng-input formControlName="colorSecondaryHex" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.color-decor">admin.templates.template.color-decor</span>
        <m-ng-input formControlName="colorDecorHex" />
      </div>
    </div>

    <div class="divider"></div>

    <p class="section-title" i18n="@@admin.templates.template.section-layout">
      admin.templates.template.section-layout
    </p>

    <m-switch i18n="@@admin.templates.template.center-images" formControlName="centerImages">
      admin.templates.template.center-images
    </m-switch>

    <div class="row">
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.margin-top">admin.templates.template.margin-top</span>
        <m-ng-input-number formControlName="marginTop" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.margin-bottom">
          admin.templates.template.margin-bottom
        </span>
        <m-ng-input-number formControlName="marginBottom" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.margin-outer">admin.templates.template.margin-outer</span>
        <m-ng-input-number formControlName="marginOuter" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.margin-inner">admin.templates.template.margin-inner</span>
        <m-ng-input-number formControlName="marginInner" />
      </div>
    </div>

    <div class="row">
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.columns-count">
          admin.templates.template.columns-count
        </span>
        <m-ng-input-number formControlName="columnsCount" />
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.gutter">admin.templates.template.gutter</span>
        <m-ng-input-number formControlName="gutter" />
      </div>
    </div>

    <m-switch i18n="@@admin.templates.template.wide-padding" formControlName="widePaddingState">
      admin.templates.template.wide-padding
    </m-switch>
    <div class="row">
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.wide-padding-left-pos">
          admin.templates.template.wide-padding-left-pos
        </span>
        <m-select formControlName="widePaddingLeftPosition" [options]="widePaddingPositionOptions"></m-select>
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.wide-padding-right-pos">
          admin.templates.template.wide-padding-right-pos
        </span>
        <m-select formControlName="widePaddingRightPosition" [options]="widePaddingPositionOptions"></m-select>
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.wide-padding-size">
          admin.templates.template.wide-padding-size
        </span>
        <m-ng-input-number formControlName="widePadding" />
      </div>
    </div>

    <div class="divider"></div>

    <p class="section-title" i18n="@@admin.templates.template.section-running-title">
      admin.templates.template.section-running-title
    </p>

    <m-switch i18n="@@admin.templates.template.rt-state" formControlName="runningTitleState">
      admin.templates.template.rt-state
    </m-switch>
    <div class="row">
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.rt-left">admin.templates.template.rt-left</span>
        <m-select formControlName="runningTitleLeft" [options]="runningTitleTextOptions"></m-select>
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.rt-right">admin.templates.template.rt-right</span>
        <m-select formControlName="runningTitleRight" [options]="runningTitleTextOptions"></m-select>
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.rt-position">admin.templates.template.rt-position</span>
        <m-select formControlName="runningTitlePosition" [options]="runningTitlePositionOptions"></m-select>
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.rt-style">admin.templates.template.rt-style</span>
        <m-ng-input-number formControlName="runningTitleStyle" />
      </div>
    </div>

    <m-switch i18n="@@admin.templates.template.pn-state" formControlName="pageNumberState">
      admin.templates.template.pn-state
    </m-switch>
    <div class="row">
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.pn-position">admin.templates.template.pn-position</span>
        <m-select formControlName="pageNumberPosition" [options]="runningTitlePositionOptions"></m-select>
      </div>
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.pn-style">admin.templates.template.pn-style</span>
        <m-ng-input-number formControlName="pageNumberStyle" />
      </div>
    </div>

    <div class="divider"></div>

    <p class="section-title" i18n="@@admin.templates.template.toc">admin.templates.template.toc</p>
    <m-switch i18n="@@admin.templates.template.toc-visible" formControlName="tocVisible">
      admin.templates.template.toc-visible
    </m-switch>
    <div class="row">
      <div class="control">
        <span class="label" i18n="@@admin.templates.template.toc-position">admin.templates.template.toc-position</span>
        <m-select formControlName="tocPosition" [options]="tocPositionOptions"></m-select>
      </div>
    </div>

    <div class="divider"></div>

    <p class="section-title" i18n="@@admin.templates.template.styles-title">admin.templates.template.styles-title</p>
    <div class="styles" formGroupName="styles">
      <div class="style" *ngFor="let style of getStyleKeys()" [formGroupName]="style">
        <m-checkbox-group formControlName="availableControls">
          <p class="section-title">{{ style }}</p>

          <div class="control">
            <span class="label" i18n="@@admin.templates.template.styles-title">
              admin.templates.template.styles-title
            </span>
            <m-ng-input formControlName="styleKey" />
          </div>

          <div class="control" formGroupName="localization">
            <span class="label" i18n="@@admin.templates.template.styles.localization">
              admin.templates.template.styles.localization
            </span>

            <div *ngFor="let local of getStyleLocalization(style)" class="row">
              <span>{{local}}</span>
              <m-ng-input [formControlName]="local" />
            </div>
          </div>

          <div class="control">
            <span class="label" i18n="@@admin.templates.template.styles.font">
              admin.templates.template.styles.font
            </span>
            <m-select formControlName="font" [options]="getStyleFonts(style)"></m-select>
          </div>

          <div class="editable-group">
            <m-checkbox-for-group value="font-size" i18n="@@admin.templates.template.styles.font-size">
              admin.templates.template.styles.font-size
            </m-checkbox-for-group>
            <div class="control">
              <!-- <span class="label" i18n="@@admin.templates.template.styles.font-size">
              admin.templates.template.styles.font-size
            </span> -->
              <m-ng-input-number formControlName="fontSize" />
            </div>
          </div>

          <div class="editable-group">
            <m-checkbox-for-group value="leading" i18n="@@admin.templates.template.styles.leading">
              admin.templates.template.styles.leading
            </m-checkbox-for-group>
            <div class="control">
              <!-- <span class="label" i18n="@@admin.templates.template.styles.leading">
              admin.templates.template.styles.leading
            </span> -->
              <m-ng-input-number formControlName="leading" />
            </div>
          </div>

          <div class="editable-group">
            <m-checkbox-for-group value="tracking" i18n="@@admin.templates.template.styles.tracking">
              admin.templates.template.styles.tracking
            </m-checkbox-for-group>
            <div class="control">
              <!-- <span class="label" i18n="@@admin.templates.template.styles.tracking">
              admin.templates.template.styles.tracking
            </span> -->
              <m-ng-input-number formControlName="tracking" />
            </div>
          </div>

          <div class="editable-group">
            <m-checkbox-for-group value="emphasis" i18n="@@admin.templates.template.styles.emphasis">
              admin.templates.template.styles.emphasis
            </m-checkbox-for-group>
            <div class="control" *ngIf="styles[style].availableControls.includes('emphasis')">
              <!-- <span class="label" i18n="@@admin.templates.template.styles.emphasis">
              admin.templates.template.styles.emphasis
            </span> -->
              <m-select formControlName="emphasis" [options]="emphasisOptions"></m-select>
            </div>
          </div>

          <div class="editable-group">
            <m-checkbox-for-group value="align" i18n="@@admin.templates.template.styles.align">
              admin.templates.template.styles.align
            </m-checkbox-for-group>
            <div class="control" *ngIf="styles[style].availableControls.includes('align')">
              <!-- <span class="label" i18n="@@admin.templates.template.styles.align">
              admin.templates.template.styles.align
            </span> -->
              <m-select formControlName="align" [options]="alignOptions"></m-select>
            </div>
          </div>

          <div class="editable-group">
            <m-checkbox-for-group value="align-maintext" i18n="@@admin.templates.template.styles.align-maintext">
              admin.templates.template.styles.align-maintext
            </m-checkbox-for-group>
            <div class="control" *ngIf="styles[style].availableControls.includes('align-maintext')">
              <!-- <span class="label" i18n="@@admin.templates.template.styles.align">
              admin.templates.template.styles.align
            </span> -->
              <m-select formControlName="align" [options]="alignMainTextOptions"></m-select>
            </div>
          </div>

          <div class="editable-group">
            <m-checkbox-for-group value="case" i18n="@@admin.templates.template.styles.case">
              admin.templates.template.styles.case
            </m-checkbox-for-group>
            <div class="control" *ngIf="styles[style].availableControls.includes('case')">
              <!-- <span class="label" i18n="@@admin.templates.template.styles.case">
              admin.templates.template.styles.case
            </span> -->
              <m-select formControlName="case" [options]="caseOptions"></m-select>
            </div>
          </div>

          <div class="editable-group">
            <m-checkbox-for-group value="startsOn" i18n="@@admin.templates.template.styles.start-on">
              admin.templates.template.styles.start-on
            </m-checkbox-for-group>
            <div class="control" *ngIf="styles[style].availableControls.includes('startsOn')">
              <!-- <span class="label" i18n="@@admin.templates.template.styles.start-on">
              admin.templates.template.styles.start-on
            </span> -->
              <m-select formControlName="startsOn" [options]="startOnOptions"></m-select>
            </div>
          </div>

          <div class="control" *ngIf="styles[style].availableControls.includes('oneOnPage')">
            <span class="label" i18n="@@admin.templates.template.styles.one-on-page">
              admin.templates.template.styles.one-on-page
            </span>
            <m-switch formControlName="oneOnPage" />
          </div>

          <div class="editable-group">
            <m-checkbox-for-group value="drop-cap" i18n="@@admin.templates.template.styles.drop-cap">
              admin.templates.template.styles.drop-cap
            </m-checkbox-for-group>
            <div class="control" *ngIf="styles[style].availableControls.includes('drop-cap')">
              <span class="label" i18n="@@admin.templates.template.styles.drop-cap-chars">
                admin.templates.template.styles.drop-cap-chars
              </span>
              <m-ng-input-number formControlName="dropCapChars" />

              <span class="label" i18n="@@admin.templates.template.styles.drop-cap-lines">
                admin.templates.template.styles.drop-cap-lines
              </span>
              <m-ng-input-number formControlName="dropCapLines" />
            </div>
          </div>

          <div class="editable-group">
            <m-checkbox-for-group value="indent-paragraph" i18n="@@admin.templates.template.styles.indent-paragraph">
              admin.templates.template.styles.indent-paragraph
            </m-checkbox-for-group>
            <div class="control" *ngIf="styles[style].availableControls.includes('indent-paragraph')">
              <!-- <span class="label" i18n="@@admin.templates.template.styles.indent-paragraph">
              admin.templates.template.styles.indent-paragraph
            </span> -->
              <m-select formControlName="indentParagraph" [options]="indentParagraphOptions"></m-select>

              <span class="label" i18n="@@admin.templates.template.styles.indent-paragraph-after-header">
                admin.templates.template.styles.indent-paragraph-after-header
              </span>
              <m-switch formControlName="indentParagraphAfterHeader" />
            </div>
          </div>

          <div class="editable-group">
            <m-checkbox-for-group value="indent-header" i18n="@@admin.templates.template.styles.indent-header">
              admin.templates.template.styles.indent-header
            </m-checkbox-for-group>
            <div class="control" *ngIf="styles[style].availableControls.includes('indent-header')">
              <!-- <span class="label" i18n="@@admin.templates.template.styles.indent-header">
              admin.templates.template.styles.indent-header
            </span> -->
              <m-select formControlName="indentHeader" [options]="indentHeaderOptions"></m-select>
            </div>
          </div>

          <div class="editable-group">
            <div class="control">
              <span class="label" i18n="@@admin.templates.template.styles.type">
                admin.templates.template.styles.type
              </span>
              <m-select formControlName="type" [options]="styleTypeOptions"></m-select>
            </div>
          </div>

          <div class="control">
            <span class="label" i18n="@@admin.templates.template.styles.is-displayed-at-sidebar">
              admin.templates.template.styles.is-displayed-at-sidebar
            </span>
            <m-switch formControlName="isDisplayedAtSidebar" />

            <span class="label" i18n="@@admin.templates.template.styles.is-configurable-at-details-sidebar">
              admin.templates.template.styles.is-configurable-at-details-sidebar
            </span>
            <m-switch formControlName="isConfigurableAtDetailsSidebar" />
          </div>

          <div class="divider"></div>
        </m-checkbox-group>
      </div>

      <m-button
        button-style="primary"
        icon-position="left"
        icon="plus.svg"
        (onClick)="addStyle()"
        i18n="@@admin.template.add-style"
      >
        admin.template.add-style
      </m-button>
    </div>

    <div class="divider"></div>
    <div class="end-spacer"></div>

    <div class="bottom-bar">
      <m-switch i18n="@@admin.templates.template.available-to-user" formControlName="isAvailableToUser">
        admin.templates.template.available-to-user
      </m-switch>

      <m-switch
        *ngIf="canCreatePublicTemplates"
        i18n="@@admin.templates.template.available-to-companies"
        formControlName="isVisibleToOtherCompanies"
      >
        admin.templates.template.available-to-companies
      </m-switch>

      <m-switch i18n="@@admin.templates.template.is-premium" formControlName="isPremium">
        admin.templates.template.is-premium
      </m-switch>

      <m-button button-style="primary" (onClick)="saveTemplate()" i18n="@@admin.common.save">
        admin.common.save
      </m-button>
    </div>
  </form>
  <ng-template #noTemplate>No template loaded</ng-template>
</m-ng-island>
