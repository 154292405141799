import { Injectable } from "@angular/core";
import { BookCoverTemplate } from "@metranpage/book-data";
import { Store } from "@metranpage/state";

type State = {
  templates: BookCoverTemplate[];
  activeTemplate?: BookCoverTemplate;
};

@Injectable({
  providedIn: "root",
})
export class CoverTemplateStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      templates: [],
      activeTemplate: undefined,
    };
  }

  getTemplatesObservable() {
    return this.getFieldObservable("templates");
  }

  getActiveTemplateObservable() {
    return this.getFieldObservable("activeTemplate");
  }

  setTemplates(templates: BookCoverTemplate[]) {
    this.update((state) => ({ ...state, templates }));
  }

  setActiveTemplate(activeTemplate?: BookCoverTemplate) {
    this.update((state) => ({ ...state, activeTemplate }));
  }

  deleteTemplate(template: BookCoverTemplate) {
    this.update((state) => ({ ...state, templates: state.templates.filter((t) => t.id !== template.id) }));
  }

  addTemplate(template: BookCoverTemplate) {
    this.update((state) => ({ ...state, templates: state.templates.concat([template]) }));
  }
}
