<m-ng-island [paddingSize]="24">
  <div class="new">
    <m-button button-style="primary" (onClick)="onAddRewardDaily()" i18n="@@admin.rewards.daily.add">
      admin.rewards.daily.add
    </m-button>
  </div>

  <form [formGroup]="searchForm">
    <table>
      <thead>
        <th>ID</th>
        <th>
          Type
          <m-select formControlName="type" [options]="getSelectTypeOptions()" />
        </th>
        <th>Limit</th>
        <th>Reward Credits Count</th>
        <th></th>
      </thead>

      <tbody>
        <tr *ngFor="let r of rewardsDaily; trackBy: trackByRewardDailyId">
          <td>{{r.id}}</td>
          <td>{{ getTypeTitle(r.type) }}</td>
          <td>{{r.limit}}</td>
          <td>{{r.rewardCreditsCount}}</td>
          <td>
            <div class="controls">
              <m-icon
                class="icon"
                src="gear.svg"
                size="s"
                color="--color-font-main"
                (click)="showRewardDailyDetails(r)"
              />
              <m-icon
                class="icon"
                src="block-delete.svg"
                size="s"
                color="--color-error"
                (click)="showDeleteRewardDailyModal(r)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </form>

  <m-admin-pagination
    [pageCount]="pageCount"
    [currentPage]="page"
    (onPageChanged)="onPageChanged($event)"
  ></m-admin-pagination>

  <m-admin-add-reward-daily-modal
    *ngIf="isAddRewardDailyModalVisible"
    (onRewardDailyAdded)="onRewardDailyAdded($event)"
    (onCancelAddition)="onCloseAddRewardDailyModal()"
  ></m-admin-add-reward-daily-modal>

  <m-modal *ngIf="isDeleteRewardDailyModalVisible" position="center" (close)="onCloseDeleteRewardDailyModal()">
    <div class="delete-modal">
      <span i18n="@@admin.rewards.daily.delete-confirmation">admin.rewards.daily.delete-confirmation</span>

      <div class="buttons">
        <m-button
          button-style="secondary"
          size="full-width"
          (onClick)="onCloseDeleteRewardDailyModal()"
          i18n="@@admin.common.cancel"
        >
          admin.common.cancel
        </m-button>
        <m-button button-style="primary" (onClick)="onDeleteRewardDaily()" i18n="@@admin.common.delete">
          admin.common.delete
        </m-button>
      </div>
    </div>
  </m-modal>
</m-ng-island>
