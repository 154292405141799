<div class="wrapper">
  <div class="header">
    <span i18n="@@cover.modal.create.title">cover.modal.create.title</span>
  </div>

  <form *ngIf="bookForm" [formGroup]="bookForm" class="controls">
    <div class="control">
      <m-ng-input
        formControlName="title"
        size="48"
        placeholder="cover.modal.create.project-name.placeholder"
        i18n-placeholder="@@cover.modal.create.project-name.placeholder"
      />
    </div>

    <div class="controls-group">
      <div class="control">
        <m-ng-input
          formControlName="authorFirstName"
          size="48"
          placeholder="cover.modal.create.author-firstname.placeholder"
          i18n-placeholder="@@cover.modal.create.author-firstname.placeholder"
        />
      </div>

      <div class="control">
        <m-ng-input
          formControlName="authorLastName"
          size="48"
          placeholder="cover.modal.create.author-lastname.placeholder"
          i18n-placeholder="@@cover.modal.create.author-lastname.placeholder"
        />
      </div>
    </div>
  </form>

  <div class="section">
    <div class="section-title" i18n="@@cover.modal.create.select-format">cover.modal.create.select-format</div>
    <div class="formats">
      <div
        *ngFor="let format of formats; trackBy: trackByFormatId"
        class="format"
        [ngClass]="{ selected: format.id === selectedFormat?.id }"
        (click)="selectFormat(format)"
      >
        {{ format.title}}
      </div>
    </div>
  </div>

  <div [ngClass]="{ hidden: selectedFormat?.name !== 'custom'}" class="section">
    <div class="section-title" i18n="@@cover.modal.create.enter-size">cover.modal.create.enter-size</div>
    <form *ngIf="customFormatForm" [formGroup]="customFormatForm" class="controls">
      <div class="controls-group">
        <div class="control">
          <m-ng-input-number
            formControlName="width"
            size="48"
            placeholder="cover.modal.create.enter-size.width"
            i18n-placeholder="@@cover.modal.create.enter-size.width"
          />
        </div>

        <div class="control">
          <m-ng-input-number
            formControlName="height"
            size="48"
            placeholder="cover.modal.create.enter-size.height"
            i18n-placeholder="@@cover.modal.create.enter-size.height"
          />
        </div>
      </div>
    </form>
  </div>
</div>

<m-button
  button-style="primary"
  size="full-width"
  [is-disabled]="isCreateButtonDisabled()"
  (onClick)="onCreateClick()"
  i18n="@@cover.modal.create.create-button"
>
  cover.modal.create.create-button
</m-button>
