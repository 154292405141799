<div #radioGroup class="container">
  <m-radio
    *ngFor="let valueRadioButton of positions"
    class="radio-button"
    gapSize="small"
    [value]="valueRadioButton.value"
    [isChecked]="isRadioChecked(valueRadioButton.value)"
    [isDisabled]="valueRadioButton.disabled"
    (onSelect)="onSelect($event)"
  >
    <m-running-titles-position
      [position]="valueRadioButton.value"
      [isDisabled]="valueRadioButton.disabled"
    ></m-running-titles-position>
  </m-radio>
</div>
