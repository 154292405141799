import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationLike } from "../../../models/notification";
import { RouterService } from "../../../services/router.service";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-like-card-view",
  templateUrl: "account-notification-like-card.view.html",
  styleUrls: ["account-notification-like-card.view.scss"],
})
export class AccountNotificationLikeCardView extends AccountNotificationBaseCardView<NotificationLike> {
  constructor(
    private readonly routerService: RouterService,
    private readonly router: Router,
  ) {
    super();
  }

  protected openObjectImage() {
    let objectType = "images";
    if (this.payload.objectType === "cover") {
      objectType = "covers";
    }
    this.router.navigate([objectType]);
    setTimeout(() => {
      this.routerService.showInModal([objectType, "preview", this.payload.objectId]);
    }, 100);
  }
}
