import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { DownloadGeneratedImageData, GeneratedImage } from "../../models/generated-image";
import { GeneratedImageService } from "../../services/generated-image.service";

@Component({
  selector: "m-generated-image-card",
  templateUrl: "./generated-image-card.view.html",
  styleUrls: ["./generated-image-card.view.scss"],
  animations: [fadeInOutOnEnterLeave],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneratedImageCardView {
  @Input()
  generatedImage!: GeneratedImage;
  @Input()
  isModal = false;

  @Output()
  onSelectImage = new EventEmitter<GeneratedImage>();
  @Output()
  onPreviewImage = new EventEmitter<GeneratedImage>();
  @Output()
  onDownnloadImage = new EventEmitter<DownloadGeneratedImageData>();
  @Output()
  onDeleteImage = new EventEmitter<GeneratedImage>();
  @Output()
  onUpscaleImage = new EventEmitter<GeneratedImage>();
  @Output()
  onUnzoomImage = new EventEmitter<GeneratedImage>();

  protected isButtonsVisible = false;

  protected imageGenerationId!: number;
  protected imageUrl!: string;

  constructor(
    private readonly generatedImageService: GeneratedImageService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.updateUrlForImage();
  }

  protected onPreviewClick() {
    this.onPreviewImage.emit(this.generatedImage);
  }

  protected onSelectClick() {
    this.onSelectImage.emit(this.generatedImage);
  }

  protected onDownloadClick() {
    this.onDownnloadImage.emit({ imageGenerationId: this.imageGenerationId, imageUrl: this.imageUrl });
  }

  protected onDeleteImageClick() {
    this.onDeleteImage.emit(this.generatedImage);
  }

  protected onUpscaleClick() {
    this.onUpscaleImage.emit(this.generatedImage);
  }

  protected onUnzoomClick() {
    this.onUnzoomImage.emit(this.generatedImage);
  }

  protected onMouseEnter() {
    this.isButtonsVisible = true;
  }

  protected onMouseLeave() {
    this.isButtonsVisible = false;
  }

  protected convertToString(value: number) {
    return `${value}`;
  }

  protected updateUrlForImage() {
    this.imageGenerationId = this.generatedImage.imageGenerationId;
    this.imageUrl = this.generatedImage.imageUrl;
    if (this.generatedImage.variantImageGenerationId && this.generatedImage.variantPreviewUrl) {
      this.imageGenerationId = this.generatedImage.variantImageGenerationId;
      this.imageUrl = this.generatedImage.variantPreviewUrl;
    }
  }

  protected getUrlForImage() {
    return this.generatedImageService.getUrlForImage(this.imageGenerationId, this.imageUrl, true);
  }
}
