import { Inject, Injectable } from "@angular/core";
import { ConfigService } from "@metranpage/core-interfaces";
import { UserService } from "@metranpage/user";
import { UserStore } from "@metranpage/user-data";
import { Observable, filter, map, switchMap, take } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WelcomeWebinarModalService {
  webinarModalVisibility$!: Observable<boolean>;

  constructor(
    readonly userStore: UserStore,
    readonly userService: UserService,
    @Inject("ConfigService")
    readonly configService: ConfigService,
  ) {
    this.webinarModalVisibility$ = userStore.getUserObservable().pipe(
      filter((u) => !!u),
      take(1),
      switchMap(() => userService.getUserActions()),
      map((actions) => {
        const name = "webinar-1-view";
        if (!configService.getConfig().company.flags.showUpdatesNews) {
          return false;
        }

        return false;

        // if (!actions) {
        //   return true;
        // }

        // const action = actions.find((a) => a.name === name);
        // if (action) {
        //   return false;
        // }
        // return true;
      }),
    );
  }
}
