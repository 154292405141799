import { CommonModule, NgOptimizedImage } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SwitchComponent } from "@metranpage/components";
import { NgxFileDropEntry, NgxFileDropModule } from "ngx-file-drop";
import { StyleKey } from "../../../../models/styles";
import { ImageLoadingProvider } from "../../../../services/image-loading.provider";
import { IMAGE_LOADING_PROVIDER } from "../../../../tokens";
import { ImageSizesComponent } from "./image-sizes/image-sizes.component";

export type ImageSize = "small" | "medium" | "large" | "fullpage";
export type CropClass = "crop" | "noncrop";
export type ImageBlockDefaultData = {
  isImagesSettingsAvailable: boolean;
  size: ImageSize;
  cropClass: CropClass;
};

export interface ImageData {
  data: {
    imageIndex: number;
    cropClass: CropClass;
    original: string;
    size: ImageSize;
    url: string;
  };
  id: string;
  style: StyleKey; // TODO: Надо уточнить, может это enum
}

@Component({
  standalone: true,
  selector: "m-markup-editor-image-block",
  templateUrl: "./image-block.component.html",
  styleUrls: ["./image-block.component.scss"],
  imports: [CommonModule, FormsModule, ImageSizesComponent, NgOptimizedImage, NgxFileDropModule, SwitchComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageBlockComponent implements OnInit {
  @Input() data!: ImageData;
  @Input() bookId!: number;
  @Input() imageDefaultData?: ImageBlockDefaultData;

  @Output()
  contentChanged = new EventEmitter<any>();

  protected defaultData: ImageBlockDefaultData = {
    isImagesSettingsAvailable: true,
    size: "medium",
    cropClass: "noncrop",
  };

  width = 0;
  height = 0;
  _errorText = "";
  loading = false;
  visibleUrl = "";

  constructor(
    @Inject(IMAGE_LOADING_PROVIDER)
    private readonly imageLoadingProvider: ImageLoadingProvider,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.data.data.url) {
      this.visibleUrl = this.imageToAbsoluteUrl(this.data.data.url);
    }

    if (this.imageDefaultData) {
      this.defaultData = this.imageDefaultData;
    }

    this.getImageDimension();
  }

  onSelect(size: ImageSize) {
    this.data.data.size = size;
    // this.data.data.cropClass = "noncrop";
    if (size !== "fullpage") {
      this.data.data.cropClass = "noncrop";
    }

    this.contentChanged.emit(this.data.data);
  }

  onCropClassSelect() {
    if (this.data.data.cropClass === "crop") {
      this.data.data.cropClass = "noncrop";
    } else {
      this.data.data.cropClass = "crop";
    }
    this.contentChanged.emit(this.data.data);
  }

  private imageToAbsoluteUrl(url: string): string {
    return this.imageLoadingProvider.getUrlForEditorImage(this.bookId, url);
  }

  getImageDimension(): void {
    const img = new Image();

    img.src = this.visibleUrl;
    // TODO: Надо сделать правильный расчет small, medium, large
    img.onload = () => {
      const designer_height = 500;
      const designer_width = 808;
      if (img.width * designer_height > designer_width * img.height) {
        this.height = (designer_width * img.height) / img.width;
      } else {
        this.width = (designer_height * img.width) / img.height;
      }
    };
  }

  public files: NgxFileDropEntry[] = [];
  public isHovered = false;

  _dropped(event: NgxFileDropEntry[]) {
    this.isHovered = false;
    this.imageDropped(event);
  }

  imageDropped(files: NgxFileDropEntry[]) {
    if (files.length > 0) {
      const file = files[0];
      if (!file.fileEntry.isFile) {
        this._errorText = $localize`:@@editor.image.upload_error:`;
        return;
      }
      this.loading = true;
      this.cdr.detectChanges();

      const fileEntry = file.fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) => {
        if (!file.type.startsWith("image")) {
          this._errorText = $localize`:@@editor.image.not_an_image_error:`;
          this.loading = false;
          this.cdr.detectChanges();
          return;
        }

        const result = await this.imageLoadingProvider.uploadEditorImage(this.bookId, file, this.data.data.imageIndex);
        if (result.status === "success") {
          this.data.data.url = result.response.relativePath;
          this.data.data.original = result.response.originalRelativePath;

          this.data.data.size = this.defaultData.size;
          this.data.data.cropClass = this.defaultData.cropClass;

          this.setDataToHtml(this.data);
          this.loading = false;

          this.contentChanged.emit(this.data.data);
        } else {
          this._errorText = $localize`:@@editor.image.upload_error:`;
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
    }
  }

  private setDataToHtml(data: ImageData) {
    if (data.data.url) {
      this.visibleUrl = this.imageToAbsoluteUrl(data.data.url);
    }
    this.cdr.detectChanges();
  }
}
