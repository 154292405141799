import { Routes } from "@angular/router";
import { AuthGuard } from "@metranpage/auth";
import { AwaitPaymentLinkPage } from "./pages/await-payment-link/await-payment-link.page";
import { PaymentFailedPage } from "./pages/payment-failed/payment-failed.page";
import { PaymentNextTariffConnectedPage } from "./pages/payment-next-tariff-connected/payment-next-tariff-connected.page";
import { PaymentSuccessPage } from "./pages/payment-success/payment-success.page";
import { PricingHostPage } from "./pages/pricing-host/pricing-host.page";
import { PricingSubscriptionPage } from "./pages/pricing-subscription/pricing-subscription.page";

export const paymentsRoutes: Routes = [
  {
    path: "payments",
    component: PricingHostPage,
    canActivate: [AuthGuard],
    data: {
      welcomeModalVisible: true,
      breadcrumbs: {
        titleKey: "tariffs-and-payments",
      },
    },
    children: [
      {
        path: "subscription",
        component: PricingSubscriptionPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
      {
        path: "",
        component: PricingSubscriptionPage,
        data: {
          showTopBar: true,
          showMobileWarning: true,
        },
      },
    ],
  },
  {
    path: "payments/await-payment-link",
    component: AwaitPaymentLinkPage,
    canActivate: [AuthGuard],
    data: {
      showTopBar: false,
      showMobileWarning: false,
    },
  },
  {
    path: "payments/success",
    component: PaymentSuccessPage,
    canActivate: [AuthGuard],
    data: {
      showTopBar: true,
      showMobileWarning: false,
      breadcrumbs: {
        titleKey: "payments",
      },
    },
  },
  {
    path: "payments/next-tariff-connected",
    component: PaymentNextTariffConnectedPage,
    canActivate: [AuthGuard],
    data: {
      showTopBar: true,
      showMobileWarning: false,
      breadcrumbs: {
        titleKey: "payments",
      },
    },
  },
  {
    path: "payments/failed",
    component: PaymentFailedPage,
    canActivate: [AuthGuard],
    data: {
      showTopBar: true,
      showMobileWarning: false,
      breadcrumbs: {
        titleKey: "payments",
      },
    },
  },
];
