import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IslandComponent } from "@metranpage/design-system";
import { CardComponent } from "../card/card.component";
import { IconComponent } from "../icon/icon.component";
import { ModalBackdropComponent } from "../modal-backdrop/modal-backdrop.component";

export type ModalPosition = "top" | "center";
export type CloseButtonPosition = "inside" | "screen";

@Component({
  selector: "m-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
  standalone: true,
  imports: [CommonModule, CardComponent, IconComponent, ModalBackdropComponent, IslandComponent],
})
export class ModalComponent {
  @Input()
  position: ModalPosition = "top";
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;
  @Input()
  paddingSize = 16;

  @Output()
  close = new EventEmitter();

  protected onCloseClick() {
    this.close.emit();
  }
}
