import { Component, EventEmitter, Input, Output } from '@angular/core';

export type TemplateFilterSelectorType = 'editable' | 'non-editable' | 'all';

@Component({
  selector: 'm-template-filter-selector',
  templateUrl: './template-filter-selector.view.html',
  styleUrls: ['./template-filter-selector.view.scss'],
})
export class TemplateFilterSelectorView {
  @Input()
  filters: TemplateFilterSelectorType[] = ['all', 'editable', 'non-editable'];
  @Input()
  activeFilter: TemplateFilterSelectorType = 'all';

  @Output()
  onTemplateEditableTypeSelect = new EventEmitter<TemplateFilterSelectorType>();

  getTypeLocalize(type: TemplateFilterSelectorType) {
    switch (type) {
      case 'editable':
        return $localize`:@@books.template.template-filter.editable:`;
      case 'non-editable':
        return $localize`:@@books.template.template-filter.non-editable:`;
      case 'all':
        return $localize`:@@books.template.template-filter.all:`;
      default:
        return $localize`:@@books.template.template-filter.all:`;
    }
  }

  protected onTemplateEditableTypeSelectClick(type: TemplateFilterSelectorType) {
    this.activeFilter = type;
    this.onTemplateEditableTypeSelect.emit(type);
  }
}
