<m-skeleton-image
  *ngIf="imageUrl"
  [url]="imageUrl"
  backgroudColor="var(--surface-on-island)"
  animationStyle="gradient"
/>

<div *ngIf="isCardInfoVisible" @fadeInOut class="card-info">
  <div class="author">
    <div class="avatar"></div>
    <div class="author-name" *ngIf="author">
      <span>{{ author.firstName }}</span>
      <span>{{ author.lastName }}</span>
    </div>
  </div>

  <div class="likes" [ngClass]="{liked: isLiked}" (click)="onLikeClick($event)">
    <m-ng-icon *ngIf="!isLiked" src="like-1.svg" [size]="25" />
    <m-ng-icon *ngIf="isLiked" src="like-2.svg" [size]="25" />

    <span>{{ likesCount }}</span>
  </div>
</div>
