import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import * as _ from "lodash-es";
import { BehaviorSubject } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { StyleSettings } from "../../../../models/styles";
import { EditorBlock } from "../../../../views/markup-editor/editor.models";
import { TextEditor } from "../../../text-editor/text-editor/text-editor.view";
import { BlockMergeEvent, BlockMovementEvent, BlockSplitEvent } from "../../editor.events";
import { Footnote, TextSelectionState } from "../../editor.service";
import { BlockDelegate } from "../block-delegate";
import { ListData, ListItem } from "./interfaces/list-data.interface";
import { ListItemView } from "./list-item-view/list-item-view";

@Component({
  selector: "m-markup-editor-list-block",
  templateUrl: "./list-block.component.html",
  styleUrls: ["./list-block.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListBlockComponent implements BlockDelegate, OnInit {
  @Input() data!: EditorBlock;
  @Input() styleDisplayOpts: StyleSettings | null = null;

  @ViewChildren(ListItemView)
  listItems: QueryList<ListItemView> | undefined = undefined;

  @Output()
  footnoteAdded: EventEmitter<Footnote[]> = new EventEmitter();
  @Output()
  footnoteChanged: EventEmitter<Footnote[]> = new EventEmitter();
  @Output()
  contentChanged = new EventEmitter<EditorBlock>();

  protected items: ListItem[] = [];

  protected listContextMenuVisible: boolean[] = [];
  protected listContextMenuPositionX: number[] = [];
  // TODO use currentActiveIndex instead
  protected currentItemIndex = 0;
  protected currentActiveIndex: number | null = null;

  protected startingNotesIndexes: number[] = [];

  private indexSelectionEvent$ = new BehaviorSubject<number>(0);

  constructor(private readonly cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.indexSelectionEvent$.pipe().subscribe((index) => {
      this.currentItemIndex = index;
      this.setCaretToBegin();
    });

    this.items = this.toVisibleItems(this.data.data.items);
    this.reindex();
  }

  onListItemBlur(newData: ListItem) {
    const data = this.items.find((item) => item.uniqueId === newData.uniqueId);
    if (data) {
      data.content = newData.content;
    }
    if (this.items.length > 0) {
      const nestedItems = this.toListData(this.items);
      this.data.data.items = nestedItems;
    }

    this.contentChanged.emit(this.data.data);
  }

  onContextMenuItemClick(event: any, index: number) {
    if (this.listItems) {
      const level = this.items[index].level;

      for (const listItem of this.items) {
        if (listItem.level === level) {
          listItem.style = event.listStyleMarker;
        }
      }
      this.updateMarkersExternally();
      this.cdr.detectChanges();
      const nestedItems = this.toListData(this.items);
      this.data.data.items = nestedItems;
    }
  }

  getContextMenuStyle(index: number) {
    const styles = {
      position: "absolute",
      left: `${this.listContextMenuPositionX[index]}px`,
      top: "unset",
      "z-index": 1,
    };
    return styles;
  }

  onContextMenu(event: MouseEvent, index: number): void {
    event.preventDefault();
    if (this.currentActiveIndex !== null) {
      this.listContextMenuVisible[this.currentActiveIndex] = false;
    }
    this.listContextMenuPositionX[index] = event.clientX;
    this.listContextMenuVisible[index] = true;
    this.currentActiveIndex = index;
  }

  toVisibleItems(items: ListData[], result: ListItem[] = [], _parent?: ListData) {
    let index = 1;
    for (const item of items) {
      const newItem: ListItem = {
        ...item,
        index,
        parentIndex: "",
        uniqueId: uuidv4(),
      };
      index = index + 1;

      result.push(newItem);
    }
    return result;
  }

  toListData(visibleItems: ListItem[]): ListData[] {
    return visibleItems.map((item) => {
      return {
        style: item.style,
        level: item.level,
        content: item.content,
      };
    });
  }

  onSplit(event: BlockSplitEvent) {
    const currentItem = this.items[this.currentItemIndex];

    const newStyle = currentItem.style;

    const newItem: ListItem = {
      content: event.text,
      level: currentItem.level,
      style: newStyle,
      index: currentItem.index + 1,
      parentIndex: currentItem.parentIndex,
      uniqueId: this.generateUniqueId(),
    };

    for (let i = this.currentItemIndex + 1; i < this.items.length; i++) {
      const item = this.items[i];
      if (item.level > currentItem.level) {
        continue;
      }
      if (item.level < currentItem.level) {
        break;
      }
      item.index++;
    }

    // TODO: need to recalculate parent indexes
    /* for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (item.parent === currentItem) {
        item.parent = newItem;
      }
    } */

    this.items.splice(this.currentItemIndex + 1, 0, newItem);
    this.cdr.detectChanges();
    this.selectItem(this.currentItemIndex + 1);
    this.reindex();
    this.updateMarkersExternally();
  }

  onMergeWithPrev(_event: BlockMergeEvent) {
    if (this.currentItemIndex > 0) {
      const currentItem = this.items[this.currentItemIndex];
      const prevItem = this.items[this.currentItemIndex - 1];
      prevItem.content += ` ${currentItem.content}`;
      this.items.splice(this.currentItemIndex, 1);
      this.currentItemIndex--;

      // TODO: need to recalculate parent indexes
      /* for (let i = this.currentItemIndex + 1; i < this.items.length; i++) {
        const item = this.items[i];
        if (item.parent === currentItem) {
          item.parent = prevItem;
        }
      } */
      for (let i = this.currentItemIndex + 1; i < this.items.length; i++) {
        const item = this.items[i];
        if (item.level === currentItem.level) {
          item.index--;
        }
      }

      this.cdr.detectChanges();
      this.selectItemAndSetCursor(this.currentItemIndex, "end");
      const nestedItems = this.toListData(this.items);
      this.data.data.items = nestedItems;
      this.reindex();
      this.updateMarkersExternally();
    }
  }

  updateMarkersExternally() {
    if (this.listItems) {
      this.collectFootnotes(); // TODO place in different method (?)
      for (const listItem of this.listItems) {
        listItem.updateMarker();
      }
    }
  }

  onListItemFocus(index: number) {
    this.currentItemIndex = index;
  }

  onBlockMovement(event: BlockMovementEvent) {
    if (event.direction === "next") {
      this.selectItem(this.currentItemIndex + 1);
    } else if (event.direction === "prev") {
      this.selectItem(this.currentItemIndex - 1);
    }
  }

  getTextSelection(): TextSelectionState | undefined {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      return te.getTextSelectionState();
    }
    return undefined;
  }

  applyBold(): void {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      te.applyBold();
    }
  }

  applyItalick(): void {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      te.applyItalic();
    }
  }

  addFootnote(): void {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      te.addFootnote();
    }
  }

  updateFootnotesText(footnotes: Footnote[]) {
    // const te = this.getActiveListItemTextEditor();
    // if (te) {
    //   return te.updateFootnotesText(footnotes);
    // }
    //
    if (!this.listItems) {
      return;
    }
    for (let i = 0; i < this.listItems.length; i++) {
      const item = this.listItems.get(i);
      item?.textEditor?.updateFootnotesText(footnotes);
    }
  }

  onFootnoteAdded(_footnotes: Footnote[]) {
    this.footnoteAdded.emit(this.collectFootnotes());
  }

  onFootnoteChanged(_footnotes: Footnote[]) {
    this.footnoteChanged.emit(this.collectFootnotes());
  }

  private collectFootnotes(): Footnote[] {
    this.startingNotesIndexes = [];

    const notes: Footnote[] = [];
    if (!this.listItems) {
      return [];
    }

    let noteIndex = 1;
    for (let i = 0; i < this.listItems.length; i++) {
      const item = this.listItems.get(i);
      this.startingNotesIndexes[i] = noteIndex;

      const itemNotes = item?.textEditor?.refreshFootnotes();
      if (itemNotes) {
        for (let j = 0; j < itemNotes.length; j++) {
          itemNotes[j].id = noteIndex;
          noteIndex++;
          notes.push(itemNotes[j]);
        }
      }
    }

    this.cdr.detectChanges();

    return notes;
  }

  removeFootnote(id: number): void {
    if (!this.listItems) {
      return;
    }
    for (let i = 0; i < this.listItems.length; i++) {
      const item = this.listItems.get(i);
      const isRemoved = item?.textEditor?.removeFootnote(id);
      if (isRemoved) {
        break;
      }
    }
  }

  setCaretToBegin(): void {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      te.setCaretToBegin();
    }
  }

  setCaretToEnd(): void {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      te.setCaretToEnd();
    }
  }

  appendTextAndFocus(text: string): void {
    const te = this.getActiveListItemTextEditor();
    if (te) {
      te.appendTextAndFocus(text);
    }
  }

  @HostListener("document:click", ["$event"])
  documentClick(_event: any): void {
    for (let i = 0; i < this.listContextMenuVisible.length; i++) {
      this.listContextMenuVisible[i] = false;
    }
  }

  @HostListener("keydown.tab", ["$event"])
  onTabKeyDown(event: KeyboardEvent) {
    event.preventDefault();
    this.tabItemRight();
  }

  @HostListener("keydown.shift.tab", ["$event"])
  onShiftDeleteKeyDown(event: KeyboardEvent) {
    event.preventDefault();
    this.tabItemLeft();
  }

  trackByFn(_index: number, item: ListItem): string {
    return item.uniqueId;
  }

  private updateItemMarkers() {
    const nestedItems = this.toListData(this.items);
    this.data.data.items = nestedItems;
    this.updateMarkersExternally();
  }

  private tabItemLeft() {
    if (this.currentItemIndex >= 0) {
      const currentItem = this.items[this.currentItemIndex];
      if (currentItem.level > 0) {
        currentItem.level -= 1;
      }
    }
    this.reindex();
    this.updateItemMarkers();
    this.cdr.markForCheck();
  }

  private tabItemRight() {
    if (this.currentItemIndex >= 0) {
      const currentItem = this.items[this.currentItemIndex];
      if (currentItem.level < 8) {
        currentItem.level += 1;
      }
      this.reindex();
      this.updateMarkersExternally();
      this.cdr.markForCheck();
    }
  }

  private reindex() {
    const indexStack: number[] = [];

    for (const item of this.items) {
      // Initialize or adjust stacks for the item's level
      while (indexStack.length <= item.level) {
        indexStack.push(0);
      }

      // Remove deeper levels if needed
      while (indexStack.length > item.level + 1) {
        indexStack.pop();
      }

      // Increment the current level index
      indexStack[item.level]++;

      // Set the current item's index
      item.index = indexStack[item.level];

      // Calculate parent index
      if (item.level === 0) {
        item.parentIndex = '';
      } else {
        // Take all parent indexes up to current level (not including current level)
        const parentIndexParts = indexStack.slice(0, item.level).filter(i => i > 0);
        item.parentIndex = parentIndexParts.length > 0 ? parentIndexParts.join('.') : '';
      }
    }
    this.cdr.detectChanges();
  }

  private findItemUpWithLevel(index: number, level: number): ListItem | undefined {
    let parentIndex = index - 1;
    while (parentIndex >= 0 && this.items[parentIndex].level >= level) {
      parentIndex--;
    }

    if (parentIndex < 0) {
      return undefined;
    }
    return this.items[parentIndex];
  }

  private selectItemAndSetCursor(index: number, setCursor: "start" | "end") {
    this.indexSelectionEvent$.next(index);
    this.setCursorPosition(setCursor);
  }

  private setCursorPosition(setCursor: "start" | "end") {
    const te = this.getActiveListItemTextEditor();

    if (te) {
      if (setCursor === "start") {
        te.setCaretToBegin();
      } else if (setCursor === "end") {
        te.setCaretToEnd();
      }
    }
  }

  private selectItem(index: number) {
    this.indexSelectionEvent$.next(index);
  }

  private generateUniqueId(): string {
    return uuidv4();
  }

  private getActiveListItemTextEditor(): TextEditor | undefined {
    const currentItem = this.listItems?.get(this.currentItemIndex);
    return currentItem?.textEditor;
  }
}
