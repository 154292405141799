import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CAN_CREATE_PUBLIC_TEMPLATES,
  LoadingService,
  NotificationsPopUpService,
  filterUndefined,
} from "@metranpage/core";
import { AVAILABLE_LANGUAGES, LangSpec } from "@metranpage/i18n";
import * as _ from "lodash-es";
import { takeUntil } from "rxjs";
import { FormatDto } from "../../models/format";
import { FormatLocCreateData } from "../../models/format-loc";
import { AdminFormatService } from "../../services/format/format.service";
import { AdminFormatStore } from "../../services/format/format.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-format-page",
  templateUrl: "./format.page.html",
  styleUrls: ["./format.page.scss"],
})
export class AdminFormatPage extends AdminBasePage implements OnInit, AfterViewInit {
  format?: FormatDto;

  protected form!: FormGroup;

  constructor(
    private readonly adminFormatService: AdminFormatService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    adminFormatStore: AdminFormatStore,
    private readonly cdr: ChangeDetectorRef,
    @Inject(AVAILABLE_LANGUAGES) readonly availableLanguages: LangSpec[],
    @Inject(CAN_CREATE_PUBLIC_TEMPLATES)
    readonly canCreatePublicFormats: boolean,
  ) {
    super();

    this.addSub(
      adminFormatStore
        .getActiveFormatObservable()
        .pipe(takeUntil(this.destroyed$), filterUndefined())
        .subscribe(async (format) => {
          this.format = format;
          this.form = this.adminFormatService.createForm();

          const formValues: any = _.omit(format, ["localization"]);
          for (const l of this.format.localization || []) {
            if (!formValues.localization) {
              formValues.localization = {};
            }
            formValues.localization[l.lang] = { title: l.title };
          }
          this.form.patchValue(formValues);
        }),
    );
  }

  ngOnInit(): void {}

  async ngAfterViewInit() {
    if ("formatId" in this.route.snapshot.params) {
      await this.adminFormatService.loadFormat(this.route.snapshot.params["formatId"]);
    }
  }

  async saveFormat() {
    const formData = this.form.getRawValue();
    const localizationData: FormatLocCreateData[] = [];
    for (const lang of Object.keys(formData.localization)) {
      const valueLoc = formData.localization[lang];
      localizationData.push({
        title: valueLoc.title,
        lang,
      });
    }
    formData.localization = localizationData;

    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminFormatService.updateFormat(this.format!.id, formData);
    this.loadingService.stopLoading();
    if (result === "success") {
      this.router.navigate(["admin", "formats"]);
    } else {
      this.notificationService.error($localize`:@@admin.format.error.cant-update-format:`);
    }
  }

  protected getLanguages() {
    return this.availableLanguages.map((v) => v.value);
  }

  protected getFormatTypeOptions() {
    return this.adminFormatService.getFormatTypeOptions();
  }
}
