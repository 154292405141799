import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsPopUpService } from "@metranpage/core";
import { PaymentResult } from "@metranpage/pricing-data";
import { PricingService } from "../../services/pricing.service";

@Component({
  selector: "m-await-payment-link",
  template: `
    <m-loading-spinner></m-loading-spinner>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100vw;
        height: 100vh;
      }
    `,
  ],
})
export class AwaitPaymentLinkPage implements OnInit {
  constructor(
    private readonly pricingService: PricingService,
    private readonly notificationsService: NotificationsPopUpService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.queryParamMap.subscribe(async (params) => {
      let result: PaymentResult | undefined = undefined;

      const creditsCount = params.get("creditsCount");
      if (creditsCount) {
        const credits = Number.parseInt(creditsCount);
        result = await this.pricingService.buyCredits(credits);
      }

      const tariff = params.get("tariffId");
      if (tariff) {
        const tariffId = Number.parseInt(tariff);
        result = await this.pricingService.buySubscription(tariffId);
      }

      const newCard = params.get("newCard");
      if (newCard) {
        result = await this.pricingService.addPaymentMethod();
      }

      if (result?.url) {
        window.location.replace(result.url);
      } else if (result?.status === "success" || result?.status === "completed") {
        this.router.navigate(["/payments/success"]);
      } else if (result?.status === "next-tariff") {
        this.router.navigate(["/payments/next-tariff-connected"]);
      } else if (result?.status === "failed") {
        this.router.navigate(["/payments/failed"]);
      } else {
        this.router.navigate(["payments", "subscription"]);
        this.notificationsService.closeAll();
        this.notificationsService.error($localize`:@@payment.errors.cant-get-payment-url:`);
      }
    });
  }
}
