import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CoreModule } from "@metranpage/core";
import { AccountTariffAndPaymentsMenuItemComponent } from "./components/account-tariff-and-payments-menu-item/account-tariff-and-payments-menu-item.component";
import { AccountMenuItemComponent } from "./components/menu-item/menu-item.component";
import { AccountActiveTariffPage } from "./pages/account-active-tariff/account-active-tariff.page";
import { AccountConnectTelegramPage } from "./pages/account-connect-telegram/account-connect-telegram.page";
import { AccountContactsPage } from "./pages/account-contacts/account-contacts.page";
import { AccountDevPage } from "./pages/account-dev/account-dev.page";
import { AccountHostPage } from "./pages/account-host.page";
import { AccountMainPage } from "./pages/account-main/account-main.page";
import { AccountNotificationsPage } from "./pages/account-notifications/account-notifications.page";
import { AccountPasswordPage } from "./pages/account-password/account-password.page";
import { AccountPaymentMethodsPage } from "./pages/account-payment-methods/account-payment-methods.page";
import { AccountPaymentsHistoryPage } from "./pages/account-payments-history/account-payments-history.page";
import { AccountRewardsPage } from "./pages/account-rewards/account-rewards.page";
import { AccountTariffAndPaymentsHostPage } from "./pages/account-tariff-and-payments-host/account-tariff-and-payments-host.page";
import { AccountVerificationCheckTokenPage } from "./pages/account-verification-check-token/account-verification-check-token.page";
import { AccountVerificationPage } from "./pages/account-verification/account-verification.page";
import { ConnectTelegramService } from "./services/connect-telegram.service";
import { UserVerificationOverlayService } from "./services/user-verification-overlay.service";
import { UserService } from "./services/user.service";
import { AccountConnectTelegramModalView } from "./views/account-connect-telegram-modal/account-connect-telegram-modal.view";
import { AccountContactsView } from "./views/account-contacts/account-contacts.view";
import { AccountDevView } from "./views/account-dev/account-dev.view";
import { AccountMainView } from "./views/account-main/account-main.view";
import { AccountNotificationAddTokensCardView } from "./views/account-notification-cards/account-notification-add-tokens-card/account-notification-add-tokens-card.view";
import { AccountNotificationBaseCardView } from "./views/account-notification-cards/account-notification-base-card/account-notification-base-card.view";
import { AccountNotificationBookLayoutCardView } from "./views/account-notification-cards/account-notification-book-layout/account-notification-book-layout-card.view";
import { AccountNotificationBookWithoutCoverCardView } from "./views/account-notification-cards/account-notification-book-without-cover-card/account-notification-book-without-cover-card.view";
import { AccountNotificationCardHost } from "./views/account-notification-cards/account-notification-card-host/account-notification-card-host.view";
import { AccountNotificationConnectTelegramCardView } from "./views/account-notification-cards/account-notification-connect-telegram-card/account-notification-connect-telegram-card.view";
import { AccountNotificationCoverModerationCardView } from "./views/account-notification-cards/account-notification-cover-moderation-card/account-notification-cover-moderation-card.view";
import { AccountNotificationEmailVerificationCardView } from "./views/account-notification-cards/account-notification-email-verification-card/account-notification-email-verification-card.view";
import { AccountNotificationImageModerationCardView } from "./views/account-notification-cards/account-notification-image-moderation-card/account-notification-image-moderation-card.view";
import { AccountNotificationLikeCardView } from "./views/account-notification-cards/account-notification-like-card/account-notification-like-card.view";
import { AccountNotificationLowBalanceCardView } from "./views/account-notification-cards/account-notification-low-balance-card/account-notification-low-balance-card.view";
import { AccountNotificationPromocodeActivateCardView } from "./views/account-notification-cards/account-notification-promocode-activate-card/account-notification-promocode-activate-card.view";
import { AccountNotificationPromocodeExpireCardView } from "./views/account-notification-cards/account-notification-promocode-expire-card/account-notification-promocode-expire-card.view";
import { AccountNotificationRegistrationCardView } from "./views/account-notification-cards/account-notification-registration-card/account-notification-registration-card.view";
import { AccountNotificationSubscriptionActivateCardView } from "./views/account-notification-cards/account-notification-subscription-activate-card/account-notification-subscription-activate-card.view";
import { AccountNotificationSubscriptionExpireCardView } from "./views/account-notification-cards/account-notification-subscription-expire-card/account-notification-subscription-expire-card.view";
import { AccountNotificationTrialPeriodActivateCardView } from "./views/account-notification-cards/account-notification-trial-period-activate-card/account-notification-trial-period-activate-card.view";
import { AccountNotificationTrialPeriodExpireCardView } from "./views/account-notification-cards/account-notification-trial-period-expire-card/account-notification-trial-period-expire-card.view";
import { AccountNotificationWebinarCardView } from "./views/account-notification-cards/account-notification-webinar-card/account-notification-webinar-card.view";
import { AccountNotificationsIconView } from "./views/account-notifications-icon/account-notifications-icon.view";
import { AccountNotificationsView } from "./views/account-notifications/account-notifications.view";
import { AccountPasswordView } from "./views/account-password/account-password.view";
import { AccountPaymentMethodView } from "./views/account-payment-method/account-payment-method.view";
import { AccountRewardNotificationBaseCardView } from "./views/account-reward-notification-base-card/account-reward-notification-base-card.view";
import { AccountRewardNotificationsView } from "./views/account-reward-notifications/account-reward-notifications.view";
import { AccountRewardTasksListView } from "./views/account-reward-tasks-list/account-reward-tasks-list.view";
import { RewardTaskConnectTelegramView } from "./views/account-reward-tasks-list/tasks/reward-task-connect-telegram/reward-task-connect-telegram.view";
import { RewardTaskDailyView } from "./views/account-reward-tasks-list/tasks/reward-task-daily/reward-task-daily.view";
import { AccountRewardsIconView } from "./views/account-rewards-icon/account-rewards-icon.view";
import { AccountSocialConnectButtonView } from "./views/account-social-connect-button/account-social-connect-button.view";
import { AccountTariffAndPaymentsNavView } from "./views/account-tariff-and-payments-nav/account-tariff-and-payments-nav.view";
import { AccountVerificationView } from "./views/account-verification/account-verification.view";
import { AccountNavView } from "./views/nav/nav.view";

@NgModule({
  declarations: [
    //components
    AccountMenuItemComponent,
    //views
    AccountMainView,
    AccountPasswordView,
    AccountTariffAndPaymentsNavView,
    AccountTariffAndPaymentsMenuItemComponent,
    AccountContactsView,
    AccountVerificationView,
    AccountNavView,
    AccountPaymentMethodView,
    AccountDevView,
    AccountSocialConnectButtonView,
    AccountConnectTelegramModalView,

    //pages
    AccountHostPage,
    AccountMainPage,
    AccountPasswordPage,
    AccountTariffAndPaymentsHostPage,
    AccountVerificationPage,
    AccountVerificationCheckTokenPage,
    AccountContactsPage,
    AccountActiveTariffPage,
    AccountPaymentMethodsPage,
    AccountPaymentsHistoryPage,
    AccountNotificationsPage,
    AccountDevPage,
    AccountConnectTelegramPage,
    AccountRewardsPage,

    // views
    AccountNotificationsView,
    AccountNotificationCardHost,
    AccountNotificationBaseCardView,
    AccountNotificationImageModerationCardView,
    AccountNotificationCoverModerationCardView,
    AccountNotificationPromocodeActivateCardView,
    AccountNotificationPromocodeExpireCardView,
    AccountNotificationEmailVerificationCardView,
    AccountNotificationTrialPeriodActivateCardView,
    AccountNotificationTrialPeriodExpireCardView,
    AccountNotificationBookLayoutCardView,
    AccountNotificationBookWithoutCoverCardView,
    AccountNotificationSubscriptionActivateCardView,
    AccountNotificationSubscriptionExpireCardView,
    AccountNotificationLowBalanceCardView,
    AccountNotificationRegistrationCardView,
    AccountNotificationsIconView,
    AccountNotificationConnectTelegramCardView,
    AccountNotificationAddTokensCardView,
    AccountNotificationLikeCardView,
    AccountNotificationWebinarCardView,
    AccountRewardsIconView,
    AccountRewardNotificationsView,
    AccountRewardTasksListView,
    AccountRewardNotificationBaseCardView,
    RewardTaskConnectTelegramView,
    RewardTaskDailyView,

    // services
  ],
  imports: [CoreModule, RouterModule],

  exports: [
    AccountConnectTelegramModalView,
    AccountVerificationPage,
    AccountRewardsIconView,
    AccountNotificationsIconView,
  ],
  providers: [
    { provide: "UserRefresher", useExisting: UserService },
    { provide: "UserVerificationOverlayService", useClass: UserVerificationOverlayService },
    { provide: "ConnectTelegramServiceInterface", useExisting: ConnectTelegramService },
  ],
})
export class UserModule {}
