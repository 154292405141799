<div class="container">
  <m-info-block *ngIf="isBookEpub" class="epub-warning" type="warning" [labels]="epubInfoLabels" />

  <m-books-markup-sidebar-palette
    [form]="form"
    [availablePalettes]="availablePalettes"
    [isSettingsAvailable]="isSettingsAvailable"
    (onShowColorsDetailsSidebar)="onShowColorsDetailsSidebar.emit()"
  />

  <div *ngIf="isBookPrint" class="section-divider"></div>

  <m-books-markup-sidebar-images *ngIf="isBookPrint" [form]="form" [isSettingsAvailable]="isSettingsAvailable" />
</div>
