import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { slideInOutVertical } from "@metranpage/components";
import { ImageGenerationAdvancedStyle, ImageGenerationBasicStyle } from "../../models/image-generation";
import { ImageGenerationUserStyle } from "../../models/image-generation-user-style/image-generation-user-style";
import { ImageGenerationUserStyleService } from "../../services/image-generation-user-style/image-generation-user-style.service";

type StylesData = ImageGenerationBasicStyle | ImageGenerationAdvancedStyle;
type TabValue = {
  value: string;
  title: string;
};

@Component({
  selector: "m-styles-selector",
  templateUrl: "./styles-selector.view.html",
  styleUrls: ["./styles-selector.view.scss"],
  animations: [slideInOutVertical],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StylesSelectorView),
      multi: true,
    },
  ],
})
export class StylesSelectorView implements OnInit, OnChanges {
  @Input()
  title?: string;
  @Input()
  data: StylesData[] = [];
  @Input()
  isModalAllDataVisible = false;
  @Input()
  page = 1;
  @Input()
  pageSize = 4;
  @Input()
  textViewAllData = "";
  @Input()
  showSectionHeader = true;
  @Input()
  canCollapsing = false;
  @Input()
  isCollapsed = true;
  @Input()
  hasNewMark = false;
  @Input()
  hasStyleReference = false;
  @Input()
  styleReference?: ImageGenerationUserStyle;
  @Input()
  userStyleReferencePrefix = "user-style-";

  @Output()
  onChangeStyleReference = new EventEmitter<void>();

  @ViewChildren("card", { read: ElementRef }) elements?: QueryList<ElementRef>;
  @ViewChildren("modalCard", { read: ElementRef }) modalElements?: QueryList<ElementRef>;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  protected value?: string;
  protected modalSelectedStyleId?: string;

  protected pagesCount = 1;

  protected selectedTab = "all";
  protected selectedTabIndex = 0;

  protected isDisabled = false;

  protected filteredData: StylesData[] = [];
  protected tabs: TabValue[] = [{ value: "all", title: $localize`:@@image-generation.select-style.group.all:` }];
  protected dataPaginated: StylesData[] = [];

  protected styleReferenceImageUrl?: string;
  protected isStyleReferenceHovered = false;
  protected isModalStyleReferenceHovered = false;

  protected isUserStyleReferenceVisible = false;
  protected isModalUserStyleReferenceVisible = false;

  constructor(
    private readonly imageGenerationUserStyleService: ImageGenerationUserStyleService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  private init() {
    this.updatePagesCount();
    this.getPaginatedData();
    this.updateTypesAndStyles();

    this.updateStyleReferenceImageUrl();
  }

  updateTypesAndStyles() {
    this.prepareTypes();
    this.filterStyles();
    this.updateModalUserStyleReferenceVisibility();
  }

  writeValue(value: string): void {
    this.value = value;
    this.modalSelectedStyleId = value;
    this.goToSelectedCardPage(this.value);
    this.updateUserStyleReferenceVisibility();
    this.cdr.detectChanges();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  filterStyles() {
    this.filteredData = this.data.filter((s) => {
      if (this.selectedTab === "all") {
        return s;
      }
      if (this.selectedTab === s.type) {
        return s;
      }
      return;
    });
  }

  prepareTypes() {
    for (const style of this.data) {
      if (!style.type) {
        continue;
      }

      const title = this.getTypeTitle(style.type);
      const tab = this.tabs.find((t) => t.value === style.type);
      if (!tab) {
        this.tabs.push({
          value: style.type || "",
          title,
        });
      }
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onSelect(styleId: number | undefined, isStyleReference = false) {
    let si = `${styleId}`;
    if (isStyleReference) {
      if (!styleId) {
        this.onChangeStyleReferenceClick();
        return;
      }
      si = `${this.userStyleReferencePrefix}${styleId}`;
    }
    this.value = si;
    this.modalSelectedStyleId = si;
    this.scrollToCard(this.elements, si);
    this.onChange(this.value);
  }

  isActive(styleId: number | string) {
    return this.value === `${styleId}`;
  }

  isModalActive(styleId: number | string) {
    return this.modalSelectedStyleId === `${styleId}`;
  }

  getStyleTitle(styleId: string | undefined) {
    if (styleId?.includes(this.userStyleReferencePrefix)) {
      return $localize`:@@image-generation.style-reference.with-image.title:`;
    }
    const style = this.data.find((s) => `${s.id}` === styleId);
    return style?.title;
  }

  goToTab(value: string) {
    this.selectedTab = value;
    this.selectedTabIndex = this.tabs.findIndex((t) => t.value === value);
    this.filterStyles();
    this.updateModalUserStyleReferenceVisibility();

    this.scrollModalToCardOrBegin();
  }

  scrollModalToCardOrBegin() {
    let selectedId = this.modalSelectedStyleId;
    const card = this.filteredData.find((v) => `${v.id}` === selectedId);
    if (!card) {
      selectedId = `${this.filteredData[0].id}`;
    }
    this.scrollToCard(this.modalElements, selectedId, "center");
  }

  onModalSelect(styleId: number | undefined, isStyleReference = false) {
    let si = `${styleId}`;
    if (isStyleReference) {
      if (!styleId) {
        this.onChangeStyleReferenceClick();
        return;
      }
      si = `${this.userStyleReferencePrefix}${styleId}`;
    }
    this.modalSelectedStyleId = si;
    this.scrollToCard(this.modalElements, si, "center");
  }

  onModalApply() {
    this.value = this.modalSelectedStyleId;
    this.selectedTab = "all";
    this.goToSelectedCardPage(this.value);
    this.updateUserStyleReferenceVisibility();
    this.onChange(this.value);
    this.closeModal();
  }

  private goToSelectedCardPage(selectedId: string | undefined) {
    const cardIndex = this.data.findIndex((d) => `${d.id}` === selectedId);
    if (cardIndex < 0) {
      this.page = 1;
      this.getPaginatedData();
      return;
    }
    this.page = Math.ceil((cardIndex + 1) / this.pageSize);
    this.getPaginatedData();
  }

  private updatePagesCount() {
    let count = this.data.length;
    if (this.hasStyleReference || this.styleReference) {
      count++;
    }
    this.pagesCount = Math.ceil(count / this.pageSize);
  }

  protected hasPreviousPage() {
    return this.page - 1 > 0;
  }

  protected hasNextPage() {
    return this.page + 1 <= this.pagesCount;
  }

  protected goToPreviousPage() {
    if (!this.hasPreviousPage()) {
      return;
    }
    this.page--;
    this.updateUserStyleReferenceVisibility();
    this.getPaginatedData();
  }

  protected goToNextPage() {
    if (!this.hasNextPage()) {
      return;
    }
    this.page++;
    this.updateUserStyleReferenceVisibility();
    this.getPaginatedData();
  }

  private getPaginatedData() {
    let fromIndex = (this.page - 1) * this.pageSize;
    if ((this.hasStyleReference || this.styleReference) && this.page !== 1) {
      fromIndex--;
    }
    let toIndex = fromIndex + this.pageSize;
    if ((this.hasStyleReference || this.styleReference) && this.page === 1) {
      toIndex--;
    }
    this.dataPaginated = this.data.slice(fromIndex, toIndex);
  }

  private scrollToCard(
    elements: QueryList<ElementRef<any>> | undefined,
    selectedId: string | undefined,
    position: "start" | "end" | "center" = "end",
  ) {
    elements?.forEach((element, i) => {
      const elemId = element.nativeElement.getAttribute("id");
      if (elemId === selectedId) {
        element.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: position,
        });
      }
    });
  }

  showModal() {
    this.selectedTab = "all";
    this.filterStyles();
    this.updateModalUserStyleReferenceVisibility();
    this.isModalAllDataVisible = true;
    this.cdr.detectChanges();
    this.scrollToCard(this.modalElements, this.value, "center");
  }

  closeModal() {
    this.selectedTab = "all";
    this.selectedTabIndex = 0;

    this.isModalAllDataVisible = false;
    this.cdr.detectChanges();
    this.scrollToCard(this.modalElements, this.value, "center");
  }

  getTypeTitle(value: string | undefined) {
    switch (value) {
      case "graphics":
        return $localize`:@@image-generation.select-style.group.graphics:`;
      case "painting":
        return $localize`:@@image-generation.select-style.group.painting:`;
      case "digital":
        return $localize`:@@image-generation.select-style.group.digital:`;
      case "photo":
        return $localize`:@@image-generation.select-style.group.photo:`;
      case "children-drawing":
        return $localize`:@@image-generation.select-style.group.children-drawing:`;
      case "fantastic":
        return $localize`:@@image-generation.select-style.group.fantastic:`;
      case "comics":
        return $localize`:@@image-generation.select-style.group.comics:`;
      default:
        return "";
    }
  }

  toggle() {
    if (!this.canCollapsing) {
      return;
    }
    this.isCollapsed = !this.isCollapsed;
  }

  protected updateStyleReferenceImageUrl() {
    if (!this.styleReference) {
      return;
    }

    this.styleReferenceImageUrl = this.imageGenerationUserStyleService.getUrlForImage(
      this.styleReference.id,
      this.styleReference.imageUrl,
      true,
    );

    this.cdr.detectChanges();
  }

  protected onStyleReferenceMouseEnter(isModal = false) {
    if (!this.styleReference) {
      return;
    }
    if (isModal) {
      this.isModalStyleReferenceHovered = true;
    } else {
      this.isStyleReferenceHovered = true;
    }
  }

  protected onStyleReferenceMouseLeave(isModal = false) {
    if (!this.styleReference) {
      return;
    }
    if (isModal) {
      this.isModalStyleReferenceHovered = false;
    } else {
      this.isStyleReferenceHovered = false;
    }
  }

  protected onChangeStyleReferenceClick(event: Event | undefined = undefined) {
    event?.preventDefault();
    event?.stopPropagation();
    this.onChangeStyleReference.emit();
  }

  private updateUserStyleReferenceVisibility() {
    this.isUserStyleReferenceVisible = !!(this.hasStyleReference || this.styleReference) && this.page === 1;
  }

  private updateModalUserStyleReferenceVisibility() {
    this.isModalUserStyleReferenceVisible =
      !!(this.hasStyleReference || this.styleReference) && this.selectedTab === "all";
  }

  @HostListener("window:keydown.escape")
  protected handleKeyDown() {
    this.closeModal();
  }
}
