import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { PricingViewService } from "@metranpage/pricing";
import * as _ from "lodash-es";
import { takeUntil } from "rxjs";
import { Tariff } from "../../models/tariff";
import { AdminTariffsService } from "../../services/tariffs/tariffs.service";
import { AdminTariffsStore } from "../../services/tariffs/tariffs.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-tariff-page",
  templateUrl: "./tariff.page.html",
  styleUrls: ["./tariff.page.scss"],
})
export class AdminTariffPage extends AdminBasePage implements OnInit, AfterViewInit {
  tariff: Tariff | undefined;

  protected form!: FormGroup;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly pricingViewService: PricingViewService,
    private readonly adminTariffsService: AdminTariffsService,
    adminTariffsStore: AdminTariffsStore,
  ) {
    super();

    this.createForm();

    this.addSub(
      adminTariffsStore
        .getActiveTariffObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((tariff) => {
          this.tariff = tariff;
          if (tariff) {
            const formValues = _.omit(tariff, ["id"]);
            this.form.patchValue(formValues);
          }
        }),
    );
  }

  ngOnInit(): void {}

  async ngAfterViewInit() {
    if ("tariffId" in this.route.snapshot.params) {
      this.adminTariffsService.loadTariff(this.route.snapshot.params["tariffId"]);
    }
  }

  async onSave() {
    if (!this.tariff) {
      return;
    }

    const value = this.form.getRawValue();

    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminTariffsService.updateTariff(this.tariff.id, value);
    this.loadingService.stopLoading();
    if (result === "success") {
      this.router.navigate(["admin", "tariffs"]);
    } else {
      this.notificationService.error($localize`:@@admin.tariffs.tariff.error.cant-update-tariff:`);
    }
  }

  onCancel() {
    this.router.navigate(["admin", "tariffs"]);
  }

  createForm() {
    this.form = this.adminTariffsService.createTariffForm();
  }

  protected getOptionsForCurrencySelect() {
    return this.pricingViewService.getOptionsForCurrencySelect();
  }

  protected getOptionsForSubtitleSelect() {
    return this.pricingViewService.getOptionsForSubtitleSelect();
  }
}
