import { CommonModule } from "@angular/common";
import { Component, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "m-checkbox-group",
  template: "<ng-content></ng-content>",
  styles: [
    `
    :host {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 16px;
      user-select: none;
    }
  `,
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true,
    },
  ],
})
export class CheckboxGroupComponent implements ControlValueAccessor {
  private value: any;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  writeValue(value: string[]): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  set(value: string[]) {
    this.value = value;
    this.onChange(this.value);
  }

  addOrRemove(value: string) {
    if (this.contains(value)) {
      this.remove(value);
    } else {
      this.add(value);
    }
  }

  contains(value: string): boolean {
    if (Array.isArray(this.value)) {
      return this.value.includes(value);
    }
    if (this.value) {
      return this.value === value;
    }

    return false;
  }

  private add(value: string) {
    if (!this.contains(value)) {
      if (Array.isArray(this.value)) {
        this.value.push(value);
      } else {
        this.value = [value];
      }
      this.onChange(this.value);
    }
  }

  private remove(value: string) {
    const index = this.value.indexOf(value);
    if (!this.value || index < 0) {
      return;
    }

    this.value.splice(index, 1);
    this.onChange(this.value);
  }
}
