import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { Subscription } from "rxjs";
import { LoginError } from "../../models/login-errors";
import { passwordsConfirmedValidator } from "../registration/registration.view";

export type ResetPassData = {
  password: string;
};

@Component({
  selector: "m-password-restore-new-password-view",
  templateUrl: "./password-restore-new-password.view.html",
  styleUrls: ["./password-restore-new-password.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class PasswordRestoreNewPasswordView {
  @Input()
  errors: LoginError[] = [];
  @Input()
  isLoading = false;
  @Input()
  userLanguage = "ru";

  @Output()
  onResetPassClick = new EventEmitter<ResetPassData>();

  @Output()
  onLangChangeClick = new EventEmitter<string>();

  protected form = new FormGroup(
    {
      password: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
      passwordConfirm: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
    },
    {
      validators: [passwordsConfirmedValidator()],
    },
  );

  protected isInputPasswordErrorVisible = false;
  protected isInputPasswordConfirmErrorVisible = false;

  private sub = new Subscription();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.watchFormChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onResetPassButtonClick() {
    this.isInputPasswordErrorVisible = true;
    this.isInputPasswordConfirmErrorVisible = true;
    this.setFormValidators("password");
    this.setFormValidators("passwordConfirm");

    if (this.isResetPassButtonDisabled()) {
      return;
    }

    const value = this.form.value;
    this.onResetPassClick.emit({
      password: value.password!,
    });
  }

  isResetPassButtonDisabled() {
    return !!this.form.get("password")?.errors || !!this.form.get("passwordConfirm")?.errors;
  }

  protected onKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      if (!this.isResetPassButtonDisabled()) {
        this.onResetPassButtonClick();
      }
    }
  }

  protected onLangChange(lang: string) {
    this.onLangChangeClick.emit(lang);
  }

  protected isFieldEmpty(controlName: string) {
    return this.form.get(controlName)?.errors?.["required"];
  }

  protected isPasswordsDifferent() {
    return !!this.form.get("passwordConfirm")?.errors?.mismatch;
  }

  protected focusoutEvent(controlName: string) {
    const value = this.form.get(controlName)?.value;
    if (value && controlName === "password") {
      this.isInputPasswordErrorVisible = true;
    }
    if (controlName === "passwordConfirm") {
      this.isInputPasswordConfirmErrorVisible = true;
    }
    this.setFormValidators(controlName);
  }

  protected watchFormChanges() {
    this.sub.add(
      this.form.get("password")?.valueChanges.subscribe((value) => {
        this.isInputPasswordErrorVisible = false;
        this.clearFormValidators("password");
        this.cdr.detectChanges();
      }),
    );

    this.sub.add(
      this.form.get("passwordConfirm")?.valueChanges.subscribe((value) => {
        this.isInputPasswordConfirmErrorVisible = false;
        this.clearFormValidators("passwordConfirm");
        this.cdr.detectChanges();
      }),
    );
  }

  private setFormValidators(controlName: string) {
    if (controlName === "password") {
      this.form.get(controlName)?.setValidators([Validators.required]);
    }
    if (controlName === "passwordConfirm") {
      this.form.get(controlName)?.setValidators([Validators.required]);
    }

    this.form.get(controlName)?.updateValueAndValidity({ emitEvent: false });
    this.cdr.detectChanges();
  }

  private clearFormValidators(controlName: string) {
    this.form.get(controlName)?.clearValidators();
    this.form.get(controlName)?.updateValueAndValidity({ emitEvent: false });
  }
}
