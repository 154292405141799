import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
  Book,
  BookCoverDto,
  BookCoverLaminationDto,
  BookDto,
  BookPaperDto,
  BookPrintSettings,
  BookPrintSettingsConstantsDto,
  BookSettings,
  BookSize,
  EditionNoticeSettings,
  MarginsState,
  MarkupSettingsDTO,
  RunningTitlesState,
  ScriptsParams,
  TemplateWithPreviewData,
  TocSettings,
  UpdateBookInfo,
  UpdateBookTypes,
} from "@metranpage/book-data";
import { Api, BASE_URL, DOWNLOAD_FILE_TEMPLATE } from "@metranpage/core-api";
import { PaymentData } from "@metranpage/user-payment-data";
import { saveAs } from "file-saver";
import { UploadOriginalFileResponse } from "./book.service";

@Injectable({
  providedIn: "root",
})
export class BooksApi extends Api {
  constructor(
    http: HttpClient,
    @Inject(BASE_URL) baseUrl: string,
    @Inject(DOWNLOAD_FILE_TEMPLATE) private readonly downloadFileTemplate: string,
  ) {
    super(http, baseUrl);
  }

  async getBooks() {
    return this.get<Book[]>("books");
  }

  async getBook(id: number) {
    return this.get<Book>(`books/${id}`);
  }

  async create() {
    return this.post<Book>("books", {});
  }

  async updateBook(id: number, bookData: BookDto) {
    return this.put<Book>(`books/${id}`, bookData);
  }

  async copyBook(id: number) {
    return this.post<Book>(`books/copy/${id}`, {});
  }

  async setBookSize(id: number, size: BookSize) {
    return this.post<Book>(`books/${id}/size`, size);
  }

  async setBookTemplate(id: number, templateId: number) {
    return this.post<Book>(`books/${id}/template`, { templateId });
  }

  async setBookDefaultTemplate(id: number) {
    return this.post<Book>(`books/${id}/default-template`, {});
  }

  async updateBookInfo(id: number, bookInfo: UpdateBookInfo) {
    return this.put<Book>(`books/${id}/info`, bookInfo);
  }

  async updateBookTypes(id: number, bookTypes: UpdateBookTypes) {
    return this.put<Book>(`books/${id}/types`, bookTypes);
  }

  async uploadOriginalFile(bookId: number, file: File) {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post<UploadOriginalFileResponse>(`books/${bookId}/original`, formData, {
      headers,
    });
  }

  async deleteBookFile(id: number) {
    return this.delete<Book>(`books/${id}/original`);
  }

  async uploadSampleFile(bookId: number) {
    return this.post(`books/${bookId}/sample`, {});
  }

  async uploadEmptyFile(bookId: number) {
    return this.post(`books/${bookId}/empty`, {});
  }

  getBookStyles(bookId: number) {
    return this.get<MarkupSettingsDTO>(`books/${bookId}/styles`);
  }

  async setBookStyles(id: number, dto: MarkupSettingsDTO) {
    return this.post<Book>(`books/${id}/styles`, dto);
  }

  async getEditorData(id: number) {
    return this.get(`books/files/${id}/editor-data`);
  }

  async setEditorData(bookId: number, data: object, generateDocx: boolean) {
    return this.post(`books/files/${bookId}/editor-data`, {
      data,
      generateDocx,
    });
  }

  getUrlForEditorImage(bookId: number, imagePath: string) {
    if (imagePath.startsWith("images/")) {
      return this.absoluteUrl(`books/files/${bookId}/${imagePath}`);
    }
    // not our image
    return imagePath;
  }

  getUrlForTemplatePreview(templateId: number, imagePath: string) {
    return this.absoluteUrl(`templates/${templateId}/previews/${imagePath}`);
  }

  getUrlForBookFile(bookId: number, filePath: string) {
    return this.absoluteUrl(`books/files/${bookId}/${filePath}`);
  }

  async deleteBook(id: number) {
    return this.delete(`books/${id}`);
  }

  async createPreview(id: number, bookSettings: BookSettings) {
    return this.put(`books/${id}/preview`, bookSettings);
  }

  async createFinal(id: number, isGoldCreditUsed: boolean) {
    return this.post(`books/${id}/final`, { isGoldCreditUsed });
  }

  async hasEnoughCreditsForBook(id: number) {
    return this.get<PaymentData>(`books/${id}/check-credits`);
  }

  async uploadEditorImage(bookId: number, file: File, imageIndex: number) {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    formData.append("imageIndex", `${imageIndex}`);

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post(`books/files/${bookId}/images`, formData, {
      headers,
    });
  }

  async downloadFinal(book: Book, filename: string, downloadFilename: string) {
    return (
      this.getAsBlob(`books/files/${book.id}/finals/${filename}`)
        // .then((res) => res.blob())
        .then((blob) => saveAs(blob, downloadFilename))
    );
  }

  async downloadEpub(book: Book, filename: string, downloadFilename: string) {
    return (
      this.getAsBlob(`books/files/${book.id}/epub/${filename}`)
        // .then((res) => res.blob())
        .then((blob) => saveAs(blob, downloadFilename))
    );
  }

  async trackActive(bookId: number) {
    return this.get(`books/${bookId}/track-active`);
  }

  getFontFileUrl(code: string): string {
    return this.absoluteUrl(`settings/font/${code}`);
  }

  async getScriptsParams(): Promise<ScriptsParams> {
    return this.get<ScriptsParams>("settings/scripts");
  }

  async getBookFeaturesForFile(file: File) {
    const formData: FormData = new FormData();
    formData.append("file", file, "file");

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post("books/get-features-for-file", formData, {
      headers,
    });
  }

  async getTemplates() {
    return this.get<TemplateWithPreviewData>("templates");
  }

  async updateMarginsState(id: number, marginsState: Partial<MarginsState>) {
    return this.post<Book>(`books/${id}/update-margins-state`, { marginsState });
  }

  async updateRunningTitlesState(id: number, runningTitlesState: Partial<RunningTitlesState>) {
    return this.post<Book>(`books/${id}/update-running-titles-state`, { runningTitlesState });
  }

  async updateTocSettings(id: number, tocSettings: TocSettings) {
    return this.post<Book>(`books/${id}/update-toc-settings`, { tocSettings });
  }

  async updateCenterImagesSettings(id: number, centerImages: boolean) {
    return this.post<Book>(`books/${id}/update-center-images-settings`, { centerImages });
  }

  /////////////////////////////
  // PRINT SETTINGS
  /////////////////////////////

  getPrintSettings(bookId: number): Promise<BookPrintSettings> {
    return this.get<BookPrintSettings>(`books/${bookId}/print-settings`);
  }

  mergePrintSettings(bookId: number, data: Partial<BookPrintSettings>) {
    return this.post<BookPrintSettings>(`books/${bookId}/print-settings`, data);
  }

  createPrintCalculationRequest(bookId: number, data: Partial<BookPrintSettings>): Promise<void> {
    return this.post<void>(`books/${bookId}/print-calculation-request`, data);
  }

  calculatePrintEstimatedPrice(bookId: number, data: Partial<BookPrintSettings>): Promise<number> {
    return this.post<number>(`books/${bookId}/print-estimated-price`, data);
  }

  getPrintSettingsConstants(): Promise<BookPrintSettingsConstantsDto> {
    return this.get<BookPrintSettingsConstantsDto>("book-constants/print-settings");
  }

  async getEditionNoticeSettings() {
    return this.get<EditionNoticeSettings[]>("edition-notice");
  }
}
