import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { GeneratedImage } from "../models/generated-image";

export type GeneratedImagesState = {
  generatedImages: GeneratedImage[];
  generatedImagesPageCount: number;
  previewGeneratedImage: GeneratedImage | undefined;
};

@Injectable({
  providedIn: "root",
})
export class GeneratedImageStore extends Store<GeneratedImagesState> {
  protected override getInitialState(): GeneratedImagesState {
    return {
      generatedImages: [],
      generatedImagesPageCount: 1,
      previewGeneratedImage: undefined,
    };
  }

  getGeneratedImagesObservable() {
    return this.getFieldObservable("generatedImages");
  }

  getGeneratedImages() {
    return this.getField("generatedImages");
  }

  setGeneratedImages(generatedImages: GeneratedImage[]) {
    this.update((state) => ({ ...state, generatedImages }));
  }

  getGeneratedImagesPageCountObservable() {
    return this.getFieldObservable("generatedImagesPageCount");
  }

  setGeneratedImagesPageCount(count: number) {
    this.update((state) => ({ ...state, generatedImagesPageCount: count }));
  }

  addGeneratedImage(generatedImage: GeneratedImage) {
    this.update((state) => {
      const storeGeneratedImage = state.generatedImages.find((ig) => ig.id === generatedImage.id);
      if (storeGeneratedImage) {
        return state;
      }

      return {
        ...state,
        generatedImages: [generatedImage].concat(state.generatedImages),
      };
    });
  }

  addGeneratedImagesToStart(generatedImages: GeneratedImage[]) {
    this.update((state) => {
      const newGeneratedImages: GeneratedImage[] = [];
      for (const generatedImage of generatedImages) {
        const storeGeneratedImage = state.generatedImages.find((ig) => ig.id === generatedImage.id);
        if (storeGeneratedImage) {
          continue;
        }
        newGeneratedImages.push(generatedImage);
      }

      return {
        ...state,
        generatedImages: newGeneratedImages.concat(state.generatedImages),
      };
    });
  }

  addGeneratedImagesToEnd(generatedImages: GeneratedImage[]) {
    this.update((state) => {
      const newGeneratedImages: GeneratedImage[] = [];
      for (const generatedImage of generatedImages) {
        const storeImageGeneration = state.generatedImages.find((ig) => ig.id === generatedImage.id);
        if (storeImageGeneration) {
          continue;
        }
        newGeneratedImages.push(generatedImage);
      }

      return {
        ...state,
        generatedImages: state.generatedImages.concat(newGeneratedImages),
      };
    });
  }

  updateGeneratedImage(updatedGeneratedImage: GeneratedImage) {
    this.update((state) => {
      const updatedGeneratedImages = state.generatedImages.map((g) => {
        if (g.id === updatedGeneratedImage.id) {
          return updatedGeneratedImage;
        }
        return g;
      });

      return {
        ...state,
        generatedImages: updatedGeneratedImages,
      };
    });

    const previewGeneratedImage = this.getField("previewGeneratedImage");
    if (updatedGeneratedImage.id === previewGeneratedImage?.id) {
      this.update((state) => {
        return {
          ...state,
          previewGeneratedImage: updatedGeneratedImage,
        };
      });
    }
  }

  getPreviewGeneratedImageObservable() {
    return this.getFieldObservable("previewGeneratedImage");
  }

  getPreviewGeneratedImage() {
    return this.getField("previewGeneratedImage");
  }

  setPreviewGeneratedImage(generatedImage: GeneratedImage | undefined) {
    this.update((state) => {
      return {
        ...state,
        previewGeneratedImage: generatedImage,
      };
    });
  }
}
