import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api } from "@metranpage/core-api";
import { MaintenanceStatus } from "../models/maintenance-status";
import { KV_URL } from "../token";

@Injectable({
  providedIn: "root",
})
export class MaintenanceApi extends Api {
  constructor(http: HttpClient, @Inject(KV_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async loadStatus(): Promise<MaintenanceStatus> {
    if (!this.baseUrl) {
      return { isMaintenanceInProgress: false };
    }

    // https://clintnetwork.gitbook.io/lucid/docs/api
    const maintenanceStatus = await this.get<{enabled: boolean}>("namespace/default/key/maintenance-status").catch(() => ({enabled: false}));
    return { isMaintenanceInProgress: maintenanceStatus.enabled };
  }
}
