import { Component, EventEmitter, Input, Output } from "@angular/core";
import { User } from "@metranpage/user-data";
import { RewardNotification } from "../../models/rewards/reward-notification";

@Component({
  selector: "m-account-reward-notifications-view",
  templateUrl: "account-reward-notifications.view.html",
  styleUrls: ["account-reward-notifications.view.scss"],
})
export class AccountRewardNotificationsView {
  @Input()
  user?: User;
  @Input()
  rewardNotifications: RewardNotification[] = [];

  @Output()
  onScroll = new EventEmitter();

  protected onScrollEvent() {
    this.onScroll.emit();
  }

  protected getTitle(reward: RewardNotification) {
    switch (reward.kind) {
      case "connect-telegram":
        return $localize`:@@user.profile.rewards.subscribe-to-telegram-channel:`;
      case "likes-give":
        return $localize`:@@user.profile.rewards.like.give:`;
      case "likes-take":
        return $localize`:@@user.profile.rewards.like.take:`;
      case "moderation-image":
        return $localize`:@@user.profile.rewards.moderation-image:`;
      case "moderation-cover":
        return $localize`:@@user.profile.rewards.moderation-cover:`;
      default:
        return "";
    }
  }

  protected getIconSrc(reward: RewardNotification) {
    switch (reward.kind) {
      case "connect-telegram":
        return "reward-telegram.svg";
      case "likes-give":
        return "like-2.svg";
      case "likes-take":
        return "like-2.svg";
      case "moderation-image":
        return "notification-image-moderation.svg";
      case "moderation-cover":
        return "notification-cover-moderation.svg";
      default:
        return "";
    }
  }
}
