import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Character } from "../../models/character/character";
import { CharactersService } from "../../services/characters.service";

@Component({
  selector: "m-prompt",
  template: "",
  styleUrls: ["./prompt.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromptView implements OnChanges {
  @Input()
  prompt = "";
  @Input()
  showCharacters = false;
  @Input()
  characters: Character[] = [];

  constructor(
    private readonly elementRef: ElementRef,
    private readonly charactersService: CharactersService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.showCharacters || this.characters.length === 0) {
      this.replaceCharactersByTemplate();
      return;
    }

    this.replaceCharacters();
  }

  replaceCharactersByTemplate() {
    const regex = /@character-id=\{\d+\}/gm;
    let html = this.prompt;
    const matches = [...this.prompt.matchAll(regex)];
    for (const match of matches) {
      let characterName = $localize`:@@characters.generation.character-template-text:`;
      if (match.index) {
        characterName = characterName.toLowerCase();
      }
      const characterTag = `<span class="character-template">${characterName}</span>`;
      html = html.replace(match[0], characterTag);
    }
    this.elementRef.nativeElement.innerHTML = html;
  }

  replaceCharacters() {
    const charactersIds = this.charactersService.getCharactersIdsFromText(this.prompt);
    let html = this.prompt;
    for (const characterId of charactersIds) {
      const character = this.characters.find((c) => c.id === characterId);
      if (!character) {
        html = this.replaceCharacterByTemplate(html, characterId);
        continue;
      }
      html = this.replaceCharacter(html, character);
    }
    this.elementRef.nativeElement.innerHTML = html;
  }

  replaceCharacterByTemplate(text: string, id: number) {
    const regex = this.charactersService.getCharactersRegex(id);
    let characterName = $localize`:@@characters.generation.character-template-text:`;
    const match = regex.exec(text);
    if (match?.index) {
      characterName = characterName.toLowerCase();
    }
    const characterTag = `<span class="character-template">${characterName}</span>`;
    const updatedText = text.replaceAll(regex, characterTag);
    return updatedText;
  }

  replaceCharacter(text: string, character: Character) {
    const regex = this.charactersService.getCharactersRegex(character.id);
    const characterTag = `<span class="character-name">${character.name}</span>`;
    const updatedText = text.replaceAll(regex, `${characterTag}`);
    return updatedText;
  }
}
