import { Injectable } from "@angular/core";
import { SelectValue } from "@metranpage/components";
import { SliderPoint } from "../views/slider-selector/slider-selector.view";

export type GeneralResultStatus = "success" | "error";

@Injectable({
  providedIn: "root",
})
export class ImageGenerationDataService {
  getDetalizationPoints() {
    return this.detalizationPoints;
  }

  getDetalizationTitle(value: string) {
    let detalization = $localize`:@@image-generation.detalization-value.normal:`;
    if (value === "minimum") {
      detalization = $localize`:@@image-generation.detalization-value.min:`;
    }
    if (value === "ultradetailed") {
      detalization = $localize`:@@image-generation.detalization-value.max:`;
    }
    return detalization;
  }

  getMoodOptions() {
    return this.moodOptions;
  }

  getMoodTitle(value: string) {
    const moodOptions = this.getMoodOptions();
    return moodOptions.find((mo) => mo.id === value)?.value;
  }

  getAdvancedGenerationModeOptions() {
    return this.advancedGenerationModeOptions;
  }

  getAdvancedGenerationModeTitle(value: string) {
    const moodOptions = this.getAdvancedGenerationModeOptions();
    return moodOptions.find((o) => o.id === value)?.value;
  }

  getAgeOptions() {
    return this.ageOptions;
  }

  getContrastPoints() {
    return this.contrastPoints;
  }

  getModelOptions() {
    return this.modelOptions;
  }

  getModeOptions() {
    return this.modeOptions;
  }

  getDefaultPrompts() {
    return this.defaultPrompts;
  }

  getUpscaleStrengthPoints() {
    return this.upscaleStrengthPoints;
  }

  protected detalizationPoints: SliderPoint[] = [
    {
      value: "minimum",
      text: $localize`:@@image-generation.detalization.min:`,
    },
    {
      value: "detailed",
      text: $localize`:@@image-generation.detalization.normal:`,
    },
    {
      value: "ultradetailed",
      text: $localize`:@@image-generation.detalization.max:`,
    },
  ];

  protected moodOptions: SelectValue[] = [
    {
      id: "devilish",
      value: $localize`:@@image-generation.mood.devilish:`,
    },
    {
      id: "evil",
      value: $localize`:@@image-generation.mood.evil:`,
    },
    {
      id: "crazy",
      value: $localize`:@@image-generation.mood.crazy:`,
    },
    {
      id: "dramatic",
      value: $localize`:@@image-generation.mood.dramatic:`,
    },
    {
      id: "sad",
      value: $localize`:@@image-generation.mood.sad:`,
    },
    {
      id: "mystical",
      value: $localize`:@@image-generation.mood.mystical:`,
    },
    {
      id: "neutral",
      value: $localize`:@@image-generation.mood.neutral:`,
    },
    {
      id: "heroic",
      value: $localize`:@@image-generation.mood.heroic:`,
    },
    {
      id: "romantic",
      value: $localize`:@@image-generation.mood.romantic:`,
    },
    {
      id: "joyful",
      value: $localize`:@@image-generation.mood.joyful:`,
    },
    {
      id: "happy",
      value: $localize`:@@image-generation.mood.happy:`,
    },
  ];

  protected ageOptions: SelectValue[] = [
    {
      id: "stone-age",
      value: $localize`:@@image-generation.age.stone-age:`,
    },
    {
      id: "bronze-age",
      value: $localize`:@@image-generation.age.bronze-age:`,
    },
    {
      id: "iron-age",
      value: $localize`:@@image-generation.age.iron-age:`,
    },
    {
      id: "antiquity",
      value: $localize`:@@image-generation.age.antiquity:`,
    },
    {
      id: "middle-ages",
      value: $localize`:@@image-generation.age.middle-ages:`,
    },
    {
      id: "renaissance",
      value: $localize`:@@image-generation.age.renaissance:`,
    },
    {
      id: "industrialization",
      value: $localize`:@@image-generation.age.industrialization:`,
    },
    {
      id: "modernity",
      value: $localize`:@@image-generation.age.modernity:`,
    },
    {
      id: "future",
      value: $localize`:@@image-generation.age.future:`,
    },
    {
      id: "set-year",
      value: $localize`:@@image-generation.age.set-year:`,
    },
  ];

  protected contrastPoints: SliderPoint[] = [
    {
      value: 2.5,
      text: $localize`:@@image-generation.contrast.weak:`,
    },
    {
      value: 3,
    },
    {
      value: 3.5,
    },
    {
      value: 4,
    },
    {
      value: 4.5,
      text: $localize`:@@image-generation.contrast.strong:`,
    },
  ];

  protected modelOptions: SelectValue[] = [
    // {
    //   id: "none",
    //   value: "None",
    // },
    {
      id: "Leonardo Anime XL",
      value: "Anime XL",
    },
    {
      id: "Leonardo Lightning XL",
      value: "Lightning XL",
    },
    {
      id: "SDXL 1.0",
      value: "SDXL 1.0",
    },
    {
      id: "Leonardo Kino XL",
      value: "Kino XL",
    },
    {
      id: "Leonardo Vision XL",
      value: "Vision XL",
    },
    {
      id: "Leonardo Diffusion XL",
      value: "Diffusion XL",
    },
    {
      id: "AlbedoBase XL",
      value: "AlbedoBase XL",
    },
    {
      id: "RPG v5",
      value: "RPG v5",
    },
    {
      id: "SDXL 0.9",
      value: "SDXL 0.9",
    },
    {
      id: "3D Animation Style",
      value: "3D Animation Style",
    },
    // {
    //   id: "DreamShaper v7",
    //   value: "DreamShaper v7",
    // },
    {
      id: "Absolute Reality v1.6",
      value: "Absolute Reality v1.6",
    },
    {
      id: "Anime Pastel Dream",
      value: "Anime Pastel Dream",
    },
    // {
    //   id: "DreamShaper v6",
    //   value: "DreamShaper v6",
    // },
    // {
    //   id: "DreamShaper v5",
    //   value: "DreamShaper v5",
    // },
    {
      id: "Leonardo Diffusion",
      value: "Diffusion",
    },
    // {
    //   id: "RPG 4.0",
    //   value: "RPG 4.0",
    // },
    {
      id: "Deliberate 1.1",
      value: "Deliberate 1.1",
    },
    {
      id: "Vintage Style Photography",
      value: "Vintage Style Photography",
    },
    // {
    //   id: "DreamShaper 3.2",
    //   value: "DreamShaper 3.2",
    // },
    {
      id: "Leonardo Select",
      value: "Select",
    },
    {
      id: "Leonardo Creative",
      value: "Creative",
    },
    {
      id: "Battle Axes",
      value: "Battle Axes",
    },
    {
      id: "Pixel Art",
      value: "Pixel Art",
    },
    {
      id: "Crystal Deposits",
      value: "Crystal Deposits",
    },
    {
      id: "Chest Armor",
      value: "Chest Armor",
    },
    {
      id: "Magic Potions",
      value: "Magic Potions",
    },
    {
      id: "Magic Items",
      value: "Magic Items",
    },
    {
      id: "Character Portraits",
      value: "Character Portraits",
    },
    {
      id: "Amulets",
      value: "Amulets",
    },
    {
      id: "Christmas Stickers",
      value: "Christmas Stickers",
    },
    {
      id: "Cute Characters",
      value: "Cute Characters",
    },
    {
      id: "Spirit Creatures",
      value: "Spirit Creatures",
    },
    {
      id: "Cute Animal Characters",
      value: "Cute Animal Characters",
    },
    {
      id: "Isometric Scifi Buildings",
      value: "Isometric Scifi Buildings",
    },
    {
      id: "Isometric Fantasy",
      value: "Isometric Fantasy",
    },
    {
      id: "Shields",
      value: "Shields",
    },
    {
      id: "Crystal Deposits Alternate",
      value: "Crystal Deposits Alternate",
    },
    {
      id: "Isometric Asteroid Tiles",
      value: "Isometric Asteroid Tiles",
    },
    {
      id: "Leonardo Signature",
      value: "Signature",
    },
  ];

  protected modeOptions: SelectValue[] = [
    { id: "ANIME", value: "Anime" },
    { id: "CINEMATIC", value: "Cinematic" },
    { id: "CREATIVE", value: "Creative" },
    { id: "DYNAMIC", value: "Dynamic" },
    { id: "ENVIRONMENT", value: "Environment" },
    { id: "GENERAL", value: "General" },
    { id: "ILLUSTRATION", value: "Illustration" },
    { id: "LEONARDO", value: "Universal" },
    { id: "NONE", value: "None" },
    { id: "PHOTOGRAPHY", value: "Photography" },
    { id: "RAYTRACED", value: "Raytraced" },
    { id: "RENDER_3D", value: "Render" },
    { id: "SKETCH_BW", value: "Sketch" },
    { id: "SKETCH_COLOR", value: "Sketch color" },
    { id: "VIBRANT", value: "Vibrant" },
  ];

  protected defaultPrompts = [
    $localize`:@@image-generation.generation.default-prompt.1:`,
    $localize`:@@image-generation.generation.default-prompt.2:`,
    $localize`:@@image-generation.generation.default-prompt.3:`,
    $localize`:@@image-generation.generation.default-prompt.4:`,
    $localize`:@@image-generation.generation.default-prompt.5:`,
    $localize`:@@image-generation.generation.default-prompt.6:`,
    $localize`:@@image-generation.generation.default-prompt.7:`,
    $localize`:@@image-generation.generation.default-prompt.8:`,
    $localize`:@@image-generation.generation.default-prompt.9:`,
  ];

  protected upscaleStrengthPoints: SliderPoint[] = [
    {
      value: 1,
      text: $localize`:@@image-generation.generation.upscale.strength-description.min:`,
    },
    {
      value: 3,
    },
    {
      value: 5,
      text: $localize`:@@image-generation.generation.upscale.strength-description.normal:`,
    },
    {
      value: 7,
    },
    {
      value: 9,
      text: $localize`:@@image-generation.generation.upscale.strength-description.max:`,
    },
  ];

  protected advancedGenerationModeOptions: SelectValue[] = [
    { id: "fast", value: $localize`:@@image-generation.image-advanced-generation-mode.fast:` },
    { id: "quality", value: $localize`:@@image-generation.image-advanced-generation-mode.quality:` },
    { id: "ultra", value: $localize`:@@image-generation.image-advanced-generation-mode.ultra:` },
  ];
}
