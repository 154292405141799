import { Component, Inject } from "@angular/core";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";
import { UserVerificationOverlayServiceInterface } from "@metranpage/user-interfaces";

@Component({
  selector: "m-account-notification-email-verification-card-view",
  templateUrl: "account-notification-email-verification-card.view.html",
  styleUrls: ["account-notification-email-verification-card.view.scss"],
})
export class AccountNotificationEmailVerificationCardView extends AccountNotificationBaseCardView<undefined> {
  constructor(
    @Inject("UserVerificationOverlayService")
    protected readonly userVerificationOverlayService: UserVerificationOverlayServiceInterface,
  ) {
    super();
  }

  openAccountVerificationPage() {
    this.userVerificationOverlayService.open();
  }
}
