import { Component, EventEmitter, Input, Output } from "@angular/core";
import { User } from "@metranpage/user-data";
import { ModerationImageRequest } from "../../models/moderation-image-request";

@Component({
  selector: "m-share-image-modal",
  templateUrl: "./share-image-modal.view.html",
  styleUrls: ["./share-image-modal.view.scss"],
})
export class ShareImageModalView {
  @Input()
  user!: User;
  @Input()
  isPublic?: boolean;
  @Input()
  moderationRequest?: ModerationImageRequest;

  @Output()
  onClose = new EventEmitter();
  @Output()
  onPublish = new EventEmitter();

  protected publishClick() {
    this.onPublish.emit();
  }

  protected onCloseClick() {
    this.onClose.emit();
  }

  protected canPublish() {
    return (
      !this.moderationRequest ||
      (!this.isPublic && this.moderationRequest?.status !== "created" && this.moderationRequest?.status !== "declined")
    );
  }

  protected isPublished() {
    return this.isPublic && this.moderationRequest?.status === "accepted";
  }

  protected isModerating() {
    return !this.isPublic && this.moderationRequest?.status === "created";
  }

  protected isDeclined() {
    return this.moderationRequest?.status === "declined";
  }

  protected hasReason() {
    return this.moderationRequest?.moderatorDescription !== "no-reason";
  }
}
