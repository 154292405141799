import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  Dimension,
  Dimensions,
  MarginKey,
  MarginsDimentionsAll,
  MarginsState,
  PagePosition,
  RunningTitleContext,
  RunningTitlesState,
} from "@metranpage/book-data";
import { PaletteDTO } from "@metranpage/components";
import { Subscription } from "rxjs";
import { MarginsService } from "../../services/margins.service";
import { MarginsStore } from "../../services/margins.store";
import { RunningTitlesService } from "../../services/running-titles.service";
import { RunningTitlesStore } from "../../services/running-titles.store";

@Component({
  selector: "m-running-titles-preview",
  templateUrl: "./running-titles-preview.component.html",
  styleUrls: ["./running-titles-preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RunningTitlesPreviewComponent implements OnInit {
  @Input("page-size")
  pageSize!: Dimensions;
  @Input("font-size")
  fontSize = 0;
  @Input()
  palette?: PaletteDTO;
  @Input("hide-margins-borders")
  isMarginsBordersHide = false;

  @Output()
  onRunningTitlesTextWidthErrors = new EventEmitter<PagePosition[]>();

  protected viewPageSize: Dimensions = { width: 0, height: 0 };
  protected sizeCoefficients = { cx: 0, cy: 0 };
  protected marginsState!: MarginsState;
  protected state!: RunningTitlesState;
  protected runningTitlesTextWidthErrors: PagePosition[] = [];

  protected fontSizeInPixeles = this.fontSize;
  protected safeMarginSizeMm = 7;
  protected marginBottomTopMarginSizeMm = 5;

  protected marginsPixels!: MarginsDimentionsAll;

  private sub = new Subscription();

  constructor(
    private elementRef: ElementRef,
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly marginsService: MarginsService,
    private readonly marginsStore: MarginsStore,
    private readonly runningTitlesService: RunningTitlesService,
    private readonly runningTitlesStore: RunningTitlesStore,
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.marginsStore.getMarginsStateObservable().subscribe((marginsState) => {
        this.marginsState = marginsState;
        this.updateMargins(marginsState);
        this.changeDetectionRef.detectChanges();
      }),
    );

    this.sub.add(
      this.runningTitlesStore
        .getRunningTitlesStateObservable()
        .pipe()
        .subscribe((state) => {
          this.state = state;
          this.checkTextWidth();
          this.changeDetectionRef.detectChanges();
        }),
    );
  }

  ngAfterViewInit() {
    window.dispatchEvent(new Event("resize"));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getPageSize() {
    return this.pageSize;
  }

  protected onPagePreviewChange(viewPageSize: Dimensions) {
    this.viewPageSize = viewPageSize;
    this.sizeCoefficients = this.marginsService.calculateSizeCoefficients(this.pageSize, this.viewPageSize);
    this.updateMargins(this.marginsState);
    this.runningTitlesStore.setFontSizeInPixeles(this.fontSizeInPixeles);
  }

  private updateMargins(state: MarginsState) {
    for (const [key, millimetersValue] of Object.entries(state.margins)) {
      const marginKey = key as MarginKey;
      const dimension = this.marginsService.getDimension(marginKey);
      const marginClassName = this.marginsService.getMarginClass(marginKey);
      const marginElements = this.elementRef.nativeElement.querySelectorAll(`.${marginClassName}`);
      for (const marginElement of marginElements) {
        const pixelsValue = this.marginsService.scaleDown(millimetersValue, marginKey, this.sizeCoefficients);
        marginElement.style[dimension] = `${pixelsValue}px`;
      }
    }
    this.calculateFontSize();
    this.calculateMarginsInPixels();

    this.changeDetectionRef.detectChanges();
  }

  private calculateFontSize() {
    if (!this.fontSize) {
      return;
    }
    this.fontSizeInPixeles = this.marginsService.calculateFontSizeInPixels(this.fontSize, this.sizeCoefficients);
  }

  protected isRunningTitleVisible(position: number) {
    return this.state?.runningTitlePosition === position && this.state.runningTitleState;
  }

  protected isPageNumberVisible(position: number) {
    return (
      (this.state?.runningTitlePosition !== position || !this.state.runningTitleState) &&
      this.state?.pageNumberPosition === position &&
      this.state.pageNumberState
    );
  }

  protected getRunningTitleText(pagePosition: PagePosition, position: number): RunningTitleContext {
    const runningTitleContext: RunningTitleContext = {
      runningTitle: "",
      pageNumber: "",
    };
    if (this.state?.runningTitleState && this.state?.runningTitlePosition === position) {
      const runningTitleText =
        pagePosition === "left" ? this.state.runningTitleLeftText : this.state.runningTitleRightText;
      runningTitleContext.runningTitle = runningTitleText;
    }
    if (this.state?.pageNumberState && this.state?.pageNumberPosition === position) {
      const pageNumberText = pagePosition === "left" ? "123" : "456";
      runningTitleContext.pageNumber = pageNumberText;
    }
    return runningTitleContext;
  }

  protected checkTextWidth() {
    this.runningTitlesTextWidthErrors = [];
    const positions: PagePosition[] = ["left", "right"];
    const runningTitlesPosition = this.state.runningTitlePosition;
    let runningTitleText = "";
    let textWidth = 0;
    for (const position of positions) {
      runningTitleText = position === "left" ? this.state.runningTitleLeftText : this.state.runningTitleRightText;
      if (runningTitlesPosition === this.state.pageNumberPosition) {
        const pageNumbersText = position === "left" ? "123" : "456";
        runningTitleText += pageNumbersText;
      }
      textWidth = this.marginsService.getTextWidth(runningTitleText, this.fontSizeInPixeles);
      const marginKey = this.runningTitlesService.convertPositionToMarginKey(runningTitlesPosition);
      let dimension: Dimension = "width";
      if (!this.marginsService.isMarginHorizontal(marginKey)) {
        dimension = "height";
      }
      if (textWidth > this.marginsPixels[marginKey][dimension]) {
        this.runningTitlesTextWidthErrors.push(position);
      }
    }

    this.emitTextWidthErrors();
  }

  private emitTextWidthErrors() {
    this.onRunningTitlesTextWidthErrors.emit(this.runningTitlesTextWidthErrors);
  }

  protected getRunningTitleStyle() {
    const selectedStyleId = this.state?.runningTitleStyle;
    const style = this.runningTitlesService.getRunningTitleStyleById(selectedStyleId);
    return style;
  }

  protected getPageNumberStyle() {
    const selectedStyleId = this.state?.pageNumberStyle;
    const style = this.runningTitlesService.getPageNumbersStyleById(selectedStyleId);
    return style;
  }

  protected calculateMarginsInPixels() {
    this.marginsPixels = this.marginsService.calculateMarginsInPixels(
      this.marginsState.margins,
      this.pageSize,
      this.sizeCoefficients,
      this.marginsState.widePaddingState,
    );
  }

  protected getPageStyle(page: PagePosition) {
    if (page === "left") {
      if (this.marginsState.widePaddingState) {
        if (this.marginsState.widePaddingLeftPosition > 1) {
          return {
            "grid-template-columns": `${this.marginsPixels.marginOuter.width}px 
              ${this.marginsPixels.textPadding.width}px 
              ${this.marginsPixels.gutter.width}px 
              ${this.marginsPixels.widePadding.width}px 
              ${this.marginsPixels.marginInner.width}px`,
          };
        }
        return {
          "grid-template-columns": `${this.marginsPixels.marginOuter.width}px 
              ${this.marginsPixels.widePadding.width}px 
              ${this.marginsPixels.gutter.width}px
              ${this.marginsPixels.textPadding.width}px 
              ${this.marginsPixels.marginInner.width}px`,
        };
      }
      return {
        "grid-template-columns": `${this.marginsPixels.marginOuter.width}px
          ${this.marginsPixels.textPadding.width}px 
          ${this.marginsPixels.marginInner.width}px`,
      };
    }

    if (page === "right") {
      if (this.marginsState.widePaddingState) {
        if (this.marginsState.widePaddingRightPosition === 1) {
          return {
            "grid-template-columns": `${this.marginsPixels.marginInner.width}px 
              ${this.marginsPixels.widePadding.width}px 
              ${this.marginsPixels.gutter.width}px
              ${this.marginsPixels.textPadding.width}px 
              ${this.marginsPixels.marginOuter.width}px`,
          };
        }
        return {
          "grid-template-columns": `${this.marginsPixels.marginInner.width}px 
              ${this.marginsPixels.textPadding.width}px 
              ${this.marginsPixels.gutter.width}px 
              ${this.marginsPixels.widePadding.width}px 
              ${this.marginsPixels.marginOuter.width}px`,
        };
      }
      return {
        "grid-template-columns": `${this.marginsPixels.marginInner.width}px
          ${this.marginsPixels.textPadding.width}px 
          ${this.marginsPixels.marginOuter.width}px`,
      };
    }

    return {};
  }

  protected getPageCssClassList(page: PagePosition): string[] {
    const result: string[] = [];

    if (this.marginsState.widePaddingState) {
      result.push("wide-paddings");
    }
    if (page === "left") {
      result.push("wrapper");
      if (this.marginsState.widePaddingLeftPosition === 2) {
        result.push("displaced");
      }
    }
    if (page === "right") {
      result.push("wrapper-mirror");
      if (this.marginsState.widePaddingRightPosition === 1) {
        result.push("displaced");
      }
    }

    return result;
  }

  protected getMarginCssClassList(page: PagePosition, margin: MarginKey): string[] {
    const result: string[] = [];

    result.push(`${this.marginsService.getMarginClass(margin)}`);
    result.push(`on-page-${page}`);
    if (this.isMarginsBordersHide) {
      result.push("hide-margins-borders");
    }

    return result;
  }

  protected getMarginStyle(page: PagePosition, margin: MarginKey) {
    const result: { [key: string]: string } = {};
    const safeMarginSizePx = this.marginsService.scaleDown(this.safeMarginSizeMm, margin, this.sizeCoefficients);
    if (margin === "marginTop") {
      result["padding-top"] = `${safeMarginSizePx}px`;
    }
    if (margin === "marginBottom") {
      result["padding-bottom"] = `${safeMarginSizePx}px`;
    }
    if (margin === "marginOuter") {
      result[`padding-${page}`] = `${safeMarginSizePx}px`;
    }
    return result;
  }

  protected getMarginAZStyle(page: PagePosition, margin: MarginKey) {
    const result: { [key: string]: string } = {};
    if (margin !== "marginBottom") {
      return result;
    }
    let marginBottomTopMarginSizeMm = this.marginBottomTopMarginSizeMm;
    if (this.marginsState.margins.marginBottom - this.safeMarginSizeMm <= 10) {
      marginBottomTopMarginSizeMm -= 2;
    }

    const marginBottomTopMarginSizePx = this.marginsService.scaleDown(
      marginBottomTopMarginSizeMm,
      margin,
      this.sizeCoefficients,
    );
    result["padding-top"] = `${marginBottomTopMarginSizePx}px`;

    return result;
  }

  protected getMarginsDeadStyle(page: PagePosition, position: number) {
    const result: { [key: string]: string } = {};
    let safeHorizontalMarginSizePx = 0;
    let safeVerticalMarginSizePx = 0;
    switch (position) {
      case 1:
        safeHorizontalMarginSizePx = this.marginsService.scaleDown(
          this.safeMarginSizeMm,
          "marginTop",
          this.sizeCoefficients,
        );
        safeVerticalMarginSizePx = this.marginsService.scaleDown(
          this.safeMarginSizeMm,
          "marginInner",
          this.sizeCoefficients,
        );
        result["padding-top"] = `${safeHorizontalMarginSizePx}px`;
        result[`padding-${page}`] = `${safeVerticalMarginSizePx}px`;
        break;
      case 2:
        safeHorizontalMarginSizePx = this.marginsService.scaleDown(
          this.safeMarginSizeMm,
          "marginTop",
          this.sizeCoefficients,
        );
        result["padding-top"] = `${safeHorizontalMarginSizePx}px`;
        break;
      case 3:
        safeHorizontalMarginSizePx = this.marginsService.scaleDown(
          this.safeMarginSizeMm,
          "marginTop",
          this.sizeCoefficients,
        );
        result["padding-bottom"] = `${safeHorizontalMarginSizePx}px`;
        break;
      case 4:
        safeHorizontalMarginSizePx = this.marginsService.scaleDown(
          this.safeMarginSizeMm,
          "marginTop",
          this.sizeCoefficients,
        );
        safeVerticalMarginSizePx = this.marginsService.scaleDown(
          this.safeMarginSizeMm,
          "marginInner",
          this.sizeCoefficients,
        );
        result["padding-bottom"] = `${safeHorizontalMarginSizePx}px`;
        result[`padding-${page}`] = `${safeVerticalMarginSizePx}px`;
        break;
      default:
        break;
    }
    // if (margin == 'marginTop') {
    //   result['padding-top'] = `${safeMarginSizePx}px`;
    // }
    // if (margin == 'marginBottom') {
    //   result['padding-bottom'] = `${safeMarginSizePx}px`;
    // }
    // if (margin == 'marginOuter') {
    //   result[`padding-${page}`] = `${safeMarginSizePx}px`;
    // }
    return result;
  }

  protected getMarginsDeadCssClassList(page: PagePosition, number: number): string[] {
    const result: string[] = [];

    result.push(`margin-dead-${number}`);
    result.push(`on-page-${page}`);
    if (this.isMarginsBordersHide) {
      result.push("hide-margins-borders");
    }

    return result;
  }

  protected getMarginsDeadAZCssClassList(page: PagePosition): string[] {
    const result: string[] = [];

    result.push("active-zone");
    result.push(`on-page-${page}`);

    return result;
  }

  protected getStyleTextPadding() {
    if (this.marginsState.columnsCount === 1 || !this.marginsState.margins.gutter) {
      return {};
    }
    const pixelsValue = this.getGutterWidthInPixeles(this.marginsState.margins.gutter);
    return { gap: `${pixelsValue}px` };
  }

  protected getGutterWidthInPixeles(millimetersValue: number) {
    const margin = "gutter";
    return this.marginsService.scaleDown(millimetersValue, margin, this.sizeCoefficients);
  }

  protected isWidePaddingEnabled() {
    return this.marginsState.widePaddingState;
  }

  protected isColumnsEnable() {
    return this.marginsState.columnsCount > 1;
  }

  protected toggleMarginsBorders() {
    this.isMarginsBordersHide = !this.isMarginsBordersHide;
  }
}
