import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  Color,
  CoverObject,
  EllipseObject,
  Fill,
  RectangleObject,
  ShapeObject,
  SolidFill,
} from "@metranpage/book-data";
import { Observable, filter, map, startWith, tap } from "rxjs";

@Component({
  selector: "m-cover-shape-object-settings",
  templateUrl: "./cover-shape-object-settings.component.html",
  styleUrls: ["./cover-shape-object-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverShapeObjectSettingsComponent implements OnChanges, OnInit {
  @Input() currentObject!: ShapeObject;
  @Input() isGradientApplicable = true;

  @Output() update = new EventEmitter<CoverObject>();
  @Output() eyeDropper = new EventEmitter<(color: Color) => void>();

  values$!: Observable<Partial<ShapeObject>>;

  readonly form = new FormGroup({
    strokeWidth: new FormControl<number | undefined>(undefined, [Validators.pattern("^[0-9.]*$")]),
    cornerRadius: new FormControl<number | undefined>(undefined, [Validators.pattern("^[0-9.]*$")]),
  });

  ngOnInit(): void {
    this.values$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      filter(() => this.form.valid),
      map((v) => <Partial<ShapeObject>>v),
      tap((v) => {
        if (!this.form.dirty || !this.form.valid) {
          return;
        }
        Object.assign(this.currentObject, v);
        this.update.emit(this.currentObject);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.reset();
    const patchValue = {
      strokeWidth: this.currentObject.strokeWidth,
      cornerRadius: this.rectangleObject?.cornerRadius ?? 0,
    };
    this.form.patchValue(patchValue);
  }

  updateFill(fill: Fill) {
    this.currentObject.fill = fill;
    this.update.emit(this.currentObject);
  }

  get fillType(): string {
    if (this.currentObject.fill instanceof SolidFill) {
      return $localize`:@@cover-editor.object.settings.color:`;
    }
    return $localize`:@@cover-editor.object.settings.gradient:`;
  }

  updateStrokeFill(fill: Fill) {
    this.currentObject.strokeFill = fill;
    this.update.emit(this.currentObject);
  }

  get strokeFillType(): string {
    if (this.currentObject.fill instanceof SolidFill) {
      return $localize`:@@cover-editor.object.settings.color:`;
    }
    return $localize`:@@cover-editor.object.settings.gradient:`;
  }

  get ellipseObject(): EllipseObject | undefined {
    return this.currentObject instanceof EllipseObject ? this.currentObject : undefined;
  }

  get rectangleObject(): RectangleObject | undefined {
    return this.currentObject instanceof RectangleObject ? this.currentObject : undefined;
  }
}
