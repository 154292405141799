import { ChangeDetectorRef, Component, HostBinding, HostListener, inject } from "@angular/core";

@Component({
  template: "",
  standalone: true,
})
export abstract class BaseControl {
  @HostBinding("class.disabled")
  protected isDisabled = false;
  @HostBinding("class.focused")
  protected isFocused = false;
  @HostBinding("class.pressed")
  protected isPressed = false;
  @HostBinding("class.hovered")
  protected isHovered = false;

  protected readonly cdr = inject(ChangeDetectorRef);

  @HostBinding("attr.tabindex")
  protected tabindex = 0;

  setDisabled(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.tabindex = -1;
    } else {
      this.tabindex = 0;
    }
    this.cdr.markForCheck();
  }

  @HostListener("focusin")
  onFocus() {
    this.isFocused = true;
    this.cdr.markForCheck();
  }
  @HostListener("focusout")
  onFocusOut() {
    this.isFocused = false;
    this.cdr.markForCheck();
  }

  @HostListener("mouseover")
  onHover() {
    this.isHovered = true;
    this.cdr.markForCheck();
  }
  @HostListener("mouseout")
  onUnhover() {
    this.isHovered = false;
    this.cdr.markForCheck();
  }
}
