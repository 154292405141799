import { Component, HostBinding, Input } from "@angular/core";
import { Book } from "@metranpage/book-data";
import { BookRouterService, EditSteps, ModalSteps } from "../../services/book-router.service";

@Component({
  selector: "m-book-card-edit",
  template: `
    <m-button
      button-style="tertiary"
      icon="/assets/icons/book-edit.svg"
      icon-position="left"
      text-align="left"
      size="full-width"
      (onClick)="onEditClick()"
    >
      {{ text }}
    </m-button>
  `,
  styles: [""],
})
export class BookCardEditView {
  @Input()
  book!: Book;
  @Input()
  text!: string;
  @Input()
  isEpub = false;
  @Input()
  step?: EditSteps | ModalSteps;

  @HostBinding("class.disabled")
  @Input()
  isDisabled = false;

  constructor(
    private readonly bookRouterService: BookRouterService,
  ) {}

  async onEditClick() {
    if (!this.step) {
      this.bookRouterService.navigateToLastBookEditStep(this.book);
      return;
    }
    this.bookRouterService.navigateToBookEditStep(this.book, this.step);
  }
}
