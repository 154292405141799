import { Component, EventEmitter, Input, Output } from "@angular/core";
import { fadeInOutOnEnterLeave } from "@metranpage/components";

export type RestorePassData = {
  email: string;
};

@Component({
  selector: "m-account-verification-view",
  templateUrl: "./account-verification.view.html",
  styleUrls: ["./account-verification.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class AccountVerificationView {
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;

  @Input()
  isLoading = false;

  @Input()
  set email(value: string) {
    if (value) {
      this.verificationText = $localize`:@@user.verify-email-send-body:${value}:email:`;
    }
  }
  @Input()
  timeoutTimeLeft = 0;
  verificationText = "";

  @Output()
  onSendVerificationEmailClick = new EventEmitter<void>();
  @Output()
  close = new EventEmitter<void>();

  onCloseClick() {
    this.close.emit();
  }
}
