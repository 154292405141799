<m-ng-island>
  <form [formGroup]="form">
    <div class="controls">
      <div class="control">
        <span class="label" i18n="@@user.profile.contacts.email">user.profile.contacts.email</span>
        <m-ng-input formControlName="email" type="email" />
      </div>

      <div class="action-block">
        <m-button
          button-style="secondary"
          (onClick)="onSave()"
          [is-disabled]="!hasChange"
          i18n="@@user.profile.contacts.save-changes"
        >
          user.profile.contacts.save-changes
        </m-button>

        <span
          *ngIf="!isSaving && savedStatus"
          class="status warning"
          i18n="@@user.profile.contacts.verification-email-was-send"
        >
          user.profile.contacts.verification-email-was-send
        </span>
        <!-- <span *ngIf="!savedStatus" class="status" i18n="@@user.profile.password.wrong-old-password">
        user.profile.password.wrong-old-password
      </span> -->
        <span *ngIf="!isSaving && !savedStatus && !user?.isVerified" class="status warning">
          <span i18n="@@user.profile.contacts.email-not-verified">user.profile.contacts.email-not-verified</span>
          <a (click)="onVerifyEmail()" i18n="@@user.profile.contacts.verify-email">
            user.profile.contacts.verify-email
          </a>
        </span>
      </div>
    </div>
  </form>
</m-ng-island>
