import { ElementRef, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RangeService {
  hasTagInRange(tag: string, range: Range, element: Element): boolean {
    let hasTag = false;
    const tags: HTMLCollection = element.getElementsByTagName(tag);

    for (let i = 0; i < tags.length; i++) {
      if (range.intersectsNode(tags[i])) {
        hasTag = true;
        break;
      }
    }
    return hasTag;
  }

  addBrToInput(range: Range, element: Element) {
    const br = document.createElement("br");
    range.insertNode(br);
    range.setStartAfter(br);
    range.setEndAfter(br);
    range.collapse(false);

    element.normalize();
  }

  setCaretToBegin(element: Element) {
    const sel = window.getSelection()!;
    sel.removeAllRanges();

    const newRange = document.createRange();
    newRange.setStart(element, 0);
    newRange.setEnd(element, 0);
    sel.addRange(newRange);
  }

  setCaretToEnd(element: Element) {
    const sel = window.getSelection()!;
    sel.removeAllRanges();

    const newRange = document.createRange();
    const position = element.childNodes.length;
    newRange.setStart(element, position);
    newRange.collapse(true);

    sel.addRange(newRange);
  }

  isCursorAtStart(element: ElementRef): boolean {
    const ne = element.nativeElement;

    const selection = window.getSelection();

    const range = selection?.getRangeAt(0);
    if (!range) {
      return false;
    }

    const rangeStartContainer = range.startContainer;
    let textFirstContainer = this.findFirstTextNode(ne);
    if (!textFirstContainer) {
      // if we cant find text element, assume this container is start element
      textFirstContainer = ne;
    }

    const isRangePointingToRoot = rangeStartContainer === ne;
    const isRangePointingToFirstTextElement = rangeStartContainer === textFirstContainer;

    return !!(
      selection?.isCollapsed &&
      (isRangePointingToRoot || isRangePointingToFirstTextElement) &&
      range.startOffset === 0
    );
  }

  isCursorAtEnd(element: ElementRef): boolean {
    const ne = element.nativeElement;

    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);

    if (!range) {
      return false;
    }

    const rangeEndContainer = range.endContainer;
    let lastTextContainer = this.findLastTextNode(ne);
    if (!lastTextContainer) {
      // if we cant find text element, assume this container is start element
      lastTextContainer = ne;
    }

    const isRangePointingToRoot = rangeEndContainer === ne;
    const isRangePointingToLastTextElement = rangeEndContainer === lastTextContainer;

    if (isRangePointingToRoot) {
      // check nodes lengths
      return !!(selection?.isCollapsed && range.endOffset === ne.childNodes.length);
    }
    if (isRangePointingToLastTextElement) {
      // check text lengths
      return !!(selection?.isCollapsed && range.endOffset === lastTextContainer?.textContent?.length);
    }

    return false;
  }

  protected findFirstTextNode(element: HTMLElement): HTMLElement | undefined {
    if (!element) {
      return undefined;
    }

    if (element.nodeType === Node.TEXT_NODE) {
      return element;
    }
    return this.findFirstTextNode(element.firstChild as HTMLElement);
  }

  protected findLastTextNode(element: HTMLElement): HTMLElement | undefined {
    if (!element) {
      return undefined;
    }

    if (element.nodeType === Node.TEXT_NODE) {
      return element;
    }
    return this.findLastTextNode(element.lastChild as HTMLElement);
  }
}
