import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CoreModule } from "@metranpage/core";
import { OAuthModule } from "angular-oauth2-oidc";
import { LoginErrorComponent } from "./components/login-error";
import { SocialLoginsComponent } from "./components/social-login";
import { SocialLoginButtonComponent } from "./components/social-login-button";
import { LoginGooglePage } from "./pages/login-google/login-google.page";
import { LoginOneTimeTokenPage } from "./pages/login-one-time-token/login-one-time-token.page";
import { LoginVkPage } from "./pages/login-vk/login-vk.page";
import { LoginPage } from "./pages/login/login.page";
import { PasswordRestoreNewPasswordPage } from "./pages/password-restore-new-password/password-restore-new-password.page";
import { PasswordRestorePage } from "./pages/password-restore/password-restore.page";
import { RegistrationPage } from "./pages/registartion/registration.page";
import { TokenStorageServiceImpl } from "./services/token-storage-impl.service";
import { AuthCoverView } from "./views/auth-cover/auth-cover.view";
import { LanguageSelectView } from "./views/language-select/language-select.view";
import { LoginView } from "./views/login/login.view";
import { PasswordRestoreNewPasswordView } from "./views/password-restore-new-password/password-restore-new-password.view";
import { PasswordRestoreView } from "./views/password-restore/password-restore.view";
import { RegistrationView } from "./views/registration/registration.view";

@NgModule({
  declarations: [
    // components
    LoginErrorComponent,
    SocialLoginsComponent,
    SocialLoginButtonComponent,
    // views
    AuthCoverView,
    LoginView,
    RegistrationView,
    PasswordRestoreView,
    PasswordRestoreNewPasswordView,
    LanguageSelectView,
    // pages
    LoginPage,
    LoginGooglePage,
    LoginVkPage,
    LoginOneTimeTokenPage,
    RegistrationPage,
    PasswordRestorePage,
    PasswordRestoreNewPasswordPage,
  ],
  imports: [CoreModule, OAuthModule.forRoot(), RouterModule],
  providers: [
    {
      provide: "TokenStorageService",
      useClass: TokenStorageServiceImpl,
    },
  ],
})
export class AuthModule {}
