import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import {
  Book,
  Dimension,
  Dimensions,
  MarginKey,
  Margins,
  MarginsDimentions,
  MarginsDimentionsAll,
  MarginsLimits,
  MarginsSelectSource,
  MarginsSelectState,
  MarginsState,
  SizeCoefficients,
} from "@metranpage/book-data";
import { GeneralResultStatus } from "@metranpage/core-data";
import { BookService } from "./book.service";
import { BooksStore } from "./books.store";
import { MarginsStore } from "./margins.store";

@Injectable({
  providedIn: "root",
})
export class MarginsService {
  renderer: Renderer2;

  constructor(
    private readonly bookService: BookService,
    private readonly booksStore: BooksStore,
    private readonly marginsStore: MarginsStore,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  onHoverMargin(selectState: MarginsSelectState, marginKey: MarginKey | undefined) {
    selectState.hovered = marginKey;
  }

  onSelectMargin(selectState: MarginsSelectState, marginKey: MarginKey | undefined, source: MarginsSelectSource) {
    selectState.selected = marginKey;
    selectState.selectedSource = source;
  }

  calculateSizeCoefficients(pageSize: Dimensions, viewPageSize: Dimensions) {
    const sizeCoefficients = { cx: 1, cy: 1 };
    sizeCoefficients.cx = pageSize.width / viewPageSize.width;
    sizeCoefficients.cy = pageSize.height / viewPageSize.height;
    return sizeCoefficients;
  }

  checkMillimetersSize(marginsLimits: MarginsLimits, millimetersValue: number, margin?: MarginKey) {
    if (!margin) {
      return false;
    }
    const checkMin = millimetersValue >= marginsLimits[margin].min;
    const checkMax = millimetersValue <= marginsLimits[margin].max;
    return checkMin && checkMax;
  }

  admissiblePixelsSize(
    marginsLimits: MarginsLimits,
    pixelsValue: number,
    sizeCoefficients: SizeCoefficients,
    marginKey?: MarginKey,
  ) {
    if (!marginKey) {
      return pixelsValue;
    }
    let millimetersValue = this.scaleUp(pixelsValue, marginKey, sizeCoefficients);
    millimetersValue = this.admissibleMillimetersSize(marginsLimits, millimetersValue, marginKey);
    return this.scaleDown(millimetersValue, marginKey, sizeCoefficients);
  }

  admissibleMillimetersSize(marginsLimits: MarginsLimits, millimetersValue: number, marginKey?: MarginKey) {
    if (!marginKey) {
      return millimetersValue;
    }
    millimetersValue = Math.max(millimetersValue, marginsLimits[marginKey].min);
    millimetersValue = Math.min(millimetersValue, marginsLimits[marginKey].max);
    return millimetersValue;
  }

  roundToDozens(value: number) {
    return Math.round(value * 10) / 10;
  }

  scaleUp(pixelsValue: number, marginKey: MarginKey, sizeCoefficients: SizeCoefficients) {
    let sizeCoefficient = 1;
    if (this.isMarginHorizontal(marginKey)) {
      sizeCoefficient = sizeCoefficients.cy;
    } else {
      sizeCoefficient = sizeCoefficients.cx;
    }
    return this.roundToDozens(pixelsValue * sizeCoefficient);
  }

  scaleDown(millimetersValue: number, marginKey: MarginKey, sizeCoefficients: SizeCoefficients) {
    let sizeCoefficient = 1;
    if (this.isMarginHorizontal(marginKey)) {
      sizeCoefficient = sizeCoefficients.cy;
    }
    sizeCoefficient = sizeCoefficients.cx;
    return this.roundToDozens(millimetersValue / sizeCoefficient);
  }

  calculateMarginsLimits(pageSize: Dimensions, marginsState: MarginsState) {
    const marginsLimits: MarginsLimits = {
      marginTop: {
        min: 8,
        max: Number.POSITIVE_INFINITY,
      },
      marginBottom: {
        min: 8,
        max: Number.POSITIVE_INFINITY,
      },
      marginOuter: {
        min: 8,
        max: Number.POSITIVE_INFINITY,
      },
      marginInner: {
        min: 10,
        max: Number.POSITIVE_INFINITY,
      },
      widePadding: {
        min: 0,
        max: 0,
      },
      gutter: {
        min: 5,
        max: Number.POSITIVE_INFINITY,
      },
    };

    const horizontalMarginsMaxLimit = this.roundToDozens(pageSize.height / 3);
    marginsLimits.marginTop.max = horizontalMarginsMaxLimit;
    marginsLimits.marginBottom.max = horizontalMarginsMaxLimit;

    const minTextPadding =
      this.getMinimalColumnSize("text-padding") * marginsState.columnsCount +
      marginsState.margins.gutter * (marginsState.columnsCount - 1);

    const minWidePadding = this.getMinimalColumnSize("wide-padding");

    const verticalMarginsMaxLimit1 = this.roundToDozens(pageSize.width / 3);
    const verticalMarginsMaxLimit2 = pageSize.width - minTextPadding;

    if (marginsState.widePaddingState) {
      // const widePaddingMaxLimit1 = this.roundToDozens(
      //   (3 / 4) * pageSize.width -
      //     marginsState.margins.marginOuter -
      //     marginsState.margins.marginInner -
      //     marginsState.margins.gutter
      // );
      const widePaddingMaxLimit2 = this.roundToDozens(
        pageSize.width -
          marginsState.margins.marginOuter -
          marginsState.margins.marginInner -
          minTextPadding -
          marginsState.margins.gutter,
      );
      marginsLimits.widePadding.min = minWidePadding;
      marginsLimits.widePadding.max = widePaddingMaxLimit2; // Math.min(widePaddingMaxLimit1, widePaddingMaxLimit2)

      // verticalMarginsMaxLimit2 = verticalMarginsMaxLimit2 - minWidePadding - marginsState.margins.gutter;
    }
    marginsLimits.marginOuter.max = Math.min(
      verticalMarginsMaxLimit1,
      Math.max(
        marginsLimits.marginOuter.min,
        this.roundToDozens(verticalMarginsMaxLimit2 - marginsState.margins.marginInner),
      ),
    );
    marginsLimits.marginInner.max = Math.min(
      verticalMarginsMaxLimit1,
      Math.max(
        marginsLimits.marginInner.min,
        this.roundToDozens(verticalMarginsMaxLimit2 - marginsState.margins.marginOuter),
      ),
    );

    return marginsLimits;
  }

  calculateMarginsBySpaceLevel(marginsLimits: MarginsLimits, pageSize: Dimensions, spaceLevel: number): Margins {
    const margins: Margins = {
      marginTop: marginsLimits.marginTop.min,
      marginBottom: marginsLimits.marginBottom.min,
      marginOuter: marginsLimits.marginOuter.min,
      marginInner: 20,
      gutter: marginsLimits.gutter.min,
      widePadding: marginsLimits.widePadding.min,
    };

    switch (spaceLevel) {
      case 1:
        margins.marginTop = this.calculateMarginFibonachi(marginsLimits, pageSize, 3, "marginTop");
        margins.marginBottom = this.calculateMarginFibonachi(marginsLimits, pageSize, 2, "marginBottom");
        break;

      case 2:
        margins.marginTop = this.calculateMarginFibonachi(marginsLimits, pageSize, 3, "marginTop");
        margins.marginBottom = this.calculateMarginFibonachi(marginsLimits, pageSize, 3, "marginBottom");
        margins.marginOuter = this.calculateMarginFibonachi(marginsLimits, pageSize, 2, "marginOuter");
        break;

      case 3:
        margins.marginTop = this.calculateMarginFibonachi(marginsLimits, pageSize, 4, "marginTop");
        margins.marginBottom = this.calculateMarginFibonachi(marginsLimits, pageSize, 3, "marginBottom");
        margins.marginOuter = this.calculateMarginFibonachi(marginsLimits, pageSize, 3, "marginOuter");
        break;

      case 4:
        margins.marginTop = this.calculateMarginFibonachi(marginsLimits, pageSize, 4, "marginTop");
        margins.marginBottom = this.calculateMarginFibonachi(marginsLimits, pageSize, 4, "marginBottom");
        margins.marginOuter = this.calculateMarginFibonachi(marginsLimits, pageSize, 3, "marginOuter");
        break;

      case 5:
        margins.marginTop = this.calculateMarginFibonachi(marginsLimits, pageSize, 4, "marginTop");
        margins.marginBottom = this.calculateMarginFibonachi(marginsLimits, pageSize, 5, "marginBottom");
        margins.marginOuter = this.calculateMarginFibonachi(marginsLimits, pageSize, 4, "marginOuter");
        break;
    }
    return margins;
  }

  calculateMarginFibonachi(
    marginsLimits: MarginsLimits,
    pageSize: Dimensions,
    modulesCount: number,
    margin: MarginKey,
  ) {
    const fibonachi = [1, 2, 3, 5, 8, 13, 21];
    const fibonachiSum = fibonachi.reduce((acc, num) => acc + num, 0);
    let module = 0;
    if (this.isMarginHorizontal(margin)) {
      module = pageSize.height / 2 / fibonachiSum;
    } else {
      module = pageSize.width / fibonachiSum;
    }

    const minimalValue = marginsLimits[margin].min;
    let marginSize = 0;
    for (let i = 0; i < modulesCount; i++) {
      const margin = module * fibonachi[i];
      marginSize = marginSize + margin;
    }
    return Math.max(minimalValue, this.roundToDozens(marginSize));
  }

  isMarginHorizontal(margin: MarginKey) {
    const horizontalMargins = ["marginTop", "marginBottom"];
    return horizontalMargins.includes(margin);
  }

  getDimension(margin: MarginKey) {
    let dimension: Dimension = "width";
    if (this.isMarginHorizontal(margin)) {
      dimension = "height";
    }
    return dimension;
  }

  calculateMarginsDimentions(pageSize: Dimensions, margins: Margins, widePaddingState: boolean) {
    const marginsDimentions: MarginsDimentions = {
      marginTop: { width: 0, height: 0 },
      marginBottom: { width: 0, height: 0 },
      marginOuter: { width: 0, height: 0 },
      marginInner: { width: 0, height: 0 },
      gutter: { width: 0, height: 0 },
      widePadding: { width: 0, height: 0 },
    };
    for (const margin of Object.keys(margins)) {
      const marginKey = margin as MarginKey;
      let value = 0;
      const dimension1 = this.getDimension(marginKey);
      const dimension2: Dimension = dimension1 === "width" ? "height" : "width";
      switch (marginKey) {
        case "marginTop":
          value = pageSize.width - margins.marginOuter - margins.marginInner;
          break;
        case "marginBottom":
          value = pageSize.width - margins.marginOuter - margins.marginInner;
          break;
        case "marginOuter":
          value = pageSize.height - margins.marginTop - margins.marginBottom;
          break;
        case "marginInner":
          value = pageSize.height - margins.marginTop - margins.marginBottom;
          break;
        case "widePadding":
          if (!widePaddingState) {
            break;
          }
          value = pageSize.height - margins.marginTop - margins.marginBottom;
          break;
        case "gutter":
          if (!widePaddingState) {
            break;
          }
          value = pageSize.height - margins.marginTop - margins.marginBottom;
          break;
        default:
          break;
      }
      marginsDimentions[marginKey][dimension2] = value;
      marginsDimentions[marginKey][dimension1] = margins[marginKey];
    }
    return marginsDimentions;
  }

  calculateMarginTextDimentions(pageSize: Dimensions, margins: Margins, widePaddingState: boolean) {
    const marginTextDimensions: Dimensions = { width: 0, height: 0 };
    marginTextDimensions.width = pageSize.width - margins.marginOuter - margins.marginInner;
    if (widePaddingState) {
      marginTextDimensions.width = marginTextDimensions.width - margins.gutter - margins.widePadding;
    }
    marginTextDimensions.height = pageSize.height - margins.marginTop - margins.marginBottom;
    return marginTextDimensions;
  }

  calculateMarginsInPixels(
    margins: Margins,
    pageSize: Dimensions,
    sizeCoefficients: SizeCoefficients,
    widePaddingState: boolean,
  ) {
    const dimensions = ["width", "height"];
    const marginsDimentions = this.calculateMarginsDimentions(pageSize, margins, widePaddingState);
    const marginsPixels: MarginsDimentionsAll = {
      marginTop: { width: 0, height: 0 },
      marginBottom: { width: 0, height: 0 },
      marginOuter: { width: 0, height: 0 },
      marginInner: { width: 0, height: 0 },
      gutter: { width: 0, height: 0 },
      widePadding: { width: 0, height: 0 },
      textPadding: { width: 0, height: 0 },
    };
    for (const margin of Object.keys(marginsDimentions)) {
      const marginKey = margin as MarginKey;
      for (const dimension of dimensions) {
        const dimensionKey = dimension as Dimension;
        marginsPixels[marginKey][dimensionKey] = this.scaleDown(
          marginsDimentions[marginKey][dimensionKey],
          marginKey,
          sizeCoefficients,
        );
      }
    }
    const marginsTextDimensions = this.calculateMarginTextDimentions(pageSize, margins, widePaddingState);
    for (const dimension of dimensions) {
      const dimensionKey = dimension as Dimension;
      marginsPixels.textPadding[dimensionKey] = this.scaleDown(
        marginsTextDimensions[dimensionKey],
        "gutter",
        sizeCoefficients,
      );
    }
    return marginsPixels;
  }

  convertMmToPx(value: number) {
    const coefficient = 3.7795751758;
    return value * coefficient;
  }

  convertPxToMm(value: number) {
    const coefficient = 3.7795751758;
    return value / coefficient;
  }

  convertFontSizePtToMm(fontSizePt: number) {
    const fontSizeCoefficient = 0.352778;
    const fontSizeInMm = fontSizePt * fontSizeCoefficient;
    return fontSizeInMm;
  }

  convertFontSizePtToPx(fontSizePt: number) {
    const fontSizeMm = this.convertFontSizePtToMm(fontSizePt);
    return this.convertMmToPx(fontSizeMm);
  }

  calculateFontSizeInPixels(fontSize: number, sizeCoefficients: SizeCoefficients) {
    let fontSizeInPixels = 0;
    const fontSizeInMillimeters = this.convertFontSizePtToMm(fontSize);
    fontSizeInPixels = this.scaleDown(fontSizeInMillimeters, "marginTop", sizeCoefficients);
    return fontSizeInPixels;
  }

  getMarginClass(marginKey: MarginKey) {
    return /(?=[A-Z])/g[Symbol.split](marginKey).join("-").toLowerCase();
  }

  async updateState(book: Book): Promise<GeneralResultStatus> {
    const state = this.marginsStore.getMarginsState();
    return await this.bookService.updateMarginsState(book, state);
  }

  getMinimalColumnSize(
    useIn: "text-padding" | "wide-padding" | "indented-line",
    fontSize: number | undefined = undefined,
    fontFamily: string | undefined = undefined,
  ) {
    let text = "съешь ещё этих мягких французских булок, да выпей чаю";

    text = text.slice(0, 35);
    if (useIn === "wide-padding") {
      text = text.slice(0, 15);
    }
    if (useIn === "indented-line") {
      text = "xyzwa";
    }

    if (!fontSize || !fontFamily) {
      const activeBook = this.booksStore.getActiveBook()!;
      fontSize = activeBook.bookSettings?.mainSize!;
      fontFamily = activeBook.bookSettings?.fontMain!;
    }

    const fontSizePx = this.convertFontSizePtToPx(fontSize);
    const textWidthPx = this.getTextWidth(text, fontSizePx, fontFamily);
    const textWidthMm = this.convertPxToMm(textWidthPx);

    return this.roundToDozens(textWidthMm);
  }

  getTextWidth(text: string, fontSizePx: number, fontFamily = "Roboto, sans-serif") {
    const canvas = this.renderer.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = `${fontSizePx}px ${fontFamily}`;
    const textMetrics = context.measureText(text);
    return textMetrics.width;
  }
}
