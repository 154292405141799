import { CommonModule } from "@angular/common";
import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { IconComponent } from "@metranpage/design-system";
import { CardComponent } from "../card/card.component";

@Component({
  selector: "m-modal-backdrop",
  templateUrl: "./modal-backdrop.component.html",
  styleUrls: ["./modal-backdrop.component.scss"],
  standalone: true,
  imports: [CommonModule, CardComponent, IconComponent],
})
export class ModalBackdropComponent {
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;

  @Output()
  close = new EventEmitter();

  protected onCloseClick() {
    this.close.emit();
  }

  protected onBackDropClick() {
    if (!this.closeOnBackDropClick) {
      return;
    }
    this.onCloseClick();
  }

  @HostListener("document:keydown.escape", ["$event"])
  onEscapeClick(event: KeyboardEvent) {
    this.onCloseClick();
  }
}
