import { CommonModule } from "@angular/common";
import { Component, Host, Input } from "@angular/core";
import { CheckboxComponent } from "../checkbox/checkbox.component";
// import { SwitchComponent } from "../switch/switch.component";
import { CheckboxGroupComponent } from "./checkbox-group.component";

@Component({
  selector: "m-checkbox-for-group",
  template: `
    <m-checkbox  [isChecked]="isChecked()" (click)="toggleCheck()"><ng-content></ng-content></m-checkbox>
    `,
  standalone: true,
  imports: [CommonModule, CheckboxComponent],
})
export class CheckboxForGroupComponent {
  @Input() value: any;

  constructor(@Host() private checkboxGroup: CheckboxGroupComponent) {}

  toggleCheck() {
    this.checkboxGroup.addOrRemove(this.value);
  }

  isChecked() {
    return this.checkboxGroup.contains(this.value);
  }
}
