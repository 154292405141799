<div class="wrapper">
  <m-cover-view
    *ngIf="cover$ | async as cover"
    [book]="book"
    [cover]="cover"
    [isCoverJustCreated]="isCoverJustCreated"
    (back)="onBackClick()"
    (processing)="onProcessing($event)"
  ></m-cover-view>
</div>

<m-modal-project-router-host />
