<div class="container" *ngIf="urls">
  <div *ngFor="let pair of urlsPairs" [ngClass]="getCssClassList(pair)" (click)="onClick(pair.leftPageNumber)">
    <div class="pages">
      <m-skeleton-image
        *ngIf="pair.leftPage"
        class="page-left"
        [url]="pair.leftPage"
        width="74"
        height="100"
        [id]="'page-' + pair.leftPageNumber"
      />
      <m-skeleton-image
        *ngIf="pair.rightPage"
        class="page-right"
        [url]="pair.rightPage"
        width="74"
        height="100"
        [id]="'page-' + pair.rightPageNumber"
      />
    </div>
    <div class="page-numbers">
      {{ getPageNumber(pair) }}
    </div>
  </div>
</div>
