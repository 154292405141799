import { Component, Input, OnChanges, SimpleChanges, inject } from "@angular/core";
import { Notification, NotificationPayload } from "../../../models/notification";
import { NotificationsPopUpService } from "../../../services/notifications-pop-up.service";
import { IconColorStyle } from "../account-notification-card-host/account-notification-card-host.view";

@Component({
  selector: "m-account-notification-base-card-view",
  templateUrl: "account-notification-base-card.view.html",
})
export class AccountNotificationBaseCardView<P extends NotificationPayload> implements OnChanges {
  @Input()
  notification!: Notification;
  payload!: P;
  @Input()
  iconSrc = "notification-info.svg";
  @Input()
  iconColorStyle: IconColorStyle = "normal";
  @Input()
  isLast = true;

  private readonly notificationService = inject(NotificationsPopUpService);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.notification) {
      this.payload = changes.notification.currentValue.payload as P;
    }
  }

  copyText(text: string) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.notificationService.notify({
          content: $localize`:@@common.promocode-copied-to-clipboard-success:`,
          type: "success",
          showOptsTimeout: 1,
        });
      })
      .catch((e) => {
        this.notificationService.error($localize`:@@common.promocode-copied-to-clipboard-failed:`);
      });
  }
}
