import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { CompanyImageGenerationPriceSettings } from "../../models/company";

export type State = {
  prices: CompanyImageGenerationPriceSettings | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyImageGenerationPriceSettingsStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      prices: undefined,
    };
  }

  getPricesObservable() {
    return this.getFieldObservable("prices");
  }

  setPrices(prices: CompanyImageGenerationPriceSettings) {
    this.update((state) => ({ ...state, prices }));
  }
}
