import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

export type NotificationType = 'success' | 'error' | 'info';
export type ShowOptions = 'dont-close' | 'close-after-time';

@Component({
  selector: 'm-notification',
  template: `
    <div class="notification-wrapper" [ngClass]="[notificationType]">
      <span class="notification-content"><ng-content></ng-content></span>
      <svg-icon src="/assets/icons/notification-close.svg" class="close-icon" (click)="onCloseClick()"></svg-icon>
    </div>
  `,
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule],
})
export class NotificationComponent {
  @Input('notification-id')
  notificationId = -1;
  @Input('notification-type')
  notificationType: NotificationType = 'info';
  @Input('show-options')
  showOptions: ShowOptions = 'close-after-time';

  @Output()
  close = new EventEmitter<void>();

  onCloseClick() {
    this.close.emit();
  }
}
