<div class="icon-wrapper">
  <m-icon
    *ngIf="hasLeftArrow"
    class="arrow arrow-left"
    src="preview-arrow-left.svg"
    size="xs"
    color="--color-bg-dots-preview"
    (click)="navigate(-1)"
  ></m-icon>
</div>

<div class="scroll-container">
  <div class="scroller" [style]="{ transform: 'translateX(' + dotsScrollX + 'px)' }">
    <div
      *ngFor="let dot of dots; trackBy: trackBy"
      class="dot"
      [class.selected]="dot.selected"
      [style.scale]="dot.scale"
      (click)="onSelect.emit(dot.index)"
    ></div>
  </div>
</div>

<div class="icon-wrapper">
  <m-icon
    *ngIf="hasRightArrow"
    class="arrow arrow-right"
    src="preview-arrow-left.svg"
    size="xs"
    color="--color-bg-dots-preview"
    (click)="navigate(1)"
  ></m-icon>
</div>
