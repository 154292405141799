import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { I18nService } from "@metranpage/i18n";
import { PromocodeDiscountData, PromocodeEnterResult } from "@metranpage/pricing-data";
import { DateTime } from "luxon";

@Component({
  selector: "m-promocode-enter-menu",
  templateUrl: "./promocode-enter-menu.view.html",
  styleUrls: ["./promocode-enter-menu.view.scss"],
})
export class PromocodeEnterMenuView implements OnChanges {
  @Input()
  promocodeResult?: PromocodeEnterResult;
  @Input()
  promocodeDiscountData?: PromocodeDiscountData;
  @Output()
  promocodeEnter = new EventEmitter<string>();

  protected form = new FormGroup({
    promocode: new FormControl("", { nonNullable: true, validators: [] }),
  });

  protected locale = "en";

  constructor(
    i18nService: I18nService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.locale = i18nService.getLocale();
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.cdr.markForCheck();
  }

  protected async onEnterPromocodeClick() {
    const promocode = this.form.get("promocode")?.value.trim();
    if (!promocode) {
      return;
    }
    this.promocodeEnter.emit(promocode);
  }

  protected onCloseClick() {
    this.promocodeResult = undefined;
  }

  protected getDateShort(date: Date) {
    const dayAndMonth = DateTime.fromISO(date.toString()).toLocaleString(
      { day: "numeric", month: "long" },
      {
        locale: this.locale,
      },
    );
    const year = DateTime.fromISO(date.toString()).toFormat("yyyy");
    return `${dayAndMonth} ${year}`;
  }
}
