import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SelectValue } from "@metranpage/components";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { GeneratedImage } from "@metranpage/image-generation";
import { takeUntil } from "rxjs";
import { ModerationImageRequest, ModerationImageRequestPublishedFrom } from "../../models/moderation-image-request";
import { AdminModerationImageRequestService } from "../../services/moderation-image-request/moderation-image-request.service";
import { AdminModerationImageRequestStore } from "../../services/moderation-image-request/moderation-image-request.store";
import { AdminBasePage } from "../admin/admin.page";

export type DescriptionOptions = {
  id: number;
  text: string;
};

@Component({
  selector: "m-admin-moderation-images-page",
  templateUrl: "./moderation-images.page.html",
  styleUrls: ["./moderation-images.page.scss"],
})
export class AdminModerationImagesPage extends AdminBasePage implements OnInit, OnDestroy {
  protected form?: FormGroup;

  moderationImageRequests: ModerationImageRequest[] = [];
  page = 1;
  pageCount = 1;

  filterPublishedFrom: ModerationImageRequestPublishedFrom = "user";
  filterOptions: SelectValue[] = [
    { id: "user", value: $localize`:@@admin.moderation.images.filter-options.user:` },
    { id: "free-tariff", value: $localize`:@@admin.moderation.images.filter-options.free-tariff:` },
  ];

  protected moderatingImages: GeneratedImage[] = [];

  protected needSelectFirstImage = true;

  protected previewModerationImageRequest?: ModerationImageRequest;
  protected previewModerationImageRequestIndex?: number;

  protected selectedDescription?: DescriptionOptions;
  protected descriptionOptions: DescriptionOptions[] = [
    { id: 1, text: $localize`:@@admin.moderation.images.description-options.1:` },
    { id: 2, text: $localize`:@@admin.moderation.images.description-options.2:` },
    { id: 3, text: $localize`:@@admin.moderation.images.description-options.3:` },
    { id: 0, text: $localize`:@@admin.moderation.images.description-options.0:` },
  ];

  @ViewChild("previewImageElement", { read: ElementRef })
  previewImageElement?: ElementRef;

  constructor(
    private readonly adminModerationImageRequestService: AdminModerationImageRequestService,
    private readonly adminModerationImageRequestStore: AdminModerationImageRequestStore,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();

    this.addSub(
      adminModerationImageRequestStore
        .getModerationImageRequestsObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((moderationImageRequests) => {
          this.moderationImageRequests = moderationImageRequests.filter(
            (mir) => mir.status === "created" && mir.publishedFrom === this.filterPublishedFrom,
          );
          this.moderatingImages = this.moderationImageRequests.map((mir) => mir.generatedImage);

          if (this.moderationImageRequests.length === 0) {
            this.resetPreviewModerationImageRequest();
          }

          this.selectFirstImage();
          this.cdr.markForCheck();
        }),
    );

    this.addSub(
      adminModerationImageRequestStore
        .getModerationImageRequestsPageCountObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((count) => {
          this.pageCount = count;
        }),
    );
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
    this.selectDefaultModeratorDescription();

    await this.adminModerationImageRequestService.loadModerationImageRequestsPaginated(
      this.page,
      this.filterPublishedFrom,
    );
  }

  protected async onAcceptClick() {
    if (!this.previewModerationImageRequest) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminModerationImageRequestService.acceptModerationImageRequest(
      this.previewModerationImageRequest.id,
    );
    this.loadingService.stopLoading();
    if (result === "success") {
      this.selectNextOrPreviousModeratedImage();
    } else {
      this.notificationService.error($localize`:@@admin.moderation.images.error.update:`);
    }
  }

  protected async onDeclineClick() {
    if (!this.previewModerationImageRequest) {
      return;
    }

    let moderatorDescription = "";
    if (this.selectedDescription?.id === 0) {
      moderatorDescription = this.form?.get("moderatorDescription")?.value;
    } else {
      moderatorDescription = this.selectedDescription?.text || "";
    }

    if (!moderatorDescription) {
      return;
    }

    if (this.selectedDescription?.id === 3) {
      moderatorDescription = "no-reason";
    }

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminModerationImageRequestService.declineModerationImageRequest(
      this.previewModerationImageRequest.id,
      {
        moderatorDescription,
      },
    );
    this.loadingService.stopLoading();
    if (result === "success") {
      this.selectNextOrPreviousModeratedImage();
    } else {
      this.notificationService.error($localize`:@@admin.moderation.images.error.update:`);
    }
  }

  protected isDeclineAvailable() {
    return (
      (this.selectedDescription?.id !== 0 && this.selectedDescription?.text !== "") ||
      (this.selectedDescription?.id === 0 && this.form?.valid)
    );
  }

  protected onSelectDescription(selectedDescription: DescriptionOptions) {
    this.selectedDescription = selectedDescription;
  }

  protected onFocusModeratorDescription() {
    this.selectCustomModeratorDescription();
  }

  private selectDefaultModeratorDescription() {
    const descriptionOption = this.descriptionOptions[0];
    if (!descriptionOption) {
      return;
    }
    this.selectedDescription = descriptionOption;
  }

  private selectCustomModeratorDescription() {
    const descriptionOption = this.descriptionOptions.find((o) => o.id === 0);
    if (!descriptionOption) {
      return;
    }
    this.selectedDescription = descriptionOption;
  }

  private selectFirstImage() {
    if (this.moderationImageRequests.length === 0 || !this.needSelectFirstImage) {
      return;
    }
    this.previewModerationImageRequestIndex = 0;
    this.previewModerationImageRequest = this.moderationImageRequests[this.previewModerationImageRequestIndex];
    this.needSelectFirstImage = false;
  }

  private selectNextOrPreviousModeratedImage() {
    if (this.moderationImageRequests.length === 0 || this.previewModerationImageRequestIndex === undefined) {
      return;
    }

    if (
      this.previewModerationImageRequestIndex &&
      this.moderationImageRequests.length === this.previewModerationImageRequestIndex
    ) {
      this.previewModerationImageRequestIndex = this.moderationImageRequests.length - 1;
    }
    this.previewModerationImageRequest = this.moderationImageRequests[this.previewModerationImageRequestIndex];
  }

  private resetPreviewModerationImageRequest() {
    this.previewModerationImageRequestIndex = undefined;
    this.previewModerationImageRequest = undefined;
    this.needSelectFirstImage = true;
  }

  protected async onSelectModerationImageRequest(generatedImage: GeneratedImage) {
    const moderationImageRequest = this.moderationImageRequests.find(
      (mir) => mir.generatedImage.id === generatedImage.id,
    );
    if (!moderationImageRequest) {
      return;
    }

    this.previewModerationImageRequest = moderationImageRequest;
    this.previewModerationImageRequestIndex = this.moderationImageRequests.findIndex(
      (mir) => mir.id === moderationImageRequest.id,
    );
    this.scrollToPreview();
  }

  protected async onScrollEvent() {
    this.page++;
    await this.adminModerationImageRequestService.loadModerationImageRequestsPaginated(
      this.page,
      this.filterPublishedFrom,
    );
  }

  protected onPrevious() {
    if (this.previewModerationImageRequestIndex === undefined || !this.hasPreviousImage()) {
      return;
    }
    this.previewModerationImageRequestIndex--;
    this.previewModerationImageRequest = this.moderationImageRequests[this.previewModerationImageRequestIndex];
  }

  protected onNext() {
    if (this.previewModerationImageRequestIndex === undefined || !this.hasNextImage()) {
      return;
    }
    this.previewModerationImageRequestIndex++;
    this.previewModerationImageRequest = this.moderationImageRequests[this.previewModerationImageRequestIndex];
  }

  protected hasPreviousImage() {
    if (this.previewModerationImageRequestIndex === undefined) {
      return false;
    }
    return this.previewModerationImageRequestIndex - 1 >= 0;
  }

  protected hasNextImage() {
    if (this.previewModerationImageRequestIndex === undefined) {
      return false;
    }
    return this.previewModerationImageRequestIndex + 1 < this.moderationImageRequests.length;
  }

  private scrollToPreview() {
    if (!this.previewImageElement) {
      return;
    }

    this.previewImageElement.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }

  createForm() {
    this.form = this.formBuilder.group({
      moderatorDescription: this.formBuilder.control("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  protected async onFilterChanged(option: number | string) {
    this.filterPublishedFrom = option as ModerationImageRequestPublishedFrom;
    this.clearStore();
    this.loadingService.startLoading({ fullPage: true });
    await this.adminModerationImageRequestService.loadModerationImageRequestsPaginated(
      this.page,
      this.filterPublishedFrom,
    );
    this.loadingService.stopLoading();
  }

  private clearStore() {
    this.page = 1;
    this.pageCount = 1;
    this.moderationImageRequests = [];
    this.adminModerationImageRequestStore.clearModerationImageRequests();
  }

  @HostListener("window:keydown", ["$event"])
  protected handleKeyDown(event: KeyboardEvent) {
    if (event.key === "ArrowLeft" && this.hasPreviousImage()) {
      this.onPrevious();
    }
    if (event.key === "ArrowRight" && this.hasNextImage()) {
      this.onNext();
    }
  }
}
