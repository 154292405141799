<ng-content [select]="input-left">
</ng-content>

<input
  [placeholder]="placeholder"
  [name]="name"
  [autocomplete]="getAutocomplete()"
  [required]="isRequired"
  [tabindex]="tabindex"
  [type]="getInputType()"
  [disabled]="isDisabled"
  (input)="onInputChange($event)"
  #input
/>

<ng-content [select]="input-right">
</ng-content>
