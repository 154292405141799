import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { CoverConceptualGeneration } from "../models/cover-conceptual-generation";

export type CoverConceptualGenerationState = {
  coverId: number;
  coverConceptualGeneration: CoverConceptualGeneration | undefined;
};

@Injectable({
  providedIn: "root",
})
export class CoverConceptualGenerationStore extends Store<CoverConceptualGenerationState> {
  protected override getInitialState(): CoverConceptualGenerationState {
    return {
      coverId: 0,
      coverConceptualGeneration: undefined,
    };
  }

  getCoverConceptualGenerationObservable() {
    return this.getFieldObservable("coverConceptualGeneration");
  }

  getCoverConceptualGeneration() {
    return this.getField("coverConceptualGeneration");
  }

  setCoverConceptualGeneration(coverId: number, coverConceptualGeneration: CoverConceptualGeneration | undefined) {
    this.update((state) => ({ ...state, coverId, coverConceptualGeneration }));
  }

  updateCoverConceptualGeneration(updatedCoverConceptualGeneration: CoverConceptualGeneration) {
    if (updatedCoverConceptualGeneration.coverId !== this.getState().coverId) {
      return;
    }

    this.update((state) => ({ ...state, coverConceptualGeneration: updatedCoverConceptualGeneration }));
  }
}
