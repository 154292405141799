import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { CompanyApiSettings, TestWebhookEventResult } from "../../models/api-settings";

@Injectable({
  providedIn: "root",
})
export class AdminCompanyApiSettingsApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getApiSettings() {
    return this.get<CompanyApiSettings>("admin/api");
  }

  async resetApiToken() {
    return this.post<CompanyApiSettings>("admin/api/token-reset", {});
  }

  async updateApiSettings(data: CompanyApiSettings) {
    return this.put<CompanyApiSettings>("admin/api", data);
  }

  async testWebhook(url: string) {
    return this.post<TestWebhookEventResult>("admin/api/test-webhook", { url });
  }
}
