<div class="select-wrapper">
  <div class="input-container" [ngClass]="getCssClassList()" (click)="onFieldClick()">
    <div class="value">
      <div
        *ngFor="let colorName of getColorNames()"
        class="value-color"
        [ngStyle]="getColorStyle(palette, colorName)"
      ></div>
    </div>

    <div class="icon-wrapper">
      <m-icon
        class="icon-arrow"
        [ngClass]="getArrowIconClassList()"
        src="dropdown-arrow-down.svg"
        color="--color-font-input"
        size="xs"
      />
    </div>
  </div>

  <div class="select-options-dropdown" *ngIf="isMenuOpened">
    <div *ngFor="let option of options" class="select-option" (click)="onOptionClick(option)">
      <div
        *ngFor="let colorName of getColorNames()"
        class="select-option-color"
        [ngStyle]="getColorStyle(option, colorName)"
      ></div>
      <div class="icon-wrapper">
        <m-icon
          class="icon-done"
          [ngClass]="getArrowIconClassList()"
          src="done.svg"
          color="--color-font-input"
          size="s"
        />
      </div>
    </div>
  </div>
</div>
