import { Component, Input } from "@angular/core";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "m-admin-menu-item-component",
  template: `
    <svg-icon class="icon" [src]="icon" alt=""></svg-icon>
    <span>{{ title }}</span>
  `,
  styleUrls: ["./menu-item.component.scss"],
  standalone: true,
  imports: [AngularSvgIconModule],
})
export class AdminMenuItemComponent {
  @Input()
  icon!: string;
  @Input()
  title!: string;
}
