<p
  *ngFor="let page of pages"
  class="page"
  (click)="onPageClick(page.page)"
  [class.active]="currentPage === page.page"
  [class.disabled]="page.page === -1"
>
  {{ page.text }}
</p>


