import { Component, HostBinding, Input, OnChanges, Renderer2, SimpleChanges } from "@angular/core";
import { GeneratedImage, GeneratedImageService } from "@metranpage/image-generation";

@Component({
  selector: "m-community-image-view",
  templateUrl: "image.view.html",
  styleUrls: ["image.view.scss"],
})
export class CommunityImageView implements OnChanges {
  @Input()
  image!: GeneratedImage;

  protected url = "";

  constructor(
    private readonly generatedImageService: GeneratedImageService,
  ) {}

  @HostBinding("style.aspect-ratio")
  get aspectRatio() {
    return this.image?.width / this.image?.height;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.image) {
      this.url = this.getUrlForImage(this.image);
    }
  }

  protected getUrlForImage(generatedImage: GeneratedImage) {
    return this.generatedImageService.getUrlForImage(generatedImage);
  }
}
