import { Component, EventEmitter, Input, Output } from "@angular/core";
import { slideInOutVertical } from "@metranpage/components";

@Component({
  selector: "m-cover-sidebar-section",
  templateUrl: "./cover-sidebar-section.view.html",
  styleUrls: ["./cover-sidebar-section.view.scss"],
  // animations: [slideInOutVertical],
})
export class CoverSidebarSectionView {
  @Input()
  title!: string;
  @Input()
  actionIcon?: string;
  @Input()
  actionTitle?: string;
  @Input()
  canCollapsing = false;
  @Input()
  isCollapsed = true;
  @Input()
  hasTooltip = false;

  @Output()
  onActionClick = new EventEmitter();

  toggle() {
    if (!this.canCollapsing) {
      return;
    }
    this.isCollapsed = !this.isCollapsed;
  }
}
