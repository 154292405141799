import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NotificationsPopUpService } from "@metranpage/core";

@Component({
  selector: "m-login-sidebar-menu",
  templateUrl: "./login-sidebar-menu.view.html",
  styleUrls: ["./login-sidebar-menu.view.scss"],
})
export class LoginSidebarMenuView implements OnInit {
  @Output()
  login = new EventEmitter();

  constructor(private readonly notificationService: NotificationsPopUpService) {}

  ngOnInit(): void {}

  onLoginClick() {
    this.login.emit();
  }
}
