import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@metranpage/auth";

@Component({
  selector: "m-account-tariff-and-payments-nav-view",
  template: `
    <m-account-tariff-and-payments-menu-item-component
      class="tab"
      [ngClass]="{ active: tab1Active.isActive, 'tab-1-br': tab2Active.isActive }"
      routerLink="/account/tariff-and-payments"
      routerLinkActive #tab1Active="routerLinkActive"
      [routerLinkActiveOptions]="{ exact: true }"
      title="user.profile.tariff-and-payments.nav-tabs.tariff"
      i18n-title="@@user.profile.tariff-and-payments.nav-tabs.tariff"
    />

    <m-account-tariff-and-payments-menu-item-component
      class="tab"
      [ngClass]="{ active: tab2Active.isActive, 'tab-2-bl': tab1Active.isActive, 'tab-2-br': tab3Active.isActive }"
      routerLink="/account/tariff-and-payments/payments-info"
      routerLinkActive #tab2Active="routerLinkActive"
      title="user.profile.tariff-and-payments.nav-tabs.payments-info"
      i18n-title="@@user.profile.tariff-and-payments.nav-tabs.payments-info"
    />

    <m-account-tariff-and-payments-menu-item-component
      class="tab"
      [ngClass]="{ active: tab3Active.isActive, 'tab-3-bl': tab2Active.isActive }"
      routerLink="/account/tariff-and-payments/payments-history"
      routerLinkActive #tab3Active="routerLinkActive"
      title="user.profile.tariff-and-payments.nav-tabs.payments-history"
      i18n-title="@@user.profile.tariff-and-payments.nav-tabs.payments-history"
    />
  `,
  styles: [
    `
      :host {
        --tab-border-radius: var(--border-radius);

        display: flex;
        width: 100%;
        height: var(--nav-tabs-pricing-height);
        min-height: var(--nav-tabs-pricing-height);
        align-items: center;
        justify-content: flex-start;
        background: var(--surface-island);
        gap: 2px;
        user-select: none;        
      }

      .tab-1-br {
        border-radius: 0 0 var(--tab-border-radius) 0;
      }

      .tab-2-br {
        border-radius: 0 0 var(--tab-border-radius) 0;
      }

      .tab-2-bl {
        border-radius: 0 0 0 var(--tab-border-radius);
      }

      .tab-3-bl {
        border-radius: 0 0 0 var(--tab-border-radius);
      }
    `,
  ],
})
export class AccountTariffAndPaymentsNavView {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}
}
