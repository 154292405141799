import { CompanyConfig, flagsForPublishers } from "@metranpage/core-interfaces";

const config: CompanyConfig = {
  deployedUrl: "https://app.rugram.com/",
  ga: "G-79QKHNF94B",
  companyUuid: "d0f132dc-5f16-42fe-8888-061facc10680",
  availableLanguages: [
    { visibleName: "Русский", iconSrc: "/assets/img/flag-ru.svg", value: "ru" },
    { visibleName: "English", iconSrc: "/assets/img/flag-gb.svg", value: "en" },
  ],
  downloadFileTemplate: "{filename}",
  color: "#113EDE",
  socials: [],
  flags: {
    ...flagsForPublishers,
    isImagesGenerationAvailable: false,
    isCommunityAvailable: false,
    isEditionNoticeAvailable: false,
    hideNavbarLogo: true,
    hideNavbarRightParts: true,
    forceWhiteTheme: true,
  },
};
export { config };
