import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { LoadingService } from "@metranpage/core";
import { AVAILABLE_LANGUAGES, LangSpec } from "@metranpage/i18n";
import * as _ from "lodash-es";
import { Company, CompanyUpdateDataDto } from "../../models/company";
import { CompanyLocUpdateData } from "../../models/company-loc";
import { AdminCompanyService } from "../../services/company/company.service";
import { AdminUnisenderSettingsService } from "../../services/company/unisender-settings.service";

@Component({
  selector: "m-admin-edit-company",
  templateUrl: "./edit-company.view.html",
  styleUrls: ["./edit-company.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCompanyView implements OnChanges {
  @Input() company!: Company;
  @Output() save = new EventEmitter<Company>();
  @Output() error = new EventEmitter<unknown>();
  @Output() cancel = new EventEmitter<void>();

  readonly form: FormGroup;

  constructor(
    @Inject(AVAILABLE_LANGUAGES) readonly availableLanguages: LangSpec[],
    private readonly adminCompanyService: AdminCompanyService,
    private readonly loadingService: LoadingService,
    private readonly adminUnisenderSettingsService: AdminUnisenderSettingsService,
  ) {
    this.form = adminUnisenderSettingsService.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.company) {
      this.onCompany();
    }
  }

  reset() {
    this.form.reset();
  }

  onCompany() {
    this.reset();

    const formValues: any = _.omit(this.company, ["localization"]);
    for (const l of this.company.localization || []) {
      if (!formValues.localization) {
        formValues.localization = {};
      }
      formValues.localization[l.lang] = { title: l.title };
    }

    this.form.patchValue(formValues);
  }

  onCancel() {
    this.cancel.emit();
  }

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const formData = this.form.getRawValue();
    const localizationData: CompanyLocUpdateData[] = [];
    for (const lang of Object.keys(formData.localization)) {
      const valueLoc = formData.localization[lang];
      localizationData.push({
        title: valueLoc.title,
        lang,
      });
    }
    formData.localization = localizationData;

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminCompanyService.updateCompany(formData as CompanyUpdateDataDto);
    this.loadingService.stopLoading();

    if (result.status === "success") {
      this.save.emit(result.company);
    } else {
      this.error.emit(result.error);
    }
  }

  getUnisenderListKey() {
    return this.adminUnisenderSettingsService.getUnisenderListsKey();
  }

  // getUnisenderTemplateLanguages() {
  //   return this.company.unisenderTemplateLanguages;
  // }

  protected getLanguages() {
    return this.availableLanguages.map((v) => v.value);
  }
}
