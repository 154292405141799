<ng-container *ngFor="let item of items; let i = index; trackBy: trackByFn">
  <m-markup-editor-list-item-view
    [item]="item"
    [styleDisplayOpts]="styleDisplayOpts"
    [startingNoteIndex]="startingNotesIndexes[i]"
    (onSplit)="onSplit($event)"
    (onMergeWithPrev)="onMergeWithPrev($event)"
    (blockMovement)="onBlockMovement($event)"
    (onFocus)="onListItemFocus(i)"
    (onBlur)="onListItemBlur($event)"
    (contextmenu)="onContextMenu($event, i)"
    (footnoteAdded)="onFootnoteAdded($event)"
    (footnoteChanged)="onFootnoteChanged($event)"
  ></m-markup-editor-list-item-view>

  <m-markup-editor-list-context-menu
    *ngIf="listContextMenuVisible[i]"
    [ngStyle]="getContextMenuStyle(i)"
    (onContextMenuItemClick)="onContextMenuItemClick($event, i)"
  ></m-markup-editor-list-context-menu>
</ng-container>
