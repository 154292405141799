import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { Theme } from "@metranpage/theme";

export type Creds = {
  email: string;
  password: string;
};

@Component({
  selector: "m-auth-cover-view",
  templateUrl: "./auth-cover.view.html",
  styleUrls: ["./auth-cover.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class AuthCoverView {
  @Input()
  colorTheme: Theme = "light";

  protected colorThemeIcon = "color-scheme-day.svg";
  protected coverSrc = "assets/img/auth/auth-cover-dt.png";

  @Output()
  onColorThemeToggle = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges): void {
    this.updateColorThemeIcon();
    this.updateCoverSrc();
  }

  private updateColorThemeIcon() {
    this.colorThemeIcon = `color-scheme-${this.colorTheme}.svg`;
  }

  private updateCoverSrc() {
    this.coverSrc = "assets/img/auth/auth-cover-lt.png";
    if (this.colorTheme === "dark") {
      this.coverSrc = "assets/img/auth/auth-cover-dt.png";
    }
  }

  protected onColorThemeCLick() {
    this.onColorThemeToggle.emit();
  }
}
