<div class="container">
  <div class="title">
    <span i18n="@@books.images-position-settings-modal.title">books.images-position-settings-modal.title</span>
    <m-icon
      class="close-icon"
      src="close-cross.svg"
      color="--color-font-main"
      size="xs"
      (click)="close.emit()"
    ></m-icon>
  </div>

  <div class="content">
    <div class="position-group">
      <div class="images-preview">
        <m-icon src="/assets/img/image-position-flex-1-{theme}.svg" size="original" />
        <m-icon src="/assets/img/image-position-flex-2-{theme}.svg" size="original" />
      </div>

      <div class="description-group">
        <m-radio value="flex" [isChecked]="isImagePositionChecked('flex')" (onSelect)="onSelectImagePosition($event)">
          <span class="label" i18n="@@books.markup.sidebar.images-flex">books.markup.sidebar.images-flex</span>
        </m-radio>

        <div class="description">
          <span i18n="@@books.markup.sidebar.images-description-flex">
            books.markup.sidebar.images-description-flex
          </span>
          <span i18n="@@books.markup.sidebar.images-description-flex-2">
            books.markup.sidebar.images-description-flex-2
          </span>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="position-group">
      <div class="images-preview">
        <m-icon src="/assets/img/image-position-fixed-1-{theme}.svg" size="original" />
        <m-icon src="/assets/img/image-position-fixed-2-{theme}.svg" size="original" />
      </div>

      <div class="description-group">
        <m-radio value="fixed" [isChecked]="isImagePositionChecked('fixed')" (onSelect)="onSelectImagePosition($event)">
          <span class="label" i18n="@@books.markup.sidebar.images-fixed">books.markup.sidebar.images-fixed</span>
        </m-radio>

        <div class="description">
          <span i18n="@@books.markup.sidebar.images-description-fixed">
            books.markup.sidebar.images-description-fixed
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="footer">
    <m-button button-style="primary" (onClick)="onSaveClick()" i18n="@@dialog.save-button">dialog.save-button</m-button>
  </div>
</div>
