import { Inject, Injectable } from "@angular/core";
import { TokenStorageService } from "@metranpage/auth-interfaces";
import { UserStore } from "@metranpage/user-data";
import { Socket } from "../socketio/socketio.service";

@Injectable({
  providedIn: "root",
})
export class RealtimeService {
  constructor(
    private readonly socket: Socket,
    @Inject("TokenStorageService")
    private readonly tokenStorage: TokenStorageService,
    private readonly userStore: UserStore,
  ) {}

  login() {
    this.logout();
    this.socket.disconnect();
    this.socket.auth = { token: this.tokenStorage.getToken() };
    this.socket.connect();

    this.socket.on<void>("connect").subscribe(() => {
      this.socket.emit("user-balance-start");
      this.socket.emit("user-updates-start");
      this.socket.emit("book-state-start");
      this.socket.emit("book-result-state-start");
      this.socket.emit("active-subscription-start");
      this.socket.emit("image-generation-state-start");
      this.socket.emit("generated-image-state-start");
      this.socket.emit("book-cover-state-start");
      this.socket.emit("cover-conceptual-generation-state-start");
      this.socket.emit("notification-state-start");
      this.socket.emit("reward-notification-state-start");
      this.socket.emit("reward-daily-state-start");
      this.socket.emit("reward-onetime-state-start");
      this.socket.emit("promocode-discount-data-state-start");
      this.socket.emit("image-enhancement-state-start");
      this.socket.emit("character-state-start");
      this.socket.emit("image-generation-user-style-start");
      this.socket.emit("company-update-state-start");

      const user = this.userStore.getUser();
      if (user?.isAdmin) {
        this.socket.emit("moderation-image-request-state-start");
        this.socket.emit("moderation-cover-request-state-start");
      }
    });
  }

  logout() {
    this.socket.disconnect();
    this.socket.auth = { token: undefined };
  }

  getEvents<T>(eventName: string) {
    return this.socket.on<T>(eventName);
  }
}
