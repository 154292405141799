import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ApiErrorHandlerService } from "@metranpage/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import {
  NonEditableTemplate,
  NonEditableTemplateCreateDataWithFile,
  NonEditableTemplateUpdateDataWithFile,
} from "../../models/non-editable-template";
import { AdminNonEditableTemplatesApi, NonEditableTemplatesFilter } from "./non-editable-templates.api";
import { AdminNonEditableTemplatesStore } from "./non-editable-templates.store";

@Injectable({
  providedIn: "root",
})
export class AdminNonEditableTemplatesService {
  constructor(
    private readonly adminNonEditableTemplatesApi: AdminNonEditableTemplatesApi,
    private readonly adminNonEditableTemplatesStore: AdminNonEditableTemplatesStore,
    private readonly apiErrorHandler: ApiErrorHandlerService,
  ) {}

  async getNonEditableTemplates(): Promise<NonEditableTemplate[]> {
    return await this.adminNonEditableTemplatesApi.getNonEditableTemplates();
  }

  async loadNonEditableTemplateById(nonEditableTemplateId: number) {
    const nonEditableTemplateData =
      await this.adminNonEditableTemplatesApi.getNonEditableTemplateById(nonEditableTemplateId);
    this.adminNonEditableTemplatesStore.setActiveNonEditableTemplate(nonEditableTemplateData);
  }

  async loadNonEditableTemplatesPaginated(page: number, filters: NonEditableTemplatesFilter = {}) {
    const nonEditableTemplatesData = await this.adminNonEditableTemplatesApi.getNonEditableTemplatesPaginated(
      page,
      filters,
    );
    this.adminNonEditableTemplatesStore.setNonEditableTemplates(nonEditableTemplatesData.items);
    this.adminNonEditableTemplatesStore.setNonEditableTemplatesPageCount(nonEditableTemplatesData.pageCount);
  }

  async createNonEditableTemplate(
    data: NonEditableTemplateCreateDataWithFile,
  ): Promise<{ status: GeneralResultStatus; id?: number }> {
    try {
      const newNonEditableTemplate = await this.adminNonEditableTemplatesApi.createNonEditableTemplate(data);
      return {
        status: "success",
        id: newNonEditableTemplate.id,
      };
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return {
        status: "error",
        id: undefined,
      };
    }
  }

  async updateNonEditableTemplate(nonEditableTemplateId: number, data: NonEditableTemplateUpdateDataWithFile) {
    try {
      await this.adminNonEditableTemplatesApi.updateNonEditableTemplate(nonEditableTemplateId, data);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse);
      return "error";
    }
  }

  async deleteNonEditableTemplate(nonEditableTemplateId: number): Promise<{ status: GeneralResultStatus }> {
    try {
      const result = await this.adminNonEditableTemplatesApi.deleteNonEditableTemplate(nonEditableTemplateId);
      return {
        status: "success",
      };
    } catch (error: any) {
      return {
        status: "error",
      };
    }
  }

  createNonEditableTemplateForm() {
    return new FormGroup({
      title: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    });
  }

  async downloadExample(): Promise<GeneralResultStatus> {
    try {
      const response = await this.adminNonEditableTemplatesApi.downloadExample();
      return "success";
    } catch (error: any) {
      return "error";
    }
  }
}
