<m-ng-island [paddingSize]="0">
  <div class="menu">
    <m-icon src="/assets/img/login-sidebar-{theme}.png" />

    <div class="content">
      <div class="title" i18n="@@user.auth.login-registration.title">user.auth.login-registration.title</div>
      <m-localized-text
        class="description"
        text="user.auth.login-registration.description"
        i18n-text="@@user.auth.login-registration.description"
      />
    </div>

    <m-button
      button-style="primary"
      icon="login.svg"
      icon-position="left"
      text-align="left"
      i18n="@@user.auth.login-registration.button"
      (onClick)="onLoginClick()"
    >
      user.auth.login-registration.button
    </m-button>
  </div>
</m-ng-island>
