<div class="header">
  <m-button
    *ngIf="!runInEditMode"
    class="button-back"
    button-style="tertiary"
    icon="button-back.svg"
    icon-position="only"
    (onClick)="onBackClick()"
  />

  <span>{{ contentHeader }}</span>
</div>

<div class="divider-hor"></div>

<div class="content">
  <div class="file-upload">
    <m-file-drop #filedrop (dropped)="dropped($event)" style="--custom-height: 100%">
      <div class="file-drop-container">
        <div class="drop-placeholder">
          <m-ng-icon src="upload.svg" [size]="40" color="--color-font-main" />

          <div>
            <div class="description-text">
              <ng-container i18n="@@books.upload.drop-text1">books.upload.drop_text1</ng-container>
            </div>
            <div class="description-text">
              <ng-container i18n="@@books.upload.drop-text2">books.upload.drop-text2</ng-container>
              <span class="mark" (click)="openFileSelector()" i18n="@@books.upload.drop-text2-upload">
                books.upload.drop_text2_upload
              </span>
              <ng-container i18n="@@books.upload.drop-text3">books.upload.drop_text3</ng-container>
            </div>
          </div>

          <p *ngIf="book?.originalUploadFilename">
            <span i18n="@@books.upload.original-filename-hint">books.upload.original-filename-hint</span>
            {{book.originalUploadFilename}}
          </p>
        </div>

        <div class="divider-ver"></div>

        <div class="sample-options">
          <span class="sample-option-text" i18n="@@books.upload.sample-options">books.upload.sample-options</span>

          <div class="buttons">
            <m-button
              button-style="secondary"
              icon="blank-document.svg"
              icon-position="left"
              size="full-width"
              i18n="@@books.upload.use-empty-doc-2"
              (onClick)="onEmptyDocumentClick()"
            >
              books.upload.use-empty-doc-2
            </m-button>

            <m-button
              button-style="secondary"
              icon="text-document.svg"
              icon-position="left"
              size="full-width"
              i18n="@@books.upload.use-sample-doc-2"
              (onClick)="onSampleDocumentClick()"
            >
              books.upload.use-sample-doc-2
            </m-button>
          </div>
        </div>
      </div>
    </m-file-drop>
  </div>

  <div class="hints">
    <div class="hint" *ngFor="let hint of hints">
      <!-- <img [src]="hint.image" alt="" *ngIf="hint.image" /> -->
      <m-ng-icon *ngIf="hint.image" [src]="hint.image" [size]="64" />
      <div class="texts">
        <h3>{{hint.title}}</h3>
        <span>{{hint.description}}</span>
      </div>
    </div>
  </div>
</div>

<m-info-modal
  *ngIf="isFileLimitModalVisible"
  title="books.info-modal.file-size-limit.text"
  i18n-title="@@books.info-modal.file-size-limit.title"
  [text]="getFileLimitModalText()"
  actionText="books.info-modal.file-size-limit.action-text"
  i18n-actionText="@@books.info-modal.file-size-limit.action-text"
  [backgroundImage]="'/assets/img/info-modal-file-size-limit.png'"
  (actionClick)="onActionClickModal()"
  (close)="onCloseFileLimitModalClick()"
/>

<m-modal
  *ngIf="hasTablesInMultiColumnTemplateModalVisible"
  [closeButtonVisible]="true"
  [closeOnBackDropClick]="true"
  (close)="onCloseTablesInMultiColumnTemplateModalClick()"
>
  <div class="modal-container">
    <div class="modal-title" i18n="@@books.upload.tables-in-multi-column-template-error.title">
      books.upload.tables-in-multi-column-template-error.title
    </div>

    <m-localized-text
      i18n-text="@@books.upload.tables-in-multi-column-template-error.text"
      text="books.upload.tables-in-multi-column-template-error.text"
    />

    <div class="modal-buttons">
      <m-button
        button-style="primary"
        (onClick)="onCloseTablesInMultiColumnTemplateModalClick()"
        i18n="@@books.upload.tables-in-multi-column-template-error.button"
      >
        books.upload.tables-in-multi-column-template-error.button
      </m-button>
    </div>
  </div>
</m-modal>

<!-- <m-loading-spinner *ngIf="(loadingState$ | async)?.isActive" @fadeInOut>
  {{(loadingState$ | async)?.description}}
</m-loading-spinner> -->
