<m-ng-island [paddingSize]="0" [ngClass]="{'modal': isModal, 'empty-results': imageGenerations.length === 0}">
  <div class="container">
    <div *ngIf="imageGenerations.length > 0; else noImageGenerations" class="results">
      <m-generation-result
        *ngFor="let imageGeneration of imageGenerations; let isLast = last;trackBy:trackByImageGeneration"
        #generationResult
        [user]="user"
        [imageGeneration]="imageGeneration"
        [basicStyle]="getBasicStyle(imageGeneration)"
        [advancedStyle]="getAdvancedStyle(imageGeneration)"
        [showDivider]="!isLast"
        [isModal]="isModal"
        [characters]="characters"
        (onPreviewImage)="onPreviewImageClick($event)"
        (onSelectImage)="onSelectImageClick($event)"
        (onDeleteImageGeneration)="onDeleteImageGenerationClick($event)"
        (onDownnloadImage)="onDownloadImageClick($event)"
        (onDeleteImage)="onDeleteImageClick($event)"
        (onUpscaleImage)="onUpscaleImageClick(imageGeneration, $event)"
        (onUnzoomImage)="onUnzoomImageClick(imageGeneration, $event)"
        (onEditImageGeneration)="onEditImageGenerationClick($event)"
      ></m-generation-result>

      <div *ngIf="isImageGenerationsLoading" class="loading"><m-loading-spinner @fadeInOut></m-loading-spinner></div>
    </div>

    <ng-template #noImageGenerations>
      <div class="no-results">
        <div class="header" i18n="@@image-generation.results.results-will-be-shown-here">
          image-generation.results.results-will-be-shown-here
        </div>
        <div class="text">
          <span i18n="@@image-generation.results.no-results.enter-prompt">
            image-generation.results.no-results.enter-prompt
          </span>
          <span class="text-accent" i18n="@@image-generation.results.no-results.press-generate">
            image-generation.results.no-results.press-generate
          </span>
        </div>
      </div>
    </ng-template>
  </div>
</m-ng-island>
