import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { I18nService } from "@metranpage/i18n";
import { DateTime } from "luxon";
import { NotificationPayloadPromocodeActivate } from "../../../models/notification";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-promocode-activate-card-view",
  templateUrl: "account-notification-promocode-activate-card.view.html",
  styleUrls: ["account-notification-promocode-activate-card.view.scss"],
})
export class AccountNotificationPromocodeActivateCardView extends AccountNotificationBaseCardView<NotificationPayloadPromocodeActivate> {
  constructor(
    private readonly i18nService: I18nService,
    private readonly router: Router,
  ) {
    super();
  }

  protected getTitle() {
    const promocodeTitile = this.payload.promocode;
    return `${$localize`:@@user.profile.notifications.card.promocode-activate.title:${promocodeTitile}:promocodeTitile:`}`;
  }

  protected getSubscriptionPeriod() {
    if (!this.payload.subscriptionPeriod) {
      return "";
    }
    const subscriptionPeriod = `${this.payload.subscriptionPeriod} ${this.i18nService.pluralize(
      $localize`:@@pricing.pluralize-months:`,
      {
        months: this.payload.subscriptionPeriod,
      },
    )}`;
    return `${$localize`:@@user.profile.notifications.card.promocode-activate.subscription.description-2:`} ${subscriptionPeriod}`;
  }

  protected getTokensDescription() {
    let description = "";
    if (this.payload.creditsCount) {
      const creditsCount = `${this.i18nService.pluralize($localize`:@@pricing.pluralize-credits:`, {
        credits: this.payload.creditsCount,
      })}`;
      description += `${this.payload.creditsCount} ${creditsCount}`;
    }
    if (description !== "") {
      description += ", ";
    }
    if (this.payload.goldCreditsCount) {
      const goldCreditsCount = `${this.i18nService.pluralize($localize`:@@pricing.pluralize-gold-credits:`, {
        credits: this.payload.goldCreditsCount,
      })}`;
      description += `${this.payload.goldCreditsCount} ${goldCreditsCount}`;
    }
    return `${description} ${$localize`:@@user.profile.notifications.card.promocode-activate.tokens.description:`}`;
  }

  protected getDateShort() {
    const date = this.payload.expireDate;
    return DateTime.fromISO(date.toString()).toLocaleString(DateTime.DATE_FULL);
  }

  protected openTariffsPage() {
    this.router.navigate(["/payments/subscription"]);
  }
}
