<div class="toolbar-button" (click)="toggleAddMenu()">
  <m-icon color="--accent" src="toolbar-plus.svg" size="s" />
</div>
<div class="toolbar-button" (click)="toggleAdditionalMenu()">
  <m-icon color="--accent" src="toolbar-additional.svg" size="s" />
</div>

<m-markup-editor-menu id="add-menu" *ngIf="isAddMenuVisible" @fadeInOut>
  <m-markup-editor-menu-item
    icon="markup-add-text.svg"
    iconArrow="markup-move-right.svg"
    title="books.markup.toolbar.add-text"
    i18n-title="@@books.markup.toolbar.add-text"
    (mouseenter)="onMenuItemEnter('text'); isHovered = true;"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    icon="markup-add-image.svg"
    title="books.markup.toolbar.add-image"
    i18n-title="@@books.markup.toolbar.add-image"
    (mouseenter)="isHovered = false"
    (click)="addImageBlock.emit(); toggleAddMenu()"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    icon="markup-add-list.svg"
    iconArrow="markup-move-right.svg"
    title="books.markup.toolbar.add-list"
    i18n-title="@@books.markup.toolbar.add-list"
    (mouseenter)="onMenuItemEnter('list'); isHovered = true;"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    [ngClass]="{'disabled': !toolbarSettings.isAddTableAvailable }"
    icon="markup-add-table.svg"
    title="books.markup.toolbar.add-table"
    i18n-title="@@books.markup.toolbar.add-table"
    (mouseenter)="isHovered = false"
    (click)="onAddTableClick()"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    icon="markup-add-page-break.svg"
    title="books.markup.toolbar.add-page-break"
    i18n-title="@@books.markup.toolbar.add-page-break"
    (mouseenter)="isHovered = false"
    (click)="addPageBreakBlock.emit(); toggleAddMenu()"
  ></m-markup-editor-menu-item>
</m-markup-editor-menu>

<m-markup-editor-menu-styles
  id="add-menu-styles"
  *ngIf="isAddMenuVisible  && (isHovered  || isStylesMenuHovered && isListItemHovered)"
  @fadeInOut
  [style.top.px]="isListItemHovered ? 85 : 0"
>
  <m-markup-editor-menu-item
    *ngIf="isAddTextItemSubmenuVisible"
    icon="text.svg"
    title="books.style-editor.main-text-title"
    i18n-title="@@books.style-editor.main-text-title"
    (click)="addText('mainText'); toggleAddMenu();"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    *ngIf="isAddTextItemSubmenuVisible"
    icon="h1.svg"
    title="books.styles.h1"
    i18n-title="@@books.styles.h1"
    (click)="addText('header1'); toggleAddMenu();"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    *ngIf="isAddTextItemSubmenuVisible"
    icon="h2.svg"
    title="books.styles.h2"
    i18n-title="@@books.styles.h2"
    (click)="addText('header2'); toggleAddMenu();"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    *ngIf="isAddTextItemSubmenuVisible"
    icon="h3.svg"
    title="books.styles.h3"
    i18n-title="@@books.styles.h3"
    (click)="addText('header3'); toggleAddMenu();"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    *ngIf="isAddTextItemSubmenuVisible"
    icon="h4.svg"
    title="books.styles.h4"
    i18n-title="@@books.styles.h4"
    (click)="addText('header4'); toggleAddMenu();"
  ></m-markup-editor-menu-item>

  <ng-container *ngIf="isAddTextItemSubmenuVisible">
    <div class="divider"></div>
  </ng-container>

  <m-markup-editor-menu-item
    *ngIf="isAddTextItemSubmenuVisible"
    icon="inset.svg"
    title="books.styles.lead"
    i18n-title="@@books.styles.lead"
    (click)="addText('lead'); toggleAddMenu();"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    *ngIf="isAddTextItemSubmenuVisible"
    icon="quote.svg"
    title="books.styles.quote"
    i18n-title="@@books.styles.quote"
    (click)="addText('quote'); toggleAddMenu();"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    *ngIf="isAddTextItemSubmenuVisible"
    icon="author-quote.svg"
    title="books.styles.quote-author"
    i18n-title="@@books.styles.quote-author"
    (click)="addText('quoteAuthor'); toggleAddMenu();"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    *ngIf="isAddTextItemSubmenuVisible"
    icon="text.svg"
    title="books.style.verse"
    i18n-title="@@books.style.verse"
    (click)="addText('verse'); toggleAddMenu();"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    *ngIf="isAddTextItemSubmenuVisible"
    icon="text.svg"
    title="books.style.annotation"
    i18n-title="@@books.style.annotation"
    (click)="addText('note'); toggleAddMenu();"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    *ngIf="isAddTextItemSubmenuVisible"
    icon="style-date.svg"
    title="books.styles.caption"
    i18n-title="@@books.styles.caption"
    (click)="addText('caption'); toggleAddMenu();"
  ></m-markup-editor-menu-item>

  <!--  -->

  <m-markup-editor-menu-item
    *ngIf="isAddListSubmenuVisible"
    icon="markup-add-list-unordered.svg"
    title="books.markup.toolbar.add-list-unordered"
    i18n-title="@@books.markup.toolbar.add-list-unordered"
    (click)="addList('unordered'); toggleAddMenu();"
  ></m-markup-editor-menu-item>

  <div *ngIf="isAddListSubmenuVisible" class="divider"></div>

  <m-markup-editor-menu-item
    *ngIf="isAddListSubmenuVisible"
    icon="markup-add-list-ordered.svg"
    title="books.markup.toolbar.add-list-ordered"
    i18n-title="@@books.markup.toolbar.add-list-ordered"
    (click)="addList('ordered'); toggleAddMenu();"
  ></m-markup-editor-menu-item>

  <m-markup-editor-menu-item
    *ngIf="isAddListSubmenuVisible"
    icon="markup-add-list-ordered-bkt.svg"
    title="books.markup.toolbar.add-list-ordered-bkt"
    i18n-title="@@books.markup.toolbar.add-list-ordered-bkt"
    (click)="addList('ordered-bkt'); toggleAddMenu();"
  ></m-markup-editor-menu-item>
</m-markup-editor-menu-styles>

<m-markup-editor-menu
  id="additional-menu"
  *ngIf="isAdditionalMenuVisible"
  @fadeInOut
>
  <m-markup-editor-menu-item
    *ngIf="indexBlock !== 0"
    icon="markup-move-up.svg"
    title="books.markup.toolbar.move-up"
    i18n-title="@@books.markup.toolbar.move-up"
    (click)="upBlock.emit(); toggleAdditionalMenu()"
  ></m-markup-editor-menu-item>
  <m-markup-editor-menu-item
    *ngIf="indexBlock !== blockLength"
    icon="markup-move-down.svg"
    title="books.markup.toolbar.move-down"
    i18n-title="@@books.markup.toolbar.move-down"
    (click)="downBlock.emit(); toggleAdditionalMenu()"
  ></m-markup-editor-menu-item>
  <m-markup-editor-menu-item
    icon="markup-delete.svg"
    title="books.markup.toolbar.delete"
    i18n-title="@@books.markup.toolbar.delete"
    (click)="deleteBlock.emit(); toggleAdditionalMenu()"
  ></m-markup-editor-menu-item>
</m-markup-editor-menu>
