import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "m-cover-object-create-button",
  templateUrl: "./cover-object-create-button.component.html",
  styleUrls: ["./cover-object-create-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverObjectCreateButtonComponent {
  @Input() text = "";
  @Input() iconSrc?: string;
  @Input() hasArrowIcon = false;
  @Input() hasNewMark = false;
}
