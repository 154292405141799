import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RewardOneTimeCreateData } from "../../../models/reward-onetime";
import { AdminRewardsOneTimeService } from "../../../services/rewards/rewards-onetime/rewards-onetime.service";

@Component({
  selector: "m-admin-add-reward-onetime-modal",
  templateUrl: "./add-reward-onetime.view.html",
  styleUrls: ["./add-reward-onetime.view.scss"],
})
export class AddRewardOneTimeView implements OnInit {
  @Output()
  onRewardOneTimeAdded = new EventEmitter<RewardOneTimeCreateData>();
  @Output()
  onCancelAddition = new EventEmitter<void>();

  protected form!: FormGroup;

  constructor(private readonly adminRewardsOneTimeService: AdminRewardsOneTimeService) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.adminRewardsOneTimeService.createRewardOneTimeForm();
  }

  createRewardOneTime() {
    this.onRewardOneTimeAdded.emit(this.form.getRawValue());
  }

  onCancel() {
    this.onCancelAddition.emit();
  }

  protected getTypeSelectOptions() {
    return this.adminRewardsOneTimeService.getAvailableRewardsDailyTypeSelectOptions();
  }
}
