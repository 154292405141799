import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { PricingViewService } from "@metranpage/pricing";
import * as _ from "lodash-es";
import { debounceTime, takeUntil } from "rxjs";
import { RewardDaily, RewardDailyCreateData, RewardDailyType } from "../../../models/reward-daily";
import { AdminRewardsDailyService } from "../../../services/rewards/rewards-daily/rewards-daily.service";
import { AdminRewardsDailyStore } from "../../../services/rewards/rewards-daily/rewards-daily.store";
import { AdminBasePage } from "../../admin/admin.page";

@Component({
  selector: "m-admin-rewards-daily-page",
  templateUrl: "./rewards-daily.page.html",
  styleUrls: ["./rewards-daily.page.scss"],
})
export class AdminRewardsDailyPage extends AdminBasePage implements OnInit {
  rewardsDaily: RewardDaily[] = [];
  page = 1;
  pageCount = 1;

  protected searchForm = new FormGroup({
    type: new FormControl("", { nonNullable: true, validators: [] }),
  });
  protected searchType: string | undefined = undefined;

  protected isAddRewardDailyModalVisible = false;
  protected isDeleteRewardDailyModalVisible = false;
  protected rewardDailyForDelete?: RewardDaily = undefined;

  constructor(
    private readonly adminRewardsDailyStore: AdminRewardsDailyStore,
    private readonly adminRewardsDailyService: AdminRewardsDailyService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly pricingViewService: PricingViewService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();

    adminRewardsDailyStore
      .getRewardsDailyObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((rewardsDaily) => {
        this.rewardsDaily = rewardsDaily;
        this.cdr.markForCheck();
      });

    adminRewardsDailyStore
      .getRewardsDailyPageCountObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((count) => {
        this.pageCount = count;
        this.cdr.markForCheck();
      });
  }

  ngOnInit(): void {
    this.adminRewardsDailyService.loadRewardsDailyPaginated(this.page);

    this.searchForm.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(600)).subscribe((value) => {
      this.searchType = undefined;
      if (value.type) {
        this.searchType = value.type;
      }

      this.adminRewardsDailyService.loadRewardsDailyPaginated(this.page, {
        type: this.searchType,
      });
    });
  }

  trackByRewardDailyId(index: number, rewardDaily: RewardDaily) {
    return rewardDaily.id;
  }

  onPageChanged(page: number) {
    this.page = page;
    this.adminRewardsDailyService.loadRewardsDailyPaginated(this.page);
  }

  protected showRewardDailyDetails(rewardDaily: RewardDaily) {
    this.router.navigate(["admin", "rewards-daily", rewardDaily.id]);
  }

  protected async onAddRewardDaily() {
    this.isAddRewardDailyModalVisible = true;
  }

  protected async onRewardDailyAdded(data: RewardDailyCreateData) {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminRewardsDailyService.createRewardDaily(data);
    this.loadingService.stopLoading();
    if (result.status === "success") {
      this.onCloseAddRewardDailyModal();
      this.adminRewardsDailyService.loadRewardsDailyPaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.rewards.daily.error.cant-create:`);
    }
  }

  protected onCloseAddRewardDailyModal() {
    this.isAddRewardDailyModalVisible = false;
  }

  protected onCloseDeleteRewardDailyModal() {
    this.isDeleteRewardDailyModalVisible = false;
    this.rewardDailyForDelete = undefined;
  }

  protected showDeleteRewardDailyModal(rewardDaily: RewardDaily) {
    this.isDeleteRewardDailyModalVisible = true;
    this.rewardDailyForDelete = rewardDaily;
  }

  protected async onDeleteRewardDaily() {
    if (!this.rewardDailyForDelete) {
      return;
    }

    const result = await this.adminRewardsDailyService.deleteRewardDaily(this.rewardDailyForDelete.id);
    if (result.status === "success") {
      this.isDeleteRewardDailyModalVisible = false;
      this.rewardDailyForDelete = undefined;
      this.adminRewardsDailyService.loadRewardsDailyPaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.rewardDailys.rewardDaily.error.cant-delete-rewardDaily:`);
    }
  }

  protected getSelectTypeOptions() {
    return this.adminRewardsDailyService.getAllRewardsDailyTypeSelectOptions();
  }

  protected getTypeTitle(type: RewardDailyType) {
    switch (type) {
      case "likes-give":
        return $localize`:@@admin.rewards.daily.task.likes.give:`;
      case "likes-take":
        return $localize`:@@admin.rewards.daily.task.likes.take:`;
      default:
        return type;
    }
  }
}
