import { Component, OnInit } from "@angular/core";
import { I18nService } from "@metranpage/i18n";
import { NotificationWebinar } from "@metranpage/user-data";
import { DateTime } from "luxon";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-webinar-card-view",
  templateUrl: "account-notification-webinar-card.view.html",
  styleUrls: ["account-notification-webinar-card.view.scss"],
})
export class AccountNotificationWebinarCardView
  extends AccountNotificationBaseCardView<NotificationWebinar>
  implements OnInit
{
  protected locale = "en";

  protected isWebinarToday = false;
  protected isWebinarTomorrow = false;
  protected isWebinarEnded = false;

  protected text = "";

  constructor(i18nService: I18nService) {
    super();
    this.locale = i18nService.getLocale();
  }

  ngOnInit() {
    this.updateDate();
    this.updateText();
  }

  protected updateDate() {
    const utcTime = DateTime.fromISO(this.notification.createdAt.toString()).toUTC();
    const utcTimeNow = DateTime.now().toUTC();

    let webinarDate = DateTime.fromISO(this.notification.createdAt.toString()).toUTC();
    if (utcTime.hour < 17) {
      webinarDate = webinarDate.set({ hour: 17, minute: 0 });
      this.isWebinarToday = true;
    } else {
      webinarDate = webinarDate.plus({ day: 1 });
      webinarDate = webinarDate.set({ hour: 17, minute: 0 });
      this.isWebinarTomorrow = true;
    }

    const diff = webinarDate.diff(utcTimeNow, "minutes");
    if (diff.toMillis() < 0) {
      this.isWebinarEnded = true;
    }
  }

  protected updateText() {
    let text = "";

    const utcTimeData = DateTime.fromISO(this.notification.createdAt.toString()).toUTC();
    const utcTimeDataNextDay = DateTime.fromISO(this.notification.createdAt.toString()).toUTC().plus({ days: 1 });

    let webinarDate = utcTimeData;
    if (this.isWebinarTomorrow) {
      text += $localize`:@@user.profile.notifications.card.webinar.active.date-tomorrow:`;
      webinarDate = utcTimeDataNextDay;
    } else {
      text += $localize`:@@user.profile.notifications.card.webinar.active.date-today:`;
    }

    const date = webinarDate.toLocaleString(
      {
        ...DateTime.DATE_SHORT,
        year: "numeric",
        month: "numeric",
      },
      { locale: this.locale },
    );

    text += ` (${date}) ${$localize`:@@user.profile.notifications.card.webinar.active.description:`}`;

    this.text = text;
  }
}
