<div class="container">
  <div class="toolbar-inline-button" (click)="boldClick()">
    <img *ngIf="!isBoldActive()" src="/assets/icons/toolbar-inline-bold.svg" />
    <img
      *ngIf="isBoldActive()"
      src="/assets/icons/toolbar-inline-bold-active.svg"
    />
  </div>

  <div class="toolbar-inline-button" (click)="italicClick()">
    <img
      *ngIf="!isItalicActive()"
      src="/assets/icons/toolbar-inline-italic.svg"
    />
    <img
      *ngIf="isItalicActive()"
      src="/assets/icons/toolbar-inline-italic-active.svg"
    />
  </div>

  <div class="toolbar-inline-button" (click)="addFootnoteClick()">
    <img src="/assets/icons/toolbar-inline-note.svg" />
  </div>
</div>
