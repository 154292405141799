import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CoverService } from "@metranpage/book";
import { Book } from "@metranpage/book-data";
import { FormatService } from "@metranpage/format-data";

@Component({
  selector: "m-last-project",
  templateUrl: "./last-project.view.html",
  styleUrls: ["./last-project.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastProjectView {
  @Input()
  projects: Book[] = [];

  @Output()
  onAddProjectClick = new EventEmitter();

  protected lastProject?: Book;
  protected coverUrl?: string;

  protected isEditMenuVisible = false;

  constructor(
    private readonly formatService: FormatService,
    private readonly coverService: CoverService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  onInit() {
    this.getLastProject();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["projects"]) {
      this.getLastProject();
    }
    this.cdr.detectChanges();
  }

  onNewProjectClick() {
    this.onAddProjectClick.emit();
  }

  onGoToProjectClick(event: Event) {
    event.stopPropagation();

    if (!this.lastProject) {
      return;
    }

    this.isEditMenuVisible = true;
  }

  onCloseLastProjectEditMenu() {
    this.isEditMenuVisible = false;
  }

  getLastProject() {
    if (this.projects.length > 0) {
      this.lastProject = this.projects[0];
      this.coverUrl = this.getCoverImageUrl();
    }
  }

  getTitle() {
    return this.lastProject?.title || "";
  }

  getAuthor() {
    return `
      ${this.lastProject?.authorLastName ?? ""}
      ${this.lastProject?.authorFirstName ?? ""}
      ${this.lastProject?.authorMiddleName ?? ""}
    `.trim();
  }

  getFormat() {
    const formatData = this.formatService.getFormatData(
      this.lastProject?.bookSettings?.width,
      this.lastProject?.bookSettings?.height,
    );
    if (formatData.format === "custom") {
      return `${formatData.width}x${formatData.height || "0"} ${$localize`:@@books.format.unit:`}`;
    }
    return formatData.format.toUpperCase();
  }

  private getCoverImageUrl() {
    if (!this.lastProject?.coverId) {
      return "/assets/img/book.png";
    }
    return this.coverService.getCoverPreviewImageUrl(this.lastProject.id);
  }

  get aspectRatio() {
    if (!this.lastProject) {
      return "1";
    }
    return `${this.lastProject.bookSettings?.width}/${this.lastProject.bookSettings?.height}`;
  }
}
