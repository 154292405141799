import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "m-sidebar-fixed",
  templateUrl: "./sidebar-fixed.component.html",
  styleUrls: ["./sidebar-fixed.component.scss"],
  standalone: true,
  imports: [CommonModule],
})
export class SidebarFixed {}
