import { CommonModule, NgClass } from "@angular/common";
import { Component, EventEmitter, HostBinding, Inject, Input, type OnInit, Output } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { AVAILABLE_LANGUAGES, LangSpec } from "@metranpage/i18n";
import { IconComponent } from "@metranpage/design-system";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "m-lang-select-2",
  template: `
    <div
      *ngFor="let language of languages"
      class="language"
      [ngClass]="{ selected: language.value === userLanguage }"
      (click)="onLanguageChange(language.value)"
    >
      <m-ng-icon [src]="language.iconSrc" [size]="getIconSize()" [noRecolor]="true" />
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        &.hidden {
          display: none
        }
      }

      .language {
        cursor: pointer;
        opacity: 0.2;

        &.selected {
          opacity: 1;
        }
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, NgClass, ReactiveFormsModule, AngularSvgIconModule, IconComponent],
})
export class LangSelect2Component implements OnInit {
  @Input()
  userLanguage?: string;
  @Input("select-style")
  selectStyle: "large" | "small" = "small";
  @Output()
  changeLang = new EventEmitter<string>();

  @HostBinding("class.hidden")
  isHidden = false;

  constructor(@Inject(AVAILABLE_LANGUAGES) protected readonly languages: LangSpec[]) {}

  ngOnInit(): void {
    this.isHidden = this.languages.length <= 1;
  }

  protected onLanguageChange(value: string) {
    this.changeLang.emit(value);
  }

  protected getIconSize() {
    return this.selectStyle === "small" ? 24 : 28;
  }
}
