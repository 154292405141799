<div class="warning">
  <div class="title">{{getTitleText()}}</div>
  <div #text class="text"></div>
</div>

<m-icon
  src="markup-footnote-delete.svg"
  color="--accent"
  size="xs"
  (click)="onRemoveClick()"
/>
