<m-ng-icon *ngIf="iconLeft" [src]="iconLeft" class="icon icon-left" [size]="iconSize" />

<input
  [placeholder]="placeholder"
  [name]="name"
  [autocomplete]="getAutocomplete()"
  [required]="isRequired"
  [tabindex]="tabindex"
  [type]="getInputType()"
  [disabled]="isDisabled"
  (input)="onInputChange($event)"
  (keydown)="onKeyDown($event)"
  #input
/>

<m-ng-icon *ngIf="iconRight" [src]="iconRight" class="icon icon-right" [size]="iconSize" />
