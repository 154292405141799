import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ThemeService } from "@metranpage/theme";
import { CreateProjectType } from "../../services/new-project.service";

@Component({
  selector: "m-select-new-project-type-modal",
  templateUrl: "./select-new-project-type-modal.view.html",
  styleUrls: ["./select-new-project-type-modal.view.scss"],
})
export class SelectNewProjectTypeModalView implements OnInit {
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;

  @Output()
  select = new EventEmitter<CreateProjectType>();
  @Output()
  close = new EventEmitter();

  protected bookImg = "create-book-{theme}.png";
  protected coverImg = "create-cover-{theme}.png";

  constructor(private readonly themeService: ThemeService) {}

  ngOnInit(): void {
    this.normalizeImgPaths();
  }

  onSelectClick(projectType: CreateProjectType) {
    this.select.emit(projectType);
  }

  onCloseClick() {
    this.close.emit();
  }

  normalizeImgPaths() {
    this.bookImg = this.bookImg.replace("{theme}", this.themeService.getThemeSuffix());
    this.coverImg = this.coverImg.replace("{theme}", this.themeService.getThemeSuffix());
  }
}
