import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { I18nService } from "@metranpage/i18n";
import { NotificationTrialPeriodEnding } from "@metranpage/user-data";
import { DateTime } from "luxon";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-trial-period-expire-card-view",
  templateUrl: "account-notification-trial-period-expire-card.view.html",
  styleUrls: ["account-notification-trial-period-expire-card.view.scss"],
})
export class AccountNotificationTrialPeriodExpireCardView extends AccountNotificationBaseCardView<NotificationTrialPeriodEnding> {
  protected locale = "en";

  constructor(
    private readonly router: Router,
    private readonly i18nService: I18nService,
  ) {
    super();
    this.locale = i18nService.getLocale();
  }

  protected getDateShort() {
    const date = this.payload.expireDate;
    return DateTime.fromISO(date.toString()).toLocaleString(DateTime.DATE_FULL, { locale: this.locale });
  }

  protected getTitle() {
    return `${$localize`:@@user.profile.notifications.card.trial-period-ending.title:`}
      ${this.i18nService.pluralize($localize`:@@common.pluralize.left:`, { value: this.payload.daysLeft })} 
      ${this.payload.daysLeft} 
      ${this.i18nService.pluralize($localize`:@@pricing.pluralize-days:`, { days: this.payload.daysLeft })}
    `;
  }

  protected getTrialPeriodDaysLeft() {
    return `${this.payload.daysLeft} ${this.i18nService.pluralize($localize`:@@pricing.pluralize-days:`, {
      days: this.payload.daysLeft,
    })}`;
  }

  protected openTariffsPage() {
    this.router.navigate(["/payments/subscription"]);
  }
}
