import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import {
  ImageGenerationUserStyle,
  ImageGenerationUserStyleDataDto,
} from "../../models/image-generation-user-style/image-generation-user-style";
import { CreateImageGenerationUserStyleResponse } from "./image-generation-user-style.service";

@Injectable({
  providedIn: "root",
})
export class ImageGenerationUserStyleApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getStyle() {
    return this.get<ImageGenerationUserStyle | undefined>("image-generation-user-style");
  }

  async createStyle(data: ImageGenerationUserStyleDataDto, file: File | undefined) {
    const formData: FormData = new FormData();
    if (file) {
      formData.append("file", file, file.name);
    }

    for (const [key, value] of Object.entries(data)) {
      if (typeof value !== "string") {
        formData.append(key, JSON.stringify(value));
        continue;
      }
      formData.append(key, value);
    }

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post<CreateImageGenerationUserStyleResponse>("image-generation-user-style", formData, {
      headers,
    });
  }

  getUrlForImage(styleId: number, imagePath: string) {
    return this.absoluteUrl(`image-generation-user-style/${styleId}/previews/${imagePath}`);
  }

  getUrlForThumbnailImage(styleId: number, imagePath: string) {
    return this.absoluteUrl(`image-generation-user-style/${styleId}/previews/thumbnail/${imagePath}`);
  }
}
