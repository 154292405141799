<m-ng-island>
  <div class="" i18n="@@admin.account-main.buttons.title">admin.account-main.buttons.title</div>

  <m-button
    button-style="secondary"
    (onClick)="onEnableNotificationsClick()"
    i18n="@@user.profile.enable-browser-notifications-button"
  >
    user.profile.enable-browser-notifications-button
  </m-button>

<div class="divider"></div>

  <div class="" i18n="@@admin.account-main.buttons.notification-test.button">admin.account-main.buttons.title</div>

  <form [formGroup]="formAdminNotifications" class="control-group">
    <m-select formControlName="notificationType" size="m-plus" [options]="testNotificationTypeOptions" />
    <m-button
      button-style="secondary"
      [is-disabled]="!formAdminNotifications.valid"
      (onClick)="onTestNotificationClick()"
      i18n="@@admin.account-main.buttons.notification-test.button"
    >
      admin.account-main.buttons.notification-test.button
    </m-button>
  </form>
</m-ng-island>
