import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { FabulaCreateGenerationResultDataDto, FabulaRemoveBackgroundDataDto } from "../../models/fabula/fabula";

@Injectable({
  providedIn: "root",
})
export class FabulaImageGenerationApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async removeBackground(data: FabulaRemoveBackgroundDataDto) {
    return this.post<FabulaCreateGenerationResultDataDto>("image-enhancement/remove-background", data);
  }
}
