import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CoreModule } from "@metranpage/core";
import { AdminMenuItemComponent } from "../common/components/menu-item.component";
import { Pagination } from "../common/views/pagination/pagination.view";
import { SuperadminAuthPage } from "./pages/auth/auth.page";
import { AdminCompaniesPage } from "./pages/companies/companies.page";
import { SuperadminCompanyAdminsSettingsPage } from "./pages/company-settings-admins/company-settings-admins.page";
import { SuperadminCompanyCommonSettingsPage } from "./pages/company-settings-common/company-settings-common.page";
import { SuperadminCompanySettingsHostPage } from "./pages/company-settings-host/company-settings-host.page";
import { FeatureTogglesPage } from "./pages/feature-toggles/feature-toggles.page";
import { SuperadminHostPage } from "./pages/superadmin-host.page";
import { SuperadminAuthInterceptor } from "./services/auth.interceptor";
import { AddCompanyView } from "./views/add-company/add-company.view";
import { SuperadminComanySettingsNavView } from "./views/company-settings-nav/company-settings-nav.view";
import { SuperadminNavView } from "./views/nav/nav.view";

@NgModule({
  declarations: [
    // components
    //
    // views
    // Pagination,
    // AdminNavView,
    SuperadminNavView,
    AddCompanyView,
    SuperadminComanySettingsNavView,
    // pages
    SuperadminHostPage,
    SuperadminAuthPage,
    FeatureTogglesPage,
    AdminCompaniesPage,
    SuperadminCompanySettingsHostPage,
    SuperadminCompanyCommonSettingsPage,
    SuperadminCompanyAdminsSettingsPage,
  ],
  imports: [RouterModule, CoreModule, AdminMenuItemComponent, Pagination],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: SuperadminAuthInterceptor, multi: true }],
})
export class SuperadminModule {}
