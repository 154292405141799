import { Injectable } from "@angular/core";
import { BookCoverTemplate } from "@metranpage/book-data";
import { Store } from "@metranpage/state";
import { Observable } from "rxjs";

type State = {
  templates: BookCoverTemplate[];
  activeTemplate?: BookCoverTemplate;
};

@Injectable({
  providedIn: "root",
})
export class AdminCoverTemplatesStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      templates: [],
      activeTemplate: undefined,
    };
  }

  getTemplatesObservable(): Observable<BookCoverTemplate[]> {
    return this.getFieldObservable("templates");
  }
  getActiveTemplateObservable(): Observable<BookCoverTemplate | undefined> {
    return this.getFieldObservable("activeTemplate");
  }

  setTemplates(templates: BookCoverTemplate[]) {
    this.update((state) => ({ ...state, templates }));
  }

  setActiveTemplate(activeTemplate?: BookCoverTemplate) {
    this.update((state) => ({ ...state, activeTemplate }));
  }
}
