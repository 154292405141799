import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CoverService } from "@metranpage/book";
import { BookCover } from "@metranpage/book-data";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { NotificationsPopUpService, RouterService } from "@metranpage/core";
import { GeneratedImage, GeneratedImageService } from "@metranpage/image-generation";
import { UserStore } from "@metranpage/user-data";
import { Subscription, map } from "rxjs";
import { CommunityDataService, PublishedObjectsTypes } from "../community-data.service";

@Component({
  selector: "m-community-detail-view",
  templateUrl: "community-detail.view.html",
  styleUrls: ["community-detail.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class CommunityDetailView implements OnInit {
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;
  @ViewChild("spinner", { read: ElementRef }) set spinner(ref: ElementRef) {
    if (ref) {
      ref.nativeElement.style.background = "none";
    }
  }

  protected objectType = "images";
  protected objectId = "";

  protected objects: (GeneratedImage | BookCover)[] | undefined;
  protected previousObject: GeneratedImage | BookCover | undefined;
  protected nextObject: GeneratedImage | BookCover | undefined;

  // TODO need to think about it. The problem was in images, that not in store yet
  // object: PublishedObjectsDataSourceType | undefined;

  image: GeneratedImage | undefined;
  cover: BookCover | undefined;

  isLoading = false;
  isUnpublishModalVisible = false;
  unpublishCallback: (() => Promise<void>) | undefined;

  private sub = new Subscription();

  constructor(
    private readonly communityDataService: CommunityDataService,
    private readonly generatedImageService: GeneratedImageService,
    private readonly coverService: CoverService,
    private readonly route: ActivatedRoute,
    private readonly notificationService: NotificationsPopUpService,
    private readonly userStore: UserStore,
    private readonly routerService: RouterService,
    private readonly renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit() {
    this.sub.add(
      this.route.params.subscribe(async (params) => {
        if (params.objectType) {
          this.objectType = params.objectType;
        } else {
          this.objectType = "images";
        }
        if (params.objectId) {
          this.objectId = params.objectId;
        } else {
        }

        await this.initObject();
      }),
    );

    this.sub.add(
      this.communityDataService
        .getItems(this.objectType as PublishedObjectsTypes)
        .pipe(
          map((items) => {
            if (items && items.length > 0) {
              return items.filter((i) => i.isPublic);
            }
            return undefined;
          }),
        )
        .subscribe((items) => {
          this.objects = items;
          this.updateNextAndPreviousObjects();
        }),
    );
  }

  async initObject() {
    if (this.objectType === "images" && this.objectId) {
      this.image = await this.getGeneratedImage(Number(this.objectId));
    }
    if (this.objectType === "covers" && this.objectId) {
      this.cover = await this.getBookCover(Number(this.objectId));
    }
    this.updateNextAndPreviousObjects();

    this.cdr.detectChanges();
  }

  protected updateNextAndPreviousObjects() {
    if (!this.objects) {
      this.previousObject = undefined;
      this.nextObject = undefined;
      return;
    }

    const currentObjectIndex = this.objects.findIndex((o) => o.id === Number(this.objectId));
    this.previousObject = undefined;
    this.nextObject = this.objects[0] ?? undefined;
    if (currentObjectIndex >= 0) {
      this.previousObject = currentObjectIndex > 0 ? this.objects[currentObjectIndex - 1] : undefined;
      this.nextObject = currentObjectIndex < this.objects.length - 1 ? this.objects[currentObjectIndex + 1] : undefined;
    }
  }

  private showLoader(): void {
    this.isLoading = true;
  }

  async getGeneratedImage(id: number) {
    this.showLoader();
    let generatedImage: GeneratedImage | undefined = undefined;
    try {
      if (!this.userStore.getUser()) {
        generatedImage = await this.generatedImageService.getGeneratedImageByIdUnauthorized(id);
      } else {
        generatedImage = await this.generatedImageService.getGeneratedImageById(id);
      }
    } catch (error) {
      this.onErrorLoadGeneration();
      return;
    }

    if (!generatedImage || generatedImage.deletedAt) {
      this.onErrorLoadGeneration();
      return;
    }

    this.isLoading = false;
    return generatedImage;
  }

  async getBookCover(id: number) {
    this.showLoader();
    let cover: BookCover | undefined = undefined;
    try {
      if (!this.userStore.getUser()) {
        cover = await this.coverService.getPublishedCoverUnauthorized(id);
      } else {
        cover = await this.coverService.getPublishedCover(id);
      }
    } catch (error) {
      this.onErrorLoadGeneration();
      return;
    }

    if (!cover) {
      this.onErrorLoadGeneration();
      return;
    }

    this.isLoading = false;
    return cover;
  }

  async onUnpublishImage(image: GeneratedImage) {
    this.isUnpublishModalVisible = true;
    this.unpublishCallback = async () => {
      await this.generatedImageService.unpublishGeneratedImage(image.id);
    };
  }

  async onUnpublishCover(cover: BookCover) {
    this.isUnpublishModalVisible = true;
    this.unpublishCallback = async () => {
      await this.coverService.unpublishBookCover(cover.id);
    };
  }

  async onUnpublishModalConfirm() {
    await this.unpublishCallback!();
    this.notificationService.notify({ content: $localize`:@@home.community.unpublish-confirm-notification:` });
    this.isUnpublishModalVisible = false;
  }

  onUnpublishModalClose() {
    this.isUnpublishModalVisible = false;
  }

  private onErrorLoadGeneration() {
    this.notificationService.error($localize`:@@image-generation.generated-image.cant-load-image-error:`);
    this.isLoading = false;
  }

  protected onCloseClick() {
    this.routerService.showInModal(undefined);
  }

  protected onPreviousClick() {
    if (!this.previousObject) {
      return;
    }
    this.navigateToObjectId(this.previousObject.id);
  }

  protected onNextClick() {
    if (!this.nextObject) {
      return;
    }
    this.navigateToObjectId(this.nextObject.id);
  }

  private navigateToObjectId(objectId: number) {
    this.routerService.showInModal([this.objectType, "preview", objectId]);
  }

  async onLikeImageClick(image: GeneratedImage, isLiked: boolean) {
    if (image.isLiked && !isLiked) {
      await this.generatedImageService.unlikeGeneratedImage(image.id);
      this.communityDataService.getEventBus("images").next(image.id);
      return;
    }
    await this.generatedImageService.likeGeneratedImage(image.id);
    this.communityDataService.getEventBus("images").next(image.id);
  }

  async onLikeCoverClick(cover: BookCover, isLiked: boolean) {
    if (cover.isLiked && !isLiked) {
      await this.coverService.unlikeBookCover(cover.id);
      this.communityDataService.getEventBus("covers").next(cover.id);
      return;
    }
    await this.coverService.likeBookCover(cover.id);
    this.communityDataService.getEventBus("covers").next(cover.id);
  }

  @HostListener("window:keydown", ["$event"])
  protected handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.onCloseClick();
    }
    if (event.key === "ArrowLeft") {
      this.onPreviousClick();
    }
    if (event.key === "ArrowRight") {
      this.onNextClick();
    }
  }
}
