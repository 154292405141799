import { CommonModule, NgClass } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "m-new-mark",
  template: `
    <ng-content select="[text-left]"></ng-content>
    <span class="mark" i18n="@@new-mark">new-mark</span>
    <ng-content select="[text-right]"></ng-content>
  `,
  styleUrls: ["./new-mark.component.scss"],
  standalone: true,
  imports: [CommonModule, NgClass],
})
export class NewMarkComponent {}
