import { Injectable } from "@angular/core";
import { TokenStorageService } from "@metranpage/auth-interfaces";

const LocalStorageKey = "metranpage-token";

@Injectable({
  providedIn: "root",
})
export class TokenStorageServiceImpl implements TokenStorageService {
  private _token?: string;

  setToken(newToken: string | undefined) {
    this._token = newToken;
    localStorage.removeItem(LocalStorageKey);
    if (newToken) {
      localStorage.setItem(LocalStorageKey, newToken);
    }
  }

  getToken(): string | undefined {
    if (!this._token) {
      this._token = localStorage.getItem(LocalStorageKey) ?? undefined;
    }
    return this._token;
  }
}
