<m-modal-backdrop
  [closeOnBackDropClick]="closeOnBackDropClick"
  [closeButtonVisible]="closeButtonVisible"
  (close)="onCloseClick()"
>
  <div class="container">
    <m-ng-island class="sidebar">
      <div class="header">
        <span *ngIf="!activeCharacter || activeCharacter?.isProcessing" i18n="@@characters.new.title">
          characters.new.title
        </span>
        <span *ngIf="activeCharacter && !activeCharacter.isProcessing" i18n="@@characters.edit.title">
          characters.edit.title
        </span>
      </div>

      <div class="divider-hor"></div>

      <div *ngIf="!activeCharacter?.isProcessing; else processingCharacter" class="content-wrapper">
        <form *ngIf="form" [formGroup]="form" class="content">
          <m-sidebar-section-2 title="characters.new.name" i18n-title="@@characters.new.name" gapSize="4px">
            <m-ng-input formControlName="name" size="32" placeholder="" />
          </m-sidebar-section-2>

          <m-sidebar-section-2 class="gender-section" title="characters.new.type" i18n-title="@@characters.new.type">
            <div class="section-group-column">
              <m-select formControlName="type" size="s-plus" [options]="getTypeOptions()" />

              <m-ng-input *ngIf="isCustomTypeVisible" formControlName="customType" size="32" placeholder="" />
            </div>
          </m-sidebar-section-2>

          <div class="sections-group">
            <m-sidebar-section-2
              class="gender-section"
              title="characters.new.gender"
              i18n-title="@@characters.new.gender"
            >
              <m-select formControlName="gender" size="s-plus" [options]="getGenderOptions()" />
            </m-sidebar-section-2>

            <m-sidebar-section-2 class="age-section" title="characters.new.age" i18n-title="@@characters.new.age">
              <m-ng-input formControlName="age" size="32" />
            </m-sidebar-section-2>
          </div>

          <m-sidebar-section-2 title="characters.new.hair" i18n-title="@@characters.new.hair" [hasCustomAction]="true">
            <m-ng-input
              formControlName="hair"
              size="32"
              placeholder="characters.new.hair.placeholder"
              i18n-placeholder="@@characters.new.hair.placeholder"
            />

            <div action class="action-group">
              <m-switch formControlName="isBald">
                <span i18n="@@characters.new.bald">characters.new.bald</span>
              </m-switch>
            </div>
          </m-sidebar-section-2>

          <m-sidebar-section-2 title="characters.new.appearance" i18n-title="@@characters.new.appearance">
            <m-textarea
              formControlName="appearance"
              [rows]="3"
              placeholder="characters.new.appearance.placeholder"
              i18n-placeholder="@@characters.new.appearance.placeholder"
            />
          </m-sidebar-section-2>
        </form>

        <div *ngIf="!activeCharacter" class="buttons">
          <m-button
            button-style="primary"
            size="full-width"
            (onClick)="onCreateCharacterClick()"
            i18n="@@characters.new.create-button"
          >
            characters.new.create-button
          </m-button>
        </div>

        <div *ngIf="activeCharacter" class="buttons">
          <m-button
            button-style="secondary"
            size="full-width"
            (onClick)="onChangeCharacterClick()"
            i18n="@@characters.edit.save-button"
          >
            characters.edit.save-button
          </m-button>

          <m-button
            button-style="primary"
            size="full-width"
            (onClick)="onSelectCharacterClick()"
            i18n="@@characters.edit.select-button"
          >
            characters.edit.select-button
          </m-button>
        </div>
      </div>
    </m-ng-island>

    <m-ng-island class="characters-wrapper" [paddingSize]="0">
      <div class="characters">
        <div class="header">
          <span i18n="@@characters.list.title">characters.list.title</span>
        </div>

        <div class="divider-hor"></div>

        <div class="content-wrapper">
          <div class="content">
            <m-ng-scrollbars (onScroll)="onScroll()">
              <div class="character-cards">
                <m-character-card [isActive]="isActive()" (onAdd)="onAddClick()" />
                <m-character-card
                  *ngFor="let c of characters; trackBy: trackById"
                  #characters
                  [character]="c"
                  [isActive]="isActive(c)"
                  [isAdded]="isAdded(c)"
                  [isLimitReached]="isCharactersInPromptLimitReached()"
                  (onSelect)="onSelectClick(c)"
                  (onEdit)="onEditClick(c)"
                  (onCopy)="onCopyClick(c)"
                  (onDelete)="showDeleteModal(c)"
                />
              </div>
            </m-ng-scrollbars>
          </div>
        </div>

        <div class="divider-hor"></div>

        <div class="footer">
          <m-info-block type="warning" [labels]="getCharactersInfo()" [singleIcon]="true" />
        </div>
      </div>
    </m-ng-island>
  </div>
</m-modal-backdrop>

<ng-template #processingCharacter>
  <div class="processing">
    <div class="spinner-24"></div>
    <div class="timer-text" i18n="@@characters.generation.processing">characters.generation.processing</div>
  </div>
</ng-template>

<m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>

<m-character-delete-modal
  *ngIf="characterForDelete"
  [name]="characterForDelete.name"
  (onDelete)="onDeleteClick()"
  (onCancel)="hideDeleteModal()"
/>
