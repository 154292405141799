import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ImageLoadingProvider } from "@metranpage/markup-editor";
import { Api, BASE_URL } from "@metranpage/core-api";
import { UploadEditorImageResult } from "./book.service";

@Injectable()
export class DefaultImageLoadingProvider extends Api implements ImageLoadingProvider {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  getUrlForEditorImage(bookId: number, imagePath: string) {
    if (imagePath.startsWith("images/")) {
      return this.absoluteUrl(`books/files/${bookId}/${imagePath}`);
    }
    // not our image
    return imagePath;
  }

  async uploadEditorImage(bookId: number, file: File, imageIndex: number): Promise<UploadEditorImageResult> {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    formData.append("imageIndex", `${imageIndex}`);

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    try {
      const response = await this.post<any>(`books/files/${bookId}/images`, formData, {
        headers,
      });
      return { status: "success", response };
    } catch (error: any) {
      console.error(error);

      return { status: "error" };
    }
  }
}
