<form *ngIf="formColorPicker" [formGroup]="formColorPicker">
  <div class="container">
    <div class="sidebar-title">
      <m-icon src="pencil.svg" size="s" color="--color-font-main" />
      <span class="" i18n="@@books.markup.sidebar.colors-styles-title">books.markup.sidebar.colors-styles-title</span>
    </div>

    <div [class.locked]="!isSettingsAvailable">
      <m-color-picker formControlName="colorPicker" />

      <div class="section-divider"></div>

      <div class="description">
        <span>{{ selectedColorDescription }}</span>
      </div>

      <div class="colors">
        <div *ngFor="let color of getColorNames()" [ngClass]="getCssClassList(color)" (click)="selectColor(color)">
          <div class="color-preview" [ngStyle]="getColorPreviewStyle(color)"></div>
          <span class="color-title">{{ getColorTitle(color) }}</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isSettingsAvailable" class="buttons">
    <m-button
      button-style="secondary"
      size="full-width"
      (onClick)="onCancelClick()"
      i18n="@@books.markup.style-details.close"
    >
      books.markup.style-details.close
    </m-button>
  </div>
  <div *ngIf="isSettingsAvailable" class="buttons">
    <m-button
      (onClick)="onCancelClick()"
      button-style="secondary"
      size="full-width"
      i18n="@@books.markup.style-details.cancel"
    >
      books.markup.style-details.cancel
    </m-button>

    <m-button
      (onClick)="onApplyClick()"
      button-style="primary"
      size="full-width"
      i18n="@@books.markup.style-details.apply"
    >
      books.markup.style-details.apply
    </m-button>
  </div>
</form>
