import { Injectable } from "@angular/core";
import { EditorBlock, EditorBlockError, EditorDataItem, EditorErrors } from "../views/markup-editor/editor.models";

const tocMarkers = ["toc", "table of contents", "table of content", "содержание", "оглавление"];

@Injectable({
  providedIn: "root",
})
export class EditorDataValidationService {
  validate(data: EditorBlock[]) {
    const result: EditorErrors = {};
    for (const block of data) {
      const errors = this.validateBlock(block);

      result[block.id] = errors;
    }

    return result;
  }

  fixError(items: EditorDataItem[], error: EditorBlockError): EditorDataItem[] {
    let result: EditorDataItem[] = [];
    if (error.type === "toc") {
      result = items.filter((items) => {
        if (items.errors) {
          for (const error of items.errors) {
            if (error.type === "toc") {
              return false;
            }
          }
        }
        return true;
      });
    } else {
      return items;
    }
    return result;
  }

  private validateBlock(block: EditorBlock): EditorBlockError[] {
    const errors: EditorBlockError[] = [];

    const text = block.data.text as string | undefined;
    if (text) {
      const isToc = this.checkToc(text);

      if (isToc) {
        errors.push({
          message: $localize`:@@editor.errors.toc:`,
          type: "toc",
          action: "delete",
          actionText: $localize`:@@editor.errors.action_delete:`,
        });
      }
    }

    // if (block.type == 'table') {
    //   let isTableCellLengthErrorAdded = false;

    //   const data: TableData = block.data;
    //   for (const row of data.rows) {
    //     if (isTableCellLengthErrorAdded) {
    //       break;
    //     }

    //     for (const cell of row) {
    //       let html = '';
    //       if (!cell.items) {
    //         continue;
    //       }

    //       for (const item of cell.items) {
    //         html = html + item.content;
    //       }
    //       const div = document.createElement('div');
    //       div.innerHTML = html;
    //       let text = div.innerText.trim();

    //       if (text.length > CELL_LENGTH_LIMIT) {
    //         errors.push({
    //           message: $localize`:@@editor.errors.table-cell-length:`,
    //           type: 'table-cell',
    //           action: 'nothing',
    //           actionText: '',
    //         });
    //         isTableCellLengthErrorAdded = true;
    //         break;
    //       }
    //     }
    //   }
    // }

    return errors;
  }

  private checkToc(text: string): boolean {
    const tocEndingRegexp = /[\.…]{5,}\s?\d+$/g;

    const clearedText = text.toLowerCase().trim().replace(/[.:]/gi, "");
    if (tocMarkers.includes(clearedText)) {
      return true;
    }

    if (tocEndingRegexp.test(text)) {
      return true;
    }
    return false;
  }
}
