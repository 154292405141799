<m-icon [src]="imageAsset" size="original" />
<span class="tab-ebook-info" i18n="@@books.temp-ebook-not-work-yet">books.temp-ebook-not-work-yet</span>

<!-- <span class="tab-ebook-info" i18n="@@books.margins.sidebar-tabs.ebook.info">books.margins.sidebar-tabs.ebook.info</span>
<m-button
  class="button-layout"
  button-style="primary"
  (onClick)="onLayoutEpubClick()"
  i18n="@@books.build.produce-button"
>
  books.build.produce-button
</m-button>
<span class="tab-ebook-price">{{ pluralizeChargeOff() }}</span> -->
