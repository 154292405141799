import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SelectValue } from "@metranpage/components";
import { PricingViewService } from "@metranpage/pricing";
import * as _ from "lodash-es";
import { Tariff, TariffCreateData } from "../../models/tariff";
import { AdminTariffsApi, TariffsFilter } from "./tariffs.api";
import { AdminTariffsStore } from "./tariffs.store";
import { GeneralResultStatus } from "@metranpage/core-data";

@Injectable({
  providedIn: "root",
})
export class AdminTariffsService {
  constructor(
    private readonly adminTariffsApi: AdminTariffsApi,
    private readonly adminTariffsStore: AdminTariffsStore,
    private readonly pricingViewService: PricingViewService,
  ) {}

  async loadTariff(tariffId: number) {
    const tariffData = await this.adminTariffsApi.getTariff(tariffId);
    this.adminTariffsStore.setActiveTariff(tariffData.tariff);
  }

  async loadTariffs(): Promise<Tariff[]> {
    return this.adminTariffsApi.getTariffs();
  }

  async loadTariffsPaginated(page: number, filters: TariffsFilter = {}) {
    const data = await this.adminTariffsApi.getTariffsPaginated(page, filters);
    this.adminTariffsStore.setTariffs(data.items);
    this.adminTariffsStore.setTariffsPageCount(data.pageCount);
  }

  async updateTariff(tariffId: number, data: TariffCreateData): Promise<GeneralResultStatus> {
    try {
      await this.adminTariffsApi.updateTariff(tariffId, data);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async createTariff(data: TariffCreateData): Promise<{ status: GeneralResultStatus; id?: number }> {
    try {
      const newTariff = await this.adminTariffsApi.createTariff(data);
      return {
        status: "success",
        id: newTariff.id,
      };
    } catch (error: any) {
      return {
        status: "error",
        id: undefined,
      };
    }
  }

  async copyTariff(tariffId: number): Promise<{ status: GeneralResultStatus; id?: number }> {
    try {
      const result = await this.adminTariffsApi.copyTariff(tariffId);
      return {
        status: "success",
        id: result.id,
      };
    } catch (error: any) {
      return {
        status: "error",
        id: undefined,
      };
    }
  }

  async deleteTariff(tariffId: number): Promise<{ status: GeneralResultStatus }> {
    try {
      const result = await this.adminTariffsApi.deleteTariff(tariffId);
      return {
        status: "success",
      };
    } catch (error: any) {
      return {
        status: "error",
      };
    }
  }

  createTariffForm() {
    return new FormGroup({
      title: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
      subtitle: new FormControl("start", { nonNullable: true, validators: [Validators.required] }),
      price: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
      isFree: new FormControl(false, { nonNullable: true, validators: [Validators.required] }),
      profit: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
      currency: new FormControl("₽", { nonNullable: true, validators: [Validators.required] }),
      period: new FormControl(1, { nonNullable: true, validators: [Validators.required] }),
      creditsPerMonth: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
      goldCreditsPerMonth: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
      activeProjects: new FormControl(1, { nonNullable: true, validators: [Validators.required] }),
      giftPeriod: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
      trialPeriodInDay: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
      forLegalEntity: new FormControl(false, { nonNullable: true, validators: [Validators.required] }),
      fileLimitMb: new FormControl(10000, { nonNullable: true, validators: [Validators.required] }),
      level: new FormControl(1, { nonNullable: true, validators: [Validators.required] }),
      creditPrice: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
      isUnlimited: new FormControl(false, { nonNullable: true, validators: [Validators.required] }),
    });
  }

  getOptionsForTariffsSelect(tariffs: Tariff[]) {
    const options: SelectValue[] = [];
    options.push({ id: 0, value: $localize`:@@admin.promocodes.promocode.select-tariff:` });
    for (const tariff of tariffs) {
      if (tariff.period > 1) {
        continue;
      }
      options.push({
        id: tariff.id,
        value: `${tariff.title} - ${tariff.period} ${this.pricingViewService.pluralizeMonths(tariff.period)}`,
      });
    }
    return _.sortBy(options, ["id"]);
  }
}
