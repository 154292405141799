<div class="input-container" [ngClass]="getCssClassList()">
  <svg-icon *ngIf="iconLeft" [src]="getLeftIconAsset()!" class="icon icon-left"></svg-icon>

  <div *ngIf="type === 'with-prefix' && prefix" class="prefix">
    <div>{{ prefix }}</div>
    <div class="divider"></div>
  </div>

  <input
    [placeholder]="placeholder"
    [name]="name"
    [autocomplete]="getAutocomplete()"
    [required]="isRequired"
    [tabindex]="tabIndex"
    [type]="getInputType()"
    (focus)="onInputFocus()"
    (blur)="onInputBlur()"
    (input)="onInputChange($event)"
    (keydown)="onKeyDown($event)"
    #input
  />

  <ng-template [ngIf]="iconRight">
    <svg-icon
      [src]="getRightIconAsset()!"
      class="icon icon-right"
      [ngClass]="getRightIconClassList()"
      (click)="onRightIconClick()"
    ></svg-icon>
  </ng-template>
</div>
