import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { ReferalLink, ReferalLinkWithAnalitics } from "../../models/referal-link";

export type State = {
  referalLinks: ReferalLinkWithAnalitics[];
  referalLinksPageCount: number;
  activeReferalLink: ReferalLink | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminReferalLinksStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      referalLinks: [],
      referalLinksPageCount: 1,
      activeReferalLink: undefined,
    };
  }

  getReferalLinksObservable() {
    return this.getFieldObservable("referalLinks");
  }

  setReferalLinks(referalLinks: ReferalLinkWithAnalitics[]) {
    this.update((state) => ({ ...state, referalLinks }));
  }

  getReferalLinksPageCountObservable() {
    return this.getFieldObservable("referalLinksPageCount");
  }

  setReferalLinksPageCount(count: number) {
    this.update((state) => ({ ...state, referalLinksPageCount: count }));
  }

  setActiveReferalLink(referalLink: ReferalLink) {
    this.update((state) => ({ ...state, activeReferalLink: referalLink }));
  }

  getActiveReferalLinkObservable() {
    return this.getFieldObservable("activeReferalLink");
  }
}
