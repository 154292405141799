<m-account-notification-card-host [notification]="notification" [isLast]="isLast">
  <div icon class="icons-wrapper">
    <m-ng-icon class="icon-main" [size]="24" src="notification-info.svg" />
  </div>

  <div title class="title-group">
    <div class="title">
      <span i18n="@@user.profile.notifications.card.like.title">user.profile.notifications.card.like.title</span>
    </div>

    <!-- <div class="card-label" i18n="@@user.profile.notifications.filter.important">
      user.profile.notifications.filter.important
    </div> -->
  </div>

  <div description class="description">
    <div *ngIf="payload.objectType === 'image'" class="row">
      <span i18n="@@user.profile.notifications.card.like.image.description-1">
        user.profile.notifications.card.like.image.description-1
      </span>
      <span class="action" i18n="@@user.profile.notifications.card.like.image.action" (click)="openObjectImage()">
        user.profile.notifications.card.like.image.action
      </span>
      <span i18n="@@user.profile.notifications.card.like.image.description-2">
        user.profile.notifications.card.like.image.description-2
      </span>
    </div>

    <div *ngIf="payload.objectType === 'cover'" class="row">
      <span i18n="@@user.profile.notifications.card.like.cover.description-1">
        user.profile.notifications.card.like.cover.description-1
      </span>
      <span class="action" i18n="@@user.profile.notifications.card.like.cover.action" (click)="openObjectImage()">
        user.profile.notifications.card.like.cover.action
      </span>
      <span i18n="@@user.profile.notifications.card.like.cover.description-2">
        user.profile.notifications.card.like.cover.description-2
      </span>
    </div>
  </div>
</m-account-notification-card-host>
