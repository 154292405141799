import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL, COMPANY_UUID, DOWNLOAD_FILE_TEMPLATE } from "@metranpage/core-api";
import { Character, CharacterDataDto, CharactersData } from "../models/character/character";

@Injectable({
  providedIn: "root",
})
export class CharacterApi extends Api {
  constructor(
    http: HttpClient,
    @Inject(BASE_URL) baseUrl: string,
    @Inject(DOWNLOAD_FILE_TEMPLATE) private readonly downloadFileTemplate: string,
    @Inject(COMPANY_UUID) private companyUuid: string,
  ) {
    super(http, baseUrl);
  }

  async createCharacter(data: CharacterDataDto) {
    return this.post("character", data);
  }

  async changeCharacter(id: number, data: CharacterDataDto) {
    return this.post(`character/change/${id}`, data);
  }

  async getCharacterById(id: number) {
    return this.get<Character | undefined>(`character/${id}`);
  }

  async loadCharacters() {
    return this.get<Character[]>("character/all");
  }

  async loadCharactersPaginated(page: number) {
    return this.get<CharactersData>("character/paginated", {
      page,
    });
  }

  async copyCharacter(id: number) {
    return this.post(`character/copy/${id}`, {});
  }

  async deleteCharacter(id: number) {
    return this.delete(`character/${id}`);
  }
}
