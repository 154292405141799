import { Injectable } from "@angular/core";
import { v4 as uuidv4 } from "uuid";
import { ListType, StyleKey, StyleSettings } from "../models/styles";
import { EditorBlock, EditorBlockError, EditorData, EditorDataItem } from "../views/markup-editor/editor.models";
import { EditorStore } from "../views/markup-editor/editor.store";
import { EditorDataValidationService } from "./editor-data-validation.service";

@Injectable({
  providedIn: "root",
})
export class MarkupService {
  constructor(
    private validationService: EditorDataValidationService,
    private readonly editorStore: EditorStore,
  ) {}

  prepareEditorData(data: EditorData) {
    const errors = this.validationService.validate(data.blocks);
    // this.setupDataHolder(data.blocks, errors);
    const result: EditorDataItem[] = [];
    for (const block of data.blocks) {
      let blockErrors: EditorBlockError[] = [];
      blockErrors = errors[block.id] || [];

      if (!block.id) {
        block.id = uuidv4();
      }

      result.push({
        block: block,
        errors: blockErrors,
      });
    }

    return result;
  }

  updateStyleDisplayOpts(style: StyleKey, opts: StyleSettings) {
    this.editorStore.setStyleDisplayOpts(style, opts);
  }

  createBlock(styleKey: StyleKey, items: EditorDataItem[], blockData?: any) {
    let newBlock: EditorBlock | undefined = undefined;
    if (styleKey === "image") {
      newBlock = this.createEmptyImageBlock(items);
    } else if (styleKey === "table") {
      newBlock = this.createEmptyTable();
    } else if (styleKey === "list") {
      newBlock = this.createEmptyList(blockData);
    } else if (styleKey === "page-break") {
      newBlock = this.createPageBreak();
    } else {
      newBlock = this.createTextBlockWithStyleAndData(styleKey, blockData);
    }
    return newBlock!;
  }

  private createTextBlockWithStyleAndData(styleKey: StyleKey, blockData?: any): EditorBlock {
    return {
      id: uuidv4(),
      style: styleKey,
      data: {
        text: "",
        ...blockData,
      },
    };
  }

  private createPageBreak(): EditorBlock {
    return {
      id: uuidv4(),
      style: "page-break",
      data: {},
    };
  }

  private createEmptyList(data: { style: ListType }): EditorBlock {
    return {
      id: uuidv4(),
      style: "list",
      data: {
        items: [
          {
            content: "",
            level: 0,
            style: data.style,
            items: [],
          },
        ],
        style: data.style,
        level: -1,
        startIndex: 0,
        endIndex: 3,
        content: "",
      },
    };
  }

  private createEmptyTable(): EditorBlock {
    return {
      id: uuidv4(),
      style: "table",
      data: {
        id: 0,
        startIndex: 0,
        startId: 0,
        endIndex: 16,
        endId: 15,
        rowsCount: 4,
        colsCount: 4,
        rows: [
          [
            {
              row: 0,
              col: 0,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
            {
              row: 0,
              col: 1,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
            {
              row: 0,
              col: 2,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
            {
              row: 0,
              col: 3,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
          ],
          [
            {
              row: 1,
              col: 0,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
            {
              row: 1,
              col: 1,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
            {
              row: 1,
              col: 2,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
            {
              row: 1,
              col: 3,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
          ],
          [
            {
              row: 2,
              col: 0,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
            {
              row: 2,
              col: 1,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
            {
              row: 2,
              col: 2,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
            {
              row: 2,
              col: 3,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
          ],
          [
            {
              row: 3,
              col: 0,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
            {
              row: 3,
              col: 1,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
            {
              row: 3,
              col: 2,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
            {
              row: 3,
              col: 3,
              rowspan: 1,
              colspan: 1,
              style: "",
              items: [
                {
                  content: "",
                },
              ],
            },
          ],
        ],
      },
    };
  }

  private createEmptyImageBlock(items: EditorDataItem[]): EditorBlock {
    return {
      id: uuidv4(),
      style: "image",
      data: {
        imageIndex: this.findLastImageIndex(items) + 1,
      },
    };
  }

  private findLastImageIndex(items: EditorDataItem[]): number {
    let index = 0;
    let emptyImagesBlock = 0; // if user add several empty block, we should count this blocks as ids too
    for (const blockData of items) {
      const block = blockData.block;
      if (block.style === "image") {
        if (block.data.url) {
          const parts = block.data.url.split("/");
          const imageName = parts[parts.length - 1];
          const match = /^image(?<id>\d+)/g.exec(imageName);
          if (match?.groups?.id) {
            const id = Number.parseInt(match?.groups?.id);
            if (id > index) {
              index = id;
            }
          }
        } else {
          emptyImagesBlock++;
        }
      }
    }
    return index + emptyImagesBlock;
  }
}
