<div class="wrapper">
  <ng-container *ngIf="solidFill && color">
    <div class="colors-wrapper">
      <m-cover-color-select
        [color]="color"
        [popupPosition]="popupPosition"
        [useColorPickerExternalHost]="useColorPickerExternalHost"
        (update)="updateSolidFill($event)"
      ></m-cover-color-select>
      <div class="color-add" *ngIf="isGradientAvailable" (click)="toGradient()">
        <m-ng-icon [size]="18" src="/assets/icons/add-02.svg"></m-ng-icon>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="gradientFill && gradient">
    <m-cover-object-gradient
      [gradient]="gradient"
      [popupPosition]="popupPosition"
      [useColorPickerExternalHost]="useColorPickerExternalHost"
      (update)="updateGradientFill($event)"
    ></m-cover-object-gradient>
  </ng-container>
</div>
