import { Injectable } from "@angular/core";
import {
  CoverSnippet,
  CoverSnippetCategory,
  CoverSnippetCategoryObjects,
  CoverSnippetCreateDataDto,
  CoverSnippetSubcategory,
  CoverSnippetSubcategoryObjects,
} from "@metranpage/book-data";
import { AnalyticsService } from "@metranpage/core";
import { plainToInstance } from "class-transformer";
import * as _ from "lodash-es";
import { CoverSnippetDataService } from "./cover-snippet-data.service";
import { CoverSnippetApi } from "./cover-snippet.api";
import { CoverSnippetStore } from "./cover-snippet.store";

@Injectable({
  providedIn: "root",
})
export class CoverSnippetService {
  constructor(
    private readonly coverSnippetDataService: CoverSnippetDataService,
    private readonly coverSnippetApi: CoverSnippetApi,
    private readonly coverSnippetStore: CoverSnippetStore,
    private readonly analytics: AnalyticsService,
  ) {}

  async getCoverSnippets(): Promise<CoverSnippet[]> {
    const coverSnippets = await this.coverSnippetApi.getCoverSnippets();
    const processedCoverSnippets = coverSnippets.map((cs) => plainToInstance(CoverSnippet, cs));
    return processedCoverSnippets;
  }

  async loadCoverSnippets() {
    const coverSnippets = await this.getCoverSnippets();
    this.coverSnippetStore.setCoverSnippets(coverSnippets);
  }

  async createCoverSnippet(data: CoverSnippetCreateDataDto, file: File) {
    try {
      const coverSnippet = await this.coverSnippetApi.createCoverSnippet(data, file);
      const coverSnippetObject = plainToInstance(CoverSnippet, coverSnippet);
      this.coverSnippetStore.addCoverSnippet(coverSnippetObject);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async applyCoverSnippet(coverSnippetId: number, bookId: number) {
    try {
      await this.coverSnippetApi.applyCoverSnippet(coverSnippetId, bookId);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  sortByCategory(coverSnippets: CoverSnippet[]): CoverSnippetCategoryObjects[] {
    const grouped: CoverSnippetCategoryObjects[] = [];

    const groupedByCategory = _.groupBy(coverSnippets, "category");
    for (const [cKey, value] of Object.entries(groupedByCategory)) {
      const groupedBySubcategory = _.groupBy(value, "subcategory");
      const items: CoverSnippetSubcategoryObjects[] = [];
      for (const [scKey, value] of Object.entries(groupedBySubcategory)) {
        const subcategory = scKey as CoverSnippetSubcategory;
        const orderedSnippets = _.orderBy(value, ["order"], ["desc"]);
        items.push({
          subcategory: subcategory,
          snippets: orderedSnippets,
        });
      }
      const category = cKey as CoverSnippetCategory;
      const orderedSubcategories = _.orderBy(
        items,
        [
          (i) => {
            return this.coverSnippetDataService.getCoverSnippetSubcategoryOrder(i.subcategory);
          },
        ],
        ["asc"],
      );
      grouped.push({
        category: category,
        items: orderedSubcategories,
      });
    }

    // grouped = this.addDefaultCategories(grouped);
    const orderedCategories = _.orderBy(
      grouped,
      [
        (i) => {
          return this.coverSnippetDataService.getCoverSnippetCategoryOrder(i.category);
        },
      ],
      ["asc"],
    );

    return orderedCategories;
  }

  // addDefaultCategories(coverSnippetCategoryObjects: CoverSnippetCategoryObjects[]): CoverSnippetCategoryObjects[] {
  //   const textCategory = coverSnippetCategoryObjects.find((cso) => cso.category === "text");
  //   if (!textCategory) {
  //     coverSnippetCategoryObjects.push({ category: "text", items: [{ subcategory: "headersAndText", snippets: [] }] });
  //   }
  //   return coverSnippetCategoryObjects;
  // }

  getUrlForPreviewImage(coverSnippet: CoverSnippet) {
    return this.coverSnippetApi.getUrlForFile(coverSnippet.file);
  }

  getUrlForPreviewImageByFileVid(vid: string) {
    return this.coverSnippetApi.getUrlForFile({ vid });
  }
}
