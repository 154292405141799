import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'm-superadmin-company-settings-nav-view',
  template: `
    <m-admin-menu-item-component
      [routerLink]="['/superadmin/companies', companyId, 'settings']"
      routerLinkActive="active"
      title="superadmin.companies-settings.common"
      i18n-title="@@superadmin.companies-settings.common"
      icon="/assets/icons/admin-nav-company.svg"
    ></m-admin-menu-item-component>

    <m-admin-menu-item-component
      [routerLink]="['/superadmin/companies', companyId, 'admins']"
      routerLinkActive="active"
      title="superadmin.companies-settings.admins"
      i18n-title="@@superadmin.companies-settings.admins"
      icon="/assets/icons/account-nav-user.svg"
    ></m-admin-menu-item-component>

    <m-admin-menu-item-component
      [routerLink]="['/superadmin/companies', companyId, 'accounts']"
      routerLinkActive="active"
      title="superadmin.companies-settings.account"
      i18n-title="@@superadmin.companies-settings.account"
      icon="/assets/icons/account-nav-user.svg"
    ></m-admin-menu-item-component>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        width: 240px;

        border-right: 1px solid var(--color-border-elevated);
        padding-right: 16px;
        margin-right: 16px;
      }
    `,
  ],
})
export class SuperadminComanySettingsNavView {
  private destroyed$ = new Subject<void>();
  protected companyId = 0;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if ('companyId' in this.route.snapshot.params) {
      this.companyId = Number(this.route.snapshot.params.companyId);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
