import { Injectable } from '@angular/core';
import { MaintenanceApi } from './maintenance.api';
import { MaintenanceStore } from './maintenance.store';
import { debounceTime, switchMap, tap, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  constructor(
    private readonly api: MaintenanceApi,
    private readonly store: MaintenanceStore,
    private readonly router: Router,
  ) {}

  startStatusUpdating() {
    timer(500, 60000)
      .pipe(
        // 1 min
        switchMap(() => this.api.loadStatus()),
        tap(({ isMaintenanceInProgress }) => this.store.updateMaintenanceStatus(isMaintenanceInProgress)),
        debounceTime(100),
        tap(() => this.handleActiveRoute()),
      )
      .subscribe();
  }

  private async handleActiveRoute() {
    const url = this.router.url
    const isMaintenanceInProgress = this.store.getStatus();
    if (isMaintenanceInProgress && !url.includes('/maintenance')) {
      await this.router.navigate(['/maintenance']);
      return;
    }
    if (!isMaintenanceInProgress && url.includes('/maintenance')) {
      await this.router.navigate(['/']);
      return;
    }
  }
}
