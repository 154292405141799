<overlay-scrollbars #overlayScrollbarsElement [options]="options">
  <div
    class="items"
    infiniteScroll
    [ngStyle]="{ padding: padding }"
    [scrollWindow]="false"
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [fromRoot]="true"
    [infiniteScrollContainer]="scrollEventElement"
    (scrolled)="onScrollEvent()"
  >
    <ng-content></ng-content>
  </div>
</overlay-scrollbars>
