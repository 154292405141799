<div class="container">
  <div class="arrow-zone">
    <div *ngIf="hasPrevious" class="arrow-button-previous" (click)="onPreviousClick()">
      <m-ng-icon src="button-arrow-right.svg" [size]="24" />
    </div>
  </div>

  <div *ngIf="previewImage" class="preview">
    <img [src]="getUrlForImage(previewImage)" class="preview-image" />
  </div>

  <div class="arrow-zone">
    <div *ngIf="hasNext" class="arrow-button-next" (click)="onNextClick()">
      <m-ng-icon src="button-arrow-right.svg" [size]="24" />
    </div>
  </div>
</div>
