import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { ImageGenerationUserStyle } from "../../models/image-generation-user-style/image-generation-user-style";

export type ImageGenerationUserStyleState = {
  userStyle: ImageGenerationUserStyle | undefined;
};

@Injectable({
  providedIn: "root",
})
export class ImageGenerationUserStyleStore extends Store<ImageGenerationUserStyleState> {
  protected override getInitialState(): ImageGenerationUserStyleState {
    return {
      userStyle: undefined,
    };
  }

  getUserStyleObservable() {
    return this.getFieldObservable("userStyle");
  }

  getUserStyle() {
    return this.getField("userStyle");
  }

  setUserStyle(userStyle: ImageGenerationUserStyle | undefined) {
    this.update((state) => ({ ...state, userStyle }));
  }
}
