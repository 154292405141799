import { CommonModule, NgClass } from '@angular/common';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AngularSvgIconModule } from 'angular-svg-icon';

@UntilDestroy()
@Component({
  selector: 'm-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss'],
  standalone: true,
  imports: [CommonModule, NgClass, ReactiveFormsModule, AngularSvgIconModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeComponent),
      multi: true,
    },
  ],
})
export class RangeComponent implements ControlValueAccessor, OnInit {
  @Input()
  name = '';
  @Input()
  min = 0;
  @Input()
  max = 100;
  @Input()
  value = 0;
  @Input()
  step = 1;
  @Input()
  leftLabel = '0';
  @Input()
  rightLabel = '100';
  @Input()
  isDotted = true;

  @ViewChild('input', { static: true, read: ElementRef })
  protected inputElementRef?: ElementRef;

  protected isDisabled = false;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  constructor(private renderer: Renderer2) {}

  ngOnInit() {}

  writeValue(value: number): void {
    this.value = value;
    this.renderer.setProperty(this.inputElementRef?.nativeElement, 'value', this.value);
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onInputChange(event: Event) {
    this.value = parseInt((event.target! as HTMLInputElement).value);
    this.onChange(this.value);
  }

  getDotStyle() {
    const width = document.querySelector('div.range-dots')?.getBoundingClientRect().width;
    if (!width) {
      return {};
    }

    const dotWidht = 6;
    const dotCount = this.max - this.min;
    const gap = (width - dotWidht * (dotCount + 1)) / dotCount;
    return { 'column-gap': `${gap}px` };
  }

  getCountDots() {
    const arr = [];
    for (let i = this.min; i <= this.max; i++) {
      arr.push(i);
    }
    return arr;
  }

  onClickDot(value: number) {
    this.value = value;
    this.renderer.setProperty(this.inputElementRef?.nativeElement, 'value', this.value);
    this.onChange(this.value);
  }
}
