import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { RewardNotificationsData } from "../models/rewards/reward-notification";

@Injectable({
  providedIn: "root",
})
export class RewardNotificationApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getUnreadRewardsCount() {
    return this.get<number>("reward-notifications/unread-count");
  }

  async loadRewardPaginated(page: number) {
    return this.get<RewardNotificationsData>("reward-notifications/paginated", {
      page,
    });
  }

  async markRewardsAsRead() {
    return this.post<RewardNotificationsData>("reward-notifications/mark-read", {});
  }
}
