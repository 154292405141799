import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { Subject } from "rxjs";
import { SuperadminAuthService } from "../../services/auth.service";

@Component({
  selector: "m-superadmin-auth",
  templateUrl: "./auth.page.html",
  styleUrls: ["./auth.page.scss"],
})
export class SuperadminAuthPage implements OnInit {
  private destroyed$ = new Subject<void>();

  protected form = new FormGroup({
    email: new FormControl("", { nonNullable: true, validators: [Validators.email, Validators.required] }),
    password: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
  });

  constructor(
    private readonly authService: SuperadminAuthService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  protected async login() {
    const email = this.form.value.email!;
    const password = this.form.value.password!;

    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.authService.login(email, password);
    this.loadingService.stopLoading();
    if (result === "success") {
      this.router.navigate(["/superadmin"]);
    } else {
      this.notificationService.error($localize`:@@superadmin.auth.auth-error:`);
    }
  }

  protected onKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.login();
    }
  }
}
