import { Injectable } from "@angular/core";
import { ActivationEnd, Router } from "@angular/router";
import { GeneralResultStatus } from "@metranpage/core-data";
import { CoverSnippet, CoverSnippetUpdateData } from "../../models/cover-snippet/cover-snippet";
import { AdminCoverSnippetsApi, CoverSnippetsFilter } from "./cover-snippets.api";
import { AdminCoverSnippetsStore } from "./cover-snippets.store";

@Injectable({
  providedIn: "root",
})
export class AdminCoverSnippetsService {
  constructor(
    private readonly adminCoverSnippetsApi: AdminCoverSnippetsApi,
    private readonly adminCoverSnippetsStore: AdminCoverSnippetsStore,
    private readonly router: Router,
  ) {
    this.watchRouteChanges();
  }

  async loadCoverSnippetsPaginated(page: number, filters: CoverSnippetsFilter = {}) {
    const coverSnippetsData = await this.adminCoverSnippetsApi.getCoverSnippetsPaginated(page, filters);
    this.adminCoverSnippetsStore.setCoverSnippets(coverSnippetsData.items);
    this.adminCoverSnippetsStore.setCoverSnippetsPageCount(coverSnippetsData.pageCount);
  }

  async loadCoverSnippet(coverSnippetId: number) {
    const coverSnippetsData = await this.adminCoverSnippetsApi.getCoverSnippet(coverSnippetId);
    this.adminCoverSnippetsStore.setActiveCoverSnippet(coverSnippetsData.coverSnippet);
  }

  async updateCoverSnippet(id: number, data: CoverSnippetUpdateData): Promise<GeneralResultStatus> {
    try {
      await this.adminCoverSnippetsApi.updateCoverSnippet(id, data);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  async deleteCoverSnippet(coverSnippetId: number): Promise<GeneralResultStatus> {
    try {
      await this.adminCoverSnippetsApi.deleteCoverSnippet(coverSnippetId);
      return "success";
    } catch (error: any) {
      return "error";
    }
  }

  private watchRouteChanges() {
    this.router.events.subscribe((data) => {
      if (data instanceof ActivationEnd) {
        if ("coverSnippetId" in data.snapshot.params) {
          const coverSnippetId = Number.parseInt(data.snapshot.params["coverSnippetId"]);
          this.loadCoverSnippet(coverSnippetId);
        }
      }
    });
  }

  async saveCoverSnippetsOrder(coverSnippets: CoverSnippet[]): Promise<GeneralResultStatus> {
    try {
      await this.adminCoverSnippetsApi.saveCoverSnippetsOrder(coverSnippets);
      return "success";
    } catch (error: any) {
      console.error(error);

      return "error";
    }
  }

  getUrlForPreviewImageByFileVid(vid: string) {
    return this.adminCoverSnippetsApi.getUrlForFile({ vid });
  }
}
