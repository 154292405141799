<div class="footnote-number">{{ footnote.id }}</div>
<m-base-text-editor
  #textEditor
  [(ngModel)]="text"
  (onBlur)="onEditorBlur()"
/>
<m-icon
  src="markup-footnote-delete.svg"
  color="--accent"
  size="xs"
  (click)="onRemoveClick()"
/>
