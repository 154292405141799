<m-modal [closeButtonVisible]="true" [closeOnBackDropClick]="true" (close)="onCloseClick()">
  <div class="container">
    <img src="/assets/img/gift.svg" />

    <form *ngIf="promocodeResult?.status !== 'success'" class="content" [formGroup]="form">
      <div class="header">
        <div class="title" i18n="@@pricing.promocode.title">pricing.promocode.title</div>
        <m-localized-text
          class="description"
          text="pricing.promocode.description"
          i18n-text="@@pricing.promocode.description"
        />
      </div>
      <div class="controls">
        <m-ng-input
          formControlName="promocode"
          text-align="center"
          placeholder="pricing.promocode.enter-promocode"
          i18n-placeholder="@@pricing.promocode.enter-promocode"
          (keydown.enter)="onEnterPromocodeClick()"
        />

        <m-button
          button-style="primary"
          size="full-width"
          (onClick)="onEnterPromocodeClick()"
          i18n="@@pricing.promocode.activate-button"
        >
          pricing.promocode.activate-button
        </m-button>
      </div>
    </form>

    <div *ngIf="promocodeResult?.status === 'success'" class="content">
      <div class="header">
        <div class="title" i18n="@@pricing.promocode.activation.success">pricing.promocode.activation.success</div>
        <m-localized-text
          *ngIf="promocodeResult?.description"
          class="description"
          [text]="promocodeResult?.description"
        />
      </div>
      <div class="controls">
        <m-button
          button-style="primary"
          size="full-width"
          (onClick)="onCloseClick()"
          i18n="@@pricing.promocode.activation.confirm"
        >
          pricing.promocode.activation.confirm
        </m-button>
      </div>
    </div>
  </div>
</m-modal>
