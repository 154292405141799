import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MarkupPreviewService } from "../../../../services/preview.service";
import { TextEditor } from "../../../text-editor/text-editor/text-editor.view";
import { BlockMergeEvent, BlockMovementEvent, BlockSplitEvent } from "../../editor.events";
import { Footnote, TextSelectionState } from "../../editor.service";
import { BlockDelegate } from "../block-delegate";
import { StyleSettings } from "./../../../../models/styles";
import { EditorBlock } from "./../../../../views/markup-editor/editor.models";

export interface BaseTextData {
  text: string;
}

@Component({
  selector: "m-markup-editor-text-block",
  templateUrl: "./base-text.block-view.html",
  styleUrls: ["./base-text.block-view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseTextBlockView implements OnInit, OnChanges, BlockDelegate {
  @Input()
  data!: EditorBlock;
  @Input()
  isHeaderBefore = false;
  @Input()
  styleDisplayOpts: StyleSettings | null = null;
  @Input()
  isActive = false;
  @Output()
  contentChanged = new EventEmitter<BaseTextData>();

  @Output()
  footnoteAdded: EventEmitter<Footnote[]> = new EventEmitter();
  @Output()
  footnoteChanged: EventEmitter<Footnote[]> = new EventEmitter();
  @Output()
  onSplit: EventEmitter<BlockSplitEvent> = new EventEmitter();
  @Output()
  onMergeWithPrev: EventEmitter<BlockMergeEvent> = new EventEmitter();
  @Output()
  blockMovement = new EventEmitter<BlockMovementEvent>();

  @ViewChild("textEditor")
  textEditor: TextEditor | undefined;

  text = "";

  constructor(
    private readonly previewService: MarkupPreviewService,
    private readonly elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.text = (this.data.data as BaseTextData).text;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.applyStyleOpts();
  }

  onFootnoteAdded(footnotes: Footnote[]) {
    this.footnoteAdded.emit(footnotes);
  }

  onFootnoteChanged(footnotes: Footnote[]) {
    this.footnoteChanged.emit(footnotes);
  }

  onEditorBlur() {
    const newData = {
      ...this.data.data,
      text: this.text,
    };

    this.contentChanged.emit(newData);

    this.cdr.markForCheck();
  }

  onBlockMovement(event: BlockMovementEvent) {
    this.blockMovement.emit(event);
  }

  getTextSelection(): TextSelectionState | undefined {
    return this.textEditor?.getTextSelectionState();
  }

  applyBold(): void {
    this.textEditor?.applyBold();
  }

  applyItalick(): void {
    this.textEditor?.applyItalic();
  }

  addFootnote(): void {
    this.textEditor?.addFootnote();
  }

  updateFootnotesText(footnotes: Footnote[]) {
    this.textEditor?.updateFootnotesText(footnotes);
  }

  removeFootnote(id: number): void {
    this.textEditor?.removeFootnote(id);
  }

  setCaretToBegin(): void {
    this.textEditor?.setCaretToBegin();
  }

  setCaretToEnd(): void {
    this.textEditor?.setCaretToEnd();
  }

  appendTextAndFocus(text: string): void {
    this.textEditor?.appendTextAndFocus(text);
  }

  private applyStyleOpts() {
    if (this.styleDisplayOpts) {
      this.previewService.applyPreviewStyle(
        this.elementRef.nativeElement.querySelector("m-text-editor") as Element,
        this.styleDisplayOpts,
        this.isHeaderBefore,
      );

      const element = this.elementRef.nativeElement.querySelector(".editor");
      this.previewService.applyIndentHeaderStyle(element, this.styleDisplayOpts);
      this.previewService.applyDropCap(element, this.styleDisplayOpts, this.isHeaderBefore);
    }
    this.cdr.markForCheck();
  }
}
