<div
  class="content"
  [class.active]="isActive"
  (mousedown)="onMouseDownHandle($event)"
  (mouseenter)="onMouseEnterHandle($event)"
  (mouseleave)="onMouseLeaveHandle($event)"
>
  <div class="edit-area">
    <ng-container [ngSwitch]="getBlockEditViewType()">
      <m-markup-editor-text-block
        #blockDelegate
        *ngSwitchCase="'text'"
        [data]="data"
        [isHeaderBefore]="isHeaderBefore"
        [styleDisplayOpts]="styleDisplayOpts"
        (contentChanged)="onContentChangedHandle($event)"
        (footnoteAdded)="onFootnoteAdded($event)"
        (footnoteChanged)="onFootnoteChanged($event)"
        (onSplit)="onSplitBlock($event)"
        (onMergeWithPrev)="onMergeBlocks($event)"
        (blockMovement)="onBlockMovement($event)"
      ></m-markup-editor-text-block>
      <m-markup-editor-list-block
        #blockDelegate
        *ngSwitchCase="'list'"
        [styleDisplayOpts]="styleDisplayOpts"
        [data]="data"
        (footnoteAdded)="onFootnoteAdded($event)"
        (footnoteChanged)="onFootnoteChanged($event)"
        (contentChanged)="onContentChangedHandle($event)"
      ></m-markup-editor-list-block>
      <m-markup-editor-table-block
        *ngSwitchCase="'table'"
        [styleDisplayOpts]="styleDisplayOpts"
        [data]="data"
        (contentChanged)="onContentChangedHandle($event)"
      ></m-markup-editor-table-block>
      <m-markup-editor-image-block
        *ngSwitchCase="'image'"
        [bookId]="bookId"
        [data]="data"
        [imageDefaultData]="imageDefaultData"
        (contentChanged)="onContentChangedHandle($event)"
      ></m-markup-editor-image-block>
      <m-markup-editor-header-block
        #blockDelegate
        *ngSwitchCase="'header'"
        [data]="data"
        [styleDisplayOpts]="styleDisplayOpts"
        [isActive]="isActive"
        (contentChanged)="onContentChangedHandle($event)"
        (footnoteAdded)="onFootnoteAdded($event)"
        (footnoteChanged)="onFootnoteChanged($event)"
        (onSplit)="onSplitBlock($event)"
        (onMergeWithPrev)="onMergeBlocks($event)"
        (blockMovement)="onBlockMovement($event)"
      ></m-markup-editor-header-block>
      <m-markup-editor-page-break-block
        *ngSwitchCase="'page-break'"
      ></m-markup-editor-page-break-block>
    </ng-container>
  </div>

  <div *ngIf="footnotes.length > 0" class="divider"></div>

  <div class="footnotes">
    <m-markup-editor-footnote-block
      *ngFor="let footnote of footnotes; let index = index"
      [footnote]="footnote"
      [palette]="palette"
      (contentChanged)="onFootnoteContentChangedHandle($event)"
      (onRemoveFootnoteClick)="removeFootnote(footnote)"
    />
  </div>

  <div *ngIf="data.warnings?.length" class="divider"></div>

  <div *ngIf="data.warnings?.length" class="warnings">
    <m-markup-editor-warning-block
      *ngFor="let warning of data.warnings; let index = index"
      [warning]="warning"
      (onRemoveWarningClick)="removeWarning(index)"
    />
  </div>
</div>

<ng-container [ngSwitch]="getBlockEditViewType()">
  <div class="style delete-icon" *ngSwitchCase="'page-break'">
    <m-icon
      src="markup-delete-bucket.svg"
      size="xs"
      (click)="onBlockDelete.emit()"
    ></m-icon>
  </div>

  <div class="style" [class.active]="isActive" *ngSwitchDefault>
    {{ style.visibleTitle }}
  </div>
</ng-container>
