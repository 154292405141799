import { Inject, Injectable } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { SelectValue } from "@metranpage/components";
import { ConfigService } from "@metranpage/core-interfaces";
import { Subscription } from "rxjs";

export type FormSectionLoc = {
  id?: number;
  // formSettingsId: number;
  lang: string;
  title: string;
};

export type FormControlLoc = {
  id?: number;
  // formSettingsId: number;
  lang: string;
  title: string;
  placeholder: string;
};

export type FormControlValidation = {
  name: "required" | "bookTypeValidator" | "pattern";
  validator: "required" | "bookTypeValidator" | "reNonEmptyString";
  message?: string;
};

export type FormControlStructure = {
  type: "text" | "with-prefix" | "textarea" | "number" | "select" | "switch" | "type-epub" | "type-print";
  // label: string;
  // localization: FormControlLoc[];
  title: string;
  placeholder: string;
  prefix?: string;
  name: string;
  value: string | number | boolean;
  nonNullable: boolean;
  options?: SelectValue[];
  validations?: FormControlValidation[];
  isForRussianPublish?: boolean;
};

export type FormSectionStructure = {
  name: string;
  title: string;
  // localization: FormSectionLoc[];
  enableControl?: FormControlStructure;
  controls: FormControlStructure[];
  validations?: FormControlValidation[];
};

const reNonEmptyString = /^(?!\s*$).+/;

function bookTypeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value.exportPrint && !value.exportEpub) {
      return {
        expectedType: true,
      };
    }
    return null;
  };
}

@Injectable({
  providedIn: "root",
})
export class ModalBookFormDataService {
  protected form!: FormGroup;

  private sub = new Subscription();

  constructor(
    private readonly formBuilder: FormBuilder,
    @Inject("ConfigService")
    readonly configService: ConfigService,
  ) {}

  initForm(formData: FormSectionStructure[]) {
    this.sub.unsubscribe();
    this.sub = new Subscription();

    this.createForm(formData);
    this.watchFormChanges(formData);

    return this.getForm();
  }

  createForm(formData: FormSectionStructure[]) {
    const sectionsControls: Record<string, any> = {};
    for (const section of formData) {
      const formGroup: Record<string, any> = {};
      if (section.enableControl) {
        const controlValidators = this.getFormValidators(section.enableControl.validations);
        formGroup[section.enableControl.name] = [section.enableControl.value, controlValidators];
      }
      for (const control of section.controls) {
        const controlValidators = this.getFormValidators(control.validations);
        formGroup[control.name] = this.formBuilder.control(control.value, {
          nonNullable: control.nonNullable,
          validators: controlValidators,
        });
      }

      const sectionValidators = this.getFormValidators(section.validations);
      sectionsControls[section.name] = this.formBuilder.group(formGroup, { validators: sectionValidators });
    }
    this.form = this.formBuilder.group(sectionsControls);
  }

  watchFormChanges(formData: FormSectionStructure[]) {
    for (const section of formData) {
      if (section.enableControl) {
        this.sub.add(
          this.form
            ?.get(section.name)
            ?.get(section.enableControl.name)
            ?.valueChanges.subscribe((value) => {
              for (const control of section.controls) {
                const formControl = this.form?.get(section.name)?.get(control.name);
                formControl?.disable();
                if (value) {
                  formControl?.enable();
                }
              }
            }),
        );
      }
    }
  }

  getForm() {
    return this.form;
  }

  destroyForm() {
    this.sub.unsubscribe();
  }

  isControlTitleVisible(control: FormControlStructure) {
    return control.type !== "type-epub" && control.type !== "type-print";
  }

  getFormValidators(validations: FormControlValidation[] | undefined) {
    const controlValidators: ValidatorFn[] = [];
    if (validations) {
      for (const validation of validations) {
        if (validation.validator === "required") {
          controlValidators.push(Validators.required);
        }
        if (validation.validator === "reNonEmptyString") {
          controlValidators.push(Validators.pattern(reNonEmptyString));
        }
        if (validation.validator === "bookTypeValidator") {
          controlValidators.push(bookTypeValidator());
        }
      }
    }
    return controlValidators;
  }

  getFormData(): FormSectionStructure[] {
    const formData: FormSectionStructure[] = [
      {
        name: "general",
        title: $localize`:@@books.new.project-modal.general.section:`,
        controls: [
          {
            type: "text",
            name: "title",
            title: $localize`:@@books.new.project-modal.general.title:`,
            placeholder: $localize`:@@books.new.project-modal.general.title-placeholder:`,
            value: "",
            nonNullable: true,
            validations: [
              {
                name: "required",
                validator: "required",
              },
              {
                name: "pattern",
                validator: "reNonEmptyString",
              },
            ],
          },
          {
            type: "text",
            name: "authorFirstName",
            value: "",
            nonNullable: true,
            title: $localize`:@@books.new.project-modal.general.author-first-name:`,
            placeholder: $localize`:@@books.new.project-modal.general.author-first-name-placeholder:`,
            validations: [
              {
                name: "required",
                validator: "required",
              },
              {
                name: "pattern",
                validator: "reNonEmptyString",
              },
            ],
          },
          {
            type: "text",
            name: "authorLastName",
            value: "",
            nonNullable: true,
            title: $localize`:@@books.new.project-modal.general.author-last-name:`,
            placeholder: $localize`:@@books.new.project-modal.general.author-last-name-placeholder:`,
            validations: [
              {
                name: "required",
                validator: "required",
              },
              {
                name: "pattern",
                validator: "reNonEmptyString",
              },
            ],
          },
          {
            type: "text",
            name: "authorMiddleName",
            value: "",
            nonNullable: true,
            title: $localize`:@@books.new.project-modal.general.author-middle-name:`,
            placeholder: $localize`:@@books.new.project-modal.general.author-middle-name-placeholder:`,
            validations: [],
            isForRussianPublish: true,
          },
          {
            type: "text",
            name: "city",
            value: "",
            nonNullable: true,
            title: $localize`:@@books.new.project-modal.general.city:`,
            placeholder: $localize`:@@books.new.project-modal.general.city-placeholder:`,
            validations: [],
          },
          {
            type: "text",
            name: "date",
            value: "",
            nonNullable: true,
            title: $localize`:@@books.new.project-modal.general.year:`,
            placeholder: $localize`:@@books.new.project-modal.general.year-placeholder:`,
            validations: [],
          },
          {
            type: "select",
            name: "language",
            title: $localize`:@@books.new.project-modal.additional.language:`,
            placeholder: "",
            value: "en",
            nonNullable: true,
            options: [
              { id: "ru", value: $localize`:@@book.language.ru:` },
              { id: "en", value: $localize`:@@book.language.en:` },
              { id: "de", value: $localize`:@@book.language.de:` },
            ],
            validations: [
              {
                name: "required",
                validator: "required",
              },
            ],
          },
        ],
      },

      {
        name: "type",
        title: $localize`:@@books.book.type-title:`,
        controls: [
          {
            type: "type-print",
            name: "exportPrint",
            title: $localize`:@@books.new.project-modal.additional.print-book:`,
            placeholder: "",
            nonNullable: true,
            value: "",
            validations: [],
          },
          {
            type: "type-epub",
            name: "exportEpub",
            value: "",
            nonNullable: true,
            title: $localize`:@@books.new.project-modal.additional.e-book:`,
            placeholder: "",
            validations: [],
          },
        ],
        validations: [
          {
            name: "bookTypeValidator",
            validator: "bookTypeValidator",
          },
        ],
      },

      {
        name: "edition-notice",
        title: $localize`:@@books.new.project-modal.edition-notice.section:`,
        enableControl: {
          type: "switch",
          name: "isEditionNoticePageEnable",
          value: "",
          nonNullable: true,
          title: $localize`:@@books.new.project-modal.additional.enable-edition-notice-page:`,
          placeholder: "",
          validations: [
            {
              name: "required",
              validator: "required",
            },
          ],
        },
        controls: [
          {
            type: "with-prefix",
            name: "isbn",
            value: "",
            nonNullable: true,
            title: "",
            prefix: $localize`:@@books.new.project-modal.edition-notice.isbn:`,
            placeholder: "",
            validations: [],
          },
          {
            type: "with-prefix",
            name: "udk",
            value: "",
            nonNullable: true,
            title: "",
            prefix: $localize`:@@books.new.project-modal.edition-notice.udk:`,
            placeholder: "",
            validations: [],
            isForRussianPublish: true,
          },
          {
            type: "with-prefix",
            name: "bbk",
            value: "",
            nonNullable: true,
            title: "",
            prefix: $localize`:@@books.new.project-modal.edition-notice.bbk:`,
            placeholder: "",
            validations: [],
            isForRussianPublish: true,
          },
          {
            type: "select",
            name: "ageRestriction",
            title: $localize`:@@books.new.project-modal.additional.age-limit:`,
            placeholder: "",
            nonNullable: true,
            value: "",
            validations: [
              {
                name: "required",
                validator: "required",
              },
            ],
            options: [
              { id: "0", value: "0+" },
              { id: "6", value: "6+" },
              { id: "12", value: "12+" },
              { id: "16", value: "16+" },
              { id: "18", value: "18+" },
            ],
          },
          {
            type: "textarea",
            name: "adviser",
            value: "",
            nonNullable: true,
            title: $localize`:@@books.new.project-modal.edition-notice.adviser:`,
            placeholder: $localize`:@@books.new.project-modal.edition-notice.adviser-placeholder:`,
            validations: [],
            isForRussianPublish: true,
          },
          {
            type: "textarea",
            name: "reviewers",
            value: "",
            nonNullable: true,
            title: $localize`:@@books.new.project-modal.edition-notice.reviewers:`,
            placeholder: $localize`:@@books.new.project-modal.edition-notice.reviewers-placeholder:`,
            validations: [],
            isForRussianPublish: true,
          },
          {
            type: "textarea",
            name: "annotation",
            value: "",
            nonNullable: true,
            title: $localize`:@@books.new.project-modal.edition-notice.annotation:`,
            placeholder: $localize`:@@books.new.project-modal.edition-notice.annotation-placeholder:`,
            validations: [],
          },
        ],
      },
    ];

    const isPublishInRussia = this.configService.getConfig().company.flags.isPublishInRussia;

    const filteredFormData: FormSectionStructure[] = [];
    for (const s of formData) {
      const sectionData = {
        ...s,
        controls: s.controls.filter(
          (c) => c.isForRussianPublish === undefined || c.isForRussianPublish === isPublishInRussia,
        ),
      };
      filteredFormData.push(sectionData);
    }

    return filteredFormData;
  }

  isFieldRequired(control: FormControlStructure): boolean {
    const requireValidator = control.validations?.find((v) => v.name === "required");
    if (requireValidator && control.type === "text") {
      return true;
    }
    return false;
  }
}
