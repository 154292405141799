import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'm-tariff-period-selector',
  templateUrl: './tariff-period-selector.view.html',
  styleUrls: ['./tariff-period-selector.view.scss'],
})
export class TariffPeriodSelectorView {
  @Input()
  tariffsPeriods: number[] = [];
  @Input()
  activeTariffPeriod = 1;

  @Output()
  onPeriodSelect = new EventEmitter<number>();

  getPeriodLocalize(period: number) {
    switch (period) {
      case 1:
        return $localize`:@@pricing.tariffs.period.month:`;
      case 6:
        return $localize`:@@pricing.tariffs.period.half-year:`;
      case 12:
        return $localize`:@@pricing.tariffs.period.year:`;
      default:
        return $localize`:@@pricing.tariffs.period.month:`;
    }
  }

  protected onPeriodSelectClick(period: number) {
    this.activeTariffPeriod = period;
    this.onPeriodSelect.emit(period);
  }
}
