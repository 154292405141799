<!--  (mouseup)="onMouseUp($event)" (mousedown)="onMouseDown($event)" -->

<m-paginator
  *ngIf="pageCount > 1"
  [pageCount]="pageCount"
  [(ngModel)]="currentPage"
  (ngModelChange)="selectPage($event)"
/>

<div id="document-content" translate="no" (mousewheel)="onScroll()">
  <div id="blocks" *ngIf="stylesOptsByStyle">
    <m-markup-editor-block
      *ngFor="let block of paginatedItems; index as i; trackBy: trackByBlockId"
      [attr.data-id]="block.block.id"
      [data]="block.block"
      [isHeaderBefore]="isHeaderBefore(block.block.id)"
      [style]="styles[block.block.style]"
      [styleDisplayOpts]="stylesOptsByStyle[block.block.style] | async"
      [isActive]="(selectedBlocksIds$ | async)?.includes(block.block.id) || false"
      [onPageIndex]="i"
      [simulatedBlockSelectEvents]="simulatedBlockSelectEvents"
      [selectionChangeEvents]="selectionChange$"
      [palette]="palette"
      [bookId]="bookId"
      [imageDefaultData]="imageDefaultData"
      (onContentChanged)="onContentChanged($event)"
      (onBlockSelected)="onBlockSelected($event)"
      (onBlockHovered)="onBlockHovered.emit($event)"
      (onBlockDelete)="deleteBlockByOnPageIndex(i)"
      (onBlockSplit)="onSplitBlock($event)"
      (onBlockMergeWithPrev)="onMergeBlocks($event)"
      (blockMovement)="onBlockMovement($event)"
      (onBlockWarningDelete)="onBlockWarningDelete.emit()"
    ></m-markup-editor-block>
  </div>
</div>

<m-paginator
  *ngIf="pageCount > 1"
  [pageCount]="pageCount"
  [(ngModel)]="currentPage"
  (ngModelChange)="selectPage($event)"
/>

<div class="spacer"></div>

<m-markup-block-toolbar
  *ngIf="blockToolbarState.isVisible"
  [style.top]="blockToolbarState.topPosition + 'px'"
  [indexBlock]="index"
  [blockLength]="itemLength"
  [settings]="toolbarSettings"
  (addTextBlock)="addBlock($event)"
  (addImageBlock)="addBlock('image')"
  (addListBlock)="addListBlock($event)"
  (addTableBlock)="addBlock('table')"
  (addPageBreakBlock)="addBlock('page-break')"
  (downBlock)="moveBlock('down')"
  (upBlock)="moveBlock('up')"
  (deleteBlock)="deleteBlock()"
/>

<m-markup-block-toolbar-inline
  id="inline-toolbar"
  #inlineToolbar
  [textSelectionState]="textSelectionState"
  (onInlineBoldClick)="onInlineBoldClick()"
  (onInlineItalicClick)="onInlineItalicClick()"
  (onInlineAddFootnoteClick)="onInlineAddFootnoteClick()"
  (onHideToolbarInline)="hideInlineToolbar()"
/>

<!-- <m-markup-editor-text-generation-context-menu
  id="text-generation-context-menu"
  #textGenerationContextMenu
  (onClick)="onTextGenerationContextMenuClick($event)"
  (onClose)="hideTextGenerationContextMenu()"
/> -->
