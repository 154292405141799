export const unisenderListKey = [
  "Default",
  "AllContacts",
  "UserRegisteredNoPreview",
  "BookPreview",
  "BookPreviewError",
  "BookDone",
  "BookDoneError",
  "Paid",
  "ErrorOnUpload",
  "DeleteAllCards",
  "CreateImageGeneration",
  "CreateBookCover",
  "SleepingUsers",
] as const;
export type UnisenderListKey = (typeof unisenderListKey)[number];

export type UnisenderSettingsItem = {
  id: number;
  companyId: number;

  title: UnisenderListKey;
  listId: number;
  timeout: number;
};

export type UnisenderSettingsItemCreateData = Omit<UnisenderSettingsItem, "id">;
export type UnisenderSettingsItemUpdateData = Partial<Omit<UnisenderSettingsItem, "id">>;

export type UnisenderSettings = Record<UnisenderListKey, UnisenderSettingsItemCreateData>;

export type CompanyUnisenderCreds = {
  unisenderApiKey: string | undefined;
  unisenderGoApiKey: string | undefined;
};
