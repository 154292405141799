import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { CAN_CREATE_PUBLIC_TEMPLATES, LoadingService, NotificationsPopUpService } from "@metranpage/core";
import { debounceTime, takeUntil } from "rxjs";
import { Format, FormatCreateDataDto } from "../../models/format";
import { FormatAccess } from "../../models/format-access.model";
import { AdminFormatAccessService } from "../../services/format-access/format-access.service";
import { AdminFormatAccessStore } from "../../services/format-access/format-access.store";
import { AdminFormatService } from "../../services/format/format.service";
import { AdminFormatStore } from "../../services/format/format.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-formats-page",
  templateUrl: "./formats.page.html",
  styleUrls: ["./formats.page.scss"],
})
export class AdminFormatsPage extends AdminBasePage implements OnInit {
  allFormats: Format[] = [];
  formats: Format[] = [];
  formatsAccess: FormatAccess[] = [];

  protected searchForm = new FormGroup({
    name: new FormControl("", { nonNullable: true, validators: [] }),
  });
  protected searchName: string | undefined = undefined;

  protected isAddFormatModalVisible = false;
  protected isDeleteFormatModalVisible = false;
  protected deleteFormatId = -1;

  protected dragIndex: number | null = null;

  constructor(
    private readonly adminFormatStore: AdminFormatStore,
    adminFormatAccessStore: AdminFormatAccessStore,
    private readonly adminFormatService: AdminFormatService,
    private readonly adminFormatAccessService: AdminFormatAccessService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(CAN_CREATE_PUBLIC_TEMPLATES)
    readonly canCreatePublicFormats: boolean,
  ) {
    super();

    adminFormatStore
      .getFormatsObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((formats) => {
        this.allFormats = formats;
        this.selectSuitableFormats();
      });

    this.addSub(
      adminFormatAccessStore
        .getFormatsAccessObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((formatsAccess) => {
          this.formatsAccess = formatsAccess;
          this.cdr.markForCheck();
        }),
    );
  }

  ngOnInit(): void {
    this.adminFormatService.loadFormats();

    this.searchForm.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(600)).subscribe((value) => {
      this.searchName = undefined;
      if (value.name) {
        this.searchName = value.name;
      }

      this.selectSuitableFormats();
    });

    this.adminFormatAccessService.loadFormatsAccess();
  }

  trackByFormatId(index: number, format: Format | FormatAccess) {
    return format.id;
  }

  selectSuitableFormats() {
    this.formats = this.allFormats.filter((f) => f.name.includes(this.searchName || ""));
    this.cdr.markForCheck();
  }

  protected async onAddFormat() {
    this.isAddFormatModalVisible = true;
  }

  protected async onFormatAdded(data: FormatCreateDataDto) {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminFormatService.createFormat(data);
    this.loadingService.stopLoading();
    if (result.status === "success") {
      this.onCloseAddFormatModal();
      this.adminFormatService.loadFormats();
    } else {
      this.notificationService.error($localize`:@@admin.format.format.error.cant-create-format:`);
    }
  }

  protected onCloseAddFormatModal() {
    this.isAddFormatModalVisible = false;
  }

  protected onCloseDeleteFormatModal() {
    this.isDeleteFormatModalVisible = false;
  }

  protected onDeleteFormatClick(formatId: number) {
    this.isDeleteFormatModalVisible = true;
    this.deleteFormatId = formatId;
  }

  protected async onDeleteFormat() {
    const result = await this.adminFormatService.deleteFormat(this.deleteFormatId);
    if (result === "success") {
      this.isDeleteFormatModalVisible = false;
      this.adminFormatService.loadFormats();
    } else {
      this.notificationService.error($localize`:@@admin.format.error.cant-delete-format:`);
    }
  }

  async onFormatVisibleClick(formatAccess: FormatAccess) {
    const isVisibleToUsers = !formatAccess.isVisibleToUsers;
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminFormatAccessService.updateFormatAccess(formatAccess.id, { isVisibleToUsers });
    this.loadingService.stopLoading();
    if (result !== "success") {
      this.notificationService.error($localize`:@@admin.format.error.cant-add-format:`);
    }
    this.cdr.markForCheck();
  }

  onDragStart(event: DragEvent, index: number) {
    event.dataTransfer?.setData("text/plain", index.toString());
    this.dragIndex = index;
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  async onDrop(event: DragEvent, index: number) {
    event.preventDefault();

    if (this.dragIndex === null) return;

    const draggedTemplate = this.allFormats.splice(this.dragIndex, 1)[0];
    this.allFormats.splice(index, 0, draggedTemplate);

    for (let i = 0; i < this.allFormats.length; i++) {
      this.allFormats[i].order = i;
    }

    this.notificationService.closeAll();
    const status = await this.adminFormatService.saveFormatsOrder(this.allFormats);
    if (status !== "success") {
      this.notificationService.error($localize`:@@admin.templates.template.error.cant-update-order:`);
    }

    this.dragIndex = null;
  }
}
