import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  Dimensions,
  MarginKey,
  MarginsLimits,
  MarginsSelectState,
  MarginsState,
  PagePosition,
  Position,
} from "@metranpage/book-data";
import { Subscription, filter, first } from "rxjs";
import { MarginsService } from "../../services/margins.service";
import { MarginsStore } from "../../services/margins.store";

type MarginData = Dimensions & { page?: PagePosition };

@Component({
  selector: "m-margins-preview",
  templateUrl: "./margins-preview.component.html",
  styleUrls: ["./margins-preview.component.scss"],
})
export class MarginsPreviewComponent implements OnInit {
  @Input("page-size")
  pageSize!: Dimensions;
  @Input("font-size-main")
  fontSizeMain = 0;
  @Input("margins-limits")
  marginsLimits!: MarginsLimits;
  @Input("select-state")
  selectState!: MarginsSelectState;

  @Input("margins-error")
  marginsError: MarginKey[] = [];

  @Output()
  onSelectStateChange = new EventEmitter<MarginsSelectState>();
  @Output()
  onMouseAction = new EventEmitter<boolean>();

  protected state!: MarginsState;

  protected isMouseKeyPressed = false;
  protected isMouseOnLeftPageMarginWide = false;
  protected isMouseOnLeftPageMarginText = false;
  protected isMouseOnRightPageMarginWide = false;
  protected isMouseOnRightPageMarginText = false;

  protected viewPageSize: Dimensions = { width: 0, height: 0 };
  protected sizeCoefficients = { cx: 0, cy: 0 };
  protected cursorStartPositionOffset: Position = { x: 0, y: 0 };
  protected marginSelectedData: MarginData = { width: 0, height: 0, page: undefined };

  protected fontSizeInPixeles = 0;

  private sub = new Subscription();

  constructor(
    private elementRef: ElementRef,
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly marginsService: MarginsService,
    readonly marginsStore: MarginsStore,
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.marginsStore
        .getMarginsStateObservable()
        .pipe(first())
        .subscribe((marginsState) => {
          this.state = marginsState;
          this.updateMargins(marginsState);
        }),
    );

    this.sub.add(
      this.marginsStore
        .getMarginsStateObservable()
        .pipe(filter((state) => state.lastChangeSource === "sidebar"))
        .subscribe((marginsState) => {
          this.state = marginsState;
          this.updateMargins(marginsState);
        }),
    );
  }

  ngAfterViewInit() {
    window.dispatchEvent(new Event("resize"));

    // timer(100).pipe(
    //   repeat({ delay: 100 }),
    //   filter((data) => {
    //     let textPreviewSize = this.getTextPreviewSize();
    //     return textPreviewSize.width > 0 && textPreviewSize.height > 0;
    //   }),
    //   take(1)
    // ).subscribe((result) => {
    //   this.textMarginSize = this.getTextPreviewSize();
    // });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onMarginMouseEnter(marginKey: MarginKey) {
    this.marginsService.onHoverMargin(this.selectState, marginKey);
    this.emitSelectState();
  }

  onMarginMouseLeave() {
    this.marginsService.onHoverMargin(this.selectState, undefined);
    this.emitSelectState();
  }

  onMarginMouseDown(marginKey: MarginKey) {
    this.marginsService.onSelectMargin(this.selectState, marginKey, "preview");
    this.emitSelectState();
  }

  onMarginMouseUp() {
    this.marginsService.onSelectMargin(this.selectState, undefined, "preview");
    this.emitSelectState();
  }

  private emitSelectState() {
    this.onSelectStateChange.emit({ ...this.selectState, isMouseKeyPressed: this.isMouseKeyPressed });
  }

  protected onPagePreviewChange(viewPageSize: Dimensions) {
    this.viewPageSize = viewPageSize;
    this.sizeCoefficients = this.marginsService.calculateSizeCoefficients(this.pageSize, this.viewPageSize);
    this.updateMargins(this.state);
  }

  protected onMouseDown(event: MouseEvent, page: PagePosition, margin: MarginKey) {
    this.isMouseKeyPressed = true;
    const marginClassName = this.marginsService.getMarginClass(margin);
    const target = <HTMLElement>(<HTMLElement>event.target).closest(`div[class*="${marginClassName}"]`);
    if (!target) {
      return;
    }
    this.marginSelectedData = { width: target.offsetWidth, height: target.offsetHeight, page: page };
    this.cursorStartPositionOffset = { x: event.clientX, y: event.clientY };

    this.onMarginMouseDown(margin);

    if (margin === "widePadding") {
      const marginElement = this.elementRef.nativeElement.querySelector(`.${marginClassName}`);
      this.marginSelectedData = { width: marginElement.offsetWidth, height: marginElement.offsetHeight, page: page };
    }
  }

  private updateMargins(state: MarginsState) {
    this.changeDetectionRef.detectChanges();
    for (const [key, millimetersValue] of Object.entries(state.margins)) {
      const marginKey = key as MarginKey;
      const dimension = this.marginsService.getDimension(marginKey);
      const marginClassName = this.marginsService.getMarginClass(marginKey);
      const marginElements = this.elementRef.nativeElement.querySelectorAll(`.${marginClassName}`);
      for (const marginElement of marginElements) {
        let pixelsValue = this.marginsService.scaleDown(millimetersValue, marginKey, this.sizeCoefficients);
        pixelsValue = this.marginsService.admissiblePixelsSize(
          this.marginsLimits,
          pixelsValue,
          this.sizeCoefficients,
          marginKey,
        );
        marginElement.style[dimension] = `${pixelsValue}px`;
      }
    }

    this.calculateFontSize();
  }

  private updateMargin(deltaX: number, deltaY: number) {
    if (!this.selectState.selected) {
      return;
    }
    let pixelsValue = 0;
    const dimension = this.marginsService.getDimension(this.selectState.selected);
    const marginClassName = this.marginsService.getMarginClass(this.selectState.selected);
    const marginElements = this.elementRef.nativeElement.querySelectorAll(`.${marginClassName}`);
    for (const marginElement of marginElements) {
      const offset = this.getOffset(this.selectState.selected, deltaX, deltaY);
      pixelsValue = this.marginSelectedData[dimension] + offset;
      pixelsValue = this.marginsService.admissiblePixelsSize(
        this.marginsLimits,
        pixelsValue,
        this.sizeCoefficients,
        this.selectState.selected,
      );
      marginElement.style[dimension] = `${pixelsValue}px`;
    }

    const millimetersValue = this.marginsService.scaleUp(pixelsValue, this.selectState.selected, this.sizeCoefficients);
    this.marginsStore.setMargin(this.selectState.selected, millimetersValue, "preview");

    this.updateWidePadding(this.selectState.selected);
  }

  private updateWidePadding(marginKey: MarginKey) {
    if (marginKey === "widePadding" || !this.marginsStore.getWidePaddingState()) {
      return;
    }

    let widePaddingSize = 0;
    marginKey = "widePadding";
    const dimension = this.marginsService.getDimension(marginKey);
    const marginClassName = this.marginsService.getMarginClass(marginKey);
    const marginWideElements = this.elementRef.nativeElement.querySelectorAll(`.${marginClassName}`);
    for (const marginWideElement of marginWideElements) {
      widePaddingSize = Number.parseInt(marginWideElement.style.width.match(/\d+/));
      widePaddingSize = this.marginsService.admissiblePixelsSize(
        this.marginsLimits,
        widePaddingSize,
        this.sizeCoefficients,
        marginKey,
      );
      marginWideElement.style[dimension] = `${widePaddingSize}px`;
    }
    if (!widePaddingSize) {
      return;
    }
    const millimetersValue = this.marginsService.scaleUp(widePaddingSize, marginKey, this.sizeCoefficients);
    this.marginsStore.setMargin(marginKey, millimetersValue, "preview");
  }

  private calculateFontSize() {
    if (!this.fontSizeMain) {
      return;
    }
    this.fontSizeInPixeles = this.marginsService.calculateFontSizeInPixels(this.fontSizeMain, this.sizeCoefficients);
  }

  private isMarginInErrorList(margin: MarginKey) {
    return this.marginsError.includes(margin);
  }

  private getOffset(margin: MarginKey, deltaX: number, deltaY: number) {
    let offset = deltaY;
    if (this.selectState.selected === "marginBottom") {
      offset *= -1;
    }
    if (!this.marginsService.isMarginHorizontal(margin)) {
      offset = deltaX;
      if (
        this.marginSelectedData.page === "left" &&
        (this.selectState.selected === "marginInner" ||
          (this.selectState.selected === "widePadding" && this.state.widePaddingLeftPosition === 2))
      ) {
        offset *= -1;
      }
      if (
        this.marginSelectedData.page === "right" &&
        (this.selectState.selected === "marginOuter" ||
          (this.selectState.selected === "widePadding" && this.state.widePaddingRightPosition === 2))
      ) {
        offset *= -1;
      }
    }
    return offset;
  }

  protected onEnterMarginWide(page: string) {
    if (page === "left") {
      this.isMouseOnLeftPageMarginWide = true;
    }
    if (page === "right") {
      this.isMouseOnRightPageMarginWide = true;
    }
  }

  protected onLeaveMarginWide(page: string) {
    if (page === "left") {
      this.isMouseOnLeftPageMarginWide = false;
    }
    if (page === "right") {
      this.isMouseOnRightPageMarginWide = false;
    }
  }

  protected onEnterMarginText(page: string) {
    if (page === "left") {
      this.isMouseOnLeftPageMarginText = true;
    }
    if (page === "right") {
      this.isMouseOnRightPageMarginText = true;
    }
  }

  protected onLeaveMarginText(page: string) {
    if (page === "left") {
      this.isMouseOnLeftPageMarginText = false;
    }
    if (page === "right") {
      this.isMouseOnRightPageMarginText = false;
    }
  }

  protected isMarginSelected(margin: MarginKey) {
    return this.selectState.selected === margin;
  }

  protected getPageCssClassList(page: PagePosition): string[] {
    const result: string[] = [];

    if (this.state.widePaddingState) {
      result.push("wide-paddings");
    }
    if (page === "left") {
      result.push("wrapper");
      if (this.state.widePaddingLeftPosition === 2) {
        result.push("displaced");
      }
    }
    if (page === "right") {
      result.push("wrapper-mirror");
      if (this.state.widePaddingRightPosition === 1) {
        result.push("displaced");
      }
    }

    return result;
  }

  protected getMarginCssClassList(page: PagePosition, margin: MarginKey): string[] {
    const result: string[] = [];

    result.push(`${this.marginsService.getMarginClass(margin)}`);
    result.push(`on-page-${page}`);
    if (this.isMarginInErrorList(margin)) {
      result.push("error");
      // result.push('indicator-hovered');
    }
    if (!this.isMarginInErrorList(margin) && this.selectState.selected === margin) {
      result.push("selected");
      result.push("indicator-selected");
    }
    if (!this.isMarginInErrorList(margin) && this.selectState.hovered === margin && !this.isMouseKeyPressed) {
      result.push("hovered");
      result.push("indicator-hovered");
    }

    return result;
  }

  protected getStyleTextPadding() {
    if (this.state.columnsCount === 1 || !this.state.margins.gutter) {
      return {};
    }
    const pixelsValue = this.getGutterWidthInPixeles(this.state.margins.gutter);
    return { gap: `${pixelsValue}px` };
  }

  protected getStyleTextPreview() {
    if (!this.fontSizeInPixeles) {
      return {};
    }
    const margin = (this.fontSizeInPixeles * 2) / 3;
    return {
      "background-image": `repeating-linear-gradient(
                            var(--color-bg-margin-preview-text),
                            var(--color-bg-margin-preview-text) ${this.fontSizeInPixeles}px,
                            transparent ${this.fontSizeInPixeles}px,
                            transparent ${this.fontSizeInPixeles + margin}px
                          )`,
    };
  }

  protected getGutterWidthInPixeles(millimetersValue: number) {
    const margin = "gutter";
    const pixelsValue = this.marginsService.scaleDown(millimetersValue, margin, this.sizeCoefficients);
    return this.marginsService.admissiblePixelsSize(this.marginsLimits, pixelsValue, this.sizeCoefficients, margin);
  }

  protected displaceMargins(page: PagePosition) {
    if (!this.state.widePaddingState) {
      return;
    }
    let value = 0;
    if (page === "left") {
      value = this.state.widePaddingLeftPosition === 1 ? 2 : 1;
      this.state.widePaddingLeftPosition = value;
    }
    if (page === "right") {
      value = this.state.widePaddingRightPosition === 1 ? 2 : 1;
      this.state.widePaddingRightPosition = value;
    }
    this.marginsStore.setWidePaddingPosition(page, value);
  }

  protected isWidePaddingEnabled() {
    return this.state.widePaddingState;
  }

  protected isColumnsEnable() {
    return this.state.columnsCount > 1;
  }

  protected getPageSize() {
    return this.pageSize;
  }

  @HostListener("window:mouseup", ["$event"])
  onMouseUpGlobal(event: MouseEvent) {
    if (this.isMouseKeyPressed) {
      this.isMouseKeyPressed = false;
      this.onMarginMouseUp();
    }
  }

  @HostListener("mousemove", ["$event"])
  onMouseMoveGlobal(event: MouseEvent) {
    if (this.isMouseKeyPressed) {
      const deltaX = event.clientX - this.cursorStartPositionOffset.x;
      const deltaY = event.clientY - this.cursorStartPositionOffset.y;

      this.updateMargin(deltaX, deltaY);
    }
  }
}
