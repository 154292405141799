<span class="checkbox-container" [class.checked]="isChecked">
  <m-icon
    src="/assets/components/checkbox-on.svg"
    class="checkbox-image"
    [class.checked]="isChecked"
    size="original"
    color="--color-font-on-accent"
  />
</span>

<span class="checkbox-label">
  <span>{{ title }}</span>
  <ng-content></ng-content>
</span>
