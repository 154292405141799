<div
  *ngIf="character; else newCharacter"
  class="image-card"
  [class.active]="isActive"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <m-skeleton-image
    *ngIf="imageGenerationId && !character.isProcessing"
    [url]="getUrlForImage()"
    width="100"
    unit="%"
    backgroudColor="var(--surface-on-island)"
    animationStyle="gradient"
  />

  <div *ngIf="character.isProcessing" class="spinner-group" (click)="onEditClick()">
    <div class="spinner-24"></div>
  </div>

  <div *ngIf="imageGenerationId && !isButtonsVisible" class="fade"></div>

  <span *ngIf="!isButtonsVisible" class="title">{{ character.name }}</span>

  <div *ngIf="isButtonsVisible" class="buttons" @fadeInOut>
    <div class="card-buttons">
      <div *ngIf="!isAdded && !isLimitReached" class="card-button" (click)="onSelectClick()">
        <m-ng-icon src="done.svg" [size]="24" />
        <span i18n="@@characters.edit.select-button">characters.edit.select-button</span>
      </div>

      <div *ngIf="isAdded" class="card-button" (click)="onEditClick()">
        <span i18n="@@characters.list.already-selected">characters.list.already-selected</span>
      </div>

      <div *ngIf="!isAdded && isLimitReached" class="card-button" (click)="onEditClick()">
        <span i18n="@@characters.list.limit-reached">characters.list.limit-reached</span>
      </div>
    </div>

    <div class="divider"></div>

    <div class="footer-buttons">
      <div class="buttons-group">
        <div
          class="button"
          title="characters.card.edit.button-description"
          i18n-title="@@characters.card.edit.button-description"
          (click)="onEditClick()"
        >
          <m-ng-icon src="pencil-24.svg" [size]="24" />
        </div>

        <div
          class="button"
          title="characters.card.copy.button-description"
          i18n-title="@@characters.card.copy.button-description"
          (click)="onCopyClick()"
        >
          <m-ng-icon src="copy.svg" [size]="24" />
        </div>

        <div
          class="delete-button"
          title="characters.card.delete.button-description"
          i18n-title="@@characters.card.delete.button-description"
          (click)="onDeleteClick()"
        >
          <m-ng-icon src="delete.svg" [size]="20" />
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #newCharacter>
  <div class="image-card new" [class.active]="isActive" (click)="onAddClick()">
    <m-ng-icon class="add-character-icon" src="add-character.svg" [size]="40" />
    <m-localized-text
      class="add-character-text"
      text="characters.list.add-new-button"
      i18n-text="@@characters.list.add-new-button"
    />
  </div>
</ng-template>
