import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { TextSelectionState } from "../markup-editor/editor.service";

@Component({
  selector: "m-markup-block-toolbar-inline",
  templateUrl: "./markup-block-toolbar-inline.view.html",
  styleUrls: ["./markup-block-toolbar-inline.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class MarkupBlockToolbarInline {
  @Input()
  textSelectionState: TextSelectionState | undefined = undefined;

  @Output()
  onInlineBoldClick = new EventEmitter<void>();
  @Output()
  onInlineItalicClick = new EventEmitter<void>();
  @Output()
  onInlineAddFootnoteClick = new EventEmitter<void>();

  @Output()
  onHideToolbarInline = new EventEmitter<void>();

  constructor(
    protected readonly elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  boldClick() {
    this.onInlineBoldClick.emit();
  }

  italicClick() {
    this.onInlineItalicClick.emit();
  }

  addFootnoteClick() {
    this.onInlineAddFootnoteClick.emit();
  }

  isBoldActive(): boolean {
    return this.textSelectionState?.hasBold || false;
  }

  isItalicActive(): boolean {
    return this.textSelectionState?.hasItalic || false;
  }

  @HostListener("document:mousedown", ["$event.target"])
  onClickOutside(target: HTMLElement): void {
    const isClickedInside = this.elementRef.nativeElement.contains(target);
    if (!isClickedInside) {
      this.onHideToolbarInline.emit();
    }
  }

  @HostListener("document:keydown", ["$event"])
  onEscKeydown(event: KeyboardEvent): void {
    if (event.key === "Escape") {
      this.onHideToolbarInline.emit();
    }
  }
}
