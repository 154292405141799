import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { SelectComponent } from "@metranpage/components";

@Component({
  selector: "m-font-select",
  templateUrl: "./font-select.view.html",
  styleUrls: ["./font-select.view.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FontSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FontSelectComponent extends SelectComponent {
  protected previewFontsStyle(fontFamily: string | undefined) {
    const style: { [key: string]: string } = {};

    if (fontFamily) {
      style["font-family"] = fontFamily;
    }

    return style;
  }
}
