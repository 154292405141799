import { Inject, Injectable } from "@angular/core";
import { IS_COVER_EDITOR_ENABLED } from "@metranpage/core";
import { BehaviorSubject, Subject } from "rxjs";

export type CreateProjectType = "book" | "cover";
export type CreateProjectEvent = {
  projectType: CreateProjectType;
};

@Injectable({
  providedIn: "root",
})
export class NewProjectService {
  selectProjectTypeEvent$ = new Subject<void>();
  createProjectEvent$ = new BehaviorSubject<CreateProjectEvent | undefined>(undefined);

  constructor(
    @Inject(IS_COVER_EDITOR_ENABLED)
    protected readonly isCoverEditorEnabled: boolean,
  ) {}

  showSelectNewProjectTypeModal() {
    this.selectProjectTypeEvent$.next();
  }

  showNewProjectModal(opts: CreateProjectEvent) {
    this.createProjectEvent$.next(opts);
  }

  resetNewProjectModal() {
    this.createProjectEvent$.next(undefined);
  }

  async selectNewProjectType() {
    if (this.isCoverEditorEnabled) {
      this.showSelectNewProjectTypeModal();
      return;
    }

    this.showNewProjectModal({ projectType: "book" });
  }
}
