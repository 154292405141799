import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { adminRoutes, superadminRoutes } from "@metranpage/admin";
import { authRoutes } from "@metranpage/auth";
import { bookRoutes } from "@metranpage/book";
import { Routes } from "@metranpage/core";
import { homeRoutes } from "@metranpage/home";
import { imageGenerationRoutes } from "@metranpage/image-generation";
import { paymentsRoutes } from "@metranpage/pricing";
import { userRoutes } from "@metranpage/user";
import { routingMaintenance } from "./maintenance/routing-maintenance";

const routes: Routes = [
  ...authRoutes,
  ...userRoutes,
  ...bookRoutes,
  ...adminRoutes,
  ...superadminRoutes,
  ...paymentsRoutes,
  ...imageGenerationRoutes,
  routingMaintenance(),
  ...homeRoutes,
  { path: "", redirectTo: "/", pathMatch: "full" },
  { path: "**", redirectTo: "/" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: "always",  /* enableTracing: true  */ })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
