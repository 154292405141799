import { Component } from "@angular/core";
import { User, UserStore } from "@metranpage/user-data";

@Component({
  selector: "m-superadmin-host-page",
  template: `
    <m-superadmin-nav-view />

    <div class="content">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        height: calc(100vh - var(--app-bar-height));
        margin: 36px 24px;
      }

      .content {
      }
    `,
  ],
})
export class SuperadminHostPage {
  user?: User;

  constructor(userStore: UserStore) {
    userStore.getUserObservable().subscribe((user) => {
      this.user = user;
    });
  }
}
