import { CommonModule, NgOptimizedImage } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { fadeInOutOnEnterLeave } from "../animations/fadeInOut";

@Component({
  selector: "m-skeleton-image",
  templateUrl: "./skeleton-image.component.html",
  styleUrls: ["./skeleton-image.component.scss"],
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  animations: [fadeInOutOnEnterLeave],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonImageComponent implements AfterViewInit, OnChanges {
  @Input()
  url = "";
  @Input()
  width = "";
  @Input()
  height = "";
  @Input()
  priority = false;
  @Input()
  backgroudColor = "#e2e5e7";
  @Input()
  unit: "%" | "px" = "px";
  @Input()
  animationStyle: "shine" | "gradient" = "shine";
  @Input()
  imgObjectFit = "unset";

  protected isLoaded = false;
  protected displayUrl = "";

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.url) {
      if (changes.url.currentValue === this.displayUrl) {
        return;
      }
      this.isLoaded = false;
      this.displayUrl = "";
      this.cdr.detectChanges();
      setTimeout(() => {
        this.displayUrl = changes.url.currentValue || "";
        this.cdr.detectChanges();
      }, 0);
    }
  }

  onLoad() {
    this.isLoaded = true;
    this.cdr.detectChanges();
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    result.push(`animation-${this.animationStyle}`);

    return result;
  }
}
