import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, QueryList, ViewChildren } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MarkupFormService } from "@metranpage/book";
import { StylesSettings, TemplateFull, TemplateLocData, TemplatePreviewLoc } from "@metranpage/book-data";
import { ColorConverterService, HSV, SelectValue } from "@metranpage/components";
import {
  CAN_CREATE_PUBLIC_TEMPLATES,
  FileDropComponent,
  LoadingService,
  NotificationsPopUpService,
} from "@metranpage/core";
import { AVAILABLE_LANGUAGES, LangSpec } from "@metranpage/i18n";
import {
  Align,
  Case,
  Emphasis,
  IndentHeader,
  IndentParagraph,
  StartOn,
  StyleControlsKey,
  StyleKey,
} from "@metranpage/markup-editor";
import * as _ from "lodash-es";
import { NgxFileDropEntry } from "ngx-file-drop";
import { filter, first, takeUntil } from "rxjs";
import { NonEditableTemplate } from "../../models/non-editable-template";
import { AdminFontsService } from "../../services/fonts/fonts.service";
import { AdminNonEditableTemplatesService } from "../../services/non-editable-templates/non-editable-templates.service";
import { AdminTemplatesService } from "../../services/templates/templates.service";
import { AdminTemplatesStore } from "../../services/templates/templates.store";
import { AdminBasePage } from "../admin/admin.page";

function templateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value.isEditable && !value.nonEditableTemplateId) {
      return {
        expectedType: true,
      };
    }
    return null;
  };
}

@Component({
  selector: "m-admin-template-page",
  templateUrl: "./template.page.html",
  styleUrls: ["./template.page.scss"],
})
export class AdminTemplatePage extends AdminBasePage implements OnInit, AfterViewInit {
  template: TemplateFull | undefined;
  previews: TemplatePreviewLoc;

  availableMainFonts: SelectValue[] = [];
  availableHeadersFonts: SelectValue[] = [];

  // @ViewChild("filedrop", { static: false })
  // protected fileDropComponent!: FileDropComponent;

  @ViewChildren("filedrop", { read: FileDropComponent })
  fileDropComponents!: QueryList<FileDropComponent>;

  protected form!: FormGroup;

  protected styles: StylesSettings = {};

  protected customStylesCount = 1;

  protected widePaddingPositionOptions = [
    { id: 1, value: "left" },
    { id: 2, value: "right" },
  ];

  protected runningTitleTextOptions = [
    { id: 0, value: "custom" },
    { id: 1, value: "title" },
    { id: 2, value: "author" },
  ];

  protected runningTitlePositionOptions = [
    { id: 0, value: "top" },
    { id: 1, value: "ouside" },
    { id: 2, value: "bottom" },
  ];

  protected emphasisOptions = [
    { id: "none", value: "none" },
    { id: "bold", value: "bold" },
    { id: "italic", value: "italic" },
    { id: "bolditalic", value: "bolditalic" },
  ];

  protected alignOptions = [
    { id: "left", value: "left" },
    { id: "center", value: "center" },
    { id: "right", value: "right" },
  ];

  protected alignMainTextOptions = [
    { id: "left", value: "left" },
    { id: "leftJustify", value: "leftJustify" },
  ];

  protected caseOptions = [
    { id: "none", value: "none" },
    { id: "uppercase", value: "uppercase" },
    { id: "lowercase", value: "lowercase" },
  ];

  protected startOnOptions = [
    { id: "current-page", value: "current-page" },
    { id: "next-page", value: "next-page" },
    { id: "odd-page", value: "odd-page" },
    { id: "even-page", value: "even-page" },
  ];

  protected indentParagraphOptions = [
    { id: "none", value: "none" },
    { id: "indented-line", value: "indented-line" },
    { id: "indent-after", value: "indent-after" },
  ];

  protected indentHeaderOptions = [
    { id: "none", value: "none" },
    { id: "before", value: "before" },
    { id: "after", value: "after" },
  ];

  protected tocPositionOptions = [
    { id: "start", value: "start" },
    { id: "end", value: "end" },
  ];

  protected imagesSizeOptions = [
    { id: "small", value: "small" },
    { id: "medium", value: "medium" },
    { id: "large", value: "large" },
    { id: "fullpage", value: "fullpage" },
  ];

  protected imagesCropClassOptions = [
    // { id: "crop", value: "crop" },
    { id: "noncrop", value: "noncrop" },
  ];

  protected styleTypeOptions = [
    { id: "text", value: "text" },
    { id: "header", value: "header" },
    { id: "list", value: "list" },
    { id: "table", value: "table" },
    { id: "image", value: "image" },
    { id: "page-break", value: "page-break" },
  ];

  protected nonEditableTemplateOptions: SelectValue[] = [];
  protected nonEditableTemplateOptionsDefault: SelectValue[] = [{ id: 0, value: "None" }];

  protected nonEditableTemplates: NonEditableTemplate[] = [];

  protected defaultHSVColor: HSV = { h: 0, s: 0, v: 0 };

  constructor(
    private readonly adminTemplatesService: AdminTemplatesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly formBuilder: FormBuilder,
    private readonly colorConverterService: ColorConverterService,
    private readonly markupFormService: MarkupFormService,
    adminTemplatesStore: AdminTemplatesStore,
    private readonly cdr: ChangeDetectorRef,
    private readonly adminFontsService: AdminFontsService,
    private readonly adminNonEditableTemplatesService: AdminNonEditableTemplatesService,
    @Inject(AVAILABLE_LANGUAGES) readonly availableLanguages: LangSpec[],
    @Inject(CAN_CREATE_PUBLIC_TEMPLATES)
    readonly canCreatePublicTemplates: boolean,
  ) {
    super();

    this.previews = {};

    // this.styles = getAvailableStyles();
    // this.createForm();
    // this.watchFormChanges();

    this.addSub(
      adminTemplatesStore
        .getActiveTemplateObservable()
        .pipe(
          takeUntil(this.destroyed$),
          filter((x) => x !== undefined && x.styles !== undefined),
          first(),
        )
        .subscribe(async (template) => {
          this.template = template!;
          this.styles = template!.styles!;
          this.createForm();
          this.watchFormChanges();
          this.watchFormFontsChanges();

          await this.updateFonts(this.template.isEditable);
          await this.loadNonEditableTemplatesForCompany();

          let formValues: any = _.omit(template, [
            "colorAccent",
            "colorPrimary",
            "colorSecondary",
            "colorDecor",
            "order",
          ]);
          const colorsHex = {
            colorAccentHex: this.colorConverterService.hsv2hex(this.template.colorAccent ?? this.defaultHSVColor),
            colorPrimaryHex: this.colorConverterService.hsv2hex(this.template.colorPrimary ?? this.defaultHSVColor),
            colorSecondaryHex: this.colorConverterService.hsv2hex(this.template.colorSecondary ?? this.defaultHSVColor),
            colorDecorHex: this.colorConverterService.hsv2hex(this.template.colorDecor ?? this.defaultHSVColor),
          };
          formValues = { ...formValues, ...colorsHex };

          for (const sl of this.template.stylesLocalization || []) {
            const sk = sl.styleKey;
            if (!formValues?.styles || !formValues.styles[sk]) {
              continue;
            }
            if (!formValues.styles[sk].localization) {
              formValues.styles[sk].localization = {};
            }
            formValues.styles[sk].localization[sl.lang] = sl.value;
          }

          this.form.patchValue(formValues);

          this.cdr.detectChanges();
        }),
    );
  }

  ngOnInit(): void {}

  async ngAfterViewInit() {
    if ("templateId" in this.route.snapshot.params) {
      await this.adminTemplatesService.loadTemplate(this.route.snapshot.params["templateId"]);
      this.previews = await this.adminTemplatesService.getTemplatePreviewsLocalizations(
        this.route.snapshot.params["templateId"],
      );
    }
  }

  async saveTemplate() {
    let value = this.form.getRawValue();

    const formValues = _.omit(value, [
      "colorAccentHex",
      "colorPrimaryHex",
      "colorSecondaryHex",
      "colorDecorHex",
      "styles",
    ]);
    const colorsHsv = {
      colorAccent: this.colorConverterService.color2hsv(value.colorAccentHex),
      colorPrimary: this.colorConverterService.color2hsv(value.colorPrimaryHex),
      colorSecondary: this.colorConverterService.color2hsv(value.colorSecondaryHex),
      colorDecor: this.colorConverterService.color2hsv(value.colorDecorHex),
    };

    const styles = value.styles;

    for (const sk in styles) {
      const localization = styles[sk].localization;
      const styleKey = styles[sk].styleKey;
      styles[sk].localization = [];
      for (const [lang, value] of Object.entries(localization)) {
        styles[sk].localization.push({
          lang,
          value,
          styleKey,
        });
      }
    }

    value = { ...formValues, ...colorsHsv, styles };

    const localization: TemplateLocData[] = [];
    for (const lang of this.availableLanguages) {
      localization.push({
        lang: lang.value,
        title: value.title,
        descriptionShort: value.descriptionShort,
        descriptionLong: value.descriptionLong,
      });
    }
    value.localization = localization;

    if (value.isEditable) {
      value.nonEditableTemplateId = null;
    }

    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminTemplatesService.updateTemplate(value);
    this.loadingService.stopLoading();
    if (result === "success") {
      this.router.navigate(["admin", "templates"]);
    } else {
      this.notificationService.error($localize`:@@admin.templates.template.error.cant-update-template:`);
    }
  }

  dropped(files: NgxFileDropEntry[], lang: string) {
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) => {
        this.notificationService.closeAll();

        this.loadingService.startLoading({ fullPage: true });
        const result = await this.adminTemplatesService.uploadTemplatePreview(this.template!.id, file, lang);
        this.loadingService.stopLoading();
        if (result === "success") {
          this.previews = await this.adminTemplatesService.getTemplatePreviewsLocalizations(
            this.route.snapshot.params["templateId"],
          );
        } else if (result === "error") {
          this.notificationService.error($localize`:@@admin.templates.template.error.cant-upload-preview:`);
        }
      });
    }
  }

  async deletePreview(id: number) {
    this.notificationService.closeAll();

    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminTemplatesService.deleteTemplatePreview(id);
    this.loadingService.stopLoading();
    if (result === "success") {
      this.previews = await this.adminTemplatesService.getTemplatePreviewsLocalizations(
        this.route.snapshot.params["templateId"],
      );
    } else if (result === "error") {
      this.notificationService.error($localize`:@@admin.templates.template.error.cant-upload-preview:`);
    }
  }

  openFileSelector(index: number) {
    const fileDropComponent = this.fileDropComponents.find((_, i) => i === index);
    if (!fileDropComponent) {
      return;
    }

    fileDropComponent.openFileSelector();
  }

  getStyleKeys(): StyleKey[] {
    // return _.filter(this.styles, (s) => s.isConfigurableAtDetailsSidebar).map((s) => s.styleKey!);
    return Object.keys(this.styles);
  }

  getStyleLocalization(sk: StyleKey) {
    return this.styles[sk].localization.map((sl) => sl.lang);
  }

  createForm() {
    const stylesControls: any = {};

    const sortedStyles = Object.entries(this.styles)
      .sort((a, b) => a[1].order - b[1].order)
      .reduce((acc: any, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    for (const style of Object.keys(sortedStyles)) {
      // if (['image', 'table', 'list'].includes(style)) {
      //   continue;
      // }

      const localization = this.formBuilder.group({});
      const sortedStylesLocales = this.styles[style].localization.sort((a, b) => b.lang.localeCompare(a.lang));
      for (const l of sortedStylesLocales) {
        const lang = l.lang;
        const locale = this.formBuilder.control(l.value, {
          nonNullable: true,
          validators: [Validators.required],
        });

        localization.addControl(lang, locale);
      }

      stylesControls[style] = this.formBuilder.group({
        font: this.formBuilder.control("Pt Serif", { nonNullable: true, validators: [Validators.required] }),
        fontSize: this.formBuilder.control(10, { nonNullable: true, validators: [Validators.required] }),
        emphasis: this.formBuilder.control("none" as Emphasis, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        align: this.formBuilder.control("left" as Align, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        case: this.formBuilder.control("none" as Case, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        startsOn: this.formBuilder.control("current-page" as StartOn, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        oneOnPage: this.formBuilder.control(false, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        dropCapChars: this.formBuilder.control(0, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        dropCapLines: this.formBuilder.control(0, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        indentParagraph: this.formBuilder.control("none" as IndentParagraph, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        indentParagraphValue: this.formBuilder.control(0, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        indentParagraphAfterHeader: this.formBuilder.control(false, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        indentHeader: this.formBuilder.control("none" as IndentHeader, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        leading: this.formBuilder.control(120, { nonNullable: true, validators: [Validators.required] }),
        tracking: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),

        isDisplayedAtSidebar: this.formBuilder.control(false, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        isConfigurableAtDetailsSidebar: this.formBuilder.control(false, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        availableControls: this.formBuilder.control([] as StyleControlsKey[], {
          nonNullable: true,
          validators: [],
        }),

        localization: localization,
        type: this.formBuilder.control("text", { nonNullable: true, validators: [Validators.required] }),

        // empty
        styleDecorationId: this.formBuilder.control(1),
        styleKey: this.formBuilder.control(style, { nonNullable: true, validators: [Validators.required] }),
        order: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
      });
    }

    this.form = this.formBuilder.group(
      {
        id: this.formBuilder.control(0),
        companyId: this.formBuilder.control(1),

        title: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),
        descriptionShort: this.formBuilder.control("", { nonNullable: false, validators: [] }),
        descriptionLong: this.formBuilder.control("", { nonNullable: false, validators: [] }),
        isAvailableToUser: this.formBuilder.control(false, { nonNullable: true, validators: [Validators.required] }),
        isVisibleToOtherCompanies: this.formBuilder.control(false, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        isPremium: this.formBuilder.control(false, { nonNullable: true, validators: [Validators.required] }),
        widthFrom: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        widthTo: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        heightFrom: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        heightTo: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        //
        fontMain: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),
        fontHeading: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),
        mainSize: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        //
        colorAccentHex: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),
        colorPrimaryHex: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),
        colorSecondaryHex: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),
        colorDecorHex: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),

        centerImages: this.formBuilder.control(true, { nonNullable: true, validators: [Validators.required] }),

        tocVisible: this.formBuilder.control(false, { nonNullable: true }),
        tocPosition: this.formBuilder.control("start", { nonNullable: true, validators: [Validators.required] }),
        //
        marginTop: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        marginBottom: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        marginOuter: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        marginInner: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),

        columnsCount: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        gutter: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        widePadding: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        widePaddingState: this.formBuilder.control(false, { nonNullable: true, validators: [Validators.required] }),
        widePaddingLeftPosition: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        widePaddingRightPosition: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        //
        runningTitleState: this.formBuilder.control(true, { nonNullable: true, validators: [Validators.required] }),
        runningTitleLeft: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        runningTitleLeftText: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),
        runningTitleRight: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        runningTitleRightText: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),
        runningTitlePosition: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        runningTitleStyle: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        pageNumberState: this.formBuilder.control(true, { nonNullable: true, validators: [Validators.required] }),
        pageNumberPosition: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        pageNumberStyle: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),

        // empty fields
        bookSettingsId: this.formBuilder.control(0),
        width: this.formBuilder.control(0),
        height: this.formBuilder.control(0),
        hideWatermark: this.formBuilder.control(false),
        updatedAt: this.formBuilder.control(new Date()),

        styles: this.formBuilder.record(stylesControls),

        isEditable: this.formBuilder.control(true, { nonNullable: true, validators: [Validators.required] }),
        nonEditableTemplateId: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),
        isImagesSettingsAvailable: this.formBuilder.control(true, {
          nonNullable: true,
          validators: [Validators.required],
        }),
        imagesSize: this.formBuilder.control("medium", { nonNullable: true, validators: [Validators.required] }),
        imagesCropClass: this.formBuilder.control("noncrop", { nonNullable: true, validators: [Validators.required] }),
      },
      // { validator: [templateValidator()] },
    );
  }

  watchFormChanges() {
    this.addSub(
      this.form?.get("isEditable")?.valueChanges.subscribe(async (value) => {
        if (value) {
          this.form?.get("nonEditableTemplateId")?.setValue(0, { emitEvent: false });
        }
        await this.updateFonts(value);
      }),
    );

    this.addSub(
      this.form?.get("nonEditableTemplateId")?.valueChanges.subscribe((value) => {
        if (!value) {
          this.form?.get("isEditable")?.setValue(true, { emitEvent: false });
          return;
        }
        this.form?.get("isEditable")?.setValue(false, { emitEvent: false });
      }),
    );

    this.addSub(
      this.form?.get("imagesSize")?.valueChanges.subscribe((value) => {
        if (value !== "fullpage") {
          this.form?.get("imagesCropClass")?.setValue("noncrop");
        }
      }),
    );
  }

  watchFormFontsChanges() {
    this.addSub(
      this.form?.get("fontHeading")?.valueChanges.subscribe((value) => {
        this.updateHeaderFont(value);
      }),
    );

    this.addSub(
      this.form?.get("fontMain")?.valueChanges.subscribe((value) => {
        this.updateMainFont(value);
      }),
    );
  }

  updateHeaderFont(value: string) {
    const styleFonts = {} as any;
    styleFonts.header1 = { font: value };
    styleFonts.header2 = { font: value };
    styleFonts.header3 = { font: value };
    styleFonts.header4 = { font: value };

    this.form?.patchValue({ styles: styleFonts });
  }

  updateMainFont(value: string) {
    const styleFonts = {} as any;
    for (const sk of Object.keys(this.styles)) {
      if (sk.includes("header")) {
        continue;
      }
      styleFonts[sk] = { font: value };
    }

    this.form?.patchValue({ styles: styleFonts });
  }

  async updateFonts(isBookSettingsEditable: boolean) {
    const fonts = await this.adminFontsService.getFonts();
    this.availableMainFonts = await this.markupFormService.getAvailableMainFonts(fonts, isBookSettingsEditable);
    this.availableHeadersFonts = await this.markupFormService.getAvailableHeaderFonts(fonts, isBookSettingsEditable);

    this.cdr.detectChanges();
  }

  private async loadNonEditableTemplatesForCompany() {
    this.nonEditableTemplates = await this.adminNonEditableTemplatesService.getNonEditableTemplates();
    this.nonEditableTemplateOptions = [...this.nonEditableTemplateOptionsDefault];
    for (const nonEditableTemplate of this.nonEditableTemplates) {
      this.nonEditableTemplateOptions.push({ id: nonEditableTemplate.id, value: nonEditableTemplate.title });
    }

    this.cdr.detectChanges();
  }

  getImagesSizeOptions() {
    return this.imagesSizeOptions;
  }

  getImagesCropClassOptions() {
    const imagesSize = this.form?.get("imagesSize")?.value;
    let imagesCropClassOptions: SelectValue[] = [];
    imagesCropClassOptions = imagesCropClassOptions.concat(this.imagesCropClassOptions);

    if (imagesSize === "fullpage") {
      imagesCropClassOptions.push({ id: "crop", value: "crop" });
    }
    return imagesCropClassOptions;
  }

  protected getStyleFonts(style: StyleKey) {
    if (style.includes("header")) {
      return this.availableHeadersFonts;
    }
    return this.availableMainFonts;
  }

  protected addStyle() {
    let newStyleKey = `StyleName${this.customStylesCount}`;

    while (this.styles[newStyleKey]) {
      this.customStylesCount++;
      newStyleKey = `StyleName${this.customStylesCount}`;
    }

    const order = Object.values(this.styles).reduce((acc, curr) => (acc.order > curr.order ? acc : curr)).order + 1;

    this.styles[newStyleKey] = {
      styleKey: newStyleKey,
      align: "left",
      case: "none",
      emphasis: "none",
      font: "Pt Serif",
      fontSize: 10,
      leading: 120,
      tracking: 0,
      oneOnPage: false,
      startsOn: "current-page",
      styleDecorationId: 1,
      dropCapChars: 1,
      dropCapLines: 3,
      indentParagraph: "indented-line",
      indentParagraphValue: 0,
      indentParagraphAfterHeader: false,
      indentHeader: "none",
      isShowedInToc: false,

      visibleTitle: newStyleKey,
      localization: [
        {
          lang: "ru",
          value: `${newStyleKey}`,
          styleKey: newStyleKey,
          templateId: this.route.snapshot.params["templateId"],
        },
        {
          lang: "en",
          value: `${newStyleKey}`,
          styleKey: newStyleKey,
          templateId: this.route.snapshot.params["templateId"],
        },
      ],

      isDisplayedAtSidebar: true,
      isConfigurableAtDetailsSidebar: true,
      availableControls: ["emphasis", "align", "case", "startsOn", "indent-header", "font-size", "leading", "tracking"],

      order,
      type: "text",
    };

    const localization = this.formBuilder.group({});
    const sortedStylesLocales = this.styles[newStyleKey].localization.sort((a, b) => b.lang.localeCompare(a.lang));
    for (const l of sortedStylesLocales) {
      const lang = l.lang;
      const locale = this.formBuilder.control(l.value, {
        nonNullable: true,
        validators: [Validators.required],
      });

      localization.addControl(lang, locale);
    }

    const stylesGroup = this.form?.get("styles") as FormGroup;

    const style = this.formBuilder.group({
      font: this.formBuilder.control("PT Serif", { nonNullable: true, validators: [Validators.required] }),
      fontSize: this.formBuilder.control(10, { nonNullable: true, validators: [Validators.required] }),
      emphasis: this.formBuilder.control("none" as Emphasis, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      align: this.formBuilder.control("left" as Align, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      case: this.formBuilder.control("none" as Case, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      startsOn: this.formBuilder.control("current-page" as StartOn, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      oneOnPage: this.formBuilder.control(false, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      dropCapChars: this.formBuilder.control(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      dropCapLines: this.formBuilder.control(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      indentParagraph: this.formBuilder.control("none" as IndentParagraph, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      indentParagraphValue: this.formBuilder.control(0, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      indentParagraphAfterHeader: this.formBuilder.control(false, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      indentHeader: this.formBuilder.control("none" as IndentHeader, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      leading: this.formBuilder.control(120, { nonNullable: true, validators: [Validators.required] }),
      tracking: this.formBuilder.control(0, { nonNullable: true, validators: [Validators.required] }),

      isDisplayedAtSidebar: this.formBuilder.control(false, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      isConfigurableAtDetailsSidebar: this.formBuilder.control(false, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      availableControls: this.formBuilder.control(
        ["align-maintext", "align", "indent-paragraph", "drop-cap", "font-size"] as StyleControlsKey[],
        {
          nonNullable: true,
          validators: [Validators.required],
        },
      ),

      localization: localization,
      type: this.formBuilder.control("text", { nonNullable: true, validators: [Validators.required] }),

      // empty
      styleDecorationId: this.formBuilder.control(1),
      styleKey: this.formBuilder.control(newStyleKey, { nonNullable: true, validators: [Validators.required] }),
      order: this.formBuilder.control(order, { nonNullable: true, validators: [Validators.required] }),
    });

    stylesGroup.addControl(newStyleKey, style);
    this.customStylesCount++;

    this.cdr.detectChanges();
  }
}
