<m-modal [closeButtonVisible]="true" [closeOnBackDropClick]="true" (close)="onCloseClick()">
  <div class="container">
    <div class="info">
      <div class="header" i18n="@@published-image.share-image.header">published-image.share-image.header</div>

      <m-localized-text
        *ngIf="canPublish()"
        class="description"
        text="published-image.share-image.description"
        i18n-text="@@published-image.share-image.description"
      />

      <m-localized-text
        *ngIf="isModerating()"
        class="description"
        text="published-image.share-image.moderation-processing"
        i18n-text="@@published-image.share-image.moderation-processing"
      />

      <m-localized-text
        *ngIf="isPublished()"
        class="description"
        text="published-image.share-image.moderation-accepted"
        i18n-text="@@published-image.share-image.moderation-accepted"
      />

      <span *ngIf="isDeclined()" class="description">
        <m-localized-text
          *ngIf=" hasReason()"
          text="published-image.share-image.moderation-declined"
          i18n-text="@@published-image.share-image.moderation-declined"
        />

        <m-localized-text
          *ngIf=" !hasReason()"
          text="published-image.share-image.moderation-declined.without-reason"
          i18n-text="@@published-image.share-image.moderation-declined.without-reason"
        />

        <span *ngIf="moderationRequest?.moderatorDescription && hasReason()">
          {{ moderationRequest?.moderatorDescription }}
        </span>
      </span>
    </div>

    <m-button
      *ngIf="!user?.isAdmin && canPublish()"
      button-style="primary"
      size="full-width"
      icon="checkmark-02.svg"
      icon-position="left"
      i18n="@@published-image.share-image.share-button-text"
      (onClick)="publishClick()"
    >
      published-image.share-image.share-button-text
    </m-button>

    <m-button
      *ngIf="user?.isAdmin && canPublish()"
      button-style="primary"
      size="full-width"
      icon="checkmark-02.svg"
      icon-position="left"
      i18n="@@image-generation.image.publish-image-button"
      (onClick)="publishClick()"
    >
      image-generation.image.publish-image-button
    </m-button>

    <!-- <div class="divider"></div>

    <m-localized-text
      class="description"
      text="published-image.share-image.share-socials"
      i18n-text="@@published-image.share-image.share-socials"
    />
    <div class="socials-links"></div> -->
  </div>
</m-modal>
