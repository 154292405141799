import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import {
  Book,
  CoverObjectType,
  PredefinedSvgObjects,
  SvgObjectCategory,
  UploadGeneratedImageRequestDto,
} from "@metranpage/book-data";
import { IS_IMAGES_GENERATION_AVAILABLE, LoadingService } from "@metranpage/core";
import { GeneratedImage } from "@metranpage/image-generation";
import { NgxFileDropEntry } from "ngx-file-drop";
import { CoverService } from "../../services/cover/cover.service";

export type CreateCoverObject = {
  type: CoverObjectType;
  imageName?: string;
  svgData?: string;
};

@Component({
  selector: "m-cover-object-create",
  templateUrl: "./cover-object-create.component.html",
  styleUrls: ["./cover-object-create.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverObjectCreateComponent {
  @Input() book!: Book;
  @Input() predefinedSvgObjects: PredefinedSvgObjects[] = [];

  @Output() create = new EventEmitter<CreateCoverObject>();
  @Output() showTemplates = new EventEmitter();
  @Output() showConceptualAssistantMenu = new EventEmitter();

  @ViewChild("fileUpload") fileUploadElement!: ElementRef;

  CoverObjectType = CoverObjectType;

  isShapeOverlayVisible = false;

  isImageGeneratorVisible = false;
  imageFileTypes = ".jpg,.jpeg,.png";

  predefinedSvgObjectsCategory: PredefinedSvgObjects | undefined = undefined;

  SvgObjectCategory = SvgObjectCategory;

  protected imageSize = {
    width: 148,
    height: 210,
  };

  constructor(
    private readonly _coverService: CoverService,
    private readonly _loadingService: LoadingService,
    @Inject(IS_IMAGES_GENERATION_AVAILABLE)
    protected readonly isImagesGenerationAvailable: boolean,
  ) {}

  ngOnInit() {
    this.imageSize = {
      width: this.book.bookSettings?.width ?? 148,
      height: this.book.bookSettings?.height ?? 210,
    };
  }

  createCoverObject(type: CoverObjectType, data?: any) {
    this.create.emit({ type, svgData: data });
  }

  async uploadImage(event: NgxFileDropEntry[]) {
    if (!event.length) {
      return;
    }
    const fileEntry = event[0].fileEntry as FileSystemFileEntry;
    fileEntry.file(async (file: File) => {
      this._loadingService.startLoading({ fullPage: true });
      const result = await this._coverService.uploadImage(this.book.id, file, "object");
      this._loadingService.stopLoading();
      this.create.emit({ type: CoverObjectType.Image, imageName: result.name });
    });
  }

  getCategoryCaption(category: SvgObjectCategory): string | undefined {
    if (category === SvgObjectCategory.BasicShapes) {
      return $localize`:@@cover-editor.object.create.object.basic-shapes.header.caption:`;
    }
    if (category === SvgObjectCategory.AgeConstraints) {
      return $localize`:@@cover-editor.object.create.object.age-constraints.header.caption:`;
    }
    if (category === SvgObjectCategory.Arrows) {
      return $localize`:@@cover-editor.object.create.object.arrows.header.caption:`;
    }
    if (category === SvgObjectCategory.Splashes) {
      return $localize`:@@cover-editor.object.create.object.splashes.header.caption:`;
    }
    return undefined;
  }

  async selectGeneratedImage(image: GeneratedImage) {
    this._loadingService.startLoading({ fullPage: true });
    this.isImageGeneratorVisible = false;
    const result = await this._coverService.uploadGeneratedObjectImage(<UploadGeneratedImageRequestDto>{
      bookId: this.book.id,
      generationId: image.imageGenerationId,
      src: image.imageUrl,
    });
    this._loadingService.stopLoading();
    this.create.emit({ type: CoverObjectType.Image, imageName: result.name });
  }

  showImageGenerator() {
    this.isImageGeneratorVisible = true;
  }

  hideImageGenerator() {
    this.isImageGeneratorVisible = false;
  }

  showTemplatesHandle() {
    this.showTemplates.emit();
  }

  onShowConceptualAssistantMenu() {
    this.showConceptualAssistantMenu.emit();
  }

  showShapesOverlay(show: boolean) {
    this.isShapeOverlayVisible = show;
  }
}
