<div *ngIf="pm; else emptyCard" class="card">
  <div class="card-data">
    <m-icon [src]="getPaymentMethodTypeIcon(pm.type, pm.cardBrand)" size="original" />
    <div *ngIf="pm.type === 'card'" class="card-info">
      <span class="card-number">**** {{ pm.cardNumber?.slice(-4) }}</span>
      <span class="card-expire-date">{{ pm.cardExpDate }}</span>
    </div>
  </div>

  <div class="divider"></div>

  <div class="footer">
    <m-radio class="selector" [isChecked]="isActive" (click)="setPaymentMethodForPayment()">
      <span *ngIf="isActive" i18n="@@user.profile.tariff-and-payments.card-active">
        user.profile.tariff-and-payments.card-active
      </span>
      <span *ngIf="!isActive" i18n="@@user.profile.tariff-and-payments.card-select">
        user.profile.tariff-and-payments.card-select
      </span>
    </m-radio>

    <m-ng-icon class="card-delete-btn" src="book-delete.svg" [size]="24" (click)="showDeletePaymentMethodModal()" />
  </div>
</div>

<ng-template #emptyCard>
  <div *ngIf="newPaymentMethod" class="new-card" (click)="addNewPaymentMethod()">
    <m-ng-icon src="plus.svg" [size]="28" />
    <span i18n="@@user.profile.tariff-and-payments.add-card">user.profile.tariff-and-payments.add-card</span>
  </div>
  <div *ngIf="!newPaymentMethod" class="card"></div>
</ng-template>
