<m-modal
  [paddingSize]="0"
  [closeButtonVisible]="true"
  [closeOnBackDropClick]="true"
  (close)="onCloseConceptualAssistantClick()"
>
  <div class="container">
    <div class="header-wrapper">
      <div class="header">
        <m-localized-text
          class="title"
          i18n-text="@@cover-editor.conceptual-assistant.start-modal.title"
          text="cover-editor.conceptual-assistant.start-modal.title"
        />

        <m-localized-text
          class="description"
          i18n-text="@@cover-editor.conceptual-assistant.start-modal.description"
          text="cover-editor.conceptual-assistant.start-modal.description"
        />
      </div>
    </div>

    <img class="image" src="/assets/img/bot_3.png" />

    <form *ngIf="form" class="params" [formGroup]="form">
      <div class="control">
        <m-textarea
          class="textarea"
          [rows]="10"
          [maxlength]="promptAnnotationMaxLength"
          [isCharactersCountVisible]="true"
          placeholder="cover-editor.conceptual-assistant.start-modal.annotation-placeholder"
          i18n-placeholder="@@cover-editor.conceptual-assistant.start-modal.annotation-placeholder"
          formControlName="promptAnnotation"
          type="normal"
        />

        <m-info-block type="error" [labels]="infoBlockData" [iconSize]="24" [singleIcon]="true" />
      </div>
    </form>

    <div class="button">
      <m-button
        button-style="primary"
        size="full-width"
        i18n="@@cover-editor.conceptual-assistant.menu.generate-button"
        (onClick)="onGenerateCoverConceptualClick()"
      >
        cover-editor.conceptual-assistant.menu.generate-button
      </m-button>
    </div>
  </div>
</m-modal>
