import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { Breadcrumb, RouterService } from "@metranpage/core";
import { FormatService } from "@metranpage/format-data";
import { UserStore } from "@metranpage/user-data";
import { filter, firstValueFrom } from "rxjs";
import { BooksStore } from "./books.store";

@Injectable({
  providedIn: "root",
})
export class BookBreadcrumbsResolver {
  constructor(
    private readonly formatService: FormatService,
    private readonly booksStore: BooksStore,
    private readonly userStore: UserStore,
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<Breadcrumb[]> {
    const book = await firstValueFrom(this.booksStore.getActiveBookObservable().pipe(filter((b) => !!b)));
    if (!book) {
      return [];
    }

    const activeSubscription = await firstValueFrom(this.userStore.getActiveSubscriptionObservable().pipe());
    const isAllowedToShow =
      ((activeSubscription?.hasPaidTariff || activeSubscription?.hasTrialPeriod) && book.bookSettings?.isEditable) ??
      false;

    // const isAllowedToMove = !book.actionKey;
    const isAllowedToMove = true;

    const formatData = this.formatService.getFormatData(book.bookSettings?.width, book.bookSettings?.height);

    const bcBookData: Breadcrumb = {
      title: $localize`:@@books.breadcrumb.book-data:`,
      url: [
        {
          outlets: {
            modal: ["edit", book.id, "book-data", formatData.width, formatData.height, book.selectedTemplateId],
          },
        },
      ],
      isEnabled: isAllowedToMove,
      isVisible: true,
    };

    const bcUpload: Breadcrumb = {
      title: $localize`:@@books.modal.header.upload-step:`,
      url: [
        {
          outlets: {
            modal: ["edit", book.id, "book-upload", formatData.width, formatData.height, book.selectedTemplateId],
          },
        },
      ],
      isEnabled: isAllowedToMove,
      isVisible: true,
    };
    const bcTemplate: Breadcrumb = {
      title: $localize`:@@books.breadcrumb.template-step:`,
      url: [
        {
          outlets: {
            modal: ["edit", book.id, "book-templates", formatData.width, formatData.height],
          },
        },
      ],
      isEnabled: isAllowedToMove && ["template", "markup", "layout", "preview"].includes(book.lastEditStep),
      isVisible: true,
    };
    const bcMarkup: Breadcrumb = {
      title: $localize`:@@books.breadcrumb.markup-step:`,
      url: `books/${book.id}/markup`,
      isEnabled: isAllowedToMove && ["markup", "layout", "preview"].includes(book.lastEditStep),
      isVisible: true,
    };
    const bcLayout: Breadcrumb = {
      title: $localize`:@@books.breadcrumb.layout-step:`,
      url: `books/${book.id}/margins`,
      urls: [`books/${book.id}/margins`, `books/${book.id}/running-titles`],
      isEnabled: isAllowedToMove && ["layout", "preview"].includes(book.lastEditStep),
      isVisible: book.exportPrint && isAllowedToShow,
    };
    const bcResult: Breadcrumb = {
      title: $localize`:@@books.breadcrumb.preview:`,
      url: `books/${book.id}/preview`,
      isEnabled: isAllowedToMove && ["preview"].includes(book.lastEditStep),
      isVisible: true,
    };

    return [bcTemplate, bcUpload, bcBookData, bcMarkup, bcLayout, bcResult];
  }
}
