import { Component, ElementRef, EventEmitter, HostListener, Input, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ColorConverterService, PaletteColorName, PaletteDTO } from "@metranpage/components";
import * as _ from "lodash-es";

@Component({
  selector: "m-color-palette",
  templateUrl: "./color-palette.component.html",
  styleUrls: ["./color-palette.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPaletteComponent),
      multi: true,
    },
  ],
})
export class ColorPaletteComponent implements ControlValueAccessor {
  @Input()
  options: PaletteDTO[] = [];
  @Input()
  isRequired = false;
  @Input()
  name = "";
  @Input()
  tabIndex = 0;

  @Output()
  onShowSettings = new EventEmitter<void>();

  protected isMenuOpened = false;
  protected palette?: PaletteDTO;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  private isDisabled = false;

  constructor(
    private readonly elementRef: ElementRef,
    private colorConverterService: ColorConverterService,
  ) {}

  writeValue(palette: PaletteDTO): void {
    this.setPalette(palette);
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private setPalette(palette: PaletteDTO | undefined) {
    if (!palette) {
      return;
    }
    this.palette = _.clone(palette);
  }

  protected onFieldClick() {
    if (this.isDisabled) {
      return;
    }

    this.isMenuOpened = !this.isMenuOpened;
    if (this.isMenuOpened) {
      this.onTouched();
    }
  }

  protected getColorNames(): PaletteColorName[] {
    if (!this.palette) {
      return [];
    }
    return Object.keys(_.omit(this.palette, "colorDecor")) as PaletteColorName[];
    // return Object.keys(this.palette) as PaletteColorName[];
  }

  protected onShowSettingsClick() {
    this.onShowSettings.emit();
  }

  protected onOptionClick(palette: PaletteDTO) {
    this.isMenuOpened = false;
    this.setPalette(palette);
    this.onChange(this.palette);
  }

  protected getArrowIconClassList(): string[] {
    if (this.isMenuOpened) {
      return ["reversed"];
    }
    return [];
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    if (this.isMenuOpened) {
      result.push("focused");
    }
    if (this.isDisabled) {
      result.push("disabled");
    }
    return result;
  }

  protected getColorStyle(palette: PaletteDTO | undefined, colorName: PaletteColorName) {
    const color = this.colorConverterService.hsv2hex(palette?.[colorName] ?? { h: 0, s: 0, v: 0 });
    return { "background-color": color };
  }

  @HostListener("document:click", ["$event"])
  onClickOutside(event: Event) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      // click in component
    } else {
      if (this.isMenuOpened) {
        this.isMenuOpened = false;
      }
    }
  }
}
