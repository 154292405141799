<div class="container-wrapper">
  <m-ng-island class="header">
    <h1 i18n="@@books.list.title">books.list.title</h1>

    <m-button button-style="primary" icon="plus.svg" icon-position="left" (onClick)="showNewProjectModal()">
      <div class="premium-wrapper">
        <span i18n="@@books.book.create-button">books.book.create-button</span>
        <m-premium-mark *ngIf="isPremiumLabelVisible" />
      </div>
    </m-button>
  </m-ng-island>

  <div class="projects">
    <m-ng-scrollbars [options]="{overflow: {x: 'hidden'}}" (onScrolled)="onScrolled()">
      <m-ng-island class="empty" *ngIf="!(books$ | async)?.length">
        <div class="empty-container">
          <img src="/assets/img/books-empty.png" alt="" class="empty-icon" />
          <div class="text" i18n="@@books.list.empty">books.list.empty</div>
          <!-- <div>
        <m-button button-style="primary" i18n="@@books.book.create-button" (onClick)="onNewBookClick()">
          books.book.create-button
        </m-button>
      </div> -->
        </div>
      </m-ng-island>

      <div class="books" *ngIf="(books$ | async)?.length">
        <m-book-card
          class="book"
          *ngFor="let book of books$ | async"
          [book]="book"
          [hasPremium]="hasPremium"
          (onEditClick)="setBookToEdit(book)"
          (onCopyClick)="copyBook(book)"
          (onDeleteClick)="setBookToDelete(book)"
        ></m-book-card>
      </div>
    </m-ng-scrollbars>
  </div>
</div>

<m-delete-project-modal
  *ngIf="bookToDelete"
  [project-title]="bookToDelete.title"
  (onCancel)="cancelDeletion()"
  (onDelete)="deleteBook(bookToDelete)"
/>

<m-info-modal
  *ngIf="isChangeTariffModalVisible"
  [title]="getInfoModalTitle()"
  [text]="getInfoModalText()"
  actionText="books.info-modal.projects-count-limit.action-text"
  i18n-actionText="@@books.info-modal.projects-count-limit.action-text"
  [backgroundImage]="'/assets/img/info-modal-projects-count-limit.png'"
  (actionClick)="onClickChangeTariffModal()"
  (close)="onCloseChangeTariffModal()"
/>

<m-modal-project-router-host />

<m-premium-tariffs-modal @fadeInOut *ngIf="isPremiumModalVisible" (close)="closePremiumModal()" />
