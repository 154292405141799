import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import { TemplateAccessDto, TemplateAccessUpdateData, TemplatesAccessData } from "../../models/template-access";

export type TemplatesAccessFilter = {};

@Injectable({
  providedIn: "root",
})
export class AdminTemplatesAccessApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  getTemplatesAccess() {
    return this.get<TemplateAccessDto[]>("admin/templates-access");
  }

  getTemplatesAccessPaginated(page: number, filters: TemplatesAccessFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<TemplatesAccessData>("admin/templates-access/paginated", {
      page,
      ...clearedFilters,
    });
  }

  async updateTemplateAccess(id: number, data: TemplateAccessUpdateData) {
    return this.post<unknown>(`admin/templates-access/${id}`, data);
  }
}
