import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import type { PartialOptions } from "overlayscrollbars";
import { OverlayScrollbarsComponent, OverlayscrollbarsModule } from "overlayscrollbars-ngx";
import { timer } from "rxjs";

@Component({
  selector: "m-ng-scrollbars",
  standalone: true,
  imports: [CommonModule, InfiniteScrollModule, OverlayscrollbarsModule],
  templateUrl: "./scrollbars.component.html",
  styleUrls: ["./scrollbars.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollbarsComponent implements OnInit {
  @Input()
  options?: PartialOptions | false | null;
  @Input()
  padding?: string;

  @Output()
  onScroll = new EventEmitter();

  @ViewChild("overlayScrollbarsElement", { static: false })
  protected overlayScrollbarsElement?: OverlayScrollbarsComponent;

  protected scrollEventElement?: HTMLElement | Document;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    timer(100).subscribe(() => {
      this.scrollEventElement = this.overlayScrollbarsElement?.osInstance()?.elements().scrollEventElement;
      this.cdr.detectChanges();
    });
  }

  protected onScrollEvent() {
    this.onScroll.emit();
  }
}
