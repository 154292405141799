<m-account-notification-card-host [notification]="notification" [isLast]="isLast">
  <div icon class="icons-wrapper">
    <m-ng-icon class="icon-main" [size]="24" src="notification-info-2.svg" />
  </div>

  <div title class="title-group">
    <div class="title">
      <span>{{ getTitle() }}</span>
    </div>

    <div class="card-label" i18n="@@user.profile.notifications.filter.important">
      user.profile.notifications.filter.important
    </div>
  </div>

  <div description class="description">
    <div *ngIf="payload.tariffTitle && payload.subscriptionPeriod" class="row">
      <span i18n="@@user.profile.notifications.card.promocode-activate.subscription.description-1">
        user.profile.notifications.card.promocode-activate.subscription.description-1
      </span>
      <a class="action" (click)="openTariffsPage()">{{ payload.tariffTitle }}</a>
      <span>{{ getSubscriptionPeriod() }}</span>
      <span i18n="@@user.profile.notifications.card.promocode-activate.subscription.description-3">
        user.profile.notifications.card.promocode-activate.subscription.description-3
      </span>
    </div>

    <div *ngIf="payload.creditsCount || payload.goldCreditsCount" class="row">
      <span>{{ getTokensDescription() }}</span>
    </div>

    <div *ngIf="payload.discontForSubscription" class="row">
      <span i18n="@@user.profile.notifications.card.promocode-activate.discount.description-1">
        user.profile.notifications.card.promocode-activate.discount.description-1
      </span>
      <span i18n="@@user.profile.notifications.card.promocode-activate.discount.description-2">
        user.profile.notifications.card.promocode-activate.discount.description-2
      </span>
      <a class="action" (click)="openTariffsPage()">{{ payload.discontForSubscription }}%</a>
      <span i18n="@@user.profile.notifications.card.promocode-activate.discount.description-3">
        user.profile.notifications.card.promocode-activate.discount.description-3
      </span>
      <span>{{ getDateShort() }}</span>
    </div>
  </div>
</m-account-notification-card-host>
