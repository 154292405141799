import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Book } from "@metranpage/book-data";
import { UserStore } from "@metranpage/user-data";
import { Observable, map } from "rxjs";
import { BookRouterService } from "../../services/book-router.service";

@Component({
  selector: "m-cover-completion-modal",
  templateUrl: "./cover-completion-modal.component.html",
  styleUrls: ["./cover-completion-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverCompletionModalComponent {
  @Input() book!: Book;

  @Output() close = new EventEmitter();
  @Output() download = new EventEmitter();

  isSettingsAvailable$!: Observable<boolean>;

  constructor(
    private readonly bookRouterService: BookRouterService,
    userStore: UserStore,
  ) {
    this.isSettingsAvailable$ = userStore.getActiveSubscriptionObservable().pipe(
      map((activeSubscription) => {
        if (!activeSubscription) {
          return false;
        }
        const hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
        const hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;
        return hasPaidTariff || hasTrialPeriod;
      }),
    );
  }

  downloadCoverImage() {
    this.download.emit();
  }

  onProcessBookClick() {
    this.bookRouterService.navigateToLastBookEditStep(this.book);
  }
}
