import { AfterViewInit, Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { takeUntil } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { Company } from "../../models/company";
import { ReferalLink } from "../../models/referal-link";
import { AdminCompanyStore } from "../../services/company/company.store";
import { AdminReferalLinksService } from "../../services/referal-links/referal-links.service";
import { AdminReferalLinksStore } from "../../services/referal-links/referal-links.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-referal-link-page",
  templateUrl: "./referal-link.page.html",
  styleUrls: ["./referal-link.page.scss"],
})
export class AdminReferalLinkPage extends AdminBasePage implements AfterViewInit {
  protected referalLink: ReferalLink | undefined;
  protected referalLinkUrl = "";

  company: Company | undefined;

  protected form!: FormGroup;

  constructor(
    private readonly adminReferalLinksService: AdminReferalLinksService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    adminCompanyStore: AdminCompanyStore,
    adminReferalLinksStore: AdminReferalLinksStore,
  ) {
    super();

    this.createForm();
    this.watchFormChanges();

    this.addSub(
      adminReferalLinksStore
        .getActiveReferalLinkObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((referalLink) => {
          this.referalLink = referalLink;
          if (referalLink) {
            this.form.patchValue(referalLink);
          }
        }),
    );

    this.addSub(
      adminCompanyStore
        .getCompanyObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((company) => {
          this.company = company;
        }),
    );
  }

  async ngAfterViewInit() {
    if ("referalLinkId" in this.route.snapshot.params) {
      this.adminReferalLinksService.loadReferalLink(this.route.snapshot.params["referalLinkId"]);
    }
  }

  async onSave() {
    if (!this.referalLink) {
      return;
    }

    const formData = this.form.getRawValue();

    if (formData.createdDate) {
      formData.createdDate = new Date(formData.createdDate);
    } else {
      formData.createdDate = undefined;
    }
    if (formData.expireDate) {
      formData.expireDate = new Date(formData.expireDate);
    } else {
      formData.expireDate = undefined;
    }

    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminReferalLinksService.updateReferalLink(this.referalLink.id, formData);
    this.loadingService.stopLoading();
    if (result === "success") {
      this.router.navigate(["admin", "referal-links"]);
    } else {
      this.notificationService.error($localize`:@@admin.referal-links.referal-link.error.cant-update-referal-link:`);
    }
  }

  onCancel() {
    this.router.navigate(["admin", "referal-links"]);
  }

  createForm() {
    this.form = this.adminReferalLinksService.createReferalLinkForm();
  }

  watchFormChanges() {
    this.addSub(
      this.form?.valueChanges.subscribe((data) => {
        this.referalLinkUrl = this.getReferalLink();
      }),
    );
  }

  protected getReferalLink() {
    const landingUrl = this.company?.landingUrl ?? "";
    const token = this.form.get("token")?.value ?? "";
    return this.adminReferalLinksService.getReferalLink(landingUrl, token);
  }

  onCopyReferalLinkUrl() {
    this.adminReferalLinksService.copyText(this.referalLinkUrl);
  }

  onGenerateToken() {
    this.form?.patchValue({ token: uuidv4() });
  }
}
