import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { BlockId } from "../markup-editor/editor.models";
import { StyleKey, StyleSettings } from "./../../models/styles";
import { Store } from "./../../state/store";
import { EditorState } from "./editor.state";

@Injectable({
  providedIn: "root",
})
export class EditorStore extends Store<EditorState> {
  protected override getInitialState(): EditorState {
    return {
      stylesSettings: {
        mainText: {
          font: "PT Serif",
          fontSize: 10,
          emphasis: "none",
          align: "left",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: false,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: true,
          isDisplayedAtSidebar: true,
          availableControls: ["align-maintext", "indent-paragraph", "drop-cap", "font-size"],
          order: 0,
          type: "text",
        },
        header1: {
          font: "PT Serif",
          fontSize: 18,
          emphasis: "none",
          align: "left",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: true,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: true,
          isDisplayedAtSidebar: true,
          availableControls: [
            "emphasis",
            "align",
            "case",
            "startsOn",
            "indent-header",
            "font-size",
            "leading",
            "tracking",
          ],
          order: 1,
          type: "header",
        },
        header2: {
          font: "PT Serif",
          fontSize: 16,
          emphasis: "none",
          align: "left",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: true,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: true,
          isDisplayedAtSidebar: true,
          availableControls: [
            "emphasis",
            "align",
            "case",
            "startsOn",
            "indent-header",
            "font-size",
            "leading",
            "tracking",
          ],
          order: 2,
          type: "header",
        },
        header3: {
          font: "PT Serif",
          fontSize: 14,
          emphasis: "none",
          align: "left",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: true,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: true,
          isDisplayedAtSidebar: true,
          availableControls: ["emphasis", "align", "case", "font-size", "leading", "tracking"],
          order: 3,
          type: "header",
        },
        header4: {
          font: "PT Serif",
          fontSize: 12,
          emphasis: "none",
          align: "left",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: false,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: true,
          isDisplayedAtSidebar: true,
          availableControls: ["emphasis", "align", "case", "font-size", "leading", "tracking"],
          order: 4,
          type: "header",
        },
        lead: {
          font: "PT Serif",
          fontSize: 10,
          emphasis: "none",
          align: "left",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: false,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: true,
          isDisplayedAtSidebar: true,
          availableControls: ["emphasis", "align", "case", "font-size", "leading", "tracking"],
          order: 5,
          type: "text",
        },
        quote: {
          font: "PT Serif",
          fontSize: 10,
          emphasis: "none",
          align: "left",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: false,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: true,
          isDisplayedAtSidebar: true,
          availableControls: ["emphasis", "align", "case", "font-size", "leading", "tracking"],
          order: 6,
          type: "text",
        },
        quoteAuthor: {
          font: "PT Serif",
          fontSize: 10,
          emphasis: "none",
          align: "left",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: false,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: true,
          isDisplayedAtSidebar: true,
          availableControls: ["emphasis", "align", "case", "font-size", "leading", "tracking"],
          order: 7,
          type: "text",
        },
        verse: {
          font: "PT Serif",
          fontSize: 10,
          emphasis: "italic",
          align: "left",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: false,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: true,
          isDisplayedAtSidebar: true,
          availableControls: ["emphasis", "align", "case", "font-size", "leading", "tracking"],
          order: 8,
          type: "text",
        },
        note: {
          font: "PT Serif",
          fontSize: 10,
          emphasis: "none",
          align: "left",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: false,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: true,
          isDisplayedAtSidebar: true,
          availableControls: ["emphasis", "align", "case", "font-size", "leading", "tracking"],
          order: 9,
          type: "text",
        },
        caption: {
          font: "PT Serif",
          fontSize: 10,
          emphasis: "none",
          align: "center",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: false,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: true,
          isDisplayedAtSidebar: true,
          availableControls: ["emphasis", "align", "case", "font-size", "leading", "tracking"],
          order: 10,
          type: "text",
        },
        list: {
          font: "PT Serif",
          fontSize: 10,
          emphasis: "none",
          align: "left",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: false,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: false,
          isDisplayedAtSidebar: false,
          availableControls: [],
          order: 11,
          type: "list",
        },
        image: {
          font: "PT Serif",
          fontSize: 10,
          emphasis: "none",
          align: "left",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: false,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: false,
          isDisplayedAtSidebar: false,
          availableControls: [],
          order: 12,
          type: "image",
        },
        table: {
          font: "PT Serif",
          fontSize: 10,
          emphasis: "none",
          align: "left",
          case: "none",
          indentParagraph: "none",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: false,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: false,
          isDisplayedAtSidebar: false,
          availableControls: [],
          order: 13,
          type: "table",
        },
        "page-break": {
          font: "PT Serif",
          fontSize: 10,
          emphasis: "none",
          align: "left",
          case: "none",
          indentParagraph: "indented-line",
          indentParagraphValue: 0,
          indentParagraphAfterHeader: false,
          indentHeader: "none",
          startsOn: "current-page",
          oneOnPage: false,
          dropCap: false,
          dropCapChars: 0,
          dropCapLines: 0,
          color: { h: 0, s: 0, v: 0 },
          leading: 120,
          tracking: 0,
          isShowedInToc: false,
          visibleTitle: "",
          isConfigurableAtDetailsSidebar: false,
          isDisplayedAtSidebar: false,
          availableControls: [],
          order: 14,
          type: "page-break",
        },
      },
      isBlockMenuVisible: false,
      selectionState: undefined,
      selectedBlockIds: [],
    };
  }

  getSettingsForStyleObservable(styleKey: StyleKey) {
    return this.getStateObservable().pipe(map((state) => state.stylesSettings[styleKey]));
  }

  getSettingsForStylesObservable() {
    return this.getStateObservable().pipe(map((state) => state.stylesSettings));
  }

  setStyleDisplayOpts(style: StyleKey, opts: StyleSettings) {
    this.update((state) => ({
      ...state,
      stylesSettings: {
        ...state.stylesSettings,
        [style]: opts,
      },
    }));
  }

  addSelectedBlockId(blockId: BlockId) {
    this.update((state) => ({
      ...state,
      selectedBlockIds: state.selectedBlockIds.concat([blockId]),
    }));
  }

  setSelectedBlockIds(blockIds: BlockId[]) {
    this.update((state) => ({ ...state, selectedBlockIds: blockIds }));
  }

  getSelectedBlockIds() {
    return this.getField("selectedBlockIds");
  }

  getSelectedBlockIdsObservable() {
    return this.getFieldObservable("selectedBlockIds");
  }

  //   setUser(user: User | undefined) {
  //     this.update((state) => ({ ...state, user }));
  //   }

  //   setBalance(balance: UserBalance | undefined) {
  //     this.update((state) => ({ ...state, balance }));
  //   }

  //   getUser() {
  //     return this.getField('user');
  //   }

  //   getUserObservable() {
  //     return this.getFieldObservable('user');
  //   }

  //   getBalance() {
  //     return this.getState().balance;
  //   }

  //   getBalanceObservable() {
  //     return this.getStateObservable().pipe(map((state) => state.balance));
  //   }
}
