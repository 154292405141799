import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { fadeInOutForSidebarElements } from "@metranpage/components";
import { Subscription } from "rxjs";

@Component({
  selector: "m-books-markup-sidebar-toc-settings",
  templateUrl: "./markup-sidebar-toc-settings.view.html",
  styleUrls: ["./markup-sidebar-toc-settings.view.scss"],
  animations: [fadeInOutForSidebarElements],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkupSidebarTocSettings {
  @Input()
  form!: FormGroup;

  protected isTocPositionVisible = false;

  private sub = new Subscription();

  ngOnInit(): void {
    this.isTocPositionVisible = this.getTocVisibleValue();
    this.watchFormChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private getTocVisibleValue() {
    return this.form?.get("tocVisible")?.value;
  }

  private watchFormChanges() {
    this.sub.add(
      this.form?.get("tocVisible")?.valueChanges.subscribe((value) => {
        this.isTocPositionVisible = value;
      }),
    );
  }
}
