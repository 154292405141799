import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { LoadingService, NotificationsPopUpService, filterUndefined } from "@metranpage/core";
import { User, UserStore } from "@metranpage/user-data";
import * as _ from "lodash-es";
import { Subject, Subscription } from "rxjs";
import { Character } from "../../models/character/character";
import { CharactersFormService, FormChangeData } from "../../services/characters-form.service";
import { CharactersService } from "../../services/characters.service";
import { CharacterStore } from "../../services/characters.store";

@Component({
  selector: "m-characters-modal-view",
  templateUrl: "./characters-modal.view.html",
  styleUrls: ["./characters-modal.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class CharactersModalView implements OnInit, OnDestroy {
  @Input()
  characters: Character[] = [];
  @Input()
  closeOnBackDropClick = true;
  @Input()
  closeButtonVisible = true;
  @Input()
  addedCharactersIds: number[] = [];
  @Input()
  charactersInPromptLimit = 3;
  @Input()
  isCharacterChanging = false;

  @Output()
  onSelect = new EventEmitter<Character>();
  @Output()
  onClose = new EventEmitter();

  @ViewChildren("characters", { read: ElementRef })
  characterElements?: QueryList<ElementRef>;

  protected activeCharacter?: Character;

  protected page = 1;
  protected pageCount = 1;

  protected form?: FormGroup;

  protected formValueChangesEvent$ = new Subject<FormChangeData | undefined>();

  protected user!: User;

  protected isLoading = true;
  protected isCharactersLoading = true;

  protected isCustomTypeVisible = false;

  sub: Subscription = new Subscription();

  protected characterForDelete?: Character;

  constructor(
    readonly userStore: UserStore,
    private readonly characterStore: CharacterStore,
    private readonly charactersService: CharactersService,
    private readonly charactersFormService: CharactersFormService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        if (!user) {
          return;
        }
        this.user = user;
      }),
    );

    this.formValueChangesEvent$ = this.charactersFormService.formValueChangesEvent$;
    this.sub.add(
      this.formValueChangesEvent$.pipe(filterUndefined()).subscribe((value) => {
        this.isCustomTypeVisible = value.type === "custom";
      }),
    );
  }

  async ngOnInit() {
    this.isLoading = true;

    // await this.loadCharactersPaginated();

    this.form = this.charactersFormService.initForm();

    // this.sub.add(
    //   this.characterStore
    //     .getCharactersObservable()
    //     .pipe(filterUndefined())
    //     .subscribe(async (characters) => {
    //       this.characters = characters.filter((ig) => !ig.deletedAt);
    //       if (this.page < this.pageCount && this.characters.length < 5) {
    //         await this.onScroll();
    //       }
    //       this.cdr.markForCheck();
    //     }),
    // );

    // this.sub.add(
    //   this.characterStore
    //     .getCharactersPageCountObservable()
    //     .pipe(filterUndefined())
    //     .subscribe((count) => {
    //       this.pageCount = count;
    //     }),
    // );

    this.sub.add(
      this.characterStore.getActiveCharacterObservable().subscribe(async (character) => {
        this.activeCharacter = character;

        this.updateFormValues();
      }),
    );

    this.isLoading = false;
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.charactersFormService.destroyForm();
    this.sub.unsubscribe();
  }

  protected updateFormValues() {
    if (this.activeCharacter) {
      let customType = "";
      const type = this.charactersFormService.getTypeOptionById(this.activeCharacter.type);
      if (type === "custom") {
        customType = this.activeCharacter.type;
      }
      const data = { ...this.activeCharacter, type, customType };
      this.form?.patchValue(data);
    } else {
      this.charactersFormService.setDefaultFormValue();
    }
  }

  protected async onAddClick() {
    this.characterStore.setActiveCharacter(undefined);
  }

  protected onCloseClick() {
    this.characterStore.setActiveCharacter(undefined);
    this.onClose.emit();
  }

  protected onSelectCharacterClick() {
    if (!this.activeCharacter) {
      return;
    }

    this.onSelectClick(this.activeCharacter);
  }

  protected onSelectClick(character: Character) {
    this.onSelect.emit(character);
    this.onCloseClick();
  }

  protected onEditClick(character: Character | undefined) {
    this.characterStore.setActiveCharacter(character);
  }

  protected async onCopyClick(character: Character) {
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.charactersService.copyCharacter(character.id);
    if (result !== "success") {
      this.notificationService.error($localize`:@@characters.copy.error:`);
    }
    this.loadingService.stopLoading();
  }

  async onCreateCharacterClick() {
    if (!this.user?.isVerified) {
      this.router.navigate(["/account/account-verification"], { queryParams: { from: "image-generation" } });
      return;
    }

    if (!this.form?.valid) {
      this.checkFormErrors();
      return;
    }

    await this.createCharacter();
  }

  private async createCharacter() {
    const data = this.getFormData();
    if (!data) {
      return;
    }

    const result = await this.charactersService.createCharacter(data);
    if (result === "error") {
      this.notificationService.error($localize`:@@characters.generation.error.cant-start-processing-error:`);
    }

    this.scrollCharactersToTop();
  }

  protected async onChangeCharacterClick() {
    if (!this.user?.isVerified) {
      this.router.navigate(["/account/account-verification"], { queryParams: { from: "image-generation" } });
      return;
    }

    if (!this.form?.valid) {
      this.checkFormErrors();
      return;
    }

    await this.changeCharacter();
  }

  private async changeCharacter() {
    const data = this.getFormData();
    if (!data || !this.activeCharacter) {
      return;
    }

    const result = await this.charactersService.changeCharacter(this.activeCharacter.id, data);
    if (result === "error") {
      this.notificationService.error($localize`:@@characters.generation.error.cant-start-processing-error:`);
    }

    this.scrollCharactersToTop();
  }

  getFormData() {
    if (!this.form || !this.form.valid) {
      return;
    }

    let data = this.form.getRawValue();

    if (data.type === "custom") {
      data.type = data.customType;
    }
    data = _.omit(data, "customType");

    data.appearance = data.appearance.replaceAll("<br>", " ");
    data.age = +data.age;

    return data;
  }

  private async loadCharactersPaginated() {
    this.isCharactersLoading = true;
    await this.charactersService.loadCharactersPaginated(this.page);
    this.isCharactersLoading = false;
  }

  protected isActive(character: Character | undefined = undefined) {
    if (!this.activeCharacter && !character) {
      return true;
    }
    return this.activeCharacter?.id === character?.id;
  }

  protected isAdded(character: Character | undefined = undefined) {
    if (!character) {
      return false;
    }
    return this.addedCharactersIds.includes(character.id);
  }

  protected async onDeleteClick() {
    if (!this.characterForDelete) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    await this.charactersService.deleteCharacter(this.characterForDelete.id);
    this.loadingService.stopLoading();

    this.charactersService.onDeleteCharacter(this.characterForDelete);

    this.hideDeleteModal();
  }

  protected showDeleteModal(character: Character) {
    this.characterForDelete = character;
    this.cdr.markForCheck();
  }

  protected hideDeleteModal() {
    this.characterForDelete = undefined;
    this.cdr.markForCheck();
  }

  protected async onScroll() {
    if (this.page >= this.pageCount) {
      return;
    }
    this.page++;
    await this.loadCharactersPaginated();
    this.cdr.markForCheck();
  }

  private checkFormErrors() {
    this.markInvalidControlAsDirty();
    this.cdr.markForCheck();
  }

  private markInvalidControlAsDirty(): void {
    const controls = this.form?.controls;
    for (const k in controls) {
      const control = controls[k];
      if (!control.valid) {
        control.markAsDirty();
      }
    }
  }

  protected isCharactersInPromptLimitReached() {
    if (this.isCharacterChanging) {
      return false;
    }
    return this.charactersService.isCharactersLimitReached(this.addedCharactersIds, this.charactersInPromptLimit);
  }

  protected isCharacterAdded(character: Character) {
    return this.addedCharactersIds.includes(character.id);
  }

  private scrollCharactersToTop() {
    if (!this.characterElements || this.characterElements.toArray().length === 0) {
      return;
    }

    this.characterElements.toArray()[0].nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  }

  protected getCharactersInfo() {
    return this.charactersService.getCharactersInfo(this.charactersInPromptLimit);
  }

  protected getTypeOptions() {
    return this.charactersFormService.getTypeOptions();
  }

  protected getGenderOptions() {
    return this.charactersFormService.getGenderOptions();
  }

  trackById(index: number, character: Character) {
    return character.id;
  }

  @HostListener("window:keydown.escape")
  protected handleKeyDown() {
    this.onCloseClick();
  }
}
