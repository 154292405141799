import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { debounceTime, takeUntil } from "rxjs";
import { NonEditableTemplate, NonEditableTemplateCreateDataWithFile } from "../../models/non-editable-template";
import { AdminNonEditableTemplatesService } from "../../services/non-editable-templates/non-editable-templates.service";
import { AdminNonEditableTemplatesStore } from "../../services/non-editable-templates/non-editable-templates.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-non-editable-templates-page",
  templateUrl: "./non-editable-templates.page.html",
  styleUrls: ["./non-editable-templates.page.scss"],
})
export class AdminNonEditableTemplatesPage extends AdminBasePage implements OnInit {
  protected isAddNonEditableTemplateModalVisible = false;
  protected isDeleteNonEditableTemplateModalVisible = false;
  protected deleteNonEditableTemplateId = -1;

  nonEditableTemplates: NonEditableTemplate[] = [];
  page = 1;
  pageCount = 1;
  dragIndex: number | null = null;

  protected searchForm = new FormGroup({
    title: new FormControl("", { nonNullable: true, validators: [] }),
  });
  protected title: string | undefined = undefined;

  constructor(
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly adminNonEditableTemplatesService: AdminNonEditableTemplatesService,
    adminNonEditableTemplatesStore: AdminNonEditableTemplatesStore,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();

    this.addSub(
      adminNonEditableTemplatesStore
        .getNonEditableTemplatesObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((templates) => {
          this.nonEditableTemplates = templates;
          this.cdr.markForCheck();
        }),
    );
    this.addSub(
      adminNonEditableTemplatesStore
        .getNonEditableTemplatesPageCountObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((count) => {
          this.pageCount = count;
          this.cdr.markForCheck();
        }),
    );
  }

  ngOnInit(): void {
    this.adminNonEditableTemplatesService.loadNonEditableTemplatesPaginated(this.page);

    this.searchForm.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(600)).subscribe((value) => {
      if (value.title) {
        this.title = value.title;
      } else {
        this.title = undefined;
      }

      this.adminNonEditableTemplatesService.loadNonEditableTemplatesPaginated(this.page, { title: this.title });
    });
  }

  trackByNonEditableTemplateId(index: number, template: NonEditableTemplate) {
    return template.id;
  }

  onPageChanged(page: number) {
    this.page = page;
    this.adminNonEditableTemplatesService.loadNonEditableTemplatesPaginated(this.page);
  }

  onAddNonEditableTemplateClick() {
    this.isAddNonEditableTemplateModalVisible = true;
  }

  async onGetNonEditableTemplateExampleClick() {
    await this.adminNonEditableTemplatesService.downloadExample();
  }

  async onNonEditableTemplateAdded(data: NonEditableTemplateCreateDataWithFile) {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminNonEditableTemplatesService.createNonEditableTemplate(data);
    this.loadingService.stopLoading();
    if (result.status === "success") {
      this.onCloseAddNonEditableTemplateModal();
      // this.router.navigate(["admin", "non-editable-templates", result.id]);
      this.adminNonEditableTemplatesService.loadNonEditableTemplatesPaginated(this.page);
    } else {
      this.notificationService.error(
        $localize`:@@admin.non-editable-templates.non-editable-template.error.cant-add-non-editable-template:`,
      );
    }
  }

  onCloseAddNonEditableTemplateModal() {
    this.isAddNonEditableTemplateModalVisible = false;
  }

  // async onCopyTemplateClick(templateId: number) {
  //   this.notificationService.closeAll();
  //   this.loadingService.startLoading({ fullPage: true });
  //   const result = await this.adminNonEditableTemplatesService.copyTemplate(templateId);
  //   this.loadingService.stopLoading();
  //   if (result.status === "success") {
  //     // this.adminTemplatesService.loadTemplatesPaginated(this.page);
  //     this.router.navigate(["admin", "templates", result.id]);
  //   } else {
  //     this.notificationService.error($localize`:@@admin.non-editable-templates.non-editable-template.error.cant-copy-non-editable-template:`);
  //   }
  // }

  onDeleteNonEditableTemplateClick(templateId: number) {
    this.isDeleteNonEditableTemplateModalVisible = true;
    this.deleteNonEditableTemplateId = templateId;
  }

  onCloseDeleteNonEditableTemplateModal() {
    this.isDeleteNonEditableTemplateModalVisible = false;
  }

  async onDeleteNonEditableTemplate() {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminNonEditableTemplatesService.deleteNonEditableTemplate(
      this.deleteNonEditableTemplateId,
    );
    this.loadingService.stopLoading();
    if (result.status === "success") {
      this.onCloseDeleteNonEditableTemplateModal();
      this.adminNonEditableTemplatesService.loadNonEditableTemplatesPaginated(this.page);
    } else {
      this.notificationService.error(
        $localize`:@@admin.non-editable-templates.non-editable-template.error.cant-delete-non-editable-template:`,
      );
    }
  }

  onDragStart(event: DragEvent, index: number) {
    event.dataTransfer?.setData("text/plain", index.toString());
    this.dragIndex = index;
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  async onDrop(event: DragEvent, index: number) {
    event.preventDefault();

    if (this.dragIndex === null) return;

    const draggedTemplate = this.nonEditableTemplates[this.dragIndex];
    this.nonEditableTemplates[this.dragIndex] = this.nonEditableTemplates[index];
    this.nonEditableTemplates[index] = draggedTemplate;

    // const status = await this.adminTemplatesService.saveTemplatesOrder(this.templates);

    // if (status === "success") {
    // } else {
    // }

    this.dragIndex = null;
  }
}
