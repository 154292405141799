import { Injectable } from "@angular/core";
import { InfoBlockData } from "@metranpage/components";
import { ApiErrorHandlerService, NotificationsPopUpService, RealtimeService, filterUndefined } from "@metranpage/core";
import { I18nService } from "@metranpage/i18n";
import { BehaviorSubject } from "rxjs";
import { Character, CharacterDataDto } from "../models/character/character";
import { CharacterResultUpdate } from "../models/character/character-update";
import { CharacterApi } from "./characters.api";
import { CharacterStore } from "./characters.store";

export type GeneralResultStatus = "success" | "error";

export type CharacterChangePopupEvent = {
  id: number;
  element?: HTMLElement;
};

@Injectable({
  providedIn: "root",
})
export class CharactersService {
  selectCharacter$ = new BehaviorSubject<Character | undefined>(undefined);
  deleteCharacter$ = new BehaviorSubject<Character | undefined>(undefined);
  showChangePopup$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly apiErrorHandler: ApiErrorHandlerService,
    private readonly characterStore: CharacterStore,
    private readonly characterApi: CharacterApi,
    private readonly notificationService: NotificationsPopUpService,
    realtimeService: RealtimeService,
    private readonly i18nService: I18nService,
  ) {
    realtimeService
      .getEvents<CharacterResultUpdate>("character-change")
      .pipe(filterUndefined())
      .subscribe((characterUpdate: CharacterResultUpdate) => {
        this.characterStore.updateCharacter(characterUpdate.character);

        if (characterUpdate.character.isProcessing) {
          this.characterStore.setActiveCharacter(characterUpdate.character);
        }

        if (characterUpdate.isError) {
          this.notifyOnCharacterGenerationError();
        }
      });
  }

  onSelectCharacter(character: Character) {
    this.selectCharacter$.next(character);
  }

  onDeleteCharacter(character: Character) {
    this.deleteCharacter$.next(character);
  }

  notifyOnCharacterGenerationError() {
    this.notificationService.error($localize`:@@characters.generation.error.error:`);
  }

  async getCharacterById(id: number): Promise<Character | undefined> {
    return await this.characterApi.getCharacterById(id);
  }

  async createCharacter(data: CharacterDataDto): Promise<GeneralResultStatus> {
    try {
      await this.characterApi.createCharacter(data);
      return "success";
    } catch (errorResponse: any) {
      this.apiErrorHandler.handleApiError(errorResponse, true);
      return "error";
    }
  }

  async changeCharacter(id: number, data: CharacterDataDto): Promise<GeneralResultStatus> {
    try {
      await this.characterApi.changeCharacter(id, data);
      return "success";
    } catch (errorResponse: any) {
      this.apiErrorHandler.handleApiError(errorResponse, true);
      return "error";
    }
  }

  async loadCharacters() {
    const characters = await this.characterApi.loadCharacters();
    this.characterStore.addCharactersToEnd(characters);
    this.characterStore.setCharactersPageCount(1);
  }

  async loadCharactersPaginated(page: number) {
    const publishedImagesData = await this.characterApi.loadCharactersPaginated(page);
    this.characterStore.addCharactersToEnd(publishedImagesData.items);
    this.characterStore.setCharactersPageCount(publishedImagesData.pageCount);
  }

  async copyCharacter(id: number) {
    return await this.characterApi.copyCharacter(id);
  }

  async deleteCharacter(id: number) {
    try {
      await this.characterApi.deleteCharacter(id);
      return "success";
    } catch (errorResponse: any) {
      this.apiErrorHandler.handleApiError(errorResponse, true);
      return "error";
    }
  }

  getCharactersInfo(charactersInTextLimit: number): InfoBlockData[] {
    return [
      {
        textData: [{ text: $localize`:@@characters.list.info.1:` }],
      },
      {
        textData: [
          {
            text: `${$localize`:@@characters.list.info.2-1:`} ${charactersInTextLimit} ${this.i18nService.pluralize(
              $localize`:@@characters.pluralize.unique:`,
              {
                value: charactersInTextLimit,
              },
            )} ${this.i18nService.pluralize($localize`:@@characters.pluralize.characters:`, {
              value: charactersInTextLimit,
            })}`,
          },
        ],
      },
    ];
  }

  isCharactersLimitReached(addedCharactersIds: number[], charactersLimit: number, checkOnEqual = true) {
    if (checkOnEqual) {
      return addedCharactersIds.length >= charactersLimit;
    }
    return addedCharactersIds.length > charactersLimit;
  }

  onVisibilityChangeSelectCharacterPopup(value: boolean) {
    this.showChangePopup$.next(value);
  }

  getCharactersIdsFromText(text: string) {
    const reCharacter = /@character-id=\{(?<charId>\d+)\}/gm;
    const charactersIds: number[] = [];
    const charactersIdMatches = text.matchAll(reCharacter);
    for (const characterIdMatch of charactersIdMatches) {
      const characterId = Number.parseInt(characterIdMatch.groups!["charId"]);
      if (charactersIds.includes(characterId)) {
        continue;
      }
      charactersIds.push(characterId);
    }
    return charactersIds;
  }

  getCharactersRegex(id: number) {
    return new RegExp(`@character-id=\\{${id}\\}`, "gm");
  }
}
