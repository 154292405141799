import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Color } from "@metranpage/book-data";

@Component({
  selector: "m-cover-selected-color",
  templateUrl: "./cover-selected-color.component.html",
  styleUrls: ["./cover-selected-color.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverSelectedColorComponent implements OnChanges {
  @Input() color?: Color;

  baseColor!: string;
  actualColor!: string;
  cssStyle!: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.color) {
      this.actualColor = this.color.toCss();
      this.baseColor = `rgb(${this.color.r}, ${this.color.g}, ${this.color.b})`;
    } else {
      this.actualColor = "transparent";
      this.baseColor = "var(--surface-on-island)";
    }
    this.cssStyle = `--actual-color: ${this.actualColor}; --base-color: ${this.baseColor}`;
  }
}
