import { Component, EventEmitter, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { NotificationsPopUpService } from "@metranpage/core";
import { PricingService } from "@metranpage/pricing";
import { PromocodeEnterResult } from "@metranpage/pricing-data";
import { Subject, Subscription } from "rxjs";

@Component({
  selector: "m-promocode-modal",
  templateUrl: "./promocode-modal.view.html",
  styleUrls: ["./promocode-modal.view.scss"],
})
export class PromocodeModalView {
  @Output()
  onPromocodeEnter = new EventEmitter<string>();
  @Output()
  onClose = new EventEmitter();

  protected promocodeEvent$ = new Subject<PromocodeEnterResult | undefined>();
  protected promocodeResult?: PromocodeEnterResult;

  protected form = new FormGroup({
    promocode: new FormControl("", { nonNullable: true, validators: [] }),
  });

  sub: Subscription = new Subscription();

  constructor(
    private readonly pricingService: PricingService,
    private readonly notificationService: NotificationsPopUpService,
  ) {
    this.promocodeEvent$ = this.pricingService.promocodeEvent$;

    this.sub.add(
      this.promocodeEvent$.subscribe((promocodeEvent) => {
        this.promocodeResult = promocodeEvent;
        if (this.promocodeResult?.status && this.promocodeResult?.status !== "success") {
          this.notificationService.error(this.getResultText());
        }
      }),
    );
  }

  ngOnInit(): void {
    this.promocodeResult = undefined;
    this.watchFormChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected onCloseClick() {
    this.promocodeEvent$.next(undefined);
    this.onClose.emit();
  }

  protected onEnterPromocodeClick() {
    this.promocodeResult = undefined;
    this.onPromocodeEnter.emit(this.form.get("promocode")?.value.trim());
  }

  protected watchFormChanges() {
    this.sub.add(
      this.form.valueChanges.subscribe(() => {
        this.promocodeResult = undefined;
      }),
    );
  }

  getResultText() {
    switch (this.promocodeResult?.status) {
      // case 'success':
      //   return $localize`:@@pricing.promocode.activation.success:`;
      case "expired":
        return $localize`:@@pricing.promocode.activation.error.expired:`;
      case "already-used":
        return $localize`:@@pricing.promocode.activation.error.already-used:`;
      case "not-found":
        return $localize`:@@pricing.promocode.activation.error.not-found:`;
      case "error":
        return $localize`:@@pricing.promocode.activation.error.error-occurs:`;
      case "error-active-subscription-has-higher-level":
        return $localize`:@@pricing.promocode.activation.error.error-active-subscription-has-higher-level:`;
      default:
        return "";
    }
  }
}
