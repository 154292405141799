import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

const ignoredUrls = ['/login', '/registration', '/restore-password', '/reset-password', '/profile'];

@Component({
  selector: 'm-mobile-alert',
  templateUrl: './mobile-alert.view.html',
  styleUrls: ['./mobile-alert.view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAlertView implements OnInit, OnDestroy {
  shouldBeVisible = false;
  isClosed = false;

  minWidth = 768;
  isAvailableOnCurrentRoute = true;

  @HostBinding('class.visible') get visible() {
    return this.shouldBeVisible && !this.isClosed;
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        const routeData = data.state.root.firstChild?.data;
        if (routeData && 'showMobileWarning' in routeData) {
          this.isAvailableOnCurrentRoute = routeData.showMobileWarning;
        } else {
          this.isAvailableOnCurrentRoute = true;
        }
        this.onWindowResize(undefined);
      }
    });
  }

  ngAfterViewInit(): void {
    addEventListener('resize', this.onWindowResize.bind(this));
    this.onWindowResize(undefined);
  }

  ngOnDestroy(): void {
    removeEventListener('resize', this.onWindowResize.bind(this));
  }

  onCloseClick() {
    this.isClosed = true;
  }

  onWindowResize(event: UIEvent | undefined) {
    if (!this.isAvailableOnCurrentRoute) {
      this.shouldBeVisible = false;
      this.changeDetectorRef.markForCheck();
      return;
    }

    if (window.innerWidth < this.minWidth) {
      this.shouldBeVisible = true;
    } else {
      this.shouldBeVisible = false;
    }
    this.changeDetectorRef.markForCheck();
  }
}
