import { Inject, Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { ConfigService } from "@metranpage/core-interfaces";
import { CompanyEditionNoticeSettingsUpdateData } from "../../models/company";
import { EditionNoticeFileType } from "../../pages/edition-notice-settings/edition-notice-settings.page";
import { AdminCompanyEditionNoticeSettingsApi } from "./companies-edition-notice-settings.api";

export type UpdateCompanySettingsResult = {
  status: GeneralResultStatus;
  error?: unknown;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyEditionNoticeSettingsService {
  constructor(private readonly api: AdminCompanyEditionNoticeSettingsApi) {}

  async getEditionNoticeSettings() {
    const settings = await this.api.getEditionNoticeSettings();
    return settings.map((s) => {
      if (!s.preview) {
        return s;
      }
      return {
        ...s,
        preview: this.api.getPreviewUrl(s.lang, s.preview),
      };
    });
  }

  async updateEditionNoticeData(data: CompanyEditionNoticeSettingsUpdateData[]): Promise<GeneralResultStatus> {
    try {
      await this.api.updateEditionNoticeData(data);
      return "success";
    } catch (error: any) {
      console.error(error);
      return "error";
    }
  }

  async uploadEditionNoticeFile(file: File, lang: string, type: EditionNoticeFileType): Promise<GeneralResultStatus> {
    try {
      await this.api.uploadEditionNoticeFile(file, lang, type);
      return "success";
    } catch (error: any) {
      console.error(error);
      return "error";
    }
  }

  async deleteEditionNoticeFile(lang: string, type: EditionNoticeFileType): Promise<GeneralResultStatus> {
    try {
      await this.api.deleteEditionNoticeFile(lang, type);
      return "success";
    } catch (error: any) {
      console.error(error);
      return "error";
    }
  }
}
