<div #radioGroup class="container">
  <m-radio
    *ngFor="let style of runningTitlesStyles"
    class="radio-button"
    [value]="style.id"
    [isChecked]="isRadioChecked(style.id)"
    [isDisabled]="isStyleDisabled(style.id)"
    (onSelect)="onSelect($event)"
  >
    <div class="styles-preview">
      <m-running-titles-styles
        class="style-preview"
        [style]="style"
        [context]="context"
        [page-position]="pagePosition"
        [font-size]="fontSize"
        [palette]="palette"
        [isDisabled]="isStyleDisabled(style.id)"
      ></m-running-titles-styles>
    </div>
  </m-radio>

  <m-radio
    *ngFor="let style of pageNumbersStyles"
    class="radio-button"
    [value]="style.id"
    [isChecked]="isRadioChecked(style.id)"
    [isDisabled]="isStyleDisabled(style.id)"
    (onSelect)="onSelect($event)"
  >
    <div class="styles-preview">
      <m-page-numbers-styles
        class="style-preview"
        [style]="style"
        [context]="context"
        [page-position]="pagePosition"
        [font-size]="fontSize"
        [palette]="palette"
        [isDisabled]="isStyleDisabled(style.id)"
      ></m-page-numbers-styles>
    </div>
  </m-radio>
</div>
