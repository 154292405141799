import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import {
  RewardOneTime,
  RewardOneTimeCreateData,
  RewardOneTimeData,
  RewardsOneTimeData,
} from "../../../models/reward-onetime";

export type RewardsOneTimeFilter = {
  type?: string;
};

@Injectable({
  providedIn: "root",
})
export class AdminRewardsOneTimeApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async createRewardOneTime(data: RewardOneTimeCreateData) {
    return this.post<{ id: number }>("admin/reward-onetime/", data);
  }

  async getRewardOneTime(rewardOneTimeId: number) {
    return this.get<RewardOneTimeData>(`admin/reward-onetime/${rewardOneTimeId}`);
  }

  async getRewardsOneTime() {
    return this.get<RewardOneTime[]>("admin/reward-onetime");
  }

  async getRewardsOneTimePaginated(page: number, filters: RewardsOneTimeFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<RewardsOneTimeData>("admin/reward-onetime/paginated", {
      page,
      ...clearedFilters,
    });
  }

  async updateRewardOneTime(rewardOneTimeId: number, data: RewardOneTimeCreateData) {
    return this.put<unknown>(`admin/reward-onetime/update/${rewardOneTimeId}`, data);
  }

  async deleteRewardOneTime(rewardOneTimeId: number) {
    return this.delete(`admin/reward-onetime/${rewardOneTimeId}`);
  }
}
