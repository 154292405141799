<div class="container">
  <div class="header">
    <div class="title" i18n="@@cover-editor.conceptual-assistant.menu.title">
      cover-editor.conceptual-assistant.menu.title
    </div>

    <div class="close-icon" (click)="onCloseConceptualAssistantClick()">
      <m-ng-icon src="close-cross.svg" [size]="12" />
    </div>
  </div>

  <div class="divider-hor"></div>

  <form class="params" [formGroup]="form">
    <div class="control">
      <div class="control-title" i18n="@@cover-editor.conceptual-assistant.menu.annotation">
        cover-editor.conceptual-assistant.menu.annotation
      </div>
      <m-textarea
        class="textarea"
        [rows]="20"
        [maxlength]="promptAnnotationMaxLength"
        placeholder="cover-editor.conceptual-assistant.menu.annotation-placeholder"
        i18n-placeholder="@@cover-editor.conceptual-assistant.menu.annotation-placeholder"
        formControlName="promptAnnotation"
        type="normal"
      />
    </div>

    <div class="control">
      <div class="control-title" i18n="@@cover-editor.conceptual-assistant.menu.target-audience">
        cover-editor.conceptual-assistant.menu.target-audience
      </div>
      <m-textarea
        class="textarea"
        [rows]="5"
        placeholder="cover-editor.conceptual-assistant.menu.target-audience-placeholder"
        i18n-placeholder="@@cover-editor.conceptual-assistant.menu.target-audience-placeholder"
        formControlName="promptTargetAudience"
        type="normal"
      />
    </div>

    <div class="control">
      <div class="control-title" i18n="@@cover-editor.conceptual-assistant.menu.genre">
        cover-editor.conceptual-assistant.menu.genre
      </div>
      <m-textarea
        class="textarea"
        [rows]="5"
        placeholder="cover-editor.conceptual-assistant.menu.genre-placeholder"
        i18n-placeholder="@@cover-editor.conceptual-assistant.menu.genre-placeholder"
        formControlName="promptGenre"
        type="normal"
      />
    </div>
  </form>

  <div class="button">
    <m-button
      button-style="primary"
      size="full-width"
      icon="stars.svg"
      icon-position="left"
      i18n="@@cover-editor.conceptual-assistant.menu.generate-button"
      [is-disabled]="!form.valid"
      (onClick)="onGenerateCoverConceptualClick()"
    >
      cover-editor.conceptual-assistant.menu.generate-button
    </m-button>
  </div>
</div>
