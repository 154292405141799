<div class="container">
  <div *ngIf="selectedTypes?.exportEpub && !selectedTypes?.exportPrint" class="epub-info">
    <img class="epub-info-img" [src]="getThemeEpub()" />

    <m-localized-text
      class="epub-info-text"
      text="@@books.preview.preview-info-text"
      i18n-text="@@books.preview.preview-info-text"
    ></m-localized-text>
  </div>

  <div class="side-panel-left" *ngIf="selectedTypes?.exportPrint">
    <m-side-panel sidebar-position="left" sidebar-type="fixed">
      <ng-container>
        <div class="side-panel-left-context">
          <m-preview-spreads
            [imageDataURLs]="previewsThumbnails$ | async"
            [selected-left-page]="selectedLeftPage"
            (onSelectLeftPage)="onSelectLeftPage($event)"
          ></m-preview-spreads>
        </div>
      </ng-container>
    </m-side-panel>
  </div>

  <div class="preview" *ngIf="selectedTypes?.exportPrint">
    <m-preview-spread
      [imageDataURLs]="previews$ | async"
      [selected-left-page]="selectedLeftPage"
      (onSelectLeftPage)="onSelectLeftPage($event)"
    ></m-preview-spread>
  </div>

  <div class="side-panel-right">
    <m-side-panel *ngIf="showLinksSidebar" sidebar-position="right" sidebar-type="fixed">
      <ng-container>
        <div class="side-panel-right-context">
          <div class="section-wrapper">
            <span class="section-title" i18n="@@books.preview-page.edit.tilte">books.preview-page.edit.tilte</span>
            <span i18n="@@books.preview.process-info">books.preview.process-info</span>
            <span i18n="@@books.preview.process-info-2">books.preview.process-info-2</span>
          </div>

          <div class="divider-hor"></div>

          <div class="edit-button-wrapper">
            <span i18n="@@books.preview-page.edit.project-info">books.preview-page.edit.project-info</span>
            <m-ng-icon class="edit-icon" src="pencil-14.svg" [size]="16" (click)="onEditClick('book-data')" />
          </div>

          <div class="edit-button-wrapper">
            <span i18n="@@books.preview-page.edit.markup">books.preview-page.edit.markup</span>
            <m-ng-icon class="edit-icon" src="pencil-14.svg" [size]="16" (click)="onEditClick('markup')" />
          </div>

          <div class="edit-button-wrapper">
            <span i18n="@@books.preview-page.edit.margins">books.preview-page.edit.margins</span>
            <m-ng-icon class="edit-icon" src="pencil-14.svg" [size]="16" (click)="onEditClick('margins')" />
          </div>

          <div class="divider-hor"></div>

          <form class="section-wrapper" [formGroup]="form">
            <span class="section-title" i18n="@@books.preview-page.edit.book-type.title">
              books.preview-page.edit.book-type.title
            </span>

            <div class="controls">
              <div class="control-wrapper">
                <m-switch formControlName="exportPrint" input-style="on-panel" class="control">
                  <span i18n="@@books.preview-page.edit.export-print">books.preview-page.edit.export-print</span>
                </m-switch>

                <div *ngIf="bookPrice && bookPrice.print" class="type-price-wrapper">
                  <m-ng-icon class="coins-icon" src="coins-16.svg" [size]="16" />
                  <span>{{ bookPrice.print }}</span>
                </div>
              </div>

              <div class="control-wrapper">
                @if (canProduceEpub) {
                <m-switch formControlName="exportEpub" input-style="on-panel" class="control">
                  <span i18n="@@books.preview-page.edit.export-epub">books.preview-page.edit.export-epub</span>
                </m-switch>
                }

                <div *ngIf="bookPrice && bookPrice.epub" class="type-price-wrapper">
                  <m-ng-icon class="coins-icon" src="coins-16.svg" [size]="16" />
                  <span>{{ bookPrice.epub }}</span>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="buttons">
          <m-button
            *ngIf="book?.exportEpub && book?.bookResults?.isEpubPreviewsReady && selectedTypes?.exportEpub"
            class="button-download"
            button-style="secondary"
            (onClick)="onDownloadPreviewEpubClick()"
            [is-disabled]="!book?.bookResults?.isEpubPreviewsReady"
            i18n="@@books.preview.preview-epub"
          >
            books.preview.preview-epub
          </m-button>

          <m-button class="button-produce" button-style="primary" (onClick)="onProduceClick()">
            <span i18n="@@books.preview.layout-book">books.preview.layout-book</span>

            <div *ngIf="amount" class="price-wrapper">
              <m-ng-icon class="coins-icon" src="coins-01.svg" [size]="16" />
              <span>{{ amount }}</span>
            </div>
          </m-button>
        </div>
      </ng-container>
    </m-side-panel>
    <m-preview-sidebar-layout-steps
      *ngIf="showLayoutStepsSidebar"
      [book]="book"
      [isPreviewsReady]="isLayoutFinished('preview')"
      [isFinalsReady]="isLayoutFinished('final')"
      [layoutStep]="layoutStep"
      [isPremiumLabelVisible]="isPremiumLabelVisible"
      (onNewBookClick)="showNewProjectModal()"
      (onDownloadProjectClick)="onDownloadProjectClick()"
      (onDownloadEpubClick)="onDownloadPreviewEpubClick()"
    />
  </div>
</div>

<m-payments-modal
  *ngIf="isPaymentsModalVisible"
  [payment-data]="paymentData"
  [is-purchases-available]="isPurchasesAvailable"
  [currency]="currency"
  (payGoldCredits)="onPayGoldCredits($event)"
  (payCredits)="onPayCredits($event)"
  (buyCredits)="onCreditsBuy($event)"
  (close)="closePricingModal()"
/>

<m-low-balance-modal
  *ngIf="isLowBalanceModalVisible && (tariffsForUpgrade$ | async) as tariffs"
  @fadeInOut
  [user]="user"
  [tariffs]="tariffs"
  [balanceData]="balanceData"
  [activeSubscription]="activeSubscription"
  [userRewardOneTime]="getSubscribeToTelegramChannelReward()"
  [currency]="currency"
  (close)="closePricingModal()"
  (payWithGoldCredits)="onPayGoldCredits($event)"
  (buySubscription)="onBuySubscription($event)"
/>

<m-info-modal
  *ngIf="isChangeTariffModalVisible"
  [title]="getInfoModalTitle()"
  [text]="getInfoModalText()"
  actionText="books.info-modal.projects-count-limit.action-text"
  i18n-actionText="@@books.info-modal.projects-count-limit.action-text"
  [backgroundImage]="'/assets/img/info-modal-projects-count-limit.png'"
  (actionClick)="onClickChangeTariffModal()"
  (close)="onCloseChangeTariffModal()"
/>

<m-info-modal
  *ngIf="isFreemiumModalVisible"
  title="books.info-modal.free-layout.text"
  i18n-title="@@books.info-modal.free-layout.title"
  text="books.info-modal.free-layout.text"
  i18n-text="@@books.info-modal.free-layout.text"
  actionText="books.info-modal.free-layout.action-text"
  i18n-actionText="@@books.info-modal.free-layout.action-text"
  [backgroundImage]="getThemeSpecificImageSource()"
  (actionClick)="onLayoutFreeBook()"
  (close)="closeFreemiumModal()"
/>

<m-confirmation-modal
  *ngIf="isUpdatePreviewModalVisible"
  text="books.preview.update-preview.modal.title"
  i18n-text="@@books.preview.update-preview.modal.title"
  confirmText="books.preview.update-preview.modal.yes"
  i18n-confirmText="@@books.preview.update-preview.modal.yes"
  cancelText="books.preview.update-preview.modal.no"
  i18n-cancelText="@@books.preview.update-preview.modal.no"
  (onConfirm)="onSubmitUpdatePreview()"
  (onClose)="onCancelUpdatePreview()"
/>

<m-premium-tariffs-modal @fadeInOut *ngIf="isPremiumModalVisible" (close)="closePremiumModal()" />

<m-loading-spinner *ngIf="(loadingState$ | async)?.isActive  ?? false" class="layout-process-loading">
  <div class="comment">{{(loadingState$ | async)?.description ?? ''}}</div>
</m-loading-spinner>

<m-modal-project-router-host />
