import { ChangeDetectorRef, Component, ElementRef, Input, Renderer2, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

export type InputTextAlign = 'left' | 'center' | 'right';

@Component({
  selector: 'm-markup-font-settings-input',
  template: `
    <div
      class="input-container"
      title="{{ tooltipText }}"
      [ngClass]="getCssClassList()"
      (mouseenter)="onMouseenter()"
      (mouseleave)="onMouseleave()"
    >
      <m-icon *ngIf="icon" [src]="icon" class="icon" [color]="getIconColor()" size="s"></m-icon>

      <input
        class="size"
        [class.error]="hasError"
        type="text"
        #input
        (input)="onInputChange($event)"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
        (keydown)="onKeyDown($event)"
      />

      <span *ngIf="units" class="units">{{ units }}</span>
    </div>
  `,
  styleUrls: ['./markup-font-settings-input.view.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkupFontSettingsInput),
      multi: true,
    },
  ],
})
export class MarkupFontSettingsInput {
  @Input()
  showControls!: boolean;
  @Input()
  hasError = false;
  @Input()
  icon?: string;
  @Input()
  textAlign?: InputTextAlign = 'right';
  @Input()
  units?: string = undefined;
  @Input()
  step = 1;
  @Input()
  multiplier = 10;
  @Input()
  tooltipText = '';

  protected isInputFocused = false;
  protected isDisabled = false;
  protected value = 0;
  protected admissibleValue = 0;

  protected isHovered = false;

  inputChanged: Subject<number> = new Subject<number>();

  @ViewChild('input', { static: true, read: ElementRef })
  protected inputElementRef?: ElementRef;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  constructor(
    private renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.inputChanged.pipe(debounceTime(2000), distinctUntilChanged()).subscribe((value) => {
      this.value = value;
      this.admissibleValue = value;
      this.onChange(this.value);
    });
  }

  writeValue(value: number): void {
    this.value = value;
    this.admissibleValue = value;
    this.setValue(this.value);
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  onInputChange(event: Event) {
    let value = (event.target! as HTMLInputElement).value;

    value = value.replaceAll(',', '.');
    const digitNumber = +value;
    if (Number.isNaN(digitNumber)) {
      this.setValue(this.admissibleValue);
      return;
    }

    this.inputChanged.next(digitNumber);
  }

  setValue(value: number) {
    this.renderer.setProperty(this.inputElementRef?.nativeElement, 'value', value);
    this.onChange(value);
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    result.push(`text-align-${this.textAlign}`);

    if (this.isInputFocused) {
      result.push('focused');
    }
    if (this.icon) {
      result.push('has-icon');
    }
    if (this.units) {
      result.push('has-units');
    }
    return result;
  }

  protected getIconColor() {
    if (this.isInputFocused) {
      return '--color-font-input';
    }
    return '--color-bg-input-icons';
  }

  protected onInputFocus() {
    this.isInputFocused = true;
    this.onTouched();
  }

  protected onInputBlur() {
    this.isInputFocused = false;
  }

  protected onKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowUp') {
      this.increase(event.shiftKey);
    }
    if (event.key === 'ArrowDown') {
      this.decrease(event.shiftKey);
    }
  }

  protected increase(isMultiplierEnable: boolean) {
    if (this.value % 1 !== 0) {
      this.value = Math.ceil(this.value);
    } else {
      const addendum = isMultiplierEnable ? this.step * this.multiplier : this.step;
      this.value += addendum;
    }
    this.setValue(this.value);
  }

  protected decrease(isMultiplierEnable: boolean) {
    if (this.value % 1 !== 0) {
      this.value = Math.floor(this.value);
    } else {
      const addendum = isMultiplierEnable ? this.step * this.multiplier : this.step;
      this.value -= addendum;
    }
    this.setValue(this.value);
  }

  protected onMouseenter() {
    this.isHovered = true;
  }

  protected onMouseleave() {
    this.isHovered = false;
  }
}
