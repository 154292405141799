import { ChangeDetectorRef, Component, EventEmitter, Output, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "m-images-count-selector",
  templateUrl: "./images-count-selector.view.html",
  styleUrls: ["./images-count-selector.view.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImagesCountSelectorView),
      multi: true,
    },
  ],
})
export class ImagesCountSelectorView {
  private onTouched = () => {};
  private onChange = (_: any) => {};

  protected value = 4;
  protected items: number[] = [1, 2, 3, 4];

  protected isDisabled = false;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onSelect(value: number) {
    this.value = value;

    this.onChange(this.value);
  }
}
