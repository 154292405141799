<m-ng-island>
  <div class="container">
    <div class="group">
      <m-logo logo-style="full"></m-logo>
      <m-ng-icon class="color-scheme" [size]="28" [src]="colorThemeIcon" (click)="onColorThemeCLick()" />
    </div>

    <div class="cover">
      <img [src]="coverSrc" />
    </div>
  </div>
</m-ng-island>
