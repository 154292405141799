import { ChangeDetectionStrategy, Component, HostListener, Input, OnDestroy } from "@angular/core";
import { Book } from "@metranpage/book-data";
import { RouterService, filterUndefined } from "@metranpage/core";
import { User, UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";
import { BooksStore } from "../../services/books.store";

@Component({
  selector: "m-new-cover-modal",
  templateUrl: "./new-cover-modal.view.html",
  styleUrls: ["./new-cover-modal.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewCoverModalView implements OnDestroy {
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;

  protected user!: User;
  protected book!: Book;

  private sub = new Subscription();

  constructor(
    private readonly routerService: RouterService,
    userStore: UserStore,
    bookStore: BooksStore,
  ) {
    this.sub.add(
      userStore
        .getUserObservable()
        .pipe(filterUndefined())
        .subscribe((user) => {
          this.user = user;
        }),
    );

    this.sub.add(
      bookStore
        .getModalBookObservable()
        .pipe(filterUndefined())
        .subscribe((b) => {
          this.book = b;
        }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  protected async onCloseClick() {
    this.routerService.closeModal();
  }

  protected onBackDropClick() {
    if (!this.closeOnBackDropClick) {
      return;
    }
    this.onCloseClick();
  }

  @HostListener("window:keydown", ["$event"])
  protected handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.onCloseClick();
    }
  }
}
