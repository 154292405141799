import { CommonModule, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

export type NavTab = {
  id: number | string;
  isActive?: boolean;
  text: string;
};

@Component({
  selector: 'm-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss'],
  standalone: true,
  imports: [CommonModule, NgClass],
})
export class NavTabsComponent implements OnInit {
  @Input()
  tabs: NavTab[] = [];
  @Input('active-tab')
  activeTab?: number | string;
  @Input()
  alignTabs: 'left' | 'center' | 'right' = 'left';
  @Input()
  edgePadding = true;

  @Output()
  onTabClick = new EventEmitter<NavTab>();

  constructor(private readonly router: Router) {}

  ngOnInit(): void {}

  protected onClick(tab: NavTab) {
    this.onTabClick.emit(tab);
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    result.push(`position-${this.alignTabs}`);
    if (this.edgePadding) {
      result.push('edge-padding');
    }

    return result;
  }
}
