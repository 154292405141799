import { CommonModule, NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'm-form-error',
  template: `
    <m-icon src="login-error.svg" color="--color-error-text" size="xs" />
    <span class="fe-label"><ng-content></ng-content></span>
  `,
  styles: [
    `

      :host {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
      }

      .fe-label {
        margin-left: 6px;
        color: var(--color-error-text);
        font-size: var(--font-size-xxxs);
        font-weight: var(--font-weight-em);
        text-align: left;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, NgClass, AngularSvgIconModule, IconComponent],
})
export class FormErrorComponent {}
