import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { CompanyFont } from "@metranpage/book-data";
import { Api, BASE_URL } from "@metranpage/core-api";

export type FontsFilter = {
  name?: string;
};

@Injectable({
  providedIn: "root",
})
export class FontsApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getCompanyFonts() {
    return this.get<CompanyFont[]>("fonts");
  }
}
