import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { DateTime } from "luxon";
import { Subscription, interval } from "rxjs";
import { ImageGeneration } from "../../models/image-generation";

@Component({
  selector: "m-generating-image-card",
  templateUrl: "./generating-image-card.view.html",
  styleUrls: ["./generating-image-card.view.scss"],
  animations: [fadeInOutOnEnterLeave],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneratingImageCardView implements OnInit, OnDestroy {
  @Input()
  type: "timer" | "text" = "timer";
  @Input()
  text?: string;
  @Input()
  imageGeneration!: ImageGeneration;

  @HostBinding("class.width-limited")
  @Input()
  isWidthLimited = true;

  @HostBinding("style.aspect-ratio")
  protected aspectRatio = "1 / 1";

  protected time = "";
  protected intervalId?: Subscription;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.aspectRatio = `${this.imageGeneration.width} / ${this.imageGeneration.height}`;

    this.intervalId = interval(100).subscribe(() => {
      this.getTime();
    });
  }

  ngOnDestroy() {
    this.intervalId?.unsubscribe();
  }

  getTime() {
    let start: any = DateTime.now();
    if (this.imageGeneration.createdAt) {
      start = DateTime.fromISO(this.imageGeneration.createdAt.toString());
    }

    const end = DateTime.now();
    const diff = end.diff(start, ["hours", "minutes", "seconds", "milliseconds"]);

    this.time = "";
    if (diff.hours > 0) {
      this.time += `${diff.hours.toString().padStart(2, "0")}:`;
    }
    if (diff.hours > 0 || diff.minutes > 0) {
      this.time += `${diff.minutes.toString().padStart(2, "0")}:`;
    }
    if (diff.minutes > 0 || diff.seconds >= 0) {
      this.time += `${diff.seconds.toString().padStart(2, "0")}:`;
    }
    if (diff.seconds > 0 || diff.milliseconds >= 0) {
      this.time += `${Math.trunc(diff.milliseconds / 10)
        .toString()
        .padStart(2, "0")}`;
    }
    this.cdr.detectChanges();
  }
}
