import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { CompanyApiSettings, TestWebhookEventResult } from "../../models/api-settings";
import { AdminCompanyApiSettingsApi } from "./companies-api.api";
import { AdminCompanyApiSettingsStore } from "./companies-api.store";

export type UpdateCompanyApiSettingsResult = {
  status: GeneralResultStatus;
  error?: unknown;
  apiSettings?: CompanyApiSettings;
};

@Injectable({
  providedIn: "root",
})
export class AdminCompanyApiSettingsService {
  constructor(
    private readonly api: AdminCompanyApiSettingsApi,
    private readonly store: AdminCompanyApiSettingsStore,
  ) {}

  async refreshApiSettings() {
    const settings = await this.api.getApiSettings();
    this.store.setApiSettings(settings);
  }

  async resetApiToken(): Promise<{ status: GeneralResultStatus; token?: string }> {
    try {
      const result = await this.api.resetApiToken();
      await this.refreshApiSettings();
      return {
        status: "success",
        token: result.token,
      };
    } catch (error: unknown) {
      return {
        status: "error",
      };
    }
  }

  async updateApiSettings(data: CompanyApiSettings): Promise<UpdateCompanyApiSettingsResult> {
    try {
      const settings = await this.api.updateApiSettings(data);
      this.store.setApiSettings(settings);
      return {
        status: "success",
        apiSettings: settings,
      };
    } catch (error: unknown) {
      return {
        status: "error",
        error: error,
      };
    }
  }

  async testWebhook(url: string): Promise<{ status: GeneralResultStatus; result?: TestWebhookEventResult }> {
    try {
      const result = await this.api.testWebhook(url);
      if (!result.success) {
        return {
          status: "error",
          result
        };
      }
      return {
        status: "success",
        result
      };
    } catch (error: unknown) {
      return {
        status: "error",
      };
    }
  }
}
