import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { I18nService } from "@metranpage/i18n";
import { DateTime } from "luxon";
import { NotificationBookLayout } from "../../../models/notification";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-book-layout-card-view",
  templateUrl: "account-notification-book-layout-card.view.html",
  styleUrls: ["account-notification-book-layout-card.view.scss"],
})
export class AccountNotificationBookLayoutCardView extends AccountNotificationBaseCardView<NotificationBookLayout> {
  protected locale = "en";

  constructor(
    private readonly router: Router,
    private readonly i18nService: I18nService,
  ) {
    super();
    this.locale = i18nService.getLocale();
  }

  protected openBookPage() {
    this.router.navigate(["books", this.payload.bookId, "preview"]);
  }
}
