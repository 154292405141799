import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Output,
  Renderer2,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
  selector: "[contenteditable][formControlName],[contenteditable][formControl],[contenteditable][ngModel]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContenteditableDirective),
      multi: true,
    },
  ],
  standalone: false,
})
export class ContenteditableDirective implements ControlValueAccessor {
  @Output() contenteditablechange = new EventEmitter<string>();
  @Output() contenteditableblur = new EventEmitter<FocusEvent>();
  @Output() contenteditablefocus = new EventEmitter<FocusEvent>();

  constructor(
    @Inject(ElementRef) private readonly elementRef: ElementRef,
    @Inject(Renderer2) private readonly renderer: Renderer2,
  ) {}

  private onTouched = () => {};

  private onChange: (value: string) => void = () => {};

  registerOnChange(onChange: (value: string) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  @HostListener("input")
  onInput() {
    const value = this.elementRef.nativeElement.innerText;
    this.onChange(value);
    this.contenteditablechange.emit(value);
  }

  @HostListener("keydown", ["$event"])
  onKeydown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  @HostListener("blur", ["$event"])
  onBlur(event: FocusEvent) {
    this.onTouched();
    this.contenteditableblur.emit(event);
  }

  @HostListener("focus", ["$event"])
  onFocus(event: FocusEvent) {
    this.onTouched();
    this.contenteditablefocus.emit(event);
  }

  setDisabledState(disabled: boolean) {
    this.renderer.setAttribute(this.elementRef.nativeElement, "contenteditable", String(!disabled));
  }

  writeValue(value: string) {
    this.renderer.setProperty(this.elementRef.nativeElement, "innerText", value);
  }
}
