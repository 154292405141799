import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { fadeInOutOnEnterLeave, slideInOutVertical } from "@metranpage/components";
import { Character } from "../../models/character/character";
import { CharactersService } from "../../services/characters.service";
import { GeneratedImageService } from "../../services/generated-image.service";

@Component({
  selector: "m-characters-popup",
  templateUrl: "./characters-popup.view.html",
  styleUrls: ["./characters-popup.view.scss"],
  animations: [slideInOutVertical, fadeInOutOnEnterLeave],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CharactersPopupView implements OnChanges {
  @Input()
  characters: Character[] = [];
  @Input()
  hasAddButton = false;
  @Input()
  isActive = false;

  @Output()
  onSelect = new EventEmitter<Character>();
  @Output()
  onSelectByKeyboard = new EventEmitter<Character>();
  @Output()
  onAdd = new EventEmitter();
  @Output()
  onClose = new EventEmitter();

  protected selectedCharacterIndex = 0;
  protected selectedCharacter: Character | undefined = undefined;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly charactersService: CharactersService,
    private readonly generatedImageService: GeneratedImageService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.resetSelectedCharacter();
  }

  protected onSelectClick(character: Character) {
    this.onSelect.emit(character);
  }

  trackById(index: number, character: Character) {
    return character.id;
  }

  protected getUrlForImage(character: Character) {
    const imageGenerationId = character.imageGenerationId;
    const imageUrl = character.generatedImages[0];
    if (!imageGenerationId || !imageUrl) {
      return "";
    }
    return this.generatedImageService.getUrlForImage(imageGenerationId, imageUrl, true);
  }

  protected onAddCharacterClick() {
    this.onAdd.emit();
  }

  protected nextCharacter() {
    if (!this.isActive) {
      return;
    }

    this.selectedCharacterIndex++;
    if (this.selectedCharacterIndex >= this.characters.length) {
      this.selectedCharacterIndex = 0;
      if (this.hasAddButton) {
        this.selectedCharacterIndex = -1;
      }
    }
    this.selectedCharacter = this.characters[this.selectedCharacterIndex];
    this.scrollToPreview();
  }

  protected previousCharacter() {
    if (!this.isActive) {
      return;
    }

    this.selectedCharacterIndex--;
    const minimalIndex = this.hasAddButton ? -1 : 0;
    if (this.selectedCharacterIndex < minimalIndex) {
      this.selectedCharacterIndex = this.characters.length - 1;
    }
    this.selectedCharacter = this.characters[this.selectedCharacterIndex];
    this.scrollToPreview();
  }

  private resetSelectedCharacter() {
    this.selectedCharacterIndex = 0;
    if (this.hasAddButton) {
      this.selectedCharacterIndex = -1;
    }
    this.selectedCharacter = this.characters[this.selectedCharacterIndex];
    // this.selectedCharacter = undefined;
  }

  protected selectCharacterByKeyboard(event: KeyboardEvent) {
    if (!this.isActive) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (this.hasAddButton && !this.selectedCharacter) {
      this.onAddCharacterClick();
      return;
    }

    this.onSelectByKeyboard.emit(this.selectedCharacter);
  }

  protected close() {
    this.resetSelectedCharacter();
    this.onClose.emit();
  }

  protected scrollToPreview() {
    const id = this.selectedCharacter ? `#character-${this.selectedCharacter.id}` : "#character-add";
    const characterElement = this.elementRef.nativeElement.querySelector(id);
    characterElement?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }

  protected onItemMouseEnter(character: Character | undefined) {
    this.selectedCharacter = character;
    this.selectedCharacterIndex = this.characters.findIndex((c) => c.id === character?.id);
  }

  @HostListener("mouseenter", ["$event"])
  onMouseEnter(event: MouseEvent) {
    this.charactersService.onVisibilityChangeSelectCharacterPopup(true);
  }

  @HostListener("mouseleave", ["$event"])
  onMouseLeave(event: MouseEvent) {
    this.charactersService.onVisibilityChangeSelectCharacterPopup(false);
  }

  @HostListener("document:mousedown", ["$event"])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  @HostListener("document:keydown", ["$event"])
  onKeydown(event: KeyboardEvent): void {
    if (event.key === "Escape") {
      this.onClose.emit();
    }
    if (event.key === "Enter") {
      this.selectCharacterByKeyboard(event);
    }
    if (event.key === "ArrowUp") {
      this.previousCharacter();
    }
    if (event.key === "ArrowDown") {
      this.nextCharacter();
    }
  }
}
