import { StyleDef, StyleKey } from "./../../models/styles";

export const FakeCursorClassName = "fake-cursor";
export const FakeCursorNode = (() => {
  const cursor = document.createElement("span");
  cursor.className = FakeCursorClassName;
  return cursor;
})();
export const FakeCursorItemAsText = FakeCursorNode.outerHTML;

export interface EditorData {
  blocks: EditorBlock[];
}

/**
 * Base editor block, holding raw data
 */
export interface EditorBlock {
  id: BlockId;
  style: StyleKey;
  data: any;
  text?: string;
  warnings?: Warning[];
}

export type BlockId = string;

export type EditorBlockErrorType = "toc" | "table-cell" | "unknown";

export interface EditorBlockError {
  type: EditorBlockErrorType;
  message: string;
  action: "delete" | "nothing";
  actionText: string;
}

export type EditorErrors = Record<BlockId, EditorBlockError[]>;

export interface Footnote {
  id: number;
  text: string;
  isFocused: boolean;
}

export type EditorDataItem = {
  // id?: string;
  text?: string;
  block: EditorBlock;
  errors: EditorBlockError[];
};

export interface UpdateEvent {
  type: string;
  block?: any;
}

export type SelectionState = {
  hasBold: boolean;
  hasItalic: boolean;
  hasFootnote: boolean;
};

/**
 * Base block config, for single block
 */
export type BlockConfig = StyleDef & {
  showStyleDelimiter: boolean;
  canChangeBlockType: boolean;
  canMerge: boolean;
  canSplit: boolean;
  isFootnotesEnabled: boolean;
  // [others: string]: any;
};

export interface SetCursorOptions {
  atStart?: boolean;
  atEnd?: boolean;
}

// type WarningTitle = "";

export type Warning = {
  id: number;
  warning: string;
  text: string;
};
export type BlockWarnings = {
  blockId: string;
  warnings: Warning[];
};
