import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DateTime } from "luxon";
import { takeUntil } from "rxjs";
import { ReferalLink } from "../../models/referal-link";
import { ReferalLinkStat } from "../../services/admin.api";
import { AdminReferalLinksService } from "../../services/referal-links/referal-links.service";
import { AdminReferalLinksStore } from "../../services/referal-links/referal-links.store";
import { AdminBasePage } from "../admin/admin.page";
import { I18nService } from "@metranpage/i18n";

@Component({
  selector: "m-admin-referal-link-stats-page",
  templateUrl: "./referal-link-stats.page.html",
  styleUrls: ["./referal-link-stats.page.scss"],
})
export class AdminReferalLinkStatsPage extends AdminBasePage implements OnInit, AfterViewInit {
  protected referalLink: ReferalLink | undefined;
  protected referalLinkUrl = "";

  protected stats: ReferalLinkStat[] = [];

  protected locale = "en";

  constructor(
    private readonly adminReferalLinksService: AdminReferalLinksService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    adminReferalLinksStore: AdminReferalLinksStore,
    i18nService: I18nService,
  ) {
    super();

    this.addSub(
      adminReferalLinksStore
        .getActiveReferalLinkObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((referalLink) => {
          this.referalLink = referalLink;

          if (this.referalLink) {
            this.updateReferalLinkStat(this.referalLink.id);
          }
        }),
    );

    this.locale = i18nService.getLocale();
  }

  ngOnInit(): void { }

  async ngAfterViewInit() {
    if ("referalLinkId" in this.route.snapshot.params) {
      this.adminReferalLinksService.loadReferalLink(this.route.snapshot.params.referalLinkId);
    }
  }

  onBack() {
    this.router.navigate(["admin", "referal-links"]);
  }

  protected async updateReferalLinkStat(referalLinkId: number) {
    this.stats = await this.adminReferalLinksService.updateReferalLinkStat(referalLinkId);
  }

  getLastDayOfMonth(year: number, month: number) {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
  }

  protected getDateShort(date: string) {
    return DateTime.fromISO(date)
      .toLocaleString({ year: "numeric", month: "long" }, { locale: this.locale })
      .toUpperCase();
  }

  protected getDate(date: string) {
    return DateTime.fromISO(date).toLocaleString(
      { year: "numeric", month: "long", day: "numeric" },
      { locale: this.locale },
    );
  }
}
