<m-ng-island>
  <form [formGroup]="form">
    <div class="controls">
      <div class="control">
        <span class="label" i18n="@@user.profile.password.old-password">user.profile.password.old-password</span>
        <m-ng-input-password formControlName="oldPassword" type="password" />
      </div>

      <div class="control">
        <span class="label" i18n="@@user.profile.password.new-password">user.profile.password.new-password</span>
        <m-ng-input-password formControlName="newPassword" type="password" />
      </div>

      <div class="control">
        <span class="label" i18n="@@user.profile.password.new-password-repeat">
          user.profile.password.new-password-repeat
        </span>
        <m-ng-input-password formControlName="newPasswordRepeat" type="password" />
      </div>

      <div class="action-block">
        <m-button
          button-style="secondary"
          (onClick)="onSaveButtonClick()"
          [is-disabled]="!isPasswordsEqual"
          i18n="@@user.profile.password.change-password"
        >
          user.profile.password.change-password
        </m-button>

        <span *ngIf="savedStatus" class="status" i18n="@@user.profile.password.changed">
          user.profile.password.changed
        </span>
        <!-- <span *ngIf="!savedStatus" class="status" i18n="@@user.profile.password.wrong-old-password">
        user.profile.password.wrong-old-password
      </span> -->
      </div>
    </div>
  </form>
</m-ng-island>
