import { Component, EventEmitter, HostListener, Inject, Input, Output } from "@angular/core";
import { ConfigService } from "@metranpage/core-interfaces";
import { BookService } from "../../services/book.service";

@Component({
  selector: "m-template-preview-card-new",
  templateUrl: "./template-preview-card.view.html",
  styleUrls: ["./template-preview-card.view.scss"],
})
export class TemplatePreviewCardNew {
  @Input()
  templateId!: number;
  @Input()
  previews: string[] = [];
  @Input()
  title!: string;
  @Input()
  description!: string;
  @Input()
  isDetailsAvailable!: boolean;
  @Input()
  imgSrc = "/assets/images/template-from-scratch-{theme}.svg";
  @Input()
  isSelectDisable = false;
  @Input()
  isPremium = false;

  @Output()
  onSelect = new EventEmitter<void>();
  @Output()
  onPreview = new EventEmitter<void>();

  protected isHovered = false;

  constructor(private readonly bookService: BookService) {}

  onInfoClick() {
    if (!this.isDetailsAvailable) {
      this.onSelectClick();
    }
    this.onPreviewClick();
  }

  onSelectClick() {
    this.onSelect.emit();
  }

  onPreviewClick() {
    this.onPreview.emit();
  }

  formatPreviewImage(imageName: string) {
    return this.bookService.getUrlForTemplatePreview(this.templateId, imageName);
  }

  @HostListener("mouseenter", ["$event"]) onEnter(e: MouseEvent) {
    this.isHovered = true;
  }

  @HostListener("mouseleave", ["$event"]) onLeave(e: MouseEvent) {
    this.isHovered = false;
  }
}
