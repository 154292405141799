import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { FormatAccess } from "../../models/format-access.model";

export type State = {
  formatsAccess: FormatAccess[];
};

@Injectable({
  providedIn: "root",
})
export class AdminFormatAccessStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      formatsAccess: [],
    };
  }

  getFormatsAccess() {
    return this.getField("formatsAccess");
  }

  getFormatsAccessObservable() {
    return this.getFieldObservable("formatsAccess");
  }

  setFormatsAccess(formatAccess: FormatAccess[]) {
    this.update((state) => ({ ...state, formatsAccess: formatAccess }));
  }

  addFormatAccess(formatAccess: FormatAccess) {
    this.update((state) => {
      const storeFormatAccess = state.formatsAccess.find((fa) => fa.id === formatAccess.id);
      if (storeFormatAccess) {
        return state;
      }

      return {
        ...state,
        storeFormatAccess: [formatAccess].concat(state.formatsAccess),
      };
    });
  }

  updateFormatAccess(updatedFormatAccess: FormatAccess) {
    const formatAccess = this.getFormatsAccess();
    const storeFormatAccess = formatAccess.find((fa) => fa.id === updatedFormatAccess.id);

    if (storeFormatAccess) {
      this.update((state) => {
        const updatedFormatAccessList = state.formatsAccess.map((fa) => {
          if (fa.id === updatedFormatAccess.id) {
            return updatedFormatAccess;
          }
          return fa;
        });

        return {
          ...state,
          formatsAccess: updatedFormatAccessList,
        };
      });
    } else {
      this.addFormatAccess(updatedFormatAccess);
    }
  }
}
