import { Component, Input } from "@angular/core";
import { User } from "@metranpage/user-data";
import { UserRewardOneTime } from "../../../../models/rewards/user-reward-onetime";
import { ConnectTelegramService } from "../../../../services/connect-telegram.service";

@Component({
  selector: "m-reward-task-connect-telegram-view",
  templateUrl: "reward-task-connect-telegram.view.html",
  styleUrls: ["reward-task-connect-telegram.view.scss"],
})
export class RewardTaskConnectTelegramView {
  @Input()
  user?: User;
  @Input()
  userRewardOneTime!: UserRewardOneTime;

  constructor(private readonly connectTelegramService: ConnectTelegramService) {}

  protected onSubscribeToChannelTelegramClick() {
    this.connectTelegramService.subscribeToChannel();
  }

  protected async onCheckSubscribeToChannelTelegramClick() {
    this.connectTelegramService.checkSubscribeToChannel();
  }

  protected canCheckSubscriptionToChannel() {
    return !!this.user?.telegramProfile && !this.user?.telegramProfile?.isChannelConnected;
  }

  protected isTaskConnectTelegramProfileCompleted() {
    return !!this.user?.telegramProfile;
  }

  protected isTaskSubscribeToTelegramChannelCompleted() {
    return !!this.user?.telegramProfile?.isChannelConnected;
  }
}
