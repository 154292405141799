import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService, LoadingState } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { ConfigService } from "@metranpage/core-interfaces";
import { I18nService } from "@metranpage/i18n";
import { Theme, ThemeService } from "@metranpage/theme";
import { Observable } from "rxjs";
import { LoginError } from "../../models/login-errors";
import { AuthService } from "../../services/auth.service";
import { Creds } from "../../views/login/login.view";

@Component({
  selector: "m-login-page",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage implements OnInit, OnDestroy {
  loadingState$: Observable<LoadingState>;

  loginErrors: LoginError[] = [];
  protected userLanguage;

  protected colorTheme: Theme = "light";

  protected isVkLoginAvailable = true;
  protected isGoogleLoginAvailable = true;

  protected returnUrl?: string;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
    private readonly i18nService: I18nService,
    private readonly themeService: ThemeService,
    private readonly notificationsService: NotificationsPopUpService,
    private readonly route: ActivatedRoute,
    @Inject("ConfigService")
    readonly configService: ConfigService,
  ) {
    this.userLanguage = i18nService.getLocale();
    this.loadingState$ = this.loadingService.loadingState$;

    this.isVkLoginAvailable = this.configService.getConfig().company.flags.isVkLoginAvailable;
    this.isGoogleLoginAvailable = this.configService.getConfig().company.flags.isGoogleLoginAvailable;

    this.returnUrl = this.route.snapshot.queryParams["returnUrl"];
  }

  ngOnInit(): void {
    this.updateColorTheme();
  }

  ngOnDestroy(): void {}

  async onLogin(creds: Creds) {
    this.notificationsService.closeAll();

    this.loadingService.startLoading();
    const result = await this.authService.login(creds.email, creds.password);

    // clean errors after request due to ugly window size change when error hides and reappears half a second later
    this.loginErrors = [];

    if (result === "success") {
      if (this.returnUrl) {
        this.router.navigateByUrl(this.returnUrl);
      } else {
        this.navigateToRandomPath();
        // this.router.navigate(["/"]);
      }
    } else if (result === "wrong-credentials") {
      this.loginErrors.push("wrong-password");
    } else if (result === "different-login-method") {
      this.notificationsService.error($localize`:@@user.auth.wrong-login-method-error:`);
    } else if (result === "network-error") {
      this.notificationsService.error($localize`:@@user.auth.generic-error:`);
    }

    this.loadingService.stopLoading();
  }

  onLoginVk() {
    this.authService.loginVk();
  }

  onLoginGoogle() {
    this.authService.loginGoogle();
  }

  onLangChange(lang: string) {
    this.i18nService.saveLocale(lang);
    window.location.reload();
  }

  protected updateColorTheme() {
    this.colorTheme = this.themeService.getTheme();
  }

  protected onColorThemeToggle() {
    this.themeService.toggleTheme();
    this.colorTheme = this.themeService.getTheme();
  }

  private navigateToRandomPath() {
    const path = ["/"];
    const random = Math.floor(Math.random() * 2);
    if (random === 1) {
      path.push("covers");
    }
    this.router.navigate(path);
  }
}
