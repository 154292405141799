import { Injectable } from "@angular/core";
import { ApiErrorHandlerService, Errors } from "@metranpage/core";
import { SuperadminApi } from "./superadmin.api";

@Injectable({
  providedIn: "root",
})
export class SuperadminAuthService {
  constructor(
    private readonly api: SuperadminApi,
    //, private readonly store: CompaniesAdminStore
    private readonly apiErrorHandler: ApiErrorHandlerService,
  ) {}

  async login(email: string, password: string) {
    try {
      const response = await this.api.login({ email, password });
      this.saveToken(response.token);
      return "success";
    } catch (errorResponse: any) {
      const error = this.apiErrorHandler.handleApiError(errorResponse, false);

      if (error === Errors.WrongSuperadminCredentials) {
        return "wrong-credentials";
      }
      return "network-error";
    }
  }

  private saveToken(token: string) {
    localStorage.setItem("m-sa-token", token);
  }
}
