import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgxFileDropComponent, NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'm-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDropComponent {
  @Input()
  accept = '.docx';
  @Input()
  multiple = true;
  @Output() public documentParsedEvent = new EventEmitter<string>();
  @Output() dropped = new EventEmitter<NgxFileDropEntry[]>();

  @ViewChild('filedrop', { static: true })
  public fileDropComponent!: NgxFileDropComponent;

  public files: NgxFileDropEntry[] = [];
  public isHovered = false;

  _dropped(event: NgxFileDropEntry[]) {
    this.isHovered = false;
    this.dropped.emit(event);
  }

  openFileSelector() {
    this.fileDropComponent.openFileSelector();
  }
}
