<form [formGroup]="form" class="wrapper" *ngIf="values$ | async as values">
  <div class="section">
    <div class="rowset">
      <div class="caption" i18n="@@cover-editor.object.settings.alignment">cover-editor.object.settings.alignment</div>
      <m-cover-object-alignment (align)="onAlign($event)"></m-cover-object-alignment>
    </div>
  </div>
  <div class="section">
    <div class="rowset">
      <div class="row space-between">
        <div class="caption" i18n="@@cover-editor.object.settings.shape.fill">
          cover-editor.object.settings.shape.fill
        </div>
        <div class="caption dimmed">{{ fillType }}</div>
      </div>
      <div class="row">
        <m-cover-fill-select
          [fill]="currentObject.fill"
          (update)="updateFill($event)"
          [useColorPickerExternalHost]="true"
          [isGradientAvailable]="isGradientApplicable"
          popupPosition="bottom"
        ></m-cover-fill-select>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="rowset">
      <div class="row space-between">
        <div class="caption" i18n="@@cover-editor.object.settings.shape.stroke">
          cover-editor.object.settings.shape.stroke
        </div>
        <div class="caption dimmed">{{ strokeFillType }}</div>
      </div>
      <div class="row">
        <m-cover-fill-select
          [fill]="currentObject.strokeFill"
          (update)="updateStrokeFill($event)"
          [useColorPickerExternalHost]="true"
          [isGradientAvailable]="false"
          popupPosition="bottom"
        ></m-cover-fill-select>
      </div>
      <div class="caption" i18n="@@cover-editor.object.settings.shape.stroke-width">
        cover-editor.object.settings.shape.stroke-width
      </div>
      <m-ng-input-number-with-icon
        iconLeft="font-size-01.svg"
        formControlName="strokeWidth"
        size="40"
        [iconSize]="28"
        text-align="right"
      />
    </div>
  </div>

  <div class="section" *ngIf="rectangleObject">
    <div class="rowset">
      <div class="caption" i18n="@@cover-editor.object.settings.shape.corner-radius">
        cover-editor.object.settings.shape.corner-radius
      </div>
      <m-ng-input-number-with-icon
        iconLeft="corner-radius-01.svg"
        formControlName="cornerRadius"
        size="40"
        [iconSize]="28"
        text-align="right"
      />
    </div>
  </div>
</form>
