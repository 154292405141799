import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AVAILABLE_LANGUAGES, LangSpec } from "@metranpage/i18n";
import { TemplateCreateData, TemplateLoc } from "../../models/template";

@Component({
  selector: "m-admin-add-template",
  templateUrl: "./add-template.view.html",
  styleUrls: ["./add-template.view.scss"],
})
export class AddTemplateView {
  @Output()
  onTemplateAdded = new EventEmitter<TemplateCreateData>();

  constructor(@Inject(AVAILABLE_LANGUAGES) private readonly availableLanguages: LangSpec[]) {}

  protected form = new FormGroup({
    title: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    widthFrom: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
    widthTo: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
    heightFrom: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
    heightTo: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
  });

  createTemplate() {
    const formData = this.form.getRawValue();

    const localization: TemplateLoc[] = [];
    for (const lang of this.availableLanguages) {
      localization.push({
        templateId: 0,
        lang: lang.value,
        title: formData.title,
        descriptionShort: "",
        descriptionLong: "",
      });
    }

    const createTemplateData: TemplateCreateData = {
      ...formData,
      localization,
    };
    this.onTemplateAdded.emit(createTemplateData);
  }
}
