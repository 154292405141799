import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FileDropComponent, LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { NgxFileDropEntry } from "ngx-file-drop";
import { takeUntil } from "rxjs";
import { NonEditableTemplate } from "../../models/non-editable-template";
import { AdminNonEditableTemplatesService } from "../../services/non-editable-templates/non-editable-templates.service";
import { AdminNonEditableTemplatesStore } from "../../services/non-editable-templates/non-editable-templates.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-non-editable-template-page",
  templateUrl: "./non-editable-template.page.html",
  styleUrls: ["./non-editable-template.page.scss"],
})
export class AdminNonEditableTemplatePage extends AdminBasePage implements OnInit, AfterViewInit {
  nonEditableTemplate: NonEditableTemplate | undefined;

  @ViewChild("filedrop", { static: false })
  protected fileDropComponent!: FileDropComponent;

  protected archiveFile?: File;
  protected originalUploadFilename?: string;

  protected form!: FormGroup;

  constructor(
    private readonly adminNonEditableTemplatesService: AdminNonEditableTemplatesService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly formBuilder: FormBuilder,
    adminNonEditableTemplatesStore: AdminNonEditableTemplatesStore,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();

    this.createForm();

    this.addSub(
      adminNonEditableTemplatesStore
        .getActiveNonEditableTemplateObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(async (nonEditableTemplate) => {
          this.nonEditableTemplate = nonEditableTemplate;
          if (nonEditableTemplate) {
            this.originalUploadFilename = nonEditableTemplate.originalUploadFilename;

            this.form.patchValue(nonEditableTemplate);
            // this.cdr.detectChanges();
          }
        }),
    );
  }

  ngOnInit(): void {}

  async ngAfterViewInit() {
    if ("nonEditableTemplateId" in this.route.snapshot.params) {
      this.adminNonEditableTemplatesService.loadNonEditableTemplateById(
        this.route.snapshot.params["nonEditableTemplateId"],
      );
    }
  }

  async onSaveNonEditableTemplate() {
    if (!this.nonEditableTemplate || !this.originalUploadFilename) {
      return;
    }

    const formData = this.form.getRawValue();
    formData.archiveFile = this.archiveFile;

    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminNonEditableTemplatesService.updateNonEditableTemplate(
      this.nonEditableTemplate.id,
      formData,
    );
    this.loadingService.stopLoading();
    if (result === "success") {
      this.router.navigate(["admin", "non-editable-templates"]);
    } else {
      this.notificationService.error(
        $localize`:@@admin.non-editable-templates.non-editable-template.error.cant-update-non-editable-template:`,
      );
    }
  }

  async onBackClick() {
    this.router.navigate(["admin", "non-editable-templates"]);
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) => {
        this.archiveFile = file;
        this.originalUploadFilename = file.name;
      });
    }
  }

  openFileSelector() {
    this.fileDropComponent.openFileSelector();
  }

  async deleteArchiveFile() {
    this.archiveFile = undefined;
    this.originalUploadFilename = undefined;
  }

  createForm() {
    this.form = this.formBuilder.group(
      {
        title: this.formBuilder.control("", { nonNullable: true, validators: [Validators.required] }),
      },
      // { validator: [nonEditableTemplateValidator()] },
    );
  }
}
