import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { BookCover, CompanyFont } from "@metranpage/book-data";
import {
  ImageGenerationAdvancedStyle,
  ImageGenerationBasicStyle,
  ImageGenerationMode,
} from "@metranpage/image-generation";
import { CoverConceptualGeneration } from "@metranpage/text-generation";
import { FontsService } from "../../services/fonts.service";

export type ImageGenerationData = {
  prompt: string;
  styleId: number;
  mode: ImageGenerationMode;
  age?: string;
  mood?: string;
};

export type ImageGenerationSelectedStyleData = {
  style?: ImageGenerationBasicStyle | ImageGenerationAdvancedStyle;
  comment?: string;
  mood?: string;
  age?: string;
  colorBackground?: string;
  colorBackgroundHex?: string;
  colorAccent?: string;
  colorAccentHex?: string;
  mode?: ImageGenerationMode;
};

export type FontWithColorData = {
  font: string;
  color?: string;
};

export type FontsWithColorData = {
  main?: FontWithColorData;
  sub?: FontWithColorData;
  sec?: FontWithColorData;
};

@Component({
  selector: "m-cover-conceptual-assistant-generation-result",
  templateUrl: "./cover-conceptual-assistant-generation-result.view.html",
  styleUrls: ["./cover-conceptual-assistant-generation-result.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverConceptualAssistantGenerationResultView {
  @Input()
  cover!: BookCover;
  @Input()
  coverConceptualGeneration!: CoverConceptualGeneration;
  @Input()
  stylesBasic: ImageGenerationBasicStyle[] = [];
  @Input()
  stylesAdvanced: ImageGenerationAdvancedStyle[] = [];
  @Input()
  fonts: CompanyFont[] = [];

  @Output()
  generateImage = new EventEmitter<ImageGenerationData>();
  @Output()
  applyFontsAndColors = new EventEmitter<FontsWithColorData>();
  @Output()
  back = new EventEmitter<void>();
  @Output()
  closeConceptualAssistant = new EventEmitter<void>();

  protected selectedPromptIndex = 0;
  protected selectedPrompt?: string;

  protected selectedStyle?: ImageGenerationSelectedStyleData;

  protected picStyle1?: ImageGenerationBasicStyle | ImageGenerationAdvancedStyle;
  protected picStyle1Id?: number;
  protected picStyle1Mode?: ImageGenerationMode;
  protected picStyle1Comment?: string;
  protected picStyle1Mood?: string;
  protected picStyle1Age?: string;
  protected picStyle1ColorBackground?: string;
  protected picStyle1ColorBackgroundHex?: string;
  protected picStyle1ColorAccent?: string;
  protected picStyle1ColorAccentHex?: string;
  protected picStyle2?: ImageGenerationBasicStyle | ImageGenerationAdvancedStyle;
  protected picStyle2Id?: number;
  protected picStyle2Mode?: ImageGenerationMode;
  protected picStyle2Comment?: string;
  protected picStyle2Mood?: string;
  protected picStyle2Age?: string;
  protected picStyle2ColorBackground?: string;
  protected picStyle2ColorBackgroundHex?: string;
  protected picStyle2ColorAccent?: string;
  protected picStyle2ColorAccentHex?: string;
  protected picStyle3?: ImageGenerationBasicStyle | ImageGenerationAdvancedStyle;
  protected picStyle3Id?: number;
  protected picStyle3Mode?: ImageGenerationMode;
  protected picStyle3Comment?: string;
  protected picStyle3Mood?: string;
  protected picStyle3Age?: string;
  protected picStyle3ColorBackground?: string;
  protected picStyle3ColorBackgroundHex?: string;
  protected picStyle3ColorAccent?: string;
  protected picStyle3ColorAccentHex?: string;

  protected fontMain?: CompanyFont;
  protected fontMainFamilyName?: string;
  protected fontMainComment?: string;
  protected fontMainColor?: string;
  protected fontSub?: CompanyFont;
  protected fontSubFamilyName?: string;
  protected fontSubComment?: string;
  protected fontSubColor?: string;
  protected fontSec?: CompanyFont;
  protected fontSecFamilyName?: string;
  protected fontSecComment?: string;
  protected fontSecColor?: string;

  constructor(
    private readonly fontsService: FontsService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    await this.init();
  }

  async init() {
    this.updateStyles();
    await this.updateFonts();
    this.cdr.detectChanges();
  }

  protected async updateFonts() {
    this.fontMain = this.getCompanyFontByFamilyName(this.coverConceptualGeneration.fontMainFamilyName);
    this.fontMainFamilyName = this.fontMain?.familyName;
    this.fontMainComment = this.coverConceptualGeneration.fontMainComment;
    this.fontMainColor = this.coverConceptualGeneration.fontMainColor;

    this.fontSub = this.getCompanyFontByFamilyName(this.coverConceptualGeneration.fontSubFamilyName);
    this.fontSubFamilyName = this.fontSub?.familyName;
    this.fontSubComment = this.coverConceptualGeneration.fontSubComment;
    this.fontSubColor = this.coverConceptualGeneration.fontSubColor;

    this.fontSec = this.getCompanyFontByFamilyName(this.coverConceptualGeneration.fontSecFamilyName);
    this.fontSecFamilyName = this.fontSec?.familyName;
    this.fontSecComment = this.coverConceptualGeneration.fontSecComment;
    this.fontSecColor = this.coverConceptualGeneration.fontSecColor;

    await this.loadFontsCss();
  }

  getCompanyFontByFamilyName(familyName: string | undefined) {
    let newFamilyName: string | undefined = familyName;
    if (familyName?.toLowerCase() === "rubik") {
      newFamilyName = "Rubik Mono One";
    }

    return this.fontsService.getCompanyFontByFamilyName(newFamilyName, this.fonts);
  }

  async loadFontsCss() {
    if (this.fontMain) {
      await this.fontsService.loadCompanyFontCss(this.fontMain);
    }
    if (this.fontSub) {
      await this.fontsService.loadCompanyFontCss(this.fontSub);
    }
    if (this.fontSec) {
      await this.fontsService.loadCompanyFontCss(this.fontSec);
    }
  }

  protected isApplyFontAndColorDisable() {
    return !this.fontMain && !this.fontSub && !this.fontSec;
  }

  protected updateStyles() {
    this.picStyle1Id = this.coverConceptualGeneration.picStyle1Id;
    this.picStyle1Mode = this.coverConceptualGeneration.picStyle1Mode;
    this.picStyle1Comment = this.coverConceptualGeneration.picStyle1Comment;
    this.picStyle1Mood = this.coverConceptualGeneration.picStyle1Mood;
    this.picStyle1Age = this.coverConceptualGeneration.picStyle1Age;
    this.picStyle1 = this.getStyleById(this.picStyle1Id, this.picStyle1Mode);
    this.picStyle1ColorBackground = this.coverConceptualGeneration.picStyle1ColorBackground;
    this.picStyle1ColorBackgroundHex = this.coverConceptualGeneration.picStyle1ColorBackgroundHex;
    this.picStyle1ColorAccent = this.coverConceptualGeneration.picStyle1ColorAccent;
    this.picStyle1ColorAccentHex = this.coverConceptualGeneration.picStyle1ColorAccentHex;

    this.picStyle2Id = this.coverConceptualGeneration.picStyle2Id;
    this.picStyle2Mode = this.coverConceptualGeneration.picStyle2Mode;
    this.picStyle2Comment = this.coverConceptualGeneration.picStyle2Comment;
    this.picStyle2Mood = this.coverConceptualGeneration.picStyle2Mood;
    this.picStyle2Age = this.coverConceptualGeneration.picStyle2Age;
    this.picStyle2 = this.getStyleById(this.picStyle2Id, this.picStyle2Mode);
    this.picStyle2ColorBackground = this.coverConceptualGeneration.picStyle2ColorBackground;
    this.picStyle2ColorBackgroundHex = this.coverConceptualGeneration.picStyle2ColorBackgroundHex;
    this.picStyle2ColorAccent = this.coverConceptualGeneration.picStyle2ColorAccent;
    this.picStyle2ColorAccentHex = this.coverConceptualGeneration.picStyle2ColorAccentHex;

    this.picStyle3Id = this.coverConceptualGeneration.picStyle3Id;
    this.picStyle3Mode = this.coverConceptualGeneration.picStyle3Mode;
    this.picStyle3Comment = this.coverConceptualGeneration.picStyle3Comment;
    this.picStyle3Mood = this.coverConceptualGeneration.picStyle3Mood;
    this.picStyle3Age = this.coverConceptualGeneration.picStyle3Age;
    this.picStyle3 = this.getStyleById(this.picStyle3Id, this.picStyle3Mode);
    this.picStyle3ColorBackground = this.coverConceptualGeneration.picStyle3ColorBackground;
    this.picStyle3ColorBackgroundHex = this.coverConceptualGeneration.picStyle3ColorBackgroundHex;
    this.picStyle3ColorAccent = this.coverConceptualGeneration.picStyle3ColorAccent;
    this.picStyle3ColorAccentHex = this.coverConceptualGeneration.picStyle3ColorAccentHex;

    this.selectDefaultPrompt();
    this.selectDefaultStyle();
  }

  protected selectDefaultPrompt() {
    const prompts = this.coverConceptualGeneration?.prompts;
    if (!prompts) {
      return;
    }
    this.selectedPrompt = prompts[0];
  }

  protected selectDefaultStyle() {
    if (this.coverConceptualGeneration.picStyle1Id && this.coverConceptualGeneration.picStyle1Id >= 0) {
      this.selectedStyle = {
        style: this.getStyleById(
          this.coverConceptualGeneration.picStyle1Id,
          this.coverConceptualGeneration.picStyle1Mode,
        ),
        comment: this.coverConceptualGeneration.picStyle1Comment,
        mood: this.coverConceptualGeneration.picStyle1Mood,
        age: this.coverConceptualGeneration.picStyle1Age,
        colorBackground: this.coverConceptualGeneration.picStyle1ColorBackground,
        colorBackgroundHex: this.coverConceptualGeneration.picStyle1ColorBackgroundHex,
        colorAccent: this.coverConceptualGeneration.picStyle1ColorAccent,
        colorAccentHex: this.coverConceptualGeneration.picStyle1ColorAccentHex,
        mode: this.coverConceptualGeneration.picStyle1Mode,
      };
      return;
    }
    if (this.coverConceptualGeneration.picStyle2Id && this.coverConceptualGeneration.picStyle2Id >= 0) {
      this.selectedStyle = {
        style: this.getStyleById(
          this.coverConceptualGeneration.picStyle2Id,
          this.coverConceptualGeneration.picStyle2Mode,
        ),
        comment: this.coverConceptualGeneration.picStyle2Comment,
        mood: this.coverConceptualGeneration.picStyle2Mood,
        age: this.coverConceptualGeneration.picStyle2Age,
        colorBackground: this.coverConceptualGeneration.picStyle2ColorBackground,
        colorBackgroundHex: this.coverConceptualGeneration.picStyle2ColorBackgroundHex,
        colorAccent: this.coverConceptualGeneration.picStyle2ColorAccent,
        colorAccentHex: this.coverConceptualGeneration.picStyle2ColorAccentHex,
        mode: this.coverConceptualGeneration.picStyle2Mode,
      };
      return;
    }
    if (this.coverConceptualGeneration.picStyle3Id && this.coverConceptualGeneration.picStyle3Id >= 0) {
      this.selectedStyle = {
        style: this.getStyleById(
          this.coverConceptualGeneration.picStyle3Id,
          this.coverConceptualGeneration.picStyle3Mode,
        ),
        comment: this.coverConceptualGeneration.picStyle3Comment,
        mood: this.coverConceptualGeneration.picStyle3Mood,
        age: this.coverConceptualGeneration.picStyle3Age,
        colorBackground: this.coverConceptualGeneration.picStyle3ColorBackground,
        colorBackgroundHex: this.coverConceptualGeneration.picStyle3ColorBackgroundHex,
        colorAccent: this.coverConceptualGeneration.picStyle3ColorAccent,
        colorAccentHex: this.coverConceptualGeneration.picStyle3ColorAccentHex,
        mode: this.coverConceptualGeneration.picStyle3Mode,
      };
      return;
    }
  }

  protected isStyleActive(id: number | undefined, mode: ImageGenerationMode | undefined) {
    return id === this.selectedStyle?.style?.id && mode === this.selectedStyle?.mode;
  }

  protected getStyleById(
    id: number | undefined,
    mode: string | undefined,
  ): ImageGenerationBasicStyle | ImageGenerationAdvancedStyle | undefined {
    if (!id || id < 0) {
      return;
    }

    let style: ImageGenerationBasicStyle | ImageGenerationAdvancedStyle | undefined = undefined;
    if (mode === "advanced") {
      style = this.stylesAdvanced.find((s) => s.id === id);
    }
    if (mode === "basic") {
      style = this.stylesBasic.find((s) => s.id === id);
    }

    return style;
  }

  protected onSelectStyleClick(
    id: number | undefined,
    mode: ImageGenerationMode | undefined,
    comment: string | undefined,
    mood: string | undefined,
    age: string | undefined,
    colorBackground: string | undefined,
    colorBackgroundHex: string | undefined,
    colorAccent: string | undefined,
    colorAccentHex: string | undefined,
  ) {
    this.selectedStyle = {
      style: this.getStyleById(id, mode),
      comment,
      mood,
      age,
      colorBackground,
      colorBackgroundHex,
      colorAccent,
      colorAccentHex,
      mode,
    };
  }

  protected onSelectPrompt(value: string | number) {
    this.selectedPromptIndex = value as number;
    const prompts = this.coverConceptualGeneration?.prompts;
    if (prompts) {
      this.selectedPrompt = prompts[this.selectedPromptIndex];
    }
  }

  protected isGenerateImageDisable() {
    return !this.selectedPrompt || !this.selectedStyle;
  }

  protected onGenerateImageClick() {
    if (this.isGenerateImageDisable()) {
      return;
    }

    let prompt = `${this.selectedPrompt}`;

    if (this.selectedStyle?.colorBackground) {
      prompt += `, цвет фона: ${this.selectedStyle?.colorBackground}`;
    }
    if (this.selectedStyle?.colorAccent) {
      prompt += `, цвет акцентных деталей: ${this.selectedStyle?.colorAccent}`;
    }

    if (this.selectedStyle?.mode === "basic") {
      // if (this.selectedStyle?.age) {
      //   prompt += `, эпоха: ${this.selectedStyle.age}`;
      // }
      if (this.selectedStyle?.mood) {
        prompt += `, настроение: ${this.selectedStyle?.mood}`;
      }
    }

    this.generateImage.emit({
      prompt: prompt,
      styleId: this.selectedStyle!.style!.id,
      mode: this.selectedStyle!.mode!,
      age: this.selectedStyle!.age!,
      mood: this.selectedStyle!.mood!,
    });
  }

  protected onApplyFontAndColorClick() {
    const data: FontsWithColorData = {};

    if (this.fontMainFamilyName) {
      data.main = {
        font: this.fontMainFamilyName,
        color: this.fontMainColor,
      };
    }
    if (this.fontSubFamilyName) {
      data.sub = {
        font: this.fontSubFamilyName,
        color: this.fontSubColor,
      };
    }
    if (this.fontSecFamilyName) {
      data.sec = {
        font: this.fontSecFamilyName,
        color: this.fontSecColor,
      };
    }

    this.applyFontsAndColors.emit(data);
  }

  protected onBackClick() {
    this.back.emit();
  }

  protected onCloseConceptualAssistantClick() {
    this.closeConceptualAssistant.emit();
  }
}
