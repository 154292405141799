import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { FileDropComponent, LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { User } from "@metranpage/user-data";
import { NgxFileDropEntry } from "ngx-file-drop";
import { UserTemplateService } from "../../services/user-template.service";

const rePhoneNumber = /^[0-9\-\+\(\)]{5,25}$/;

function userTemlateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value.email && !value.phone) {
      return {
        expectedType: true,
      };
    }
    return null;
  };
}

@Component({
  selector: "m-template-upload-modal",
  templateUrl: "./template-upload-modal.view.html",
  styleUrls: ["./template-upload-modal.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateUploadModalView {
  @Input()
  user?: User;
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;

  @Output()
  onClose = new EventEmitter<void>();

  @ViewChild("filedrop", { static: false })
  protected fileDropComponent!: FileDropComponent;

  protected form?: FormGroup;
  protected file?: File;

  constructor(
    private readonly userTemplateService: UserTemplateService,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.createForm();
  }

  protected createForm() {
    this.form = new FormGroup(
      {
        email: new FormControl("", { nonNullable: true, validators: [] }),
        phone: new FormControl("", { nonNullable: true, validators: [Validators.pattern(rePhoneNumber)] }),
      },
      [userTemlateValidator()],
    );

    this.form.patchValue({ email: this.user?.email || "" });
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) => {
          this.file = file;
          this.cdr.detectChanges();
        });
      }
    }
  }

  openFileSelector() {
    this.fileDropComponent.openFileSelector();
  }

  isUploadActive() {
    return this.form?.valid && this.file;
  }

  protected async onUploadTemplate() {
    if (!this.isUploadActive()) {
      return;
    }

    this.notificationService.closeAll();

    this.loadingService.startLoading({
      fullPage: true,
      description: $localize`:@@books.modal.upload-template.upload.process-hint:`,
    });

    const data = this.form?.value;
    const result = await this.userTemplateService.uploadUserTemplateFile(data, this.file!);

    this.loadingService.stopLoading();
    if (result === "success") {
      this.notificationService.notify({
        content: $localize`:@@books.modal.upload-template.request.success:`,
        type: "success",
      });
      this.onCloseClick();
    } else if (result === "file-limit-error") {
      this.notificationService.error($localize`:@@books.modal.upload-template.request.error.file-limit:`);
    } else if (result === "error") {
      this.notificationService.error($localize`:@@books.modal.upload-template.request.error:`);
    }
  }

  protected async onCloseClick() {
    this.onClose.emit();
  }

  protected onBackDropClick() {
    if (!this.closeOnBackDropClick) {
      return;
    }
    this.onCloseClick();
  }

  @HostListener("window:keydown", ["$event"])
  protected handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.onCloseClick();
    }
  }
}
