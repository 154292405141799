import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { EditorDataItem, Heading } from "@metranpage/markup-editor";
import { slideInOutSidebarLTR } from "@metranpage/components";
import { Subject, Subscription } from "rxjs";

@Component({
  selector: "m-books-toc-bar",
  templateUrl: "./toc-markup-bar.view.html",
  styleUrls: ["./toc-markup-bar.view.scss"],
  animations: [slideInOutSidebarLTR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TocMarkupBar implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  isTocSettingsEnable = false;
  @Input()
  headings!: Heading[];
  @Input()
  activeHeadingIndex = 0;
  @Input()
  form?: FormGroup;
  @Output()
  onHeadingClick = new EventEmitter<Heading>();

  isExpanded = false;

  closeTimer$ = new Subject<void>();
  sub = new Subscription();

  protected editorItems: EditorDataItem[] = [];

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    // this.sub = this.closeTimer$.pipe(debounceTime(2000)).subscribe(() => {
    //   this.isExpanded = false;
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onBlockSelectionChanged(selectedBlocks: EditorDataItem[]) {}

  protected toggle() {
    this.isExpanded = !this.isExpanded;
  }

  protected onMouseLeave() {
    this.closeTimer$.next();
  }

  protected isHeadingActive(index: number) {
    return this.activeHeadingIndex === index;
  }

  protected headingClick(heading: Heading, index: number) {
    this.activeHeadingIndex = index;
    this.onHeadingClick.emit(heading);
  }

  trackByHeadingId(index: number, heading: Heading) {
    return index;
  }
}
