import { ChangeDetectorRef, Component, ComponentRef, Input, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { MarginKey, PagePosition, RunningTitleContext, RunningTitlesStyle } from "@metranpage/book-data";
import { PaletteDTO } from "@metranpage/components";
import { Subscription } from "rxjs";
import { RunningTitlesService } from "../../services/running-titles.service";
import { RunningTitlesStore } from "../../services/running-titles.store";
import { RunningTitlesStyle1Component } from "./running-titles-styles/running-titles-style-1/running-titles-style-1.component";
import { RunningTitlesStyle2Component } from "./running-titles-styles/running-titles-style-2/running-titles-style-2.component";
import { RunningTitlesStyle3Component } from "./running-titles-styles/running-titles-style-3/running-titles-style-3.component";
import { RunningTitlesStyle4Component } from "./running-titles-styles/running-titles-style-4/running-titles-style-4.component";
import { RunningTitlesStyle5Component } from "./running-titles-styles/running-titles-style-5/running-titles-style-5.component";

export type RunningTitleStyles =
  | RunningTitlesStyle1Component
  | RunningTitlesStyle2Component
  | RunningTitlesStyle3Component
  | RunningTitlesStyle4Component
  | RunningTitlesStyle5Component;

const runningTitlesStylesMappings = [
  { id: 1, style: RunningTitlesStyle1Component },
  { id: 2, style: RunningTitlesStyle2Component },
  { id: 3, style: RunningTitlesStyle3Component },
  { id: 4, style: RunningTitlesStyle4Component },
  { id: 5, style: RunningTitlesStyle5Component },
];

@Component({
  selector: "m-running-titles-styles",
  templateUrl: "./running-titles-styles.component.html",
})
export class RunningTitlesStylesComponent implements OnInit {
  @Input()
  style?: RunningTitlesStyle;
  @Input()
  context: RunningTitleContext = {
    runningTitle: "",
    pageNumber: "",
  };
  @Input("page-position")
  pagePosition: PagePosition = "left";
  @Input("font-size")
  fontSize = 12;
  @Input()
  palette?: PaletteDTO;
  @Input()
  position: MarginKey = "marginTop";
  @Input()
  isDisabled = false;

  @ViewChild("runningTitleContainer", { read: ViewContainerRef })
  runningTitleContainerRef!: ViewContainerRef;
  private runningTitleComponentRef: ComponentRef<RunningTitleStyles> | null = null;

  private sub = new Subscription();

  constructor(
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly runningTitlesService: RunningTitlesService,
    private readonly runningTitlesStore: RunningTitlesStore,
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.runningTitlesStore
        .getRunningTitlesStateObservable()
        .pipe()
        .subscribe((state) => {
          this.updateStyleComponentData();
          this.changeDetectionRef.detectChanges();
        }),
    );
  }

  ngAfterViewInit() {
    this.updateStyleComponentData();
    this.changeDetectionRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.runningTitleComponentRef) {
      this.runningTitleComponentRef.destroy();
      this.runningTitleComponentRef = null;
    }
    this.sub.unsubscribe();
  }

  private getRunningTitleComponentType(typeId: number) {
    const type = runningTitlesStylesMappings.find((item) => item.id === typeId)?.style;
    return type || undefined;
  }

  private createRunningTitleStyleComponent() {
    const styleId = this.style?.id ?? 1;
    const componentType = this.getRunningTitleComponentType(styleId) as any;
    if (!componentType) {
      return;
    }
    this.runningTitleContainerRef.clear();
    this.runningTitleComponentRef = this.runningTitleContainerRef.createComponent(componentType);
    this.setStyleComponentData(this.runningTitleComponentRef);
  }

  private setStyleComponentData(componentRef: ComponentRef<RunningTitleStyles> | null) {
    if (!componentRef) {
      return;
    }
    componentRef.instance.style = this.style;
    componentRef.instance.context = this.context;
    componentRef.instance.pagePosition = this.pagePosition;
    componentRef.instance.fontSize = this.fontSize;
    componentRef.instance.palette = this.palette;
    componentRef.instance.position = this.position;
  }

  private updateStyleComponentData() {
    if (this.runningTitleContainerRef) {
      this.createRunningTitleStyleComponent();
    }
  }
}
