<div class="container">
  <input
    #input
    class="input"
    type="range"
    [value]="index"
    [min]="0"
    [max]="points.length -1"
    [step]="1"
    (change)="onInputChange($event)"
  />
  <div class="points">
    <div *ngFor="let p of points; index as i" class="point" (click)="onSelect(i)">
      <span *ngIf="p.text" class="description" [ngClass]="{active: i === index}">{{ p.text }}</span>
    </div>
  </div>
</div>
