import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { Tariff } from "../../models/tariff";

export type State = {
  tariffs: Tariff[];
  tariffsPageCount: number;
  activeTariff: Tariff | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminTariffsStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      tariffs: [],
      tariffsPageCount: 1,
      activeTariff: undefined,
    };
  }

  getTariffsObservable() {
    return this.getFieldObservable("tariffs");
  }

  setTariffs(tariffs: Tariff[]) {
    this.update((state) => ({ ...state, tariffs }));
  }

  getTariffsPageCountObservable() {
    return this.getFieldObservable("tariffsPageCount");
  }

  setTariffsPageCount(count: number) {
    this.update((state) => ({ ...state, tariffsPageCount: count }));
  }

  setActiveTariff(tariff: Tariff) {
    this.update((state) => ({ ...state, activeTariff: tariff }));
  }

  getActiveTariffObservable() {
    return this.getFieldObservable("activeTariff");
  }
}
