import { Injectable } from "@angular/core";
import { CompanyFont } from "@metranpage/book-data";
import * as _ from "lodash-es";
import { MarkupFormService } from "../pages/markup/markup-form.service";
import { FontsApi } from "./fonts.api";

@Injectable({
  providedIn: "root",
})
export class FontsService {
  constructor(
    private readonly fontsApi: FontsApi,
    private readonly markupFormService: MarkupFormService,
  ) {}

  async getCompanyFonts(): Promise<CompanyFont[]> {
    return await this.fontsApi.getCompanyFonts();
  }

  async loadCompanyFontCss(font: CompanyFont) {
    const fontUrl = this.fontsApi.getCompanyFontUrl(font);

    const styles = `
    @font-face {
      font-family: '${font.familyName}';
      src: url(${fontUrl});
      font-style: 'normal';
      font-weight: 'normal';
    }`;

    const node = document.createElement("style");
    node.innerHTML = styles;
    document.body.appendChild(node);
  }

  toFontFace(font: CompanyFont): FontFace {
    const fontFamily = font.familyName;
    const url = this.fontsApi.getCompanyFontUrl(font);
    let weight = "normal";
    let style = "normal";

    if (font.subfamilyName.includes("bold")) {
      weight = "bold";
    }

    if (font.subfamilyName.includes("italic")) {
      style = "italic";
    }

    return new FontFace(fontFamily, `url('${url}')`, {
      weight,
      style,
    });
  }

  private _getSafeName(filename: string) {
    return filename.replaceAll(/[\W\s]/gm, "_").toLowerCase();
  }

  getCompanyFontByFamilyName(familyName: string | undefined, fonts: CompanyFont[]): CompanyFont | undefined {
    if (!familyName) {
      return;
    }
    let fontsInFamily = fonts.filter((f) => f.familyName === familyName);
    if (fontsInFamily.length === 0) {
      fontsInFamily = fonts.filter((f) => f.familyName.includes(familyName));
    }
    let font = fontsInFamily.find((f) => f.subfamilyName === "regular");
    if (!font && fontsInFamily.length > 0) {
      font = fontsInFamily[0];
    }
    return font;
  }

  isFontExist(familyName: string | undefined, fonts: CompanyFont[]): boolean {
    if (!familyName) {
      return false;
    }
    const fontsInFamily = fonts.filter((f) => f.familyName === familyName);
    let font = fontsInFamily.find((f) => f.subfamilyName === "regular");
    if (!font && fontsInFamily.length > 0) {
      font = fontsInFamily[0];
    }

    if (!font) {
      const mainFontsNames = this.markupFormService.getBaseMainFontsNames();
      const headersFontsNames = this.markupFormService.getBaseHeadersFontsNames();
      let fontsNames = mainFontsNames.concat(headersFontsNames);
      fontsNames = _.uniqWith(fontsNames, _.isEqual);
      return fontsNames.includes(familyName);
    }

    return !!font;
  }
}
