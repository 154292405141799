import { APP_INITIALIZER, Provider } from '@angular/core';
import { MaintenanceService } from './services/maintenance.service';

export function provideMaintenance(): Provider {
  return {
    provide: APP_INITIALIZER,
    useFactory: (maintenanceService: MaintenanceService) => () => maintenanceService.startStatusUpdating(),
    deps: [MaintenanceService],
    multi: true,
  };
}
