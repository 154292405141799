import { CommonModule, NgClass } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

export type SidebarType = 'normal' | 'fixed';
export type SidebarColor = 'normal' | 'custom';
export type SidebarPosition = 'left' | 'right';

@Component({
  selector: 'm-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  standalone: true,
  imports: [CommonModule, NgClass, IconComponent],
})
export class SidePanelComponent implements AfterViewInit {
  @Input('sidebar-type')
  sidebarType: SidebarType = 'normal';
  @Input('sidebar-position')
  sidebarPosition: SidebarPosition = 'left';
  @Input('sidebar-color')
  sidebarColor: SidebarColor = 'normal';
  @Input('sidebar-name')
  sidebarName = '';

  @HostBinding('style.position')
  hostPosition = 'relative';

  isExpanded = false;

  constructor(
    private elementRef: ElementRef,
    private readonly changeDetectionRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.sidebarType === 'fixed') {
      this.isExpanded = true;
      this.hostPosition = 'relative';
    }

    if (this.sidebarType === 'normal') {
      this.isExpanded = false;
      this.hostPosition = 'absolute';
    }

    this.changeDetectionRef.detectChanges();
  }

  protected getPositionClass(): string[] {
    const result: string[] = [];

    result.push(`position-${this.sidebarPosition}`);

    return result;
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    result.push(this.sidebarType);
    result.push(`position-${this.sidebarPosition}`);
    result.push(`color-${this.sidebarColor}`);

    return result;
  }

  protected expand() {
    this.isExpanded = true;
  }

  protected onMouseLeave() {
    if (this.sidebarType === 'normal') {
      this.isExpanded = false;
    }
  }
}
