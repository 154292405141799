import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { debounceTime, takeUntil } from "rxjs";
import { Company } from "../../models/company";
import { ReferalLink, ReferalLinkCreateData, ReferalLinkWithAnalitics } from "../../models/referal-link";
import { AdminCompanyStore } from "../../services/company/company.store";
import { AdminReferalLinksService } from "../../services/referal-links/referal-links.service";
import { AdminReferalLinksStore } from "../../services/referal-links/referal-links.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-referal-links-page",
  templateUrl: "./referal-links.page.html",
  styleUrls: ["./referal-links.page.scss"],
})
export class AdminReferalLinksPage extends AdminBasePage implements OnInit, OnDestroy {
  referalLinks: ReferalLinkWithAnalitics[] = [];
  page = 1;
  pageCount = 1;

  company: Company | undefined;

  protected searchForm = new FormGroup({
    token: new FormControl("", { nonNullable: true, validators: [] }),
  });
  protected searchToken: string | undefined = undefined;

  protected updateReferalLinkDataForm?: FormGroup;

  protected isAddReferalLinkModalVisible = false;
  protected isDeleteReferalLinkModalVisible = false;
  protected referalLinkForDelete?: ReferalLink = undefined;

  constructor(
    private readonly adminReferalLinksService: AdminReferalLinksService,
    private readonly adminReferalLinksStore: AdminReferalLinksStore,
    private readonly adminCompanyStore: AdminCompanyStore,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();

    this.addSub(
      adminReferalLinksStore
        .getReferalLinksObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((referalLinks) => {
          this.referalLinks = referalLinks;
          this.cdr.markForCheck();
        }),
    );
    this.addSub(
      adminReferalLinksStore
        .getReferalLinksPageCountObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((count) => {
          this.pageCount = count;
          this.cdr.markForCheck();
        }),
    );

    this.addSub(
      adminCompanyStore
        .getCompanyObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((company) => {
          this.company = company;
          this.cdr.markForCheck();
        }),
    );
  }

  ngOnInit(): void {
    this.adminReferalLinksService.loadReferalLinksPaginated(this.page);

    this.searchForm.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(600)).subscribe((value) => {
      this.searchToken = undefined;
      if (value.token) {
        this.searchToken = value.token;
      }
      this.adminReferalLinksService.loadReferalLinksPaginated(this.page, {
        token: this.searchToken,
      });
    });
  }

  trackByReferalLinkId(index: number, referalLink: ReferalLink) {
    return referalLink.id;
  }

  onPageChanged(page: number) {
    this.page = page;
    this.adminReferalLinksService.loadReferalLinksPaginated(this.page);
  }

  protected showReferalLinkDetails(referalLink: ReferalLink) {
    this.router.navigate(["admin", "referal-links", referalLink.id]);
  }

  protected showReferalLinkStats(referalLink: ReferalLink) {
    this.router.navigate(["admin", "referal-links", referalLink.id, "stats"]);
  }

  protected async onAddReferalLink() {
    this.isAddReferalLinkModalVisible = true;
  }

  protected async onReferalLinkAdded(data: ReferalLinkCreateData) {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminReferalLinksService.createReferalLink(data);
    this.loadingService.stopLoading();
    if (result.status === "success") {
      this.onCloseAddReferalLinkModal();
      this.adminReferalLinksService.loadReferalLinksPaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.referal-links.referal-link.error.cant-create-referal-link:`);
    }
  }

  protected onCloseAddReferalLinkModal() {
    this.isAddReferalLinkModalVisible = false;
  }

  protected onCloseDeleteReferalLinkModal() {
    this.isDeleteReferalLinkModalVisible = false;
    this.referalLinkForDelete = undefined;
  }

  protected showReferalLinkDeleteModal(referalLink: ReferalLink) {
    this.isDeleteReferalLinkModalVisible = true;
    this.referalLinkForDelete = referalLink;
  }

  protected async onDeleteReferalLink() {
    if (!this.referalLinkForDelete) {
      return;
    }

    const result = await this.adminReferalLinksService.deleteReferalLink(this.referalLinkForDelete.id);
    if (result.status === "success") {
      this.isDeleteReferalLinkModalVisible = false;
      this.referalLinkForDelete = undefined;
      this.adminReferalLinksService.loadReferalLinksPaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.referal-links.referal-link.error.cant-delete-referal-link:`);
    }
  }

  protected onCopyReferalLinkUrl(referalLink: ReferalLink) {
    const landingUrl = this.company?.landingUrl ?? "";
    const token = referalLink.token;
    const url = this.adminReferalLinksService.getReferalLink(landingUrl, token);
    this.adminReferalLinksService.copyText(url);
  }
}
