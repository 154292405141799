<form [formGroup]="form">
  <p><b i18n="@@admin.templates.add-template">admin.templates.add-template</b></p>
  <div>
    <span
      class="label"
      i18n="@@admin.templates.template.title"
    >admin.templates.template.title</span>
    <m-ng-input formControlName="title" />
  </div>

  <div class="row">
    <div>
      <span
        class="label"
        i18n="@@admin.templates.template.width-from"
      >admin.templates.template.width-from</span>
      <m-ng-input-number formControlName="widthFrom" />
    </div>
    <div>
      <span
        class="label"
        i18n="@@admin.templates.template.width-to"
      >admin.templates.template.width-to</span>
      <m-ng-input-number formControlName="widthTo" />
    </div>
  </div>

  <div class="row">
    <div>
      <span
        class="label"
        i18n="@@admin.templates.template.height-from"
      >admin.templates.template.height-from</span>
      <m-ng-input-number formControlName="heightFrom" />
    </div>
    <div>
      <span
        class="label"
        i18n="@@admin.templates.template.height-to"
      >admin.templates.template.height-to</span>
      <m-ng-input-number formControlName="heightTo" />
    </div>
  </div>

  <m-button
    button-style="primary"
    (onClick)="createTemplate()"
    i18n="@@admin.common.create"
  >
    admin.common.create
  </m-button>
</form>
