import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { BookCover } from "@metranpage/book-data";
import { slideInOutVertical } from "@metranpage/components";

@Component({
  selector: "m-admin-moderation-covers-queue",
  templateUrl: "./moderation-covers-queue.view.html",
  styleUrls: ["./moderation-covers-queue.view.scss"],
  animations: [slideInOutVertical],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminModerationCoversQueueView {
  @Input()
  moderatingCovers: BookCover[] = [];
  @Input()
  isModeratingImagesLoading = false;

  @Output()
  onSelect = new EventEmitter<BookCover>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(_changes: SimpleChanges) {
    this.cdr.detectChanges();
  }

  protected onPreviewImageShowClick(generatedImage: BookCover) {
    this.onSelect.emit(generatedImage);
  }
}
