<div class="card" [class.unread]="!notification.isShown">
  <div class="wrapper">
    <div class="icon">
      <ng-content select="[icon]"></ng-content>
    </div>

    <div class="content">
      <div class="title">
        <ng-content select="[title]"></ng-content>
      </div>
      <div class="description">
        <ng-content select="[description]"></ng-content>
      </div>
    </div>
  </div>

  <div class="tail-group">
    <div *ngIf="!notification.isShown" class="marker"></div>
    <div class="date">{{ date }}</div>
  </div>
</div>

<div *ngIf="!isLast" class="divider-hor"></div>
