import { Component, HostBinding, Input, Renderer2, SimpleChanges } from "@angular/core";
import { GeneratedImage, GeneratedImageService } from "@metranpage/image-generation";

@Component({
  selector: "m-community-image-view",
  templateUrl: "image.view.html",
  styleUrls: ["image.view.scss"],
})
export class CommunityImageView {
  @Input()
  image!: GeneratedImage;

  protected url = "";

  constructor(
    private readonly generatedImageService: GeneratedImageService,
    private readonly renderer: Renderer2,
  ) {}

  @HostBinding("style.aspect-ratio")
  get aspectRatio() {
    return this.image?.width / this.image?.height;
  }
  @HostBinding("style.height")
  get height() {
    return `${100 / this.aspectRatio}%`;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.image) {
      this.url = this.getUrlForImage(this.image);
    }
  }

  protected getUrlForImage(generatedImage: GeneratedImage) {
    return this.generatedImageService.getUrlForImage(generatedImage.imageGenerationId, generatedImage.imageUrl);
  }
}
