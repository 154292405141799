import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { CompanyPrintSettings } from "../../models/company";

@Injectable({
  providedIn: "root",
})
export class AdminCompanyPrintSettingsApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getPrintSettings() {
    return this.get<CompanyPrintSettings>("admin/print-settings");
  }

  async updatePrintSettings(data: CompanyPrintSettings) {
    return this.post<CompanyPrintSettings>("admin/print-settings", data);
  }

  async deletePrintSettings() {
    return this.delete<void>("admin/print-settings");
  }
}
