<form [formGroup]="form" class="wrapper">
  <div class="gradient-type-wrapper">
    <m-select formControlName="type" size="s" [options]="gradientTypeOptions" [hideArrowDivider]="true"></m-select>
  </div>
  <div class="colors-wrapper">
    <div *ngFor="let colorStop of colorStops; let i = index">
      <m-cover-color-select
        [color]="colorStop.color"
        [showDelete]="true"
        [popupPosition]="popupPosition"
        [useColorPickerExternalHost]="useColorPickerExternalHost"
        (delete)="deleteColorStop(i)"
        (update)="updateColorStop($event, i)"
      ></m-cover-color-select>
    </div>
    <div class="color-add" (click)="addColorStop()">
      <m-ng-icon [size]="18" src="/assets/icons/add-02.svg"></m-ng-icon>
    </div>
  </div>
</form>
