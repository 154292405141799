import { CommonModule, NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { AngularSvgIconModule } from "angular-svg-icon";
import { IconComponent } from "../icon/icon.component";

export type SelectValue = {
  value: string;
  id: number | string;
  icon?: string;
  hasNewMark?: boolean;
};

export type SelectStyle = "normal" | "on-panel";
export type SelectSize = "s" | "s-plus" | "m" | "m-plus";

@Component({
  selector: "m-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  standalone: true,
  imports: [CommonModule, NgClass, ReactiveFormsModule, AngularSvgIconModule, IconComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements OnInit, ControlValueAccessor {
  @Input()
  options: SelectValue[] = [];
  @Input()
  defaultValueId = 0;
  @Input()
  noSelectedText = $localize`:@@components.select.no-selected:`;
  @Input()
  errorSelectedText = $localize`:@@components.select.error-selected:`;
  @Input("input-style")
  inputStyle: SelectStyle = "normal";
  @Input("size")
  inputSize: SelectSize = "m";
  @Input()
  isRequired = false;
  @Input()
  name = "";
  @Input()
  tabIndex = 0;
  @Input()
  iconLeft?: string = undefined;

  @Input() hideArrowDivider = false;

  @Output() dropdownMouseLeave = new EventEmitter();
  @Output() optionMouseOver = new EventEmitter<string | number>();

  public touched = false;

  protected isMenuOpened = false;
  protected value?: string;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  private isDisabled = false;

  get showErrorText(): boolean {
    return this.touched && this.value === this.noSelectedText && this.isRequired;
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setValueById(this.defaultValueId);
  }

  public getElementRef(): ElementRef {
    return this.elementRef;
  }

  public getChangeDetectorRef(): ChangeDetectorRef {
    return this.cdr;
  }

  writeValue(value: number | string): void {
    this.setValueById(value);
    this.cdr.markForCheck();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  protected getCssClassList(): string[] {
    const result: string[] = [];

    result.push(this.inputStyle);
    result.push(`size-${this.inputSize}`);

    if (this.isMenuOpened) {
      result.push("focused");
    }

    if (this.iconLeft) {
      result.push("has-left-icon");
    }

    if (this.value === this.noSelectedText) {
      result.push("no-data");
    }

    if (this.isDisabled) {
      result.push("disabled");
    }

    return result;
  }

  protected onFieldClick() {
    if (this.isDisabled) {
      return;
    }

    this.isMenuOpened = !this.isMenuOpened;
    if (this.isMenuOpened) {
      this.touched = true;
      this.onTouched();
    }
    this.cdr.markForCheck();
  }

  protected onOptionClick(valueId: number | string) {
    this.setValueById(valueId);

    this.isMenuOpened = false;

    this.onChange(valueId);
  }

  protected onOptionMouseOver(valueId: number | string) {
    this.optionMouseOver.emit(valueId);
  }

  protected onDropdownMouseLeave() {
    this.dropdownMouseLeave.emit();
  }

  protected getLeftIconAsset(): string | undefined {
    if (!this.iconLeft) {
      return undefined;
    }
    return `/assets/icons/${this.iconLeft}`;
  }

  protected getArrowIconClassList(): string[] {
    if (this.isMenuOpened) {
      return ["reversed"];
    }
    return [];
  }

  protected isArrowDividerShow() {
    return this.inputSize !== "s" && this.inputSize !== "s-plus" && !this.hideArrowDivider;
  }

  getIconArrowSize() {
    if (this.inputSize === "s" || this.inputSize === "s-plus") {
      return "xs";
    }
    return "s";
  }

  private setValueById(valueId: number | string) {
    this.value = this.options.find((v) => v.id === valueId)?.value;
    if (!this.value) {
      this.value = this.noSelectedText;
    }
  }

  protected trackByFn(index: number, item: SelectValue) {
    return item.id;
  }

  @HostListener("document:click", ["$event"])
  onClickOutside(event: Event) {
    if (this.elementRef.nativeElement.contains(event.target)) {
      // click in component
    } else {
      if (this.isMenuOpened) {
        this.isMenuOpened = false;
        this.cdr.markForCheck();
      }
    }
  }
}
