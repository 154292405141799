import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";

interface State {
  isMaintenanceInProgress: boolean;
}

@Injectable({
  providedIn: "root",
})
export class MaintenanceStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      isMaintenanceInProgress: false,
    };
  }

  updateMaintenanceStatus(status: boolean) {
    this.update((state) => ({
      ...state,
      isMaintenanceInProgress: status,
    }));
  }

  getStatus(): boolean {
    return this.getField("isMaintenanceInProgress");
  }
}
