import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AnalyticsService, FileDropComponent, LoadingService, NotificationsPopUpService } from "@metranpage/core";
import { User } from "@metranpage/user-data";
import { NgxFileDropEntry } from "ngx-file-drop";
import { ImageGenerationUserStyle } from "../../models/image-generation-user-style/image-generation-user-style";
import { ImageGenerationUserStyleService } from "../../services/image-generation-user-style/image-generation-user-style.service";
import { SliderPoint } from "../slider-selector/slider-selector.view";

const reFileExtensions = /(\.)(jpg|jpeg|jfif|pjpeg|pjp|png)$/gm;

@Component({
  selector: "m-style-reference-upload-modal",
  templateUrl: "./style-reference-upload-modal.view.html",
  styleUrls: ["./style-reference-upload-modal.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StyleReferenceUploadModalView implements OnInit {
  @Input()
  user?: User;
  @Input()
  styleReference?: ImageGenerationUserStyle;
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;

  @Output()
  onClose = new EventEmitter<void>();

  @ViewChild("filedrop", { static: false })
  protected fileDropComponent!: FileDropComponent;

  protected file?: File;
  protected form?: FormGroup;

  protected imageUrl?: string;
  protected isPreviewHovered = false;

  protected strengthTypePoints: SliderPoint[] = [
    {
      value: "Low",
      text: $localize`:@@style-reference-upload-modal.style-repeatability.min:`,
    },
    {
      value: "Mid",
      text: $localize`:@@style-reference-upload-modal.style-repeatability.mid:`,
    },
    {
      value: "High",
      text: $localize`:@@style-reference-upload-modal.style-repeatability.max:`,
    },
  ];

  constructor(
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly imageGenerationUserStyleService: ImageGenerationUserStyleService,
    private readonly analytics: AnalyticsService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.createForm();
    this.setDefaultFormValues();

    if (this.styleReference) {
      this.imageUrl = this.imageGenerationUserStyleService.getUrlForImage(
        this.styleReference.id,
        this.styleReference.imageUrl,
        true,
      );
    }
  }

  protected createForm() {
    this.form = new FormGroup({
      strengthType: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
    });
  }

  protected setDefaultFormValues() {
    let defaultStrengthType = this.strengthTypePoints[1]?.value || "Mid";
    if (this.styleReference) {
      defaultStrengthType = this.styleReference.strengthType;
    }
    this.form?.patchValue({ strengthType: defaultStrengthType });
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file(async (file: File) => {
          if (!file.name.match(reFileExtensions)) {
            this.notificationService.error(
              $localize`:@@image-generation.style-reference.upload.request.error.file-extension:`,
            );
            return;
          }

          this.file = file;
          this.showImagePreview(file);
          this.cdr.detectChanges();
        });
      }
    }
  }

  openFileSelector() {
    this.fileDropComponent.openFileSelector();
  }

  isCreateStyleButtonActive() {
    return !!this.file || this.styleReference;
  }

  protected async onCreateStyleClick() {
    if (!this.isCreateStyleButtonActive()) {
      return;
    }

    this.analytics.event("create-user-style-reference");

    this.notificationService.closeAll();

    this.loadingService.startLoading({
      fullPage: true,
      description: $localize`:@@image-generation.style-reference.upload.process-hint:`,
    });

    const data = this.form?.value;
    const result = await this.imageGenerationUserStyleService.createStyle(data, this.file);

    this.loadingService.stopLoading();

    if (result.error) {
      if (result.error === "file-limit-error") {
        this.notificationService.error($localize`:@@image-generation.style-reference.upload.request.error.file-limit:`);
        return;
      }
      this.notificationService.error($localize`:@@image-generation.style-reference.upload.request.error:`);
      return;
    }

    this.notificationService.notify({
      content: $localize`:@@image-generation.style-reference.upload.request.success:`,
      type: "success",
    });

    if (result.userStyleReference) {
      this.imageGenerationUserStyleService.onChangeUserStyleReference(result.userStyleReference.id);
    }

    this.onCloseClick();
  }

  showImagePreview(file: File) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      this.isPreviewHovered = false;
      this.cdr.detectChanges();
    };
    reader.readAsDataURL(file);
  }

  protected async onCloseClick() {
    this.onClose.emit();
  }

  protected onBackDropClick() {
    if (!this.closeOnBackDropClick) {
      return;
    }
    this.onCloseClick();
  }

  protected onDragOver(event: Event) {
    this.isPreviewHovered = true;
  }

  protected onMouseEnter(event: Event) {
    this.isPreviewHovered = true;
  }

  protected onMouseLeave(event: Event) {
    this.isPreviewHovered = false;
  }

  // protected updateButtonText() {
  //   this.buttonText = $localize`:@@style-reference-upload-modal.apply-button:`
  //   if (this.styleReference) {
  //     this.buttonText = $localize`:@@style-reference-upload-modal.apply-button:`
  //   }
  // }

  @HostListener("window:keydown", ["$event"])
  protected handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.onCloseClick();
    }
  }
}
