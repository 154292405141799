import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AbstractControl, ReactiveFormsModule, ValidationErrors, ValidatorFn } from "@angular/forms";
import { fromEvent, tap } from "rxjs";
import { IconComponent } from "../icon/icon.component";
import { InputComponent, InputType } from "../input/input.component";

export const IsNumberValidator: ValidatorFn = (control: AbstractControl<string, string>): ValidationErrors | null => {
  const f = Number.parseFloat(control.value);
  if (Number.isNaN(f)) {
    return {
      notNumber: true,
    };
  }
  return null;
};

@Component({
  selector: "m-ng-input-number",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IconComponent, InputComponent],
  templateUrl: "./input-number.component.html",
  styleUrls: ["../input/input.component.scss", "./input-number.component.scss"],
})
export class InputNumberComponent extends InputComponent implements OnInit {
  @Input()
  // override type: InputType = "number"; // with number - html will change underlying logic, so angular validation will not work
  override type: InputType = "text";
  @Input()
  step = 1;
  @Input()
  multiplier = 10;

  previousValue!: string;

  override ngOnInit(): void {
    super.ngOnInit();

    fromEvent<FocusEvent>(this.inputElementRef?.nativeElement, "focusout")
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((event: FocusEvent) => {
          if (event.target) {
            this.checkValidAndUpdate((event.target as HTMLInputElement).value);
          }
        }),
      )
      .subscribe();
  }

  override onFocus(): void {
    this.previousValue = this.ngControl?.value;
    super.onFocus();
  }

  // TODO smh cast to number and dont break generic in inputcomponent
  override onInputChange(event: Event) {
    if ((event.target as HTMLInputElement).value) {
      super.onInputChange(event);
      return;
    }

    // Manually set control as dirty because when changing input
    // value state don't change automatically. Use markAsDirty
    // here because we must avoid assigning value '' here
    this.ngControl?.control?.markAsDirty();

    // Required validator don't work properly
    // isRequired don't fire required error
    this.ngControl?.control?.setErrors({ required: true });
    // super.onInputChange(+value); // cast to number
  }

  protected onKeyDown(event: KeyboardEvent) {
    // if (this.type !== "number" || !event.target) {
    //   return;
    // }
    if (!event.target) {
      return;
    }

    let value = (event.target as HTMLInputElement).value;
    value = value.replaceAll(",", ".");
    const digitNumber = +value;
    if (Number.isNaN(digitNumber)) {
      return;
    }

    if (event.key === "ArrowUp") {
      event.preventDefault();
      this.increase(digitNumber, event.shiftKey);
    }
    if (event.key === "ArrowDown") {
      event.preventDefault();
      this.decrease(digitNumber, event.shiftKey);
    }
  }

  protected increase(value: number, isMultiplierEnable: boolean) {
    let v = value;
    if (value % 1 !== 0) {
      v = Math.ceil(value);
    } else {
      const addendum = isMultiplierEnable ? this.step * this.multiplier : this.step;
      v += addendum;
    }
    this.setValue(v);
  }

  protected decrease(value: number, isMultiplierEnable: boolean) {
    let v = value;
    if (value % 1 !== 0) {
      v = Math.floor(value);
    } else {
      const addendum = isMultiplierEnable ? this.step * this.multiplier : this.step;
      v -= addendum;
    }
    this.setValue(v);
  }

  setValue(value: number) {
    if (this.inputElementRef?.nativeElement) {
      this.inputElementRef.nativeElement.value = value;
    }
    this.onChange(`${value}`);
  }

  protected override castValueBeforeOnChange(value: string) {
    // TODO this hack is not working, there is still actulal value in form. Keeping this until better times
    const numbered = Number.parseFloat(value);
    /* if (this.ngControl) {
      if (this.ngControl.errors?.max) {
        if (numbered > this.ngControl.errors?.max?.max) {
          return this.ngControl.errors?.max?.max;
        }
      }
      if (this.ngControl.errors?.min) {
        if (numbered < this.ngControl.errors?.min?.min) {
          return this.ngControl.errors?.min?.min;
        }
      }
    } */
    return numbered;
  }

  private checkValidAndUpdate(value: string | number): void {
    if ((!value && Number(value) !== 0) || Number.isNaN(value)) {
      this.ngControl?.control?.setValue(this.previousValue);
    }
  }

  ngOnDestroy() {
    this.checkValidAndUpdate(this.ngControl?.control?.value);
  }
}
