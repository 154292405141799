import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'm-promo-card',
  templateUrl: './promo-card.html',
  styleUrls: ['./promo-card.scss'],
})
export class PromoCard {
  @Input() title = '';
  @Input() description = '';
  @Input()
  icon?: string = undefined;
  @Input() backgroundColor!: string;

  @ViewChild('infoImg', { static: true }) infoImg!: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    if (this.backgroundColor === 'present') {
      this.renderer.setStyle(this.infoImg.nativeElement, 'background-color', 'var(--accent)');
    }
  }

  protected getIconAsset(): string | undefined {
    if (!this.icon) {
      return undefined;
    }
    return `/assets/icons/${this.icon}`;
  }
}
