import { Component, Input } from "@angular/core";

@Component({
  selector: "m-account-tariff-and-payments-menu-item-component",
  template: `
    <span>{{ title }}</span>
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background: var(--surface-on-island);
        color: var(--text-text-40);
        cursor: pointer;
        font-size: var(--font-size-14);
        font-weight: var(--font-weight);
        letter-spacing: 0.02em;
        line-height: 16.8px;
        text-align: left;

        &.active {
          background: var(--surface-island);
          color: var(--text-text);
        }
      }
    `,
  ],
})
export class AccountTariffAndPaymentsMenuItemComponent {
  @Input()
  title!: string;
}
