import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import {
  CoverObjectType,
  CoverSnippet,
  CoverSnippetCategory,
  CoverSnippetCategoryObjects,
  CoverSnippetSubcategory,
  CoverSnippetSubcategoryObjects,
  EllipseObject,
  PredefinedSvgObjects,
  RectangleObject,
  SvgObject,
} from "@metranpage/book-data";
import { ScrollPosition } from "@metranpage/design-system";
import * as _ from "lodash-es";
import { CoverSnippetDataService } from "../../services/cover-snippet/cover-snippet-data.service";
import { CoverSnippetService } from "../../services/cover-snippet/cover-snippet.service";
import { CreateCoverObject } from "../cover-object-create/cover-object-create.component";

@Component({
  selector: "m-cover-snippets-sidebar",
  templateUrl: "./cover-snippets-sidebar.view.html",
  styleUrls: ["./cover-snippets-sidebar.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverSnippetsSidebarView {
  @Input() selectedTab = 1;
  @Input() selectedCoverSnippetCategoryObjects: CoverSnippetCategoryObjects | undefined = undefined;
  @Input() selectedCoverSnippetSubcategoryObjects: CoverSnippetSubcategoryObjects | undefined = undefined;
  @Input() coverSnippetSidebarWidth = 280;
  @Input() scrollPosition?: ScrollPosition;
  @Input() predefinedSvgObjects: PredefinedSvgObjects[] = [];

  @Output() selectTab = new EventEmitter<number>();
  @Output() createCoverObject = new EventEmitter<CreateCoverObject>();
  @Output() setSubcategoryObjects = new EventEmitter<CoverSnippetSubcategoryObjects | undefined>();
  @Output() scroll = new EventEmitter<ScrollPosition>();

  CoverObjectType = CoverObjectType;

  protected limitSubcategories = 3;

  constructor(
    private readonly coverSnippetService: CoverSnippetService,
    private readonly coverSnippetDataService: CoverSnippetDataService,
  ) {}

  protected onCreateCoverObject(coverObjectType: CoverObjectType, svgData: any) {
    this.createCoverObject.emit({
      type: coverObjectType,
      svgData,
    });
  }

  protected onCreateSnippetObject(coverObjectType: CoverObjectType, coverSnippet: CoverSnippet) {
    this.createCoverObject.emit({
      type: coverObjectType,
      coverSnippet,
    });
  }

  protected onSetCoverSnippetSubcategoryObjects(
    coverSnippetSubcategoryObjects: CoverSnippetSubcategoryObjects | undefined,
  ) {
    this.setSubcategoryObjects.emit(coverSnippetSubcategoryObjects);
  }

  protected onTabClick(selectedTab: number) {
    if (this.selectedTab === selectedTab) {
      return;
    }
    this.selectTab.emit(selectedTab);
  }

  getCoverSnippetSubcategoryTitle(subcategory: CoverSnippetSubcategory): string | undefined {
    return this.coverSnippetDataService.getCoverSnippetSubcategoryTitle(subcategory);
  }

  getUrlForPreviewImage(coverSnippet: CoverSnippet) {
    return this.coverSnippetService.getUrlForPreviewImage(coverSnippet);
  }

  protected limitItems(data: any[] | undefined): any[] {
    if (!data) {
      return [];
    }
    if (data.length <= this.limitSubcategories) {
      return data;
    }
    return data.slice(0, this.limitSubcategories);
  }

  protected isTextSnippet(category: CoverSnippetCategory | undefined): boolean {
    if (category === "text") {
      return true;
    }
    // if (["headersAndText", "lists", "quotes"].includes(subcategory)) {
    //   return true;
    // }
    return false;
  }

  protected isShapeSnippet(coverSnippet: CoverSnippet): boolean {
    if (this.isTextSnippet(coverSnippet.category)) {
      return false;
    }
    if (coverSnippet.objects.length === 1) {
      const firstObject = coverSnippet.objects[0];
      if (
        firstObject instanceof RectangleObject ||
        firstObject instanceof EllipseObject ||
        firstObject instanceof SvgObject
      ) {
        return true;
      }
    }
    return false;
  }

  protected getUrlForShapeSnippet(coverSnippet: CoverSnippet): string {
    const firstObject = coverSnippet.objects[0];
    if (firstObject instanceof RectangleObject) {
      return "/assets/icons/cover/svg-shapes/rectangle-i-01.svg";
    }
    if (firstObject instanceof EllipseObject) {
      return "/assets/icons/cover/svg-shapes/ellipse-i-01.svg";
    }
    if (firstObject instanceof SvgObject) {
      let imageUrl = firstObject.imageUrl || "";
      for (const categoryObjects of this.predefinedSvgObjects) {
        const o = _.find(categoryObjects.urls, { objectUrl: imageUrl });
        if (o) {
          imageUrl = o.iconUrl;
          break;
        }
      }
      return imageUrl;
    }
    return "";
  }

  protected onScroll(scrollPosition: ScrollPosition) {
    this.scroll.emit(scrollPosition);
  }
}
