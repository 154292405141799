<div class="container" [formGroup]="form">
  <div class="indication-block">
    <div class="title">{{title}}</div>
    <input
      [value]="value"
      (input)="setValueFromEvent($event)"
      class="slider"
      type="range"
      [min]="min"
      [max]="max"
      [step]="1"
      [ngStyle]="{ '--pseudo-width': pseudoWidth }"
    />
  </div>
  <m-ng-input-number text-align="center" class="input-number" formControlName="input" [multiplier]="+multiplier" />
</div>
