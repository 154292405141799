import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { lerp } from "@metranpage/book-data";
import { ThemeService } from "@metranpage/theme";
import { Subject, takeUntil, timer } from "rxjs";

export type Step = {
  x: number;
  height: number;
  value: number;
};

@Component({
  selector: "m-font-size-selector",
  templateUrl: "./font-size-selector.view.html",
  styleUrls: ["./font-size-selector.view.scss"],

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FontSizeSelector),
      multi: true,
    },
  ],
})
export class FontSizeSelector implements AfterViewInit, ControlValueAccessor {
  // @Input()
  width = 248;
  // @Input()
  height = 50;
  @Input("initial-range-value")
  initialRangeValue = 0;
  @Input("steps-count")
  stepsCount = 5;

  protected value = 1;
  protected isDisabled = false;

  private rangeStartX = 30;
  private rangeStopX = 248 - 45;
  private rangeStartY = 40;

  private stepsInitHeight = 15;
  private stepsHeightDiff = 2.5;
  private steps: Step[] = [];

  protected ctx!: CanvasRenderingContext2D;

  private cursor!: Path2D;
  private cursorPosition = 0;
  private cursorWidth = 4;
  private cursorWidthScaled = 8;

  protected cursorStartPosition = 0;
  protected cursorStartPositionOffset = 0;

  private isCursorPressed = false;
  private isCursorScaled = false;
  private isMouseOnComponent = false;

  private destroyed$ = new Subject<void>();

  @ViewChild("canvas", { static: true, read: ElementRef })
  protected canvas!: ElementRef;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  constructor(private readonly themeService: ThemeService) {
    themeService.changeEvents$.pipe(takeUntil(this.destroyed$)).subscribe((theme) => {
      this.redraw();
    });
  }

  ngAfterViewInit(): void {
    timer(100).subscribe(() => {
      this.initDraw();
      this.setCursorToValue(this.value);
      this.redraw();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  writeValue(value: number): void {
    this.value = value;
    this.setCursorToValue(this.value);
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  protected initDraw() {
    if (!this.ctx) {
      this.ctx = this.canvas.nativeElement.getContext("2d", { willReadFrequently: true });
    }

    this.canvas.nativeElement.width = this.width;
    this.canvas.nativeElement.height = this.height;
    this.ctx.clearRect(0, 0, this.width, this.height);

    this.calculateSteps(this.stepsCount);
    this.drawScale();
  }

  protected redraw() {
    if (!this.ctx) {
      return;
    }
    this.ctx.clearRect(0, 0, this.width, this.height);
    this.drawScale();
    this.drawCursor();
  }

  protected drawScale() {
    this.ctx.save();

    this.ctx.strokeStyle = getComputedStyle(this.ctx.canvas).getPropertyValue("--color-bg-panel");
    this.ctx.lineWidth = 1;
    this.ctx.lineCap = "round";

    for (const step of this.steps) {
      const line = new Path2D();
      line.moveTo(step.x, this.rangeStartY - step.height);
      line.lineTo(step.x, this.rangeStartY);
      this.ctx.stroke(line);
    }

    this.ctx.fillStyle = getComputedStyle(this.ctx.canvas).getPropertyValue("--color-font-main");
    this.ctx.font = "18px serif";
    this.ctx.fillText("T", 5, 39);
    this.ctx.font = "40px serif";
    this.ctx.fillText("T", 215, 37);

    this.ctx.restore();
  }

  protected drawCursor() {
    // const normalizedValue = (this.value - this.minValue) / (this.maxValue - this.minValue);
    const normalizedValue = (this.cursorPosition - this.rangeStartX) / (this.rangeStopX - this.rangeStartX);

    let cWidth = this.cursorWidth;
    const cHeight = lerp(35, 50, normalizedValue);
    let cBorder = 3;
    if (this.isCursorScaled) {
      cWidth = this.cursorWidthScaled;
      cBorder = 6;
    }

    this.cursor = new Path2D();
    this.cursor.roundRect(this.cursorPosition - cWidth / 2, this.height - cHeight, cWidth, cHeight - 3, cBorder);

    this.ctx.fillStyle = getComputedStyle(this.ctx.canvas).getPropertyValue("--accent");
    this.ctx.fill(this.cursor);
  }

  protected setCursorPosition(step: Step) {
    this.cursorPosition = step.x;
    this.value = step.value;
    this.redraw();

    this.onChange(this.value);
  }

  protected setCursorToValue(value: number) {
    const step = this.steps.find((step: Step) => step.value === value);
    if (!step) {
      return;
    }
    this.cursorPosition = step.x;
    this.setCursorPosition(step);
  }

  protected calculateSteps(stepsCount: number) {
    const rangeLength = this.rangeStopX - this.rangeStartX;
    const stepSize = rangeLength / stepsCount;
    for (let i = 0; i <= stepsCount; i++) {
      this.steps.push({
        x: this.rangeStartX + i * stepSize,
        height: this.stepsInitHeight + this.stepsHeightDiff * i,
        value: this.initialRangeValue + i,
      });
    }
  }

  private setCursorToNearestValue() {
    this.isCursorPressed = false;
    const step = this.steps.sort(
      (a, b) => Math.abs(a.x - this.cursorPosition) - Math.abs(b.x - this.cursorPosition),
    )[0];
    this.value = step.value;
    this.setCursorToValue(this.value);
    this.redraw();
  }

  private changeValue(value: number) {
    const step = this.steps.find((step: Step) => step.value === this.value + value);
    if (step) {
      this.value = this.value + value;
    }
  }

  onMouseEnter(event: MouseEvent) {
    this.isMouseOnComponent = true;
    // this.isCursorScaled = true;
    this.redraw();
  }

  onMouseLeave(event: MouseEvent) {
    this.isMouseOnComponent = false;
    // this.isCursorScaled = this.isCursorPressed;
    this.redraw();
  }

  onMouseDown(event: MouseEvent) {
    const x = event.offsetX;
    const y = event.offsetY;

    if (this.ctx.isPointInPath(this.cursor, x, y)) {
      this.isCursorPressed = true;

      this.cursorPosition = x;
      this.cursorStartPosition = x;
      this.cursorStartPositionOffset = event.clientX;
    }
  }

  onMouseUp(event: MouseEvent) {
    if (!this.isCursorPressed) {
      const x = event.offsetX;
      if (x > this.cursorPosition) {
        this.changeValue(1);
        this.setCursorToValue(this.value);
      } else if (x < this.cursorPosition) {
        this.changeValue(-1);
        this.setCursorToValue(this.value);
      }
    }
  }

  @HostListener("window:mouseup", ["$event"])
  onMouseUpGlobal(event: MouseEvent) {
    if (this.isCursorPressed) {
      // this.isCursorScaled = this.isMouseOnComponent;
      this.setCursorToNearestValue();
    }
  }

  @HostListener("window:mousemove", ["$event"])
  onMouseMoveGlobal(event: MouseEvent) {
    if (this.isCursorPressed) {
      const deltaX = event.clientX - this.cursorStartPositionOffset;
      let x = this.cursorStartPosition + deltaX;
      if (x < this.rangeStartX) {
        x = this.rangeStartX;
      } else if (x > this.rangeStopX) {
        x = this.rangeStopX;
      }
      this.cursorPosition = x;
      this.redraw();
    }
  }
}
