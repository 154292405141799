import { Injectable } from "@angular/core";
import { CoverSnippet } from "@metranpage/book-data";
import { Store } from "@metranpage/state";

type State = {
  coverSnippets: CoverSnippet[];
};

@Injectable({
  providedIn: "root",
})
export class CoverSnippetStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      coverSnippets: [],
    };
  }

  getCoverSnippetsObservable() {
    return this.getFieldObservable("coverSnippets");
  }

  setCoverSnippets(coverSnippets: CoverSnippet[]) {
    this.update((state) => ({ ...state, coverSnippets }));
  }

  addCoverSnippet(coverSnippet: CoverSnippet) {
    this.update((state) => {
      const storeCoverSnippet = state.coverSnippets.find((cs) => cs.id === coverSnippet.id);
      if (storeCoverSnippet) {
        return state;
      }

      return {
        ...state,
        coverSnippets: [coverSnippet].concat(state.coverSnippets),
      };
    });
  }
}
