<form *ngIf="form" [formGroup]="form" class="container">
  <m-sidebar-section
    [isSettingsAvailable]="isSettingsAvailable"
    title="books.markup.sidebar.images-title"
    i18n-title="@@books.markup.sidebar.images-title"
  >
    <div class="sidebar-content" [class.locked]="!isSettingsAvailable">
      <m-books-markup-sidebar-images-position formControlName="centerImages" />
    </div>
  </m-sidebar-section>
</form>
