import { Component } from "@angular/core";
import { I18nService } from "@metranpage/i18n";
import { LoadingService, LoadingState } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { Theme, ThemeService } from "@metranpage/theme";
import { Observable } from "rxjs";
import { LoginError } from "../../models/login-errors";
import { AuthService } from "../../services/auth.service";
import { RestorePassData } from "../../views/password-restore/password-restore.view";

@Component({
  selector: "m-password-restore-page",
  templateUrl: "./password-restore.page.html",
  styleUrls: ["./password-restore.page.scss"],
})
export class PasswordRestorePage {
  loadingState$: Observable<LoadingState>;

  loginErrors: LoginError[] = [];
  protected userLanguage;
  isEmailSent = false;

  protected colorTheme: Theme = "light";

  constructor(
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
    private readonly notificationsService: NotificationsPopUpService,
    private readonly i18nService: I18nService,
    private readonly themeService: ThemeService,
  ) {
    this.userLanguage = i18nService.getLocale();
    this.loadingState$ = this.loadingService.loadingState$;
  }

  ngOnInit(): void {
    this.updateColorTheme();
  }

  async onRestorePassClick(data: RestorePassData) {
    this.notificationsService.closeAll();

    this.loadingService.startLoading();
    const result = await this.authService.restorePassword(data.email);

    // clean errors after request due to ugly window size change when error hides and reappears half a second later
    this.loginErrors = [];

    if (result === "success") {
      this.isEmailSent = true;
    } else if (result === "cant-restore-pass-for-social-login") {
      this.notificationsService.error($localize`:@@user.auth.restore-cant-restore-for-social-error:`);
    } else if (result === "email-not-found") {
      this.notificationsService.error($localize`:@@user.auth.restore-pass-email-not-found-error:`);
    } else if (result === "network-error") {
      this.notificationsService.error($localize`:@@user.auth.generic-error:`);
    }

    this.loadingService.stopLoading();
  }

  protected updateColorTheme() {
    this.colorTheme = this.themeService.getTheme();
  }

  protected onColorThemeToggle() {
    this.themeService.toggleTheme();
    this.colorTheme = this.themeService.getTheme();
  }

  onLangChange(lang: string) {
    this.i18nService.saveLocale(lang);
    window.location.reload();
  }
}
