<div class="left-wrapper">
  <div class="reward-wrapper">
    <m-ng-icon class="reward-icon" [size]="24" src="reward-tokens.svg" />
    <span class="reward">{{ userRewardDaily.reward.rewardCreditsCount }}</span>
  </div>

  <div class="title">{{ getTitle() }}</div>
</div>

<div class="right-wrapper">
  <div *ngIf="isTaskComplete()" class="task-completed-wrapper">
    <m-ng-icon class="task-completed-icon" [size]="16" src="reward-task-completed.svg" />
    <span i18n="@@user.profile.reward-tasks.completed">user.profile.reward-tasks.completed</span>
  </div>

  <div *ngIf="!isTaskComplete()" class="progress-bar-wrapper">
    <div class="progress-bar">
      <div class="progress-bar-fill" [ngStyle]="{'width.%': getProgress()}"></div>
      <span class="progress-bar-text">{{ getProgressText() }}</span>
    </div>
  </div>
</div>
