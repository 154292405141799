import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL, FILES_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import {
  CoverSnippet,
  CoverSnippetData,
  CoverSnippetUpdateData,
  CoverSnippetsData,
} from "../../models/cover-snippet/cover-snippet";

export type CoverSnippetsFilter = {};

@Injectable({
  providedIn: "root",
})
export class AdminCoverSnippetsApi extends Api {
  constructor(
    http: HttpClient,
    @Inject(BASE_URL) baseUrl: string,
    @Inject(FILES_URL) private filesUrl: string,
  ) {
    super(http, baseUrl);
  }

  getCoverSnippetsPaginated(page: number, filters: CoverSnippetsFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<CoverSnippetsData>("admin/cover-snippets/paginated", {
      page,
      ...clearedFilters,
    });
  }

  getCoverSnippet(coverSnippetId: number) {
    return this.get<CoverSnippetData>(`admin/cover-snippets/${coverSnippetId}`);
  }

  async updateCoverSnippet(id: number, data: CoverSnippetUpdateData) {
    return this.post<unknown>(`admin/cover-snippets/${id}`, data);
  }

  deleteCoverSnippet(coverSnippetId: number) {
    return this.delete<unknown>(`admin/cover-snippets/${coverSnippetId}`);
  }

  saveCoverSnippetsOrder(data: CoverSnippet[]) {
    return this.post<unknown>("admin/cover-snippets/update-order", data);
  }

  getUrlForFile(file: { vid: string }) {
    return `${this.filesUrl}/${file.vid}`;
  }
}
