<m-account-notification-card-host [notification]="notification" [isLast]="isLast">
  <div icon class="icons-wrapper">
    <m-ng-icon class="icon-main" [size]="24" src="notification-info.svg" />
  </div>

  <div title class="title-group">
    <div class="title">
      <span i18n="@@user.profile.notifications.card.registration.title">
        user.profile.notifications.card.registration.title
      </span>
    </div>

    <div class="card-label" i18n="@@user.profile.notifications.filter.important">
      user.profile.notifications.filter.important
    </div>
  </div>

  <div description class="description">
    <div class="row">
      <span i18n="@@user.profile.notifications.card.registration.description">
        user.profile.notifications.card.registration.description
      </span>
    </div>
  </div>
</m-account-notification-card-host>
