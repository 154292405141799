import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { CompanyMaintenanceSettings } from "../../models/company";

@Injectable({
  providedIn: "root",
})
export class AdminCompanyMaintenanceSettingsApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getMaintenanceSettings() {
    return this.get<CompanyMaintenanceSettings>("admin/maintenance-settings");
  }

  async updateMaintenanceSettings(data: CompanyMaintenanceSettings) {
    return this.post<CompanyMaintenanceSettings>("admin/maintenance-settings", data);
  }
}
