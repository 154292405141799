<div class="select-wrapper">
  <div class="input-container" [ngClass]="getCssClassList()" (click)="onFieldClick()">
    <span class="color-format">
      {{ colorFormat.toUpperCase() }}
    </span>

    <m-icon
      class="icon icon-arrow"
      [ngClass]="getArrowIconClassList()"
      src="dropdown-arrow-down.svg"
      size="xs"
      color="--color-font-input"
    />
  </div>

  <div class="select-options-dropdown" *ngIf="isMenuOpened">
    <div class="select-option" *ngFor="let option of options" (click)="onOptionClick(option)">
      <div class="icon-wrapper">
        <m-icon *ngIf="colorFormat === option" src="done.svg" size="s" color="--color-font-input" />
      </div>
      <span>{{ option.toUpperCase() }}</span>
    </div>
  </div>
</div>
