import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { TemplateAccess } from "../../models/template-access";

export type State = {
  templatesAccess: TemplateAccess[];
  templatesAccessPageCount: number;
};

@Injectable({
  providedIn: "root",
})
export class AdminTemplatesAccessStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      templatesAccess: [],
      templatesAccessPageCount: 1,
    };
  }

  getTemplatesAccessObservable() {
    return this.getFieldObservable("templatesAccess");
  }

  setTemplatesAccess(templatesAccess: TemplateAccess[]) {
    this.update((state) => ({ ...state, templatesAccess }));
  }

  getTemplatesAccessPageCountObservable() {
    return this.getFieldObservable("templatesAccessPageCount");
  }

  setTemplatesAccessPageCount(count: number) {
    this.update((state) => ({ ...state, templatesAccessPageCount: count }));
  }
}
