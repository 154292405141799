import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import type { PartialOptions } from "overlayscrollbars";
import { OverlayScrollbarsComponent, OverlayscrollbarsModule } from "overlayscrollbars-ngx";
import { timer } from "rxjs";

export type ScrollPosition = {
  top: number;
  left: number;
};

@Component({
  selector: "m-ng-scrollbars",
  standalone: true,
  imports: [CommonModule, InfiniteScrollModule, OverlayscrollbarsModule],
  templateUrl: "./scrollbars.component.html",
  styleUrls: ["./scrollbars.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollbarsComponent implements OnInit, OnChanges {
  @Input()
  options?: PartialOptions | false | null;
  @Input()
  padding?: string;
  @Input()
  scrollPosition?: ScrollPosition;

  @Output()
  onScrolled = new EventEmitter();
  @Output()
  onScroll = new EventEmitter<ScrollPosition>();

  @ViewChild("overlayScrollbarsElement", { static: false })
  protected overlayScrollbarsElement?: OverlayScrollbarsComponent;

  protected scrollEventElement?: HTMLElement | Document;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    timer(100).subscribe(() => {
      this.scrollEventElement = this.overlayScrollbarsElement?.osInstance()?.elements().scrollEventElement;
      this.cdr.detectChanges();
    });
  }

  // ngAfterViewInit() {
  //   this.scrollTo(this.scrollPosition);
  // }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scrollPosition) {
      this.scrollPosition = changes.scrollPosition.currentValue;
      this.scrollTo(this.scrollPosition);
    }
  }

  protected onScrolledEvent() {
    this.onScrolled.emit();
  }

  osScroll(event: any) {
    const viewport = this.overlayScrollbarsElement?.osInstance()?.elements()?.viewport;
    if (!viewport) {
      return;
    }
    const { scrollLeft, scrollTop } = viewport;

    this.onScroll.emit({
      top: scrollTop,
      left: scrollLeft,
    });
  }

  scrollTo(scrollPosition: ScrollPosition | undefined) {
    timer(100).subscribe(() => {
      const viewport = this.overlayScrollbarsElement?.osInstance()?.elements()?.viewport;
      if (!viewport) {
        return;
      }
      viewport.scrollTo({ top: scrollPosition?.top || 0, left: scrollPosition?.left || 0, behavior: "smooth" });
    });
  }
}
