import { Injectable } from "@angular/core";
import { BookCover } from "@metranpage/book-data";
import { Store } from "@metranpage/state";

export type PublishedCoversState = {
  publishedCovers: BookCover[];
  publishedCoversPageCount: number;
};

@Injectable({
  providedIn: "root",
})
export class PublishedCoverStore extends Store<PublishedCoversState> {
  protected override getInitialState(): PublishedCoversState {
    return {
      publishedCovers: [],
      publishedCoversPageCount: 1,
    };
  }

  getPublishedCoversObservable() {
    return this.getFieldObservable("publishedCovers");
  }

  getPublishedCovers() {
    return this.getField("publishedCovers");
  }

  getPublishedCoversPageCountObservable() {
    return this.getFieldObservable("publishedCoversPageCount");
  }

  setPublishedCovers(publishedCovers: BookCover[]) {
    this.update((state) => ({ ...state, publishedCovers }));
  }

  setPublishedCoversPageCount(count: number) {
    this.update((state) => ({ ...state, publishedCoversPageCount: count }));
  }

  addPublishedCover(publishedCover: BookCover) {
    this.update((state) => {
      const storePublishedCover = state.publishedCovers.find((ig) => ig.id === publishedCover.id);
      if (storePublishedCover) {
        return state;
      }

      return {
        ...state,
        publishedCovers: [publishedCover].concat(state.publishedCovers),
      };
    });
  }

  addPublishedCoversToStart(publishedCovers: BookCover[]) {
    this.update((state) => {
      const newPublishedCovers: BookCover[] = [];
      for (const publishedCover of publishedCovers) {
        const storePublishedCover = state.publishedCovers.find((ig) => ig.id === publishedCover.id);
        if (storePublishedCover) {
          continue;
        }
        newPublishedCovers.push(publishedCover);
      }

      return {
        ...state,
        publishedCovers: newPublishedCovers.concat(state.publishedCovers),
      };
    });
  }

  addPublishedCoversToEnd(publishedCovers: BookCover[]) {
    this.update((state) => {
      const newPublishedCovers: BookCover[] = [];
      for (const publishedCover of publishedCovers) {
        const storeCoverGeneration = state.publishedCovers.find((ig) => ig.id === publishedCover.id);
        if (storeCoverGeneration) {
          continue;
        }
        newPublishedCovers.push(publishedCover);
      }

      return {
        ...state,
        publishedCovers: state.publishedCovers.concat(newPublishedCovers),
      };
    });
  }

  updatePublishedCover(updatedPublishedCover: BookCover) {
    const publishedCovers = this.getPublishedCovers();
    const publishedCover = publishedCovers.find((pi) => pi.id === updatedPublishedCover.id);
    if (publishedCover) {
      this.update((state) => {
        const updatedPublishedCovers = state.publishedCovers.map((g) => {
          if (g.id === updatedPublishedCover.id) {
            return updatedPublishedCover;
          }
          return g;
        });

        return {
          ...state,
          publishedCovers: updatedPublishedCovers,
        };
      });
    } else {
      this.addPublishedCover(updatedPublishedCover);
    }
  }

  updatePublishedCoverOnLike(id: number, mode: "like" | "unlike") {
    const publishedCovers = this.getPublishedCovers();
    const publishedCover = publishedCovers.find((pi) => pi.id === id);
    if (!publishedCover) {
      return;
    }

    if (mode === "like") {
      publishedCover.isLiked = true;
      publishedCover.likesCount++;
    }

    if (mode === "unlike") {
      publishedCover.isLiked = false;
      publishedCover.likesCount--;
    }
  }
}
