import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ButtonsCreator {
  protected buttons = {
    back: {
      classes: "onboarding-button secondary back",
      text: $localize`:@@onboarding.buttons.back:`,
      type: "back",
      // action: () => {
      //   return this.back();
      // },
    },
    next: {
      classes: "onboarding-button primary",
      text: $localize`:@@onboarding.buttons.next:`,
      type: "next",
    },
    complete: {
      classes: "onboarding-button primary",
      text: $localize`:@@onboarding.buttons.complete:`,
      type: "next",
    },
    cancel: {
      classes: "onboarding-button secondary",
      text: $localize`:@@onboarding.buttons.cancel:`,
      type: "cancel",
    },
  };

  getButton(buttonName: "back" | "next" | "complete" | "cancel") {
    return this.buttons[buttonName];
  }
}
