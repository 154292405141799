import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CoverObject, CoverObjectType, GroupObject, ImageObject, ShapeObject, TextObject } from "@metranpage/book-data";
import { User } from "@metranpage/user-data";
import { CoverUiService } from "../../services/cover/cover-ui.service";

@Component({
  selector: "m-cover-object-list-item",
  templateUrl: "./cover-object-list-item.component.html",
  styleUrls: ["./cover-object-list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverObjectListItemComponent implements OnChanges {
  @Input() object!: CoverObject;
  @Input() isSelected = false;
  @Input() isCurrent = false;
  @Input() user!: User;

  @Input() isGroupItem = false;
  @Input() listIds: string[] = [];

  @Output() select = new EventEmitter<CoverObject>();

  CoverObjectType = CoverObjectType;

  constructor(private readonly _coverUiService: CoverUiService) {}

  get objectTypeIconPath(): string {
    if (this.object instanceof TextObject) {
      return "/assets/icons/text-01.svg";
    }
    if (this.object instanceof ImageObject) {
      return "/assets/icons/image-04.svg";
    }
    if (this.object instanceof ShapeObject) {
      return "/assets/icons/shape-01.svg";
    }
    if (this.object instanceof GroupObject) {
      return "/assets/icons/group-01.svg";
    }
    return "/assets/icons/text-01.svg";
  }

  get visibilityIcon(): string {
    if (this.object.isVisible) {
      return "/assets/icons/eye.svg";
    }
    return "/assets/icons/eye-close.svg";
  }

  get lockIcon(): string {
    return `/assets/icons/${this.object.isLocked ? "locked-01.svg" : "unlocked-01.svg"}`;
  }

  get groupObject(): GroupObject | undefined {
    return this.object instanceof GroupObject ? this.object : undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log(this.connectedTo);
  }

  setCurrent(object: CoverObject) {
    this.select.emit(object);
  }

  onObjectClick() {
    this.setCurrent(this.object);
  }

  validateObjectName(name: string | undefined | null): boolean {
    const value = name?.trim();
    if (value?.length) {
      return true;
    }
    return false;
  }

  onNameChange() {
    if (this.validateObjectName(this.object.name)) {
      this._coverUiService.renameObject(this.object);
    }
  }

  onNameBlur() {
    if (!this.validateObjectName(this.object.name)) {
      this.object.name = this.object.getDefaultName();
      this._coverUiService.renameObject(this.object);
    }
  }

  onNameFocus() {
    this.select.emit(this.object);
  }
}
