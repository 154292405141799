import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import * as _ from "lodash-es";
import { takeUntil } from "rxjs";
import { RewardDaily } from "../../../models/reward-daily";
import { AdminRewardsDailyService } from "../../../services/rewards/rewards-daily/rewards-daily.service";
import { AdminRewardsDailyStore } from "../../../services/rewards/rewards-daily/rewards-daily.store";
import { AdminBasePage } from "../../admin/admin.page";

@Component({
  selector: "m-admin-reward-daily-page",
  templateUrl: "./reward-daily.page.html",
  styleUrls: ["./reward-daily.page.scss"],
})
export class AdminRewardDailyPage extends AdminBasePage implements OnInit, AfterViewInit {
  rewardDaily?: RewardDaily;

  protected form!: FormGroup;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly adminRewardsDailyService: AdminRewardsDailyService,
    adminRewardsDailyStore: AdminRewardsDailyStore,
  ) {
    super();

    this.createForm();

    this.addSub(
      adminRewardsDailyStore
        .getActiveRewardDailyObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((rewardDaily) => {
          this.rewardDaily = rewardDaily;
          if (rewardDaily) {
            const formValues = _.omit(rewardDaily, ["id"]);
            this.form.patchValue(formValues);
          }
        }),
    );
  }

  ngOnInit(): void {}

  async ngAfterViewInit() {
    if ("rewardDailyId" in this.route.snapshot.params) {
      this.adminRewardsDailyService.loadRewardDaily(this.route.snapshot.params["rewardDailyId"]);
    }
  }

  async onSave() {
    if (!this.rewardDaily) {
      return;
    }

    const value = this.form.getRawValue();

    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminRewardsDailyService.updateRewardDaily(this.rewardDaily.id, value);
    this.loadingService.stopLoading();
    if (result === "success") {
      this.router.navigate(["admin", "rewards-daily"]);
    } else {
      this.notificationService.error($localize`:@@admin.rewards.daily.error.cant-update:`);
    }
  }

  onCancel() {
    this.router.navigate(["admin", "rewards-daily"]);
  }

  createForm() {
    this.form = this.adminRewardsDailyService.createRewardDailyForm();
  }

  protected getTypeSelectOptions() {
    return this.adminRewardsDailyService.getAllRewardsDailyTypeSelectOptions();
  }
}
