import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { IconComponent } from "../icon/icon.component";
import { InputComponent } from "../input/input.component";

@Component({
  selector: "m-ng-input-password",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IconComponent, InputComponent],
  templateUrl: "./input-password.component.html",
  styleUrls: ["../input/input.component.scss", "./input-password.component.scss"],
})
export class InputPasswordComponent extends InputComponent {
  @Input()
  withLeftIcon = false;

  protected isPasswordVisible = false;

  protected override getInputType(): string {
    if (this.isPasswordVisible) {
      return "text";
    }
    return "password";
  }

  protected togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
    this.cdr.markForCheck();
  }

  protected getPasswordVisibilityIcon(): string {
    if (this.isPasswordVisible) {
      return "/assets/icons/input-eye.svg";
    }
    return "/assets/icons/input-eye-closed.svg";
  }
}
