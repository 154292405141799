import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BookCoverTemplate } from "@metranpage/book-data";
import { Api, BASE_URL } from "@metranpage/core-api";

@Injectable({
  providedIn: "root",
})
export class CoverTemplateApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  getTemplates(): Promise<BookCoverTemplate[]> {
    return this.get<BookCoverTemplate[]>("cover-templates");
  }

  deleteTemplate(templateId: number): Promise<void> {
    return this.delete<void>(`admin/cover-templates/${templateId}`);
  }

  //////////////////////////////////
  // Images

  getTemplatePreviewImageUrl(templateId: number): string {
    const etag = Math.floor(Math.random() * 10000);
    return `${this.baseUrl}cover-templates/${templateId}/images/file/cover-preview?etag=${etag}`;
  }
}
