import { CommonModule } from "@angular/common";
import { Component, EventEmitter, HostListener, Input, OnInit, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { AngularSvgIconModule } from "angular-svg-icon";

@Component({
  selector: "m-switch",
  templateUrl: "./switch.component.html",
  styleUrls: ["./switch.component.scss"],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, AngularSvgIconModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent implements ControlValueAccessor, OnInit {
  @Input()
  isChecked = false;
  /**
  Sometimes, this components should just reflect outside state and not change by itself. Set isInteractive to false in that case 
   */
  @Input()
  isInteractive = true;
  @Input()
  withLabel = true;

  @Output()
  onToggle = new EventEmitter<void>(undefined);

  protected isDisabled = false;
  private onTouched = () => {};
  private onChange = (_: any) => {};

  ngOnInit(): void {}

  ngAfterViewInit() {}

  @HostListener("click")
  toggle() {
    if (this.isDisabled) {
      return;
    }

    if (!this.isInteractive) {
      return;
    }

    this.isChecked = !this.isChecked;
    this.onTouched();
    this.onChange(this.isChecked);

    this.onToggle.emit();
  }

  writeValue(value: boolean): void {
    if (value === null) {
      return;
    }
    this.isChecked = value;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
