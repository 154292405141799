import { Component } from "@angular/core";

@Component({
  selector: "m-account-host-page",
  template: `
    <m-account-nav-view />

    <div class="content">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
        gap: 8px;
        height: calc(100vh - var(--app-bar-height));
      }

      .content {
        display: flex;
        width: 100%;
        height: 100%;
        min-width: 50vw;
        flex-direction: column;
        margin: 0 0 70px;
        overflow-x: hidden;
        overflow-y: auto;        
      }
    `,
  ],
})
export class AccountHostPage {}
