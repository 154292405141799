import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ConfigService } from "@metranpage/core-interfaces";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { filter, firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OidcService {
  googleConfig: AuthConfig = {
    issuer: "https://accounts.google.com",
    tokenEndpoint: "https://oauth2.googleapis.com/token",
    redirectUri: "",
    clientId: "69677547108-bgjees7irsha3uag18a18nbnimqj0pqs.apps.googleusercontent.com", // prod
    dummyClientSecret: "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", // prod
    // clientId: '145861208697-fjh72j3i5uj6eej9p0c2klstu1srb0un.apps.googleusercontent.com', // local
    // dummyClientSecret: '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', // local
    responseType: "code",
    scope: "openid profile email",
    strictDiscoveryDocumentValidation: false,
    showDebugInformation: true,
  };

  vkConfig: AuthConfig = {
    loginUrl: "https://oauth.vk.com/authorize",
    tokenEndpoint: "https://oauth.vk.com/access_token",
    userinfoEndpoint: "https://api.vk.com/method/users.get",
    redirectUri: "",
    clientId: "8214065", // prod
    //   '8212648 ', // local
    responseType: "token",
    scope: "email",
    strictDiscoveryDocumentValidation: false,
    showDebugInformation: true,
    oidc: false,
    disablePKCE: true,
    clearHashAfterLogin: false,
  };

  constructor(
    private oauthService: OAuthService,
    private http: HttpClient,
    @Inject("ConfigService")
    private configService: ConfigService,
  ) {
    this.googleConfig.redirectUri = `${this.configService.getDeployedUrl()}login/google`;
    this.vkConfig.redirectUri = `${this.configService.getDeployedUrl()}login/vk`;
  }

  loginGoogle() {
    this.oauthService.configure(this.googleConfig);
    this.oauthService.loadDiscoveryDocument().then((_) => {
      this.oauthService.initLoginFlow();
    });
  }

  processGoogleResponse() {
    return new Promise((resolve, reject) => {
      this.oauthService.configure(this.googleConfig);
      this.oauthService.events.pipe(filter((e) => e.type === "token_received")).subscribe(
        (res) => {
          resolve(this.oauthService.getIdentityClaims());
        },
        (err) => {
          reject(err);
        },
      );
      this.oauthService.tryLoginCodeFlow();
    });
  }

  loginVk() {
    this.oauthService.configure(this.vkConfig);
    this.oauthService.initLoginFlow();
  }

  processVkResponse() {
    return new Promise((resolve, reject) => {
      const email = new URLSearchParams(location.hash).get("email");

      this.oauthService.configure(this.vkConfig);

      this.oauthService.events.pipe(filter((e) => e.type === "token_received")).subscribe((res) => {
        firstValueFrom(
          this.http.jsonp(
            `https://api.vk.com/method/users.get?v=5.131&access_token=${this.oauthService.getAccessToken()}`,
            "callback",
          ),
        )
          .then((res: any) => {
            resolve({
              sub: `${res.response[0].id}`,
              firstName: res.response[0].first_name,
              lastName: res.response[0].last_name,
              email: email,
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
      this.oauthService.tryLogin().catch((e) => {
        reject(e);
      });
    });
  }
}
