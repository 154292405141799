<div class="content">
  @if (iconSrc) {
    <m-ng-icon [size]="32" [src]="iconSrc"></m-ng-icon>
  }
  <m-localized-text class="text" [text]="text"></m-localized-text>

  @if (hasNewMark) {
    <m-new-mark />
  }
</div>

@if (hasArrowIcon) {
  <m-ng-icon [size]="28" src="forward.svg"></m-ng-icon>
}
