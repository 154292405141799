import { ChangeDetectorRef, Component, ElementRef, Input, OnInit } from "@angular/core";
import { MarginKey, PagePosition, RunningTitleContext, RunningTitlesStyle } from "@metranpage/book-data";
import { ColorConverterService, PaletteDTO } from "@metranpage/components";

@Component({
  selector: "m-running-titles-base-style",
  template: "",
})
export class RunningTitlesBaseStyleComponent implements OnInit {
  @Input()
  style?: RunningTitlesStyle;
  @Input()
  context: RunningTitleContext = {
    runningTitle: "",
    pageNumber: "",
  };
  @Input("page-position")
  pagePosition: PagePosition = "left";
  @Input("font-size")
  fontSize = 12;
  @Input()
  palette?: PaletteDTO;
  @Input()
  position: MarginKey = "marginTop";

  constructor(
    private colorConverterService: ColorConverterService,
    private readonly elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.applyDecoratorStyle();
  }

  protected getRunningTitleText() {
    return this.context.runningTitle ?? "";
  }

  protected getPageNumberText() {
    return this.context.pageNumber ?? "";
  }

  protected isRunningTitleVisible() {
    return this.getRunningTitleText() && this.getRunningTitleText() !== "";
  }

  protected isPageNumberVisible() {
    return this.getPageNumberText() && this.getPageNumberText() !== "";
  }

  protected getRunningTitleCssClassList(): string[] {
    const result: string[] = [];

    result.push("running-title");
    this.baseCssClassList(result);

    return result;
  }

  protected getPageNumberCssClassList(): string[] {
    const result: string[] = [];

    result.push("page-number");
    this.baseCssClassList(result);

    return result;
  }

  baseCssClassList(list: string[]) {
    if (this.position === "marginBottom") {
      list.push("position-bottom");
    }
    if (this.position === "marginOuter") {
      list.push("orientation-vertical");
    }
    if (this.style?.isCentered) {
      list.push("centered");
    }
    if (this.pagePosition === "right") {
      list.push("mirror");
    }
    return list;
  }

  private applyDecoratorStyle() {
    this.elementRef.nativeElement.style.setProperty("--running-title-font-size", `${this.fontSize}px`);
    this.elementRef.nativeElement.style.setProperty(
      "--running-title-font-color",
      this.colorConverterService.normalizeColor(this.palette?.colorPrimary),
    );
    this.elementRef.nativeElement.style.setProperty(
      "--running-title-color-decorator",
      this.colorConverterService.normalizeColor(this.palette?.colorAccent),
    );
    this.cdr.markForCheck();
  }
}
