<m-ng-island>
  <div class="container">
    <m-button
      button-style="primary"
      icon-position="left"
      icon="plus.svg"
      size="full-width"
      text-align="left"
      (onClick)="onNewProjectClick()"
    >
      <div class="premium-wrapper">
        <span i18n="@@home.last-project.new-project">home.last-project.new-project</span>
        <m-premium-mark *ngIf="isPremiumLabelVisible" />
      </div>
    </m-button>

    <div *ngIf="projects.length > 0" class="last-project">
      <span class="group-title" i18n="@@home.last-project.last-project">home.last-project.last-project</span>

      <div class="book-card" (click)="onGoToProjectClick($event)">
        <m-skeleton-image
          class="cover"
          [url]="coverUrl!"
          backgroudColor="var(--surface-on-island)"
          animationStyle="gradient"
        />

        <div class="info">
          <div class="title">{{ getTitle() }}</div>
          <div class="small">
            <div class="author">{{ getAuthor() }}</div>
            <div class="format">{{ getFormat() }}</div>
          </div>
        </div>

        <m-last-project-edit-menu
          *ngIf="isEditMenuVisible"
          [lastProject]="lastProject"
          (close)="onCloseLastProjectEditMenu()"
        />
      </div>
    </div>
  </div>
</m-ng-island>
