import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { ObjectsAlignment } from "@metranpage/book-data";

@Component({
  selector: "m-cover-object-alignment",
  templateUrl: "./cover-object-alignment.component.html",
  styleUrls: ["./cover-object-alignment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverObjectAlignmentComponent {
  @Output() align = new EventEmitter<ObjectsAlignment>();

  onAlign(alignment: ObjectsAlignment) {
    this.align.emit(alignment);
  }
}
