<form *ngIf="form" [formGroup]="form">
  <div class="toc-control">
    <div class="switch-wrapper">
      <m-switch formControlName="tocVisible" />
      <span class="label" i18n="@@books.markup.add-toc-to-layout">books.markup.add-toc-to-layout</span>
    </div>

    <m-books-markup-sidebar-toc-settings-buttons-column
      *ngIf="isTocPositionVisible"
      formControlName="tocPosition"
      @fadeInOut
    />
  </div>
</form>
