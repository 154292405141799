import { CommonModule, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import {
  ButtonComponent,
  CardComponent,
  CheckboxComponent,
  CheckboxForGroupComponent,
  CheckboxGroupComponent,
  ColorConverterComponent,
  ColorFormatSelectComponent,
  ColorPickerComponent,
  FilterSwitchComponent,
  FixedSidebar,
  FormErrorComponent,
  IconComponent,
  InfoBlockComponent,
  InputComponent,
  LangSelect2Component,
  LangSelectComponent,
  LoadingSpinnerComponent,
  LoadingSpinnerResizableComponent,
  LocalizedTextComponent,
  LogoComponent,
  ModalBackdropComponent,
  ModalComponent,
  NavTabsComponent,
  NewMarkComponent,
  PremiumMarkComponent,
  PreviewDotsComponent,
  RadioComponent,
  RangeComponent,
  SelectComponent,
  SidePanelComponent,
  SidebarFixed,
  SidebarTabs,
  SkeletonImageComponent,
  SoonMarkComponent,
  SwitchBookTypeComponent,
  SwitchComponent,
  TextAreaComponent,
  TooltipComponent,
} from "@metranpage/components";
import { DesignSystemModule } from "@metranpage/design-system";
import { AngularSvgIconModule } from "angular-svg-icon";
import { NgxFileDropModule } from "ngx-file-drop";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { Breadcrumbs } from "./breadcrumbs/breadcrumbs.view";
import { DebugLocaleDetectionComponent } from "./components/debug-locale-detection/debug-locale-detection.component";
import { FileDropComponent } from "./components/file-drop/file-drop.component";
import { NotificationHostComponent } from "./components/notification-host/notification-host.component";
import { NotificationComponent } from "./components/notification-pop-up/notification.component";
import { DebounceClickDirective } from "./directives/debounce-click.directive";
import { IsProdDirective } from "./directives/is-prod.directive";
import { ConfirmationModalView } from "./views/confimation-modal/confimation-modal.view";
import { InfoModalView } from "./views/info-modal/info-modal.view";

@NgModule({
  declarations: [
    FileDropComponent,
    IsProdDirective,
    DebugLocaleDetectionComponent,
    InfoModalView,
    ConfirmationModalView,
    DebounceClickDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxFileDropModule,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
    RouterModule,
    CardComponent,
    LogoComponent,
    InputComponent,
    TextAreaComponent,
    SelectComponent,
    CheckboxComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
    LoadingSpinnerResizableComponent,
    SwitchComponent,
    SwitchBookTypeComponent,
    ModalBackdropComponent,
    ModalComponent,
    IconComponent,
    LocalizedTextComponent,
    LangSelectComponent,
    LangSelect2Component,
    FormErrorComponent,
    PreviewDotsComponent,
    SidePanelComponent,
    ColorPickerComponent,
    ColorConverterComponent,
    ColorFormatSelectComponent,
    RangeComponent,
    RadioComponent,
    FixedSidebar,
    SidebarFixed,
    SidebarTabs,
    TooltipComponent,
    SkeletonImageComponent,
    // OnboardingModule,
    NavTabsComponent,
    InfoBlockComponent,
    Breadcrumbs,
    CheckboxGroupComponent,
    CheckboxForGroupComponent,
    FilterSwitchComponent,
    NewMarkComponent,
    SoonMarkComponent,
    PremiumMarkComponent,

    DesignSystemModule,
    InfiniteScrollModule,

    NotificationHostComponent,
    NotificationComponent,
  ],
  exports: [
    BrowserAnimationsModule,
    FileDropComponent,
    CheckboxComponent,
    IsProdDirective,
    AngularSvgIconModule,
    ReactiveFormsModule,
    CommonModule,
    CardComponent,
    LogoComponent,
    InputComponent,
    TextAreaComponent,
    SelectComponent,
    CheckboxComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
    LoadingSpinnerResizableComponent,
    SwitchComponent,
    SwitchBookTypeComponent,
    IconComponent,
    ModalBackdropComponent,
    ModalComponent,
    DebugLocaleDetectionComponent,
    LocalizedTextComponent,
    LangSelectComponent,
    LangSelect2Component,
    FormErrorComponent,
    PreviewDotsComponent,
    SidePanelComponent,
    ColorPickerComponent,
    ColorConverterComponent,
    ColorFormatSelectComponent,
    RangeComponent,
    RadioComponent,
    FormErrorComponent,
    FixedSidebar,
    SidebarFixed,
    SidebarTabs,
    TooltipComponent,
    SkeletonImageComponent,
    // OnboardingModule,
    NavTabsComponent,
    InfoBlockComponent,
    Breadcrumbs,
    CheckboxGroupComponent,
    CheckboxForGroupComponent,
    FilterSwitchComponent,
    NewMarkComponent,
    SoonMarkComponent,
    PremiumMarkComponent,

    DesignSystemModule,
    InfiniteScrollModule,

    NotificationHostComponent,
    NotificationComponent,
    InfoModalView,
    ConfirmationModalView,
    DebounceClickDirective,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
  bootstrap: [],
})
export class CoreModule {}
