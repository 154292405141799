import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { SNCover } from "../../models/social-network-covers";
import { BehaviorSubject, Observable, shareReplay, tap } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class SocialNetworkCoversStore extends Store<SNCover[]> {
  protected override getInitialState(): SNCover[] {
    return []
  }

  updateCoversState(newState: SNCover[]): void {
    this.update(state => ([...newState]));
  }

  updateCoverState(newState: SNCover): void {

    this.update(state => {
      if (!state.length) {
        return [newState];
      }

      return state.map(stateItem => {
        if (stateItem.id === newState.id) {
          return newState;
        }
        return stateItem
      })
    });
  }

  getCovers(): SNCover[] {
    return this.getState()
  }

  getCovers$(): BehaviorSubject<SNCover[]> {
    return this.getStateObservable();
  }

}
