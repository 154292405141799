import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Company, CompanyStore } from "@metranpage/company";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { PricingService } from "@metranpage/pricing";
import { PaymentMethodDataDto } from "@metranpage/pricing-data";
import { User, UserStore } from "@metranpage/user-data";
import { Subscription } from "rxjs";
import { UserService } from "../../services/user.service";

@Component({
  selector: "m-account-payment-methods-page",
  templateUrl: "./account-payment-methods.page.html",
  styleUrls: ["./account-payment-methods.page.scss"],
})
export class AccountPaymentMethodsPage implements OnInit, OnDestroy {
  user?: User;

  protected paymentMethods: PaymentMethodDataDto[] = [];

  protected emptyCells: undefined[] = [];
  protected paymentMethodsCountInRow = 3;

  protected paymentMethodForDelete?: PaymentMethodDataDto;

  protected company?: Company;

  sub: Subscription = new Subscription();

  constructor(
    private readonly userService: UserService,
    readonly userStore: UserStore,
    private readonly notificationsService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly pricingService: PricingService,
    private readonly route: ActivatedRoute,
    readonly companyStore: CompanyStore,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        this.user = user;
      }),
    );

    this.sub.add(
      companyStore.getCompanyObservable().subscribe((company) => {
        this.company = company;
      }),
    );
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(async (params) => {
      const success = params.get("Success");
      if (success === "False") {
        this.notificationsService.error($localize`:@@user.profile.tariff-and-payments.add-card-error:`);
      }
    });

    this.loadPaymentMethods();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async onAddNewPaymentMethodClick() {
    window.open("payments/await-payment-link?newCard=true", "_blank");
  }

  async selectPaymentMethodClick(paymentMethod: PaymentMethodDataDto) {
    this.loadingService.startLoading({ fullPage: true });
    await this.pricingService.selectPaymentMethod(paymentMethod);
    this.loadingService.stopLoading();
  }

  async deletePaymentMethod() {
    if (!this.paymentMethodForDelete) {
      return;
    }

    this.loadingService.startLoading({ fullPage: true });
    this.paymentMethods = await this.pricingService.deletePaymentMethod(this.paymentMethodForDelete);
    this.loadingService.stopLoading();

    this.paymentMethodForDelete = undefined;

    this.cdr.markForCheck();
  }

  protected isPaymentMethodActive(paymentMethod: PaymentMethodDataDto) {
    if (paymentMethod.system === "cloud-payments") {
      return this.user?.paymentCardId === paymentMethod.id;
    }
    if (paymentMethod.system === "stripe") {
      return this.user?.stripePaymentMethodId === paymentMethod.id;
    }
    return false;
  }

  async loadPaymentMethods() {
    this.loadingService.startLoading({ fullPage: true });
    this.paymentMethods = await this.pricingService.getPaymentMethods();
    this.loadingService.stopLoading();

    this.cdr.detectChanges();
  }

  trackByPaymentMethodId(index: number, data: PaymentMethodDataDto) {
    return data.id;
  }

  protected showDeletePaymentMethodModal(paymentMethod: PaymentMethodDataDto) {
    this.paymentMethodForDelete = paymentMethod;
  }

  protected closeDeletePaymentMethodModal() {
    this.paymentMethodForDelete = undefined;
  }
}
