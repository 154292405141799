<form *ngIf="form" [formGroup]="form">
  <div class="sidebar-section-content">
    <div class="section-header">
      <div class="title-group">
        <m-icon *ngIf="!isSettingsAvailable" src="padlock.svg" color="--color-font-main" size="xs-plus" />
        <span class="title" i18n="@@books.markup.sidebar.colors-title">books.markup.sidebar.colors-title</span>
      </div>
      <m-icon
        class="icon-gear"
        src="gear2.svg"
        size="xs"
        color="--color-font-main"
        (click)="onShowColorsDetailsSidebar.emit()"
      />
    </div>
    <m-color-palette formControlName="palette" [options]="availablePalettes" [class.locked]="!isSettingsAvailable" />
  </div>
</form>
