import { Component } from '@angular/core';

@Component({
  selector: 'm-superadmin-nav-view',
  template: `
    <m-admin-menu-item-component
      routerLink="/superadmin/companies"
      routerLinkActive="active"
      title="admin.nav.companies"
      i18n-title="@@admin.nav.companies"
      icon="/assets/icons/admin-nav-company.svg"
    ></m-admin-menu-item-component>

    <m-admin-menu-item-component
      routerLink="/superadmin/feature-flags"
      routerLinkActive="active"
      title="admin.nav.feature-flags"
      i18n-title="@@admin.nav.feature-flags"
      icon="/assets/icons/styles-editor.svg"
    ></m-admin-menu-item-component>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        width: 240px;

        border-right: 1px solid var(--color-border-elevated);
        padding-right: 16px;
        margin-right: 16px;
      }
    `,
  ],
})
export class SuperadminNavView {}
