<div class="header">
  <m-button button-style="tertiary" icon="button-back.svg" icon-position="only" (onClick)="onBackClick()" />

  <span>{{ contentHeader }}</span>

  <m-button
    button-style="primary"
    i18n="@@books.template.select-template.select-button"
    [is-disabled]="isSelectDisable()"
    (onClick)="selectTemplate()"
  >
    books.template.select-template.select-button
  </m-button>
</div>

<div class="divider-hor"></div>

<div *ngIf="template; else noTemplate" class="content">
  <div class="preview">
    <m-preview-spread
      [fullwidth]="true"
      [imageDataURLs]="previews"
      [selected-left-page]="selectedLeftPage"
      (onSelectLeftPage)="onSelectLeftPage($event)"
    ></m-preview-spread>
  </div>

  <!-- <div class="preview-group">
    <div class="preview">
      <img [src]="(currentImage$ | async)?.url" alt="" />
      <div class="preview-buttons">
        <div class="preview-button" [class.active]="hasPreviousPreview()" (click)="onPreviousPreviewClick()"></div>
        <div class="preview-button" [class.active]="hasNextPreview()" (click)="onNextPreviewClick()"></div>
      </div>
    </div>

    <m-preview-dots
      [dotsCount]="template.previews.length"
      [selectedIndex]="(currentImage$ | async)?.index || 0"
      (onSelect)="selectPreviewImage($event)"
    ></m-preview-dots>
  </div> -->

  <div class="options">
    <div class="heading">
      <span class="title">{{template.title}}</span>
      <span class="description">{{ template.descriptionShort }}</span>
      <div class="info">
        <m-info-block type="warning" [labels]="getInfoLabels()"></m-info-block>
      </div>
    </div>

    <div class="separator"></div>

    <div class="parameters">
      <div class="section">
        <span class="section-title" i18n="@@books.template.template-info.fonts">
          books.template.template-info.fonts
        </span>
        <span class="section-description">
          <div class="section-description-item">
            <span i18n="@@books.template.template-info.fonts-headings">
              books.template.template-info.fonts-headings
            </span>
            –
            <span>{{ template.fontHeading }}</span>
          </div>
          <div class="section-description-item">
            <span i18n="@@books.template.template-info.fonts-main-text">
              books.template.template-info.fonts-main-text
            </span>
            –
            <span>{{ template.fontMain }}</span>
          </div>
        </span>
      </div>

      <div class="divider"></div>

      <div class="section">
        <span class="section-title" i18n="@@books.template.template-info.font-size">
          books.template.template-info.font-size
        </span>
        <span class="section-description">
          <div class="section-description-item">
            <span i18n="@@books.template.template-info.font-size-main-text">
              books.template.template-info.font-size-main-text
            </span>
            –
            <span>{{ getStyleFontSize('mainText') }}</span>
            <span class="units" i18n="@@books.template.template-info.font-size-units">
              books.template.template-info.font-size-units
            </span>
          </div>
          <div class="section-description-item">
            <span i18n="@@books.template.template-info.font-size-header1">
              books.template.template-info.font-size-header1
            </span>
            –
            <span>{{ getStyleFontSize('header1') }}</span>
            <span class="units" i18n="@@books.template.template-info.font-size-units">
              books.template.template-info.font-size-units
            </span>
          </div>
          <div class="section-description-item">
            <span i18n="@@books.template.template-info.font-size-header2">
              books.template.template-info.font-size-header2
            </span>
            –
            <span>{{ getStyleFontSize('header2') }}</span>
            <span class="units" i18n="@@books.template.template-info.font-size-units">
              books.template.template-info.font-size-units
            </span>
          </div>
          <div class="section-description-item">
            <span i18n="@@books.template.template-info.font-size-header3">
              books.template.template-info.font-size-header3
            </span>
            –
            <span>{{ getStyleFontSize('header3') }}</span>
            <span class="units" i18n="@@books.template.template-info.font-size-units">
              books.template.template-info.font-size-units
            </span>
          </div>
          <div class="section-description-item">
            <span i18n="@@books.template.template-info.font-size-header4">
              books.template.template-info.font-size-header4
            </span>
            –
            <span>{{ getStyleFontSize('header4') }}</span>
            <span class="units" i18n="@@books.template.template-info.font-size-units">
              books.template.template-info.font-size-units
            </span>
          </div>
        </span>
      </div>

      <div class="divider"></div>

      <div class="section">
        <span class="section-title" i18n="@@books.template.template-info.margins">
          books.template.template-info.margins
        </span>
        <span class="section-description">
          <div class="section-description-item">
            <span i18n="@@books.template.template-info.margins-inner">books.template.template-info.margins-inner</span>
            –
            <span>{{ template.marginInner }}</span>
            <span class="units" i18n="@@books.template.template-info.margins-units">
              books.template.template-info.margins-units
            </span>
          </div>
          <div class="section-description-item">
            <span i18n="@@books.template.template-info.margins-outer">books.template.template-info.margins-outer</span>
            –
            <span>{{ template.marginOuter }}</span>
            <span class="units" i18n="@@books.template.template-info.margins-units">
              books.template.template-info.margins-units
            </span>
          </div>
          <div class="section-description-item">
            <span i18n="@@books.template.template-info.margins-top">books.template.template-info.margins-top</span>
            –
            <span>{{ template.marginTop }}</span>
            <span class="units" i18n="@@books.template.template-info.margins-units">
              books.template.template-info.margins-units
            </span>
          </div>
          <div class="section-description-item">
            <span i18n="@@books.template.template-info.margins-bottom">
              books.template.template-info.margins-bottom
            </span>
            –
            <span>{{ template.marginBottom }}</span>
            <span class="units" i18n="@@books.template.template-info.margins-units">
              books.template.template-info.margins-units
            </span>
          </div>
        </span>
      </div>

      <div class="divider"></div>

      <div class="section">
        <span class="section-title" i18n="@@books.template.template-info.running-titles">
          books.template.template-info.running-titles
        </span>
        <span class="section-description">
          <span>{{ getRunningTitlesDescription(template.runningTitlePosition) }}</span>
        </span>
      </div>

      <div class="divider"></div>

      <div class="section">
        <span class="section-title" i18n="@@books.template.template-info.page-numbers">
          books.template.template-info.page-numbers
        </span>
        <span class="section-description">
          <span>{{ getPageNumbersDescription(template.pageNumberPosition) }}</span>
        </span>
      </div>

      <div class="divider"></div>

      <div class="section">
        <span class="section-title" i18n="@@books.template.template-info.images-position">
          books.template.template-info.images-position
        </span>
        <span class="section-description">
          <span *ngIf="!template.centerImages" i18n="@@books.template.template-info.images-position-flex">
            books.template.template-info.images-position-flex
          </span>
          <span *ngIf="template.centerImages" i18n="@@books.template.template-info.images-position-fixed">
            books.template.template-info.images-position-fixed
          </span>
        </span>
      </div>
    </div>
  </div>
</div>
<ng-template #noTemplate>
  <div class="content"></div>
</ng-template>
