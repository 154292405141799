import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import { NotificationsData, TestNotificationData } from "../models/notification";

@Injectable({
  providedIn: "root",
})
export class NotificationApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async getUnreadNotificationsCount() {
    return this.get<number>("notifications/unread-count");
  }

  async loadNotificationPaginated(page: number) {
    return this.get<NotificationsData>("notifications/paginated", {
      page,
    });
  }

  async markNotificationsAsRead() {
    return this.post<NotificationsData>("notifications/mark-read", {});
  }

  async notifyOnEmailVerification() {
    return this.post<void>("notifications/email-verification", {});
  }

  async testNotification(data: TestNotificationData) {
    return this.post<void>("notifications/test-notice", data);
  }
}
