<form *ngIf="!isLoading" class="container" [formGroup]="form">
  <m-nav-tabs class="nav-tabs" [tabs]="navTabs" (onTabClick)="onNavTabClick($event)"></m-nav-tabs>

  <div class="preview">
    <m-margins-preview
      [page-size]="pageSize"
      [font-size-main]="fontSizeMain"
      [margins-limits]="marginsLimits"
      [select-state]="selectState"
      [margins-error]="marginsError"
      (onSelectStateChange)="onSelectStateChange($event)"
    ></m-margins-preview>
  </div>

  <div class="side-panel">
    <m-side-panel sidebar-position="right" sidebar-type="fixed">
      <ng-container>
        <div *ngIf="canProduceEpub" class="sidebar-tabs">
          <div [ngClass]="getSidebarTabCssClassList(1)" (click)="activeSidebarTab(1)">
            <m-icon src="book.svg" [color]="getTabIcon(1)" size="s"></m-icon>
            <span i18n="@@books.margins.sidebar-tabs.printbook">books.margins.sidebar-tabs.printbook</span>
          </div>
          <div [ngClass]="getSidebarTabCssClassList(2)" (click)="activeSidebarTab(2)">
            <m-icon src="ebook.svg" [color]="getTabIcon(2)" size="s"></m-icon>
            <span i18n="@@books.margins.sidebar-tabs.ebook">books.margins.sidebar-tabs.ebook</span>
          </div>
        </div>

        <div *ngIf="activeSidebarTabNumber === 1" class="side-panel-printbook">
          <div class="maket-block">
            <div class="maket-label-block">
              <span class="label-header" i18n="@@books.margins.maket">books.margins.maket</span>
            </div>
            <div class="maket-switches-block">
              <div class="switch-wrapper">
                <m-switch class="switch" formControlName="columnsCount"></m-switch>
                <span class="label" i18n="@@books.margins.columns-count">books.margins.columns-count</span>
              </div>
              <div class="switch-wrapper">
                <m-switch class="switch" formControlName="widePaddingState"></m-switch>
                <span class="label" i18n="@@books.margins.paddings-wide">books.margins.paddings-wide</span>
              </div>
            </div>

            <m-info-block
              *ngIf="isTablesInBook || isCenterImages || isColumsCountDisable || isWidePaddingDisable"
              type="warning"
              [labels]="getInfoLabels()"
              (onActionClick)="onInfoBlockActionClick($event)"
            />
          </div>

          <div class="separator-block"></div>

          <div class="maket-block maket-block-space">
            <div class="maket-label-block">
              <span class="label-header" i18n="@@books.margins.space-level">books.margins.space-level</span>
              <span class="tooltip-host-space-level">
                <m-icon src="info.svg" color="--color-fill-space-level-info" size="xs"></m-icon>
                <span class="tooltip-space-level" i18n="@@books.margins.space-level-hint">
                  books.margins.space-level-hint
                </span>
              </span>
            </div>
            <m-range
              class="maket-space-level-range"
              formControlName="spaceLevel"
              [min]="spaceLevelLimits.min"
              [max]="spaceLevelLimits.max"
              [step]="1"
              leftLabel="1"
              i18n-leftLabel="@@books.margins.space-level-less"
              leftLabel="@@books.margins.space-level-less"
              i18n-rightLabel="@@books.margins.space-level-more"
              rightLabel="@@books.margins.space-level-more"
            ></m-range>
          </div>

          <div class="maket-block">
            <div class="margins-group">
              <div class="margins-page-left">
                <div class="margin-top">
                  <m-ng-input-number
                    class="input"
                    [ngClass]="getInputCssClassList('marginTop')"
                    formControlName="marginTop"
                    size="32"
                    text-align="center"
                    [is-highlighted]="isInputHighlighted('marginTop')"
                    (mouseenter)="onMarginInputMouseEnter('marginTop')"
                    (mouseleave)="onMarginInputMouseLeave()"
                    (focus)="onMarginInputFocus('marginTop')"
                    (focusout)="onMarginInputFocusOut()"
                  />
                </div>
                <div class="margin-inner">
                  <m-ng-input-number
                    class="input"
                    [ngClass]="getInputCssClassList('marginInner')"
                    formControlName="marginInner"
                    size="32"
                    text-align="center"
                    [is-highlighted]="isInputHighlighted('marginInner')"
                    (mouseenter)="onMarginInputMouseEnter('marginInner')"
                    (mouseleave)="onMarginInputMouseLeave()"
                    (focus)="onMarginInputFocus('marginInner')"
                    (focusout)="onMarginInputFocusOut()"
                  />
                </div>
                <div class="margin-bottom">
                  <m-ng-input-number
                    class="input"
                    [ngClass]="getInputCssClassList('marginBottom')"
                    formControlName="marginBottom"
                    size="32"
                    text-align="center"
                    [is-highlighted]="isInputHighlighted('marginBottom')"
                    (mouseenter)="onMarginInputMouseEnter('marginBottom')"
                    (mouseleave)="onMarginInputMouseLeave()"
                    (focus)="onMarginInputFocus('marginBottom')"
                    (focusout)="onMarginInputFocusOut()"
                  />
                </div>
                <div class="margin-outer">
                  <m-ng-input-number
                    class="input"
                    [ngClass]="getInputCssClassList('marginOuter')"
                    formControlName="marginOuter"
                    size="32"
                    text-align="center"
                    [is-highlighted]="isInputHighlighted('marginOuter')"
                    (mouseenter)="onMarginInputMouseEnter('marginOuter')"
                    (mouseleave)="onMarginInputMouseLeave()"
                    (focus)="onMarginInputFocus('marginOuter')"
                    (focusout)="onMarginInputFocusOut()"
                  />
                </div>
                <span class="margins-label" i18n="@@books.margins.margins-size">books.margins.margins-size</span>
              </div>
              <div class="margins-page-right"></div>
            </div>

            <!-- <div *ngIf="isMarginsWideEnable()" class="margins-settings-block">
              <div>
                <span class="margins-settings-label" i18n="@@books.margins.gutter">books.margins.gutter</span>
              </div>
              <m-input
                class="input"
                [ngClass]="getInputCssClassList('gutter')"
                formControlName="gutter"
                input-style="custom"
                size="custom"
                text-align="center"
                type="number"
                [is-highlighted]="isInputHighlighted('gutter')"
                (mouseenter)="onMarginInputMouseEnter('gutter')"
                (mouseleave)="onMarginInputMouseLeave()"
                (focus)="onMarginInputFocus('gutter')"
                (focusout)="onMarginInputFocusOut()"
              ></m-input>
            </div> -->

            <div *ngIf="isMarginsWideEnable()" class="margins-settings-block">
              <div>
                <span class="margins-settings-label" i18n="@@books.margins.padding-wide">
                  books.margins.padding-wide
                </span>
              </div>
              <m-ng-input-number
                class="input"
                [ngClass]="getInputCssClassList('widePadding')"
                formControlName="widePadding"
                size="32"
                text-align="center"
                [is-highlighted]="isInputHighlighted('widePadding')"
                (mouseenter)="onMarginInputMouseEnter('widePadding')"
                (mouseleave)="onMarginInputMouseLeave()"
                (focus)="onMarginInputFocus('widePadding')"
                (focusout)="onMarginInputFocusOut()"
              />
            </div>

            <m-info-block
              type="error"
              [labels]="getMarginsErrorsList()"
              (onActionClick)="onMarginsErrorsInfoBlockActionClick($event)"
            />
          </div>
        </div>

        <div *ngIf="activeSidebarTabNumber === 2" class="side-panel-ebook">
          <m-sidebar-tab-ebook />
        </div>
        <div class="buttons">
          <m-button button-style="secondary" icon-position="only" icon="button-back.svg" (onClick)="onBackClick()" />

          <m-button
            button-style="primary"
            size="full-width"
            [is-disabled]="!form.valid"
            (onClick)="onNextClick()"
            i18n="@@books.styles.next-button"
          >
            books.styles.next-button
          </m-button>
        </div>
      </ng-container>
    </m-side-panel>
  </div>
</form>

<m-images-position-settings-modal
  *ngIf="isImagesSettingsModalVisible"
  [value]="isCenterImages ?? false"
  (onSave)="onSaveImagesPosition($event)"
  (close)="closeImagesSettingsModalVisible()"
/>

<m-loading-spinner *ngIf="isLoading" @fadeInOut></m-loading-spinner>

<m-modal-project-router-host />
