import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, SimpleChanges, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { ThemeService } from "@metranpage/theme";
import { Subject, takeUntil, timer } from "rxjs";
import { AdvancedGenerationMode, ImageSize } from "../../models/image-generation";

export type ImageProportionValue = {
  hp: number;
  wp: number;
};
export type ImageProportionWithSizeValue = ImageSize & ImageProportionValue;
export type ImageProportionSizeMode = "basic" | AdvancedGenerationMode;

@Component({
  selector: "m-image-proportion-selector",
  templateUrl: "./image-proportion-selector.view.html",
  styleUrls: ["./image-proportion-selector.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageProportionSelectorView),
      multi: true,
    },
  ],
})
export class ImageProportionSelectorView {
  @Input()
  sizeMode: ImageProportionSizeMode = "basic";

  private onTouched = () => {};
  private onChange = (_: any) => {};

  protected value: ImageProportionWithSizeValue = { width: 0, height: 0, wp: 1, hp: 1 };
  protected selectedProportion: ImageProportionValue = { hp: 1, wp: 1 };
  protected orientation: "hor" | "ver" = "ver";

  protected orientationIconSrc = "";

  protected items: ImageProportionValue[] = [
    { wp: 1, hp: 1 },
    { wp: 3, hp: 4 },
    { wp: 9, hp: 16 },
  ];

  protected sizesBasic: ImageProportionWithSizeValue[] = [
    { width: 512, height: 512, wp: 1, hp: 1 },
    { width: 512, height: 680, wp: 3, hp: 4 },
    { width: 512, height: 920, wp: 9, hp: 16 },
  ];

  protected sizesAdvancedFast: ImageProportionWithSizeValue[] = [
    { width: 1024, height: 1024, wp: 1, hp: 1 },
    { width: 896, height: 1184, wp: 3, hp: 4 },
    { width: 768, height: 1376, wp: 9, hp: 16 },
  ];

  protected sizesAdvancedQuality: ImageProportionWithSizeValue[] = [
    { width: 1024, height: 1024, wp: 1, hp: 1 },
    { width: 896, height: 1184, wp: 3, hp: 4 },
    { width: 768, height: 1376, wp: 9, hp: 16 },
  ];

  protected sizesAdvancedUltra: ImageProportionWithSizeValue[] = [
    { width: 1024, height: 1024, wp: 1, hp: 1 },
    { width: 896, height: 1184, wp: 3, hp: 4 },
    { width: 768, height: 1376, wp: 9, hp: 16 },
  ];

  protected isDisabled = false;

  private destroyed$ = new Subject<void>();

  constructor(
    private readonly themeService: ThemeService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    themeService.changeEvents$.pipe(takeUntil(this.destroyed$)).subscribe((theme) => {
      this.updateOrientationIconSrc();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sizeMode) {
      this.sizeMode = changes.sizeMode.currentValue;

      this.updateValue();
    }
  }

  writeValue(value: ImageProportionWithSizeValue): void {
    this.value = value;
    this.updateOrientationFromSize();
    this.updateProportionsFromSize();

    timer(1).subscribe(() => {
      this.updateValue();
    });

    this.cdr.detectChanges();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onSelect(proportion: ImageProportionValue) {
    this.selectedProportion = proportion;

    this.updateValue();
  }

  onChangeOrientationClick() {
    this.changeOrientations();
    this.changeValueOrientation();
    this.updateOrientation();
    this.updateOrientationIconSrc();
    this.updateValue();
  }

  private changeOrientations() {
    const swp = this.selectedProportion.wp;
    const shp = this.selectedProportion.hp;
    this.selectedProportion = {
      wp: shp,
      hp: swp,
    };

    for (const i of this.items) {
      const wp = i.wp;
      const hp = i.hp;
      i.wp = hp;
      i.hp = wp;
    }
  }

  private changeValueOrientation() {
    const w = this.value.width;
    const h = this.value.height;
    const wp = this.value.wp;
    const hp = this.value.hp;
    this.value = {
      width: h,
      height: w,
      wp: hp,
      hp: wp,
    };
  }

  private updateOrientationFromSize() {
    if (this.value.width > this.value.height) {
      this.orientation = "hor";
    } else {
      this.orientation = "ver";
    }

    this.updateOrientationIconSrc();
  }

  private updateOrientation() {
    if (this.orientation === "hor") {
      this.orientation = "ver";
    } else {
      this.orientation = "hor";
    }

    this.updateOrientationIconSrc();
  }

  private updateOrientationIconSrc() {
    const theme = this.themeService.getTheme();
    this.orientationIconSrc = `format-orientation-${this.orientation}-${theme}.svg`;

    this.cdr.markForCheck();
  }

  getText(value: ImageProportionValue) {
    if (value.wp === 0 || value.hp === 0) {
      return "Auto";
    }
    return `${value.wp}:${value.hp}`;
  }

  isSelected(value: ImageProportionValue) {
    return this.selectedProportion.wp === value.wp && this.selectedProportion.hp === value.hp;
  }

  private roundToNumber(number: number, numberToRound = 10) {
    return number % numberToRound ? number + numberToRound - (number % numberToRound) : number;
  }

  protected getSizes() {
    switch (this.sizeMode) {
      case "fast":
        return this.sizesAdvancedFast;
      case "quality":
        return this.sizesAdvancedQuality;
      case "ultra":
        return this.sizesAdvancedUltra;
      default:
        return this.sizesBasic;
    }
  }

  private updateValue() {
    const sizes = this.getSizes();
    let size = sizes.find((s) => s.wp === this.selectedProportion.wp && s.hp === this.selectedProportion.hp);
    let width = size?.width;
    let height = size?.height;
    if (this.orientation === "hor") {
      size = sizes.find((si) => si.wp === this.selectedProportion.hp && si.hp === this.selectedProportion.wp);
      width = size?.height;
      height = size?.width;
    }

    if (!width || !height) {
      return;
    }

    this.value = {
      ...this.selectedProportion,
      width,
      height,
    };

    this.onChange(this.value);
  }

  private updateProportionsFromSize() {
    let wp = 1;
    let hp = 1;
    let tolerance = 0.1;
    const k = this.value.width / this.value.height;
    if (k > 1) {
      this.items = this.items.map((i) => {
        const wp = i.hp;
        const hp = i.wp;
        return {
          wp,
          hp,
        };
      });
    }

    let iteration = 0;
    let item = this.items.find((i) => i.wp / i.hp < k + tolerance && i.wp / i.hp > k - tolerance);
    while (!item && iteration < k * 100) {
      tolerance += 0.1;
      iteration++;
      item = this.items.find((i) => i.wp / i.hp < k + tolerance && i.wp / i.hp > k - tolerance);
    }

    if (item) {
      wp = item.wp;
      hp = item.hp;
    }

    this.selectedProportion = { wp, hp };

    this.value = {
      ...this.value,
      ...this.selectedProportion,
    };
  }
}
