import { CommonModule, NgClass } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";

import { AngularSvgIconModule } from "angular-svg-icon";
import { ColorConverterComponent } from "../color-converter/color-converter.component";
import { ColorConverterService, Position } from "../color-converter/color-converter.service";
import { HSV } from "../color-converter/color.models";
import { IconComponent } from "../icon/icon.component";
import { InputComponent } from "../input/input.component";

@Component({
  selector: "m-color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    NgClass,
    ReactiveFormsModule,
    AngularSvgIconModule,
    InputComponent,
    ColorConverterComponent,
    IconComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
  ],
})
export class ColorPickerComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input("color-name") colorName = "";
  @Input("show-color-converter") isColorConverShow = true;

  @Input("show-opacity") showOpacity = false;
  @Input("show-eyedropper") showEyeDropper = false;
  @Input("enable-opacity") enableOpacity = true;

  @Output() eyeDropper = new EventEmitter();

  @ViewChild("colorWrapper")
  protected colorWrapperRef!: ElementRef;

  @ViewChild("colorSelector", { static: true, read: ElementRef })
  protected colorSelector!: ElementRef;

  @ViewChild("saturationWrapper")
  protected saturationWrapperRef!: ElementRef;

  @ViewChild("saturationSelector", { static: true, read: ElementRef })
  protected saturationSelector!: ElementRef;

  @ViewChild("opacityWrapper")
  protected opacityWrapperRef!: ElementRef;

  @ViewChild("opacitySelector", { static: true, read: ElementRef })
  protected opacitySelector!: ElementRef;

  protected isDisabled = false;

  protected selectorWidthColor = 0;
  protected selectorHeightColor = 0;

  protected selectorWidthSaturation = 0;
  protected selectorHeightSaturation = 0;

  protected selectorWidthOpacity = 0;
  protected selectorHeightOpacity = 0;

  protected isMouseInComponent = false;

  protected isKeyPressedOnColors = false;
  protected selectedPositionColors: Position = { x: 0, y: this.selectorHeightColor / 2 };
  protected cursorStartPositionColors: Position = { x: 0, y: 0 };
  protected cursorStartPositionOffsetColors: Position = { x: 0, y: 0 };

  protected isKeyPressedOnSaturation = false;
  protected selectedPositionSaturation: Position = { x: 0, y: 0 };
  protected cursorStartPositionSaturation: Position = { x: 0, y: 0 };
  protected cursorStartPositionOffsetSaturation: Position = { x: 0, y: 0 };

  protected isKeyPressedOnOpacity = false;
  protected selectedPositionOpacity: Position = { x: 0, y: this.selectorHeightOpacity / 2 };
  protected cursorStartPositionOpacity: Position = { x: 0, y: 0 };
  protected cursorStartPositionOffsetOpacity: Position = { x: 0, y: 0 };

  protected ctxColors!: CanvasRenderingContext2D;
  protected ctxSaturation!: CanvasRenderingContext2D;
  protected ctxOpacity!: CanvasRenderingContext2D;

  protected colorFormat = 0;
  protected saturation = "";
  //protected opacity = 1;
  protected hsv: HSV = { h: 0, s: 0, v: 0 };

  formColorSelector!: FormGroup;

  private onTouched = () => {};
  private onChange = (_: any) => {};

  constructor(private colorConverterService: ColorConverterService) {}

  ngOnInit(): void {
    this.initForm();
    this.watchFormChanges();
  }

  ngAfterViewInit(): void {
    this.initializeSelectors();
    this.redrawOnFormChange(this.formColorSelector.value.colorConverter, false);
  }

  initializeSelectors() {
    this.saturationSelector.nativeElement.width = this.selectorWidthSaturation =
      this.saturationWrapperRef.nativeElement.offsetWidth;
    this.saturationSelector.nativeElement.height = this.selectorHeightSaturation =
      this.saturationWrapperRef.nativeElement.offsetHeight;

    this.colorSelector.nativeElement.width = this.selectorWidthColor = this.colorWrapperRef.nativeElement.offsetWidth;
    this.colorSelector.nativeElement.height = this.selectorHeightColor =
      this.colorWrapperRef.nativeElement.offsetHeight;

    this.opacitySelector.nativeElement.width = this.selectorWidthOpacity =
      this.opacityWrapperRef.nativeElement.offsetWidth;
    this.opacitySelector.nativeElement.height = this.selectorHeightOpacity =
      this.opacityWrapperRef.nativeElement.offsetHeight;

    this.selectedPositionOpacity.y = this.selectorHeightOpacity / 2;
    this.selectedPositionColors.y = this.selectorHeightColor / 2;
  }

  writeValue(value: HSV): void {
    this.hsv = value;
    this.setCursorPosition();
    this.drawColorSelector();
    this.drawSaturationSelector();
    this.drawOpacitySelector();
    this.setFormValues();
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private initForm() {
    this.formColorSelector = new FormGroup({
      colorConverter: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  private watchFormChanges() {
    this.formColorSelector.valueChanges.subscribe((value) => {
      this.redrawOnFormChange(value.colorConverter);
    });
  }

  private setFormValues() {
    this.formColorSelector.controls.colorConverter.setValue(this.hsv, { emitEvent: false });
  }

  private drawColorSelector() {
    if (!this.ctxColors) {
      this.ctxColors = this.colorSelector?.nativeElement.getContext("2d", { willReadFrequently: true });
    }

    this.ctxColors.clearRect(0, 0, this.selectorWidthColor, this.selectorHeightColor);

    const gradientH = this.ctxColors.createLinearGradient(0, 0, this.selectorWidthColor, 0);
    gradientH.addColorStop(0, "rgba(255,0,0,1)");
    gradientH.addColorStop(0.17, "rgba(255,0,255,1)");
    gradientH.addColorStop(0.35, "rgba(0,0,255,1)");
    gradientH.addColorStop(0.51, "rgba(0,255,255,1)");
    gradientH.addColorStop(0.65, "rgba(0,255,0,1)");
    gradientH.addColorStop(0.85, "rgba(255,255,0,1)");
    gradientH.addColorStop(1, "rgba(255,0,0,1)");
    this.ctxColors.fillStyle = gradientH;
    this.ctxColors.fillRect(0, 0, this.selectorWidthColor, this.selectorHeightColor);

    this.drawCursorColorSelector();
  }

  private drawCursorColorSelector() {
    if (this.selectedPositionColors) {
      this.ctxColors.beginPath();
      this.ctxColors.strokeStyle = "#FFF";
      this.ctxColors.lineWidth = 3;
      this.ctxColors.arc(this.selectedPositionColors.x, this.selectedPositionColors.y, 8, 0, 2 * Math.PI);
      this.ctxColors.stroke();
      this.ctxColors.closePath();
    }
  }

  private drawSaturationSelector() {
    if (!this.ctxSaturation) {
      this.ctxSaturation = this.saturationSelector.nativeElement.getContext("2d", { willReadFrequently: true });
    }

    this.saturation = this.colorConverterService.rgb2css(this.colorConverterService.hsv2rgb(this.hsv.h, 100, 100));

    this.ctxSaturation.fillStyle = this.saturation || "rgba(255,255,255,1)";
    this.ctxSaturation.fillRect(0, 0, this.selectorWidthSaturation, this.selectorHeightSaturation);

    const whiteGrad = this.ctxSaturation.createLinearGradient(0, 0, this.selectorWidthSaturation, 0);
    whiteGrad.addColorStop(0, "rgba(255,255,255,1)");
    whiteGrad.addColorStop(1, "rgba(255,255,255,0)");

    this.ctxSaturation.fillStyle = whiteGrad;
    this.ctxSaturation.fillRect(0, 0, this.selectorWidthSaturation, this.selectorHeightSaturation);

    const blackGrad = this.ctxSaturation.createLinearGradient(0, 0, 0, this.selectorHeightSaturation);
    blackGrad.addColorStop(0, "rgba(0,0,0,0)");
    blackGrad.addColorStop(1, "rgba(0,0,0,1)");

    this.ctxSaturation.fillStyle = blackGrad;
    this.ctxSaturation.fillRect(0, 0, this.selectorWidthSaturation, this.selectorHeightSaturation);

    this.drawCursorSaturationSelector();
  }

  private drawCursorSaturationSelector() {
    if (this.selectedPositionSaturation) {
      this.ctxSaturation.beginPath();
      this.ctxSaturation.strokeStyle = "#FFF";
      this.ctxSaturation.arc(this.selectedPositionSaturation.x, this.selectedPositionSaturation.y, 8, 0, 2 * Math.PI);
      this.ctxSaturation.lineWidth = 3;
      this.ctxSaturation.stroke();
      this.ctxColors.closePath();

      this.ctxSaturation.beginPath();
      this.ctxSaturation.strokeStyle = "#000";
      this.ctxSaturation.arc(this.selectedPositionSaturation.x, this.selectedPositionSaturation.y, 10, 0, 2 * Math.PI);
      this.ctxSaturation.lineWidth = 1;
      this.ctxSaturation.stroke();
      this.ctxColors.closePath();
    }
  }

  private drawOpacitySelector() {
    if (!this.ctxOpacity) {
      this.ctxOpacity = this.opacitySelector?.nativeElement.getContext("2d", { willReadFrequently: true });
    }

    this.ctxOpacity.clearRect(0, 0, this.selectorWidthOpacity, this.selectorHeightOpacity);

    const gradientH = this.ctxOpacity.createLinearGradient(0, 0, this.selectorWidthOpacity, 0);
    const rgb = this.colorConverterService.hsv2rgb(this.hsv.h, this.hsv.s, this.hsv.v);
    gradientH.addColorStop(0, `rgba(${rgb.r},${rgb.g},${rgb.b},0`);
    gradientH.addColorStop(1, `rgba(${rgb.r},${rgb.g},${rgb.b},1`);

    this.ctxOpacity.fillStyle = gradientH;
    this.ctxOpacity.fillRect(0, 0, this.selectorWidthOpacity, this.selectorHeightOpacity);

    this.drawCursorOpacitySelector();
  }

  private drawCursorOpacitySelector() {
    if (this.selectedPositionOpacity) {
      this.ctxOpacity.beginPath();
      this.ctxOpacity.strokeStyle = "#FFF";
      this.ctxOpacity.lineWidth = 3;
      this.ctxOpacity.arc(this.selectedPositionOpacity.x, this.selectedPositionOpacity.y, 8, 0, 2 * Math.PI);
      this.ctxOpacity.stroke();
      this.ctxOpacity.closePath();

      this.ctxOpacity.beginPath();
      this.ctxOpacity.strokeStyle = "#000";
      this.ctxOpacity.arc(this.selectedPositionOpacity.x, this.selectedPositionOpacity.y, 10, 0, 2 * Math.PI);
      this.ctxOpacity.lineWidth = 1;
      this.ctxOpacity.stroke();
      this.ctxOpacity.closePath();
    }
  }

  private redrawOnFormChange(value: HSV, emitColor = true) {
    this.hsv = value;
    this.setCursorPosition();
    this.drawColorSelector();
    this.drawSaturationSelector();
    this.drawOpacitySelector();
    if (emitColor) {
      this.emitColor();
    }
  }

  private redrawOnSliderChange() {
    this.drawColorSelector();
    this.drawSaturationSelector();
    this.drawOpacitySelector();
    this.setFormValues();
  }

  private onChangeSaturationCursorPosition() {
    this.hsv.s = (this.selectedPositionSaturation.x / this.selectorWidthSaturation) * 100;
    this.hsv.v = (1 - this.selectedPositionSaturation.y / this.selectorHeightSaturation) * 100;

    this.redrawOnSliderChange();
  }

  private onChangeColorCursorPosition() {
    this.hsv.h = ((this.selectorWidthColor - this.selectedPositionColors.x) / this.selectorWidthColor) * 360;

    this.redrawOnSliderChange();
  }

  private onChangeOpacityCursorPosition() {
    this.hsv.a = this.selectedPositionOpacity.x / this.selectorWidthOpacity;
    this.redrawOnSliderChange();
  }

  onSaturationMouseDown(event: MouseEvent) {
    this.isKeyPressedOnSaturation = true;

    this.selectedPositionSaturation = { x: event.offsetX, y: event.offsetY };
    this.cursorStartPositionSaturation = { x: event.offsetX, y: event.offsetY };
    this.cursorStartPositionOffsetSaturation = { x: event.clientX, y: event.clientY };

    this.onChangeSaturationCursorPosition();
  }

  onColorsMouseDown(event: MouseEvent) {
    this.isKeyPressedOnColors = true;

    this.selectedPositionColors.x = event.offsetX;
    this.cursorStartPositionColors = { x: event.offsetX, y: event.offsetY };
    this.cursorStartPositionOffsetColors = { x: event.clientX, y: event.clientY };

    this.onChangeColorCursorPosition();
  }

  onOpacityMouseDown(event: MouseEvent) {
    this.isKeyPressedOnOpacity = true;

    this.selectedPositionOpacity.x = event.offsetX;
    this.cursorStartPositionOpacity = { x: event.offsetX, y: event.offsetY };
    this.cursorStartPositionOffsetOpacity = { x: event.clientX, y: event.clientY };

    this.onChangeOpacityCursorPosition();
  }

  private emitColor() {
    this.onChange(this.hsv);
  }

  private setCursorPosition() {
    this.selectedPositionColors.x = (1 - (this.hsv.h / 360 > 0 ? this.hsv.h / 360 : 1)) * this.selectorWidthColor;
    this.selectedPositionSaturation = {
      x: (this.hsv.s / 100) * this.selectorWidthSaturation,
      y: this.selectorHeightSaturation - (this.hsv.v / 100) * this.selectorHeightSaturation,
    };
    this.selectedPositionOpacity.x = (this.hsv.a ?? 1) * this.selectorWidthOpacity;
  }

  @HostListener("window:mouseup", ["$event"])
  onMouseUpGlobal(event: MouseEvent) {
    if (this.isKeyPressedOnColors) {
      this.isKeyPressedOnColors = false;
      this.onChangeColorCursorPosition();
      this.emitColor();
    }

    if (this.isKeyPressedOnSaturation) {
      this.isKeyPressedOnSaturation = false;
      this.onChangeSaturationCursorPosition();
      this.emitColor();
    }

    if (this.isKeyPressedOnOpacity) {
      this.isKeyPressedOnOpacity = false;
      this.onChangeOpacityCursorPosition();
      this.emitColor();
    }
  }

  @HostListener("window:mousemove", ["$event"])
  onMouseMoveGlobal(event: MouseEvent) {
    if (this.isKeyPressedOnColors) {
      const deltaX = event.clientX - this.cursorStartPositionOffsetColors.x;
      let x = this.cursorStartPositionColors.x + deltaX;
      if (x < 0) {
        x = 0;
      } else if (x > this.selectorWidthColor) {
        x = this.selectorWidthColor;
      }
      this.selectedPositionColors.x = x;
      this.onChangeColorCursorPosition();
    }

    if (this.isKeyPressedOnSaturation) {
      const deltaX = event.clientX - this.cursorStartPositionOffsetSaturation.x;
      const deltaY = event.clientY - this.cursorStartPositionOffsetSaturation.y;
      let x = this.cursorStartPositionSaturation.x + deltaX;
      let y = this.cursorStartPositionSaturation.y + deltaY;
      if (x < 0) {
        x = 0;
      } else if (x > this.selectorWidthSaturation) {
        x = this.selectorWidthSaturation;
      }
      if (y < 0) {
        y = 0;
      } else if (y > this.selectorHeightSaturation) {
        y = this.selectorHeightSaturation;
      }
      this.selectedPositionSaturation = { x: x, y: y };
      this.onChangeSaturationCursorPosition();
    }

    if (this.isKeyPressedOnOpacity) {
      const deltaX = event.clientX - this.cursorStartPositionOffsetOpacity.x;
      let x = this.cursorStartPositionOpacity.x + deltaX;
      if (x < 0) {
        x = 0;
      } else if (x > this.selectorWidthOpacity) {
        x = this.selectorWidthOpacity;
      }
      this.selectedPositionOpacity.x = x;
      this.onChangeOpacityCursorPosition();
    }
  }

  onEyeDropper() {
    this.eyeDropper.emit();
  }
}
