<ng-container>
  <div class="list-context-menu">
    <a class="title" i18n="@@editor.select.list_style"
      >editor.select.list_style</a
    >
    <hr />
    <div class="list_styles">
      <img
        [src]="'/assets/icons/markup-add-list-unordered.svg'"
        class="icon"
        size="s"
        (click)="onItemClick($event, 'unordered')"
      />
      <img
        [src]="'/assets/icons/markup-add-list-ordered.svg'"
        class="icon"
        size="xs"
        (click)="onItemClick($event, 'ordered')"
      />
      <img
        [src]="'/assets/icons/markup-add-list-ordered-bkt.svg'"
        class="icon"
        size="xs"
        (click)="onItemClick($event, 'ordered-bkt')"
      />
    </div>
  </div>
</ng-container>
