import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'm-markup-style-details-button',
  template: `
    <m-icon [src]="iconSrc" [size]="size" color="--color-font-main" />
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: var(--color-bg-input);
        border: 1px solid var(--color-bg-input);
        border-radius: 8px;
        cursor: pointer;

        transition: border-color 0.2s;

        &:hover {
          border: 1px solid var(--color-border-input-focused);
        }

        &.selected {
          border: 1px solid var(--accent);
        }
      }
    `,
  ],
})
export class MarkupStyleDetailsSettingsButton {
  @Input()
  iconSrc!: string;
  @HostBinding('class.selected')
  @Input()
  isSelected = false;

  @Input()
  size: 'xs' | 's' = 'xs';
}
