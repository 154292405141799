import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { slideInOutVertical } from "@metranpage/components";
import { GeneratedImage, GeneratedImageService } from "@metranpage/image-generation";

@Component({
  selector: "m-admin-moderation-images-queue",
  templateUrl: "./moderation-images-queue.view.html",
  styleUrls: ["./moderation-images-queue.view.scss"],
  animations: [slideInOutVertical],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminModerationImagesQueueView implements OnChanges {
  @Input()
  moderatingImages: GeneratedImage[] = [];
  @Input()
  isModeratingImagesLoading = false;

  @Output()
  onSelect = new EventEmitter<GeneratedImage>();

  constructor(
    private readonly generatedImageService: GeneratedImageService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(_changes: SimpleChanges) {
    this.cdr.detectChanges();
  }

  protected onPreviewImageShowClick(generatedImage: GeneratedImage) {
    this.onSelect.emit(generatedImage);
  }

  protected getUrlForImage(generatedImage: GeneratedImage, isThumbnail = false) {
    return this.generatedImageService.getUrlForImage(generatedImage, isThumbnail);
  }
}
