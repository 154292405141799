import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { PricingViewService } from "@metranpage/pricing";
import { debounceTime, takeUntil } from "rxjs";
import { Promocode, PromocodeCreateData, PromocodeWithAnalitics } from "../../models/promocode";
import { Tariff } from "../../models/tariff";
import { AdminPromocodesService } from "../../services/promocodes/promocodes.service";
import { AdminPromocodesStore } from "../../services/promocodes/promocodes.store";
import { AdminTariffsService } from "../../services/tariffs/tariffs.service";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-promocodes-page",
  templateUrl: "./promocodes.page.html",
  styleUrls: ["./promocodes.page.scss"],
})
export class AdminPromocodesPage extends AdminBasePage implements OnInit, OnDestroy {
  tariffs: Tariff[] = [];

  promocodes: PromocodeWithAnalitics[] = [];
  page = 1;
  pageCount = 1;
  promocode: Promocode | null = null;

  protected searchForm = new FormGroup({
    token: new FormControl("", { nonNullable: true, validators: [] }),
  });
  protected searchToken: string | undefined = undefined;

  protected updatePromocodeDataForm?: FormGroup;

  protected isAddPromocodeModalVisible = false;
  protected isDeletePromocodeModalVisible = false;
  protected promocodeForDelete?: Promocode = undefined;

  constructor(
    private readonly adminPromocodesService: AdminPromocodesService,
    private readonly adminPromocodesStore: AdminPromocodesStore,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly pricingViewService: PricingViewService,
    private readonly adminTariffsService: AdminTariffsService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();

    this.addSub(
      adminPromocodesStore
        .getPromocodesObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((promocodes) => {
          this.promocodes = promocodes;
          this.cdr.markForCheck();
        }),
    );

    this.addSub(
      adminPromocodesStore
        .getPromocodesPageCountObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((count) => {
          this.pageCount = count;
          this.cdr.markForCheck();
        }),
    );
  }

  ngOnInit(): void {
    this.loadTariffs();

    this.adminPromocodesService.loadPromocodesPaginated(this.page);

    this.searchForm.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(600)).subscribe((value) => {
      this.searchToken = undefined;
      if (value.token) {
        this.searchToken = value.token;
      }
      this.adminPromocodesService.loadPromocodesPaginated(this.page, {
        token: this.searchToken,
      });
    });

    this.addSub(
      this.adminPromocodesStore
        .getActivePromocodeObservable()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((promocode) => {
          this.promocode = promocode || null;
        }),
    );
  }

  trackByPromocodeId(index: number, promocode: Promocode) {
    return promocode.id;
  }

  onPageChanged(page: number) {
    this.page = page;
    this.adminPromocodesService.loadPromocodesPaginated(this.page);
  }

  protected showPromocodeDetails(promocode: Promocode) {
    this.router.navigate(["admin", "promocodes", promocode.id]);
  }

  protected async onAddPromocode(promocodeId?: number) {
    this.isAddPromocodeModalVisible = true;
    if (promocodeId) {
      this.adminPromocodesService.loadPromocode(promocodeId);
    }
  }

  protected async onPromocodeSaved(data: PromocodeCreateData) {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const resultStatus = await this.managePromocode(data);
    this.loadingService.stopLoading();
    if (resultStatus === "success") {
      this.onCloseAddPromocodeModal();
      this.adminPromocodesService.loadPromocodesPaginated(this.page);
      this.adminPromocodesService.clearActivePromocodeState();
    } else {
      this.notificationService.error($localize`:@@admin.promocodes.promocode.error.cant-create-promocode:`);
    }
  }

  private async managePromocode(data: PromocodeCreateData): Promise<GeneralResultStatus> {
    if (this.promocode) {
      return this.adminPromocodesService.updatePromocode(this.promocode.id, data);
    }

    return this.adminPromocodesService.createPromocode(data).then(({ status }) => status);
  }

  protected onCloseAddPromocodeModal() {
    this.isAddPromocodeModalVisible = false;
  }

  protected onCloseDeletePromocodeModal() {
    this.isDeletePromocodeModalVisible = false;
    this.promocodeForDelete = undefined;
  }

  protected showPromocodeDeleteModal(promocode: Promocode) {
    this.isDeletePromocodeModalVisible = true;
    this.promocodeForDelete = promocode;
  }

  protected async onDeletePromocode() {
    if (!this.promocodeForDelete) {
      return;
    }

    const result = await this.adminPromocodesService.deletePromocode(this.promocodeForDelete.id);
    if (result.status === "success") {
      this.isDeletePromocodeModalVisible = false;
      this.promocodeForDelete = undefined;
      this.adminPromocodesService.loadPromocodesPaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.promocodes.promocode.error.cant-delete-promocode:`);
    }
  }

  private async loadTariffs() {
    this.tariffs = await this.adminTariffsService.loadTariffs();
  }

  protected getTariffTitle(tariffId: number | undefined) {
    const tariff = this.tariffs.find((t) => t.id === tariffId);
    if (!tariff) {
      return "";
    }
    return `${tariff.title} - ${tariff.period} ${this.pricingViewService.pluralizeMonths(tariff.period)}`;
  }
}
