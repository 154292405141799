import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BookModalEventBus {
  private uploadFile = new Subject<void>();
  uploadFile$ = this.uploadFile.asObservable();

  private templateSelected = new Subject<void>();
  templateSelected$ = this.templateSelected.asObservable();

  onUploadFile() {
    this.uploadFile.next();
  }

  onTemplateSelected() {
    this.templateSelected.next();
  }
}
