<m-modal-backdrop
  [closeButtonVisible]="closeButtonVisible"
  [closeOnBackDropClick]="closeOnBackDropClick"
  (close)="onCloseClick()"
>
  <m-ng-island [paddingSize]="24">
    <div class="new-project-modal">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </m-ng-island>
</m-modal-backdrop>
