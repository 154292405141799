import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CoverObject, ObjectsAlignment } from "@metranpage/book-data";

@Component({
  selector: "m-cover-multiselect-settings",
  templateUrl: "./cover-multiselect-settings.component.html",
  styleUrls: ["./cover-multiselect-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverMultiselectSettingsComponent {
  @Input() objects: CoverObject[] = [];

  @Output() align = new EventEmitter<ObjectsAlignment>();
  @Output() group = new EventEmitter();
  @Output() colorsUpdate = new EventEmitter<CoverObject[]>();

  onAlign(alignment: ObjectsAlignment) {
    this.align.emit(alignment);
  }

  onGroupObjects() {
    this.group.emit();
  }

  onColorsUpdate(objects: CoverObject[]) {
    this.colorsUpdate.emit(objects);
  }
}
