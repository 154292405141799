import { Inject, Injectable } from "@angular/core";
import { BookFeatures } from "@metranpage/book-data";
import { BookFeaturesCollector } from "@metranpage/book-interfaces";
import { RealtimeService } from "@metranpage/core";
import {
  ActiveSubscription,
  PaymentMethodDataDto,
  PaymentResult,
  PricingApi,
  PromocodeDiscountData,
  PromocodeEnterResult,
} from "@metranpage/pricing-data";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PricingService {
  promocodeEvent$ = new BehaviorSubject<PromocodeEnterResult | undefined>(undefined);
  promocodeDiscountDataEvent$ = new BehaviorSubject<PromocodeDiscountData | undefined>(undefined);

  constructor(
    private readonly api: PricingApi,
    @Inject("BookFeaturesCollector")
    private readonly booksFeaturesCollector: BookFeaturesCollector,
    realtimeService: RealtimeService,
  ) {
    realtimeService
      .getEvents<PromocodeDiscountData>("promocode-discount-data-change")
      .subscribe((promocodeDiscountDataUpdate: PromocodeDiscountData | undefined) => {
        this.promocodeDiscountDataEvent$.next(promocodeDiscountDataUpdate);
      });
  }

  async getPaymentUrl(creditsCount: number, tariffId: number): Promise<PaymentResult> {
    try {
      const result = await this.api.getPaymentUrl(creditsCount, tariffId);
      return {
        status: "success",
        url: result.url,
      };
    } catch (error: any) {
      return {
        status: "error",
      };
    }
  }

  // async updateOrderStatus(orderId: string, status: string): Promise<BuyCreditsResult> {
  //   try {
  //     const result = await this.api.updateOrderStatus(orderId, status);
  //     return {
  //       status: 'success',
  //     };
  //   } catch (error: any) {
  //     return {
  //       status: 'error',
  //     };
  //   }
  // }

  async getBookFeaturesForFile(file: File): Promise<BookFeatures | undefined> {
    try {
      const response = await this.booksFeaturesCollector.getBookFeaturesForFile(file);
      return response as BookFeatures;
    } catch (error: any) {
      return undefined;
    }
  }

  async getTariffsForCompany() {
    return await this.api.getTariffsForCompany();
  }

  async getHigherTariff() {
    return await this.api.getHigherTariff();
  }

  async getActiveSubscription() {
    return await this.api.getActiveSubscription();
  }

  async buyCredits(creditsCount: number): Promise<PaymentResult | undefined> {
    try {
      const result = await this.api.buyCredits(creditsCount);
      return {
        status: result.status,
        url: result.url,
      };
    } catch (error: any) {
      return {
        status: "error",
      };
    }
  }

  async buySubscription(tariffId: number): Promise<PaymentResult | undefined> {
    try {
      const result = await this.api.buySubscription(tariffId);
      return {
        status: result.status,
        url: result.url,
      };
    } catch (error: any) {
      return {
        status: "error",
      };
    }
  }

  async addPaymentMethod(): Promise<PaymentResult | undefined> {
    return await this.api.addPaymentMethod();
  }

  async getPaymentMethods() {
    return await this.api.getPaymentMethods();
  }

  async selectPaymentMethod(data: PaymentMethodDataDto) {
    return await this.api.selectPaymentMethod(data);
  }

  async deletePaymentMethod(data: PaymentMethodDataDto) {
    return await this.api.deletePaymentMethod(data);
  }

  async getOrders() {
    return await this.api.getOrders();
  }

  async activatePromocode(promocode: string): Promise<PromocodeEnterResult> {
    return await this.api.activatePromocode(promocode);
  }

  async loadPromocodeDiscountData(): Promise<PromocodeDiscountData | undefined> {
    return await this.api.loadPromocodeDiscountData();
  }

  getPromocodeResultText(promocodeResult: PromocodeEnterResult | undefined) {
    switch (promocodeResult?.status) {
      // case 'success':
      //   return $localize`:@@pricing.promocode.activation.success:`;
      case "expired":
        return $localize`:@@pricing.promocode.activation.error.expired:`;
      case "already-used":
        return $localize`:@@pricing.promocode.activation.error.already-used:`;
      case "not-found":
        return $localize`:@@pricing.promocode.activation.error.not-found:`;
      case "error":
        return $localize`:@@pricing.promocode.activation.error.error-occurs:`;
      case "error-active-subscription-has-higher-level":
        return $localize`:@@pricing.promocode.activation.error.error-active-subscription-has-higher-level:`;
      default:
        return "";
    }
  }

  hasPremium(activeSubscription: ActiveSubscription | undefined): boolean {
    return (
      activeSubscription?.tariff.isUnlimited || (!activeSubscription?.tariff.isFree && !!activeSubscription?.tariff.level) || !!activeSubscription?.hasTrialPeriod
    );
  }
}
