import { NgOptimizedImage } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule, Type } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { I18nModule } from "@metranpage/i18n";
import { CoreModule } from "@metranpage/core";
import { NgxFileDropModule } from "ngx-file-drop";
import { DefaultImageLoadingProvider, ImageLoadingProvider } from "./services/image-loading.provider";
import { BASE_URL, IMAGE_LOADING_PROVIDER } from "./tokens";
import { MarkupBlockToolbarInline } from "./views/markup-block-toolbar-inline/markup-block-toolbar-inline.view";
import { MarkupBlockToolbar } from "./views/markup-block-toolbar/markup-block-toolbar.view";
import { MarkupEditorMenuItem } from "./views/markup-editor-menu-item/markup-editor-menu-item.view";
import { MarkupEditorMenu } from "./views/markup-editor-menu/markup-editor-menu.view";
import { MarkupEditorMenuStyles } from "./views/markup-editor-menu styles/markup-editor-menu-styles.view";
import { BaseTextBlockView } from "./views/markup-editor/blocks/base-text/base-text.block-view";
import { MarkupBlockView } from "./views/markup-editor/blocks/block.view";
import { FootnoteBlockView } from "./views/markup-editor/blocks/footnote/footnote.block-view";
import { HeaderBlockView } from "./views/markup-editor/blocks/header/header.block-view";
import { ImageBlockComponent } from "./views/markup-editor/blocks/image-block/image-block.component";
import { ListBlockComponent } from "./views/markup-editor/blocks/list-block/list-block.component";
import { ListContextMenuComponent } from "./views/markup-editor/blocks/list-block/list-context-menu/list-context-menu.component";
import { ListItemView } from "./views/markup-editor/blocks/list-block/list-item-view/list-item-view";
import { PageBreakBlockView } from "./views/markup-editor/blocks/page-break/page-break.block-view";
import { TableBlockComponent } from "./views/markup-editor/blocks/table-block/table-block.component";
import { WarningBlockView } from "./views/markup-editor/blocks/warning/warning.block-view";
import { MarkupEditorView } from "./views/markup-editor/markup-editor.view";
import { Paginator } from "./views/paginator/paginator.view";
import { BaseTextEditor } from "./views/text-editor/base-text-editor/base-text-editor.view";
import { TextEditor } from "./views/text-editor/text-editor/text-editor.view";
import { TextGenerationContextMenuButtonView } from "./views/text-generation-context-menu/text-generation-context-menu-button.view";
import { TextGenerationContextMenuView } from "./views/text-generation-context-menu/text-generation-context-menu.view";

export type Config = {
  url: string;
  imageLoadingProviderClass?: Type<ImageLoadingProvider>;
};

@NgModule({
  declarations: [
    MarkupEditorView,
    MarkupBlockView,
    BaseTextBlockView,
    HeaderBlockView,
    PageBreakBlockView,
    FootnoteBlockView,
    WarningBlockView,
    TextGenerationContextMenuView,
    TextGenerationContextMenuButtonView,

    MarkupBlockToolbar,
    MarkupBlockToolbarInline,
    MarkupEditorMenuItem,
    MarkupEditorMenu,
    MarkupEditorMenuStyles,

    BaseTextEditor,
    TextEditor,
    ListBlockComponent,
    ListItemView,
    Paginator,
  ],
  imports: [
    CoreModule,
    ListContextMenuComponent,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    NgOptimizedImage,
    RouterModule,
    NgxFileDropModule,
    I18nModule,
    ImageBlockComponent,
    TableBlockComponent,
  ],
  exports: [MarkupEditorView],
  providers: [],
})
// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
export class MarkupEditorModule {
  static forRoot(
    config: Config = { url: "", imageLoadingProviderClass: undefined },
  ): ModuleWithProviders<MarkupEditorModule> {
    let imageLoadingProviderClass = config.imageLoadingProviderClass;
    if (!imageLoadingProviderClass) {
      imageLoadingProviderClass = DefaultImageLoadingProvider;
    }
    return {
      ngModule: MarkupEditorModule,
      providers: [
        { provide: BASE_URL, useValue: config.url },
        {
          provide: IMAGE_LOADING_PROVIDER,
          useClass: imageLoadingProviderClass,
        },
      ],
    };
  }
}
