import { Injectable } from '@angular/core';
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrowserNotificationService {
  requestNotificationPermission() {
    if (!('Notification' in window)) {
      // no support in browser
      return;
    }

    if (Notification.permission === 'granted') {
      // already granted
      this.sendNotification($localize`:@@app-name:`, $localize`:@@user.profile.browser-notifications-accepted:`);
      return;
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          this.sendNotification($localize`:@@app-name:`, $localize`:@@user.profile.browser-notifications-accepted:`);
        }
      });
    }
  }

  // messages with same tag will overwrite each other, so no notifacations will show if some event occured in several tabs
  sendNotification(title: string, message: string, tag?: string) {
    if (!('Notification' in window)) {
      // no support in browser
      return;
    }

    const notification = new Notification(title, {
      body: message,
      tag,
    });

    notification.onclick = () => {
      window.focus();
      notification.close();
    };

    timer(30000).subscribe(() => {
      notification.close();
    });
  }
}
