<div class="header" (click)="toggle()">
  <div class="title-group">
    <m-icon *ngIf="!isSettingsAvailable" src="padlock.svg" color="--color-font-main" size="xs-plus" />
    <span class="title">{{ title }}</span>
  </div>
  <span class="icon" [class.collapsed]="isCollapsed"></span>
  <m-icon src="dropdown-arrow-down.svg" color="--color-font-main" size="xs" [class.collapsed]="isCollapsed" />
</div>
<div class="content" *ngIf="isCollapsed" @slideInOutVertical>
  <ng-content></ng-content>
</div>
