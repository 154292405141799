import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SelectValue, fadeInOutOnEnterLeave } from "@metranpage/components";
import { Subscription } from "rxjs";
import { LoginError } from "../../models/login-errors";

export function passwordsConfirmedValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password: string = control.get("password")?.value;
    const passwordConfirm: string = control.get("passwordConfirm")?.value;

    if (!passwordConfirm?.length) {
      return null;
    }

    if (password !== passwordConfirm) {
      control.get("passwordConfirm")?.setErrors({ mismatch: true });
    } else {
      control.get("passwordConfirm")?.setErrors(null);
      return null;
    }
    return null;
  };
}

export type RegistrationData = {
  firstName: string;
  email: string;
  password: string;
  role: number;
  dpaAgreement: boolean;
  promoAgreement: boolean;
};

@Component({
  selector: "m-registration-view",
  templateUrl: "./registration.view.html",
  styleUrls: ["./registration.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class RegistrationView {
  @Input()
  errors: LoginError[] = [];
  @Input()
  isLoading = false;
  @Input()
  isVkLoginAvailable = true;
  @Input()
  isGoogleLoginAvailable = true;
  @Input()
  userLanguage = "ru";

  @Output()
  onVkLoginClick = new EventEmitter<void>();
  @Output()
  onGoogleLoginClick = new EventEmitter<void>();
  @Output()
  onRegisterClick = new EventEmitter<RegistrationData>();

  @Output()
  onLangChangeClick = new EventEmitter<string>();

  private regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  protected step: "email-data" | "user-data" = "email-data";

  protected form = new FormGroup(
    {
      email: new FormControl("", { nonNullable: true, validators: [Validators.email, Validators.required] }),
      password: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
      passwordConfirm: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      firstName: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
      role: new FormControl(-1, { nonNullable: true, validators: [Validators.required, Validators.min(1)] }),
      dpaAgreement: new FormControl(false, { nonNullable: true, validators: [Validators.requiredTrue] }),
      promoAgreement: new FormControl(true, { nonNullable: true, validators: [] }),
    },
    {
      validators: [passwordsConfirmedValidator()],
    },
  );

  protected isInputEmailErrorVisible = false;
  protected isInputPasswordErrorVisible = false;
  protected isInputPasswordConfirmErrorVisible = false;

  protected isInputFirstNameErrorVisible = false;
  protected isInputRoleErrorVisible = false;
  protected isInputDpaAgreementErrorVisible = false;

  private sub = new Subscription();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.watchFormChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onRegisterButtonClick() {
    this.isInputFirstNameErrorVisible = true;
    this.isInputRoleErrorVisible = true;
    this.isInputDpaAgreementErrorVisible = true;
    this.setFormValidators("firstName");
    this.setFormValidators("role");
    this.setFormValidators("dpaAgreement");

    if (this.isRegisterButtonDisabled()) {
      return;
    }

    const value = this.form.value;
    this.onRegisterClick.emit({
      firstName: value.firstName!,
      email: value.email!,
      password: value.password!,
      role: value.role!,
      dpaAgreement: value.dpaAgreement!,
      promoAgreement: value.promoAgreement!,
    });
  }

  isRegisterButtonDisabled() {
    return !this.form.valid;
  }

  protected onNextButtonClick() {
    this.isInputEmailErrorVisible = true;
    this.isInputPasswordErrorVisible = true;
    this.isInputPasswordConfirmErrorVisible = true;
    this.setFormValidators("email");
    this.setFormValidators("password");
    this.setFormValidators("passwordConfirm");

    if (this.isNextButtonDisabled()) {
      return;
    }

    this.step = "user-data";
  }

  protected isNextButtonDisabled() {
    return !(
      this.form.get("email")?.valid &&
      this.form.get("password")?.valid &&
      this.form.get("passwordConfirm")?.valid
    );
  }

  protected onBackButtonClick() {
    this.step = "email-data";
  }

  protected get roleOptions(): SelectValue[] {
    return [
      { id: 1, value: $localize`:@@user.roles.publisher:` },
      { id: 2, value: $localize`:@@user.roles.author:` },
      { id: 3, value: $localize`:@@user.roles.designer:` },
      { id: 4, value: $localize`:@@user.roles.student:` },
    ];
  }

  protected onKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter") {
      if (!this.isRegisterButtonDisabled()) {
        this.onRegisterButtonClick();
      }
    }
  }

  protected isEmailIncorrect() {
    return this.form.get("email")?.errors?.["email"];
  }

  protected isFieldEmpty(controlName: string) {
    return this.form.get(controlName)?.errors?.["required"];
  }

  protected isFieldNotInRange(controlName: string) {
    return this.form.get(controlName)?.errors?.["min"];
  }

  protected getQueryParams() {
    return this.route.snapshot.queryParams;
  }

  protected onLangChange(lang: string) {
    this.onLangChangeClick.emit(lang);
  }

  protected isPasswordsDifferent() {
    return !!this.form.get("passwordConfirm")?.errors?.mismatch;
  }

  protected isSocialLoginVisible() {
    return this.isVkLoginAvailable || this.isGoogleLoginAvailable;
  }

  protected onGoogleLogin() {
    this.onGoogleLoginClick.emit();
  }

  protected onVkLogin() {
    this.onVkLoginClick.emit();
  }

  protected focusoutEvent(controlName: string) {
    const value = this.form.get(controlName)?.value;
    if (value && controlName === "email") {
      this.isInputEmailErrorVisible = true;
    }
    if (value && controlName === "password") {
      this.isInputPasswordErrorVisible = true;
    }
    if (controlName === "passwordConfirm") {
      this.isInputPasswordConfirmErrorVisible = true;
    }

    if (controlName === "firstName") {
      this.isInputFirstNameErrorVisible = true;
    }
    if (controlName === "role") {
      this.isInputRoleErrorVisible = true;
    }
    if (controlName === "dpaAgreement") {
      this.isInputDpaAgreementErrorVisible = true;
    }
    this.setFormValidators(controlName);
  }

  protected watchFormChanges() {
    this.sub.add(
      this.form.get("email")?.valueChanges.subscribe((value) => {
        this.isInputEmailErrorVisible = false;
        this.clearFormValidators("email");
        this.cdr.detectChanges();
      }),
    );

    this.sub.add(
      this.form.get("password")?.valueChanges.subscribe((value) => {
        this.isInputPasswordErrorVisible = false;
        this.clearFormValidators("password");
        this.cdr.detectChanges();
      }),
    );

    this.sub.add(
      this.form.get("passwordConfirm")?.valueChanges.subscribe((value) => {
        this.isInputPasswordConfirmErrorVisible = false;
        this.clearFormValidators("passwordConfirm");
        this.cdr.detectChanges();
      }),
    );

    this.sub.add(
      this.form.get("firstName")?.valueChanges.subscribe((value) => {
        this.isInputFirstNameErrorVisible = false;
        this.clearFormValidators("firstName");
        this.cdr.detectChanges();
      }),
    );

    this.sub.add(
      this.form.get("role")?.valueChanges.subscribe((value) => {
        this.isInputRoleErrorVisible = false;
        this.clearFormValidators("role");
        this.cdr.detectChanges();
      }),
    );

    this.sub.add(
      this.form.get("dpaAgreement")?.valueChanges.subscribe((value) => {
        this.isInputDpaAgreementErrorVisible = false;
        this.clearFormValidators("dpaAgreement");
        this.cdr.detectChanges();
      }),
    );
  }

  private setFormValidators(controlName: string) {
    if (controlName === "email") {
      this.form.get(controlName)?.setValidators([Validators.email, Validators.required]);
    }
    if (controlName === "password") {
      this.form.get(controlName)?.setValidators([Validators.required]);
    }
    if (controlName === "passwordConfirm") {
      this.form.get(controlName)?.setValidators([Validators.required]);
    }

    if (controlName === "firstName") {
      this.form.get(controlName)?.setValidators([Validators.required]);
    }
    if (controlName === "role") {
      this.form.get(controlName)?.setValidators([Validators.required, Validators.min(1)]);
    }
    if (controlName === "dpaAgreement") {
      this.form.get(controlName)?.setValidators([Validators.requiredTrue]);
    }

    this.form.get(controlName)?.updateValueAndValidity({ emitEvent: false });
    this.cdr.detectChanges();
  }

  private clearFormValidators(controlName: string) {
    this.form.get(controlName)?.clearValidators();
    this.form.get(controlName)?.updateValueAndValidity({ emitEvent: false });
  }
}
