import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import { RewardDaily, RewardDailyCreateData, RewardDailyData, RewardsDailyData } from "../../../models/reward-daily";

export type RewardsDailyFilter = {
  type?: string;
};

@Injectable({
  providedIn: "root",
})
export class AdminRewardsDailyApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  async createRewardDaily(data: RewardDailyCreateData) {
    return this.post<{ id: number }>("admin/reward-daily/", data);
  }

  async getRewardDaily(rewardDailyId: number) {
    return this.get<RewardDailyData>(`admin/reward-daily/${rewardDailyId}`);
  }

  async getRewardsDaily() {
    return this.get<RewardDaily[]>("admin/reward-daily");
  }

  async getRewardsDailyPaginated(page: number, filters: RewardsDailyFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<RewardsDailyData>("admin/reward-daily/paginated", {
      page,
      ...clearedFilters,
    });
  }

  async updateRewardDaily(rewardDailyId: number, data: RewardDailyCreateData) {
    return this.put<unknown>(`admin/reward-daily/update/${rewardDailyId}`, data);
  }

  async deleteRewardDaily(rewardDailyId: number) {
    return this.delete(`admin/reward-daily/${rewardDailyId}`);
  }
}
