import { CommonModule, NgClass } from '@angular/common';

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'm-running-titles-position',
  templateUrl: './running-titles-position.component.html',
  styleUrls: ['./running-titles-position.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RunningTitlesPositionComponent),
      multi: true,
    },
  ],
})
export class RunningTitlesPositionComponent implements OnInit {
  @Input()
  position = 0;
  @Input()
  isDisabled = false;

  ngOnInit(): void {}

  protected getCssClassList(): string[] {
    const result: string[] = [];

    result.push(`position-${this.position}`);
    if (this.isDisabled) {
      result.push('disabled');
    }

    return result;
  }
}
