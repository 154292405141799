import { Component, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'm-admin-page',
  template: '',
})
export class AdminBasePage implements OnDestroy {
  destroyed$ = new Subject<void>();

  private sub: Subscription = new Subscription();

  // constructor() {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();

    this.sub.unsubscribe();
  }

  addSub(subscription: Subscription | undefined) {
    this.sub.add(subscription);
  }
}
