import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { RewardDaily } from "../../../models/reward-daily";

export type State = {
  rewardsDaily: RewardDaily[];
  rewardsDailyPageCount: number;
  activeRewardDaily: RewardDaily | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminRewardsDailyStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      rewardsDaily: [],
      rewardsDailyPageCount: 1,
      activeRewardDaily: undefined,
    };
  }

  getRewardsDaily() {
    return this.getField("rewardsDaily");
  }

  getRewardsDailyObservable() {
    return this.getFieldObservable("rewardsDaily");
  }

  setRewardsDaily(rewardsDaily: RewardDaily[]) {
    this.update((state) => ({ ...state, rewardsDaily }));
  }

  getRewardsDailyPageCountObservable() {
    return this.getFieldObservable("rewardsDailyPageCount");
  }

  setRewardsDailyPageCount(count: number) {
    this.update((state) => ({ ...state, rewardsDailyPageCount: count }));
  }

  setActiveRewardDaily(rewardDaily: RewardDaily) {
    this.update((state) => ({ ...state, activeRewardDaily: rewardDaily }));
  }

  getActiveRewardDailyObservable() {
    return this.getFieldObservable("activeRewardDaily");
  }
}
