<overlay-scrollbars #overlayScrollbarsElement [options]="options" (osScroll)="osScroll($event)">
  <div
    class="items"
    infiniteScroll
    [ngStyle]="{ padding: padding }"
    [scrollWindow]="false"
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [fromRoot]="true"
    [infiniteScrollContainer]="scrollEventElement"
    (scrolled)="onScrolledEvent()"
  >
    <ng-content></ng-content>
  </div>
</overlay-scrollbars>
