import { Component, OnDestroy, OnInit } from "@angular/core";
import { AnalyticsService } from "@metranpage/core";
import { OnboardingService } from "@metranpage/onboarding";
import * as _ from "lodash-es";
import { Subscription } from "rxjs";

@Component({
  selector: "m-image-generation-page",
  templateUrl: "./image-generation.page.html",
  styleUrls: ["./image-generation.page.scss"],
})
export class ImageGenerationPage implements OnDestroy, OnInit {
  onboardingStartedBasic = false;
  onboardingStartedAdvanced = false;

  protected selectedTab = 2;

  sub: Subscription = new Subscription();

  constructor(
    private readonly onboardingService: OnboardingService,
    private readonly analytics: AnalyticsService,
  ) {
    this.sub.add(
      this.onboardingService.onStartOnboarding$.pipe().subscribe(() => {
        this.startOnboarding(true);
      }),
    );
  }

  ngOnInit(): void {
    this.analytics.event("ai-generator");
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onImageGenerationViewInit() {
    this.startOnboarding();
  }

  startOnboarding(showForced = false) {
    if ((!this.onboardingStartedBasic || showForced) && this.selectedTab === 1) {
      this.onboardingStartedBasic = true;
      this.onboardingService.startOnboarding("image-generation-basic", showForced);
    }
    if ((!this.onboardingStartedAdvanced || showForced) && this.selectedTab === 2) {
      this.onboardingStartedAdvanced = true;
      this.onboardingService.startOnboarding("image-generation-advanced", showForced);
    }
  }

  protected onTabSelect(selectedTab: number) {
    this.selectedTab = selectedTab;
    this.startOnboarding();
  }
}
