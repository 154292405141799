import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "m-colors-menu",
  templateUrl: "./colors-menu.view.html",
  styleUrls: ["./colors-menu.view.scss"],
})
export class ColorsMenuView {
  @Input()
  position: "top" | "bottom" = "top";

  @HostBinding("class")
  get cssClassList() {
    const classes: string[] = [];

    classes.push(`position-${this.position}`);

    return classes.join(" ");
  }
}
