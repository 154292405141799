<m-button button-style="primary" (onClick)="onAddAdmin()" i18n="@@superadmin.companies-settings.add-admin">
  superadmin.companies-settings.add-admin
</m-button>

<m-modal *ngIf="isAddAdminModalVisible" (close)="closeAddAdmin()" class="add-admin-modal">
  <form [formGroup]="addAdminForm" class="add-admin-form">
    <p><b>Добавить администратора?</b></p>
    <p>
      <span class="label">Email</span>
      <m-ng-input formControlName="email" size="32" />
    </p>

    <m-button button-style="primary" (onClick)="addAdmin()">Добавить</m-button>
  </form>
</m-modal>

<table>
  <thead>
    <th>ID</th>
    <th>Email</th>
    <th></th>
  </thead>

  <tbody>
    <tr *ngFor="let admin of admins; trackBy: trackByAdminId">
      <td>{{admin.id}}</td>
      <td>{{admin.email}}</td>

      <td>
        <div class="controls"></div>
      </td>
    </tr>
  </tbody>
</table>
