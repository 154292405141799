<div class="select-wrapper">
  <div class="input-container" [class.error]="showErrorText" [ngClass]="getCssClassList()" (click)="onFieldClick()">
    <svg-icon *ngIf="iconLeft" [src]="getLeftIconAsset()!" class="icon icon-left"></svg-icon>

    <span class="value">
      {{ value }}
    </span>

    <div class="arrow-divider" *ngIf="isArrowDividerShow()"></div>

    <m-icon
      class="icon-arrow"
      [ngClass]="getArrowIconClassList()"
      src="dropdown-arrow-down.svg"
      color="--color-font-input"
      [size]="getIconArrowSize()"
    />
  </div>
  <span class="error-label" *ngIf="showErrorText">{{ errorSelectedText }}</span>

  <div class="select-options-dropdown" *ngIf="isMenuOpened" (mouseleave)="onDropdownMouseLeave()">
    <div
      class="select-option"
      *ngFor="let option of options; trackBy: trackByFn"
      (click)="onOptionClick(option.id)"
      (mouseover)="onOptionMouseOver(option.id)"
      [attr.data-value]="option.value"
    >
      <m-icon
        *ngIf="option.icon"
        class="icon-value"
        [src]="option.icon"
        color="--color-font-input"
        [size]="getIconArrowSize()"
      />
      <span>{{ option.value }}</span>
    </div>
  </div>
</div>
