<div
  class="layer-outer-wrapper"
  [class.current]="isSelected"
  [class.invalid]="!validateObjectName(object.name)"
  (click)="onObjectClick()"
  [class.group-item]="isGroupItem"
  [class.group]="groupObject"
>
  <div class="layer-wrapper">
    <div class="cell type">
      <m-ng-icon [size]="24" [src]="objectTypeIconPath"></m-ng-icon>
      <div
        class="object-role"
        *ngIf="user.isAdmin && object.role"
        [ngClass]="object.role.role"
        [class.mapped-by-id]="object.role.isMappedById"
      ></div>
    </div>
    <div
      class="cell name"
      spellcheck="false"
      contenteditable
      [(ngModel)]="object.name"
      (contenteditablechange)="onNameChange()"
      (contenteditableblur)="onNameBlur()"
      (contenteditablefocus)="onNameFocus()"
    ></div>
    <div class="cell visibility" *ngIf="!object.isVisible">
      <m-ng-icon [size]="24" src="/assets/icons/eye-close.svg"></m-ng-icon>
    </div>
    <ng-content select="drag-handle"></ng-content>
    <div class="cell lock" *ngIf="object.isLocked">
      <m-ng-icon [size]="24" src="/assets/icons/locked-01.svg"></m-ng-icon>
    </div>
  </div>
  <div *ngIf="groupObject" class="group-wrapper">
    <m-cover-object-list
      [user]="user"
      [objects]="groupObject.objects"
      [isGroup]="true"
      [currentObject]="undefined"
      [selectedObjects]="[]"
      [listId]="groupObject.groupId!"
      [listIds]="listIds"
    ></m-cover-object-list>
  </div>
</div>
