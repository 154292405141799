import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { fadeInOutOnEnterLeave } from "@metranpage/components";
import { Character } from "../../models/character/character";
import { GeneratedImageService } from "../../services/generated-image.service";

@Component({
  selector: "m-character-card",
  templateUrl: "./character-card.view.html",
  styleUrls: ["./character-card.view.scss"],
  animations: [fadeInOutOnEnterLeave],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CharacterCardView implements OnChanges {
  @Input()
  character?: Character;
  @Input()
  isActive = false;
  @Input()
  isAdded = false;
  @Input()
  isLimitReached = false;

  @Output()
  onAdd = new EventEmitter();
  @Output()
  onSelect = new EventEmitter();
  @Output()
  onEdit = new EventEmitter();
  @Output()
  onCopy = new EventEmitter();
  @Output()
  onDelete = new EventEmitter();

  protected isButtonsVisible = false;

  protected imageUrl?: string;
  protected imageGenerationId?: number;

  constructor(private readonly generatedImageService: GeneratedImageService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.character) {
      this.updateUrlForImage();
    }
  }

  protected onAddClick() {
    this.onAdd.emit();
  }

  protected onSelectClick() {
    this.onSelect.emit();
  }

  protected onEditClick() {
    this.onEdit.emit();
  }

  protected onCopyClick() {
    this.onCopy.emit();
  }

  protected onDeleteClick() {
    this.onDelete.emit();
  }

  protected onMouseEnter() {
    if (this.character?.isProcessing) {
      return;
    }
    this.isButtonsVisible = true;
  }

  protected onMouseLeave() {
    this.isButtonsVisible = false;
  }

  protected convertToString(value: number) {
    return `${value}`;
  }

  protected updateUrlForImage() {
    this.imageGenerationId = this.character?.imageGenerationId;
    this.imageUrl = this.character?.generatedImages[0];
  }

  protected getUrlForImage() {
    if (!this.imageGenerationId || !this.imageUrl) {
      return "";
    }
    return this.generatedImageService.getUrlForImage(this.imageGenerationId, this.imageUrl, true);
  }
}
