import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { EditorBlock } from "../../markup-editor/editor.models";
import {
  BlockMergeEvent,
  BlockMovementEvent,
  BlockSelectEvent,
  BlockSimulatedSelectEvent,
  BlockSplitEvent,
} from "../editor.events";
import { Footnote, MarkupEditorService } from "../editor.service";
import { PaletteDTO } from "./../../../models/editor";
import { StyleDef, StyleKey, StyleSettings, StyleType } from "./../../../models/styles";
import { BlockDelegate } from "./block-delegate";
import { ImageBlockDefaultData } from "./image-block/image-block.component";

export type BlockEditViewType = "text" | "header1";

@Component({
  selector: "m-markup-editor-block",
  templateUrl: "./block.view.html",
  styleUrls: ["./block.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkupBlockView implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  data!: EditorBlock;
  @Input()
  isHeaderBefore = false;
  @Input()
  style!: StyleDef;
  @Input()
  styleDisplayOpts: StyleSettings | null = null;
  @Input()
  isActive = false;
  @Input()
  onPageIndex!: number;
  @Input()
  simulatedBlockSelectEvents!: Observable<BlockSimulatedSelectEvent>;
  @Input()
  selectionChangeEvents!: Observable<Event>;
  @Input()
  palette?: PaletteDTO;
  @Input()
  bookId!: number;
  @Input()
  imageDefaultData?: ImageBlockDefaultData;

  @Output()
  onContentChanged = new EventEmitter<EditorBlock>();
  @Output()
  onBlockSelected = new EventEmitter<BlockSelectEvent>();
  @Output()
  onBlockHovered = new EventEmitter<StyleKey | undefined>();
  @Output()
  onBlockDelete = new EventEmitter();
  @Output()
  onBlockSplit: EventEmitter<BlockSplitEvent> = new EventEmitter();
  @Output()
  onBlockMergeWithPrev: EventEmitter<BlockMergeEvent> = new EventEmitter();
  @Output()
  blockMovement = new EventEmitter<BlockMovementEvent>();
  @Output()
  onBlockWarningDelete = new EventEmitter();

  @ViewChild("blockDelegate")
  blockDelegate: BlockDelegate | undefined = undefined;

  footnotes: Footnote[] = [];

  protected sub = new Subscription();

  constructor(
    readonly elementRef: ElementRef,
    private readonly markupEditorService: MarkupEditorService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.sub.add(
      this.simulatedBlockSelectEvents.subscribe((event: BlockSimulatedSelectEvent) => {
        if (event.blockId === this.data.id) {
          // if we get a simulated select event on this block - then pass all related data and set cursor to this block
          this.onBlockSelected.emit({
            blockId: this.data.id,
            multi: false,
            blockTopPosition: this.elementRef.nativeElement.offsetTop,
            onPageIndex: this.onPageIndex,
          });

          if (this.getBlockEditViewType() === "text" || this.getBlockEditViewType() === "header") {
            if (event.setCursorTo === "start") {
              this.blockDelegate?.setCaretToBegin();
            } else if (event.setCursorTo === "end") {
              this.blockDelegate?.setCaretToEnd();
            }
          }
        }
      }),
    );

    this.sub.add(
      this.selectionChangeEvents.subscribe((e) => {
        if (this.getBlockEditViewType() === "image") {
          return;
        }

        const selection = this.blockDelegate?.getTextSelection();
        if (selection) {
          this.markupEditorService.onChangeSelectionState({
            ...selection,
            onPageIndex: this.onPageIndex,
          });
        }

        this.cdr.markForCheck();
      }),
    );
  }

  applyBold() {
    this.blockDelegate?.applyBold();
  }

  applyItalick() {
    this.blockDelegate?.applyItalick();
  }

  addFootnote() {
    this.blockDelegate?.addFootnote();
  }

  onFootnoteContentChangedHandle(footnote: Footnote) {
    const note = this.footnotes.find((note) => note.id === footnote.id);
    if (note) {
      note.text = footnote.text;
    }

    this.blockDelegate?.updateFootnotesText(this.footnotes);
  }

  removeFootnote(footnote: Footnote) {
    this.blockDelegate?.removeFootnote(footnote.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getBlockEditViewType(): StyleType {
    return this.style.type;
  }

  onSplitBlock(event: BlockSplitEvent) {
    // saturate event with additional block metadata
    const newData = {
      ...event,
      blockId: this.data.id,
      styleKey: this.style.styleKey,
    };
    this.onBlockSplit.emit(newData);

    this.cdr.markForCheck();
  }

  onMergeBlocks(event: BlockMergeEvent) {
    // saturate event with additional block metadata
    const newData = {
      ...event,
      blockId: this.data.id,
      styleKey: this.style.styleKey,
    };
    this.onBlockMergeWithPrev.emit(newData);

    this.cdr.markForCheck();
  }

  onBlockMovement(event: BlockMovementEvent) {
    this.blockMovement.emit({
      ...event,
      blockId: this.data.id,
    });
  }

  appendTextAndFocus(text: string) {
    this.blockDelegate?.appendTextAndFocus(text);
  }

  onContentChangedHandle(event: any) {
    const newData = {
      ...this.data,
      data: event,
    };
    this.onContentChanged.emit(newData);

    this.cdr.markForCheck();
  }

  onMouseDownHandle(event: any) {
    this.onBlockSelected.emit({
      blockId: this.data.id,
      multi: event.ctrlKey,
      blockTopPosition: this.elementRef.nativeElement.offsetTop,
      onPageIndex: this.onPageIndex,
    });
  }

  onMouseEnterHandle(event: any) {
    this.onBlockHovered.emit(this.style.styleKey);
  }

  onMouseLeaveHandle(event: any) {
    this.onBlockHovered.emit(undefined);
  }

  onFootnoteAdded(footnotes: Footnote[]) {
    this.footnotes = footnotes;
  }

  onFootnoteChanged(footnotes: Footnote[]) {
    this.footnotes = footnotes;
  }

  isInViewport() {
    const rect = this.elementRef.nativeElement.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - 100 &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  removeWarning(warningIndex: number) {
    if (!this.data.warnings) {
      return;
    }

    this.data.warnings.splice(
      this.data.warnings.findIndex((item) => item.id === warningIndex),
      1,
    );

    const newData = {
      ...this.data,
    };
    this.onContentChanged.emit(newData);
    this.onBlockWarningDelete.emit();

    this.cdr.markForCheck();
  }
}
