import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, input, output } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { ObjectPosition } from "@metranpage/book-data";
import { InputNumberWithIconComponent } from "@metranpage/design-system";
import { filter, map, tap } from "rxjs";

@Component({
  selector: "m-cover-position-object-settings",
  templateUrl: "./cover-object-position.component.html",
  styleUrl: "./cover-object-position.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [InputNumberWithIconComponent, FormsModule, ReactiveFormsModule],
})
export class CoverObjectPositionComponent implements OnInit {
  form!: FormGroup;
  objectPosition = input<ObjectPosition, ObjectPosition>(
    {
      x: 0,
      y: 0,
      rotationAngle: 0,
      scale: 100,
    },
    {
      transform: (value: ObjectPosition) => {
        this.updateFormValue(value);
        return value;
      },
    },
  );
  updatePosition = output<ObjectPosition>();
  skipEmit = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      x: [this.objectPosition().x],
      y: [this.objectPosition().y],
      rotationAngle: [this.objectPosition().rotationAngle],
      scale: [this.objectPosition().scale, [Validators.min(1)]],
    });

    this.form.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((_) => !this.skipEmit),
        map((formValue) => this.changedValues(formValue)),
        tap((updatedValues) => {
          if (updatedValues && this.form.valid) {
            this.updatePosition.emit(updatedValues);
          }
        }),
      )
      .subscribe();
  }

  private updateFormValue(objectProperty: ObjectPosition) {
    if (this.form) {
      this.skipEmit = true;
      Object.keys(objectProperty).map((property) => {
        if (this.form.controls[property]) {
          this.form.controls[property].setValue(objectProperty[property as keyof ObjectPosition]);
        }
      });
      this.skipEmit = false;
    }
  }

  private changedValues(formValue: ObjectPosition): ObjectPosition | null {
    const updatedPosition = {} as ObjectPosition;
    const currentPosition = this.objectPosition();

    Object.keys(formValue).map((param) => {
      if (
        formValue[param as keyof ObjectPosition] !== currentPosition[param as keyof ObjectPosition] &&
        !Number.isNaN(formValue[param as keyof ObjectPosition])
      ) {
        updatedPosition[param as keyof ObjectPosition] = formValue[param as keyof ObjectPosition];
      }
    });
    return Object.keys(updatedPosition).length ? updatedPosition : null;
  }
}
