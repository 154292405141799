import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { RewardDailyCreateData } from "../../../models/reward-daily";
import { AdminRewardsDailyService } from "../../../services/rewards/rewards-daily/rewards-daily.service";

@Component({
  selector: "m-admin-add-reward-daily-modal",
  templateUrl: "./add-reward-daily.view.html",
  styleUrls: ["./add-reward-daily.view.scss"],
})
export class AddRewardDailyView implements OnInit {
  @Output()
  onRewardDailyAdded = new EventEmitter<RewardDailyCreateData>();
  @Output()
  onCancelAddition = new EventEmitter<void>();

  protected form!: FormGroup;

  constructor(private readonly adminRewardsDailyService: AdminRewardsDailyService) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.adminRewardsDailyService.createRewardDailyForm();
  }

  createRewardDaily() {
    this.onRewardDailyAdded.emit(this.form.getRawValue());
  }

  onCancel() {
    this.onCancelAddition.emit();
  }

  protected getTypeSelectOptions() {
    return this.adminRewardsDailyService.getAvailableRewardsDailyTypeSelectOptions();
  }
}
