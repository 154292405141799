export type BookSize = {
  width: number;
  height: number;
};

export type ScriptsParams = {
  genres: {
    [key: string]: {
      moods: {
        [key: string]: {
          makeStyles: MakeStylesParams[];
          masterPages: MasterPagesParams[];
        };
      };
    };
  };
};

export type MakeStylesParams = {
  id: number;
  genType: number;
  mainFont: string;
  headFont: string;
  mainSize: number;
  headProp: number;
  contrasts: string;
  mainVar: number[];
  mainIndentsVar: number[];
  headVar1: number[];
  headVar2: number[];
  headVar3: number[];
  headVar4: number[];
  preh1Var: number[];
  primVar: number[];
  leadVar: number[];
  quoteVar: number[];
  accVar: number[];
  ctVar: number[];
  cnVar: number[];
  phsVar: number[];
  indexHVar: number[];
  indexH1Var: number[];
  indexH2Var: number[];
  indexH3Var: number[];
  indexNH1Var: number[];
  indexNH2Var: number[];
  indexNH3Var: number[];
};

export type SelectedMakeStylesParams = {
  id: number;
  mainFont: string;
  headFont: string;
  mainSize: number;
  headProp: number;
  contrasts: string;
  mainVar: number;
  mainIndentsVar: number;
  headVar1: number;
  headVar2: number;
  headVar3: number;
  headVar4: number;
  preh1Var: number;
  primVar: number;
  leadVar: number;
  quoteVar: number;
  accVar: number;
  ctVar: number;
  cnVar: number;
  phsVar: number;
  indexHVar: number;
  indexH1Var: number;
  indexH2Var: number;
  indexH3Var: number;
  indexNH1Var: number;
  indexNH2Var: number;
  indexNH3Var: number;
};

export type MasterPagesParams = {
  id: number;
  format: string;
  mainFont: string;
  mainSize: number;
  genType: number;
  maketType: number;
};

export const defaultMakeStylesParams: SelectedMakeStylesParams = {
  id: -1,
  // layoutType: 'books',
  mainFont: "Pt Serif",
  headFont: "Pt Serif",
  mainSize: 10,
  headProp: 1,
  contrasts: "",
  mainVar: 2,
  mainIndentsVar: 2,
  headVar1: 2,
  headVar2: 1,
  headVar3: 1,
  headVar4: 1,
  preh1Var: 1,
  primVar: 1,
  leadVar: 4,
  quoteVar: 4,
  accVar: 1,
  ctVar: 1,
  cnVar: 1,
  phsVar: 1,
  indexHVar: 1,
  indexH1Var: 1,
  indexH2Var: 1,
  indexH3Var: 1,
  indexNH1Var: 1,
  indexNH2Var: 1,
  indexNH3Var: 1,
};

export class ScriptsSettings {
  settings: ScriptsParams;

  constructor(settings: ScriptsParams) {
    this.settings = settings;
  }

  hasGenres(): boolean {
    return Object.keys(this.settings.genres).length > 1;
  }

  getGenreMoodValues() {
    const genres = Object.keys(this.settings.genres);
    const genreMoodValues: any = {};
    for (const genre of genres) {
      genreMoodValues[genre] = Object.keys(this.settings.genres[genre].moods);
    }

    return genreMoodValues;
  }

  getMakeStylesSettings(genre: string, mood: string) {
    return this.settings.genres[genre].moods[mood].makeStyles;
  }

  getMasterPagesSettings(genre: string, mood: string) {
    return this.settings.genres[genre].moods[mood].masterPages;
  }

  getDefaultGenre() {
    return Object.keys(this.settings.genres)[0];
  }

  getDefaultMood() {
    const defaultGenre = this.getDefaultGenre();
    return Object.keys(this.settings.genres[defaultGenre].moods)[0];
  }

  getFirstMood(genre: string) {
    return Object.keys(this.settings.genres[genre].moods)[0];
  }

  selectMakeStyles(
    genre: string,
    mood: string,
    mainSize: number,
    headProp: number | undefined,
    fonts: { mainFont: string; headFont: string } | undefined,
  ): SelectedMakeStylesParams {
    const msItems = this.getMakeStylesSettings(genre, mood);

    msItems?.filter((item) => {
      if (item.mainSize !== mainSize) {
        return false;
      }

      if (fonts && item.headFont !== fonts.headFont) {
        return false;
      }

      // const matchProp =
      //       headProp !== undefined && row.contrasts
      //         ? (headProp > 5 && row.contrasts.startsWith('после')) ||
      //           (headProp <= 5 && row.contrasts.startsWith('до'))
      //         : true;

      return true;
    });

    if (!msItems) {
      throw new Error($localize`:@@books.build.recommended-settings-not-found-make-styles-error:`);
    }

    function select(makeStyles: MakeStylesParams): SelectedMakeStylesParams {
      return {
        id: makeStyles.id,
        mainFont: makeStyles.mainFont,
        headFont: makeStyles.headFont,
        mainSize: makeStyles.mainSize,
        headProp: makeStyles.headProp,
        contrasts: makeStyles.contrasts,
        mainVar: makeStyles.mainVar[0],
        mainIndentsVar: makeStyles.mainIndentsVar[0],
        headVar1: makeStyles.headVar1[0],
        headVar2: makeStyles.headVar2[0],
        headVar3: makeStyles.headVar3[0],
        headVar4: makeStyles.headVar4[0],
        preh1Var: makeStyles.preh1Var[0],
        primVar: makeStyles.primVar[0],
        leadVar: makeStyles.leadVar[0],
        quoteVar: makeStyles.quoteVar[0],
        accVar: makeStyles.accVar[0],
        ctVar: makeStyles.ctVar[0],
        cnVar: makeStyles.cnVar[0],
        phsVar: makeStyles.phsVar[0],
        indexHVar: makeStyles.indexHVar[0],
        indexH1Var: makeStyles.indexH1Var[0],
        indexH2Var: makeStyles.indexH2Var[0],
        indexH3Var: makeStyles.indexH3Var[0],
        indexNH1Var: makeStyles.indexNH1Var[0],
        indexNH2Var: makeStyles.indexNH2Var[0],
        indexNH3Var: makeStyles.indexNH3Var[0],
      };
    }

    return select(msItems[0]);
  }
}
