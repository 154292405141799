import { CommonModule, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, SecurityContext, ViewEncapsulation } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "m-localized-text",
  template: `
    <span [innerHTML]="html"></span>
  `,
  styles: [
    `
    :host ::ng-deep {
      a {
        text-decoration: underline;
      }
    }
  `,
  ],
  // encapsulation: ViewEncapsulation.ShadowDom,
  standalone: true,
  imports: [CommonModule, NgClass, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocalizedTextComponent {
  @Input()
  text? = "";

  protected html?: string;

  constructor(private readonly domSanitizer: DomSanitizer, private readonly cdr: ChangeDetectorRef) { }

  ngOnChanges() {
    this.html = this.getHtml();
    this.cdr.detectChanges();
  }

  getHtml() {
    if (!this.text) return "";
    const html = this.domSanitizer.sanitize(SecurityContext.HTML, this.text);
    if (!html) {
      return "";
    }
    return html;
  }
}
