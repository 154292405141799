<div class="wrapper">
  <div *ngIf="onlyTextObjects" [formGroup]="formGroup">
    <div class="section">
      <div class="rowset">
        <div class="caption" i18n="@@cover-editor.object.settings.text.font-family">
          cover-editor.object.settings.text.font-family
        </div>
        <m-font-select
          *ngIf="fontOptions$ | async as fontOptions; else fontsLoading"
          formControlName="fontFamily"
          size="s"
          [options]="fontOptions"
          [hideArrowDivider]="true"
          (optionMouseOver)="onPreviewFontFamily($event.toString())"
          (dropdownMouseLeave)="onResetFontFamily()"
        ></m-font-select>
        <ng-template #fontsLoading>
          <div i18n="@@cover-editor.fonts.fonts-loading">loading ...</div>
        </ng-template>
        <div class="row equal-size">
          <div></div>
          <m-ng-input-number-with-icon
            iconLeft="font-size-01.svg"
            formControlName="fontSize"
            size="40"
            [iconSize]="28"
            text-align="right"
          />
        </div>
        <div class="row equal-size">
          <m-ng-input-number-with-icon
            iconLeft="line-height-01.svg"
            formControlName="lineHeight"
            size="40"
            [iconSize]="28"
            text-align="right"
          />
          <m-ng-input-number-with-icon
            iconLeft="letter-spacing-01.svg"
            formControlName="letterSpacing"
            size="40"
            [iconSize]="28"
            text-align="right"
          />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="row control-line">
        <div class="caption" i18n="@@cover-editor.object.settings.text.style">
          cover-editor.object.settings.text.style
        </div>
        <div class="button-set">
          <m-ng-icon
            [size]="24"
            (click)="toggleParam('bold')"
            src="/assets/icons/bold-01.svg"
            [class.active]="formGroup.controls['bold'].value"
          ></m-ng-icon>
          <m-ng-icon
            [size]="24"
            (click)="toggleParam('italic')"
            src="/assets/icons/italic-01.svg"
            [class.active]="formGroup.controls['italic'].value"
          ></m-ng-icon>
          <m-ng-icon
            [size]="24"
            (click)="toggleParam('underline')"
            src="/assets/icons/underline-01.svg"
            [class.active]="formGroup.controls['underline'].value"
          ></m-ng-icon>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="row control-line">
        <div class="caption" i18n="@@cover-editor.object.settings.text.align">
          cover-editor.object.settings.text.align
        </div>
        <div class="button-set">
          <m-ng-icon
            [size]="24"
            (click)="toggleParam('textAlign', 'left')"
            src="/assets/icons/align-left-01.svg"
            [class.active]="formGroup.controls['textAlign'].value === 'left'"
          ></m-ng-icon>
          <m-ng-icon
            [size]="24"
            (click)="toggleParam('textAlign', 'center')"
            src="/assets/icons/align-center-01.svg"
            [class.active]="formGroup.controls['textAlign'].value === 'center'"
          ></m-ng-icon>
          <m-ng-icon
            [size]="24"
            (click)="toggleParam('textAlign', 'right')"
            src="/assets/icons/align-right-01.svg"
            [class.active]="formGroup.controls['textAlign'].value === 'right'"
          ></m-ng-icon>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="row control-line">
        <div class="caption" i18n="@@books.markup.style-details.case-title">books.markup.style-details.case-title</div>
        <div class="button-set">
          <m-ng-icon
            [size]="24"
            (click)="toggleParam('textCase', textCase.Auto)"
            src="/assets/icons/cover-minus.svg"
            [class.active]="formGroup.controls['textCase'].value === textCase.Auto"
          ></m-ng-icon>
          <m-ng-icon
            [size]="24"
            (click)="toggleParam('textCase', textCase.Upper)"
            src="/assets/icons/cover-upper-case.svg"
            [class.active]="formGroup.controls['textCase'].value === textCase.Upper"
          ></m-ng-icon>
          <m-ng-icon
            [size]="24"
            (click)="toggleParam('textCase', textCase.Lower)"
            src="/assets/icons/cover-lower-case.svg"
            [class.active]="formGroup.controls['textCase'].value === textCase.Lower"
          ></m-ng-icon>
        </div>
      </div>
    </div>

    <div class="section shadow">
      <div class="row space-between">
        <span class="caption" i18n="@@cover.shadow">cover.shadow</span>
        <m-switch
          [isInteractive]="identicalShadowParams"
          [isChecked]="withShadow"
          [withLabel]="false"
          class="elements-group"
          (onToggle)="toggleShadow()"
        ></m-switch>
      </div>
      <ng-container *ngIf="withShadow">
        <div class="shadow-contols" formGroupName="shadow">
          <div class="row">
            <m-slider-with-indication
              title="cover.offset"
              i18n-title="@@cover.offset"
              formControlName="offset"
              name="offset"
            ></m-slider-with-indication>
          </div>
          <div class="row">
            <m-slider-with-indication
              formControlName="direction"
              title="cover.direction"
              i18n-title="@@cover.direction"
              [max]="360"
            ></m-slider-with-indication>
          </div>
          <div class="row">
            <m-slider-with-indication
              formControlName="blur"
              title="cover.blur"
              i18n-title="@@cover.blur"
            ></m-slider-with-indication>
          </div>
          <div class="row">
            <m-slider-with-indication
              formControlName="transparency"
              title="cover.transparency"
              i18n-title="@@cover.transparency"
            ></m-slider-with-indication>
          </div>
          <div class="shadow-color">
            <div class="caption" i18n="@@cover.shadow-color">cover.shadow-color</div>
            <div class="row">
              <m-cover-color-select
                [color]="color"
                [useColorPickerExternalHost]="false"
                (update)="updateShadowColorCtrl($event)"
                [showOpacity]="false"
              ></m-cover-color-select>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="section slim">
    <m-cover-multicolor-settings [objects]="objects" (update)="onObjectsUpdate($event)" />
  </div>
  <div class="section">
    <m-button
      button-style="secondary"
      size="full-width"
      icon-position="left"
      icon="group-01.svg"
      (click)="onGroupObjects()"
      i18n="@@cover-editor.object.settings.multiselect.button.group"
    >
      cover-editor.object.settings.multiselect.button.group
    </m-button>
  </div>
</div>
