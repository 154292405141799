import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NewProjectService } from "@metranpage/book";
import { slideInOutVertical } from "@metranpage/components";
import { AnalyticsService, NotificationsPopUpService } from "@metranpage/core";
import { UserService } from "@metranpage/user";
import { UserStore } from "@metranpage/user-data";
import { DateTime } from "luxon";
import { HomeService } from "../../services/home.service";
import { PromoMenuButtonsService } from "./promo-menu-buttons.service";

export type PromoButtonAction =
  | "create-book"
  | "image-generation"
  | "create-cover"
  | "upgrade-image"
  | "get-tokens"
  | "participate-webinar";
export type PromoButton = {
  id: number;
  iconScr: string;
  title: string;
  action: PromoButtonAction;
  hasSoonMark?: boolean;
};

@Component({
  selector: "m-promo-menu-buttons",
  templateUrl: "./promo-menu-buttons.view.html",
  styleUrls: ["./promo-menu-buttons.view.scss"],
  animations: [slideInOutVertical],
})
export class PromoMenuButtonsView implements OnInit {
  constructor(
    private readonly homeService: HomeService,
    private readonly router: Router,
    private readonly notificationService: NotificationsPopUpService,
    private readonly userStore: UserStore,
    private readonly userService: UserService,
    private readonly analytics: AnalyticsService,
    private readonly promoMenuButtonsService: PromoMenuButtonsService,
    readonly newProjectService: NewProjectService,
  ) {}

  protected buttons: PromoButton[] = [
    {
      id: 6,
      iconScr: "/assets/img/promo-image-6.png",
      title: $localize`:@@home.promo-menu-buttons.button.participate-webinar:`,
      action: "participate-webinar",
    },
    {
      id: 1,
      iconScr: "/assets/img/promo-image-1.png",
      title: $localize`:@@home.promo-menu-buttons.button.create-book:`,
      action: "create-book",
    },
    {
      id: 2,
      iconScr: "/assets/img/promo-image-2.png",
      title: $localize`:@@home.promo-menu-buttons.button.create-image-generation:`,
      action: "image-generation",
    },
    {
      id: 3,
      iconScr: "/assets/img/promo-image-3.png",
      title: $localize`:@@home.promo-menu-buttons.button.create-cover:`,
      action: "create-cover",
    },
    {
      id: 4,
      iconScr: "/assets/img/promo-image-4.png",
      title: $localize`:@@home.promo-menu-buttons.button.upgrade-image:`,
      action: "upgrade-image",
      hasSoonMark: true,
    },
    {
      id: 5,
      iconScr: "/assets/img/promo-image-5.png",
      title: $localize`:@@home.promo-menu-buttons.button.get-tokens:`,
      action: "get-tokens",
      hasSoonMark: false,
    },
  ];

  async ngOnInit() {
    await this.checkWebinarAction();
  }

  updateButtons() {}

  async checkWebinarAction() {
    const actionName = "webinar-1-view";
    const userAction = await this.userService.getUserActionByName(actionName);
    if (!userAction) {
      return;
    }

    const isWebinarEnded = this.checkWebinarDate(userAction.createdAt);
    if (isWebinarEnded) {
      this.buttons = this.buttons.filter((i) => i.action !== "participate-webinar");
    }
  }

  private checkWebinarDate(createdAt: Date) {
    let isWebinarEnded = false;

    const utcTime = DateTime.fromISO(createdAt.toString()).toUTC();
    const utcTimeNow = DateTime.now().toUTC();

    let webinarDate = DateTime.fromISO(createdAt.toString()).toUTC();
    if (utcTime.hour < 17) {
      webinarDate = webinarDate.set({ hour: 17, minute: 0 });
    } else {
      webinarDate = webinarDate.plus({ day: 1 });
      webinarDate = webinarDate.set({ hour: 17, minute: 0 });
    }

    const diff = webinarDate.diff(utcTimeNow, "minutes");
    if (diff.toMillis() < 0) {
      isWebinarEnded = true;
    }

    return isWebinarEnded;
  }

  trackByButtonId(index: number, button: PromoButton) {
    return button.id;
  }

  protected onClick(action: PromoButtonAction) {
    if (!this.userStore.getUser()) {
      this.homeService.showLoginModal();
      return;
    }

    if (action === "create-book") {
      this.analytics.event("promo-create-book");
      this.newProjectService.showNewProjectModal({ projectType: "book" });
      return;
    }
    if (action === "image-generation") {
      this.analytics.event("promo-ai-generate");
      this.router.navigate(["image-generation"]);
      return;
    }
    if (action === "create-cover") {
      this.analytics.event("promo-concept");
      this.newProjectService.showNewProjectModal({ projectType: "cover" });
      return;
    }
    if (action === "upgrade-image") {
      this.analytics.event("promo-upgrade-image");
      this.notificationService.notify({
        content: $localize`:@@soon-text-notification:`,
        type: "success",
        showOptsTimeout: 2,
      });
      return;
    }
    if (action === "get-tokens") {
      this.analytics.event("promo-get-tokens");
      this.router.navigate(["account", "rewards"]);
      return;
    }
    if (action === "participate-webinar") {
      this.promoMenuButtonsService.onWebinarModalVisibilityChange(true);
      return;
    }
  }
}
