<m-ng-island [paddingSize]="24">
  <form [formGroup]="form" class="form" (ngSubmit)="onSubmit()">
    <div class="control">
      <span class="label" i18n="@@admin.company.currency">admin.company.currency</span>
      <m-select
        class="select"
        input-style="normal"
        size="s"
        [options]="getCompanyCurrencyOptions()"
        formControlName="currency"
      ></m-select>
    </div>

    <div class="divider-hor"></div>

    <m-switch formControlName="isCloudPaymentsEnable" i18n="@@admin.cloudpayments.enable">
      admin.cloudpayments.enable
    </m-switch>

    <div formGroupName="cloudpayments" class="form-group">
      <m-localized-text text="admin.cloudpayments.description" i18n-text="@@admin.cloudpayments.description" />

      <div>
        <span i18n="@@admin.cloudpayments.public-id">admin.cloudpayments.public-id</span>
        <m-ng-input formControlName="publicId" />
      </div>
      <div>
        <span i18n="@@admin.cloudpayments.private-key">admin.cloudpayments.private-key</span>
        <m-ng-input formControlName="privateKey" />
      </div>

      <!-- <m-button button-style="secondary" (onClick)="onDeleteCloudPayments()" i18n="@@admin.common.delete">
        admin.common.delete
      </m-button> -->
    </div>

    <div class="divider-hor"></div>

    <m-switch formControlName="isStripeEnable" i18n="@@admin.stripe.enable">admin.stripe.enable</m-switch>

    <div formGroupName="stripe" class="form-group">
      <m-localized-text text="admin.stripe.description" i18n-text="@@admin.stripe.description" />

      <div>
        <span i18n="@@admin.stripe.public-key">admin.stripe.public-key</span>
        <m-ng-input formControlName="publicKey" />
      </div>
      <div>
        <span i18n="@@admin.stripe.secret-key">admin.stripe.secret-key</span>
        <m-ng-input formControlName="secretKey" />
      </div>
      <div>
        <span i18n="@@admin.stripe.webhook-secret">admin.stripe.webhook-secret</span>
        <m-ng-input formControlName="webhookSecret" />
      </div>

      <!-- <m-button button-style="secondary" (onClick)="onDeleteStripe()" i18n="@@admin.common.delete">
        admin.common.delete
      </m-button> -->
    </div>
  </form>

  <div class="divider-hor"></div>

  <div class="row">
    <m-button button-style="primary" (onClick)="onSubmit()" [is-disabled]="!form.valid" i18n="@@admin.common.save">
      admin.common.save
    </m-button>
  </div>
</m-ng-island>
