<m-side-panel class="sidebar" sidebar-position="right" sidebar-type="fixed">
  <ng-container>
    <div class="side-panel-right-context">
      <span class="text-block" i18n="@@books.preview.processing-steps">books.preview.processing-steps</span>
      <span class="text-block" i18n="@@books.preview.processing-steps-2">books.preview.processing-steps-2</span>

      <div class="separator-block"></div>

      <m-layout-steps [layoutState]="getLayoutState()"></m-layout-steps>
    </div>

    <div class="buttons">
      <m-button
        *ngIf="!isFinalsReady"
        button-style="primary"
        i18n="@@books.book.create-button"
        (onClick)="onNewBookClick.emit()"
      >
        books.book.create-button
      </m-button>
      <m-button
        *ngIf="isFinalsReady && book?.exportPrint"
        button-style="primary"
        i18n="@@books.book.download-project-button"
        (onClick)="onDownloadProjectClick.emit()"
      >
        books.book.download-project-button
      </m-button>
      <m-button
        *ngIf="isFinalsReady && !book?.exportPrint && book?.exportEpub"
        button-style="primary"
        i18n="@@books.preview.download-epub"
        (onClick)="onDownloadEpubClick.emit()"
      >
        books.preview.download-epub
      </m-button>
    </div>
  </ng-container>
</m-side-panel>
