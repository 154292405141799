import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { ImageGenerationAdvancedStyle, ImageGenerationBasicStyle } from "@metranpage/image-generation";

@Component({
  selector: "m-cover-conceptual-assistant-generation-result-style-card",
  templateUrl: "./cover-conceptual-assistant-generation-result-style-card.view.html",
  styleUrls: ["./cover-conceptual-assistant-generation-result-style-card.view.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverConceptualAssistantGenerationResultStyleCardView {
  @Input()
  style!: ImageGenerationBasicStyle | ImageGenerationAdvancedStyle;
  @Input()
  isActive = false;

  @HostBinding("class.active") get active() {
    return this.isActive;
  }
}
