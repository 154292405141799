import { Injectable } from "@angular/core";
import { RealtimeService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { CoverConceptualGenerationDataDto } from "../models/cover-conceptual-generation";
import { CoverConceptualGenerationResultUpdate } from "../models/cover-conceptual-generation-update";
import { TextGenerationDataDto } from "../models/text-generation";
import { CoverConceptualGenerationStore } from "./cover-conceptual-generation.store";
import { TextGenerationApi } from "./text-generation.api";

@Injectable({
  providedIn: "root",
})
export class TextGenerationService {
  constructor(
    private readonly textGenerationApi: TextGenerationApi,
    private readonly coverConceptualGenerationStore: CoverConceptualGenerationStore,
    private readonly notificationService: NotificationsPopUpService,
    realtimeService: RealtimeService,
  ) {
    realtimeService
      .getEvents<CoverConceptualGenerationResultUpdate>("cover-conceptual-generation-result")
      .subscribe((coverConceptualGenerationUpdate: CoverConceptualGenerationResultUpdate | undefined) => {
        if (coverConceptualGenerationUpdate) {
          const newCoverConceptualGeneration = coverConceptualGenerationUpdate.coverConceptualGeneration;

          this.coverConceptualGenerationStore.updateCoverConceptualGeneration(newCoverConceptualGeneration);

          if (coverConceptualGenerationUpdate.isError) {
            this.notifyOnTextGenerationError();
          }
        }
      });
  }

  async upgradeTextGeneration(data: TextGenerationDataDto) {
    return await this.textGenerationApi.upgradeTextGeneration(data);
  }

  async loadLastCoverConceptualGeneration(coverId: number) {
    const lastCoverConceptualGeneration = await this.textGenerationApi.loadLastCoverConceptualGeneration(coverId);
    this.coverConceptualGenerationStore.setCoverConceptualGeneration(coverId, lastCoverConceptualGeneration);
  }

  async coverConceptualGeneration(data: CoverConceptualGenerationDataDto) {
    return await this.textGenerationApi.coverConceptualGeneration(data);
  }

  notifyOnTextGenerationError() {
    this.notificationService.error($localize`:@@cover-editor.conceptual-assistant.result.generation-error:`);
  }
}
