import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NotificationsPopUpService } from "./notifications-pop-up.service";

export enum Errors {
  NetworkError = -2,
  ServerError = -1,
  WrongCredentials = 1,
  DifferentLoginMethod = 2,
  UserNotExist = 3,
  EmailMustBeUnique = 4,
  EmailNotFound = 5,
  CannotRestorePassForSocialLogin = 6,
  RestoreTokenNotFound = 7,
  VeriftTokenExpired = 8,
  VerifyTokenNotCorrect = 9,
  InsufficientFunds = 10,
  PasswordNotCorrect = 25,
  PasswordIsEqualToCurrent = 26,
  FreeTariffNotFound = 27,
  PromocodeMustBeUnique = 28,
  ReferalLinkMustBeUnique = 29,
  TemplateNotFound = 30,
  WrongSuperadminCredentials = 31,
  ActiveTariffIsHigher = 32,
  ActiveSubscriptionHasNextTariff = 33,
  EpubFixerServiceError = 34,
  FontMustBeUnique = 35,
  NonEditableTemplateMustBeUnique = 36,
  NonEditableTemplateTemplateFileNotFound = 37,
  NonEditableTemplateSettingsFileNotFound = 38,
  ImageGenerationNotFound = 40,
  EditionNoticeSettingsNotFound = 41,
  TemplateNotSuitableForUploadedDocument = 43,
  TelegramProfileNotFound = 45,
  CharacterNameMustBeUnique = 52,
  ImageGenerationUserStyleNoImageFile = 53,
}

export interface ErrorBody {
  code: number;
  message?: string;
}

@Injectable({
  providedIn: "root",
})
export class ApiErrorHandlerService {
  constructor(private readonly notificationsService: NotificationsPopUpService) {}

  handleApiError(errorResponse: any, showNotification = true): Errors {
    const error = this.parseApiError(errorResponse);
    if (showNotification) {
      this.showNotificationForError(error);
    }
    return error;
  }

  private showNotificationForError(error: Errors) {
    let errorText = $localize`:@@user.auth.generic-error:`;

    if (error === Errors.ServerError || error === Errors.NetworkError) {
      errorText = $localize`:@@user.auth.generic-error:`;
    }
    // auth / user errors
    else if (error === Errors.WrongCredentials) {
      errorText = $localize`:@@user.auth.wrong-creds-error:`;
    } else if (error === Errors.DifferentLoginMethod) {
      errorText = $localize`:@@user.auth.wrong-login-method-error:`;
    } else if (error === Errors.UserNotExist) {
      errorText = $localize`:@@user.verify-email-user-not-found-error:`;
    } else if (error === Errors.EmailMustBeUnique) {
      errorText = $localize`:@@user.auth.email-not-unique-error:`;
    } else if (error === Errors.EmailNotFound) {
      errorText = $localize`:@@user.auth.restore-pass-email-not-found-error:`;
    } else if (error === Errors.CannotRestorePassForSocialLogin) {
      errorText = $localize`:@@user.auth.restore-cant-restore-for-social-error:`;
    } else if (error === Errors.RestoreTokenNotFound) {
      errorText = $localize`:@@user.auth.restore-pass-token-not-found-error:`;
    } else if (error === Errors.VeriftTokenExpired) {
      errorText = $localize`:@@user.verify-email-token-not-expired-error:`;
    } else if (error === Errors.VerifyTokenNotCorrect) {
      errorText = $localize`:@@user.verify-email-token-not-correct-error:`;
    } else if (error === Errors.InsufficientFunds) {
      errorText = $localize`:@@payment.errors.insufficient-funds:`;
    } else if (error === Errors.PasswordNotCorrect) {
      errorText = $localize`:@@user.profile.change-password-not-correct-error:`;
    } else if (error === Errors.PasswordIsEqualToCurrent) {
      errorText = $localize`:@@user.profile.change-password-is-equal-to-current-error:`;
    } else if (error === Errors.PromocodeMustBeUnique) {
      errorText = $localize`:@@admin.promocodes.promocode.error.promocode-token-must-be-unique:`;
    } else if (error === Errors.ReferalLinkMustBeUnique) {
      errorText = $localize`:@@admin.referal-links.referal-link.error.referal-link-token-must-be-unique:`;
    } else if (error === Errors.ActiveTariffIsHigher) {
      errorText = $localize`:@@admin.users.add-subscription.error.active-tariff-is-higher:`;
    } else if (error === Errors.ActiveSubscriptionHasNextTariff) {
      errorText = $localize`:@@admin.users.add-subscription.error.active-subscription-has-next-tariff:`;
    } else if (error === Errors.EpubFixerServiceError) {
      errorText = $localize`:@@books.epub.layout.error.epub-fixer-service-error:`;
    } else if (error === Errors.FontMustBeUnique) {
      errorText = $localize`:@@admin.fonts.font.error.font-name-must-be-unique:`;
    } else if (error === Errors.NonEditableTemplateMustBeUnique) {
      errorText = $localize`:@@admin.non-editable-templates.non-editable-template.error.title-must-be-unique:`;
    } else if (error === Errors.NonEditableTemplateTemplateFileNotFound) {
      errorText = $localize`:@@admin.non-editable-templates.non-editable-template.error.template-file-not-found:`;
    } else if (error === Errors.NonEditableTemplateSettingsFileNotFound) {
      errorText = $localize`:@@admin.non-editable-templates.non-editable-template.error.settings-file-not-found:`;
    } else if (error === Errors.ImageGenerationNotFound) {
      errorText = $localize`:@@image-generation.generation.error.image-generation-not-found:`;
    } else if (error === Errors.EditionNoticeSettingsNotFound) {
      errorText = $localize`:@@admin.companies.company.edition-notice-settings-not-found:`;
    } else if (error === Errors.TemplateNotSuitableForUploadedDocument) {
      errorText = $localize`:@@books.template.select-template.template-not-suitable-for-uploaded-document:`;
    } else if (error === Errors.TelegramProfileNotFound) {
      errorText = $localize`:@@user.profile.socials.telegram-profile-not-found-error:`;
    } else if (error === Errors.CharacterNameMustBeUnique) {
      errorText = $localize`:@@characters.generation.error.name-must-be-unique-error:`;
    } else if (error === Errors.ImageGenerationUserStyleNoImageFile) {
      errorText = $localize`:@@image-generation.style-reference.upload.no-image-file.error:`;
    }

    this.notificationsService.error(errorText);
  }

  private parseApiError(error: any): Errors {
    if (this.isApiError(error)) {
      const response: HttpErrorResponse = error as HttpErrorResponse;
      if (response.status === 0) {
        return Errors.NetworkError;
      }
      // if (response.status == 404) {
      //   return Errors.ServerError;
      // }
      const responseBody = response.error;
      const responseBodyError = responseBody.error;
      const code = Number.parseInt(responseBodyError.code);
      return code;
    }
    return Errors.ServerError;
  }

  private isApiError(error: any) {
    return error.status && error.error;
  }
}
