import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { NonEditableTemplate } from "../../models/non-editable-template";

export type State = {
  nonEditableTemplates: NonEditableTemplate[];
  nonEditableTemplatesPageCount: number;
  activeNonEditableTemplates: NonEditableTemplate | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminNonEditableTemplatesStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      nonEditableTemplates: [],
      nonEditableTemplatesPageCount: 1,
      activeNonEditableTemplates: undefined,
    };
  }

  getNonEditableTemplatesObservable() {
    return this.getFieldObservable("nonEditableTemplates");
  }

  setNonEditableTemplates(nonEditableTemplates: NonEditableTemplate[]) {
    this.update((state) => ({ ...state, nonEditableTemplates: nonEditableTemplates }));
  }

  getNonEditableTemplatesPageCountObservable() {
    return this.getFieldObservable("nonEditableTemplatesPageCount");
  }

  setNonEditableTemplatesPageCount(count: number) {
    this.update((state) => ({ ...state, nonEditableTemplatesPageCount: count }));
  }

  setActiveNonEditableTemplate(nonEditableTemplate: NonEditableTemplate) {
    this.update((state) => ({ ...state, activeNonEditableTemplates: nonEditableTemplate }));
  }

  getActiveNonEditableTemplateObservable() {
    return this.getFieldObservable("activeNonEditableTemplates");
  }
}
