<m-modal
  [paddingSize]="24"
  [closeButtonVisible]="closeButtonVisible"
  [closeOnBackDropClick]="closeOnBackDropClick"
  (close)="onCloseClick()"
>
  <div class="container">
    <m-icon src="/assets/img/login-modal-{theme}.png" />

    <div class="content">
      <div class="title" i18n="@@user.verify-email-send-header">user.verify-email-send-header</div>
      <div class="description-block">
        <m-localized-text class="description" [text]="verificationText" />
        <br />
        <div class="timer-block">
          <m-localized-text
            class="description link"
            text="user.verify-email-send-button"
            i18n-text="@@user.verify-email-send-button"
            (click)="!timeoutTimeLeft && onSendVerificationEmailClick.emit()"
          />
          <span *ngIf="timeoutTimeLeft > 0" class="description">
            <span i18n="@@user.verify-email-send-timer1">user.verify-email-send-timer1</span>
            {{ timeoutTimeLeft }}
            <span i18n="@@user.verify-email-send-timer2">user.verify-email-send-timer2</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</m-modal>
