import { Injectable } from "@angular/core";
import { Store } from "@metranpage/state";
import { CompanyFont } from "../../models/font";

export type State = {
  fonts: CompanyFont[];
  fontsPageCount: number;
  activeFont: CompanyFont | undefined;
};

@Injectable({
  providedIn: "root",
})
export class AdminFontsStore extends Store<State> {
  protected override getInitialState(): State {
    return {
      fonts: [],
      fontsPageCount: 1,
      activeFont: undefined,
    };
  }

  getFontsObservable() {
    return this.getFieldObservable("fonts");
  }

  setFonts(fonts: CompanyFont[]) {
    this.update((state) => ({ ...state, fonts }));
  }

  getFontsPageCountObservable() {
    return this.getFieldObservable("fontsPageCount");
  }

  setFontsPageCount(count: number) {
    this.update((state) => ({ ...state, fontsPageCount: count }));
  }

  setActiveFont(font: CompanyFont) {
    this.update((state) => ({ ...state, activeFont: font }));
  }

  getActiveFontObservable() {
    return this.getFieldObservable("activeFont");
  }
}
