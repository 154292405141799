import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Api, BASE_URL, DOWNLOAD_FILE_TEMPLATE } from "@metranpage/core-api";
import { saveAs } from "file-saver";
import * as _ from "lodash-es";
import {
  NonEditableTemplate,
  NonEditableTemplateCreateDataWithFile,
  NonEditableTemplateData,
} from "../../models/non-editable-template";

export type NonEditableTemplatesFilter = {
  title?: string;
};

@Injectable({
  providedIn: "root",
})
export class AdminNonEditableTemplatesApi extends Api {
  constructor(
    http: HttpClient,
    @Inject(BASE_URL) baseUrl: string,
    @Inject(DOWNLOAD_FILE_TEMPLATE) private readonly downloadFileTemplate: string,
  ) {
    super(http, baseUrl);
  }

  async createNonEditableTemplate(data: NonEditableTemplateCreateDataWithFile) {
    const formData: FormData = new FormData();

    formData.append("file", data.archiveFile, data.archiveFile.name);

    for (const [key, value] of Object.entries(data)) {
      if (key === "archiveFile") {
        continue;
      }
      if (typeof value !== "string") {
        formData.append(key, JSON.stringify(value));
        continue;
      }
      formData.append(key, value);
    }

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post<{ id: number }>("admin/non-editable-templates/", formData, {
      headers,
    });
  }

  // async copyNonEditableTemplate(NonEditableTemplateId: number) {
  //   return this.post<{ id: number }>(`admin/non-editable-templates/${NonEditableTemplateId}/copy`, {});
  // }

  async getNonEditableTemplateById(nonEditableTemplateId: number) {
    return this.get<NonEditableTemplate>(`admin/non-editable-templates/${nonEditableTemplateId}`);
  }

  async getNonEditableTemplates() {
    return this.get<NonEditableTemplate[]>("admin/non-editable-templates");
  }

  async getNonEditableTemplatesPaginated(page: number, filters: NonEditableTemplatesFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<NonEditableTemplateData>("admin/non-editable-templates/paginated", {
      page,
      ...clearedFilters,
    });
  }

  async updateNonEditableTemplate(nonEditableTemplateId: number, data: NonEditableTemplateCreateDataWithFile) {
    const formData: FormData = new FormData();

    if (data.archiveFile) {
      formData.append("file", data.archiveFile, data.archiveFile.name);
    }

    for (const [key, value] of Object.entries(data)) {
      if (key === "archiveFile") {
        continue;
      }
      if (typeof value !== "string") {
        formData.append(key, JSON.stringify(value));
        continue;
      }
      formData.append(key, value);
    }

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post<{ id: number }>(`admin/non-editable-templates/${nonEditableTemplateId}`, formData, {
      headers,
    });
  }

  async deleteNonEditableTemplate(NonEditableTemplateId: number) {
    return this.delete(`admin/non-editable-templates/${NonEditableTemplateId}`);
  }

  async downloadExample() {
    return (
      this.getAsBlob("admin/non-editable-templates/example")
        // .then((res) => res.blob())
        .then((blob) =>
          saveAs(
            blob,
            `${this.downloadFileTemplate
              .replace("_{id}", "")
              .replace("_{filename}", "")}__non-editable-template-example.zip`,
          ),
        )
    );
  }
}
