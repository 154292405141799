import { User } from "@metranpage/user-data";
import { BehaviorSubject, Observable } from "rxjs";
import { PublishedObjectsDataSourceType, PublishedObjectsTypes } from "./community-data.service";

export abstract class PublishedObjectsDataSource<T extends PublishedObjectsDataSourceType> {
  objectType!: PublishedObjectsTypes;

  page = 1;
  pageCount = 1;
  eventsBus = new BehaviorSubject<number>(0);

  constructor(objectType: PublishedObjectsTypes) {
    this.objectType = objectType;
  }

  abstract getItems(): Observable<T[]>;
  abstract getObject(id: number): T | undefined;
  abstract loadNextPage(): void;
  abstract getImageUrlForObject(id: number, isThumbnail: boolean): string | undefined;
  abstract getAuthorForObject(id: number): User | undefined;

  abstract like(id: number): void;
  abstract unlike(id: number): void;

  getEventBus() {
    return this.eventsBus;
  }
  sendEvent(id: number) {
    this.eventsBus.next(id);
  }
}
