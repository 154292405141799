import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";

export type ModalOpts = {
  needReload: boolean;
  queryParams?: { [key: string]: string | number | boolean };
};

@Injectable({
  providedIn: "root",
})
export class RouterService {
  constructor(private readonly router: Router) { }

  getRouteParam(snapshot: ActivatedRouteSnapshot, param: string) {
    if (snapshot.params[param]) {
      return snapshot.params[param];
    }

    for (const child of snapshot.children) {
      const value: any = this.getRouteParam(child, param);
      if (value) {
        return value;
      }
    }

    return undefined;
  }

  getRouteAsUrlSegments(snapshot: ActivatedRouteSnapshot) {
    let url: string[] = [];

    const paths = snapshot.url.map((u) => u.path);
    url = url.concat(paths);

    for (const child of snapshot.children) {
      if (child.outlet !== "primary") {
        break;
      }
      url = url.concat(this.getRouteAsUrlSegments(child));
    }

    return url;
  }

  async showInModal(
    routeInModal: any = null,
    outletName = "modal",
    opts: ModalOpts = { needReload: false, queryParams: {} },
  ) {
    let modalRoute: any = { outlets: { [outletName]: null } };
    if (routeInModal !== null) {
      modalRoute = { outlets: { [outletName]: routeInModal } };
    }

    await this.router.navigate([modalRoute], { queryParams: opts.queryParams });

    if (opts.needReload) {
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }

  async closeModal(outletName = "modal") {
    await this.router.navigate([{ outlets: { [outletName]: null } }]);
  }

  async closeModalAndReload(outletName = "modal") {
    await this.router.navigate([{ outlets: { [outletName]: null } }]);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }
}
