import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, ActivationEnd, Router } from "@angular/router";
import { CompanyStore } from "@metranpage/company";
import { InfoBlockData, SelectValue, fadeInOutOnEnterLeave } from "@metranpage/components";
import {
  LoadingService,
  NotificationsPopUpService,
  RewardsService,
  RewardsStore,
  RouterService,
  UserRewardOneTime,
  filterUndefined,
} from "@metranpage/core";
import { I18nService } from "@metranpage/i18n";
import { PricingService } from "@metranpage/pricing";
import { ActiveSubscription, PaymentCurrency, Tariff } from "@metranpage/pricing-data";
import { FirstPaymentService } from "@metranpage/user";
import { User, UserBalance, UserStore } from "@metranpage/user-data";
import { BalanceData } from "@metranpage/user-payment-data";
import { DateTime } from "luxon";
import { Observable, Subscription, combineLatest, map } from "rxjs";
import { GeneratedImage } from "../../models/generated-image";
import { ImageGeneration, ImageGenerationPrices, UnzoomSettings, UpscaleSettings } from "../../models/image-generation";
import { GeneratedImageService } from "../../services/generated-image.service";
import { GeneratedImageStore } from "../../services/generated-image.store";
import { ImageGenerationDataService } from "../../services/image-generation-data.service";
import { ImageGenerationService } from "../../services/image-generation.service";
import { VariantImageGenerationStore } from "../../services/variant-image-generation.store";
import { SelectedImage } from "./selected-image.model";

type ImageGenerationMode = "upscale" | "unzoom";

@Component({
  selector: "m-preview-image-modal",
  templateUrl: "./preview-image-modal.view.html",
  styleUrls: ["./preview-image-modal.view.scss"],
  animations: [fadeInOutOnEnterLeave],
})
export class PreviewImageModalView implements OnChanges, OnInit, OnDestroy {
  @Input()
  user!: User;
  @Input()
  prices!: ImageGenerationPrices;
  @Input()
  imageGeneration!: ImageGeneration;
  @Input()
  generatedImage!: GeneratedImage;
  @Input()
  closeButtonVisible = true;
  @Input()
  closeOnBackDropClick = true;
  @Input()
  paddingSize = 16;
  @Input()
  hasPrevious = false;
  @Input()
  hasNext = false;
  @Input()
  isModal = false;

  @Output()
  onPrevious = new EventEmitter();
  @Output()
  onNext = new EventEmitter();
  @Output()
  onSelect = new EventEmitter<GeneratedImage>();
  @Output()
  onClose = new EventEmitter();

  protected formVarianImageGenerationsSelect!: FormGroup;
  protected formUpscale!: FormGroup;

  protected selectedImage!: SelectedImage;

  protected megapixel = 0;

  protected optionsIcon: string | undefined = undefined;

  protected generatedImageIndex?: number;
  protected nextGeneratedImageId?: number;
  protected previousGeneratedImageId?: number;

  private variantChanges$!: Subscription;
  private previewGeneratedImageChanges$!: Subscription;

  protected generatedImageForDelete?: GeneratedImage;

  protected modalConfirmationDeleteImageText = "";

  protected generatedImageForShare?: GeneratedImage;

  private sub: Subscription = new Subscription();

  protected locale = "en";

  protected isUpscalePriceLoading = false;

  protected isLowBalanceModalVisible = false;
  tariffsForUpgrade$!: Observable<Tariff[]>;
  protected activeSubscription?: ActiveSubscription;
  protected higherTariff?: Tariff;
  protected hasPaidTariff = false;
  protected hasTrialPeriod = false;
  protected balance!: UserBalance;
  protected imageGenerationPaymentData!: BalanceData;

  protected imageGenerationMode?: ImageGenerationMode;
  protected unzoomErrors: InfoBlockData[] = [];
  protected upscaleErrors: InfoBlockData[] = [];

  protected rewardsOneTime: UserRewardOneTime[] = [];
  protected currency: PaymentCurrency = "RUB";

  constructor(
    private readonly userStore: UserStore,
    private readonly generatedImageService: GeneratedImageService,
    private readonly imageGenerationService: ImageGenerationService,
    private readonly imageGenerationDataService: ImageGenerationDataService,
    private readonly variantImageGenerationStore: VariantImageGenerationStore,
    private readonly generatedImageStore: GeneratedImageStore,
    private readonly notificationService: NotificationsPopUpService,
    private readonly loadingService: LoadingService,
    private readonly routerService: RouterService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly pricingService: PricingService,
    private readonly firstPaymentService: FirstPaymentService,
    private readonly companyStore: CompanyStore,
    private readonly cdr: ChangeDetectorRef,
    i18nService: I18nService,
    rewardsStore: RewardsStore,
    private readonly rewardsService: RewardsService,
  ) {
    this.sub.add(
      userStore.getUserObservable().subscribe((user) => {
        if (!user) {
          return;
        }
        this.user = user;
      }),
    );

    this.sub.add(
      userStore.getActiveSubscriptionObservable().subscribe((activeSubscription) => {
        this.activeSubscription = activeSubscription;
        this.hasPaidTariff = activeSubscription?.hasPaidTariff ?? false;
        this.hasTrialPeriod = activeSubscription?.hasTrialPeriod ?? false;

        if (!this.activeSubscription) {
          return;
        }
        this.getHigherTariff();
      }),
    );

    this.sub.add(
      userStore.getBalanceObservable().subscribe((balance) => {
        if (!balance) {
          return;
        }
        this.balance = balance;
      }),
    );

    this.tariffsForUpgrade$ = combineLatest([
      userStore.getActiveSubscriptionObservable(),
      pricingService.getTariffsForCompany(),
    ]).pipe(
      map(([subscription, tariffs]) => ({ subscription, tariffs: tariffs.filter((v) => v.isFree === false) })),
      map((info) => {
        if (!info.subscription || info.subscription.tariff.isFree) {
          return info.tariffs.filter((t) => t.period === 1);
        }
        return info.tariffs.filter((t) => t.period === info.subscription?.tariff.period);
      }),
    );

    this.sub.add(
      rewardsStore.getRewardsOneTimeObservable().subscribe((rewards) => {
        this.rewardsOneTime = rewards;
      }),
    )
    this.sub.add(
      companyStore
        .getCompanyObservable()
        .pipe(filterUndefined())
        .subscribe((company) => {
          this.currency = company.currency;
        }),
    );

    this.initVarianImagesSelectForm();
    this.initUpscaleForm();

    this.watchRouteChanges();
    this.locale = i18nService.getLocale();
  }

  async ngOnInit(): Promise<void> {
    await this.init();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.generatedImage) {
      await this.initForModalView();
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private async init() {
    if (this.isModal) {
      this.initForModalView();
    } else {
      this.initForPageView();

      this.sub.add(
        this.userStore.getBalanceObservable().subscribe((balance) => {
          if (!balance) {
            return;
          }
          this.balance = balance;
        }),
      );
    }
  }

  private async initForPageView() {
    this.loadingService.startLoading({ fullPage: true });

    this.prices = await this.imageGenerationService.loadPrices();

    const imageGenerationId = Number(this.routerService.getRouteParam(this.route.snapshot, "imageGenerationId"));
    const generatedImageId = Number(this.routerService.getRouteParam(this.route.snapshot, "generatedImageId"));

    let imageGeneration: ImageGeneration | undefined = undefined;
    let generatedImage: GeneratedImage | undefined = undefined;
    try {
      imageGeneration = await this.imageGenerationService.getImageGenerationById(imageGenerationId);
      generatedImage = await this.generatedImageService.getGeneratedImageById(generatedImageId);
    } catch (error) {
      this.onErrorLoadGeneration();
      return;
    }

    if (!imageGeneration || imageGeneration.deletedAt || !generatedImage || generatedImage.deletedAt) {
      this.onErrorLoadGeneration();
      return;
    }

    this.imageGeneration = imageGeneration;
    this.generatedImage = generatedImage;

    this.updateGeneratedImageIndex();

    const rootGen = this.imageGeneration;
    const width = this.generatedImage?.width ?? this.imageGeneration.width;
    const height = this.generatedImage?.height ?? this.imageGeneration.height;

    let url = undefined;
    if (this.generatedImage) {
      url = this.getUrlForImage(this.generatedImage);
    }

    this.selectedImage = {
      imageGeneration: this.imageGeneration,
      originalGeneratedImage: this.generatedImage,
      generatedImage: this.generatedImage,
      generatedImageUrl: url,
      rootImageGeneration: rootGen,
      hasImage: this.generatedImage !== undefined,
      width,
      height,
      isVertical: width < height,
      availableVariants: [],
      variantSelectOptions: [],
    };

    this.watchVariantChanges();
    await this.loadVariantImages();

    await this.selectLastVariantGeneratedImage();
    await this.updateUpscalePrice();

    this.generatedImageStore.setPreviewGeneratedImage(this.generatedImage);
    this.watchPreviewGeneratedImageChanges();

    this.loadingService.stopLoading();

    this.cdr.detectChanges();
  }

  private async initForModalView() {
    this.loadingService.startLoading({ fullPage: true });

    const rootGen = this.imageGeneration;
    const width = this.generatedImage?.width ?? this.imageGeneration.width;
    const height = this.generatedImage?.height ?? this.imageGeneration.height;

    let url = undefined;
    if (this.generatedImage) {
      url = this.getUrlForImage(this.generatedImage);
    }

    this.selectedImage = {
      imageGeneration: this.imageGeneration,
      originalGeneratedImage: this.generatedImage,
      generatedImage: this.generatedImage,
      generatedImageUrl: url,
      rootImageGeneration: rootGen,
      hasImage: this.generatedImage !== undefined,
      width,
      height,
      isVertical: width < height,
      availableVariants: [],
      variantSelectOptions: [],
    };

    this.watchVariantChanges();
    await this.loadVariantImages();

    await this.selectLastVariantGeneratedImage();
    await this.updateUpscalePrice();

    this.loadingService.stopLoading();

    this.cdr.detectChanges();
  }

  private async selectLastVariantGeneratedImage() {
    if (this.selectedImage.availableVariants.length === 0) {
      await this.updatePreviewImage(this.selectedImage.rootImageGeneration, this.selectedImage.generatedImage);
      this.selectImageGeneration(this.selectedImage.rootImageGeneration.id);
      return;
    }

    const lastVariantImageGeneration = this.selectedImage.availableVariants[0];
    const lastVariantGeneratedImage =
      lastVariantImageGeneration.generatedImages.length > 0 ? lastVariantImageGeneration.generatedImages[0] : undefined;
    await this.updatePreviewImage(lastVariantImageGeneration, lastVariantGeneratedImage);
    this.selectImageGeneration(lastVariantImageGeneration.id);
  }

  // generated image can be undefined if image generation just created or in status error
  private async updatePreviewImage(imageGeneration: ImageGeneration, generatedImage: GeneratedImage | undefined) {
    this.selectedImage.imageGeneration = imageGeneration;
    this.selectedImage.generatedImage = generatedImage;

    this.selectedImage.hasImage = generatedImage !== undefined;
    this.selectedImage.width = generatedImage?.width ?? imageGeneration.width;
    this.selectedImage.height = generatedImage?.height ?? imageGeneration.height;
    this.selectedImage.isVertical = this.selectedImage.width < this.selectedImage.height;

    if (!this.selectedImage.generatedImage) {
      return;
    }

    this.selectedImage.generatedImageUrl = this.getUrlForImage(this.selectedImage.generatedImage);

    this.megapixel = this.imageGenerationService.getImageMegapixel(this.selectedImage.generatedImage);
    await this.updateUpscalePrice();
  }

  // results will come in watch variant changes
  protected async loadVariantImages() {
    if (!this.selectedImage.generatedImage) {
      return;
    }
    await this.imageGenerationService.loadVariantImageGenerations(
      this.selectedImage.rootImageGeneration.id,
      this.selectedImage.generatedImage.id,
    );
  }

  private watchVariantChanges() {
    if (this.variantChanges$) {
      this.variantChanges$.unsubscribe();
    }

    this.variantChanges$ = this.variantImageGenerationStore
      .getVariantImageGenerationsObservable()
      .subscribe(async (newGens) => {
        await this.updateAvailableVariantsWith(newGens);
      });

    this.sub.add(this.variantChanges$);
  }

  private watchPreviewGeneratedImageChanges() {
    if (this.previewGeneratedImageChanges$) {
      this.previewGeneratedImageChanges$.unsubscribe();
    }

    this.previewGeneratedImageChanges$ = this.generatedImageStore
      .getPreviewGeneratedImageObservable()
      .pipe(filterUndefined())
      .subscribe(async (previewGeneratedImage) => {
        if (this.selectedImage.generatedImage?.id === previewGeneratedImage.id) {
          this.generatedImage = previewGeneratedImage;
          this.selectedImage.generatedImage = this.generatedImage;
        }
        if (this.selectedImage.originalGeneratedImage.id === previewGeneratedImage.id) {
          this.selectedImage.originalGeneratedImage = previewGeneratedImage;
        }
      });

    this.sub.add(this.previewGeneratedImageChanges$);
  }

  private async updateAvailableVariantsWith(newGens: ImageGeneration[]) {
    if (newGens.length === 0) {
      return;
    }
    if (newGens[0].rootGeneratedImageId !== this.selectedImage.originalGeneratedImage.id) {
      return;
    }

    let isNewImage = false;
    if (this.selectedImage.availableVariants.length < newGens.length) {
      isNewImage = true;
    }

    this.selectedImage.availableVariants = newGens;
    this.selectedImage.variantSelectOptions = this.getOptionsForImageSelect();

    let currentGeneratedImage = undefined;
    let isImageChanged = false;
    for (const variant of this.selectedImage.availableVariants) {
      if (isNewImage && variant.status === "created" && !isImageChanged) {
        this.selectImageGeneration(variant.id);
        await this.updatePreviewImage(variant, currentGeneratedImage);
        isImageChanged = true;
      }

      if (this.selectedImage.imageGeneration.id !== variant.id) {
        return;
      }

      if (variant.status === "success") {
        if (variant?.generatedImages?.length > 0) {
          currentGeneratedImage = variant?.generatedImages[0];
          await this.updatePreviewImage(variant, currentGeneratedImage);
        }
      }

      if (variant.status === "error") {
        this.selectImageGeneration(this.selectedImage.rootImageGeneration.id);
      }

      if (variant.deletedAt) {
        this.selectImageGeneration(this.selectedImage.rootImageGeneration.id);
      }
    }

    this.cdr.detectChanges();
  }

  async updateUpscalePrice() {
    if (!this.prices || !this.selectedImage.generatedImage) {
      return;
    }

    this.isUpscalePriceLoading = true;
    const upscalePrice = await this.imageGenerationService.getUpscalePrice(this.selectedImage.generatedImage.id);
    this.isUpscalePriceLoading = false;
    this.prices.upscale = upscalePrice;
  }

  protected selectImageGeneration(imageGenerationId: number | undefined) {
    this.cdr.detectChanges();
    this.formVarianImageGenerationsSelect.patchValue({ images: imageGenerationId });
  }

  private initVarianImagesSelectForm() {
    this.formVarianImageGenerationsSelect = new FormGroup({
      images: new FormControl("", [Validators.required]),
    });

    this.sub.add(
      this.formVarianImageGenerationsSelect.get("images")?.valueChanges.subscribe(async (value) => {
        const variantImageGeneration = this.selectedImage.availableVariants.find((i) => i.id === value);
        if (!variantImageGeneration || variantImageGeneration.status === "error") {
          await this.displayOriginal();
          return;
        }
        await this.displayVariant(variantImageGeneration);
      }),
    );
  }

  private async displayOriginal() {
    await this.updatePreviewImage(this.selectedImage.rootImageGeneration, this.selectedImage.originalGeneratedImage);
    this.updateOptionIcon(this.selectedImage.rootImageGeneration);
  }

  private async displayVariant(imageGeneration: ImageGeneration) {
    if (imageGeneration.generatedImages && imageGeneration.generatedImages.length > 0) {
      const image = imageGeneration.generatedImages[0];
      await this.updatePreviewImage(imageGeneration, image);
    } else {
      await this.updatePreviewImage(imageGeneration, undefined);
    }

    this.updateOptionIcon(imageGeneration);
  }

  private initUpscaleForm() {
    this.formUpscale = new FormGroup({
      creativityStrength: new FormControl(5, [Validators.required]),
      upscaleMultiplier: new FormControl(1.5, [Validators.required]),
    });
  }

  protected onShowDeleteGeneratedImageModal() {
    this.modalConfirmationDeleteImageText = $localize`:@@image-generation.generation.delete-generated-image-with-child-generations-confirmation:`;
    if (this.isGeneratedImageVariant(this.selectedImage.generatedImage)) {
      this.modalConfirmationDeleteImageText = $localize`:@@image-generation.generation.delete-generated-image-confirmation:`;
    }
    this.generatedImageForDelete = this.selectedImage.generatedImage;
    this.cdr.detectChanges();
  }

  protected onCloseDeleteGeneratedImageModal() {
    this.generatedImageForDelete = undefined;
    this.cdr.detectChanges();
  }

  protected async deleteImage() {
    if (!this.generatedImageForDelete) {
      return;
    }
    await this.generatedImageService.deleteGeneratedImage(this.generatedImageForDelete.id);

    if (this.isGeneratedImageVariant(this.generatedImageForDelete)) {
      this.onCloseDeleteGeneratedImageModal();
      return;
    }

    this.resetPreview();
    this.onCloseDeleteGeneratedImageModal();
  }

  protected resetPreview() {
    this.generatedImageIndex = undefined;

    this.generatedImageStore.setPreviewGeneratedImage(undefined);
    this.onCloseClick();
  }

  protected isGeneratedImageVariant(generatedImage: GeneratedImage | undefined) {
    return this.generatedImage && this.generatedImage.id !== generatedImage?.id;
  }

  protected selectClick() {
    this.onSelect.emit(this.selectedImage.generatedImage);
  }

  protected onShowShareImageModal() {
    this.generatedImageForShare = this.selectedImage.generatedImage;
    this.cdr.detectChanges();
  }

  protected onCloseShareImageModal() {
    this.generatedImageForShare = undefined;
    this.cdr.detectChanges();
  }

  protected async onPublishImageClick() {
    if (!this.generatedImageForShare) {
      return;
    }
    await this.generatedImageService.publishGeneratedImage(this.generatedImageForShare.id);
    this.onCloseShareImageModal();
  }

  protected onPreviousClick() {
    if (!this.isModal) {
      this.routerService.showInModal([this.imageGeneration.id, this.previousGeneratedImageId]);
      return;
    }
    this.onPrevious.emit();
  }

  protected onNextClick() {
    if (!this.isModal) {
      this.routerService.showInModal([this.imageGeneration.id, this.nextGeneratedImageId]);
      return;
    }
    this.onNext.emit();
  }

  protected async onDownloadImageClick() {
    if (!this.selectedImage.generatedImage) {
      return;
    }
    await this.generatedImageService.downloadGeneratedImage(this.selectedImage.generatedImage);
  }

  protected onCloseClick() {
    if (!this.isModal) {
      this.routerService.closeModal();
      return;
    }
    this.onClose.emit();
  }

  protected async onUnzoomClick() {
    if (!this.selectedImage.generatedImage) {
      return;
    }

    if (!this.isEnoughtTokens("unzoom")) {
      await this.calculatePaymentData("unzoom");
      return;
    }

    const data: UnzoomSettings = {
      generatedImageId: this.selectedImage.generatedImage.id,
    };

    this.notificationService.notify({
      content: $localize`:@@image-generation.generation.variant-image.unzoom.start:`,
      type: "success",
    });
    await this.imageGenerationService.unzoomImage(data);

    await this.firstPaymentService.onPayment();
  }

  protected async onUpscaleClick() {
    if (!this.selectedImage.generatedImage) {
      return;
    }

    if (!this.isEnoughtTokens("upscale")) {
      await this.calculatePaymentData("upscale");
      return;
    }

    const formData = this.formUpscale.getRawValue();

    // if (megapixel >= 10) {
    //   formData.upscaleMultiplier = 1
    // }

    const data: UpscaleSettings = {
      generatedImageId: this.selectedImage.generatedImage.id,
      ...formData,
    };

    this.notificationService.notify({
      content: $localize`:@@image-generation.generation.variant-image.upscale.start:`,
      type: "success",
    });
    await this.imageGenerationService.upscaleImage(data);

    await this.firstPaymentService.onPayment();
  }

  protected onBackDropClick() {
    if (!this.closeOnBackDropClick) {
      return;
    }
    this.onCloseClick();
  }

  isEnoughtTokens(mode: ImageGenerationMode): boolean {
    if (mode === "upscale" && this.isUpscalePriceLoading) {
      return false;
    }

    if (this.prices && this.balance && this.prices[mode] <= this.balance.credits) {
      return true;
    }
    return false;
  }

  getPrice(mode: ImageGenerationMode) {
    if (!this.prices) {
      return;
    }
    return this.prices[mode];
  }

  getUpscaleStrengthPoints() {
    return this.imageGenerationDataService.getUpscaleStrengthPoints();
  }

  getOptionsForImageSelect(): SelectValue[] {
    const options: SelectValue[] = [];

    if (this.selectedImage.originalGeneratedImage) {
      options.push({
        id: this.selectedImage.originalGeneratedImage.imageGenerationId,
        value: $localize`:@@image-generation.generation.variant-image.original:`,
      });
    }

    const filteredImageGenerations = this.selectedImage.availableVariants.filter(
      (ig) => !ig.deletedAt && ig.status !== "error",
    );
    if (filteredImageGenerations.length > 0) {
      for (const ig of filteredImageGenerations) {
        let title = "";
        let icon = undefined;
        if (ig.generationMode === "upscale") {
          title += `${$localize`:@@image-generation.generation.variant-image.upscale:`}`;
          icon = "upscale.svg";
        }
        if (ig.generationMode === "unzoom") {
          title += `${$localize`:@@image-generation.generation.variant-image.unzoom:`}`;
          icon = "unzoom.svg";
        }
        if (ig.createdAt) {
          title += ` - ${DateTime.fromISO(ig.createdAt.toString()).toLocaleString(
            {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            },
            {
              locale: this.locale,
            },
          )}`;
        }
        options.push({ id: ig.id, value: title, icon });
      }
    }

    if (options.length === 1) {
      return [];
    }

    return options;
  }

  private updateOptionIcon(imageGeneration: ImageGeneration | undefined) {
    this.optionsIcon = undefined;
    if (imageGeneration?.generationMode === "upscale") {
      this.optionsIcon = "upscale.svg";
    }
    if (imageGeneration?.generationMode === "unzoom") {
      this.optionsIcon = "unzoom.svg";
    }
  }

  protected getUrlForImage(generatedImage: GeneratedImage) {
    return this.generatedImageService.getUrlForImage(generatedImage.imageGenerationId, generatedImage.imageUrl);
  }

  protected isUnzoomDisable() {
    return !this.selectedImage?.generatedImage || this.isModelXL() || this.isMegapixelLimitReach("unzoom");
  }

  protected getUnzoomErrors() {
    const errors: InfoBlockData[] = [];

    if (this.isModelXL()) {
      let text = "";
      switch (this.selectedImage?.rootImageGeneration.generationMode) {
        case "basic":
          text = $localize`:@@image-generation.generation.variant-image.unzoom.error.xl-model-basic:`;
          break;
        case "advanced":
          text = $localize`:@@image-generation.generation.variant-image.unzoom.error.xl-model-advanced:`;
          break;
        default:
          text = $localize`:@@image-generation.generation.variant-image.unzoom.error.xl-model:`;
          break;
      }
      errors.push({
        textData: [{ text }],
      });
    }

    if (this.isMegapixelLimitReach("unzoom")) {
      errors.push({
        textData: [{ text: $localize`:@@image-generation.generation.variant-image.unzoom.error.megapixel-limit:` }],
      });
    }

    return errors;
  }

  private isModelXL() {
    return !!this.selectedImage?.rootImageGeneration.model.toLowerCase().includes("sdxl");
  }

  protected isUpscaleDisable() {
    return !this.selectedImage?.generatedImage || !this.prices?.upscale || this.isMegapixelLimitReach("upscale");
  }

  protected getUpscaleErrors() {
    const errors: InfoBlockData[] = [];

    if (this.isMegapixelLimitReach("upscale")) {
      errors.push({
        textData: [{ text: $localize`:@@image-generation.generation.variant-image.upscale.error.megapixel-limit:` }],
      });
    }

    // if (this.isImageGenerationUpscale()) {
    //   errors.push({
    //     textData: [{ text: $localize`:@@image-generation.generation.variant-image.upscale.error.re-upscale-limit:` }],
    //   });
    // }

    return errors;
  }

  private isMegapixelLimitReach(mode: "upscale" | "unzoom") {
    let megapixelLimit = 15;
    if (mode === "unzoom") {
      megapixelLimit = 6;
    }
    return this.megapixel >= megapixelLimit;
  }

  protected isUnzoomAvailable() {
    return !this.isMegapixelLimitReach("unzoom");
  }

  protected isUpscaleAvailable() {
    return !this.isMegapixelLimitReach("upscale");
  }

  protected updateGeneratedImageIndex() {
    this.generatedImageIndex = this.generatedImageService.getGeneratedImageIndex(
      this.generatedImage,
      this.imageGeneration?.generatedImages,
    );
    const images = this.imageGenerationService.orderGeneratedImages(this.imageGeneration.generatedImages);
    this.hasPrevious = this.hasPreviousImage();
    if (this.hasPrevious) {
      this.previousGeneratedImageId = images[this.generatedImageIndex - 1]?.id;
    }
    this.hasNext = this.hasNextImage();
    if (this.hasNext) {
      this.nextGeneratedImageId = images[this.generatedImageIndex + 1]?.id;
    }
  }

  protected hasPreviousImage() {
    if (!this.imageGeneration || this.generatedImageIndex === undefined) {
      return false;
    }
    return this.generatedImageIndex - 1 >= 0;
  }

  protected hasNextImage() {
    if (!this.imageGeneration || this.generatedImageIndex === undefined) {
      return false;
    }
    return this.generatedImageIndex + 1 < this.imageGeneration?.generatedImages.length;
  }

  private onErrorLoadGeneration() {
    this.notificationService.error($localize`:@@image-generation.generated-image.cant-load-image-error:`);
    this.onCloseClick();
  }

  private watchRouteChanges() {
    this.sub.add(
      this.router.events.subscribe((data) => {
        if (data instanceof ActivationEnd) {
          if ("imageGenerationId" in data.snapshot.params && "generatedImageId" in data.snapshot.params) {
            this.init();
          }
        }
      }),
    );
  }

  private async calculatePaymentData(mode: ImageGenerationMode) {
    const price = this.prices[mode];
    this.imageGenerationPaymentData = {
      price: price,
      userBalance: this.balance,
    };

    this.loadingService.stopLoading();
    this.imageGenerationMode = mode;
    this.isLowBalanceModalVisible = true;

    this.cdr.markForCheck();
  }

  protected closePricingModal() {
    this.isLowBalanceModalVisible = false;
  }

  protected onBuySubscription(tariff: Tariff) {
    this.closePricingModal();
    window.open(`payments/await-payment-link?tariffId=${tariff.id}`, "_blank");
  }

  protected async getHigherTariff() {
    this.higherTariff = await this.pricingService.getHigherTariff();
  }

  protected getSubscribeToTelegramChannelReward() {
    return this.rewardsService.getSubscribeToTelegramChannelReward(this.rewardsOneTime);
  }

  @HostListener("window:keydown", ["$event"])
  protected handleKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.onCloseClick();
    }
    if (event.key === "ArrowLeft" && this.hasPrevious) {
      this.onPreviousClick();
    }
    if (event.key === "ArrowRight" && this.hasNext) {
      this.onNextClick();
    }
  }
}
