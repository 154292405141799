import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "m-fixed-sidebar",
  templateUrl: "./fixed-sidebar.component.html",
  styleUrls: ["./fixed-sidebar.component.scss"],
  standalone: true,
  imports: [CommonModule],
})
export class FixedSidebar {}
