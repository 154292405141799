import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { LoadingService } from "@metranpage/core";
import { NotificationsPopUpService } from "@metranpage/core";
import { PricingViewService } from "@metranpage/pricing";
import { debounceTime, takeUntil } from "rxjs";
import { Tariff, TariffCreateData } from "../../models/tariff";
import { AdminTariffsService } from "../../services/tariffs/tariffs.service";
import { AdminTariffsStore } from "../../services/tariffs/tariffs.store";
import { AdminBasePage } from "../admin/admin.page";

@Component({
  selector: "m-admin-tariffs-page",
  templateUrl: "./tariffs.page.html",
  styleUrls: ["./tariffs.page.scss"],
})
export class AdminTariffsPage extends AdminBasePage implements OnInit {
  tariffs: Tariff[] = [];
  page = 1;
  pageCount = 1;

  protected searchForm = new FormGroup({
    title: new FormControl("", { nonNullable: true, validators: [] }),
  });
  protected searchTitle: string | undefined = undefined;

  protected isAddTariffModalVisible = false;
  protected isDeleteTariffModalVisible = false;
  protected tariffForDelete?: Tariff = undefined;

  constructor(
    private readonly adminTariffsStore: AdminTariffsStore,
    private readonly adminTariffsService: AdminTariffsService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly pricingViewService: PricingViewService,
  ) {
    super();

    adminTariffsStore
      .getTariffsObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tariffs) => {
        this.tariffs = tariffs;
      });

    adminTariffsStore
      .getTariffsPageCountObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((count) => {
        this.pageCount = count;
      });
  }

  ngOnInit(): void {
    this.adminTariffsService.loadTariffsPaginated(this.page);

    this.searchForm.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(600)).subscribe((value) => {
      this.searchTitle = undefined;
      if (value.title) {
        this.searchTitle = value.title;
      }

      this.adminTariffsService.loadTariffsPaginated(this.page, {
        title: this.searchTitle,
      });
    });
  }

  trackByTariffId(index: number, tariff: Tariff) {
    return tariff.id;
  }

  onPageChanged(page: number) {
    this.page = page;
    this.adminTariffsService.loadTariffsPaginated(this.page);
  }

  protected showTariffDetails(tariff: Tariff) {
    this.router.navigate(["admin", "tariffs", tariff.id]);
  }

  protected async onAddTariff() {
    this.isAddTariffModalVisible = true;
  }

  protected async onTariffAdded(data: TariffCreateData) {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminTariffsService.createTariff(data);
    this.loadingService.stopLoading();
    if (result.status === "success") {
      this.onCloseAddTariffModal();
      this.adminTariffsService.loadTariffsPaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.tariffs.tariff.error.cant-create-tariff:`);
    }
  }

  async onCopyTariffClick(tariffId: number) {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });
    const result = await this.adminTariffsService.copyTariff(tariffId);
    this.loadingService.stopLoading();
    if (result.status === "success") {
      this.router.navigate(["admin", "tariffs", result.id]);
    } else {
      this.notificationService.error($localize`:@@admin.tariffs.tariff.error.cant-copy-tariff:`);
    }
  }

  protected onCloseAddTariffModal() {
    this.isAddTariffModalVisible = false;
  }

  protected onCloseDeleteTariffModal() {
    this.isDeleteTariffModalVisible = false;
    this.tariffForDelete = undefined;
  }

  protected showDeleteTariffModal(tariff: Tariff) {
    this.isDeleteTariffModalVisible = true;
    this.tariffForDelete = tariff;
  }

  protected async onDeleteTariff() {
    if (!this.tariffForDelete) {
      return;
    }

    const result = await this.adminTariffsService.deleteTariff(this.tariffForDelete.id);
    if (result.status === "success") {
      this.isDeleteTariffModalVisible = false;
      this.tariffForDelete = undefined;
      this.adminTariffsService.loadTariffsPaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.tariffs.tariff.error.cant-delete-tariff:`);
    }
  }

  protected getSubtitleText(subtitle: string) {
    const subtitles = this.pricingViewService.getOptionsForSubtitleSelect();
    return subtitles.find((s) => s.id === subtitle)?.value ?? "";
  }
}
