import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export type Footnote = {
  id: number;
  text: string;
  isFocused: boolean;
};

export type TextSelectionState = {
  onPageIndex: number | undefined;
  range: Range | undefined;
  hasBold: boolean;
  hasItalic: boolean;
  hasFootnote: boolean;
};

@Injectable({
  providedIn: "root",
})
export class MarkupEditorService {
  selectionState$ = new BehaviorSubject<TextSelectionState>({
    onPageIndex: undefined,
    range: undefined,
    hasBold: false,
    hasItalic: false,
    hasFootnote: false,
  });

  onChangeSelectionState(selectionState: TextSelectionState) {
    this.selectionState$.next(selectionState);
  }
}
