import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, SimpleChanges, TemplateRef } from "@angular/core";
import { Sizeable } from "./sizeable";

export type ColumnData = {
  items: Sizeable[];
  totalHeight: number;
};

@Component({
  selector: "m-c-image-grid-column",
  templateUrl: "./column.component.html",
  styleUrls: ["./column.component.scss"],
  standalone: true,
  imports: [CommonModule],
})
export class ColumnComponent {
  @Input() data!: ColumnData;
  @Input() itemTemplate!: TemplateRef<any>;

  /**
   * if we use width and height in css - css engine will scale down to nearest integer, instead of proper rounding, so aspect ration will be a bit off.
   * Here we use hack, to keep images a bit smaller in height, so overlay will be correct
   *
   * NOTE: this still need to be tested in safari
  */
  calcAspectRatio(item: Sizeable) {
    const { width, height } = item;
    return Math.ceil((width / height) * 100) / 100 + 0.01;
  }
}
