import { Component } from "@angular/core";
import { I18nService } from "@metranpage/i18n";
import { NotificationTrialPeriodActivate } from "@metranpage/user-data";
import { DateTime } from "luxon";
import { AccountNotificationBaseCardView } from "../account-notification-base-card/account-notification-base-card.view";

@Component({
  selector: "m-account-notification-trial-period-activate-card-view",
  templateUrl: "account-notification-trial-period-activate-card.view.html",
  styleUrls: ["account-notification-trial-period-activate-card.view.scss"],
})
export class AccountNotificationTrialPeriodActivateCardView extends AccountNotificationBaseCardView<NotificationTrialPeriodActivate> {
  protected locale = "en";

  constructor(private readonly i18nService: I18nService) {
    super();
    this.locale = i18nService.getLocale();
  }

  protected getDateShort() {
    const date = this.payload.expireDate;
    return DateTime.fromISO(date.toString()).toLocaleString(DateTime.DATE_FULL, { locale: this.locale });
  }

  protected getTrialPeriod() {
    return `${this.payload.trialPeriodInDay} ${this.i18nService.pluralize($localize`:@@pricing.pluralize-days:`, {
      days: this.payload.trialPeriodInDay,
    })}`;
  }
}
