<div class="container">
  <m-pricing-tariffs
    [activeSubscription]="(activeSubscription | async)!"
    [tariffsPeriods]="tariffsPeriods"
    [activeTariffPeriod]="activeTariffPeriod"
    [tariffs]="getTariffsForPeriod()"
    [faqs]="faqs"
    [currency]="currency"
    (onPeriodSelectClick)="onPeriodSelectClick($event)"
    (onBuySubscriptionClick)="onBuySubscriptionClick($event)"
    (onTariffSettingsClick)="onTariffSettingsClick($event)"
  />
</div>
