import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { LoadingService, NotificationsPopUpService, RewardOneTimeType } from "@metranpage/core";
import { PricingViewService } from "@metranpage/pricing";
import { debounceTime, takeUntil } from "rxjs";
import { RewardOneTime, RewardOneTimeCreateData } from "../../../models/reward-onetime";
import { AdminRewardsOneTimeService } from "../../../services/rewards/rewards-onetime/rewards-onetime.service";
import { AdminRewardsOneTimeStore } from "../../../services/rewards/rewards-onetime/rewards-onetime.store";
import { AdminBasePage } from "../../admin/admin.page";

@Component({
  selector: "m-admin-rewards-onetime-page",
  templateUrl: "./rewards-onetime.page.html",
  styleUrls: ["./rewards-onetime.page.scss"],
})
export class AdminRewardsOneTimePage extends AdminBasePage implements OnInit {
  rewardsOneTime: RewardOneTime[] = [];
  page = 1;
  pageCount = 1;

  protected searchForm = new FormGroup({
    type: new FormControl("", { nonNullable: true, validators: [] }),
  });
  protected searchType: string | undefined = undefined;

  protected isAddRewardOneTimeModalVisible = false;
  protected isDeleteRewardOneTimeModalVisible = false;
  protected rewardOneTimeForDelete?: RewardOneTime = undefined;

  constructor(
    private readonly adminRewardsOneTimeStore: AdminRewardsOneTimeStore,
    private readonly adminRewardsOneTimeService: AdminRewardsOneTimeService,
    private readonly router: Router,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationsPopUpService,
    private readonly pricingViewService: PricingViewService,
  ) {
    super();

    adminRewardsOneTimeStore
      .getRewardsOneTimeObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((rewardsOneTime) => {
        this.rewardsOneTime = rewardsOneTime;
      });

    adminRewardsOneTimeStore
      .getRewardsOneTimePageCountObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((count) => {
        this.pageCount = count;
      });
  }

  ngOnInit(): void {
    this.adminRewardsOneTimeService.loadRewardsOneTimePaginated(this.page);

    this.searchForm.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(600)).subscribe((value) => {
      this.searchType = undefined;
      if (value.type) {
        this.searchType = value.type;
      }

      this.adminRewardsOneTimeService.loadRewardsOneTimePaginated(this.page, {
        type: this.searchType,
      });
    });
  }

  trackByRewardOneTimeId(index: number, rewardOneTime: RewardOneTime) {
    return rewardOneTime.id;
  }

  onPageChanged(page: number) {
    this.page = page;
    this.adminRewardsOneTimeService.loadRewardsOneTimePaginated(this.page);
  }

  protected showRewardOneTimeDetails(rewardOneTime: RewardOneTime) {
    this.router.navigate(["admin", "rewards-onetime", rewardOneTime.id]);
  }

  protected async onAddRewardOneTime() {
    this.isAddRewardOneTimeModalVisible = true;
  }

  protected async onRewardOneTimeAdded(data: RewardOneTimeCreateData) {
    this.notificationService.closeAll();
    this.loadingService.startLoading({ fullPage: true });

    const result = await this.adminRewardsOneTimeService.createRewardOneTime(data);
    this.loadingService.stopLoading();
    if (result.status === "success") {
      this.onCloseAddRewardOneTimeModal();
      this.adminRewardsOneTimeService.loadRewardsOneTimePaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.rewards.onetime.error.cant-create:`);
    }
  }

  protected onCloseAddRewardOneTimeModal() {
    this.isAddRewardOneTimeModalVisible = false;
  }

  protected onCloseDeleteRewardOneTimeModal() {
    this.isDeleteRewardOneTimeModalVisible = false;
    this.rewardOneTimeForDelete = undefined;
  }

  protected showDeleteRewardOneTimeModal(rewardOneTime: RewardOneTime) {
    this.isDeleteRewardOneTimeModalVisible = true;
    this.rewardOneTimeForDelete = rewardOneTime;
  }

  protected async onDeleteRewardOneTime() {
    if (!this.rewardOneTimeForDelete) {
      return;
    }

    const result = await this.adminRewardsOneTimeService.deleteRewardOneTime(this.rewardOneTimeForDelete.id);
    if (result.status === "success") {
      this.isDeleteRewardOneTimeModalVisible = false;
      this.rewardOneTimeForDelete = undefined;
      this.adminRewardsOneTimeService.loadRewardsOneTimePaginated(this.page);
    } else {
      this.notificationService.error($localize`:@@admin.rewards.onetime.error.cant-delete:`);
    }
  }

  protected getSelectTypeOptions() {
    return this.adminRewardsOneTimeService.getAvailableRewardsDailyTypeSelectOptions();
  }

  protected getTypeTitle(type: RewardOneTimeType) {
    switch (type) {
      case "subscribe-to-telegram-channel":
        return $localize`:@@admin.rewards.onetime.task.subscribe-to-telegram-channel:`;

      default:
        return type;
    }
  }
}
