import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ListStyle } from "../interfaces/list-data.interface";
// import { IconComponent } from "projects/markup-editor/src/lib/components/icon/icon.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AngularSvgIconModule,
    // TODO add icon project as dependency
    //IconComponent
  ],
  selector: "m-markup-editor-list-context-menu",
  templateUrl: "./list-context-menu.component.html",
  styleUrls: ["./list-context-menu.component.scss"],
})
export class ListContextMenuComponent {
  @Output()
  onContextMenuItemClick: EventEmitter<unknown> = new EventEmitter<unknown>();

  listStyleMarker!: ListStyle;

  onItemClick(event: any, listStyle: ListStyle) {
    this.listStyleMarker = listStyle;
    this.onContextMenuItemClick.emit({
      event,
      listStyleMarker: this.listStyleMarker,
    });
  }
}
