import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Template, TemplateFull, TemplateFullDto, TemplatePreview } from "@metranpage/book-data";
import { Api, BASE_URL } from "@metranpage/core-api";
import * as _ from "lodash-es";
import { TemplateCreateData, TemplateData, TemplatesData } from "../../models/template";

export type TemplatesFilter = {};

@Injectable({
  providedIn: "root",
})
export class AdminTemplatesApi extends Api {
  constructor(http: HttpClient, @Inject(BASE_URL) baseUrl: string) {
    super(http, baseUrl);
  }

  getTemplatesPaginated(page: number, filters: TemplatesFilter) {
    const clearedFilters = _.omitBy(filters, _.isUndefined);
    return this.get<TemplatesData>("admin/templates/paginated", {
      page,
      ...clearedFilters,
    });
  }

  getTemplate(templateId: number) {
    return this.get<TemplateData>(`admin/templates/${templateId}`);
  }

  async createTemplate(data: TemplateCreateData) {
    return this.post<{ id: number }>("admin/templates/", data);
  }

  async updateTemplate(data: TemplateFullDto) {
    return this.post<unknown>(`admin/templates/${data.id}`, data);
  }

  copyTemplate(templateId: number) {
    return this.post<{ id: number }>(`admin/templates/${templateId}/copy`, {});
  }

  deleteTemplate(templateId: number) {
    return this.delete<unknown>(`admin/templates/${templateId}`);
  }

  getTemplatePreviews(templateId: number) {
    return this.get<TemplatePreview[]>(`admin/templates/${templateId}/previews`);
  }

  async uploadTemplatePreview(templateId: number, file: File, lang: string) {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    formData.append("lang", lang);

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");

    return this.post(`admin/templates/${templateId}/previews`, formData, {
      headers,
    });
  }

  deleteTemplatePreview(id: number) {
    return this.delete<unknown>(`admin/templates/${0}/previews/${id}`);
  }

  saveTemplatesOrder(data: Template[]) {
    return this.post<unknown>("admin/templates/update-order", data);
  }
}
