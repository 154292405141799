import { BehaviorSubject, Observable, distinctUntilChanged, map } from "rxjs";
import { Action } from "./action";

export type StateUpdater<T> = (state: T) => T;
export type FieldGetter<T> = (state: T) => any;

export abstract class Store<T> {
  private state: BehaviorSubject<T>;

  constructor() {
    this.state = new BehaviorSubject<T>(this.getInitialState());
  }

  protected abstract getInitialState(): T;

  protected update(stateUpdater: StateUpdater<T>) {
    this.state.next(stateUpdater(this.state.value));
  }

  protected getState() {
    return this.state.value;
  }

  protected getField<K extends keyof T>(key: K): T[K] {
    return this.state.value[key];
  }

  protected getStateObservable() {
    return this.state;
  }

  protected getFieldObservable<K extends keyof T>(key: K): Observable<T[K]> {
    return this.state.pipe(
      map((state) => state[key]),
      distinctUntilChanged(),
    );
  }

  //   dispatch(action: Action<T>) {}

  //   addReducer(action);
}
