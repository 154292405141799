<m-ng-island [paddingSize]="24">
  <div class="cover-snippets-group">
    <h1>Company cover snippets</h1>

    <form [formGroup]="searchForm">
      <table>
        <thead>
          <th>ID</th>
          <th>Visibility to user</th>
          <th>Preview</th>
          <th *ngIf="canCreatePublicTemplates">Visibility to companies</th>
          <th></th>
        </thead>

        <tbody>
          <tr
            *ngFor="let coverSnippet of coverSnippets; let i = index; trackBy: trackByCoverSnippetId"
            (dragstart)="onDragStart($event, i)"
            (dragover)="onDragOver($event)"
            (drop)="onDrop($event, i)"
            draggable="true"
          >
            <td>{{coverSnippet.id}}</td>

            <td>
              <div class="cell-wrapper">
                <!-- <m-icon
                  *ngIf="coverSnippet.isVisibleToUsers"
                  src="input-eye.svg"
                  size="m"
                  color="--color-success"
                ></m-icon>
                <m-icon
                  *ngIf="!coverSnippet.isVisibleToUsers"
                  src="input-eye-closed.svg"
                  size="m"
                  color="--color-error"
                ></m-icon> -->

                <m-switch
                  [isChecked]="coverSnippet.isVisibleToUsers"
                  (onToggle)="onChangeVisibleToUsers(coverSnippet)"
                />
              </div>
            </td>

            <td>
              <div class="preview-image-wrapper">
                <img class="preview-image" [src]="getUrlForPreviewImage(coverSnippet)" alt="" />
              </div>
            </td>

            <td *ngIf="canCreatePublicTemplates">
              <div class="cell-wrapper">
                <!-- <m-icon
                  *ngIf="coverSnippet.isVisibleToOtherCompanies"
                  src="input-eye.svg"
                  size="m"
                  color="--color-success"
                ></m-icon>
                <m-icon
                  *ngIf="!coverSnippet.isVisibleToOtherCompanies"
                  src="input-eye-closed.svg"
                  size="m"
                  color="--color-error"
                ></m-icon> -->

                <m-switch
                  [isChecked]="coverSnippet.isVisibleToOtherCompanies"
                  (onToggle)="onChangeVisibleToOtherCompanies(coverSnippet)"
                />
              </div>
            </td>

            <td class="controls">
              <!-- <a [routerLink]="'/admin/cover-snippets/' + coverSnippet.id">
                <m-icon class="settings-icon" size="s" src="gear.svg" color="--color-font-main" />
              </a> -->
              <a (click)="onDeleteCoverSnippetClick(coverSnippet.id)">
                <m-icon class="settings-icon" size="s" src="block-delete.svg" color="--color-error" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </form>

    <m-admin-pagination
      [pageCount]="pageCount"
      [currentPage]="page"
      (onPageChanged)="onPageChanged($event)"
    ></m-admin-pagination>

    <m-modal *ngIf="isDeleteCoverSnippetModalVisible" (close)="onCloseDeleteCoverSnippetModal()">
      <div class="hide-modal">
        <p i18n="@@admin.cover-snippets.cover-snippet.hide-coverSnippet-confirmation">
          admin.cover-snippets.cover-snippet.hide-coverSnippet-confirmation
        </p>
        <m-button
          (onClick)="onDeleteCoverSnippet()"
          button-style="secondary"
          size="full-width"
          i18n="@@admin.common.hide"
        >
          admin.common.hide
        </m-button>
      </div>
    </m-modal>
  </div>

  <div class="cover-snippets-group">
    <h1>Public cover snippets</h1>

    <form [formGroup]="searchForm">
      <table>
        <thead>
          <th>ID</th>
          <th>Preview</th>
          <th></th>
          <th>Visibility to user</th>
          <th>Premium Cover Snippet</th>
        </thead>

        <tbody>
          <tr *ngFor="let coverSnippetAccess of coverSnippetsAccess; let i = index; trackBy: trackByCoverSnippetId">
            <td>{{coverSnippetAccess.id}}</td>

            <td>
              <div class="preview-image-wrapper">
                <img class="preview-image" [src]="getUrlForPreviewImage(coverSnippetAccess.coverSnippet)" alt="" />
              </div>
            </td>

            <td>
              <m-icon
                *ngIf="coverSnippetAccess.isVisibleToUsers"
                src="input-eye.svg"
                size="m"
                color="--color-success"
              ></m-icon>
              <m-icon
                *ngIf="!coverSnippetAccess.isVisibleToUsers"
                src="input-eye-closed.svg"
                size="m"
                color="--color-error"
              ></m-icon>
            </td>

            <td>
              <m-switch
                [isChecked]="coverSnippetAccess.isVisibleToUsers"
                (onToggle)="onCoverSnippetVisibleClick(coverSnippetAccess)"
                input-style="on-panel"
              />
            </td>

            <td>
              <m-switch
                [isChecked]="coverSnippetAccess.isPremium"
                (onToggle)="onCoverSnippetPremiumChangeClick(coverSnippetAccess)"
                input-style="on-panel"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>

    <m-admin-pagination
      [pageCount]="coverSnippetsAccessPageCount"
      [currentPage]="coverSnippetsAccessPage"
      (onPageChanged)="onCoverSnippetsAccessPageChanged($event)"
    ></m-admin-pagination>
  </div>
</m-ng-island>
