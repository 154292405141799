import { Injectable } from "@angular/core";
import { GeneralResultStatus } from "@metranpage/core-data";
import { UserTemplateApi } from "./user-template.api";

export type UploadUserTemplateFileResultStatus = GeneralResultStatus | "file-limit-error";
export type UploadUserTemplateFileResponse = {
  success: boolean;
  error?: string;
};

export type UserTemplateData = {
  phone: string;
};

@Injectable({
  providedIn: "root",
})
export class UserTemplateService {
  constructor(private readonly userTemplateApi: UserTemplateApi) {}

  async uploadUserTemplateFile(data: UserTemplateData, file: File): Promise<UploadUserTemplateFileResultStatus> {
    try {
      const response = await this.userTemplateApi.uploadUserTemplateFile(data, file);
      if (!response.success && response.error === "file-limit-error") {
        return "file-limit-error";
      }

      return "success";
    } catch (error: any) {
      return "error";
    }
  }
}
