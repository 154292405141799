import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconComponent } from "../icon/icon.component";
import { ReactiveFormsModule } from "@angular/forms";
import { InputComponent } from "../input/input.component";

@Component({
  selector: "m-ng-input-with-prefix",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IconComponent, InputComponent],
  templateUrl: "./input-with-prefix.component.html",
  styleUrls: ["../input/input.component.scss", "./input-with-prefix.component.scss"],
})
export class InputWithPrefixComponent extends InputComponent {
  @Input()
  prefix?: string = undefined;
}
