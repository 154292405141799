<form *ngIf="!promocodeDiscountData" class="wrapper" [formGroup]="form">
  <m-ng-input
    formControlName="promocode"
    text-align="center"
    placeholder="pricing.promocode.enter-promocode"
    i18n-placeholder="@@pricing.promocode.enter-promocode"
  />

  <m-button
    button-style="primary"
    size="full-width"
    (onClick)="onEnterPromocodeClick()"
    i18n="@@pricing.promocode.activate-button"
  >
    pricing.promocode.activate-button
  </m-button>
</form>

<div *ngIf="promocodeDiscountData" class="discount-wrapper">
  <div class="discount-title">
    <span i18n="@@pricing.promocode.discount.title">pricing.promocode.discount.title</span>
    <span class="accent" i18n="@@pricing.promocode.discount.title-accent">pricing.promocode.discount.title-accent</span>
    <span class="accent">{{ promocodeDiscountData.discount }}%</span>
  </div>
  <div class="discount-expire-date">
    <span i18n="@@pricing.promocode.discount.description">pricing.promocode.discount.description</span>
    <span>{{ getDateShort(promocodeDiscountData.expireDate) }}</span>
  </div>
</div>

<m-modal
  *ngIf="promocodeResult?.status === 'success'"
  [closeButtonVisible]="true"
  [closeOnBackDropClick]="true"
  (close)="onCloseClick()"
>
  <div class="container">
    <img src="/assets/img/gift.svg" />

    <div class="content">
      <div class="header">
        <div class="title" i18n="@@pricing.promocode.activation.success">pricing.promocode.activation.success</div>
        <m-localized-text
          *ngIf="promocodeResult?.description"
          class="description"
          [text]="promocodeResult?.description"
        />
      </div>
      <div class="controls">
        <m-button
          button-style="primary"
          size="full-width"
          (onClick)="onCloseClick()"
          i18n="@@pricing.promocode.activation.confirm"
        >
          pricing.promocode.activation.confirm
        </m-button>
      </div>
    </div>
  </div>
</m-modal>
