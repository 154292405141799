import { Component, ElementRef, Input } from "@angular/core";

@Component({
  selector: "m-account-menu-item-component",
  template: `
    <div class="content">
      <m-ng-icon class="icon" [class.icon-dander]="colorStyle === 'danger'" [src]="icon" [size]="28" />
      <span [class.dander]="colorStyle === 'danger'">{{ title }}</span>
    </div>
    <m-ng-icon *ngIf="showWarning" class="icon-warning" src="info.svg" [size]="28" />
  `,
  styles: [
    `
      :host {
        display: flex;
        width: 100%;
        height: 48px;
        align-items: center;
        justify-content: flex-start;
        padding: 8px 16px;
        border: 1px 0 0 0;
        cursor: pointer;
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-semibold);
        gap: 10px;
        user-select: none;
        color: var(--text-text-80);
        border-left: 2px solid transparent;

        m-ng-icon {
          &.icon {
            --icon-color: var(--text-text-80);
          }

          &.icon-warning {
            --icon-color: var(--color-font-running-title-info);
          }

          &.icon-dander {
            --icon-color: var(--danger);
          }
        }

        &:hover {
          border-color: var(--text-text-80);
        }

        &.active {
          border-color: var(--accent);
          color: var(--accent);

          m-ng-icon {
            &.icon {
              --icon-color: var(--accent);
            }
          }
        }


      }

      .content {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 10px;
      }

      .dander {
        color: var(--danger);
      }
    `,
  ],
})
export class AccountMenuItemComponent {
  @Input()
  icon!: string;
  @Input()
  title!: string;
  @Input()
  colorStyle: "normal" | "danger" = "normal";
  @Input()
  showWarning? = false;
}
