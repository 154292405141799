import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { StyleKey } from "@metranpage/markup-editor";
import { StylesSettings } from "@metranpage/book-data";
import { SelectValue, fadeInOutForSidebarElements, slideInOutVertical } from "@metranpage/components";
import * as _ from "lodash-es";
import { Subscription } from "rxjs";

@Component({
  selector: "m-books-markup-sidebar-text",
  templateUrl: "./markup-sidebar-text-settings.view.html",
  styleUrls: ["./markup-sidebar-text-settings.view.scss"],
  animations: [slideInOutVertical, fadeInOutForSidebarElements],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkupSidebarTextSettings implements OnChanges, OnDestroy {
  @Input()
  styles!: StylesSettings;
  @Input()
  selectedStyles!: StyleKey[];
  @Input()
  hoveredStyle: StyleKey | undefined = undefined;
  @Input()
  availableMainFonts!: SelectValue[];
  @Input()
  availableHeadersFonts!: SelectValue[];
  @Input()
  form!: FormGroup;
  @Input()
  styleDetailsSidebarVisible: StyleKey | undefined = undefined;
  @Input()
  isSettingsAvailable = false;

  // @Output()
  // onStyleDisplayOptionChanged = new EventEmitter<{ style: StyleKey; opts: StyleDisplayOpts }>();
  @Output()
  onStyleSelected = new EventEmitter<StyleKey>();
  @Output()
  onShowStyleDetails = new EventEmitter<StyleKey>();

  inactiveStylesList: string[] = ["table", "page-break", "image"];

  private sub = new Subscription();

  get isInactiveStyle(): boolean {
    return this.inactiveStylesList.includes(this.selectedStyles[0]);
  } // this.tableSelectedRowsCount = this.tableSelectedCells.stop?.rI + this.tableSelectedCells.stop?.rowOffset - this.tableSelectedCells.start?.rI + 1;
  // this.tableSelectedColumnsCount = this.tableSelectedCells.stop?.cI + this.tableSelectedCells.stop?.colOffset - this.tableSelectedCells.start?.cI + 1;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedStyles?.currentValue) {
      this.cdr.markForCheck();
    }
  }

  onStyle(styleKey: StyleKey): void {
    if (!this.isInactiveStyle) {
      this.onStyleSelected.emit(styleKey);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getStyleKeys(): StyleKey[] {
    // return _.filter(this.styles, (s) => s.isDisplayedAtSidebar).map((s) => s.styleKey!);

    return _.filter(this.styles, (s) => s.isDisplayedAtSidebar)
      .sort((a, b) => a.order - b.order)
      .map((s) => s.styleKey!);
  }

  isStyleSettingsValid(styleKey: StyleKey) {
    return this.form.get("styles")?.get(styleKey)?.valid;
  }
}
