<m-ng-island [class.modal]="isModal">
  <div class="container">
    <div *ngIf="selectedTab === 2" class="character-wrapper" (click)="showCharactersModalClick()">
      <m-ng-icon src="character.svg" [size]="28" />
    </div>

    <form *ngIf="form" [formGroup]="form" class="controls">
      <div class="control">
        <m-prompt-text-editor
          #promptText
          formControlName="prompt"
          [maxLenght]="promptMaxLenght"
          [characters]="characters"
          [charactersInPromptLimit]="charactersInPromptLimit"
          [errors]="promptErrors"
          [needBlockArrowsUpAndDown]="needBlockArrowsUpAndDown()"
          (charactersIdsChanged)="charactersIdsChanged($event)"
          (notExistingCharactersIdsChanged)="notExistingCharactersIdsChanged($event)"
          (onShowChangePopup)="showChangePopup($event)"
          (onShowCharactersModal)="showCharactersModal($event)"
        />
      </div>

      <!-- <m-prompt-restriction *ngIf="formAdvanced.errors?.restrictedWord" /> -->
    </form>

    <div class="buttons">
      <m-button button-style="primary" (onClick)="onGenerationClick()">
        <span i18n="@@image-generation.generate">image-generation.generate</span>
        <div class="price">
          <m-ng-icon class="token-icon" src="/assets/img/app-bar-menu/token-simple-{theme}.svg" [size]="20" />
          <span *ngIf="igBasicPrice || igAdvancedPrice">{{getPrice()}}</span>
        </div>
      </m-button>
    </div>
  </div>
</m-ng-island>

<!-- this modal replace text after @ -->
<m-characters-popup
  id="characters-popup"
  #charactersPopup
  [characters]="availableCharacters"
  [hasAddButton]="false"
  [isActive]="isPopupActive"
  (onSelect)="onSelectFromPopup($event)"
  (onSelectByKeyboard)="onSelectFromPopupByKeyboard($event)"
  (onClose)="hidePopup()"
/>

<!-- this modal replace html element character -->
<m-characters-popup
  id="characters-change-popup"
  #charactersChangePopup
  [characters]="availableCharacters"
  [hasAddButton]="true"
  [isActive]="isChangePopupActive"
  (onAdd)="onAddCharacterFromChangePopup()"
  (onSelect)="onSelectFromChangePopup($event)"
  (onSelectByKeyboard)="onSelectFromChangePopupByKeyboard($event)"
  (onClose)="hideChangePopup()"
/>
